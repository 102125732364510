<div>
  <div class="mt-1 ml-3 mr-3">
    <div class="row">
      <div class="mb-3" style="min-height: 300px; width: 100%">
        <div class="table-responsive data-table mb-3">
          <table class="table table-md wbg table-shadow" style="margin-bottom: 0px; width: 100%">
            <thead>
              <tr>
                <td colspan="5">
                  <div class="indived-search" style="position: relative">

                    <input type="search" #inputString class="form-control search-all" style="padding: 4px 12px"
                      placeholder="Search " autocomplete="off" />
                  </div>
                </td>
                <td colspan="8">
                  <div class="pagenation-right d-flex justify-content-end align-items-center">
                    <select class="form-select form-select-dark mx-2" [(ngModel)]="statusValue"
                      (ngModelChange)="dropdownSelect($event)">
                      <option [value]="item.value" *ngFor="let item of listFeed">
                        <ng-container *ngIf="item.type === 'call' ">
                          {{ item.name }}
                        </ng-container>                        
                      </option>
                    </select>
                    <div class="float-right" style="color: #fff; font-size: 12px">
                      <app-cus-pagination></app-cus-pagination>
                    </div>
                  </div>
                </td>
              </tr>
            </thead>
            <tr>
              <th nowrap>
                <input type="checkbox" class="selectAll" id="selectAll" [checked]="selectedAll == true"
                  (change)="selectAllforAssignmentNew()" />
              </th>
              <th nowrap *ngFor="let map of columnMaps1">
                {{ map?.header }}
                <span *ngIf="map?.header !== 'Action'"><button class="sortButton"
                    (click)="toggleSort(map?.sortKey,'asc')"><img class="img7 ml-1"
                      src="../../../../assets/img/sort-data.svg" /></button></span>
              </th>
            </tr>
            <tbody>
              <tr *ngFor="let record of records;let id = index">
                <td class="vm" nowrap>
                  <input type="checkbox" name="lead" [checked]="selectedAll == true" class="selectLead"
                    (change)="selectAgentforAssignment($event, record)" id="selectLead" />
                </td>
                <td class="vm" nowrap *ngFor="let map of columnMaps1">
                  <div class="txt-oflow" *ngIf="map?.header !== 'Action';else action_content">
                    <div *ngIf="map?.header !== 'Name';else showEdit ">
                      <ng-container *ngIf="map?.primaryKey === 'CallStatus';else showAll">
                        <div class="status-custom" [ngStyle]="{
                          'color': getFeedbackColor(record[map?.primaryKey])?.textColor,
                          'background-color': getFeedbackColor(record[map?.primaryKey])?.bgColor
                        }">
                          {{ getFeedbackColor(record[map?.primaryKey])?.name}}
                        </div>
                      </ng-container>
                      <ng-template #showAll>
                        {{ record[map?.primaryKey] | formatcell:map?.format }}
                      </ng-template>
                    </div>
                    <ng-template #showEdit>
                      <button class="btn btn-link btn-sm p-0" style="position:relative"
                        (click)="openEditModal('editLead2',record)">
                        {{ record[map?.primaryKey] | formatcell:map?.format }}
                      </button>
                    </ng-template>
                  </div>
                  <ng-template #action_content>
                    <div class="example-button-row mat-bset d-flex align-items-center">
                      <mat-sidenav-container *ngIf="rowSetting?.view">
                        <mat-sidenav-content>
                          <button class="viewLeadButton" mat-button (click)="openSideNav(record?.id)">
                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 32 32">
                              <g id="Group_825" data-name="Group 825" transform="translate(-277.389 -126.804)">
                                <path class="gsvg" id="Path_922" data-name="Path 922"
                                  d="M308.329,142.464a15.8,15.8,0,0,0-29.88,0,.993.993,0,0,0,0,.68,15.8,15.8,0,0,0,29.88,0A1,1,0,0,0,308.329,142.464Zm-14.94,9.34c-5.3,0-10.9-3.93-12.93-9,2.03-5.07,7.63-9,12.93-9s10.9,3.93,12.93,9C304.289,147.874,298.689,151.8,293.389,151.8Z" />
                                <path class="gsvg" id="Path_923" data-name="Path 923"
                                  d="M293.389,136.8a6,6,0,1,0,6,6A6,6,0,0,0,293.389,136.8Zm0,10a4,4,0,1,1,4-4A4,4,0,0,1,293.389,146.8Z" />
                                <rect id="_Transparent_Rectangle_" data-name=" Transparent Rectangle " width="32"
                                  height="32" transform="translate(277.389 126.804)" fill="none" />
                              </g>
                            </svg>
                          </button>
                        </mat-sidenav-content>
                      </mat-sidenav-container>
                      <div ngbTooltip="Call" placement="top" tooltipClass="my-custom-class" class="mr-2"
                        (click)="callModal1('totalCallCount',record,id)" style="display:inline;cursor:pointer"
                        data-toggle="modal" data-target="#callingscreen1" *ngIf="rowSetting?.call">
                        <!-- <img src="./assets/img/call.svg" class="img13" /> -->
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 32 32">
                          <g id="Group_783" data-name="Group 783" transform="translate(-580.695 -61.66)">
                            <path class="gsvg" id="Path_911" data-name="Path 911"
                              d="M606.7,90.66h-.17c-19.65-1.13-22.44-17.71-22.83-22.77a3,3,0,0,1,2.751-3.23h5.519a2,2,0,0,1,1.86,1.26l1.52,3.74a2,2,0,0,1-.44,2.16l-2.13,2.15a9.368,9.368,0,0,0,7.58,7.6l2.17-2.15a2,2,0,0,1,2.17-.41l3.77,1.51a2,2,0,0,1,1.23,1.86v5.28A3,3,0,0,1,606.7,90.66Zm-20-24a1,1,0,0,0-1,1v.08c.46,5.92,3.41,19.92,20.94,20.92a1,1,0,0,0,1.059-.937V82.38l-3.77-1.51-2.87,2.85-.48-.06c-8.7-1.09-9.88-9.79-9.88-9.88l-.06-.48,2.84-2.87-1.5-3.77Z"
                              fill="#993d3e" />
                            <rect id="_Transparent_Rectangle_" data-name=" Transparent Rectangle " width="16"
                              height="16" transform="translate(580.695 61.66)" fill="none" />
                          </g>
                        </svg>
                      </div>
                    </div>
                  </ng-template>
                </td>
              </tr>
              <tr *ngIf="isLoading ==true">
                <td colspan="10">
                  <div class="norecords">
                    <img src="../../../../assets/img/waiting.gif" />

                    <div>Loading Data<br />Please wait while we fetch the data for you</div>
                  </div>
                </td>
              </tr>
              <tr *ngIf="noRecords == true">
                <td colspan="10">
                  <div class="norecords">
                    <img src="../../../../assets/img/nodata.svg" />
                    <div>No records found.<br />Please select a different date range.</div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>