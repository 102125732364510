import { MarketingService } from "./../marketing.service";
import {
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewChildren,
} from "@angular/core";
import * as xlsx from "xlsx";
import { Exceldata } from "../smsmarketing/models/ExecelData.model";
import { SharedService } from "src/app/shared/shared.service";
import { LayoutService } from "src/app/layout/layout.service";
import { ModalDismissReasons, NgbModal } from "@ng-bootstrap/ng-bootstrap";
// import { AuthService } from "src/app/account/auth/auth.service";
// import { RoleService } from "../../role-management/role.service";
import { Subscription } from "rxjs";
import { DOCUMENT } from "@angular/common";
import { ProjectService } from "../../project-management/project.service";
import { MailserviceService } from "../../mailservice.service";
// import { MatSnackBar } from "@angular/material";
import { Router } from "@angular/router";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ProjectchangeService } from "src/app/layout/project-change/projectchange.service";
import { ErrorHandlerService } from "src/app/error-handler.service";
// import { clearTimeout } from 'timers';

@Component({
  selector: "app-callmarketing",
  templateUrl: "./callmarketing.component.html",
  styleUrls: ["./callmarketing.component.css"],
})
export class CallmarketingComponent implements OnInit, OnDestroy {
  public callData: any[] = [];
  public filePath: any;
  public fileData: Exceldata[] = [];
  public userData: any;
  public projectId: any;
  public order: boolean = false;
  public orderFeild: string = "UpdatedDate";
  public orderString: boolean | "asc" | "desc" = "desc";
  public search: any;
  public page: any = 1;
  public pageSize: any = 8;
  public startCall: boolean = false;
  public stopCall: boolean = false;
  public startIndex: number = 0;
  public statusValue = "pending";
  public clearTime: any;
  public downloadLink: string;
  @ViewChild("uploadFile") uploadFile;
  @ViewChildren("icon") audioIcons: any;
  @ViewChildren("audio") audios: any;
  public phoneNumber: any;
  public comments: any;
  public followUpdate: any;
  closeResult: string;
  admin: boolean = false;
  public error: boolean = false;
  public errorMessage: string = "";
  public insertCount: number = 0;
  public uploaded: boolean = false;
  public project: Subscription;
  public currentUser: any = {};
  public sendMails: {
    from: string;
    to: string;
    subject: string;
    content: string;
  } = { from: "", to: "", subject: "", content: "" };
  public currentProject: any = {};
  // public statusValue:any='';
  public answered = [
    "FirstName",
    "PhoneNumber",
    "cc",
    "CalledBy",
    "createdAt",
    "Email",
    "Actions",
  ];
  public pending = [
    "createdAt",
    "FirstName",
    "PhoneNumber",
    "Email",
    "City",
    "Channel",
    "Assignedto",
    "CallStatus",
    "Actions",
  ];

  public interested = [
    "FirstName",
    "PhoneNumber",
    "Email",
    "Channel",
    // "City",
    "CalledBy",
    "CalledDate",
    "CallStatus",
    // "DemoScheduled",
    // "RecordingDuration",
    "Actions",
  ];

  public tableColumns = [
    "createdAt",
    "FirstName",
    "PhoneNumber",
    "Email",
    "City",
    "Channel",
    "Assignedto",
    "CallStatus",
    "Actions",
  ];

  // public callStopped: boolean = false;

  constructor(
    private readonly marketingService: MarketingService,
    private readonly sharedService: SharedService,
    private readonly layoutService: LayoutService,
    private readonly projectService: ProjectService,
    private readonly modalService: NgbModal,
    private readonly mailService: MailserviceService,
    private readonly snackbarRef: MatSnackBar,
    private readonly router: Router,
    public projectchangeService: ProjectchangeService,
    private handleError: ErrorHandlerService,
    @Inject(DOCUMENT) private domService: HTMLDocument // private readonly loginService: AuthService, // private readonly companyService: RoleService
  ) { }

  ngOnDestroy(): void {
    if (this.project) {
      this.project.unsubscribe();
    }
  }

  ngOnInit() {
    this.marketingService
      .getExcelLink("services/TemplateDocuments/accessque.xlsx")
      .subscribe((data) => {
        this.downloadLink = data["url"];
      }, (error) => {
        this.handleError.handleError(error)
      });
    this.userData = JSON.parse(localStorage.getItem("userData"));
    // this.project = this.sharedService.projectId.subscribe((data) => {
    //   if (data) {
    //     this.projectId = data;
    //     this.statusValue = "all";
    //     this.topBarTitle();
    //     this.projectService.getProject(this.projectId).subscribe((data) => {
    //       this.sendMails.from = data["SupportMail"];
    //     });
    //     this.sharedService.getProjectAdmin(this.projectId).subscribe((data) => {
    //       this.admin = data;
    //       this.getCallData();
    //     });
    //     this.sharedService.currentProject.subscribe((data) => {
    //       if (data) {
    //         this.currentProject = data;
    //       }
    //     });
    //   }
    // });
    setTimeout(() => {
      this.projectId = this.projectchangeService._projectId;
      this.statusValue = "all";
      this.topBarTitle();
      this.projectService.getProject(this.projectId).subscribe((data) => {
        this.sendMails.from = data["SupportMail"];
      }, (error) => {
        this.handleError.handleError(error)
      });
      this.sharedService.getProjectAdmin(this.projectId).subscribe((data) => {
        this.admin = data;
        this.getCallData();
      }, (error) => {
        this.handleError.handleError(error)
      });
      this.sharedService.currentProject.subscribe((data) => {
        if (data) {
          this.currentProject = data;
        }
      });
    }, 500);
  }

  topBarTitle() {
    this.sharedService.pageEvent.emit({
      pageName: `Call Marketing`,
    });
  }


  getCallData() {
    this.marketingService
      .getCallData({
        ProjectId: this.projectId,
        callStatus: this.statusValue,
        mailId: this.userData["mail"],
        admin: this.admin,
      })
      .subscribe((data) => {
        this.callData = data;
      }, (error) => {
        this.handleError.handleError(error)
      });
  }


  open(content) {
    this.modalService.open(content, { size: "lg" }).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }

  private getDismissReason(reason: any): string {
    this.phoneNumber = "";
    this.comments = "";
    this.followUpdate = "";
    this.currentUser = {};

    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }

  // stopCallListener() {
  //   this.stopCall = true;
  //   this.startCall = false;
  //   clearTimeout(this.clearTime);
  //   if (this.statusValue !== "pending") {
  //     this.startIndex += 1;
  //   }
  // }

  sortOrder(feildName) {
    this.orderFeild = feildName;
    this.order = !this.order;
    if (this.order) {
      this.orderString = "asc";
    } else {
      this.orderString = "desc";
    }
  }

  dropdownSelect(value) {
    this.router.navigate([`/leadsDashboard/type/${value}`]);
  }

  showCallDetails(content, item) {
    if (
      item["CallStatus"] === "pending" ||
      item["CallStatus"] === "notanswered"
    ) {
      this.statusValue = "pending";
    }
    if (item["Phase"] === "demo" || item["Phase"] === "followup") {
      this.statusValue = "interested";
    }
    if (item["CallStatus"] === "notinterested") {
      this.statusValue = "notinterested";
    }
    if (
      item["Phase"] === "demo-interested" ||
      item["Phase"] === "demo-followup" ||
      item["Phase"] === "   MOU-Sent" ||
      item["Phase"] === "MOU-Signed" ||
      item["Phase"] === "Payment-raised" ||
      item["Phase"] === "Payment-Received" ||
      item["Phase"] === " Invoice-Sent"
    ) {
      this.statusValue = "demo-interested";
    }
    if (item["Phase"] === "demo-notinterested") {
      this.statusValue = "demo-notinterested";
    }

    this.marketingService
      .showDetails(item["id"], this.statusValue)
      .subscribe((data) => {
        this.currentUser = data;
        this.open(content);
      }, (error) => {
        this.handleError.handleError(error)
      });
  }

  deleteContact(id) {
    this.marketingService.deleteContact(id).subscribe((data) => {
      this.getCallData();
    }, (error) => {
      this.handleError.handleError(error)
    });
  }

  audioPlay(audio: any, icon: any) {
    if ([...icon.classList].includes("fa-play")) {
      this.audioIcons._results.forEach((element) => {
        if ([...element.nativeElement.classList].includes("fa-pause")) {
          element.nativeElement.classList.remove("fa-pause");
          element.nativeElement.classList.add("fa-play");
        }
      });
      this.audios._results.forEach((element) => {
        element.nativeElement.pause();
      });
      icon.classList.add("fa-pause");
      icon.classList.remove("fa-play");
      audio.play();
    } else {
      icon.classList.add("fa-play");
      icon.classList.remove("fa-pause");
      audio.pause();
    }

    // audio.nativeElement.play();
    let current = this.audios.filter((el) => el === audio);

    let eAudio = this.domService.getElementsByTagName("audio");
    // if (eAudio && eAudio.length > 0) {
    //   for (var i = 0; i < eAudio.length; i++) {
    //     if (e.target !== eAudio[i]) {
    //       eAudio[i].pause();
    //     }
    //   }
    // }
  }

  openMail(content, email) {
    this.sendMails.to = email;
    this.open(content);
  }

  sendMail() {
    this.mailService
      .sendmail(
        {
          Body: { html: this.sendMails.content },
          From: {
            email: "support@accessque.com",
            name: this.currentProject["Name"] || "Accessque Ltd",
          },
          To: this.sendMails.to,
          ReplyTo: this.sendMails.from,
          Subject: this.sendMails.subject,
        },
        this.projectId
      )
      .subscribe((data) => {

        this.snackbarRef.open("Mail Sent Successfully", "Close", {
          verticalPosition: "top",
          horizontalPosition: "end",
          duration: 5000,
        });
        this.modalService.dismissAll();
      }, (error) => {
        this.handleError.handleError(error)
      });
  }
  projectChange(data) {
    this.projectId = data;
    this.statusValue = "all";
    this.topBarTitle();
    this.projectService.getProject(this.projectId).subscribe((data) => {
      this.sendMails.from = data["SupportMail"];
    }, (error) => {
      this.handleError.handleError(error)
    });
    this.sharedService.getProjectAdmin(this.projectId).subscribe((data) => {
      this.admin = data;
      this.getCallData();
    }, (error) => {
      this.handleError.handleError(error)
    });
    this.sharedService.currentProject.subscribe((data) => {
      if (data) {
        this.currentProject = data;
      }
    });
  }
}
