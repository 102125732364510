import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { LeadsDashboardService } from '../leads-dashboard/leads-dashboard.service';
import { MatDialog } from "@angular/material/dialog";
import { NgbModal, NgbModalOptions, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { CountupTimerService } from 'ngx-timer';
import { MomentFormatService } from 'src/app/moment-format-service';
import { UhLeadsService } from '../uhleads.service';
import * as XLSX from 'xlsx';
import { ExportExcelService } from '../excel.service';


@Component({
  selector: "app-company-home",
  templateUrl: "./company-home.component.html",
  styleUrls: ["./company-home.component.scss"],
})
export class CompanyHomeComponent implements OnInit {
  userData: any;
  companyId: any;
  companyInfo: any;
  projectsList: any = [];
  docUrl: any;

  arrayBuffer: any;
  file: File;
  selectedProjectId: any;
  colData: { displayName: string; }[];
  companyRegistDocuments: any;
  companyPanDocument: any;
  companyTanDocument: any;
  documentType: any;

  constructor(
    private route: ActivatedRoute,
    private LeadsDashboardService: LeadsDashboardService,
    public dialog: MatDialog,
    private modalService: NgbModal,
    private vref: ViewContainerRef,
    public countupTimerService: CountupTimerService,
    public momentService: MomentFormatService,
    private uhleadsservice: UhLeadsService,
    public ExcelService: ExportExcelService,
    public router: Router,

  ) { }

  ngOnInit(): void {
    this.userData = JSON.parse(localStorage.getItem("userData"));
    this.companyId = this.route.snapshot.paramMap.get("companyId");
    console.log("companyId: ", this.companyId);
    this.getCompanyInfo(this.companyId);
    this.getProjects(this.companyId);
    // document.documentElement.style.setProperty('--rorotel-sidebar-topbar-text-color', 'red')
  }

  getCompanyInfo(companyId) {
    this.LeadsDashboardService.getCompanyInfo({
      companyId: companyId,
      mail: this.userData.mail
    }).subscribe((res: any) => {
      console.log("Company Info: ", res.data[0]);
      this.companyInfo = res.data[0];
    });
  }

  getProjects(companyId) {
    this.LeadsDashboardService.getProjects({ companyId: companyId }).subscribe(
      (res: any) => {
        if (res.status == 200) {
          console.log("Projects: ", res.projects);
          this.projectsList = res.projects;
          for (let i = 0; i < this.projectsList.length; i++) {
            this.projectsList[i].dashboardUrl =
              "/" + this.projectsList[i].projectId + "/leads";
          }
        }
      }
    );
  }

  openPreview(content, url) {
    // this.docUrl = '';
    this.docUrl = url;
    this.modalService
      .open(content, {
        ariaLabelledBy: "modal-basic-title",
        windowClass: "myCustomModalClass",
      })
      .result.then(
        (result) => { },
        (reason) => { }
      );
  }

  contentLoaded() {
    document.getElementById("progressBar").style.display = "none";
  }

  inviteAgentModal(inviteLeadsModal, projectId) {
    // console.log("Leads: ", this.leadIds)
    this.selectedProjectId = projectId;
    this.modalService
      .open(inviteLeadsModal, {
        backdrop: "static",
        ariaLabelledBy: "modal-basic-title",
        windowClass: "myCustomModalClass",
      })
      .result.then(
        (result) => { },
        (reason) => { }
      );
  }

  incomingfile(event) {
    this.file = event.target.files[0];
  }

  Upload() {
    let fileReader = new FileReader();
    fileReader.onload = (e) => {
      this.arrayBuffer = fileReader.result;
      var data = new Uint8Array(this.arrayBuffer);
      var arr = new Array();
      for (var i = 0; i != data.length; ++i)
        arr[i] = String.fromCharCode(data[i]);
      var bstr = arr.join("");
      var workbook = XLSX.read(bstr, { type: "binary" });
      var first_sheet_name = workbook.SheetNames[0];
      var worksheet = workbook.Sheets[first_sheet_name];
      console.log(XLSX.utils.sheet_to_json(worksheet, { raw: true }));
      let emailList = XLSX.utils.sheet_to_json(worksheet, { raw: true });

      let usersList = [];
      let projects = [];

      for (let i = 0; i < emailList.length; i++) {
        console.log(emailList[i]["Email"]);
        console.log(this.validateEmail(emailList[i]["Email"]));

        if (this.validateEmail(emailList[i]["Email"])) {
          usersList.push({
            inviteEmails: emailList[i]["Email"],
            projects: [{
              projectId: this.selectedProjectId,
              role: emailList[i]["Role"],
            }],
          });
        }
      }

      let payload = {
        userId: this.userData.id,
        mail: this.userData.mail,
        token: this.userData.token,
        emailBody: "Inviting USer",
        companyId: this.companyId,
        inviteUsersProjects: usersList,
      };

      console.log("Payload: ", payload);

      this.LeadsDashboardService.inviteUSers(payload).subscribe((res: any) => {
        if (res.status == 200) {
          this.uhleadsservice.showSuccess("Users invited successfully", "Success");
          document.getElementById("closeInviteButton").click();
        } else {
          this.uhleadsservice.showError("Something went wrong", "Error");
        }
      })
    };
    fileReader.readAsArrayBuffer(this.file);
  }

  validateEmail(email) {
    var emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailPattern.test(email);
  }

  downloadTemplate() {
    this.colData = [{ displayName: 'Email' }, { displayName: 'Role' }]
    let colNames = [{}];
    // console.log(this.colData)
    for (let i = 0; i < this.colData.length; i++) {

      colNames[0][this.colData[i].displayName] = ''
    }
    console.log(colNames)
    this.ExcelService.exportAsExcelFile(colNames, 'agents_upload');

    console.log("Generate Excel file")
  }

  getFileDetails(e, type) {
    console.log(e);
    this.documentType = type;
    switch (type) {
      case "companyRegistDocuments":
        this.companyRegistDocuments = e.target.files;
        break;
      case "companyPanDocument":
        this.companyPanDocument = e.target.files;
        break;
      case "companyTanDocument":
        this.companyTanDocument = e.target.files;
        break;
    }
    this.uploadFile();
  }

  uploadFile() {
    console.log("File upload: ", this.documentType)
    // formData.append(
    //   "companyRegistDocuments",
    //   this.companyRegistDocuments[0],
    //   this.companyRegistDocuments[0].name
    // );
    let payload = {}
    let formData: FormData = new FormData();
    formData.append("companyId", this.companyId);

    if (this.documentType == 'companyRegistDocuments') {
      formData.append(
        "companyRegistDocuments",
        this.companyRegistDocuments[0],
        this.companyRegistDocuments[0].name
      );
    } else if (this.documentType == 'companyPanDocument') {
      formData.append(
        "companyPanDocument",
        this.companyPanDocument[0],
        this.companyPanDocument[0].name
      );
    } else if (this.documentType == 'companyTanDocument') {
      formData.append(
        "companyTanDocument",
        this.companyTanDocument[0],
        this.companyTanDocument[0].name
      );
    }


    this.LeadsDashboardService.uploadCompanyDocument(formData).subscribe((res: any) => {
      if (res.status == 200) {
        this.uhleadsservice.showSuccess("File upload successful", "Success");
        this.getCompanyInfo(this.companyId);
      } else {
        this.uhleadsservice.showError("Upload failed. Please try again", "Error")
      }
    })
  }
  openAddUserModel() {
    console.log("UserModel");
  }
  inviteUserModal(inviteusermodals, projectId?: any) {
    // console.log("Leads: ", this.leadIds)
    // this.selectedProjectId = projectId;
    this.modalService
      .open(inviteusermodals, {
        backdrop: "static",
        ariaLabelledBy: "modal-basic-title",
        windowClass: "myCustomModalClass",
      })
      .result.then(
        (result) => { },
        (reason) => { }
      );
  }
  openAgentManagement() {
    this.router.navigate(['/' + this.companyId + '/agents'])
  }
}