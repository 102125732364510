<form class="pt-2" [formGroup]="updateLead" (ngSubmit)="updateLead()">
  <div class="form-row">
    <div class="form-group col-md-4">
      <label>First Name</label>
      <input class="form-control" name="FirstName" formControlName="FirstName" [ngClass]="{
                'is-invalid': f.FirstName.touched && f.FirstName.invalid
              }" />
      <div *ngIf="f.FirstName.touched && f.FirstName.errors" class="invalid-feedback">
        <div>First Name is required</div>
      </div>
    </div>
    <div class="form-group col-md-4">
      <label>Last Name</label>
      <input class="form-control" name="LastName" formControlName="LastName" [ngClass]="{
              'is-invalid': f.LastName.touched && f.LastName.invalid
            }" />
      <div *ngIf="f.LastName.touched && f.LastName.errors" class="invalid-feedback">
        <div>Last Name is required</div>
      </div>
    </div>
    <div class="form-group col-md-4">
      <label>Date of Birth</label>
      <input type="date" name="DOB" class="form-control" formControlName="DOB" [ngClass]="{
            'is-invalid': f.DOB.touched && f.DOB.invalid
          }" />
      <div *ngIf="f.DOB.touched && f.DOB.errors" class="invalid-feedback">
        <div>DOB is required</div>
      </div>
    </div>
    <div class="form-group col-md-4">
      <label>Email</label>
      <input class="form-control" name="Email" formControlName="Email" [ngClass]="{
                'is-invalid': f.Email.touched && f.Email.invalid
              }" />
      <div *ngIf="f.Email.touched && f.Email.errors" class="invalid-feedback">
        <div>Email is required</div>
      </div>
    </div>
    <div class="form-group col-md-4">
      <label>Phone Number</label>
      <div class="form-row">
        <div class="col-4">
          <input class="form-control" name="CountryCode" formControlName="CountryCode" [ngClass]="{
            'is-invalid': f.CountryCode.touched && f.CountryCode.invalid
          }" />

        </div>
        <div class="col-8">
          <input class="form-control" name="PhoneNumber" formControlName="PhoneNumber" [ngClass]="{
            'is-invalid': f.PhoneNumber.touched && f.PhoneNumber.invalid
          }" />

        </div>
      </div>
    </div>
    <div class="form-group col-md-4">
      <label for="">Enrollment Year </label>
      <input type="text" name="EnrollmentYear" class="form-control" formControlName="EnrollmentYear" [ngClass]="{
          'is-invalid': f.EnrollmentYear.touched && f.EnrollmentYear.invalid
        }" />

    </div>

    <div class="form-group col-md-4">
      <label for="">Enrollment Term </label>
      <input type="text" name="EnrollmentTerm" class="form-control" formControlName="EnrollmentTerm" [ngClass]="{
          'is-invalid': f.EnrollmentTerm.touched && f.EnrollmentTerm.invalid
        }" />

    </div>

    <div class="form-group col-md-4">
      <label for="">Address </label>
      <input type="text" name="Address" class="form-control" formControlName="Address" [ngClass]="{
          'is-invalid': f.Address.touched && f.Address.invalid
        }" />

    </div>

    <div class="form-group col-md-4">
      <label for="">City </label>
      <input type="text" name="City" class="form-control" formControlName="City" [ngClass]="{
          'is-invalid': f.City.touched && f.City.invalid
        }" />

    </div>
    <div class="form-group col-md-4">
      <label for="">State </label>
      <input type="text" name="State" class="form-control" formControlName="State" [ngClass]="{
          'is-invalid': f.State.touched && f.State.invalid
        }" />

    </div>
    <div class="form-group col-md-4">
      <label for="">Zip </label>
      <input type="text" name="Zip" class="form-control" formControlName="Zip" [ngClass]="{
          'is-invalid': f.Zip.touched && f.Zip.invalid
        }" />

    </div>
    <div class="form-group col-md-4">
      <label for="">Country </label>
      <input type="text" name="Country" class="form-control" formControlName="Country" [ngClass]="{
          'is-invalid': f.Country.touched && f.Country.invalid
        }" />

    </div>
  </div>
  <div class="form-row">

    <div class="form-group col-md-8">
      <label for="">Comments </label>
      <textarea type="text" name="LeadComment" class="form-control" formControlName="LeadComment" [ngClass]="{
          'is-invalid': f.LeadComment.touched && f.LeadComment.invalid
        }">

        </textarea>

    </div>

  </div>
  <div class="form-row">
    <div class="form-group col-md-12">
      <label for="">Feedback</label>
      <div class="mt-2">

        <div class="form-check form-check-inline" *ngFor="let item of listFeed">
          <input class="form-check-input" type="radio" name="CallStatus" id="inlineRadio1" [value]="item.value"
            formControlName="CallStatus" />
          <label class="form-check-label" for="inlineRadio1">{{item.name}}</label>
        </div>

      </div>
    </div>

  </div>
  <div class="d-flex justify-content-end">
    <div class="mt-3">
      <button class="btn btn-primary btn-sm" type="submit">Update</button>
    </div>
  </div>
</form>


<!-- <form class="pt-2" [formGroup]="updateLead" (ngSubmit)="updateLead()">
  <div class="form-row">
      <div class="form-group col-md-4" *ngFor="let item of formSetting">
          <label>{{item.label}}</label>
          <input class="form-control" 
          [type]="item.type"
          name="FirstName" 
          [formControlName]="item.formControlName" 
          [ngClass]="{
              'is-invalid': f.[item.formControlName].touched && f.[item.formControlName].invalid
            }" />
          <div *ngIf="f.[item.formControlName].touched && f.[item.formControlName].errors" class="invalid-feedback">
            <div>{{item.requiredMsg}}</div>
          </div>
      </div>
  </div>
</form> -->