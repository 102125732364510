<!-- <p>company-management works!</p> -->
<mat-sidenav id="sideNav" #sideNav mode="side" class="pr" opened="false" position="end" class="pf w45 z-top">
  <div class="modal-header d-flex pf w45" style="right: 0; top: 0; z-index: 9999">
    <div class="d-flex">
      <div class="close hand" (click)="sideNav.toggle()">
        <span aria-hidden="true">&times;</span>
      </div>
      <div class="modal-title mr-auto">Overview</div>
    </div>
  </div>
  <mat-tab-group class="mt-5 sideDataViewer">
    <mat-tab label="Company details">
      <div class="p-2">
        <table class="table table-borderless table-md caller-table my-1">
          <tr>
            <td>Called by</td>
            <td>- - </td>
          </tr>
          <tr>
            <td>Date & Time</td>
            <td>- -</td>
          </tr>
        </table>
      </div>
    </mat-tab>
    <mat-tab label="Call details">
      hello
    </mat-tab>
    <mat-tab label="Document details">
      hello
    </mat-tab>
    <!-- <mat-tab label="Communication">
      hello 
    </mat-tab>
    <mat-tab label="Activities">
      hello 
    </mat-tab>	 -->
  </mat-tab-group>
</mat-sidenav>

<div class="container-fluid">
  <div class="pt-3 pl-2 pr-2 d-flex justify-content-between mb-4">
    <div class="db-sm-header" style="line-height: 330%">Activity</div>
    <div class="db-sm-header mb-1 ml-auto">
      <button class="form-group" style="padding: 0;background:none">
        <select class="form-control cdd" id="" style="padding: 0; background: #fff" (change)="updateDateFilter($event)">
          <option value="all">All</option>
          <option value="24hrs">24 hrs</option>
          <option value="72hrs">72 hrs</option>
          <option value="7days">07 days</option>
          <option value="14days">14 days</option>
          <option value="30days">30 days</option>
          <option value="custom">Custom</option>
        </select>
      </button>
    </div>
    <div *ngIf="showDate" class="datePicker">
      <div style="background: #fff; height: 76px; border: 1px solid #ccc" class="custom-date">
        <mat-form-field class="mr-3">
          <input class="label" matInput [matDatepicker]="picker1" placeholder="Start Date" [formControl]="date2"
            (dateInput)="getStartDate('input', $event)" />
          <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
          <mat-datepicker #picker1 style="width: 80px"></mat-datepicker>
        </mat-form-field>

        <mat-form-field class="mr-3">
          <input class="label" matInput [matDatepicker]="picker2" placeholder="End Date" [formControl]="date1"
            (dateInput)="getEndDate('input', $event)" />
          <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
          <mat-datepicker #picker2 style="width: 80px"></mat-datepicker>
        </mat-form-field>
        <button class="btn btn-secondary btn-sm" (click)="filterData()">
          Submit
        </button>
      </div>
    </div>
  </div>
</div>

<div class="mb-3">

  <carousel [cellsToScroll]="0" style="padding-left: 30px; z-index: unset" class="fixheight">
    <div class="carousel-cell">
      <div class="mr-3">
        <div class="db-block w-175" (click)="getCompanyOnCardSelect('totalcompanies');onClick(1)"
          [ngClass]="{ 'db-block-active': tab === 'tab1' }">
          <div class="p-3">
            <div class="d-flex">
              <div class="img-circle d-flex">
                <img src="../../../assets/images/company-c.svg" style="width: 50px" />
              </div>
              <div class="f25 text-right ml-auto mt-3">
                <!-- {{ totalApplications }} -->
                {{topBarCounts.totalcompanies}}
              </div>
            </div>
            <div class="box-sub-text1 mt-2">Total Companies</div>
          </div>
        </div>
      </div>
    </div>

    <div class="carousel-cell">
      <div class="mr-3">
        <div class="db-block w-175" (click)="getCompanyOnCardSelect('currentmonthcompanies');onClick(2)"
          [ngClass]="{ 'db-block-active': tab === 'tab2' }">
          <div class="p-3">
            <div class="d-flex">
              <div class="img-circle d-flex">
                <img src="../../../assets/images/empty-calendar.svg" style="width: 50px" />
              </div>
              <div class="f25 text-right ml-auto mt-3">
                <!-- {{ totalApplications }} -->
                {{topBarCounts.currentmonthcompanies}}
              </div>
            </div>
            <div class="box-sub-text1 mt-2">Enrolled in <b>{{currentmonthcompanies || 'N/A'}}</b></div>
          </div>
        </div>
      </div>
    </div>

    <div class="carousel-cell">
      <div class="mr-3">
        <div class="db-block w-175" (click)="getCompanyOnCardSelect('last6monthcompanies');onClick(3)"
          [ngClass]="{ 'db-block-active': tab === 'tab3' }">
          <div class="p-3">
            <div class="d-flex">
              <div class="img-circle d-flex">
                <img src="../../../assets/images/six-month-calendar.svg" style="width: 50px" />
              </div>
              <div class="f25 text-right ml-auto mt-3">
                <!-- {{ totalApplications }} -->
                {{topBarCounts.last6monthcompanies}}
              </div>
            </div>
            <div class="box-sub-text1 mt-2">Enrolled in <b>6 Months</b></div>
          </div>
        </div>
      </div>
    </div>

    <div class="carousel-cell">
      <div class="mr-3">
        <div class="db-block w-175" (click)="getCompanyOnCardSelect('acivecompanies');onClick(4)"
          [ngClass]="{ 'db-block-active': tab === 'tab4' }">
          <div class="p-3">
            <div class="d-flex">
              <div class="img-circle d-flex">
                <img src="../../../assets/images/active-company.svg" style="width: 50px" />
              </div>
              <div class="f25 text-right ml-auto mt-3">
                <!-- {{ totalApplications }} -->
                {{topBarCounts.acivecompanies}}

              </div>
            </div>
            <div class="box-sub-text1 mt-2">Active Companies</div>
          </div>
        </div>
      </div>
    </div>

    <div class="carousel-cell">
      <div class="mr-3">
        <div class="db-block w-175" (click)="getCompanyOnCardSelect('inacivecompanies');onClick(5)"
          [ngClass]="{ 'db-block-active': tab === 'tab5' }">
          <div class="p-3">
            <div class="d-flex">
              <div class="img-circle d-flex">
                <img src="../../../assets/images/inactive-company.svg" style="width: 50px" />
              </div>
              <div class="f25 text-right ml-auto mt-3">
                <!-- {{ totalApplications }} -->
                {{topBarCounts.inacivecompanies}}

              </div>
            </div>
            <div class="box-sub-text1 mt-2">Inactive Companies</div>
          </div>
        </div>
      </div>
    </div>
    <div class="carousel-cell">
      <div class="mr-3">
        <div class="db-block w-175" (click)="getCompanyOnCardSelect('renewalExpirycompanies');onClick(6)"
          [ngClass]="{ 'db-block-active': tab === 'tab6' }">
          <div class="p-3">
            <div class="d-flex">
              <div class="img-circle d-flex">
                <img src="../../../assets/images/about-renew.svg" style="width: 50px" />
              </div>
              <div class="f25 text-right ml-auto mt-3">
                <!-- {{ totalApplications }} -->
                {{topBarCounts.renewalExpirycompanies}}
              </div>
            </div>
            <div class="box-sub-text1 mt-2">Renewal Expiry in <b>{{currentmonthcompanies || 'N/A'}}</b></div>
          </div>
        </div>
      </div>
    </div>
    <div class="carousel-cell"></div>

  </carousel>
</div>
<div class="containers">
  <div class="d-flex mb-0 mx-3 mt-3 align-items-center">
    <div class="header-12 mr-auto pt-2">
      List of Companies
    </div>
    <div class="ml-auto">
      <button ngbTooltip="Create Company" placement="top" tooltipClass="my-custom-class"
        class="btn btn-secondary btn-sm d-flex align-content-center" (click)="open(content)"
        *ngIf="userData['companyId'] === 2">
        <img alt="" class="img11" src="./assets/img/add-w.svg" /> &nbsp; Create Company</button>
    </div>
  </div>

  <div class="m-3">
    <div class="table-responsive data-table" style="background:#fff">

      <table class="table table-md">
        <thead>
          <tr>
            <td colspan="17" style="padding: 8px">
              <div class="table-search-content">
                <div class="indived-search" style="position: relative">
                  <input type="text" class="form-control search-all" [(ngModel)]="search" placeholder="Search..." />
                </div>
                <div class="pagenation-right">
                  <ngb-pagination [(page)]="page" [pageSize]="pageSize" [collectionSize]="totalCompanies.length"
                    class="pagenation-right" [maxSize]="5" [boundaryLinks]="true"></ngb-pagination>
                </div>
              </div>
            </td>
          </tr>

          <tr style="background: #eaf5fd; border-bottom: none">
            <!-- <th style="display: none">Company id</th> -->
            <th nowrap><span>
                Created Date
                <img class="img7" src="../../../../assets/img/sort-data.svg" style="cursor: pointer"
                  (click)="sortOrder('Name')" />
              </span></th>
            <th nowrap><span>
                Updated Date
                <img class="img7" src="../../../../assets/img/sort-data.svg" style="cursor: pointer"
                  (click)="sortOrder('Name')" />
              </span></th>
            <th nowrap>
              <span>
                Company Name
                <img class="img7" src="../../../../assets/img/sort-data.svg" style="cursor: pointer"
                  (click)="sortOrder('Name')" />
              </span>
            </th>
            <th nowrap>
              <span>
                Contact Number
                <img class="img7" src="../../../../assets/img/sort-data.svg" style="cursor: pointer" (click)="sortOrder('Name')" />
              </span>
            </th>
            <th class="vm" nowrap>
              <span>
                Email Id's
                <img class="img7"  src="../../../../assets/img/sort-data.svg" style="cursor: pointer" (click)="sortOrder('Name')" />
              </span>
            </th>
            <th nowrap>
              <span>
                Projects
                <img class="img7" src="../../../../assets/img/sort-data.svg" style="cursor: pointer"
                  (click)="sortOrder('Name')" />
              </span>
            </th>

            <th nowrap>
              <span>
                No. of Users
                <img class="img7" src="../../../../assets/img/sort-data.svg" style="cursor: pointer"
                  (click)="sortOrder('Name')" />
              </span>
            </th>
            <th nowrap>
              <span>
                Voip
                <img class="img7" src="../../../../assets/img/sort-data.svg" style="cursor: pointer"
                  (click)="sortOrder('Name')" />
              </span>
            </th>
            <th nowrap>
              <span>
                Subscription Start Date
                <img class="img7" src="../../../../assets/img/sort-data.svg" style="cursor: pointer"
                  (click)="sortOrder('Name')" />
              </span>
            </th>
            <th nowrap>
              <span>
                Subscription End Date
                <img class="img7" src="../../../../assets/img/sort-data.svg" style="cursor: pointer"
                  (click)="sortOrder('Name')" />
              </span>
            </th>
            <th nowrap>
              <span>
                Document Status
                <img class="img7" src="../../../../assets/img/sort-data.svg" style="cursor: pointer"
                  (click)="sortOrder('Name')" />
              </span>
            </th>
            <!-- <th nowrap>
              <span>
                Company Status
                <img
                  class="img7"
                  src="../../../../assets/img/sort-data.svg"
                  style="cursor: pointer"
                  (click)="sortOrder('Name')"
                />
              </span>
            </th> -->
            <th nowrap>
              Actions
            </th>
            <!-- <th>
              <span>
                Twilio Number
                <img
                  class="img7"
                  src="../../../../assets/img/sort-data.svg"
                  style="cursor: pointer"
                  (click)="sortOrder('TwilioNumber')"
                />
              </span>
            </th> -->

          </tr>
        </thead>
        <tbody>
          <tr *ngFor="
              let item of totalCompanies
                | filter: search
                | slice: (page - 1) * pageSize:page * pageSize
                | sort: orderFeild:orderString;
              let id = index
            ">
            <!-- <td
              class="id-col-mod"
              style="cursor: pointer; display: none; vertical-align: middle"
              (click)="onCompanySelect(item['id'], content)"
            >
              {{ "company" + item["id"] }}
            </td> -->
            <!-- class="id-col-mod" -->
            <td style="vertical-align: middle" nowrap>
              {{ item["createdAt"] | date: "dd/MM/yy, hh:mm a" }}
            </td>
            <td style="vertical-align: middle" nowrap>
              {{ item["updatedAt"] | date: "dd/MM/yy, hh:mm a" }}
            </td>
            <td class="vm d-flex align-items-center">
              <div class="profile-ctr mr-2">
                <div class="p-img">
                  <img src="{{item.companylogowithoutname?item.companylogowithoutname:item.companyLogo}}"
                    style="width: 100%;">
                </div>
                <div [className]="item['active']?'status-dot green':'status-dot red'"
                  [ngbTooltip]="item['active']?'Active':'Inactive'" placement="right" tooltipClass="my-custom-class">
                </div>
              </div>
              <div style="white-space:nowrap">
                {{ item["companyName"] }}
              </div>
            </td>

            <!-- <td style="vertical-align: middle">{{ item["TwilioNumber"] }}</td> -->
            <!-- <td>
							{{ item['ActiveStatus'] ? 'active' : 'inactive' }}
              <div
                [ngClass]="{
                  'status-open': item['ActiveStatus'],
                  'status-hold': !item['ActiveStatus']
                }"
              >
                {{ item["ActiveStatus"] ? "Active" : "In Active" }}
              </div>
						</td> -->
            <td class="vm">+{{ item["primaryContCountryCode"] +' '+ item["primaryContact"] }}</td>
            <td class="vm">
              {{item["primaryEmail"]}}
            </td>
            <td class="vm" *ngIf="item.projectCount>'0'">
              <a [routerLink]="['/projects', item['companyId']]">{{item["projectCount"]}}</a>
            </td>
            <td class="vm" *ngIf="!item.projectCount">
              0
            </td>
            <td class="vm">
              {{item["totalUsers"]}}
            </td>
            <td class="vm">
              <div class="d-flex" *ngIf="item.voipName=='twilio' || item.voipName=='Twilio'">
                <div class="img14 mr-2"><img src="../../../assets/img/twillo-icon.svg" /></div>
              </div>
              <div class="d-flex" *ngIf="item.voipName=='other'">
                <div class="img14 mr-2"><img src="../../../assets/img/other-voip.svg" /></div>
              </div>
              <div class="d-flex" *ngIf="!item.voipName">
                --
              </div>
            </td>

            <!-- <td nowrap><a [routerLink]="['/projects', item['companyId']]"
              class="btn btn-primary btn-sm"
              >Manage Projects</a
            > 
            </td> -->
            <td class="vm">
              {{item["subscriptionStart"] | date: "medium"}}
            </td>
            <td class="vm">
              {{item["subscriptionEnd"] | date: "medium"}}
            </td>
            <td class="vm">
              {{getDocumentRecord(item)}}
            </td>
            <!-- <td>
              {{item["active"]?'Active':'Inactive'}}
            </td> -->
            <td class="vm" style="white-space: nowrap;">
              <mat-sidenav-container style="display: inline !important" ngbTooltip="Overview" placement="top"
                class="mr-2" tooltipClass="my-custom-class">
                <mat-sidenav-content style="display: inline !important">
                  <div style="display: inline !important; cursor: pointer" (click)="sideNav.toggle()">
                    <img src="../../../../assets/img/view2.svg" class="img13" />
                  </div>
                </mat-sidenav-content>
              </mat-sidenav-container>

              <div ngbTooltip="Call" placement="top" tooltipClass="my-custom-class" class="mr-2"
                style="display: inline; cursor: pointer" data-toggle="modal" data-target="#callingscreen2">
                <img src="../../../../assets/img/call.svg" class="img13" />
              </div>

              <div ngbTooltip="Menu" placement="top" tooltipClass="my-custom-class" class="mr-2"
                style="display: inline; cursor: pointer" [matMenuTriggerFor]="menu">
                <img src="../../../../assets/img/vdot-menu.svg" class="img13" style="height:14px;" />
              </div>
              <mat-menu #menu="matMenu">
                <button mat-menu-item>
                  <img src="../../../../assets/img/edit.svg" class="img13" />
                  <span><a [routerLink]="['/editCompany', item['companyId']]">Edit Company</a></span>
                </button>
                <button mat-menu-item (click)="deleteCompany(item['companyId'])">
                  <img src="../../../../assets/img/delete.svg" class="img13" />
                  <span><a>Delete Company</a></span>
                </button>
                <button mat-menu-item>
                  <img src="../../../../assets/img/folder.svg" class="img13" />
                  <span><a [routerLink]="['/projects', item['companyId']]">Manage Projects</a> </span>
                </button>
              </mat-menu>


              <!-- <button tooltipClass="my-custom-class" mat-icon-button [matMenuTriggerFor]="menu" aria-label="menu" ngbTooltip="Menu" class="mr-2">
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #menu="matMenu">
                <button mat-menu-item>
                  <mat-icon>edit</mat-icon>
                  <span > <a [routerLink]="['/editCompany', item['companyId']]"
                    >Edit Company</a></span>
                </button>
                <button mat-menu-item>
                  <mat-icon>delete</mat-icon>
                  <span>Delete Company</span>
                </button>
                <button mat-menu-item>
                  <mat-icon>folder</mat-icon>
                  <span><a [routerLink]="['/projects', item['companyId']]"
                    >Manage Projects</a
                  > </span>
                </button>
              </mat-menu> -->
            </td>

            <!-- <td>{{ item['updatedAt'] | date: 'short' }}</td>
            <a [routerLink]="['/projects', item['id'], item['Name']]"
                class="btn btn-primary btn-sm"
                >Manage Projects</a
              > -->
          </tr>
          <tr *ngIf="isLoading ==true">
            <td colspan="13">
              <div class="norecords">
                <img src="../../../../assets/img/waiting.gif" />

                <div>Loading Data<br />Please wait while we fetch the data for you</div>
              </div>
            </td>
          </tr>
          <tr *ngIf="noRecords == true">
            <td colspan="13">
              <div class="norecords">
                <img src="../../../../assets/img/nodata.svg" />
                <div>No records found.<br />Please select a different date range.</div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- <ngb-pagination class="pagination-right"></ngb-pagination> -->
    <!-- [(page)]="page"
        [pageSize]="pageSize"
        [collectionSize]="roles.length"
        class="pagenation-right" -->
  </div>
</div>
<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      {{ isUpdate ? "Update" : "Create" }} Company
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <ngb-alert type="danger" *ngIf="isError" [dismissible]="false">{{
      errorMessage
      }}</ngb-alert>
    <form (ngSubmit)="submit(f)" #f="ngForm">
      <div class="p-3">
        <div class="row mb-2">
          <label for="name" class="form-label mt-2">Company Name</label>
          <input type="text" class="form-control" placeholder="Please enter name of role .." name="name"
            [(ngModel)]="companyInfo.name" required #userName="ngModel" (ngModelChange)="forwardMail($event)" max="10"
            [ngClass]="{
            'is-invalid': !userName.valid && userName.touched,
            'is-valid': userName.valid && userName.touched
          }" />
          <!-- <div
							class="valid-feedback"
							*ngIf="userName.valid && userName.touched"
						>
							looks good!
						</div> -->
          <div class="invalid-feedback" *ngIf="!userName.valid && userName.touched">
            Please Enter Valid Name
          </div>
        </div>

        <div class="row mb-2">
          <label for="description" class="form-label">Support Mail</label>
          <input type="mail" class="form-control" placeholder="Please Enter Support Mail" id="description"
            name="supportMail" [(ngModel)]="companyInfo.supportMail" required #desc="ngModel" email [ngClass]="{
            'is-invalid': !desc.valid && desc.touched,
            'is-valid': desc.valid && desc.touched
          }" />
          <!-- <div class="valid-feedback" *ngIf="desc.valid && desc.touched">
							look good!
						</div> -->
          <div class="invalid-feedback" *ngIf="!desc.valid && desc.touched">
            Please Enter Valid Support Mail
          </div>
        </div>
        <!-- <div class="row" *ngIf="isUpdate">
        <div class="p-0" [ngClass]="{ col: !isUpdate, 'col-8': isUpdate }">
          <label for="twilioNumber" class="form-label"
            >PhoneNumber(Twilio Number)</label
          >
          <div class="input-group">
            <div class="input-group-prepend" *ngIf="!isUpdate">
              <button
                type="button"
                class="btn btn-default dropdown-toggle"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                +<span class="country-code">{{ selectedCountryCode }}</span>
              </button>

              <ul class="dropdown-menu" style="height: 10rem; overflow: auto">
                <li
                  *ngFor="let country of countries"
                  class="my-1"
                  style="cursor: pointer"
                >
                  <span href="#" (click)="selectCountry(country)">
                    <div class="flag flag-{{ country.cc }} mx-1"></div>
                    <span>{{ country.name }} (+{{ country.code }})</span>
                  </span>
                </li>
              </ul>
            </div>
            <input
              type="tel"
              class="form-control"
              name="twilioNumber"
              ngModel
              [(ngModel)]="companyInfo.twilioNumber"
              required
              pattern="[- +()0-9]+"
              #twilioPhoneNumber="ngModel"
              placeholder="please enter twilio phone number"
              [ngClass]="{
                'is-invalid':
                  !twilioPhoneNumber.valid && twilioPhoneNumber.touched,
                'is-valid': twilioPhoneNumber.valid && twilioPhoneNumber.touched
              }"
            />
            <div
              class="invalid-feedback"
              *ngIf="!twilioPhoneNumber.valid && twilioPhoneNumber.touched"
            >
              please enter a valid phoneNumber
            </div>
          </div>
        </div>
        <div class="col-2 d-flex align-items-center" *ngIf="isUpdate">
          <mat-checkbox ngModel name="updateIvr" style="margin-top: 1.5rem"
            >Update IVR</mat-checkbox
          >
        </div>
      </div> -->

        <div class="row mb-2" *ngIf="isUpdate">
          <div class="col p-0">
            <label for="description" class="form-label">Active Status</label>
            <select required class="form-control" name="ActiveStatus" [(ngModel)]="companyInfo['ActiveStatus']"
              #activeStatus="ngModel">
              <option value="true">Active</option>
              <option value="false">In Active</option>
            </select>
            <div class="invalid-feedback" *ngIf="!activeStatus.valid && activeStatus.touched">
              please select value
            </div>
          </div>
        </div>

        <div class="row mb-2">
          <label for="description" class="form-label">Forward Mail:</label>
          <input type="mail" class="form-control" id="description" name="forwardMail"
            [(ngModel)]="companyInfo.forwardMail" #forward="ngModel" [disabled]="true" email [ngClass]="{
            'is-invalid': !forward.valid && forward.touched,
            'is-valid': forward.valid && forward.touched
          }" />
          <!-- <div class="valid-feedback" *ngIf="forward.valid && desc.touched">
							look good!
						</div> -->
          <div class="invalid-feedback" *ngIf="!forward.valid && forward.touched">
            please enter valid description
          </div>
        </div>
        <!-- <div class="row" *ngIf="isUpdate">
        <label for="fromEmail">Incoming Mail Group:</label>
        <ng-multiselect-dropdown
          [(ngModel)]="group"
          [data]="groups"
          [settings]="companySettings"
          style="width: 100%; padding: 0"
          name="incomingMailGroup"
        >
        </ng-multiselect-dropdown>
      </div> -->

        <div class="modal-footer">
          <button type="submit" class="btn btn-sm btn-primary" [disabled]="!f.valid">
            {{ isUpdate ? "Update" : "Submit" }}
          </button>
        </div>
      </div>
    </form>
  </div>
</ng-template>
<div class="fixed-footer">Powered by <a href="https://avantao.com/#/" target="_blank">Avantao</a></div>