import { BehaviorSubject, Observable } from "rxjs";
import { map } from "rxjs/operators";
// import { HttpClientService } from '@angular/common/http';
import { environment } from "src/environments/environment";
import { Role } from "../create-agent/models/role.model";
import { Injectable } from "@angular/core";
import { HttpClientService } from "src/app/httpclient.service";
import { ProjectService } from "../project-management/project.service";

interface CreateRole {
  name: string;
  description: string;
  companyName: string;
  companyId: number;
  projectId: any;
}

interface UpdateCompany {
  name: string;
  id: number;
  // twilioNumber: string;
  supportMail: string;
  ActiveStatus: boolean;
  // incomingMailGroup: number;
  forwardMail: string;
}
interface CompanyCreation {
  name: string;
  twilioNumber: string;
  supportMail: string;
  forwardMail: string;
}
interface UpdateRole {
  id: number;
  name: string;
  Description: String;
  activestatus: boolean;
  companyName: string;
  companyId: number;
}

@Injectable({
  providedIn: "root",
})
export class RoleService {
  baseApiUrl2: any = environment.baseApiUrl2;
  newDashboardAPI: any = environment.newDashboardAPI;
  public selectedRole = new BehaviorSubject<any>({ id: 1 });

  constructor(
    private http: HttpClientService,
    private projectService: ProjectService
  ) { }

  fetchRoles(id) {
    return this.http.get<Role[]>(`${this.baseApiUrl2}Role/company/${id}`).pipe(
      map((roles) => {
        return roles;
      })
    );
  }

  createRole<Observable>(data: CreateRole) {
    return this.http.post(`${this.baseApiUrl2}createRole`, data);
  }

  updateRole<Observable>(data: UpdateRole) {
    return this.http.put(`${this.baseApiUrl2}Role/${data.id}`, {
      name: data.name,
      Description: data.Description,
      activestatus: data.activestatus,
      companyName: data.companyName,
      companyId: data.companyId,
    });
  }

  getRoleById(id: any) {

    return this.http.get(`${this.baseApiUrl2}Role/${id}`);
  }

  //company realted apis

  createCompany(data: CompanyCreation) {
    return this.http.post(`${this.baseApiUrl2}createCompany`, data);
  }

  getCompanies() {
    return this.http.get<any[]>(`${this.baseApiUrl2}getCompanies`);
  }

  getCompanyById(id) {
    return this.http.get(`${this.baseApiUrl2}getCompanyById/${id}`);
  }

  updateCompany(data: UpdateCompany) {
    return this.http.post(`${this.baseApiUrl2}updateCompany`, data);
  }

  getAllCompanies(companyId) {
    return this.http.get<any[]>(
      `${this.baseApiUrl2}getRoleCompanies/${companyId}`
    );
  }

  getAllCompaniesNew(data) {
    return this.http.post(`${this.newDashboardAPI}newLeadsDashboard/getCompaniesList`, data)
  }
  getCompanyByIdV2(id) {
    return this.http.get(`${this.newDashboardAPI}newLeadsDashboard/getCompanyByIdV2/${id}`);
  }

  updateMailGroup(companyId, group) {
    return this.http.post(`${this.baseApiUrl2}updateMailGroup`, {
      companyId,
      group,
    });
  }

  projectAdmin(companyId): Observable<any> {
    let admin: boolean = false;
    let projects;
    let userData = JSON.parse(localStorage.getItem("userData"));
    // 
    if (userData["companyId"] === 0) {

      admin = true;
      return this.projectService.dashboardProjects({
        admin: admin,
        companyId: companyId,
        userId: userData["companyId"],
      });
    } else {
      admin = false;
      return this.projectService.dashboardProjects({
        admin: admin,
        companyId: userData["companyId"],
        userId: userData["id"],
      });
    }
  }
  projectAdmin1(payload): Observable<any> {
    let admin: boolean = false;
    let projects;
    let userData = JSON.parse(localStorage.getItem("userData"));

    // console.log(userData, "---------->userData");
    if (userData["companyId"] === 0) {
      console.log(admin, userData);
      admin = true;
      return this.projectService.dashboardProjects1({
        admin: admin,
        companyId: payload.companyId,
        userId: userData["newAgentId"],
        newCompanyId: payload.newCompanyId
      });
    } else {
      admin = false;
      return this.projectService.dashboardProjects1({

        admin: admin,
        companyId: payload.companyId,
        userId: userData["newAgentId"],
        newCompanyId: payload.newCompanyId

      });
    }
  }

  getRoleAgents(body: { name: string; projectId: any }) {
    return this.http.post<any[]>(`${this.baseApiUrl2}getRoleAgents`, body);
  }
  addNewMenu(obj) {
    return this.http.post(`${this.baseApiUrl2}addNewMenu`, obj);
  }
}
