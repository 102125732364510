<!-- <app-loginheader></app-loginheader> -->
<div class="login_details">
	<div class="login_data_list">
		<div class="login_description">
			<div>
				<div class="login_logo">
					<a>
						<img src="../../../../assets//images/logo.png">
					</a>
				</div>
				<h1>
					Welcome to our CRM.
				</h1>
				<h1>Sign In to see latest updates.</h1>
				<p>
					Enter your details to proceed further
				</p>

				<form [formGroup]="loginForm" (ngSubmit)="onSubmit()" *ngIf="forgetpassword && !resetPassword">
					<div class="personal_details">
						<div class="form-group mb-3">
							<label for="email" class="form-label">Email</label>
							<div class="input-group">
								<input class="form-control label_input_field" placeholder="john.doe@gmail.com"
									type="email" formControlName="email" id="email">
								<span class="input-icon"> <i class="fa fa-envelope" aria-hidden="true"></i></span>
							</div>
							<div class="invalid-feedback" *ngIf="submitted && f.email.errors">
								<div *ngIf="f.email.errors.required">Email is required</div>
								<div *ngIf="f.email.errors.email">
									Email must be a valid email address
								</div>
							</div>
						</div>
						<div class="form-group mb-3">
							<label for="formGroupExampleInput2" class="form-label">Password</label>
							<div class="input_box mail_details">
								<input class="form-control label_input_field"
									[type]="fieldTextType ? 'text' : 'password'" formControlName="password"
									id="password" placeholder="Password" (ngModelChange)="passwordChange()">
								<span class="input-icon">
									<i class="fa" [ngClass]="{
									'fa-eye-slash': !fieldTextType,
									'fa-eye': fieldTextType
								  }" (click)="toggleFieldTextType()"></i>
								</span>
							</div>
							<div class="invalid-feedback" *ngIf="submitted && f.password.errors">
								<div *ngIf="f.password.errors.required">Password is required</div>
							</div>
						</div>
					</div>

					<div class="remember_password">
						<div class="form-check remember_wrp">
							<input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1">
							<label class="form-check-label" for="flexRadioDefault1">
								Remember me
							</label>
						</div>

						<div class="form-check recover_password">
							<a href="javascript:void(0);" routerLink="/forgot">
								Recover password
							</a>
						</div>
					</div>

					<div class="end_btn">
						<div class="signin_btn">
							<button type="submit" class="btn btn-blue">Sign In</button>
						</div>

						<!-- <div class="signup_btn">
							<button type="button" class="btn btn-gray">Sign Up</button>
						</div> -->
					</div>
				</form>
				<form #resetform="ngForm" (ngSubmit)="resetForm(resetform.value)"
					*ngIf="resetPassword && !forgetpassword">
					<div class="personal_details">

						<div class="form-group mb-4">
							<label for="old-password" class="form-label">Old Password</label>
							<input type="password" class="form-control mail_details label_input_field" id="old-password"
								placeholder="old password" ngModel name="oldPassword" />
						</div>
						<div class="form-group mb-4">
							<label for="newPassword" class="form-label">New Password</label>
							<input type="password" class="form-control mail_details label_input_field" id="newPassword"
								placeholder="new password" ngModel name="newPassword" required #newPassword="ngModel" minlength="8"
								pattern="(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{8,}" />
								<div class="invalid-feedback" *ngIf="!newPassword.valid && newPassword.touched">
									<div *ngIf="newPassword.errors.required">
										Please Enter Valid Password
									</div>
									<div *ngIf="newPassword.errors.minlength">
										Please Enter Minimum 8 Characters
									</div>
									<div *ngIf="newPassword.errors.pattern">
										Password Must Contain UpperCase,Numbers,Alphabets and Special
										Characters
									</div>
								</div>
						</div>
	
						<div class="form-group mb-4">
							<label for="confirm-password" class="form-label">Confirm Password</label>
							<input type="password" class="form-control mail_details label_input_field" id="email" placeholder="confirm password"
								name="confirmPassword" ngModel required minlength="8"
								pattern="(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{8,}"
								#confirmPassword="ngModel" [ngClass]="{
								'is-invalid': !confirmPassword.valid && confirmPassword.touched,
								'is-valid': confirmPassword.valid && confirmPassword.touched
							}" />
							<div class="invalid-feedback" *ngIf="!confirmPassword.valid && confirmPassword.touched">
								<div *ngIf="confirmPassword.errors.required">
									Please Enter Valid Password
								</div>
								<div *ngIf="confirmPassword.errors.minlength">
									Please Enter Minimum 8 Characters
								</div>
								<div *ngIf="confirmPassword.errors.pattern">
									Password Must Contain UpperCase,Numbers,Alphabets and Special
									Characters
								</div>
							</div>
						</div>
	
						<div class="end_btn">
							<div class="signin_btn">
								<button type="submit" class="btn btn-blue">Submit</button>
							</div>
	
							<div class="signup_btn">
								<button type="button" class="btn btn-gray" (click)="cancel()">Cancel</button>
							</div>
						</div>
					</div>
				</form>
				<ngb-alert type="danger" *ngIf="error" [dismissible]="false" class="my-2">{{
					error
					}}</ngb-alert>
				<div class="social_media">
					<a href="https://twitter.com/AccessQue/" target="_blank">
						<i class="fa-brands fa-twitter"></i>
					</a>

					<a href="https://www.facebook.com/profile.php?id=100064747820124" target="_blank">
						<i class="fa-brands fa-linkedin"></i>
					</a>

					<a href="https://www.linkedin.com/showcase/77110702/admin/feed/posts/" target="_blank">
						<i class="fa-brands fa-facebook-f"></i>
					</a>

					<a href="https://www.instagram.com/accessque_crm/" target="_blank">
						<i class="fa-brands fa-instagram"></i>
					</a>
				</div>
			</div>
		</div>
	</div>

	<div class="login_image"></div>
</div>