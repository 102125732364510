<div class="row">
  <div class="home-banner-bg2">
    <div class="banenr-text2">B2B Lead generation</div>
  </div>
</div>
<div class="container-md m-auto">
  <!--Section AccessQue-->
  <div class="row mt-5">
    <div class="col-md-5 col-sm-5 col-lg-5">
      <img src="../../../assets/images/b2b-ig1.png" style="width:400px;border-radius:100px">
      <!-- <img
        src="../../../assets/images/b2b-leads-graphic.png"
        class="border-tb-radius"
        style="width: 100%"
      /> -->
    </div>
    <div class="col-md-7 col-sm-7 col-lg-7">
      <p class="highlightFont2 w-80 lh130 pt-3 px-3 pb-3">
        <span class="hightlight-text-2">Social media Lead generations</span>, reduces manual intervention for leads
        routing Our CRM Connects to social medial platforms directly and Capture
        leads easily and store their contact information categorically (segment,
        source, or location wise).
      </p>
      <p class="lightFont w-80 lh130 px-3">
        The information can also be categorized as per account managers or
        executives for responsible account management.
      </p>
      <p class="lightFont w-80 lh130 px-3">
        Hence reduces manual intervention of leads routing and clod calling
        tracking. These campaigns can be Facebook, Linked in, Instagram and many
        more.
      </p>
    </div>
    <!-- </div>
  <div class="row">
    <div class="col-md-6 col-sm-6 col-lg-6">
       <img
        src="../../../assets/images/b2b-leads-graphic.png"
      /> 
    </div>
  </div> -->
    <div class="row mt-5 mb-5">
      <div class="col-md-12 text-center">
        <button class="btn btn-primary" routerLink="/bookademo/b2b">
          Book a Demo
        </button>
      </div>
    </div>
  </div>