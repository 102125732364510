import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LandingpageComponent } from '../landingpage.component';
import { AiAnalyticsComponent } from './ai-analytics/ai-analytics.component';
import { HomepageComponent } from './homepage/homepage.component';
import { B2bComponent } from '../b2b/b2b.component';
import { CrmComponent } from './crm/crm.component';
import { AivoicebotComponent } from './aivoicebot/aivoicebot.component';
import { SoarComponent } from './soar/soar.component';
import { ContactComponent } from './contact/contact.component';
import { LipsyncComponent } from './lipsync/lipsync.component';

const routes: Routes = [
  {
    path: '',
    component: HomepageComponent,
    children: [
      {
        path: '',
        component: LandingpageComponent
      },
      {
        path: 'aicalls',
        component: AiAnalyticsComponent
      },
      {
        path: 'b2b',
        component: B2bComponent
      },
      {
        path: 'crm',
        component: CrmComponent
      },
      {
        path: 'aivoicebot',
        component: AivoicebotComponent
      },
      {
        path: 'soar',
        component: SoarComponent
      },
      {
        path: 'contact',
        component: ContactComponent
      },
      {
        path: 'lipsync',
        component: LipsyncComponent
      }
    ]
  },

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class NavbarsRoutingModule { }
