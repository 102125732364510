<div class="dashboard-right-header-wrapper">
  <div class="dashboard-right-header-nav-container">
    <div class="section-heading-container dashboard-header-padding">
      <a href="javascript:void(0);" (click)="toggle()">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 32 32">
          <g id="Group_745" data-name="Group 745" transform="translate(-19.078)">
            <rect id="Rectangle_883" class="csvg" data-name="Rectangle 883" width="18" height="2"
              transform="translate(23.078 6)" />
            <rect id="Rectangle_884" class="csvg" data-name="Rectangle 884" width="18" height="2"
              transform="translate(23.078 12)" />
            <rect id="Rectangle_885" class="csvg" data-name="Rectangle 885" width="18" height="2"
              transform="translate(23.078 18)" />
            <rect id="Rectangle_886" class="csvg" data-name="Rectangle 886" width="18" height="2"
              transform="translate(23.078 24)" />
            <rect id="Rectangle_887" class="csvg" data-name="Rectangle 887" width="2" height="2"
              transform="translate(45.078 6)" />
            <rect id="Rectangle_888" class="csvg" data-name="Rectangle 888" width="2" height="2"
              transform="translate(45.078 12)" />
            <rect id="Rectangle_889" class="csvg" data-name="Rectangle 889" width="2" height="2"
              transform="translate(45.078 18)" />
            <rect id="Rectangle_890" class="csvg" data-name="Rectangle 890" width="2" height="2"
              transform="translate(45.078 24)" />
            <rect id="_Transparent_Rectangle_" data-name=" Transparent Rectangle " width="32" height="32"
              transform="translate(19.078)" fill="none" />
          </g>
        </svg>
      </a>
      <p class="section-heading ml-3">{{ pageName }}</p>
    </div>

    <div class="d-flex align-items-center">
      <div class="mr-4">
        <div class="dropdown dialer-dropdown" *ngIf="userData.NewCompanyId && companyInfo.voipName">
          <button type="button" class="btn dialer-btn" data-toggle="dropdown">
            <div class="d-flex align-items-center justify-content-center">
              <div class="status-circle {{ currentStatus }}"></div>
              <div class="dialer-text ml-2" style="min-width: auto;">
                {{ currentStatus === "Idle" ? "Online" : currentStatus }}
              </div>
              <div>
                <img src="../../../assets/img/down-arrow-w.svg" class="img12" />
              </div>
            </div>
          </button>
          <div class="dropdown-menu dialer-dropdown-menu">
            <ng-container *ngIf="activities.length > 0">
              <ng-container *ngFor="let status of activities">
                <a class="dropdown-item dialer-dropdown-item" *ngIf="status.friendlyName !== 'Reserved'"
                  (click)="toggleIsOnQueue(status.sid)" href="javascript:void(0);">
                  <div class="status-circle {{ status?.friendlyName }}"></div>
                  <p class="dialer-text2">
                    {{
                    status?.friendlyName === "Idle"
                    ? "Online"
                    : status?.friendlyName
                    }}
                  </p>
                  <img src="../../../assets/img/dialer/tick-green.svg" *ngIf="currentStatus === status.friendlyName"
                    class="right-icon" />
                </a>
              </ng-container>
            </ng-container>
          </div>
        </div>
        <div class="dropdown dialer-dropdown" *ngIf="!userData.NewCompanyId">
          <button type="button" class="btn dialer-btn" data-toggle="dropdown">
            <div class="d-flex align-items-center justify-content-center">
              <div class="status-circle {{ currentStatus }}"></div>
              <div class="dialer-text ml-2" style="min-width: auto;">
                {{ currentStatus === "Idle" ? "Online" : currentStatus }}
              </div>
              <div>
                <img src="../../../assets/img/down-arrow-w.svg" class="img12" />
              </div>
            </div>
          </button>
          <div class="dropdown-menu dialer-dropdown-menu">
            <ng-container *ngIf="activities.length > 0">
              <ng-container *ngFor="let status of activities">
                <a class="dropdown-item dialer-dropdown-item" *ngIf="status.friendlyName !== 'Reserved'"
                  (click)="toggleIsOnQueue(status.sid)" href="javascript:void(0);">
                  <div class="status-circle {{ status?.friendlyName }}"></div>
                  <p class="dialer-text2">
                    {{
                    status?.friendlyName === "Idle"
                    ? "Online"
                    : status?.friendlyName
                    }}
                  </p>
                  <img src="../../../assets/img/dialer/tick-green.svg" *ngIf="currentStatus === status.friendlyName"
                    class="right-icon" />
                </a>
              </ng-container>
            </ng-container>
          </div>
        </div>
      </div>

      <div class="mr-4">
        <div *ngIf="!userData.NewCompanyId">
          <a href="javascript:void(0)" routerLink="/tickets/ticket-view">
            <img src="../../../../assets/img/add-ticket.svg" class="img24" />
          </a>
        </div>
      </div>

      <div class="mr-4">
        <div *ngIf="userData.NewCompanyId && companyInfo.voipName">
          <a ngbTooltip="Calls" placement="bottom" tooltipClass="my-custom-class" class="header-nav-links"
            href="javascript:void(0);" (click)="showDailer = !showDailer">
            <img src="./assets/img/call.svg" class="img20" alt=""
              *ngIf="!(isCallingProgress || isOutgoing || isIncoming || isCallcomming)" />
            <i class="fas fa-phone" style="color: green"
              *ngIf="isCallingProgress || isOutgoing || isIncoming || isCallcomming"></i>
          </a>
        </div>
        <div *ngIf="!userData.NewCompanyId">
          <a ngbTooltip="Calls" placement="bottom" tooltipClass="my-custom-class" class="header-nav-links"
            href="javascript:void(0);" (click)="showDailer = !showDailer">
            <img src="./assets/img/call.svg" class="img20" alt=""
              *ngIf="!(isCallingProgress || isOutgoing || isIncoming || isCallcomming)" />
            <i class="fas fa-phone" style="color: green"
              *ngIf="isCallingProgress || isOutgoing || isIncoming || isCallcomming"></i>
          </a>
        </div>
      </div>

      <div class="mr-4">
        <a ngbTooltip="Notifications" placement="bottom" tooltipClass="my-custom-class" (click)="isNotify = !isNotify"
          class="header-nav-links">
          <img src="../../../../assets/img/notifications.svg" class="img20" />
          <span class="notification d-flex justify-content-center">{{
            notificationCount > 99?'99+':notificationCount
            }}</span>
        </a>
      </div>

      <div class="mr-0">

        <div ngbDropdown class="d-inline-block" style="border: 0">
          <a class="header-profile-img-containerx" style="display: flex;
          align-items: center;" ngbDropdownToggle>
            <div style="height: 30px;
          width: 30px;
          overflow: hidden;
          border-radius: 25px;"><img src="{{ profilePhoto ? profilePhoto : './assets/img/user.svg' }}" alt=""
                style="width: 30px" />
            </div>
            <span style="padding-left: 5px"><img src="../../../assets/img/down-arrow-w.svg" class="img12" /></span>
          </a>

          <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
            <a routerLink="/profile" class="dropdown-item notify-item">
              <span>
                <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                  viewBox="0 0 32 32">
                  <g>
                    <path class="gsvg"
                      d="M14.34,4c2.61,0,4.72,2.24,4.72,5s-2.11,5-4.72,5-4.72-2.24-4.72-5,2.11-5,4.72-5m0-2c-3.65,0-6.61,3.13-6.61,7s2.96,7,6.61,7,6.61-3.13,6.61-7-2.96-7-6.61-7Z" />
                    <path class="gsvg"
                      d="M23.78,30h-1.89v-5c0-2.76-2.11-5-4.72-5h-5.67c-2.61,0-4.72,2.24-4.72,5v5h-1.89v-5c0-3.87,2.96-7,6.61-7h5.67c3.65,0,6.61,3.13,6.61,7v5Z" />
                  </g>
                </svg>
                Profile</span>
            </a>

            <a (click)="openSettings()" class="dropdown-item notify-item">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 32 32">
                <g id="Group_890" data-name="Group 890" transform="translate(-726.143 -136.79)">
                  <path id="Path_998" data-name="Path 998" class="gsvg"
                    d="M753.143,153.55v-1.53l1.92-1.68a2,2,0,0,0,.38-2.55l-2.36-4a2.018,2.018,0,0,0-2.37-.9l-2.43.82a11.485,11.485,0,0,0-1.31-.75l-.51-2.52a2,2,0,0,0-2-1.61h-4.68a2,2,0,0,0-2,1.61l-.51,2.52a11.522,11.522,0,0,0-1.32.75l-2.38-.86a2.2,2.2,0,0,0-2.37.94l-2.36,4a2,2,0,0,0,.41,2.51l1.89,1.73v1.53l-1.89,1.68a2,2,0,0,0-.41,2.55l2.36,4a2.018,2.018,0,0,0,2.37.9l2.43-.82a11.309,11.309,0,0,0,1.31.75l.51,2.52a2,2,0,0,0,2,1.61h4.72a2,2,0,0,0,2-1.61l.51-2.52a11.522,11.522,0,0,0,1.32-.75l2.42.82a2.018,2.018,0,0,0,2.37-.9l2.28-4a2,2,0,0,0-.41-2.51Zm-1.79,7.24-3.43-1.16a8.862,8.862,0,0,1-2.71,1.57l-.71,3.59h-4.72l-.71-3.55a9.332,9.332,0,0,1-2.7-1.57l-3.44,1.12-2.36-4,2.72-2.4a8.893,8.893,0,0,1,0-3.13l-2.72-2.47,2.36-4,3.43,1.16a8.852,8.852,0,0,1,2.71-1.57l.71-3.59h4.72l.71,3.55a9.382,9.382,0,0,1,2.7,1.57l3.44-1.12,2.36,4-2.72,2.4a8.942,8.942,0,0,1,0,3.13l2.72,2.47Z" />
                  <path id="Path_999" class="gsvg" data-name="Path 999"
                    d="M742.143,158.79a5.94,5.94,0,1,1,.12,0Zm0-10a3.91,3.91,0,0,0-4,3.818c0,.061,0,.121,0,.182a3.909,3.909,0,0,0,3.817,4q.091,0,.183,0a3.911,3.911,0,0,0,4-3.818c0-.06,0-.121,0-.182a3.91,3.91,0,0,0-3.818-4Z" />
                  <rect id="_Transparent_Rectangle_" data-name=" Transparent Rectangle " width="32" height="32"
                    transform="translate(726.143 136.79)" fill="none" />
                </g>
              </svg>
              <span> Notification Settings</span>
            </a>

            <a href="javascript:void(0);" class="dropdown-item notify-item" (click)="logout()">
              <svg focusable="false" preserveAspectRatio="xMidYMid meet" xmlns="http://www.w3.org/2000/svg" width="16"
                height="16" viewBox="0 0 32 32" aria-hidden="true">
                <path
                  d="M6,30H18a2.0023,2.0023,0,0,0,2-2V25H18v3H6V4H18V7h2V4a2.0023,2.0023,0,0,0-2-2H6A2.0023,2.0023,0,0,0,4,4V28A2.0023,2.0023,0,0,0,6,30Z"
                  class="gsvg"></path>
                <polygon
                  points="20.586 20.586 24.172 17 10 17 10 15 24.172 15 20.586 11.414 22 10 28 16 22 22 20.586 20.586"
                  class="gsvg"></polygon>
              </svg>
              <span> Logout</span>
            </a>
          </div>
        </div>

      </div>
    </div>
    <!--.............Dailer....................  -->
    <div (click)="showDailer = false" *ngIf="showDailer" class="customBackdrop"></div>
    <div class="dialer-wrapper" *ngIf="showDailer">
      <div class="progress" *ngIf="
        (isCallingProgress || isOutgoing || isIncoming || isCallcomming) &&
        !isShownMarketing
      " (click)="changeTab()">
        <div class="progress-bar progress-bar-striped bg-info progress-bar-animated c-progress" role="progressbar"
          aria-valuenow="100" aria-valuemin="0" ariavaluemax="100">
          Call in Progress..
        </div>
      </div>
      <div class="dialer-header">
        <div class="d-flex p-3 align-items-center" *ngIf="!userData.NewCompanyId">
          <div>
            <select (ngModelChange)="projectChange($event)" [(ngModel)]="projectId" class="select-company2">
              <option value="*">Select Project</option>
              <option *ngFor="let company of projects" [value]="company['id']">
                {{ company["Name"] }}
              </option>
            </select>
          </div>
          <div class="ml-auto">
            <div class="dropdown">
              <button type="button" class="btn bottom-number pr-0 mr-0" data-toggle="dropdown" aria-haspopup="true"
                aria-expanded="false">
                {{ selectedCallerId }}
                <img src="../../../assets/img/down-arrow.svg" class="img12 ml-2" />
              </button>
              <div class="dropdown-menu dialer-dropdown-menu ml-1">
                <a style="cursor: pointer" *ngFor="let item of callerIds" class="dropdown-item dialer-dropdown-item"
                  (click)="selectCalledId(item)">{{ item }}</a>
              </div>
            </div>
          </div>
        </div>
        <!-- dailer dropdown for changing the active status -->
        <div class="dropdown-menu dialer-dropdown-menu">
          <ng-container *ngIf="activities.length > 0">
            <ng-container *ngFor="let status of activities">
              <a class="dropdown-item dialer-dropdown-item" *ngIf="status.friendlyName !== 'Reserved'"
                (click)="toggleIsOnQueue(status.sid)" href="javascript:void(0);">
                <div class="status-circle {{ status?.friendlyName }}"></div>
                <p class="dialer-text2">
                  {{
                  status?.friendlyName === "Idle"
                  ? "Online"
                  : status?.friendlyName
                  }}
                </p>
                <img src="../../../assets/img/dialer/tick-green.svg" *ngIf="currentStatus === status.friendlyName"
                  class="right-icon" />
              </a>
            </ng-container>
          </ng-container>
        </div>
        <!-- </div> -->

        <div class="dropdown dialer-dropdown-location" style="display: none">
          <button type="button" class="btn location-dialer" data-toggle="dropdown">
            <div style="display: flex; align-items: center; justify-content: center">
              <img src="../../../assets/img/dialer/globe-green.svg" />
            </div>
          </button>
          <div class="dropdown-menu location-dropdown-menu">
            <a class="dropdown-item dialer-dropdown-item" href="javascript:void(0);">
              <img src="../../../assets/img/dialer/globe-green.svg" class="status-circle glob" />
              <p class="dialer-text">Via browser</p>
              <img src="../../../assets/img/dialer/tick-green.svg" class="right-icon" />
            </a>
            <a class="dropdown-item dialer-dropdown-item disable-item" href="javascript:void(0);">
              <img src="../../../assets/img/dialer/phone-gray.svg" class="status-circle glob" />
              <p class="dialer-text disable-item">Via phone: Not configured</p>
              <!-- <img src="../../../assets/img/dialer/tick-green.svg" class="right-icon"> -->
            </a>
          </div>
        </div>
      </div>

      <!--.......code for no- incoming call screen.........  -->

      <div class="dialer-middle-screen" *ngIf="dailerScreen === 'nocall'">
        <mat-tab-group style="margin-top: 0">
          <mat-tab label="Call Logs">
            <div class="d-flex align-items-center mx-2 my-1" *ngFor="let item of callLogs">
              <div class="logo mx-2">
                <mat-icon style="font-size: 18px" *ngIf="item['CallStatus'] === 'inbound'">
                  phone_callback
                </mat-icon>
                <mat-icon style="font-size: 18px" *ngIf="item['CallStatus'] === 'outbound'">
                  phone_forwarded
                </mat-icon>
                <mat-icon style="font-size: 18px" *ngIf="item['CallStatus'] === 'missed'">call_missed</mat-icon>
              </div>
              <div class="d-flex justify-content-between"
                style="flex: 1; border-bottom: 1px solid rgba(0, 0, 0, 0.199)">
                <div>
                  <div style="font-size: 14px" class="my-1">
                    {{
                    item["DisplayName"].length < 18 ? item["DisplayName"] : item["DisplayName"].slice(0, 18) + "..." }}
                      </div>
                      <div style="font-size: 12px" class="my-1">
                        {{ item["CallStatus"] }}
                      </div>
                  </div>
                  <div class="d-flex align-items-end flex-column">
                    <div style="font-size: 10px" class="my-1 d-flex align-items-center">
                      <span>
                        {{
                        item["RecordingDuration"] !== null
                        ? item["RecordingDuration"] + " secs"
                        : "0" + " secs"
                        }}
                      </span>
                      <span (click)="agentToCall(item['CalledPerson'])" style="cursor: pointer">
                        <mat-icon style="font-size: 16px; margin-left: 10px">call</mat-icon>
                      </span>
                    </div>
                    <div style="font-size: 10px" class="my-1">
                      {{ item["createdAt"] | date: "medium" }}
                    </div>
                  </div>
                </div>
              </div>
          </mat-tab>
          <mat-tab label="Contacts" style="height: 420px">
            <app-agentscontacts></app-agentscontacts>
          </mat-tab>
        </mat-tab-group>
      </div>
      <div class="dialer-middle-screen" *ngIf="dailerScreen === 'callcomming' && !isShownMarketing">
        <div class="custom-incomingcall-widget">
          <div class="custom-user-img">
            <img src="../../../assets/img/dialer/calling/user.jpg" alt="" />
          </div>
          <h4 class="custom-user-name m-t-10 m-b-0 text-ellipsis" *ngIf="!isReservation">
            {{ phoneNumber }}
          </h4>
          <h4 class="custom-user-name m-t-10 m-b-0 text-ellipsis" *ngIf="isReservation">
            {{ reservation?.task?.attributes?.from }}
          </h4>
          <h6 class="custom-user-phone m-t-10 m-b-0 text-ellipsis" *ngIf="isReservation">
            {{ reservation?.task?.attributes?.from_country }}
          </h6>
          <div class="custom-small text-muted">calling ??</div>
          <div class="custom-incoming-btns" *ngIf="isReservation">
            <button type="button" class="btn orange-btn m-r-10" (click)="accept(reservation)">
              Answer
            </button>
            <button type="button" class="btn btn-danger" (click)="complete()">
              Complete
            </button>
          </div>
          <div class="custom-incoming-btns" *ngIf="!isReservation">
            <button type="button" class="btn orange-btn m-r-10" (click)="acceptAgentCall()">
              Answer
            </button>
            <button type="button" class="btn btn-danger" (click)="hangUp()">
              Complete
            </button>
          </div>
        </div>
      </div>
      <div class="dialer-middle-screen" *ngIf="dailerScreen === 'outgoing' && !isShownMarketing">
        <div class="custom-incomingcall-widget">
          <div class="custom-user-img" style="margin-top: 50px">
            <img src="../../../assets/img/dialer/calling/user.jpg" alt="" />
          </div>
          <h4 class="custom-user-name m-t-10 m-b-0 text-ellipsis">
            {{
            (phoneNumber?.replace("client:", "")
            | agentname: totalAgents
            | titlecase) || agentCall?.replace("client:", "")
            }}
          </h4>
          <div class="custom-small text-muted">calling ...</div>
          <div class="custom-incoming-btns">
            <button type="button" class="btn btn-primary btn-lg mt-4" style="font-size: 14px"
              (click)="hangUpOutgoingCall()">
              Hang Up
            </button>
          </div>
        </div>
      </div>
      <!--.......code for no- incoming call screen.........  -->

      <div class="dialer-keypad" *ngIf="dailerScreen === 'dailer'">
        <div class="input-area">
          <form class="form-inline dialer-form">
            <div class="d-flex justify-content-center align-items-center" style="width: 100%">
              <button type="button" class="btn btn-default dropdown-toggle" data-toggle="dropdown" aria-haspopup="true"
                aria-expanded="false">
                +<span class="country-code">{{ selectedCountryCode }}</span>&nbsp;
                <i class="fa fa-caret-down"></i>
              </button>

              <ul class="dropdown-menu" style="height: 50%;width:90%;overflow: auto;">
                <div>
                  <input type="search" (input)="onInput($event)" class="form-control search-all"
                    style="height: auto; padding: 4px 12px;width: 93%;margin: 0 9px;" placeholder="Search "
                    autocomplete="off" />
                  <li *ngFor="let country of countries">
                    <span href="#" (click)="selectCountry(country)">
                      <!-- <div class="flag flag-{{ country.flag || country.flags.svg || country.flags.png }}"></div> -->
                      <img src="{{ country.flag || country.flags.svg || country.flags.png }}" alt=""
                        style="height: 15px;width: 20px;">
                      <span style="padding-left: 8px">{{ country?.name }} (+{{ country?.callingCodes[0] }})</span>
                    </span>
                  </li>
                </div>

              </ul>
              <input type="text" name="phoneNUmber" [(ngModel)]="phoneNumber" class="form-control dialer-form-control"
                style="font-size: 22px" placeholder="Phone number, Name" />
              <div class="close img16" style="width: 12px" (click)="phoneNumber = ''">
                <img src="../../../assets/img/close.svg" />
              </div>
            </div>
          </form>
        </div>
        <div class="custom-keyboard">
          <div class="row nomar">
            <div class="digit" id="one" (click)="addDigit('1')">1</div>
            <div class="digit" id="two" (click)="addDigit('2')">
              2
              <div class="sub">ABC</div>
            </div>
            <div class="digit nomar" id="three" (click)="addDigit('3')">
              3
              <div class="sub">DEF</div>
            </div>
          </div>
          <div class="row nomar">
            <div class="digit" id="four" (click)="addDigit('4')">
              4
              <div class="sub">GHI</div>
            </div>
            <div class="digit" id="five" (click)="addDigit('5')">
              5
              <div class="sub">JKL</div>
            </div>
            <div class="digit nomar" (click)="addDigit('6')">
              6
              <div class="sub">MNO</div>
            </div>
          </div>
          <div class="row nomar">
            <div class="digit" (click)="addDigit('7')">
              7
              <div class="sub">PQRS</div>
            </div>
            <div class="digit" (click)="addDigit('8')">
              8
              <div class="sub">TUV</div>
            </div>
            <div class="digit nomar" (click)="addDigit('9')">
              9
              <div class="sub">WXYZ</div>
            </div>
          </div>
          <div class="row nomar">
            <div class="digit" (click)="addDigit('*')">*</div>
            <div class="digit" (click)="addDigit('0')">0</div>
            <div class="digit nomar" (click)="addDigit('#')">#</div>
          </div>
        </div>
        <div class="custom-dailer-callnow">
          <div class="row nomar">
            <button id="call" class="dial-btn" (click)="callPhoneNumber()">
              Call now
            </button>
          </div>
        </div>
      </div>

      <!--.......code for keypad screen.........  -->

      <div class="dialer-keypad" *ngIf="dailerScreen === 'oncall' && !isShownMarketing">
        <div class="dialer-keypad">
          <div class="custom-call-info" style="display: flex; justify-content: center">
            <div class="row">
              <p class="custom-usernumber-dialer" *ngIf="isReservation" style="text-align: center">
                {{ task?.attributes?.from }}
              </p>
              <p class="custom-usernumber-dialer" *ngIf="!isReservation" style="text-align: center">
                {{ phoneNumber }}
              </p>
              <p class="custom-call-timer" style="text-align: center">
                <countup-timer [countUpTimerConfig]="testConfig"></countup-timer>
              </p>
            </div>
          </div>
          <div class="custom-keyboard">
            <div class="row nomar">
              <div class="digit" id="one" (click)="addDigit('1')">1</div>
              <div class="digit" id="two" (click)="addDigit('2')">
                2
                <div class="sub">ABC</div>
              </div>
              <div class="digit nomar" id="three" (click)="addDigit('3')">
                3
                <div class="sub">DEF</div>
              </div>
            </div>
            <div class="row nomar">
              <div class="digit" id="four" (click)="addDigit('4')">
                4
                <div class="sub">GHI</div>
              </div>
              <div class="digit" id="five" (click)="addDigit('5')">
                5
                <div class="sub">JKL</div>
              </div>
              <div class="digit nomar" (click)="addDigit('6')">
                6
                <div class="sub">MNO</div>
              </div>
            </div>
            <div class="row nomar">
              <div class="digit" (click)="addDigit('7')">
                7
                <div class="sub">PQRS</div>
              </div>
              <div class="digit" (click)="addDigit('8')">
                8
                <div class="sub">TUV</div>
              </div>
              <div class="digit nomar" (click)="addDigit('9')">
                9
                <div class="sub">WXYZ</div>
              </div>
            </div>
            <div class="row nomar">
              <div class="digit" (click)="addDigit('*')">*</div>
              <div class="digit" (click)="addDigit('0')">0</div>
              <div class="digit nomar" (click)="addDigit('#')">#</div>
            </div>
          </div>
          <div class="custom-dailer-other-option">
            <div class="row" style="justify-content: center">
              <button class="custom-pause" [ngClass]="hold ? 'active' : ''" (click)="toggleHold()"
                type="button"></button>
              <button class="custom-mute" [ngClass]="mute ? 'active' : ''" (click)="toggleMute()"
                type="button"></button>
              <button class="custom-transfer" [ngClass]="!isCollapsed ? 'active' : ''"
                (click)="isCollapsed = !isCollapsed" type="button" [attr.aria-expanded]="!isCollapsed"
                aria-controls="custom-transfertcall-popup"></button>

              <div id="custom-transfertcall-popup" [ngbCollapse]="isCollapsed">
                <div id="transfercalldetails">
                  <div class="transfer-call-screen">
                    <div class="custom-treansfercall-widget">
                      <div class="custom-treansfercall form-group">
                        <div style="display: flex; justify-content: space-between">
                          <div>
                            <label for="custom-transfercall-label">Transfer Call List</label>
                          </div>
                          <div>
                            <i class="fa fa-times" aria-hidden="true" (click)="isCollapsed = !isCollapsed"></i>
                          </div>
                        </div>
                        <select class="form-control" style="font-size: 13px" name="transfer" [(ngModel)]="transfer.to"
                          (change)="toggleTransferPanel()" id="custom-transfercall-numberlist"
                          placeholder="Select Queue">
                          <option value="null" selected disabled>
                            <p class="custom-tranfert-number">Select Queue</p>
                          </option>
                          <ng-container *ngFor="let queue of taskQueue; let i = index">
                            <option [value]="queue.TwilioSkillName">
                              <p class="custom-tranfert-number">
                                {{ queue?.Name }}
                              </p>
                            </option>
                          </ng-container>
                        </select>
                      </div>
                      <div class="custom-transfercall-btns2">
                        <button class="transfer-call mt-1 mb-1" (click)="getConference()"
                          [disabled]="transfer.to === null">
                          Transfer Call
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="custom-dailer-callnow">
            <div class="row">
              <div style="margin: 0 40px; width: 100%">
                <!--<button id="call" class="dial-btn" (click)="callPhoneNumber()"> Call now</button>-->
                <button type="button" class="btn btn-primary btn-block" (click)="hangUp()">
                  Hang Up
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--.......code for keypad screen.........  -->

      <div class="dialer-bottom">
        <div class="call-down" (click)="dailerScreen = 'nocall'" style="cursor: pointer">
          <img src="../../../assets/img/dial-contact-icon.png" />
        </div>

        <div class="keypad-icon" (click)="dailerScreen = 'dailer'">
          <img src="../../../assets/img/dial-pad-icon.png" />
        </div>
      </div>
    </div>
  </div>
  <div class="close-chatwindow" (click)="toggleView()" *ngIf="isChat || isNotify"></div>

  <div class="button-container">
    <button (click)="isChat = !isChat" class="floating-action-btn" style="position: relative"
      aria-label="Example icon button with a filter list icon">
      <div *ngIf="unReadMessages !== 0" class="badge">{{ unReadMessages }}</div>

      <img src="assets/logos/chat.png" alt="chat" style="width: 30px; height: 30px; padding: 0"
        (click)="unReadMessages = 0" />
    </button>
  </div>

  <div class="show-calling" *ngIf="isShownMarketing">
    <button (click)="open(content)" class="floating-action-btn" style="position: relative"
      aria-label="Example icon button with a filter list icon">
      <i class="fas fa-phone" style="
        color: white;
        width: 30px;
        height: 30px;
        padding: 0;
        font-size: 27px;
      "></i>
    </button>
  </div>

  <div class="chat-bubble" *ngIf="isloading" [ngStyle]="{ display: isChat ? 'block' : 'none' }">
    <app-chatview [workerJS]="workerJS" [worker]="configuration" [taskQueues]="taskQueue" [isChat]="isChat">
    </app-chatview>
  </div>
  <div [ngStyle]="{ display: isNotify ? 'block' : 'none' }">
    <app-notification></app-notification>
  </div>
  <ng-template #content let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Calling Screen</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body" style="height: 700px; overflow-y: scroll">
      <div class="row">
        <div class="col-md-3">
          <div class="ndialer">
            <!--.......code for no- incoming call screen.........  -->
            <div class="sm-text" style="
              text-align: center;
               
              font-weight: 100;
              background: #1f344c;
              width: 135px;
              margin: 0 auto;
              padding: 5px;
              border-radius: 15px;
              letter-spacing: 1px;
            ">
              Current Caller
            </div>

            <div class="dialer-middle-screen" *ngIf="dialerScreen1 === 'outgoing1'">
              <div class="custom-incomingcall-widget">
                <h4 class="mt-3 text-ellipsis">
                  {{ marketingId?.name }}
                </h4>
                <div class="mb-2 mt-1 lg-text" style="font-weight: 100" *ngIf="marketingId.city">
                  {{ marketingId?.city }}
                </div>
                <div class="my-1 lg-text">
                  {{ phoneNumber || agentCall }}
                </div>
                <div class="custom-user-img my-3 mt-5">
                  <img class="img80" src="./assets/img/user-w.svg" alt="" />
                </div>
                <h5>Calling ...</h5>
                <div class="custom-incoming-btns my-3 mt-4">
                  <button type="button" class="btn-round m-r-10" (click)="hangUpOutgoingCall()">
                    <img class="img-38" src="./assets/img/hangup.svg" alt="" />
                  </button>
                </div>
              </div>
            </div>
            <!--.......code for no- incoming call screen.........  -->

            <div *ngIf="dialerScreen1 === 'dialer'">
              <div class="end-call">
                <span style="font-size: 14px; font-weight: 400">Call Ended</span><br />
                {{ marketingId?.phoneNumber }}
              </div>
            </div>

            <!--.......code for keypaid screen.........  -->

            <div class="dialer-keypad" *ngIf="dialerScreen1 === 'oncall'">
              <div class="custom-incomingcall-widget">
                <h4 class="mt-3 text-ellipsis">
                  {{ marketingId?.name }}
                </h4>
                <div class="mb-2 mt-1 lg-text" style="font-weight: 100" *ngIf="marketingId.city">
                  {{ marketingId?.city }}
                </div>
                <div class="my-1 lg-text">
                  {{ phoneNumber || agentCall }}
                </div>
                <div class="custom-user-img my-3 mt-5">
                  <img class="img80" src="./assets/img/user-w.svg" alt="" />
                </div>
                <h5 *ngIf="callStatus != 'inProgress'">{{ callStatus }}</h5>
              <p *ngIf="callStatus == 'inProgress'" class="custom-call-timer" style="text-align: center">
                <countup-timer [countUpTimerConfig]="testConfig"></countup-timer>
              </p>
                <div class="custom-incoming-btns my-3 mt-4">
                  <button type="button" class="btn-round m-r-10" (click)="hangUpOutgoingCall()">
                    <img class="img-38" src="./assets/img/hangup.svg" alt="" />
                  </button>
                </div>
              </div>
            </div>
            <!--.......code for keypaid screen.........  -->
          </div>
          <div class="mt-2 p-3 ndialer2" *ngIf="marketingId.nextCaller">
            <div class="mt-4" style="float: right">
              <img class="img50" src="./assets/img/user-w.svg" alt="" />
            </div>
            <div class="sm-text" style="
               
              font-weight: 100;
              background: #f75c2b;
              width: 110px;
              padding: 5px;
              border-radius: 15px;
              letter-spacing: 1px;
              text-align: center;
            ">
              Next Caller
            </div>
            <div class="lg-text pt-2 pb-1">
              {{ marketingId?.nextCaller?.FirstName }}
            </div>
            <div class="md-text pb-2">{{ marketingId?.nextCaller?.City }}</div>
            <div class="md-text">
              +{{
              marketingId?.nextCaller?.CountryCode +
              marketingId?.nextCaller?.PhoneNumber
              }}
            </div>
          </div>

          <div class="mt-2 d-flex justify-content-between">
            <div class="cbtn" type="button" (click)="redialContact()" ngbTooltip="Redial" placement="top"
              tooltipClass="my-custom-class">
              <img class="img18" src="./assets/img/redial3.svg" alt="" />
            </div>

            <!-- ngbTooltip="Conference" -->
            <button class="cbtn" type="button" placement="bottom-left" [ngbPopover]="calendarContent"
              [disabled]="disabled">
              <!-- tooltipClass="my-custom-class" -->
              <img class="img18" src="./assets/img/conference.svg" alt="" />
            </button>
            <ng-template #calendarContent>
              <form (ngSubmit)="conferenceCall(form.value)" #form="ngForm">
                <div class="xp-3" style="width: 185px">
                  <div class="mb-1">
                    <input id="phone_number" name="phone_number" type="text" class="form-control"
                      placeholder="Enter Phone Number" ngModel />
                  </div>
                  <ng-multiselect-dropdown name="manager" [settings]="layoutService.dropdownSettings"
                    [data]="layoutService.projectAgents" [(ngModel)]="layoutService.projectSelectedAgent"
                    style="width: 100%; padding: 0" class="dropdown-primary"></ng-multiselect-dropdown>
                  <button class="btn btn-primary btn-block btn-md mt-2">
                    Connect
                  </button>
                </div>
              </form>
            </ng-template>

            <div class="cbtn" type="button" (click)="stopCalls()" ngbTooltip="Stop Calls" placement="top"
              tooltipClass="my-custom-class">
              <img class="img18" style="padding-top: 3px" src="./assets/img/stop-call-r.svg" alt="" />
            </div>

            <div class="cbtn" type="button" (click)="startCall()" ngbTooltip="Start Calls" placement="top"
              tooltipClass="my-custom-class">
              <img class="img18" style="padding-top: 3px" src="./assets/img/start-calls.svg" alt="" />
            </div>
          </div>
        </div>
        <div class="col-md-9 qt">
          <!--New form started -->
          <mat-tab-group>
            <mat-tab label="Questionaries">
              <form class="pt-2" [formGroup]="this.layoutService.commentsForm" (ngSubmit)="submit()">
                <div class="form-row">
                  <div class="form-group col-md-4">
                    <label>Name</label>
                    <input type="text" class="form-control" id="" placeholder="Name" formControlName="name" [ngClass]="{
                      'is-invalid':
                        submitted &&
                        f.name.errors &&
                        layoutService.commentsValue.interested !==
                          'notinterested',
                      'is-valid': f.name.valid
                    }" />
                    <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                      <div *ngIf="f.name.errors.required">name is required</div>
                    </div>
                  </div>
                  <div class="form-group col-md-4">
                    <label>Email Id</label>
                    <input type="email" class="form-control" id="" placeholder="Email Id" formControlName="emailId"
                      [ngClass]="{
                      'is-invalid':
                        submitted &&
                        f.emailId.errors &&
                        layoutService.commentsValue.interested !==
                          'notinterested',
                      'is-valid': f.emailId.valid
                    }" />
                    <div *ngIf="submitted && f.emailId.errors" class="invalid-feedback">
                      <div *ngIf="
                        f.emailId.errors.required &&
                        layoutService.commentsValue.interested !==
                          'notinterested'
                      ">
                        Please Enter a Valid EmailId
                      </div>
                    </div>
                  </div>
                  <div class="form-group col-md-4">
                    <label>Date of Birth</label>
                    <input type="date" class="form-control" id="" placeholder="" formControlName="dob" [ngClass]="{
                      'is-invalid':
                        submitted &&
                        f.dob.errors &&
                        layoutService.commentsValue.interested !==
                          'notinterested',
                      'is-valid': f.dob.valid && f.dob.touched
                    }" />
                    <div *ngIf="
                      submitted &&
                      f.dob.errors &&
                      layoutService.commentsValue.interested !== 'notinterested'
                    " class="invalid-feedback">
                      <div *ngIf="f.dob.errors.required">
                        Date of birth is required
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-row">
                  <div class="form-group col-md-4">
                    <label>State/Country</label>
                    <input type="email" class="form-control" id="" placeholder="" formControlName="state" [ngClass]="{
                      'is-invalid':
                        submitted &&
                        f.state.errors &&
                        layoutService.commentsValue.interested !==
                          'notinterested',
                      'is-valid': f.state.valid && f.state.touched
                    }" />
                    <div *ngIf="
                      submitted &&
                      f.state.errors &&
                      layoutService.commentsValue.interested !== 'notinterested'
                    " class="invalid-feedback">
                      <div *ngIf="f.state.errors.required">State is required</div>
                    </div>
                  </div>
                  <div class="form-group col-md-4">
                    <label>Alternative Contact Number</label>
                    <input type="text" class="form-control" id="" placeholder="" formControlName="altContactNumber"
                      [ngClass]="{
                      'is-invalid':
                        submitted &&
                        f.altContactNumber.errors &&
                        layoutService.commentsValue.interested !==
                          'notinterested',
                      'is-valid':
                        f.altContactNumber.valid && f.altContactNumber.touched
                    }" />
                    <div *ngIf="submitted && f.altContactNumber.errors" class="invalid-feedback">
                      <div *ngIf="f.altContactNumber.errors">
                        Alternate ContactNumber is required
                      </div>
                    </div>
                  </div>

                  <div class="form-group col-md-4">
                    <label for="">Country Preference </label>
                    <input type="text" class="form-control" id="" placeholder="" formControlName="countryPreference"
                      [ngClass]="{
                      'is-invalid':
                        submitted &&
                        f.countryPreference.errors &&
                        layoutService.commentsValue.interested !==
                          'notinterested',
                      'is-valid':
                        f.countryPreference.valid && f.countryPreference.touched
                    }" />
                    <div *ngIf="submitted && f.countryPreference.errors" class="invalid-feedback">
                      <div *ngIf="f.countryPreference.errors.required">
                        CountryPreference is required
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-row">
                  <div class="form-group col-md-4">
                    <label for="">Existing Counselling</label>
                    <div class="mt-1">
                      <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" name="existingCounseling" id="inlineRadio1"
                          value="yes" formControlName="existingCounseling" />
                        <label class="form-check-label" for="inlineRadio1">Yes</label>
                      </div>
                      <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" name="existingCounseling" id="inlineRadio2"
                          value="no" formControlName="existingCounseling" />
                        <label class="form-check-label" for="inlineRadio2">No</label>
                      </div>
                    </div>
                  </div>
                  <div class="form-group col-md-4">
                    <label for="">Valid Passport?</label>
                    <div class="mt-1">
                      <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" name="passport" id="inlineRadio1" value="yes"
                          formControlName="passport" />
                        <label class="form-check-label" for="inlineRadio1">Yes</label>
                      </div>
                      <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" name="passport" id="inlineRadio2" value="no"
                          formControlName="passport" />
                        <label class="form-check-label" for="inlineRadio2">No</label>
                      </div>
                    </div>
                  </div>

                  <div class="form-group col-md-4">
                    <label>Applied for Visa?</label>
                    <div class="mt-1">
                      <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" name="visa" id="inlineRadio1" value="yes"
                          formControlName="visa" />
                        <label class="form-check-label" for="inlineRadio1">Yes</label>
                      </div>
                      <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" name="visa" id="inlineRadio2" value="no"
                          formControlName="visa" />
                        <label class="form-check-label" for="inlineRadio2">No</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-row">
                  <div class="form-group col-md-4">
                    <div style="float: left; width: 35%; margin-right: 10px">
                      <label>IELETS</label>
                      <input type="text" formControlName="IELETS" class="form-control" placeholder="" [ngClass]="{
                        'is-invalid':
                          submitted &&
                          f.IELETS.errors &&
                          layoutService.commentsValue.interested !==
                            'notinterested',
                        'is-valid': f.IELETS.valid && f.IELETS.touched
                      }" />
                      <div *ngIf="
                        submitted &&
                        f.IELETS.errors &&
                        layoutService.commentsValue.interested !==
                          'notinterested'
                      " class="invalid-feedback">
                        <div *ngIf="f.IELETS.errors.required">
                          IELETS is required
                        </div>
                      </div>
                    </div>
                    <div class="" style="float: left; width: 35%; margin-right: 10px">
                      <label>TOEFL</label>
                      <input type="text" formControlName="TOEFL" class="form-control" placeholder="" [ngClass]="{
                        'is-invalid':
                          submitted &&
                          f.TOEFL.errors &&
                          layoutService.commentsValue.interested !==
                            'notinterested',
                        'is-valid': f.TOEFL.valid && f.TOEFL.touched
                      }" />
                    </div>
                    <div *ngIf="submitted && f.TOEFL.errors" class="invalid-feedback">
                      <div *ngIf="f.TOEFL.errors.required">TOEFL is required</div>
                    </div>
                  </div>

                  <div class="form-group col-md-4">
                    <div class="" style="float: left; width: 35%; margin-right: 10px">
                      <label>PTE</label>
                      <input type="text" formControlName="PTE" class="form-control" placeholder="" [ngClass]="{
                        'is-invalid':
                          submitted &&
                          f.PTE.errors &&
                          layoutService.commentsValue.interested !==
                            'notinterested',
                        'is-valid': f.PTE.valid && f.PTE.touched
                      }" />
                      <div *ngIf="submitted && f.PTE.errors" class="invalid-feedback">
                        <div *ngIf="f.PTE.errors.required">PTE is required</div>
                      </div>
                    </div>
                    <div class="" style="float: left; width: 35%; margin-right: 10px">
                      <label>Duolingo</label>
                      <input type="text" formControlName="Dulingo" class="form-control" placeholder="" [ngClass]="{
                        'is-invalid':
                          submitted &&
                          f.Dulingo.errors &&
                          layoutService.commentsValue.interested !==
                            'notinterested',
                        'is-valid': f.Dulingo.valid && f.Dulingo.touched
                      }" />
                      <div *ngIf="submitted && f.Dulingo.errors" class="invalid-feedback">
                        <div *ngIf="f.Dulingo.errors.required">
                          Dulingo is required
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="form-group col-md-4">
                    <label>If not when planning to appear</label>
                    <input type="" class="form-control" id="" placeholder="" formControlName="planning" [ngClass]="{
                      'is-invalid':
                        submitted &&
                        f.planning.errors &&
                        layoutService.commentsValue.interested !==
                          'notinterested',
                      'is-valid': f.planning.valid && f.planning.touched
                    }" />
                    <div *ngIf="submitted && f.planning.errors" class="invalid-feedback">
                      <div *ngIf="
                        f.planning.errors.required &&
                        layoutService.commentsValue.interested !==
                          'notinterested'
                      ">
                        Planning is required
                      </div>
                    </div>
                  </div>
                </div>

                <div class="form-row">
                  <div class="form-group col-md-4">
                    <label for="">Current Qualification</label>
                    <input type="text" formControlName="qualification" class="form-control" placeholder="" [ngClass]="{
                      'is-invalid':
                        submitted &&
                        f.qualification.errors &&
                        layoutService.commentsValue.interested !==
                          'notinterested',
                      'is-valid':
                        f.qualification.valid && f.qualification.touched
                    }" />
                    <div *ngIf="
                      submitted &&
                      f.qualification.errors &&
                      layoutService.commentsValue.interested !== 'notinterested'
                    " class="invalid-feedback">
                      <div *ngIf="f.qualification.errors.required">
                        Qualification is required
                      </div>
                    </div>
                  </div>
                  <div class="form-group col-md-4">
                    <label for="">Work Experience</label>
                    <input type="text" class="form-control" id="" formControlName="workExperience" placeholder=""
                      [ngClass]="{
                      'is-invalid':
                        submitted &&
                        f.workExperience.errors &&
                        layoutService.commentsValue.interested !==
                          'notinterested',
                      'is-valid':
                        f.workExperience.valid && f.workExperience.touched
                    }" />
                    <div *ngIf="
                      submitted &&
                      f.workExperience.errors &&
                      layoutService.commentsValue.interested !== 'notinterested'
                    " class="invalid-feedback">
                      <div *ngIf="f.workExperience.errors.required">
                        Work Experience is required
                      </div>
                    </div>
                  </div>
                  <div class="form-group col-md-4">
                    <label>Course Interested</label>
                    <input type="text" formControlName="courseInterested" class="form-control" placeholder="" [ngClass]="{
                      'is-invalid':
                        submitted &&
                        f.courseInterested.errors &&
                        layoutService.commentsValue.interested !==
                          'notinterested',
                      'is-valid':
                        f.courseInterested.valid && f.courseInterested.touched}" />
                    <div *ngIf="
                      submitted &&
                      f.courseInterested.errors &&
                      layoutService.commentsValue.interested !== 'notinterested'
                    " class="invalid-feedback">
                      <div *ngIf="f.courseInterested.errors.required">
                        Course Interested is required
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-row">
                  <div class="col-md-4">
                    <label for="description" class="inputEmail4">Assigned To</label>
                    <ng-multiselect-dropdown name="manager" [settings]="layoutService.dropdownSettings"
                      [data]="layoutService.roleAgents" formControlName="assignedTo"
                      [(ngModel)]="layoutService.selectedAgent" style="width: 100%; padding: 0"
                      [ngClass]="{'is-invalid': f.assignedTo.errors}">
                    </ng-multiselect-dropdown>
                    <div *ngIf="f.assignedTo.errors" class="invalid-feedback">
                      <div *ngIf="f.assignedTo.errors.required">
                        Assigned To is required
                      </div>
                    </div>
                    <!-- #manager="ngModel" -->
                  </div>
                  <div class="form-group col-md-4">
                    <label for="">Counselling Time and Date</label>
                    <input type="datetime-local" class="form-control" id="" placeholder=""
                      formControlName="counsellingDate" [ngClass]="{
                      'is-invalid': f.counsellingDate.errors
                    }" />
                    <div *ngIf="f.counsellingDate.errors" class="invalid-feedback">
                      <div *ngIf="f.counsellingDate.errors.required">
                        Counselling Date is required
                      </div>
                    </div>
                  </div>
                  <div class="form-group col-md-4">
                    <label for="">Call Back</label>
                    <input type="datetime-local" class="form-control" id="" placeholder="" formControlName="callBack"
                      [ngClass]="{
                      'is-invalid': f.callBack.errors
                    }" />
                    <div *ngIf="f.callBack.errors" class="invalid-feedback">
                      <div *ngIf="f.callBack.errors.required">
                        Call Back Date is required
                      </div>
                    </div>
                  </div>
                  <div class="form-group col-md-4">
                    <label>Funding Source</label>
                    <input type="text" formControlName="fundingResource" class="form-control" placeholder="" [ngClass]="{
                      'is-invalid':
                        submitted &&
                        f.fundingResource.errors &&
                        layoutService.commentsValue.interested !==
                          'notinterested',
                      'is-valid':
                        f.fundingResource.valid && f.fundingResource.touched
                    }" />
                    <div *ngIf="
                      submitted &&
                      f.fundingResource.errors &&
                      layoutService.commentsValue.interested !== 'notinterested'
                    " class="invalid-feedback">
                      <div *ngIf="f.fundingResource.errors.required">
                        Funding Resource is required
                      </div>
                    </div>
                  </div>
                  <div class="form-group col-md-4">
                    <label for="">Intake preferred</label>
                    <input class="form-control" type="text" formControlName="intakePreferred" [ngClass]="{
                      'is-invalid':
                        submitted &&
                        f.intakePreferred.errors &&
                        layoutService.commentsValue.interested !==
                          'notinterested',
                      'is-valid':
                        f.intakePreferred.valid && f.intakePreferred.touched
                    }" />
                    <div *ngIf="
                      submitted &&
                      f.intakePreferred.errors &&
                      layoutService.commentsValue.interested !== 'notinterested'
                    " class="invalid-feedback">
                      <div *ngIf="f.intakePreferred.errors.required">
                        In Take Proffered is required
                      </div>
                    </div>
                  </div>

                  <div class="form-group col-md-12">
                    <label for="">Feedback</label>
                    <div class="mt-2">
                      <div class="form-check form-check-inline" *ngFor="let item of listFeed">
                        <input class="form-check-input" type="radio" name="interested" id="inlineRadio1"
                          [value]="item.value" formControlName="interested" />
                        <label class="form-check-label" for="inlineRadio1">{{item?.name}}</label>
                      </div>
                    </div>
                    <div *ngIf="isIntrested" class="invalid-feedback" style="display: block !important;">
                      Feedback is required
                    </div>
                  </div>
                </div>
                <div class="d-flex justify-content-end">
                  <div class="mt-3">
                    <button class="btn btn-light btn-sm mr-2" type="button" (click)="layoutService.onReset()">
                      Reset
                    </button>
                    <button class="btn btn-primary btn-sm" type="submit">
                      Update
                    </button>
                  </div>
                </div>
              </form>
            </mat-tab>
            <mat-tab label="Feedback">
              <label for="" class="form-label mt-2">Comments</label>
              <textarea class="form-control" rows="5" [(ngModel)]="comments"></textarea>
            </mat-tab>
            <mat-tab label="More info">
              <div class="m-4">
                <div class="form-row">
                  <div class="form-group col-md-4">
                    <label for="">Name</label>
                    <div class="font-weight-bold">{{ marketingId?.name }}</div>
                  </div>
                  <div class="form-group col-md-4">
                    <label for="">City</label>
                    <div class="font-weight-bold">
                      {{ marketingId?.lead?.City }}
                    </div>
                  </div>
                  <div class="form-group col-md-4">
                    <label for="">Email</label>
                    <div class="font-weight-bold">
                      {{ marketingId?.lead?.Email }}
                    </div>
                  </div>
                </div>
                <div class="form-row">
                  <div class="form-group col-md-4">
                    <label for="">Assigned To</label>
                    <div class="font-weight-bold">
                      {{ marketingId?.lead?.Assignedto }}
                    </div>
                  </div>
                  <div class="form-group col-md-4">
                    <label for="">Form Id</label>
                    <div class="font-weight-bold">
                      {{ marketingId?.lead["Miscellaneous"]["form_id"] || "-" }}
                    </div>
                  </div>
                  <div class="form-group col-md-4">
                    <label for="">Form Name</label>
                    <div class="font-weight-bold">
                      {{ marketingId?.lead["Miscellaneous"]["form_name"] || "-" }}
                    </div>
                  </div>
                </div>
                <div class="form-row">
                  <div class="form-group col-md-4">
                    <label for="">Education</label>
                    <div class="font-weight-bold">
                      {{
                      marketingId?.lead["Education"] != null
                      ? marketingId?.lead["Education"]
                      : "-"
                      }}
                    </div>
                  </div>
                  <div class="form-group col-md-4" style="margin-bottom: 5px" *ngIf="
                    marketingId.lead['Miscellaneous'] &&
                    marketingId.lead['Miscellaneous']['form_name']
                      .toLowerCase()
                      .includes('usa')
                  ">
                    <label>Desired Start Term</label>
                    <div class="font-weight-bold">
                      {{
                      marketingId?.lead["Miscellaneous"]["Desired Start Term"] ||
                      "-"
                      }}
                    </div>
                  </div>
                  <div class="form-group col-md-4" style="margin-top: 5px" *ngIf="
                    marketingId.lead['Miscellaneous'] &&
                    marketingId.lead['Miscellaneous']['form_name']
                      .toLowerCase()
                      .includes('usa')
                  ">
                    <label>English Proficiency</label>
                    <div class="font-weight-bold">
                      {{
                      marketingId?.lead["Miscellaneous"][
                      "English Proficiency"
                      ] || "-"
                      }}
                    </div>
                  </div>
                  <div class="form-group col-md-4" style="margin-top: 5px" *ngIf="
                    marketingId.lead['Miscellaneous'] &&
                    marketingId.lead['Miscellaneous']['form_name']
                      .toLowerCase()
                      .includes('usa')
                  ">
                    <label>Preferred Study Level</label>
                    <div class="font-weight-bold">
                      {{
                      marketingId?.lead["Miscellaneous"][
                      "Preferred Study Level"
                      ] || "-"
                      }}
                    </div>
                  </div>
                  <div class="form-group col-md-4" style="margin-top: 5px" *ngIf="
                    marketingId.lead['Miscellaneous'] &&
                    marketingId.lead['Miscellaneous']['form_name']
                      .toLowerCase()
                      .includes('ielts')
                  ">
                    <label>Choose Batch</label>
                    <div class="font-weight-bold">
                      {{
                      marketingId?.lead["Miscellaneous"]["Choose Batch"] || "-"
                      }}
                    </div>
                  </div>
                  <div class="form-group col-md-4" style="margin-top: 5px" *ngIf="
                    marketingId.lead['Miscellaneous'] &&
                    marketingId.lead['Miscellaneous']['form_name']
                      .toLowerCase()
                      .includes('ielts')
                  ">
                    <label>Training Mode</label>
                    <div class="font-weight-bold">
                      {{
                      marketingId?.lead["Miscellaneous"]["Training Mode"] || "-"
                      }}
                    </div>
                  </div>
                </div>
              </div>
            </mat-tab>
          </mat-tab-group>
          <!--New form ended-->
        </div>
      </div>
    </div>
    <div class="mt-2" style="position: relative">
      <div style="position: absolute; bottom: 25px; left: 310px; margin: 0">
        <div class="mess mr-2" style="float: left; width: 240px; white-space: nowrap" *ngIf="isdemoScheduled"
          role="alert">
          <img src="../assets/img/scheduled.svg" class="img18" /> &nbsp;
          Counselling Scheduled
        </div>
        <div class="alertmess mr-2" style="float: left; white-space: nowrap" role="alert" *ngIf="isCustomerUpdate">
          <img src="../assets/img/scheduled.svg" class="img18" /> &nbsp; Comments
          Are Updated
        </div>
      </div>
    </div>
  </ng-template>