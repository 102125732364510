import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CustomService } from '../custom.service';

@Component({
  selector: 'app-cus-pagination',
  templateUrl: './cus-pagination.component.html',
  styleUrls: ['./cus-pagination.component.css']
})
export class CusPaginationComponent implements OnInit {
  pageNo: number = 1;
  @Input() totalCount: any;
  totalPages: number;
  @Output() currPage = new EventEmitter();

  onPageChange(pageNo: number) {
    this.pageNo = pageNo;
    // this.currPage.next(this.pageNo);
    this.customService.pageNumber.next(this.pageNo);
  }

  constructor(private customService: CustomService) { }

  ngOnInit(): void {
    this.customService.totalPages.subscribe((resp: any) => {
      this.totalPages = resp
    });
    this.customService.pageNumber.subscribe((res:any)=>{
      this.pageNo = res;
    });
    this.onPageChange(this.pageNo);
    document.getElementById("firstPage").click();
  }

}
