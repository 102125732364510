import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "statusvalue",
})
export class StatusvaluePipe implements PipeTransform {
  transform(value: any): any {
    if (value === "demo-followup") {
      return "D-Followup";
    }
    if (value === "demo-interested") {
      return "D-Interested";
    }
    if (value === "demo-notinterested") {
      return "D-Not Interested";
    }
    if (value === "notinterested") {
      return "Not Interested";
    }
    return value;
  }
}
