import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { LeadgenerationService } from 'src/app/pages/leadgeneration/leadgeneration.service';
import { AicallsService } from './aicalls.service';
export enum REGEX {
  EMAIL = '[A-Za-z0-9._%-]+@[A-Za-z0-9._%-]+\\.[a-z]{2,4}',
  PHONE = '[0-9]{10}$',
  PASSWORD = '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$',
  ZIP = '^[a-zA-Z0-9,\'-]*$',
  NUMBER = '^[0-9]*$',
  URL = '^(http:\/\/www\.|https:\/\/www\.|www\.|http:\/\/|https:\/\/)[a-zA-Z0-9\.\-]+\\.[a-zA-Z]{2,5}[\.]{0,1}',
  CHARACTER = '^[a-zA-Z \-\']+',
  SPECIAL_PHONE = '^[+|0-9]+[0-9]{1,}$',
  ADDRESS = '^[a-zA-Z0-9\s,\'-]*$',
}

@Component({
  selector: 'app-ai-analytics',
  templateUrl: './ai-analytics.component.html',
  styleUrls: ['./ai-analytics.component.css']
})
export class AiAnalyticsComponent implements OnInit {
  callsForm: FormGroup;
  selectedCountryCode: number;
  countryList: any[] = [];
  countryList1: any[] = [];
  selectedCountry: any;
  selectedAudio: File;
  isLoading: boolean = false;
  noRecords: boolean = false;
  analytics = {
    "proper_tone_of_voice": 0.0,
    "skilled_listening": 0.0,
    "normal_speaking_speed": 0.0,
    "solid_call/chat_closeout": 0.0,
    "well-customer_expectations": 0.0,
    "demonstrated_emotional_intelligence": 0.0,
    "the_right_language": 0.0,
    "good_rapport": 0.0
  };
  max = 5;
  isReadonly = true;
  totalAnalyzedCalls: number = 0;
  isLoaded = false;

  constructor(
    private fb: FormBuilder,
    private leadsService: LeadgenerationService,
    private aicallsServices: AicallsService
  ) { }

  ngOnInit(): void {
    this.leadsService.readJson('../../../../assets/json/countryList.json').subscribe((resp: any) => {
      this.countryList = resp;
      this.countryList1 = resp;
    });
    this.initCalls();
    this.totalAnalyzed();
  }
  initCalls() {
    this.callsForm = this.fb.group({
      email: [null, Validators.compose([Validators.required, Validators.pattern(REGEX.EMAIL)])],
      name: new FormControl('', [Validators.required, Validators.pattern(REGEX.CHARACTER)]),
      companyname: new FormControl('', [Validators.required, Validators.pattern(REGEX.CHARACTER)]),
      phone: new FormControl('', [Validators.required]),
      audio: new FormControl('', [Validators.required])
    });
  }

  get f() {
    return this.callsForm.controls;
  }
  onSubmit() {
    this.isLoaded = false;
    this.isLoading = true;
    let formData: FormData = new FormData();
    formData.append("email", this.callsForm.value.email);
    formData.append("name", this.callsForm.value.name);
    formData.append("companyname", this.callsForm.value.companyname);
    formData.append("phone", this.callsForm.value.phone);
    formData.append("audio", this.selectedAudio, this.selectedAudio.name);
    this.aicallsServices.uploadAudio(formData).toPromise()
      .then((resp: any) => {
        this.isLoading = false;
        this.isLoaded = true;
        this.callsForm.reset();
        this.analytics = JSON.parse(resp.analytics);
      })
      .catch(err => {
        this.isLoading = false;
      })
  }
  selectCountry(country) {
    this.selectedCountry = country;
    console.log(this.selectedCountry);
    this.selectedCountryCode = country.callingCodes[0];
  }
  onInput(Event: any) {
    let search = Event.target.value;
    let serachData = [];
    if (search) {
      this.countryList = this.countryList.filter((x) => {
        // debugger
        return x.name.toLowerCase().includes(search.toLowerCase());
      });
    } else {
      this.countryList = this.countryList1;
    }
  }
  getFileDetails(event) {
    let fileList: FileList = event.target.files;
    let file: File = fileList[0];
    this.selectedAudio = file;
  }

  getStar(perc) {
    return ((perc * 5) / 100);
  }
  totalAnalyzed() {
    this.aicallsServices.totalAnalyzed().toPromise()
      .then((resp: any) => {
        this.totalAnalyzedCalls = resp.sentimentalCount;
      })
      .catch(err => {

      })
  }
}
