import { HttpClientService } from "src/app/httpclient.service";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { HttpClientModule } from "@angular/common/http";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { BehaviorSubject } from "rxjs";
import { ToastrService } from "ngx-toastr";

@Injectable({
  providedIn: 'root'
})
export class CallanalyticsService {
  baseApiUrl = environment.baseApiUrl2;
  userData = JSON.parse(`${localStorage.getItem("userData")}`);
  constructor(
    private http: HttpClientService,
    private http2: HttpClient,
    private toastr: ToastrService
  ) { }


  getCounts(data) {
    return this.http.post(`${this.baseApiUrl}/getCounts`, data);
  }
  getgoodVsbadVsfraud(data) {
    return this.http.post(`${this.baseApiUrl}/goodVsbadVsfraud`, data);
  }
  gettopgoodcalls(data) {
    return this.http.post(`${this.baseApiUrl}/topgoodcalls`, data);
  }
  gettopbadcalls(data) {
    return this.http.post(`${this.baseApiUrl}/topbadcalls`, data);
  }
  getcallsPerMonth(data) {
    return this.http.post(`${this.baseApiUrl}/callsPerMonth`, data);
  }
}
