import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HttpClientService } from 'src/app/httpclient.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DemosService {
  baseUrl2 = environment.baseApiUrl2;
  startCall = new BehaviorSubject<any>(null);
  stopCall = new BehaviorSubject<any>(null);
  getCallSid = new BehaviorSubject<any>(null);
  startTimer = new BehaviorSubject<any>(null);
  pageNumber = new BehaviorSubject<any>(null);
  totalPages = new BehaviorSubject<any>(null);
  constructor(private http: HttpClientService) { }

  getList(data){
    return this.http.post(
      `${this.baseUrl2}demo/listAlldemo`,
      data
    )
  }  
  updateCallComment(data){
    return this.http.post(
      `${this.baseUrl2}demo/updateCallComments`,
      data
    )
  }
  search(data){
    return this.http.post(
      `${this.baseUrl2}demo/search`,
      data
    )
  }
  getTopCounts(data){
    return this.http.post(
      `${this.baseUrl2}demo/getTopBarCount`,
      data
    )
  }

  getDemoById(id){
    return this.http.get(
      `${this.baseUrl2}demo/listDemobyId/${id}`)
  }
  updateCallStatus(data) {
    return this.http.post(
      `${this.baseUrl2}demo/updateCallStatus`,
      data
    );
  }
  updateCallSid(data) {
    return this.http.post(`${this.baseUrl2}demo/updateCallSid`, data);
  }
  
  updateDemo(data) {
    return this.http.post(`${this.baseUrl2}/demo/updateDemo`, data);
  }
}
