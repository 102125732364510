<div class="container-fluid">
  <div class="row">
    <!-- Nav -->
    <nav class="navbar navbar-expand-lg bg-light">
      <div class="container-fluid">
        <a class="navbar-brand" href="index.html"><img src='../../assets/images/Accessque-small.svg'
            alt="AccessQue"></a>
        <button class="navbar-toggler collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#navbarText"
          aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="navbar-collapse collapse mobile-view" id="navbarText">
          <ul class="navbar-nav me-auto mb-2 mb-lg-0 mob-center">
            <li class="nav-item"><button type="button" class="btn btn-link" routerLink="">Home</button>
            </li>
            <li class="nav-item"><button type="button" class="btn btn-link" routerLink="">About Us</button>
            </li>
            <li class="nav-item">
              <div class="dropdown">
                <button class="btn btn-link dropdown-toggle" type="button" id="dropdownMenuButton"
                  data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  Our Projects
                </button>
                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                  <a class="dropdown-item" href="#">B2B Lead generation</a>
                  <a class="dropdown-item" href="#">Accessque lead generation</a>
                  <a class="dropdown-item" href="#">Virtual Agents-Voice Bots</a>
                  <a class="dropdown-item" href="#">Soar integration.</a>
                  <a class="dropdown-item" href="#">Sales, Marketing & Referrals</a>
                  <a class="dropdown-item" href="#">Agent Monitoring</a>
                  <a class="dropdown-item" routerLink="../aicalls">AI Call Analytics</a>
                  <a class="dropdown-item" href="#">Automation-RPA Bots</a>
                  <a class="dropdown-item" href="#">BI Analytics</a>
                </div>
              </div>
            </li>

            <li class="nav-item">
              <button type="button" class="btn btn-link mr-2" routerLink="../login">Login</button>
            </li>
            <li class="nav-item">
              <button type="button" class="btn btn-link" routerLink="/signup">Sign-up</button>
            </li>
            <li class="nav-item">
              <button type="button" class="btn btn-primary" routerLink="/bookademo/aicalls">Book a Demo</button>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </div>
  <div class="row">
    <div class="home-banner-bg2">
      <div class="banenr-text2">AI Call Analytics</div>
    </div>
  </div>
</div>
<div class="container-md m-auto">
  <!--Section AccessQue-->
  <div class="row mt-5">
    <div class="col-md-6 col-sm-6 col-lg-6">
      <p class="lightFont w-80 lh130 px-3">
        CRM has AI data model to monitor Agent Call recordings. OUR AI analyser tells us about Good call and bad call by
        rating the agents based on
      </p>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6 col-sm-6 col-lg-6">
      <ul class="data-list pl-3">
        <li>Report Should be analysed based on the below parameters</li>
        <li>Proper tone of voice</li>
        <li>Skilled listening</li>
        <li>Normal speaking speed</li>
        <li>The right language</li>
        <li>Effective questioning, including the use of open, probing, and closed questions</li>
        <li>Good rapport</li>
        <li>Demonstrated emotional intelligence</li>
        <li>Solid call/chat closeout</li>
        <li>Well-managed customer expectations</li>
        <li>Appropriate use of script</li>
      </ul>
    </div>
    <div class="col-md-6 col-sm-6 col-lg-6">
      <img src="../../../assets/images/ai-calls-graphic-1.png" style="width:100%" />
    </div>
  </div>
  <div class="row mt-5 mb-5">
    <div class="col-md-12 text-center">
      <button class="btn btn-primary" routerLink="/bookademo/aicalls">Book a Demo</button>
    </div>
  </div>

</div>

<footer>
  <div class="container pb-2 pt-2 d-flex">
    <div class="col-12 col-lg-3 pt-3">
      <a href="#"><img src='../../assets/images/accessQue_white.svg' class="img-fluid smallLogo" /></a>
      <ul class="nav text-white align-items-center mb-20 nav-gap-md nav-no-opacity ml-2 mt-2">
        <li class="nav-item"><a class="nav-link" href="#"><svg xmlns="http://www.w3.org/2000/svg" width="8" height="17"
              fill="none">
              <path fill="currentColor"
                d="M6.318 2.8h1.391V.202A16.842 16.842 0 0 0 5.683.088c-2.006 0-3.38 1.353-3.38 3.837v2.287H.089v2.902h2.214v7.303h2.713V9.114H7.14l.338-2.902H5.016v-2c0-.839.21-1.413 1.302-1.413Z">
              </path>
            </svg></a></li>
        <li class="nav-item"><a class="nav-link" href="#"><svg xmlns="http://www.w3.org/2000/svg" width="21" height="15"
              fill="none">
              <path fill="currentColor"
                d="M19.687 2.485A2.472 2.472 0 0 0 17.953.73C16.423.313 10.29.313 10.29.313s-6.133 0-7.662.416A2.473 2.473 0 0 0 .895 2.485c-.41 1.55-.41 4.782-.41 4.782s0 3.233.41 4.782c.226.855.89 1.5 1.734 1.729 1.53.415 7.662.415 7.662.415s6.132 0 7.662-.415a2.435 2.435 0 0 0 1.734-1.729c.41-1.549.41-4.782.41-4.782s0-3.232-.41-4.782ZM8.285 10.203v-5.87l5.126 2.934-5.126 2.936Z">
              </path>
            </svg></a></li>
        <li class="nav-item"><a class="nav-link" href="#"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="16"
              fill="none">
              <path fill="currentColor"
                d="M17.477 4.484c.012.165.012.329.012.493 0 5.014-3.817 10.792-10.792 10.792-2.149 0-4.145-.623-5.824-1.703.305.035.599.047.916.047a7.596 7.596 0 0 0 4.709-1.62 3.8 3.8 0 0 1-3.547-2.63c.235.034.47.058.717.058.34 0 .68-.047.998-.13A3.793 3.793 0 0 1 1.625 6.07v-.047a3.82 3.82 0 0 0 1.714.482 3.79 3.79 0 0 1-1.691-3.159c0-.704.188-1.35.517-1.914a10.781 10.781 0 0 0 7.82 3.97 4.282 4.282 0 0 1-.094-.87c0-2.09 1.691-3.793 3.793-3.793 1.092 0 2.079.458 2.771 1.198a7.466 7.466 0 0 0 2.408-.916c-.282.88-.881 1.62-1.668 2.09a7.604 7.604 0 0 0 2.184-.587 8.153 8.153 0 0 1-1.902 1.961Z">
              </path>
            </svg></a></li>
        <li class="nav-item"><a class="nav-link" href="#"><svg xmlns="http://www.w3.org/2000/svg" width="17" height="17"
              fill="none">
              <path fill="currentColor"
                d="M8.788 4.097C6.47 4.097 4.6 5.95 4.6 8.25c0 2.298 1.87 4.153 4.188 4.153 2.318 0 4.188-1.855 4.188-4.153 0-2.3-1.87-4.153-4.188-4.153Zm0 6.853c-1.498 0-2.723-1.211-2.723-2.7 0-1.49 1.221-2.7 2.723-2.7 1.502 0 2.723 1.21 2.723 2.7 0 1.489-1.225 2.7-2.723 2.7Zm5.336-7.023a.97.97 0 0 1-.977.968.97.97 0 0 1-.976-.968c0-.535.437-.969.976-.969.54 0 .977.434.977.969Zm2.774.983c-.062-1.298-.36-2.447-1.32-3.394C14.624.569 13.465.272 12.156.207c-1.349-.076-5.39-.076-6.74 0C4.113.27 2.954.565 1.995 1.512 1.035 2.46.74 3.61.674 4.906c-.076 1.338-.076 5.346 0 6.683.063 1.298.361 2.447 1.32 3.394.959.947 2.114 1.244 3.423 1.309 1.348.076 5.39.076 6.739 0 1.308-.062 2.468-.358 3.422-1.309.956-.947 1.254-2.096 1.32-3.394.076-1.337.076-5.342 0-6.68Zm-1.742 8.114a2.745 2.745 0 0 1-1.553 1.54c-1.075.423-3.627.325-4.815.325-1.188 0-3.743.095-4.815-.325a2.746 2.746 0 0 1-1.552-1.54c-.427-1.066-.329-3.596-.329-4.774 0-1.179-.094-3.712.329-4.775a2.745 2.745 0 0 1 1.552-1.54C5.048 1.512 7.6 1.61 8.788 1.61c1.188 0 3.743-.094 4.815.325a2.745 2.745 0 0 1 1.553 1.54c.426 1.066.328 3.596.328 4.775 0 1.178.098 3.712-.328 4.774Z">
              </path>
            </svg></a></li>
      </ul>
    </div>
    <div class="footerLinks">
      <div class="col-6 col-md-4 text-white footerText mt-3">
        <h6>Company</h6>
        <ul class="footer-link">
          <li><a href="#">About Us</a></li>
        </ul>
      </div>
      <div class="col-6 col-md-4 text-white footerText mt-3">
        <h6>Policies</h6>
        <ul class="footer-link">
          <li><a href="privacy">Privacy Policy</a></li>
          <li><a href="#">Terms & Conditions</a></li>
        </ul>
      </div>
      <div class="col-6 col-md-4 text-white footerText mt-3">
        <h6>Contact</h6>
        <ul class="footer-link">
          <li><a href="#">+00 000 000 0000</a></li>
          <li><a href="#">+00 000 000 0000</a></li>
          <li><a href="#">+00 000 000 0000</a></li>
        </ul>
      </div>

    </div>
  </div>
  <div class="copy mt-4">
    <p class="extrasmallFont text-white">Developed by <strong>Avantao Technologies </strong><br />© {{todaydate | date: 'yyyy'}} Copyright
      Accessque. </p>
  </div>

</footer>