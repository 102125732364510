<div class="container-fluid p-0">
  <div class="row">
    <div class="home-banner-bg2">
      <div class="banenr-text2">AI Lip-sync with Deep fake Detection</div>
    </div>
  </div>
</div>
<div class="container-md m-auto">
  <div class="row">
    <div class="col-md-12">
      <p class="lightFont w-80 lh130 p-5" style="font-size: 20px;">
        We propose detection of deepfake videos based on the dissimilarity between the audio and visual modalities,
        termed as the Modality Dissonance Score (MDS). We hypothesize that manipulation of either modality will lead to
        dis-harmony between the two modalities, eg, loss of lip-sync, unnatural facial and lip movements, etc. MDS is
        computed as an aggregate of dissimilarity scores between audio and visual segments in a video.
      </p>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6 col-sm-6 col-lg-6">
      <p class="lightFont w-80 lh130 px-5 pb-2">
        Discriminative features are learnt for the audio and visual channels in a chunk-wise manner, employing the
        cross-entropy loss for individual modalities, and a contrastive loss that models inter-modality similarity.
      </p>
      <p class="lightFont w-80 lh130 px-5 pb-2"> Extensive experiments on the DFDC and DeepFake-TIMIT Datasets show that
        our approach outperforms the state-of-the-art by up to 7%. We also demonstrate temporal forgery localization,
        and show how our technique identifies the manipulated video segments.
      </p>
    </div>
    <div class="col-md-6 text-center">
      <img src="../../../../assets/images/lipsync-deepface-graphic.png" style="width:300px">
    </div>
  </div>
  <div class="row">
    <div class="col-md-4">
      <div class="card mt-4">
        <form (ngSubmit)="onSubmit()" [formGroup]="callsForm">
          <div class="card-body p-4 demo-block">
            <div class="header-lg">Check with your Video file</div>
            <div class="mb-3">
              <label for="exampleFormControlInput1" class="form-label">Name</label>
              <input type="email" class="form-control" id="exampleFormControlInput1" formControlName="name"
                class="form-control" [ngClass]="{ 'is-invalid': f.name.touched && f.name.errors }" placeholder="">
            </div>
            <div class="mb-3">
              <label for="exampleFormControlInput1" class="form-label">Email</label>
              <input type="email" class="form-control" id="exampleFormControlInput1" formControlName="email"
                class="form-control" [ngClass]="{ 'is-invalid': f.email.touched && f.email.errors }" placeholder="">
            </div>
            <div class="mb-3">
              <label for="exampleFormControlInput1" class="form-label">Mobile</label>
              <input type="email" class="form-control" formControlName="phone" class="form-control"
                [ngClass]="{ 'is-invalid': f.phone.touched && f.phone.errors }" id="exampleFormControlInput1"
                placeholder="">
            </div>
            <div class="mb-3">
              <label for="exampleFormControlInput1" class="form-label">Company Name</label>
              <input type="email" class="form-control" formControlName="companyname" class="form-control" [ngClass]="{ 'is-invalid':f.companyname.touched 
                  && f.companyname.errors }" id="exampleFormControlInput1" placeholder="">
            </div>
            <div class="mb-3 d-flex text-start">
              <div>
                <input hidden type="file" accept="video/mp4,video/x-m4v,video/*" #uploadFile2
                  (change)="getFileDetails($event)" />
                <img src="../../../../assets/images/audio-3.png">
                <button class="btn btn-link ml-2 " type="button" (click)="uploadFile2.click()">
                  Upload Video file here..</button>
              </div>
            </div>
            <div class="mb-3 d-flex text-start">
              <div><button class="btn btn-primary ml-2" type="submit">Check</button></div>
            </div>

          </div>
        </form>
      </div>
    </div>
    <div class="col-md-8">
      <div class="card mt-4 ml-2 voice-bg">
        <div class="card-body p-4 demo-block">
          <div *ngIf="isLoaded">
            <table class="table table-borderless table-md caller-table my-1">
              <tr>
                <td class="sound-wave-txt">Video is syncing properly - {{analytics | titlecase}}</td>
              </tr>
            </table>
          </div>
          <div *ngIf="!isLoaded">
            <div class="sound-wave-block">
              <img src="../../../../assets/images/sound-wave-w.svg">
            </div>
            <div class="sound-wave-txt">Yes - No</div>
            <div *ngIf="isLoading ==true">
              <div class="norecords">
                <img src="../../../../assets/img/waiting.gif" />
                <div>Loading Data<br />Please wait while we fetch the data for you</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row mt-5 mb-5">
    <div class="col-md-12 text-center">
      <button class="btn btn-primary" routerLink="/bookademo/lipsync">Book a Demo</button>
    </div>
  </div>
  <!-- Modal -->
  <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-md">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Book a Demo</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><img
              src="../../../../assets/img/close-w.svg" class="img12"></button>
        </div>
        <div class="modal-body">
          <div class="py-3">
            Please complete this form. An expert will contact you soon.
          </div>
          <form>
            <div class="mb-3">
              <label for="exampleFormControlInput1" class="form-label">First Name</label>
              <input type="" class="form-control" id="exampleFormControlInput1" placeholder="">
            </div>
            <div class="mb-3">
              <label for="exampleFormControlInput1" class="form-label">Last Name</label>
              <input type="" class="form-control" id="exampleFormControlInput1" placeholder="">
            </div>
            <div class="mb-3">
              <label for="exampleFormControlInput1" class="form-label">Company Name</label>
              <input type="" class="form-control" id="exampleFormControlInput1" placeholder="">
            </div>
            <div class="mb-3">
              <label for="exampleFormControlInput1" class="form-label">Description</label>
              <textarea type="" class="form-control" id="exampleFormControlInput1">
              </textarea>
            </div>
            <div class="mb-3">
              <label for="exampleFormControlInput1" class="form-label">Email</label>
              <input type="" class="form-control" id="exampleFormControlInput1" placeholder="">
            </div>
            <div class="row mb-3 ml-0">
              <div class="col-auto mr-3">
                <label for="exampleFormControlInput1" class="form-label">Mobile</label>
                <input type="email" class="form-control" style="width:350px;" id="exampleFormControlInput1"
                  placeholder="">
              </div>
              <div class="col-auto">
                <button type="submit" class="btn btn-outline-secondary mt-20">Send OTP</button>
              </div>
            </div>
            <div class="mb-3">
              <input type="email" class="form-control" id="exampleFormControlInput1" placeholder="Enter OTP">
            </div>
            <div class="mb-3 d-flex align-items-center">
              <div>
                <img src="../../../../assets/signup/cap.jpg" style="width:230px">
              </div>
              <div class="ml-auto">
                <button class="btn btn-primary btn-sm">Submit</button>
              </div>
            </div>
          </form>
        </div>

      </div>
    </div>
  </div>
  <!-- AUDIO UPLOAD FORM -->

  <!-- <div>
      <form>
        <div class="form-group mt-3 mb-3">
          <label for="email" class="label-txt">Email</label>
          <input
            type="email"
            formControlName="email"
            class="form-control"
            [ngClass]="{ 'is-invalid': f.email.touched && f.email.errors }"
            id="email"
            placeholder="Email"
          />
  
          <div *ngIf="f.email.touched && f.email.errors" class="invalid-feedback">
            <div *ngIf="f.email.errors.required">Email is required</div>
            <div *ngIf="f.email.errors.pattern">
              Email must be a valid email address
            </div>
          </div>
        </div>
      </form>
    </div> -->
</div>