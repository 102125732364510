import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'requeststatus'
})
export class RequeststatusPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    if (value.includes('inprogress')) {
      return 'In Progress';
    }
    return value;
  }

}
