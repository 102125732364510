<div class="reportFilter-block pt-3">

    <!-- FOR PROJECTS -->
    <!-- <div class="filter_blocks">
        <div class="dropdown_list">
            <button type="button" class="btn btn-light btn-sm d-flex align-items-center" (click)="toggleFilters('showext');"
                [ngClass]="{'btn-filter-active': selectedIndexExt}">
                Ext :&nbsp; <div *ngIf="selectedIndexExt">{{currentExtname | titlecase}}</div>
                <div>
                    <i class="fa-solid fa-angle-down arrow-down" style="top:0"></i>
                </div>
            </button>

            <div class="drop-show drop-show-label" *ngIf="showext">
                <ul class="more_dropdown_list">
                    <li><input type="text" [(ngModel)]="extSearch" class="form-input spl-search-icon" placeholder="Search"></li>
                    <li *ngFor="let a of extList | filter: extSearch;">
                        <div class="input_wrp_filter">
                            <input type="checkbox" class="more_dropdown_list_input"
                                [ngModel]="selectedIndexExt === a.ext"
                                (change)="projectSelection($event, a.ext,a.name)" />
                            <label><span>{{a.name | titlecase}} </span></label>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div> -->
    <!-- EXT -->
    <div class="filter_blocks">
        <div class="dropdown_list">
            <button type="button" class="btn btn-light btn-sm d-flex align-items-center"
                (click)="toggleFilters('showext');" [ngClass]="{'btn-filter-active': extSelectionArray.length>0}">
                Ext :&nbsp;
                <div *ngIf="extSelectionArray.length>0 && extSelectionArray.length != extList.length; else showAll">
                    {{getExtName(extSelectionArray[extSelectionArray.length-1]) | titlecase}}</div>
                <div *ngIf="extSelectionArray.length>1 && extSelectionArray.length != extList.length;"
                    class="badge_count">+{{extSelectionArray.length}}
                </div>
                <div>
                    <i class="fa-solid fa-angle-down arrow-down" style="top:0"></i>
                </div>
            </button>
            <div class="drop-show drop-show-label" *ngIf="showext">
                <ul class="more_dropdown_list">
                    <li><input type="text" class="form-input spl-search-icon" [(ngModel)]="extSearch"
                            placeholder="Search"></li>
                    <li>
                        <div class="input_wrp_filter">
                            <input class="more_dropdown_list_input" type="checkbox" value="" id="defaultCheck1"
                                name="all" [checked]="isAllCheckedext()" (change)="checkAllext($event)" />
                            <label style="display: flex !important;">
                                <span>All</span>
                            </label>
                        </div>
                    </li>
                    <li *ngFor="let a of extList | filter: extSearch;">
                        <div class="input_wrp_filter">
                            <input class="more_dropdown_list_input" type="checkbox"
                                (change)="extSelection($event.target)" [value]="a.ext" id="defaultCheck1"
                                name="sizecb[]" [(ngModel)]="a.extStatus" />
                            <label style="display: flex !important;">
                                <span>{{a.name | titlecase}}</span>
                            </label>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <!-- EXT END -->

    <!-- DEST EXT -->
    <!-- <div class="filter_blocks">
        <div class="dropdown_list">
            <button type="button" class="btn btn-light btn-sm d-flex align-items-center"
                (click)="toggleFilters('showdestext');"
                [ngClass]="{'btn-filter-active': destextSelectionArray.length>0}">
                Dest Ext :&nbsp;
                <div *ngIf="destextSelectionArray.length>0 && destextSelectionArray.length != destextList.length; else showAll">
                    {{destextSelectionArray[destextSelectionArray.length-1] | titlecase}}</div>
                <div *ngIf="destextSelectionArray.length>1 && destextSelectionArray.length != destextList.length;" class="badge_count">+{{destextSelectionArray.length}}
                </div>
                <div>
                    <i class="fa-solid fa-angle-down arrow-down" style="top:0"></i>
                </div>
            </button>
            <div class="drop-show drop-show-label" *ngIf="showdestext">
                <ul class="more_dropdown_list">
                    <li><input type="text" class="form-input spl-search-icon" [(ngModel)]="destextSearch" placeholder="Search"></li>
                    <li>
                        <div class="input_wrp_filter">
                            <input class="more_dropdown_list_input" type="checkbox" value="" id="defaultCheck1"
                                name="all" [checked]="isAllCheckeddestext()" (change)="checkAlldestext($event)" />
                            <label style="display: flex !important;">
                                <span>All</span>
                            </label>
                        </div>
                    </li>
                    <li *ngFor="let a of destextList | filter: destextSearch;">
                        <div class="input_wrp_filter">
                            <input class="more_dropdown_list_input" type="checkbox"
                                (change)="destextSelection($event.target)" [value]="a.name" id="defaultCheck1"
                                name="sizecb[]" [(ngModel)]="a.destextStatus" />
                            <label style="display: flex !important;">
                                <span>{{a.name | titlecase}}</span>
                            </label>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div> -->
    <!-- DEST EXT END -->

    <!-- CALL STATUS -->
    <div class="filter_blocks">
        <div class="dropdown_list">
            <button type="button" class="btn btn-light btn-sm d-flex align-items-center"
                (click)="toggleFilters('showcallStatus');"
                [ngClass]="{'btn-filter-active': callStatusSelectionArray.length>0}">
                Call Status :&nbsp;
                <div
                    *ngIf="callStatusSelectionArray.length>0 && callStatusSelectionArray.length != callStatusList.length; else showAll">
                    {{getCallStatus(callStatusSelectionArray[callStatusSelectionArray.length-1]) | titlecase}}</div>
                <div *ngIf="callStatusSelectionArray.length>1 && callStatusSelectionArray.length != callStatusList.length;"
                    class="badge_count">+{{callStatusSelectionArray.length}}
                </div>
                <div>
                    <i class="fa-solid fa-angle-down arrow-down" style="top:0"></i>
                </div>
            </button>
            <div class="drop-show drop-show-label" *ngIf="showcallStatus">
                <ul class="more_dropdown_list">
                    <li><input type="text" class="form-input spl-search-icon" [(ngModel)]="callStatusSearch"
                            placeholder="Search"></li>
                    <li>
                        <div class="input_wrp_filter">
                            <input class="more_dropdown_list_input" type="checkbox" value="" id="defaultCheck1"
                                name="all" [checked]="isAllCheckedcallStatus()" (change)="checkAllcallStatus($event)" />
                            <label style="display: flex !important;">
                                <span>All</span>
                            </label>
                        </div>
                    </li>
                    <li *ngFor="let a of callStatusList | filter: callStatusSearch;">
                        <div class="input_wrp_filter">
                            <input class="more_dropdown_list_input" type="checkbox"
                                (change)="callStatusSelection($event.target)" [value]="a.id" id="defaultCheck1"
                                name="sizecb[]" [(ngModel)]="a.callStatusStatus" />
                            <label style="display: flex !important;">
                                <span>{{a.name | titlecase}}</span>
                            </label>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <!-- CALL STATUS END -->

    <!-- CDR -->
    <div class="filter_blocks">
        <div class="dropdown_list">
            <button type="button" class="btn btn-light btn-sm d-flex align-items-center"
                (click)="toggleFilters('showcdr');" [ngClass]="{'btn-filter-active': cdrSelectionArray.length>0}">
                CDR :&nbsp;
                <div *ngIf="cdrSelectionArray.length>0 && cdrSelectionArray.length != cdrList.length; else showAll">
                    {{getCDR(cdrSelectionArray[cdrSelectionArray.length-1]) | titlecase}}</div>
                <div *ngIf="cdrSelectionArray.length>1 && cdrSelectionArray.length != cdrList.length;"
                    class="badge_count">+{{cdrSelectionArray.length}}
                </div>
                <div>
                    <i class="fa-solid fa-angle-down arrow-down" style="top:0"></i>
                </div>
            </button>
            <div class="drop-show drop-show-label" *ngIf="showcdr">
                <ul class="more_dropdown_list">
                    <li><input type="text" class="form-input spl-search-icon" [(ngModel)]="cdrSearch"
                            placeholder="Search"></li>
                    <li>
                        <div class="input_wrp_filter">
                            <input class="more_dropdown_list_input" type="checkbox" value="" id="defaultCheck1"
                                name="all" [checked]="isAllCheckedcdr()" (change)="checkAllcdr($event)" />
                            <label style="display: flex !important;">
                                <span>All</span>
                            </label>
                        </div>
                    </li>
                    <li *ngFor="let a of cdrList | filter: cdrSearch;">
                        <div class="input_wrp_filter">
                            <input class="more_dropdown_list_input" type="checkbox"
                                (change)="cdrSelection($event.target)" [value]="a.id" id="defaultCheck1" name="sizecb[]"
                                [(ngModel)]="a.cdrStatus" />
                            <label style="display: flex !important;">
                                <span>{{a.name | titlecase}}</span>
                            </label>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <!-- CDR END -->

    <!-- TIMEZONES -->
    <!-- <div class="filter_blocks">
        <div class="dropdown_list">
            <button type="button" class="btn btn-light btn-sm d-flex align-items-center"
                (click)="toggleFilters('showtimezones');"
                [ngClass]="{'btn-filter-active': timezonesSelectionArray.length>0}">
                Timezones :&nbsp;
                <div *ngIf="timezonesSelectionArray.length>0 && timezonesSelectionArray.length != timezonesList.length; else showAll">
                    {{timezonesSelectionArray[timezonesSelectionArray.length-1] | titlecase}}</div>
                <div *ngIf="timezonesSelectionArray.length>1 && timezonesSelectionArray.length != timezonesList.length;" class="badge_count">+{{timezonesSelectionArray.length}}
                </div>
                <div>
                    <i class="fa-solid fa-angle-down arrow-down" style="top:0"></i>
                </div>
            </button>
            <div class="drop-show drop-show-label" *ngIf="showtimezones">
                <ul class="more_dropdown_list">
                    <li><input type="text" class="form-input spl-search-icon" [(ngModel)]="timezonesSearch" placeholder="Search"></li>
                    <li>
                        <div class="input_wrp_filter">
                            <input class="more_dropdown_list_input" type="checkbox" value="" id="defaultCheck1"
                                name="all" [checked]="isAllCheckedtimezones()" (change)="checkAlltimezones($event)" />
                            <label style="display: flex !important;">
                                <span>All</span>
                            </label>
                        </div>
                    </li>
                    <li *ngFor="let a of timezonesList | filter: timezonesSearch;">
                        <div class="input_wrp_filter">
                            <input class="more_dropdown_list_input" type="checkbox"
                                (change)="timezonesSelection($event.target)" [value]="a.name" id="defaultCheck1"
                                name="sizecb[]" [(ngModel)]="a.timezonesStatus" />
                            <label style="display: flex !important;">
                                <span>{{a.name | titlecase}}</span>
                            </label>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div> -->
    <!-- TIMEZONES END -->

    <!-- DATE -->
    <div class="filter_blocks">
        <div class="dropdown_list">
            <button type="button" class="btn btn-light btn-filter-active d-flex align-items-center"
                style="font-size: 13px; padding: 4px 10px;" (click)="toggleFilters('showDate');hideDate()">
                Date :&nbsp; <div> {{startDate | date: 'dd MMMM yyyy'}} -
                    {{endDate | date: 'dd MMMM yyyy'}}</div>
                <div><i class="fa-solid fa-angle-down arrow-down" style="top:0"></i></div>
            </button>
            <div class="drop-show drop-show-date" *ngIf="showDate">
                <ul class="more_dropdown_list">
                    <li>
                        <div class="input_wrp_filter">
                            <input class="more_dropdown_list_input_radio" type="radio" value="24hrs"
                                [checked]="selectedIndexDate === '24hrs'" (change)="dateSelection($event)" />
                            <label><span>24 hrs</span></label>
                        </div>
                    </li>
                    <li>
                        <div class="input_wrp_filter">
                            <input class="more_dropdown_list_input_radio" type="radio" value="72hrs"
                                [checked]="selectedIndexDate === '72hrs' " (change)="dateSelection($event)" />
                            <label><span>72 hrs</span></label>
                        </div>
                    </li>
                    <li>
                        <div class="input_wrp_filter">
                            <input class="more_dropdown_list_input_radio" type="radio" value="07days"
                                [checked]="selectedIndexDate === '07days' " (change)="dateSelection($event)" />
                            <label><span>07 days</span></label>
                        </div>
                    </li>
                    <li>
                        <div class="input_wrp_filter">
                            <input class="more_dropdown_list_input_radio" type="radio" value="14days"
                                [checked]="selectedIndexDate === '14days' " (change)="dateSelection($event)" />
                            <label><span>14 days</span></label>
                        </div>
                    </li>
                    <li>
                        <div class="input_wrp_filter">
                            <input class="more_dropdown_list_input_radio" type="radio" value="30days"
                                [checked]="selectedIndexDate === '30days' " (change)="dateSelection($event)" />
                            <label><span>30 days</span></label>
                        </div>
                    </li>
                    <li class="d-block">
                        <div class="input_wrp_filter">
                            <input class="more_dropdown_list_input_radio" type="radio" value="custom"
                                [checked]="selectedIndexDate === 'custom' " (change)="dateSelection($event)" />
                            <label><span>Custom</span></label>
                        </div>
                        <div *ngIf="showDateCustom" style="position: relative;">
                            <div class="datePicker">
                                <div style="background: #fff; height: 76px; border: 1px solid #ccc" class="custom-date">
                                    <mat-form-field class="mr-3">
                                        <input class="label" matInput [matDatepicker]="picker1" placeholder="Start Date"
                                            [formControl]="date2" (dateInput)="getStartDate('input', $event)" />
                                        <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                                        <mat-datepicker #picker1 style="width: 80px"></mat-datepicker>
                                    </mat-form-field>

                                    <mat-form-field class="mr-3">
                                        <input class="label" matInput [matDatepicker]="picker2" placeholder="End Date"
                                            [formControl]="date1" (dateInput)="getEndDate('input', $event)" />
                                        <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                                        <mat-datepicker #picker2 style="width: 80px"></mat-datepicker>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <!-- DATE END -->
</div>
<div class="row bg-g pb-2">
    <div class="col-md-12 text-right">

        <button type="button" class="btn btn-primary btn-sm mr-2" (click)="generateData()">Generate</button>
        <button type="button" class="btn btn-secondary btn-sm" (click)="resetFilters()">Reset</button>

    </div>
</div>
<ng-template #showAll>
    <div>All</div>
</ng-template>