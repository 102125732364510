<div class="m-5" *ngIf="!isEditCompany && !isEditProject && !isCreateProject">
  <div class="p-3 b-radius-10 bg-w">
    <mat-stepper [linear]="isLinear" #stepper>
      <mat-step [stepControl]="firstFormGroup">
        <div class="pt-2 d-flex">
          <div class="ml-auto d-flex">
            <div>
              <img src="../../../assets/images/info.svg" class="img16 mr-2 hand icon-color" ngbTooltip="Information"
                placement="top" tooltipClass="my-custom-class" />
            </div>
            <div>
              <img src="../../../assets/images/video-play.svg" class="img16 hand" ngbTooltip="Demo video"
                placement="top" tooltipClass="my-custom-class" />
            </div>
          </div>
        </div>
        <form [formGroup]="firstFormGroup">
          <ng-template matStepLabel>Create Company</ng-template>

          <form class="was-validated" novalidate #form="ngForm" [ngClass]="{ 'was-validated': form.submitted }">
            <div class="form-row">
              <div class="form-group col-md-8">
                <label for="inputEmail4">Company name</label>
                <input type="text" class="form-control" id="uname" placeholder="Enter Company Name"
                  [(ngModel)]="CompanyDetails.CompanyName" name="uname" required />
                <div class="valid-feedback">Valid.</div>
                <div class="invalid-feedback">
                  Please enter the company name.
                </div>
              </div>

              <div class="form-group col-md-4">
                <label for="users">Total No. of Users</label>
                <input type="text" class="form-control" id="users" placeholder="Max Number of Users"
                  [(ngModel)]="CompanyDetails.NoOfUsers" name="users" required />
                <div class="valid-feedback">Valid.</div>
                <div class="invalid-feedback">Please enter a number</div>
              </div>
            </div>
            <div class="header-lg mb-2 mt-2">Contact Number</div>
            <div class="form-row">
              <div class="form-group col-md-6">
                <label for="primaryNumber">Primary Number</label>
                <input type="text" class="form-control" id="primaryNumber" placeholder=""
                  [(ngModel)]="CompanyDetails.PrimaryPhoneNumber" name="primaryNumber" required />
                <div class="valid-feedback">Valid.</div>
                <div class="invalid-feedback">Please enter a number</div>
              </div>
              <div class="form-group col-md-6">
                <label for="secondaryNumber">Secondary number</label>
                <input type="text" class="form-control" id="secondaryNumber" placeholder=""
                  [(ngModel)]="CompanyDetails.SecondaryPhoneNumber" name="secondaryNumber" required />
                <div class="valid-feedback">Valid.</div>
                <div class="invalid-feedback">Please enter a number</div>
              </div>
            </div>

            <div class="header-lg mb-2 mt-2">Email id's</div>
            <div class="form-row">
              <div class="form-group col-md-6">
                <label for="Email">Primary Email</label>
                <input type="text" class="form-control" id="Email" placeholder=""
                  [(ngModel)]="CompanyDetails.PrimaryEmail" name="Email" required />
                <div class="valid-feedback">Valid.</div>
                <div class="invalid-feedback">Please enter an email</div>
              </div>
              <div class="form-group col-md-6">
                <label for="SecondaryEmail">Secondary Email</label>
                <input type="text" class="form-control" id="SecondaryEmail" placeholder=""
                  [(ngModel)]="CompanyDetails.SecondaryEmail" name="SecondaryEmail" required />
                <div class="valid-feedback">Valid.</div>
                <div class="invalid-feedback">Please enter an email</div>
              </div>
            </div>
            <div class="header-lg mb-2 mt-2">Company Domain</div>
            <div class="form-row">
              <div class="form-group col-md-6">
                <label for="domainName">Domain Namme</label>
                <input type="text" class="form-control" id="domainName" placeholder=""
                  [(ngModel)]="CompanyDetails.domainName" placeholder="eg. example.com" name="domainName" required />
                <div class="valid-feedback">Valid.</div>
                <div class="invalid-feedback">Please enter an domain name</div>
              </div>

            </div>

            <div class="form-row">
              <div class="form-group col-md-6">
                <label for="voip">Voip </label>
                <div class="form-control" style="border: 0">
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="inlineRadioOptions" id="twilio" value="twilio"
                      [(ngModel)]="CompanyDetails.Voip.voip" required />
                    <div class="invalid-feedback"></div>
                    <label class="form-check-label" for="twilio">
                      <img src="../../../assets/images/twillo.png" />
                    </label>
                    <!-- <mat-menu #menu="matMenu">
                      <div class="p-2">
                        <input
                          type="text"
                          class="form-control"
                          id="twilio"
                          placeholder="Twillio Number"
                          [(ngModel)]="CompanyDetails.Voip.number"
                          name="twilio"
                          (click)="$event.stopPropagation()"
                        />
                      </div>
                    </mat-menu> -->
                  </div>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" [matMenuTriggerFor]="menuOthers"
                      name="inlineRadioOptions" id="others" value="other" [(ngModel)]="CompanyDetails.Voip.voip" />

                    <div class="invalid-feedback"></div>
                    <label class="form-check-label pt-1" for="others">
                      Others
                    </label>
                    <mat-menu #menuOthers="matMenu">
                      <div class="p-2">
                        <input type="text" class="form-control mb-2" id="others" placeholder="Enter Name of Voip"
                          [(ngModel)]="CompanyDetails.Voip.voip" value="other" readonly="true"
                          (click)="$event.stopPropagation()" />
                        <input type="text" class="form-control" id="others" name="number" placeholder="Voip Number"
                          [(ngModel)]="CompanyDetails.Voip.number" (click)="$event.stopPropagation()" />
                      </div>
                    </mat-menu>
                  </div>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="inlineRadioOptions" id="NoVOIP" value="NoVOIP"
                      [(ngModel)]="CompanyDetails.Voip.voip" />

                    <div class="invalid-feedback"></div>
                    <label class="form-check-label pt-1" for="NoVOIP">
                      No VOIP
                    </label>

                  </div>
                </div>
              </div>
              <div class="form-group col-md-3">
                <label for="startDate">Subscription Start Date</label>
                <input type="date" class="form-control" id="startDate"
                  [(ngModel)]="CompanyDetails.SubscriptionStartDate" (change)="onChnageSubscriptionStartDate($event)"
                  name="startDate" required />
                <div class="valid-feedback">Valid.</div>
                <div class="invalid-feedback">Please enter a number</div>
              </div>
              <div class="form-group col-md-3">
                <label for="endDate">Subscription End Date</label>
                <input type="date" class="form-control" [min]="minSubscriptionEndDate" id="endDate"
                  [(ngModel)]="CompanyDetails.SubscriptionEndDate" name="endDate" required />
                <div class="valid-feedback">Valid.</div>
                <div class="invalid-feedback">Please enter a number</div>
              </div>
            </div>

            <div class="header-lg mb-2 mt-3">Company Address</div>
            <div class="form-row">
              <div class="form-group col-md-6">
                <label for="faltNo">Flat number/Building No.</label>
                <input type="input" class="form-control" id="faltNo" [(ngModel)]="CompanyDetails.FaltNo" name="faltNo"
                  required />
                <div class="valid-feedback">Valid.</div>
                <div class="invalid-feedback">Please enter a valid details.</div>
              </div>
              <div class="form-group col-md-6">
                <label for="address">Full Address</label>
                <input type="input" class="form-control" id="address" [(ngModel)]="CompanyDetails.Address"
                  name="address" required />
                <div class="valid-feedback">Valid.</div>
                <div class="invalid-feedback">Please enter a valid details.</div>
              </div>
              <div class="form-group col-md-6">
                <label for="city">City</label>
                <input type="text" class="form-control" id="city" [(ngModel)]="CompanyDetails.City" name="city"
                  required />
                <div class="valid-feedback">Valid.</div>
                <div class="invalid-feedback">Please enter a city</div>
              </div>
              <div class="form-group col-md-6">
                <label for="state">Pin/zip code </label>
                <input type="input" class="form-control" id="state" name="state" pattern="^[0-9]*$"
                  [(ngModel)]="CompanyDetails.Zip" required />
                <div class="valid-feedback">Valid.</div>
                <div class="invalid-feedback">Please enter a Pin/Zip Number</div>
              </div>
              <div class="form-group col-md-6">
                <label for="landmark">Landmark </label>
                <input type="input" class="form-control" id="landmark" name="landmark"
                  [(ngModel)]="CompanyDetails.LandMark" required />
                <div class="valid-feedback">Valid.</div>
                <div class="invalid-feedback">Please enter a Landmark</div>
              </div>
              <div class="form-group col-md-6">
                <label for="country">Country</label>
                <input type="input" class="form-control" id="country" name="country"
                  [(ngModel)]="CompanyDetails.Country" required />
                <div class="valid-feedback">Valid.</div>
                <div class="invalid-feedback">Please enter a Country</div>
              </div>
            </div>

            <!-- Upload Docs -->
            <div class="header-lg mb-2  mt-2">Upload Documents</div>

            <div class="form-row">
              <div class="form-group col-md-6">
                <div class="py-3">
                  <table class="table table-md doc-table">
                    <tr>
                      <th style="width: 50px" nowrap>S No.</th>
                      <th nowrap>Document Name</th>
                      <th nowrap class="text-center">Actions</th>
                    </tr>
                    <tr>
                      <td>1</td>
                      <td>
                        <img src="../../../assets/img/doc-grey-1.svg" class="img11 mr-2"
                          *ngIf="companyRegistDocuments.length <= 0" />
                        <img src="../../../assets/img/documents_submitting.svg" class="img11 mr-2"
                          *ngIf="companyRegistDocuments.length > 0" />

                        Company Registration Document
                      </td>
                      <td nowrap class="text-center">
                        <div class="d-flex justify-content-center">
                          <div>
                            <button style="background: transparent;"
                              [disabled]="!companyRegistDocuments && companyRegistDocuments.length==0">
                              <img src="../../../assets/img/file-download.svg" class="img11 mr-2"
                                (click)="downLoadDocument(companyRegistDocuments, 'companyRegistDocuments')" />
                            </button>
                          </div>
                          <div (click)="filesInput1.click()">
                            <input type="file" #filesInput1 id="filesInput1" (change)="
                                getFileDetails($event, 'companyRegistDocuments')
                              " style="display: none" />

                            <img src="../../../assets/img/file-upload.svg" class="img11 mr-2"
                              style="cursor: pointer;" />
                          </div>
                          <div (click)="deleteFileDetails('companyRegistDocuments')">
                            <img src="../../../assets/images/delete.svg" style="cursor: pointer;" class="img11" />
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>2</td>
                      <td>
                        <img src="../../../assets/img/doc-grey-1.svg" class="img11 mr-2"
                          *ngIf="companyPanDocument.length <= 0" />
                        <img src="../../../assets/img/documents_submitting.svg" class="img11 mr-2"
                          *ngIf="companyPanDocument.length > 0" />
                        Company Pan Number
                      </td>
                      <td class="text-center">
                        <div class="d-flex justify-content-center">
                          <div>
                            <button style="background: transparent;"
                              [disabled]="!companyPanDocument && companyPanDocument.length==0">
                              <img src="../../../assets/img/file-download.svg" class="img11 mr-2"
                                (click)="downLoadDocument(companyPanDocument, 'companyPanDocument')" />
                            </button>
                          </div>
                          <div>
                            <input type="file" #filesInput2 id="filesInput2" (change)="
                                getFileDetails($event, 'companyPanDocument')
                              " style="display: none" />
                            <img src="../../../assets/img/file-upload.svg" class="img11 mr-2" style="cursor: pointer;"
                              (click)="filesInput2.click()" />
                          </div>
                          <div (click)="deleteFileDetails('companyPanDocument')">
                            <img src="../../../assets/images/delete.svg" style="cursor: pointer;" class="img11" />
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>3</td>
                      <td>
                        <img src="../../../assets/img/doc-grey-1.svg" class="img11 mr-2"
                          *ngIf="companyTanDocument.length <= 0" />
                        <img src="../../../assets/img/documents_submitting.svg" class="img11 mr-2"
                          *ngIf="companyTanDocument.length > 0" />
                        Company Tan Number
                      </td>
                      <td class="text-center">
                        <div class="d-flex justify-content-center">
                          <div>
                            <button style="background: transparent;"
                              [disabled]="!companyTanDocument && companyTanDocument.length==0">
                              <img src="../../../assets/img/file-download.svg" class="img11 mr-2"
                                (click)="downLoadDocument(companyTanDocument, 'companyTanDocument')" />
                            </button>
                          </div>
                          <div>
                            <input type="file" #filesInput3 id="filesInput3" (change)="
                                getFileDetails($event, 'companyTanDocument')
                              " style="display: none" />
                            <img src="../../../assets/img/file-upload.svg" class="img11 mr-2" style="cursor: pointer;"
                              (click)="filesInput3.click()" />
                          </div>
                          <div (click)="deleteFileDetails('companyTanDocument')">
                            <img src="../../../assets/images/delete.svg" style="cursor: pointer;" class="img11" />
                          </div>
                        </div>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
              <div class="form-group col-md-6 doc_upload_inst">
                <div class="header-12 pl-3 mb-2">Documents Checklist</div>
                <ul class="mt-5">
                  <li>
                    <img src="../../../assets/img/ticker-red.svg" class="img12 mr-2"
                      *ngIf="companyRegistDocuments.length <= 0" />
                    <img src="../../../assets/img/ticker-green.svg" class="img12 mr-2"
                      *ngIf="companyRegistDocuments.length > 0" />
                    Company Registration Document
                  </li>
                  <li>
                    <img src="../../../assets/img/ticker-red.svg" class="img12 mr-2"
                      *ngIf="companyPanDocument.length <= 0" />
                    <img src="../../../assets/img/ticker-green.svg" class="img12 mr-2"
                      *ngIf="companyPanDocument.length > 0" />
                    Company Pan Number
                  </li>
                  <li>
                    <img src="../../../assets/img/ticker-red.svg" class="img12 mr-2"
                      *ngIf="companyTanDocument.length <= 0" />
                    <img src="../../../assets/img/ticker-green.svg" class="img12 mr-2"
                      *ngIf="companyTanDocument.length > 0" />
                    Company Tan Number
                  </li>
                </ul>
              </div>
            </div>
            <!-- <div class="header-lg mb-4 mt-2">Theme Configuration</div> -->

            <!-- <div class="row">
              <div class="col-md-4">
                <div class="col-md-12 header-12 py-2" style="padding-left: 0;">
                  Company logo with name
                </div>
                <div class="upload-logo-block float-left mr-4">
                  <div class="p-4 d-flex">

                    <label for="filesInput4"></label>
                    <input type="file" #filesInput4 id="filesInput4" name="filesInput4" class="form-control"
                      (change)="getFileDetails($event, 'companyLogo')" style="display: none" required />

                    <div class="l-text d-flex align-items-center justify-content-center" *ngIf="!previewCompanyURL">
                      Upload Company Logo <br>with text</div>
                    <div class="l-text d-flex align-items-center justify-content-center" style="width: 100%;"
                      *ngIf="previewCompanyURL"><img id="previewLogo" [src]="previewCompanyURL" height="40px" /></div>
                    <div class="ml-auto">
                      <div class="img-circle d-flex align-items-center justify-content-center"
                        (click)="filesInput4.click()" for="filesInput4">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 22 22">
                          <g id="Group_775" data-name="Group 775" transform="translate(-171.243 -61.66)">
                            <path id="Path_886" class="csvg" data-name="Path 886"
                              d="M177.243,76.419l.953.953,5.131-5.124V84.531h1.352V72.249l5.131,5.124.953-.953L184,69.66Z"
                              transform="translate(-1.944 -2.592)" />
                            <path id="Path_887" class="csvg" data-name="Path 887"
                              d="M176.595,67.716v-2.7h13.519v2.7h1.352v-2.7a1.352,1.352,0,0,0-1.352-1.352H176.595a1.352,1.352,0,0,0-1.352,1.352v2.7Z"
                              transform="translate(-1.296 -0.648)" />
                            <rect id="_Transparent_Rectangle_" data-name=" Transparent Rectangle " width="22"
                              height="22" transform="translate(171.243 61.66)" fill="none" />
                          </g>
                        </svg>
                      </div>
                    </div>
                  </div>
                  <hr>
                  <div class="px-4 py-2 d-flex align-items-center ">
                    <div class="mr-3 mt-1">
                      <img src="../../../assets/images/info.svg" class="img24 hand" ngbTooltip="Information"
                        placement="top" tooltipClass="my-custom-class" />
                    </div>
                    <div class="l-text2">
                      Logo size should be between <br>300px (width) x 80px (height).
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="col-md-12 header-12 py-2" style="padding-left: 0;">
                  Company logo
                </div>
                <div class="upload-logo-block float-left mr-4">
                  <div class="p-4 d-flex">

                    <label for="filesInput6"></label>
                    <input type="file" #filesInput6 id="filesInput6" name="filesInput6" class="form-control"
                      (change)="getFileDetails($event, 'companylogowithoutname')" style="display: none" required />

                    <div class="l-text d-flex align-items-center justify-content-center"
                      *ngIf="!previewCompanyLogoWithoutNameURL">Upload Company Logo</div>
                    <div class="l-text d-flex align-items-center justify-content-center" style="width: 100%;"
                      *ngIf="previewCompanyLogoWithoutNameURL"><img id="previewCompanyLogoWithoutNameURL"
                        [src]="previewCompanyLogoWithoutNameURL" height="40px" /></div>

                    <div class="ml-auto">
                      <div class="img-circle d-flex align-items-center justify-content-center"
                        (click)="filesInput6.click()" for="filesInput6">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 22 22">
                          <g id="Group_775" data-name="Group 775" transform="translate(-171.243 -61.66)">
                            <path id="Path_886" class="csvg" data-name="Path 886"
                              d="M177.243,76.419l.953.953,5.131-5.124V84.531h1.352V72.249l5.131,5.124.953-.953L184,69.66Z"
                              transform="translate(-1.944 -2.592)" />
                            <path id="Path_887" class="csvg" data-name="Path 887"
                              d="M176.595,67.716v-2.7h13.519v2.7h1.352v-2.7a1.352,1.352,0,0,0-1.352-1.352H176.595a1.352,1.352,0,0,0-1.352,1.352v2.7Z"
                              transform="translate(-1.296 -0.648)" />
                            <rect id="_Transparent_Rectangle_" data-name=" Transparent Rectangle " width="22"
                              height="22" transform="translate(171.243 61.66)" fill="none" />
                          </g>
                        </svg>
                      </div>
                    </div>
                  </div>
                  <hr>
                  <div class="px-4 py-2 d-flex align-items-center ">
                    <div class="mr-3 mt-1">
                      <img src="../../../assets/images/info.svg" class="img24 hand" ngbTooltip="Information"
                        placement="top" tooltipClass="my-custom-class" />
                    </div>
                    <div class="l-text2">
                      Logo size should be between <br> 80px (width) x 80px (height)
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="col-md-12 header-12 py-2" style="padding-left: 0;">
                  Favicon logo
                </div>
                <div class="upload-logo-block float-left mr-4">
                  <div class="p-4 d-flex">

                    <label for="filesInput5"></label>
                    <input type="file" #filesInput5 id="filesInput5" name="filesInput5" class="form-control"
                      (change)="getFileDetails($event, 'favIconLogo')" style="display: none" required />

                    <div class="l-text d-flex align-items-center justify-content-center" *ngIf="!previewFaviconURL">
                      Upload Favicon</div>
                    <div class="l-text d-flex align-items-center justify-content-center" style="width: 100%;"
                      *ngIf="previewFaviconURL"><img id="previewFeviconLogo" [src]="previewFaviconURL" height="40px" />
                    </div>

                    <div class="ml-auto">
                      <div class="img-circle d-flex align-items-center justify-content-center"
                        (click)="filesInput5.click()">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 22 22">
                          <g id="Group_775" data-name="Group 775" transform="translate(-171.243 -61.66)">
                            <path id="Path_886" class="csvg" data-name="Path 886"
                              d="M177.243,76.419l.953.953,5.131-5.124V84.531h1.352V72.249l5.131,5.124.953-.953L184,69.66Z"
                              transform="translate(-1.944 -2.592)" />
                            <path id="Path_887" class="csvg" data-name="Path 887"
                              d="M176.595,67.716v-2.7h13.519v2.7h1.352v-2.7a1.352,1.352,0,0,0-1.352-1.352H176.595a1.352,1.352,0,0,0-1.352,1.352v2.7Z"
                              transform="translate(-1.296 -0.648)" />
                            <rect id="_Transparent_Rectangle_" data-name=" Transparent Rectangle " width="22"
                              height="22" transform="translate(171.243 61.66)" fill="none" />
                          </g>
                        </svg>
                      </div>
                    </div>
                  </div>
                  <hr>
                  <div class="px-4 py-2 d-flex align-items-center">
                    <div class="mr-3 mt-1">
                      <img src="../../../assets/images/info.svg" class="img24 hand" ngbTooltip="Information"
                        placement="top" tooltipClass="my-custom-class" />
                    </div>
                    <div class="l-text2">
                      Favicon size should be between <br> 16px x 16px with .ico format
                    </div>
                  </div>
                </div>
              </div>
            </div> -->

            <!-- <div class="row my-3">
              <div class="col-md-3">
                <div class="card p-3 text-center">
                  <label for="filesInput4"></label>
                  <input type="file" #filesInput4 id="filesInput4" name="filesInput4" class="form-control" (change)="getFileDetails($event, 'companyLogo')"
                    style="display: none" required/>
                    
                <div class="valid-feedback">Valid.</div>
                <div class="invalid-feedback">Please upload your logo</div>
                  <div class="btn btn-link btn-sm ml-3" (click)="filesInput4.click()" for="filesInput4">
                    <img src="../../../assets/img/upload2.svg" class="img11" />
                  </div>
                  <div class="header-12" *ngIf="companyLogo.length <= 0">
                    Upload Company Logo
                  </div>
                  <div class="header-12" *ngIf="companyLogo.length > 0" style="color: green; font-weight: bold">
                    {{ companyLogo[0].name }}
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="card p-3 text-center">
                  <label for="filesInput5"></label>
                  <input type="file" #filesInput5 id="filesInput5" name="filesInput5" class="form-control" (change)="getFileDetails($event, 'favIconLogo')"
                    style="display: none" required/>
                    <div class="valid-feedback">Valid.</div>
                <div class="invalid-feedback">Please upload your favicon</div>
                  <div class="btn btn-link btn-sm ml-3" (click)="filesInput5.click()">
                    <img src="../../../assets/img/upload2.svg" class="img11" />
                  </div>
                  <div class="header-12" *ngIf="favIconLogo.length <= 0">
                    Upload Favicon
                  </div>
                  <div class="header-12" *ngIf="favIconLogo.length > 0" style="color: green; font-weight: bold">
                    {{ favIconLogo[0].name }}
                  </div>
                </div>
              </div>
            </div> -->

            <div class="row">
              <div class="col-md-12">
                <div class="row">
                  <div class="col-md-12 header-md py-3">
                    <div>
                      <!-- For custom theme -->
                    </div>
                  </div>
                </div>
                <div class="row">
                  <!--<div class="col-md-3 col-lg-3">
                    <div class="row">
                      <div class="col-md-12 header-12 py-2">
                        Sidebar Icons color
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6">
                        <div class="color-picker-block">
                          <div class="d-flex">
                            <input class="form-control c-box mr-2" type="color" #iconcolor id="iconcolor"
                              name="iconcolor" value="#000000" [(ngModel)]="
                                CompanyDetails.Theme.sidebar_topbar_icon_color
                              " required />

                            <div>
                              <span class="form-control form-txt">{{
                                CompanyDetails.Theme.sidebar_topbar_icon_color
                                }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                   <div class="col-md-3 col-lg-3">
                    <div class="row">
                      <div class="col-md-12 header-12 py-2">
                        Button color
                      </div>
                     
                      <div class="col-md-4">
                        <div class="color-picker-block">
                          <div class="d-flex">
                            <input class="c-box mr-2" type="color" id="favcolor" name="favcolor" value="#000000"
                              [(ngModel)]="
                                CompanyDetails.Theme.table_popup_button_color
                              " />
                            <div>
                              <span class="form-control form-txt">{{
                                CompanyDetails.Theme.table_popup_button_color
                                }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3 col-lg-3">
                    <div class="row">
                      <div class="col-md-12 header-12 py-2">
                        Link color
                      </div>
                      <div class="col-md-4">
                        <div class="color-picker-block">
                          <div class="d-flex">
                            <input class="c-box mr-2" type="color" id="favcolor" name="favcolor" value="#000000"
                              [(ngModel)]="
                                CompanyDetails.Theme.table_popup_links_color
                              " />
                            <div>
                              <span class="form-control form-txt">{{
                                CompanyDetails.Theme.table_popup_links_color
                                }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> -->
                  <div class="col-md-12 d-flex justify-content-end align-items-end mt-4">
                    <div class="pl-3">
                      <button class="btn btn-light btn-sm mr-2" type="button"
                        (click)="resetCompanyForm()">Reset</button>
                      <button class="btn btn-secondary btn-sm ml-4" data-toggle="modal" data-target="#previewmodal"
                        type="button" (click)="getPreviewDetails(companyLogo[0])">
                        Preview
                      </button>

                      <button type="submit" class="btn btn-secondary btn-sm ml-4" (click)="CreateCompany(form)">
                        Create Company
                      </button>
                      <button matStepperNext class="btn btn-secondary btn-sm ml-4" [disabled]="!companyCreated"
                        type="button">
                        Next
                      </button>
                      <!-- <button matStepperNext class="btn btn-secondary btn-sm ml-4" 
                        type="button">
                        Next
                      </button> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal fade" id="previewmodal" tabindex="-1" role="dialog"
              aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
              <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLongTitle">
                      Preview Theme
                    </h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div class="modal-body">
                    <div class="pre-ctr d-flex">
                      <div class="pre-sidebar d-flex flex-column pre-rel">
                        <div class="pre-logo" *ngIf="companyLogo[0]">
                          <img id="previewLogo" [src]="previewCompanyURL" weight="50%" />
                        </div>
                        <div class="pre-navbar">
                          <div class="d-flex align-items-center px-2 py-2 pre-navbar-item">
                            <div class="img18 mr-1">
                              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 32 32">
                                <g id="Group_616" data-name="Group 616" transform="translate(-42.312 1.171)">
                                  <path class="previewcsvg" id="Path_792" data-name="Path 792"
                                    d="M68.312,4.829v4h-20v-4h20m0-2h-20a2,2,0,0,0-2,2v4a2,2,0,0,0,2,2h20a2,2,0,0,0,2-2v-4A2,2,0,0,0,68.312,2.829Z" />
                                  <path class="previewcsvg" id="Path_793" data-name="Path 793"
                                    d="M52.312,14.829v10h-4v-10h4m0-2h-4a2,2,0,0,0-2,2v10a2,2,0,0,0,2,2h4a2,2,0,0,0,2-2v-10A2,2,0,0,0,52.312,12.829Z" />
                                  <path class="previewcsvg" id="Path_794" data-name="Path 794"
                                    d="M68.312,14.829v10h-10v-10h10m0-2h-10a2,2,0,0,0-2,2v10a2,2,0,0,0,2,2h10a2,2,0,0,0,2-2v-10A2,2,0,0,0,68.312,12.829Z" />
                                  <rect id="Rectangle_853" data-name="Rectangle 853" width="32" height="32"
                                    transform="translate(42.312 -1.171)" fill="none" />
                                </g>
                              </svg>
                            </div>
                            <div>University</div>
                          </div>

                          <div class="d-flex align-items-center px-2 py-2 pre-navbar-item">
                            <div class="img18 mr-1">
                              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 32 32">
                                <g id="Group_793" data-name="Group 793" transform="translate(-306.587 -61.66)">
                                  <path id="Path_891" data-name="Path 891"
                                    d="M333.587,86.66h-6a3,3,0,0,0-3,3v2h2v-2a1,1,0,0,1,1-1h6a1,1,0,0,1,1,1v2h2v-2A3,3,0,0,0,333.587,86.66Z"
                                    class="previewcsvg" />
                                  <path id="Path_892" data-name="Path 892"
                                    d="M326.587,81.66a4,4,0,1,0,4-4A4,4,0,0,0,326.587,81.66Zm6,0a2,2,0,1,1-2-2A2,2,0,0,1,332.587,81.66Z"
                                    class="previewcsvg" />
                                  <path id="Path_893" data-name="Path 893"
                                    d="M312.587,82.66v-1h-2v1a7,7,0,0,0,7,7h3v-2h-3A5,5,0,0,1,312.587,82.66Z"
                                    class="previewcsvg" />
                                  <rect id="Rectangle_903" data-name="Rectangle 903" width="7" height="2"
                                    transform="translate(325.587 71.66)" class="previewcsvg" />
                                  <rect id="Rectangle_904" data-name="Rectangle 904" width="10" height="2"
                                    transform="translate(325.587 67.66)" class="previewcsvg" />
                                  <rect id="Rectangle_905" data-name="Rectangle 905" width="10" height="2"
                                    transform="translate(325.587 63.66)" class="previewcsvg" />
                                  <path id="Path_894" data-name="Path 894"
                                    d="M317.587,72.66h-6a3,3,0,0,0-3,3v2h2v-2a1,1,0,0,1,1-1h6a1,1,0,0,1,1,1v2h2v-2A3,3,0,0,0,317.587,72.66Z"
                                    class="previewcsvg" />
                                  <path id="Path_895" data-name="Path 895"
                                    d="M314.587,71.66a4,4,0,1,0-4-4A4,4,0,0,0,314.587,71.66Zm0-6a2,2,0,1,1-2,2A2,2,0,0,1,314.587,65.66Z"
                                    class="previewcsvg" />
                                  <rect id="_Transparent_Rectangle_" data-name=" Transparent Rectangle " width="32"
                                    height="32" transform="translate(306.587 61.66)" fill="none" />
                                </g>
                              </svg>
                            </div>
                            <div>Referrals</div>
                          </div>

                          <div class="d-flex align-items-center px-2 py-2 pre-navbar-item">
                            <div class="img18 mr-1">
                              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 32 32">
                                <g id="Group_792" data-name="Group 792" transform="translate(-9103 -17228)">
                                  <path id="Path_896" data-name="Path 896"
                                    d="M360.412,65.66h-2v26a2,2,0,0,0,2,2h26v-2h-26Z"
                                    transform="translate(8746.588 17164.34)" class="previewcsvg" />
                                  <path id="Path_897" data-name="Path 897"
                                    d="M386.412,79.66h-7v2H383l-7.59,7.59-4.29-4.3a1,1,0,0,0-1.415-.006l-.005.006-7.29,7.3,1.41,1.41,6.59-6.59,4.29,4.3a1,1,0,0,0,1.414,0l.006,0,8.29-8.3v3.59h2Z"
                                    transform="translate(8743.588 17163.34)" class="previewcsvg" />
                                  <rect id="_Transparent_Rectangle_" data-name=" Transparent Rectangle " width="32"
                                    height="32" transform="translate(9103 17228)" fill="none" />
                                </g>
                              </svg>
                            </div>
                            <div>Bi Analytics</div>
                          </div>

                          <div class="d-flex align-items-center px-2 py-2 pre-navbar-item">
                            <div class="img18 mr-1">
                              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 32 32">
                                <g id="Group_788" data-name="Group 788" transform="translate(-466.919 -61.66)">
                                  <path id="Path_901" data-name="Path 901"
                                    d="M496.919,69.66h-4.1a5,5,0,0,0-9.8,0h-14.1v2h14.1a5,5,0,0,0,9.8,0h4.1Zm-9,4a3,3,0,1,1,3-3A2.946,2.946,0,0,1,487.919,73.66Z"
                                    class="previewcsvg" />
                                  <path id="Path_902" data-name="Path 902"
                                    d="M468.919,85.66h4.1a5,5,0,0,0,9.8,0h14.1v-2h-14.1a5,5,0,0,0-9.8,0h-4.1Zm9-4a3,3,0,1,1-3,3A2.946,2.946,0,0,1,477.919,81.66Z"
                                    class="previewcsvg" />
                                  <rect id="_Transparent_Rectangle_" data-name=" Transparent Rectangle " width="32"
                                    height="32" transform="translate(466.919 61.66)" fill="none" />
                                </g>
                              </svg>
                            </div>
                            <div>Administration</div>
                          </div>
                        </div>
                        <div class="clr"></div>
                        <div class="pre-version p-2 align-items-end pre-abs" style="bottom: 0; left: 0; right: 0">
                          VERSION 1.1.0
                        </div>
                      </div>
                      <div class="pre-content d-flex flex-column">
                        <div class="pre-topbar p-3 d-flex">
                          <div class="img12 mr-2">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 72.23">
                              <defs></defs>
                              <g id="Layer_2" data-name="Layer 2">
                                <g id="Layer_1-2" data-name="Layer 1">
                                  <path class="previewcsvg"
                                    d="M0,35c.28-.08.25-.36.34-.55a4.15,4.15,0,0,1,3.74-2.54c.32,0,.65,0,1,0H95a7.63,7.63,0,0,1,1.65.1,4.16,4.16,0,0,1-.69,8.25c-.33,0-.65,0-1,0q-44.65,0-89.33,0C3,40.31,1.06,39.67,0,37.16Z" />
                                  <path class="cls-1"
                                    d="M0,67c.28-.14.24-.44.35-.66A4,4,0,0,1,4.11,63.9c2.44,0,4.88,0,7.32,0H95.38c2.41,0,4,1.12,4.5,3.21a4.13,4.13,0,0,1-3.5,5,15.17,15.17,0,0,1-1.65.06q-44.37,0-88.74,0c-2.64,0-4.81-.42-6-3Z" />
                                  <path class="previewcsvg"
                                    d="M0,3.17c.28-.17.25-.5.38-.75A4.05,4.05,0,0,1,3.9,0c.26,0,.52,0,.78,0H95.29c2.52,0,4.14,1.17,4.62,3.32a4.15,4.15,0,0,1-3.64,4.95c-.45,0-.91,0-1.37,0q-44.76,0-89.53,0C2.79,8.34,1,7.58,0,5.13Z" />
                                </g>
                              </g>
                            </svg>
                          </div>
                          <div class="pre-header-text">Lead Dashboard</div>

                          <div class="pre-topbar-right-set ml-auto d-flex">
                            <div class="mr-2">
                              <button class="btn btn-secondary btn-sm"
                                [ngStyle]="{ 'background-color': CompanyDetails.Theme.table_popup_button_color,'border-color':CompanyDetails.Theme.table_popup_button_color }"
                                type="button">
                                Create Ticket
                              </button>
                            </div>
                            <div class="img12 mr-3">
                              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 49.62 49.62">
                                <g id="Layer_2" data-name="Layer 2">
                                  <g id="Layer_1-2" data-name="Layer 1">
                                    <path id="ic_call_24px" data-name="ic call 24px" class="previewcsvg"
                                      d="M10,21.48A41.73,41.73,0,0,0,28.15,39.64l6.06-6.06A2.74,2.74,0,0,1,37,32.92a31.67,31.67,0,0,0,9.85,1.57,2.75,2.75,0,0,1,2.75,2.76h0v9.62a2.75,2.75,0,0,1-2.75,2.75A46.86,46.86,0,0,1,0,2.76,2.75,2.75,0,0,1,2.76,0h9.65a2.75,2.75,0,0,1,2.75,2.76,31.65,31.65,0,0,0,1.57,9.84A2.74,2.74,0,0,1,16,15.41Z" />
                                  </g>
                                </g>
                              </svg>
                            </div>
                            <div class="img12 mr-3">
                              <svg id="Layer_2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 319.16 351.27">
                                <g id="Layer_1-2">
                                  <path class="previewcsvg"
                                    d="M263.24,303.41c-12.97,0-25.93,.07-38.9-.06-2.37-.02-3.42,.6-4.15,2.93-8.55,27.07-32.94,45.07-60.78,45-27.7-.08-52.13-18.12-60.48-44.89-.81-2.6-2.03-3.04-4.45-3.03-25.68,.08-51.36,.09-77.04,.04-11.22-.02-18.45-7.7-17.33-18.17,.36-3.35,1.98-6.1,3.94-8.72,7.78-10.35,15.55-20.72,23.34-31.07,13.65-18.11,20.36-38.56,20.45-61.19,.08-20.07-.24-40.15,.15-60.21,1.04-52.22,41.07-98.01,92.61-106.21,2.31-.37,3.01-1.13,3.19-3.47C144.44,6.17,151.52-.09,159.66,0c8.08,.09,14.97,6.21,15.6,14.22,.2,2.48,.97,3.25,3.43,3.64,45.64,7.15,83.75,45.48,90.96,91.19,1.09,6.92,1.57,13.85,1.56,20.84,0,17.95,0,35.9,0,53.86,.01,22.91,6.76,43.57,20.62,61.88,7.75,10.23,15.35,20.56,23.12,30.77,4.3,5.65,5.62,11.66,2.42,18.13-3.23,6.53-8.97,8.92-16,8.89-12.72-.04-25.43-.01-38.15-.01Zm7.93-32.09c-1.85-2.48-3.32-4.47-4.81-6.45-17.52-23.33-26.72-49.52-26.91-78.75-.12-18.7-.03-37.4-.04-56.1,0-5.24-.4-10.44-1.22-15.63-6.88-43.92-51.22-74.31-94.66-64.84-37.83,8.25-63.56,39.88-63.84,78.68-.14,18.45-.1,36.9-.01,55.35,.15,30.45-9.11,57.72-27.46,81.97-1.38,1.82-2.73,3.66-4.3,5.77h223.25Zm-84.35,32.39h-54.62c6.93,10.42,16.14,15.8,28.14,15.51,11.33-.28,20.06-5.58,26.48-15.51Z" />
                                </g>
                              </svg>
                            </div>
                            <div class="img12">
                              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
                                <g id="Layer_2" data-name="Layer 2">
                                  <g id="Layer_1-2" data-name="Layer 1">
                                    <path class="previewcsvg"
                                      d="M46.88,0h6.25c.25.05.49.12.75.16,2.56.4,5.17.64,7.69,1.21a49.91,49.91,0,0,1,37.67,40c.31,1.82.51,3.66.76,5.49v6.25a7.37,7.37,0,0,0-.16.76c-.34,2.31-.56,4.65-1,6.94-4,19.38-20.59,35.24-40.1,38.41-1.85.3-3.72.52-5.58.77H46.88c-.22,0-.44-.12-.66-.16-2.28-.33-4.58-.55-6.84-1C20,95,3.93,78.28.78,58.81.48,56.92.26,55,0,53.12V46.87c.32-2.21.56-4.44,1-6.64A50,50,0,0,1,41.29.77C43.14.46,45,.25,46.88,0ZM20,82.21A31.59,31.59,0,0,1,38.08,62c-5.63-3.94-9-9.2-9.7-16a21,21,0,0,1,6.16-17.54,21.67,21.67,0,0,1,30.51-.4,20.91,20.91,0,0,1,6.66,15.12c.14,8-3.32,14.13-9.75,18.79A31.6,31.6,0,0,1,80,82.23,44.09,44.09,0,1,0,7.93,36.71,43.64,43.64,0,0,0,20,82.21ZM50,94.15a44.51,44.51,0,0,0,24-7.1,1.41,1.41,0,0,0,.69-1.83,25.34,25.34,0,0,0-49.47.23A1.24,1.24,0,0,0,25.87,87,44.35,44.35,0,0,0,50,94.15Zm0-66.33A15.89,15.89,0,1,0,65.88,43.71,15.9,15.9,0,0,0,50,27.82Z" />
                                  </g>
                                </g>
                              </svg>
                            </div>
                          </div>
                        </div>
                        <div class="pre-content-section m-2 p-3">
                          <div class="d-flex">
                            <div class="pre-content-card text-center py-3 px-1 mr-3">
                              <p>Total Students</p>
                              <div class="pre-card-count">545</div>
                            </div>

                            <div class="pre-content-card text-center py-3 px-1 mr-3">
                              <p>Completed Students</p>
                              <div class="pre-card-count">305</div>
                            </div>

                            <div class="pre-content-card text-center py-3 px-1 mr-3">
                              <p>Validated Students</p>
                              <div class="pre-card-count">100</div>
                            </div>

                            <div class="pre-content-card text-center py-3 px-1 mr-3">
                              <p>Documents Submitted</p>
                              <div class="pre-card-count">45</div>
                            </div>
                          </div>
                          <div class="mt-3">
                            <div class="d-flex">
                              <div class="pre-header-text">
                                Total Applications
                              </div>
                              <div class="ml-auto"></div>
                            </div>
                          </div>
                          <div class="mt-1">
                            <div class="d-flex p-1 pre-table-search">
                              <div>
                                <input type="text" class="pre-seach-field" placeholder="Search.." />
                              </div>
                            </div>
                          </div>

                          <div class="mt-1">
                            <div class="table-responsive">
                              <table class="table table-md pre-data-table">
                                <tr
                                  [ngStyle]="{ 'background-color': CompanyDetails.Theme.table_popup_button_color,'border-color':CompanyDetails.Theme.table_popup_button_color,'color': 'white' }">
                                  <th>Created Date</th>
                                  <th>Student Name</th>
                                  <th>Assign To</th>
                                  <th>Enrollment Term</th>
                                  <th>Doc Status</th>
                                </tr>
                                <tr>
                                  <td>23 Sep, 2022 10:10</td>
                                  <td>
                                    <a class="pre-link">Krishna Jakkamsetti</a>
                                  </td>
                                  <td>Priyanka</td>
                                  <td>G4</td>
                                  <td>Pending</td>
                                </tr>
                                <tr>
                                  <td>23 Sep, 2022 10:10</td>
                                  <td>
                                    <a class="pre-link">Krishna Jakkamsetti</a>
                                  </td>
                                  <td>Priyanka</td>
                                  <td>G4</td>
                                  <td>Pending</td>
                                </tr>
                                <tr>
                                  <td>23 Sep, 2022 10:10</td>
                                  <td>
                                    <a href="#" class="pre-link">Krishna Jakkamsetti</a>
                                  </td>
                                  <td>Priyanka</td>
                                  <td>G4</td>
                                  <td>Pending</td>
                                </tr>
                                <tr>
                                  <td>23 Sep, 2022 10:10</td>
                                  <td>
                                    <a class="pre-link">Krishna Jakkamsetti</a>
                                  </td>
                                  <td>Priyanka</td>
                                  <td>G4</td>
                                  <td>Pending</td>
                                </tr>
                                <tr>
                                  <td>23 Sep, 2022 10:10</td>
                                  <td>
                                    <a class="pre-link">Krishna Jakkamsetti</a>
                                  </td>
                                  <td>Priyanka</td>
                                  <td>G4</td>
                                  <td>Pending</td>
                                </tr>
                                <tr>
                                  <td>23 Sep, 2022 10:10</td>
                                  <td>
                                    <a class="pre-link">Krishna Jakkamsetti</a>
                                  </td>
                                  <td>Priyanka</td>
                                  <td>G4</td>
                                  <td>Pending</td>
                                </tr>
                              </table>
                            </div>
                          </div>
                        </div>
                        <div class="pre-footer px-3 py-1 align-items-end">
                          Powered by <a href="#">Avantao</a>
                          <div class="clr"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </form>
      </mat-step>

      <mat-step *ngIf="twilioSelected" label="Select Number">

        <ng-template matStepLabel>Select Number</ng-template>
        <div>
          <div class="text-left mt-2" style="padding: 0 6px;">
            <div *ngIf="countryList?.length>0">
              <!-- <label class="form-label1">Projects</label> -->
              <select (ngModelChange)="selectCountry($event)" [(ngModel)]="selectedCountryCode" class="select-company"
                style="text-transform: capitalize">
                <option value="0" disabled>
                  Select Country
                </option>
                <!-- <option value="All">
                  All
                </option> -->
                <option *ngFor="let item of countryList" [value]="item['countryCode']">
                  {{ item["country"] }}
                </option>
              </select>
            </div>
          </div>
          <div class="mt-1 ml-3 mr-3">
            <div class="row">
              <div class="pl-2 pr-2 pt-2 mb-3" style="min-height: 300px; width: 100%">
                <div class="table-responsive data-table mb-3">
                  <table class="table table-md wbg table-shadow" style="margin-bottom: 0px; width: 100%">
                    <thead>
                      <tr>
                        <td colspan="6" class="vm">
                          <div class="float-right" style="color: #fff; font-size: 12px">
                            <div class="float-right" style="color: #fff; font-size: 12px">
                              <ngb-pagination [(page)]="page" [pageSize]="pageSize"
                                [collectionSize]="twilioAvailableList.length" class="pagenation-right" [maxSize]="5"
                                [boundaryLinks]="true"></ngb-pagination>
                            </div>

                          </div>
                        </td>
                      </tr>
                    </thead>
                    <tr>
                      <th nowrap>
                        <div class="d-flex">
                          Country Name
                        </div>
                      </th>
                      <th nowrap>
                        <div class="d-flex">
                          Phone Number
                        </div>
                      </th>
                      <th nowrap>
                        Voice

                      </th>
                      <th nowrap>
                        SMS

                      </th>
                      <th nowrap>
                        MMS

                      </th>
                      <th>Actions</th>
                    </tr>

                    <tbody>
                      <tr *ngFor="
                                  let item of twilioAvailableList
                                  | slice: (page - 1) * pageSize:page * pageSize
                                let id = index">
                        <td class="vm">
                          {{ selectedCountry }}
                        </td>
                        <td class="vm">
                          {{ item.phoneNumber?item.phoneNumber:item.phone_number }}
                        </td>
                        <td class="vm">
                          {{ item.capabilities.voice}}
                        </td>
                        <td class="vm">
                          {{ item.capabilities.SMS}}
                        </td>
                        <td class="vm">
                          {{ item.capabilities.MMS}}
                        </td>


                        <td class="text-capitalize vm" nowrap style="padding: 2px">
                          <div class="example-button-row mat-bset d-flex">

                            <button class="btn btn-secondary btn-sm  mr-2"
                              (click)="openConfirmationForm(content1,item)">
                              Select
                            </button>

                          </div>
                        </td>
                      </tr>
                      <tr *ngIf="isLoading ==true">
                        <td colspan="6">
                          <div class="norecords">
                            <img src="../../../../assets/img/waiting.gif" />

                            <div>Loading Data<br />Please wait while we fetch the data for
                              you</div>
                          </div>
                        </td>
                      </tr>
                      <tr *ngIf="noRecords == true">
                        <td colspan="6">
                          <div class="norecords">
                            <img src="../../../../assets/img/nodata.svg" />
                            <div>No records found.<br />
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>

                </div>
              </div>
            </div>
          </div>
        </div>
        <ng-template #content1 let-modal>

          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              Confirmation
            </h5>
            <button type="button" #closeInviteButton id="closeInviteButton" class="close" aria-label="Close"
              (click)="modal.dismiss('Cross click')">
              <span aria-hidden="true" style="font-size: 20px;">&times;</span>
            </button>
            <!-- <button type="button" id="closeAssignButton" #closeAssignButton (click)="closeAssignModal()" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button> -->
          </div>
          <div class="modal-body">
            <p>Are you sure you want to select this number ?</p>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary" matStepperNext (click)="updateCompanyNumber()">Yes</button>
            <button type="button" class="btn btn-secondary" data-dismiss="modal">No</button>
          </div>

        </ng-template>

      </mat-step>

      <mat-step [stepControl]="secondFormGroup" label="Create Project">
        <div class="pt-2 d-flex">
          <div class="ml-auto d-flex">
            <div>
              <img src="../../../assets/images/info.svg" class="img16 mr-2 hand" ngbTooltip="Information"
                placement="top" tooltipClass="my-custom-class" />
            </div>
            <div>
              <img src="../../../assets/images/video-play.svg" class="img16 hand" ngbTooltip="Demo video"
                placement="top" tooltipClass="my-custom-class" />
            </div>
          </div>
        </div>
        <form [formGroup]="secondFormGroup">
          <ng-template matStepLabel>Create Project</ng-template>
          <form class="was-validated" novalidate #form2="ngForm" [ngClass]="{ 'was-validated': form2.submitted }"
            *ngFor="let p of SelectedProjects; let indexOfelement = index">
            <div class="form-row">
              <div class="form-group col-md-6">
                <label for="inputEmail4">Project Name</label>
                <input type="text" class="form-control" id="pname" placeholder="Enter Project Name"
                  [(ngModel)]="p.ProjectName" name="pname" required />
                <div class="valid-feedback">Valid.</div>
                <div class="invalid-feedback">
                  Please enter the project name.
                </div>
              </div>
              <div class="form-group col-md-6">
                <label for="inputEmail4">Project Type</label>
                <select class="form-control" (change)="onChange($event)" [(ngModel)]="p.ProjectType" required>
                  <option value="" disabled>Select project type</option>
                  <option value="{{item.ProjectType}}" *ngFor="let item of ProjectTypeList">{{item.ProjectType}}
                  </option>
                  <option value="other">Other</option>

                </select>

                <div class="valid-feedback">Valid.</div>
                <div class="invalid-feedback">
                  Please enter the project name.
                </div>
              </div>
            </div>
            <div class="form-row" *ngIf="p.ProjectType=='other'">
              <div class="form-group col-md-6">
                <label for="ProjectOther">Other Project Type</label>
                <div class="d-flex">
                  <input type="text" class="form-control" id="ProjectOther" placeholder="Enter Other Project Type"
                    [(ngModel)]="p.ProjectOther" name="ProjectOther" required />

                </div>
              </div>

            </div>
            <div class="form-row" *ngIf="isVoipSelected">
              <div class="form-group col-md-6">
                <label for="inputEmail4">Project telephone number (VoIP)</label>
                <input type="text" [(ngModel)]="p.ProjectTelephoneNumber" class="form-control" id="voipNumber"
                  name="voipNumber" placeholder="" [required]="isVoipSelected?'true':'false'" />
                <div class="valid-feedback">Valid.</div>
                <div class="invalid-feedback">
                  Please enter the voip number.
                </div>
              </div>
              <div class="form-group col-md-6">
                <label for="inputAddress2">Outgoing Number</label>
                <input type="text" [(ngModel)]="p.ProjectOutgoingNumber" class="form-control" id="outgoingNumber"
                  name="outgoingNumber" placeholder="" [required]="isVoipSelected?'true':'false'" />
                <div class="valid-feedback">Valid.</div>
                <div class="invalid-feedback">
                  Please enter the outgoing number.
                </div>
              </div>
            </div>

            <div class="form-row">
              <div class="form-group col-md-3">
                <label for="inputEmail4" style="display: block">Project Email services</label>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" [(ngModel)]="p.EmailService.choice" type="radio"
                    [matMenuTriggerFor]="menu2" name="inlineRadioOptions1" id="EmailYes" value="Yes" required />
                  <div class="invalid-feedback"></div>
                  <label class="form-check-label" for="inlineRadio1">Yes</label>
                  <mat-menu #menu2="matMenu" class="px-3 pt-1">
                    <div class="form-group custom-popup">
                      <label for="inputEmail4">Incoming</label>
                      <input type="text" class="form-control" [(ngModel)]="p.EmailService.incoming" id="incoming"
                        name="incoming" placeholder=" " (click)="$event.stopPropagation()"
                        [required]="p.EmailService.choice == 'yes' " />
                    </div>
                    <div class="form-group custom-popup">
                      <label for="inputEmail4">Outgoing</label>
                      <input type="text" class="form-control" [(ngModel)]="p.EmailService.outgoing" id="outgoing"
                        name="outgoing" placeholder=" " (click)="$event.stopPropagation()"
                        [required]="p.EmailService.choice == 'yes' " />
                    </div>
                    <div class="form-group custom-popup">
                      <label for="inputEmail4">Mail Group</label>
                      <input type="text" class="form-control" [(ngModel)]="p.EmailService.mailGroup" id="mailGroup"
                        name="mailGroup" placeholder=" " (click)="$event.stopPropagation()"
                        [required]="p.EmailService.choice == 'yes' " />
                    </div>
                  </mat-menu>
                </div>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" [(ngModel)]="p.EmailService.choice" type="radio"
                    name="inlineRadioOptions1" id="EmailNo" value="No" required />
                  <label class="form-check-label" for="inlineRadio2">No</label>
                </div>
              </div>
              <div class="form-group col-md-3">
                <label for="inputEmail4" style="display: block">Project SMS services</label>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio" name="inlineRadioOptions2" id="smsYes" value="Yes"
                    [(ngModel)]="p.SmsService" required />
                  <label class="form-check-label" for="inlineRadio1">Yes</label>
                </div>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio" name="inlineRadioOptions2" id="smsNo" value="No"
                    [(ngModel)]="p.SmsService" required />
                  <label class="form-check-label" for="inlineRadio2">No</label>
                </div>
              </div>

              <div class="form-group col-md-3">
                <label for="inputEmail4" style="display: block">Project Whatsapp services</label>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" [(ngModel)]="p.WhatsappService" type="radio"
                    name="inlineRadioOptions3" id="whatsappYes" value="Yes" required />
                  <label class="form-check-label" for="inlineRadio1">Yes</label>
                </div>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" [(ngModel)]="p.WhatsappService" type="radio"
                    name="inlineRadioOptions3" id="whatsappNo" value="No" required />
                  <label class="form-check-label" for="inlineRadio2">No</label>
                </div>
              </div>
              <div class="form-group col-md-3 text-right"></div>
            </div>
            <div class="mt-2 mb-3"></div>
            <div class="header-lg mb-2 mt-2">Select Modules</div>
            <div class="form-row">
              <div class="" [ngClass]="
                  isClass == true
                    ? 'form-group col-md-8 module-card'
                    : 'form-group col-md-4 module-card'
                ">
                <div class="mb-2">
                  <mat-accordion class="mb-3" (click)="$event.stopPropagation()">
                    <mat-expansion-panel>
                      <mat-expansion-panel-header>
                        <mat-panel-title> Lead management </mat-panel-title>
                        <!-- <mat-slide-toggle (click)="$event.stopPropagation()" class="mr-3"></mat-slide-toggle> -->
                        <mat-slide-toggle (change)="openState($event)"
                          [checked]="ProjectDetails.Modules.leadManagement.LeadManagement" class="mr-3">
                        </mat-slide-toggle>

                      </mat-expansion-panel-header>

                      <section class="example-section" (click)="$event.stopPropagation();">
                        <span class="example-list-section">
                          <div class="d-flex">
                            <div>
                              <mat-checkbox (click)="$event.stopPropagation();" class="example-margin"
                                (change)="selectAllFeedbacks()" [checked]="allComplete" [color]="feedBacks.color"
                                [indeterminate]="someComplete()" [checked]="feedBacks.selected">
                                {{feedBacks.name}}

                              </mat-checkbox>
                            </div>
                            <div class="ml-auto">
                              <div class="d-flex mb-1">
                                <div>
                                  <button class="btn btn-link btn-sm p-0 ml-0 mr-2 mb-0 mt-0"
                                    [matMenuTriggerFor]="moduleSearch" (click)="$event.stopPropagation();">
                                    <img src="../../../assets/img/search.svg" class="img14">
                                  </button>
                                  <button class="btn btn-link btn-sm p-0 ml-0 mb-0 mt-0"
                                    [matMenuTriggerFor]="newFeedback" (click)="$event.stopPropagation();">
                                    <img src="../../../assets/img/add-g.svg" class="img14">
                                  </button>

                                  <mat-menu #moduleSearch="matMenu" xPosition="before" yPosition="below"
                                    class="px-3 pt-1 w250" style="width:250px!important">
                                    <div class="form-group custom-popup mb-1">
                                      <input type="text" class="form-control" id="inputAddress2"
                                        placeholder="Search by keyword" (click)="$event.stopPropagation();">
                                    </div>
                                  </mat-menu>


                                  <mat-menu #newFeedback="matMenu" xPosition="before" yPosition="below"
                                    class="px-3 pt-1 w250" style="width:250px!important">
                                    <div class="form-group custom-popup" style="width:240px!important;">
                                      <label for="inputEmail4">Feedback Name</label>
                                      <input type="text" class="form-control" id="inputAddress2" placeholder=" "
                                        (click)="$event.stopPropagation();">
                                    </div>

                                    <div class="d-flex align-items-center justify-content-end">
                                      <button class="btn btn-primary btn-sm mr-2">Add</button>
                                      <button class="btn btn-light btn-sm">Cancel</button>
                                    </div>
                                  </mat-menu>

                                </div>
                              </div>
                            </div>
                          </div>
                        </span>

                        <span class="example-list-section">

                          <ul class="float-left itemList">
                            <li *ngFor="let subtask of feedBacks.subtasks; let i = index;">
                              <mat-checkbox [(ngModel)]="subtask.selected" [checked]="subtask.selected"
                                (change)="addFeedback(subtask, i)" [color]="subtask.color"
                                (ngModelChange)="updateAllComplete()">
                                {{subtask.name}}
                              </mat-checkbox>
                            </li>
                          </ul>



                        </span>
                      </section>
                      <div style="clear:both"></div>
                      <div class="float-right p-2">
                        Pagination
                      </div>

                      <div style="clear:both"></div>
                      <hr>
                      <div style="clear:both"></div>


                      <section class="example-section">
                        <span class="example-list-section">

                          <div class="d-flex">
                            <div>
                              <mat-checkbox class="example-margin" [checked]="channel.selected" [color]="channel.color"
                                (change)=" selectAllChannels()">
                                {{channel.name}}
                              </mat-checkbox>
                            </div>
                            <div class="ml-auto">
                              <div class="d-flex">
                                <div>
                                  <img src="../../../assets/img/add-g.svg" class="img14 mr-2">
                                </div>
                                <div>
                                  <img src="../../../assets/img/search.svg" class="img14">
                                </div>
                              </div>
                            </div>
                          </div>


                        </span>
                        <span class="example-list-section">
                          <!-- <ul>
                            <li *ngFor="let subtask of channel.subtasks">
                              <mat-checkbox [(ngModel)]="subtask.completed" [color]="subtask.color" []
                                (ngModelChange)="updateAllComplete()">
                                {{subtask.name}}
                              </mat-checkbox>
                            </li>
                          </ul> -->

                          <ul class="float-left itemList">
                            <li *ngFor="let subtask of channel.subtasks; let i = index;">
                              <mat-checkbox [(ngModel)]="subtask.selected" [checked]="subtask.selected"
                                (change)="addChannel(subtask, i)" [color]="subtask.color"
                                (ngModelChange)="updateAllComplete()">
                                {{subtask.name}}
                              </mat-checkbox>
                            </li>
                          </ul>
                        </span>
                      </section>

                      <div style="clear:both"></div>
                      <hr>
                      <div style="clear:both"></div>

                      <section class="example-section">
                        <span class="example-list-section">

                          <div class="d-flex">
                            <div>
                              <mat-checkbox class="example-margin" [checked]="leadTableColoumn.selected"
                                [color]="leadTableColoumn.color" (change)=" selectAllColumns()">
                                {{leadTableColoumn.name}}
                              </mat-checkbox>
                            </div>
                            <div class="ml-auto">
                              <div class="d-flex">
                                <div>
                                  <img src="../../../assets/img/add-g.svg" class="img14 mr-2">
                                </div>
                                <div>
                                  <img src="../../../assets/img/search.svg" class="img14">
                                </div>
                              </div>
                            </div>
                          </div>


                        </span>
                        <span class="example-list-section">
                          <!-- <ul>
                            <li *ngFor="let subtask of channel.subtasks">
                              <mat-checkbox [(ngModel)]="subtask.completed" [color]="subtask.color" []
                                (ngModelChange)="updateAllComplete()">
                                {{subtask.name}}
                              </mat-checkbox>
                            </li>
                          </ul> -->

                          <ul class="float-left itemList">
                            <li *ngFor="let subtask of leadTableColoumn.subtasks; let i = index;">
                              <mat-checkbox [(ngModel)]="subtask.selected" [checked]="subtask.selected"
                                (change)="addColumns(subtask, i)" [color]="subtask.color"
                                (ngModelChange)="updateAllComplete()">
                                {{subtask.name}}
                              </mat-checkbox>
                            </li>
                          </ul>
                        </span>
                      </section>
                    </mat-expansion-panel>
                  </mat-accordion>
                </div>

                <!-- <div class="mb-2">
                  <mat-accordion (click)="$event.stopPropagation()">
                    <mat-expansion-panel
                      (opened)="panelOpenState = true"
                      (closed)="panelOpenState = false"
                    >
                      <mat-expansion-panel-header>
                        <mat-panel-title> Incident management </mat-panel-title>
                        <mat-slide-toggle
                          (click)="$event.stopPropagation()"
                          class="mr-3"
                        ></mat-slide-toggle>
                      </mat-expansion-panel-header>
                      <section class="example-section">
                        <span class="example-list-section">
                          <div class="d-flex">
                            <div>
                              <mat-checkbox
                                class="example-margin"
                                [checked]="allComplete"
                                [color]="task.color"
                                [indeterminate]="someComplete()"
                                (change)="setAll($event.checked)"
                              >
                                {{ leadTableColoumn.name }}
                              </mat-checkbox>
                            </div>
                            <div class="ml-auto">
                              <div class="d-flex">
                                <div>
                                  <img
                                    src="../../../assets/img/add-g.svg"
                                    class="img14 mr-2"
                                  />
                                </div>
                                <div>
                                  <img
                                    src="../../../assets/img/search.svg"
                                    class="img14"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </span>
                        <span class="example-list-section">
                          <ul>
                            <li
                              *ngFor="let subtask of leadTableColoumn.subtasks"
                            >
                              <mat-checkbox
                                [(ngModel)]="subtask.completed"
                                [color]="subtask.color"
                                (ngModelChange)="updateAllComplete()"
                              >
                                {{ subtask.name }}
                              </mat-checkbox>
                            </li>
                          </ul>
                        </span>
                      </section>
                    </mat-expansion-panel>
                  </mat-accordion>
                </div> -->
              </div>

              <!-- <div class="form-group col-md-4 module-card">
                <div class="mb-2">
                  <mat-accordion (click)="$event.stopPropagation()">
                    <mat-expansion-panel
                      (opened)="panelOpenState = true"
                      (closed)="panelOpenState = false"
                    >
                      <mat-expansion-panel-header>
                        <mat-panel-title> Referrals </mat-panel-title>
                        <mat-slide-toggle
                          (click)="$event.stopPropagation()"
                          class="mr-3"
                        ></mat-slide-toggle>
                      </mat-expansion-panel-header>
                      <section class="example-section">
                        <span class="example-list-section">
                          <div class="d-flex">
                            <div>
                              <mat-checkbox
                                class="example-margin"
                                [checked]="allComplete"
                                [color]="task.color"
                                [indeterminate]="someComplete()"
                                (change)="setAll($event.checked)"
                              >
                                {{ leadTableColoumn.name }}
                              </mat-checkbox>
                            </div>
                            <div class="ml-auto">
                              <div class="d-flex">
                                <div>
                                  <img
                                    src="../../../assets/img/add-g.svg"
                                    class="img14 mr-2"
                                  />
                                </div>
                                <div>
                                  <img
                                    src="../../../assets/img/search.svg"
                                    class="img14"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </span>
                        <span class="example-list-section">
                          <ul>
                            <li
                              *ngFor="let subtask of leadTableColoumn.subtasks"
                            >
                              <mat-checkbox
                                [(ngModel)]="subtask.completed"
                                [color]="subtask.color"
                                (ngModelChange)="updateAllComplete()"
                              >
                                {{ subtask.name }}
                              </mat-checkbox>
                            </li>
                          </ul>
                        </span>
                      </section>
                    </mat-expansion-panel>
                  </mat-accordion>
                </div>
                <div class="mb-2">
                  <mat-accordion (click)="$event.stopPropagation()">
                    <mat-expansion-panel
                      (opened)="panelOpenState = true"
                      (closed)="panelOpenState = false"
                    >
                      <mat-expansion-panel-header>
                        <mat-panel-title> Marketing </mat-panel-title>
                        <mat-slide-toggle
                          (click)="$event.stopPropagation()"
                          class="mr-3"
                        ></mat-slide-toggle>
                      </mat-expansion-panel-header>
                      <section class="example-section">
                        <span class="example-list-section">
                          <div class="d-flex">
                            <div>
                              <mat-checkbox
                                class="example-margin"
                                [checked]="allComplete"
                                [color]="task.color"
                                [indeterminate]="someComplete()"
                                (change)="setAll($event.checked)"
                              >
                                {{ leadTableColoumn.name }}
                              </mat-checkbox>
                            </div>
                            <div class="ml-auto">
                              <div class="d-flex">
                                <div>
                                  <img
                                    src="../../../assets/img/add-g.svg"
                                    class="img14 mr-2"
                                  />
                                </div>
                                <div>
                                  <img
                                    src="../../../assets/img/search.svg"
                                    class="img14"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </span>
                        <span class="example-list-section">
                          <ul>
                            <li
                              *ngFor="let subtask of leadTableColoumn.subtasks"
                            >
                              <mat-checkbox
                                [(ngModel)]="subtask.completed"
                                [color]="subtask.color"
                                (ngModelChange)="updateAllComplete()"
                              >
                                {{ subtask.name }}
                              </mat-checkbox>
                            </li>
                          </ul>
                        </span>
                      </section>
                    </mat-expansion-panel>
                  </mat-accordion>
                </div>
              </div>

              <div class="form-group col-md-4 module-card">
                <div class="mb-2">
                  <mat-accordion (click)="$event.stopPropagation()">
                    <mat-expansion-panel
                      (opened)="panelOpenState = true"
                      (closed)="panelOpenState = false"
                    >
                      <mat-expansion-panel-header>
                        <mat-panel-title> Bi analytics </mat-panel-title>
                        <mat-slide-toggle
                          (click)="$event.stopPropagation()"
                          class="mr-3"
                        ></mat-slide-toggle>
                      </mat-expansion-panel-header>
                      <section class="example-section">
                        <span class="example-list-section">
                          <div class="d-flex">
                            <div>
                              <mat-checkbox
                                class="example-margin"
                                [checked]="allComplete"
                                [color]="task.color"
                                [indeterminate]="someComplete()"
                                (change)="setAll($event.checked)"
                              >
                                {{ leadTableColoumn.name }}
                              </mat-checkbox>
                            </div>
                            <div class="ml-auto">
                              <div class="d-flex">
                                <div>
                                  <img
                                    src="../../../assets/img/add-g.svg"
                                    class="img14 mr-2"
                                  />
                                </div>
                                <div>
                                  <img
                                    src="../../../assets/img/search.svg"
                                    class="img14"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </span>
                        <span class="example-list-section">
                          <ul>
                            <li
                              *ngFor="let subtask of leadTableColoumn.subtasks"
                            >
                              <mat-checkbox
                                [(ngModel)]="subtask.completed"
                                [color]="subtask.color"
                                (ngModelChange)="updateAllComplete()"
                              >
                                {{ subtask.name }}
                              </mat-checkbox>
                            </li>
                          </ul>
                        </span>
                      </section>
                    </mat-expansion-panel>
                  </mat-accordion>
                </div>
                <div class="mb-2">
                  <mat-accordion (click)="$event.stopPropagation()">
                    <mat-expansion-panel
                      (opened)="panelOpenState = true"
                      (closed)="panelOpenState = false"
                    >
                      <mat-expansion-panel-header>
                        <mat-panel-title> My Team </mat-panel-title>
                        <mat-slide-toggle
                          (click)="$event.stopPropagation()"
                          class="mr-3"
                        ></mat-slide-toggle>
                      </mat-expansion-panel-header>
                      <section class="example-section">
                        <span class="example-list-section">
                          <div class="d-flex">
                            <div>
                              <mat-checkbox
                                class="example-margin"
                                [checked]="allComplete"
                                [color]="task.color"
                                [indeterminate]="someComplete()"
                                (change)="setAll($event.checked)"
                              >
                                {{ leadTableColoumn.name }}
                              </mat-checkbox>
                            </div>
                            <div class="ml-auto">
                              <div class="d-flex">
                                <div>
                                  <img
                                    src="../../../assets/img/add-g.svg"
                                    class="img14 mr-2"
                                  />
                                </div>
                                <div>
                                  <img
                                    src="../../../assets/img/search.svg"
                                    class="img14"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </span>
                        <span class="example-list-section">
                          <ul>
                            <li
                              *ngFor="let subtask of leadTableColoumn.subtasks"
                            >
                              <mat-checkbox
                                [(ngModel)]="subtask.completed"
                                [color]="subtask.color"
                                (ngModelChange)="updateAllComplete()"
                              >
                                {{ subtask.name }}
                              </mat-checkbox>
                            </li>
                          </ul>
                        </span>
                      </section>
                    </mat-expansion-panel>
                  </mat-accordion>
                </div>
              </div> -->
            </div>
            <hr />
            <div class="p-4">
              <div class="d-flex flex-column">
                <div class="p-3 text-center header-lg">
                  Do you want create another Project..
                </div>
                <div style="margin: 0 auto">
                  <button [disabled]="isLoading==true" class="btn btn-secondary btn-lg ml-2"
                    (click)="CreateProject(form2)">
                    <img src="../../../assets/img/add-w.svg" class="img12 mr-2" />Add Project
                  </button>
                </div>
              </div>
            </div>

            <!-- <table class="table table-sm table-borderless doc-table2" *ngIf="addedProjects && addedProjects.length>0">
              <div *ngFor="let item of addedProjects;let indexofelement = index">
                <tr >
                  <td style="vertical-align: bottom">
                    <div class="header-12 lh150">#897867</div>
                    <div class="header-lg">{{item.projectName}}</div>
                  </td>
                  <td style="vertical-align: bottom">
                    <div class="blue-pill mr-2 float-left">
                      <img
                        src="../../../assets/images/incoming-call.svg"
                        class="img12 mr-2"
                      />{{item.voipIncoimngNumber}}
                    </div>
                    <div class="pink-pill mr-2 float-left">
                      <img
                        src="../../../assets/images/outgoing-call.svg"
                        class="img12 mr-2"
                      />{{item.voipOutgoingNumber}}
                    </div>
                  </td>
                  <td style="vertical-align: bottom">
                    <div class="light-pill mr-2 float-left">
                      <img
                        src="../../../assets/img/message-b.svg"
                        class="img12 hand"
                      />
                    </div>
                    <div class="light-pill mr-2 float-left">
                      <img
                        src="../../../assets/img/email-g.svg"
                        class="img12 hand"
                      />
                    </div>
                    <div class="light-pill mr-2 float-left">
                      <img
                        src="../../../assets/img/whatsapp.png"
                        class="img12 hand"
                      />
                    </div>
                  </td>
                  <td class="text-right" style="vertical-align: bottom">
                    <img
                      src="../../../assets/images/edit.svg"
                      class="img11 mr-2 hand"
                    />
                    <img
                      src="../../../assets/images/delete.svg"
                      class="img11 hand" (click)="DeleteProject(indexofelement)"
                    />
                  </td>
                </tr>
                <tr *ngIf="item.modules">
                  <td colspan="4">
                    <div class="header-12">Modules</div>
                    <div class="pt-2" *ngFor="let data of item.modules">
                      <div class="light-pill mr-2 float-left">{{data.modeule}}</div>
                    </div>
                  </td>
                </tr>
              </div>
            </table> -->
            <hr />

          </form>
          <div class="p-2">
            <div class="d-flex align-items-center justify-content-end">
              <div class="d-flex ml-auto">
                <button class="btn btn-light btn-lg mr-2" type="button" (click)="resetForm()">Reset</button>
                <button class="btn btn-secondary btn-lg mr-2" [disabled]="isLoading==true"
                  (click)="CreateProject(form2)" type="button">
                  <img src="../../../assets/img/ticker-white.svg" class="img12 mr-2" />Create Project
                </button>
                <button matStepperNext class="btn btn-secondary btn-sm" [disabled]="!projectCreated" type="button">
                  Next
                </button>

              </div>
            </div>
          </div>
          <!-- <div
            class="p-3 d-flex flex-column align-items-center justify-content-center"
          >
            <img
              src="../../../assets/img/finger-rise.svg"
              class="img50 text-center"
            />
            <div class="p-3 text-center">
              Succes.. Project Created successfully
            </div>
          </div> -->
          <hr />
          <table class="table table-sm table-borderless doc-table2" *ngIf="addedProjects && addedProjects.length>0">
            <tbody *ngFor="let item of addedProjects;let indexofelement = index">
              <tr>
                <td style="vertical-align: bottom">
                  <!-- <div class="header-12 lh150">#897867</div> -->
                  <div class="header-lg">{{item.projectName}}</div>
                </td>
                <td style="vertical-align: bottom">
                  <div class="blue-pill mr-2 float-left">
                    <img src="../../../assets/images/incoming-call.svg" class="img12 mr-2" />{{item.voipIncoimngNumber}}
                  </div>
                  <div class="pink-pill mr-2 float-left">
                    <img src="../../../assets/images/outgoing-call.svg" class="img12 mr-2" />{{item.voipOutgoingNumber}}
                  </div>
                </td>
                <td style="vertical-align: bottom">
                  <div class="light-pill mr-2 float-left">
                    <img src="../../../assets/img/message-b.svg" class="img12 hand" />
                  </div>
                  <div class="light-pill mr-2 float-left">
                    <img src="../../../assets/img/email-g.svg" class="img12 hand" />
                  </div>
                  <div class="light-pill mr-2 float-left">
                    <img src="../../../assets/img/whatsapp.png" class="img12 hand" />
                  </div>
                </td>
                <!-- <td class="text-right" style="vertical-align: bottom">
                  <img
                    src="../../../assets/images/edit.svg"
                    class="img11 mr-2 hand"
                  />
                  <img
                    src="../../../assets/images/delete.svg"
                    class="img11 hand"
                  />
                </td> -->
              </tr>
              <tr *ngIf="item.modules">
                <td colspan="4">
                  <div class="header-12">Modules</div>
                  <div class="pt-2" *ngFor="let data of item.modules">
                    <div class="light-pill mr-2 float-left">{{data.modeule}}</div>
                  </div>
                </td>
              </tr>
              <tr>
                <td colspan="8">
                  <hr />
                </td>
              </tr>
            </tbody>

          </table>

          <!-- <div class="p-4">
            <div class="d-flex flex-column">
              <div class="p-3 text-center header-lg">
                Do you want create another Project..
              </div>
              <div style="margin: 0 auto">
                <button [disabled]="isLoading==true" class="btn btn-secondary btn-lg ml-2" (click)="CreateProject(form2)">
                  <img
                    src="../../../assets/img/add-w.svg"
                    class="img12 mr-2"
                  />Add Project
                </button>
              </div>
            </div>
          </div> -->

          <div class="float-right">
            <!-- <button matStepperPrevious class="btn btn-secondary btn-sm mr-4">
              Back
            </button> -->
          </div>
        </form>
      </mat-step>

      <mat-step>
        <ng-template matStepLabel>Add User</ng-template>
        <!-- for Help -->
        <div class="pt-2 d-flex">
          <div class="ml-auto d-flex">
            <div>
              <img src="../../../assets/images/info.svg" class="img16 mr-2 hand" ngbTooltip="Information"
                placement="top" tooltipClass="my-custom-class" />
            </div>
            <div>
              <img src="../../../assets/images/video-play.svg" class="img16 hand" ngbTooltip="Demo video"
                placement="top" tooltipClass="my-custom-class" />
            </div>
          </div>
        </div>
        <!-- Help ends -->
        <div class="form-row ml-4">
          <div class="col-md-8">
            <div class="header-lg">Invite Users to PROJECT</div>
            <p class="sub-text-1 pt-1 pb-3">
              Invite teammates to collaborate and use products in your
              organisation. We’ll ask new users to enter their personal details
              when they sign up.
            </p>
            <label for="inputState">Email address
              <button class="btn btn-link btn-sm" ngbTooltip="Download sample" placement="top"
                (click)="downloadTemplate()" tooltipClass="my-custom-class">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 22 22">
                  <g id="Group_776" data-name="Group 776" transform="translate(-215.728 -61.66)">
                    <path id="Path_888" class="psvg" data-name="Path 888"
                      d="M234.853,86.66H221.1a1.375,1.375,0,0,1-1.375-1.375v-8.25A1.375,1.375,0,0,1,221.1,75.66h2.063v1.375H221.1v8.25h13.75v-8.25h-2.063V75.66h2.063a1.375,1.375,0,0,1,1.375,1.375v8.25A1.376,1.376,0,0,1,234.853,86.66Z"
                      transform="translate(-1.25 -4.375)" fill="#b02022" />
                    <rect id="Rectangle_902" class="psvg" data-name="Rectangle 902" width="4" height="1"
                      transform="translate(224.728 75.66)" fill="#b02022" />
                    <path id="Path_889" class="psvg" data-name="Path 889"
                      d="M233.008,68.19l-2.468,2.461V63.66h-1.375v6.992L226.7,68.19l-.969.969,4.125,4.125,4.125-4.125Z"
                      transform="translate(-3.125 -0.625)" fill="#b02022" />
                    <rect id="_Transparent_Rectangle_" data-name=" Transparent Rectangle " width="22" height="22"
                      transform="translate(215.728 61.66)" fill="none" />
                  </g>
                </svg>
              </button>
            </label>

            <div class="modal-body" style="height: none;">

              <div class="px-2">
                <input type="file" style="display: inline-block;" (change)="incomingfile($event)"
                  placeholder="Upload file" accept=".xlsx">
                <button type="button" class="btn btn-info" (click)="Upload()">Invite</button>
              </div>
            </div>

            <form [formGroup]="rulesForm">
              <mat-form-field>
                <mat-chip-list #chipList formArrayName="emails">
                  <mat-chip [color]="(item.invalid)?'warn':''" selected *ngFor="let item of emailList; let i=index"
                    [selectable]="true" [removable]="removable" (removed)="removeEmail(item)" required name="chips">
                    {{item.value}}
                    <mat-icon matChipRemove *ngIf="removable">X</mat-icon>
                  </mat-chip>


                  <input placeholder="Enter Email-id" class="form-control" type="email" matInput
                    [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes "
                    [matChipInputAddOnBlur]="true " (matChipInputTokenEnd)="add($event) " />
                </mat-chip-list>
              </mat-form-field>
              <!-- <mat-error *ngIf="rulesForm.get('emails').hasError('incorrectEmail')">Invalid email ID</mat-error> -->

            </form>

            <!-- <input class="form-control" placeholder="Invite by email address.." type="email" matInput
              [formControl]="emailFormControl" [errorStateMatcher]="matcher" placeholder="Ex. pat@example.com"
              [(ngModel)]="inviteEmail" />
            <mat-error *ngIf="
                emailFormControl.touched &&
                emailFormControl.hasError('email') &&
                !emailFormControl.hasError('required')
              ">
              Please enter a valid email address
            </mat-error>
            <mat-error *ngIf="
                emailFormControl.touched &&
                emailFormControl.hasError('required')
              ">
              Email is <strong>required</strong>
            </mat-error> -->
            <p class="sub-text-2 pt-2 pb-4">
              Separate emails using a comma. Note, we can’t send invitations to
              distribution lists.
            </p>
          </div>
        </div>

        <div class="form-row ml-4">
          <div class="col-md-8">
            <div>
              <table class="table table-condensed table-hover">
                <tr>
                  <th>Project</th>
                  <th>Project Role</th>
                </tr>
                <tr *ngFor="let project of newProjectDetails">
                  <td>
                    <mat-checkbox class="example-margin" (change)="selectedRoles()" [checked]="project.selected"
                      [(ngModel)]="project.selected">{{ project.projectName }}</mat-checkbox>
                  </td>
                  <td rowspan="2" class="border-left">
                    <mat-radio-group aria-label="Select an option" [(ngModel)]="project.role">
                      <section class="example-section drop-section">
                        <mat-radio-button value="admin">Administrator</mat-radio-button>
                        <p class="ml-4 mb-2">
                          Can access the product, with no product admin
                          permissions
                        </p>
                      </section>
                      <section class="example-section drop-section">
                        <mat-radio-button value="agent">Agent</mat-radio-button>
                        <p class="ml-4 mb-2">
                          Can access the product, with no product admin
                          permissions
                        </p>
                      </section>
                      <section class="example-section drop-section" *ngIf="project.projectType=='Student Study Abroad'">
                        <mat-radio-button value="counsellor">Counsellor</mat-radio-button>
                        <p class="ml-4 mb-2">
                          Can access the product, with no product admin
                          permissions
                        </p>
                      </section>
                      <section class="example-section drop-section" *ngIf="project.projectType=='Student Study Abroad'">
                        <mat-radio-button value="administrator specialist">Administrator Specialist</mat-radio-button>
                        <p class="ml-4 mb-2">
                          Can access the product, with no product admin
                          permissions
                        </p>
                      </section>
                    </mat-radio-group>
                  </td>
                </tr>
              </table>
            </div>
            <div class="d-flex align-items-center justify-content-center p-3">
              <button class="btn btn-secondary btn-sm mr-2" (click)="inviteAgents()"
                [disabled]="newProjectDetails?.length<=0" type="button">
                Invite
              </button>
              <!-- <button class="btn btn-secondary btn-sm mr-2" (click)="inviteAgents()"
                 type="button">
                Invite
              </button> -->
              <div class="btn btn-light btn-sm">Cancel</div>
            </div>
          </div>
        </div>

        <div class="float-right">
          <button class="btn btn-secondary btn-sm mr-2" matStepperPrevious type="button">
            Back
          </button>
          <button class="btn btn-secondary btn-sm" (click)="stepper.reset()" type="button">
            Done
          </button>
        </div>
      </mat-step>
    </mat-stepper>
  </div>
</div>
<div class="m-5" *ngIf="isEditCompany">
  <div class="p-3 b-radius-10 bg-w">
    <mat-stepper [linear]="isLinear" #stepper>
      <mat-step [stepControl]="firstFormGroup">
        <div class="pt-2 d-flex">
          <div class="ml-auto d-flex">
            <div>
              <img src="../../../assets/images/info.svg" class="img16 mr-2 hand icon-color" ngbTooltip="Information"
                placement="top" tooltipClass="my-custom-class" />
            </div>
            <div>
              <img src="../../../assets/images/video-play.svg" class="img16 hand" ngbTooltip="Demo video"
                placement="top" tooltipClass="my-custom-class" />
            </div>
          </div>
        </div>
        <form [formGroup]="firstFormGroup">
          <ng-template matStepLabel>Edit Company</ng-template>

          <form class="was-validated" novalidate #form="ngForm" [ngClass]="{ 'was-validated': form.submitted }">
            <div class="form-row">
              <div class="form-group col-md-8">
                <label for="inputEmail4">Company name</label>
                <input type="text" class="form-control" id="uname" placeholder="Enter Company Name"
                  [(ngModel)]="CompanyDetails.CompanyName" name="uname" required />
                <div class="valid-feedback">Valid.</div>
                <div class="invalid-feedback">
                  Please enter the company name.
                </div>
              </div>

              <div class="form-group col-md-4">
                <label for="users">Total No. of Users</label>
                <input type="text" class="form-control" id="users" placeholder="Max Number of Users"
                  [(ngModel)]="CompanyDetails.NoOfUsers" name="users" required />
                <div class="valid-feedback">Valid.</div>
                <div class="invalid-feedback">Please enter a number</div>
              </div>
            </div>
            <div class="header-lg mb-2 mt-2">Contact Number</div>
            <div class="form-row">
              <div class="form-group col-md-6">
                <label for="primaryNumber">Primary Number</label>
                <input type="text" class="form-control" id="primaryNumber" placeholder=""
                  [(ngModel)]="CompanyDetails.PrimaryPhoneNumber" name="primaryNumber" required />
                <div class="valid-feedback">Valid.</div>
                <div class="invalid-feedback">Please enter a number</div>
              </div>
              <div class="form-group col-md-6">
                <label for="secondaryNumber">Secondary number</label>
                <input type="text" class="form-control" id="secondaryNumber" placeholder=""
                  [(ngModel)]="CompanyDetails.SecondaryPhoneNumber" name="secondaryNumber" required />
                <div class="valid-feedback">Valid.</div>
                <div class="invalid-feedback">Please enter a number</div>
              </div>
            </div>

            <div class="header-lg mb-2 mt-2">Email id's</div>
            <div class="form-row">
              <div class="form-group col-md-6">
                <label for="Email">Primary Email</label>
                <input type="text" class="form-control" id="Email" placeholder=""
                  [(ngModel)]="CompanyDetails.PrimaryEmail" name="Email" required />
                <div class="valid-feedback">Valid.</div>
                <div class="invalid-feedback">Please enter an email</div>
              </div>
              <div class="form-group col-md-6">
                <label for="SecondaryEmail">Secondary Email</label>
                <input type="text" class="form-control" id="SecondaryEmail" placeholder=""
                  [(ngModel)]="CompanyDetails.SecondaryEmail" name="SecondaryEmail" required />
                <div class="valid-feedback">Valid.</div>
                <div class="invalid-feedback">Please enter an email</div>
              </div>
            </div>
            <div class="header-lg mb-2 mt-2">Company Domain</div>
            <div class="form-row">
              <div class="form-group col-md-6">
                <label for="domainName">Domain Namme</label>
                <input type="text" class="form-control" id="domainName" placeholder=""
                  [(ngModel)]="CompanyDetails.domainName" placeholder="eg. example.com" name="domainName" required
                  [readonly]="isdomainName" />
                <div class="valid-feedback">Valid.</div>
                <div class="invalid-feedback">Please enter an domain name</div>
              </div>

            </div>
            <div class="form-row">
              <div class="form-group col-md-6">
                <label for="voip">Voip </label>
                <div class="form-control" style="border: 0">
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="inlineRadioOptions" id="twilio" value="twilio"
                      [(ngModel)]="CompanyDetails.Voip.voip" [matMenuTriggerFor]="menu" required />
                    <div class="invalid-feedback"></div>
                    <label class="form-check-label" for="twilio">
                      <img src="../../../assets/images/twillo.png" />
                    </label>
                    <mat-menu #menu="matMenu">
                      <div class="p-2">
                        <input type="text" class="form-control" id="twilio" placeholder="Twillio Number"
                          [(ngModel)]="CompanyDetails.Voip.number" name="twilio" (click)="$event.stopPropagation()" />
                      </div>
                    </mat-menu>
                  </div>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" [matMenuTriggerFor]="menuOthers"
                      name="inlineRadioOptions" id="others" value="other" [(ngModel)]="CompanyDetails.Voip.voip" />

                    <div class="invalid-feedback"></div>
                    <label class="form-check-label pt-1" for="others">
                      Others
                    </label>
                    <mat-menu #menuOthers="matMenu">
                      <div class="p-2">
                        <input type="text" class="form-control mb-2" id="others" placeholder="Enter Name of Voip"
                          [(ngModel)]="CompanyDetails.Voip.voip" (click)="$event.stopPropagation()" />
                        <input type="text" class="form-control" id="others" name="number" placeholder="Voip Number"
                          [(ngModel)]="CompanyDetails.Voip.number" (click)="$event.stopPropagation()" />
                      </div>
                    </mat-menu>
                  </div>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="inlineRadioOptions" id="NoVOIP" value="NoVOIP"
                      [(ngModel)]="CompanyDetails.Voip.voip" />

                    <div class="invalid-feedback"></div>
                    <label class="form-check-label pt-1" for="NoVOIP">
                      No VOIP
                    </label>

                  </div>
                </div>
              </div>
              <div class="form-group col-md-3">
                <label for="startDate">Subscription Start Date</label>
                <input type="date" class="form-control" id="startDate"
                  [(ngModel)]="CompanyDetails.SubscriptionStartDate" name="startDate" required />
                <div class="valid-feedback">Valid.</div>
                <div class="invalid-feedback">Please enter a number</div>
              </div>
              <div class="form-group col-md-3">
                <label for="endDate">Subscription End Date</label>
                <input type="date" class="form-control" id="endDate" [(ngModel)]="CompanyDetails.SubscriptionEndDate"
                  name="endDate" required />
                <div class="valid-feedback">Valid.</div>
                <div class="invalid-feedback">Please enter a number</div>
              </div>
            </div>

            <div class="header-lg mb-2 mt-3">Company Address</div>
            <div class="form-row">
              <div class="form-group col-md-6">
                <label for="faltNo">Flat number/Building No.</label>
                <input type="input" class="form-control" id="faltNo" [(ngModel)]="CompanyDetails.FaltNo" name="faltNo"
                  required />
                <div class="valid-feedback">Valid.</div>
                <div class="invalid-feedback">Please enter a valid details.</div>
              </div>
              <div class="form-group col-md-6">
                <label for="address">Full Address</label>
                <input type="input" class="form-control" id="address" [(ngModel)]="CompanyDetails.Address"
                  name="address" required />
                <div class="valid-feedback">Valid.</div>
                <div class="invalid-feedback">Please enter a valid details.</div>
              </div>
              <div class="form-group col-md-6">
                <label for="city">City</label>
                <input type="text" class="form-control" id="city" [(ngModel)]="CompanyDetails.City" name="city"
                  required />
                <div class="valid-feedback">Valid.</div>
                <div class="invalid-feedback">Please enter a city</div>
              </div>
              <div class="form-group col-md-6">
                <label for="state">Pin/zip code </label>
                <input type="input" class="form-control" id="state" name="state" pattern="^[0-9]*$"
                  [(ngModel)]="CompanyDetails.Zip" required />
                <div class="valid-feedback">Valid.</div>
                <div class="invalid-feedback">Please enter a Pin/Zip Number</div>
              </div>
              <div class="form-group col-md-6">
                <label for="landmark">Landmark </label>
                <input type="input" class="form-control" id="landmark" name="landmark"
                  [(ngModel)]="CompanyDetails.LandMark" required />
                <div class="valid-feedback">Valid.</div>
                <div class="invalid-feedback">Please enter a Landmark</div>
              </div>
              <div class="form-group col-md-6">
                <label for="country">Country</label>
                <input type="input" class="form-control" id="country" name="country"
                  [(ngModel)]="CompanyDetails.Country" required />
                <div class="valid-feedback">Valid.</div>
                <div class="invalid-feedback">Please enter a Country</div>
              </div>
            </div>

            <!-- Upload Docs -->
            <div class="header-lg mb-2  mt-2">Upload Documents</div>

            <div class="form-row">
              <div class="form-group col-md-6">
                <div class="py-3">
                  <table class="table table-md doc-table">
                    <tr>
                      <th style="width: 50px" nowrap>S No.</th>
                      <th nowrap>Document Name</th>
                      <th nowrap class="text-center">Actions</th>
                    </tr>
                    <tr>
                      <td>1</td>
                      <td>
                        <img src="../../../assets/img/doc-grey-1.svg" class="img11 mr-2"
                          *ngIf="companyRegistDocuments.length == 0 && (!CompanyDetails.companyRegistDocuments || CompanyDetails.companyRegistDocuments=='error')" />
                        <img src="../../../assets/img/documents_submitting.svg" class="img11 mr-2"
                          *ngIf="companyRegistDocuments.length > 0 || (CompanyDetails.companyRegistDocuments && CompanyDetails.companyRegistDocuments!='error')" />

                        Company Registration Document
                      </td>
                      <td nowrap class="text-center">
                        <div class="d-flex justify-content-center">
                          <div (click)="filesInput1.click()">
                            <input type="file" #filesInput1 id="filesInput1" (change)="
                                getFileDetails($event, 'companyRegistDocuments')
                              " style="display: none" />
                            <img src="../../../assets/img/file-upload.svg" class="img11 mr-2" />
                          </div>
                          <div>
                            <img src="../../../assets/images/delete.svg" class="img11" />
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>2</td>
                      <td>
                        <img src="../../../assets/img/doc-grey-1.svg" class="img11 mr-2"
                          *ngIf="companyPanDocument.length == 0 && (!CompanyDetails.companyPanDocument || CompanyDetails.companyPanDocument=='error')" />
                        <img src="../../../assets/img/documents_submitting.svg" class="img11 mr-2"
                          *ngIf="companyPanDocument.length > 0 || (CompanyDetails.companyPanDocument && CompanyDetails.companyPanDocument!='error')" />

                        Company Pan Number
                      </td>
                      <td class="text-center">
                        <div class="d-flex justify-content-center">
                          <div>
                            <input type="file" #filesInput2 id="filesInput2" (change)="
                                getFileDetails($event, 'companyPanDocument')
                              " style="display: none" />
                            <img src="../../../assets/img/file-upload.svg" class="img11 mr-2"
                              (click)="filesInput2.click()" />
                          </div>
                          <div>
                            <img src="../../../assets/images/delete.svg" class="img11" />
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>3</td>
                      <td>
                        <img src="../../../assets/img/doc-grey-1.svg" class="img11 mr-2"
                          *ngIf="companyTanDocument.length == 0 && (!CompanyDetails.companyTanDocument || CompanyDetails.companyTanDocument=='error')" />
                        <img src="../../../assets/img/documents_submitting.svg" class="img11 mr-2"
                          *ngIf="companyTanDocument.length > 0 || (CompanyDetails.companyTanDocument && CompanyDetails.companyTanDocument!='error')" />

                        Company Tan Number
                      </td>
                      <td class="text-center">
                        <div class="d-flex justify-content-center">
                          <div>
                            <input type="file" #filesInput3 id="filesInput3" (change)="
                                getFileDetails($event, 'companyTanDocument')
                              " style="display: none" />
                            <img src="../../../assets/img/file-upload.svg" class="img11 mr-2"
                              (click)="filesInput3.click()" />
                          </div>
                          <div>
                            <img src="../../../assets/images/delete.svg" class="img11" />
                          </div>
                        </div>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
              <div class="form-group col-md-6 doc_upload_inst">
                <div class="header-12 pl-3 mb-2">Documents Checklist</div>
                <ul class="mt-5">
                  <li>
                    <img src="../../../assets/img/ticker-red.svg" class="img12 mr-2"
                      *ngIf="companyRegistDocuments.length == 0 && (!CompanyDetails.companyRegistDocuments || CompanyDetails.companyRegistDocuments=='error')" />
                    <img src="../../../assets/img/ticker-green.svg" class="img12 mr-2"
                      *ngIf="companyRegistDocuments.length > 0 || (CompanyDetails.companyRegistDocuments && CompanyDetails.companyRegistDocuments!='error')" />
                    Company Registration Document
                  </li>
                  <li>
                    <img src="../../../assets/img/ticker-red.svg" class="img12 mr-2"
                      *ngIf="companyPanDocument.length == 0 && (!CompanyDetails.companyPanDocument || CompanyDetails.companyPanDocument=='error')" />
                    <img src="../../../assets/img/ticker-green.svg" class="img12 mr-2"
                      *ngIf="companyPanDocument.length > 0 || (CompanyDetails.companyPanDocument && CompanyDetails.companyPanDocument!='error')" />

                    Company Pan Number
                  </li>
                  <li>
                    <img src="../../../assets/img/ticker-red.svg" class="img12 mr-2"
                      *ngIf="companyTanDocument.length == 0 && (!CompanyDetails.companyTanDocument || CompanyDetails.companyTanDocument=='error')" />
                    <img src="../../../assets/img/ticker-green.svg" class="img12 mr-2"
                      *ngIf="companyTanDocument.length > 0 || (CompanyDetails.companyTanDocument && CompanyDetails.companyTanDocument!='error')" />

                    Company Tan Number
                  </li>
                </ul>
              </div>
            </div>

            <div class="header-lg mb-2 mt-2">Theme Configuration</div>
            <div class="row">
              <div class="col-md-4">
                <div class="col-md-12 header-12 py-2" style="padding-left: 0;">
                  Company logo with name
                </div>
                <div class="upload-logo-block float-left mr-4">
                  <div class="p-4 d-flex">

                    <label for="filesInput4"></label>
                    <input type="file" #filesInput4 id="filesInput4" name="filesInput4" class="form-control"
                      (change)="getFileDetails($event, 'companyLogo')" style="display: none" required />

                    <div class="l-text d-flex align-items-center justify-content-center" *ngIf="!previewCompanyURL">
                      Upload Company Logo <br>with text</div>
                    <div class="l-text d-flex align-items-center justify-content-center" style="width: 100%;"
                      *ngIf="previewCompanyURL"><img id="previewLogo" [src]="previewCompanyURL" height="40px" /></div>
                    <div class="ml-auto">
                      <div class="img-circle d-flex align-items-center justify-content-center"
                        (click)="filesInput4.click()" for="filesInput4">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 22 22">
                          <g id="Group_775" data-name="Group 775" transform="translate(-171.243 -61.66)">
                            <path id="Path_886" class="csvg" data-name="Path 886"
                              d="M177.243,76.419l.953.953,5.131-5.124V84.531h1.352V72.249l5.131,5.124.953-.953L184,69.66Z"
                              transform="translate(-1.944 -2.592)" />
                            <path id="Path_887" class="csvg" data-name="Path 887"
                              d="M176.595,67.716v-2.7h13.519v2.7h1.352v-2.7a1.352,1.352,0,0,0-1.352-1.352H176.595a1.352,1.352,0,0,0-1.352,1.352v2.7Z"
                              transform="translate(-1.296 -0.648)" />
                            <rect id="_Transparent_Rectangle_" data-name=" Transparent Rectangle " width="22"
                              height="22" transform="translate(171.243 61.66)" fill="none" />
                          </g>
                        </svg>
                      </div>
                    </div>
                  </div>
                  <hr>
                  <div class="px-4 py-2 d-flex align-items-center ">
                    <div class="mr-3 mt-1">
                      <img src="../../../assets/images/info.svg" class="img24 hand" ngbTooltip="Information"
                        placement="top" tooltipClass="my-custom-class" />
                    </div>
                    <div class="l-text2">
                      Logo size should be between <br>300px (width) x 80px (height).
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-4">

                <div class="col-md-12 header-12 py-2" style="padding-left: 0;">
                  Company logo
                </div>
                <div class="upload-logo-block float-left mr-4">
                  <div class="p-4 d-flex">

                    <label for="filesInput6"></label>
                    <input type="file" #filesInput6 id="filesInput6" name="filesInput6" class="form-control"
                      (change)="getFileDetails($event, 'companylogowithoutname')" style="display: none" required />

                    <div class="l-text d-flex align-items-center justify-content-center"
                      *ngIf="!previewCompanyLogoWithoutNameURL">Upload Company Logo</div>
                    <div class="l-text d-flex align-items-center justify-content-center" style="width: 100%;"
                      *ngIf="previewCompanyLogoWithoutNameURL"><img id="previewCompanyLogoWithoutNameURL"
                        [src]="previewCompanyLogoWithoutNameURL" height="40px" /></div>

                    <div class="ml-auto">
                      <div class="img-circle d-flex align-items-center justify-content-center"
                        (click)="filesInput6.click()" for="filesInput6">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 22 22">
                          <g id="Group_775" data-name="Group 775" transform="translate(-171.243 -61.66)">
                            <path id="Path_886" class="csvg" data-name="Path 886"
                              d="M177.243,76.419l.953.953,5.131-5.124V84.531h1.352V72.249l5.131,5.124.953-.953L184,69.66Z"
                              transform="translate(-1.944 -2.592)" />
                            <path id="Path_887" class="csvg" data-name="Path 887"
                              d="M176.595,67.716v-2.7h13.519v2.7h1.352v-2.7a1.352,1.352,0,0,0-1.352-1.352H176.595a1.352,1.352,0,0,0-1.352,1.352v2.7Z"
                              transform="translate(-1.296 -0.648)" />
                            <rect id="_Transparent_Rectangle_" data-name=" Transparent Rectangle " width="22"
                              height="22" transform="translate(171.243 61.66)" fill="none" />
                          </g>
                        </svg>
                      </div>
                    </div>
                  </div>
                  <hr>
                  <div class="px-4 py-2 d-flex align-items-center ">
                    <div class="mr-3 mt-1">
                      <img src="../../../assets/images/info.svg" class="img24 hand" ngbTooltip="Information"
                        placement="top" tooltipClass="my-custom-class" />
                    </div>
                    <div class="l-text2">
                      Logo size should be between <br> 80px (width) x 80px (height)
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="col-md-12 header-12 py-2" style="padding-left: 0;">
                  Favicon logo
                </div>
                <div class="upload-logo-block float-left mr-4">
                  <div class="p-4 d-flex">

                    <label for="filesInput5"></label>
                    <input type="file" #filesInput5 id="filesInput5" name="filesInput5" class="form-control"
                      (change)="getFileDetails($event, 'favIconLogo')" style="display: none" required />

                    <div class="l-text d-flex align-items-center justify-content-center" *ngIf="!previewFaviconURL">
                      Upload Favicon</div>
                    <div class="l-text d-flex align-items-center justify-content-center" style="width: 100%;"
                      *ngIf="previewFaviconURL"><img id="previewFeviconLogo" [src]="previewFaviconURL" height="40px" />
                    </div>

                    <div class="ml-auto">
                      <div class="img-circle d-flex align-items-center justify-content-center"
                        (click)="filesInput5.click()">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 22 22">
                          <g id="Group_775" data-name="Group 775" transform="translate(-171.243 -61.66)">
                            <path id="Path_886" class="csvg" data-name="Path 886"
                              d="M177.243,76.419l.953.953,5.131-5.124V84.531h1.352V72.249l5.131,5.124.953-.953L184,69.66Z"
                              transform="translate(-1.944 -2.592)" />
                            <path id="Path_887" class="csvg" data-name="Path 887"
                              d="M176.595,67.716v-2.7h13.519v2.7h1.352v-2.7a1.352,1.352,0,0,0-1.352-1.352H176.595a1.352,1.352,0,0,0-1.352,1.352v2.7Z"
                              transform="translate(-1.296 -0.648)" />
                            <rect id="_Transparent_Rectangle_" data-name=" Transparent Rectangle " width="22"
                              height="22" transform="translate(171.243 61.66)" fill="none" />
                          </g>
                        </svg>
                      </div>
                    </div>
                  </div>
                  <hr>
                  <div class="px-4 py-2 d-flex align-items-center">
                    <div class="mr-3 mt-1">
                      <img src="../../../assets/images/info.svg" class="img24 hand" ngbTooltip="Information"
                        placement="top" tooltipClass="my-custom-class" />
                    </div>
                    <div class="l-text2">
                      Favicon size should be between <br> 16px x 16px with .ico format
                    </div>
                  </div>
                </div>
              </div>

            </div>

            <!-- old company logo & favicon -->
            <!-- <div class="row my-3">
              <div class="col-md-3">
                <div class="card p-3 text-center">
                  <label for="filesInput4"></label>
                  <input type="file" #filesInput4 id="filesInput4" name="filesInput4" class="form-control" (change)="getFileDetails($event, 'companyLogo')"
                    style="display: none" required/>
                    
                <div class="valid-feedback">Valid.</div>
                <div class="invalid-feedback">Please upload your logo</div>
                  <div class="btn btn-link btn-sm ml-3" (click)="filesInput4.click()" for="filesInput4">
                    <img src="../../../assets/img/upload2.svg" class="img11" />
                  </div>
                  <div class="header-12" *ngIf="CompanyDetails.companyLogo=='' &&  companyLogo.length <= 0">
                    Upload Company Logo
                  </div>
                  <div class="header-12" *ngIf="companyLogo.length > 0" style="color: green; font-weight: bold">
                    {{ companyLogo[0].name }}
                  </div>
                  <div class="header-12" *ngIf="CompanyDetails.companyLogo && companyLogo.length <=0 " style="color: green; font-weight: bold">
                    Company Logo Uploaded
                  </div>
 
                </div>
              </div>
              <div class="col-md-3">
                <div class="card p-3 text-center">
                  <label for="filesInput5"></label>
                  <input type="file" #filesInput5 id="filesInput5" name="filesInput5" class="form-control" (change)="getFileDetails($event, 'favIconLogo')"
                    style="display: none" required/>
                    <div class="valid-feedback">Valid.</div>
                <div class="invalid-feedback">Please upload your favicon</div>
                  <div class="btn btn-link btn-sm ml-3" (click)="filesInput5.click()">
                    <img src="../../../assets/img/upload2.svg" class="img11" />
                  </div>
                  <div class="header-12" *ngIf="CompanyDetails.favIconLogo=='' && favIconLogo.length <= 0">
                    Upload Favicon
                  </div>
                  <div class="header-12" *ngIf="favIconLogo.length > 0" style="color: green; font-weight: bold">
                    {{ favIconLogo[0].name }}
                  </div>
                  <div class="header-12" *ngIf="CompanyDetails.favIconLogo && favIconLogo.length <=0 " style="color: green; font-weight: bold">
                    Favicon Uploaded
                  </div>
                </div>
              </div>
            </div> -->

            <div class="row">
              <div class="col-md-12">
                <div class="row">
                  <div class="col-md-12 header-md py-3">
                    <div>
                      For custom theme
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-3 col-lg-3">
                    <div class="row">
                      <div class="col-md-12 header-12 py-2">
                        Sidebar Icons color
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6">
                        <div class="color-picker-block">
                          <div class="d-flex">
                            <input class="form-control c-box mr-2" type="color" #iconcolor id="iconcolor"
                              name="iconcolor" value="#000000" [(ngModel)]="
                                CompanyDetails.Theme.sidebar_topbar_icon_color
                              " required />

                            <div>
                              <span class="form-control form-txt">{{
                                CompanyDetails.Theme.sidebar_topbar_icon_color
                                }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-3 col-lg-3">
                    <div class="row">
                      <div class="col-md-12 header-12 py-2">
                        Button color
                      </div>
                      <!-- <div class="col-md-4">
                        <div class="color-picker-block">
                          <label>Headers background</label>
                          <div class="d-flex">
                            <input class="c-box mr-2" type="color" id="favcolor" name="favcolor" value="#000000"
                              [(ngModel)]="
                                CompanyDetails.Theme.table_popup_bg_color
                              " />
                            <div>
                              <span class="form-control form-txt">{{
                                CompanyDetails.Theme.table_popup_bg_color
                              }}</span>
                            </div>
                          </div>
                        </div>
                      </div> -->
                      <div class="col-md-4">
                        <div class="color-picker-block">
                          <div class="d-flex">
                            <input class="c-box mr-2" type="color" id="favcolor" name="favcolor" value="#000000"
                              [(ngModel)]="
                                CompanyDetails.Theme.table_popup_button_color
                              " />
                            <div>
                              <span class="form-control form-txt">{{
                                CompanyDetails.Theme.table_popup_button_color
                                }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3 col-lg-3">
                    <div class="row">
                      <div class="col-md-12 header-12 py-2">
                        Link color
                      </div>
                      <div class="col-md-4">
                        <div class="color-picker-block">
                          <div class="d-flex">
                            <input class="c-box mr-2" type="color" id="favcolor" name="favcolor" value="#000000"
                              [(ngModel)]="
                                CompanyDetails.Theme.table_popup_links_color
                              " />
                            <div>
                              <span class="form-control form-txt">{{
                                CompanyDetails.Theme.table_popup_links_color
                                }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12 d-flex justify-content-end align-items-end mt-4">
                    <div class="pl-3">
                      <button class="btn btn-light btn-sm mr-2" type="button">Reset</button>
                      <button class="btn btn-secondary btn-sm ml-4" data-toggle="modal" data-target="#previewmodal"
                        type="button" (click)="getPreviewDetails(companyLogo[0])">
                        Preview
                      </button>

                      <button type="submit" class="btn btn-secondary btn-sm ml-4" (click)="updateCompanyDetails(form)">
                        Update Company
                      </button>

                      <!-- <button matStepperNext class="btn btn-secondary btn-sm ml-4" 
                        type="button">
                        Next
                      </button> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal fade" id="previewmodal" tabindex="-1" role="dialog"
              aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
              <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLongTitle">
                      Preview Theme
                    </h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div class="modal-body">
                    <div class="pre-ctr d-flex">
                      <div class="pre-sidebar d-flex flex-column pre-rel">
                        <div class="pre-logo" *ngIf="companyLogo[0] || CompanyDetails.companyLogo">
                          <img id="previewLogo" [src]="previewCompanyURL" weight="50%" />
                        </div>
                        <div class="pre-navbar">
                          <div class="d-flex align-items-center px-2 py-2 pre-navbar-item">
                            <div class="img18 mr-1">
                              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 32 32">
                                <g id="Group_616" data-name="Group 616" transform="translate(-42.312 1.171)">
                                  <path class="previewcsvg" id="Path_792" data-name="Path 792"
                                    d="M68.312,4.829v4h-20v-4h20m0-2h-20a2,2,0,0,0-2,2v4a2,2,0,0,0,2,2h20a2,2,0,0,0,2-2v-4A2,2,0,0,0,68.312,2.829Z" />
                                  <path class="previewcsvg" id="Path_793" data-name="Path 793"
                                    d="M52.312,14.829v10h-4v-10h4m0-2h-4a2,2,0,0,0-2,2v10a2,2,0,0,0,2,2h4a2,2,0,0,0,2-2v-10A2,2,0,0,0,52.312,12.829Z" />
                                  <path class="previewcsvg" id="Path_794" data-name="Path 794"
                                    d="M68.312,14.829v10h-10v-10h10m0-2h-10a2,2,0,0,0-2,2v10a2,2,0,0,0,2,2h10a2,2,0,0,0,2-2v-10A2,2,0,0,0,68.312,12.829Z" />
                                  <rect id="Rectangle_853" data-name="Rectangle 853" width="32" height="32"
                                    transform="translate(42.312 -1.171)" fill="none" />
                                </g>
                              </svg>
                            </div>
                            <div>University</div>
                          </div>

                          <div class="d-flex align-items-center px-2 py-2 pre-navbar-item">
                            <div class="img18 mr-1">
                              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 32 32">
                                <g id="Group_793" data-name="Group 793" transform="translate(-306.587 -61.66)">
                                  <path id="Path_891" data-name="Path 891"
                                    d="M333.587,86.66h-6a3,3,0,0,0-3,3v2h2v-2a1,1,0,0,1,1-1h6a1,1,0,0,1,1,1v2h2v-2A3,3,0,0,0,333.587,86.66Z"
                                    class="previewcsvg" />
                                  <path id="Path_892" data-name="Path 892"
                                    d="M326.587,81.66a4,4,0,1,0,4-4A4,4,0,0,0,326.587,81.66Zm6,0a2,2,0,1,1-2-2A2,2,0,0,1,332.587,81.66Z"
                                    class="previewcsvg" />
                                  <path id="Path_893" data-name="Path 893"
                                    d="M312.587,82.66v-1h-2v1a7,7,0,0,0,7,7h3v-2h-3A5,5,0,0,1,312.587,82.66Z"
                                    class="previewcsvg" />
                                  <rect id="Rectangle_903" data-name="Rectangle 903" width="7" height="2"
                                    transform="translate(325.587 71.66)" class="previewcsvg" />
                                  <rect id="Rectangle_904" data-name="Rectangle 904" width="10" height="2"
                                    transform="translate(325.587 67.66)" class="previewcsvg" />
                                  <rect id="Rectangle_905" data-name="Rectangle 905" width="10" height="2"
                                    transform="translate(325.587 63.66)" class="previewcsvg" />
                                  <path id="Path_894" data-name="Path 894"
                                    d="M317.587,72.66h-6a3,3,0,0,0-3,3v2h2v-2a1,1,0,0,1,1-1h6a1,1,0,0,1,1,1v2h2v-2A3,3,0,0,0,317.587,72.66Z"
                                    class="previewcsvg" />
                                  <path id="Path_895" data-name="Path 895"
                                    d="M314.587,71.66a4,4,0,1,0-4-4A4,4,0,0,0,314.587,71.66Zm0-6a2,2,0,1,1-2,2A2,2,0,0,1,314.587,65.66Z"
                                    class="previewcsvg" />
                                  <rect id="_Transparent_Rectangle_" data-name=" Transparent Rectangle " width="32"
                                    height="32" transform="translate(306.587 61.66)" fill="none" />
                                </g>
                              </svg>
                            </div>
                            <div>Referrals</div>
                          </div>

                          <div class="d-flex align-items-center px-2 py-2 pre-navbar-item">
                            <div class="img18 mr-1">
                              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 32 32">
                                <g id="Group_792" data-name="Group 792" transform="translate(-9103 -17228)">
                                  <path id="Path_896" data-name="Path 896"
                                    d="M360.412,65.66h-2v26a2,2,0,0,0,2,2h26v-2h-26Z"
                                    transform="translate(8746.588 17164.34)" class="previewcsvg" />
                                  <path id="Path_897" data-name="Path 897"
                                    d="M386.412,79.66h-7v2H383l-7.59,7.59-4.29-4.3a1,1,0,0,0-1.415-.006l-.005.006-7.29,7.3,1.41,1.41,6.59-6.59,4.29,4.3a1,1,0,0,0,1.414,0l.006,0,8.29-8.3v3.59h2Z"
                                    transform="translate(8743.588 17163.34)" class="previewcsvg" />
                                  <rect id="_Transparent_Rectangle_" data-name=" Transparent Rectangle " width="32"
                                    height="32" transform="translate(9103 17228)" fill="none" />
                                </g>
                              </svg>
                            </div>
                            <div>Bi Analytics</div>
                          </div>

                          <div class="d-flex align-items-center px-2 py-2 pre-navbar-item">
                            <div class="img18 mr-1">
                              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 32 32">
                                <g id="Group_788" data-name="Group 788" transform="translate(-466.919 -61.66)">
                                  <path id="Path_901" data-name="Path 901"
                                    d="M496.919,69.66h-4.1a5,5,0,0,0-9.8,0h-14.1v2h14.1a5,5,0,0,0,9.8,0h4.1Zm-9,4a3,3,0,1,1,3-3A2.946,2.946,0,0,1,487.919,73.66Z"
                                    class="previewcsvg" />
                                  <path id="Path_902" data-name="Path 902"
                                    d="M468.919,85.66h4.1a5,5,0,0,0,9.8,0h14.1v-2h-14.1a5,5,0,0,0-9.8,0h-4.1Zm9-4a3,3,0,1,1-3,3A2.946,2.946,0,0,1,477.919,81.66Z"
                                    class="previewcsvg" />
                                  <rect id="_Transparent_Rectangle_" data-name=" Transparent Rectangle " width="32"
                                    height="32" transform="translate(466.919 61.66)" fill="none" />
                                </g>
                              </svg>
                            </div>
                            <div>Administration</div>
                          </div>
                        </div>
                        <div class="clr"></div>
                        <div class="pre-version p-2 align-items-end pre-abs" style="bottom: 0; left: 0; right: 0">
                          VERSION 1.1.0
                        </div>
                      </div>
                      <div class="pre-content d-flex flex-column">
                        <div class="pre-topbar p-3 d-flex">
                          <div class="img12 mr-2">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 72.23">
                              <defs></defs>
                              <g id="Layer_2" data-name="Layer 2">
                                <g id="Layer_1-2" data-name="Layer 1">
                                  <path class="previewcsvg"
                                    d="M0,35c.28-.08.25-.36.34-.55a4.15,4.15,0,0,1,3.74-2.54c.32,0,.65,0,1,0H95a7.63,7.63,0,0,1,1.65.1,4.16,4.16,0,0,1-.69,8.25c-.33,0-.65,0-1,0q-44.65,0-89.33,0C3,40.31,1.06,39.67,0,37.16Z" />
                                  <path class="cls-1"
                                    d="M0,67c.28-.14.24-.44.35-.66A4,4,0,0,1,4.11,63.9c2.44,0,4.88,0,7.32,0H95.38c2.41,0,4,1.12,4.5,3.21a4.13,4.13,0,0,1-3.5,5,15.17,15.17,0,0,1-1.65.06q-44.37,0-88.74,0c-2.64,0-4.81-.42-6-3Z" />
                                  <path class="previewcsvg"
                                    d="M0,3.17c.28-.17.25-.5.38-.75A4.05,4.05,0,0,1,3.9,0c.26,0,.52,0,.78,0H95.29c2.52,0,4.14,1.17,4.62,3.32a4.15,4.15,0,0,1-3.64,4.95c-.45,0-.91,0-1.37,0q-44.76,0-89.53,0C2.79,8.34,1,7.58,0,5.13Z" />
                                </g>
                              </g>
                            </svg>
                          </div>
                          <div class="pre-header-text">Lead Dashboard</div>

                          <div class="pre-topbar-right-set ml-auto d-flex">
                            <div class="mr-2">
                              <button class="btn btn-secondary btn-sm"
                                [ngStyle]="{ 'background-color': CompanyDetails.Theme.table_popup_button_color,'border-color':CompanyDetails.Theme.table_popup_button_color }"
                                type="button">
                                Create Ticket
                              </button>
                            </div>
                            <div class="img12 mr-3">
                              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 49.62 49.62">
                                <g id="Layer_2" data-name="Layer 2">
                                  <g id="Layer_1-2" data-name="Layer 1">
                                    <path id="ic_call_24px" data-name="ic call 24px" class="previewcsvg"
                                      d="M10,21.48A41.73,41.73,0,0,0,28.15,39.64l6.06-6.06A2.74,2.74,0,0,1,37,32.92a31.67,31.67,0,0,0,9.85,1.57,2.75,2.75,0,0,1,2.75,2.76h0v9.62a2.75,2.75,0,0,1-2.75,2.75A46.86,46.86,0,0,1,0,2.76,2.75,2.75,0,0,1,2.76,0h9.65a2.75,2.75,0,0,1,2.75,2.76,31.65,31.65,0,0,0,1.57,9.84A2.74,2.74,0,0,1,16,15.41Z" />
                                  </g>
                                </g>
                              </svg>
                            </div>
                            <div class="img12 mr-3">
                              <svg id="Layer_2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 319.16 351.27">
                                <g id="Layer_1-2">
                                  <path class="previewcsvg"
                                    d="M263.24,303.41c-12.97,0-25.93,.07-38.9-.06-2.37-.02-3.42,.6-4.15,2.93-8.55,27.07-32.94,45.07-60.78,45-27.7-.08-52.13-18.12-60.48-44.89-.81-2.6-2.03-3.04-4.45-3.03-25.68,.08-51.36,.09-77.04,.04-11.22-.02-18.45-7.7-17.33-18.17,.36-3.35,1.98-6.1,3.94-8.72,7.78-10.35,15.55-20.72,23.34-31.07,13.65-18.11,20.36-38.56,20.45-61.19,.08-20.07-.24-40.15,.15-60.21,1.04-52.22,41.07-98.01,92.61-106.21,2.31-.37,3.01-1.13,3.19-3.47C144.44,6.17,151.52-.09,159.66,0c8.08,.09,14.97,6.21,15.6,14.22,.2,2.48,.97,3.25,3.43,3.64,45.64,7.15,83.75,45.48,90.96,91.19,1.09,6.92,1.57,13.85,1.56,20.84,0,17.95,0,35.9,0,53.86,.01,22.91,6.76,43.57,20.62,61.88,7.75,10.23,15.35,20.56,23.12,30.77,4.3,5.65,5.62,11.66,2.42,18.13-3.23,6.53-8.97,8.92-16,8.89-12.72-.04-25.43-.01-38.15-.01Zm7.93-32.09c-1.85-2.48-3.32-4.47-4.81-6.45-17.52-23.33-26.72-49.52-26.91-78.75-.12-18.7-.03-37.4-.04-56.1,0-5.24-.4-10.44-1.22-15.63-6.88-43.92-51.22-74.31-94.66-64.84-37.83,8.25-63.56,39.88-63.84,78.68-.14,18.45-.1,36.9-.01,55.35,.15,30.45-9.11,57.72-27.46,81.97-1.38,1.82-2.73,3.66-4.3,5.77h223.25Zm-84.35,32.39h-54.62c6.93,10.42,16.14,15.8,28.14,15.51,11.33-.28,20.06-5.58,26.48-15.51Z" />
                                </g>
                              </svg>
                            </div>
                            <div class="img12">
                              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
                                <g id="Layer_2" data-name="Layer 2">
                                  <g id="Layer_1-2" data-name="Layer 1">
                                    <path class="previewcsvg"
                                      d="M46.88,0h6.25c.25.05.49.12.75.16,2.56.4,5.17.64,7.69,1.21a49.91,49.91,0,0,1,37.67,40c.31,1.82.51,3.66.76,5.49v6.25a7.37,7.37,0,0,0-.16.76c-.34,2.31-.56,4.65-1,6.94-4,19.38-20.59,35.24-40.1,38.41-1.85.3-3.72.52-5.58.77H46.88c-.22,0-.44-.12-.66-.16-2.28-.33-4.58-.55-6.84-1C20,95,3.93,78.28.78,58.81.48,56.92.26,55,0,53.12V46.87c.32-2.21.56-4.44,1-6.64A50,50,0,0,1,41.29.77C43.14.46,45,.25,46.88,0ZM20,82.21A31.59,31.59,0,0,1,38.08,62c-5.63-3.94-9-9.2-9.7-16a21,21,0,0,1,6.16-17.54,21.67,21.67,0,0,1,30.51-.4,20.91,20.91,0,0,1,6.66,15.12c.14,8-3.32,14.13-9.75,18.79A31.6,31.6,0,0,1,80,82.23,44.09,44.09,0,1,0,7.93,36.71,43.64,43.64,0,0,0,20,82.21ZM50,94.15a44.51,44.51,0,0,0,24-7.1,1.41,1.41,0,0,0,.69-1.83,25.34,25.34,0,0,0-49.47.23A1.24,1.24,0,0,0,25.87,87,44.35,44.35,0,0,0,50,94.15Zm0-66.33A15.89,15.89,0,1,0,65.88,43.71,15.9,15.9,0,0,0,50,27.82Z" />
                                  </g>
                                </g>
                              </svg>
                            </div>
                          </div>
                        </div>
                        <div class="pre-content-section m-2 p-3">
                          <div class="d-flex">
                            <div class="pre-content-card text-center py-3 px-1 mr-3">
                              <p>Total Students</p>
                              <div class="pre-card-count">545</div>
                            </div>

                            <div class="pre-content-card text-center py-3 px-1 mr-3">
                              <p>Completed Students</p>
                              <div class="pre-card-count">305</div>
                            </div>

                            <div class="pre-content-card text-center py-3 px-1 mr-3">
                              <p>Validated Students</p>
                              <div class="pre-card-count">100</div>
                            </div>

                            <div class="pre-content-card text-center py-3 px-1 mr-3">
                              <p>Documents Submitted</p>
                              <div class="pre-card-count">45</div>
                            </div>
                          </div>
                          <div class="mt-3">
                            <div class="d-flex">
                              <div class="pre-header-text">
                                Total Applications
                              </div>
                              <div class="ml-auto"></div>
                            </div>
                          </div>
                          <div class="mt-1">
                            <div class="d-flex p-1 pre-table-search">
                              <div>
                                <input type="text" class="pre-seach-field" placeholder="Search.." />
                              </div>
                            </div>
                          </div>

                          <div class="mt-1">
                            <div class="table-responsive">
                              <table class="table table-md pre-data-table">
                                <tr
                                  [ngStyle]="{ 'background-color': CompanyDetails.Theme.table_popup_button_color,'border-color':CompanyDetails.Theme.table_popup_button_color,'color': 'white' }">
                                  <th>Created Date</th>
                                  <th>Student Name</th>
                                  <th>Assign To</th>
                                  <th>Enrollment Term</th>
                                  <th>Doc Status</th>
                                </tr>
                                <tr>
                                  <td>23 Sep, 2022 10:10</td>
                                  <td>
                                    <a class="pre-link">Krishna Jakkamsetti</a>
                                  </td>
                                  <td>Priyanka</td>
                                  <td>G4</td>
                                  <td>Pending</td>
                                </tr>
                                <tr>
                                  <td>23 Sep, 2022 10:10</td>
                                  <td>
                                    <a class="pre-link">Krishna Jakkamsetti</a>
                                  </td>
                                  <td>Priyanka</td>
                                  <td>G4</td>
                                  <td>Pending</td>
                                </tr>
                                <tr>
                                  <td>23 Sep, 2022 10:10</td>
                                  <td>
                                    <a href="#" class="pre-link">Krishna Jakkamsetti</a>
                                  </td>
                                  <td>Priyanka</td>
                                  <td>G4</td>
                                  <td>Pending</td>
                                </tr>
                                <tr>
                                  <td>23 Sep, 2022 10:10</td>
                                  <td>
                                    <a class="pre-link">Krishna Jakkamsetti</a>
                                  </td>
                                  <td>Priyanka</td>
                                  <td>G4</td>
                                  <td>Pending</td>
                                </tr>
                                <tr>
                                  <td>23 Sep, 2022 10:10</td>
                                  <td>
                                    <a class="pre-link">Krishna Jakkamsetti</a>
                                  </td>
                                  <td>Priyanka</td>
                                  <td>G4</td>
                                  <td>Pending</td>
                                </tr>
                                <tr>
                                  <td>23 Sep, 2022 10:10</td>
                                  <td>
                                    <a class="pre-link">Krishna Jakkamsetti</a>
                                  </td>
                                  <td>Priyanka</td>
                                  <td>G4</td>
                                  <td>Pending</td>
                                </tr>
                              </table>
                            </div>
                          </div>
                        </div>
                        <div class="pre-footer px-3 py-1 align-items-end">
                          Powered by <a href="#">Avantao</a>
                          <div class="clr"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </form>
      </mat-step>

    </mat-stepper>
  </div>
</div>

<div class="m-5" *ngIf="isEditProject">
  <div class="p-3 b-radius-10 bg-w">
    <mat-stepper [linear]="isLinear" #stepper>
      <mat-step [stepControl]="secondFormGroup" label="Edit Project">
        <div class="pt-2 d-flex">
          <div class="ml-auto d-flex">
            <div>
              <img src="../../../assets/images/info.svg" class="img16 mr-2 hand" ngbTooltip="Information"
                placement="top" tooltipClass="my-custom-class" />
            </div>
            <div>
              <img src="../../../assets/images/video-play.svg" class="img16 hand" ngbTooltip="Demo video"
                placement="top" tooltipClass="my-custom-class" />
            </div>
          </div>
        </div>
        <form [formGroup]="secondFormGroup">
          <ng-template matStepLabel>Edit Project</ng-template>
          <form class="was-validated" novalidate #form2="ngForm" [ngClass]="{ 'was-validated': form2.submitted }"
            *ngFor="let p of SelectedProjects; let indexOfelement = index">
            <div class="form-row">
              <div class="form-group col-md-6">
                <label for="inputEmail4">Project Name</label>
                <input type="text" class="form-control" id="pname" placeholder="Enter Project Name"
                  [(ngModel)]="p.ProjectName" name="pname" required />
                <div class="valid-feedback">Valid.</div>
                <div class="invalid-feedback">
                  Please enter the project name.
                </div>
              </div>
              <div class="form-group col-md-6">

                <label for="inputEmail4">Project Type</label>
                <select class="form-control" (change)="onChange($event)" [(ngModel)]="p.ProjectType" required>
                  <option selected="true" disabled>Select project type</option>
                  <option value="{{item.ProjectType}}" *ngFor="let item of ProjectTypeList">{{item.ProjectType}}
                  </option>
                  <option value="other">Other</option>

                </select>

                <div class="valid-feedback">Valid.</div>
                <div class="invalid-feedback">
                  Please enter the project name.
                </div>
              </div>
            </div>
            <div class="form-row" *ngIf="p.ProjectType=='other'">
              <div class="form-group col-md-6">
                <label for="ProjectOther">Other Project Type</label>
                <div class="d-flex">
                  <input type="text" class="form-control" id="ProjectOther" placeholder="Enter Other Project Type"
                    [(ngModel)]="p.ProjectOther" name="ProjectOther" required />

                </div>
              </div>

            </div>

            <div class="form-row">
              <div class="form-group col-md-6">
                <label for="inputEmail4">Project telephone number (VoIP)</label>
                <input type="text" [(ngModel)]="p.ProjectTelephoneNumber" class="form-control" id="voipNumber"
                  name="voipNumber" placeholder="" [readonly]="isReadOnly" [required]="!isReadOnly" />
                <div class="valid-feedback">Valid.</div>
                <div class="invalid-feedback">
                  Please enter the voip number.
                </div>
              </div>
              <div class="form-group col-md-6">
                <label for="inputAddress2">Outgoing Number</label>
                <input type="text" [(ngModel)]="p.ProjectOutgoingNumber" class="form-control" id="outgoingNumber"
                  name="outgoingNumber" placeholder="" [readonly]="isReadOnly" [required]="!isReadOnly" />
                <div class="valid-feedback">Valid.</div>
                <div class="invalid-feedback">
                  Please enter the outgoing number.
                </div>
              </div>
            </div>

            <div class="form-row">
              <div class="form-group col-md-3">
                <label for="inputEmail4" style="display: block">Project Email services</label>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" [(ngModel)]="p.EmailService.choice" type="radio"
                    [matMenuTriggerFor]="menu2" name="inlineRadioOptions1" id="EmailYes" value="Yes" required />
                  <div class="invalid-feedback"></div>
                  <label class="form-check-label" for="inlineRadio1">Yes</label>
                  <mat-menu #menu2="matMenu" class="px-3 pt-1">
                    <div class="form-group custom-popup">
                      <label for="inputEmail4">Incoming</label>
                      <input type="text" class="form-control" [(ngModel)]="p.EmailService.incoming" id="incoming"
                        name="incoming" placeholder=" " (click)="$event.stopPropagation()"
                        [required]="p.EmailService.choice == 'yes' " />
                    </div>
                    <div class="form-group custom-popup">
                      <label for="inputEmail4">Outgoing</label>
                      <input type="text" class="form-control" [(ngModel)]="p.EmailService.outgoing" id="outgoing"
                        name="outgoing" placeholder=" " (click)="$event.stopPropagation()"
                        [required]="p.EmailService.choice == 'yes' " />
                    </div>
                    <div class="form-group custom-popup">
                      <label for="inputEmail4">Mail Group</label>
                      <input type="text" class="form-control" [(ngModel)]="p.EmailService.mailGroup" id="mailGroup"
                        name="mailGroup" placeholder=" " (click)="$event.stopPropagation()"
                        [required]="p.EmailService.choice == 'yes' " />
                    </div>
                  </mat-menu>
                </div>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" [(ngModel)]="p.EmailService.choice" type="radio"
                    name="inlineRadioOptions1" id="EmailNo" value="No" required />
                  <label class="form-check-label" for="inlineRadio2">No</label>
                </div>
              </div>
              <div class="form-group col-md-3">
                <label for="inputEmail4" style="display: block">Project SMS services</label>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio" name="inlineRadioOptions2" id="smsYes" value="Yes"
                    [(ngModel)]="p.SmsService" required />
                  <label class="form-check-label" for="inlineRadio1">Yes</label>
                </div>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio" name="inlineRadioOptions2" id="smsNo" value="No"
                    [(ngModel)]="p.SmsService" required />
                  <label class="form-check-label" for="inlineRadio2">No</label>
                </div>
              </div>

              <div class="form-group col-md-3">
                <label for="inputEmail4" style="display: block">Project Whatsapp services</label>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" [(ngModel)]="p.WhatsappService" type="radio"
                    name="inlineRadioOptions3" id="whatsappYes" value="Yes" required />
                  <label class="form-check-label" for="inlineRadio1">Yes</label>
                </div>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" [(ngModel)]="p.WhatsappService" type="radio"
                    name="inlineRadioOptions3" id="whatsappNo" value="No" required />
                  <label class="form-check-label" for="inlineRadio2">No</label>
                </div>
              </div>
              <div class="form-group col-md-3 text-right"></div>
            </div>
            <div class="mt-2 mb-3"></div>
            <div class="header-lg mb-2 mt-2">Select Modules</div>
            <div class="form-row">
              <div class="" [ngClass]="
                  isClass == true
                    ? 'form-group col-md-8 module-card'
                    : 'form-group col-md-4 module-card'
                ">
                <div class="mb-2">
                  <mat-accordion class="mb-3" (click)="$event.stopPropagation()">
                    <mat-expansion-panel>
                      <mat-expansion-panel-header>
                        <mat-panel-title> Lead management </mat-panel-title>
                        <!-- <mat-slide-toggle (click)="$event.stopPropagation()" class="mr-3"></mat-slide-toggle> -->
                        <mat-slide-toggle (change)="openState($event)"
                          [checked]="ProjectDetails.Modules.leadManagement.LeadManagement" class="mr-3">
                        </mat-slide-toggle>
                      </mat-expansion-panel-header>

                      <section class="example-section" (click)="$event.stopPropagation();">
                        <span class="example-list-section">
                          <div class="d-flex">
                            <div>
                              <mat-checkbox (click)="$event.stopPropagation();" class="example-margin"
                                (change)="selectAllFeedbacks()" [checked]="allComplete" [color]="feedBacks.color"
                                [indeterminate]="someComplete()" [checked]="feedBacks.selected">
                                {{feedBacks.name}}

                              </mat-checkbox>
                            </div>
                            <div class="ml-auto">
                              <div class="d-flex mb-1">
                                <div>
                                  <button class="btn btn-link btn-sm p-0 ml-0 mr-2 mb-0 mt-0"
                                    [matMenuTriggerFor]="moduleSearch" (click)="$event.stopPropagation();">
                                    <img src="../../../assets/img/search.svg" class="img14">
                                  </button>
                                  <button class="btn btn-link btn-sm p-0 ml-0 mb-0 mt-0"
                                    [matMenuTriggerFor]="newFeedback" (click)="$event.stopPropagation();">
                                    <img src="../../../assets/img/add-g.svg" class="img14">
                                  </button>

                                  <mat-menu #moduleSearch="matMenu" xPosition="before" yPosition="below"
                                    class="px-3 pt-1 w250" style="width:250px!important">
                                    <div class="form-group custom-popup mb-1">
                                      <input type="text" class="form-control" id="inputAddress2"
                                        placeholder="Search by keyword" (click)="$event.stopPropagation();">
                                    </div>
                                  </mat-menu>


                                  <mat-menu #newFeedback="matMenu" xPosition="before" yPosition="below"
                                    class="px-3 pt-1 w250" style="width:250px!important">
                                    <div class="form-group custom-popup" style="width:240px!important;">
                                      <label for="inputEmail4">Feedback Name</label>
                                      <input type="text" class="form-control" id="inputAddress2" placeholder=" "
                                        (click)="$event.stopPropagation();">
                                    </div>

                                    <div class="d-flex align-items-center justify-content-end">
                                      <button class="btn btn-primary btn-sm mr-2">Add</button>
                                      <button class="btn btn-light btn-sm">Cancel</button>
                                    </div>
                                  </mat-menu>

                                </div>
                              </div>
                            </div>
                          </div>
                        </span>

                        <span class="example-list-section">

                          <ul class="float-left itemList">
                            <li *ngFor="let subtask of feedBacks.subtasks; let i = index;">
                              <mat-checkbox [(ngModel)]="subtask.selected" [checked]="subtask.selected"
                                (change)="addFeedback(subtask, i)" [color]="subtask.color"
                                (ngModelChange)="updateAllComplete()">
                                {{subtask.name}}
                              </mat-checkbox>
                            </li>
                          </ul>



                        </span>
                      </section>
                      <div style="clear:both"></div>
                      <div class="float-right p-2">
                        Pagination
                      </div>

                      <div style="clear:both"></div>
                      <hr>
                      <div style="clear:both"></div>


                      <section class="example-section">
                        <span class="example-list-section">

                          <div class="d-flex">
                            <div>
                              <mat-checkbox class="example-margin" [checked]="channel.selected" [color]="channel.color"
                                (change)=" selectAllChannels()">
                                {{channel.name}}
                              </mat-checkbox>
                            </div>
                            <div class="ml-auto">
                              <div class="d-flex">
                                <div>
                                  <img src="../../../assets/img/add-g.svg" class="img14 mr-2">
                                </div>
                                <div>
                                  <img src="../../../assets/img/search.svg" class="img14">
                                </div>
                              </div>
                            </div>
                          </div>


                        </span>
                        <span class="example-list-section">
                          <!-- <ul>
                            <li *ngFor="let subtask of channel.subtasks">
                              <mat-checkbox [(ngModel)]="subtask.completed" [color]="subtask.color" []
                                (ngModelChange)="updateAllComplete()">
                                {{subtask.name}}
                              </mat-checkbox>
                            </li>
                          </ul> -->

                          <ul class="float-left itemList">
                            <li *ngFor="let subtask of channel.subtasks; let i = index;">
                              <mat-checkbox [(ngModel)]="subtask.selected" [checked]="subtask.selected"
                                (change)="addChannel(subtask, i)" [color]="subtask.color"
                                (ngModelChange)="updateAllComplete()">
                                {{subtask.name}}
                              </mat-checkbox>
                            </li>
                          </ul>
                        </span>
                      </section>

                      <div style="clear:both"></div>
                      <hr>
                      <div style="clear:both"></div>

                      <section class="example-section">
                        <span class="example-list-section">

                          <div class="d-flex">
                            <div>
                              <mat-checkbox class="example-margin" [checked]="leadTableColoumn.selected"
                                [color]="leadTableColoumn.color" (change)=" selectAllColumns()">
                                {{leadTableColoumn.name}}
                              </mat-checkbox>
                            </div>
                            <div class="ml-auto">
                              <div class="d-flex">
                                <div>
                                  <img src="../../../assets/img/add-g.svg" class="img14 mr-2">
                                </div>
                                <div>
                                  <img src="../../../assets/img/search.svg" class="img14">
                                </div>
                              </div>
                            </div>
                          </div>


                        </span>
                        <span class="example-list-section">


                          <ul class="float-left itemList">
                            <li *ngFor="let subtask of leadTableColoumn.subtasks; let i = index;">
                              <mat-checkbox [(ngModel)]="subtask.selected" [checked]="subtask.selected"
                                (change)="addColumns(subtask, i)" [color]="subtask.color"
                                (ngModelChange)="updateAllComplete()">
                                {{subtask.name}}
                              </mat-checkbox>
                            </li>
                          </ul>
                        </span>
                      </section>
                    </mat-expansion-panel>
                  </mat-accordion>
                </div>
              </div>
            </div>
            <div class="p-2">
              <div class="d-flex align-items-center justify-content-end">
                <div class="d-flex ml-auto">
                  <button class="btn btn-secondary btn-lg mr-2" (click)="UpdateProject(form2)" type="button">
                    <img src="../../../assets/img/ticker-white.svg" class="img12 mr-2" />Update Project
                  </button>

                  <!-- <button matStepperNext class="btn btn-secondary btn-sm" type="button">
                    Next
                  </button> -->
                </div>
              </div>
            </div>
          </form>
          <!-- <hr />
          <div
            class="p-3 d-flex flex-column align-items-center justify-content-center"
          >
            <img
              src="../../../assets/img/finger-rise.svg"
              class="img50 text-center"
            />
            <div class="p-3 text-center">
              Succes.. Project Created successfully
            </div>
          </div>
          <hr />
          <table class="table table-sm table-borderless doc-table2">
            <tr>
              <td style="vertical-align: bottom">
                <div class="header-12 lh150">#897867</div>
                <div class="header-lg">UCLA University</div>
              </td>
              <td style="vertical-align: bottom">
                <div class="blue-pill mr-2 float-left">
                  <img
                    src="../../../assets/images/incoming-call.svg"
                    class="img12 mr-2"
                  />8976789689
                </div>
                <div class="pink-pill mr-2 float-left">
                  <img
                    src="../../../assets/images/outgoing-call.svg"
                    class="img12 mr-2"
                  />1123789689
                </div>
              </td>
              <td style="vertical-align: bottom">
                <div class="light-pill mr-2 float-left">
                  <img
                    src="../../../assets/img/message-b.svg"
                    class="img12 hand"
                  />
                </div>
                <div class="light-pill mr-2 float-left">
                  <img
                    src="../../../assets/img/email-g.svg"
                    class="img12 hand"
                  />
                </div>
                <div class="light-pill mr-2 float-left">
                  <img
                    src="../../../assets/img/whatsapp.png"
                    class="img12 hand"
                  />
                </div>
              </td>
              <td class="text-right" style="vertical-align: bottom">
                <img
                  src="../../../assets/images/edit.svg"
                  class="img11 mr-2 hand"
                />
                <img
                  src="../../../assets/images/delete.svg"
                  class="img11 hand"
                />
              </td>
            </tr>
            <tr>
              <td colspan="4">
                <div class="header-12">Modules</div>
                <div class="pt-2">
                  <div class="light-pill mr-2 float-left">Lead management</div>
                  <div class="light-pill mr-2 float-left">Referrals</div>
                  <div class="light-pill mr-2 float-left">Marketing</div>
                  <div class="light-pill mr-2 float-left">BI Analytics</div>
                  <div class="light-pill mr-2 float-left">
                    Incident Management
                  </div>
                  <div class="light-pill mr-2 float-left">My Team</div>
                </div>
              </td>
            </tr>
          </table>
          <hr />
          <div class="p-4">
            <div class="d-flex flex-column">
              <div class="p-3 text-center header-lg">
                Do you want create another Project..
              </div>
              <div style="margin: 0 auto">
                <button class="btn btn-secondary btn-lg ml-2">
                  <img
                    src="../../../assets/img/add-w.svg"
                    class="img12 mr-2"
                  />Add Project
                </button>
              </div>
            </div>
          </div> -->

          <div class="float-right">
            <!-- <button matStepperPrevious class="btn btn-secondary btn-sm mr-4">
              Back
            </button> -->
          </div>
        </form>
      </mat-step>

    </mat-stepper>
  </div>
</div>

<div class="m-5" *ngIf="isCreateProject">
  <div class="p-3 b-radius-10 bg-w">
    <mat-stepper [linear]="isLinear" #stepper>
      <mat-step [stepControl]="secondFormGroup" label="Create Project">
        <div class="pt-2 d-flex">
          <div class="ml-auto d-flex">
            <div>
              <img src="../../../assets/images/info.svg" class="img16 mr-2 hand" ngbTooltip="Information"
                placement="top" tooltipClass="my-custom-class" />
            </div>
            <div>
              <img src="../../../assets/images/video-play.svg" class="img16 hand" ngbTooltip="Demo video"
                placement="top" tooltipClass="my-custom-class" />
            </div>
          </div>
        </div>
        <form [formGroup]="secondFormGroup">
          <ng-template matStepLabel>Create Project</ng-template>
          <form class="was-validated" novalidate #form2="ngForm" [ngClass]="{ 'was-validated': form2.submitted }"
            *ngFor="let p of SelectedProjects; let indexOfelement = index">
            <div class="form-row">
              <div class="form-group col-md-6">
                <label for="inputEmail4">Project Namess</label>
                <input type="text" class="form-control" id="pname" placeholder="Enter Project Name"
                  [(ngModel)]="p.ProjectName" name="pname" required />
                <div class="valid-feedback">Valid.</div>
                <div class="invalid-feedback">
                  Please enter the project name.
                </div>
              </div>
              <div class="form-group col-md-6">
                <label for="inputEmail4">Project Type</label>
                <select class="form-control" (change)="onChange($event)" [(ngModel)]="p.ProjectType" required>
                  <option value="" disabled>Select project type</option>
                  <option value="{{item.ProjectType}}" *ngFor="let item of ProjectTypeList">{{item.ProjectType}}
                  </option>
                  <option value="other">Other</option>

                </select>

                <div class="valid-feedback">Valid.</div>
                <div class="invalid-feedback">
                  Please enter the project name.
                </div>
              </div>
            </div>

            <div class="form-row" *ngIf="p.ProjectType=='other'">
              <div class="form-group col-md-6">
                <label for="ProjectOther">Other Project Type</label>
                <div class="d-flex">
                  <input type="text" class="form-control" id="ProjectOther" placeholder="Enter Other Project Type"
                    [(ngModel)]="p.ProjectOther" name="ProjectOther" required />

                </div>
              </div>

            </div>
            <div class="form-row">
              <div class="form-group col-md-6">
                <label for="inputEmail4">Project telephone number (VoIP)</label>
                <input type="text" [(ngModel)]="p.ProjectTelephoneNumber" class="form-control" id="voipNumber"
                  name="voipNumber" placeholder="" required />
                <div class="valid-feedback">Valid.</div>
                <div class="invalid-feedback">
                  Please enter the voip number.
                </div>
              </div>
              <div class="form-group col-md-6">
                <label for="inputAddress2">Outgoing Number</label>
                <input type="text" [(ngModel)]="p.ProjectOutgoingNumber" class="form-control" id="outgoingNumber"
                  name="outgoingNumber" placeholder="" required />
                <div class="valid-feedback">Valid.</div>
                <div class="invalid-feedback">
                  Please enter the outgoing number.
                </div>
              </div>
            </div>

            <div class="form-row">
              <div class="form-group col-md-3">
                <label for="inputEmail4" style="display: block">Project Email services</label>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" [(ngModel)]="p.EmailService.choice" type="radio"
                    [matMenuTriggerFor]="menu2" name="inlineRadioOptions1" id="EmailYes" value="Yes" required />
                  <div class="invalid-feedback"></div>
                  <label class="form-check-label" for="inlineRadio1">Yes</label>
                  <mat-menu #menu2="matMenu" class="px-3 pt-1">
                    <div class="form-group custom-popup">
                      <label for="inputEmail4">Incoming</label>
                      <input type="text" class="form-control" [(ngModel)]="p.EmailService.incoming" id="incoming"
                        name="incoming" placeholder=" " (click)="$event.stopPropagation()"
                        [required]="p.EmailService.choice == 'yes' " />
                    </div>
                    <div class="form-group custom-popup">
                      <label for="inputEmail4">Outgoing</label>
                      <input type="text" class="form-control" [(ngModel)]="p.EmailService.outgoing" id="outgoing"
                        name="outgoing" placeholder=" " (click)="$event.stopPropagation()"
                        [required]="p.EmailService.choice == 'yes' " />
                    </div>
                    <div class="form-group custom-popup">
                      <label for="inputEmail4">Mail Group</label>
                      <input type="text" class="form-control" [(ngModel)]="p.EmailService.mailGroup" id="mailGroup"
                        name="mailGroup" placeholder=" " (click)="$event.stopPropagation()"
                        [required]="p.EmailService.choice == 'yes' " />
                    </div>
                  </mat-menu>
                </div>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" [(ngModel)]="p.EmailService.choice" type="radio"
                    name="inlineRadioOptions1" id="EmailNo" value="No" required />
                  <label class="form-check-label" for="inlineRadio2">No</label>
                </div>
              </div>
              <div class="form-group col-md-3">
                <label for="inputEmail4" style="display: block">Project SMS services</label>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio" name="inlineRadioOptions2" id="smsYes" value="Yes"
                    [(ngModel)]="p.SmsService" required />
                  <label class="form-check-label" for="inlineRadio1">Yes</label>
                </div>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio" name="inlineRadioOptions2" id="smsNo" value="No"
                    [(ngModel)]="p.SmsService" required />
                  <label class="form-check-label" for="inlineRadio2">No</label>
                </div>
              </div>

              <div class="form-group col-md-3">
                <label for="inputEmail4" style="display: block">Project Whatsapp services</label>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" [(ngModel)]="p.WhatsappService" type="radio"
                    name="inlineRadioOptions3" id="whatsappYes" value="Yes" required />
                  <label class="form-check-label" for="inlineRadio1">Yes</label>
                </div>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" [(ngModel)]="p.WhatsappService" type="radio"
                    name="inlineRadioOptions3" id="whatsappNo" value="No" required />
                  <label class="form-check-label" for="inlineRadio2">No</label>
                </div>
              </div>
              <div class="form-group col-md-3 text-right"></div>
            </div>
            <div class="mt-2 mb-3"></div>
            <div class="header-lg mb-2 mt-2">Select Modules</div>
            <div class="form-row">
              <div class="" [ngClass]="
                  isClass == true
                    ? 'form-group col-md-8 module-card'
                    : 'form-group col-md-4 module-card'
                ">
                <div class="mb-2">
                  <mat-accordion class="mb-3" (click)="$event.stopPropagation()">
                    <mat-expansion-panel>
                      <mat-expansion-panel-header>
                        <mat-panel-title> Lead management </mat-panel-title>
                        <!-- <mat-slide-toggle (click)="$event.stopPropagation()" class="mr-3"></mat-slide-toggle> -->
                        <mat-slide-toggle (change)="openState($event)"
                          [checked]="ProjectDetails.Modules.leadManagement.LeadManagement" class="mr-3">
                        </mat-slide-toggle>

                      </mat-expansion-panel-header>

                      <section class="example-section" (click)="$event.stopPropagation();">
                        <span class="example-list-section">
                          <div class="d-flex">
                            <div>
                              <mat-checkbox (click)="$event.stopPropagation();" class="example-margin"
                                (change)="selectAllFeedbacks()" [checked]="allComplete" [color]="feedBacks.color"
                                [indeterminate]="someComplete()" [checked]="feedBacks.selected">
                                {{feedBacks.name}}

                              </mat-checkbox>
                            </div>
                            <div class="ml-auto">
                              <div class="d-flex mb-1">
                                <div>
                                  <button class="btn btn-link btn-sm p-0 ml-0 mr-2 mb-0 mt-0"
                                    [matMenuTriggerFor]="moduleSearch" (click)="$event.stopPropagation();">
                                    <img src="../../../assets/img/search.svg" class="img14">
                                  </button>
                                  <button class="btn btn-link btn-sm p-0 ml-0 mb-0 mt-0"
                                    [matMenuTriggerFor]="newFeedback" (click)="$event.stopPropagation();">
                                    <img src="../../../assets/img/add-g.svg" class="img14">
                                  </button>

                                  <mat-menu #moduleSearch="matMenu" xPosition="before" yPosition="below"
                                    class="px-3 pt-1 w250" style="width:250px!important">
                                    <div class="form-group custom-popup mb-1">
                                      <input type="text" class="form-control" id="inputAddress2"
                                        placeholder="Search by keyword" (click)="$event.stopPropagation();">
                                    </div>
                                  </mat-menu>


                                  <mat-menu #newFeedback="matMenu" xPosition="before" yPosition="below"
                                    class="px-3 pt-1 w250" style="width:250px!important">
                                    <div class="form-group custom-popup" style="width:240px!important;">
                                      <label for="inputEmail4">Feedback Name</label>
                                      <input type="text" class="form-control" id="inputAddress2" placeholder=" "
                                        (click)="$event.stopPropagation();">
                                    </div>

                                    <div class="d-flex align-items-center justify-content-end">
                                      <button class="btn btn-primary btn-sm mr-2">Add</button>
                                      <button class="btn btn-light btn-sm">Cancel</button>
                                    </div>
                                  </mat-menu>

                                </div>
                              </div>
                            </div>
                          </div>
                        </span>

                        <span class="example-list-section">

                          <ul class="float-left itemList">
                            <li *ngFor="let subtask of feedBacks.subtasks; let i = index;">
                              <mat-checkbox [(ngModel)]="subtask.selected" [checked]="subtask.selected"
                                (change)="addFeedback(subtask, i)" [color]="subtask.color"
                                (ngModelChange)="updateAllComplete()">
                                {{subtask.name}}
                              </mat-checkbox>
                            </li>
                          </ul>



                        </span>
                      </section>
                      <div style="clear:both"></div>
                      <div class="float-right p-2">
                        Pagination
                      </div>

                      <div style="clear:both"></div>
                      <hr>
                      <div style="clear:both"></div>


                      <section class="example-section">
                        <span class="example-list-section">

                          <div class="d-flex">
                            <div>
                              <mat-checkbox class="example-margin" [checked]="channel.selected" [color]="channel.color"
                                (change)=" selectAllChannels()">
                                {{channel.name}}
                              </mat-checkbox>
                            </div>
                            <div class="ml-auto">
                              <div class="d-flex">
                                <div>
                                  <img src="../../../assets/img/add-g.svg" class="img14 mr-2">
                                </div>
                                <div>
                                  <img src="../../../assets/img/search.svg" class="img14">
                                </div>
                              </div>
                            </div>
                          </div>


                        </span>
                        <span class="example-list-section">
                          <!-- <ul>
                            <li *ngFor="let subtask of channel.subtasks">
                              <mat-checkbox [(ngModel)]="subtask.completed" [color]="subtask.color" []
                                (ngModelChange)="updateAllComplete()">
                                {{subtask.name}}
                              </mat-checkbox>
                            </li>
                          </ul> -->

                          <ul class="float-left itemList">
                            <li *ngFor="let subtask of channel.subtasks; let i = index;">
                              <mat-checkbox [(ngModel)]="subtask.selected" [checked]="subtask.selected"
                                (change)="addChannel(subtask, i)" [color]="subtask.color"
                                (ngModelChange)="updateAllComplete()">
                                {{subtask.name}}
                              </mat-checkbox>
                            </li>
                          </ul>
                        </span>
                      </section>

                      <div style="clear:both"></div>
                      <hr>
                      <div style="clear:both"></div>

                      <section class="example-section">
                        <span class="example-list-section">

                          <div class="d-flex">
                            <div>
                              <mat-checkbox class="example-margin" [checked]="leadTableColoumn.selected"
                                [color]="leadTableColoumn.color" (change)=" selectAllColumns()">
                                {{leadTableColoumn.name}}
                              </mat-checkbox>
                            </div>
                            <div class="ml-auto">
                              <div class="d-flex">
                                <div>
                                  <img src="../../../assets/img/add-g.svg" class="img14 mr-2">
                                </div>
                                <div>
                                  <img src="../../../assets/img/search.svg" class="img14">
                                </div>
                              </div>
                            </div>
                          </div>


                        </span>
                        <span class="example-list-section">
                          <!-- <ul>
                            <li *ngFor="let subtask of channel.subtasks">
                              <mat-checkbox [(ngModel)]="subtask.completed" [color]="subtask.color" []
                                (ngModelChange)="updateAllComplete()">
                                {{subtask.name}}
                              </mat-checkbox>
                            </li>
                          </ul> -->

                          <ul class="float-left itemList">
                            <li *ngFor="let subtask of leadTableColoumn.subtasks; let i = index;">
                              <mat-checkbox [(ngModel)]="subtask.selected" [checked]="subtask.selected"
                                (change)="addColumns(subtask, i)" [color]="subtask.color"
                                (ngModelChange)="updateAllComplete()">
                                {{subtask.name}}
                              </mat-checkbox>
                            </li>
                          </ul>
                        </span>
                      </section>
                    </mat-expansion-panel>
                  </mat-accordion>
                </div>

              </div>


            </div>
            <hr />
            <div class="p-4">
              <div class="d-flex flex-column">
                <div class="p-3 text-center header-lg">
                  Do you want create another Project..
                </div>
                <div style="margin: 0 auto">
                  <button [disabled]="isLoading==true" class="btn btn-secondary btn-lg ml-2"
                    (click)="CreateProject(form2)">
                    <img src="../../../assets/img/add-w.svg" class="img12 mr-2" />Add Project
                  </button>
                </div>
              </div>
            </div>
            <hr />

          </form>
          <div class="p-2">
            <div class="d-flex align-items-center justify-content-end">
              <div class="d-flex ml-auto">
                <button class="btn btn-light btn-lg mr-2" type="button" (click)="resetForm()">Reset</button>
                <button class="btn btn-secondary btn-lg mr-2" [disabled]="isLoading==true"
                  (click)="CreateProject(form2)" type="button">
                  <img src="../../../assets/img/ticker-white.svg" class="img12 mr-2" />Create Project
                </button>
                <button matStepperNext class="btn btn-secondary btn-sm" [disabled]="!projectCreated" type="button">
                  Next
                </button>

              </div>
            </div>
          </div>
          <!-- <div
            class="p-3 d-flex flex-column align-items-center justify-content-center"
          >
            <img
              src="../../../assets/img/finger-rise.svg"
              class="img50 text-center"
            />
            <div class="p-3 text-center">
              Succes.. Project Created successfully
            </div>
          </div> -->
          <hr />
          <table class="table table-sm table-borderless doc-table2" *ngIf="addedProjects && addedProjects.length>0">
            <tbody *ngFor="let item of addedProjects;let indexofelement = index">
              <tr>
                <td style="vertical-align: bottom">
                  <!-- <div class="header-12 lh150">#897867</div> -->
                  <div class="header-lg">{{item.projectName}}</div>
                </td>
                <td style="vertical-align: bottom">
                  <div class="blue-pill mr-2 float-left">
                    <img src="../../../assets/images/incoming-call.svg" class="img12 mr-2" />{{item.voipIncoimngNumber}}
                  </div>
                  <div class="pink-pill mr-2 float-left">
                    <img src="../../../assets/images/outgoing-call.svg" class="img12 mr-2" />{{item.voipOutgoingNumber}}
                  </div>
                </td>
                <td style="vertical-align: bottom">
                  <div class="light-pill mr-2 float-left">
                    <img src="../../../assets/img/message-b.svg" class="img12 hand" />
                  </div>
                  <div class="light-pill mr-2 float-left">
                    <img src="../../../assets/img/email-g.svg" class="img12 hand" />
                  </div>
                  <div class="light-pill mr-2 float-left">
                    <img src="../../../assets/img/whatsapp.png" class="img12 hand" />
                  </div>
                </td>
                <!-- <td class="text-right" style="vertical-align: bottom">
                  <img
                    src="../../../assets/images/edit.svg"
                    class="img11 mr-2 hand"
                  />
                  <img
                    src="../../../assets/images/delete.svg"
                    class="img11 hand"
                  />
                </td> -->
              </tr>
              <tr *ngIf="item.modules">
                <td colspan="4">
                  <div class="header-12">Modules</div>
                  <div class="pt-2" *ngFor="let data of item.modules">
                    <div class="light-pill mr-2 float-left">{{data.modeule}}</div>
                  </div>
                </td>
              </tr>
              <tr>
                <td colspan="8">
                  <hr />
                </td>
              </tr>
            </tbody>

          </table>

          <!-- <div class="p-4">
            <div class="d-flex flex-column">
              <div class="p-3 text-center header-lg">
                Do you want create another Project..
              </div>
              <div style="margin: 0 auto">
                <button [disabled]="isLoading==true" class="btn btn-secondary btn-lg ml-2" (click)="CreateProject(form2)">
                  <img
                    src="../../../assets/img/add-w.svg"
                    class="img12 mr-2"
                  />Add Project
                </button>
              </div>
            </div>
          </div> -->

          <div class="float-right">
            <!-- <button matStepperPrevious class="btn btn-secondary btn-sm mr-4">
              Back
            </button> -->
          </div>
        </form>
      </mat-step>
      <mat-step>
        <ng-template matStepLabel>Add User</ng-template>
        <!-- for Help -->
        <div class="pt-2 d-flex">
          <div class="ml-auto d-flex">
            <div>
              <img src="../../../assets/images/info.svg" class="img16 mr-2 hand" ngbTooltip="Information"
                placement="top" tooltipClass="my-custom-class" />
            </div>
            <div>
              <img src="../../../assets/images/video-play.svg" class="img16 hand" ngbTooltip="Demo video"
                placement="top" tooltipClass="my-custom-class" />
            </div>
          </div>
        </div>
        <!-- Help ends -->
        <div class="form-row ml-4">
          <div class="col-md-8">
            <div class="header-lg">Invite Users to PROJECT</div>
            <p class="sub-text-1 pt-1 pb-3">
              Invite teammates to collaborate and use products in your
              organisation. We’ll ask new users to enter their personal details
              when they sign up.
            </p>
            <label for="inputState">Email address</label>

            <div class="modal-body" style="height: none;">
              <div class="px-2">
                <input type="file" style="display: inline-block;" (change)="incomingfile($event)"
                  placeholder="Upload file" accept=".xlsx">
                <button type="button" class="btn btn-info" (click)="Upload()">Invite</button>
              </div>
            </div>

            <form [formGroup]="rulesForm">
              <mat-form-field>
                <mat-chip-list #chipList formArrayName="emails">
                  <mat-chip [color]="(item.invalid)?'warn':''" selected *ngFor="let item of emailList; let i=index"
                    [selectable]="true" [removable]="removable" (removed)="removeEmail(item)" required name="chips">
                    {{item.value}}
                    <mat-icon matChipRemove *ngIf="removable">X</mat-icon>
                  </mat-chip>


                  <input placeholder="Enter Email-id" class="form-control" type="email" matInput
                    [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes "
                    [matChipInputAddOnBlur]="true " (matChipInputTokenEnd)="add($event) " />
                </mat-chip-list>
              </mat-form-field>
              <!-- <mat-error *ngIf="rulesForm.get('emails').hasError('incorrectEmail')">Invalid email ID</mat-error> -->

            </form>

            <!-- <input class="form-control" placeholder="Invite by email address.." type="email" matInput
              [formControl]="emailFormControl" [errorStateMatcher]="matcher" placeholder="Ex. pat@example.com"
              [(ngModel)]="inviteEmail" />
            <mat-error *ngIf="
                emailFormControl.touched &&
                emailFormControl.hasError('email') &&
                !emailFormControl.hasError('required')
              ">
              Please enter a valid email address
            </mat-error>
            <mat-error *ngIf="
                emailFormControl.touched &&
                emailFormControl.hasError('required')
              ">
              Email is <strong>required</strong>
            </mat-error> -->
            <p class="sub-text-2 pt-2 pb-4">
              Separate emails using a comma. Note, we can’t send invitations to
              distribution lists.
            </p>
          </div>
        </div>

        <div class="form-row ml-4">
          <div class="col-md-8">
            <div>
              <table class="table table-condensed table-hover">
                <tr>
                  <th>Project</th>
                  <th>Project Role</th>
                </tr>
                <tr *ngFor="let project of newProjectDetails;let i =index">
                  <td>
                    <mat-checkbox class="example-margin" (change)="selectedRoles()" [checked]="project.selected"
                      [(ngModel)]="project.selected">{{ project.projectName }}</mat-checkbox>
                  </td>
                  <td rowspan="1" class="border-left">
                    <mat-radio-group aria-label="Select an option" [(ngModel)]="project.role">

                      <!-- <section class="example-section drop-section" *ngFor="let item of roles;let i=index">
                        <mat-radio-button [value]="item.value">{{item.name}}</mat-radio-button>
                        <p class="ml-4 mb-2">
                          Can access the product, with no product admin
                          permissions
                        </p>
                      </section>
                      -->
                      <section class="example-section drop-section">
                        <mat-radio-button value="admin">Administrator</mat-radio-button>
                        <p class="ml-4 mb-2">
                          Can access the product, with no product admin
                          permissions
                        </p>
                      </section>
                      <section class="example-section drop-section">
                        <mat-radio-button value="agent">Agent</mat-radio-button>
                        <p class="ml-4 mb-2">
                          Can access the product, with no product admin
                          permissions
                        </p>
                      </section>
                      <section class="example-section drop-section" *ngIf="project.projectType=='Student Study Abroad'">
                        <mat-radio-button value="counsellor">Counsellor</mat-radio-button>
                        <p class="ml-4 mb-2">
                          Can access the product, with no product admin
                          permissions
                        </p>
                      </section>
                      <section class="example-section drop-section" *ngIf="project.projectType=='Student Study Abroad'">
                        <mat-radio-button value="administrator specialist">Administrator Specialist</mat-radio-button>
                        <p class="ml-4 mb-2">
                          Can access the product, with no product admin
                          permissions
                        </p>
                      </section>
                    </mat-radio-group>
                  </td>
                </tr>
                <!-- <tr>
                  <td rowspan="2" class="border-left">
                    <mat-radio-group aria-label="Select an option" [(ngModel)]="selectedrole">
                     
                      <section class="example-section drop-section" *ngFor="let item of roles;let i=index">
                        <mat-radio-button [value]="item.value">{{item.name}}</mat-radio-button>
                        <p class="ml-4 mb-2">
                          Can access the product, with no product admin
                          permissions
                        </p>
                      </section>
                    
                     
                    </mat-radio-group>
                  </td>
                </tr> -->
              </table>
            </div>
            <div class="d-flex align-items-center justify-content-center p-3">
              <button class="btn btn-secondary btn-sm mr-2" (click)="inviteAgents()"
                [disabled]="newProjectDetails?.length<=0" type="button">
                Invite
              </button>
              <!-- <button class="btn btn-secondary btn-sm mr-2" (click)="inviteAgents()"
                 type="button">
                Invite
              </button> -->
              <div class="btn btn-light btn-sm">Cancel</div>
            </div>
          </div>
        </div>

        <div class="float-right">
          <button class="btn btn-secondary btn-sm mr-2" matStepperPrevious type="button">
            Back
          </button>
          <button class="btn btn-secondary btn-sm" (click)="stepper.reset();projectList()" type="button">
            Done
          </button>
        </div>
      </mat-step>
    </mat-stepper>
  </div>
</div>


<div class="modal fade" id="uploadFiles" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content" style="width: 400px">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Upload Documents</h5>
        <button type="button" id="closeButton4" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body"></div>
    </div>
  </div>
</div>

<div class="fixed-footer">
  Powered by
  <a href="https://avantao.com/#/" target="_blank">Avantao</a>
</div>



<!-- <div class="m-5" *ngIf="!isEditCompany && !isEditProject && !isCreateProject">
  <div class="p-3 b-radius-10 bg-w">
    <mat-horizontal-stepper [linear]="isLinear" #stepper (selectionChange)="setIndex($event)" (click)="triggerClick()">
              <mat-step [stepControl]="firstFormGroup">
          <div class="pt-2 d-flex">
            <div class="ml-auto d-flex">
              <div>
                <img src="../../../assets/images/info.svg" class="img16 mr-2 hand icon-color" ngbTooltip="Information"
                  placement="top" tooltipClass="my-custom-class" />
              </div>
              <div>
                <img src="../../../assets/images/video-play.svg" class="img16 hand" ngbTooltip="Demo video"
                  placement="top" tooltipClass="my-custom-class" />
              </div>
            </div>
          </div>
          <form [formGroup]="firstFormGroup">
            <ng-template matStepLabel>Create Company</ng-template>
          <form class="was-validated" novalidate #form="ngForm" [ngClass]="{ 'was-validated': form.submitted }">

            <button mat-button matStepperNext (click)="click();click();CreateCompany(form)">Next</button>
            </form>
          </form>
        </mat-step>

        <mat-step [stepControl]="secondFormGroup" label="Create Project" >
          <div class="pt-2 d-flex">
            <div class="ml-auto d-flex">
              <div>
                <img src="../../../assets/images/info.svg" class="img16 mr-2 hand" ngbTooltip="Information"
                  placement="top" tooltipClass="my-custom-class" />
              </div>
              <div>
                <img src="../../../assets/images/video-play.svg" class="img16 hand" ngbTooltip="Demo video"
                  placement="top" tooltipClass="my-custom-class" />
              </div>
            </div>
          </div>
          <button mat-button matStepperPrevious>Back</button>
          <button mat-button matStepperNext>Next</button>
      
        </mat-step>

        <mat-step>
          <ng-template matStepLabel>Add User</ng-template>
          <div class="pt-2 d-flex">
            <div class="ml-auto d-flex">
              <div>
                <img src="../../../assets/images/info.svg" class="img16 mr-2 hand" ngbTooltip="Information"
                  placement="top" tooltipClass="my-custom-class" />
              </div>
              <div>
                <img src="../../../assets/images/video-play.svg" class="img16 hand" ngbTooltip="Demo video"
                  placement="top" tooltipClass="my-custom-class" />
              </div>
            </div>
          </div>
          <div class="form-row ml-4">
            <div class="col-md-8">
              <div class="header-lg">Invite Users to PROJECT</div>
              <p class="sub-text-1 pt-1 pb-3">
                Invite teammates to collaborate and use products in your
                organisation. We’ll ask new users to enter their personal details
                when they sign up.
              </p>
              <label for="inputState">Email address    
                <button class="btn btn-link btn-sm" ngbTooltip="Download sample" placement="top" 
                (click)="downloadTemplate()" tooltipClass="my-custom-class">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 22 22">
                  <g id="Group_776" data-name="Group 776" transform="translate(-215.728 -61.66)">
                    <path id="Path_888" class="psvg" data-name="Path 888" d="M234.853,86.66H221.1a1.375,1.375,0,0,1-1.375-1.375v-8.25A1.375,1.375,0,0,1,221.1,75.66h2.063v1.375H221.1v8.25h13.75v-8.25h-2.063V75.66h2.063a1.375,1.375,0,0,1,1.375,1.375v8.25A1.376,1.376,0,0,1,234.853,86.66Z" transform="translate(-1.25 -4.375)" fill="#b02022"/>
                    <rect id="Rectangle_902"  class="psvg" data-name="Rectangle 902" width="4" height="1" transform="translate(224.728 75.66)" fill="#b02022"/>
                    <path id="Path_889"  class="psvg" data-name="Path 889" d="M233.008,68.19l-2.468,2.461V63.66h-1.375v6.992L226.7,68.19l-.969.969,4.125,4.125,4.125-4.125Z" transform="translate(-3.125 -0.625)" fill="#b02022"/>
                    <rect id="_Transparent_Rectangle_" data-name=" Transparent Rectangle " width="22" height="22" transform="translate(215.728 61.66)" fill="none"/>
                  </g>
                </svg>
              </button>
              </label>

              <div class="modal-body" style="height: none;">
            
                <div class="px-2">
                  <input type="file" style="display: inline-block;" (change)="incomingfile($event)"
                    placeholder="Upload file" accept=".xlsx">
                  <button type="button" class="btn btn-info" (click)="Upload()">Invite</button>
                </div>
              </div>

              <form [formGroup]="rulesForm">
                <mat-form-field>
                  <mat-chip-list #chipList formArrayName="emails">
                    <mat-chip [color]="(item.invalid)?'warn':''" selected *ngFor="let item of emailList; let i=index"
                      [selectable]="true" [removable]="removable" (removed)="removeEmail(item)" required name="chips">
                      {{item.value}}
                      <mat-icon matChipRemove *ngIf="removable">X</mat-icon>
                    </mat-chip>


                    <input placeholder="Enter Email-id" class="form-control" type="email" matInput
                      [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes "
                      [matChipInputAddOnBlur]="true " (matChipInputTokenEnd)="add($event) " />
                  </mat-chip-list>
                </mat-form-field>

              </form>

              <p class="sub-text-2 pt-2 pb-4">
                Separate emails using a comma. Note, we can’t send invitations to
                distribution lists.
              </p>
            </div>
          </div>

          <div class="form-row ml-4">
            <div class="col-md-8">
              <div>
                <table class="table table-condensed table-hover">
                  <tr>
                    <th>Project</th>
                    <th>Project Role</th>
                  </tr>
                  <tr *ngFor="let project of newProjectDetails">
                    <td>
                      <mat-checkbox class="example-margin" (change)="selectedRoles()" [checked]="project.selected"
                        [(ngModel)]="project.selected">{{ project.projectName }}</mat-checkbox>
                    </td>
                    <td rowspan="2" class="border-left">
                      <mat-radio-group aria-label="Select an option" [(ngModel)]="project.role">
                        <section class="example-section drop-section">
                          <mat-radio-button value="admin">Administrator</mat-radio-button>
                          <p class="ml-4 mb-2">
                            Can access the product, with no product admin
                            permissions
                          </p>
                        </section>
                        <section class="example-section drop-section">
                          <mat-radio-button value="agent">Agent</mat-radio-button>
                          <p class="ml-4 mb-2">
                            Can access the product, with no product admin
                            permissions
                          </p>
                        </section>
                        <section class="example-section drop-section" *ngIf="project.projectType=='Student Study Abroad'">
                          <mat-radio-button value="counsellor">Counsellor</mat-radio-button>
                          <p class="ml-4 mb-2">
                            Can access the product, with no product admin
                            permissions
                          </p>
                        </section>
                        <section class="example-section drop-section" *ngIf="project.projectType=='Student Study Abroad'">
                          <mat-radio-button value="administrator specialist">Administrator Specialist</mat-radio-button>
                          <p class="ml-4 mb-2">
                            Can access the product, with no product admin
                            permissions
                          </p>
                        </section>
                      </mat-radio-group>
                    </td>
                  </tr>
                </table>
              </div>
              <div class="d-flex align-items-center justify-content-center p-3">
                <button class="btn btn-secondary btn-sm mr-2" (click)="inviteAgents()"
                  [disabled]="newProjectDetails?.length<=0" type="button">
                  Invite
                </button>
             
                <div class="btn btn-light btn-sm">Cancel</div>
              </div>
            </div>
          </div>

          <div class="float-right">
            <button class="btn btn-secondary btn-sm mr-2" matStepperPrevious type="button">
              Back
            </button>
            <button class="btn btn-secondary btn-sm" (click)="stepper.reset()" type="button">
              Done
            </button>
          </div>
        </mat-step>
    </mat-horizontal-stepper>
  </div>
</div> -->