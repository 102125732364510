import { Injectable } from "@angular/core";
import { HttpClientService } from "src/app/httpclient.service";
import { environment } from "src/environments/environment";
import { ToastrService } from "ngx-toastr";
import { ReferralData } from "../marketing/smsmarketing/models/ReferralData.model";
import { BehaviorSubject } from "rxjs";
import { FormBuilder, RequiredValidator, Validators } from "@angular/forms";

@Injectable({
  providedIn: "root",
})
export class CreateCompanyService {
  baseApiUrl = environment.SMSapiurl;
  baseApiUrl2 = environment.baseApiUrl2;
  baseApiUrl3 = environment.newDashboardAPI;
  startCall = new BehaviorSubject<any>(null);
  stopCall = new BehaviorSubject<any>(null);


  constructor(
    private http: HttpClientService,
    private toastr: ToastrService,
    private readonly formBuilder: FormBuilder
  ) { }

  createCompany(data) {
    console.log("Request Body: ", data)
    return this.http.post(`${this.baseApiUrl3}companyManagment/createCompany`, data);
  }
  updateCompanyDetails(data) {
    return this.http.post(`${this.baseApiUrl3}companyManagment/updateCompanyDetails`, data);
  }

  createProject(data) {
    console.log("Request Body: ", data)
    return this.http.post(`${this.baseApiUrl3}ProjectManagement/createProject`, data);
  }
  updateProjectDetails(data) {
    console.log("Request Body: ", data)
    return this.http.post(`${this.baseApiUrl3}ProjectManagement/updateProjectDetails`, data);
  }
  getProjectType() {
    return this.http.get(`${this.baseApiUrl3}ProjectManagement/getProjectType`);
  }


  getTopBarCards(data) {
    return this.http.post(
      `${this.baseApiUrl3}companyManagment/getTopBarCards`,
      data
    );
  }

  getcompanyById(data) {
    return this.http.get(`${this.baseApiUrl3}companyManagment/getCompanyById/${data}`);
  }
  getProjectById(data) {
    return this.http.post(`${this.baseApiUrl3}newLeadsDashboard/getProjectInfo`, data);
  }
  selectTwilioNumber(data) {
    return this.http.post(`${this.baseApiUrl3}companyManagment/selectTwilioNumber`, data);
  }
  getNumberList(data) {
    return this.http.post(`${this.baseApiUrl3}companyManagment/getNumberList`, data);
  }
  deleteCompany(data) {
    return this.http.get(`${this.baseApiUrl3}companyManagment/deleteCompany/${data}`);
  }

}
