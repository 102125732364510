import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
// import { MatDatepickerInputEvent } from '@angular/material';
import { MomentFormatService } from 'src/app/moment-format-service';
import { ReportsService } from '../../../reports/reports.service';
import * as XLSX from 'xlsx';
import { DashboardReportsService } from '../../../dashboard-reports/dashboard-reports.service';
import { RorotelService } from '../rorotel.service';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
import * as moment from 'moment';


@Component({
  selector: "app-rorotelFilter",
  templateUrl: "./rorotelFilter.component.html",
  styleUrls: ["./rorotelFilter.component.css"],
})
export class RorotelFilter implements OnInit {

  @Output() generate = new EventEmitter();
  @Output() export = new EventEmitter();

  extSearch: any;
  destextSearch: any;
  callStatusSearch: any;
  cdrSearch: any;
  timezonesSearch: any;

  showext: boolean = false;
  showdestext: boolean = false;
  showcallStatus: boolean = false;
  showcdr: boolean = false;
  showDate: boolean = false;
  showtimezones: boolean = false;

  startDate: any;
  endDate: any;
  selectedIndexDate: string;
  showDateCustom: boolean = false;
  filterValue: string;
  date1: any;
  date2: any;
  userData: any;
  selectedIndexExt: number;
  currentExtname: any;
  extList: any[] = [];
  extSelectionArray: any[] = [];
  destextList: any[] = [];
  destextSelectionArray: any[] = [];
  callStatusList: any[] = [];
  callStatusSelectionArray: any[] = [];
  cdrList: any[] = [];
  cdrSelectionArray: any[] = [];
  timezonesList: any[] = [];
  timezonesSelectionArray: any[] = [];

  constructor(
    private rorotelService: RorotelService,
    private momentService: MomentFormatService,
    private ReportService2: ReportsService,
  ) { }

  ngOnInit() {
    this.userData = JSON.parse(localStorage.getItem("userData") || "{}");
    this.date1 = new FormControl(new Date());
    this.date2 = new FormControl(new Date(Date.now() - 864e5));
    this.startDate = this.momentService.dashboardDateFormat(
      new Date(new Date(Date.now() - 864e5 * 30)).getTime()
    );
    this.endDate = this.momentService.dashboardDateFormat(
      new Date(new Date(Date.now() + 864e5))
    );
    this.getExt();
    this.getdestExt();
    this.getcallStatus();
    this.getcdr();
    this.gettimezones();

  }
  toggleFilters(val) {
    switch (val) {
      case 'showext':
        this.showext = !this.showext;
        this.showDate = false;
        this.showdestext = false;
        this.showcallStatus = false;
        this.showcdr = false;
        this.showtimezones = false;
        break;
      case 'showDate':
        this.showext = false;
        this.showDate = !this.showDate;
        this.showdestext = false;
        this.showcallStatus = false;
        this.showcdr = false;
        this.showtimezones = false;
        break;
      case 'showdestext':
        this.showext = false;
        this.showDate = false;
        this.showdestext = !this.showdestext;
        this.showcallStatus = false;
        this.showcdr = false;
        this.showtimezones = false;
        break;
      case 'showcallStatus':
        this.showext = false;
        this.showDate = false;
        this.showdestext = false;
        this.showcallStatus = !this.showcallStatus;
        this.showcdr = false;
        this.showtimezones = false;
        break;
      case 'showcdr':
        this.showext = false;
        this.showDate = false;
        this.showdestext = false;
        this.showcallStatus = false;
        this.showcdr = !this.showcdr;
        this.showtimezones = false;
        break;
      case 'showtimezones':
        this.showext = false;
        this.showDate = false;
        this.showdestext = false;
        this.showcallStatus = false;
        this.showcdr = false;
        this.showtimezones = !this.showtimezones;
        break;

      default:
        this.showext = false;
        this.showDate = false;
        this.showdestext = false;
        this.showcallStatus = false;
        this.showcdr = false;
        this.showtimezones = false;
        break;
    }
  }
  getExt() {
    this.rorotelService.list('ext').subscribe((data: any) => {
      this.extList = Object.values(data);
      this.checkAllext({ target: { checked: true } });
    })
  }
  extSelection(ev) {
    var s = ev.value;
    if (ev.checked) {
      this.extSelectionArray.push(s);
    } else {
      var index = this.extSelectionArray.indexOf(s);
      if (index > -1) {
        this.extSelectionArray.splice(index, 1);
      }
    }
  }

  checkAllext(ev) {
    this.extSelectionArray = [];
    this.extList.forEach((x) => {
      if (ev.target.checked === true) {
        this.extSelectionArray.push(x.ext);
      }
      return (x.extStatus = ev.target.checked);
    });
  }

  isAllCheckedext() {
    var rValue = this.extList.every((_) => _.extStatus);
    return rValue;
  }
  unCheckextsSelectionArray() {
    this.extSelectionArray = [];
    this.extList.forEach((x) => {
      return (x.extStatus = false);
    });
  }

  getdestExt() {
    this.rorotelService.list('destext').subscribe((data: any) => {
      this.destextList = Object.values(data);
      this.checkAlldestext({ target: { checked: true } });
    })
  }
  destextSelection(ev) {
    var s = ev.value;
    if (ev.checked) {
      this.destextSelectionArray.push(s);
    } else {
      var index = this.destextSelectionArray.indexOf(s);
      if (index > -1) {
        this.destextSelectionArray.splice(index, 1);
      }
    }
  }

  checkAlldestext(ev) {
    this.destextSelectionArray = [];
    this.destextList.forEach((x) => {
      if (ev.target.checked === true) {
        this.destextSelectionArray.push(x.name);
      }
      return (x.destextStatus = ev.target.checked);
    });
  }

  isAllCheckeddestext() {
    var rValue = this.destextList.every((_) => _.destextStatus);
    return rValue;
  }
  unCheckdestextsSelectionArray() {
    this.destextSelectionArray = [];
    this.destextList.forEach((x) => {
      return (x.destextStatus = false);
    });
  }

  getcallStatus() {
    this.callStatusList = [
      { id: 8, name: 'Answered' },
      { id: 4, name: 'Unanswered' },
      { id: 2, name: 'Busy' },
      { id: 1, name: 'Failed' }
    ];
    this.checkAllcallStatus({ target: { checked: true } });
  }
  callStatusSelection(ev) {
    var s = ev.value;
    if (ev.checked) {
      this.callStatusSelectionArray.push(s);
    } else {
      var index = this.callStatusSelectionArray.indexOf(s);
      if (index > -1) {
        this.callStatusSelectionArray.splice(index, 1);
      }
    }
  }

  checkAllcallStatus(ev) {
    this.callStatusSelectionArray = [];
    this.callStatusList.forEach((x) => {
      if (ev.target.checked === true) {
        this.callStatusSelectionArray.push(x.id);
      }
      return (x.callStatusStatus = ev.target.checked);
    });
  }

  isAllCheckedcallStatus() {
    var rValue = this.callStatusList.every((_) => _.callStatusStatus);
    return rValue;
  }
  unCheckcallStatussSelectionArray() {
    this.callStatusSelectionArray = [];
    this.callStatusList.forEach((x) => {
      return (x.callStatusStatus = false);
    });
  }

  getcdr() {
    this.cdrList = [
      { id: 1, name: 'Incoming' },
      { id: 2, name: 'Outgoing' },
    ];
    this.checkAllcdr({ target: { checked: true } });
  }
  cdrSelection(ev) {
    var s = ev.value;
    if (ev.checked) {
      this.cdrSelectionArray.push(s);
    } else {
      var index = this.cdrSelectionArray.indexOf(s);
      if (index > -1) {
        this.cdrSelectionArray.splice(index, 1);
      }
    }
  }

  checkAllcdr(ev) {
    this.cdrSelectionArray = [];
    this.cdrList.forEach((x) => {
      if (ev.target.checked === true) {
        this.cdrSelectionArray.push(x.id);
      }
      return (x.cdrStatus = ev.target.checked);
    });
  }

  isAllCheckedcdr() {
    var rValue = this.cdrList.every((_) => _.cdrStatus);
    return rValue;
  }
  unCheckcdrsSelectionArray() {
    this.cdrSelectionArray = [];
    this.cdrList.forEach((x) => {
      return (x.cdrStatus = false);
    });
  }

  gettimezones() {
    this.timezonesList = [
      { id: 1, name: 'Americas' },
      { id: 2, name: 'EMEA' },
      { id: 3, name: 'India' }
    ];
    this.checkAlltimezones({ target: { checked: true } });
  }
  timezonesSelection(ev) {
    var s = ev.value;
    if (ev.checked) {
      this.timezonesSelectionArray.push(s);
    } else {
      var index = this.timezonesSelectionArray.indexOf(s);
      if (index > -1) {
        this.timezonesSelectionArray.splice(index, 1);
      }
    }
  }

  checkAlltimezones(ev) {
    this.timezonesSelectionArray = [];
    this.timezonesList.forEach((x) => {
      if (ev.target.checked === true) {
        this.timezonesSelectionArray.push(x.name);
      }
      return (x.timezonesStatus = ev.target.checked);
    });
  }

  isAllCheckedtimezones() {
    var rValue = this.timezonesList.every((_) => _.timezonesStatus);
    return rValue;
  }
  unChecktimezonessSelectionArray() {
    this.timezonesSelectionArray = [];
    this.timezonesList.forEach((x) => {
      return (x.cdrStatus = false);
    });
  }

  resetFilters() {
    this.startDate = this.momentService.dashboardDateFormat(
      new Date(new Date(Date.now() - 864e5 * 365)).getTime()
    );
    this.endDate = this.momentService.dashboardDateFormat(
      new Date(new Date(Date.now() + 864e5))
    );
    this.checkAllext({ target: { checked: true } });
    this.checkAllcallStatus({ target: { checked: true } });
    this.checkAllcdr({ target: { checked: true } });
    this.generateData();
  }

  generateData() {
    let postData = {
      from: moment(
        this.startDate
      ).format("MMM-DD-YYYY"),
      to: moment(
        this.endDate
      ).format("MMM-DD-YYYY"),
      ext: this.extSelectionArray.length === this.extList.length
        ? String(this.extList.map(x => x.ext))
        : this.extSelectionArray.length > 0
          ? String(this.extSelectionArray)
          : String(this.extList.map(x => x.ext)),
      status: this.callStatusSelectionArray.length === this.callStatusList.length
        ? String(this.callStatusList.map(x => x.id))
        : this.callStatusSelectionArray.length > 0
          ? String(this.callStatusSelectionArray)
          : String(this.callStatusList.map(x => x.id)),
      cdr: this.cdrSelectionArray.length === this.cdrList.length
        ? "All"
        : this.cdrSelectionArray.length > 0
          ? String(this.cdrSelectionArray)
          : "All",
    };
    this.ReportService2.rorotelfilterData.next(postData);
  }
  dateSelection(event) {
    if (event.target.value != "custom") {
      this.endDate = this.momentService.dashboardDateFormat(
        new Date(new Date(Date.now())).getTime()
      );
    }
    if (event.target.value == "custom") {
      this.filterValue = "custom";
      this.showDateCustom = true;
      this.selectedIndexDate = event.target.value;
    } else if (event.target.value == "24hrs") {
      this.showDateCustom = false;
      this.filterValue = "24hrs";
      this.selectedIndexDate = event.target.value;
      this.startDate = this.momentService.dashboardDateFormat(
        new Date(new Date(Date.now() - 864e5)).getTime()
      );
    } else if (event.target.value == "72hrs") {
      this.showDateCustom = false;
      this.filterValue = "72hrs";
      this.selectedIndexDate = event.target.value;
      this.startDate = this.momentService.dashboardDateFormat(
        new Date(new Date(Date.now() - 864e5 * 3)).getTime()
      );
    } else if (event.target.value == "07days") {
      this.showDateCustom = false;
      this.filterValue = "07days";
      this.selectedIndexDate = event.target.value;
      this.startDate = this.momentService.dashboardDateFormat(
        new Date(new Date(Date.now() - 864e5 * 7)).getTime()
      );
    } else if (event.target.value == "14days") {
      this.showDateCustom = false;
      this.filterValue = "14days";
      this.selectedIndexDate = event.target.value;
      this.startDate = this.momentService.dashboardDateFormat(
        new Date(new Date(Date.now() - 864e5 * 14)).getTime()
      );
    } else if (event.target.value == "30days") {
      this.showDateCustom = false;
      this.filterValue = "30days";
      this.selectedIndexDate = event.target.value;
      this.startDate = this.momentService.dashboardDateFormat(
        new Date(new Date(Date.now() - 864e5 * 30)).getTime()
      );
    }
  }
  hideDate() {
    // this.filterValuev
    if (this.filterValue == "custom" && this.showDateCustom == false) {
      this.showDateCustom = true;
    }
  }

  getStartDate(type: string, event: MatDatepickerInputEvent<Date>) {
    this.startDate = event.value;
  }
  getEndDate(type: string, event: MatDatepickerInputEvent<Date>) {
    this.endDate = event.value;
  }
  projectSelection(event, id, name) {
    if (event.target.checked) {
      this.selectedIndexExt = id;
      this.currentExtname = name;
    } else {
      this.selectedIndexExt = undefined;
      this.currentExtname = "";
    }
  }
  getCallStatus(val) {
    var agentList = this.callStatusList;
    var name = agentList.filter((x) => x.id == val)[0];
    return name.name;
  }
  getCDR(val) {
    var agentList = this.cdrList;
    var name = agentList.filter((x) => x.id == val)[0];
    return name.name;
  }
  getExtName(val) {
    var agentList = this.extList;
    var name = agentList.filter((x) => x.ext == val)[0];
    return name.name;
  }
}
