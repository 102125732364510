<div class="reportFilter-block pt-3">
    <!-- FOR PROJECTS -->
    <div class="filter_blocks">
        <div class="dropdown_list">
            <button type="button" class="btn btn-light btn-sm d-flex align-items-center"
                (click)="toggleFilters('showProjects');" [ngClass]="{'btn-filter-active': selectedIndexProject}">
                Projects :&nbsp; <div *ngIf="selectedIndexProject">{{currentProjectName | titlecase}}</div>
                <div>
                    <i class="fa-solid fa-angle-down arrow-down" style="top:0"></i>
                </div>
            </button>

            <div class="drop-show drop-show-label" *ngIf="showProjects">
                <ul class="more_dropdown_list">
                    <li><input type="text" class="form-input spl-search-icon" placeholder="Search"></li>
                    <li *ngFor="let a of projectLists">
                        <div class="input_wrp_filter">
                            <input *ngIf="a.Name == 'Documents'" type="checkbox" class="more_dropdown_list_input"
                                [ngModel]="selectedIndexProject === a.id"
                                (change)="projectSelection($event, a.id,a.Name)" />
                            <label *ngIf="a.Name == 'Documents'"><span>{{a.Name | titlecase}} </span></label>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <!-- FOR Courses -->
    <!-- <div class="filter_blocks">
        <div class="dropdown_list">
            <button type="button" class="btn btn-light btn-sm d-flex align-items-center" (click)="toggleFilters('showCourses');"
                [ngClass]="{'btn-filter-active': courseSelectionArray.length>0}">
                Courses <div *ngIf="courseSelectionArray.length>0">
                    {{ ":&nbsp;" +courseSelectionArray[courseSelectionArray.length-1]}}</div><div
                    *ngIf="courseSelectionArray.length>1" class="badge_count">+{{courseSelectionArray.length}}</div>
                    <div><i
                    class="fa-solid fa-angle-down arrow-down" style="top:0"></i>
                    </div>
            </button>
            <div class="drop-show drop-show-label" *ngIf="showCourses">
                <ul class="more_dropdown_list">
                    <li><input type="text" class="form-input spl-search-icon" placeholder="Search"></li>
                    <li>
                        <div class="input_wrp_filter">
                            <input class="more_dropdown_list_input" 
                            type="checkbox" 
                            value="" 
                            id="defaultCheck1" 
                            name="all" 
                            [checked]="isAllCheckedcourse()"
                            (change)="checkAllcourse($event)"/>
                            <label><span>All</span></label>
                        </div>
                    </li>
                    <li *ngFor="let a of coursesList">
                        <div class="input_wrp_filter">
                            <input class="more_dropdown_list_input" type="checkbox"
                                (change)="courseSelection($event.target)" [value]="a.course" id="defaultCheck1" name="sizecb[]" [(ngModel)]="a.courseState" />
                            <label><span>{{a.course}}</span></label>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div> -->
    <!-- FOR Applications -->
    <!-- <div class="filter_blocks" *ngIf="list[1].checked === true">
        <div class="dropdown_list">
            <button type="button" class="btn btn-light btn-sm" (click)="toggleFilters('showApplications');"
                [ngClass]="{'btn-filter-active': applicationSelectionArray.length>0}">
                Applications
                <span *ngIf="applicationSelectionArray.length>0">
                    {{ ":"+ applicationSelectionArray[applicationSelectionArray.length-1]}}</span>
                <span *ngIf="applicationSelectionArray.length>1"
                    class="badge_count">+{{applicationSelectionArray.length}}</span>
                <i class="fa-solid fa-xmark cross-arrow"
                    (click)="list[1].checked = false;unCheckapplicationSelectionArray()"></i>
            </button>
            <div class="drop-show drop-show-label" *ngIf="showApplications">
                <ul class="more_dropdown_list">
                    <li>
                        <div class="input_wrp_filter">
                            <input 
                            class="more_dropdown_list_input" 
                            type="checkbox"
                            value="" 
                            id="defaultCheck1" 
                            name="all" 
                            [checked]="isAllCheckedapplication()"
                            (change)="checkAllapplication($event)"
                            />
                            <label><span>All</span></label>
                        </div>
                    </li>
                    <li *ngFor="let a of applicationsList">
                        <div class="input_wrp_filter">
                            <input 
                            class="more_dropdown_list_input"
                            type="checkbox"
                            (change)="applicationSelection($event.target)"
                            [value]="a.LeadDocStatus" 
                            id="defaultCheck1" 
                            name="sizecb[]" 
                            [(ngModel)]="a.applicationState"
                             />
                            <label><span>{{a.LeadDocStatus | titlecase}}</span></label>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div> -->
    <!-- FOR Users -->
    <div class="filter_blocks">
        <div class="dropdown_list">
            <button type="button" class="btn btn-light btn-sm d-flex align-items-center"
                (click)="toggleFilters('showUsers');" [ngClass]="{'btn-filter-active': usersSelectionArray.length>0}">
                Users :&nbsp;
                <div *ngIf="usersSelectionArray.length>0">
                    {{getAgentName(usersSelectionArray[usersSelectionArray.length-1])}}</div>
                <div *ngIf="usersSelectionArray.length>1" class="badge_count">+{{usersSelectionArray.length}}</div>
                <div>
                    <i class="fa-solid fa-angle-down arrow-down" style="top:0"></i>
                </div>
            </button>
            <div class="drop-show drop-show-label" *ngIf="showUsers">
                <ul class="more_dropdown_list">
                    <li><input type="text" class="form-input spl-search-icon" placeholder="Search"></li>
                    <li>
                        <div class="input_wrp_filter">
                            <input class="more_dropdown_list_input" type="checkbox" value="" id="defaultCheck1"
                                name="all" [checked]="isAllCheckedusers()" (change)="checkAllusers($event)" />
                            <label style="display: flex !important;">
                                <div class="pic mr-1 float-left text-center">
                                    <p class="initials" style="background: #0092AB;">
                                        <span style="font-size: 9px !important;color: #fff;">AL</span>
                                    </p>
                                </div>
                                <span>All</span>
                            </label>
                        </div>
                    </li>
                    <li *ngFor="let a of agentsList">
                        <div class="input_wrp_filter">
                            <input class="more_dropdown_list_input" type="checkbox"
                                (change)="usersSelection($event.target)" [value]="a.Mailid" id="defaultCheck1"
                                name="sizecb[]" [(ngModel)]="a.usersState" />
                            <label style="display: flex !important;">
                                <div *ngIf="a.agentname" class="pic mr-1 float-left text-center">
                                    <img *ngIf="a.ProfilePhoto != (null ||  '' )" src="{{ a.ProfilePhoto }}" style="width: 100%;
                                        height: 100%;
                                        object-fit: cover;
                                        border-radius: 100px;" />

                                    <p [ngStyle]="{ background: a.AgentColor.bg }" class="initials"
                                        *ngIf="a.ProfilePhoto == null || a.ProfilePhoto == '' ">
                                        <span [ngStyle]="{ color: a.AgentColor.color }"
                                            style="font-size: 9px !important;"> {{ a.agentInitial }}</span>
                                    </p>
                                </div>
                                <span>{{a.agentname | titlecase}}</span>
                            </label>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <!-- FOR Enrollment Year -->
    <!-- <div class="filter_blocks" *ngIf="list[2].checked === true">
        <div class="dropdown_list">
            <button type="button" class="btn btn-light btn-sm" (click)="toggleFilters('showEnrollmentYear');"
                [ngClass]="{'btn-filter-active': enrollmentYearSelectionArray.length>0}">
                Enrollment Year <span *ngIf="enrollmentYearSelectionArray.length>0">
                    {{ ":"+ enrollmentYearSelectionArray[enrollmentYearSelectionArray.length-1]}}</span>
                <span *ngIf="enrollmentYearSelectionArray.length>1"
                    class="badge_count">+{{enrollmentYearSelectionArray.length}}</span>

                <i class="fa-solid fa-xmark cross-arrow"
                    (click)="list[2].checked = false;unCheckenrollmentYearSelectionArray()"></i>
            </button>
            <div class="drop-show drop-show-enrollment" *ngIf="showEnrollmentYear">
                <ul class="more_dropdown_list">
                    <li>
                        <div class="input_wrp_filter">
                            <input 
                            class="more_dropdown_list_input" 
                            type="checkbox"
                            id="defaultCheck1" 
                            name="all" 
                            [checked]="isAllCheckedenrollmentYear()"
                            (change)="checkAllenrollmentYear($event)"
                            />
                            <label><span>All</span></label>
                        </div>
                    </li>
                    <li *ngFor="let a of enrollmentYearList">
                        <div class="input_wrp_filter">
                            <input class="more_dropdown_list_input" type="checkbox"
                                (change)="enrollmentYearSelection($event.target)"
                                [value]="a.EnrollmentYear" 
                                id="defaultCheck1" 
                                name="sizecb[]" 
                                [(ngModel)]="a.enrollmentYearState"
                                />
                            <label><span>{{a.EnrollmentYear}}</span></label>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div> -->
    <!-- FOR Enrollment Term -->
    <!-- <div class="filter_blocks" *ngIf="list[3].checked === true">
        <div class="dropdown_list">
            <button type="button" class="btn btn-light btn-sm" (click)="toggleFilters('showEnrollmentTerm');"
                [ngClass]="{'btn-filter-active': enrollmentTermSelectionArray.length>0}">
                Enrollment Term <span *ngIf="enrollmentTermSelectionArray.length>0">
                    {{ ":"+ enrollmentTermSelectionArray[enrollmentTermSelectionArray.length-1]}}</span>
                <span *ngIf="enrollmentTermSelectionArray.length>1"
                    class="badge_count">+{{enrollmentTermSelectionArray.length}}</span>

                <i class="fa-solid fa-xmark cross-arrow"
                    (click)="list[3].checked = false;unCheckenrollmentTermSelectionArray()"></i>
            </button>
            <div class="drop-show drop-show-enrollment" *ngIf="showEnrollmentTerm">
                <ul class="more_dropdown_list">
                    <li>
                        <div class="input_wrp_filter">
                            <input 
                            class="more_dropdown_list_input" 
                            type="checkbox"
                            id="defaultCheck1" 
                            name="all" 
                            [checked]="isAllCheckedenrollmentTerm()"
                            (change)="checkAllenrollmentTerm($event)"
                            />
                            <label><span>All</span></label>
                        </div>
                    </li>
                    <li *ngFor="let a of enrollmentTermList">
                        <div class="input_wrp_filter">
                            <input class="more_dropdown_list_input" type="checkbox"
                                (change)="enrollmentTermSelection($event.target)"
                                [value]="a.EnrollmentTerm" 
                                id="defaultCheck1" 
                                name="sizecb[]" 
                                [(ngModel)]="a.enrollmentTermState"
                                />
                            <label><span>{{a.EnrollmentTerm}}</span></label>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div> -->
    <!-- FOR Date -->
    <div class="filter_blocks">
        <div class="dropdown_list">
            <button type="button" class="btn btn-light btn-filter-active d-flex align-items-center"
                style="font-size: 13px; padding: 4px 10px;" (click)="toggleFilters('showDate');hideDate()">
                Date :&nbsp; <div> {{startDate | date: 'dd MMMM yyyy'}} -
                    {{endDate | date: 'dd MMMM yyyy'}}</div>
                <div><i class="fa-solid fa-angle-down arrow-down" style="top:0"></i></div>
            </button>
            <div class="drop-show drop-show-date" *ngIf="showDate">
                <ul class="more_dropdown_list">
                    <li>
                        <div class="input_wrp_filter">
                            <input class="more_dropdown_list_input_radio" type="radio" value="24hrs"
                                [checked]="selectedIndexDate === '24hrs'" (change)="dateSelection($event)" />
                            <label><span>24 hrs</span></label>
                        </div>
                    </li>
                    <li>
                        <div class="input_wrp_filter">
                            <input class="more_dropdown_list_input_radio" type="radio" value="72hrs"
                                [checked]="selectedIndexDate === '72hrs' " (change)="dateSelection($event)" />
                            <label><span>72 hrs</span></label>
                        </div>
                    </li>
                    <li>
                        <div class="input_wrp_filter">
                            <input class="more_dropdown_list_input_radio" type="radio" value="07days"
                                [checked]="selectedIndexDate === '07days' " (change)="dateSelection($event)" />
                            <label><span>07 days</span></label>
                        </div>
                    </li>
                    <li>
                        <div class="input_wrp_filter">
                            <input class="more_dropdown_list_input_radio" type="radio" value="14days"
                                [checked]="selectedIndexDate === '14days' " (change)="dateSelection($event)" />
                            <label><span>14 days</span></label>
                        </div>
                    </li>
                    <li>
                        <div class="input_wrp_filter">
                            <input class="more_dropdown_list_input_radio" type="radio" value="30days"
                                [checked]="selectedIndexDate === '30days' " (change)="dateSelection($event)" />
                            <label><span>30 days</span></label>
                        </div>
                    </li>
                    <li class="d-block">
                        <div class="input_wrp_filter">
                            <input class="more_dropdown_list_input_radio" type="radio" value="custom"
                                [checked]="selectedIndexDate === 'custom' " (change)="dateSelection($event)" />
                            <label><span>Custom</span></label>
                        </div>
                        <div *ngIf="showDateCustom" style="position: relative;">
                            <div class="datePicker">
                                <div style="background: #fff; height: 76px; border: 1px solid #ccc" class="custom-date">
                                    <mat-form-field class="mr-3">
                                        <input class="label" matInput [matDatepicker]="picker1" placeholder="Start Date"
                                            [formControl]="date2" (dateInput)="getStartDate('input', $event)" />
                                        <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                                        <mat-datepicker #picker1 style="width: 80px"></mat-datepicker>
                                    </mat-form-field>

                                    <mat-form-field class="mr-3">
                                        <input class="label" matInput [matDatepicker]="picker2" placeholder="End Date"
                                            [formControl]="date1" (dateInput)="getEndDate('input', $event)" />
                                        <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                                        <mat-datepicker #picker2 style="width: 80px"></mat-datepicker>
                                    </mat-form-field>
                                    <!-- <button class="btn btn-secondary btn-sm" (click)="filterData()">
                                        Submit
                                    </button> -->
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <!-- FOR More -->
    <!-- <div class="filter_blocks">
        <app-multi-select-dropdown [list]="list" (shareCheckedList)="shareCheckedList($event)"
            (shareIndividualCheckedList)="shareIndividualCheckedList($event)">
        </app-multi-select-dropdown>
    </div> -->
</div>
<div class="row bg-g pb-2">
    <div class="col-md-12 text-right">

        <button type="button" class="btn btn-primary btn-sm mr-2" (click)="generateData()">Generate</button>
        <!-- <button type="button" class="btn btn-secondary btn-sm mr-2" 
            (click)="exportData()">Export</button> -->
        <button type="button" class="btn btn-secondary btn-sm" (click)="resetFilters()">Reset</button>

    </div>
</div>