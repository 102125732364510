<mat-sidenav id="sideNav" #sideNav mode="side" class="pr" opened="false" position="end" class="pf w45 z-top"
  style="right: 0; top: 0; z-index: 9999">
  <div class="modal-header d-flex pf w45" style="right: 0; top: 0; z-index: 9999">
    <div class="d-flex">
      <div class="close hand" (click)="sideNav.toggle()">
        <span aria-hidden="true">&times;</span>
      </div>
      <div class="modal-title mr-auto">Overview</div>
    </div>
  </div>
  <mat-tab-group class="mt-5 sideDataViewer">
    <mat-tab label="Rule Details">
      <div class="mx-2 my-2">

      </div>
    </mat-tab>
    <mat-tab label="Activity">
      <div class="mx-2 my-2">

      </div>
    </mat-tab>

  </mat-tab-group>
</mat-sidenav>

<div class="containers">
  <div class="d-flex mb-2">

    <div class="ml-auto">

      <button class="btn btn-link btn-sm" (click)="open(content)">
        <a ngbTooltip="Create Rule" placement="top" tooltipClass="my-custom-class">

          <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22">
            <g id="Group_764" data-name="Group 764" transform="translate(-286.561 -0.829)">
              <path id="Path_869" data-name="Path 869" class="psvg"
                d="M302.855,15.28V8.829h-1.843V15.28h-6.451v1.843h6.451v6.451h1.843V17.123h6.451V15.28Z"
                transform="translate(-4.373 -4.373)" />
              <rect id="Rectangle_901" data-name="Rectangle 901" width="22" height="22"
                transform="translate(286.561 0.829)" fill="none" />
            </g>
          </svg></a>
      </button>
    </div>
  </div>
  <div class="content-container">
    <div class="table-scroll data-table" style="background:#fff">
      <table class="table table-md">
        <thead>
          <tr>
            <td colspan="8" style="padding: 8px">
              <div class="table-search-content">
                <div class="indived-search" style="position: relative">
                  <input type="text" class="form-control search-all" [(ngModel)]="search" placeholder="Search..." />

                </div>
                <div class="pagenation-right">
                  <ngb-pagination [(page)]="page" [pageSize]="pageSize" [collectionSize]="totalRules.length"
                    class="pagenation-right" [maxSize]="6" [boundaryLinks]="true"></ngb-pagination>
                </div>
              </div>
            </td>
          </tr>

          <tr style="background: #eaf5fd; border-bottom: none">
            <!-- <th style="display: none">Company id</th> -->
            <th>
              <span>
                Created Date

                <img class="img7" src="../../../../assets/img/sort-data.svg" style="cursor: pointer"
                  (click)="sortOrder('createdAt')" />
              </span>
            </th>
            <th>
              <span>
                Updated Date

                <img class="img7" src="../../../../assets/img/sort-data.svg" style="cursor: pointer"
                  (click)="sortOrder('updatedAt')" />
              </span>
            </th>

            <th>
              <span>
                Project Name
                <!-- <img class="img7" src="../../../../assets/img/sort-data.svg" style="cursor: pointer"
                  (click)="sortOrder('TwilioNumber')" /> -->
              </span>
            </th>
            <!-- <th>
              <span>
                Rule Type
                <img class="img7" src="../../../../assets/img/sort-data.svg" style="cursor: pointer"
                  (click)="sortOrder('ruleType')" />
              </span>
            </th> -->
            <th>
              <span>
                Rule Name
                <img class="img7" src="../../../../assets/img/sort-data.svg" style="cursor: pointer"
                  (click)="sortOrder('ruleName')" />
              </span>
            </th>

            <th>
              <span>
                Status
                <img class="img7" src="../../../../assets/img/sort-data.svg" style="cursor: pointer"
                  (click)="sortOrder('ActiveStatus')" />
              </span>
            </th>
            <th>
              <span>
                Created by
                <img class="img7" src="../../../../assets/img/sort-data.svg" style="cursor: pointer"
                  (click)="sortOrder('createdBy')" />
              </span>
            </th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody style="background:#fff">
          <tr *ngFor="
              let item of totalRules
                | slice: (page - 1) * pageSize:page * pageSize
                | sort: orderFeild:orderString
                | filter: search;
              let id = index
            ">
            <td>{{item.createdAt | date:'dd-mm-yyyy'}}</td>
            <td>{{item.updatedAt | date:'dd-mm-yyyy'}}</td>
            <td>{{item.projectName}}</td>
            <!-- <td>{{item.ruleType}}</td> -->
            <td>{{item.ruleName}}</td>
            <td>{{item.status==true?'Active':'Inactive'}}</td>
            <td>{{item.createdBy}}</td>
            <td>
              <div class="example-button-row mat-bset d-flex">
                <mat-sidenav-container>
                  <mat-sidenav-content>
                    <button class="viewLeadButton" (click)="sideNav.toggle(); sideNavData(item)">
                      <img class="img12" src="../../../../assets/img/view2.svg" />
                    </button>
                  </mat-sidenav-content>
                </mat-sidenav-container>

              </div>

              <!-- <div ngbTooltip="Menu" placement="top" tooltipClass="my-custom-class" class="mr-2"
              style="display: inline; cursor: pointer" [matMenuTriggerFor]="menu">
              <img src="../../../../assets/img/vdot-menu.svg" class="img13" style="height:14px;" />
            </div>
             <mat-menu #menu="matMenu">
              <button mat-menu-item>
                <img src="../../../../assets/img/edit.svg" class="img13" />
                <span ><a [routerLink]="['/editProject', item['companyId'],item['projectId']]"
                  >Edit Project</a></span>
              </button>
              <button mat-menu-item [disabled]="!item['webhookURL']">
                <a [className]="item['webhookURL']?'float-left cursor':'float-left'" (click)="copyText(item['webhookURL'])"> <i class="far fa-clone"></i> WebHook URL</a>
              </button>
           
            </mat-menu> -->
            </td>
          </tr>
          <tr *ngIf="isLoading ==true">
            <td colspan="8">
              <div class="norecords">
                <img src="../../../../assets/img/waiting.gif" />

                <div>Loading Data<br />Please wait while we fetch the data for you</div>
              </div>
            </td>
          </tr>
          <tr *ngIf="noRecords == true">
            <td colspan="8">
              <div class="norecords">
                <img src="../../../../assets/img/nodata.svg" />
                <div>No records found.<br />Please select a different date range.</div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

</div>
<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      {{ isUpdate ? "Update" : "Create" }} Rule
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" style="height: 400px; overflow: auto;width: 800px;">

    <form [formGroup]="ruleForm">
      <div class="row">
        <div class="col-md-6">
          <label for="projectId" class="form-label mt-2">Project Name <span style="color: red;">*</span></label>
          <!-- <mat-form-field appearance="outline" id="projectId" style="position: relative;width: 285px;">
            <mat-label>Select Project</mat-label>
            <mat-select formControlName="projectId" style="position: relative;"   name="projectId">
              <mat-option  *ngFor="let item of totalProjects" [value]="item.projectId" >{{item.projectName}}</mat-option>
            
            </mat-select>
          </mat-form-field> -->
          <select class="form-control" id="projectId" formControlName="projectId">
            <option value="" selected disabled>Select Project</option>
            <option *ngFor="let item of totalProjects" [value]="item.projectId">{{item.projectName}}</option>

          </select>
        </div>
        <div class="col-md-6">
          <label for="rulename" class="form-label mt-2">Rule Name <span style="color: red;">*</span></label>
          <input type="text" id="rulename" class="form-control" placeholder="Please enter Rule Name" name="name"
            formControlName="ruleName" required />

        </div>

      </div>
      <!-- <div class="row">
       
        <div class="col-md-12">
          <label for="name" class="form-label mt-2">Rule type <span style="color: red;">*</span></label>
          <select class="form-control" formControlName="ruleType" required>
            <option value="" selected disabled>Select Rule Type</option>
            <option value="Leads Rule" >Leads Rule</option>
            <option value="Assignment Rule" >Assignment Rule</option>
            <option value="Campaign Rule" >Campaign Rule</option>

          </select>
          
        </div>
       
      </div> -->
      <div class="row">

        <div class="col-md-12">
          <label for="description" class="form-label mt-2">Description <span style="color: red;">*</span></label>
          <textarea cols="10" rows="4" id="description" name="description" style="height: 80px;"
            formControlName="description" class="form-control" placeholder="Please enter description"
            required></textarea>
        </div>

      </div>
      <div class="row" *ngIf="ruleForm.value.projectId">

        <div class="col-md-12">
          <label for="when" class="form-label mt-2">When : </label>
        </div>
        <div class="col-md-6">
          <label for="channel" class="form-label mt-2">Channel : <span style="color: red;">*</span></label>
          <mat-form-field appearance="outline" id="channel" style="position: relative">
            <mat-label>Select Channel</mat-label>
            <mat-select formControlName="channel" style="position: relative;" name="channel">
              <mat-option value="facebook">Facebook</mat-option>
              <mat-option value="website">Website</mat-option>
              <mat-option value="linkedin">Linkedin</mat-option>

            </mat-select>
          </mat-form-field>
          <!-- <select name="channel" formControlName="channel" class="form-control" id="channel">
              <option value="" disabled selected>Select Channel</option>
              <option value="facebook">Facebook</option>
              <option value="website">Website</option>
              <option value="linkedin">linkedin</option>
            </select> -->
        </div>
        <div class="col-md-6">
          <label for="notificationtype" class="form-label mt-2">Notification Type : <span
              style="color: red;">*</span></label>
          <mat-form-field appearance="outline" id="notificationtype" style="position: relative">
            <mat-label>Select Type</mat-label>
            <mat-select formControlName="notificationtype" #mySelect style="position: relative;" multiple
              name="type_list" (selectionChange)="selectChange($event)">
              <mat-option *ngFor="let item of notificationtype" [value]="item.value" #matoption
                (click)="selectSingleValue(item.value,matoption.selected,emailtemplate)">{{item.name}}</mat-option>

            </mat-select>
          </mat-form-field>
          <!-- <select name="notificationtype" class="form-control" id="notificationtype">
              <option value="">Select Type</option>
              <option value="all">All</option>
              <option value="email">Email</option>
              <option value="sms">SMS</option>
              <option value="whatsapp">Whatsapp</option>
              <option value="telegram">Telegram</option>
            </select> -->
        </div>

        <!-- <button class="btn btn-link btn-sm">
              <a ngbTooltip="Create Rule" placement="top" tooltipClass="my-custom-class">
      
                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22">
                  <g id="Group_764" data-name="Group 764" transform="translate(-286.561 -0.829)">
                    <path id="Path_869" data-name="Path 869" class="psvg"
                      d="M302.855,15.28V8.829h-1.843V15.28h-6.451v1.843h6.451v6.451h1.843V17.123h6.451V15.28Z"
                      transform="translate(-4.373 -4.373)" />
                    <rect id="Rectangle_901" data-name="Rectangle 901" width="22" height="22"
                      transform="translate(286.561 0.829)" fill="none" />
                  </g>
                </svg></a>
            </button> -->
      </div>


      <div class="modal-footer">
        <button type="submit" class="btn btn-primary btn-sm" (click)="submit()">
          {{ isUpdate ? "Update" : "Submit" }}
        </button>
      </div>
    </form>


  </div>
</ng-template>
<ng-template #emailtemplate let-modal>


  <app-emailsettings [comefrom]="comefrom" (createTemplate)="createTemplate($event)"></app-emailsettings>

</ng-template>