<!-- <app-loginheader></app-loginheader> -->
<div class="login_details">
	<div class="login_data_list">
		<div class="login_description">
			<div class="login_logo">
				<a>
					<img src="../../../../assets//images/logo.png">
				</a>
			</div>
			<h1>
				Lost your password?
			</h1>
			<h1>Enter your details to recover.</h1>
			<p>
				Enter your details to proceed further
			</p>
			<div *ngIf="fp === 'fp1'">
				<form #emailForm="ngForm" class="login_description" (ngSubmit)="checkEmail()">
					<div class="personal_details">
						<div class="mb-3">
							<label for="formGroupExampleInput" class="form-label">Email</label>
							<div class="input_box mail_details">
								<input type="email" class="form-control label_input_field" placeholder="john.doe@gmail.com"
								id="email" name="email" [(ngModel)]="email" #emailInput="ngModel" required email>
								<span class="input-icon"> <i class="fa fa-envelope" aria-hidden="true"></i></span>
							</div>
							<div *ngIf="emailInput.invalid && (emailInput.dirty || emailInput.touched)" class="invalid-feedback">
								<div *ngIf="emailInput.errors?.required">Email is required</div>
								<div *ngIf="emailInput.errors?.email">Invalid email format</div>
							</div>
						</div>
					</div>
					<div class="btn-block end_forgot_btn">
						<button class="btn sendmail-btn btn-confirm" type="submit" >
							Recover
						</button>
						<button class="btn cancel-btn btn-cancel" type="button" (click)="cancel()">
							Cancel
						</button>
					</div>
				</form>
			</div>
			<div *ngIf="fp === 'fp2'" class="verification_wrp">
				<div class="form-group mb-3">
					<label for="old-password" class="label-txt">Verification</label>
					<input type="password" class="form-control mail_details" id="old-password"
						placeholder="verification" [(ngModel)]="compareNumber" name="comparePassword" />
				</div>

				<div class="btn-block end_forgot_btn">
					<button class="btn sendmail-btn btn-confirm" type="button" (click)="comparePassword()">
						Confirm
					</button>
					<button class="btn cancel-btn btn-cancel" type="button" (click)="cancel()">
						Cancel
					</button>
				</div>
			</div>
			<div *ngIf="fp === 'fp3'">
				<form (ngSubmit)="onForget(form.value)" #form="ngForm" class="new_password">
					<div class="form-group mb-3 verification_wrp">
						<label for="new-password" class="label-txt">New Password</label>
						<input type="password" class="form-control mail_details" id="new-password"
							placeholder="new password" ngModel name="newPassword" />
					</div>

					<div class="form-group mb-3 confirm_wrp">
						<label for="confirm-password" class="label-txt">Confirm Password</label>
						<input type="password" class="form-control mail_details" id="confirm-password"
							placeholder="confirm password" ngModel name="confirmPassword" />
					</div>

					<div class="btn-block end_submit_btn">
						<button class="btn sendmail-btn btn-blue" type="submit">submit</button>
						<button class="btn cancel-btn btn-gray" type="button" (click)="cancel()">
							Cancel
						</button>
					</div>
				</form>
			</div>
			<ngb-alert type="danger" *ngIf="error" [dismissible]="false">{{
				error
				}}</ngb-alert>
			<div class="social_media">
				<a href="https://twitter.com/AccessQue/" target="_blank">
					<i class="fa-brands fa-twitter"></i>
				</a>

				<a href="https://www.facebook.com/profile.php?id=100064747820124" target="_blank">
					<i class="fa-brands fa-linkedin"></i>
				</a>

				<a href="https://www.linkedin.com/showcase/77110702/admin/feed/posts/" target="_blank">
					<i class="fa-brands fa-facebook-f"></i>
				</a>

				<a href="https://www.instagram.com/accessque_crm/" target="_blank">
					<i class="fa-brands fa-instagram"></i>
				</a>
			</div>
		</div>
	</div>

	<div class="login_image">
	</div>
</div>