import { Injectable } from '@angular/core';
import { HttpClientService } from 'src/app/httpclient.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LipsyncService {
  baseApiUrl2: any = environment.baseApiUrl2;
  constructor(private http: HttpClientService) { }

  checkUser(data) {
    return this.http.post(`${this.baseApiUrl2}user`, data)
  }
  uploadVideo(data) {
    return this.http.post(`${this.baseApiUrl2}uploadVideo`, data)
  }
}
