<div class="reportFilter-block">
    <!-- FOR Courses -->
    <div class="filter_blocks" #Program>
        <div class="dropdown_list">
            <button type="button" class="btn btn-light btn-sm" (click)="toggleFilters('showCourses');"
                [ngClass]="{'btn-filter-active': courseSelectionArray.length>0}">
                Program
                <span *ngIf="courseSelectionArray.length>0 && courseSelectionArray.length == coursesList.length">:
                    All</span>
                <span *ngIf="courseSelectionArray.length>0 && courseSelectionArray.length != coursesList.length">{{
                    ":"+courseSelectionArray[courseSelectionArray.length-1]}}</span>
                <span *ngIf="courseSelectionArray.length>1 && courseSelectionArray.length != coursesList.length"
                    class="badge_count">+{{courseSelectionArray.length}}</span>
                <i class="fa-solid fa-angle-down arrow-down"></i>
            </button>
            <div class="drop-show drop-show-label" *ngIf="showCourses">
                <ul class="more_dropdown_list">
                    <li><input type="text" placeholder="Search" class="form-input spl-search-icon"></li>
                    <li>
                        <div class="input_wrp_filter">
                            <input class="more_dropdown_list_input" type="checkbox" value="" id="defaultCheck1"
                                name="all" [checked]="isAllCheckedcourse()"
                                (change)="checkAllcourse($event.target.checked)" />
                            <label><span>All</span></label>
                        </div>
                    </li>
                    <li *ngFor="let a of coursesList">
                        <div class="input_wrp_filter">
                            <input class="more_dropdown_list_input" type="checkbox"
                                (change)="courseSelection($event.target)" [value]="a.course" id="defaultCheck1"
                                name="sizecb[]" [(ngModel)]="a.courseState" />
                            <label><span>{{a.course}}</span></label>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <!-- FOR Applications -->
    <div class="filter_blocks" #Applications>
        <div class="dropdown_list">
            <button type="button" class="btn btn-light btn-sm" (click)="toggleFilters('showApplications');"
                [ngClass]="{'btn-filter-active': applicationSelectionArray.length>0}">
                Document Status
                <span
                    *ngIf="applicationSelectionArray.length>0 && applicationSelectionArray.length == applicationsList.length">
                    {{ ":"+ " All"}}</span>
                <span
                    *ngIf="applicationSelectionArray.length>0 && applicationSelectionArray.length != applicationsList.length">
                    {{ ":"+ getApplicationName(applicationSelectionArray[applicationSelectionArray.length-1])}}</span>
                <span
                    *ngIf="applicationSelectionArray.length>1 && applicationSelectionArray.length != applicationsList.length"
                    class="badge_count">+{{applicationSelectionArray.length}}</span>
                <i class="fa-solid fa-angle-down arrow-down"></i>
            </button>
            <div class="drop-show drop-show-date" *ngIf="showApplications">
                <ul class="more_dropdown_list">
                    <li><input type="text" placeholder="Search" class="form-input spl-search-icon"></li>
                    <li>
                        <div class="input_wrp_filter">
                            <input class="more_dropdown_list_input" type="checkbox" value="" id="defaultCheck1"
                                name="all" [checked]="isAllCheckedapplication()"
                                (change)="checkAllapplication($event.target.checked)" />
                            <label><span>All</span></label>
                        </div>
                    </li>
                    <li *ngFor="let a of applicationsList">
                        <div class="input_wrp_filter">
                            <input class="more_dropdown_list_input" type="checkbox"
                                (change)="applicationSelection($event.target)" [value]="a.value" id="defaultCheck1"
                                name="sizecb[]" [(ngModel)]="a.applicationState" />
                            <label><span>{{a.LeadDocStatus | titlecase}}</span></label>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <!-- FOR CAll status -->
    <div class="filter_blocks" #CallStatus>
        <div class="dropdown_list">
            <button type="button" class="btn btn-light btn-sm" (click)="toggleFilters('showApplicationsCall');"
                [ngClass]="{'btn-filter-active': applicationCallSelectionArray.length>0}">
                Call Status
                <span
                    *ngIf="applicationCallSelectionArray.length>0 && applicationCallSelectionArray.length == applicationsCallList.length">
                    {{ ":"+ " All"}}</span>
                <span
                    *ngIf="applicationCallSelectionArray.length>0 && applicationCallSelectionArray.length != applicationsCallList.length">
                    {{ ":"+
                    getApplicationCallName(applicationCallSelectionArray[applicationCallSelectionArray.length-1])}}</span>
                <span
                    *ngIf="applicationCallSelectionArray.length>1 && applicationCallSelectionArray.length != applicationsCallList.length"
                    class="badge_count">+{{applicationCallSelectionArray.length}}</span>
                <i class="fa-solid fa-angle-down arrow-down"></i>
            </button>
            <div class="drop-show drop-show-date" *ngIf="showApplicationsCall">
                <ul class="more_dropdown_list">
                    <li><input type="text" placeholder="Search" class="form-input spl-search-icon"></li>
                    <li>
                        <div class="input_wrp_filter">
                            <input class="more_dropdown_list_input" type="checkbox" value="" id="defaultCheck1"
                                name="all" [checked]="isAllCheckedapplicationCall()"
                                (change)="checkAllCallapplication($event.target.checked)" />
                            <label><span>All</span></label>
                        </div>
                    </li>
                    <li *ngFor="let a of applicationsCallList">
                        <div class="input_wrp_filter">
                            <input class="more_dropdown_list_input" type="checkbox"
                                (change)="applicationCallSelection($event.target)" [value]="a.value" id="defaultCheck1"
                                name="sizecb[]" [(ngModel)]="a.applicationStateCall" />
                            <label><span>{{a.LeadCallStatus | titlecase}}</span></label>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <!-- FOR Users -->
    <div class="filter_blocks" #Users>
        <div class="dropdown_list">
            <button type="button" class="btn btn-light btn-sm" (click)="toggleFilters('showUsers');"
                [ngClass]="{'btn-filter-active': usersSelectionArray.length>0}">
                Users
                <span *ngIf="usersSelectionArray.length>0 && usersSelectionArray.length == agentsList.length">
                    {{ ":"+ " All"}}</span>
                <span *ngIf="usersSelectionArray.length>0 && usersSelectionArray.length != agentsList.length">
                    {{ ":"+ getAgentName(usersSelectionArray[usersSelectionArray.length-1])}}</span>
                <span *ngIf="usersSelectionArray.length>1 && usersSelectionArray.length != agentsList.length"
                    class="badge_count">+{{usersSelectionArray.length}}</span>
                <i class="fa-solid fa-angle-down arrow-down"></i>
            </button>
            <div class="drop-show drop-show-date" *ngIf="showUsers">
                <ul class="more_dropdown_list">
                    <li><input type="text" placeholder="Search" class="form-input spl-search-icon"></li>
                    <li>
                        <div class="input_wrp_filter">
                            <input class="more_dropdown_list_input" type="checkbox" value="" id="defaultCheck1"
                                name="all" [checked]="isAllCheckedusers()"
                                (change)="checkAllusers($event.target.checked)" />
                            <label style="display: flex !important;">
                                <div class="pic mr-1 float-left text-center">
                                    <p class="initials m-0" style="background: #92001b;">
                                        <span style="font-size: 9px !important;color: #fff;">AL</span>
                                    </p>
                                </div>
                                <span>All</span>
                            </label>
                        </div>
                    </li>
                    <li *ngFor="let a of agentsList">
                        <div class="input_wrp_filter">
                            <input class="more_dropdown_list_input" type="checkbox"
                                (change)="usersSelection($event.target)" [value]="a.Mailid" id="defaultCheck1"
                                name="sizecb[]" [(ngModel)]="a.usersState" />
                            <label style="display: flex !important;">
                                <div *ngIf="a.agentname" class="pic mr-1 float-left text-center" style="width:20px;">
                                    <img *ngIf="a.ProfilePhoto != (null ||  '' )" src="{{ a.ProfilePhoto }}" style="width: 100%;
                                        
                                        object-fit: cover;
                                        border-radius: 100px;"
                                        onerror="this.onerror=null;this.src='../assets/img/profile-dummy.png';" />

                                    <p [ngStyle]="{ background: a.AgentColor.bg }" class="initials m-0"
                                        *ngIf="a.ProfilePhoto == null || a.ProfilePhoto == '' ">
                                        <span [ngStyle]="{ color: a.AgentColor.color }"
                                            style="font-size: 9px !important;"> {{ a.agentInitial }}</span>
                                    </p>
                                </div>
                                <span>{{a.agentname | titlecase}}</span>
                            </label>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <!-- FOR Enrollment Year -->
    <div class="filter_blocks" #EnrollmentYear>
        <div class="dropdown_list">
            <button type="button" class="btn btn-light btn-sm" (click)="toggleFilters('showEnrollmentYear');"
                [ngClass]="{'btn-filter-active': enrollmentYearSelectionArray.length>0}">
                Enrollment Year
                <span
                    *ngIf="enrollmentYearSelectionArray.length>0 && enrollmentYearSelectionArray.length == enrollmentYearList.length">
                    {{ ":"+ " All"}}</span>
                <span
                    *ngIf="enrollmentYearSelectionArray.length>0 && enrollmentYearSelectionArray.length != enrollmentYearList.length">
                    {{ ":"+ enrollmentYearSelectionArray[enrollmentYearSelectionArray.length-1]}}</span>
                <span
                    *ngIf="enrollmentYearSelectionArray.length>1  && enrollmentYearSelectionArray.length != enrollmentYearList.length"
                    class="badge_count">+{{enrollmentYearSelectionArray.length}}</span>

                <i class="fa-solid fa-angle-down arrow-down"></i>
            </button>
            <div class="drop-show drop-show-enrollment" *ngIf="showEnrollmentYear">
                <ul class="more_dropdown_list">
                    <li><input type="text" placeholder="Search" class="form-input spl-search-icon"></li>
                    <li>
                        <div class="input_wrp_filter">
                            <input class="more_dropdown_list_input" type="checkbox" id="defaultCheck1" name="all"
                                [checked]="isAllCheckedenrollmentYear()"
                                (change)="checkAllenrollmentYear($event.target.checked)" />
                            <label><span>All</span></label>
                        </div>
                    </li>
                    <li *ngFor="let a of enrollmentYearList">
                        <div class="input_wrp_filter">
                            <input class="more_dropdown_list_input" type="checkbox"
                                (change)="enrollmentYearSelection($event.target)" [value]="a.EnrollmentYear"
                                id="defaultCheck1" name="sizecb[]" [(ngModel)]="a.enrollmentYearState" />
                            <label><span>{{a.EnrollmentYear}}</span></label>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <!-- FOR Enrollment Term -->
    <div class="filter_blocks" #EnrollmentTerm>
        <div class="dropdown_list">
            <button type="button" class="btn btn-light btn-sm" (click)="toggleFilters('showEnrollmentTerm');"
                [ngClass]="{'btn-filter-active': enrollmentTermSelectionArray.length>0}">
                Enrollment Term
                <span
                    *ngIf="enrollmentTermSelectionArray.length>0 && enrollmentTermSelectionArray.length == enrollmentTermList.length">
                    {{ ":"+ " All"}}</span>
                <span
                    *ngIf="enrollmentTermSelectionArray.length>0 && enrollmentTermSelectionArray.length != enrollmentTermList.length">
                    {{ ":"+ enrollmentTermSelectionArray[enrollmentTermSelectionArray.length-1]}}</span>
                <span
                    *ngIf="enrollmentTermSelectionArray.length>1  && enrollmentTermSelectionArray.length != enrollmentTermList.length"
                    class="badge_count">+{{enrollmentTermSelectionArray.length}}</span>

                <i class="fa-solid fa-angle-down arrow-down"></i>
            </button>
            <div class="drop-show drop-show-enrollment" *ngIf="showEnrollmentTerm">
                <ul class="more_dropdown_list">
                    <li><input type="text" placeholder="Search" class="form-input spl-search-icon"></li>
                    <li>
                        <div class="input_wrp_filter">
                            <input class="more_dropdown_list_input" type="checkbox" id="defaultCheck1" name="all"
                                [checked]="isAllCheckedenrollmentTerm()"
                                (change)="checkAllenrollmentTerm($event.target.checked)" />
                            <label><span>All</span></label>
                        </div>
                    </li>
                    <li *ngFor="let a of enrollmentTermList">
                        <div class="input_wrp_filter">
                            <input class="more_dropdown_list_input" type="checkbox"
                                (change)="enrollmentTermSelection($event.target)" [value]="a.EnrollmentTerm"
                                id="defaultCheck1" name="sizecb[]" [(ngModel)]="a.enrollmentTermState" />
                            <label><span>{{a.EnrollmentTerm}}</span></label>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <!-- FOR Date -->
    <div class="filter_blocks" #Date>
        <div class="dropdown_list">
            <button type="button" class="btn btn-filter btn-filter-active"
                (click)="toggleFilters('showDate');hideDate()">
                Date <span> {{":"}} {{startDate | date: 'dd MMMM yyyy'}} -
                    {{endDate | date: 'dd MMMM yyyy'}}</span> <i class="fa-solid fa-angle-down arrow-down"></i>
            </button>
            <div class="drop-show drop-show-date" *ngIf="showDate">
                <ul class="more_dropdown_list">
                    <li><input type="text" placeholder="Search" class="form-input spl-search-icon"></li>
                    <li>
                        <div class="input_wrp_filter">
                            <input class="more_dropdown_list_input_radio" type="radio" value="24hrs"
                                [checked]="selectedIndexDate === '24hrs'"
                                (change)="toggleFilters('showDate');dateSelection($event)" />
                            <label><span>24 hrs</span></label>
                        </div>
                    </li>
                    <li>
                        <div class="input_wrp_filter">
                            <input class="more_dropdown_list_input_radio" type="radio" value="72hrs"
                                [checked]="selectedIndexDate === '72hrs' "
                                (change)="toggleFilters('showDate');dateSelection($event)" />
                            <label><span>72 hrs</span></label>
                        </div>
                    </li>
                    <li>
                        <div class="input_wrp_filter">
                            <input class="more_dropdown_list_input_radio" type="radio" value="07days"
                                [checked]="selectedIndexDate === '07days' "
                                (change)="toggleFilters('showDate');dateSelection($event)" />
                            <label><span>07 days</span></label>
                        </div>
                    </li>
                    <li>
                        <div class="input_wrp_filter">
                            <input class="more_dropdown_list_input_radio" type="radio" value="14days"
                                [checked]="selectedIndexDate === '14days' "
                                (change)="toggleFilters('showDate');dateSelection($event)" />
                            <label><span>14 days</span></label>
                        </div>
                    </li>
                    <li>
                        <div class="input_wrp_filter">
                            <input class="more_dropdown_list_input_radio" type="radio" value="30days"
                                [checked]="selectedIndexDate === '30days' "
                                (change)="toggleFilters('showDate');dateSelection($event)" />
                            <label><span>30 days</span></label>
                        </div>
                    </li>
                    <li>
                        <div class="input_wrp_filter">
                            <input class="more_dropdown_list_input_radio" type="radio" value="yearly"
                                [checked]="selectedIndexDate === 'yearly' "
                                (change)="toggleFilters('showDate');dateSelection($event)" />
                            <label><span>Yearly</span></label>
                        </div>
                    </li>
                    <li class="d-block">
                        <div class="input_wrp_filter">
                            <input class="more_dropdown_list_input_radio" type="radio" value="custom"
                                [checked]="selectedIndexDate === 'custom' " (change)="dateSelection($event)" />
                            <label><span>Custom</span></label>
                        </div>
                        <div *ngIf="showDateCustom" style="position: relative;">
                            <div class="datePicker">
                                <div style="background: #fff; height: 76px; border: 1px solid #ccc" class="custom-date">
                                    <mat-form-field class="mr-3">
                                        <input class="label" matInput [matDatepicker]="picker1" placeholder="Start Date"
                                            [formControl]="date2" (dateInput)="getStartDate('input', $event)" />
                                        <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                                        <mat-datepicker #picker1 style="width: 80px"></mat-datepicker>
                                    </mat-form-field>

                                    <mat-form-field class="mr-3">
                                        <input class="label" matInput [matDatepicker]="picker2" placeholder="End Date"
                                            [formControl]="date1" (dateInput)="getEndDate('input', $event)" />
                                        <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                                        <mat-datepicker #picker2 style="width: 80px"></mat-datepicker>
                                    </mat-form-field>
                                    <button class="btn btn-secondary btn-sm"
                                        (click)="toggleFilters('showDate');filterData()">
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-md-12 text-right">
        <button type="button" class="btn btn-primary btn-sm mr-2" (click)="generateData()">Apply</button>
        <button type="button" class="btn btn-secondary btn-sm" (click)="exportData()">Export</button>
    </div>
</div>