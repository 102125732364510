import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "roleName",
})
export class RoleNamePipe implements PipeTransform {
  transform(value: any, projectId: any, project: any[]): any {
    if (project.find((val) => val["id"] === projectId)) {
      const ele = project.find((val) => val["id"] === projectId);
      return ele["Name"] + "-" + value;
    }
    return value;
  }
}
