import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClientService } from '../httpclient.service';

@Injectable({
  providedIn: 'root'
})
export class BookdemoService {
  baseUrl2 = environment.baseApiUrl2;
  constructor(
    private http: HttpClientService
  ) { }

  bookdemo(data) {
    return this.http.post(`${this.baseUrl2}bookdemo`, data);
  }
}
