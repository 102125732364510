import { Component, OnInit, ViewChild, Inject, OnDestroy, ViewChildren, ElementRef, TemplateRef } from "@angular/core";
import { FormControl, ReactiveFormsModule } from "@angular/forms";
import { SharedService } from "src/app/shared/shared.service";
import { UhLeadsService } from "../uhleads.service";
import * as xlsx from "xlsx";
import { DOCUMENT } from "@angular/common";
import { NgbModal, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import { LayoutService } from "src/app/layout/layout.service";
import { MomentFormatService } from "src/app/moment-format-service";
import { post } from "jquery";
import { MarketingService, InputSMSData } from "../marketing/marketing.service";
import { RoleService } from "../role-management/role.service";
import { CreateAgent } from "../create-agent/create-agent.service";
import { Router, ActivatedRoute } from "@angular/router";
import { ErrorHandlerService } from "src/app/error-handler.service";
import { ReferreeService } from "../referree/referree.service";
var sortJsonArray = require('node_modules/sort-json-array');
import { Exceldata } from "../marketing/smsmarketing/models/ExecelData.model";
import { UhubDashboardService } from "./uhub-dashboard.service";
import { environment } from "src/environments/environment";
import { AbstractControl, Validators } from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import * as moment from "moment";
import { NotificationsService } from "src/app/notification.service";
import { fromEvent } from "rxjs";
import {
  filter,
  debounceTime,
  distinctUntilChanged,
  tap,
} from "rxjs/operators";
import { CustomService } from "src/components/customs/custom.service";
import { ColumnSetting, RowSetting } from "src/components/customs/layout.model";


@Component({
  selector: 'app-uhub-dashboard',
  templateUrl: './uhub-dashboard.component.html',
  styleUrls: ['./uhub-dashboard.component.css']
})
export class UhubDashboardComponent implements OnInit {

  userData: any;
  projectId: any;
  admin: any;
  public error: boolean = false;
  public errorMessage: string = "";
  endDate: any;
  startDate: any;
  date1: any;
  date2: any;
  sideViewerData: any = { Activities: [], CallDetails: [] };
  currentLeadId: any;
  activityfilterString: string = "";
  minItems = 5;
  leadSearch: string = "";
  currentCard: string = "total";
  sortColumn = "createdAt";
  sortOrder = "des";
  smsText: string;
  smsList: [] = [];
  projectLists: any;
  public filterValue = "all";
  showDate: boolean = false;
  filterString: string;
  LeadList: any[] = [];
  leadType: string = "total";
  tab!: string;
  @ViewChild("uploadFile") uploadFile;
  @ViewChild("closeButton") closeButton;
  @ViewChild("inputString", { static: true }) input: ElementRef;
  @ViewChild("totalRecords", { static: true }) totalRecords: ElementRef;
  @ViewChild("sideNav", { static: true }) sideNav;


  topBarCount: {
    total: number;
    facebook: number;
    web: number;
    followup: number;
    pending: number;
    followupDate: number;
    counsellingpending: number;
    counsellingcompleted: number;
    interested: number;
    qualifiedLead: number;
    qualifiedHotLead: number;
    assignedToMe: number;
  } = {
      total: 0,
      facebook: 0,
      web: 0,
      followup: 0,
      pending: 0,
      followupDate: 0,
      counsellingpending: 0,
      counsellingcompleted: 0,
      interested: 0,
      qualifiedLead: 0,
      qualifiedHotLead: 0,
      assignedToMe: 0
    };

  leadtoEdit: {
    LeadId: string;
    FirstName: string;
    LastName: string;
    CountryCode: string;
    PhoneNumber: string;
    Email: string;
    University: string;
    CallStatus: string;
    AgentMail: string;
  };

  public marketingId: {
    id: string;
    status: string;
    phoneNumber: string;
    email: string;
    name: string;
    module: string;
    city: string;
    nextCaller: any;
    lead: any;
  } = {
      id: "",
      status: "",
      name: "",
      email: "",
      phoneNumber: "",
      module: "",
      city: "",
      nextCaller: null,
      lead: {},
    };

  private colors = [
    [{ bg: "blueviolet", color: "white" }],
    [{ bg: "AntiqueWhite", color: "white" }],
    [{ bg: "Coral", color: "white" }],
    [{ bg: "Crimson", color: "white" }],
    [{ bg: "DarkOrange", color: "white" }],
    [{ bg: "DeepPink", color: "white" }],
    [{ bg: "#c5924e", color: "white" }],
  ];


  public filePath: any;
  public fileData: Exceldata[] = [];
  public uploaded: boolean = false;
  public insertCount: number = 0;
  public callData: any[] = [];
  public startCall: boolean = false;
  public stopCall: boolean = false;
  public startIndex: number = 0;
  public statusValue = "all";
  public clearTime: any;
  UhubList: any[] = [];
  UhubList1: any;
  allLeads: any;
  public fileSource = environment.fileServer;
  public downloadLink: string;
  public page: any = 1;
  public pageSize: any = 12;
  selectedAll: boolean = false;
  leadIds = [];
  dialerScreen1 = "dialer";
  callStatus: string = "Calling..";
  newStatus: any;
  selectedLead: any;
  nextCallerName: any;
  nextCallerCity: any;
  nextCallerNumber: any;
  leadsLoading: boolean = false;
  noLeadsFound: boolean = false;
  agentList: any;
  pageNumber: Number = 1;
  totalPages: Number = 0;
  callStatusFilter: String = 'all';
  public currentLead: any;
  public isUpdateLead: boolean = false;
  public submitted: boolean = false;
  defaultAgent = "default";
  selectedAgent: any;
  totalAgents: any;
  agentName: any;
  GBPagentList: any;

  public search: string;
  isLoading: boolean = true;
  noRecords: boolean = false;
  listFeed: any;
  @ViewChild('editLead2') editLead2: TemplateRef<any>;
  @ViewChild('totalCallCount') totalCallCount: TemplateRef<any>;
  @ViewChild('staticBackdrop') staticBackdrop: TemplateRef<any>;
  projectSettings: ColumnSetting[] = [];
  projectSettings1: any[] = [];
  rowSetting: RowSetting = {
    records: 12,
    add: false,
    edit: true,
    delete: false,
    view: true,
    call: true
  }

  constructor(
    private SharedService: SharedService,
    private momentService: MomentFormatService,
    private companyService: RoleService,
    private uhleadsservice: UhLeadsService,
    private ReferreeService: ReferreeService,
    public marketingService: MarketingService,
    private readonly handleError: ErrorHandlerService,
    private router: Router,
    public layoutService: LayoutService,
    public UhubDashboardService: UhubDashboardService,
    private readonly modalService: NgbModal,
    private readonly snackbarRef: MatSnackBar,
    private agentService: CreateAgent,
    private NotificationService: NotificationsService,

    private customService: CustomService,

    @Inject(DOCUMENT) private domService: HTMLDocument
  ) {

    // NEW CODE REUSEABLE
    var tablesList = [
      {
        primaryKey: 'createdAt',
        header: 'Created Date',
        format: 'date',
        sortKey: 'createdAt',
        view: true
      },
      {
        primaryKey: 'name',
        header: 'Name',
        sortKey: 'name',
        view: true
      },
      {
        primaryKey: 'Email',
        header: 'Email',
        sortKey: 'Email',
        view: true
      },
      {
        primaryKey: 'AssignedtoName',
        header: 'Assigned To',
        sortKey: 'AssignedtoName',
        view: true
      },
      {
        primaryKey: 'FormName',
        header: 'Form Name',
        sortKey: 'FormName',
        view: true
      },
      {
        primaryKey: 'PhoneNumber',
        header: 'Phone',
        sortKey: 'PhoneNumber',
        view: true
      },
      {
        primaryKey: 'Channel',
        header: 'Channel',
        sortKey: 'Channel',
        view: true
      },
      {
        primaryKey: 'CallStatus',
        header: 'Call Status',
        sortKey: 'CallStatus',
        view: true
      },
      {
        primaryKey: 'id',
        header: 'Action',
        view: true
      }
    ];
    this.sortReusbaleTable(tablesList);
    for (let i = 0; i < tablesList.length; i++) {
      this.projectSettings1.push(tablesList[i]);
    }
  }

  ngOnInit(): void {

    this.SharedService.pageEvent.emit({
      pageName: "Leads Dashboard",
    });
    var x = document.getElementsByClassName(
      "sideDataViewer"
    ) as HTMLCollectionOf<HTMLElement>;
    for (let i = 0; i < x.length; i++) {
      x[i].style.display = "none";
    }
    this.getAgentNames();
    this.date1 = new FormControl(new Date());
    this.date2 = new FormControl(new Date(Date.now() - 864e5));
    this.startDate = this.momentService.dashboardDateFormat(
      new Date(new Date(Date.now() - 864e5 * 365)).getTime()
    );
    this.endDate = this.momentService.dashboardDateFormat(
      new Date(new Date(Date.now() + 864e5))
    );

    this.userData = JSON.parse(localStorage.getItem("userData"));

    this.companyService.projectAdmin(this.userData['companyId']).subscribe((projects) => {
      this.projectId = projects.filter(x => x.Name == 'Accessque')[0].id;
      if (this.projectId) {
        this.listFeedback();
        this.SharedService.getProjectAdmin(this.projectId).subscribe(
          (data: any) => {
            this.admin = data;
            setTimeout(() => {
              // this.getListofUniversities();
              this.getTopBarCount();
              this.onClick(1);
              this.getLeadsByCards(this.leadType);
              this.getUniversalRecords();
              // this.getLead();
            }, 500);

            this.customService.pageNumber.subscribe((pageNo: any) => {
              this.pageNumber = pageNo;
              console.log("Clicked on page number:", pageNo);
              this.getLeads(this.admin, this.projectId, this.userData.mail, this.leadType, this.startDate, this.endDate, this.pageNumber, this.callStatusFilter);

            });

            this.uhleadsservice
              .getAgentList(this.projectId, this.userData.id)
              .subscribe((data: any) => {
                this.agentList = data;
                // for (let i = 0; i < this.agentList.length; i++) {
                //   if (this.agentList[i].admin == true) {
                //     this.agentList.pop(i);
                //   }
                // }
              }, (error) => {
                this.handleError.handleError(error)
              });
          }, (error) => {
            this.handleError.handleError(error)
          });
      }
    }, (error) => {
      this.handleError.handleError(error)
    });



    this.marketingService
      .getExcelLink(this.fileSource + "/TemplateDocuments/accessque.xlsx")
      .subscribe((data) => {
        this.downloadLink = data["url"];
        // console.log("this.downloadLink", this.downloadLink);

      }, (error) => {
        this.handleError.handleError(error)
      });


    // server-side search
    fromEvent(this.input.nativeElement, "keyup")
      .pipe(
        filter(Boolean),
        debounceTime(1500),
        distinctUntilChanged(),
        tap((event: KeyboardEvent) => {
          this.search = this.input.nativeElement.value;
          this.pageNumber = 1;
          // console.log(event);
          // console.log(this.input.nativeElement.value);
          this.searchLeads(this.input.nativeElement.value, this.pageNumber);
        })
      )
      .subscribe();

  }

  audioPlay(e) {
    let eAudio = this.domService.getElementsByTagName("audio");
    if (eAudio && eAudio.length > 0) {
      for (var i = 0; i < eAudio.length; i++) {
        if (e.target !== eAudio[i]) {
          eAudio[i].pause();
        }
      }
    }
  }

  showmore(itemsCount) {
    if (itemsCount < this.minItems) {
    } else if (this.minItems + 5 < itemsCount) {
      this.minItems = this.minItems + 5;
    } else if (this.minItems < itemsCount) {
      this.minItems = itemsCount;
    }
  }
  onInputActivity(event: any) {
    if (this.leadSearch != "") {
      this.minItems = event;
    } else {
      this.minItems = 5;
    }
  }

  sendSms(item, HTMLid) {
    this.smsText = (<HTMLInputElement>(
      document.getElementById(HTMLid)
    )).value;
    let fromNumber = '';
    this.companyService.projectAdmin(13).subscribe((res: any) => {
      this.projectLists = res;
      // console.log(res);
      for (let i = 0; i < res.length; i++) {
        if (res[i].Name == 'Accessque') {
          fromNumber = res[i].TwilioNumber;
        }
      }
      let payload = {
        text: this.smsText,
        from: fromNumber,
        to: '+' + item.CountryCode + item.PhoneNumber,
        AgentMail: this.userData.mail
      }
      // console.log(payload);
      this.UhubDashboardService.sendSms(payload, this.projectId).subscribe((res: any) => {
        this.uhleadsservice.showInfo('Message successfully sent.', 'Sent');
        this.UhubDashboardService.getSms(this.currentLeadId).subscribe((smsList: any) => {
          this.smsList = smsList.SMSDetails;
          (<HTMLInputElement>(
            document.getElementById(HTMLid)
          )).value = ''
        })
      })
    })

  }

  updateDateFilter(event: any) {
    if (event.target.value != "custom") {
      this.endDate = this.momentService.dashboardDateFormat(
        new Date(new Date(Date.now())).getTime()
      );
    }
    if (event.target.value == "custom") {
      //show custom date filter
      this.showDate = true;
    } else if (event.target.value == "all") {
      this.startDate = this.momentService.dashboardDateFormat(
        new Date(new Date(Date.now() - 864e5 * 365)).getTime()
      );

      this.showDate = false;
    } else if (event.target.value == "24hrs") {
      this.startDate = this.momentService.dashboardDateFormat(
        new Date(new Date(Date.now() - 864e5)).getTime()
      );

      this.showDate = false;
    } else if (event.target.value == "72hrs") {
      this.startDate = this.momentService.dashboardDateFormat(
        new Date(new Date(Date.now() - 864e5 * 3)).getTime()
      );

      this.showDate = false;
    } else if (event.target.value == "7days") {
      this.startDate = this.momentService.dashboardDateFormat(
        new Date(new Date(Date.now() - 864e5 * 7)).getTime()
      );

      this.showDate = false;
    } else if (event.target.value == "14days") {
      this.startDate = this.momentService.dashboardDateFormat(
        new Date(new Date(Date.now() - 864e5 * 14)).getTime()
      );

      this.showDate = false;
    } else if (event.target.value == "30days") {
      this.startDate = this.momentService.dashboardDateFormat(
        new Date(new Date(Date.now() - 864e5 * 30)).getTime()
      );

      this.showDate = false;
    }
    this.getTopBarCount();
    this.getLeads(this.admin, this.projectId, this.userData.mail, 'total', this.startDate, this.endDate, this.pageNumber, this.callStatusFilter);
    // write here
    // this.getReferralList(
    //   this.admin,
    //   this.projectId,
    //   this.userData.mail,
    //   "all",
    //   this.startDate,
    //   this.endDate
    // );
  }

  hideDate() {
    // this.filterValuev
    if (this.filterValue == "custom" && this.showDate == false) {
      this.showDate = true;
    }
  }

  filterData() {
    // this.onClick(null);
    this.startDate = this.momentService.dashboardDateFormat(
      new Date(this.date2.value).getTime()
    );
    this.endDate = this.momentService.dashboardDateFormat(
      new Date(this.date1.value).getTime()
    );

    if (this.endDate < this.startDate) {
      // window.alert("Choose the right date format!");
      this.uhleadsservice.showWarning(
        "End date cannot be less than Start date!",
        "Wrong Date!"
      );
      return;
    } else {

      // write here
      // this.getReferralList(
      //   this.admin,
      //   this.projectId,
      //   this.userData.mail,
      //   "all",
      //   this.startDate,
      //   this.endDate
      // );
    }
  }

  getStartDate(type: string, event: MatDatepickerInputEvent<Date>) {
    this.startDate = event.value;
    this.startDate = this.momentService.dashboardDateFormat(
      new Date(this.startDate).getTime
    );
  }
  getEndDate(type: string, event: MatDatepickerInputEvent<Date>) {
    this.endDate = event.value;
    this.endDate = this.momentService.dashboardDateFormat(
      new Date(this.endDate).getTime
    );
  }

  getLeadsByCards(cardValue) {
    this.filterString = "";
    this.LeadList = [];
    let obj;
    this.leadType = cardValue;
    this.statusValue = 'all';
    this.callStatusFilter = 'all';
    obj = {
      admin: this.admin,
      mail: this.userData.mail,
      ProjectId: this.projectId,
      leadType: this.leadType,
      start: this.startDate,
      end: this.endDate,
      page: 1
    };
    if (this.leadType == 'pending' || this.leadType == 'followup' || this.leadType == 'interested') {
      this.callStatusFilter = this.leadType;
      this.statusValue = this.leadType;
    }
    this.getTopBarCount();
    this.getLeads(this.admin, this.projectId, this.userData.mail, cardValue, this.startDate, this.endDate, this.pageNumber, this.callStatusFilter);
  }

  onClick(check: any) {
    if (check) {
      this.tab = "tab" + check;
    } else {
      this.tab = "";
    }
  }

  convertFile(e) {
    this.filePath = e.target.files[0]["name"].toString();
    // console.log(e, this.filePath);
    let reader = new FileReader();
    reader.readAsArrayBuffer(e.target.files[0]);
    reader.onload = (eve) => {
      let data = new Uint8Array(reader.result as any);
      let wb = xlsx.read(data, { type: "array" });
      let sheet_name = wb.SheetNames;
      let sheet_data: any[][] = xlsx.utils.sheet_to_json(
        wb.Sheets[sheet_name[0]],
        { header: 1 }
      );
      if (sheet_data[0][0] === "First Name" && sheet_data.length !== 0) {
        this.fileData =
          this.marketingService.convert2dArraytoObject(sheet_data);
        // console.log("this.fileData", sheet_data);
        this.marketingService
          .postCallData({
            data: this.fileData,
            createdBy: this.userData["mail"],
            ProjectId: this.projectId,
            type: 'Manual'
          })
          .subscribe((data) => {
            // console.log(data);
            this.uploaded = true;
            this.error = false;
            this.insertCount = data["count"];
            setTimeout(() => {
              this.uploaded = false;
              this.filePath = "";
            }, 5000);
            // write here
            // this.getDashboardLeads(this.leadType);
          }, (error) => {
            this.handleError.handleError(error)
          });
      } else {
        this.error = true;
        this.errorMessage = "Please Upload a Valid File";
      }
    };
    this.uploadFile.nativeElement.value = "";
  }

  redirectToUpload() {
    let menuid = 0;
    this.router.navigate(['./upload/' + this.projectId+'/'+menuid]);
  }


  startCallListener() {
    // console.log(this.startCall);
    if (this.callData.length === 0) {
      this.startCall = false;
      return;
    }

    if (!this.startCall) {
      if (this.statusValue === "pending") {
        this.startIndex = 0;
      }
      // console.log("data emitted");

      this.layoutService.marketingCall.next({
        id: this.callData[this.startIndex]["id"],
        number: "+" + this.callData[this.startIndex]["CountryCode"] + this.callData[this.startIndex]["PhoneNumber"],
        name: this.callData[this.startIndex]["FirstName"],
        email: this.callData[this.startIndex]["Email"],
        comments: this.callData[this.startIndex]["Comments"],
        module: this.callData[this.startIndex]["Module"],
        city: this.callData[this.startIndex]["city"],
        nextCaller: this.callData[this.startIndex + 1],
        lead: this.callData[this.startIndex],
        callFeedbacks: this.listFeed
      });
      this.startCall = true;
    }
  }

  followUpCall(data) {
    console.log("Followup call: ", data);
    if (!this.startCall) {
      this.layoutService.marketingCall.next({
        id: data["id"],
        number: "+" + data["CountryCode"] + data["PhoneNumber"],
        name: data["FirstName"],
        email: data["Email"],
        comments: data["Comments"],
        module: data["Module"],
        city: data["city"],
        nextCaller: null,
        lead: data,
        callFeedbacks: this.listFeed
      });
      this.startCall = true;
    }

  }

  startCallListener2(data, index) {
    this.dialerScreen1 = "outgoing1";
    this.callStatus = "Calling..";
    $("input[name=option1]").prop("checked", false);

    this.newStatus = false;

    this.callData[0] = data;
    // console.log(this.callData[0]);
    this.selectedLead = data;

    this.marketingId = {
      id: data.id,
      status: data.CallStatus,
      name: data.FirstName,
      email: data.Email,
      phoneNumber: "+" + data.CountryCode + data.PhoneNumber,
      // phoneNumber: "+919848311098",
      module: data.Module,
      city: "",
      nextCaller: 1,
      lead: 0,
    };

    this.nextCallerName = this.LeadList[index + 1].FirstName;
    this.nextCallerCity = this.LeadList[index + 1].City;
    this.nextCallerNumber =
      "+" +
      this.LeadList[index + 1].CountryCode +
      this.LeadList[index + 1].PhoneNumber;
    this.startIndex = index + 1;

    this.UhubDashboardService.startCall.next({
      phoneNumber: this.marketingId.phoneNumber,
      leadData: this.marketingId,
    });
    this.startCall = true;
  }

  stopCallListener() {
    this.stopCall = true;
    this.startCall = false;
    if (this.statusValue !== "pending") {
      this.startIndex += 1;
    }
    clearTimeout(this.clearTime);
  }

  onInput2(event) {
    if (this.filterString != "") {
      this.UhubList = this.allLeads;
    } else {
      this.UhubList = this.UhubList1;
    }
  }

  public onInput(event: any) {
    let payload = {
      admin: this.admin,
      ProjectId: this.projectId,
      start: "2021-08-02 09:40:25+00",
      end: "2022-08-03 09:40:25+00",
      page: 1,
      leadType: "All",
      mail: "All",
      callStatus: "All",
      course: "All",
      enrollmentYear: "All",
      enrollmentTerm: "All",
      export: false,
      search: this.search,
    };
    if (this.search != "") {
      // this.uhleadsservice
      //   .GetSearchDashboardLeads(payload)
      //   .subscribe((res: any) => {
      //     // console.log("Search Results: ",res);
      //     this.activityTable = res.data;
      //   });
      // this.activityTable = this.allRecords;
    } else if (this.search == "") {
      // this.getData(this.userData, "all", this.startDate, this.endDate);
      this.totalRecords.nativeElement.click();
      // this.getTopBarCRM();
      // this.getCardData(this.userData, "total", this.startDate, this.endDate);
    }
  }

  searchLeads(value, page) {
    let payload = {
      admin: this.admin,
      ProjectId: this.projectId,
      start: "2020-08-02 09:40:25+00",
      end: "2025-08-03 09:40:25+00",
      page: page,
      leadType: "All",
      mail: "All",
      callStatus: "All",
      course: "All",
      enrollmentYear: "All",
      enrollmentTerm: "All",
      export: false,
      search: value,
    };

    if (value.length > 0) {
      this.leadType = "filter";
      this.UhubDashboardService
        .getUhubSearchDashboardLeads(payload)
        .subscribe((res: any) => {
          // console.log("Search Results: ",res);
          // this.LeadList = res.data;
          var test1 = res.data;
          var test2 = test1.map(obj => ({ ...obj, name: obj.FirstName + " " + obj.LastName }));
          this.LeadList = test2;
          for (let i = 0; i <= this.LeadList.length; i++) {
            this.LeadList[i].AssignedtoName = this.getAgentName(this.LeadList[i].Assignedto);
            this.LeadList[i].AssignedtoInitial = this.getAgentInitial(this.LeadList[i].Assignedto);
            this.LeadList[i].AssignedtoColor = this.getAgentColor(this.LeadList[i].Assignedto);
            this.LeadList[i].AssignedtoImageURL = this.getAgentphoto(this.LeadList[i].Assignedto);
            this.LeadList[i].FormName = this.LeadList[i].Miscellaneous.form_name;
          }
          let newTotal = ~~(Number(res.totalResults) / 12) + 1;
          this.customService.totalPages.next(Number(newTotal));
        }, (error) => {
          this.handleError.handleError(error)
        });
    } else {
      this.leadType = "all";
      this.getTopBarCount();
      this.getLeadsByCards('total');
    }
  }



  dropdownSelect(event) {
    if (event == 'all') {
      this.getLeadsByCards('total');
    }
    else {
      this.callStatusFilter = event;
      this.leadType = 'dropdown'
      this.onClick(null);
      this.getTopBarCount();
      // this.getTopBarCount();
      this.getLeads(this.admin, this.projectId, this.userData.mail, this.leadType, this.startDate, this.endDate, this.pageNumber, this.callStatusFilter);
    }

  }

  getTopBarCount() {
    var postData = {
      AgentMail: this.userData.mail,
      ProjectId: this.projectId,
      start: this.startDate,
      end: this.endDate,
    };

    this.UhubDashboardService.getTopBarCount(postData).subscribe((res: any) => {

      if (this.leadType == "total") {
        this.totalPages = res.total;
      } else if (this.leadType == "facebook") {
        this.totalPages = res.facebook;
      } else if (this.leadType == "Website") {
        this.totalPages = res.web;
      } else if (this.leadType == "callback") {
        this.totalPages = res.followup;
      } else if (this.leadType == "pending") {
        this.totalPages = res.pending;
      } else if (this.leadType == "pending") {
        this.totalPages = res.pending;
      } else if (this.leadType == "interested") {
        this.totalPages = res.interested;
      } else if (this.leadType == "counsellingpending") {
        this.totalPages = res.counsellingpending;
      } else if (this.leadType == "counsellingcompleted") {
        this.totalPages = res.counsellingcompleted;
      } else if (this.leadType == "QualifiedLead") {
        this.totalPages = res.qualifiedLead;
      } else if (this.leadType == "QualifiedHotLead") {
        this.totalPages = res.qualifiedHotLead;
      } else if (this.leadType == "assignedto") {
        this.totalPages = res.assignedToMe;
      } else if (this.leadType == "dropdown") {
        this.UhubDashboardService.getCountByCallStatus({ status: this.callStatusFilter, ProjectId: this.projectId, start: this.startDate, end: this.endDate }).subscribe((count: any) => {
          this.totalPages = count.count;
          this.totalPages = ~~(Number(this.totalPages) / 12) + 1;
          this.customService.totalPages.next(this.totalPages);
        })
      }
      this.totalPages = ~~(Number(this.totalPages) / 12) + 1;
      this.customService.totalPages.next(this.totalPages);
      this.topBarCount = res;
    }, (error) => {
      this.handleError.handleError(error)
    });
  }

  getUniversalRecords() {
    var postData = {
      admin: this.admin,
      mail: this.userData.mail,
      ProjectId: this.projectId,
      leadType: "total",
      page: 1,
      start: this.momentService.dashboardDateFormat(
        new Date(new Date(Date.now() - 31536e6)).getTime()
      ),
      end: this.momentService.dashboardDateFormat(new Date().getTime()),
    };
    this.UhubDashboardService.getLeads(postData)
      .toPromise()
      .then((resp: any) => {
        this.allLeads = resp;
        this.allLeads.forEach(function (a) {
          Object.keys(a).map((key) => {
            a[key] === null ? (a[key] = "-") : a[key];
            a[key] === "" ? (a[key] = "-") : a[key];
          });
        });
      })
      .catch((err) => { });
  }

  getLeads(admin, projectId, mail, leadType, start, end, page, callStatus) {
    this.isLoading = true;
    let payload = {
      admin: admin,
      mail: mail,
      ProjectId: projectId,
      leadType: leadType,
      start: start,
      end: end,
      page: page,
      callStatus: callStatus
    };

    this.UhubDashboardService.getLeads(payload).subscribe((res: any) => {
      this.isLoading = false;
      this.LeadList = res.data;
      var test1 = res.data;
      var test2 = test1.map(obj => ({ ...obj, name: obj.FirstName + " " + obj.LastName }));
      this.LeadList = test2;
      for (let i = 0; i <= this.LeadList.length; i++) {
        this.LeadList[i].AssignedtoName = this.getAgentName(this.LeadList[i].Assignedto);
        this.LeadList[i].AssignedtoInitial = this.getAgentInitial(this.LeadList[i].Assignedto);
        this.LeadList[i].AssignedtoColor = this.getAgentColor(this.LeadList[i].Assignedto);
        this.LeadList[i].AssignedtoImageURL = this.getAgentphoto(this.LeadList[i].Assignedto);
        this.LeadList[i].FormName = this.LeadList[i].Miscellaneous.form_name;
      }

      if (this.LeadList.length == 0) {
        this.noRecords = true;
      }
      this.callData = this.LeadList;

      this.sortTableData(this.sortColumn, this.sortOrder);

      this.LeadList.forEach(function (a) {
        Object.keys(a).map((key) => {
          a[key] === null ? (a[key] = "-") : a[key];
          a[key] === "" ? (a[key] = "-") : a[key];
        });
      });
    }, (error) => {
      this.handleError.handleError(error)
    });
    // this.getTopBarCount();
    // this.getLeadsByCards(this.leadType);
    // this.getUniversalRecords();
  }

  selectAllforAssignment() {
    if (this.selectedAll == false) {
      this.selectedAll = true;
      this.leadIds = [];
      for (let i = 0; i < this.LeadList.length; i++) {
        this.leadIds.push(this.LeadList[i].id);
      }
    } else if (this.selectedAll == true) {
      this.selectedAll = false;
      this.leadIds = [];
    }
  }

  toggleSort(column, order) {
    if (this.sortOrder == 'asc') {
      this.sortOrder = 'des'
    } else {
      this.sortOrder = 'asc';
    }
    this.sortTableData(column, this.sortOrder);
  }

  sortTableData(column, order) {
    // console.log(this.referralList);
    // console.log(this.sortColumn,"::::", this.sortOrder)
    this.sortColumn = column;
    // this.LeadList = sortJsonArray(this.LeadList, this.sortColumn, this.sortOrder);
    var test1 = sortJsonArray(this.LeadList, this.sortColumn, this.sortOrder);
    var test2 = test1.map(obj => ({ ...obj, name: obj.FirstName + " " + obj.LastName }));
    this.LeadList = test2;
    // console.log(sortJsonArray(this.referralList, this.sortColumn, this.sortOrder));
  }

  selectAgentforAssignment(e, item) {
    if (e.target.checked) {
      this.leadIds.push(item.id);
    } else if (!e.target.checked) {
      this.removeItem(this.leadIds, item.id);
    }
  }

  removeItem(arr, value) {
    var index = arr.indexOf(value);
    if (index > -1) {
      arr.splice(index, 1);
    }
    return arr;
  }

  openEditLead(content, lead) {
    // this.editStatus = lead.CallStatus;

    // this.leadtoEdit = {
    //   LeadId: lead.id,
    //   FirstName: lead.FirstName,
    //   LastName: lead.LastName,
    //   CountryCode: "+" + lead.CountryCode,
    //   PhoneNumber: lead.PhoneNumber,
    //   Email: lead.Email,
    //   University: lead.University,
    //   CallStatus: lead.CallStatus,
    //   AgentMail: this.userData.mail,
    // };

    // this.modalService.open(content, { size: "md" });
  }

  updateLead() {
    // this.edited = true;
    // if (this.ReferreeService.editLead.invalid) {
    //   return;
    // }

    // this.leadtoEdit.CountryCode = this.leadtoEdit.CountryCode.replace(/\+/g, "");
    // if (this.leadtoEdit.CountryCode == "-") {
    //   this.leadtoEdit.CountryCode = "00";
    // }
    // if (this.leadtoEdit.PhoneNumber == "-") {
    //   this.leadtoEdit.PhoneNumber = "0000000000";
    // }

    // this.ReferreeService.updateLead(this.leadtoEdit).subscribe((res: any) => {
    //   this.edited = false;
    //   this.getReferralList(
    //     this.admin,
    //     this.projectId,
    //     this.userData.mail,
    //     "all",
    //     this.startDate,
    //     this.endDate
    //   );
    //   document.getElementById("closeButton3").click();
    // }, (error) => {
    //   this.handleError.handleError(error)
    // });
  }

  sideNavData(data) {
    this.activityfilterString = "";

    this.minItems = 5;
    var x = document.getElementsByClassName(
      "sideDataViewer"
    ) as HTMLCollectionOf<HTMLElement>;
    for (let i = 0; i < x.length; i++) {
      x[i].style.display = "";
    }
    this.UhubDashboardService.getLead(data.id).subscribe((res: any) => {
      this.UhubDashboardService.getSms(data.id).subscribe((smsList: any) => {
        console.log(smsList);
        this.smsList = smsList.SMSDetails;


        //write here
        // this.UhubDashboardService.getSms(data.id).subscribe((smsList: any)=>{
        //   // console.log(smsList);
        //   this.smsList = smsList.SMSDetails;
        // })
        this.sideViewerData = res[0];


        console.log("Activities: ", this.sideViewerData.Activities.length)
        console.log("Sidenav Data: ", this.sideViewerData);
        this.replaceNull(this.sideViewerData);
        this.currentLeadId = this.sideViewerData.id;
      })

    }, (error) => {
      this.handleError.handleError(error)
    });
  }

  replaceNull(a: any) {
    Object.keys(a).map((key) => {
      a[key] === null ? (a[key] = "-") : a[key];
      a[key] === "" ? (a[key] = "-") : a[key];
    });
  }

  updateComments(modal, item) {
    console.log("data in edit : ", item)
    this.layoutService.commentsForm.get('interested').clearValidators();
    this.layoutService.commentsForm.get('interested').updateValueAndValidity();
    this.layoutService.commentsForm.get('counsellingDate').patchValue(this.layoutService.dateFormatForAddUpdate(this.layoutService.commentsForm.get('counsellingDate').value))
    this.layoutService.commentsForm.get('callBack').patchValue(this.layoutService.dateFormatForAddUpdate(this.layoutService.commentsForm.get('callBack').value))
    this.layoutService.commentsForm.get('dob').patchValue(this.layoutService.dateFormatForAddUpdate(this.layoutService.commentsForm.get('dob').value));
    // this.layoutService.commentsForm.get('callBack').patchValue(this.layoutService.dateFormatForAddUpdate(this.layoutService.commentsForm.get('callBack').value))
    this.layoutService.commentsForm.get('interested').valueChanges.subscribe(async (selectedValue) => {
      await this.layoutService.commentsForm.get('callBack').clearValidators();
      await this.layoutService.commentsForm.get('callBack').updateValueAndValidity()
      await this.layoutService.commentsForm.get('counsellingDate').clearValidators();
      await this.layoutService.commentsForm.get('counsellingDate').updateValueAndValidity()
      await this.layoutService.commentsForm.get('assignedTo').clearValidators()
      await this.layoutService.commentsForm.get('assignedTo').updateValueAndValidity()
      if (selectedValue == 'callback') {
        await this.layoutService.commentsForm.get('callBack').setValidators([Validators.required]);
        this.layoutService.commentsForm.get('callBack').updateValueAndValidity();
      } else if (selectedValue == 'counsellingpending') {
        await this.layoutService.commentsForm.get('counsellingDate').setValidators([Validators.required])
        this.layoutService.commentsForm.get('counsellingDate').updateValueAndValidity();
        await this.layoutService.commentsForm.get('assignedTo').setValidators([Validators.required])
        this.layoutService.commentsForm.get('assignedTo').updateValueAndValidity();
      }
    })
    this.layoutService.onReset();
    this.currentLead = item;
    this.layoutService.commentsForm.patchValue({
      name: item["FirstName"],
      emailId: item["Email"],
      qualification: item["Education"],
      state: item["City"],
      countryCode: item["CountryCode"],
      phoneNumber: item["PhoneNumber"],
    });
    this.marketingService
      .showDetails(this.currentLead["id"], this.statusValue)
      .subscribe((data) => {
        this.layoutService.commentsForm.patchValue({
          comments:
            data["Phase"] === "demo"
              ? data["DemoComments"] || ""
              : data["Comments"] || "",
        });
      }, (error) => {
        this.handleError.handleError(error)
      });;

    this.layoutService.getCommentLead(item["id"]).subscribe((data) => {
      if (data) {
        this.isUpdateLead = true;
      } else {
        this.isUpdateLead = false;
      }
    }, (error) => {
      this.handleError.handleError(error)
    });
    this.modalService.open(this.editLead2, { size: "xl" });
  }


  async submit() {
    // this.snackbarRef.open("Mail Sent Successfully", "Close", {
    //   verticalPosition: "top",
    //   horizontalPosition: "end",
    //   duration: 5000,
    // });
    if (this.isUpdateLead) {

      if (this.layoutService.commentsForm.invalid) {
        // console.log("error");

      } else {
        this.layoutService
          .updateCommentsBasedOnId(this.currentLead["id"])
          .subscribe(() => {
            this.updateLeadComments();
            this.snackbarRef.open("Update Successfully", '', {
              verticalPosition: "top",
              horizontalPosition: "end",
              duration: 5000,
              panelClass: ['success-snackbar']
            });
            this.modalService.dismissAll();
            this.getLeads(this.admin, this.projectId, this.userData.mail, this.leadType, this.startDate, this.endDate, this.pageNumber, this.callStatusFilter);
            // this.getDashboardLeads(this.leadType);
          }, (error) => {
            this.handleError.handleError(error)
          });
      }
    }
    else {
      if (this.layoutService.commentsForm.invalid) {
        // console.log("error");
        // console.log(this.layoutService.commentsForm);

      } else {
        this.layoutService
          .updateComments(this.currentLead["id"])
          .subscribe(() => {
            this.updateLeadComments();
            this.modalService.dismissAll();
          }, (error) => {
            this.handleError.handleError(error)
          });
      }
    }
    if (this.layoutService.commentsValue.name.length === 0) {
      return;
    }
    this.layoutService.submitted = true;
    this.submitted = this.layoutService.submitted;
    if (this.layoutService.commentsValue.interested !== "notinterested") {
      if (
        this.layoutService.commentsValue.counsellingDate &&
        (this.layoutService.commentsValue.interested === "QualifiedLead" ||
          this.layoutService.commentsValue.interested === "QualifiedHotLead")
      ) {
        // console.log("demo is created for lead");
        await this.scheduleDemo();
      } else {
        // this.updateLeadComments();
      }
    } else {
      if (this.layoutService.commentsValue.interested === "notinterested") {
        this.marketingService
          .updateLeadStatus({
            id: this.currentLead.id,
            phase: this.layoutService.commentsValue.interested,
          }).subscribe(() => { }, (error) => {
            this.handleError.handleError(error)
          });
      }
    }
  }

  updateLeadComments() {
    if (this.currentLead.phase !== "demo") {
      let callStatus = "interested";
      let futureLead = "no";
      if (this.layoutService.commentsValue.interested === "futurelead") {
        callStatus = "interested";
        futureLead = "yes";
      } else {
        callStatus = this.layoutService.commentsValue.interested;
        futureLead = "no";
      }
      this.layoutService
        .updateCallComments({
          id: this.currentLead["id"],
          agentMail: this.userData.mail,
          comments: this.layoutService.commentsValue.comments,
          followUpdate:
            this.layoutService.commentsValue.interested === "interested" &&
              this.layoutService.commentsValue.counsellingDate
              ? moment(
                this.layoutService.commentsValue.counsellingDate,
                "YYYY-MM-DDTHH:mm:SSZ"
              ).format("YYYY-MM-DDTHH:mm:SSZ")
              : this.layoutService.commentsValue.callBack,
          callStatus: this.layoutService.commentsValue.interested,
          futureLead: futureLead,
          assignedTo:
            this.layoutService.selectedAgent &&
              this.layoutService.selectedAgent.length !== 0
              ? this.layoutService.selectedAgent[0]
              : this.currentLead["Assignedto"],
        })
        .subscribe((data) => {
          this.getTopBarCount();
          this.getLeads(this.admin, this.projectId, this.userData.mail, this.leadType, this.startDate, this.endDate, this.pageNumber, this.callStatusFilter);
          // console.log(data);
          console.log(data);
        }, (error) => {
          this.handleError.handleError(error)
        });
    }
  }


  scheduleDemo() {
    let dt = this.layoutService.commentsValue.counsellingDate;
    dt = moment(dt).format("dddd D MMM YY") + " at " + moment(dt).format("h:m a");
    let template: string = this.currentLead["Miscellaneous"]["form_name"]
      .toLowerCase()
      .includes("ielts")
      ? "ielts"
      : "usastudy";

    let num = Math.floor(Math.random() * 90000) + 10000;
    let room = "live-" + num.toString();
    let smsId = null;
    let callId = null;
    let mailId = null;

    let demoScheduled = `${environment.BaseURL}videocalling/mainscreen/${this.projectId}/${room}`;
    const smsMarketingBefore6hr: InputSMSData = {
      ProjectId: this.projectId,
      Module: this.currentLead["Module"],
      RecipientNumber:
        "+" +
        this.currentLead["CountryCode"] +
        this.currentLead["PhoneNumber"].replace(/\n/g, ""),
      ScheduledSMSTime: moment(
        this.layoutService.commentsValue.counsellingDate,
        "YYYY-MM-DDTHH:mm:SSZ"
      ).format("YYYY-MM-DDTHH:mm:SSZ"),
      Type: "Information",
    };
    this.marketingService
      .getAutoDialCompany(this.projectId)
      .subscribe(async (data) => {
        // console.log(data);
        if (data) {
          let apiKey = data["API_KEY"];
          if (dt) {
            smsId = await this.marketingService.createAutoSmsScheduler(
              smsMarketingBefore6hr,
              apiKey
            );
          }
        }
        setTimeout(() => {
          this.marketingService
            .createDemo({
              ProjectId: this.projectId,
              Name: this.currentLead["FirstName"],
              AutoEmailId: mailId ? mailId["mailId"] : null,
              AutoCallId: callId ? callId["callId"] : null,
              AutoSMSId: smsId ? smsId["smsId"] : null,
              Comments: this.layoutService.commentsValue.comments,
              DateTime: moment(
                this.layoutService.commentsValue.counsellingDate,
                "YYYY-MM-DDTHH:mm:SSZ"
              ).format("YYYY-MM-DDTHH:mm:SSZ"),
              Presenter:
                this.layoutService.selectedAgent &&
                  this.layoutService.selectedAgent.length !== 0
                  ? this.layoutService.selectedAgent[0]["value"]
                  : this.currentLead["Assignedto"],
              Feedback: this.layoutService.commentsValue.interested,
              Url: demoScheduled + "/" + this.userData.workerFriendlyName,
              ClientURL: demoScheduled + "/" + this.currentLead["FirstName"],
              RoomName: room,
              LeadId: this.currentLead.id,
              agentEmail: this.userData.mail,
            })
            .subscribe((demo) => {
              if (dt) {
                this.marketingService
                  .updateDemo({
                    demoId: demo["id"],
                    leadId: this.currentLead.id,
                  })
                  .subscribe((data) => {
                    if (dt) {
                      this.layoutService.sendSmsForLead({
                        date: dt,
                        from: this.layoutService.twilioNumber,
                        projectId: this.projectId,
                        student_name: this.currentLead["FirstName"],
                        tosms: this.currentLead.PhoneNumber,
                      });
                    }
                    if (dt) {
                      this.marketingService.sendMailForDemo(
                        {
                          name: this.currentLead["FirstName"],
                          demoDate: moment(
                            this.layoutService.commentsValue.counsellingDate
                          ).format("MMMM Do YYYY, h:mm:ss a"),
                          url:
                            demoScheduled + "/" + this.currentLead["FirstName"],
                        },
                        this.currentLead.Email,
                        this.projectId,
                        template
                      );
                      this.updateLeadComments();
                    }
                  }, (error) => {
                    this.handleError.handleError(error)
                  });
              }
            }, (error) => {
              this.handleError.handleError(error)
            });
        }, 2000);
      }, (error) => {
        this.handleError.handleError(error)
      });
  }

  get f(): { [key: string]: AbstractControl } {
    return this.layoutService.commentsForm.controls;
  }
  get createLeads(): { [key: string]: AbstractControl } {
    return this.marketingService.createLead.controls;
  }

  assignAgentModal() {
    this.defaultAgent = "default";
    this.selectedAgent = null;
  }
  agentSelected(selectedAgent) {
    this.selectedAgent = selectedAgent;
  }
  assignLEads() {
    if (this.leadIds.length == 0) {
      this.uhleadsservice.showWarning("Select leads to be assigned", "Alert!");
      return;
    }
    if (this.selectedAgent) {
      this.UhubDashboardService
        .assignLeads({
          AssignedTo: this.selectedAgent,
          InsertedBy: this.userData.mail,
          LeadIds: this.leadIds,
        })
        .subscribe((res) => {
          console.log("Assigned agents")


          this.uhleadsservice.showSuccess(
            "Successfully assigned the agents",
            "Success"
          );

          this.getTopBarCount();
          this.getLeads(this.admin, this.projectId, this.userData.mail, this.leadType, this.startDate, this.endDate, this.pageNumber, this.callStatusFilter);


          this.selectedAll = false;
          this.closeButton.nativeElement.click();
          document.getElementById("closeAssignButton").click();
        }, (error) => {
          this.handleError.handleError(error)
        });
    } else {
      this.uhleadsservice.showWarning("Please select an agent", "Alert!");
    }
  }

  getAgentNames() {
    this.agentService.getAgentNames().subscribe((data) => {
      this.totalAgents = data;

      this.uhleadsservice.getGBPAgentList().subscribe((res: any) => {
        this.GBPagentList = res;
        for (let i = 0; i < this.GBPagentList.length; i++) {
          this.totalAgents.push({
            FirstName: this.GBPagentList[i].FIRST_NAME,
            LastName: this.GBPagentList[i].LAST_NAME,
            Mailid: this.GBPagentList[i].EMAIL_ADDRESS,
            ProfilePhoto: this.GBPagentList[i].ProfilePhoto,
          });
        }
      }, (error) => {
        this.handleError.handleError(error)
      });
      // this.totalAgents.push({FirstName: "Test", LastName: "Test", MailId: "test@test.com", ProfilePhote:"test.com"})
    }, (error) => {
      this.handleError.handleError(error)
    });
  }

  getAgentName(email) {
    let name = "";
    for (var k = 0; k < this.totalAgents.length; k++) {
      if (this.totalAgents[k].Mailid == email) {
        name =
          this.totalAgents[k].FirstName + " " + this.totalAgents[k].LastName;
        return name;
      }
    }
  }


  createLead() {
    this.submitted = true;
    if (this.marketingService.createLead.invalid) {
      return;
    }
    let fileData: Exceldata[] = [
      new Exceldata(
        this.marketingService.createLeadValue["firstName"],
        this.marketingService.createLeadValue["lastName"],
        this.marketingService.createLeadValue["countryCode"],
        this.marketingService.createLeadValue["phoneNumber"],
        this.marketingService.createLeadValue["email"],
        this.userData.mail,
        this.marketingService.createLeadValue["module"],
        this.marketingService.createLeadValue["channel"],
        {
          form_id: "",
          form_name: this.marketingService.createLeadValue["form_name"],
          "Choose Batch": "",
          "Training Mode": "",
        },
        this.marketingService.createLeadValue["comments"],
        this.marketingService.createLeadValue["createdAt"],
      ),
    ];
    this.marketingService
      .postCallData({
        data: fileData,
        createdBy: this.userData["mail"],
        ProjectId: this.projectId,
        type: 'Excel'
      })
      .subscribe((data) => {
        this.marketingService.resetCreateLead();
        this.submitted = false;
        this.modalService.dismissAll();
      }, (error) => {
        this.handleError.handleError(error)
      });
  }

  openCreateLead(content) {
    this.modalService.open(content, { size: "md" });
  }

  // getAgentName(email) {
  //   let name = "";
  //   for (var k = 0; k < this.totalAgents.length; k++) {
  //     if (this.totalAgents[k].Mailid == email) {
  //       name =
  //         this.totalAgents[k].FirstName + " " + this.totalAgents[k].LastName;
  //       return name;
  //     }
  //   }
  // }
  getAgentInitial(email) {
    let initial = "";
    for (var k = 0; k < this.totalAgents.length; k++) {
      if (this.totalAgents[k].Mailid == email) {
        initial =
          this.totalAgents[k].FirstName.charAt(0) +
          this.totalAgents[k].LastName.charAt(0);
        return initial;
      }
    }
  }

  getAgentColor(email) {
    let color = "";
    for (var k = 0; k < this.totalAgents.length; k++) {
      if (this.totalAgents[k].Mailid == email) {
        color =
          this.totalAgents[k].FirstName.charAt(0) +
          this.totalAgents[k].LastName.charAt(0);

        let randomIndex =
          this.colors[
          this.numberFromText(this.getAgentInitial(email)) %
          this.colors.length
          ];
        color = randomIndex[0]["bg"];
        return color;
      }
    }
  }
  getAgentphoto(email) {
    let imageUrl = "";
    for (var k = 0; k < this.totalAgents.length; k++) {
      if (this.totalAgents[k].Mailid == email) {
        imageUrl = this.totalAgents[k].ProfilePhoto;
        return imageUrl;
      }
    }
  }

  numberFromText(text) {
    const charCodes = text
      .split("") // => ["A", "A"]
      .map((char) => char.charCodeAt(0)) // => [65, 65]
      .join(""); // => "6565"
    return parseInt(charCodes, 10);
  }
  listFeedback() {
    this.SharedService.listFeedback(this.projectId).toPromise()
      .then((resp: any) => {
        this.listFeed = resp.feedback;
      })
      .catch(err => {
      })
  }
  openTablesModal(cardsChangemodal) {
    // return await this.cardsChangemodallComponent.open();
    this.modalService.open(cardsChangemodal, {
      ariaLabelledBy: "modal-basic-title",
      windowClass: "myCustomModalClass",
    }).result.then(
      (result) => { },
      (reason) => { }
    );
  }
  async removeTable(value, view) {
    if (view === false) {
      await this.projectSettings1.map(x => {
        if (x.primaryKey === value) {
          x.view = false;
        }
      });
      for (var i = 0; i < this.projectSettings.length; i++) {
        var obj = this.projectSettings[i];

        if (obj.primaryKey === value) {
          this.projectSettings.splice(i, 1);
        }
      }
    } else {
      await this.projectSettings1.map(x => {
        if (x.primaryKey === value) {
          x.view = true;
        }
        this.sortReusbaleTable(this.projectSettings1);
      });
    }
  }

  updateTables() {
    console.log(this.projectSettings)
    // this.projectSettings = this.projectSettings1;
  }
  updateLeadModal(event) {
    this.updateComments(event.name, event.item)
  }
  callModal(event) {
    this.followUpCall(event.item)
    // this.checkThresHold(event.name, event.item, event.id)
  }
  sidenavOpen(event) {
    // this.viewLead(event)
    this.sideNav.toggle();
    this.sideNavData({ id: event });
  }
  async sortReusbaleTable(data) {
    var test1 = data.filter(x => {
      if (x.view) {
        return x
      }
    });
    this.projectSettings = [
      ...test1.filter(x => x.header !== 'Action'),
      ...test1.filter(x => x.header === 'Action')
    ];
  }
  dropdownSelectReuse(event) {
    this.statusValue = event;
    this.dropdownSelect(event);
  }
  searchLeadsNew(event) {
    this.searchLeads(event.search, event.pageNumber);
  }
  selectAgentforAssignmentNew(event) {
    this.selectAgentforAssignment(event.e, event.item)
  }
}
