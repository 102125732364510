export class Ticket {
    constructor(
        private id: string,
        private summary: string,
        private ReportedBy: string,
        private UpdatedDate: string,
        private Assignedto: string,
        private group: string,
        private CreatedDate: string,
        private RequestId: string,
        private RequestStatus: string,
        private TicketRead: boolean,
        private TicketChannel?: string,
        private TicketCount?: number,
    ) { }
}