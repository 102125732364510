import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HttpClientService } from 'src/app/httpclient.service';
import { Router } from '@angular/router';
import { CreateAgent } from 'src/app/pages/create-agent/create-agent.service';

interface ForgetPassword {
  oldPassword: string,
  newPassword: string,
  mail: string
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public baseUrl = environment.baseApiUrl;
  public baseApiUrl2 = environment.baseApiUrl2;
  public admin = new BehaviorSubject<Boolean>(false);
  public companyAdmin = new BehaviorSubject<Boolean>(false);
  private router: Router;
  public headers = {};
  isLoggedIn: any;
  configuration: any;
  userData: any;
  workerJS: any = null;

  constructor(private http: HttpClientService, private agentService: CreateAgent) {
    this.headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-auth-token': 'asseccque'
      })
    };
  }


  allUser() {
    return this.http.post(this.baseUrl + 'users', this.headers)
      .toPromise()
      .then(response => response)
      .catch(this.handleError);
  }
  login(body: any) {
    return this.http.post(this.baseUrl, body)
      .toPromise()
      .then(response => response)
      .catch(this.handleError);
  }

  getUserRoles(id) {
    return this.http.get<any[]>(`${this.baseApiUrl2}adminRole/${id}`)
  }

  checkEmail(body: { mail: string }) {
    return this.http.post(`${this.baseApiUrl2}checkEmail`, body);
  }

  changePassword(body: { newPassword: string, mail: string }) {
    return this.http.post(`${this.baseApiUrl2}changePassword`, body)
  }

  checkSuperAdmin(id) {
    return this.http.get(`${this.baseApiUrl2}superAdmin/${id}`)
  }


  forgetPassword(body: ForgetPassword) {
    return this.http.put(`${this.baseApiUrl2}forgetpassword`, body);
  }

  private handleError(error: any): Promise<any> {
    if (error.status === 401) {
      return Promise.reject(error);
    }
    if (error.status === 400) {
      return Promise.reject(error);
    }
    if (error.status === 500) {
      return Promise.reject(error);
    }
    if (error.status === 404) {
      return Promise.reject(error);
    }
    if (error.status === 601) {
      return Promise.reject(error);
    }
    return Promise.reject(error);
  }
  saveAgentLocation(body: { latlng: any, address: any, id: any }) {
    return this.http.post(`${this.baseApiUrl2}locationUpdate`, body);
  }

  redirectLogoutUser() {
    this.router.navigate(["/login"]);
  }
}
