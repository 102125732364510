import { Component, OnInit } from '@angular/core';
import { UhLeadsService } from 'src/app/pages/uhleads.service';
import { DashPaginationService } from './dash-pagination.service';

@Component({
  selector: 'app-dash-pagination',
  templateUrl: './dash-pagination.component.html',
  styleUrls: ['./dash-pagination.component.scss'],
})
export class DashPaginationComponent implements OnInit {
  pageNo: Number = 1;
  totalPages: Number;
  onPageChange(pageNo: number) {
    this.pageNo = pageNo;
    this.dashService.pageNumber.next(this.pageNo);
  }

  constructor(
    private dashService: DashPaginationService,
  ) { }

  ngOnInit(): void {
    this.dashService.totalPages.subscribe((res: any) => {
      this.totalPages = res;
    });
    this.dashService.pageNumber.subscribe((res: any) => {
      console.log('New Page No: ', res)
      this.pageNo = res;
      console.log("Page No: ", this.pageNo)
    })
    this.onPageChange(1);
    document.getElementById("firstPage").click();
  }

}
