import { AuthService } from "src/app/account/auth/auth.service";
import { FormBuilder, FormControl, FormGroup, NgForm, Validators } from "@angular/forms";
import { RoleService } from "./../role-management/role.service";
import { Component, OnInit } from "@angular/core";
import { NgbModal, ModalDismissReasons, NgbModalOptions, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { SharedService } from "src/app/shared/shared.service";
import { ManagementService } from "../management.service";
import { IDropdownSettings } from "ng-multiselect-dropdown";
import { CreateAgent } from "../create-agent/create-agent.service";
import { environment } from "src/environments/environment";
import { ProjectService } from "./project.service";
import { ActivatedRoute } from "@angular/router";
import { ErrorHandlerService } from "src/app/error-handler.service";
import { LeadsDashboardService } from "../leads-dashboard/leads-dashboard.service";
import { UhLeadsService } from "../uhleads.service";
import * as XLSX from 'xlsx';
import { ExportExcelService } from "../excel.service";
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { AnyMxRecord } from "dns";


export enum REGEX {
  EMAIL = '[A-Za-z0-9._%-]+@[A-Za-z0-9._%-]+\\.[a-z]{2,4}',
  PHONE = '[0-9]{10}$',
  PASSWORD = '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$',
  ZIP = '^[a-zA-Z0-9,\'-]*$',
  NUMBER = '^[0-9]*$',
  URL = '^(http:\/\/www\.|https:\/\/www\.|www\.|http:\/\/|https:\/\/)[a-zA-Z0-9\.\-]+\\.[a-zA-Z]{2,5}[\.]{0,1}',
  CHARACTER = '^[a-zA-Z ]+',
  SPECIAL_PHONE = '^[+|0-9]+[0-9]{1,}$',
  ADDRESS = '^[a-zA-Z0-9\s,\'-]*$',
}

@Component({
  selector: "app-project-management",
  templateUrl: "./project-management.component.html",
  styleUrls: ["./project-management.component.css"],
})
export class ProjectManagementComponentNew implements OnInit {
  closeResult: string;
  search: string;
  companyInfo: any = {
    name: "",
    twilioNumber: "",
    supportMail: "",
    forwardMail: "",
    desc: "",
  };
  feedbackDetails: any = {
    projId: "",
    id: "",
    name: "",
    active: true,
    bgColor: "",
    textColor: "",
  };
  totalCompanies: any[] = [];
  isUpdate: boolean = false;
  inputData: any;
  isError: boolean = false;
  errorMessage: string;
  superAdmin: Boolean = false;
  userData: any;
  public page: any = 1;
  public pageSize: any = 6;
  selectedCountryCode;
  countries: any[];
  group: any[] = [];
  groups: any[] = [];
  companySettings: IDropdownSettings;
  public order: boolean = false;
  public orderFeild: string = "UpdatedDate";
  public orderString: boolean | "asc" | "desc" = "desc";
  public companyId: string = "";
  public companyName: string = "";
  public companyForwardMail: string = "";
  public displayMenus: any[] = [];
  feedbacks: any[] = [];
  feedbackForm: FormGroup;
  feedbackModel: NgbModalRef;
  projectId: any;
  updatefeedbackModel: NgbModalRef;
  colData: { displayName: string }[];
  arrayBuffer: any;
  file: File;
  selectedProjectId: any;

  public separatorKeysCodes = [ENTER, COMMA];
  public emailList = [];
  removable = true;
  rulesForm: FormGroup;
  fb2: FormBuilder = new FormBuilder();
  role: any;
  typeOfUpload: any;
  isLoading: Boolean = false
  noRecords: Boolean = false
  constructor(
    private modalService: NgbModal,
    private companyService: RoleService,
    private sharedService: SharedService,
    private authService: AuthService,
    private managementService: ManagementService,
    private agentService: CreateAgent,
    private projectService: ProjectService,
    private handleError: ErrorHandlerService,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private LeadsDashboardService: LeadsDashboardService,
    private uhleadsservice: UhLeadsService,
    public ExcelService: ExportExcelService
  ) { }

  ngOnInit() {
    this.route.params.subscribe((data) => {
      if (data["companyId"]) {
        this.companyId = data["companyId"];
        this.getCompanyById(data["companyId"]);
        this.getProjects(this.companyId);
        // this.getCompanies(data["companyId"]);
        this.listMasterFeedbacks();
      }
      if (data["name"]) {
        this.companyName = data["name"];
      }
    });

    this.companySettings = {
      singleSelection: true,
      allowSearchFilter: true,
      closeDropDownOnSelection: true,
      textField: "Name",
    };
    this.fetchCountries();
    this.userData = JSON.parse(localStorage.getItem("userData"));
    // this.sharedService.companyCreated.next("company created");
    this.sharedService.pageEvent.emit({ pageName: "Project Management" });
    // this.authService.admin.subscribe((data) => {
    //   this.superAdmin = data;
    // });

    this.rulesForm = this.fb.group({
      emails: this.fb.array([], [this.validateArrayNotEmpty]),
    });
  }

  open(content) {
    // this.isError = false;
    this.modalService.open(content, { size: "lg" }).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }

  private getDismissReason(reason: any): string {
    // this.getCompanies(this.companyId);
    this.getProjects(this.companyId);
    this.isUpdate = false;
    this.companyInfo["id"] = null;
    this.resetForm();
    this.isError = false;
    // this.companyInfo = { name: '', twilioNumber: '', supportMail: '' };
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }

  submit(form: NgForm) {
    if (form.valid) {
      if (!this.isUpdate) {
        this.projectService
          .createProject({
            name: form.value.name,
            supportMail: form.value.supportMail,
            twilioNumber: form.value.twilioNumber,
            forwardMail: this.companyInfo.forwardMail,
            companyId: this.companyId,
            description: form.value.desc,
          })
          .subscribe(
            (data) => {
              console.log(data);
              // this.sharedService.companyCreated.next("company created");
              this.managementService
                .createIvr(
                  {
                    projectName: data["Name"].replace(/\s+/g, "").toLowerCase(),
                    projectId: data["id"],
                    twilioNumber: data["TwilioNumber"],
                  },
                  data["id"]
                )
                .subscribe((data) => {
                  this.modalService.dismissAll();
                  console.log(data, "successfully created ivr ");
                });
            },
            (err) => {
              this.isError = true;
              setTimeout(() => {
                this.isError = false;
              }, 5000);
              this.errorMessage = err.error.status;
              this.handleError.handleError(err);
            }
          );
      } else {
        console.log(form.value);
        this.projectService
          .updateProject({
            name: form.value.name,
            ActiveStatus: form.value.ActiveStatus,
            supportMail: form.value.supportMail,
            twilioNumber: form.value.twilioNumber,
            id: this.companyInfo["id"],
            incomingMailGroup:
              form.value.incomingMailGroup.length !== 0
                ? form.value.incomingMailGroup[0]["id"]
                : null,
            forwardMail: this.companyInfo["forwardMail"],
          })
          .subscribe(
            (data) => {
              if (form.value.updateIvr) {
                console.log("updating Ivr");
                this.managementService
                  .createIvr(
                    {
                      projectName: form.value.name
                        .replace(/\s+/g, "")
                        .toLowerCase(),
                      twilioNumber: form.value.twilioNumber,
                      projectId: this.companyInfo.id,
                    },
                    this.companyInfo.id
                  )
                  .subscribe((data) => {
                    console.log(data, "successfully created ivr ");
                  });
              }
              this.modalService.dismissAll();
            },
            (err) => {
              this.isError = true;
              this.errorMessage = err.error.status;
              this.handleError.handleError(err);
            }
          );
      }
    }
  }

  resetForm() {
    this.companyInfo = {
      name: "",
      twilioNumber: "",
      supportMail: "",
      activeStatus: null,
      id: null,
    };
    this.isError = false;
    this.errorMessage = "";
    this.groups = [];
    this.group = [];
  }

  onCompanySelect(id: any, content) {
    // if (this.superAdmin) {
    this.projectId = id;
    this.isUpdate = true;
    this.getFeedbackById(id);
    this.projectService.getProject(id).subscribe(
      (data) => {
        this.companyInfo = {
          name: data["Name"],
          supportMail: data["SupportMail"],
          twilioNumber: data["TwilioNumber"],
          ActiveStatus: data["ActiveStatus"],
          id: id,
          forwardMail: data["ForwardMail"] || "",
          desc: data["Description"],
        };
        this.open(content);
        this.agentService.getMailGroups(data["id"]).subscribe(
          (datas) => {
            this.groups = datas.map((datac) => ({
              Name: datac["Name"],
              id: datac["id"],
            }));
            let selectedGroup = this.groups.find(
              (datap) => datap["id"] === data["IncomingMailGroup"]
            );
            if (selectedGroup) {
              this.group = [selectedGroup["Name"]];
            }
          },
          (error) => {
            this.handleError.handleError(error);
          }
        );
      },
      (error) => {
        this.handleError.handleError(error);
      }
    );
    // }
  }

  getCompanies(id) {
    this.projectService.getProjects(id).subscribe(
      (data) => {
        this.totalCompanies = data;
      },
      (error) => {
        this.handleError.handleError(error);
      }
    );
  }
  getProjects(companyId) {
    this.isLoading = true
    this.noRecords = false
    this.totalCompanies = []
    this.LeadsDashboardService.getProjects({ companyId: companyId }).subscribe(
      (res: any) => {
        if (res.projects.length > 0) {
          this.totalCompanies = res.projects;
        }
        else {
          this.noRecords = true
        }
        this.isLoading = false

      }
    );
  }

  selectCountry(country) {
    console.log(country);
    this.selectedCountryCode = country.code;
  }

  fetchCountries() {
    this.sharedService.getCountries().subscribe(
      (data) => {
        this.countries = data;
      },
      (error) => {
        this.handleError.handleError(error);
      }
    );
  }

  sortOrder(felidName) {
    this.orderFeild = felidName;
    this.order = !this.order;
    if (this.order) {
      this.orderString = "asc";
    } else {
      this.orderString = "desc";
    }
  }

  forwardMail(event) {
    let name = event.replace(/[^A-Z0-9]+/gi, "");
    this.companyInfo["forwardMail"] = name + this.companyForwardMail;
  }

  getCompanyById(id) {
    this.companyService.getCompanyByIdV2(id).subscribe(
      (Data: any) => {
        if (Data.data) {
          this.companyName = Data.data.companyName
        }

        // this.companyForwardMail = "-" + data["ForwardMail"];
      },
      (error) => {
        this.handleError.handleError(error);
      }
    );
  }
  checkBox(item): boolean {
    return this.displayMenus.includes(parseInt(item));
  }
  childMenuCheckBox(value) {
    console.log(
      value.option._selected,
      "-----------------------",
      value.option._value
    );
    if (value.option._selected) {
      this.displayMenus.push(value.option._value);
    } else {
      this.displayMenus = this.displayMenus.filter((val) => {
        return val !== value.option._value;
      });
    }
  }
  listMasterFeedbacks() {
    this.projectService
      .listMasterFeedbacks()
      .toPromise()
      .then((resp: any) => {
        this.feedbacks = resp.data;
      })
      .catch((err) => { });
  }
  statusCheckedValues(value) {
    let obj = {
      ProjectId: this.companyInfo.id,
      feedbackId: this.displayMenus,
    };
    this.projectService
      .addInProject(obj)
      .toPromise()
      .then((resp: any) => {
        this.displayMenus = [];
        this.modalService.dismissAll();
      })
      .catch((err) => {
        this.modalService.dismissAll();
      });
  }
  getFeedbackById(id) {
    this.projectService
      .getFeedbackById(id)
      .toPromise()
      .then((resp: any) => {
        for (let i = 0; i < resp.feedback.length; i++) {
          this.displayMenus.push(resp.feedback[i].feedbackId);
        }
      })
      .catch((err) => { });
  }
  open1(content) {
    this.initFeedback();
    const options: NgbModalOptions = {
      backdropClass: "app-session-modal-backdrop",
      windowClass: "app-session-modal-window",
      size: "md",
    };
    this.feedbackModel = this.modalService.open(content, options);
    this.feedbackModel.result.then(
      (result) => {
        // this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }
  initFeedback() {
    this.feedbackForm = this.fb.group({
      name: new FormControl("", [Validators.required]),
      type: new FormControl("", Validators.required),
      desc: new FormControl("", [Validators.required]),
    });
  }

  get feb() {
    return this.feedbackForm.controls;
  }
  submitFeedback() {
    if (this.feedbackForm.invalid) {
      return;
    }
    let obj = this.feedbackForm.value;
    obj.value = obj.name
      .replace(/[&\/\\#,+()$~%.'":*?<>{}\s]/g, "")
      .toLocaleLowerCase();
    this.projectService
      .addNewMenu(obj)
      .toPromise()
      .then((resp: any) => {
        if (resp.status === true) {
          this.listMasterFeedbacks();
          this.feedbackModel.close();
        } else {
          this.isError = true;
          this.errorMessage = resp.msg;
          setTimeout(() => {
            this.isError = false;
            this.errorMessage = "";
          }, 3000);
        }
      })
      .catch((err) => {
        this.isError = true;
        this.errorMessage = err.error.msg;
        setTimeout(() => {
          this.isError = false;
          this.errorMessage = "";
        }, 3000);
      });
  }
  editFeedbackByProject(id, projId, component) {
    let obj = {
      projId: projId,
      id: id,
    };
    this.projectService
      .getFeedbackByProj(obj)
      .toPromise()
      .then((resp: any) => {
        this.feedbackDetails.projId = projId;
        this.feedbackDetails.id = id;
        this.feedbackDetails.name = resp[0].name;
        this.feedbackDetails.active = resp[0].active;
        this.feedbackDetails.bgColor = resp[0].bgColor;
        this.feedbackDetails.textColor = resp[0].textColor;
      })
      .catch((err) => { });
    this.updatefeedbackModel = this.modalService.open(component, {
      size: "sm",
    });
  }

  updateFeedbackById(form: NgForm) {
    console.log(this.feedbackDetails);
    this.projectService
      .updateFeedbackByProj(this.feedbackDetails)
      .toPromise()
      .then((resp: any) => {
        this.updatefeedbackModel.close();
      })
      .catch((err) => { });
  }

  incomingfile(event) {
    this.file = event.target.files[0];
  }

  inviteAgentModal(inviteLeadsModal, projectId) {
    // console.log("Leads: ", this.leadIds)
    this.selectedProjectId = projectId;
    this.modalService
      .open(inviteLeadsModal, {
        backdrop: "static",
        ariaLabelledBy: "modal-basic-title",
        windowClass: "myCustomModalClass",
      })
      .result.then(
        (result) => { },
        (reason) => { }
      );
  }

  Upload() {
    let fileReader = new FileReader();
    fileReader.onload = (e) => {
      this.arrayBuffer = fileReader.result;
      var data = new Uint8Array(this.arrayBuffer);
      var arr = new Array();
      for (var i = 0; i != data.length; ++i)
        arr[i] = String.fromCharCode(data[i]);
      var bstr = arr.join("");
      var workbook = XLSX.read(bstr, { type: "binary" });
      var first_sheet_name = workbook.SheetNames[0];
      var worksheet = workbook.Sheets[first_sheet_name];
      console.log(XLSX.utils.sheet_to_json(worksheet, { raw: true }));
      let emailList = XLSX.utils.sheet_to_json(worksheet, { raw: true });
      // this.emailList = emailList;

      let usersList = [];
      let projects = [];
      if (
        emailList.length > 0 &&
        emailList[0]["Role"] &&
        emailList[0]["Email"]
      ) {
        for (let i = 0; i < emailList.length; i++) {
          console.log(emailList[i]["Email"]);
          console.log(this.validateEmail(emailList[i]["Email"]));

          if (this.validateEmail(emailList[i]["Email"])) {
            // this.emailList.push({ value: emailList[i]["Email"], invalid: false})
            usersList.push({
              inviteEmails: emailList[i]["Email"],
              projects: [
                {
                  projectId: this.selectedProjectId,
                  role: emailList[i]["Role"],
                },
              ],
            });
          }
        }

        let payload = {
          userId: this.userData.id,
          mail: this.userData.mail,
          token: this.userData.token,
          emailBody: "Inviting USer",
          companyId: this.companyId,
          inviteUsersProjects: usersList,
        };

        console.log("Payload: ", payload);

        this.LeadsDashboardService.inviteUSers(payload).subscribe((res: any) => {
          if (res.status == 200) {
            this.uhleadsservice.showSuccess("Users invited successfully", "Success");
            document.getElementById("closeInviteButton").click();
          } else {
            this.uhleadsservice.showError("Something went wrong", "Error");
          }
        })
      } else {
        this.uhleadsservice.showError(
          "Please upload a valid file",
          "invalid data"
        );
      }
    };
    fileReader.readAsArrayBuffer(this.file);
  }

  validateEmail2(email) {
    var emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailPattern.test(email);
  }

  downloadTemplate() {
    this.colData = [{ displayName: "Email" }, { displayName: "Role" }];
    let colNames = [{}];
    // console.log(this.colData)
    for (let i = 0; i < this.colData.length; i++) {
      colNames[0][this.colData[i].displayName] = "";
    }
    console.log(colNames);
    this.ExcelService.exportAsExcelFile(colNames, "agents_upload");

    console.log("Generate Excel file");
  }

  add(event): void {
    console.log(event.value);
    if (event.value) {
      if (this.validateEmail(event.value)) {
        this.emailList.push({ value: event.value, invalid: false });
      } else {
        this.emailList.push({ value: event.value, invalid: true });
        this.rulesForm.controls["emails"].setErrors({ incorrectEmail: true });
      }
    }
    if (event.input) {
      event.input.value = "";
    }
  }

  removeEmail(data: any): void {
    console.log("Removing " + data);
    if (this.emailList.indexOf(data) >= 0) {
      this.emailList.splice(this.emailList.indexOf(data), 1);
    }
  }

  validateArrayNotEmpty(c: FormControl) {
    if (c.value && c.value.length === 0) {
      return {
        validateArrayNotEmpty: { valid: false },
      };
    }
    return null;
  }

  validateEmail(email) {
    var re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  inviteAgents() {
    console.log("Emails: ", this.emailList);
    console.log("Role: ", this.role);
    if (this.role && this.emailList.length > 0) {
      let usersList = [];
      let projects = [];

      for (let i = 0; i < this.emailList.length; i++) {
        console.log(this.emailList[i]["value"]);
        console.log(this.validateEmail(this.emailList[i]["value"]));

        if (this.validateEmail(this.emailList[i]["value"])) {
          usersList.push({
            inviteEmails: this.emailList[i]["value"],
            projects: [
              {
                projectId: this.selectedProjectId,
                role: this.role,
              },
            ],
          });
        }
      }

      let payload = {
        userId: this.userData.id,
        mail: this.userData.mail,
        token: this.userData.token,
        emailBody: "Inviting USer",
        companyId: this.companyId,
        inviteUsersProjects: usersList,
      };

      console.log("Payload: ", payload);

      this.LeadsDashboardService.inviteUSers(payload).subscribe((res: any) => {
        if (res.status == 200) {
          this.uhleadsservice.showSuccess("Users invited successfully", "Success");
          document.getElementById("closeInviteButton").click();
        } else {
          this.uhleadsservice.showError("Something went wrong", "Error");
        }
      })
    } else {
      this.uhleadsservice.showError(
        "Please enter emails and select a role",
        "Missing info"
      );
    }
  }
  copyText(userinput) {
    if (userinput) {
      navigator.clipboard.writeText(userinput);
      this.uhleadsservice.showSuccess("Copied!", "")
      // this.show()
    }


  }
}
