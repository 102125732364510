<div class="row">
  <div class="home-banner-bg2">
    <div class="banenr-text2">AI Voice Bots</div>
  </div>
</div>
<div class="container-md m-auto">
  <!--Section AccessQue-->
  <div class="row mt-5">
    <div class="col-md-6 col-sm-6 col-lg-6">
      <img src="../../../../assets/images/ai-voicebot-graphic.png" class="border-tb-radius3" style="width: 70%" />
    </div>
    <div class="col-md-6 col-sm-6 col-lg-6">
      <p class="highlightFont w-80 lh130 pt-3 px-3 pb-3">
        Voice Bots can automate routine voice tasks for organizations in financial services, healthcare management,
        sales, and more.
      </p>

      <p class="lightFont w-80 lh130 px-3">
        voice bot that can handle multi-turn customer interactions in a natural and friendly way. This Voice Bot is made
        for hybrid workforces, allowing voice bots and expert agents to tag-team contact centre
        workloads.
      </p>

      <button class="btn btn-primary mt-5 ml-3 " routerLink="/bookademo/aivoicebot">
        Book a Demo
      </button>
    </div>
  </div>
</div>