import { ActivatedRoute } from "@angular/router";
import { SharedService } from "./../../shared/shared.service";
import {
  Component,
  OnInit,
  ViewChild,
  AfterViewInit,
  Input,
  OnDestroy,
  Output,
  EventEmitter,
} from "@angular/core";
import { EmailEditorComponent } from "angular-email-editor";
import { MailserviceService } from "../mailservice.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { CreateAgent } from "../create-agent/create-agent.service";
import { RoleService } from "../role-management/role.service";
import { IDropdownSettings } from "ng-multiselect-dropdown";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Subscription } from "rxjs";
import { ProjectService } from "../project-management/project.service";
import { ErrorHandlerService } from "src/app/error-handler.service";
import { ProjectchangeService } from "src/app/layout/project-change/projectchange.service";

@Component({
  selector: "app-emailsettings",
  templateUrl: "./emailsettings.component.html",
  styleUrls: ["./emailsettings.component.css"],
})
export class EmailsettingsComponent
  implements OnInit, AfterViewInit, OnDestroy {
  inputData: any = {};
  localst: any;
  isUpdate: boolean = false;
  template: any;
  group: any[] = [];
  groups: any[] = [];
  projectId: number;
  companySettings: IDropdownSettings;
  error: boolean = false;
  errorMessage: string = "";
  @Input() templateId = null;
  @Input() comefrom: any;

  project: Subscription;

  @ViewChild(EmailEditorComponent, { static: true })
  private emailEditor!: EmailEditorComponent;

  @Output() createTemplate = new EventEmitter();

  constructor(
    private mailService: MailserviceService,
    private sharedService: SharedService,
    private route: ActivatedRoute,
    private snackbar: MatSnackBar,
    private agentService: CreateAgent,
    private companyService: RoleService,
    public readonly modalService: NgbModal,
    private handleError: ErrorHandlerService,
    private projectchangeService: ProjectchangeService,
    private readonly projectService: ProjectService,

  ) { }

  ngAfterViewInit() { }

  ngOnInit() {

    this.localst = JSON.parse(localStorage.getItem("userData"));


    // this.project = this.sharedService.projectId.subscribe((data) => {
    // if (this.localst["companyId"] === 0) {
    this.projectId = this.projectchangeService._projectId;
    this.getProjectId(this.projectId);
    // this.getCompany(data);
    // });


    this.companySettings = {
      singleSelection: true,
      allowSearchFilter: true,
      closeDropDownOnSelection: true,
      textField: "Name",
    };
    if (this.templateId != null) {
      this.loadTemplate(this.templateId);
      this.isUpdate = true;
    } else {
      this.isUpdate = false;
    }
    this.route.params.subscribe((res) => {
      if (res["id"]) {
        this.loadTemplate(res["id"]);
      }
    });

    // this.sharedService.pageEvent.emit({ pageName: 'Email Settings' });
  }
  ngOnDestroy(): void {
    this.templateId = null;
    this.template = null;
    if (this.project) {
      this.project.unsubscribe();
    }
  }

  loadTemplate(id) {
    this.isUpdate = true;
    this.mailService.getTemplateById(id).subscribe((data) => {
      this.template = data;
      this.inputData.name = data["Name"];
      if (data["Design"]) {
        setTimeout(() => {
          if (this.emailEditor) {
            this.emailEditor.loadDesign(data["Design"]);
          }
        }, 1500);
      }
    }, (error) => {
      this.handleError.handleError(error)
    });
  }

  // getCompany(companyId) {
  //   // this.companyId = companyId;
  //   // this.agentService.GetAllGroups(companyId).subscribe((data) => {
  //   //   this.groups = data.map((data) => ({
  //   //     Name: data["Name"],
  //   //     id: data["id"],
  //   //   }));
  //   //   this.companyService.getCompanyById(companyId).subscribe((data) => {
  //   //     this.inputData.fromEmail = data["SupportMail"];
  //   //     let selectedGroup = this.groups.find(
  //   //       (datas) => datas["id"] === data["IncomingMailGroup"]
  //   //     );
  //   //     this.group =
  //   //       data["IncomingMailGroup"] === null
  //   //         ? ["select group"]
  //   //         : [selectedGroup["Name"]];
  //   //   });
  //   // });
  // }

  getProjectId(id) {
    this.projectService.getProject(id).subscribe((data) => {
      // this.fromMail=data['ForwardMail']
      this.inputData["fromEmail"] = data["ForwardMail"] || "";
    }, (error) => {
      this.handleError.handleError(error)
    });
  }

  saveTemplate() {
    this.emailEditor.editor.exportHtml((data) => {

      this.mailService.htmlTemplate.next(data["html"].toString());
      if (this.comefrom == 'workflow') {
        let payload = {
          Name: this.inputData.name,
          Creator: this.localst["workerFriendlyName"],
          Html: data["html"],
          Design: data["design"],
          FromEmail: this.inputData["fromEmail"],
          projectId: this.projectId,
          content: 'content'
        }
        this.createTemplate.emit(JSON.stringify(payload))
      }
      else {
      this.mailService
        .createTemplate({
          Name: this.inputData.name,
          Creator: this.localst["workerFriendlyName"],
          Html: data["html"],
          Design: data["design"],
          FromEmail: this.inputData["fromEmail"],
          companyId: this.projectId,
        })
        .subscribe(
          (data) => {
            if (data["status"]) {
              // this.modalService.dismissAll()
              return (this.errorMessage = data["status"]);
            }

            this.modalService.dismissAll();
            // this.snackbar.open('template created successfully', 'close', { duration: 10000 })
          },
          (err) => {
            this.snackbar.open(err.error.status, "close", { duration: 10000 });
            this.handleError.handleError(err)

          }
        );
      }
    });
  }

  updateTemplate() {
    this.emailEditor.editor.exportHtml((data) => {
      this.mailService
        .updateTemplate({
          id: this.template["id"],
          Design: data["design"],
          Html: data["html"],
          Active: this.template["Active"],
          FromEmail: this.inputData["fromEmail"],
        })
        .subscribe(
          (data) => {
            if (data) {
              this.modalService.dismissAll();
              // this.snackbar.open('upadted successfully template', 'close', { duration: 10000 })
            }

          },
          (err) => {
            this.error = true;
            this.errorMessage = "something went wrong please try again";
            this.handleError.handleError(err)

            // this.snackbar.open('something went wrong please try again', 'close', { duration: 10000 })
          }
        );
    });
  }

  // updateGroup(value) {
  //   this.companyService
  //     .updateMailGroup(this.projectId, this.group[0]["id"])
  //     .subscribe((data) => {});
  // }
  projectChange(data) {
    this.projectId = data;
    this.getProjectId(this.projectId);
  }
}

