import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import {
  Location,
  LocationStrategy,
  HashLocationStrategy,
} from "@angular/common";
import * as shape from "d3-shape";
import * as d3Array from "d3-array";

import { Color, colorSets } from "@swimlane/ngx-charts/utils/color-sets";

import {
  formatLabel,
  escapeLabel,
} from "@swimlane/ngx-charts/common/label.helper";
import {
  single,
  multi,
  boxData,
  bubble,
  generateData,
  generateGraph,
  treemap,
  timelineFilterBarData,
  fiscalYearReport,
} from "../dashboard-reports/data";
import { bubbleDemoData } from "../../custom-charts/bubble-chart-interactive/data";

import { BubbleChartInteractiveServerDataModel } from "../../custom-charts/bubble-chart-interactive/models";
import { data as countries } from "emoji-flags";
import chartGroups from "../dashboard-reports/chartTypes";
import { barChart, lineChartSeries } from "../dashboard-reports/combo-chart-data";

import { InputTypes } from "@swimlane/ngx-ui";
import { LegendPosition } from "@swimlane/ngx-charts/common/types/legend.model";
import { ScaleType } from "@swimlane/ngx-charts/common/types/scale-type.enum";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { ReportsService } from "../reports/reports.service";
import { SharedService } from "src/app/shared/shared.service";
import { MomentFormatService } from "src/app/moment-format-service";
import { each, post } from "jquery";
import { use } from "echarts";
import { forEach, toNumber } from "lodash";
import { Console } from "console";
import * as moment from "moment";
import { CreateAgent } from "../create-agent/create-agent.service";
import { UhLeadsService } from "../uhleads.service";
import * as XLSX from 'xlsx';
import { stringLength } from "@firebase/util";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import { CallanalyticsService } from "./callanalytics.service";
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

const monthName = new Intl.DateTimeFormat("en-us", { month: "short" });
const weekdayName = new Intl.DateTimeFormat("en-us", { weekday: "short" });

@Component({
  selector: "app-callAnalyticsNew",
  templateUrl: "./callAnalyticsNew.component.html",
  styleUrls: ["./callAnalyticsNew.component.scss", "./callAnalyticsNew2.component.scss"],
})
export class CallAnalyticsNewComponent implements OnInit {

  public filterValue = "all";
  showDate: boolean = false;
  startDate: any;
  endDate: any;
  date1: any;
  date2: any;
  // APP_VERSION = pkg.version;

  inputTypes = InputTypes;

  theme = "dark";
  chartType: string;
  chartGroups: any[];
  chart: any;
  realTimeData: boolean = false;
  countries: any[];
  single: any[];
  multi: any[];
  fiscalYearReport: any[];
  dateData: any[];
  dateDataWithRange: any[];
  calendarData: any[];
  statusData: any[];
  sparklineData: any[];
  timelineFilterBarData: any[];
  graph: { links: any[]; nodes: any[] };
  bubble: any;
  linearScale: boolean = false;
  range: boolean = false;

  view: [number, number];
  view2: [number, number];
  width: number = 1000;
  width2: number = 450;
  height: number = 350;
  fitContainer: boolean = false;

  // options
  showXAxis = true;
  showYAxis = true;
  gradient = false;
  showLegend = true;
  legendTitle = "Legend";
  legendPosition = LegendPosition.Right;
  showXAxisLabel = true;
  tooltipDisabled = false;
  showText = false;
  xAxisLabel = "Country";
  showYAxisLabel = true;
  yAxisLabel = "Applications Received";
  showGridLines = true;
  innerPadding = "10%";
  barPadding = 100;
  groupPadding = 16;
  roundDomains = false;
  maxRadius = 10;
  minRadius = 3;
  showSeriesOnHover = true;
  roundEdges: boolean = true;
  animations: boolean = true;
  xScaleMin: any;
  xScaleMax: any;
  yScaleMin: number;
  yScaleMax: number;
  showDataLabel: boolean = false;
  noBarWhenZero: boolean = true;
  trimXAxisTicks: boolean = true;
  trimYAxisTicks: boolean = true;
  rotateXAxisTicks: boolean = true;
  maxXAxisTickLength: number = 16;
  maxYAxisTickLength: number = 16;
  strokeColor: string = "#FFFFFF";
  strokeWidth: number = 2;

  curves = {
    Basis: shape.curveBasis,
    "Basis Closed": shape.curveBasisClosed,
    Bundle: shape.curveBundle.beta(1),
    Cardinal: shape.curveCardinal,
    "Cardinal Closed": shape.curveCardinalClosed,
    "Catmull Rom": shape.curveCatmullRom,
    "Catmull Rom Closed": shape.curveCatmullRomClosed,
    Linear: shape.curveLinear,
    "Linear Closed": shape.curveLinearClosed,
    "Monotone X": shape.curveMonotoneX,
    "Monotone Y": shape.curveMonotoneY,
    Natural: shape.curveNatural,
    Step: shape.curveStep,
    "Step After": shape.curveStepAfter,
    "Step Before": shape.curveStepBefore,
    default: shape.curveLinear,
  };

  // line interpolation
  curveType: string = "Linear";
  curve: any = this.curves[this.curveType];
  interpolationTypes = [
    "Basis",
    "Bundle",
    "Cardinal",
    "Catmull Rom",
    "Linear",
    "Monotone X",
    "Monotone Y",
    "Natural",
    "Step",
    "Step After",
    "Step Before",
  ];

  closedCurveType: string = "Linear Closed";
  closedCurve: any = this.curves[this.closedCurveType];
  closedInterpolationTypes = [
    "Basis Closed",
    "Cardinal Closed",
    "Catmull Rom Closed",
    "Linear Closed",
  ];

  colorSets: any;
  colorScheme: any;
  schemeType = ScaleType.Ordinal;
  selectedColorScheme: string;
  rangeFillOpacity: number = 0.15;

  // Override colors for certain values
  customColors: any[] = [
    {
      name: "Germany",
      value: "#a8385d",
    },
  ];

  // pie
  showLabels = true;
  explodeSlices = false;
  doughnut = false;
  arcWidth = 0.25;

  // line, area
  autoScale = true;
  timeline = false;

  // margin
  margin: boolean = false;
  marginTop: number = 40;
  marginRight: number = 40;
  marginBottom: number = 40;
  marginLeft: number = 40;

  // box
  boxData = boxData;

  // gauge
  gaugeMin: number = 0;
  gaugeMax: number = 100;
  gaugeLargeSegments: number = 10;
  gaugeSmallSegments: number = 5;
  gaugeTextValue: string = "";
  gaugeUnits: string = "alerts";
  gaugeAngleSpan: number = 240;
  gaugeStartAngle: number = -120;
  gaugeShowAxis: boolean = true;
  gaugeValue: number = 50; // linear gauge value
  gaugePreviousValue: number = 70;

  // heatmap
  heatmapMin: number = 0;
  heatmapMax: number = 50000;

  // Combo Chart
  barChart: any[] = barChart;
  lineChartSeries: any[] = lineChartSeries;
  // barChart: any[];
  // lineChartSeries: any[];
  lineChartScheme: Color = {
    name: "coolthree",
    selectable: true,
    group: ScaleType.Ordinal,
    domain: ["#01579b", "#7aa3e5", "#a8385d", "#00bfa5"],
  };

  comboBarScheme: Color = {
    name: "singleLightBlue",
    selectable: true,
    group: ScaleType.Ordinal,
    domain: ["#01579b"],
  };

  showRightYAxisLabel: boolean = true;
  yAxisLabelRight: string = "Utilization";

  // demos
  totalSales = 0;
  salePrice = 100;
  personnelCost = 100;

  mathText = "3 - 1.5*sin(x) + cos(2*x) - 1.5*abs(cos(x))";
  mathFunction: (o: any) => any;

  treemap: any[];
  treemapPath: any[] = [];
  sumBy: string = "Size";

  // bubble chart interactive demo
  bubbleDemoTempData: any[] = [];
  bubbleDemoChart: BubbleChartInteractiveServerDataModel;

  // Reference lines
  showRefLines: boolean = true;
  showRefLabels: boolean = true;

  // Supports any number of reference lines.
  refLines = [
    { value: 42500, name: "Maximum" },
    { value: 37750, name: "Average" },
    { value: 33000, name: "Minimum" },
  ];

  // data
  plotData: any;

  // Sidebar Controls:
  colorVisible: boolean = true;
  dataVisible: boolean = true;
  dimVisible: boolean = true;
  optsVisible: boolean = true;

  //Filters Data
  userData: any;
  filtersData: any;

  //data for charts
  statusCounts: any;
  completedStatus: any;
  assignedCounts: any;
  courseCounts: any;
  projectId: any;
  combochartData: {
    barChart: any[];
    lineChart: [{ name: "Completed"; series: any[] }];
  };
  barChartCombo: any = [];
  barChartCombo2: any = [
    { name: "Completed", series: [] },
    { name: "Pending", series: [] },
  ];

  lineChartCombo = [{ name: "Completed", series: [] }];
  lineChartCalls = [
    { name: "Total", series: [] },
    { name: "Bad", series: [] },
    { name: "Good", series: [] },
    { name: "Fraud", series: [] }
  ];
  inboundOutbound = [];
  totalAgents: any[];
  GBPagentList: any;
  topPerformersGood: any;
  topPerformersBad: any;
  incomingVsOutgoing: any[];
  callStatusCounts: any[];
  top10Data: any[];
  top10Validation: any[];
  counts: any;

  constructor(
    public location: Location,
    private sharedService: SharedService,
    private momentFormatService: MomentFormatService,
    private callService: CallanalyticsService
  ) {
    this.mathFunction = this.getFunction();

    Object.assign(this, {
      single,
      multi,
      countries,
      chartGroups,
      colorSets,
      graph: generateGraph(50),
      boxData,
      bubble,
      plotData: this.generatePlotData(),
      treemap,
      bubbleDemoData,
      fiscalYearReport,
    });

    // interactive drill down demos
    this.treemapProcess();
    this.bubbleDemoChart = new BubbleChartInteractiveServerDataModel();
    this.bubbleDemoProcess(bubbleDemoData[0]);

    this.dateData = generateData(5, false);
    this.dateDataWithRange = generateData(2, true);
    this.setColorScheme("cool");
    this.calendarData = this.getCalendarData();
    this.statusData = this.getStatusData();
    this.sparklineData = generateData(1, false, 30);
    this.timelineFilterBarData = timelineFilterBarData();
  }
  get dateDataWithOrWithoutRange() {
    if (this.range) {
      return this.dateDataWithRange;
    } else {
      return this.dateData;
    }
  }
  ngOnInit() {
    this.sharedService.pageEvent.emit({
      pageName: "BI Analytics",
    });

    this.userData = JSON.parse(localStorage.getItem("userData") || "{}");
    // console.log("USerData: ", this.userData);
    this.date1 = new FormControl(new Date());
    this.date2 = new FormControl(new Date(Date.now() - 864e5));
    this.endDate = new Date(Date.now());
    this.startDate = new Date(Date.now() - 864e5 * 365);

    this.startDate = this.momentFormatService.dashboardDateFormat(
      new Date(new Date(Date.now() - 864e5 * 365)).getTime()
    );
    this.endDate = this.momentFormatService.dashboardDateFormat(
      new Date(new Date(Date.now() + 864e5))
    );
    if (!this.fitContainer) {
      this.applyDimensions();
    }
    this.callChartFunctions();
  }

  callChartFunctions() {
    this.getCounts();
    this.getGoodVsbadVsfraud();
    this.getTopGoodCalls();
    this.getTopBadCalls();
    this.getCallsPerMonth();
  }

  hideDate() {
    // this.filterValuev
    if (this.filterValue == "custom" && this.showDate == false) {
      this.showDate = true;
    }
  }
  updateDateFilter(event: any) {
    if (event.target.value != "custom") {
      this.endDate = this.momentFormatService.dashboardDateFormat(
        new Date(new Date(Date.now())).getTime()
      );
    }
    if (event.target.value == "custom") {
      //show custom date filter
      this.showDate = true;
    } else if (event.target.value == "all") {
      this.startDate = this.momentFormatService.dashboardDateFormat(
        new Date(new Date(Date.now() - 864e5 * 365)).getTime()
      );

      this.showDate = false;
    } else if (event.target.value == "24hrs") {
      this.startDate = this.momentFormatService.dashboardDateFormat(
        new Date(new Date(Date.now() - 864e5)).getTime()
      );

      this.showDate = false;
    } else if (event.target.value == "72hrs") {
      this.startDate = this.momentFormatService.dashboardDateFormat(
        new Date(new Date(Date.now() - 864e5 * 3)).getTime()
      );

      this.showDate = false;
    } else if (event.target.value == "7days") {
      this.startDate = this.momentFormatService.dashboardDateFormat(
        new Date(new Date(Date.now() - 864e5 * 7)).getTime()
      );

      this.showDate = false;
    } else if (event.target.value == "14days") {
      this.startDate = this.momentFormatService.dashboardDateFormat(
        new Date(new Date(Date.now() - 864e5 * 14)).getTime()
      );

      this.showDate = false;
    } else if (event.target.value == "30days") {
      this.startDate = this.momentFormatService.dashboardDateFormat(
        new Date(new Date(Date.now() - 864e5 * 30)).getTime()
      );

      this.showDate = false;
    }
    this.callChartFunctions();
  }

  getStartDate(type: string, event: MatDatepickerInputEvent<Date>) {
    this.startDate = event.value;
    this.startDate = this.momentFormatService.dashboardDateFormat(
      new Date(this.startDate).getTime
    );
  }
  getEndDate(type: string, event: MatDatepickerInputEvent<Date>) {
    this.endDate = event.value;
    this.endDate = this.momentFormatService.dashboardDateFormat(
      new Date(this.endDate).getTime
    );
  }

  //Date Filter
  filterData() {
    this.startDate = this.momentFormatService.dashboardDateFormat(
      new Date(this.date2.value).getTime()
    );
    this.endDate = this.momentFormatService.dashboardDateFormat(
      new Date(this.date1.value).getTime()
    );

    if (this.endDate < this.startDate) {

      return;
    } else {
      this.callChartFunctions();
      this.showDate = false;
    }
  }

  applyDimensions() {
    this.view = [this.width, this.height];
    this.view2 = [this.width2, this.height];
  }

  toggleFitContainer() {
    if (this.fitContainer) {
      this.view = undefined;
    } else {
      this.applyDimensions();
    }
  }
  setColorScheme(name) {
    this.selectedColorScheme = name;
    this.colorScheme = this.colorSets.find((s) => s.name === name);
  }
  select(data) {
    console.log("Item clicked", JSON.parse(JSON.stringify(data)));
  }
  activate(data) {
    // console.log('Activate', JSON.parse(JSON.stringify(data)));
  }

  deactivate(data) {
    // console.log('Deactivate', JSON.parse(JSON.stringify(data)));
  }

  getDatesInRange(startDate, endDate) {
    const date = new Date(startDate.getTime());

    // ✅ Exclude start date
    date.setDate(date.getDate() + 1);

    const dates = [];

    // ✅ Exclude end date
    while (date <= endDate) {
      dates.push(this.formatDate(new Date(date)));
      date.setDate(date.getDate() + 1);
    }

    return dates;
  }
  formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }

  GetSortOrder(prop) {
    return function (a, b) {
      if (a[prop] > b[prop]) {
        return 1;
      } else if (a[prop] < b[prop]) {
        return -1;
      }
      return 0;
    };
  }

  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  selectChart(chartSelector) {
    this.chartType = chartSelector = chartSelector.replace("/", "");
    this.location.replaceState(this.chartType);

    for (const group of this.chartGroups) {
      this.chart = group.charts.find((x) => x.selector === chartSelector);
      if (this.chart) break;
    }

    this.linearScale = false;
    this.yAxisLabel = "GDP Per Capita";
    this.xAxisLabel = "Country";

    this.width = 700;
    this.height = 300;

    Object.assign(this, this.chart.defaults);

    if (!this.fitContainer) {
      this.applyDimensions();
    }
  }

  changeTheme(theme: string) {
    this.theme = theme;
    if (theme === "light") {
      this.strokeColor = "#000000";
    } else {
      this.strokeColor = "#FFFFFF";
    }
  }

  getInterpolationType(curveType) {
    return this.curves[curveType] || this.curves["default"];
  }

  onLegendLabelClick(entry) {
    console.log("Legend clicked", entry);
  }

  getCalendarData(): any[] {
    // today
    const now = new Date();
    const todaysDay = now.getDate();
    const thisDay = new Date(now.getFullYear(), now.getMonth(), todaysDay);

    // Monday
    const thisMonday = new Date(
      thisDay.getFullYear(),
      thisDay.getMonth(),
      todaysDay - thisDay.getDay() + 1
    );
    const thisMondayDay = thisMonday.getDate();
    const thisMondayYear = thisMonday.getFullYear();
    const thisMondayMonth = thisMonday.getMonth();

    // 52 weeks before monday
    const calendarData = [];
    const getDate = (d) => new Date(thisMondayYear, thisMondayMonth, d);
    for (let week = -52; week <= 0; week++) {
      const mondayDay = thisMondayDay + week * 7;
      const monday = getDate(mondayDay);

      // one week
      const series = [];
      for (let dayOfWeek = 7; dayOfWeek > 0; dayOfWeek--) {
        const date = getDate(mondayDay - 1 + dayOfWeek);

        // skip future dates
        if (date > now) {
          continue;
        }

        // value
        const value = dayOfWeek < 6 ? date.getMonth() + 1 : 0;

        series.push({
          date,
          name: weekdayName.format(date),
          value,
        });
      }

      calendarData.push({
        name: monday.toString(),
        series,
      });
    }

    return calendarData;
  }

  calendarAxisTickFormatting(mondayString: string) {
    const monday = new Date(mondayString);
    const month = monday.getMonth();
    const day = monday.getDate();
    const year = monday.getFullYear();
    const lastSunday = new Date(year, month, day - 1);
    const nextSunday = new Date(year, month, day + 6);
    return lastSunday.getMonth() !== nextSunday.getMonth()
      ? monthName.format(nextSunday)
      : "";
  }

  calendarTooltipText(c): string {
    return `
          <span class="tooltip-label">${c.label
      } • ${c.cell.date.toLocaleDateString()}</span>
          <span class="tooltip-val">${c.data.toLocaleString()}</span>
        `;
  }

  pieTooltipText({ data }) {
    const label = formatLabel(data.name);
    const val = formatLabel(data.value);
    return `
          <span class="tooltip-label">${escapeLabel(label)}</span>
          <span class="tooltip-val">${val}</span>
        `;
  }

  dollarValueFormat(c): string {
    return `$${c.value.toLocaleString()}`;
  }

  getStatusData() {
    const sales = Math.round(1e4 * Math.random());
    const dur = 36e5 * Math.random();
    return this.calcStatusData(sales, dur);
  }

  calcStatusData(
    sales = this.statusData[0].value,
    dur = this.statusData[2].value
  ) {
    const ret = sales * this.salePrice;
    const cost = ((sales * dur) / 60 / 60 / 1000) * this.personnelCost;
    const ROI = (ret - cost) / cost;
    return [
      {
        name: "Sales",
        value: sales,
      },
      {
        name: "Gross",
        value: ret,
        extra: { format: "currency" },
      },
      {
        name: "Avg. Time",
        value: dur,
        extra: { format: "time" },
      },
      {
        name: "Cost",
        value: cost,
        extra: { format: "currency" },
      },
      {
        name: "ROI",
        value: ROI,
        extra: { format: "percent" },
      },
    ];
  }

  statusValueFormat(c): string {
    switch (c.data.extra ? c.data.extra.format : "") {
      case "currency":
        return `$${Math.round(c.value).toLocaleString()}`;
      case "time":
        return multiFormat(c.value);
      case "percent":
        return `${Math.round(c.value * 100)}%`;
      default:
        return c.value.toLocaleString();
    }
  }

  valueFormatting(value: number): string {
    return `${Math.round(value).toLocaleString()} €`;
  }

  currencyFormatting(value: number) {
    return `${Math.round(value).toLocaleString()}`;
  }

  gdpLabelFormatting(c) {
    return `${escapeLabel(
      c.label
    )}<br/><small class="number-card-label"></small>`;
  }

  statusLabelFormat(c): string {
    return `${c.label}<br/><small class="number-card-label">This week</small>`;
  }

  generatePlotData() {
    if (!this.mathFunction) {
      return [];
    }
    const twoPi = 2 * Math.PI;
    const length = 25;
    const series = Array({ length }).map((d, i) => {
      const x = i / (length - 1);
      const t = x * twoPi;
      return {
        name: ~~(x * 360),
        value: this.mathFunction(t),
      };
    });

    return [
      {
        name: this.mathText,
        series,
      },
    ];
  }

  getFunction(text = this.mathText) {
    try {
      text = `with (Math) { return ${this.mathText} }`;
      // tslint:disable-next-line: function-constructor
      const fn = new Function("x", text).bind(Math); // tslint:disable-line: tsr-detect-eval-with-expression
      return typeof fn(1) === "number" ? fn : null;
    } catch (err) {
      return null;
    }
  }

  treemapProcess(sumBy = this.sumBy) {
    this.sumBy = sumBy;
    const children = treemap[0];
    const value =
      sumBy === "Size" ? sumChildren(children) : countChildren(children);
    this.treemap = [children];
    this.treemapPath = [{ name: "Top", children: [children], value }];

    function sumChildren(node) {
      return (node.value =
        node.size || d3Array.sum(node.children, sumChildren));
    }

    function countChildren(node) {
      return (node.value = node.children
        ? d3Array.sum(node.children, countChildren)
        : 1);
    }
  }

  treemapSelect(item) {
    if (item.children) {
      const idx = this.treemapPath.indexOf(item);
      this.treemapPath.splice(idx + 1);
      this.treemap = this.treemapPath[idx].children;
      return;
    }
    const node = this.treemap.find((d) => d.name === item.name);
    if (node.children) {
      this.treemapPath.push(node);
      this.treemap = node.children;
    }
  }

  getFlag(country) {
    return this.countries.find((c) => c.name === country).emoji;
  }

  onFilter(event) {
    console.log("timeline filter", event);
  }

  /*
  **
  Combo Chart
  **
  [yLeftAxisScaleFactor]="yLeftAxisScale" and [yRightAxisScaleFactor]="yRightAxisScale"
  exposes the left and right min and max axis values for custom scaling, it is probably best to
  scale one axis in relation to the other axis but for flexibility to scale either the left or
  right axis both were exposed.
  **
  */

  yLeftAxisScale(min, max) {
    return { min: `${min}`, max: `${max}` };
  }

  yRightAxisScale(min, max) {
    return { min: `${min}`, max: `${max}` };
  }

  yLeftTickFormat(data) {
    return `${data.toLocaleString()}`;
  }

  yRightTickFormat(data) {
    return `${data}`;
  }
  /*
  **
  End of Combo Chart
  **
  */

  onSelect(event) {
    console.log(event);
  }

  dblclick(event) {
    console.log("Double click", event);
  }

  /*
  **
  Bubble Chart Interactive Demo
  **
  */

  bubbleDemoProcess(dataFromServer) {
    this.bubbleDemoChart.setDataFromServer(dataFromServer);
    this.bubbleDemoTempData = this.bubbleDemoChart.toChart();
  }

  getBubbleInteractiveTitle() {
    return this.bubbleDemoChart.getChartTitle();
  }

  bubbleShowDrillDownResetLink() {
    return this.bubbleDemoChart.getDrilldownDepth() > 0;
  }

  onClickResetBubbleInteractiveDrill() {
    this.bubbleDemoChart.resetDrilldown();
    this.bubbleDemoTempData = this.bubbleDemoChart.toChart();
  }

  onSelectBubbleInteractivePoint(event) {
    this.bubbleDemoChart.drilldown(event);
    this.bubbleDemoTempData = this.bubbleDemoChart.toChart();
  }

  getCounts() {
    let obj = {
      "from": this.startDate,
      "to": this.endDate
    }
    this.callService.getCounts(obj).subscribe((res: any) => {
      this.counts = res.data;
      console.log("🚀 ---------------------------------------------------------------------------------------------------------------------------------🚀");
      console.log("🚀 ~ file: callAnalyticsNew.component.ts:895 ~ CallAnalyticsNewComponent ~ this.callService.getCounts ~ this.counts:", this.counts);
      console.log("🚀 ---------------------------------------------------------------------------------------------------------------------------------🚀");
    }, (error) => {

    });
  }

  getGoodVsbadVsfraud() {
    let obj = {
      "from": this.startDate,
      "to": this.endDate
    }
    this.callService.getgoodVsbadVsfraud(obj).subscribe((res: any) => {
      this.inboundOutbound = [
        { name: "Good", series: [] },
        { name: "Bad", series: [] },
        { name: "Fraud", series: [] },
      ];
      const d1 = new Date(this.startDate);
      const d2 = new Date(this.endDate);
      let allDates = this.getDatesInRange(d1, d2);
      let check = false;
      let chartData = [];
      for (let k = 0; k < 3; k++) {
        for (let i = 0; i < allDates.length; i++) {
          check = false;
          for (let j = 0; j < res.data[k].length; j++) {
            if (allDates[i] == res.data[k][j].createdAt) {
              check = true;
              this.inboundOutbound[k].series.push({
                name: allDates[i],
                value: toNumber(res.data[k][j].count),
              });
            }
          }
          if (check == false) {
            this.inboundOutbound[k].series.push({
              name: allDates[i],
              value: 0,
            });
          }
        }
      }

      if (allDates.length > 300) {
        for (let i = 0; i < this.inboundOutbound[0].series.length; i++) {
          let d = new Date(this.inboundOutbound[0].series[i].name);

          this.inboundOutbound[0].series[i].name = moment(
            this.inboundOutbound[0].series[i].name
          ).format("YYYY");
        }
        const years = [
          ...new Set(this.inboundOutbound[0].series.map((item) => item.name)),
        ];


        for (let i = 0; i < years.length; i++) {
          let bad = 0;
          let good = 0;
          let fraud = 0;

          for (let j = 0; j < allDates.length; j++) {
            if (years[i] == this.inboundOutbound[0].series[j].name) {
              bad = bad + this.inboundOutbound[0].series[j].value;
              good = good + this.inboundOutbound[1].series[j].value;
              fraud = fraud + this.inboundOutbound[2].series[j].value;
            }
          }

          chartData.push({
            name: years[i],
            series: [
              {
                name: "bad",
                value: bad,
              },
              {
                name: "good",
                value: good,
              },
              {
                name: "fraud",
                value: fraud,
              },
            ],
          });
        }
      } else if (allDates.length > 150) {
        for (let i = 0; i < this.inboundOutbound[0].series.length; i++) {
          let d = new Date(this.inboundOutbound[0].series[i].name);

          this.inboundOutbound[0].series[i].name = moment(
            this.inboundOutbound[0].series[i].name
          ).format("MMM YY");
        }

        const years = [
          ...new Set(this.inboundOutbound[0].series.map((item) => item.name)),
        ];

        for (let i = 0; i < years.length; i++) {
          let bad = 0;
          let good = 0;
          let fraud = 0;

          for (let j = 0; j < allDates.length; j++) {
            if (years[i] == this.inboundOutbound[0].series[j].name) {
              bad = bad + this.inboundOutbound[0].series[j].value;
              good = good + this.inboundOutbound[1].series[j].value;
              fraud = fraud + this.inboundOutbound[2].series[j].value;
            }
          }

          chartData.push({
            name: years[i],
            series: [
              {
                name: "bad",
                value: bad,
              },
              {
                name: "good",
                value: good,
              },
              {
                name: "fraud",
                value: fraud,
              },
            ],
          });
        }
      } else if (allDates.length > 10) {
        for (let i = allDates.length - 1; i > 0; i--) {
          for (let j = i - 1; j > i - 7; j--) {
            allDates[j] = allDates[i];
          }
          i = i - 6;
        }

        for (let i = 0; i < this.inboundOutbound[0].series.length; i++) {
          this.inboundOutbound[0].series[i].name = moment(allDates[i]).format(
            "DD MMM"
          );
          this.inboundOutbound[1].series[i].name = moment(allDates[i]).format(
            "DD MMM"
          );
        }

        const years = [
          ...new Set(this.inboundOutbound[0].series.map((item) => item.name)),
        ];

        for (let i = 0; i < years.length; i++) {
          let bad = 0;
          let good = 0;
          let fraud = 0;

          for (let j = 0; j < allDates.length; j++) {
            if (years[i] == this.inboundOutbound[0].series[j].name) {
              bad = bad + this.inboundOutbound[0].series[j].value;
              good = good + this.inboundOutbound[1].series[j].value;
              fraud = fraud + this.inboundOutbound[2].series[j].value;
            }
          }

          chartData.push({
            name: years[i],
            series: [
              {
                name: "bad",
                value: bad,
              },
              {
                name: "good",
                value: good,
              },
              {
                name: "fraud",
                value: fraud,
              },
            ],
          });
        }
      } else {
        for (let i = 0; i < this.inboundOutbound[0].series.length; i++) {
          this.inboundOutbound[0].series[i].name = moment(allDates[i]).format(
            "DD MMM"
          );
          this.inboundOutbound[1].series[i].name = moment(allDates[i]).format(
            "DD MMM"
          );
        }

        const years = [
          ...new Set(this.inboundOutbound[0].series.map((item) => item.name)),
        ];

        for (let i = 0; i < years.length; i++) {
          let bad = 0;
          let good = 0;
          let fraud = 0;

          for (let j = 0; j < allDates.length; j++) {
            if (years[i] == this.inboundOutbound[0].series[j].name) {
              bad = bad + this.inboundOutbound[0].series[j].value;
              good = good + this.inboundOutbound[1].series[j].value;
              fraud = fraud + this.inboundOutbound[2].series[j].value;
            }
          }

          chartData.push({
            name: years[i],
            series: [
              {
                name: "bad",
                value: bad,
              },
              {
                name: "good",
                value: good,
              },
              {
                name: "fraud",
                value: fraud,
              },
            ],
          });
        }
      }

      this.inboundOutbound = chartData;
    }, (error) => {

    })
  }

  getTopGoodCalls() {
    let obj = {
      "from": this.startDate,
      "to": this.endDate
    }
    this.callService.gettopgoodcalls(obj).subscribe((res: any) => {
      this.topPerformersGood = [];
      let gaugeData = [];
      let max = 0;
      res.data.forEach((element) => {
        gaugeData.push({
          name: element.full_name,
          value: element.count,
        });
        max = max + element.count;
      });
      this.gaugeMax = Math.ceil(gaugeData[0].value / 10) * 10;
      this.topPerformersGood = gaugeData;
    }, (error) => {

    })
  }

  getTopBadCalls() {
    let obj = {
      "from": this.startDate,
      "to": this.endDate
    }
    this.callService.gettopbadcalls(obj).subscribe((res: any) => {
      this.topPerformersBad = [];
      let gaugeData = [];
      let max = 0;
      res.data.forEach((element) => {
        gaugeData.push({
          name: element.full_name,
          value: element.count,
        });
        max = max + element.count;
      });
      this.gaugeMax = Math.ceil(gaugeData[0].value / 10) * 10;
      this.topPerformersBad = gaugeData;
    }, (error) => {

    })
  }
  getCallsPerMonth() {
    let obj = {
      "from": this.startDate,
      "to": this.endDate
    }
    this.callService.getcallsPerMonth(obj).subscribe((res: any) => {
      this.lineChartCalls = [
        { name: "Total", series: [] },
        { name: "Bad", series: [] },
        { name: "Good", series: [] },
        { name: "Fraud", series: [] }
      ];
      const d1 = new Date(this.startDate);
      const d2 = new Date(this.endDate);

      let allDates = this.getDatesInRange(d1, d2);
      let check = false;
      console.log("1210 res.data.length ------> ", res.data.length)
      for (let a = 0; a < res.data.length; a++) {
        for (let b = 0; b < allDates.length; b++) {
          check = false;
          for (let c = 0; c < res.data[a].length; c++) {
            if (allDates[b] == res.data[a][c].createdAt) {
              check = true;
              this.lineChartCalls[a].series.push({
                name: allDates[b],
                value: toNumber(res.data[a][c].count),
              });
            }
          }
          if (check == false) {
            this.lineChartCalls[a].series.push({
              name: allDates[b],
              value: 0,
            });
          }
        }
      }

      console.log("🚀 -------------------------------------------------------------------------------------------------------------------------------------------------🚀");
      console.log("🚀 ~ file: callAnalyticsNew.component.ts:1232 ~ CallAnalyticsNewComponent ~ this.callService.getcallsPerMonth ~ allDates.length:", allDates.length);
      console.log("🚀 -------------------------------------------------------------------------------------------------------------------------------------------------🚀");
      if (allDates.length > 500) {
        for (let i = 0; i < this.lineChartCalls[0].series.length; i++) {
          let d = new Date(this.lineChartCalls[0].series[i].name);

          this.lineChartCalls[0].series[i].name = moment(
            this.lineChartCalls[0].series[i].name
          ).format("YYYY");
        }
        let temp3 = this.lineChartCalls;
        let temp4 = [
          { name: "Total", series: [] },
          { name: "Bad", series: [] },
          { name: "Good", series: [] },
          { name: "Fraud", series: [] }
        ];
        const years = [
          ...new Set(this.lineChartCalls[0].series.map((item) => item.name)),
        ];
        for (let i = 0; i < years.length; i++) {
          temp4[0].series.push({
            name: years[i],
            value: 0,
          });
          temp4[1].series.push({
            name: years[i],
            value: 0,
          });
          temp4[2].series.push({
            name: years[i],
            value: 0,
          });
          temp4[3].series.push({
            name: years[i],
            value: 0,
          });

          for (let j = 0; j < temp3[0].series.length; j++) {
            if (years[i] == temp3[0].series[j].name) {
              temp4[0].series[i].value = temp4[0].series[i].value + temp3[0].series[j].value;
              temp4[1].series[i].value = temp4[1].series[i].value + temp3[1].series[j].value;
              temp4[2].series[i].value = temp4[2].series[i].value + temp3[2].series[j].value;
              temp4[3].series[i].value = temp4[3].series[i].value + temp3[3].series[j].value;
            }
          }
        }
        this.lineChartCalls = temp4;
      } else if (allDates.length > 300) {
        // console.log("Month View");
        for (let i = 0; i < this.lineChartCalls[0].series.length; i++) {
          let d = new Date(this.lineChartCalls[0].series[i].name);

          this.lineChartCalls[0].series[i].name = moment(
            this.lineChartCalls[0].series[i].name
          ).format("MMM YY");
        }

        let temp3 = this.lineChartCalls;
        let temp4 = [
          { name: "Total", series: [] },
          { name: "Bad", series: [] },
          { name: "Good", series: [] },
          { name: "Fraud", series: [] }
        ];

        const years = [
          ...new Set(this.lineChartCalls[0].series.map((item) => item.name)),
        ];
        for (let i = 0; i < years.length; i++) {
          temp4[0].series.push({
            name: years[i],
            value: 0,
          });
          temp4[1].series.push({
            name: years[i],
            value: 0,
          });
          temp4[2].series.push({
            name: years[i],
            value: 0,
          });
          temp4[3].series.push({
            name: years[i],
            value: 0,
          });
          for (let j = 0; j < temp3[0].series.length; j++) {
            if (years[i] == temp3[0].series[j].name) {
              temp4[0].series[i].value = temp4[0].series[i].value + temp3[0].series[j].value;
              temp4[1].series[i].value = temp4[1].series[i].value + temp3[1].series[j].value;
              temp4[2].series[i].value = temp4[2].series[i].value + temp3[2].series[j].value;
              temp4[3].series[i].value = temp4[3].series[i].value + temp3[3].series[j].value;
            }
          }
        }

        this.lineChartCalls = temp4;
      } else if (allDates.length > 30) {
        // console.log("Week View");
        for (let i = allDates.length - 1; i > 0; i--) {
          for (let j = i - 1; j > i - 7; j--) {
            allDates[j] = allDates[i];
          }
          i = i - 6;
        }

        for (let i = 0; i < this.lineChartCalls[0].series.length; i++) {
          this.lineChartCalls[0].series[i].name = allDates[i];
        }

        const dates = [
          ...new Set(this.lineChartCalls[0].series.map((item) => item.name)),
        ];
        let temp3 = this.lineChartCalls;
        let temp4 = [
          { name: "Total", series: [] },
          { name: "Bad", series: [] },
          { name: "Good", series: [] },
          { name: "Fraud", series: [] }
        ];

        for (let i = 0; i < dates.length; i++) {
          temp4[0].series.push({
            name: dates[i],
            value: 0,
          });
          temp4[1].series.push({
            name: dates[i],
            value: 0,
          });
          temp4[2].series.push({
            name: dates[i],
            value: 0,
          });
          temp4[3].series.push({
            name: dates[i],
            value: 0,
          });

          for (let j = 0; j < temp3[0].series.length; j++) {
            if (dates[i] == temp3[0].series[j].name) {
              temp4[0].series[i].value = temp4[0].series[i].value + temp3[0].series[j].value;
              temp4[1].series[i].value = temp4[1].series[i].value + temp3[1].series[j].value;
              temp4[2].series[i].value = temp4[2].series[i].value + temp3[2].series[j].value;
              temp4[3].series[i].value = temp4[3].series[i].value + temp3[3].series[j].value;
            }
          }
        }

        this.lineChartCalls = temp4;
      } else {
        //Empty Condition
      }
    }, (error) => {

    })
  }
}

function multiFormat(value) {
  if (value < 1000) return `${value.toFixed(2)}ms`;
  value /= 1000;
  if (value < 60) return `${value.toFixed(2)}s`;
  value /= 60;
  if (value < 60) return `${value.toFixed(2)}mins`;
  value /= 60;
  return `${value.toFixed(2)}hrs`;
}