<div
  class="simple-notification"
  [@enterLeave]="item.state"
  (click)="onClick($event)"
  [class]="theClass"
  [ngClass]="{
    alert: item.type === 'alert',
    error: item.type === 'error',
    warn: item.type === 'warn',
    success: item.type === 'success',
    info: item.type === 'info',
    bare: item.type === 'bare',
    'rtl-mode': rtl
  }"
  (mouseenter)="onEnter()"
  (mouseleave)="onLeave()"
>
  <div *ngIf="!item.html">
    <div>
      <button (click)="remove()" style="position:fixed; right:5px; top:5px;background:none">
        <img src="../../assets/img/close.svg" style="width:12px">
      </button>
      <div style="margin-top:5px">
        <div class="circle" style="float:left;">
          <img src="../../assets/img/user-profile.svg">
        </div>

        <div class="sn-title" *ngIf="titleIsTemplate; else regularTitle">
          <ng-container *ngTemplateOutlet="title"></ng-container>
        </div>

        <ng-template #regularTitle>
          <div class="sn-title" [innerHTML]="title"></div>
        </ng-template>

        <div class="sn-content" *ngIf="contentIsTemplate; else regularContent">
          <ng-container *ngTemplateOutlet="content"></ng-container>
        </div>

        <ng-template #regularContent>
          <div class="sn-content" [innerHTML]="content"></div>
        </ng-template>

        <!-- <div
          class="icon"
          *ngIf="item.icon !== 'bare'"
          [innerHTML]="safeSvg"
        ></div> -->

        <div style="margin-left:50px;margin-top:5px;float:right"  *ngIf="notificationType!='CRM - Add Event(Calendar)' && notificationType!='CRM - Reschedule Event(Calendar)' && notificationType!='CRM - Cancel Event(Calendar)' && notificationType!='CRM - Schedule Reminder(Calendar)' ">
           <!-- <button *ngIf="saved ==false" class="act-btn-3" (click)="saveNotification(true)"><img src="../../assets/img/bookmark-b.svg"></button>
          <button *ngIf="saved ==true" class="act-btn-3" (click)="saveNotification(false)"><img src="../../assets/img/bookmark-g.svg"></button> -->

          <button class="act-btn-1" (click)="viewNotification()">View</button>
          <!-- <button class="act-btn-2" (click)="deleteNotification()">Delete</button> -->
          <div></div>
          <div *ngIf="item.html">
            <div class="sn-content" [innerHTML]="item.html"></div>
            <div
              class="icon"
              [class.icon-hover]="clickIconToClose"
              *ngIf="item.icon"
              [innerHTML]="safeSvg"
              (click)="onClickIcon($event)"
            ></div>
          </div>

          <!-- <div class="sn-progress-loader" *ngIf="showProgressBar">
            <span [ngStyle]="{ width: progressWidth + '%' }"></span>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</div>
