import {
  Component,
  OnInit,
  ViewChild,
  HostListener,
  ElementRef,
  Inject,
  TemplateRef,
} from "@angular/core";
import { UhLeadsService } from "../uhleads.service";
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule } from "@angular/forms";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import { SharedService } from "src/app/shared/shared.service";
import { DashboardService } from "../dashboard/dashboard.service";
import { LayoutService } from "src/app/layout/layout.service";
import { formatDate } from "@angular/common";
import { MarketingService } from "../marketing/marketing.service";
import * as xlsx from "xlsx";
import { NgbModal, NgbModalOptions, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { AbstractControl, Validators } from "@angular/forms";
import * as moment from "moment";
import { Exceldata2 } from "../marketing/smsmarketing/models/ExecelData.model2";
import { start } from "repl";
import { AuthService } from "src/app/account/auth/auth.service";
import { Router } from "@angular/router";
import { forEach, now, round } from "lodash";
import { CreateAgent } from "../create-agent/create-agent.service";
import {
  countUpTimerConfigModel,
  CountupTimerService,
  timerTexts,
} from "ngx-timer";
import { DOCUMENT } from "@angular/common";
import { environment } from "src/environments/environment";
import { MomentFormatService } from "src/app/moment-format-service";
import { NotificationsService } from "src/app/notification.service";

import { fromEvent } from "rxjs";
import {
  filter,
  debounceTime,
  distinctUntilChanged,
  tap,
} from "rxjs/operators";
import { RoleService } from "../role-management/role.service";
import { ErrorHandlerService } from "src/app/error-handler.service";
import { CardsSetting, ColumnSetting, FormSetting, ModalConfig, RowSetting } from "src/components/customs/layout.model";
import { ModalComponent } from "src/components/customs/modals/modal/modal.component";
import { CardsService } from "src/components/customs/custom-cards/cards.service";
import { MatTabGroup } from "@angular/material/tabs";
import { CustomService } from "src/components/customs/custom.service";
var sortJsonArray = require('node_modules/sort-json-array');
export enum REGEX {
  EMAIL = '[A-Za-z0-9._%-]+@[A-Za-z0-9._%-]+\\.[a-z]{2,4}',
  PHONE = '[0-9]{10}$',
  PASSWORD = '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$',
  ZIP = '^[a-zA-Z0-9,\'-]*$',
  NUMBER = '^[0-9]*$',
  URL = '^(http:\/\/www\.|https:\/\/www\.|www\.|http:\/\/|https:\/\/)[a-zA-Z0-9\.\-]+\\.[a-zA-Z]{2,5}[\.]{0,1}',
  CHARACTER = '^[a-zA-Z \-\']+',
  SPECIAL_PHONE = '^[+|0-9]+[0-9]{1,}$',
  ADDRESS = '^[a-zA-Z0-9\s,\'-]*$',
}


@Component({
  selector: "app-accessqueleads",
  templateUrl: "./accessqueleads.component.html",
  styleUrls: ["./accessqueleads.component.css"],
})
export class AccessqueleadsComponent implements OnInit {
  public fileSource = environment.fileServer;
  public userData: any;
  public filterValue = "all";
  nav_position: string = "end";
  public statusValue = "pending";
  public page: any = 1;
  public pageSize: any = 12;
  public search: string;
  totalApplications: number;
  completedApplications: number;
  completedApplications2: number;
  incompleteapplications: number;
  documentspending: number;
  documentssubmitted: number;
  callback: number;
  activityData: any;
  activityData2: any;

  activityTable: any = [];
  activityTable2: any;

  startDate: any;
  endDate: any;
  leadsbody: any;
  companyId: any;
  companyAdmin: Boolean = false;
  rotating: boolean = false;
  projectId: any;
  downloadLink: any;
  public filePath: any;
  public fileData: Exceldata2[] = [];
  public uploaded: boolean = false;
  public error: boolean = false;
  public insertCount: number = 0;
  public leadType: string = "all";
  public errorMessage: string = "";
  public submitted: boolean = false;
  public edited: boolean = false;
  admin: boolean = false;
  public counselling: boolean = false;
  isCallcomming: boolean = false;
  connection: any = null;
  // audio: any;
  audio = new Audio();
  audioStatus: boolean = false;
  sideViewerData: any = { Activities: [], CallDetails: [] };
  documentsList: any;
  documentsList2: any;
  selectedCallerId: any;
  selectedLead: any;
  commentText: string = "";
  newStatus: any;
  docUrl: any;
  currentLeadId: any;
  currentGuid: any;
  documentComments: string;
  validationStatus: boolean = false;
  currentItem: any;
  showdeferredintake: Boolean = false;
  DIyearArray: any = []
  @ViewChild("uploadFile") uploadFile;
  @ViewChild("uploadFile2") uploadFile2;
  @ViewChild("closeButton") closeButton;
  @ViewChild("closeButton2") closeButton2;
  @ViewChild("closeButton3", { static: true }) closeButton3;
  @ViewChild("sideNav", { static: true }) sideNav;
  @ViewChild("inputString", { static: true }) input: ElementRef;
  @ViewChild("totalRecords", { static: true }) totalRecords: ElementRef;
  @ViewChild("tabgroup", { static: true }) tabGroup: MatTabGroup;
  leadIds = [];
  selectedAll: boolean = false;
  agentList: any;
  GBPagentList: any;
  defaultAgent = "default";
  selectedAgent: any;

  testConfig: any;
  // callStarted: boolean = false;
  startCall: boolean = false;
  public stopCall: boolean = false;
  public clearTime: any;
  dialerScreen1 = "dialer";
  phoneNumber: any = "";
  agentCall: String = "";
  isShownMarketing: boolean = false;
  dailerScreen: any = "nocall";
  isCallingProgress: boolean = false;
  isOutgoing: boolean = false;
  isIncoming: boolean = false;
  disabled: any;
  callStatus: string = "Calling..";
  tab: any;
  showDate: boolean = false;

  nextCallerName: any;
  nextCallerCity: any;
  nextCallerNumber: any;
  files: any = [];

  public marketingId: {
    id: string;
    status: string;
    phoneNumber: string;
    email: string;
    name: string;
    module: string;
    city: string;
    nextCaller: any;
    lead: any;
  } = {
      id: "",
      status: "",
      name: "",
      email: "",
      phoneNumber: "",
      module: "",
      city: "",
      nextCaller: null,
      lead: {},
    };

  totalAgents: any;
  agentName: any;

  public callData: any[] = [];
  public startIndex: number = 0;

  date1: any;
  date2: any;
  serializedDate = new FormControl(new Date().toISOString());
  documentsDenied: number;
  CallsPending: number;
  revalidate: any;
  minItems = 5;
  unassigned: any;
  assigned: any;
  private colors = [
    [{ bg: "blueviolet", color: "white" }],
    [{ bg: "AntiqueWhite", color: "white" }],
    [{ bg: "Coral", color: "white" }],
    [{ bg: "Crimson", color: "white" }],
    [{ bg: "DarkOrange", color: "white" }],
    [{ bg: "DeepPink", color: "white" }],
    [{ bg: "#c5924e", color: "white" }],
  ];
  assignedtome: any;
  file: File;
  customDocName: string;
  customDocType: string;
  docUploadError: string;
  docUploadError2: string = "Select files to upload.";
  customFileList: any = [];
  customDocLeadId: any;
  leadtoEdit: {
    LeadId: string;
    FirstName: string;
    LastName: string;
    CountryCode: string;
    PhoneNumber: string;
    Email: string;
    DOB: Date;
    EnrollmentYear: string;
    EnrollmentTerm: string;
    Address: string;
    City: string;
    State: string;
    Zip: string;
    Country: string;
    LeadComment: string;
    CallStatus: string;
    AgentMail: string;
  };
  editComment: string;
  editStatus: string;
  allRecords: any;
  isLoading: boolean = true;
  noRecords: boolean = false;
  selectedFiles: File[] = [];
  docUploadID: any;
  docUploadStatus: boolean = false;
  activityfilterString: string = "";
  uploadSuccess: string = "none";
  topBarCounts = {
    validatedDocs: "0",
    completedDocs: "0",
    studentDenied: "0",
    reopen: "0",
    total: "0",
    revalidatedDocs: "0",
    pendingDocs: "0",
    documentsSubmitted: "0",
    unassigned: "0",
    assigned: "0",
    assignedtoMe: "0",
    uncontactable: "0",
  };
  cardLeadType: string = "all";
  thresItem: any;
  thresId: any;
  thresModal: NgbModalRef;
  pageNumber: Number = 1;
  totalPages: Number = 0;
  currentCard: string = "total";
  sortColumn = "updatedAt";
  sortOrder = "des";
  smsList: [] = [];
  projectLists: any;
  smsText: string;

  DIyear: any;
  DIterms: any;

  addFeedbackModal: NgbModalRef;
  feedbackForm: FormGroup;
  listFeed: any;

  projectSettings: ColumnSetting[] = [];
  projectSettings1: any[] = [];
  formSetting: FormSetting[] = [
    {
      primaryKey: 'name',
      label: 'Name',
      type: 'text',
      formControlName: 'name',
      required: true,
      requiredMsg: 'Name is required',
      patternMsg: 'Only characters are allowed'
    },
    {
      primaryKey: 'email',
      label: 'Email',
      type: 'text',
      formControlName: 'email',
      required: true,
      requiredMsg: 'Email is required.',
      patternMsg: 'Please provide valid email.'
    }
  ];
  rowSetting: RowSetting = {
    records: 12,
    add: false,
    edit: true,
    delete: false,
    view: true,
    call: true
  }
  cardsSettings: CardsSetting[] = [];
  dummycards1: any[] = [];
  // @ViewChild('cardsChangemodal') private cardsChangemodallComponent: ModalComponent;
  cardsChangemodalConfig: ModalConfig =
    {
      modalTitle: 'View/Hide Cards',
      dismissButtonLabel: 'Dismiss',
      closeButtonLabel: 'Close',
      hideDismissButton: true
    };
  topBarCounts12: any;
  @ViewChild('editLead2') editLead2: TemplateRef<any>;
  @ViewChild('totalCallCount') totalCallCount: TemplateRef<any>;
  @ViewChild('staticBackdrop') staticBackdrop: TemplateRef<any>;
  aiFiles: any;

  aiDocMessages: boolean = false;

  constructor(
    public uhleadsservice: UhLeadsService,
    public sharedService: SharedService,
    public dashBoardService: DashboardService,
    public layoutService: LayoutService,
    public marketingService: MarketingService,
    private readonly loginService: AuthService,
    private modalService: NgbModal,
    private authService: AuthService,
    private router: Router,
    private agentService: CreateAgent,
    public countupTimerService: CountupTimerService,
    private momentService: MomentFormatService,
    private NotificationService: NotificationsService,
    private companyService: RoleService,
    private handleError: ErrorHandlerService,
    @Inject(DOCUMENT) private domService: HTMLDocument,
    private fb: FormBuilder,
    private cardsService: CardsService,
    private customService: CustomService
  ) {
    this.testConfig = new countUpTimerConfigModel();
    this.testConfig.timerClass = "test_Timer_class";
    this.testConfig.timerTexts = new timerTexts();
    this.testConfig.timerTexts.hourText = ":"; //default - hh
    this.testConfig.timerTexts.minuteText = ":"; //default - mm
    this.testConfig.timerTexts.secondsText = " ";
    // this.cardsService.cardsSetting.next(this.cardsSettings);
    var tablesList = [
      {
        primaryKey: 'createdAt',
        header: 'Created At',
        format: 'date',
        sortKey: 'createdAt',
        view: true
      },
      {
        primaryKey: 'updatedAt',
        header: 'Updated At',
        format: 'date',
        sortKey: 'updatedAt',
        view: true
      },
      {
        primaryKey: 'name',
        header: 'Name',
        sortKey: 'name',
        view: true
      },
      {
        primaryKey: 'Email',
        header: 'Email',
        sortKey: 'Email',
        view: true
      },
      {
        primaryKey: 'AssignedtoName',
        header: 'Assigned To',
        sortKey: 'AssignedtoName',
        view: true
      },
      {
        primaryKey: 'PhoneNumber',
        header: 'Phone Number',
        sortKey: 'PhoneNumber',
        view: true
      },
      {
        primaryKey: 'Program',
        header: 'Program',
        sortKey: 'Program',
        view: true
      },
      {
        primaryKey: 'EnrollmentTerm',
        header: 'Enrollment Term',
        sortKey: 'EnrollmentTerm',
        view: true
      },
      {
        primaryKey: 'LeadDocStatus',
        header: 'Document status',
        sortKey: 'LeadDocStatus',
        view: true
      },
      {
        primaryKey: 'CallStatus',
        header: 'Call Status',
        sortKey: 'CallStatus',
        view: true
      },
      {
        primaryKey: 'id',
        header: 'Action',
        view: true
      }
    ];
    this.sortReusbaleTable(tablesList);
    for (let i = 0; i < tablesList.length; i++) {
      this.projectSettings1.push(tablesList[i]);
    }
  }
  ngOnInit() {
    var x = document.getElementsByClassName(
      "sideDataViewer"
    ) as HTMLCollectionOf<HTMLElement>;
    for (let i = 0; i < x.length; i++) {
      x[i].style.display = "none";
    }
    this.getAgentNames();
    this.sharedService.pageEvent.emit({
      pageName: "Leads Dashboard",
    });
    this.userData = JSON.parse(localStorage.getItem("userData"));
    console.log("USer Data: ", this.userData);
    this.date1 = new FormControl(new Date());
    this.date2 = new FormControl(new Date(Date.now() - 864e5));
    this.filterValue == "24hrs";
    var today = new Date();
    this.endDate = new Date(Date.now());
    this.startDate = new Date(Date.now() - 864e5 * 365);

    this.startDate = this.momentService.dashboardDateFormat(
      new Date(new Date(Date.now() - 864e5 * 365)).getTime()
    );
    this.endDate = this.momentService.dashboardDateFormat(
      new Date(new Date(Date.now() + 864e5))
    );

    this.companyService.projectAdmin(this.companyId).subscribe((projects) => {
      this.projectId = projects.filter((x) => x.Name == "Accessque")[0].id;
      // this.projectId = 2;
      if (this.projectId != null) {
        this.listFeedback();
        this.sharedService.getProjectAdmin(this.projectId).subscribe((data) => {
          this.admin = data;
          // this.getTopBarCRM();
          this.getTopBarCRMNew();
          this.getCardCount(
            {
              admin: this.admin,
              mail: this.userData.mail,
              ProjectId: this.projectId,
              leadType: "all",
              start: this.startDate,
              end: this.endDate,
            },
            "all"
          );
          // this.getCounts(
          //   this.userData,
          //   "all",
          //   this.startDate,
          //   this.endDate,
          //   "all"
          // );
          this.getAllData(
            this.userData,
            "all",
            new Date(Date.now() - 864e5 * 365),
            new Date(Date.now())
          );

          // this.uhleadsservice.getGBPAgentList().subscribe((res: any)=>{
          //   console.log("GBP agents: ", JSON.stringify(res));
          //   this.GBPagentList = res;

          // })

          // this.getData(this.userData, this.statusValue, this.startDate, this.endDate);
          this.showTotalApplications(); //Shohw only pending records on page  load
          this.onClick(1); //Activating pending card by default

          this.uhleadsservice
            .getAgentList(this.projectId, this.userData.id)
            .subscribe((data: any) => {
              this.agentList = data;
              // for (let i = 0; i < this.agentList.length; i++) {
              //   if (this.agentList[i].admin == true) {
              //     this.agentList.pop(i);
              //   }
              // }
            }, (error) => {
              this.handleError.handleError(error)
            });

          ////

          this.getDownloadLink();
          this.uhleadsservice.startTimer.subscribe((res) => {
            this.callStatus = "inProgress";
            this.startTimer();
          });

          this.uhleadsservice.viewReceivedLead.subscribe((res: any) => {
            if (res) {
              this.viewLead(res);
            }
          });

          this.customService.pageNumber.subscribe((pageNo: any) => {
            this.pageNumber = pageNo;
            if (this.leadType != "filter") {
              // this.getTopBarCRM();
              this.getCardCount(
                {
                  admin: this.admin,
                  mail: this.userData.mail,
                  ProjectId: this.projectId,
                  leadType: "all",
                  start: this.startDate,
                  end: this.endDate,
                },
                "all"
              );
            } else if (this.leadType == "filter") {
              // this.searchLeads(this.search, this.pageNumber)

              let payload = {
                admin: this.admin,
                ProjectId: this.projectId,
                start: "2021-08-02 09:40:25+00",
                end: "2022-08-03 09:40:25+00",
                page: this.pageNumber,
                leadType: "All",
                mail: "All",
                callStatus: "All",
                course: "All",
                enrollmentYear: "All",
                enrollmentTerm: "All",
                export: false,
                search: this.search,
              };
              if (this.search.length > 0) {
                this.uhleadsservice
                  .GetSearchDashboardLeads(payload)
                  .subscribe((res: any) => {
                    var test1 = res.data;
                    var test2 = test1.map(obj => ({ ...obj, name: obj.FirstName + " " + obj.LastName }));
                    this.activityTable = test2;
                  }, (error) => {
                    this.handleError.handleError(error)
                  });
              } else {
                // this.totalRecords.nativeElement.click();
                this.leadType = "all";
              }
            }
          });
        }, (error) => {
          this.handleError.handleError(error)
        });
      } else {
        this.admin = false;
      }
    }, (error) => {
      this.handleError.handleError(error)
    });

    // server-side search
    fromEvent(this.input.nativeElement, "keyup")
      .pipe(
        filter(Boolean),
        debounceTime(1500),
        distinctUntilChanged(),
        tap((event: KeyboardEvent) => {
          this.search = this.input.nativeElement.value;
          this.pageNumber = 1;
          // console.log(event);
          // console.log(this.input.nativeElement.value);
          this.searchLeads(this.input.nativeElement.value, this.pageNumber);
        })
      )
      .subscribe();

    //incoming sms check
    this.uhleadsservice.incomingSms.subscribe((from: any) => {
      console.log("from::::", from)
      if (from.from) {
        this.uhleadsservice.getSms(this.currentLeadId).subscribe((smsList: any) => {
          console.log("Received an incoming sms and refreshed the list");

        })
      }
    })
    let d = new Date();
    this.DIyearArray.push(d.getFullYear())
    let year = d.getFullYear()
    for (let i = 0; i < 4; i++) {
      year = year + 1
      this.DIyearArray.push(year)
    }

  }
  startTimer() {
    let cdate = new Date();
    cdate.setHours(cdate.getHours());
    this.countupTimerService.startTimer(cdate);
  }

  onClick(check) {
    if (check == 1) {
      this.tab = "tab1";
    } else if (check == 2) {
      this.tab = "tab2";
    } else if (check == 3) {
      this.tab = "tab3";
    } else if (check == 4) {
      this.tab = "tab4";
    } else if (check == 5) {
      this.tab = "tab5";
    } else if (check == 6) {
      this.tab = "tab6";
    } else if (check == 7) {
      this.tab = "tab7";
    } else if (check == 8) {
      this.tab = "tab8";
    } else if (check == 9) {
      this.tab = "tab9";
    } else if (check == 10) {
      this.tab = "tab10";
    } else if (check == 11) {
      this.tab = "tab11";
    } else if (check == 12) {
      this.tab = "tab12";
    } else if (check == 13) {
      this.tab = "tab13";
    } else {
      this.tab = null;
    }
  }

  selectAllforAssignment() {
    console.log("assign all---------------------")
    if (this.selectedAll == false) {
      this.selectedAll = true;
      this.leadIds = [];
      for (let i = 0; i < this.activityTable.length; i++) {
        this.leadIds.push(this.activityTable[i].id);
      }
    } else if (this.selectedAll == true) {
      this.selectedAll = false;
      this.leadIds = [];
    }
  }

  selectAgentforAssignment(e, item) {
    if (e.target.checked) {
      this.leadIds.push(item.id);
    } else if (!e.target.checked) {
      this.removeItem(this.leadIds, item.id);
    }
  }

  assignAgentModal() {
    this.defaultAgent = "default";
    this.selectedAgent = null;
  }

  agentSelected(selectedAgent) {
    this.selectedAgent = selectedAgent;
  }

  assignLEads() {
    if (this.leadIds.length == 0) {
      this.uhleadsservice.showWarning("Select leads to be assigned", "Alert!");
      return;
    }
    if (this.selectedAgent) {
      this.uhleadsservice
        .assignLeads({
          AssignedTo: this.selectedAgent,
          InsertedBy: this.userData.mail,
          LeadIds: this.leadIds,
        })
        .subscribe((res) => {
          let payload = {
            AgentMail: this.userData.mail,
            GBPAssignedTo: this.selectedAgent,
            GUID: "",
            LeadId: this.leadIds,
            Platform: "CRM",
            StudentDocId: 0,
            Title: "Assigned",
            description:
              "New leads are assigned to you by " +
              this.getAgentName(this.userData.mail),
            notificationType: "AssignedToAgent",
            token: this.userData.token,
          };
          this.NotificationService.sendNotifications(payload).subscribe(
            (res: any) => {
              if (res.status === 200) {
              }
            }, (error) => {
              this.handleError.handleError(error)
            });
          this.uhleadsservice.showSuccess(
            "Successfully assigned the agents",
            "Success"
          );
          // this.getDashboardLeads();
          // this.getData(this.userData, "all", this.startDate, this.endDate);
          this.getTopBarCRMNew();
          this.getCardData(
            this.userData,
            "total"
          );
          this.selectedAll = false;
          this.closeButton.nativeElement.click();
          document.getElementById("closeAssignButton").click();
        }, (error) => {
          this.handleError.handleError(error)
        });
    } else {
      this.uhleadsservice.showWarning("Please select an agent", "Alert!");
    }
  }

  removeItem(arr, value) {
    var index = arr.indexOf(value);
    if (index > -1) {
      arr.splice(index, 1);
    }
    return arr;
  }

  setLeadType() {
    this.leadType = "";
  }

  showTotalApplications() {
    this.statusValue = "all";
    this.leadType = "all";
    this.currentCard = "total";
    // this.getCounts(this.userData, "all", this.startDate, this.endDate);
    // this.getCounts(this.userData, "all", this.startDate, this.endDate, "all");
    this.getTopBarCRMNew();
    this.getCardCount(
      {
        admin: this.admin,
        mail: this.userData.mail,
        ProjectId: this.projectId,
        leadType: "all",
        start: this.startDate,
        end: this.endDate,
      },
      "all"
    );
  }

  //Shows date if the filter value is custom
  hideDate() {
    // this.filterValuev
    if (this.filterValue == "custom" && this.showDate == false) {
      this.showDate = true;
    }
  }

  showCompletedApplications() {
    this.statusValue = "all";
    this.leadType = "validatedDocs";
    this.currentCard = "validated";
    this.getTopBarCRMNew();
    this.getCardCount(
      {
        admin: this.admin,
        mail: this.userData.mail,
        ProjectId: this.projectId,
        leadType: "validatedDocs",
        start: this.startDate,
        end: this.endDate,
      },
      "validatedDocs"
    );
  }
  showUncontactable() {
    this.statusValue = "all";
    this.leadType = "uncontactable";
    this.currentCard = "uncontactable";

    this.getTopBarCRMNew();
    this.getCardCount(
      {
        admin: this.admin,
        mail: this.userData.mail,
        ProjectId: this.projectId,
        leadType: "uncontactable",
        start: this.startDate,
        end: this.endDate,
      },
      "uncontactable"
    );
  }
  showCompletedApplications2() {
    this.statusValue = "all";
    this.leadType = "completedDocs";
    this.currentCard = "completed";
    // this.getCounts(
    //   this.userData,
    //   "all",
    //   this.startDate,
    //   this.endDate,
    //   "completedDocuments"
    // );
    this.getTopBarCRMNew();
    this.getCardCount(
      {
        admin: this.admin,
        mail: this.userData.mail,
        ProjectId: this.projectId,
        leadType: "completedDocs",
        start: this.startDate,
        end: this.endDate,
      },
      "completedDocs"
    );
  }

  showIncompleteApplications() {
    this.statusValue = "all";
    this.leadType = "pendingDocs";
    // this.getCounts(
    //   this.userData,
    //   "all",
    //   this.startDate,
    //   this.endDate,
    //   "ValidationPending"
    // );
    this.getTopBarCRMNew();
    this.getCardCount(
      {
        admin: this.admin,
        mail: this.userData.mail,
        ProjectId: this.projectId,
        leadType: "pendingDocs",
        start: this.startDate,
        end: this.endDate,
      },
      "pendingDocs"
    );
  }
  showDocumentsPending() {
    this.statusValue = "all";
    this.leadType = "pendingDocs";
    this.currentCard = "updated";
    // this.getCounts(
    //   this.userData,
    //   "all",
    //   this.startDate,
    //   this.endDate,
    //   "documentsPending"
    // );
    this.getTopBarCRMNew();
    this.getCardCount(
      {
        admin: this.admin,
        mail: this.userData.mail,
        ProjectId: this.projectId,
        leadType: "pendingDocs",
        start: this.startDate,
        end: this.endDate,
      },
      "pendingDocs"
    );
  }
  showDocumentsSubmitted() {
    this.statusValue = "all";
    this.leadType = "documentsSubmitted";
    this.currentCard = "updated";
    // this.getCounts(
    //   this.userData,
    //   "all",
    //   this.startDate,
    //   this.endDate,
    //   "documentsSubmitted"
    // );
    this.getTopBarCRMNew();
    this.getCardCount(
      {
        admin: this.admin,
        mail: this.userData.mail,
        ProjectId: this.projectId,
        leadType: "documentsSubmitted",
        start: this.startDate,
        end: this.endDate,
      },
      "documentsSubmitted"
    );
  }
  showCallback() {
    this.statusValue = "all";
    this.leadType = "callback";
    // this.getCounts(
    //   this.userData,
    //   "all",
    //   this.startDate,
    //   this.endDate,
    //   "callback"
    // );
    this.getTopBarCRMNew();
    this.getCardCount(
      {
        admin: this.admin,
        mail: this.userData.mail,
        ProjectId: this.projectId,
        leadType: "callback",
        start: this.startDate,
        end: this.endDate,
      },
      "callback"
    );
  }
  showCallsPending() {
    this.statusValue = "all";
    this.leadType = "pending";
    // this.getCounts(
    //   this.userData,
    //   "all",
    //   this.startDate,
    //   this.endDate,
    //   "pendingCalls"
    // );
    this.getTopBarCRMNew();
    this.getCardCount(
      {
        admin: this.admin,
        mail: this.userData.mail,
        ProjectId: this.projectId,
        leadType: "pending",
        start: this.startDate,
        end: this.endDate,
      },
      "pending"
    );
  }

  showRevalidate() {
    this.statusValue = "all";
    this.leadType = "revalidatedDocs";
    this.currentCard = "revalidate";
    // this.getCounts(
    //   this.userData,
    //   "all",
    //   this.startDate,
    //   this.endDate,
    //   "revalidate"
    // );
    this.getTopBarCRMNew();
    this.getCardCount(
      {
        admin: this.admin,
        mail: this.userData.mail,
        ProjectId: this.projectId,
        leadType: "revalidatedDocs",
        start: this.startDate,
        end: this.endDate,
      },
      "revalidatedDocs"
    );
  }

  showApplicationsDenied() {
    this.statusValue = "all";
    this.leadType = "studentDenied";
    this.currentCard = "denied";
    // this.getCounts(
    //   this.userData,
    //   "all",
    //   this.startDate,
    //   this.endDate,
    //   "denied"
    // );
    this.getTopBarCRMNew();
    this.getCardCount(
      {
        admin: this.admin,
        mail: this.userData.mail,
        ProjectId: this.projectId,
        leadType: "studentDenied",
        start: this.startDate,
        end: this.endDate,
      },
      "studentDenied"
    );
  }

  showUnassigned() {
    this.statusValue = "all";
    this.leadType = "unassigned";
    this.currentCard = "total";
    // this.getCounts(
    //   this.userData,
    //   "all",
    //   this.startDate,
    //   this.endDate,
    //   "unassigned"
    // );
    this.getTopBarCRMNew();
    this.getCardCount(
      {
        admin: this.admin,
        mail: this.userData.mail,
        ProjectId: this.projectId,
        leadType: "unassigned",
        start: this.startDate,
        end: this.endDate,
      },
      "unassigned"
    );
  }

  showAssigned() {
    this.statusValue = "all";
    this.leadType = "assigned";
    this.currentCard = "assigned";
    this.getTopBarCRMNew();
    this.getCardCount(
      {
        admin: this.admin,
        mail: this.userData.mail,
        ProjectId: this.projectId,
        leadType: "assigned",
        start: this.startDate,
        end: this.endDate,
      },
      "assigned"
    );
  }

  showAssignedtoMe() {
    this.statusValue = "all";
    this.leadType = "assignedtoMe";
    this.currentCard = "assigned";
    // this.getCounts(
    //   this.userData,
    //   "all",
    //   this.startDate,
    //   this.endDate,
    //   "assignedtome"
    // );
    var obj = {
      admin: this.admin,
      mail: this.userData.mail,
      ProjectId: this.projectId,
      leadType: "assignedtoMe",
      start: this.startDate,
      end: this.endDate,
    };
    this.getTopBarCRMNew();
    this.getCardCount(obj, "assignedtoMe");
  }

  public onInput(event: any) {
    let payload = {
      admin: this.admin,
      ProjectId: this.projectId,
      start: "2021-08-02 09:40:25+00",
      end: "2022-08-03 09:40:25+00",
      page: 1,
      leadType: "All",
      mail: "All",
      callStatus: "All",
      course: "All",
      enrollmentYear: "All",
      enrollmentTerm: "All",
      export: false,
      search: this.search,
    };
    if (this.search != "") {
    } else if (this.search == "") {
      this.totalRecords.nativeElement.click();
    }
  }

  searchLeads(value, page) {
    let payload = {
      admin: this.admin,
      ProjectId: this.projectId,
      start: "2021-08-02 09:40:25+00",
      end: "2022-08-03 09:40:25+00",
      page: page,
      leadType: "All",
      mail: "All",
      callStatus: "All",
      course: "All",
      enrollmentYear: "All",
      enrollmentTerm: "All",
      export: false,
      search: value,
    };

    if (value.length > 0) {
      this.leadType = "filter";
      this.uhleadsservice
        .GetSearchDashboardLeads(payload)
        .subscribe((res: any) => {
          // console.log("Search Results: ",res);
          var test1 = res.data;
          var test2 = test1.map(obj => ({ ...obj, name: obj.FirstName + " " + obj.LastName }));
          this.activityTable = test2;
          // this.activityTable = res.data;

          let newTotal = ~~(Number(res.totalResults) / 12) + 1;
          this.customService.totalPages.next(Number(newTotal));
        }, (error) => {
          this.handleError.handleError(error)
        });
    } else {
      this.leadType = "all";
      this.getTopBarCRMNew();
      this.getCardData(this.userData, "total");
    }
  }

  //Table Filter
  dropdownSelect(event) {
    this.onClick(null);
    console.log("callstatus filter: ", event);
    this.leadType = event;
    this.getTopBarCRMNew();
    this.getCardData(this.userData, event);
  }

  updateDateFilter(event: any) {
    if (event.target.value != "custom") {
      this.endDate = this.momentService.dashboardDateFormat(
        new Date(new Date(Date.now())).getTime()
      );
    }
    if (event.target.value == "custom") {
      //show custom date filter
      this.showDate = true;
    } else if (event.target.value == "all") {
      this.startDate = this.momentService.dashboardDateFormat(
        new Date(new Date(Date.now() - 864e5 * 365)).getTime()
      );

      this.showDate = false;
    } else if (event.target.value == "24hrs") {
      this.startDate = this.momentService.dashboardDateFormat(
        new Date(new Date(Date.now() - 864e5)).getTime()
      );

      this.showDate = false;
    } else if (event.target.value == "72hrs") {
      this.startDate = this.momentService.dashboardDateFormat(
        new Date(new Date(Date.now() - 864e5 * 3)).getTime()
      );

      this.showDate = false;
    } else if (event.target.value == "7days") {
      this.startDate = this.momentService.dashboardDateFormat(
        new Date(new Date(Date.now() - 864e5 * 7)).getTime()
      );

      this.showDate = false;
    } else if (event.target.value == "14days") {
      this.startDate = this.momentService.dashboardDateFormat(
        new Date(new Date(Date.now() - 864e5 * 14)).getTime()
      );

      this.showDate = false;
    } else if (event.target.value == "30days") {
      this.startDate = this.momentService.dashboardDateFormat(
        new Date(new Date(Date.now() - 864e5 * 30)).getTime()
      );

      this.showDate = false;
    }
    // this.getCounts(this.userData, "all", this.startDate, this.endDate, "all");
    this.getTopBarCRMNew();
    this.getCardCount(
      {
        admin: this.admin,
        mail: this.userData.mail,
        ProjectId: this.projectId,
        leadType: this.cardLeadType,
        start: this.startDate,
        end: this.endDate,
      },
      this.cardLeadType
    );

    // this.getData(this.userData, "all", this.startDate, this.endDate);
    this.getTopBarCRMNew();
    this.getCardData(this.userData, "total");
  }

  updateActivity() {
    // this.getData(this.userData, "all", this.startDate, this.endDate);
    this.getTopBarCRMNew();
    this.getCardData(this.userData, "total");
    this.totalApplications = this.activityTable.length;
    this.completedApplications = this.activityTable.filter(function (el) {
      return el.LeadDocStatus == "validated" || el.LeadDocStatus == "Validated";
    }).length;
    this.completedApplications2 = this.activityTable.filter(function (el) {
      return el.LeadDocStatus == "completed" || el.LeadDocStatus == "complete";
    }).length;
    this.incompleteapplications = this.activityTable.filter(function (el) {
      return el.APPStatus == "Incomplete";
    }).length;
    this.documentspending = this.activityTable.filter(function (el) {
      return el.Phase == "pending";
    }).length;
    this.documentssubmitted = this.activityTable.filter(function (el) {
      return el.Phase == "complete" || el.APPStatus == "complete";
    }).length;
    this.callback = this.activityTable.filter(function (el) {
      return el.Phase == "callback" || el.Phase == "Callback";
    }).length;
  }

  getStartDate(type: string, event: MatDatepickerInputEvent<Date>) {
    this.startDate = event.value;
    this.startDate = this.momentService.dashboardDateFormat(
      new Date(this.startDate).getTime
    );
  }
  getEndDate(type: string, event: MatDatepickerInputEvent<Date>) {
    this.endDate = event.value;
    this.endDate = this.momentService.dashboardDateFormat(
      new Date(this.endDate).getTime
    );
  }

  getDownloadLink() {
    this.marketingService
      .getExcelLink(this.fileSource + "/TemplateDocuments/accessque.xlsx")
      .subscribe((data) => {
        this.downloadLink = data["url"];
      }, (error) => {
        this.handleError.handleError(error)
      });
  }

  convertFile(e) {
    this.filePath = e.target.files[0]["name"].toString();
    let reader = new FileReader();
    reader.readAsArrayBuffer(e.target.files[0]);
    reader.onload = (eve) => {
      let data = new Uint8Array(reader.result as any);
      let wb = xlsx.read(data, { type: "array" });
      let sheet_name = wb.SheetNames;
      let sheet_data: any[][] = xlsx.utils.sheet_to_json(
        wb.Sheets[sheet_name[0]],
        { header: 1 }
      );
      if (sheet_data[0][0] === "GUID" && sheet_data.length !== 0) {
        this.fileData = this.uhleadsservice.convert2dArraytoObject(sheet_data);
        this.uhleadsservice
          .postCallData({
            data: this.fileData,
            createdBy: this.userData["mail"],
            ProjectId: this.projectId,
            addType: 'Excel'
          })
          .subscribe((data) => {
            this.uploaded = true;
            this.error = false;
            this.insertCount = data["count"];
            setTimeout(() => {
              this.uploaded = false;
              this.filePath = "";
            }, 5000);
            // this.getDashboardLeads(this.leadType);
            // this.getData(this.userData, "all", this.startDate, this.endDate);
            this.getTopBarCRMNew();
            this.getCardData(
              this.userData,
              "total"
            );
          }, (error) => {
            this.handleError.handleError(error)
          });
      } else {
        this.error = true;
        this.errorMessage = "Please Upload a Valid File";
      }
    };
    this.uploadFile.nativeElement.value = "";
  }

  get createLeads(): { [key: string]: AbstractControl } {
    return this.uhleadsservice.createLead.controls;
  }
  get editLeads(): { [key: string]: AbstractControl } {
    return this.uhleadsservice.editLead.controls;
  }
  initCreateLead() {
    this.uhleadsservice.createLead.setValue({
      firstName: "",
      lastName: "",
      countryCode: "",
      form_name: "",
      phoneNumber: "",
      email: "",
      channel: "",
      assignedTo: this.userData ? this.userData["mail"] : "",
      module: "LeadsMarketing",
      Miscellaneous: ""
    })
  }

  createLead() {
    this.submitted = true;
    console.log(this.uhleadsservice.createLead)
    if (this.uhleadsservice.createLead.invalid) {
      return;
    }
    let fileData: Exceldata2[] = [
      new Exceldata2(
        this.uhleadsservice.createLeadValue["GUID"],
        this.uhleadsservice.createLeadValue["firstName"],
        this.uhleadsservice.createLeadValue["lastName"],
        this.uhleadsservice.createLeadValue["countryCode"],
        this.uhleadsservice.createLeadValue["phoneNumber"],
        this.uhleadsservice.createLeadValue["email"],
        // this.userData.createLeadValue["assignedTo"],
        '',
        this.uhleadsservice.createLeadValue["program"],
        this.uhleadsservice.createLeadValue["applicationType"],
        this.uhleadsservice.createLeadValue["CreatedDate"],
        this.uhleadsservice.createLeadValue["comments"]
      ),
    ];
    console.log(fileData)
    this.uhleadsservice
      .postCallData({
        data: fileData,
        createdBy: this.userData["mail"],
        ProjectId: this.projectId,
        addType: 'Manual'
      })
      .subscribe((data) => {
        // this.marketingService.resetCreateLead();
        this.getTopBarCRMNew();
        this.getCardData(
          this.userData,
          "total"
        );
        this.submitted = false;
        this.modalService.dismissAll();
        this.initCreateLead();
      }, (error) => {
        this.handleError.handleError(error)
      });
  }

  getDashboardLeads(leadType) {
    if (!this.projectId) {
      return;
    }
    // this.statusValue = "pending";
    this.leadType = leadType;
    return this.marketingService
      .getDashboardLeads({
        admin: this.admin,
        // admin: true,
        mail: this.userData["mail"],
        ProjectId: this.projectId,
        leadType: this.leadType,
        counselling: this.counselling,
      })
      .subscribe((data) => {
        this.callData = data;
      }, (error) => {
        this.handleError.handleError(error)
      });
  }

  //Date Filter
  filterData() {
    this.onClick(null);
    this.startDate = this.momentService.dashboardDateFormat(
      new Date(this.date2.value).getTime()
    );
    this.endDate = this.momentService.dashboardDateFormat(
      new Date(this.date1.value).getTime()
    );

    if (this.endDate < this.startDate) {
      // window.alert("Choose the right date format!");
      this.uhleadsservice.showWarning(
        "End date cannot be less than Start date!",
        "Wrong Date!"
      );
      return;
    } else {
      this.getTopBarCRMNew();
      this.getCardData(this.userData, "total");
      this.getTopBarCRMNew();
      this.getCardCount(
        {
          admin: this.admin,
          mail: this.userData.mail,
          ProjectId: this.projectId,
          leadType: this.cardLeadType,
          start: this.startDate,
          end: this.endDate,
        },
        this.cardLeadType
      );
      this.showDate = false;
    }
  }

  getData(userData: any, leadtype, startDate, endDate) {
    this.leadIds = [];
    this.isLoading = true;
    this.noRecords = false;
    this.search = "";
    this.onClick(null);
    this.activityTable = [];

    this.leadsbody = {
      admin: this.admin,
      // admin: true,
      mail: userData.mail,
      ProjectId: this.projectId,
      leadType: leadtype,
      start: this.startDate,
      end: this.endDate,
    };

    this.uhleadsservice.getActivityTable2(this.leadsbody).subscribe((res) => {
      this.activityTable2 = res;

      this.activityTable2.forEach(function (a) {
        Object.keys(a).map((key) => {
          a[key] === null ? (a[key] = "-") : a[key];
        });
      });
      console.log("Activity Table: ", this.activityTable2);

      for (let i = 0; i < this.activityTable2.length; i++) {
        if (this.activityTable2[i].PhoneNumber == "0000000000") {
          this.activityTable2[i].CountryCode = "-";
          this.activityTable2[i].PhoneNumber = "-";
        }
        this.activityTable2[i].AssignedtoName = this.getAgentName(
          this.activityTable2[i].Assignedto
        );

        this.activityTable2[i].AssignedtoInitial = this.getAgentInitial(
          this.activityTable2[i].Assignedto
        );
        this.activityTable2[i].AssignedtoColor = this.getAgentColor(
          this.activityTable2[i].Assignedto
        );
        this.activityTable2[i].AssignedtoImageURL = this.getAgentphoto(
          this.activityTable2[i].Assignedto
        );
        if (
          this.activityTable2[i].LeadDocStatus == "CRM-Revalidate" ||
          this.activityTable2[i].LeadDocStatus == "Admin-Revalidate" ||
          this.activityTable2[i].LeadDocStatus == "Admin-revalidate" ||
          this.activityTable2[i].LeadDocStatus == "CRM-revalidate"
        ) {
          this.activityTable2[i].LeadDocStatus = "Revalidate";
        }
      }
      var test1 = this.activityTable2;
      var test2 = test1.map(obj => ({ ...obj, name: obj.FirstName + " " + obj.LastName }));
      this.activityTable = test2;
      // this.activityTable = this.activityTable2;
      this.getAllData(
        this.userData,
        "all",
        new Date(Date.now() - 864e5 * 365),
        new Date(Date.now())
      );
      // this.getAgentName()
      this.callData = this.activityTable;
      this.isLoading = false;
      if (this.activityTable.length == 0) {
        this.noRecords = true;
      } else {
        this.noRecords = false;
      }
    }, (error) => {
      this.handleError.handleError(error)
    });
  }

  getAllData(userData: any, leadtype, startDate, endDate) {
    this.leadsbody = {
      admin: this.admin,
      // admin: true,
      mail: userData.mail,
      ProjectId: this.projectId,
      leadType: leadtype,
      start: this.startDate,
      end: this.endDate,
      page: this.pageNumber,
    };

    this.uhleadsservice
      .getCardLeadByType(this.leadsbody)
      .subscribe((res: any) => {
        for (let i = 0; i < res.length; i++) {
          if (res[i].PhoneNumber == "0000000000") {
            res[i].CountryCode = "-";
            res[i].PhoneNumber = "-";
          }
          res[i].AssignedtoName = this.getAgentName(res[i].Assignedto);

          res[i].AssignedtoInitial = this.getAgentInitial(res[i].Assignedto);
          res[i].AssignedtoColor = this.getAgentColor(res[i].Assignedto);
          res[i].AssignedtoImageURL = this.getAgentphoto(res[i].Assignedto);
          if (
            res[i].LeadDocStatus == "CRM-Revalidate" ||
            res[i].LeadDocStatus == "Admin-Revalidate" ||
            res[i].LeadDocStatus == "Admin-revalidate" ||
            res[i].LeadDocStatus == "CRM-revalidate"
          ) {
            res[i].LeadDocStatus = "Revalidate";
          }
        }
        this.allRecords = res;
      }, (error) => {
        this.handleError.handleError(error)
      });
  }

  getCallData() { }

  startCallListener() {
    this.dialerScreen1 = "outgoing1";
    this.callStatus = "Calling..";

    this.callData = this.activityTable;

    this.uhleadsservice
      .getDocumentDetails(this.activityTable[0].GUID)
      .subscribe((res) => {
        this.documentsList = res;
        this.documentsList = this.documentsList.uploadDocsStatus;
      }, (error) => {
        this.handleError.handleError(error)
      });

    if (this.callData.length === 0) {
      this.startCall = false;
      return;
    }

    if (this.statusValue === "pending") {
      this.startIndex = 0;
    }
    this.marketingId = {
      id: this.callData[this.startIndex]["id"],
      status: this.callData[this.startIndex]["CallStatus"],
      name: this.callData[this.startIndex]["FirstName"],
      email: this.callData[this.startIndex]["Email"],
      phoneNumber:
        "+" +
        this.callData[this.startIndex]["CountryCode"] +
        this.callData[this.startIndex]["PhoneNumber"],
      // phoneNumber: "+919848311098",
      module: this.callData[this.startIndex]["Module"],
      city: this.callData[this.startIndex]["City"],
      nextCaller: this.callData[this.startIndex + 1],
      lead: this.callData[this.startIndex],
    };

    this.nextCallerName = this.callData[this.startIndex + 1].FirstName;
    this.nextCallerCity = this.callData[this.startIndex + 1].City;
    this.nextCallerNumber =
      "+" +
      this.callData[this.startIndex + 1].CountryCode +
      this.callData[this.startIndex + 1].PhoneNumber;
    this.startIndex = +1;

    this.uhleadsservice.startCall.next({
      phoneNumber: this.marketingId.phoneNumber,
      userData: this.userData,
      leadData: this.marketingId,
    });

    this.startCall = true;
  }

  startCallListener2(data, index) {
    console.log(index);
    this.showdeferredintake = false
    this.DIyear = null
    this.DIterms = null

    this.dialerScreen1 = "outgoing1";
    this.callStatus = "Calling..";
    $("input[name=option1]").prop("checked", false);

    this.newStatus = false;

    this.callData[0] = data;
    this.selectedLead = data;

    this.uhleadsservice.getDocumentDetails(data.GUID).subscribe((res) => {
      this.documentsList = res;
      this.documentsList = this.documentsList.uploadDocsStatus;
    }, (error) => {
      this.handleError.handleError(error)
    });
    this.marketingId = {
      id: data.id,
      status: data.CallStatus,
      name: data.FirstName,
      email: data.Email,
      phoneNumber: "+" + data.CountryCode + data.PhoneNumber,
      // phoneNumber: "+919848311098",
      module: data.Module,
      city: data.City,
      nextCaller: 1,
      lead: 0,
    };

    this.nextCallerName = this.activityData[index + 1] ? this.activityData[index + 1].FirstName : '-';
    this.nextCallerCity = this.activityData[index + 1] ? this.activityData[index + 1].City : '-';
    this.nextCallerNumber =
      "+" +
      (this.activityData[index + 1] ? this.activityData[index + 1].CountryCode : '-') +
      (this.activityData[index + 1] ? this.activityData[index + 1].PhoneNumber : '-');
    this.startIndex = index + 1;

    this.uhleadsservice.startCall.next({
      phoneNumber: this.marketingId.phoneNumber,
      leadData: this.marketingId,
    });
    this.startCall = true;
  }

  //Start Call timer

  // conferenceCall(from) {}

  conferenceCall(value: any) {
    this.uhleadsservice.conferenceCall.next({ value });
  }

  conferenceCall2(value: any) {
    if (value.phone_number) {
      this.marketingService
        .conferenceCallConnect(
          {
            CallSid: this.connection.parameters.CallSid,
            phone: value.phone_number,
            callerId: this.selectedCallerId,
          },
          14
        )
        .subscribe(() => { }, (error) => {
          this.handleError.handleError(error)
        });
    }
    if (value.manager[0]) {
      if (value.manager[0].Name) {
        this.marketingService
          .conferenceCallConnect(
            {
              CallSid: this.connection.parameters.CallSid,
              phone: "client:" + value.manager[0].value,
              callerId: this.selectedCallerId,
            },
            14
          )
          .subscribe(() => { }, (error) => {
            this.handleError.handleError(error)
          });
      }
    }
  }

  updateComments() {
    if (!this.newStatus) {
    } else {
      this.uhleadsservice.getCallSid.subscribe((data) => {
        this.uhleadsservice
          .updateComments2({
            id: this.selectedLead.id,
            comments: this.commentText,
            callStatus: this.newStatus,
            diyear: this.DIyear,
            diterms: this.DIterms,
            CallSid: data,
          })
          .subscribe((res) => {
            this.showdeferredintake = false
            this.DIyear = null
            this.DIterms = null
            // this.getData(this.userData, "all", this.startDate, this.endDate);
            this.getTopBarCRMNew();
            this.getCardData(
              this.userData,
              "total"
            );
          }, (error) => {
            this.handleError.handleError(error)
          });
        this.commentText = "";
        this.closeButton2.nativeElement.click();
      }, (error) => {
        this.handleError.handleError(error)
      });
    }
    this.closeButton2.nativeElement.click();
  }

  resetCallingScreen() {
    this.commentText = "";
    this.newStatus = null;
    this.showdeferredintake = false
    this.DIyear = null
    this.DIterms = null
  }
  onStatusChange(data) {
    this.newStatus = data;
    if (this.newStatus == 'deferredintake') {
      this.showdeferredintake = true;
    }
    else {
      this.showdeferredintake = false
      this.DIyear = null
      this.DIterms = null

    }
  }

  sendSms(item, HTMLid) {
    this.smsText = (<HTMLInputElement>(
      document.getElementById(HTMLid)
    )).value;
    let fromNumber = '';
    this.companyService.projectAdmin(13).subscribe((res: any) => {
      this.projectLists = res;
      console.log(res);
      for (let i = 0; i < res.length; i++) {
        if (res[i].Name == 'Documents') {
          fromNumber = res[i].TwilioNumber;
        }
      }
      let payload = {
        text: this.smsText,
        from: fromNumber,
        to: '+' + item.CountryCode + item.PhoneNumber,
        AgentMail: this.userData.mail
      }
      console.log(payload);
      this.uhleadsservice.sendSms(payload, this.projectId).subscribe((res: any) => {
        this.uhleadsservice.showInfo('Message successfully sent.', 'Sent');
        this.uhleadsservice.getSms(this.currentLeadId).subscribe((smsList: any) => {
          this.smsList = smsList.SMSDetails;
          (<HTMLInputElement>(
            document.getElementById(HTMLid)
          )).value = ''
        })
      })
    })

  }

  sideNavData(data) {
    this.activityfilterString = "";
    // this.files = [];
    this.file = null;
    this.customDocName = "";
    this.minItems = 5;
    var x = document.getElementsByClassName(
      "sideDataViewer"
    ) as HTMLCollectionOf<HTMLElement>;
    //  x.style.display = none;
    for (let i = 0; i < x.length; i++) {
      x[i].style.display = "";
    }
    this.uhleadsservice.getStudentActivity(data.id).subscribe((res) => {
      this.uhleadsservice.getSms(data.id).subscribe((smsList: any) => {
        console.log(smsList);
        this.smsList = smsList.SMSDetails;
      })
      this.sideViewerData = res;
      console.log("Current Lead: ", this.sideViewerData);
      this.currentLeadId = this.sideViewerData.id;
      if (this.customDocLeadId != this.currentLeadId) {
        this.customFileList = [];
        this.customDocLeadId = this.currentLeadId;
      } else {
        this.customDocLeadId = this.currentLeadId;
      }
      this.currentGuid = this.sideViewerData.GUID;
      this.replaceNull(this.sideViewerData);
      this.documentValidation(data.id);
      function replaceNull(a: any) {
        Object.keys(a).map((key) => {
          a[key] === null ? (a[key] = "-") : a[key];
        });
      }
      this.sideViewerData.uploadlistofdocuments.forEach((x) => {
        x.DocComments.forEach((y) => {
          y.AgentColor = this.getAgentColor(y.AgentMail);
        });
      });
      for (
        let i = 0;
        i < this.sideViewerData.uploadlistofdocuments.length;
        i++
      ) {
        this.sideViewerData.LeadComments[i].AgentColor = this.getAgentColor(
          this.sideViewerData.LeadComments[i].AgentMail
        );
      }
    }, (error) => {
      this.handleError.handleError(error)
    });
  }

  replaceNull(a: any) {
    Object.keys(a).map((key) => {
      a[key] === null ? (a[key] = "-") : a[key];
    });
  }

  documentValidation(id) {
    this.uhleadsservice.getDocumentComments(id).subscribe((res) => {
      this.documentsList2 = res;
      this.documentsList2 = this.documentsList2.uploadlistofdocuments;
      console.log("document list", this.documentsList2);

      this.documentsList2.forEach(function (a) {
        Object.keys(a).map((key) => {
          a[key] === null ? (a[key] = "-") : a[key];
        });
      });
      this.checkValidation();
      // for (var i = 0; i < this.documentsList2.length; i++) {
      //   for (var j = 0; j < this.documentsList2[i].DocComments.length; j++) {
      //     for (var k = 0; k < this.totalAgents.length; k++) {
      //       if (
      //         this.totalAgents[k].Mailid ==
      //         this.documentsList2[i].DocComments[j].AgentMail
      //       ) {
      //         this.agentName =
      //           this.totalAgents[k].FirstName +
      //           " " +
      //           this.totalAgents[k].LastName;
      //       }
      //     }
      //     this.documentsList2[i].DocComments[j].AgentMail = this.agentName;
      //   }
      // }
    }, (error) => {
      this.handleError.handleError(error)
    });
  }

  getAgentName(email) {
    let name = "";
    for (var k = 0; k < this.totalAgents.length; k++) {
      if (this.totalAgents[k].Mailid == email) {
        name =
          this.totalAgents[k].FirstName + " " + this.totalAgents[k].LastName;
        return name;
      }
    }
  }
  getAgentInitial(email) {
    let initial = "";
    for (var k = 0; k < this.totalAgents.length; k++) {
      if (this.totalAgents[k].Mailid == email) {
        initial =
          this.totalAgents[k].FirstName.charAt(0) +
          this.totalAgents[k].LastName.charAt(0);
        return initial;
      }
    }
  }

  getAgentColor(email) {
    let color = "";
    for (var k = 0; k < this.totalAgents.length; k++) {
      if (this.totalAgents[k].Mailid == email) {
        color =
          this.totalAgents[k].FirstName.charAt(0) +
          this.totalAgents[k].LastName.charAt(0);

        let randomIndex =
          this.colors[
          this.numberFromText(this.getAgentInitial(email)) %
          this.colors.length
          ];
        color = randomIndex[0]["bg"];
        return color;
      }
    }
  }
  getAgentphoto(email) {
    let imageUrl = "";
    for (var k = 0; k < this.totalAgents.length; k++) {
      if (this.totalAgents[k].Mailid == email) {
        imageUrl = this.totalAgents[k].ProfilePhoto;
        return imageUrl;
      }
    }
  }

  numberFromText(text) {
    const charCodes = text
      .split("") // => ["A", "A"]
      .map((char) => char.charCodeAt(0)) // => [65, 65]
      .join(""); // => "6565"
    return parseInt(charCodes, 10);
  }

  changeValidation(value, docId) {
    this.uhleadsservice
      .updateValidationStatus({
        StudentdocId: docId,
        // admin: this.admin,
        admin: false,
        validationStatus: value,
        Platform: "CRM",
        MailId: this.userData.mail,
      })
      .subscribe((res) => {
        this.documentValidation(this.currentLeadId);
        this.sideNavData({ id: this.currentLeadId });
        this.uhleadsservice.showSuccess("Document Status updated!", "Success!");

        let payload = {
          AgentMail: this.userData.mail,
          GBPAssignedTo: "",
          GUID: "",
          LeadId: 0,
          Platform: "CRM",
          StudentDocId: docId,
          Title: "Document Validation",
          description:
            "Document status changed to " +
            value +
            " by " +
            this.getAgentName(this.userData.mail),
          notificationType: "Validation",
          token: this.userData.token,
        };

        this.NotificationService.sendNotifications(payload).subscribe(
          (res: any) => {
            if (res.status === 200) {
            }
          }
        );
      }, (error) => {
        this.handleError.handleError(error)
      });
  }

  checkValidation() {
    if (this.documentsList2.length == 0) {
      this.validationStatus = false;
      return;
    }
    for (var i = 0; i < this.documentsList2.length; i++) {
      if (
        this.documentsList2[i].AgentValidation == "revalidate" ||
        this.documentsList2[i].AgentValidation == "-"
      ) {
        this.validationStatus = false;
        return;
      } else if (this.documentsList2[i].AgentValidation == "validated") {
        this.validationStatus = true;
        //AddNotification
      } else {
        this.validationStatus = false;
      }
    }
    if (this.validationStatus == true) {
      // Send Notification

      let payload = {
        AgentMail: "ramana@universityhub.com",
        GBPAssignedTo: "",
        GUID: "12356yyu98",
        LeadId: 0,
        Platform: "CRM",
        StudentDocId: 0,
        Title: "Documents Validated",
        description: "All the docuiments have been validated",
        notificationType: "alldocuments",
        token: this.userData.token,
      };

      let payload2 = {
        AgentMail: "ramana@universityhub.com",
        GBPAssignedTo: "",
        GUID: "12356yyu98",
        LeadId: 0,
        Platform: "CRMGBP",
        StudentDocId: 0,
        Title: "Documents Validated",
        description: "All the docuiments have been validated",
        notificationType: "alldocuments",
        token: this.userData.token,
      };

      // this.NotificationService.sendNotifications(payload).subscribe(
      //   (res: any) => {
      //     if (res.status === 200) {
      //       this.uhleadsservice.showSuccess(
      //         "All Documents Validated",
      //         "Validation Complete"
      //       );
      //       this.NotificationService.success("Validation", "Notification sent");
      //     }
      //   }
      // );
      // this.NotificationService.sendNotifications(payload2).subscribe(
      //   (res: any) => {
      //     if (res.status === 200) {
      //       // this.uhleadsservice.showSuccess("All Documents Validated", "Validation Complete");
      //       this.NotificationService.success(
      //         "Validation",
      //         "Notification sent to GBP"
      //       );
      //     }
      //   }
      // );
    }
  }

  postComments(item, HTMLid) {
    this.documentComments = (<HTMLInputElement>(
      document.getElementById(HTMLid)
    )).value;
    var body = {
      ProjectId: this.projectId,
      GUID: this.currentGuid,
      StudentDocId: item.StudentdocId,
      Text: this.documentComments,
      Admin: this.admin,
      // Admin: false,
      LeadId: this.currentLeadId,
      AgentMail: this.userData.mail,
      Platform: "CRM",
    };

    if (HTMLid == "leadComments") {
      body.StudentDocId = null;
    }

    this.uhleadsservice.postDocumentComment(body).subscribe((res) => {
      this.documentValidation(this.currentLeadId);
      this.sideNavData(item);
      this.uhleadsservice.showSuccess("Comment Posted!", "Success!");
      (<HTMLInputElement>document.getElementById(HTMLid)).value = "";

      let payload = {
        LeadId: 0,
        GBPAssignedTo: "",
        Platform: "CRM",
        GUID: this.currentGuid,
        StudentDocId: 0,
        notificationType: "LeadComments",
        description: this.getAgentName(this.userData.mail) + " has commented",
        AgentMail: this.userData.mail,
        Title: "Comment",
        token: this.userData.token,
      };

      if (HTMLid == "leadComments") {
        payload.StudentDocId = 0;
      } else if (HTMLid == "documentComments" || HTMLid == "callingComments") {
        payload.StudentDocId = item.StudentdocId;
      }
      try {
        this.NotificationService.sendNotifications(payload).subscribe(
          (res: any) => {
            if (res.status === 200) {
              // this.toastr.showSuccess('Successfully send notification', 'Notification')
              // this.NotificationService.success('success', 'loading component');
            } else if (res.status == 500) {
            }
          }, (error) => {
            this.handleError.handleError(error)
          });
      } catch (err) {
        console.log("Notification not sent- Error :", err);
      }
    }, (error) => {
      this.handleError.handleError(error)
    });
  }

  showmore(itemsCount) {
    if (itemsCount < this.minItems) {
    } else if (this.minItems + 5 < itemsCount) {
      this.minItems = this.minItems + 5;
    } else if (this.minItems < itemsCount) {
      this.minItems = itemsCount;
    }
  }

  callFromDocs(data, item) {
    this.startCallListener2(data, 0);
    this.currentItem = item;
  }

  getAgentNames() {
    this.agentService.getAgentNames().subscribe((data) => {
      this.totalAgents = data;

      this.uhleadsservice.getGBPAgentList().subscribe((res: any) => {
        this.GBPagentList = res;
        for (let i = 0; i < this.GBPagentList.length; i++) {
          this.totalAgents.push({
            FirstName: this.GBPagentList[i].FIRST_NAME,
            LastName: this.GBPagentList[i].LAST_NAME,
            Mailid: this.GBPagentList[i].EMAIL_ADDRESS,
            ProfilePhoto: this.GBPagentList[i].ProfilePhoto,
          });
        }
      }, (error) => {
        this.handleError.handleError(error)
      });
      // this.totalAgents.push({FirstName: "Test", LastName: "Test", MailId: "test@test.com", ProfilePhote:"test.com"})
    }, (error) => {
      this.handleError.handleError(error)
    });
  }

  fileUpload(event, item) {
    let fileList: FileList = event.target.files;
    this.files = fileList
    let file: File = fileList[0];
    if (fileList.length > 0) {
      if (!this.validateFile(file.type)) {
        return;
      }
      let formData: FormData = new FormData();
      formData.append("studentdocid", item.StudentdocId);
      formData.append("document", file, file.name);
      formData.append("Platform", "CRM");
      formData.append("MailId", this.userData.mail);

      this.uhleadsservice
        .uploadDocument(formData, item.StudentdocId)
        .subscribe((res) => {
          this.uhleadsservice.showSuccess("File upload successful!", "Success");
          this.sideNavData({ id: this.currentLeadId });
        }, (error) => {
          this.handleError.handleError(error)
        });
    }
  }
  uploadCustomDocument(event) {
    console.log("🚀 ---------------------------------------------------------------------------🚀");
    console.log("🚀 ~ file: uhleads.component.ts:2164 ~ uploadCustomDocument ~ event:", event);
    console.log("🚀 ---------------------------------------------------------------------------🚀");
    // this.files=[]
    let fileList: FileList = event.target.files;
    this.files = fileList;

    this.file = fileList[0];
    if (fileList.length > 0) {
      if (!this.validateFile(this.file.type)) {
        this.uhleadsservice.showError("Invalid file format!", "Error");
        return;
      }
      this.uploadFile2.nativeElement.value = '';

    } else {
      this.aiDocMessages = true;
      this.docUploadError = "Custom document added";
      setTimeout(() => {
        this.aiDocMessages = false;
        this.docUploadError = '';
      }, 5000);
    }
  }

  addCustomDocument() {

    this.docUploadError = "";
    if (this.customDocName == "") {
      this.docUploadError = "Document name cannot be empty!";
      return;
    } else {
      let formData: FormData = new FormData();
      formData.append("GUID", this.currentGuid);
      formData.append("docName", this.customDocName);
      formData.append("Platform", "CRM");
      formData.append("MailId", this.userData.mail);

      if (this.file != undefined) {
        formData.append("document", this.file, this.file.name);
      }
      this.uhleadsservice
        .uploadCustomDocument(formData)
        .subscribe((res: any) => {
          // let response = res.map((d:any) =>{status: d.status});
          let response = res.status;
          if (response == 200) {
            this.customFileList.push({
              docName: this.customDocName,
              fileName: "",
            });
            this.docUploadError = "Custom document added";
            this.uhleadsservice.showSuccess(
              "Custom document added!",
              "Success"
            );
          } else if (response == 201) {
            this.customFileList.push({
              docName: this.customDocName,
              fileName: this.file.name,
            });
            this.docUploadError = "Uploaded a custom document";
            this.uhleadsservice.showSuccess(
              "Uploaded a custom document!",
              "Success"
            );
          } else if (response == 202) {
            this.docUploadError = "Document with given name already exists!";
            this.uhleadsservice.showError(
              "Document with given name already exists!",
              "Error"
            );
            return;
          }
          this.sideNavData({ id: this.currentLeadId });
          this.file = null;
          // this.files = [];
          this.customDocName = "";
        }, (error) => {
          this.handleError.handleError(error)
        });
    }
  }

  deleteCustomDocument(docId, docName) {
    this.uhleadsservice
      .deleteCustomDocument({
        StudentdocId: docId,
        docName: docName,
        Platform: "CRM",
        MailId: this.userData.mail,
      })
      .subscribe((res: any) => {
        let response = res.status;
        if (response == 200) {
          this.uhleadsservice.showSuccess(
            "Custom document deleted successfully.",
            "Deleted Successfully!"
          );
        } else {
          this.uhleadsservice.showError("Something went wrong!", "Error!");
        }
      }, (error) => {
        this.handleError.handleError(error)
      });
    this.sideNavData({ id: this.currentLeadId });
  }

  downLoadDocument(url, name) {
    const link = document.createElement("a");
    link.setAttribute("target", "_blank");
    link.setAttribute("type", "hidden");
    link.setAttribute("href", url);
    link.setAttribute("download", name);
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  //Validate Uploaded file
  validateFile(name: String) {
    var ext = name.substring(name.lastIndexOf(".") + 1);
    if (ext.toLowerCase() == "image/jpeg") {
      return true;
    } else if (ext.toLowerCase() == "document") {
      return true;
    } else if (ext.toLowerCase() == "image/png") {
      return true;
    } else if (ext.toLowerCase() == "application/msword") {
      return true;
    } else if (ext.toLowerCase() == "application/vnd.openxmlformats-officedocument.wordprocessingml.document") {
      return true;
    } else if (ext.toLowerCase() == "application/pdf") {
      return true;
    } else {
      this.uhleadsservice.showError("Please upload a valid file!", "Error!");
      return false;
    }
  }

  openPreview(content, url) {
    // this.docUrl = '';
    this.docUrl = url;
    this.modalService
      .open(content, {
        ariaLabelledBy: "modal-basic-title",
        windowClass: "myCustomModalClass",
      })
      .result.then(
        (result) => { },
        (reason) => { }
      );
  }

  contentLoaded() {
    document.getElementById("progressBar").style.display = "none";
  }

  playAudio(data) {
    // let audio = new Audio();
    if (this.audioStatus == false) {
      this.audio.src = data.RecordingURL;
      this.audio.pause();
      this.audio.currentTime = 0;
      // this.audio.load();
      this.audio.play();
      this.audioStatus = true;
    } else if (this.audioStatus == true) {
      this.audio.pause();
      this.audio.currentTime = 0;
      this.audioStatus = false;
    }
  }
  audioPlay(e) {
    let eAudio = this.domService.getElementsByTagName("audio");
    if (eAudio && eAudio.length > 0) {
      for (var i = 0; i < eAudio.length; i++) {
        if (e.target !== eAudio[i]) {
          eAudio[i].pause();
        }
      }
    }
  }

  pauseAudio(data) {
    // audio.stop();
  }

  callNext() { }

  stopCurrentCall() {
    this.uhleadsservice.stopCall.next({
      phoneNumber: this.marketingId.phoneNumber,
    });
    this.callStatus = "Call Ended";

    this.uhleadsservice.updateLimitByType({ projectId: this.projectId, type: 'aianalytics' }).subscribe((data) => {

    }, (err) => {

    })
  }

  stopCallListener() {
    this.uhleadsservice.stopCall.next({
      phoneNumber: this.marketingId.phoneNumber,
    });
    this.stopCall = true;
    this.startCall = false;
    if (this.statusValue !== "pending") {
      this.startIndex += 1;
    }
    clearTimeout(this.clearTime);
  }

  followUpCall(data) {
    if (!this.startCall) {
      this.layoutService.marketingCall.next({
        id: data["id"],
        number: "+" + data["CountryCode"] + data["PhoneNumber"],
        name: data["FirstName"],
        email: data["Email"],
        comments: data["Comments"],
        module: data["Module"],
        city: data["city"],
        nextCaller: null,
        lead: data,
        callFeedbacks: this.listFeed
      });
      this.startCall = true;
    }
  }

  openCreateLead(content) {
    this.modalService.open(content, { size: "md" });
  }
  openEditLead(content, lead) {
    if (lead.DOB == "-") {
      lead.DOB = "";
    }

    this.editStatus = lead.CallStatus;

    this.leadtoEdit = {
      LeadId: lead.id,
      FirstName: lead.FirstName,
      LastName: lead.LastName,
      CountryCode: "+" + lead.CountryCode,
      PhoneNumber: lead.PhoneNumber,
      Email: lead.Email,
      DOB: lead.DOB,
      EnrollmentYear: lead.EnrollmentYear,
      EnrollmentTerm: lead.EnrollmentTerm,
      Address: lead.Address,
      City: lead.City,
      State: lead.State,
      Zip: lead.Zip,
      Country: lead.Country,
      LeadComment: this.editComment,
      CallStatus: this.editStatus,
      AgentMail: this.userData.mail,
    };
    this.modalService.open(this.editLead2, { size: "lg" });
  }

  replaceAll(string, search, replace) {
    return string.split(search).join(replace);
  }

  updateLead() {
    this.edited = true;
    if (this.uhleadsservice.editLead.invalid) {
      return;
    }

    // this.leadtoEdit.CountryCode = this.leadtoEdit.CountryCode.replace("+", "");
    this.leadtoEdit.CountryCode = this.replaceAll(this.leadtoEdit.CountryCode, '+', '');
    if (this.leadtoEdit.CountryCode == "-") {
      this.leadtoEdit.CountryCode = "00";
    }
    if (this.leadtoEdit.PhoneNumber == "-") {
      this.leadtoEdit.PhoneNumber = "0000000000";
    }
    var keys = Object.keys(this.leadtoEdit);
    keys.forEach((a) => {
      if (
        this.leadtoEdit[a] === "-" ||
        this.leadtoEdit[a] === "" ||
        this.leadtoEdit[a] === undefined
      ) {
        this.leadtoEdit[a] = null;
      }
    });

    this.uhleadsservice.updateLead(this.leadtoEdit).subscribe((res: any) => {
      this.uhleadsservice.showSuccess("Lead details updated", "Success!");
      this.edited = false;

      document.getElementById("closeButton3").click();
      // this.getCounts(this.userData, "all", this.startDate, this.endDate, "all");
      this.getTopBarCRMNew();
      this.getCardCount(
        {
          admin: this.admin,
          mail: this.userData.mail,
          ProjectId: this.projectId,
          leadType: this.cardLeadType,
          start: this.startDate,
          end: this.endDate,
          page: 1,
        },
        this.cardLeadType
      );
    }, (error) => {
      this.handleError.handleError(error)
    });
  }

  // Clear file and document name in custom document upload modal
  clearData() {
    this.file = null;
    // this.files = [];
    this.customDocName = "";
  }

  selectFiles() {
    const frmData = new FormData();

    for (var i = 0; i < this.selectedFiles.length; i++) {
      frmData.append("fileUpload", this.selectedFiles[i]);
    }
  }

  getFileDetails(e) {
    for (var i = 0; i < e.target.files.length; i++) {
      this.selectedFiles.push(e.target.files[i]);
    }
    if (this.selectedFiles.length > 0) {
      this.docUploadError2 = "Click on upload to start uploading your files";

      var x = document.getElementsByClassName(
        "docUploadError"
      ) as HTMLCollectionOf<HTMLElement>;
      for (let i = 0; i < x.length; i++) {
        x[i].style.color = "green";
      }
    }
  }

  deleteFile(i) {
    this.selectedFiles.splice(i, 1);
  }

  uploadAttachments() {
    let check = true;
    this.selectedFiles.forEach((element) => {
      if (this.validateFile(element.type) == false) {
        this.docUploadError2 =
          "One or more files you uploaded is not a valid format. Please upload either doc, pdf, png or JPG/JPEG format.";
        check = false;
        var x = document.getElementsByClassName(
          "docUploadError"
        ) as HTMLCollectionOf<HTMLElement>;
        for (let i = 0; i < x.length; i++) {
          x[i].style.color = "red";
        }
      }
    });
    if (check == true) {
      var x = document.getElementsByClassName(
        "docUploadError"
      ) as HTMLCollectionOf<HTMLElement>;
      for (let i = 0; i < x.length; i++) {
        x[i].style.color = "green";
      }
      this.docUploadError2 =
        "Compressing your documents. Please wait for the process to finish";
      this.docUploadStatus = true;

      let formData: FormData = new FormData();
      formData.append("studentdocid", this.docUploadID);
      Array.from(this.selectedFiles).forEach((file) =>
        formData.append("document", file, file.name)
      );
      formData.append("Platform", "CRM");
      formData.append("MailId", this.userData.mail);
      formData.append("projectId", this.projectId);
      formData.append("leadid", this.docUploadID);
      formData.append("type", this.customDocType);
      formData.append("projectType", 'old');
      // studentdocid, MailId, Platform, companyId, projectId, leadid, type, projectType
      this.uhleadsservice
        .uploadDocumentv2(formData, this.docUploadID)
        .subscribe((res: any) => {
          // this.uhleadsservice.showSuccess("File upload successful!", "Success");

          if (res.status == 200) {
            this.uploadSuccess = "success";
            this.sideNavData({ id: this.currentLeadId });
            this.uhleadsservice.showSuccess(
              "File upload successful!",
              "Success"
            );
            this.docUploadStatus = false;

            // document.getElementById("closeButton4").click();
            try {
              // document.getElementById("closeButton4").click();
            } catch (err) {
              this.uploadSuccess = "failure";
            }
          }
        }, (error) => {
          this.uploadSuccess = "failure";
          this.uhleadsservice.showError(
            "File upload failed!",
            "Success"
          );
          this.docUploadStatus = false;
          this.handleError.handleError(error);
        });
    }
  }
  cancelUpload() {
    document.getElementById("closeButton4").click();
  }

  clearSelectedFiles(item) {
    this.docUploadID = item.StudentdocId;
    this.customDocType = item.ProgramDoc;
    this.docUploadStatus = false;
    this.selectedFiles = [];
    this.docUploadError2 = "Select files to upload.";
    this.uploadSuccess = "none";

    var x = document.getElementsByClassName(
      "docUploadError"
    ) as HTMLCollectionOf<HTMLElement>;
    for (let i = 0; i < x.length; i++) {
      x[i].style.color = "green";
    }
  }

  open() {
    let payload = {
      LeadId: 0,
      GBPAssignedTo: "",
      Platform: "CRM",
      // GUID: "f083dc22-9ed2-4204-b46c-d4e2958b6de0",
      GUID: "12356yyu98",
      StudentDocId: 0,
      notificationType: "LeadComments",
      description: "Test Comment",
      AgentMail: "ramana@universityhub.com",
      Title: "Test",
      token: this.userData.token,
    };

    let payload2 = {
      AgentMail: "ramana@universityhub.com",
      GBPAssignedTo: "",
      GUID: "12356yyu98",
      LeadId: 0,
      Platform: "CRM",
      StudentDocId: 0,
      Title: "Comment",
      description: "12356yyu98",
      notificationType: "LeadComments",
      token: this.userData.token,
    };

    this.NotificationService.sendNotifications(payload).subscribe(
      (res: any) => {
        if (res.status === 200) {
        }
      }, (error) => {
        this.handleError.handleError(error)
      });

    this.NotificationService.sendNotifications(payload2).subscribe(
      (res: any) => {
        if (res.status === 200) {
        }
      }, (error) => {
        this.handleError.handleError(error)
      });
  }

  viewLead(id) {
    this.sideNav.toggle();
    this.sideNavData({ id: id });
  }

  onInputActivity(event: any) {
    console.log("event: ", event.value);
    if (this.activityfilterString != "") {
      this.minItems = event;
    } else {
      this.minItems = 5;
    }
  }
  getTopBarCRM() {
    var postData = {
      ProjectId: this.projectId,
      start: this.startDate,
      end: this.endDate,
      AgentMail: this.userData.mail,
    };
    this.uhleadsservice
      .getTopBarCRM(postData)
      .toPromise()
      .then((resp: any) => {
        this.totalPages = 1;

        if (this.leadType == "all") {
          this.totalPages = resp.TopBarCount.total;
        } else if (this.leadType == "assigned") {
          this.totalPages = resp.TopBarCount.assigned;
        } else if (this.leadType == "assignedtoMe") {
          this.totalPages = resp.TopBarCount.assignedtoMe;
        } else if (this.leadType == "completedDocs") {
          this.totalPages = resp.TopBarCount.completedDocs;
        } else if (this.leadType == "documentsSubmitted") {
          this.totalPages = resp.TopBarCount.documentsSubmitted;
        } else if (this.leadType == "pendingDocs") {
          this.totalPages = resp.TopBarCount.pendingDocs;
        } else if (this.leadType == "reopen") {
          this.totalPages = resp.TopBarCount.reopen;
        } else if (this.leadType == "revalidatedDocs") {
          this.totalPages = resp.TopBarCount.revalidatedDocs;
        } else if (this.leadType == "studentDenied") {
          this.totalPages = resp.TopBarCount.studentDenied;
        } else if (this.leadType == "unassigned") {
          this.totalPages = resp.TopBarCount.unassigned;
        } else if (this.leadType == "uncontactable") {
          this.totalPages = resp.TopBarCount.uncontactable;
        } else if (this.leadType == "validatedDocs") {
          this.totalPages = resp.TopBarCount.validatedDocs;
        } else if (this.leadType == "pending") {
          this.totalPages = resp.TopBarCount.pending;
        } else if (this.leadType == "called") {
          this.totalPages = resp.TopBarCount.called;
        } else if (this.leadType == "callback") {
          this.totalPages = resp.TopBarCount.callback;
        } else if (this.leadType == "informed") {
          this.totalPages = resp.TopBarCount.informed;
        } else if (this.leadType == "wrongnumber") {
          this.totalPages = resp.TopBarCount.wrongnumber;
        } else if (this.leadType == "notanswered") {
          this.totalPages = resp.TopBarCount.notanswered;
        } else if (this.leadType == "deferredintake") {
          this.totalPages = resp.TopBarCount.deferredintake;
        } else if (this.leadType == "uncontactable") {
          this.totalPages = resp.TopBarCount.uncontactable;
        }

        this.totalPages = ~~(Number(this.totalPages) / 12) + 1;
        this.customService.totalPages.next(this.totalPages);

        this.topBarCounts = resp.TopBarCount;
      })
      .catch((err) => {
        this.handleError.handleError(err)

      });
  }
  getTopBarCRMNew() {
    var postData = {
      ProjectId: this.projectId,
      start: this.startDate,
      end: this.endDate,
      AgentMail: this.userData.mail,
    };
    this.uhleadsservice
      .getTopBarCRMNew(postData)
      .toPromise()
      .then((resp: any) => {
        this.totalPages = 1;
        this.topBarCounts12 = resp;
        this.getCountCardsNew();
        // var data2 = this.topBarCounts12;
        // data2.filter(x => {
        //   if (x.value === this.leadType) {
        //     this.totalPages = x.count;
        //     console.log(this.totalPages)
        //   }
        // });
      })
  }
  sortData(arr, value) {
    console.log("Value:    ", value);
    return arr.sort((a, b) => {
      return a[value] - b[value];
      //return a[value] < b[value] ? -1 : a[value] > b[value] ? 1: 0
    });
  }
  toggleSort(column, order) {
    if (this.sortOrder == 'asc') {
      this.sortOrder = 'des'
    } else {
      this.sortOrder = 'asc';
    }
    this.sortTableData(column, this.sortOrder);
  }

  sortTableData(column, order) {

    if (column == "createdAt") {
      if (this.currentCard == "total") {
        column = "createdAt";
      } else if (this.currentCard == "validated") {
        column = "ValidatedDate";
      } else if (this.currentCard == "uncontactable") {
        column = "createdAt";
      } else if (this.currentCard == "completed") {
        column = "CompletedDate";
      } else if (this.currentCard == "updated") {
        column = "createdAt";
      } else if (this.currentCard == "revalidate") {
        column = "RevalidateDate";
      } else if (this.currentCard == "denied") {
        column = "DeniedDate";
      } else if (this.currentCard == "assigned") {
        column = "createdAt";
      }
    }
    this.sortColumn = column;
    this.activityData = sortJsonArray(this.activityData, column, this.sortOrder);

    // if (this.sortOrder == 'asc'){
    //   this.sortOrder ='des'
    // }else{
    //   this.sortOrder = 'asc';
    // }
  }

  getCardCount(obj, type) {

    this.cardLeadType = type;
    this.leadIds = [];
    this.activityTable = [];
    this.isLoading = true;
    this.noRecords = false;
    this.search = "";

    this.activityData2 = [];
    this.activityData = [];
    // this.sharedService.projectId.subscribe((data) => {
    this.companyService.projectAdmin(this.companyId).subscribe((projects) => {
      obj.projectId = projects.filter((x) => x.Name == "Documents")[0].id;
      // obj.ProjectId = data;
      obj.page = this.pageNumber;
      obj.leadType = this.leadType;
      this.uhleadsservice
        .getCardLeadByType(obj)
        .toPromise()
        .then((resp: any) => {
          this.activityData2 = resp;
          this.activityData2.forEach(function (a) {
            Object.keys(a).map((key) => {
              a[key] === null ? (a[key] = "-") : a[key];
            });
          });
          for (let i = 0; i < this.activityData2.length; i++) {
            if (this.activityData2[i].PhoneNumber == "0000000000") {
              this.activityData2[i].PhoneNumber = "-";
              this.activityData2[i].CountryCode = "-";
            }

            this.activityData2[i].AssignedtoName = this.getAgentName(
              this.activityData2[i].Assignedto
            );
            this.activityData2[i].AssignedtoInitial = this.getAgentInitial(
              this.activityData2[i].Assignedto
            );
            this.activityData2[i].AssignedtoColor = this.getAgentColor(
              this.activityData2[i].Assignedto
            );
            this.activityData2[i].AssignedtoImageURL = this.getAgentphoto(
              this.activityData2[i].Assignedto
            );
            if (
              this.activityData2[i].LeadDocStatus == "CRM-Revalidate" ||
              this.activityData2[i].LeadDocStatus == "Admin-Revalidate" ||
              this.activityData2[i].LeadDocStatus == "Admin-revalidate" ||
              this.activityData2[i].LeadDocStatus == "CRM-revalidate"
            ) {
              this.activityData2[i].LeadDocStatus = "Revalidate";
            }
          }
          this.activityData = this.activityData2;
          this.sortTableData(this.sortColumn, this.sortOrder);

          var test1 = this.activityData;
          var test2 = test1.map(obj => ({ ...obj, name: obj.FirstName + " " + obj.LastName }));
          this.activityTable = test2;
          this.isLoading = false;
          if (this.activityTable.length == 0) {
            this.noRecords = true;
          } else {
            this.noRecords = false;
          }
        })
        .catch((err) => {
          this.handleError.handleError(err)
        });
    }, (error) => {
      this.handleError.handleError(error)
    });
  }

  getCardData(userData: any, leadtype) {
    this.leadIds = [];
    this.isLoading = true;
    this.noRecords = false;
    this.search = "";
    this.onClick(null);
    this.activityTable = [];
    let obj = {
      admin: this.admin,
      // admin: true,
      mail: userData.mail,
      ProjectId: this.projectId,
      leadType: leadtype,
      start: this.startDate,
      end: this.endDate,
      page: this.pageNumber,
    };
    console.log("LeadType: ", leadtype);

    this.uhleadsservice
      .getCardLeadByType(obj)
      .toPromise()
      .then((resp: any) => {
        // console.log(":: obj ::", resp);
        this.activityTable2 = resp;
        this.activityTable2.forEach(function (a) {
          Object.keys(a).map((key) => {
            a[key] === null ? (a[key] = "-") : a[key];
          });
        });
        // console.log("Activity Table: ", this.activityTable2);

        for (let i = 0; i < this.activityTable2.length; i++) {
          if (this.activityTable2[i].PhoneNumber == "0000000000") {
            this.activityTable2[i].CountryCode = "-";
            this.activityTable2[i].PhoneNumber = "-";
          }
          this.activityTable2[i].AssignedtoName = this.getAgentName(
            this.activityTable2[i].Assignedto
          );

          this.activityTable2[i].AssignedtoInitial = this.getAgentInitial(
            this.activityTable2[i].Assignedto
          );
          this.activityTable2[i].AssignedtoColor = this.getAgentColor(
            this.activityTable2[i].Assignedto
          );
          this.activityTable2[i].AssignedtoImageURL = this.getAgentphoto(
            this.activityTable2[i].Assignedto
          );
          if (
            this.activityTable2[i].LeadDocStatus == "CRM-Revalidate" ||
            this.activityTable2[i].LeadDocStatus == "Admin-Revalidate" ||
            this.activityTable2[i].LeadDocStatus == "Admin-revalidate" ||
            this.activityTable2[i].LeadDocStatus == "CRM-revalidate"
          ) {
            this.activityTable2[i].LeadDocStatus = "Revalidate";
          }
        }
        // this.activityTable = this.activityTable2;
        var test1 = this.activityTable2;
        var test2 = test1.map(obj => ({ ...obj, name: obj.FirstName + " " + obj.LastName }));
        this.activityTable = test2;
        this.getAllData(
          this.userData,
          "total",
          new Date(Date.now() - 864e5 * 365),
          new Date(Date.now())
        );
        this.callData = this.activityTable;
        this.isLoading = false;
        if (this.activityTable.length == 0) {
          this.noRecords = true;
        } else {
          this.noRecords = false;
        }
      })
      .catch((err) => { this.handleError.handleError(err) });
  }
  checkThresHold(content, item, id) {
    this.thresItem = item;
    this.thresId = id;
    console.log(this.thresItem, this.thresId)
    if (item.TotalCalls > 12) {
      this.thresModal = this.modalService.open(this.totalCallCount, {
        ariaLabelledBy: "modal-basic-title",
        windowClass: "myCustomModalClass",
      });
    } else {

      this.startCallListener2(this.thresItem, this.thresId);
      document.getElementById("openModalButton").click();
    }
  }
  thresHold() {
    this.thresModal.close();
    this.startCallListener2(this.thresItem, this.thresId);
    document.getElementById("openModalButton").click();
  }
  onDIItermsChange(data) {
  }
  onDIYearChange(data) {
  }
  listFeedback() {
    this.sharedService.listFeedback(this.projectId).toPromise()
      .then((resp: any) => {
        this.listFeed = resp.feedback;
        for (let i = 0; i < this.listFeed.length; i++) {
          this.cardsSettings.push({
            primaryKey: this.listFeed[i].value,
            header: this.listFeed[i].name,
            view: true
          });
          this.dummycards1.push({
            primaryKey: this.listFeed[i].value,
            header: this.listFeed[i].name,
            view: true
          });
        }
        this.cardsSettings.sort((a, b) => a.header.localeCompare(b.header))
      })
      .catch(err => {

      })
  }
  redirectToUpload() {
    let menuid = 0;
    this.router.navigate(['./upload/' + this.projectId+'/'+menuid]);
  }
  async openCardsModal(cardsChangemodal) {
    // return await this.cardsChangemodallComponent.open();
    this.modalService.open(cardsChangemodal, {
      ariaLabelledBy: "modal-basic-title",
      windowClass: "myCustomModalClass",
    }).result.then(
      (result) => { },
      (reason) => { }
    );
  }
  async removeCard(value, view) {
    console.log(view)
    if (view === false) {
      this.dummycards1.map(x => {
        if (x.primaryKey === value) {
          x.view = false
        }
      });
      for (var i = 0; i < this.cardsSettings.length; i++) {
        var obj = this.cardsSettings[i];

        if (obj.primaryKey === value) {
          this.cardsSettings.splice(i, 1);
        }
      }
    } else {
      await this.dummycards1.map(x => {
        if (x.primaryKey === value) {
          x.view = true;
          this.cardsSettings.push(x);
          this.cardsSettings.sort((a, b) => a.header.localeCompare(b.header))
        }
      });
    }

  }
  pageChange(event) {
    this.leadType = event;
    this.getCardData(
      this.userData,
      this.leadType
    );
    this.getCountCardsNew();
  }
  getCountCardsNew() {
    var data2 = this.topBarCounts12.TopBarCount.query;

    var data1 = data2.filter(x => {
      if (x.value === (this.leadType === 'all' ? 'total' : this.leadType)) {
        return x
      }
    });
    if (data1.length > 0) {
      this.totalPages = data1[0].count;
      this.totalPages = ~~(Number(this.totalPages) / 12) + 1;
      this.customService.totalPages.next(this.totalPages);
    } else {
      this.totalPages = 1;
      this.totalPages = ~~(Number(this.totalPages) / 12) + 1;
      this.customService.totalPages.next(this.totalPages);
    }
  }

  openTablesModal(cardsChangemodal) {
    // return await this.cardsChangemodallComponent.open();
    this.modalService.open(cardsChangemodal, {
      ariaLabelledBy: "modal-basic-title",
      windowClass: "myCustomModalClass",
    }).result.then(
      (result) => { },
      (reason) => { }
    );
  }
  async removeTable(value, view) {
    if (view === false) {
      await this.projectSettings1.map(x => {
        if (x.primaryKey === value) {
          x.view = false;
        }
      });
      for (var i = 0; i < this.projectSettings.length; i++) {
        var obj = this.projectSettings[i];

        if (obj.primaryKey === value) {
          this.projectSettings.splice(i, 1);
        }
      }
    } else {
      await this.projectSettings1.map(x => {
        if (x.primaryKey === value) {
          x.view = true;
        }
        this.sortReusbaleTable(this.projectSettings1);
      });
    }
  }

  updateTables() {
    console.log(this.projectSettings)
    // this.projectSettings = this.projectSettings1;
  }
  updateLeadModal(event) {
    this.openEditLead(event.name, event.item)
  }
  callModal(event) {
    this.uhleadsservice.getLimitByType({ projectId: this.projectId, type: 'aianalytics' }).subscribe((data: any) => {
      let resp = data;
      let limit = resp.data.api_calls;
      console.log(limit)
      if (parseInt(limit) > 0) {
        this.checkThresHold(event.name, event.item, event.id)
      } else {
        this.uhleadsservice.showInfo('Calling limit exhausted', 'Call Limit');
      }
    });
  }
  sidenavOpen(event) {
    this.viewLead(event)
  }
  async sortReusbaleTable(data) {
    var test1 = data.filter(x => {
      if (x.view) {
        return x
      }
    });
    console.log(test1)
    this.projectSettings = [
      ...test1.filter(x => x.header !== 'Action'),
      ...test1.filter(x => x.header === 'Action')
    ];
  }
  dropdownSelectReuse(event) {
    this.statusValue = event;
    this.dropdownSelect(event);
  }
  searchLeadsNew(event) {
    this.searchLeads(event.search, event.pageNumber);
  }
  selectAgentforAssignmentNew(event) {
    this.selectAgentforAssignment(event.e, event.item)
  }
  addCustomDocumentAI() {
    if (this.customDocType == "none" || this.customDocType == undefined || this.customDocType == null) {
      this.aiDocMessages = true;
      this.docUploadError = "Document type cannot be none. Please select other type";
      setTimeout(() => {
        this.aiDocMessages = false;
        this.docUploadError = '';
      }, 5000);
      return;
    } else if (!this.file) {
      this.aiDocMessages = true;
      this.docUploadError = "Please select any file .";
      setTimeout(() => {
        this.aiDocMessages = false;
        this.docUploadError = '';
      }, 5000);
      return;
    } else {
      this.docUploadStatus = true;
      this.docUploadError = 'Document validation is in progress.';
      var formData = new FormData();
      this.files = [this.file];
      Array.from(this.files).forEach((_file: any) =>
        formData.append("document", _file, _file.name)
      );
      // formData.append("pdf", _file, _file.name)
      // MailId, Platform, companyId, projectId, GUID, docName, projectType
      formData.append("projectType", 'old');
      formData.append("docName", this.customDocType);
      formData.append("type", this.customDocType);
      formData.append("MailId", this.userData.mail);
      formData.append("projectId", this.projectId);
      formData.append("GUID", this.currentGuid);
      formData.append("Platform", "CRM");

      // uploadCustomDocs3

      this.uhleadsservice.uploadCustomDocs3(formData).subscribe((data: any) => {
        this.file = null;
        this.customDocType = 'none';
        this.closeButton.nativeElement.click();
        document.getElementById("closeAssignButton").click();
        this.documentValidation(this.currentLeadId);
        this.sideNavData({ id: this.currentLeadId });
        this.uhleadsservice.showSuccess(data.msg, "Success!");
        this.docUploadError = "Custom document added";
        this.docUploadStatus = false;
        this.aiDocMessages = true;
        setTimeout(() => {
          this.aiDocMessages = false;
          this.docUploadError = '';
        }, 5000);
        this.closeButton.nativeElement.click();
        document.getElementById("closeAssignButton").click();
      }, (err) => {
        console.log("🚀 -----------------------------------------------------------------------------------------------🚀");
        console.log("🚀 ~ file: accessqueleads.component.ts:3185 ~ this.uhleadsservice.uploadCustomDocs3 ~ err:", err);
        console.log("🚀 -----------------------------------------------------------------------------------------------🚀");
        this.docUploadStatus = false;
        this.aiDocMessages = true;
        this.docUploadError = 'There is some error in validating the file .';
        setTimeout(() => {
          this.aiDocMessages = false;
          this.docUploadError = '';
        }, 5000);
        this.closeButton.nativeElement.click();
        document.getElementById("closeAssignButton").click();
      })
      // this.uhleadsservice.pdfCompression(formData).subscribe((pdfData: any) => {
      //   var formdata = new FormData();
      //   formdata.append("pdf", this.file, this.file.name);
      //   formdata.append("doctype", this.customDocType);
      //   let bodyPayload = `?pdf=${pdfData.s3_url}&doctype=${this.customDocType}`;
      //   this.uhleadsservice.docChecker(bodyPayload).subscribe((aidata) => {
      //     if (aidata['Document_status'] == 'Valid') {
      //       this.docUploadStatus = false;
      //       this.docUploadError = "Uploaded document is a valid document.";
      //       setTimeout(() => {
      //         this.docUploadError = 'Adding the document to document list.';
      //       }, 5000);
      //       let formData: FormData = new FormData();
      //       formData.append("GUID", this.currentGuid);
      //       formData.append("docName", this.customDocType);
      //       formData.append("Platform", "CRM");
      //       formData.append("MailId", this.userData.mail);
      //       formData.append("document", this.file, this.file.name);

      //       this.uhleadsservice
      //         .uploadCustomDocument(formData)
      //         .subscribe((res: any) => {
      //           let response = res.status;
      //           let docId = res.StudentdocId;
      //           if (response == 200) {
      //             this.customFileList.push({
      //               docName: this.customDocType,
      //               fileName: "",
      //             });
      //             this.docUploadError = "Custom document added";
      //             this.uhleadsservice.showSuccess(
      //               "Custom document added!",
      //               "Success"
      //             );
      //             this.uhleadsservice
      //               .updateValidationStatus({
      //                 StudentdocId: docId,
      //                 admin: false,
      //                 validationStatus: 'validated',
      //                 Platform: "CRM",
      //                 MailId: this.userData.mail,
      //               })
      //               .subscribe((res2) => {
      //                 this.documentValidation(this.currentLeadId);
      //                 this.sideNavData({ id: this.currentLeadId });
      //                 this.uhleadsservice.showSuccess("Document Status updated!", "Success!");

      //                 let payload = {
      //                   AgentMail: this.userData.mail,
      //                   GBPAssignedTo: "",
      //                   GUID: "",
      //                   LeadId: 0,
      //                   Platform: "CRM",
      //                   StudentDocId: res.StudentdocId,
      //                   Title: "Document Validation",
      //                   description:
      //                     "Document status changed to " +
      //                     "validated" +
      //                     " by " +
      //                     this.getAgentName(this.userData.mail),
      //                   notificationType: "Validation",
      //                   token: this.userData.token,
      //                 };
      //                 this.file = null;
      //                 // this.files = [];
      //                 this.customDocType = 'none';
      //                 this.NotificationService.sendNotifications(payload).subscribe(
      //                   (res: any) => {
      //                     if (res.status === 200) {
      //                     }
      //                   }
      //                 );
      //               })
      //             this.closeButton.nativeElement.click();
      //             document.getElementById("closeAssignButton").click();
      //           } else if (response == 201) {
      //             this.customFileList.push({
      //               docName: this.customDocType,
      //               fileName: this.file.name,
      //             });
      //             this.docUploadError = "Uploaded a custom document";
      //             this.uhleadsservice.showSuccess(
      //               "Uploaded a custom document!",
      //               "Success"
      //             );
      //             this.uhleadsservice
      //               .updateValidationStatus({
      //                 StudentdocId: docId,
      //                 admin: false,
      //                 validationStatus: 'validated',
      //                 Platform: "CRM",
      //                 MailId: this.userData.mail,
      //               })
      //               .subscribe((res2) => {
      //                 this.documentValidation(this.currentLeadId);
      //                 this.sideNavData({ id: this.currentLeadId });
      //                 this.uhleadsservice.showSuccess("Document Status updated!", "Success!");

      //                 let payload = {
      //                   AgentMail: this.userData.mail,
      //                   GBPAssignedTo: "",
      //                   GUID: "",
      //                   LeadId: 0,
      //                   Platform: "CRM",
      //                   StudentDocId: res.StudentdocId,
      //                   Title: "Document Validation",
      //                   description:
      //                     "Document status changed to " +
      //                     "validated" +
      //                     " by " +
      //                     this.getAgentName(this.userData.mail),
      //                   notificationType: "Validation",
      //                   token: this.userData.token,
      //                 };

      //                 this.NotificationService.sendNotifications(payload).subscribe(
      //                   (res: any) => {
      //                     if (res.status === 200) {
      //                     }
      //                   }
      //                 );
      //                 // this.files = [];
      //                 this.file = null;
      //                 this.customDocType = 'none';
      //                 this.closeButton.nativeElement.click();
      //                 document.getElementById("closeAssignButton").click();
      //               })
      //           } else if (response == 202) {
      //             this.aiDocMessages = true;
      //             this.docUploadError = "Document with given name already exists!";
      //             setTimeout(() => {
      //               this.aiDocMessages = false;
      //               this.docUploadError = ''
      //             }, 5000);
      //             this.uhleadsservice.showError(
      //               "Document with given name already exists!",
      //               "Error"
      //             );
      //             return;
      //           }
      //         });
      //     } else {
      //       // this.files = [];
      //       this.file = null;
      //       this.customDocName = "";
      //       this.docUploadError = '';
      //       this.docUploadStatus = false;
      //       this.customDocType = 'none';
      //       this.aiDocMessages = true;
      //       this.docUploadError = aidata['Messege'];
      //       setTimeout(() => {
      //         this.aiDocMessages = false;
      //         this.docUploadError = ''
      //       }, 5000);
      //     }
      //   }, (error) => {
      //     this.aiDocMessages = true;
      //     this.docUploadError = 'There is some error in validating the file .';
      //     this.docUploadStatus = false;
      //     setTimeout(() => {
      //       this.aiDocMessages = false;
      //       this.docUploadError = ''
      //     }, 5000);
      //   })
      // })

    }
  }
}
