import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'updatedbyName'
})
export class UpdatedbyNamePipe implements PipeTransform {

  transform(value: any, agents: any[], customer: any, reportedBY): any {
    if (agents.find(agent => agent['Mailid'] === value)) {
      let agent = agents.find(agent => agent['Mailid'] === value);
      return agent['FirstName'] + ' ' + agent['LastName'];
    }
    if (value === customer['mail']) {
      return reportedBY
    }

    return value;
  }

}
