import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ErrorHandlerService } from '../error-handler.service';
import { LeadgenerationService } from '../pages/leadgeneration/leadgeneration.service';
import { BookdemoService } from './bookdemo.service';

export enum REGEX {
  EMAIL = '[A-Za-z0-9._%-]+@[A-Za-z0-9._%-]+\\.[a-z]{2,4}',
  PHONE = '[0-9]{10}$',
  PASSWORD = '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$',
  ZIP = '^[a-zA-Z0-9,\'-]*$',
  NUMBER = '^[0-9]*$',
  URL = '^(http:\/\/www\.|https:\/\/www\.|www\.|http:\/\/|https:\/\/)[a-zA-Z0-9\.\-]+\\.[a-zA-Z]{2,5}[\.]{0,1}',
  CHARACTER = '^[a-zA-Z \-\']+',
  SPECIAL_PHONE = '^[+|0-9]+[0-9]{1,}$',
  ADDRESS = '^[a-zA-Z0-9\s,\'-]*$',
}

@Component({
  selector: 'app-bookademo',
  templateUrl: './bookademo.component.html',
  styleUrls: ['./bookademo.component.css']
})
export class BookademoComponent implements OnInit {
  type: any;
  bookdemoForm: FormGroup;
  countryList: any[] = [];
  countryList1: any[] = [];
  selectedCountry: any;
  selectedCountryCode: number = 0;

  constructor(
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private leadsService: LeadgenerationService,
    private bookdemoService: BookdemoService,
    private errorhandler: ErrorHandlerService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe((data) => {
      this.type = data.type;
    });
    this.leadsService.readJson('../../../../assets/json/countryList.json').subscribe((resp: any) => {
      this.countryList = resp;
      this.countryList1 = resp;
    });
    this.initBook();
  }
  initBook() {
    this.bookdemoForm = this.fb.group({
      name: new FormControl('', [Validators.required, Validators.pattern(REGEX.CHARACTER)]),
      cname: new FormControl('', [Validators.required, Validators.pattern(REGEX.CHARACTER)]),
      email: [null, Validators.compose([Validators.required, Validators.pattern(REGEX.EMAIL)])],
      country: new FormControl('', [Validators.required, Validators.pattern(REGEX.CHARACTER)]),
      country_code: new FormControl('', [Validators.required]),
      phone: new FormControl('', [Validators.required, Validators.pattern(REGEX.NUMBER)]),
      captcha_verification: new FormControl('', [Validators.required])
    })
  }

  get f() {
    return this.bookdemoForm.controls;
  }

  onSubmit() {
    var obj = this.bookdemoForm.value;
    obj.type = this.type;
    obj.country = this.selectedCountry.name;
    obj.country_code = this.selectedCountryCode;
    this.bookdemoService.bookdemo(obj).toPromise()
      .then((resp: any) => {
        this.errorhandler.showsuccessbar('Successfully registered for demo.');
        this.bookdemoForm.reset();
        this.router.navigate(['/home'])
      })
      .catch(err => {

      })
  }
  selectCountry(country) {
    this.selectedCountry = country;
    this.selectedCountryCode = country.callingCodes[0];
  }
  onInput(Event: any) {
    let search = Event.target.value;
    let serachData = [];
    if (search) {
      this.countryList = this.countryList.filter((x) => {
        // debugger
        return x.name.toLowerCase().includes(search.toLowerCase());
      });
    } else {
      this.countryList = this.countryList1;
    }
  }
}
