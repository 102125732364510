import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LayoutComponent } from './layout.component';
import { SettingsComponent } from 'src/components/settings/settings.component';
import { IncomingCallsComponent } from './topbar/components/incoming-calls/incoming-calls.component';
import { EnquiryCallsComponent } from './topbar/components/enquiry-calls/enquiry-calls.component';


const routes: Routes = [
  {
    path: '', component: LayoutComponent,
    children: [
      {
        path: "settings",
        component: SettingsComponent,
      },
      {
        path: "incomingcalls",
        component: IncomingCallsComponent,
      },
      {
        path: "enquirycalls",
        component: EnquiryCallsComponent,
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LayoutRoutingModule { }
