import { PagesModule } from "./../pages.module";
import { FormsModule } from "@angular/forms";
import { NgModule } from "@angular/core";
import { CommonModule, DatePipe } from "@angular/common";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { DashboardRoutingModule } from "./dashboard-routing.module";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { SharedModule } from "src/app/shared/shared.module";
import { MatPaginatorModule } from "@angular/material/paginator";
import { Ng2SearchPipeModule } from "ng2-search-filter";
import { SortPipe } from "./sort.pipe";
import { TicketsModule } from "../tickets/tickets.module";
import { LayoutModule } from "src/app/layout/layout.module";
// import { FilterPipe } from './filter.pipe';

@NgModule({
  declarations: [DashboardComponent, SortPipe],
  imports: [
    CommonModule,
    DashboardRoutingModule,
    SharedModule,
    FormsModule,
    NgbModule,
    MatPaginatorModule,
    Ng2SearchPipeModule,
    TicketsModule,
    LayoutModule
  ],
  providers: [DatePipe],
  exports: [SortPipe, Ng2SearchPipeModule],
})
export class DashboardModule { }
