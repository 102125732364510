// import { HttpClientService } from '@angular/common/http';
import { Injectable, EventEmitter, Output, Directive } from "@angular/core";
import { BehaviorSubject, Observable, Subject, of } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { HttpClientService } from "../httpclient.service";
import { ProjectService } from "../pages/project-management/project.service";
import { RoleService } from "../pages/role-management/role.service";

@Directive()
@Injectable({
  providedIn: "root",
})
export class SharedService {
  public baseApiUrl: any = environment.baseApiUrl;
  public baseApiUrl2: any = environment.baseApiUrl2;
  public pageEvent: any = new EventEmitter();
  @Output() chatEvent: EventEmitter<any> = new EventEmitter<any>();
  public newMessage: any = new EventEmitter();
  public tagsEvent: any = new EventEmitter();
  public CompanyId = new BehaviorSubject(1);
  public companyCreated = new BehaviorSubject("");
  public totalAgents = new BehaviorSubject([]);
  public saveAddress = new BehaviorSubject<{
    latlng: any;
    address: string;
    types: string;
  }>(null);
  public traceLocation = new BehaviorSubject<{ destination: any; source: any }>(
    null
  );
  public switchToggle = new Subject<string>();
  public toggleSidebar = new Subject<string>();
  public removeLink = new Subject<String>();
  public projectId = new BehaviorSubject<any>(null);
  public currentProject = new BehaviorSubject<any>(null);
  // public smsModuleForm=new BehaviorSubject<any>(null);
  // public formSubmitted=new BehaviorSubject<boolean>(null);

  constructor(
    private http: HttpClientService,
    private roleService: RoleService,
    private projectService: ProjectService
  ) { }

  getCountries() {
    return this.http.get<any[]>(`${this.baseApiUrl}allCountries`);
  }

  getProjectAdmin(data): Observable<boolean | any> {
    let userData = JSON.parse(localStorage.getItem("userData"));
    // return this.projectId.pipe(
    //   map((data) => {
    if (data) {
      if (userData["companyId"] === 0) {
        return of(true);
      } else {
        return this.projectService
          .projectAdmin({ projectId: data, userId: userData["id"] })
          .pipe(map((data) => data["admin"]));
      }
    }
    // return of(null);
    // })

    // return of(null);
  }
  getAllCountryList() {
    return this.http.get('https://countriesnow.space/api/v0.1/countries/codes');
  }
  listFeedback(id, menuId: number = 0) {
    // /feedback/add
    return this.http.get(`${this.baseApiUrl2}feedback/activeMenu/${id}/${menuId}`)
  }
  sortByType(feedbackArray, fieldName, order = 'asc') {
    feedbackArray.sort((a, b) => {
      const fieldA = a[fieldName];
      const fieldB = b[fieldName];

      const comparison = order === 'asc' ? 1 : -1;

      if (fieldA > fieldB) {
        return comparison;
      } else if (fieldA < fieldB) {
        return -comparison;
      }

      return 0;
    });

    return feedbackArray;
  }
}
