<!-- <div class="container-fluid">
	<div class="row">
		<div class="col" style="background:pink">02</div>
		<div class="col">03</div>
		<div class="col">04</div>
	</div>
</div> -->
<div class="row">
	<!--Carousel-->
	<div id="carouselExampleDark" class="carousel carousel-dark slide carousel-expand-lg" data-bs-ride="carousel">
		<div class="carousel-indicators">
			<button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="0" class="active"
				aria-current="true" aria-label="Slide 1"></button>
			<button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="1"
				aria-label="Slide 2"></button>
			<button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="2"
				aria-label="Slide 3"></button>
		</div>
		<div class="carousel-inner">
			<div class="carousel-item active" data-bs-interval="10000">
				<img src="../../assets/images/home-banner.png" class="d-block w-100" alt="...">
				<div class="carousel-caption d-none d-md-block">
					<div class="container">
						<div class="col text-start">
							<h3 class="subTitle">The best customer experiences <br />are built with </h3>
							<h1 class="title">Accessque</h1>
							<div class="buttons">
								<a href="#" class="btn btn-primary btn-md" id="know">
									Know More
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="carousel-item" data-bs-interval="2000">
				<img src="../../assets/images/home-banner.png" class="d-block w-100" alt="...">
				<div class="carousel-caption d-none d-md-block">
					<div class="container">
						<div class="col text-center">
							<h3 class="subTitle">The best customer experiences <br />are built with </h3>
							<h1 class="title">Accessque</h1>
							<div class="buttons">
								<a href="#" class="btn btn-primary btn-md" id="know">
									Know More
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="carousel-item">
				<img src="../../assets/images/home-banner.png" class="d-block w-100" alt="...">
				<div class="carousel-caption d-none d-md-block">
					<div class="container">
						<div class="col text-end">
							<h3 class="subTitle">The best customer experiences <br />are built with </h3>
							<h1 class="title">Accessque</h1>
							<div class="buttons">
								<a href="#" class="btn btn-primary btn-md btn-accessQue" id="know">
									Know More
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleDark" data-bs-slide="prev">
			<span class="carousel-control-prev-icon" aria-hidden="true"></span>
			<span class="visually-hidden">Previous</span>
		</button>
		<button class="carousel-control-next" type="button" data-bs-target="#carouselExampleDark" data-bs-slide="next">
			<span class="carousel-control-next-icon" aria-hidden="true"></span>
			<span class="visually-hidden">Next</span>
		</button>
	</div>

	<!--Section AccessQue-->
	<div class="row mt-5">
		<div class="col-md-1 bg-dots"></div>
		<div class="col-8 col-md-5 col-sm-5 ms-5">
			<div class='boldFont orangeText'>
				<h2>AccessQue</h2>
			</div>
			<p class="lightFont w-80 lh130 pt-2">
				The Next generation AI CRM for small, medium, and enterprise businesses for More sales, better service,
				greater customer insight. </p>
			<p class="lightFont w-80 lh130">We Generate insights, scale marketing efforts, boost ROI.Customize to your
				needs.</p>
		</div>
		<div class="col-lg-3 col-md-4 col-sm-4 text-end nomobile">
			<img class='image reveal fade-bottom' src='../../assets/images/info-graphic1.png'>
		</div>
	</div>


	<!--Why AccessQue-->
	<div class="row mt-5 b-blue">
		<div class='col bg-dots-w'>
			<div class='mt-4'>
				<div class='text-center white'>
					<h2>Why AccessQue ?</h2>
				</div>
			</div>
			<div class="whyAccess">
				<div class="whyAccess_left btn-tab">
					<button class="tablinks2 active " onclick="openCity2(event, 'lm')"><img
							src='../../assets/images/xcall-3.png'>
						<p>Instant Calls</p>
					</button>
					<button class="tablinks2 " onclick="openCity2(event, 'rtl')"><img
							src='../../assets/images/chat-3.png'>
						<p>Live Chat</p>
					</button>
					<button class="tablinks2 " onclick="openCity2(event, 'sim')"><img
							src='../../assets/images/reports-3.png'>
						<p>Social</p>
					</button>
					<button class="tablinks2 " onclick="openCity2(event, 'ai')"><img
							src='../../assets/images/email-3.png'>
						<p>Mails</p>
					</button>
					<button class="tablinks2 " onclick="openCity2(event, 'ml')"><img
							src='../../assets/images/audio-3.png'>
						<p>Integrated Voice</p>
					</button>
					<button class="tablinks2 " onclick="openCity2(event, 'il')"><img
							src='../../assets/images/tickets-3.png'>
						<p>Tickets</p>
					</button>
				</div>
				<div class="whyAccess_right tabcontent-ctr">
					<div id="lm" class="tabcontent2 bg-dots-primary" style='display: block;'>
						<div class='p-3'>
							<h3 class='primary'>Instant Calls</h3>
							<p class="pt-2 lh130">Accessque Guide is a smart knowledge base that helps you harness the
								power of institutional knowledge.</p>
						</div>
					</div>

					<div id="rtl" class="tabcontent2 bg-dots-primary">
						<div class='p-3'>
							<h3 class='primary'>Live Chat</h3>
							<p class="pt-2 lh130">Create a personal connection with
								customers looking for support. It’s fast and effective</p>
						</div>
					</div>

					<div id="sim" class="tabcontent2 bg-dots-primary">
						<div class='p-3'>
							<h3 class='primary'>Social messaging</h3>
							<p class="pt-2 lh130">Be where they want to be — connect popular messaging apps to
								Accessque.</p>
						</div>
					</div>

					<div id="ai" class="tabcontent2 bg-dots-primary">
						<div class='p-3'>
							<h3 class='primary'>Mails</h3>
							<p class="pt-2 lh130">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur sit
								amet ipsum quis nulla viverra auctor.</p>
							<p class="lh130">

								Ut orci dolor, porttitor a consequat sed, ultrices nec erat. Aliquam tempus.Lorem ipsum
								dolor sit
								amet, consectetur adipiscing elit. Curabitur sit amet ipsum quis nulla viverra
								auctor.Lorem ipsum
								dolor sit amet, consectetur adipiscing elit. Curabitur sit amet ipsum quis nulla viverra
								auctor.</p>
						</div>
					</div>

					<div id="ml" class="tabcontent2 bg-dots-primary">
						<div class='p-3'>
							<h3 class='primary'>Integrated Voice</h3>
							<p class="pt-2 lh130">Talk it out directly with customers and provide personalized solutions
								to complex issues.</p>
						</div>
					</div>

					<div id="il" class="tabcontent2 bg-dots-primary">
						<div class='p-3'>
							<h3 class='primary'>Ticketing system</h3>
							<p class="pt-2 lh130">Track, prioritize, and solve customer support tickets all in one
								place.</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

	<!-- The Way it works -->
	<div class='row hideScroll'>
		<div class='col-md-12'>
			<div class='pt-5'>
				<div class='text-center orangeText'>
					<h2>The way it Works</h2>
				</div>
			</div>
		</div>
		<div class='row bg-circle2 mt-5'>
			<div class='col-md-3 text-center reveal fade-bottom tww_items pb-5'>
				<img src='../../assets/images/enter.png'>
				<div class='md-txt p-2'><span class="lightFont">Login to</span><span class="semiBoldFont">
						AccessQue</span>
				</div>
			</div>
			<div class='col-md-3 text-center reveal fade-bottom tww_items pt-3 pb-5'>
				<img src='../../assets/images/experiment.png'>
				<div class='md-txt p-2'><span class="lightFont">Select</span><span class="semiBoldFont"> Plan</span>
				</div>
			</div>
			<div class='col-md-3 text-center reveal fade-bottom tww_items pb-5'>
				<img src='../../assets/images/star-business.png'>
				<div class='md-txt p-2'><span class="lightFont">Start</span> <span class="semiBoldFont"> Business</span>
				</div>
			</div>
			<div class='col-md-3 b-w text-center reveal fade-right tww_items pb-5'>
				<img src='../../assets/images/results.png'>
				<div class='md-txt p-2'><span class="lightFont">Run</span><span class="semiBoldFont"> Business</span>
				</div>
			</div>
		</div>
	</div>

	<!--Price block starts-->
	<div class='row mt-5 bgLinear'>
		<div class='col-md-12'>
			<div class='p-3 pt-5'>
				<div class='text-center orangeText'>
					<h2>Price</h2>
				</div>
			</div>
		</div>
		<div class="bg-dots2 mb-3 mt-3">
			<div class='price-block reveal fade-left pr'>
				<div class="boldFont lightOrange">Basic</div>
				<ul class="featuresList mt-4">
					<li>User management </li>
					<li>Lead management </li>
					<li>Calls</li>
					<li>Chats</li>
					<li>Mails</li>
					<li>Company management </li>
					<li>Progect Management</li>
				</ul>
				<div class="price-bottom text-start">
					<div class="small-text pl-4">2 Users Minimum</div>
					<button type="button" class='price-btn mt-4'>Contact Us</button>
				</div>
			</div>
			<div class='price-block reveal fade-bottom pr'>
				<div class="boldFont lightOrange">Standarad</div>
				<ul class="featuresList mt-4">
					<li>User management </li>
					<li>Lead management </li>
					<li>Call Management</li>
					<li>Chats</li>
					<li>Mails</li>
					<li>Call recordings</li>
					<li>Reports</li>
					<li>Incident Management</li>
					<li>Company Management</li>
					<li>Progect Management</li>
					<li>Marketing</li>
				</ul>
				<div class="price-bottom text-start">
					<div class="small-text pl-4">3 Users Minimum</div>
					<button type="button" class='price-btn mt-4'>Contact Us</button>
				</div>
			</div>
			<div class='price-block reveal fade-right pr'>
				<div class="boldFont lightOrange">Premium</div>
				<ul class="featuresList mt-4">
					<li>Company management</li>
					<li>Progect Management</li>
					<li>User management</li>
					<li>Lead management</li>
					<li>Call Management</li>
					<li>Chats Management</li>
					<li>Mails</li>
					<li>Call recordings</li>
					<li>Reports</li>
					<li>Incident Management</li>
					<li>Referall Management</li>
					<li>BI Analytics</li>
					<li>Calendar</li>
					<li>Appointments</li>
					<li>Marketing</li>
					<li>Sales</li>
					<li>Website Analysis</li>
				</ul>
				<div class="price-bottom text-start">
					<div class="small-text pl-4">6 Users Minimum</div>
					<button type="button" class='price-btn mt-4'>Contact Us</button>
				</div>
			</div>

			<div class='price-block reveal fade-right pr'>
				<div class="boldFont lightOrange">Pro</div>
				<ul class="featuresList mt-4">
					<li>Company management</li>
					<li>Progect Management</li>
					<li>User management</li>
					<li>Lead management</li>
					<li>Call Management</li>
					<li>Chats</li>
					<li>Mails</li>
					<li>Call recordings</li>
					<li>Reports</li>
					<li>Incident Management</li>
					<li>Referall Management</li>
					<li>BI Analytics</li>
					<li>AI Voice Bots(English)</li>
					<li>Agent Monitoring</li>
					<li>Ai Call Analytics</li>
					<li>Appointments</li>
					<li>Marketing</li>
					<li>Sales</li>
					<li>AI Incident Analytics</li>
					<li>Website Analysis</li>
				</ul>
				<div class="price-bottom text-start">
					<div class="small-text pl-4">10 Users Minimum</div>
					<button type="button" class='price-btn mt-4'>Contact Us</button>
				</div>
			</div>

			<div class='price-block reveal fade-right pr'>
				<div class="boldFont lightOrange">Enterprise</div>
				<ul class="featuresList mt-4">
					<li>Company management</li>
					<li>Progect Management</li>
					<li>User management</li>
					<li>Lead management</li>
					<li>Call Management</li>
					<li>Chats</li>
					<li>Mails</li>
					<li>Call recordings</li>
					<li>Reports</li>
					<li>Incident Management</li>
					<li>Referall Management</li>
					<li>BI Analytics</li>
					<li>AI Voice Bots(English)</li>
					<li>Agent Monitoring</li>
					<li>Ai Call Analytics</li>
					<li>Appointments</li>
					<li>Marketing</li>
					<li>Sales</li>
					<li>Work Automation Bots</li>
					<li>Website Analysis</li>
				</ul>
				<div class="price-bottom text-start">
					<div class="small-text pl-4">15 Users Minimum</div>
					<button type="button" class='price-btn mt-4'>Contact Us</button>
				</div>
			</div>
		</div>
	</div>

	<!--Our Clients-->
	<div class="row mt-5">
		<div class="col-md-12">
			<div class="swiper-container">
				<div class="swiperBg"></div>
				<div class="swiperBg swiperBg2"></div>
				<div class="swiperBg swiperBg3"></div>
				<div class='text-center orangeText m-3'>
					<h2>Our Clients</h2>
				</div>

				<div class="container">
					<div class="row">
						<div class="col-md-3 col-sm-6 col-xs-12">
							<div class="client-img2">
								<img src="../../assets/images/clients/2.png" alt="">
							</div>
						</div>
						<div class="col-md-3 col-sm-6 col-xs-12">
							<div class="client-img2">
								<img src="../../assets/images/clients/3.png" alt="">
							</div>
						</div>
						<div class="col-md-3 col-sm-6 col-xs-12">
							<div class="client-img2">
								<img src="../../assets/images/clients/1.png" style="width:210px;" alt="">
							</div>
						</div>
						<div class="col-md-3 col-sm-6 col-xs-12">
							<div class="client-img2">
								<img src="../../assets/images/clients/32-dentalcare.png" alt="">
							</div>
						</div>
					</div>
					<div class="row pb-5">
						<div class="col-md-3 col-sm-6 col-xs-12">
							<div class="client-img2">
								<img src="../../assets/images/clients/academious.png" alt="">
							</div>
						</div>
						<div class="col-md-3 col-sm-6 col-xs-12">
							<div class="client-img2">
								<img src="../../assets/images/clients/insol.png" alt="">
							</div>
						</div>
						<div class="col-md-3 col-sm-6 col-xs-12">
							<div class="client-img2">
								<img src="../../assets/images/clients/rorotel.png" alt="">
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

	<!--Customers Feedback-->
	<div class='row whiteBg hideScroll'>
		<div class="titleFeedback">

			<div class="feedbackTitle mt-3 ml-5">
				<h2>Customer Feedback</h2>
			</div>
			<div class="feedbackBtn">
				<button type="button" class="btn btn-outline-secondary me-2" onclick="location.href = '#'">←</button>
				<button type="button" class="btn btn-secondary">→</button>
			</div>
		</div>
		<div class='col-md-12'>
			<div class='col'>
				<div class="feedBack mt-5 m-3">
					<!--Feedbc windows-->
					<div class="feedbackWindow fbOrange reveal fade-left">
						<ul class="stars mt-3">
							<li></li>
							<li></li>
							<li></li>
							<li></li>
							<li class="empty"></li>
						</ul>
						<p>
							Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
							labore et
							dolore magna aliqua. Ut enim ad minim veniam
						</p>
						<div class="customerFeedback">
							<img src="../../assets/images/cust_01.jpeg" alt="customername" />
							<span>
								<h5>Pancy.Liam</h5>Lorem ipsum
							</span>
						</div>
					</div>

					<div class="feedbackWindow fbWhite reveal fade-bottom">
						<ul class="stars mt-5">
							<li></li>
							<li></li>
							<li></li>
							<li></li>
							<li class="empty"></li>
						</ul>
						<p>
							Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
							labore et
							dolore magna aliqua. Ut enim ad minim veniam
						</p>
						<div class="customerFeedback">
							<img src="../../assets/images/cust_01.jpeg" alt="customername" />
							<span>
								<h5>Pancy.Liam</h5>Lorem ipsum
							</span>
						</div>
					</div>

					<div class="feedbackWindow fbDark reveal fade-right">
						<ul class="stars mt-3">
							<li></li>
							<li></li>
							<li></li>
							<li></li>
							<li class="empty"></li>
						</ul>
						<p>
							Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
							labore et
							dolore magna aliqua. Ut enim ad minim veniam
						</p>
						<div class="customerFeedback">
							<img src="../../assets/images/cust_01.jpeg" alt="customername" />
							<span>
								<h5>Pancy.Liam</h5>Lorem ipsum
							</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>


	<!-- Students outlook starts -->
	<div class='row bg-dots mt-5 mb-5'>
		<div class='col-md-12'>
			<div class='p-3 '>
				<div class='text-center orangeText'>
					<h2>Testimonails </h2>
				</div>
			</div>
		</div>


		<div class='col'>
			<div class="testiMonials">
				<div class='testi-block reveal fade-in'>
					<div class="test-left">
						Lorem ipsum dolor sit amet, con sec tetur adipiscing elit. Suspe ndisse eleif end...
						<br />
						<b>- Sunile Rathod</b>
					</div>
					<div class="test-right ">
						01.
					</div>
				</div>
				<div class='testi-block reveal fade-in'>
					<div class="test-left">
						Lorem ipsum dolor sit amet, con sec tetur adipiscing elit. Suspe ndisse eleif end...
						<br />
						<b>- Sunile Rathod</b>
					</div>
					<div class="test-right">
						02.
					</div>
				</div>
				<div class='testi-block reveal fade-in'>
					<div class="test-left">
						Lorem ipsum dolor sit amet, con sec tetur adipiscing elit. Suspe ndisse eleif end...
						<br />
						<b>- Sunile Rathod</b>
					</div>
					<div class="test-right">
						03.
					</div>
				</div>
				<div class='testi-block reveal fade-in'>
					<div class="test-left">
						Lorem ipsum dolor sit amet, con sec tetur adipiscing elit. Suspe ndisse eleif end...
						<br />
						<b>- Sunile Rathod</b>
					</div>
					<div class="test-right">
						04.
					</div>
				</div>
				<div class='testi-block reveal fade-in'>
					<div class="test-left">
						Lorem ipsum dolor sit amet, con sec tetur adipiscing elit. Suspe ndisse eleif end...
						<br />
						<b>- Sunile Rathod</b>
					</div>
					<div class="test-right">
						05.
					</div>
				</div>
			</div>
		</div>
	</div>

</div>