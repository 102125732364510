<body style="background:pink">
  <div class="containers">
    <div class="d-flex p-4 justify-content-between">
      <div class="d-flex mr-2" style="width: 49%; float: left; border: 0px dashed #ccc">
        <div class="box-content-wrapper-one2 mr-2" style="background: none; box-shadow: none">
          <div style="width: 100%">
            <div class="db-sm-header mb-2" style="display: block">
              Social Media
            </div>
            <div class="box-content-wrapper-one2 mr-2 pt-4" style="
              width: 95%;
              height: 100px;
              justify-content: space-evenly;
              cursor: pointer;
            " (click)="sectionLeads('facebook')">
              <div clas="d-flex flex-row">
                <img src="../../../../assets/img/facebook-c.svg" class="img50" />
              </div>
              <div clas="d-flex flex-row text-center">
                <p class="box-number-text primary">
                  {{ dashboardInfo["Facebook"] }}
                </p>
                <div class="box-sub-text1 text-center" style="text-transform:capitalize">
                  {{ counselling ? "Counselling" : "leads" }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="box-content-wrapper-one2 mr-2" style="background: none; box-shadow: none">
          <div style="width: 100%">
            <div class="db-sm-header mb-2" style="display: block; text-align: right"></div>
            <div class="box-content-wrapper-one2 pt-4" style="
              width: 95%;
              height: 100px;
              justify-content: space-evenly;
              cursor: pointer;
            " (click)="sectionLeads('Website')">
              <div clas="d-flex flex-row">
                <img src="../../../../assets/img/universityhub.jpeg" class="img50" />
              </div>
              <div clas="d-flex flex-row text-center">
                <p class="box-number-text primary">
                  {{ dashboardInfo["Website"] }}
                </p>
                <div class="box-sub-text1 text-center" style="text-transform:capitalize">
                  {{ counselling ? "Counselling" : "leads" }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex" style="width: 48%; float: left; border: 0px dashed #000">
        <div class="box-content-wrapper-one2 mr-2" style="background: none; box-shadow: none">
          <div style="width: 100%">
            <div class="db-sm-header mb-2" style="display: block">Activity</div>
            <div class="box-content-wrapper-one2 pt-4" style="
              width: 95%;
              height: 100px;
              justify-content: space-evenly;
              cursor: pointer;
            " (click)="sectionLeads('followup')">
              <div clas="d-flex flex-row">
                <img src="../../../../assets/img/tick-green.svg" class="img50" />
              </div>
              <div clas="d-flex flex-row text-center">
                <p class="box-number-text primary">
                  {{ dashboardInfo["followup"] }}
                </p>
                <div class="box-sub-text1 text-center">Follow Up</div>
              </div>
            </div>
          </div>
        </div>
        <div class="box-content-wrapper-one2 mr-2" style="background: none; box-shadow: none">
          <div style="width: 100%">
            <div class="db-sm-header mb-2" style="display: block"></div>
            <div class="box-content-wrapper-one2 pt-4" style="
              width: 95%;
              height: 100px;
              justify-content: space-evenly;
              cursor: pointer;
            " (click)="sectionLeads('pending')">
              <div clas="d-flex flex-row">
                <img src="../../../../assets/img/pending.svg" class="img50" />
              </div>
              <div clas="d-flex flex-row text-center">
                <p class="box-number-text primary">
                  {{ dashboardInfo["pending"] }}
                </p>
                <div class="box-sub-text1 text-center">Pending Calls</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- attachment bar hidden -->
    <div class="content-containers" style="margin-left: 30px; margin-right: 30px">
      <input type="file" (change)="convertFile($event)" style="display: none" #uploadFile accept=".xlsx" />
      <div class="mt-3">
        <div class="d-flex float-left">
          <h3 class="header-md">
            Latest Leads
          </h3>
        </div>
      </div>
      <div class="d-flex justify-content-end mt-3" *ngIf="
        (statusValue === 'pending' && leadType === 'pending') ||
        (statusValue === 'notanswered' && leadType === 'notanswered')
      ">
        <div>
          <div class="float-left">
            <div *ngIf="filePath" class="form-control" style="
              height: 16px;
              padding: 0px;
              background: none;
              font-size: 13px;
              color: #999;
              border: 0;
            ">
              {{ filePath }}
            </div>
            <div class="upload-msg-success" *ngIf="uploaded" style="color: green; font-size: 10px">
              {{ insertCount + " " + "Contacts inserted" }}
            </div>
            <div class="upload-msg-error" *ngIf="error">
              {{ errorMessage }}
            </div>
          </div>
          <button class="btn btn-secondary btn-sm float-left" (click)="redirectToUpload()" ngbTooltip="Attachments"
            placement="top" tooltipClass="my-custom-class">
            <img src="../../../../assets/img/attach-w.svg" class="img13" />
          </button>
        </div>
        <a href="{{ downloadLink }}" class="btn btn-secondary btn-sm ml-2" ngbTooltip="Download Sample" placement="top"
          tooltipClass="my-custom-class"><img src="../../../../assets/img/download-w.svg" style="width: 13px" /></a>
        <button class="btn btn-secondary btn-sm ml-2" ngbTooltip="Start Call" placement="top"
        tooltipClass="my-custom-class" (click)="startCallListener()">

        <img src="../../../../assets/img/start-call-w.svg" style="width: 13px" />
      </button>
        <button class="btn btn-secondary btn-sm ml-2" ngbTooltip="Create Lead" placement="top"
          tooltipClass="my-custom-class" (click)="openCreateLead(content3)">

          <img src="assets/img/add-w.svg" style="width: 13px" />
        </button>
        <button *ngIf="!admin" class="btn btn-secondary btn-sm ml-2" ngbTooltip="Stop Call" placement="top"
          tooltipClass="my-custom-class" (click)="stopCallListener()">
          <!-- *ngIf="!admin" -->
          <!-- *ngIf="!admin" -->
          <img src="../../../../assets/img/stop-call-w.svg" style="width: 13px" />
        </button>
      </div>
      <div class="d-flex justify-content-end mt-3" *ngIf="
        statusValue === 'demo-interested' ||
        statusValue === 'demo-notinterested'
      ">
        <span class="badge badge-pill badge-warning">D*-Demo</span>
      </div>
      <div style="clear: both"></div>
    </div>

    <!-- attachment bar hidden ends -->
    <!-- <div class="header-md pt-4" style="padding-left: 30px">Latest Leads</div> -->


    <div class="right-content-right-box-bottom-container">
      <div class="bottom-content-box-header-container">
        <div class="ticket-requested-list-container">
          <div class="requsted-list-heading-container">
            <div class="table-scroll data-table">
              <table class="table table-md xtable-bordered">
                <thead style="position: relative">
                  <tr>
                    <td colspan="10" style="padding: 8px">
                      <div class="table-search-content indived-search">
                        <div class="searchall-parent">
                          <input type="text" (input)="onInput($event)" class="form-control search-all"
                            [(ngModel)]="search" placeholder="Search " autocomplete="off" />
                        </div>
                        <div class="pagenation-right d-flex">
                          <select class="form-select mx-2 form-select-dark" [(ngModel)]="statusValue"
                            (ngModelChange)="dropdownSelect($event)">
                            <option value="Website">All</option>
                            <option [value]="item.value" *ngFor="let item of listFeed">{{item.name}}</option>
                          </select>
                          <ngb-pagination [(page)]="page" [pageSize]="pageSize" [collectionSize]="callData.length"
                            [maxSize]="5" [boundaryLinks]="true" class="pagenation-right"></ngb-pagination>
                        </div>
                      </div>
                    </td>
                  </tr>
                </thead>

                <tbody>
                  <tr>
                    <th *ngIf="statusValue === 'pending' ||
                      statusValue === 'notanswered' ||
                      statusValue==='callback'||
                      statusValue === 'qualifiedhotlead'||
                      statusValue === 'interested'||
                      statusValue === 'futurelead'||
                      statusValue === 'Website'||
                      statusValue === 'notinterested'||
                      statusValue === 'callfailed'||
                      statusValue === 'poorenglish'||
                      statusValue === 'interestedpending'||
                      statusValue === 'duplicate'">
                      Created Date
                      <img class=" img7" src="../../../../assets/img/sort-data.svg" style="cursor: pointer"
                        (click)="sortOrder('createdAt')" />
                    </th>
                    <th>
                      Name
                      <img class="img7" src="../../../../assets/img/sort-data.svg" style="cursor: pointer"
                        (click)="sortOrder('FirstName')" />
                    </th>
                    <th>
                      Email
                      <img class="img7" src="../../../../assets/img/sort-data.svg" style="cursor: pointer"
                        (click)="sortOrder('Email')" />
                    </th>
                    <th>
                      Phone Number

                      <img class="img7" src="../../../../assets/img/sort-data.svg" style="cursor: pointer"
                        (click)="sortOrder('PhoneNumber')" />
                    </th>
                    <th>
                      Form Name
                      <img class="img7" src="../../../../assets/img/sort-data.svg" style="cursor: pointer"
                        (click)="sortOrder('Module')" />
                    </th>
                    <th *ngIf="
                      statusValue === 'pending' ||
                      statusValue === 'notanswered' ||
                      statusValue === 'Website' ||
                      statusValue === 'callfailed' ||
                      statusValue === 'poorenglish' ||
                      statusValue === 'interestedpending' ||
                      statusValue === 'duplicate'">
                      City
                      <img class="img7" src="../../../../assets/img/sort-data.svg" style="cursor: pointer"
                        (click)="sortOrder('City')" />
                    </th>
                    <th>Education</th>

                    <th *ngIf="admin">
                      Assigned To
                      <img class="img7" src="../../../../assets/img/sort-data.svg" style="cursor: pointer"
                        (click)="sortOrder('Assignedto')" />
                    </th>
                    <th>Status</th>
                    <th *ngIf="
                      statusValue !== 'pending' &&
                      statusValue !== 'notanswered' &&
                      statusValue !== 'Website' &&
                      statusValue !== 'notinterested' &&
                      statusValue !== 'existingcounselling' &&
                      statusValue !== 'callfailed' &&
                      statusValue !== 'poorenglish' &&
                      statusValue !== 'interestedpending' &&
                      statusValue !== 'duplicate'">
                      {{statusValue === "callback" || statusValue === "futurelead"
                      ? "CallBack Date"
                      : "Counselling Date"
                      }}
                    </th>
                    <th>Actions</th>
                  </tr>
                  <tr *ngFor="
                    let item of callData
                      | filter: search
                      | slice: (page - 1) * pageSize:page * pageSize
                      | sort: orderFeild:orderString;
                    let id = index
                  ">
                    <td class="vm" *ngIf="statusValue === 'pending' ||
                  statusValue === 'notanswered' ||
                  statusValue==='callback'||
                  statusValue === 'qualifiedhotlead'||
                  statusValue === 'interested'||
                  statusValue === 'futurelead'||
                  statusValue === 'Website'||    
                  statusValue === 'callfailed'||
                  statusValue === 'poorenglish'||
                  statusValue === 'interestedpending'||
                  statusValue === 'duplicate'||
                  statusValue === 'notinterested'">
                      <ng-container>
                        {{setTimeZoneToZero(item["createdAt"])}}
                      </ng-container>
                    </td>
                    <td class="vm" style="
                      text-decoration: none;
                      color: #163252;
                      text-transform: capitalize;
                      cursor: pointer;
                    " (click)="updateComments(content2, item)">
                      {{ item["FirstName"] + " " + (item["LastName"] || "") | titlecase}}
                    </td>
                    <td class="vm">{{ item["Email"] || "-" }}</td>
                    <td class="vm" [ngClass]="{ error: item['PhoneNumber'].length > 10 }">
                      +
                      {{ item["CountryCode"] + item["PhoneNumber"] }}
                    </td>
                    <td class="vm">
                      {{ (item["Miscellaneous"]["form_name"] | formName) || "-" }}
                    </td>
                    <td class="vm"
                      *ngIf="statusValue === 'pending'
                    ||statusValue === 'notanswered'||
                    statusValue === 'Website' || statusValue === 'callfailed' || statusValue === 'poorenglish' || statusValue === 'interestedpending'|| statusValue === 'duplicate'">
                      {{ item["City"] || "-" }}
                    </td>
                    <td class="vm">
                      {{ item["Education"] ? item["Education"] : "-" }}
                    </td>

                    <td class="vm" *ngIf="admin">
                      {{ item["Assignedto"] || "-" }}
                    </td>
                    <td class="vm" *ngIf="statusValue !== 'demo-interested'">
                      <ng-container *ngIf="statusValue !== 'demo-notinterested'">
                        <div *ngIf="
                          statusValue === 'pending' ||
                          statusValue === 'notanswered' ||
                          statusValue === 'Website' ||
                          statusValue === 'notinterested' ||
                          statusValue === 'callfailed' ||
                          statusValue === 'poorenglish' ||
                          statusValue === 'interestedpending' ||
                          statusValue === 'duplicate'" [ngClass]="{
                          'status-inprogress': item['CallStatus'] === 'pending',
                          'status-closed':
                            item['CallStatus'] === 'notanswered' ||
                            item['CallStatus'] === 'notinterested' ||
                            item['CallStatus'] === 'callfailed' ||
                            item['CallStatus'] === 'poorenglish' ||
                            item['CallStatus'] === 'interestedpending' ||
                            item['CallStatus'] === 'duplicate' ||
                            item['CallStatus'] === 'callback',
                          'status-open':
                            item['CallStatus'] === 'answered' ||
                            item['CallStatus'] === 'interested' ||
                            item['CallStatus'] === 'called'}">
                          {{ item["CallStatus"] | textpipe | titlecase }}
                        </div>
                        <div *ngIf="
                          statusValue !== 'pending'&&
                          statusValue !== 'notanswered'&&
                          statusValue !== 'Website'&&
                          statusValue !== 'notinterested'&&
                          statusValue !== 'futurelead'&&
                          statusValue !== 'existingcounselling' &&
                          statusValue !== 'callfailed' &&
                          statusValue !== 'poorenglish' &&
                          statusValue !== 'interestedpending' &&
                          statusValue !== 'duplicate'
                        " [ngClass]="{
                          'status-inprogress': item['DemoScheduled'] === null,
                          'status-open': item['DemoScheduled'] !== null
                        }">
                          {{
                          item["DemoScheduled"] === null
                          ? "CallBack"
                          : "Counselling"
                          }}
                        </div>
                        <div *ngIf="statusValue === 'futurelead'" [ngClass]="{
                          'status-inprogress': item['DemoScheduled'] === null,
                          'status-open': item['DemoScheduled'] !== null
                        }">
                          {{
                          item["DemoScheduled"] === null
                          ? "Future Lead"
                          : "Counselling"
                          }}
                        </div>
                        <div *ngIf="statusValue === 'existingcounselling'" [ngClass]="{
                          'status-inprogress': item['Phase'] !== 'demo',
                          'status-open': item['Phase'] === 'demo'
                        }">
                          {{
                          item["Phase"] === "demo"
                          ? "Counselling"
                          : item["Phase"] === "followup"
                          ? "CallBack"
                          : "Future Lead"
                          }}
                        </div>
                      </ng-container>
                      <ng-container *ngIf="statusValue === 'demo-notinterested'">
                        <div class="status-inprogress">
                          {{ item["Phase"] | statusvalue }}
                        </div>
                      </ng-container>
                    </td>

                    <td class="vm" *ngIf="
                      statusValue !== 'pending' &&
                      statusValue !== 'notanswered' &&
                      statusValue !== 'Website' &&
                      statusValue !== 'notinterested' &&
                      statusValue !== 'existingcounselling'&&
                      statusValue !== 'callfailed' &&
                      statusValue !== 'poorenglish' &&
                      statusValue !== 'interestedpending' &&
                      statusValue !== 'duplicate'
               ">
                      <ng-container *ngIf="
                        statusValue !== 'pending' &&
                        statusValue !== 'notanswered' &&
                        statusValue !== 'Website' &&
                        statusValue !== 'callfailed' &&
                        statusValue !== 'poorenglish' &&
                        statusValue !== 'interestedpending' &&
                        statusValue !== 'duplicate'">
                        {{
                        item["DemoScheduled"] === null
                        ? (item["FollowUpdate"] | date: "medium")
                        : (item["DateTime"] | date: "medium")
                        }}
                      </ng-container>
                      <ng-container *ngIf="statusValue === 'demo-notinterested'">
                        {{setTimeZoneToZero(item["createdAt"])}}
                      </ng-container>
                      <ng-container *ngIf="statusValue === 'demo-interested'">
                        <span *ngIf="item['RequestSent']">
                          {{
                          item["Status"] === "NotSigned"
                          ? (item["RequestSent"] )
                          : (item["SignedAt"] | date: "medium")
                          }}
                        </span>
                        <span *ngIf="!item['RequestSent']">
                          {{ item["DateTime"] | date: "medium" }}
                        </span>
                      </ng-container>
                      <ng-container *ngIf="statusValue === 'notinterested'">
                        {{ item["updatedAt"] | date: "medium" }}
                      </ng-container>
                    </td>
                    <td class="vm">
                      <img (click)="showCallDetails(content1, item['id'])" class="img7 img-hover"
                        src="assets/img/view2.svg" ngbTooltip="View Details" placement="top"
                        tooltipClass="my-custom-class" />

                      <img (click)="followUpCall(item)" class="img11" style="margin: 0 5px" src="assets/img/calls.svg"
                        ngbTooltip="Follow Up Call" placement="top" tooltipClass="my-custom-class" />

                      <!-- *ngIf="!admin" -->

                      <ng-container *ngIf="item['RecordingURL']">
                        <ng-container *ngIf="statusValue !== 'demo-notinterested'">
                          <i class="fas fa-play img-hover" style="
                            color: #999999;
                            font-size: 11px;
                            margin-right: 0;
                          " #icon (click)="audioPlay(audio, icon)"></i>
                          <audio style="height: 1.5rem; width: 14rem; display: none" [src]="item['RecordingURL']" #audio
                            controls controlsList="nodownload"></audio>
                        </ng-container>
                      </ng-container>

                      <ng-container *ngIf="statusValue !== 'pending' &&
                        statusValue !== 'notanswered' &&
                        statusValue !== 'Website' &&
                        statusValue !== 'callfailed' &&
                        statusValue !== 'poorenglish' &&
                        statusValue !== 'interestedpending' &&
                        statusValue !== 'duplicate'">
                        <a href="{{ item['URL'] }}" target="__blank">
                          <i *ngIf="item['DemoScheduled'] !== null" class="fas fa-video img-hover"
                            style="color: #999999; font-size: 12px" ngbTooltip="Demo Link" placement="top"
                            tooltipClass="my-custom-class"></i>
                        </a>
                      </ng-container>

                      <ng-container *ngIf="statusValue === 'demo-interested'">
                        <ng-container *ngIf="item['URL']">
                          <a href="{{ item['URL'] }}" target="__blank">
                            <i *ngIf="item['DemoScheduled'] !== null" class="fas fa-video img-hover"
                              style="color: #999999; font-size: 12px" ngbTooltip="Demo Link" placement="top"
                              tooltipClass="my-custom-class"></i>
                          </a>
                          <img *ngIf="item['DemoScheduled'] === null" (click)="followUpCall(item)"
                            class="img7 img-hover" src="assets/img/chat-phone-icon.png" ngbTooltip="Follow Up Call"
                            placement="top" tooltipClass="my-custom-class" />
                        </ng-container>
                      </ng-container>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>



  <!-- view details  -->
  <ng-template #content1 let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Overview</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body" style="max-height: 600px; background: #e7e7e7">
      <div class="">
        <!-- new style start-->
        <div class="profile-block">
          <div class="header-12 pb-2">Caller Details</div>
          <div class="form-row">
            <div class="form-group col-md-4">
              <div class="d-flex">
                <div class="font-weight-bold mb-2">
                  <img ngbTooltip="Name" placement="top" tooltipClass="my-custom-class"
                    src="../../../../assets/img/user-profile.svg" class="img16 mr-2" />
                </div>
                <div class="mt-1" *ngIf="currentUser">
                  {{
                  currentUser["FirstName"] ||
                  "" + " " + currentUser["LastName"] ||
                  " "
                  }}
                </div>
              </div>
              <div class="d-flex">
                <div class="font-weight-bold mb-2">
                  <img ngbTooltip="City" placement="top" tooltipClass="my-custom-class"
                    src="../../../../assets/img/city.svg" class="img16 mr-2" />
                </div>
                <div class="mt-1" *ngIf="currentUser">
                  <span>{{ currentUser["City"] || "-" }}</span>
                </div>
              </div>
            </div>
            <div class="form-group col-md-4">
              <div class="d-flex">
                <div class="font-weight-bold mb-2">
                  <img ngbTooltip="Email" placement="top" tooltipClass="my-custom-class"
                    src="../../../../assets/img/email-g.svg" class="img16 mr-2" />
                </div>
                <div class="mt-0" *ngIf="currentUser">
                  {{ currentUser["Email"] }}
                </div>
              </div>
              <div class="d-flex">
                <div class="font-weight-bold mb-2">
                  <img ngbTooltip="Phone" placement="top" tooltipClass="my-custom-class"
                    src="../../../../assets/img/call.svg" class="img16 mr-2" />
                </div>
                <div class="mt-1" *ngIf="currentUser">
                  +{{
                  (currentUser["CountryCode"] || "") +
                  currentUser["PhoneNumber"]
                  }}
                </div>
              </div>
            </div>
            <div class="form-group col-md-4">
              <div class="d-flex">
                <div class="font-weight-bold mb-2">
                  <img ngbTooltip="Created Date" placement="top" tooltipClass="my-custom-class"
                    src="../../../../assets/img/created-date.svg" class="img16 mr-2" />
                </div>
                <div class="mt-1" *ngIf="currentUser">
                  {{ setTimeZoneToZero(currentUser["createdAt"])}}
                </div>
              </div>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-4">
              <label>Channel</label>
              <div class="font-weight-bold" *ngIf="currentUser">
                {{ currentUser && currentUser["Channel"] ? currentUser["Channel"] : "-" }}
              </div>
            </div>
            <div class="form-group col-md-4" style="margin-bottom: 5px">
              <label>Assigned To</label>
              <div class="font-weight-bold" *ngIf="currentUser">{{ currentUser["Assignedto"] }}</div>
            </div>
            <div class="form-group col-md-4">
              <label>Form Id</label>
              <div class="font-weight-bold" *ngIf="currentUser">
                {{ currentUser["Miscellaneous"]["form_id"] || "-" }}
              </div>
            </div>
            <div class="form-group col-md-4" style="margin-bottom: 5px">
              <label>Education</label>
              <div class="font-weight-bold">
                {{
                currentUser && currentUser["Education"] != null
                ? currentUser["Education"]
                : "-"
                }}
              </div>
            </div>
            <div class="form-group col-md-4" style="margin-bottom: 5px">
              <label>Form Name</label>
              <div class="font-weight-bold">
                {{ currentUser && currentUser["Miscellaneous"]["form_name"] || "-" }}
              </div>
            </div>
            <div class="form-group col-md-4" style="margin-bottom: 5px" *ngIf="
              currentUser && currentUser['Miscellaneous'] &&
              currentUser['Miscellaneous']['form_name']
                .toLowerCase()
                .includes('usa')">
              <label>Desired Start Term</label>
              <div class="font-weight-bold">
                {{ currentUser?.Miscellaneous["Desired Start Term"] || "-" }}
              </div>
            </div>
            <div class="form-group col-md-4" style="margin-top: 5px" *ngIf="
            currentUser && currentUser['Miscellaneous'] &&
              currentUser['Miscellaneous']['form_name']
                .toLowerCase()
                .includes('usa')">
              <label>English Proficiency</label>
              <div class="font-weight-bold">
                {{ currentUser["Miscellaneous"]["English Proficiency"] || "-" }}
              </div>
            </div>
            <div class="form-group col-md-4" style="margin-top: 5px" *ngIf="
          currentUser && currentUser['Miscellaneous'] &&
              currentUser['Miscellaneous']['form_name']
                .toLowerCase()
                .includes('usa')">
              <label>Preferred Study Level</label>
              <div class="font-weight-bold">
                {{ currentUser["Miscellaneous"]["Preferred Study Level"] || "-" }}
              </div>
            </div>
            <div class="form-group col-md-4" style="margin-top: 5px" *ngIf="
          currentUser && currentUser['Miscellaneous'] &&
              currentUser['Miscellaneous']['form_name']
                .toLowerCase()
                .includes('ielts') ">
              <label>Choose Batch</label>
              <div class="font-weight-bold">
                {{ currentUser["Miscellaneous"]["Choose Batch"] || "-" }}
              </div>
            </div>
            <div class="form-group col-md-4" style="margin-top: 5px" *ngIf="
          currentUser && currentUser['Miscellaneous'] &&
              currentUser['Miscellaneous']['form_name']
                .toLowerCase()
                .includes('ielts') ">
              <label>Training Mode</label>
              <div class="font-weight-bold">
                {{ currentUser["Miscellaneous"]["Training Mode"] || "-" }}
              </div>
            </div>
          </div>
        </div>
        <ng-container *ngIf="statusValue !== 'pending'">
          <div class="profile-block">
            <div class="header-12 pb-2">Call Details</div>
            <div class="form-row">
              <div class="form-group col-md-4">
                <label>No of Times Called</label>
                <div class="font-weight-bold">{{ currentUser ? currentUser["CallCount"] : '-' }}</div>
              </div>
              <div class="form-group col-md-4">
                <label>Last Called</label>
                <div class="font-weight-bold">
                  {{
                  currentUser && currentUser["CalledDate"]
                  ? setTimeZoneToZero((currentUser["CalledDate"]))
                  : "-"
                  }}
                </div>
              </div>
              <div class="form-group col-md-4 text-right">
                <label>Call Status</label><br />
                <div class=" " *ngIf="currentUser &&  currentUser['CallStatus']" [ngClass]="{
                  'status-inprogress': currentUser['CallStatus'] === 'pending',
                  'status-closed':
                    currentUser['CallStatus'] === 'notanswered' ||
                    currentUser['CallStatus'] === 'notinterested' ||
                    currentUser['CallStatus'] === 'callfailed' ||
                    currentUser['CallStatus'] === 'poorenglish' ||
                    currentUser['CallStatus'] === 'interestedpending' ||
                    currentUser['CallStatus'] === 'duplicate' ||
                    currentUser['CallStatus'] === 'callback',
                  'status-open':
                    currentUser['CallStatus'] === 'answered' ||
                    currentUser['CallStatus'] === 'interested' ||
                    currentUser['CallStatus'] === 'called' }">
                  {{ currentUser && currentUser["CallStatus"] ? (currentUser["CallStatus"] | textpipe | titlecase) : '-'
                  }}
                </div>
              </div>
              <div class="form-group col-md-4" style="margin-bottom: 5px">
                <label>Last Called Agent</label>
                <div class="font-weight-bold">
                  {{ currentUser && currentUser["CalledBy"] || "-" }}
                </div>
              </div>
              <div class="form-group col-md-8" style="margin-bottom: 5px">
                <label>Call Comments</label>
                <div class="font-weight-bold">
                  {{ currentUser && currentUser["Comments"] || "-" }}
                </div>
              </div>
              <div class="form-group col-md-4" *ngIf="
            currentUser && currentUser['Phase'] !== 'followup' &&
                currentUser['CallStatus'] === 'notinterested'">
                <label>Counselling Scheduled</label>
                <div class="font-weight-bold">
                  {{ currentUser && currentUser["DemoScheduled"] ? "Yes" : "No" }}
                </div>
              </div>
            </div>
            <div class="form-row" *ngIf="
          currentUser && currentUser['Phase'] !== 'followup' &&
              currentUser['CallStatus'] === 'notinterested'
            ">
              <div class="form-group col-md-4">
                <label>Counselling Presenter</label>
                <div class="font-weight-bold">
                  {{ currentUser["Presenter"] || "-" }}
                </div>
              </div>
              <div class="form-group col-md-4">
                <label>Counselling Date</label>
                <div class="font-weight-bold">
                  {{
                  currentUser["DateTime"]
                  ? setTimeZoneToZero((currentUser["DateTime"] | date: "medium"))
                  : "-"
                  }}
                </div>
              </div>
            </div>
          </div>
        </ng-container>
        <div class="profile-block" *ngIf="
          statusValue !== 'pending' &&
          statusValue !== 'notanswered' &&
          statusValue !== 'Website'
        ">
          <div class="header-12 pb-2">Lead Details</div>
          <div class="form-row" *ngIf="currentUser &&
            statusValue !== 'pending' &&
            statusValue !== 'notanswered' &&
            statusValue !== 'website'">
            <div class="form-group col-md-4">
              <label>Alternative Contact</label>
              <div class="font-weight-bold">
                {{ currentUser["AltContactNo"] || "-" }}
              </div>
            </div>
            <div class="form-group col-md-4">
              <label>State</label>
              <div class="font-weight-bold">
                {{ currentUser["State"] || "-" }}
              </div>
            </div>
            <div class="form-group col-md-4">
              <label>ExistingCounselling</label>
              <div class="font-weight-bold">
                {{ currentUser["ExistingCounselling"] || "-" }}
              </div>
            </div>
            <div class="form-group col-md-4">
              <label>IELTS</label>
              <div class="font-weight-bold">
                {{ currentUser["IELTS"] || "-" }}
              </div>
            </div>
            <div class="form-group col-md-4">
              <label>PTE</label>
              <div class="font-weight-bold">
                {{ currentUser["PTE"] || "-" }}
              </div>
            </div>
            <div class="form-group col-md-4">
              <label>TOEFL</label>
              <div class="font-weight-bold">
                {{ currentUser["TOEFL"] || "-" }}
              </div>
            </div>
            <div class="form-group col-md-4">
              <label>Duolingo</label>
              <div class="font-weight-bold">
                {{ currentUser["Duolingo"] || "-" }}
              </div>
            </div>
            <div class="form-group col-md-4">
              <label>AppearTest</label>
              <div class="font-weight-bold">
                {{ currentUser["AppearTest"] || "-" }}
              </div>
            </div>
            <div class="form-group col-md-4">
              <label>WorkExperience</label>
              <div class="font-weight-bold">
                {{ currentUser["WorkExperience"] || "-" }}
              </div>
            </div>
            <div class="form-group col-md-4">
              <label>FundingSource</label>
              <div class="font-weight-bold">
                {{ currentUser["FundingSource"] || "-" }}
              </div>
            </div>
            <div class="form-group col-md-4">
              <label>Passport</label>
              <div class="font-weight-bold">
                {{ currentUser["Passport"] || "-" }}
              </div>
            </div>
            <div class="form-group col-md-4">
              <label>Visa</label>
              <div class="font-weight-bold">
                {{ currentUser["Visa"] || "-" }}
              </div>
            </div>
            <div class="form-group col-md-4">
              <label>IntakePreferrence</label>
              <div class="font-weight-bold">
                {{ currentUser["IntakePreferrence"] || "-" }}
              </div>
            </div>
            <div class="form-group col-md-4">
              <label>Date of Birth</label>
              <div class="font-weight-bold">
                <span *ngIf='currentUser && !currentUser["DOB"]'>-</span>
                <span *ngIf='currentUser && currentUser["DOB"]'>{{ currentUser["DOB"] === (null||"")
                  ?"-":setTimeZoneToZero(currentUser["DOB"]) }}</span>
                <!-- {{ currentUser["DOB"] | date: "dd/MM/yy" || "-" }} -->
              </div>
            </div>
            <div class="form-group col-md-4">
              <label>CallbackDT</label>
              <div class="font-weight-bold">
                {{ setTimeZoneToZero(currentUser["CallbackDT"] !="" ? currentUser["CallbackDT"] :"-" )}}
              </div>
            </div>
          </div>
        </div>
        <div class="profile-block" *ngIf="currentUser && currentUser['DateTime']">
          <div class="header-12 pb-2">Counselling Details</div>

          <div class="form-row" *ngIf="
            statusValue !== 'pending' &&
            statusValue !== 'notanswered' &&
            statusValue !== 'website'">
            <!-- <div class="form-group col-md-4">
            <label>No of Counselling Sessions</label>
            <div class="font-weight-bold">
            </div>
          </div> -->
            <div class="form-group col-md-4">
              <label>Counselling Date Time</label>
              <div class="font-weight-bold">
                {{ setTimeZoneToZero(currentUser["DateTime"] !=(null || "") ? currentUser["Datetime"] :"-" )}}
              </div>
            </div>
            <div class="form-group col-md-4">
              <label>Counselling Status</label><br />
              <div class=" " [ngClass]="{
                'status-inprogress': currentUser['Status'] === 'notinterested',
                'status-open':
                  currentUser['Status'] === null ||
                  currentUser['Status'] === 'interested'
              }">
                {{
                currentUser["Status"]
                ? (currentUser["Status"] | statusvalue)
                : "Interested"
                }}
              </div>
            </div>
            <div class="form-group col-md-4">
              <label>Counsellor Comments</label>
              <div class="font-weight-bold">
                {{ currentUser["DemoComments"] || "-" }}
              </div>
            </div>
            <div class="form-group col-md-4">
              <label>Counselling Agent</label><br />
              <div class="font-weight-bold">
                {{ currentUser["Presenter"] }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


  </ng-template>


  <!-- edit lead -->
  <ng-template #content2 let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Update Lead Details</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body" style="max-height: 900px; background: #fff">
      <form class="pt-2" [formGroup]="this.layoutService.commentsForm" (ngSubmit)="submit()">
        <div class="form-row">

          <div class="form-group col-md-4">
            <label>Name</label>
            <input type="text" class="form-control" id="" placeholder="Name" formControlName="name" [ngClass]="{
              'is-invalid':
                submitted &&
                f.name.errors &&
                layoutService.commentsValue.interested !== 'notinterested',
              'is-valid': f.name.valid
            }" />
            <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
              <div *ngIf="f.name.errors.required">name is required</div>
            </div>
          </div>

          <div class="form-group col-md-4">
            <label>Email Id</label>
            <input type="email" class="form-control" id="" placeholder="Email Id" formControlName="emailId" [ngClass]="{
              'is-invalid':
                submitted &&
                f.emailId.errors &&
                layoutService.commentsValue.interested !== 'notinterested',
              'is-valid': f.emailId.valid
            }" />
            <div *ngIf="submitted && f.emailId.errors" class="invalid-feedback">`
              <div *ngIf="
                f.emailId.errors.required &&
                layoutService.commentsValue.interested !== 'notinterested'
              ">
                Please Enter a Valid EmailId
              </div>
            </div>
          </div>

          <div class="form-group col-md-4">
            <label>Date of Birth</label>
            <input type="date" class="form-control" id="" placeholder="" formControlName="dob" [ngClass]="{
              'is-invalid':
                submitted &&
                f.dob.errors &&
                layoutService.commentsValue.interested !== 'notinterested','is-valid': f.dob.valid && f.dob.touched
            }" />
            <div *ngIf="
              submitted &&
              f.dob.errors &&
              layoutService.commentsValue.interested !== 'notinterested'" class="invalid-feedback">
              <div *ngIf="f.dob.errors.required">Date of birth is required</div>
            </div>
          </div>

        </div>

        <div class="form-row">
          <div class="form-group col-md-4">
            <label>State/Country</label>
            <input type="email" class="form-control" id="" placeholder="" formControlName="state" [ngClass]="{
              'is-invalid':
                submitted &&
                f.state.errors &&
                layoutService.commentsValue.interested !== 'notinterested',
              'is-valid': f.state.valid && f.state.touched}" />

            <div *ngIf="
              submitted &&
              f.state.errors &&
              layoutService.commentsValue.interested !== 'notinterested'
            " class="invalid-feedback">
              <div *ngIf="f.state.errors.required">State is required</div>
            </div>
          </div>
          <div class="form-group col-md-4">
            <label>Alternative Contact Number</label>
            <input type="text" class="form-control" id="" placeholder="" formControlName="altContactNumber" [ngClass]="{
              'is-invalid':
                submitted &&
                f.altContactNumber.errors &&
                layoutService.commentsValue.interested !== 'notinterested',
              'is-valid': f.altContactNumber.valid && f.altContactNumber.touched
            }" />
            <div *ngIf="submitted && f.altContactNumber.errors" class="invalid-feedback">
              <div *ngIf="f.altContactNumber.errors">
                Alternate ContactNumber is required
              </div>
            </div>
          </div>
          <div class="form-group col-md-4">
            <label>Phone Number</label>
            <div class="form-row">
              <div class="col-2">
                <input type="text" class="form-control" id="" placeholder="" formControlName="countryCode" [ngClass]="{
                  'is-invalid':
                    submitted &&
                    f.altContactNumber.errors &&
                    layoutService.commentsValue.interested !== 'notinterested',
                  'is-valid': f.countryCode.valid && f.countryCode.touched
                }" />
                <div *ngIf="submitted && f.countryCode.errors" class="invalid-feedback">
                  <div *ngIf="f.countryCode.errors">
                    Alternate ContactNumber is required
                  </div>
                </div>
              </div>
              <div class="col-8">
                <input type="text" class="form-control" id="" placeholder="" formControlName="phoneNumber" [ngClass]="{
                  'is-invalid':
                    submitted &&
                    f.altContactNumber.errors &&
                    layoutService.commentsValue.interested !== 'notinterested',
                  'is-valid': f.phoneNumber.valid && f.phoneNumber.touched
                }" />
                <div *ngIf="submitted && f.phoneNumber.errors" class="invalid-feedback">
                  <div *ngIf="f.phoneNumber.errors">
                    Alternate ContactNumber is required
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="form-group col-md-4">
            <label for="">Country Preference </label>
            <input type="text" class="form-control" id="" placeholder="" formControlName="countryPreference" [ngClass]="{
              'is-invalid':
                submitted &&
                f.countryPreference.errors &&
                layoutService.commentsValue.interested !== 'notinterested',
              'is-valid':
                f.countryPreference.valid && f.countryPreference.touched
            }" />
            <div *ngIf="submitted && f.countryPreference.errors" class="invalid-feedback">
              <div *ngIf="f.countryPreference.errors.required">
                CountryPreference is required
              </div>
            </div>
          </div>
        </div>

        <div class="form-row">
          <div class="form-group col-md-4">
            <label for="">Existing Counselling</label>
            <div class="mt-1">
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" name="existingCounseling" id="inlineRadio1" value="yes"
                  checked="checked" formControlName="existingCounseling" />
                <label class="form-check-label" for="inlineRadio1">Yes</label>
              </div>
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" name="existingCounseling" id="inlineRadio2" value="no"
                  formControlName="existingCounseling" />
                <label class="form-check-label" for="inlineRadio2">No</label>
              </div>
            </div>
          </div>
          <div class="form-group col-md-4">
            <label for="">Valid Passport?</label>
            <div class="mt-1">
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" name="passport" id="inlineRadio1" value="yes"
                  formControlName="passport" />
                <label class="form-check-label" for="inlineRadio1">Yes</label>
              </div>
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" name="passport" id="inlineRadio2" value="no"
                  formControlName="passport" />
                <label class="form-check-label" for="inlineRadio2">No</label>
              </div>
            </div>
          </div>

          <div class="form-group col-md-4">
            <label>Applied for Visa?</label>
            <div class="mt-1">
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" name="visa" id="inlineRadio1" value="yes"
                  formControlName="visa" />
                <label class="form-check-label" for="inlineRadio1">Yes</label>
              </div>
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" name="visa" id="inlineRadio2" value="no"
                  formControlName="visa" />
                <label class="form-check-label" for="inlineRadio2">No</label>
              </div>
            </div>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-md-4">
            <div style="float: left; width: 35%; margin-right: 10px">
              <label>IELTS</label>
              <input type="text" formControlName="IELETS" class="form-control" placeholder="" [ngClass]="{
                'is-invalid':
                  submitted &&
                  f.IELETS.errors &&
                  layoutService.commentsValue.interested !== 'notinterested',
                'is-valid': f.IELETS.valid && f.IELETS.touched
              }" />

              <div *ngIf="
                submitted &&
                f.IELETS.errors &&
                layoutService.commentsValue.interested !== 'notinterested'
              " class="invalid-feedback">
                <div *ngIf="f.IELETS.errors.required">IELETS is required</div>
              </div>
            </div>
            <div class="" style="float: left; width: 35%; margin-right: 10px">
              <label>TOEFL</label>
              <input type="text" formControlName="TOEFL" class="form-control" placeholder="" [ngClass]="{
                'is-invalid':
                  submitted &&
                  f.TOEFL.errors &&
                  layoutService.commentsValue.interested !== 'notinterested',
                'is-valid': f.TOEFL.valid && f.TOEFL.touched
              }" />

            </div>
            <div *ngIf="submitted && f.TOEFL.errors" class="invalid-feedback">
              <div *ngIf="f.TOEFL.errors.required">TOEFL is required</div>
            </div>
          </div>

          <div class="form-group col-md-4">
            <div class="" style="float: left; width: 35%; margin-right: 10px">
              <label>PTE</label>
              <input type="text" formControlName="PTE" class="form-control" placeholder="" [ngClass]="{
                'is-invalid':
                  submitted &&
                  f.PTE.errors &&
                  layoutService.commentsValue.interested !== 'notinterested',
                'is-valid': f.PTE.valid && f.PTE.touched
              }" />

              <div *ngIf="submitted && f.PTE.errors" class="invalid-feedback">
                <div *ngIf="f.PTE.errors.required">PTE is required</div>
              </div>
            </div>
            <div class="" style="float: left; width: 35%; margin-right: 10px">
              <label>Duolingo</label>
              <input type="text" formControlName="Dulingo" class="form-control" placeholder="" [ngClass]="{
                'is-invalid':
                  submitted &&
                  f.Dulingo.errors &&
                  layoutService.commentsValue.interested !== 'notinterested',
                'is-valid': f.Dulingo.valid && f.Dulingo.touched
              }" />

              <div *ngIf="submitted && f.Dulingo.errors" class="invalid-feedback">
                <div *ngIf="f.Dulingo.errors.required">Dulingo is required</div>
              </div>
            </div>
          </div>

          <div class="form-group col-md-4">
            <label>If not when planning to appear</label>
            <input type="" class="form-control" id="" placeholder="" formControlName="planning" [ngClass]="{
              'is-invalid':
                submitted &&
                f.planning.errors &&
                layoutService.commentsValue.interested !== 'notinterested',
              'is-valid': f.planning.valid && f.planning.touched
            }" />
            <div *ngIf="submitted && f.planning.errors" class="invalid-feedback">
              <div *ngIf="f.planning.errors.required &&
                layoutService.commentsValue.interested !== 'notinterested'">
                Planning is required
              </div>
            </div>
          </div>
        </div>

        <div class="form-row">
          <div class="form-group col-md-4">
            <label for="">Current Qualification</label>
            <input type="text" formControlName="qualification" class="form-control" placeholder="" [ngClass]="{
              'is-invalid':
                submitted &&
                f.qualification.errors &&
                layoutService.commentsValue.interested !== 'notinterested',
              'is-valid': f.qualification.valid && f.qualification.touched
            }" />
            <div *ngIf="
              submitted &&
              f.qualification.errors &&
              layoutService.commentsValue.interested !== 'notinterested'
            " class="invalid-feedback">
              <div *ngIf="f.qualification.errors.required">
                Qualification is required
              </div>
            </div>
          </div>

          <div class="form-group col-md-4">
            <label for="">Work Experience</label>
            <input type="text" class="form-control" id="" formControlName="workExperience" placeholder="" [ngClass]="{
              'is-invalid':
                submitted &&
                f.workExperience.errors &&
                layoutService.commentsValue.interested !== 'notinterested',
              'is-valid': f.workExperience.valid && f.workExperience.touched
            }" />
            <div *ngIf="
              submitted &&
              f.workExperience.errors &&
              layoutService.commentsValue.interested !== 'notinterested'
            " class="invalid-feedback">
              <div *ngIf="f.workExperience.errors.required">
                Work Experience is required
              </div>
            </div>
          </div>

          <div class="form-group col-md-4">
            <label>Course Interested</label>
            <input type="text" formControlName="courseInterested" class="form-control" placeholder="" [ngClass]="{
              'is-invalid':
                submitted &&
                f.courseInterested.errors &&
                layoutService.commentsValue.interested !== 'notinterested',
              'is-valid': f.courseInterested.valid && f.courseInterested.touched
            }" />

            <div *ngIf="
              submitted &&
              f.courseInterested.errors &&
              layoutService.commentsValue.interested !== 'notinterested'
            " class="invalid-feedback">
              <div *ngIf="f.courseInterested.errors.required">
                Course Interested is required
              </div>
            </div>
          </div>

        </div>

        <div class="form-row">

          <div class="col-md-4">
            <label for="description" class="inputEmail4">Assigned To</label>
            <ng-multiselect-dropdown [settings]="layoutService.dropdownSettings" [data]="layoutService.roleAgents"
              name="manager" formControlName="assignedTo" [(ngModel)]="layoutService.selectedAgent"
              style="width: 100%; padding: 0">
            </ng-multiselect-dropdown>
            <div *ngIf="submitted && f.assignedTo.errors" class="invalid-feedback">
              <div *ngIf="f.assignedTo.errors.required">
                Assigned To is required
              </div>
            </div>
          </div>

          <div cGlass="form-group col-md-4">
            <label for="">Counselling Time and Date</label>
            <input type="datetime-local" class="form-control" id="" placeholder="" formControlName="counsellingDate"
              [ngClass]="{'is-invalid':  submitted && f.counsellingDate.errors}" />
            <div *ngIf="submitted && f.counsellingDate.errors" class="invalid-feedback">
              <div *ngIf="f.counsellingDate.errors.required">
                Counselling date is required
              </div>
            </div>
          </div>
          <div class="form-group col-md-4">
            <label for="">Call Back</label>
            <input type="datetime-local" class="form-control" id="" placeholder="" formControlName="callBack" [ngClass]="{
              'is-invalid': submitted && f.callBack.errors}" />
            <div *ngIf="submitted && f.callBack.errors" class="invalid-feedback">
              <div *ngIf="f.callBack.errors.required">
                callBack date is required
              </div>
            </div>
          </div>
          <div class="form-group col-md-4">
            <label>Funding Source</label>
            <input type="text" formControlName="fundingResource" class="form-control" placeholder="" [ngClass]="{
              'is-invalid':
                submitted &&
                f.fundingResource.errors &&
                layoutService.commentsValue.interested !== 'notinterested',
              'is-valid': f.fundingResource.valid && f.fundingResource.touched
            }" />

            <div *ngIf=" submitted &&f.fundingResource.errors &&layoutService.commentsValue.interested !== 'notinterested'
            " class="invalid-feedback">
              <div *ngIf="f.fundingResource.errors.required">
                Funding Resource is required
              </div>
            </div>
          </div>
          <div class="form-group col-md-4">
            <label for="">Intake preferred</label>
            <input class="form-control" type="text" formControlName="intakePreferred" [ngClass]="{
              'is-invalid':
                submitted &&
                f.intakePreferred.errors &&
                layoutService.commentsValue.interested !== 'notinterested',
              'is-valid': f.intakePreferred.valid && f.intakePreferred.touched
            }" />

            <div *ngIf="submitted && f.intakePreferred.errors &&layoutService.commentsValue.interested !== 'notinterested'
            " class="invalid-feedback">
              <div *ngIf="f.intakePreferred.errors.required">
                In Take Proffered is required
              </div>
            </div>
          </div>
          <div class="form-group col-md-4">
            <label for="">Comments</label>
            <input type="text" formControlName="comments" class="form-control" placeholder="" [ngClass]="{
              'is-valid': f.comments.valid && f.comments.touched
            }" />
          </div>

          <!-- Radio button  -->

          <div class="form-group col-md-12">
            <label for="">Feedback</label>
            <div class="mt-2">
              <div class="form-check form-check-inline" *ngFor="let item of listFeed">
                <input class="form-check-input" type="radio" name="interested" id="inlineRadio1" [value]="item.value"
                  formControlName="interested" />
                <label class="form-check-label" for="inlineRadio1">{{item.name}}</label>
              </div>
            </div>
            <div *ngIf="submitted && f.interested.errors" class="invalid-feedback">
              <div *ngIf="f.interested.errors.required">
                Select any one value
              </div>
            </div>
          </div>

        </div>
        <div class="d-flex justify-content-end">
          <div class="mt-3">
            <button class="btn btn-primary btn-sm" type="submit">Update</button>
          </div>
        </div>
      </form>
    </div>
  </ng-template>

  <!-- add lead -->
  <ng-template #content3 let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Add Lead</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body p-3" style="max-height: 600px; background: #fff">
      <form (ngSubmit)="createLead()" [formGroup]="marketingService.createLead">
        <div class="form-group">
          <label class="form-label">First Name</label>
          <input class="form-control" name="firstName" formControlName="firstName" [ngClass]="{
            'is-invalid': submitted && createLeads.firstName.invalid,
            'is-valid':
              createLeads.firstName.valid && createLeads.firstName.touched
          }" />
          <div *ngIf="submitted && createLeads.firstName.errors" class="invalid-feedback">
            <div>First Name is required</div>
          </div>
        </div>

        <div class="form-group">
          <label class="form-label">Last Name</label>
          <input class="form-control" name="lastName" formControlName="lastName" [ngClass]="{
            'is-invalid': submitted && createLeads.lastName.invalid,
            'is-valid':
              createLeads.lastName.valid && createLeads.lastName.touched
          }" />
          <div *ngIf="submitted && createLeads.lastName.errors" class="invalid-feedback">
            <div>LastName is required</div>
          </div>
        </div>
        <div class="form-group">
          <label class="form-label">Phone Number</label>
          <div class="row">
            <div class="col-2">
              <input class="form-control" name="countryCode" formControlName="countryCode" [ngClass]="{
                'is-invalid': submitted && createLeads.countryCode.invalid,
                'is-valid':
                  createLeads.countryCode.valid &&
                  createLeads.countryCode.touched
              }" />
              <div *ngIf="submitted && createLeads.countryCode.errors" class="invalid-feedback">
                <div>Country Code is required</div>
              </div>
            </div>
            <div class="col-10">
              <input class="form-control" name="phoneNumber" formControlName="phoneNumber" [ngClass]="{
                'is-invalid': submitted && createLeads.phoneNumber.invalid,
                'is-valid':
                  createLeads.phoneNumber.valid &&
                  createLeads.phoneNumber.touched
              }" />
              <div *ngIf="submitted && createLeads.phoneNumber.errors" class="invalid-feedback">
                <div>Phone Number is required</div>
              </div>
            </div>
          </div>
        </div>
        <div class="form-group">
          <label class="form-label">Email</label>
          <input type="text" name="email" class="form-control" formControlName="email" [ngClass]="{
            'is-invalid': submitted && createLeads.email.invalid,
            'is-valid': createLeads.email.valid && createLeads.email.touched
          }" />
          <div *ngIf="submitted && createLeads.email.errors" class="invalid-feedback">
            <div>Email is required</div>
          </div>
        </div>
        <div class="form-group">
          <label class="form-label">Form Name</label>
          <input type="text" name="form_name" class="form-control" formControlName="form_name" [ngClass]="{
            'is-invalid': submitted && createLeads.form_name.invalid,
            'is-valid':
              createLeads.form_name.valid && createLeads.form_name.touched
          }" />
          <div *ngIf="submitted && createLeads.form_name.errors" class="invalid-feedback">
            <div>Form Name is required</div>
          </div>
        </div>
        <div class="form-group">
          <label class="form-label">Channel</label>
          <input type="text" name="channel" class="form-control" formControlName="channel" [ngClass]="{
            'is-invalid': submitted && createLeads.channel.invalid,
            'is-valid': createLeads.channel.valid && createLeads.channel.touched
          }" />
          <div *ngIf="submitted && createLeads.channel.errors" class="invalid-feedback">
            <div>Channel is required</div>
          </div>
        </div>
        <div class="d-flex justify-content-end">
          <button class="btn btn-primary btn-sm">Submit</button>
        </div>
      </form>
    </div>
  </ng-template>

  <div class="fixed-footer">
    Powered by <a href="#">Avantao</a>
  </div>
</body>