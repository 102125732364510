import { Injectable } from '@angular/core';
import { HttpClientService } from 'src/app/httpclient.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AicallsService {
  baseApiUrl2: any = environment.baseApiUrl2;
  constructor(private http: HttpClientService) { }

  checkUser(data) {
    return this.http.post(`${this.baseApiUrl2}user`, data)
  }
  uploadAudio(data) {
    return this.http.post(`${this.baseApiUrl2}uploadAudio`, data)
  }
  totalAnalyzed() {
    return this.http.get(`${this.baseApiUrl2}callCounts`)

  }
}
