export class Exceldata2 {
  constructor(
    private GUID: string,
    private firstName: string,
    private lastName: string,
    private countryCode: string,
    private phoneNumber: string,
    private email: string,
    private assignedTo?: string,
    private Program?: string,
    private ApplicationType?: any,
    private CreatedDate?: any,
    private Comments?: any,
    private Channel?: any,
    private form_name?: any
  ) { }
}
