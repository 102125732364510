<mat-sidenav id="sideNav" #sideNav mode="side" class="pr" opened="false" position="end" class="pf w45 z-top">
    <div class="modal-header d-flex pf w45" style="right: 0; top: 0; z-index: 9999">
        <div class="d-flex">
            <div class="close hand" (click)="sideNav.toggle()">
                <span aria-hidden="true">&times;</span>
            </div>
            <div class="modal-title mr-auto">Overview</div>
        </div>
    </div>
    <mat-tab-group class="mt-5 sideDataViewer">
        <mat-tab label="Event Details">
            <div class="mx-2 my-2" *ngIf="sideViewerData">
                <table class="table table-borderless table-md caller-table my-1">

                    <tr>
                        <td>Event Title</td>
                        <td>{{ sideViewerData.EventTitle }}
                        </td>
                    </tr>
                    <tr>
                        <td>Event Description</td>
                        <td>{{ sideViewerData.EventDescription }}</td>
                    </tr>
                    <tr>
                        <td>Members</td>
                        <td>{{ sideViewerData.Members || '--' }}</td>
                    </tr>
                    <tr>
                        <td>Start Date & Time</td>
                        <td>{{ sideViewerData.StartDate }} {{ sideViewerData.StartTime }}</td>
                    </tr>
                    <tr>
                        <td>End Date & Time</td>
                        <td>{{ sideViewerData.EndDate }} {{ sideViewerData.EndTime }}</td>
                    </tr>

                    <tr>
                        <td>Is Cancel ? </td>
                        <td>{{ sideViewerData.Is_cancel?'Yes':'No' }}</td>
                    </tr>
                    <tr>
                        <td>Cancellation Reason </td>
                        <td>{{ sideViewerData.cancel_reason || '--' }}</td>
                    </tr>
                    <tr>
                        <td>Cancellation Date & Time </td>
                        <td>{{ getDateTime(sideViewerData.cancelScheduleDateTime) }}</td>
                    </tr>
                    <tr>
                        <td>Is Reschedule ?</td>
                        <td>{{ sideViewerData.Is_reschedule?'Yes':'No' }}</td>
                    </tr>
                    <tr>
                        <td>Reschedule Date & Time </td>
                        <td>{{ getDateTime(sideViewerData.RescheduleDateTime)}}</td>
                    </tr>
                    <tr>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>Meeting Platform</td>
                        <td>{{ sideViewerData.meetingDetails?sideViewerData.meetingDetails.MeetingPlatform:'--' }}</td>
                    </tr>
                    <tr>
                        <td>Topic</td>
                        <td>{{ sideViewerData.meetingDetails?sideViewerData.meetingDetails.Topic:'--' }}</td>
                    </tr>
                    <tr>
                        <td>Start Time</td>
                        <td>{{ sideViewerData.meetingDetails?getDateTime(sideViewerData.meetingDetails.StartTime) :
                            '--'}}</td>
                    </tr>
                    <tr>
                        <td>Host Email</td>
                        <td>{{ sideViewerData.meetingDetails?sideViewerData.meetingDetails.HostEmail:'--' }}</td>
                    </tr>

                </table>
            </div>
        </mat-tab>
        <mat-tab label="End-User Details" *ngIf="sideViewerData && sideViewerData.Name!=null">
            <div class="mx-2 my-2">
                <table class="table table-borderless table-md caller-table my-1">

                    <tr>
                        <td>User Name</td>
                        <td>{{ sideViewerData.Name ||'--' }}</td>
                    </tr>
                    <tr>
                        <td>User Email Id</td>
                        <td>{{ sideViewerData.EmailId ||'--' }}</td>
                    </tr>
                    <tr>
                        <td>User Mobile Number</td>
                        <td *ngIf="sideViewerData.CountryCode">+{{
                            sideViewerData.CountryCode}} - {{sideViewerData.MobileNumber}}</td>
                        <td *ngIf="!sideViewerData.CountryCode">{{sideViewerData.MobileNumber ||'--' }}</td>
                    </tr>

                </table>
            </div>
        </mat-tab>

        <!-- <mat-tab label="Agent Details">
            <div class="mx-2 my-2" *ngIf="sideViewerData">
                <table class="table table-borderless table-md caller-table my-1">

                    <tr>
                        <td>Agent Name</td>
                        <td>{{ sideViewerData.agentDetails.FirstName }} {{ sideViewerData.agentDetails.LastName }}</td>
                    </tr>
                    <tr>
                        <td>Agent Email Id</td>
                        <td>{{ sideViewerData.agentDetails.Mailid }}</td>
                    </tr>

                </table>
            </div>
        </mat-tab> -->

        <!-- <mat-tab label="Meeting Details" *ngIf="sideViewerData && sideViewerData.meetingDetails!=null">
            <div class="mx-2 my-2">
                <table class="table table-borderless table-md caller-table my-1">
                    <tr>
                        <td>Meeting Platform</td>
                        <td>{{ sideViewerData.meetingDetails.MeetingPlatform }}</td>
                    </tr>
                    <tr>
                        <td>Topic</td>
                        <td>{{ sideViewerData.meetingDetails.Topic }}</td>
                    </tr>
                    <tr>
                        <td>Start Time</td>
                        <td>{{ sideViewerData.meetingDetails.StartTime | date:'YYYY-MM-dd hh:mm a'}}</td>
                    </tr>
                    <tr>
                        <td>Host Email</td>
                        <td>{{ sideViewerData.meetingDetails.HostEmail }}</td>
                    </tr>


                </table>
            </div>
        </mat-tab> -->
    </mat-tab-group>
</mat-sidenav>

<div class="text-left mt-2" style="padding: 0 2rem;">
    <app-project-change (projectChange)="projectChange($event)"></app-project-change>
</div>

<div class="container-fluid">
    <div class="pt-3 pl-2 pr-2 d-flex justify-content-between mb-4">
        <div class="db-sm-header" style="line-height: 330%;margin: 0 0 0 5px;">Activity</div>
    </div>
    <div class="row" style="padding-left: 30px; z-index: unset;">

        <div class="carousel-cell">
            <div class="mr-3">
                <div class="db-block w-175" (click)="onClick(1,'All')"
                    [ngClass]="{ 'db-block-active': tab === 'tab1' }">
                    <div class="p-3">
                        <div class="d-flex">
                            <div class="img-circle d-flex">
                                <img src="../../../../assets/img/completed_applications.svg" style="width: 20px" />
                            </div>
                            <div class="f25 text-right ml-auto mt-3">
                                <!-- {{ completedApplications2 }} -->
                                {{cardCount.All||'0'}}
                            </div>
                        </div>
                        <div class="box-sub-text1 mt-2">Total Events</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="carousel-cell">
            <div class="mr-3">
                <div class="db-block w-175" (click)="onClick(2,'Cancelled')"
                    [ngClass]="{ 'db-block-active': tab === 'tab2' }">
                    <div class="p-3">
                        <div class="d-flex">
                            <div class="img-circle d-flex">
                                <img src="../../../../assets/img/completed_applications.svg" style="width: 20px" />
                            </div>
                            <div class="f25 text-right ml-auto mt-3">
                                <!-- {{ completedApplications2 }} -->
                                {{cardCount.Cancelled||'0'}}

                            </div>
                        </div>
                        <div class="box-sub-text1 mt-2">Cancelled Events</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="carousel-cell">
            <div class="mr-3">
                <div class="db-block w-175" (click)="onClick(3,'Reschdeuled')"
                    [ngClass]="{ 'db-block-active': tab === 'tab3' }">
                    <div class="p-3">
                        <div class="d-flex">
                            <div class="img-circle d-flex">
                                <img src="../../../../assets/img/completed_applications.svg" style="width: 20px" />
                            </div>
                            <div class="f25 text-right ml-auto mt-3">
                                <!-- {{ completedApplications2 }} -->
                                {{cardCount.Reschdeuled||'0'}}

                            </div>
                        </div>
                        <div class="box-sub-text1 mt-2">Rescheduled Events</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="carousel-cell">
            <div class="mr-3">
                <div class="db-block w-175" (click)="onClick(4,'Upcoming')"
                    [ngClass]="{ 'db-block-active': tab === 'tab4' }">
                    <div class="p-3">
                        <div class="d-flex">
                            <div class="img-circle d-flex">
                                <img src="../../../../assets/img/completed_applications.svg" style="width: 20px" />
                            </div>
                            <div class="f25 text-right ml-auto mt-3">
                                <!-- {{ completedApplications2 }} -->
                                {{cardCount.Upcoming||'0'}}

                            </div>
                        </div>
                        <div class="box-sub-text1 mt-2">Upcoming Events</div>
                    </div>
                </div>
            </div>
        </div>

    </div>
    <div>
        <div class="d-flex align-items-end" *ngIf="eventDetails.length">
            <div class="ml-3 header-12" style="margin-left: 8px !important;">
                Total Events
            </div>
            <div class="ml-auto mr-3">
                <div class="content-containers" style="margin-left: 30px">
                    <div class="d-flex justify-content-end mt-3">
                        <button class="btn btn-secondary btn-sm ml-2" ngbTooltip="Export Details" placement="top"
                            tooltipClass="my-custom-class" (click)="exportList()">

                            <img src="../../../../assets/img/upload-w.svg" class="img13" />
                        </button>
                    </div>
                    <div style="clear: both"></div>
                </div>
            </div>
        </div>
    </div>
    <div>
        <div class="mt-1 ml-3 mr-3">
            <div class="row">
                <div class="pl-2 pr-2 pt-2 mb-3" style="min-height: 300px; width: 100%">
                    <div class="table-responsive data-table mb-3">
                        <table class="table table-md wbg table-shadow" style="margin-bottom: 0px; width: 100%">
                            <thead>
                                <tr>
                                    <td colspan="2">
                                        <div class="indived-search" style="position: relative">
                                            <input type="search" #inputString class="form-control search-all"
                                                style="padding: 4px 12px" [(ngModel)]="search" placeholder="Search "
                                                autocomplete="off" />
                                        </div>
                                    </td>
                                    <!-- <td colspan="2" class="vm">
                                        <div class="float-right" style="color: #fff; font-size: 12px">
                                           <select name="" class="form-control" id="">
                                               <option value="is_cancel">Cancel Appointment</option>
                                               <option value="is_reschedule">Reshedule Appointment</option>
                                           </select>
                                        </div>
                                    </td> -->
                                    <td colspan="9" class="vm">
                                        <div class="float-right" style="color: #fff; font-size: 12px">

                                            <!-- <select class="form-select form-select-dark mx-2" [(ngModel)]="EventType"
                                                (ngModelChange)="dropdownSelect($event)">
                                                <option value="All">All</option>
                                                <option value="Cancelled">Cancelled</option>
                                                <option value="Reschdeuled">Reschdeuled</option>
                                                <option value="Upcoming">Upcoming</option>

                                            </select> -->
                                            <div class="float-right" style="color: #fff; font-size: 12px"
                                                *ngIf="pageCount">

                                                <ngb-pagination [collectionSize]="pageCount" [(page)]="page"
                                                    [maxSize]="5" [boundaryLinks]="true"
                                                    (pageChange)="onPageChange($event)" class="pagenation-right">
                                                </ngb-pagination>
                                            </div>

                                        </div>
                                    </td>
                                </tr>
                            </thead>
                            <tr>
                                <th nowrap>
                                    <div class="d-flex">
                                        Name
                                        <span><button class="sortButton" (click)="sortOrder('Name')"><img
                                                    class="img7 ml-1"
                                                    src="../../../../assets/img/sort-data.svg" /></button></span>
                                    </div>
                                </th>
                                <th nowrap>
                                    EmailId
                                    <span><button class="sortButton" (click)="sortOrder('EmailId')"><img
                                                class="img7 ml-1"
                                                src="../../../../assets/img/sort-data.svg" /></button></span>
                                </th>
                                <th nowrap>
                                    Mobile Number
                                    <span><button class="sortButton" (click)="sortOrder('Mobile Number')"><img
                                                class="img7 ml-1"
                                                src="../../../../assets/img/sort-data.svg" /></button></span>
                                </th>
                                <th nowrap>
                                    Agent Name
                                    <!-- <span><button class="sortButton" (click)="sortOrder('')"><img
                                                class="img7 ml-1"
                                                src="../../../../assets/img/sort-data.svg" /></button></span> -->
                                </th>
                                <th style="width: 180px" nowrap>
                                    Agent Email Id
                                    <!-- <span><button class="sortButton" (click)="sortOrder('Event Descritpion')"><img
                                                class="img7 ml-1"
                                                src="../../../../assets/img/sort-data.svg" /></button></span> -->
                                </th>
                                <th nowrap>
                                    Start Date - Time
                                    <span><button class="sortButton" (click)="sortOrder('StartDate')"><img
                                                class="img7 ml-1"
                                                src="../../../../assets/img/sort-data.svg" /></button></span>
                                </th>
                                <th nowrap>
                                    End Date - Time
                                    <span><button class="sortButton" (click)="sortOrder('EndDate')"><img
                                                class="img7 ml-1"
                                                src="../../../../assets/img/sort-data.svg" /></button></span>
                                </th>
                                <th nowrap *ngIf="EventType!='All' || EventType!='Upcoming'">
                                    <span *ngIf="EventType=='Cancelled'">Is cancel?</span>
                                    <span *ngIf="EventType=='Reschdeuled'">Is Rescheudle?</span>
                                </th>
                                <th nowrap *ngIf="EventType=='All' || EventType=='Upcoming'">
                                    Is cancel?
                                </th>
                                <th nowrap *ngIf="EventType=='All' || EventType=='Upcoming'">
                                    Is Rescheudle?
                                </th>


                                <th>Actions</th>
                            </tr>

                            <tbody>
                                <tr *ngFor="
                            let item of eventDetails
                            | filter: search
                            | sort: orderFeild:orderString
                        ">

                                    <td class="vm" nowrap>

                                        <div class="txt-oflow">
                                            <button class="btn btn-link btn-sm" style="position:relative">
                                                {{ item.Name || '--'}}
                                            </button>

                                        </div>

                                    </td>
                                    <td class="vm">
                                        {{ item.EmailId ||'--' }}
                                    </td>

                                    <td class="text-capitalize vm">
                                        <p *ngIf="item.CountryCode">
                                            +{{ item.CountryCode }}{{ item.MobileNumber }}
                                        </p>
                                        <p *ngIf="!item.CountryCode">
                                            {{ item.MobileNumber || '--'}}
                                        </p>
                                    </td>
                                    <td class="txt-oflow vm">
                                        {{ item.agentDetails.FirstName }} {{ item.agentDetails.LastName }}
                                    </td>
                                    <td class="txt-oflow vm">
                                        {{ item.agentDetails.Mailid }}
                                    </td>
                                    <td class="vm">
                                        {{ item.StartDate }} {{ item.StartTime }}
                                    </td>
                                    <td class="vm">
                                        {{ item.EndDate }} {{ item.EndTime}}
                                    </td>
                                    <td class="vm" *ngIf="EventType!='All' || EventType!='Upcoming'">
                                        <span *ngIf="EventType=='Cancelled'"> {{ item.Is_cancel?'Yes':'No' }}</span>
                                        <span *ngIf="EventType=='Reschdeuled'">{{ item.Is_reschedule ?'Yes':'No'
                                            }}</span>
                                    </td>

                                    <td class="vm" *ngIf="EventType=='All'|| EventType=='Upcoming'">
                                        {{ item.Is_cancel?'Yes':'No' }}
                                    </td>

                                    <td class="vm" *ngIf="EventType=='All'|| EventType=='Upcoming'">
                                        {{ item.Is_reschedule ?'Yes':'No'
                                        }}
                                    </td>
                                    <td class="text-capitalize vm" nowrap style="padding: 2px">
                                        <div class="example-button-row mat-bset d-flex">
                                            <mat-sidenav-container>
                                                <mat-sidenav-content>
                                                    <button class="viewLeadButton"
                                                        (click)="sideNav.toggle(); sideNavData(item)" mat-button>
                                                        <img class="img12" src="../../../../assets/img/view2.svg" />
                                                    </button>
                                                </mat-sidenav-content>
                                            </mat-sidenav-container>

                                        </div>
                                    </td>
                                </tr>
                                <tr *ngIf="isLoading ==true">
                                    <td colspan="10">
                                        <div class="norecords">
                                            <img src="../../../../assets/img/waiting.gif" />

                                            <div>Loading Data<br />Please wait while we fetch the data for
                                                you</div>
                                        </div>
                                    </td>
                                </tr>
                                <tr *ngIf="noRecords == true">
                                    <td colspan="10">
                                        <div class="norecords">
                                            <img src="../../../../assets/img/nodata.svg" />
                                            <div>No records found.<br />
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>



<!-- backdrop for create agent  -->
<div class="fixed-footer">Powered by <a href="https://avantao.com/#/" target="_blank">Avantao</a></div>