<div *ngIf="contentLoaded; else loader">
    <carousel [cellsToScroll]="2" style="padding-left: 50px; z-index: unset" class="fixheight">
        <div *ngFor="let i of cardData; let index=index;" class="carousel-cell">
            <div class="mr-3">
                <div class="db-block w-175" (click)="getLeadsByCards(i); onClick(1)"
                    [ngClass]="{ 'db-block-active': tab === 'tab1' }">
                    <div class="p-2">
                        <div class="d-flex flex-column align-items-center justify-content-center">
                            <div class="img-circle">
                                <img src="../../../../assets/status/company.svg" class="img10" style="width: 10px;" />
                            </div>
                            <div class="f20 mt-3">
                                {{topBarCounts[i.value]}}
                            </div>
                            <div class="box-sub-text1 mt-2">{{i.name}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </carousel>
</div>

<ng-template #loader>
    <div>
        <carousel [cellsToScroll]="2" style="padding-left: 50px; z-index: unset" class="fixheight">
            <div *ngFor="let i of [0,1,2,3,4]; let index=index;" class="carousel-cell">
                <div class="mr-3">
                    <div class="db-block w-175">
                        <div class="p-2">
                            <div class="d-flex flex-column align-items-center justify-content-center">
                                <div class="img-circle">
                                    <img src="../../../../assets/status/company.svg" class="img10"
                                        style="width: 10px;" />
                                </div>
                                <div class="f20 mt-3">
                                    <ngx-skeleton-loader
                                        [theme]="{ width: '100px', 'border-radius': '0', height: '15px' }"></ngx-skeleton-loader>
                                </div>
                                <div class="box-sub-text1 mt-2"><ngx-skeleton-loader
                                        [theme]="{ width: '50px', 'border-radius': '0', height: '15px' }"></ngx-skeleton-loader>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </carousel>
    </div>
</ng-template>