import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
import { MatButtonModule } from "@angular/material/button";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { NgModule } from "@angular/core";
import { CommonModule, DatePipe } from "@angular/common";

import { LayoutRoutingModule } from "./layout-routing.module";
import { FooterComponent } from "./footer/footer.component";
import { TopbarComponent } from "./topbar/topbar.component";
import { SidebarComponent } from "./sidebar/sidebar.component";
import { LayoutComponent } from "./layout.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgbModule, NgbDropdownModule } from "@ng-bootstrap/ng-bootstrap";
import { NgxTimerModule } from "ngx-timer";
// import {
//   MatListModule,
//   MatIconModule,
//   MatTabsModule,
//   MatMenuModule,
// } from "@angular/material";
import { ChatModule } from "../pages/chat/chat.module";
import { AgemtscontactsComponent } from "./agemtscontacts/agemtscontacts.component";
import { NotificationComponent } from "./notification/notification.component";
import { DateTimePickerComponent } from "./date-time-picker/date-time-picker.component";
import { ActivelinkDirective } from "./sidebar/activelink.directive";
import { RemovelinkDirective } from "./sidebar/removelink.directive";
import { SettingsComponent } from "src/components/settings/settings.component";
import { DemoMaterialModule } from "../pages/uhleads/material.module";
import { MatListModule } from "@angular/material/list";
import { MatIconModule } from "@angular/material/icon";
import { MatTabsModule } from "@angular/material/tabs";
import { MatMenuModule } from "@angular/material/menu";
import { ProjectChangeComponent } from './project-change/project-change.component';
import { IncomingCallsComponent } from "./topbar/components/incoming-calls/incoming-calls.component";
import { EnquiryCallsComponent } from "./topbar/components/enquiry-calls/enquiry-calls.component";

@NgModule({
  declarations: [
    FooterComponent,
    TopbarComponent,
    SidebarComponent,
    LayoutComponent,
    AgemtscontactsComponent,
    NotificationComponent,
    DateTimePickerComponent,
    ActivelinkDirective,
    RemovelinkDirective,
    SettingsComponent,
    ProjectChangeComponent,
    IncomingCallsComponent,
    EnquiryCallsComponent
    // ActivelinkComponent,
  ],
  imports: [
    CommonModule,
    NgbModule,
    FormsModule,
    NgbDropdownModule,
    ReactiveFormsModule,
    LayoutRoutingModule,
    NgxTimerModule,
    MatListModule,
    MatIconModule,
    MatSnackBarModule,
    ChatModule,
    MatButtonModule,
    NgMultiSelectDropDownModule,
    MatTabsModule,
    MatMenuModule,
    DemoMaterialModule
    // AppModule
  ],
  providers: [DatePipe],
  exports: [DateTimePickerComponent, ProjectChangeComponent],
})
export class LayoutModule { }
