<div class="modal-header">
    <h4 *ngIf="isEdit=='dashboard'" class="modal-title" id="modal-basic-title">Select data to display in Dashboard</h4>
    <h4 *ngIf="isEdit=='overview'" class="modal-title" id="modal-basic-title">Select data to display in Overview</h4>
    <button type="button" id="closeButton3" class="close" aria-label="Close" (click)="closeDialog()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <section class="example-section" (click)="$event.stopPropagation();">


        <span class="example-list-section">

            <ul *ngIf="isEdit=='dashboard'" class="float-left itemList fb-cards">
                <li *ngFor="let subtask of config.columns; let i = index;">
                    <mat-checkbox [(ngModel)]="subtask.showInDashboard" [checked]="subtask.showInDashboard"
                        (change)="addColumn(subtask, i)" [color]="subtask.color">
                        {{subtask.displayName}}
                    </mat-checkbox>
                </li>
            </ul>

            <ul *ngIf="isEdit=='overview'" class="float-left itemList fb-cards">
                <li *ngFor="let subtask of config.columns; let i = index;">
                    <mat-checkbox [(ngModel)]="subtask.showInOverview" [checked]="subtask.showInOverview"
                        (change)="addColumn(subtask, i)" [color]="subtask.color">
                        {{subtask.displayName}}
                    </mat-checkbox>
                </li>
            </ul>

        </span>
    </section>
    <div class="row">
        <div class="col-md-12 text-right">
            <button class="btn btn-primary btn-sm mr-2" type="button" (click)="open1(addColumnForm)">
                Add New
            </button>
            <button type="button" class="btn btn-primary btn-sm" (click)="updateColumns()">
                Update
            </button>
        </div>
    </div>
</div>

<ng-template #addColumnForm let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">
            Add New Column
        </h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" style="max-height: 600px; overflow: auto">
        <ngb-alert type="danger" *ngIf="isError" [dismissible]="false">{{
            errorMessage
            }}</ngb-alert>
        <form (ngSubmit)="submitColumn();modal.dismiss('Cross click')" [formGroup]="columnForm">

            <div class="form-group">
                <label class="form-label">Name</label>
                <input class="form-control" name="displayName" #displayName formControlName="displayName"
                    [(ngModel)]="newColumn" [ngClass]="{
              'is-invalid': feb.displayName.touched && feb.displayName.invalid,
              'is-valid':
                feb.displayName.valid && feb.displayName.touched
            }" />
                <div *ngIf="feb.displayName.errors" class="invalid-feedback">
                    <div>{{errorMessage2}}</div>
                </div>
            </div>

            <div class="form-group">
                <label class="form-label">Select Type of Data: </label>
                <select id="inputState" class="form-control" name="type" [(ngModel)]="columnType" class="form-control"
                    formControlName="type"
                    [ngClass]="{'is-invalid': feb.type.touched && feb.type.invalid,'is-valid': feb.type.valid && feb.type.touched}">
                    <option disabled value="">Choose One</option>
                    <option value='String'>String</option>
                    <option value='Date'>Date</option>
                    <option value='Number'>Number</option>
                    <option value='Boolean'>Boolean</option>
                </select>
                <div *ngIf="feb.type.errors" class="invalid-feedback">
                    <div>Choose One</div>
                </div>
            </div>

            <div class="form-group">
                <label class="form-label">Show Column in Dashboard: </label>
                <select id="inputState" class="form-control" name="showInDashboard" [(ngModel)]="showColumnInDashboard"
                    class="form-control" formControlName="showInDashboard"
                    [ngClass]="{'is-invalid': feb.showInDashboard.touched && feb.showInDashboard.invalid,'is-valid': feb.showInDashboard.valid && feb.showInDashboard.touched}">
                    <option disabled value="">Choose One</option>
                    <option value=true>Yes</option>
                    <option value=false>No</option>
                </select>
                <div *ngIf="feb.showInDashboard.errors" class="invalid-feedback">
                    <div>Choose One</div>
                </div>
            </div>

            <div class="form-group">
                <label class="form-label">Show Column in Overview: </label>
                <select id="inputState" class="form-control" name="showInOverview" [(ngModel)]="showColumnInOverview"
                    class="form-control" formControlName="showInOverview"
                    [ngClass]="{'is-invalid': feb.showInOverview.touched && feb.showInOverview.invalid,'is-valid': feb.showInOverview.valid && feb.showInOverview.touched}">
                    <option disabled value="">Choose One</option>
                    <option value=true>Yes</option>
                    <option value=false>No</option>
                </select>
                <div *ngIf="feb.showInOverview.errors" class="invalid-feedback">
                    <div>Choose One</div>
                </div>
            </div>

            <!-- <div class="form-group">
                <label class="form-label">Show Feedback in Call</label>
                <select id="inputState" class="form-control" name="type2" [(ngModel)]="showNewFeedback2"
                    class="form-control" formControlName="type2" [ngClass]="{'is-invalid': feb.type2.touched && feb.type2.invalid,'is-valid': feb.type2.valid && feb.type2.touched}">
                    <option disabled value="">Choose One</option>
                    <option value=true>Show</option>
                    <option value=false>Hide</option>
                </select>
                <div *ngIf="feb.type.errors" class="invalid-feedback">
                    <div>Choose One</div>
                </div>
            </div> -->

            <div class="d-flex justify-content-end">
                <button type="submit" class="btn btn-primary btn-sm" [disabled]="!columnForm.valid">Submit</button>
            </div>
        </form>
    </div>
</ng-template>

<!-- <button mat-button mat-dialog-close (click)="closeDialog()">Cancel</button> -->