import { AuthService } from "src/app/account/auth/auth.service";
import { FormBuilder, FormControl, FormGroup, NgForm, Validators } from "@angular/forms";
import { RoleService } from "./../role-management/role.service";
import { Component, OnInit } from "@angular/core";
import { NgbModal, ModalDismissReasons, NgbModalOptions, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { SharedService } from "src/app/shared/shared.service";
import { ManagementService } from "../management.service";
import { IDropdownSettings } from "ng-multiselect-dropdown";
import { CreateAgent } from "../create-agent/create-agent.service";
import { environment } from "src/environments/environment";
import { ProjectService } from "./project.service";
import { ActivatedRoute } from "@angular/router";
import { ErrorHandlerService } from "src/app/error-handler.service";
export enum REGEX {
  EMAIL = '[A-Za-z0-9._%-]+@[A-Za-z0-9._%-]+\\.[a-z]{2,4}',
  PHONE = '[0-9]{10}$',
  PASSWORD = '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$',
  ZIP = '^[a-zA-Z0-9,\'-]*$',
  NUMBER = '^[0-9]*$',
  URL = '^(http:\/\/www\.|https:\/\/www\.|www\.|http:\/\/|https:\/\/)[a-zA-Z0-9\.\-]+\\.[a-zA-Z]{2,5}[\.]{0,1}',
  CHARACTER = '^[a-zA-Z ]+',
  SPECIAL_PHONE = '^[+|0-9]+[0-9]{1,}$',
  ADDRESS = '^[a-zA-Z0-9\s,\'-]*$',
}

@Component({
  selector: "app-project-management",
  templateUrl: "./project-management.component.html",
  styleUrls: ["./project-management.component.css"],
})
export class ProjectManagementComponent implements OnInit {
  closeResult: string;
  search: string;
  companyInfo: any = {
    name: "",
    twilioNumber: "",
    supportMail: "",
    forwardMail: "",
    desc: "",
  };
  feedbackDetails: any = {
    projId: '',
    id: '',
    name: "",
    active: true,
    bgColor: "",
    textColor: ""
  };
  totalCompanies: any[] = [];
  isUpdate: boolean = false;
  inputData: any;
  isError: boolean = false;
  errorMessage: string;
  superAdmin: Boolean = false;
  userData: any;
  public page: any = 1;
  public pageSize: any = 6;
  selectedCountryCode;
  countries: any[];
  group: any[] = [];
  groups: any[] = [];
  companySettings: IDropdownSettings;
  public order: boolean = false;
  public orderFeild: string = "UpdatedDate";
  public orderString: boolean | "asc" | "desc" = "desc";
  public companyId: string = "";
  public companyName: string = "";
  public companyForwardMail: string = "";
  public displayMenus: any[] = [];
  feedbacks: any[] = [];
  feedbackForm: FormGroup;
  feedbackModel: NgbModalRef;
  projectId: any;
  updatefeedbackModel: NgbModalRef;
  constructor(
    private modalService: NgbModal,
    private companyService: RoleService,
    private sharedService: SharedService,
    private authService: AuthService,
    private managementService: ManagementService,
    private agentService: CreateAgent,
    private projectService: ProjectService,
    private handleError: ErrorHandlerService,
    private route: ActivatedRoute,
    private fb: FormBuilder
  ) { }

  ngOnInit() {
    this.route.params.subscribe((data) => {
      if (data["companyId"]) {
        this.companyId = data["companyId"];
        this.getCompanyById(data["companyId"]);
        this.getCompanies(data["companyId"]);
        this.listMasterFeedbacks();
      }
      if (data["name"]) {
        this.companyName = data["name"];
      }
    });

    this.companySettings = {
      singleSelection: true,
      allowSearchFilter: true,
      closeDropDownOnSelection: true,
      textField: "Name",
    };

    this.fetchCountries();
    this.userData = JSON.parse(localStorage.getItem("userData"));
    // this.sharedService.companyCreated.next("company created");
    this.sharedService.pageEvent.emit({ pageName: "Project Management" });
    // this.authService.admin.subscribe((data) => {
    //   this.superAdmin = data;
    // });
  }

  open(content) {
    // this.isError = false;
    this.modalService.open(content, { size: "lg" }).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }

  private getDismissReason(reason: any): string {
    this.getCompanies(this.companyId);
    this.isUpdate = false;
    this.companyInfo["id"] = null;
    this.resetForm();
    this.isError = false;
    // this.companyInfo = { name: '', twilioNumber: '', supportMail: '' };
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }

  submit(form: NgForm) {
    if (form.valid) {
      if (!this.isUpdate) {
        this.projectService
          .createProject({
            name: form.value.name,
            supportMail: form.value.supportMail,
            twilioNumber: form.value.twilioNumber,
            forwardMail: this.companyInfo.forwardMail,
            companyId: this.companyId,
            description: form.value.desc,
          })
          .subscribe(
            (data) => {

              // this.sharedService.companyCreated.next("company created");
              this.managementService
                .createIvr(
                  {
                    projectName: data["Name"].replace(/\s+/g, "").toLowerCase(),
                    projectId: data["id"],
                    twilioNumber: data["TwilioNumber"],
                  },
                  data["id"]
                )
                .subscribe((data) => {
                  this.modalService.dismissAll();

                });
            },
            (err) => {
              this.isError = true;
              setTimeout(() => {
                this.isError = false;
              }, 5000);
              this.errorMessage = err.error.status;
              this.handleError.handleError(err)

            }
          );
      } else {

        this.projectService
          .updateProject({
            name: form.value.name,
            ActiveStatus: form.value.ActiveStatus,
            supportMail: form.value.supportMail,
            twilioNumber: form.value.twilioNumber,
            id: this.companyInfo["id"],
            incomingMailGroup:
              form.value.incomingMailGroup.length !== 0
                ? form.value.incomingMailGroup[0]["id"]
                : null,
            forwardMail: this.companyInfo["forwardMail"],
          })
          .subscribe(
            (data) => {
              if (form.value.updateIvr) {

                this.managementService
                  .createIvr(
                    {
                      projectName: form.value.name
                        .replace(/\s+/g, "")
                        .toLowerCase(),
                      twilioNumber: form.value.twilioNumber,
                      projectId: this.companyInfo.id,
                    },
                    this.companyInfo.id
                  )
                  .subscribe((data) => {

                  });
              }
              this.modalService.dismissAll();
            },
            (err) => {
              this.isError = true;
              this.errorMessage = err.error.status;
              this.handleError.handleError(err)

            }
          );
      }
    }
  }

  resetForm() {
    this.companyInfo = {
      name: "",
      twilioNumber: "",
      supportMail: "",
      activeStatus: null,
      id: null,
    };
    this.isError = false;
    this.errorMessage = "";
    this.groups = [];
    this.group = [];
  }

  onCompanySelect(id: any, content) {
    // if (this.superAdmin) {
    this.projectId = id;
    this.isUpdate = true;
    this.getFeedbackById(id);
    this.projectService.getProject(id).subscribe((data) => {
      this.companyInfo = {
        name: data["Name"],
        supportMail: data["SupportMail"],
        twilioNumber: data["TwilioNumber"],
        ActiveStatus: data["ActiveStatus"],
        id: id,
        forwardMail: data["ForwardMail"] || "",
        desc: data["Description"],
      };
      this.open(content);
      this.agentService.getMailGroups(data["id"]).subscribe((datas) => {
        this.groups = datas.map((datac) => ({
          Name: datac["Name"],
          id: datac["id"],
        }));
        let selectedGroup = this.groups.find(
          (datap) => datap["id"] === data["IncomingMailGroup"]
        );
        if (selectedGroup) {
          this.group = [selectedGroup["Name"]];
        }
      }, (error) => {
        this.handleError.handleError(error)
      });
    }, (error) => {
      this.handleError.handleError(error)
    });
    // }
  }

  getCompanies(id) {
    this.projectService.getProjects(id).subscribe((data) => {
      this.totalCompanies = data;
    }, (error) => {
      this.handleError.handleError(error)
    });
  }

  selectCountry(country) {

    this.selectedCountryCode = country.code;
  }

  fetchCountries() {
    this.sharedService.getCountries().subscribe((data) => {
      this.countries = data;
    }, (error) => {
      this.handleError.handleError(error)
    });
  }

  sortOrder(felidName) {
    this.orderFeild = felidName;
    this.order = !this.order;
    if (this.order) {
      this.orderString = "asc";
    } else {
      this.orderString = "desc";
    }
  }

  forwardMail(event) {
    let name = event.replace(/[^A-Z0-9]+/gi, "");
    this.companyInfo["forwardMail"] = name + this.companyForwardMail;
  }

  getCompanyById(id) {
    this.companyService.getCompanyById(id).subscribe((data) => {
      this.companyForwardMail = "-" + data["ForwardMail"];
    }, (error) => {
      this.handleError.handleError(error)
    });
  }
  checkBox(item): boolean {
    return this.displayMenus.includes(parseInt(item));
  }
  childMenuCheckBox(value) {
    console.log(value.option._selected, "-----------------------", value.option._value)
    if (value.option._selected) {
      this.displayMenus.push(value.option._value);
    } else {
      this.displayMenus = this.displayMenus.filter((val) => {
        return val !== value.option._value
      });
    }
  }
  listMasterFeedbacks() {
    this.projectService.listMasterFeedbacks().toPromise()
      .then((resp: any) => {
        this.feedbacks = resp.data;
      })
      .catch(err => {

      })
  }
  statusCheckedValues(value) {
    let obj = {
      ProjectId: this.companyInfo.id,
      feedbackId: this.displayMenus
    };
    this.projectService.addInProject(obj).toPromise()
      .then((resp: any) => {
        this.displayMenus = [];
        this.modalService.dismissAll();
      })
      .catch(err => {
        this.modalService.dismissAll();
      })
  }
  getFeedbackById(id) {
    this.projectService.getFeedbackById(id).toPromise()
      .then((resp: any) => {
        for (let i = 0; i < resp.feedback.length; i++) {
          this.displayMenus.push(resp.feedback[i].feedbackId)
        }
      })
      .catch(err => {

      })
  }
  open1(content) {
    this.initFeedback();
    const options: NgbModalOptions = {
      backdropClass: 'app-session-modal-backdrop',
      windowClass: 'app-session-modal-window',
      size: "md"
    };
    this.feedbackModel = this.modalService.open(content, options);
    this.feedbackModel.result.then(
      (result) => {
        // this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }
  initFeedback() {
    this.feedbackForm = this.fb.group({
      name: new FormControl('', [Validators.required]),
      type: new FormControl('', Validators.required),
      desc: new FormControl('', [Validators.required]),
    });
  }

  get feb() {
    return this.feedbackForm.controls;
  }
  submitFeedback() {
    if (this.feedbackForm.invalid) {
      return;
    }
    let obj = this.feedbackForm.value;
    obj.value = obj.name.replace(/[&\/\\#,+()$~%.'":*?<>{}\s]/g, '').toLocaleLowerCase();
    this.projectService.addNewMenu(obj).toPromise()
      .then((resp: any) => {
        if (resp.status === true) {
          this.listMasterFeedbacks();
          this.feedbackModel.close();
        } else {
          this.isError = true;
          this.errorMessage = resp.msg;
          setTimeout(() => {
            this.isError = false;
            this.errorMessage = '';
          }, 3000);
        }
      })
      .catch(err => {
        this.isError = true;
        this.errorMessage = err.error.msg;
        setTimeout(() => {
          this.isError = false;
          this.errorMessage = '';
        }, 3000);
      })
  }
  editFeedbackByProject(id, projId, component) {
    let obj = {
      "projId": projId,
      "id": id
    };
    this.projectService.getFeedbackByProj(obj).toPromise()
      .then((resp: any) => {
        this.feedbackDetails.projId = projId;
        this.feedbackDetails.id = id;
        this.feedbackDetails.name = resp[0].name;
        this.feedbackDetails.active = resp[0].active;
        this.feedbackDetails.bgColor = resp[0].bgColor;
        this.feedbackDetails.textColor = resp[0].textColor;
      })
      .catch(err => {

      })
    this.updatefeedbackModel = this.modalService.open(component, { size: 'sm' });
  }

  updateFeedbackById(form: NgForm) {
    console.log(this.feedbackDetails)
    this.projectService.updateFeedbackByProj(this.feedbackDetails).toPromise()
      .then((resp: any) => {
        this.updatefeedbackModel.close();
      })
      .catch((err => {

      }))
  }
}
