import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'nameformatting'
})
export class NameformattingPipe implements PipeTransform {

  transform(value: string,): any {
    if (value.toLowerCase().includes("whatsapp:")) {
      return value.toLowerCase().replace("whatsapp:", '');
    }
    return value;
  }

}
