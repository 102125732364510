import {
  Component,
  OnInit,
  ViewChild,
  HostListener,
  ElementRef,
  Inject,
} from "@angular/core";
import { UhLeadsService } from "../uhleads.service";
import { FormControl, ReactiveFormsModule } from "@angular/forms";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import { SharedService } from "src/app/shared/shared.service";
import { DashboardService } from "../dashboard/dashboard.service";
import { LayoutService } from "src/app/layout/layout.service";
import { formatDate } from "@angular/common";
import { MarketingService } from "../marketing/marketing.service";
import * as xlsx from "xlsx";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { AbstractControl, Validators } from "@angular/forms";
import * as moment from "moment";
import { Exceldata2 } from "../marketing/smsmarketing/models/ExecelData.model2";
import { AuthService } from "src/app/account/auth/auth.service";
import { Router } from "@angular/router";
import { CreateAgent } from "../create-agent/create-agent.service";
import {
  countUpTimerConfigModel,
  CountupTimerService,
  timerTexts,
} from "ngx-timer";
import { DOCUMENT } from "@angular/common";
import { environment } from "src/environments/environment";
import { MomentFormatService } from "src/app/moment-format-service";
import { NotificationsService } from "src/app/notification.service";

import { fromEvent } from "rxjs";
import {
  filter,
  debounceTime,
  distinctUntilChanged,
  tap,
} from "rxjs/operators";
import { RoleService } from "../role-management/role.service";
import { ErrorHandlerService } from "src/app/error-handler.service";
import { cuNextService } from "../cunext.service";
var sortJsonArray = require("node_modules/sort-json-array");

@Component({
  selector: 'app-cu-next',
  templateUrl: './cu-next.component.html',
  styleUrls: ['./cu-next.component.css']
})
export class CuNextComponent implements OnInit {
  public fileSource = environment.fileServer;
  public userData: any;
  public filterValue = "all";
  nav_position: string = "end";
  public statusValue = "pending";
  public page: any = 1;
  public pageSize: any = 12;
  public search: string;
  totalApplications: number;
  completedApplications: number;
  completedApplications2: number;
  incompleteapplications: number;
  documentspending: number;
  documentssubmitted: number;
  callback: number;
  activityData: any;
  activityData2: any;

  activityTable: any = [];
  activityTable2: any;

  startDate: any;
  endDate: any;
  leadsbody: any;
  companyId: any;
  companyAdmin: Boolean = false;
  rotating: boolean = false;
  projectId: any;
  downloadLink: any;
  public filePath: any;
  public fileData: Exceldata2[] = [];
  public uploaded: boolean = false;
  public error: boolean = false;
  public insertCount: number = 0;
  public leadType: string = "all";
  public errorMessage: string = "";
  public submitted: boolean = false;
  public edited: boolean = false;
  admin: boolean = false;
  public counselling: boolean = false;
  isCallcomming: boolean = false;
  connection: any = null;
  // audio: any;
  audio = new Audio();
  audioStatus: boolean = false;
  sideViewerData: any = { Activities: [], CallDetails: [] };
  documentsList: any;
  documentsList2: any;
  selectedCallerId: any;
  selectedLead: any;
  commentText: string = "";
  newStatus: any;
  docUrl: any;
  currentLeadId: any;
  currentGuid: any;
  documentComments: string;
  validationStatus: boolean = false;
  currentItem: any;
  showdeferredintake: Boolean = false;
  DIyearArray: any = [];
  @ViewChild("uploadFile") uploadFile;
  @ViewChild("closeButton") closeButton;
  @ViewChild("closeButton2") closeButton2;
  @ViewChild("closeButton3", { static: true }) closeButton3;
  @ViewChild("sideNav", { static: true }) sideNav;
  @ViewChild("inputString", { static: true }) input: ElementRef;
  @ViewChild("totalRecords", { static: true }) totalRecords: ElementRef;

  leadIds = [];
  selectedAll: boolean = false;
  agentList: any;
  GBPagentList: any;
  defaultAgent = "default";
  selectedAgent: any;

  testConfig: any;
  // callStarted: boolean = false;
  startCall: boolean = false;
  public stopCall: boolean = false;
  public clearTime: any;
  dialerScreen1 = "dialer";
  phoneNumber: any = "";
  agentCall: String = "";
  isShownMarketing: boolean = false;
  dailerScreen: any = "nocall";
  isCallingProgress: boolean = false;
  isOutgoing: boolean = false;
  isIncoming: boolean = false;
  disabled: any;
  callStatus: string = "Calling..";
  tab: any;
  showDate: boolean = false;

  nextCallerName: any;
  nextCallerCity: any;
  nextCallerNumber: any;

  public marketingId: {
    id: string;
    status: string;
    phoneNumber: string;
    email: string;
    name: string;
    module: string;
    city: string;
    nextCaller: any;
    lead: any;
  } = {
      id: "",
      status: "",
      name: "",
      email: "",
      phoneNumber: "",
      module: "",
      city: "",
      nextCaller: null,
      lead: {},
    };

  totalAgents: any;
  agentName: any;

  public startIndex: number = 0;

  date1: any;
  date2: any;
  serializedDate = new FormControl(new Date().toISOString());
  documentsDenied: number;
  CallsPending: number;
  revalidate: any;
  minItems = 5;
  unassigned: any;
  assigned: any;
  private colors = [
    [{ bg: "blueviolet", color: "white" }],
    [{ bg: "AntiqueWhite", color: "white" }],
    [{ bg: "Coral", color: "white" }],
    [{ bg: "Crimson", color: "white" }],
    [{ bg: "DarkOrange", color: "white" }],
    [{ bg: "DeepPink", color: "white" }],
    [{ bg: "#c5924e", color: "white" }],
  ];
  assignedtome: any;
  file: File;
  customDocName: string;
  docUploadError: string;
  docUploadError2: string = "Select files to upload.";
  customFileList: any = [];
  customDocLeadId: any;
  leadtoEdit: {
    id: any
    application_status: string
    accepted_app_status: string
    first_name: string
    last_name: string
    id_number: string
    student_email: string
    when_like_to_begin_studies: string
    what_program: string
    program_enddate_listed: string
    address_line1?: string
    address_line2?: string
    city?: string
    state?: string
    country?: string
    zipcode?: null,
    reject_reason?: string
  };
  editComment: string;
  editStatus: string;
  allRecords: any;
  isLoading: boolean = true;
  noRecords: boolean = false;
  selectedFiles: File[] = [];
  docUploadID: any;
  docUploadStatus: boolean = false;
  activityfilterString: string = "";
  uploadSuccess: string = "none";
  topBarCounts = {
    Total: "0",
    Accepted: "0",
    Cancelled: "0",
    Cold: "0",
    Deferred: "0",
    GradesHold: "0",
    GraduationHold: "0",
    InReview: "0",
    Incomplete: "0",
    Reject: "0",
    Submitted: "0",
    NotApplicable: "0",
    AcceptedHoldGrades: "0",
    AcceptedHoldGraduation: "0",
    AcceptedEarly: "0",
    AcceptedStatus: "0",
    AcceptedMSCS: "0",
    AcceptedBusinessHold: "0"
  };
  cardLeadType: string = "all";
  thresItem: any;
  thresId: any;
  thresModal: NgbModalRef;
  pageNumber: Number = 1;
  totalPages: Number = 0;
  currentCard: string = "total";
  sortColumn = "updatedAt";
  sortOrder = "des";
  projectLists: any;
  smsText: string;

  DIyear: any;
  DIterms: any;
  rejectReason: boolean = false;
  constructor(
    public uhleadsservice: UhLeadsService,
    public cunextService: cuNextService,
    public sharedService: SharedService,
    public dashBoardService: DashboardService,
    public layoutService: LayoutService,
    public marketingService: MarketingService,
    private readonly loginService: AuthService,
    private readonly modalService: NgbModal,
    private authService: AuthService,
    private router: Router,
    private agentService: CreateAgent,
    public countupTimerService: CountupTimerService,
    private momentService: MomentFormatService,
    private NotificationService: NotificationsService,
    private companyService: RoleService,
    private handleError: ErrorHandlerService,
    @Inject(DOCUMENT) private domService: HTMLDocument
  ) {
    this.testConfig = new countUpTimerConfigModel();
    this.testConfig.timerClass = "test_Timer_class";
    this.testConfig.timerTexts = new timerTexts();
    this.testConfig.timerTexts.hourText = ":"; //default - hh
    this.testConfig.timerTexts.minuteText = ":"; //default - mm
    this.testConfig.timerTexts.secondsText = " ";
  }

  ngOnInit() {
    var x = document.getElementsByClassName(
      "sideDataViewer"
    ) as HTMLCollectionOf<HTMLElement>;
    for (let i = 0; i < x.length; i++) {
      x[i].style.display = "none";
    }
    this.getAgentNames();
    this.userData = JSON.parse(localStorage.getItem("userData"));
    this.date1 = new FormControl(new Date());
    this.date2 = new FormControl(new Date(Date.now() - 864e5));
    this.filterValue == "24hrs";
    var today = new Date();
    this.endDate = new Date(Date.now());
    this.startDate = new Date(Date.now() - 864e5 * 365);

    this.startDate = this.momentService.dashboardDateFormat(
      new Date(new Date(Date.now() - 864e5 * 365)).getTime()
    );
    this.endDate = this.momentService.dashboardDateFormat(
      new Date(new Date(Date.now() + 864e5))
    );

    this.companyService.projectAdmin(this.companyId).subscribe(
      (projects) => {
        this.projectId = projects.filter(
          (x) => x.Name == "CuNext"
        )[0].id;
        if (this.projectId != null) {
          this.sharedService.getProjectAdmin(this.projectId).subscribe(
            (data) => {
              this.admin = data;
              this.getTopBarCuNext();
              this.getCardCount(
                {
                  admin: this.admin,
                  mail: this.userData.mail,
                  ProjectId: this.projectId,
                  accepted_app_status: "all",
                  start: this.startDate,
                  end: this.endDate
                },
                "all"
              );
              this.getAllData(
                this.userData,
                "all",
                new Date(Date.now() - 864e5 * 365),
                new Date(Date.now())
              );
              // this.getData(this.userData, this.statusValue, this.startDate, this.endDate);
              this.showTotalApplications(); //Shohw only pending records on page  load
              this.onClick(1); //Activating pending card by default

              // this.getDownloadLink();

              // this.uhleadsservice.viewReceivedLead.subscribe((res: any) => {
              //   if (res) {
              //     this.viewLead(res);
              //   }
              // });

              this.uhleadsservice
                .getAgentList(this.projectId, this.userData.id)
                .subscribe(
                  (data: any) => {
                    this.agentList = data;
                  },
                  (error) => {
                    this.handleError.handleError(error);
                  }
                );

              this.uhleadsservice.pageNumber.subscribe((pageNo: any) => {
                this.pageNumber = pageNo;
                if (this.leadType != "filter") {
                  this.getCardCount(
                    {
                      admin: this.admin,
                      mail: this.userData.mail,
                      ProjectId: this.projectId,
                      accepted_app_status: "all",
                      start: this.startDate,
                      end: this.endDate,
                    },
                    "all"
                  );
                } else if (this.leadType == "filter") {
                  // this.searchLeads(this.search, this.pageNumber)

                  let payload = {
                    admin: this.admin,
                    ProjectId: this.projectId,
                    start: "2021-08-02 09:40:25+00",
                    end: "2022-08-03 09:40:25+00",
                    page: this.pageNumber,
                    leadType: "All",
                    search: this.search,
                  };
                  if (this.search.length > 0) {
                    this.cunextService
                      .GetSearchDashboardStudent(payload)
                      .subscribe(
                        (res: any) => {
                          this.activityTable = res.data;
                        },
                        (error) => {
                          this.handleError.handleError(error);
                        }
                      );
                  } else {
                    this.totalRecords.nativeElement.click();
                    this.leadType = "all";
                  }
                }
              });
            },
            (error) => {
              this.handleError.handleError(error);
            }
          );
        } else {
          this.admin = false;
        }
      },
      (error) => {
        this.handleError.handleError(error);
      }
    );

    // server-side search
    fromEvent(this.input.nativeElement, "keyup")
      .pipe(
        filter(Boolean),
        debounceTime(1500),
        distinctUntilChanged(),
        tap((event: KeyboardEvent) => {
          this.search = this.input.nativeElement.value;
          this.pageNumber = 1;
          this.searchLeads(this.input.nativeElement.value, this.pageNumber);
        })
      )
      .subscribe();
    let d = new Date();
    this.DIyearArray.push(d.getFullYear());
    let year = d.getFullYear();
    for (let i = 0; i < 4; i++) {
      year = year + 1;
      this.DIyearArray.push(year);
    }
  }

  onClick(check) {
    if (check == 1) {
      this.tab = "tab1";
    } else if (check == 2) {
      this.tab = "tab2";
    } else if (check == 3) {
      this.tab = "tab3";
    } else if (check == 4) {
      this.tab = "tab4";
    } else if (check == 5) {
      this.tab = "tab5";
    } else if (check == 6) {
      this.tab = "tab6";
    } else if (check == 7) {
      this.tab = "tab7";
    } else if (check == 8) {
      this.tab = "tab8";
    } else if (check == 9) {
      this.tab = "tab9";
    } else if (check == 10) {
      this.tab = "tab10";
    } else if (check == 11) {
      this.tab = "tab11";
    } else if (check == 12) {
      this.tab = "tab12";
    } else if (check == 13) {
      this.tab = "tab13";
    } else {
      this.tab = null;
    }
  }

  getAgentNames() {
    this.agentService.getAgentNames().subscribe(
      (data) => {
        this.totalAgents = data;

        this.uhleadsservice.getGBPAgentList().subscribe(
          (res: any) => {
            this.GBPagentList = res;
            for (let i = 0; i < this.GBPagentList.length; i++) {
              this.totalAgents.push({
                FirstName: this.GBPagentList[i].FIRST_NAME,
                LastName: this.GBPagentList[i].LAST_NAME,
                Mailid: this.GBPagentList[i].EMAIL_ADDRESS,
                ProfilePhoto: this.GBPagentList[i].ProfilePhoto,
              });
            }
          },
          (error) => {
            this.handleError.handleError(error);
          }
        );
        // this.totalAgents.push({FirstName: "Test", LastName: "Test", MailId: "test@test.com", ProfilePhote:"test.com"})
      },
      (error) => {
        this.handleError.handleError(error);
      }
    );
  }
  assignAgentModal() {
    this.defaultAgent = "default";
    this.selectedAgent = null;
  }
  agentSelected(selectedAgent) {
    this.selectedAgent = selectedAgent;
  }
  assignLEads() {
    if (this.leadIds.length == 0) {
      this.uhleadsservice.showWarning("Select leads to be assigned", "Alert!");
      return;
    }
    if (this.selectedAgent) {
      this.cunextService
        .assignStudent({
          AssignedTo: this.selectedAgent,
          InsertedBy: this.userData.mail,
          StudentID: this.leadIds,
        })
        .subscribe(
          (res) => {
            this.selectedAll = false;
            document.querySelectorAll('input[type=checkbox]').forEach(el => {
              let element: any = el
              element.checked = false;
            });
            // let payload = {
            //   AgentMail: this.userData.mail,
            //   GBPAssignedTo: this.selectedAgent,
            //   LeadId: this.leadIds,
            //   Platform: "CRM",
            //   StudentDocId: 0,
            //   Title: "Assigned",
            //   description:
            //     "New leads are assigned to you by " +
            //     this.getAgentName(this.userData.mail),
            //   notificationType: "AssignedToAgent",
            //   token: this.userData.token,
            // };
            // this.NotificationService.sendNotifications(payload).subscribe(
            //   (res: any) => {
            //     if (res.status === 200) {
            //     }
            //   },
            //   (error) => {
            //     this.handleError.handleError(error);
            //   }
            // );
            this.uhleadsservice.showSuccess(
              "Successfully assigned the agents",
              "Success"
            );
            this.getTopBarCuNext();
            // this.getCardData(
            //   this.userData,
            //   "total",
            //   this.startDate,
            //   this.endDate
            // );
            this.closeButton.nativeElement.click();
            document.getElementById("closeAssignButton").click();
          },
          (error) => {
            this.handleError.handleError(error);
          }
        );
    } else {
      this.uhleadsservice.showWarning("Please select an agent", "Alert!");
    }
  }

  removeItem(arr, value) {
    var index = arr.indexOf(value);
    if (index > -1) {
      arr.splice(index, 1);
    }
    return arr;
  }
  setLeadType() {
    this.leadType = "";
  }

  showTotalApplications() {
    this.statusValue = "all";
    this.leadType = "all";
    this.currentCard = "total";
    this.getTopBarCuNext();
    this.getCardCount(
      {
        admin: this.admin,
        mail: this.userData.mail,
        ProjectId: this.projectId,
        accepted_app_status: "all",
        start: this.startDate,
        end: this.endDate,
      },
      "all"
    );
  }
  showIncompleteApplications() {
    this.statusValue = "all";
    this.leadType = "Incomplete";
    this.currentCard = "Incomplete";
    this.getTopBarCuNext();
    this.getCardCount(
      {
        admin: this.admin,
        mail: this.userData.mail,
        ProjectId: this.projectId,
        accepted_app_status: "Incomplete",
        start: this.startDate,
        end: this.endDate,
      },
      "Incomplete"
    );
  }

  showSubmittedApplications() {
    this.statusValue = "all";
    this.leadType = "Submitted";
    this.currentCard = "Submitted";
    this.getTopBarCuNext();
    this.getCardCount(
      {
        admin: this.admin,
        mail: this.userData.mail,
        ProjectId: this.projectId,
        accepted_app_status: "Submitted",
        start: this.startDate,
        end: this.endDate,
      },
      "Submitted"
    );

  }
  showInReviewApplications() {
    this.statusValue = "all";
    this.leadType = "In Review";
    this.currentCard = "In Review";
    this.getTopBarCuNext();
    this.getCardCount(
      {
        admin: this.admin,
        mail: this.userData.mail,
        ProjectId: this.projectId,
        accepted_app_status: "In Review",
        start: this.startDate,
        end: this.endDate,
      },
      "In Review"
    );
  }
  showGraduationHoldApplications() {
    this.statusValue = "all";
    this.leadType = "Graduation Hold";
    this.currentCard = "Graduation Hold";
    this.getTopBarCuNext();
    this.getCardCount(
      {
        admin: this.admin,
        mail: this.userData.mail,
        ProjectId: this.projectId,
        accepted_app_status: "Graduation Hold",
        start: this.startDate,
        end: this.endDate,
      },
      "Graduation Hold"
    );
  }
  showGradesHoldApplications() {
    this.statusValue = "all";
    this.leadType = "Grades Hold";
    this.currentCard = "Grades Hold";
    this.getTopBarCuNext();
    this.getCardCount(
      {
        admin: this.admin,
        mail: this.userData.mail,
        ProjectId: this.projectId,
        accepted_app_status: "Grades Hold",
        start: this.startDate,
        end: this.endDate,
      },
      "Grades Hold"
    );
  }
  showAcceptedApplications() {
    this.statusValue = "all";
    this.leadType = "Accepted";
    this.currentCard = "Accepted";
    this.getTopBarCuNext();
    this.getCardCount(
      {
        admin: this.admin,
        mail: this.userData.mail,
        ProjectId: this.projectId,
        accepted_app_status: "Accepted",
        start: this.startDate,
        end: this.endDate,
      },
      "Accepted"
    );
  }
  showDeferredApplications() {
    this.statusValue = "all";
    this.leadType = "Deferred";
    this.currentCard = "Deferred";
    this.getTopBarCuNext();
    this.getCardCount(
      {
        admin: this.admin,
        mail: this.userData.mail,
        ProjectId: this.projectId,
        accepted_app_status: "Deferred",
        start: this.startDate,
        end: this.endDate,
      },
      "Deferred"
    );
  }
  showRejectApplications() {
    this.statusValue = "all";
    this.leadType = "Reject";
    this.currentCard = "Reject";
    this.getTopBarCuNext();
    this.getCardCount(
      {
        admin: this.admin,
        mail: this.userData.mail,
        ProjectId: this.projectId,
        accepted_app_status: "Reject",
        start: this.startDate,
        end: this.endDate,
      },
      "Reject"
    );
  }
  showCOLDApplications() {
    this.statusValue = "all";
    this.leadType = "COLD";
    this.currentCard = "COLD";
    this.getTopBarCuNext();
    this.getCardCount(
      {
        admin: this.admin,
        mail: this.userData.mail,
        ProjectId: this.projectId,
        accepted_app_status: "COLD",
        start: this.startDate,
        end: this.endDate,
      },
      "COLD"
    );
  }
  showCancelledApplications() {
    this.statusValue = "all";
    this.leadType = "Cancelled";
    this.currentCard = "Cancelled";
    this.getTopBarCuNext();
    this.getCardCount(
      {
        admin: this.admin,
        mail: this.userData.mail,
        ProjectId: this.projectId,
        accepted_app_status: "Cancelled",
        start: this.startDate,
        end: this.endDate,
      },
      "Cancelled"
    );
  }
  //Shows date if the filter value is custom
  hideDate() {
    // this.filterValuev
    if (this.filterValue == "custom" && this.showDate == false) {
      this.showDate = true;
    }
  }

  public onInput(event: any) {
    let payload = {
      admin: this.admin,
      ProjectId: this.projectId,
      start: "2021-08-02 09:40:25+00",
      end: "2022-08-03 09:40:25+00",
      page: 1,
      leadType: "All",
      search: this.search,
    };
    if (this.search != "") {
      this.cunextService
        .GetSearchDashboardStudent(payload)
        .subscribe((res: any) => {
          this.activityTable = res.data;
        });
      this.activityTable = this.allRecords;
    } else if (this.search == "") {
      this.totalRecords.nativeElement.click();
    }
  }

  searchLeads(value, page) {
    let payload = {
      admin: this.admin,
      ProjectId: this.projectId,
      start: "2020-08-02 09:40:25+00",
      end: "2025-08-03 09:40:25+00",
      page: page,
      leadType: "All",
      search: value,
    };

    if (value.length > 0) {
      this.leadType = "filter";
      this.cunextService.GetSearchDashboardStudent(payload).subscribe(
        (res: any) => {
          this.activityTable = res.data;

          let newTotal = ~~(Number(res.totalResults) / 12) + 1;
          this.uhleadsservice.totalPages.next(Number(newTotal));
        },
        (error) => {
          this.handleError.handleError(error);
        }
      );
    } else {
      this.leadType = "all";
      this.getTopBarCuNext();
      // this.getCardData(this.userData, "total", this.startDate, this.endDate);
    }
  }
  updateDateFilter(event: any) {
    if (event.target.value != "custom") {
      this.endDate = this.momentService.dashboardDateFormat(
        new Date(new Date(Date.now())).getTime()
      );
    }
    if (event.target.value == "custom") {
      //show custom date filter
      this.showDate = true;
    } else if (event.target.value == "all") {
      this.startDate = this.momentService.dashboardDateFormat(
        new Date(new Date(Date.now() - 864e5 * 365)).getTime()
      );

      this.showDate = false;
    } else if (event.target.value == "24hrs") {
      this.startDate = this.momentService.dashboardDateFormat(
        new Date(new Date(Date.now() - 864e5)).getTime()
      );

      this.showDate = false;
    } else if (event.target.value == "72hrs") {
      this.startDate = this.momentService.dashboardDateFormat(
        new Date(new Date(Date.now() - 864e5 * 3)).getTime()
      );

      this.showDate = false;
    } else if (event.target.value == "7days") {
      this.startDate = this.momentService.dashboardDateFormat(
        new Date(new Date(Date.now() - 864e5 * 7)).getTime()
      );

      this.showDate = false;
    } else if (event.target.value == "14days") {
      this.startDate = this.momentService.dashboardDateFormat(
        new Date(new Date(Date.now() - 864e5 * 14)).getTime()
      );

      this.showDate = false;
    } else if (event.target.value == "30days") {
      this.startDate = this.momentService.dashboardDateFormat(
        new Date(new Date(Date.now() - 864e5 * 30)).getTime()
      );

      this.showDate = false;
    }
    // this.getCounts(this.userData, "all", this.startDate, this.endDate, "all");
    this.getTopBarCuNext();
    this.getCardCount(
      {
        admin: this.admin,
        mail: this.userData.mail,
        ProjectId: this.projectId,
        leadType: this.cardLeadType,
        start: this.startDate,
        end: this.endDate,
      },
      this.cardLeadType
    );

    // this.getData(this.userData, "all", this.startDate, this.endDate);
    this.getTopBarCuNext();
    // this.getCardData(this.userData, "total", this.startDate, this.endDate);
  }

  updateActivity() {
    // this.getData(this.userData, "all", this.startDate, this.endDate);
    this.getTopBarCuNext();
    // this.getCardData(this.userData, "total", this.startDate, this.endDate);
    this.totalApplications = this.activityTable.length;
    this.completedApplications = this.activityTable.filter(function (el) {
      return el.LeadDocStatus == "validated" || el.LeadDocStatus == "Validated";
    }).length;
    this.completedApplications2 = this.activityTable.filter(function (el) {
      return el.LeadDocStatus == "completed" || el.LeadDocStatus == "complete";
    }).length;
    this.incompleteapplications = this.activityTable.filter(function (el) {
      return el.APPStatus == "Incomplete";
    }).length;
    this.documentspending = this.activityTable.filter(function (el) {
      return el.Phase == "pending";
    }).length;
    this.documentssubmitted = this.activityTable.filter(function (el) {
      return el.Phase == "complete" || el.APPStatus == "complete";
    }).length;
    this.callback = this.activityTable.filter(function (el) {
      return el.Phase == "callback" || el.Phase == "Callback";
    }).length;
  }

  getStartDate(type: string, event: MatDatepickerInputEvent<Date>) {
    this.startDate = event.value;
    this.startDate = this.momentService.dashboardDateFormat(
      new Date(this.startDate).getTime
    );
  }
  getEndDate(type: string, event: MatDatepickerInputEvent<Date>) {
    this.endDate = event.value;
    this.endDate = this.momentService.dashboardDateFormat(
      new Date(this.endDate).getTime
    );
  }

  // getDownloadLink() {
  //   this.marketingService
  //     .getExcelLink(this.fileSource + "/TemplateDocuments/campbellsville.xlsx")
  //     .subscribe(
  //       (data) => {
  //         this.downloadLink = data["url"];
  //       },
  //       (error) => {
  //         this.handleError.handleError(error);
  //       }
  //     );
  // }

  redirectToUpload() {
    this.router.navigate(["./upload/" + this.projectId]);
  }

  get editLeads(): { [key: string]: AbstractControl } {
    return this.cunextService.editLead.controls;
  }

  getDashboardLeads(leadType) {
    if (!this.projectId) {
      return;
    }
    this.leadType = leadType;
  }

  //Date Filter
  filterData() {
    this.onClick(null);
    this.startDate = this.momentService.dashboardDateFormat(
      new Date(this.date2.value).getTime()
    );
    this.endDate = this.momentService.dashboardDateFormat(
      new Date(this.date1.value).getTime()
    );

    if (this.endDate < this.startDate) {
      // window.alert("Choose the right date format!");
      this.uhleadsservice.showWarning(
        "End date cannot be less than Start date!",
        "Wrong Date!"
      );
      return;
    } else {
      this.getTopBarCuNext();
      // this.getCardData(this.userData, "total", this.startDate, this.endDate);
      this.getTopBarCuNext();
      this.getCardCount(
        {
          admin: this.admin,
          mail: this.userData.mail,
          ProjectId: this.projectId,
          leadType: this.cardLeadType,
          start: this.startDate,
          end: this.endDate,
        },
        this.cardLeadType
      );
      this.showDate = false;
    }
  }
  // getCounts(userData: any, leadtype, startDate, endDate, filter) {
  //   this.leadIds = [];
  //   this.activityTable = [];
  //   this.isLoading = true;
  //   this.noRecords = false;
  //   this.search = "";
  //   // this.activityTable = [];
  //   this.companyService.projectAdmin(this.companyId).subscribe(
  //     (projects) => {
  //       this.projectId = projects.filter(
  //         (x) => x.Name == "CuNext"
  //       )[0].id;
  //       this.leadsbody = {
  //         admin: this.admin,
  //         // admin: true,
  //         mail: userData.mail,
  //         ProjectId: this.projectId,
  //         leadType: leadtype,
  //         start: this.startDate,
  //         end: this.endDate,
  //       };

  //       this.uhleadsservice.getActivityTable2(this.leadsbody).subscribe(
  //         (res) => {
  //           this.activityData2 = res;

  //           this.activityData2.forEach(function (a) {
  //             Object.keys(a).map((key) => {
  //               a[key] === null ? (a[key] = "-") : a[key];
  //             });
  //           });

  //           for (let i = 0; i < this.activityData2.length; i++) {
  //             if (this.activityData2[i].PhoneNumber == "0000000000") {
  //               this.activityData2[i].PhoneNumber = "-";
  //               this.activityData2[i].CountryCode = "-";
  //             }

  //             this.activityData2[i].AssignedtoName = this.getAgentName(
  //               this.activityData2[i].Assignedto
  //             );
  //             this.activityData2[i].AssignedtoInitial = this.getAgentInitial(
  //               this.activityData2[i].Assignedto
  //             );
  //             this.activityData2[i].AssignedtoColor = this.getAgentColor(
  //               this.activityData2[i].Assignedto
  //             );
  //             this.activityData2[i].AssignedtoImageURL = this.getAgentphoto(
  //               this.activityData2[i].Assignedto
  //             );
  //             if (
  //               this.activityData2[i].LeadDocStatus == "CRM-Revalidate" ||
  //               this.activityData2[i].LeadDocStatus == "Admin-Revalidate" ||
  //               this.activityData2[i].LeadDocStatus == "Admin-revalidate" ||
  //               this.activityData2[i].LeadDocStatus == "CRM-revalidate"
  //             ) {
  //               this.activityData2[i].LeadDocStatus = "Revalidate";
  //             }
  //           }

  //           this.activityData = this.activityData2;
  //           this.totalApplications = this.activityData.length;
  //           this.completedApplications = this.activityData.filter(function (
  //             el
  //           ) {
  //             return el.LeadDocStatus == "validated";
  //           }).length;
  //           this.completedApplications2 = this.activityData.filter(function (
  //             el
  //           ) {
  //             return (
  //               el.LeadDocStatus == "completed" ||
  //               el.LeadDocStatus == "Completed"
  //             );
  //           }).length;
  //           this.incompleteapplications = this.activityData.filter(function (
  //             el
  //           ) {
  //             // return el.LeadDocStatus != "validated";
  //             return (
  //               el.LeadDocStatus == "pending" ||
  //               el.LeadDocStatus == "Admin-revalidate" ||
  //               el.LeadDocStatus == "revalidate" ||
  //               el.LeadDocStatus == "CRM-revalidate" ||
  //               el.LeadDocStatus == "Revalidate" ||
  //               el.LeadDocStatus == "reopen" ||
  //               el.LeadDocStatus == "denied"
  //             );
  //           }).length;
  //           var count = 0;

  //           this.documentspending = this.activityData.filter(function (el) {
  //             if (el.uploadlistofdocuments.length == 0) {
  //               count = count + 1;
  //             } else {
  //               var checkDock = true;
  //               for (let i = 0; i < el.uploadlistofdocuments.length; i++) {
  //                 if (el.uploadlistofdocuments[i].StudentDocPath == null) {
  //                   checkDock = false;
  //                 }
  //               }
  //               if (checkDock == false) {
  //                 count = count + 1;
  //               } else {
  //               }
  //             }
  //             return el.Phase == "pending";
  //           }).length;
  //           this.documentspending = count;
  //           this.documentsDenied = this.activityData.filter(function (el) {
  //             return (
  //               el.LeadDocStatus == "denied" || el.LeadDocStatus == "Denied"
  //             );
  //           }).length;
  //           this.CallsPending = this.activityData.filter(function (el) {
  //             return el.Phase == "pending" || el.Phase == "Pending";
  //           }).length;
  //           this.revalidate = this.activityData.filter(function (el) {
  //             return (
  //               el.LeadDocStatus == "revalidate" ||
  //               el.LeadDocStatus == "Revalidate" ||
  //               el.LeadDocStatus == "CRM-revalidate" ||
  //               el.LeadDocStatus == "GBP-revalidate"
  //             );
  //           }).length;
  //           this.unassigned = this.activityData.filter(function (el) {
  //             return el.Assignedto == "" || el.Assignedto == "-";
  //           }).length;

  //           this.assigned = this.activityData.length - this.unassigned;

  //           this.assignedtome = this.activityData.filter(function (el) {
  //             return el.Assignedto == userData.mail;
  //           }).length;
  //           this.unassigned = this.activityData.filter(function (el) {
  //             return el.Assignedto == "" || el.Assignedto == "-";
  //           }).length;

  //           this.assigned = this.activityData.length - this.unassigned;

  //           this.documentssubmitted =
  //             this.totalApplications - this.documentspending;

  //           this.callback = this.activityData.filter(function (el) {
  //             return el.Phase == "callback" || el.Phase == "Callback";
  //           }).length;

  //           var DocPendingList = [];
  //           var DocSubmittedList = [];

  //           if (filter == "all") {
  //             this.activityTable = this.activityData;
  //           } else if (filter == "validatedDocuments") {
  //             this.activityTable = this.activityData.filter(function (el) {
  //               return el.LeadDocStatus == "validated";
  //             });
  //           } else if (filter == "completedDocuments") {
  //             this.activityTable = this.activityData.filter(function (el) {
  //               return el.LeadDocStatus == "completed";
  //             });
  //           } else if (filter == "ValidationPending") {
  //             this.activityTable = this.activityData.filter(function (el) {
  //               return (
  //                 el.LeadDocStatus == "pending" ||
  //                 el.LeadDocStatus == "Admin-revalidate" ||
  //                 el.LeadDocStatus == "revalidate" ||
  //                 el.LeadDocStatus == "CRM-revalidate" ||
  //                 el.LeadDocStatus == "Revalidate" ||
  //                 el.LeadDocStatus == "reopen" ||
  //                 el.LeadDocStatus == "denied"
  //               );
  //             });
  //           } else if (filter == "completedApplications") {
  //             this.activityTable = this.activityData.filter(function (el) {
  //               return el.APPStatus == "complete" || el.APPStatus == "complete";
  //             });
  //           } else if (filter == "incompleteApplications") {
  //             this.activityTable = this.activityData.filter(function (el) {
  //               return el.APPStatus == "Incomplete";
  //             });
  //           } else if (filter == "documentsPending") {
  //             this.activityTable = this.activityData.filter(function (el) {
  //               if (el.uploadlistofdocuments.length == 0) {
  //                 DocPendingList.push(el);
  //               } else {
  //                 var checkDock = true;
  //                 for (let i = 0; i < el.uploadlistofdocuments.length; i++) {
  //                   if (el.uploadlistofdocuments[i].StudentDocPath == null) {
  //                     checkDock = false;
  //                   }
  //                 }
  //                 if (checkDock == false) {
  //                   DocPendingList.push(el);
  //                 }
  //               }
  //               return el.Phase == "pending";
  //             });
  //             this.activityTable = DocPendingList;
  //           } else if (filter == "documentsSubmitted") {
  //             this.activityTable = this.activityData.filter(function (el) {
  //               if (el.uploadlistofdocuments.length != 0) {
  //                 var docCheck = true;
  //                 for (let i = 0; i < el.uploadlistofdocuments.length; i++) {
  //                   if (el.uploadlistofdocuments[i].StudentDocPath == null) {
  //                     docCheck = false;
  //                   }
  //                 }
  //                 if (docCheck == true) {
  //                   DocSubmittedList.push(el);
  //                 }
  //               }
  //               return el.Phase == "complete" || el.APPStatus == "complete";
  //             });
  //             this.activityTable = DocSubmittedList;
  //           } else if (filter == "callback") {
  //             this.activityTable = this.activityData.filter(function (el) {
  //               return el.Phase == "callback" || el.Phase == "Callback";
  //             });
  //           } else if (filter == "denied") {
  //             this.activityTable = this.activityData.filter(function (el) {
  //               return (
  //                 el.LeadDocStatus == "Denied" || el.LeadDocStatus == "denied"
  //               );
  //             });
  //           } else if (filter == "pendingCalls") {
  //             this.activityTable = this.activityData.filter(function (el) {
  //               return el.Phase == "pending" || el.Phase == "Pending";
  //             });
  //           } else if (filter == "revalidate") {
  //             this.activityTable = this.activityData.filter(function (el) {
  //               return (
  //                 el.LeadDocStatus == "revalidate" ||
  //                 el.LeadDocStatus == "Revalidate" ||
  //                 el.LeadDocStatus == "CRM-revalidate" ||
  //                 el.LeadDocStatus == "GBP-revalidate"
  //               );
  //             });
  //           } else if (filter == "assigned") {
  //             this.activityTable = this.activityData.filter(function (el) {
  //               return el.Assignedto != "-";
  //             });
  //           } else if (filter == "assignedtome") {
  //             this.activityTable = this.activityData.filter(function (el) {
  //               return el.Assignedto == userData.mail;
  //             });
  //           } else if (filter == "unassigned") {
  //             this.activityTable = this.activityData.filter(function (el) {
  //               return el.Assignedto == "-" || el.Assignedto == "";
  //             });
  //           } else if (filter == "assigned") {
  //             this.activityTable = this.activityData.filter(function (el) {
  //               return el.Assignedto != "-";
  //             });
  //           } else if (filter == "unassigned") {
  //             this.activityTable = this.activityData.filter(function (el) {
  //               return el.Assignedto == "-" || el.Assignedto == "";
  //             });
  //           }

  //           this.isLoading = false;
  //           if (this.activityTable.length == 0) {
  //             this.noRecords = true;
  //           } else {
  //             this.noRecords = false;
  //           }
  //         },
  //         (error) => {
  //           this.handleError.handleError(error);
  //         }
  //       );
  //     },
  //     (error) => {
  //       this.handleError.handleError(error);
  //     }
  //   );
  //   this.getAllData(
  //     this.userData,
  //     "all",
  //     new Date(Date.now() - 864e5 * 365),
  //     new Date(Date.now())
  //   );
  // }

  getData(userData: any, leadtype, startDate, endDate) {
    this.leadIds = [];
    this.isLoading = true;
    this.noRecords = false;
    this.search = "";
    this.onClick(null);
    this.activityTable = [];

    this.leadsbody = {
      admin: this.admin,
      // admin: true,
      mail: userData.mail,
      ProjectId: this.projectId,
      leadType: leadtype,
      start: this.startDate,
      end: this.endDate,
    };

    this.uhleadsservice.getActivityTable2(this.leadsbody).subscribe(
      (res) => {
        this.activityTable2 = res;

        this.activityTable2.forEach(function (a) {
          Object.keys(a).map((key) => {
            a[key] === null ? (a[key] = "-") : a[key];
          });
        });

        for (let i = 0; i < this.activityTable2.length; i++) {
          if (this.activityTable2[i].PhoneNumber == "0000000000") {
            this.activityTable2[i].CountryCode = "-";
            this.activityTable2[i].PhoneNumber = "-";
          }
          this.activityTable2[i].AssignedtoName = this.getAgentName(
            this.activityTable2[i].Assignedto
          );

          this.activityTable2[i].AssignedtoInitial = this.getAgentInitial(
            this.activityTable2[i].Assignedto
          );
          this.activityTable2[i].AssignedtoColor = this.getAgentColor(
            this.activityTable2[i].Assignedto
          );
          this.activityTable2[i].AssignedtoImageURL = this.getAgentphoto(
            this.activityTable2[i].Assignedto
          );
          if (
            this.activityTable2[i].LeadDocStatus == "CRM-Revalidate" ||
            this.activityTable2[i].LeadDocStatus == "Admin-Revalidate" ||
            this.activityTable2[i].LeadDocStatus == "Admin-revalidate" ||
            this.activityTable2[i].LeadDocStatus == "CRM-revalidate"
          ) {
            this.activityTable2[i].LeadDocStatus = "Revalidate";
          }
        }

        this.activityTable = this.activityTable2;
        this.getAllData(
          this.userData,
          "all",
          new Date(Date.now() - 864e5 * 365),
          new Date(Date.now())
        );
        // this.getAgentName()
        this.isLoading = false;
        if (this.activityTable.length == 0) {
          this.noRecords = true;
        } else {
          this.noRecords = false;
        }
      },
      (error) => {
        this.isLoading = false;
        this.handleError.handleError(error);
      }
    );
  }

  getAllData(userData: any, leadtype, startDate, endDate) {
    this.leadsbody = {
      admin: this.admin,
      // admin: true,
      mail: userData.mail,
      ProjectId: this.projectId,
      accepted_app_status: leadtype,
      start: this.startDate,
      end: this.endDate,
      page: this.pageNumber,
    };

    this.cunextService.getCardAccepetedType(this.leadsbody).subscribe(
      (res: any) => {
        this.allRecords = res;
      },
      (error) => {
        this.allRecords = [];
        this.handleError.handleError(error);
        this.uhleadsservice.showError("Something went wrong!", "Error!");
      }
    );
  }

  onStatusChange(data) {
    this.newStatus = data;
    if (this.newStatus == "deferredintake") {
      this.showdeferredintake = true;
    } else {
      this.showdeferredintake = false;
      this.DIyear = null;
      this.DIterms = null;
    }
  }

  sideNavData(data) {
    this.activityfilterString = "";
    this.customDocName = "";
    this.minItems = 5;
    var x = document.getElementsByClassName(
      "sideDataViewer"
    ) as HTMLCollectionOf<HTMLElement>;
    //  x.style.display = none;
    for (let i = 0; i < x.length; i++) {
      x[i].style.display = "";
    }
    this.cunextService.getStudentActivity(data.id).subscribe(
      (res) => {
        this.sideViewerData = res;
        this.currentLeadId = this.sideViewerData.id;
        // this.replaceNull(this.sideViewerData);
      },
      (error) => {
        this.handleError.handleError(error);
      }
    );
  }

  replaceNull(a: any) {
    Object.keys(a).map((key) => {
      a[key] === null ? (a[key] = "-") : a[key];
    });
  }
  getAgentName(email) {
    let name = "";
    for (var k = 0; k < this.totalAgents.length; k++) {
      if (this.totalAgents[k].Mailid == email) {
        name =
          this.totalAgents[k].FirstName + " " + this.totalAgents[k].LastName;
        return name;
      }
    }
  }
  getAgentInitial(email) {
    let initial = "";
    for (var k = 0; k < this.totalAgents.length; k++) {
      if (this.totalAgents[k].Mailid == email) {
        initial =
          this.totalAgents[k].FirstName.charAt(0) +
          this.totalAgents[k].LastName.charAt(0);
        return initial;
      }
    }
  }

  getAgentColor(email) {
    let color = "";
    for (var k = 0; k < this.totalAgents.length; k++) {
      if (this.totalAgents[k].Mailid == email) {
        color =
          this.totalAgents[k].FirstName.charAt(0) +
          this.totalAgents[k].LastName.charAt(0);

        let randomIndex =
          this.colors[
          this.numberFromText(this.getAgentInitial(email)) %
          this.colors.length
          ];
        color = randomIndex[0]["bg"];
        return color;
      }
    }
  }
  getAgentphoto(email) {
    let imageUrl = "";
    for (var k = 0; k < this.totalAgents.length; k++) {
      if (this.totalAgents[k].Mailid == email) {
        imageUrl = this.totalAgents[k].ProfilePhoto;
        return imageUrl;
      }
    }
  }

  numberFromText(text) {
    const charCodes = text
      .split("") // => ["A", "A"]
      .map((char) => char.charCodeAt(0)) // => [65, 65]
      .join(""); // => "6565"
    return parseInt(charCodes, 10);
  }

  checkValidation() {
    if (this.documentsList2.length == 0) {
      this.validationStatus = false;
      return;
    }
    for (var i = 0; i < this.documentsList2.length; i++) {
      if (
        this.documentsList2[i].AgentValidation == "revalidate" ||
        this.documentsList2[i].AgentValidation == "-"
      ) {
        this.validationStatus = false;
        return;
      } else if (this.documentsList2[i].AgentValidation == "validated") {
        this.validationStatus = true;
        //AddNotification
      } else {
        this.validationStatus = false;
      }
    }
    if (this.validationStatus == true) {
      // Send Notification

      let payload = {
        AgentMail: "ramana@universityhub.com",
        GBPAssignedTo: "",
        GUID: "12356yyu98",
        LeadId: 0,
        Platform: "CRM",
        StudentDocId: 0,
        Title: "Documents Validated",
        description: "All the docuiments have been validated",
        notificationType: "alldocuments",
        token: this.userData.token,
      };

      let payload2 = {
        AgentMail: "ramana@universityhub.com",
        GBPAssignedTo: "",
        GUID: "12356yyu98",
        LeadId: 0,
        Platform: "CRMGBP",
        StudentDocId: 0,
        Title: "Documents Validated",
        description: "All the docuiments have been validated",
        notificationType: "alldocuments",
        token: this.userData.token,
      };

    }
  }

  showmore(itemsCount) {
    if (itemsCount < this.minItems) {
    } else if (this.minItems + 5 < itemsCount) {
      this.minItems = this.minItems + 5;
    } else if (this.minItems < itemsCount) {
      this.minItems = itemsCount;
    }
  }

  contentLoaded() {
    document.getElementById("progressBar").style.display = "none";
  }

  openEditLead(content, lead) {
    this.editStatus = lead.CallStatus;
    this.leadtoEdit = {
      id: lead.id,
      application_status: lead.application_status,
      accepted_app_status: lead.accepted_app_status,
      first_name: lead.first_name,
      last_name: lead.last_name,
      id_number: lead.id_number,
      student_email: lead.student_email,
      when_like_to_begin_studies: lead.when_like_to_begin_studies,
      what_program: lead.what_program,
      program_enddate_listed: moment(new Date(lead.program_enddate_listed)).format("YYYY-MM-DD"),
      address_line1: lead.address_line1,
      address_line2: lead.address_line2,
      city: lead.city,
      state: lead.state,
      country: lead.country,
      zipcode: lead.zipcode,
      reject_reason: lead.application_status == 'Cancelled' || lead.application_status == 'Reject' ? lead.reject_reason : ''
    };
    this.modalService.open(content, { size: "lg" });
  }

  replaceAll(string, search, replace) {
    return string.split(search).join(replace);
  }

  updateLead() {
    this.edited = true;
    if (this.cunextService.editLead.invalid) {
      return;
    }

    if ((this.leadtoEdit.application_status == 'Cancelled' || this.leadtoEdit.application_status == 'Reject')
      && (this.leadtoEdit.reject_reason == "" || this.leadtoEdit.reject_reason == null)) {
      this.rejectReason = true;
      return;
    } else {
      this.rejectReason = false;
    }

    this.leadtoEdit['AgentMail'] = this.userData.mail;

    var keys = Object.keys(this.leadtoEdit);
    keys.forEach((a) => {
      if (
        this.leadtoEdit[a] === "-" ||
        this.leadtoEdit[a] === "" ||
        this.leadtoEdit[a] === undefined
      ) {
        this.leadtoEdit[a] = null;
      }
    });

    this.cunextService.updateStudentInfo(this.leadtoEdit).subscribe(
      (res: any) => {
        this.uhleadsservice.showSuccess("Student details updated", "Success!");
        this.edited = false;

        document.getElementById("closeButton3").click();
        // this.getCounts(this.userData, "all", this.startDate, this.endDate, "all");
        this.getTopBarCuNext();
        this.getCardCount(
          {
            admin: this.admin,
            mail: this.userData.mail,
            ProjectId: this.projectId,
            accepted_app_status: this.cardLeadType,
            start: this.startDate,
            end: this.endDate,
            page: 1,
          },
          this.cardLeadType
        );
      },
      (error) => {
        this.handleError.handleError(error);
      }
    );
  }

  // viewLead(id) {
  //   this.sideNav.toggle();
  //   this.sideNavData({ id: id });
  // }

  onInputActivity(event: any) {
    if (this.activityfilterString != "") {
      this.minItems = event;
    } else {
      this.minItems = 5;
    }
  }
  getTopBarCuNext() {
    var postData = {
      ProjectId: this.projectId,
      start: this.startDate,
      end: this.endDate,
      AgentMail: this.userData.mail,
    };
    this.cunextService
      .getTopBarCuNext(postData)
      .toPromise()
      .then((resp: any) => {
        this.totalPages = 1;
        if (this.leadType == "all" || this.leadType == "total") {
          this.totalPages = resp.TopBarCount.Total;
        } else if (this.leadType == "Incomplete") {
          this.totalPages = resp.TopBarCount.Incomplete;
        } else if (this.leadType == "Submitted") {
          this.totalPages = resp.TopBarCount.Submitted;
        } else if (this.leadType == "In Review") {
          this.totalPages = resp.TopBarCount.InReview;
        } else if (this.leadType == "Graduation Hold") {
          this.totalPages = resp.TopBarCount.GraduationHold;
        } else if (this.leadType == "Grades Hold") {
          this.totalPages = resp.TopBarCount.GradesHold;
        } else if (this.leadType == "Accepted") {
          this.totalPages = resp.TopBarCount.Accepted;
        } else if (this.leadType == "Deferred") {
          this.totalPages = resp.TopBarCount.Deferred;
        } else if (this.leadType == "Reject") {
          this.totalPages = resp.TopBarCount.Reject;
        } else if (this.leadType == "COLD") {
          this.totalPages = resp.TopBarCount.Cold;
        } else if (this.leadType == "Cancelled") {
          this.totalPages = resp.TopBarCount.Cancelled;
        } else if (this.leadType == "Not Applicable") {
          this.totalPages = resp.TopBarCount.NotApplicable;
        } else if (this.leadType == "Accepted, hold for grades") {
          this.totalPages = resp.TopBarCount.AcceptedHoldGrades;
        } else if (this.leadType == "Accepted, graduation hold") {
          this.totalPages = resp.TopBarCount.AcceptedHoldGraduation;
        } else if (this.leadType == "Accepted early") {
          this.totalPages = resp.TopBarCount.AcceptedEarly;
        } else if (this.leadType == "AcceptedStatus") {
          this.totalPages = resp.TopBarCount.AcceptedStatus;
        } else if (this.leadType == "Accepted, MSCS Approval Pending") {
          this.totalPages = resp.TopBarCount.AcceptedMSCS;
        } else if (this.leadType == "Accepted, business office hold") {
          this.totalPages = resp.TopBarCount.AcceptedBusinessHold;
        }

        this.totalPages = ~~(Number(this.totalPages) / 12) + 1;
        this.uhleadsservice.totalPages.next(this.totalPages);
        this.topBarCounts = resp.TopBarCount;
      })
      .catch((err) => {
        this.handleError.handleError(err);
      });
  }

  sortData(arr, value) {
    return arr.sort((a, b) => {
      return a[value] - b[value];
      //return a[value] < b[value] ? -1 : a[value] > b[value] ? 1: 0
    });
  }
  toggleSort(column, order) {
    if (this.sortOrder == "asc") {
      this.sortOrder = "des";
    } else {
      this.sortOrder = "asc";
    }
  }

  getCardCount(obj, type) {
    this.cardLeadType = type;
    this.leadIds = [];
    this.activityTable = [];
    this.isLoading = true;
    this.noRecords = false;
    this.search = "";

    this.activityData2 = [];
    this.activityData = [];
    // this.sharedService.projectId.subscribe((data) => {
    this.companyService.projectAdmin(this.companyId).subscribe(
      (projects) => {
        obj.projectId = projects.filter(
          (x) => x.Name == "CuNext"
        )[0].id;
        // obj.ProjectId = data;
        obj.page = this.pageNumber;
        obj.accepted_app_status = this.leadType;
        this.cunextService
          .getCardAccepetedType(obj)
          .toPromise()
          .then((resp: any) => {
            this.activityData2 = resp;
            this.activityData2.forEach(function (a) {
              Object.keys(a).map((key) => {
                a[key] === null ? (a[key] = "-") : a[key];
              });
            }, (error) => {
              this.activityData2 = [];
              this.handleError.handleError(error);
            })
            this.activityData = this.activityData2;

            this.activityTable = this.activityData;
            this.isLoading = false;
            if (this.activityTable.length == 0) {
              this.noRecords = true;
            } else {
              this.noRecords = false;
            }
          })
          .catch((err) => {
            this.isLoading = false;
            this.handleError.handleError(err);
          });
      },
      (error) => {
        this.handleError.handleError(error);
      }
    );
  }

  getCardData(userData: any, leadtype, startDate, endDate) {
    this.leadIds = [];
    this.isLoading = true;
    this.noRecords = false;
    this.search = "";
    this.onClick(null);
    this.activityTable = [];
    let obj = {
      admin: this.admin,
      // admin: true,
      mail: userData.mail,
      ProjectId: this.projectId,
      accepted_app_status: leadtype,
      start: this.startDate,
      end: this.endDate,
      page: this.pageNumber,
    };
    this.cunextService
      .getCardAccepetedType(obj)
      .toPromise()
      .then((resp: any) => {
        this.activityData2 = resp;
        this.activityData2.forEach(function (a) {
          Object.keys(a).map((key) => {
            a[key] === null ? (a[key] = "-") : a[key];
          });
        }, (error) => {
          this.activityData2 = [];
          this.handleError.handleError(error);
        });
        // this.getAllData(
        //   this.userData,
        //   "total",
        //   new Date(Date.now() - 864e5 * 365),
        //   new Date(Date.now())
        // );
        this.activityData = this.activityData2;

        this.activityTable = this.activityData;
        this.isLoading = false;
        if (this.activityTable.length == 0) {
          this.noRecords = true;
        } else {
          this.noRecords = false;
        }
      })
      .catch((err) => {
        this.isLoading = false;
        this.handleError.handleError(err);
      });
  }

  dropdownSelect(event) {
    this.onClick(null);
    this.leadType = event;
    this.getTopBarCuNext()
    this.getCardData(this.userData, event, this.startDate, this.endDate);
  }

  selectAllforAssignment() {
    if (this.selectedAll == false) {
      this.selectedAll = true;
      this.leadIds = [];
      for (let i = 0; i < this.activityTable.length; i++) {
        this.leadIds.push(this.activityTable[i].id);
      }
    } else if (this.selectedAll == true) {
      this.selectedAll = false;
      this.leadIds = [];
    }
  }
  selectAgentforAssignment(e, item) {
    if (e.target.checked) {
      this.leadIds.push(item.id);
    } else if (!e.target.checked) {
      this.removeItem(this.leadIds, item.id);
    }
  }

}
