<button class="p-btn" id='firstPage' (click)="pagination.first()" [disabled]="pagination.isFirst">
  <img src="../../../assets/img/left-end-w.svg" class="img12">
</button>
<button class="p-btn" (click)="pagination.prev()" [disabled]="pagination.isFirst">
  <img src="../../../assets/img/left-arrow-w.svg" class="img12">
</button>
<button class="p-btn-label" appPaginationCustom #pagination="appPaginationCustom" [totalPages]=totalPages
  (pageChange)="onPageChange($event)">Page {{ pagination.pageNo }}</button>
<span> / {{ pagination.totalPages }}</span>

<button class="p-btn" (click)="pagination.next()" [disabled]="pagination.isLast">
  <img src="../../../assets/img/right-arrow-w.svg" class="img12">
</button>
<button class="p-btn" (click)="pagination.last()" [disabled]="pagination.isLast">
  <img src="../../../assets/img/right-end-w.svg" class="img12">
</button>
<!-- <p>pageNo: {{ pagination.pageNo }}</p> -->