import {
  Component,
  OnInit,
  Inject,
  ViewChild,
  TemplateRef,
  ViewContainerRef,
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { LeadsDashboardService } from "./leads-dashboard.service";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  NgForm,
  Validators,
} from "@angular/forms";

import {
  CardsSetting,
  ColumnSetting,
  FormSetting,
  ModalConfig,
  RowSetting,
} from "src/components/customs/layout.model";
import {
  NgbModal,
  NgbModalOptions,
  NgbModalRef,
} from "@ng-bootstrap/ng-bootstrap";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ViewEncapsulation } from "@angular/core";
import { toLower } from "lodash";
import { TemplatePortal } from "@angular/cdk/portal";
import { JsonFormData } from "../../../app/pages/components/json-form/json-form.component";
import {
  countUpTimerConfigModel,
  CountupTimerService,
  timerTexts,
} from "ngx-timer";
import { MomentFormatService } from "src/app/moment-format-service";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import { UhLeadsService } from "../uhleads.service";
import { CreateCompanyService } from "../create-company/create-company.service";
import { ErrorHandlerService } from "src/app/error-handler.service";
import * as moment from "moment";
import { InputSMSData, MarketingService } from "../marketing/marketing.service";
import { environment } from "src/environments/environment";
import { Dialogs1Component } from "src/app/shared/dialogs/dialogs1/dialogs1.component";
interface Project {
  value: string;
  viewValue: string;
}

@Component({
  selector: "app-leads-dashboard",
  templateUrl: "./leads-dashboard.component.html",
  styleUrls: ["./leads-dashboard.component.css"],
})
export class LeadsDashboardComponent implements OnInit {
  userData: any;
  projectId: any;
  companyId: any;

  projects: Project[] = [
    { value: "project1", viewValue: "AccessQue" },
    { value: "project2", viewValue: "UniversityHub" },
    { value: "project2", viewValue: "Rorotel" },
  ];

  cardsList: any[] = [];
  cardsSettings: CardsSetting[] = [];

  columnList: any[] = [];
  columnSettings: ColumnSetting[] = [];
  feedbackList: any[] = [];
  encapsulation: ViewEncapsulation.None;
  @ViewChild("leadEditModal", { read: TemplateRef })
  leadEditModal: TemplateRef<any>;
  @ViewChild("leadEditButton", { static: true }) editLead;

  currentLeadId: any;
  editFormData: any = {
    controls: [
      {
        name: "firstName",
        label: "First name",
        value: "",
        type: "text",
        validators: {
          required: true,
          minLength: 10,
        },
      },
      {
        name: "lastName",
        label: "Last name",
        value: "",
        type: "text",
        validators: {},
      },
      {
        name: "comments",
        label: "Comments",
        value: "",
        type: "textarea",
        validators: {},
      },
      {
        name: "agreeTerms",
        label: "Do you agree?",
        value: "false",
        type: "checkbox",
        validators: {},
      },
      {
        name: "lightDark",
        label: "Toggle dark mode",
        value: "false",
        type: "toggle",
        validators: {},
      },
      {
        name: "size",
        label: "Size",
        value: "",
        type: "range",
        options: {
          min: "0",
          max: "100",
          step: "1",
        },
        validators: {},
      },
    ],
  };
  agentList: any[];
  commentText: any;
  newStatus: any;
  documentsList: any[];
  config: any;
  dialerScreen1 = "dialer";
  agentCall: String = "";
  callStatus: string = "Calling..";
  testConfig: any;
  error: boolean = false;

  public filterValue = "all";
  showDate: boolean = false;
  startDate: any;
  endDate: any;
  date1: any;
  date2: any;

  public marketingId: {
    id: string;
    status: string;
    phoneNumber: string;
    email: string;
    name: string;
    module: string;
    city: string;
    nextCaller: any;
    lead: any;
  } = {
      id: "",
      status: "",
      name: "",
      email: "",
      phoneNumber: "",
      module: "",
      city: "",
      nextCaller: null,
      lead: {},
    };
  leadToCall: any;
  public stopCall: boolean = false;
  isEdit: string;
  channelList: any = [];
  formList: any = ['form1', 'form2', 'form3']
  isAdmin: Boolean = false
  counsellingDate: any;;
  twilioNumber: any;

  constructor(
    private route: ActivatedRoute,
    private LeadsDashboardService: LeadsDashboardService,
    public dialog: MatDialog,
    private modalService: NgbModal,
    private vref: ViewContainerRef,
    public countupTimerService: CountupTimerService,
    public momentService: MomentFormatService,
    private uhleadsservice: UhLeadsService,
    private CreateCompanyService: CreateCompanyService,
    private handleError: ErrorHandlerService,
    public marketingService: MarketingService,
  ) {
    this.testConfig = new countUpTimerConfigModel();
    this.testConfig.timerClass = "test_Timer_class";
    this.testConfig.timerTexts = new timerTexts();
    this.testConfig.timerTexts.hourText = ":"; //default - hh
    this.testConfig.timerTexts.minuteText = ":"; //default - mm
    this.testConfig.timerTexts.secondsText = " ";
  }

  ngOnInit(): void {

    this.userData = JSON.parse(localStorage.getItem("userData"));
    this.isAdmin = this.userData.SuperAdmin
    this.projectId = this.route.snapshot.paramMap.get("projectId");

    this.date1 = new FormControl(new Date());
    this.date2 = new FormControl(new Date(Date.now() - 864e5));
    this.filterValue == "24hrs";
    var today = new Date();
    this.endDate = new Date(Date.now());
    this.startDate = new Date(Date.now() - 864e5 * 365);

    this.startDate = this.momentService.dashboardDateFormat(
      new Date(new Date(Date.now() - 864e5 * 365)).getTime()
    );
    this.endDate = this.momentService.dashboardDateFormat(
      new Date(new Date(Date.now() + 864e5))
    );

    this.LeadsDashboardService.dateChange.next({ start: this.startDate, end: this.endDate });

    console.log("ProjectId: ", this.projectId);
    this.getProjectConfig(this.projectId);


    this.LeadsDashboardService.lead.subscribe((lead: any) => {
      this.modalService.dismissAll()
      if (lead && lead.id == 'new') {
        console.log('add lead')
        this.editFormData.controls = [];

        for (let i = 0; i < this.columnList.length; i++) {
          this.editFormData.controls.push({
            name: this.columnList[i].name,
            label: this.columnList[i].displayName,
            value: '',
            type: this.columnList[i].type,
          });
          if (
            this.editFormData.controls[i].name == toLower("CallStatus") ||
            this.editFormData.controls[i].name == "CallStatus"
          ) {
            this.editFormData.controls[i].type = "select";
            this.editFormData.controls[i].value = toLower(
              this.editFormData.controls[i].value
            );
          } else if (
            this.editFormData.controls[i].name == toLower("AssignedTo") ||
            this.editFormData.controls[i].name == "AssignedTo"
          ) {
            this.editFormData.controls[i].type = "select";
            this.editFormData.controls[i].value = toLower(
              this.editFormData.controls[i].value
            );
          } else if (
            this.editFormData.controls[i].name == toLower("Channel") ||
            this.editFormData.controls[i].name == "Channel"
          ) {
            this.editFormData.controls[i].type = "select";
            this.editFormData.controls[i].value = toLower(
              this.editFormData.controls[i].value
            );
          } else if (
            this.editFormData.controls[i].name == toLower("FormName") ||
            this.editFormData.controls[i].name == "FormName"
          ) {
            this.editFormData.controls[i].type = "select";
            this.editFormData.controls[i].value = toLower(
              this.editFormData.controls[i].value
            );
          }
        }
        document.getElementById("addLeadButton").click();
      }

      if (lead && lead.id != 'new') {
        console.log("Lead to Edit: ", lead);
        this.currentLeadId = lead.id
        this.editFormData.controls = [];

        for (let i = 0; i < this.columnList.length; i++) {
          this.editFormData.controls.push({
            name: this.columnList[i].name,
            label: this.columnList[i].displayName,
            value: lead[toLower(this.columnList[i].name)],
            type: this.columnList[i].type,
          });
          if (
            this.editFormData.controls[i].name == toLower("CallStatus") ||
            this.editFormData.controls[i].name == "CallStatus"
          ) {
            this.editFormData.controls[i].type = "select";
            this.editFormData.controls[i].value = toLower(
              this.editFormData.controls[i].value
            );
          } else if (
            this.editFormData.controls[i].name == toLower("AssignedTo") ||
            this.editFormData.controls[i].name == "AssignedTo"
          ) {
            this.editFormData.controls[i].type = "select";
            this.editFormData.controls[i].value = toLower(
              this.editFormData.controls[i].value
            );
          } else if (
            this.editFormData.controls[i].name == toLower("Channel") ||
            this.editFormData.controls[i].name == "Channel"
          ) {
            this.editFormData.controls[i].type = "select";
            this.editFormData.controls[i].value = toLower(
              this.editFormData.controls[i].value
            );
          } else if (
            this.editFormData.controls[i].name == toLower("FormName") ||
            this.editFormData.controls[i].name == "FormName"
          ) {
            this.editFormData.controls[i].type = "select";
            this.editFormData.controls[i].value = toLower(
              this.editFormData.controls[i].value
            );
          }
        }
        console.log("Form Data: ", this.editFormData.controls);
        document.getElementById("leadEditButton").click();
      }
    });

    this.LeadsDashboardService.callLead.subscribe((lead: any) => {

      this.dialerScreen1 = "outgoing1";
      if (lead) {
        this.modalService.dismissAll()
        this.leadToCall = lead;
        console.log("Lead to call: ", lead);
        console.log("config: ", this.config);

        this.marketingId = {
          id: lead.id,
          status: lead.callstatus,
          name: lead.firstname + " " + lead.lastname,
          email: lead.email,
          phoneNumber: "+" + lead.countrycode + lead.phone,
          module: "",
          city: "",
          nextCaller: null,
          lead: {},
        };

        this.LeadsDashboardService.startCall.next({
          phoneNumber: this.marketingId.phoneNumber,
          userData: this.userData,
          leadData: this.marketingId,
          projectId: this.projectId,
          companyId: this.companyId ? this.companyId : this.userData.NewCompanyId
        });

        document.getElementById("callLeadButton").click();
      }
    });
  }

  startTimer() {
    let cdate = new Date();
    cdate.setHours(cdate.getHours());
    this.countupTimerService.startTimer(cdate);
  }

  editLeadData() {
    console.log(this.editFormData);
    this.error = false;

    for (let i = 0; i < this.editFormData.controls.length; i++) {
      console.log(this.editFormData.controls[i]);
      if (this.editFormData.controls[i].name == 'firstname' || this.editFormData.controls[i].name == 'lastname' || this.editFormData.controls[i].name == 'email' || this.editFormData.controls[i].name == 'countrycode' || this.editFormData.controls[i].name == 'phone') {
        if (this.editFormData.controls[i].value == '') {
          this.uhleadsservice.showError("Please fill all fields", "Missing Info");
          this.error = true;
          return
        }
      }

      if (this.editFormData.controls[i].name == 'email') {
        if (this.validateEmail(this.editFormData.controls[i].value) == false) {
          this.uhleadsservice.showError("Please Enter a valid email", "Invalid Email");
          this.error = true
        }
      }
    }
    if (!this.error) {
      this.LeadsDashboardService.updateLead({ id: this.currentLeadId, projectId: this.projectId, companyId: this.companyId, leadData: this.editFormData, mail: this.userData.mail }).subscribe((res: any) => {
        if (res.status == 200) {

          this.uhleadsservice.showSuccess(res.message, "Success");
          this.editFormData.controls.map(x => {
            if (x.name == "callstatus") {
              console.log(x.value == "qualifiedhotlead")
              if (x.value == "qualifiedhotlead") {
                this.scheduleDemo();
              }
            }
          })
          this.LeadsDashboardService.config.next('refreshLeads');
          this.modalService.dismissAll();
          document.getElementById("closeEditButton").click();
        }
      })
    }
  }

  addLeadData() {
    console.log(this.editFormData);
    this.error = false;

    for (let i = 0; i < this.editFormData.controls.length; i++) {
      console.log(this.editFormData.controls[i]);
      if (this.editFormData.controls[i].name == 'firstname' || this.editFormData.controls[i].name == 'lastname' || this.editFormData.controls[i].name == 'email' || this.editFormData.controls[i].name == 'countrycode' || this.editFormData.controls[i].name == 'phone') {
        if (this.editFormData.controls[i].value == '') {
          const config: MatDialogConfig = {
            panelClass: 'dialog-responsive',
            data: {
              imgSrc: '../../../assets/images/dialog_info.png',
              title: 'Missing Info',
              header: 'Add Leads',
              message: 'Please fill required fields',
              timeout: 3000
            }
          };
          this.dialog.closeAll();
          this.dialog.open(Dialogs1Component, config);
          // this.uhleadsservice.showError("Please fill all fields", "Missing Info");
          this.error = true;
          return
        }
      }
      if (this.editFormData.controls[i].name == 'email') {
        if (this.validateEmail(this.editFormData.controls[i].value) == false) {
          this.uhleadsservice.showError("Please Enter a valid email", "Invalid Email");
          this.error = true
        }
      }

    }

    let payload = {
      projectId: this.projectId,
      companyId: this.companyId,
      leadData: this.editFormData,
      mail: this.userData.mail
    }
    if (!this.error) {
      this.LeadsDashboardService.addLead(payload).subscribe((res: any) => {
        console.log('User added: ', res);
        this.LeadsDashboardService.config.next('refreshLeads');
        this.modalService.dismissAll();
        const config: MatDialogConfig = {
          panelClass: 'dialog-responsive',
          data: {
            imgSrc: '../../../assets/images/success_info.png',
            title: 'Success!',
            header: 'Add Leads',
            message: 'Lead Added Successfully',
            timeout: 3000
          }
        };
        this.dialog.closeAll();
        this.dialog.open(Dialogs1Component, config);
        document.getElementById("closeAddButton").click();
      })
    }

  }

  validateEmail(email) {
    var emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailPattern.test(email);
  }

  //Shows date if the filter value is custom
  hideDate() {
    // this.filterValuev
    if (this.filterValue == "custom" && this.showDate == false) {
      this.showDate = true;
    }
  }

  getAgents(projectId, companyId) {
    this.LeadsDashboardService.getAgents({ companyId: companyId, projectId: projectId }).subscribe((res: any) => {
      this.agentList = res.agents;
      console.log("Agents: ", this.agentList)
    })
  }

  updateDateFilter(event: any) {
    if (event.target.value != "custom") {
      this.endDate = this.momentService.dashboardDateFormat(
        new Date(new Date(Date.now() + 864e5 * 1)).getTime()
      );
    }
    if (event.target.value == "custom") {
      //show custom date filter
      this.showDate = true;
    } else if (event.target.value == "all") {
      this.startDate = this.momentService.dashboardDateFormat(
        new Date(new Date(Date.now() - 864e5 * 365)).getTime()
      );

      this.showDate = false;
    } else if (event.target.value == "24hrs") {
      this.startDate = this.momentService.dashboardDateFormat(
        new Date(new Date(Date.now() - 864e5)).getTime()
      );

      this.showDate = false;
    } else if (event.target.value == "72hrs") {
      this.startDate = this.momentService.dashboardDateFormat(
        new Date(new Date(Date.now() - 864e5 * 3)).getTime()
      );

      this.showDate = false;
    } else if (event.target.value == "7days") {
      this.startDate = this.momentService.dashboardDateFormat(
        new Date(new Date(Date.now() - 864e5 * 7)).getTime()
      );

      this.showDate = false;
    } else if (event.target.value == "14days") {
      this.startDate = this.momentService.dashboardDateFormat(
        new Date(new Date(Date.now() - 864e5 * 14)).getTime()
      );

      this.showDate = false;
    } else if (event.target.value == "30days") {
      this.startDate = this.momentService.dashboardDateFormat(
        new Date(new Date(Date.now() - 864e5 * 30)).getTime()
      );

      this.showDate = false;
    }

    console.log('StartDate: ', this.startDate)
    console.log('EndDate: ', this.endDate)
    if (event.target.value != "custom") {
      this.LeadsDashboardService.dateChange.next({ start: this.startDate, end: this.endDate });
    }


    // this.getCounts(this.userData, "all", this.startDate, this.endDate, "all");
    // this.getTopBarCRMNew();
    // this.getCardCount(
    //   {
    //     admin: this.admin,
    //     mail: this.userData.mail,
    //     ProjectId: this.projectId,
    //     leadType: this.cardLeadType,
    //     start: this.startDate,
    //     end: this.endDate,
    //   },
    //   this.cardLeadType
    // );

    // this.getData(this.userData, "all", this.startDate, this.endDate);
    // this.getTopBarCRMNew();
    // this.getCardData(this.userData, "total");
  }

  getStartDate(type: string, event: MatDatepickerInputEvent<Date>) {
    this.startDate = event.value;
    this.startDate = this.momentService.dashboardDateFormat(
      new Date(this.startDate).getTime
    );
  }
  getEndDate(type: string, event: MatDatepickerInputEvent<Date>) {
    this.endDate = event.value;
    this.endDate = this.momentService.dashboardDateFormat(
      new Date(this.endDate).getTime
    );
  }

  filterData() {
    // this.onClick(null);
    this.startDate = this.momentService.dashboardDateFormat(
      new Date(this.date2.value).getTime()
    );
    this.endDate = this.momentService.dashboardDateFormat(
      new Date(this.date1.value).getTime()
    );

    if (this.endDate < this.startDate) {
      // window.alert("Choose the right date format!");
      this.uhleadsservice.showWarning(
        "End date cannot be less than Start date!",
        "Wrong Date!"
      );
      return;
    } else {
      this.LeadsDashboardService.dateChange.next({ start: this.startDate, end: this.endDate });

      // this.getTopBarCRMNew();
      // this.getCardData(this.userData, "total");
      // this.getTopBarCRMNew();
      // this.getCardCount(
      //   {
      //     admin: this.admin,
      //     mail: this.userData.mail,
      //     ProjectId: this.projectId,
      //     leadType: this.cardLeadType,
      //     start: this.startDate,
      //     end: this.endDate,
      //   },
      //   this.cardLeadType
      // );
      this.showDate = false;
    }
  }


  getProjectConfig(id) {
    this.LeadsDashboardService.getConfig({ projectId: id }).subscribe(
      (res: any) => {
        console.log("Config Data: ", res);
        this.companyId = res.ProjectInfo[0].companyId;
        this.config = res.ProjectInfo[0].config[0];
        this.cardsList = res.ProjectInfo[0].config[0].cards;
        this.columnList = res.ProjectInfo[0].config[0].columns;
        this.feedbackList = res.ProjectInfo[0].config[0].feedback;
        this.LeadsDashboardService.config.next(res.ProjectInfo[0].config[0]);
        this.LeadsDashboardService.projectId.next(this.projectId);
        this.getAgents(this.projectId, this.companyId);
        for (let i = 0; i < this.columnList.length; i++) {
          //change column name to lower case for the table column names
          this.columnList[i].name = toLower(this.columnList[i].name);

          if (this.columnList[i].type == "Date") {
            this.columnList[i].pipe = "date: 'mediumDate'";
          }
        }

        for (let i = 0; i < this.cardsList.length; i++) {
          if (this.cardsList[i].type == 'channel') {
            this.channelList.push(this.cardsList[i]);
          }
        }
        console.log('ChannelList: ', this.channelList)
        console.log("Cards: ", this.cardsList);
        console.log("Columns: ", this.columnList);
        console.log("Feedback: ", this.feedbackList);
      }
    );
    this.CreateCompanyService.getcompanyById(this.userData.NewCompanyId).subscribe((Data: any) => {
      this.twilioNumber = Data.data.voipNumber;
    });
  }

  editfeedbackCards() {
    this.isEdit = "cards";
    this.LeadsDashboardService.isEdit.next(this.isEdit);
    const dialogRef = this.dialog.open(EditConfigDialog, {
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
    });
  }

  editfeedbackCalls() {
    this.isEdit = "calls";
    this.LeadsDashboardService.isEdit.next(this.isEdit);

    const dialogRef = this.dialog.open(EditConfigDialog, {
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
    });
  }

  editColumnsDashboard() {
    this.isEdit = "dashboard";
    this.LeadsDashboardService.isEdit.next(this.isEdit);
    const dialogRef = this.dialog.open(EditColumnsDialog, {
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
    });
  }

  editColumnsOverview() {
    this.isEdit = "overview";
    this.LeadsDashboardService.isEdit.next(this.isEdit);
    const dialogRef = this.dialog.open(EditColumnsDialog, {
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
    });
  }

  async openCardsModal(cardsChangemodal) {
    // return await this.cardsChangemodallComponent.open();
    this.modalService
      .open(cardsChangemodal, {
        ariaLabelledBy: "modal-basic-title",
        windowClass: "myCustomModalClass",
      })
      .result.then(
        (result) => { },
        (reason) => { }
      );
  }

  openTablesModal(cardsChangemodal) {
    // return await this.cardsChangemodallComponent.open();
    this.modalService
      .open(cardsChangemodal, {
        ariaLabelledBy: "modal-basic-title",
        windowClass: "myCustomModalClass",
      })
      .result.then(
        (result) => { },
        (reason) => { }
      );
  }

  openEditLeadModal(editLeadModal) {
    this.modalService
      .open(editLeadModal, {
        backdrop: "static",
        ariaLabelledBy: "modal-basic-title",
        windowClass: "addlead-modal-width",
      })
      .result.then(
        (result) => { },
        (reason) => { }
      );
    // this.vref.createEmbeddedView(this.leadEditModal);
  }
  openCallLeadModal(callLeadModal) {
    let ngbModalOptions: NgbModalOptions = {
      backdrop: "static",
      keyboard: false,
    };
    this.modalService
      .open(callLeadModal, {
        backdrop: "static",
        ariaLabelledBy: "modal-basic-title",
        windowClass: "myCustomModalClass",
      })
      .result.then(
        (result) => { },
        (reason) => { }
      );
    // this.vref.createEmbeddedView(this.leadEditModal);
  }

  openEdit() {

  }

  closeEdit() {
    this.vref.remove();
  }

  async removeCard(value, view) {
    if (view === false) {
      this.cardsList.map((x) => {
        if (x.primaryKey === value) {
          x.view = false;
        }
      });
      for (var i = 0; i < this.cardsSettings.length; i++) {
        var obj = this.cardsSettings[i];

        if (obj.primaryKey === value) {
          this.cardsSettings.splice(i, 1);
        }
      }
    } else {
      await this.cardsList.map((x) => {
        if (x.primaryKey === value) {
          x.view = true;
          this.cardsSettings.push(x);
          this.cardsSettings.sort((a, b) => a.header.localeCompare(b.header));
        }
      });
    }
  }

  async removeTable(value, view) {
    if (view === false) {
      await this.columnSettings.map((x) => {
        if (x.primaryKey === value) {
          x.view = false;
        }
      });
      for (var i = 0; i < this.columnSettings.length; i++) {
        var obj = this.columnSettings[i];

        if (obj.primaryKey === value) {
          this.columnSettings.splice(i, 1);
        }
      }
    } else {
      await this.columnSettings.map((x) => {
        if (x.primaryKey === value) {
          x.view = true;
        }
        this.sortReusbaleTable(this.columnSettings);
      });
    }
  }

  async sortReusbaleTable(data) {
    var test1 = data.filter((x) => {
      if (x.view) {
        return x;
      }
    });
    console.log(test1);
    this.columnSettings = [
      ...test1.filter((x) => x.header !== "Action"),
      ...test1.filter((x) => x.header === "Action"),
    ];
  }
  updateTables() {
    console.log(this.columnSettings);
  }

  stopCurrentCall() {

    this.LeadsDashboardService.callLead.next(null)
    this.LeadsDashboardService.stopCall.next({
      phoneNumber: this.marketingId.phoneNumber,
    });
    this.CreateCompanyService.stopCall.next({
      phoneNumber: this.marketingId.phoneNumber,
      userData: this.userData,
      leadData: this.marketingId,
    });
    this.callStatus = "Call Ended";
  }

  resetCallingScreen() {
    this.callStatus = "";
    this.marketingId = {
      id: "",
      status: "",
      name: "",
      email: "",
      phoneNumber: "",
      module: "",
      city: "",
      nextCaller: null,
      lead: {},
    };
  }

  updateComments() {
    if (!this.newStatus) {
    } else {
      this.LeadsDashboardService.getCallSid.subscribe((data) => {
        this.LeadsDashboardService
          .updateCallComments({
            id: this.marketingId.id,
            comments: this.commentText,
            callStatus: this.newStatus,
            projectId: this.projectId,
            companyId: this.companyId,
            CallSid: data,
          })
          .subscribe((res) => {

            this.modalService.dismissAll()
            let payload = {
              id: this.marketingId.id,
              projectId: this.projectId,
              companyId: this.companyId,
            }
            this.LeadsDashboardService.updateLeadDetails.next(payload)
            // // this.getData(this.userData, "all", this.startDate, this.endDate);
            // this.getTopBarCRMNew();
            // this.getCardData(
            //   this.userData,
            //   "total"
            // );
          }, (error) => {
            this.handleError.handleError(error)
          });
        this.commentText = "";
        // this.closeButton2.nativeElement.click();
      }, (error) => {
        this.handleError.handleError(error)
      });
    }
    // this.closeButton2.nativeElement.click();
  }

  onStatusChange(data) {
    this.newStatus = data;
    // if (this.newStatus == 'deferredintake') {
    //   this.showdeferredintake = true;
    // }
    // else {
    //   this.showdeferredintake = false
    //   this.DIyear = null
    //   this.DIterms = null

    // }
  }

  replaceNull(a: any) {
    Object.keys(a).map((key) => {
      a[key] === null ? (a[key] = "-") : a[key];
    });
  }
  ngOnDestroy(): void {
    this.LeadsDashboardService.lead.next(null);
    this.LeadsDashboardService.updateLeadDetails.next(null);
    // this.LeadsDashboardService.config.unsubscribe();
  }

  scheduleDemo() {
    console.log(this.editFormData);
    let dt = moment(this.counsellingDate).format("dddd D MMM YY") + " at " + moment(this.counsellingDate).format("h:m a");
    let num = Math.floor(Math.random() * 90000) + 10000;
    let room = "live-" + num.toString();
    let smsId = null;
    let callId = null;
    let mailId = null;
    let demoScheduled = `${environment.BaseURL}videocalling/mainscreen/V2/${this.userData.NewCompanyId}/${room}`;

    let obj1: any = {};
    obj1.ProjectId = this.userData.NewCompanyId;
    obj1.AutoEmailId = mailId ? mailId["mailId"] : null;
    obj1.AutoSMSId = smsId ? smsId["smsId"] : null;
    obj1.Comments = null;
    obj1.DateTime = moment(this.counsellingDate, "YYYY-MM-DDTHH:mm:SSZ").format("YYYY-MM-DDTHH:mm:SSZ");
    obj1.Presenter = this.userData.mail;
    obj1.Feedback = 'qualifiedhotlead';
    obj1.Url = demoScheduled + "/" + this.userData.workerFriendlyName;
    obj1.RoomName = room,
      obj1.LeadId = this.currentLeadId;
    obj1.agentEmail = this.userData.mail;

    this.editFormData.controls.map(x => {
      if (x.name == "firstname") {
        obj1.Name = x.value;
        obj1.ClientURL = demoScheduled + "/" + x.value;
      }
    });
    this.marketingService
      .createDemo(obj1)
      .subscribe((demo) => {
        let obj = {
          userURL: "",
          agentURL: "",
          userMail: "",
          agentMail: "",
          from: "",
          projectId: "",
          student_name: "",
          agent_name: "",
          tosms: "",
          demoDate: ""
        }
        let mobileNumber = '';
        this.editFormData.controls.map(x => {
          console.log("🚀 ~ file: leads-dashboard.component.ts:953 ~ LeadsDashboardComponent ~ .subscribe ~ x", x);
          if (x.name == "firstname") {
            obj.student_name = x.value;
            obj.userURL = demoScheduled + "/" + x.value;
          }
          if (x.name == "email") {
            obj.userMail = x.value
          }
          if (x.name == "countrycode") {
            mobileNumber = "+" + mobileNumber + x.value.replace("+", "");
          }
          if (x.name == "phone") {
            mobileNumber = mobileNumber + x.value
          }
        });
        obj.from = "+" + this.twilioNumber.replace("+", "");
        obj.agentMail = obj1.agentEmail;
        obj.agentURL = obj1.Url;
        obj.projectId = this.userData.NewCompanyId;
        obj.agent_name = this.userData.firstName;
        obj.demoDate = dt;
        obj.tosms = mobileNumber;
        console.log("🚀 ~ file: leads-dashboard.component.ts:976 ~ LeadsDashboardComponent ~ .subscribe ~ obj", obj);

        this.LeadsDashboardService.videoCallMailSms(obj).subscribe(x => {

        });
      });
  }
}

//Edit Feedback Options Component
@Component({
  selector: "edit-feedback-config",
  templateUrl: "editFeedbackModel.html",
  styleUrls: ["./leads-dashboard.component.css"],
})
export class EditConfigDialog {
  cardsList: any[] = [];
  cardsSettings: CardsSetting[] = [];
  config: any;
  projectId: any;
  newFeedback: any;
  AddFeedback: any;
  showNewFeedback: any;
  showNewFeedback2: any;

  feedbackModel: NgbModalRef;
  feedbackForm: FormGroup;

  isError: boolean = false;
  errorMessage: string;
  errorMessage2: string = "Name is required";
  isEdit: any;

  constructor(
    public dialog: MatDialog,
    private modalService: NgbModal,
    public LeadsDashboardService: LeadsDashboardService,
    private route: ActivatedRoute,
    private fb: FormBuilder,
  ) { }

  ngOnInit() {
    this.LeadsDashboardService.projectId.subscribe((projectId: any) => {
      this.projectId = projectId;
      this.LeadsDashboardService.config.subscribe((config: any) => {
        console.log("Dialog Config: ", config);
        this.config = config;
        this.LeadsDashboardService.isEdit.subscribe((isEdit: any) => {
          this.isEdit = isEdit;
        });
      });
    });
  }

  openDialog() {
    const dialogRef = this.dialog.open(EditConfigDialog, {
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
    });
  }

  updateCards() {
    this.LeadsDashboardService.updateCards({
      cards: this.config.feedback,
      projectId: this.projectId,
    }).subscribe((res: any) => {
      this.LeadsDashboardService.getConfig({
        projectId: this.projectId,
      }).subscribe((res: any) => {
        // this.config = res.ProjectInfo[0].config
        this.LeadsDashboardService.refresh.next(true);
        this.closeDialog();
      });
    });
  }
  closeDialog() {
    this.dialog.closeAll();
  }

  addFeedback(feedback, index) { }
  change() {
    console.log("Value: ", this.newFeedback);
  }
  submitFeedback() {
    if (this.showNewFeedback == "false") {
      this.showNewFeedback = false;
    } else {
      this.showNewFeedback = true;
    }
    this.config.feedback.push({
      name: this.newFeedback,
      value: toLower(this.newFeedback),
      selected: this.showNewFeedback,
      callingScreen: true,
      cards: true,
      color: "primary",
    });
    this.openDialog();
  }
  async removeCard(value, view) {
    if (view === false) {
      this.cardsList.map((x) => {
        if (x.primaryKey === value) {
          x.view = false;
        }
      });
      for (var i = 0; i < this.cardsSettings.length; i++) {
        var obj = this.cardsSettings[i];

        if (obj.primaryKey === value) {
          this.cardsSettings.splice(i, 1);
        }
      }
    } else {
      await this.cardsList.map((x) => {
        if (x.primaryKey === value) {
          x.view = true;
          this.cardsSettings.push(x);
          this.cardsSettings.sort((a, b) => a.header.localeCompare(b.header));
        }
      });
    }
  }

  open1(content) {
    this.initFeedback();
    const options: NgbModalOptions = {
      backdropClass: "app-session-modal-backdrop",
      windowClass: "app-session-modal-window",
      size: "md",
    };
    this.feedbackModel = this.modalService.open(content, options);
    this.feedbackModel.result.then(
      (result) => {
        // this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
    this.closeDialog();
  }
  initFeedback() {
    this.feedbackForm = this.fb.group({
      name: new FormControl("", [Validators.required]),
      type: new FormControl("", Validators.required),
      type2: new FormControl("", Validators.required),

      // desc: new FormControl("", [Validators.required]),
    });
  }

  get feb() {
    // console.log("Feedback validation: ", this.newFeedback)

    for (var index = 0; index < this.config.feedback.length; ++index) {
      var animal = this.config.feedback[index].name;

      if (
        this.config.feedback[index].name == this.newFeedback ||
        this.config.feedback[index].value == this.newFeedback
      ) {
        this.feedbackForm.controls["name"].setErrors({ incorrect: true });
        this.errorMessage2 = "Name already exists";
        break;
      }
    }
    // this.feedbackForm.controls['name'].setErrors({'incorrect': true});
    return this.feedbackForm.controls;
  }
}

//Edit Columns Component

@Component({
  selector: "edit-columns-config",
  templateUrl: "editColumnModel.html",
  styleUrls: ["./leads-dashboard.component.css"],
})
export class EditColumnsDialog {
  cardsList: any[] = [];
  cardsSettings: CardsSetting[] = [];
  config: any;
  projectId: any;
  newColumn: any;
  AddColumn: any;
  showNewColumn: any;
  showNewFeedback2: any;

  columnType: any;
  showColumnInDashboard: any;
  showColumnInOverview: any;

  columnModel: NgbModalRef;
  columnForm: FormGroup;

  isError: boolean = false;
  errorMessage: string;
  errorMessage2: string = "Name is required";
  isEdit: any;

  constructor(
    public dialog: MatDialog,
    private modalService: NgbModal,
    public LeadsDashboardService: LeadsDashboardService,
    private route: ActivatedRoute,
    private fb: FormBuilder
  ) { }

  ngOnInit() {
    this.LeadsDashboardService.projectId.subscribe((projectId: any) => {
      this.projectId = projectId;
      this.LeadsDashboardService.config.subscribe((config: any) => {
        console.log("Dialog Config: ", config);
        this.config = config;
        this.LeadsDashboardService.isEdit.subscribe((isEdit: any) => {
          this.isEdit = isEdit;
        });
      });
    });
  }

  openDialog() {
    const dialogRef = this.dialog.open(EditColumnsDialog, {
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
    });
  }

  updateColumns() {
    console.log("Update Columns: ", this.config);

    let payload = {
      columns: this.config.columns,
      column: '',
      projectId: this.projectId
    };

    this.LeadsDashboardService.updateColumn(payload).subscribe((res: any) => {
      if (res.status == 200) {
        console.log('Created a new column');
        this.closeDialog();
        this.LeadsDashboardService.refresh.next(true);
      } else {
        console.log(res)
      }
    });

  }
  closeDialog() {
    this.dialog.closeAll();
  }

  addColumn(column, index) { }
  change() {
    console.log("Value: ", this.newColumn);
  }
  submitColumn() {
    if (this.showNewColumn == "false") {
      this.showNewColumn = false;
    } else {
      this.showNewColumn = true;
    }
    this.config.columns.push({
      displayName: this.newColumn,
      type: this.columnType,
      name: toLower(this.newColumn).replace(/\s/g, ""),
      selected: this.showNewColumn,
      showInDashboard: true,
      showInOverview: true,
    });

    let payload = {
      columns: this.config.columns,
      column: {
        displayName: this.newColumn,
        name: toLower(this.newColumn).replace(/\s/g, ""),
        type: this.columnType
      },
      projectId: this.projectId
    };
    this.LeadsDashboardService.updateColumn(payload).subscribe((res: any) => {
      if (res.status == 200) {
        console.log('Created a new column')
      } else {
        console.log(res)
      }
    });
    this.openDialog();
  }
  async removeCard(value, view) {
    if (view === false) {
      this.cardsList.map((x) => {
        if (x.primaryKey === value) {
          x.view = false;
        }
      });
      for (var i = 0; i < this.cardsSettings.length; i++) {
        var obj = this.cardsSettings[i];

        if (obj.primaryKey === value) {
          this.cardsSettings.splice(i, 1);
        }
      }
    } else {
      await this.cardsList.map((x) => {
        if (x.primaryKey === value) {
          x.view = true;
          this.cardsSettings.push(x);
          this.cardsSettings.sort((a, b) => a.header.localeCompare(b.header));
        }
      });
    }
  }

  open1(content) {
    this.initColumn();
    const options: NgbModalOptions = {
      backdropClass: "app-session-modal-backdrop",
      windowClass: "app-session-modal-window",
      size: "md",
    };
    this.columnModel = this.modalService.open(content, options);
    this.columnModel.result.then(
      (result) => {
        // this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
    this.closeDialog();
  }
  initColumn() {
    this.columnForm = this.fb.group({
      displayName: new FormControl("", [Validators.required]),
      // name: new FormControl("", [Validators.required]),
      type: new FormControl("", Validators.required),
      showInDashboard: new FormControl("", Validators.required),
      showInOverview: new FormControl("", Validators.required),

      // desc: new FormControl("", [Validators.required]),
    });
  }

  get feb() {
    for (var index = 0; index < this.config.columns.length; ++index) {
      if (
        this.config.columns[index].displayName == this.newColumn ||
        this.config.columns[index].name == this.newColumn
      ) {
        this.columnForm.controls["displayName"].setErrors({ incorrect: true });
        this.errorMessage2 = "Name already exists";
        break;
      }
    }
    // this.feedbackForm.controls['name'].setErrors({'incorrect': true});
    return this.columnForm.controls;
  }

}
