import { AuthService } from "src/app/account/auth/auth.service";
import { SharedService } from "./../../shared/shared.service";
import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, NgForm } from "@angular/forms";
import { NgbModal, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";
import { CreateAgentv2 } from "./create-agent.service";
import { Group } from "./models/group.model";
import { Role } from "./models/role.model";
import { Agent } from "./models/agent.model";
import { MailserviceService } from "../mailservice.service";
import { RoleService } from "../role-management/role.service";
import { IDropdownSettings } from "ng-multiselect-dropdown";
import { Subscription } from "rxjs";
import { ProjectService } from "../project-management/project.service";
import { ProjectchangeService } from "src/app/layout/project-change/projectchange.service";
import { ErrorHandlerService } from "src/app/error-handler.service";
import { ActivatedRoute } from "@angular/router";
import { UhLeadsService } from "../uhleads.service";
import { COMMA, ENTER, SPACE } from "@angular/cdk/keycodes";
import * as XLSX from 'xlsx';
import * as moment from 'moment';
import { LeadsDashboardService } from "../leads-dashboard/leads-dashboard.service";
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

interface PendingSelection {
  [key: number]: boolean;
}

@Component({
  selector: "app-create-agent",
  templateUrl: "./create-agent.component.html",
  styleUrls: ["./create-agent.component.less"],
})
export class CreateAgentComponentNew implements OnInit {
  closeResult = "";
  public Roles: Role[];
  public pendingSelection: PendingSelection;
  public selectedRoles: Role[];
  public unselectedRoles: Role[];
  public groups: Group[] = [];
  public pendingselectiongroup: PendingSelection;
  public selectedGroups: Group[];
  public unselectedGroups: Group[];
  public agents: Agent[] = [];
  public page: any = 1;
  public pageSize: any = 9;
  public isUpdate: boolean = false;
  public FirstName = "";
  public LastName = "";
  public Mailid = "";
  public Role = "";
  public PhoneNumber = "";
  public error = "";
  public isAdminRole: boolean;
  public companyId: any;
  public companies: any[] = [];
  public companySettings: IDropdownSettings;
  public selectedProjects: any[] = [];
  public unselectedProjects: any[] = [];

  public iserror: boolean = false;
  public ActiveStatus: string;
  public search: string;
  public template: any;
  public superAdmin: Boolean = false;
  public userData: any;
  public agentSubscription: Subscription;
  public order: boolean = false;
  public orderFeild: string = "UpdatedDate";
  public orderString: boolean | "asc" | "desc" = "desc";
  public validation: boolean = false;
  public projects: any[] = [];
  public projectId: any;

  countries = [];
  selectedCountryCode = 1;
  projectList: any = []
  projectType: any;
  isAdminForProject: Boolean = false
  isLoading: Boolean = false
  noRecords: Boolean = false

  public separatorKeysCodes = [ENTER, COMMA, SPACE];
  public emailList = [];
  removable = true;
  rulesForm: FormGroup;
  fb2: FormBuilder = new FormBuilder();
  role: any;
  typeOfUpload: any;
  file: File;
  arrayBuffer: any;
  previewCompanyURL: any = ''
  isEditCompany: Boolean = false;
  selectedCompany: any;
  selectedCompanyDetails: any;
  selectProjectDetails: any
  selectCountry(country) {
    console.log(country);
    this.selectedCountryCode = country.code;
  }
  constructor(
    private modalService: NgbModal,
    private AgentService: CreateAgentv2,
    private SharedService: SharedService,
    private mailService: MailserviceService,
    private roleService: RoleService,
    private authService: AuthService,
    private projectService: ProjectService,
    private handleError: ErrorHandlerService,
    public projectchangeService: ProjectchangeService,
    public route: ActivatedRoute,
    public fb: FormBuilder,
    public uhleadsService: UhLeadsService,
    public LeadsDashboardService: LeadsDashboardService,
  ) { }

  ngOnInit() {
    this.fetchCountries();
    this.userData = JSON.parse(localStorage.getItem("userData"));
    console.log()
    // this.authService.admin.subscribe(data => {
    //   this.superAdmin = data
    // });
    this.rulesForm = this.fb.group({
      emails: this.fb.array([], [this.validateArrayNotEmpty]),
    });
    this.companySettings = {
      singleSelection: true,
      allowSearchFilter: true,
      closeDropDownOnSelection: true,
      textField: "Name",
    };
    console.log(this.companyId);
    this.route.params.subscribe((Data: any) => {
      if (Data['companyId']) {
        this.companyId = Data['companyId'];
        this.getProjectDetails();
      }
      // else{
      //   this.roleService.getCompanies().subscribe((companies) => {
      //     this.companies = companies;
      //     this.companyId = [
      //       this.companies.find((company) => company["id"] === this.userData.id),
      //     ];
      //     this.SharedService.CompanyId.subscribe((data) => {
      //       if (this.userData.companyId === 0) {
      //         console.log(+data, "this is id");
      //         // this.projectId = +data;
      //         this.companyId = [
      //           this.companies.find((company) => company["id"] === +data),
      //         ];
      //         console.log(this.companyId);
      //         this.projectService
      //           .getProjects(this.companyId[0]["id"])
      //           .subscribe((data) => {
      //             this.unselectedProjects = data;
      //             this.projects = data;
      //           }, (error) => {
      //             this.handleError.handleError(error)
      //           });
      //         // console.log(this.companyId[0]);
      //         // this.getAllAgents();
      //         this.getRolesGroups();
      //       } else {
      //         this.projectId = +data;
      //         console.log("companies", companies);
      //         this.projectService
      //           .dashboardProjects({
      //             companyId: this.userData.companyId,
      //             userId: this.userData.id,
      //             admin: false,
      //           })
      //           .subscribe((data) => {
      //             this.projects = data;
      //             this.unselectedProjects = data;
      //             this.getRolesGroups();
      //           }, (error) => {
      //             this.handleError.handleError(error)
      //           });
      //         this.companyId = [
      //           this.companies.find(
      //             (company) => company["id"] === this.userData.companyId
      //           ),
      //         ];
      //         // this.getAllAgents();
      //       }
      //     }, (error) => {
      //       this.handleError.handleError(error)
      //     });
      //     // this.agentSubscription = this.SharedService.projectId.subscribe(
      //     //   (data) => {
      //     //     if (data) {
      //     //       if (this.userData.companyId === 0) {
      //     //         console.log(+data, "this is id");
      //     //         this.projectId = +data;
      //     //         this.getAllAgents();
      //     //       } else {
      //     //         this.projectId = +data;
      //     //         this.getAllAgents();
      //     //       }
      //     //     }
      //     //   }
      //     // );

      //     setTimeout(() => {
      //       this.projectId = this.projectchangeService._projectId;
      //       if (this.userData.companyId === 0) {
      //         this.getAllAgents();
      //       } else {
      //         this.getAllAgents();
      //       }
      //     }, 500);
      //   }, (error) => {
      //     this.handleError.handleError(error)
      //   });
      //   this.SharedService.pageEvent.emit({ pageName: "Agent Management" });
      //   this.mailService.getTemplateByName("ticket").subscribe((data) => {
      //     this.template = data;
      //   }, (error) => {
      //     this.handleError.handleError(error)
      //   });
      // }
    })

  }

  public getProjectDetails() {
    this.AgentService.getAllProjects({ companyId: this.companyId }).subscribe((Data: any) => {
      if (Data.status === 200) {
        this.projectList = Data.projects
        this.projectId = Data.projects[0].projectId
        this.selectProjectDetails = Data.projects[0]
        this.projectType = Data.projects[0].projectType == 'Student Study Abroad' ? true : false
        this.getAllAgents()
      }
    })
  }

  open(content) {
    this.iserror = false;
    this.modalService.open(content, { size: "lg" }).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }

  private getDismissReason(reason: any): string {
    // this.getRolesGroups();
    this.FirstName = "";
    this.LastName = "";
    this.PhoneNumber = "";
    this.Mailid = "";
    this.isUpdate = false;
    this.validation = false;
    this.iserror = false;
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }

  submitV1(form: NgForm) {

    if (this.selectedGroups.length !== 0 && form.valid) {

      let PhoneNumber;
      const loginUserId = this.userData.id;
      const FirstName = form.value.firstname;
      const LastName = form.value.lastname;
      const Mailid = this.Mailid;
      PhoneNumber = `${this.selectedCountryCode}${form.value.number}`;
      const workerSid = Math.random();
      let ActiveStatus = true;
      let isRoleAdmin = form.value.isRoleAdmin;
      let companyId = this.companyId[0]["id"];
      if (this.isUpdate) {
        PhoneNumber = form.value.number;
      }
      console.log(form.value);
      if (this.isUpdate) {
        ActiveStatus = form.value.ActiveStatus === "active" ? true : false;
      }
      const Projects = this.selectedProjects.map((project) => project["id"]);
      const Roles = this.selectedRoles.map((role) => {
        return role["id"];
      });
      const Groups = this.selectedGroups.map((Group) => {
        return Group["id"];
      });

      const createAgent: Object = {
        loginUserId,
        FirstName,
        LastName,
        Mailid,
        PhoneNumber,
        workerSid,
        Roles,
        Groups,
        ActiveStatus,
        isRoleAdmin,
        companyId,
        Projects,
      };
      this.createagent(createAgent);
      this.validation = false;
    } else {
      this.validation = true;
    }
  }

  submit(form: NgForm) {

    if (form.valid) {

      let PhoneNumber;
      const loginUserId = this.userData.id;
      const FirstName = form.value.firstname;
      const LastName = form.value.lastname;
      const Mailid = this.Mailid;
      PhoneNumber = `${this.selectedCountryCode}${form.value.number}`;
      const workerSid = Math.random();
      let ActiveStatus = true;
      let companyId = this.companyId;
      let projectId = this.projectId;
      if (this.isUpdate) {
        PhoneNumber = form.value.number;
      }
      console.log(form.value);
      if (this.isUpdate) {
        ActiveStatus = form.value.ActiveStatus === "active" ? true : false;
      }
      let role = form.value.role

      let payload = {
        mail: this.userData.mail,
        companyId: this.companyId,
        token: this.userData.token,
        userId: this.userData.id,
        emailBody: 'Inviting USer',
        inviteUsersProjects: [
          {
            inviteEmails: Mailid,
            projects: [
              {
                projectId: this.projectId,
                role: role
              }
            ]
          }
        ]
      }

      const createAgent: Object = {
        loginUserId,
        FirstName,
        LastName,
        Mailid,
        PhoneNumber,
        workerSid,
        role,
        ActiveStatus,
        companyId,
        projectId,
      };
      console.log("createAgent", createAgent)
      this.createagent(payload);
      this.validation = false;
    } else {
      this.validation = true;
    }
  }

  createagent(body: Object) {


    if (this.isUpdate) {
      return this.AgentService.AgentUpdate(body).subscribe((resp) => {
        if (resp) this.getAllAgents();
        this.modalService.dismissAll();
        this.iserror = false;
      }, (error) => {
        this.handleError.handleError(error)
      });
    }
    else {
      this.AgentService.createAgentV2(body).subscribe(
        (resp: any) => {
          if ("status" in resp) {
            this.iserror = true;
            return (this.error = resp["status"]);
          }
          if (resp) this.getAllAgents();
          this.modalService.dismissAll();
          this.uhleadsService.showSuccess("Users invited successfully", "Success");

          // this.mailService.sendmail({
          //   From: 'support@avantao.com',
          //   To: 'saivikass54321@gmail.com',
          //   Subject: 'agent creation successfully completed in accessque',
          //   Body: {
          //     html: this.template['Html'].replace('{{FirstName}} {{LastName}}', `${body['FirstName'] + body['LastName']}`)
          //   }
          // }).subscribe(data => {
          //   console.log(data);
          // })
          this.iserror = false;
        }
      );
    }
  }

  getAllAgents() {
    let payload = {
      projectId: this.projectId,
      userMail: this.userData.mail
    }
    this.isLoading = true
    this.noRecords = false

    this.agents = []
    this.AgentService.GetAllAgentsV2(payload).subscribe((res: any) => {
      if (res.data && res.data.length > 0) {
        this.agents = res.data;
        this.isAdminForProject = res.isAdmin
      }
      else {
        this.isAdminForProject = res.isAdmin

        this.noRecords = true
      }
      this.isLoading = false

    }, (error) => {
      this.handleError.handleError(error)
    });
  }
  // checkUserRole() {
  //   let payload={
  //     projectId:this.projectId,
  //     userMail:this.userData.mail
  //   }
  //   this.isLoading=true
  //   this.agents=[]
  //   this.AgentService.checkUserRole(payload).subscribe((res:any) => {
  //     if(res.data && res.data.length>0){
  //       this.isAdminForProject=res.isAdmin
  //     }
  //     else{
  //       this.noRecords=true
  //     }
  //   this.isLoading=false

  //   }, (error) => {
  //     this.handleError.handleError(error)
  //   });
  // }


  // updateAgent(id: number, content) {
  //   this.isUpdate = true;
  //   this.AgentService.GetAgentbyid(id).subscribe((res) => {
  //     if (content != "") {
  //       this.open(content);
  //     }
  //     this.FirstName = res["FirstName"];
  //     this.PhoneNumber = res["PhoneNumber"];
  //     this.LastName = res["LastName"];
  //     this.Mailid = res["Mailid"];
  //     this.selectedRoles = res["SelectedRoles"];
  //     this.selectedGroups = res["SelectedGroups"];
  //     this.selectedProjects = res["SelectedProjects"];
  //     this.ActiveStatus =
  //       res["ActiveStatus"] === "true" ? "active" : "inactive";
  //     this.isAdminRole = res["IsRoleAdmin"];
  //     this.companyId = [
  //       this.companies.find((data) => data["id"] === res["CompanyId"]),
  //     ];
  //     // this.ActiveStatus

  //     // let SelectedRoleids = this.selectedRoles.map((Role) => Role["id"]);
  //     this.unselectedProjects = this.projects.filter(
  //       (el) => !this.selectedProjects.some((ele) => ele["id"] === el["id"])
  //     );
  //     this.getRolesGroups();
  //     let SelectedGroupsids = this.selectedGroups.map((Group) => Group["id"]);
  //     // let AllRoles;
  //     let AllGroups;
  //     this.AgentService.getAgentManGroups({
  //       roles: this.selectedRoles,
  //     }).subscribe((res) => {
  //       AllGroups = res;
  //       this.unselectedGroups = AllGroups.filter(
  //         (data) => !this.selectedGroups.some((ele) => ele["id"] === data["id"])
  //       );
  //     }, (error) => {
  //       this.handleError.handleError(error)
  //     });
  //   }, (error) => {
  //     this.handleError.handleError(error)
  //   });
  // }

  deleteAgent(id) {
    this.AgentService.updateAgentStatus({ id: id }).subscribe((data) => {
      this.getAllAgents();
    }, (error) => {
      this.handleError.handleError(error)
    });
  }

  cancel() {
    this.modalService.dismissAll();
  }


  fetchCountries() {
    this.SharedService.getCountries().subscribe((data) => {
      this.countries = data;
    }, (error) => {
      this.handleError.handleError(error)
    });
  }

  sortOrder(feildName) {
    this.orderFeild = feildName;
    this.order = !this.order;
    if (this.order) {
      this.orderString = "asc";
    } else {
      this.orderString = "desc";
    }
  }

  projectChanged(data) {
    this.Role = null
    this.emailList = []
    let tempProjectDetails = this.projectList.filter(x => x.projectId == data)
    this.selectProjectDetails = tempProjectDetails[0]
    this.projectType = tempProjectDetails[0]['projectType'] == 'Student Study Abroad' ? true : false

    if (this.userData.companyId === 0) {
      this.projectId = +data;
      this.getAllAgents();
    } else {
      this.projectId = +data;
      this.getAllAgents();
    }
  }
  selecteduserStatus: any;
  selectuserStatusData: any
  activeInactiveUser(content1, item, From) {
    this.selectuserStatusData = item
    this.selecteduserStatus = From
    this.modalService.open(content1, { size: "sm" }).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );

  }
  updateUserStatus(from?: any, item?: any) {
    let payload;
    if (from == 1) {
      payload = {
        id: this.selectuserStatusData.id,
        Mailid: this.selectuserStatusData.Mailid,
        ActiveStatus: this.selecteduserStatus == '1' ? 'false' : 'true',
        agentMail: this.userData.mail,
      }
    }
    else {
      payload = {
        id: item.id,
        Mailid: item.Mailid,
        is_deleted: 'true',
        agentMail: this.userData.mail
      }
    }

    // console.log("payload",payload)
    this.AgentService.updateAgentStatusV2(payload).subscribe((res: any) => {
      if (res.status == true) {
        this.uhleadsService.showSuccess(res.message, 'success');
        this.getAllAgents()
      }
      else {
        this.uhleadsService.showWarning(res.message, 'warning');

      }
      this.modalService.dismissAll()

      // this.isLoading=false

    }, (error) => {
      this.handleError.handleError(error)
    });
  }
  incomingfile(event) {
    this.file = event.target.files[0];
  }

  Upload() {
    let fileReader = new FileReader();
    fileReader.onload = (e) => {
      this.arrayBuffer = fileReader.result;
      var data = new Uint8Array(this.arrayBuffer);
      var arr = new Array();
      for (var i = 0; i != data.length; ++i)
        arr[i] = String.fromCharCode(data[i]);
      var bstr = arr.join("");
      var workbook = XLSX.read(bstr, { type: "binary" });
      var first_sheet_name = workbook.SheetNames[0];
      var worksheet = workbook.Sheets[first_sheet_name];
      console.log(XLSX.utils.sheet_to_json(worksheet, { raw: true }));
      let emailList = XLSX.utils.sheet_to_json(worksheet, { raw: true });
      // this.emailList = emailList;

      let usersList = [];
      let projects = [];
      if (
        emailList.length > 0 &&
        emailList[0]["Role"] &&
        emailList[0]["Email"]
      ) {


        projects.push({
          projectId: this.projectId,
          role: this.Role,
        });

        // for(let j=0; j<this.emailList.length; j++) {
        //   if(this.emailList[j].invalid==false)
        //   usersList.push({
        //     inviteEmails: this.emailList[j].value,
        //     projects: projects,
        //     // projectId: this.newProjectDetails[i].projectId,
        //     // assignedRoles: this.newProjectDetails[i].role,
        //   });
        // }


        for (let i = 0; i < emailList.length; i++) {
          console.log(emailList[i]["Email"]);
          console.log(this.validateEmail(emailList[i]["Email"]));

          if (this.validateEmail(emailList[i]["Email"])) {
            // this.emailList.push({ value: emailList[i]["Email"], invalid: false})
            usersList.push({
              inviteEmails: emailList[i]["Email"],
              projects: projects,
            });
          }
        }

        let payload = {
          userId: this.userData.id,
          mail: this.userData.mail,
          token: this.userData.token,
          emailBody: "Inviting USer",
          // companyId: 2,
          companyId: this.companyId,
          inviteUsersProjects: usersList,
        };

        console.log("Payload: ", payload);

        // if(payload.inviteUsersProjects[0].projects.length>0){
        //   this.LeadsDashboardService.inviteUSers(payload).subscribe((res:any) => {
        //     if(res.status == 200) {
        //       this.uhleadsService.showSuccess("Users invited successfully", "Success");
        //       document.getElementById("closeInviteButton").click();
        //     } else {
        //       this.uhleadsService.showError("Something went wrong", "Error");
        //     }
        //   })
        // } else {
        //   this.uhleadsService.showError(
        //     "Please enter emails and select a role",
        //     "Missing info"
        //   );
        // }

      } else {
        this.uhleadsService.showError(
          "Please upload a valid file",
          "invalid data"
        );
      }
    };
    fileReader.readAsArrayBuffer(this.file);
  }
  removeEmail(data: any): void {
    console.log("Removing " + data);
    console.log("Has error: ", this.rulesForm.get('emails'))

    if (this.emailList.indexOf(data) >= 0) {
      this.emailList.splice(this.emailList.indexOf(data), 1);
    }
  }

  validateArrayNotEmpty(c: FormControl) {
    if (c.value && c.value.length === 0) {
      return {
        validateArrayNotEmpty: { valid: false },
      };
    }
    return null;
  }

  validateEmail(email) {
    var re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  add(event): void {
    console.log(event.value);
    console.log("Has error: ", this.rulesForm.get('emails').hasError('incorrectEmail'))
    if (event.value) {
      if (this.validateEmail(event.value)) {
        this.emailList.push({ value: event.value, invalid: false });
      } else {
        this.emailList.push({ value: event.value, invalid: true });
        this.rulesForm.controls["emails"].setErrors({ incorrectEmail: true });
      }
    }
    if (event.input) {
      event.input.value = "";
    }
  }

  inviteAgents() {
    console.log("Emails: ", this.emailList);
    console.log("Role: ", this.role);
    if (this.emailList.length > 0) {
      let usersList = [];
      let projects = [];

      projects.push({
        projectId: this.projectId,
        role: this.Role,
      });
      for (let j = 0; j < this.emailList.length; j++) {
        if (this.emailList[j].invalid == false)
          usersList.push({
            inviteEmails: this.emailList[j].value,
            projects: projects,

          });
      }

      let payload = {
        userId: this.userData.id,
        mail: this.userData.mail,
        token: this.userData.token,
        emailBody: "Inviting USer",
        // companyId: 2,
        companyId: this.companyId,
        inviteUsersProjects: usersList,
      };

      console.log("Payload: ", payload);

      if (payload.inviteUsersProjects[0].projects.length > 0) {
        this.LeadsDashboardService.inviteUSers(payload).subscribe((res: any) => {
          if (res.status == 200) {
            this.getAllAgents()
            this.Role = null
            this.emailList = []
            this.uhleadsService.showSuccess("Users invited successfully", "Success");
            document.getElementById("closeInviteButton").click();
          } else {
            this.uhleadsService.showError("Something went wrong", "Error");
          }
        });
      } else {
        this.uhleadsService.showError(
          "Please enter emails and select a role",
          "Missing info"
        );
      }
    } else {
      this.uhleadsService.showError(
        "Please enter emails and select a role",
        "Missing info"
      );
    }
  }

  exportList() {
    let obj = {
      projectId: this.projectId,
      companyId: this.companyId,
      AgentId: this.userData.id,
      isexport: true
    }
    this.AgentService.exportDetails(obj).subscribe((resp: any) => {

      let AgentDetails = resp.data
      console.log(AgentDetails)

      var fd = AgentDetails.map(x => {
        x.FullName = x.FirstName + " " + x.LastName
        x.Role = x.userRole
        x.PhoneNumber = x.PhoneNumber
        x.Mailid = x.Mailid
        x.Address = x.Address ? x.Address : '--'
        x.ActiveStatus = x.ActiveStatus == "true" ? 'Active' : 'Inactive'
        x.createdAt = moment(x.createdAt).format('YYYY-MM-DD HH:mm')
        x.updatedAt = moment(x.updatedAt).format('YYYY-MM-DD HH:mm')

        const { FullName, Role, PhoneNumber, Mailid, Address, ActiveStatus, createdAt, updatedAt } = x;
        return { FullName, Role, PhoneNumber, Mailid, Address, ActiveStatus, createdAt, updatedAt };
      })
      var json = fd;

      const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
      const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
      var a = document.createElement("a");
      a.setAttribute('style', 'display:none;');
      document.body.appendChild(a);
      var blob = new Blob([excelBuffer], { type: EXCEL_TYPE });
      var url = window.URL.createObjectURL(blob);
      a.href = url;
      var x: Date = new Date();
      var link: string = "Agent_Management_" + x.getTime() + '.xlsx';
      a.download = link;
      a.click();
    });
  }
}
