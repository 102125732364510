<div class="container-fluid">
	<!-- Header Starts -->
	<div class="row d-block">
		<nav class="navbar navbar-expand-lg navbar-light bg-light">
			<div class="container-fluid">
				<div style="
						display: flex;
						justify-content: space-between;
						width: 100%;
						align-items: center;
					">
					<div>
						<a class="navbar-brand" href="index.html"><img src="../../assets/images/acq-logo.png" /></a>
						<button class="navbar-toggler" type="button" data-bs-toggle="collapse"
							data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
							aria-expanded="false" aria-label="Toggle navigation">
							<span class="navbar-toggler-icon"></span>
						</button>
					</div>
					<div class="" id="navbarSupportedContent">
						<div class="me-auto"></div>

						<!--
              <a href="#" class='btn btn-link'>Services</a>
              <a href="#" class='btn btn-link'>Plans</a>
              <a href="#" class='btn btn-link'>About Us</a>
              <a href="#" class='btn btn-link'>Contact Us</a>-->
						<a type="button" class="btn orange-btn" style="margin-right: 10px"
							[routerLink]="['/account/login']">Member Login</a>
						<!--
              <button type="button" class='btn btn-primary btn-md' style='margin-right:10px'>Sign Up</button>
            </form>-->
						<!-- Login Modal -->
						<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel"
							aria-hidden="true">
							<div class="modal-dialog" style="width: 390px">
								<div class="modal-content">
									<div class="modal-body">
										<button type="button" class="btn-close float-end" data-bs-dismiss="modal"
											aria-label="Close"></button>
										<div class="md-txt primary p-l-10 p-b-10">
											Log in to your <span class="grey">Account</span>
										</div>
										<div class="p-a-15">
											<form class="row g-3">
												<div class="col-md-4">
													<label>Dial Code</label>
													<select class="form-select" aria-label="Default select example">
														<option selected>India +91</option>
														<option value="code 1">code 1</option>
														<option value="code 2">code 2</option>
														<option value="code 3">code 3</option>
													</select>
												</div>
												<div class="col-md-8">
													<input type="text" class="form-control" style="margin-top: 23px"
														id="" placeholder="Mobile Number" />
												</div>

												<div class="input-group">
													<label type="text" class="form-control text-center primary"
														style="border: 0" placeholder="Enter Mobile Number"
														aria-label="Enter Mobile Number"
														aria-describedby="button-addon2" value="">OTP Sent
													</label>
													<div class="input-group-append">
														<button class="btn btn-outline-secondary btn-otp" type="button"
															id="button-addon2">
															Send OTP
														</button>
													</div>
												</div>

												<div class="input-group" style="display: none">
													<select class="form-select" aria-label="Default select example"
														style="width: 80px">
														<option selected>Ind +91</option>
														<option value="code 1">code 1</option>
														<option value="code 2">code 2</option>
														<option value="code 3">code 3</option>
													</select>

													<input type="text" class="form-control"
														placeholder="Enter Mobile Number"
														aria-label="Enter Mobile Number"
														aria-describedby="button-addon2" value="9866411591" />
													<div class="input-group-append">
														<button class="btn btn-outline-secondary btn-otp" type="button"
															id="button-addon2">
															Send OTP
														</button>
													</div>
												</div>
												<!-- For OTP Enter-->

												<div class="col-md-12">
													<input type="text" class="form-control text-center"
														style="font-weight: bold" id=" " placeholder=" "
														value="5 8 6 7 8 9" />
												</div>
												<!-- For OTP close -->

												<div class="d-grid xgap-2">
													<button class="btn btn-primary" type="button">
														Login
													</button>
												</div>
												<div style="
														width: 250px;
														margin: 10px auto 0;
														text-align: center;
													">
													<div class="brline"></div>
													<div style="float: left; padding: 0 10px">or</div>
													<div class="brline"></div>
												</div>
												<div class="d-grid gap-2">
													<button class="g-button" type="button">
														Continue with Gmail
													</button>
												</div>
												<div class="text-center">
													Don’t have an account?
													<a href="registration.html" class="link"
														style="display: inline">Register Now</a>
												</div>
											</form>
										</div>
									</div>
								</div>
							</div>
						</div>
						<!-- Login Modal Ends -->
						<!-- Book my Trail Modal Starts -->
						<div class="modal fade" id="exampleModal2" tabindex="-1" aria-labelledby="exampleModalLabel"
							aria-hidden="true">
							<div class="modal-dialog" style="width: 390px">
								<div class="modal-content">
									<div class="modal-body">
										<button type="button" class="btn-close float-end" data-bs-dismiss="modal"
											aria-label="Close"></button>
										<div class="md-txt primary p-l-10 p-b-10">
											Book a Free<span class="grey">Trial</span>
										</div>
										<div class="p-a-15">
											<form class="row g-3">
												<div class="col-md-12">
													<input type="text" class="form-control" id=" "
														placeholder="Enter Student Name" />
												</div>
												<div class="input-group">
													<input type="text" class="form-control"
														placeholder="Enter Mobile Number"
														aria-label="Enter Mobile Number"
														aria-describedby="button-addon2" />
													<div class="input-group-append">
														<button class="btn btn-outline-secondary btn-otp" type="button"
															id="button-addon2">
															Send OTP
														</button>
													</div>
												</div>

												<!-- For OTP Enter-->
												<div class="col-md-12 text-center primary">
													OTP sent
												</div>
												<div class="col-md-12">
													<input type="text" class="form-control text-center"
														style="font-weight: bold" id=" " placeholder=" "
														value="5 8 6 7 8 9" />
												</div>
												<!-- For OTP close -->

												<div class="col-md-6">
													<input type="text" class="form-control" id=" "
														placeholder="Email address" />
												</div>
												<div class="col-md-6">
													<select class="form-select" aria-label="Default select example">
														<option selected>Select State</option>
														<option value="code 1">Andhra Pradesh</option>
														<option value="code 2">Delhi</option>
														<option value="code 3">Goa</option>
													</select>
												</div>

												<div style="
														width: 300px;
														margin: 10px auto 0;
														text-align: center;
													">
													<div class="brline2"></div>
													<div style="float: left; padding: 0 10px">
														select your camp
													</div>
													<div class="brline2"></div>
												</div>

												<div class="d-grid gap-2 d-md-block text-center">
													<button type="button" class="btn btn-secondary btn-md">
														Basecamp
													</button>
													<button type="button" class="btn btn-secondary btn-md">
														Transitcamp
													</button>
													<button type="button" class="btn btn-secondary btn-md">
														Summit
													</button>
												</div>
												<div class="d-grid xgap-2">
													<button class="btn btn-primary" type="button">
														Schedule a Free Trial
													</button>
												</div>

												<!-- Message stats -->
												<div class="md-txt primary text-center">
													<img src="../../assets/images/check.svg"
														style="width: 48px; display: block; margin: 0 auto" />
													Thank you!
												</div>
												<div class="md-txt primary text-center">
													<p class="text-center md-txt" style="font-size: 15px">
														Your account have been registered successfully.
													</p>
													<a href="basecamp.html" class="link"
														style="font-size: 16px; font-weight: bold">
														Let's Experiment</a>
												</div>
												<!-- Message Ends -->
											</form>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</nav>
	</div>

	<!-- Book my Trail Modal Ends -->

	<!-- Banner Starts -->
	<div class="row">
		<div class="home-banner-bg2">
			<div class="banenr-text2">Privacy Policy</div>
		</div>
	</div>
	<!-- Banner Ends -->
	<!-- Video Section Starts -->

	<div class="row b-w">
		<div class="col-md-8 mx-auto p-a-30">
			<b class="bhead">AccessQue Privacy Policy</b>
			<p>
				This privacy policy (“Policy”) was last updated on February, 2021. We may change or update this policy
				at any time, and the same will be updated here. If you are a AccessQue user or customer, we shall notify
				the changes or updates either by sending an email or a notification on the AccessQue App (as defined
				below). Please ensure to read such notices carefully.
			</p>
			<p>
				We sincerely believe that you should always know what data we collect from you, the purposes for which
				such data is used, and that you should have the ability to make informed decisions about what you want
				to share with us.
			</p>
			<p>
				Therefore, we want to be transparent about:
				<br>(i) how and why we collect, store and use your personal data in the various capacities in which you
				interact with us; and
				<br>(ii) the rights that you have to determine the contours of this interaction.
			</p>
			<p>
				While we would strongly advise you to read the Policy in full, the following summary will give you a
				snapshot of the salient points covered herein:
			</p>

			<p>
				This Policy details the critical aspects governing your personal data relationship with AccessQue which
				is owned and operated by Avantao Technologies(opc)private Limited Private Limited, a company
				incorporated under the laws of India, and having its registered office at #39, NGEF Lane, 2nd Floor,
				Suite No.673 Indiranagar, Bangalore, India 560 038</p>
			<p>
				Your personal data relationship with AccessQue varies based on the capacity in which you interact with
				us/avail of our products and solutions (“Services”). You could be: <br>(i) a visitor to AccessQue.com
				(“Website”) or any pages thereof (“Visitor”) <br>(ii) A person/entity availing of one of our Services
				(“Customer”); or <br>(iii) an employee/agent/representative/appointee of a Customer who uses the said
				Service (“User”);
				Based on whether you are a Visitor, Customer or User, the type of data we collect and the purpose for
				which we use it will differ and this Policy details such variations;
				This Policy is a part of and should be read in conjunction with our Terms of Service; and
				This Policy will clarify the rights available to you vis-à-vis the personal data you share with us.</p>
			<p>
				If you have any queries or concerns with this Policy, please contact our Grievance Officer (refer
				Section 12). If you do not agree with the Policy, we would advise you to not visit/use the Website or
				the AccessQue mobile application(s)/platform(s) (collectively “App”).
			</p>
			<b style="color: #f57722; font-size: 16px">INFORMATION WE COLLECT AND HOW WE USE IT</b><br>
			<b style="color: #f57722; font-size: 16px">TYPE OF USER VISITOR CUSTOMER USER WHAT DATA WE MAY COLLECT</b>
			<p>Your IP Address<br>
				Your location
			</p>

			<p>How you behave on the Website, (what pages you land on, how much time you spend, etc.);
				What Device you use to access the Website and its details (model, operating system, etc.);
				Cookies and Web Beacon data; and
				Data from third parties who have your explicit consent to lawfully share that data.
				If you submit an inquiry through the Website, we will ask for your:
				Name; and e-mail.
			</p>
			<p>
				The name and e-mail of your representative who signs up for a Service on your behalf; and
				Credit Card/Debit Card/Other Payment Mode information to check your financial qualifications, detect
				fraud and facilitate payments for our Services.
				Your name, e-mail;
				Your IP Address;
				Your location unless you deactivate location services in the relevant section of the App;
				How you behave in the relevant product environment and use the features;
				What device you use to access the Website/App and its details (model, operating system, etc.);
				Cookies and Web Beacon data;
				Data from third parties who have your explicit consent to lawfully share that data;
				SMSs that you send or receive on your Device from your existing and prospective customers (“Leads”) if
				you have opted for the same in the relevant section of the App;
				Phone call logs to record your call activities with Leads (i.e. call time, duration, phone number of
				relevant Lead) if you have opted for the same in the relevant section of the App; and
				E-mails that you send and receive for the purpose of reading them to sync with your Leads, if you have
				opted to sync your e-mail with your AccessQue account using our E-mail Sync App. If you do choose to
				activate SMS and Call Log parsing or E-mail Sync to improve your experience, you can be sure that we
				will never collect or store any information that does not pertain to Leads.
				HOW AND WHY WE USE IT We use this information to analyse and identify your behaviour and enhance the
				interactions you have with the Website.
			</p>
			<p>If you submit your details and give us your consent, we may use your data to send you
				e-mails/newsletters, re-target AccessQue advertisements or re-market our Services using services from
				third-parties like Facebook and Google.
			</p>
			<p>
				We collect this data in order to help you register for and facilitate provision of our Services.
				We also use this data to enable you to make payments for our Services. We use a third-party service
				provider to manage payment processing. This service provider is not permitted to store, retain, or use
				information you provide except for the sole purpose of payment processing on our behalf.

			</p>
			<p>
				If you give us your consent, we may send you newsletters and e-mails to market other products and
				services we may provide.
			</p>
			<p>
				We collect this data in order to facilitate provision of our Services.
				We will occasionally send you e-mails regarding changes or updates to the Service that you are using. In
				the event you report an issue with a Service, we may also screen/video record your Device only when you
				use the App for a limited time period to help us better understand how to address the issue.
			</p>
			<p>
				If you give us your consent, we may send you newsletters and e-mails to market other products and
				services we may provide.
			</p>
			<p>
				We may also conduct anonymized usage behaviour analysis at the aggregate level to determine how the
				features of a particular Service are being used.

			</p>
			<b style="color: #f57722; font-size: 16px">WHAT ARE THE COOKIES AND WEB BEACONS REFERRED TO ABOVE?</b>
			<p>
				TYPE OF FILE COOKIES WEB BEACON
				WHAT THEY DO Cookies are text files which are sent from our server and downloaded to your Device when
				you visit our Website.
				They are useful because they allow us to recognize your Device when you return. You can disable them
				through your browser should you so wish.

			</p>
			<p>You can find more information about cookies at: <b>www.allaboutcookies.org</b></p>
			<p>
				Web Beacon (also known as Clear GIF, Web Bugs or Pixel Tag) is a tiny picture file embedded on the
				Website/App that tracks your behaviour and navigation. It is similar to a cookie in what it does, but it
				doesn’t get downloaded on to your Device.
			</p>
			<p>You can find more information about Web Beacons at:<b>
					http://www.allaboutcookies.org/faqs/beacons.html</b>
			</p>
			<p>
				WHAT THEY WON’T DO
				Harvest any personally identifiable information; or
				Contain any executable software, malicious code or virus.
				<b>
					FOR THE AVOIDANCE OF ANY DOUBT, WE SHOULD CLARIFY THAT IN THE EVENT WE ANONYMIZE AND AGGREGATE
					INFORMATION COLLECTED FROM YOU, WE WILL BE ENTITLED TO USE SUCH ANONYMIZED DATA FREELY, WITHOUT ANY
					RESTRICTIONS OTHER THAN THOSE SET OUT UNDER APPLICABLE LAW.</b>

			</p>
			<p>Where such data is not being used by us to render Services to you, we shall explicitly seek your consent
				for using the same. You can choose to withdraw this consent at any time, here.
			</p>


			<b style="color: #f57722; font-size: 16px">YOUR RIGHTS & PREFERENCES AS A DATA SUBJECT IN THE EU</b>
			<p>
				It might have come to your attention that a new law was passed in the European Union (EU) called the
				General Data Protection Regulation (GDPR). The GDPR gives certain rights to individuals who are EU data
				subjects, in relation to their personal data.
			</p>
			<p>
				Subject to the GDPR and applicable law’s limitations, the rights afforded to you if you are an EU data
				subject are:
			</p>

			<b style="color: #f57722; font-size: 16px">RIGHT TO BE INFORMED</b>
			<p>
				You have a right to be informed about the manner in which any of your personal data is collected or used
				which we have endeavoured to do by way of this Policy.
			</p>

			<b style="color: #f57722; font-size: 16px">RIGHT OF ACCESS</b>
			<p>
				You have a right to access the personal data you have provided by requesting us to provide you with the
				same.
			</p>

			<b style="color: #f57722; font-size: 16px">RIGHT TO RECTIFICATION</b>
			<p>
				You have a right to request us to amend or update your personal data if it is inaccurate or incomplete.
			</p>

			<b style="color: #f57722; font-size: 16px">RIGHT TO ERASURE</b>
			<p>
				You have a right to request us to delete your personal data.
			</p>
			<b style="color: #f57722; font-size: 16px">RIGHT TO RESTRICT</b>
			<p>
				You have a right to request us to temporarily or permanently stop processing all or some of your
				personal data.
			</p>
			<b style="color: #f57722; font-size: 16px">RIGHT TO OBJECT</b>
			<p>
				You have a right, at any time, to object to our processing of your personal data under certain
				circumstances. You have an absolute right to object to us processing your personal data for the purposes
				of direct marketing.
			</p>
			<b style="color: #f57722; font-size: 16px">RIGHT TO DATA PORTABILITY</b>
			<p>
				You have a right to request us to provide you with a copy of your personal data in electronic format and
				you can transmit that personal data for using another third-party’s product/service.
			</p>
			<b style="color: #f57722; font-size: 16px">RIGHT NOT TO BE SUBJECT TO AUTOMATED DECISION-MAKING</b>
			<p>
				You have a right to not be subject to a decision based solely on automated decision making, including
				profiling.
			</p>
			<p>
				In case you want to exercise the rights set out above you can contact our Grievance Officer whose
				details are set out in Section 12 below.
			</p>
			<p>
				The data provided by you as a Visitor, or when you sign up as a Customer / User or register for our
				Services will be processed by us for the purpose of rendering Services to you or in order to take steps
				prior to rendering such Services, at your request. Where such data is not being used by us to render
				Services to you, we shall explicitly seek your consent for using the same. You can choose to withdraw
				this consent at any time, here. Additionally, we may process your data to serve legitimate interests.
			</p>
			<p>Accordingly, the grounds on which we can engage in processing are as follows:</p>
			<p>
				NATURE OF DATA GROUNDS
				Visitor Data Consent;
				Performance of a Contract; and
				Legitimate Interest
				Account Registration Data
				Performance of a Contract; and
				Legitimate Interest
				Payment Data
				Performance of a Contract;
				Compliance with applicable laws; and
				Legitimate Interest
				Service Usage Data
				Performance of a Contract; and
				Legitimate Interest
				Data for Marketing our Services
				Consent; and
				Legitimate Interest

			</p>
			<p>If you believe we have used your personal data in violation of the rights above or have not responded to
				your objections, you may lodge a complaint with your local supervisory authority.
			</p>
			<b style="color: #f57722; font-size: 16px">Additionally, please note:</b>
			<p>
				If you are a Customer/User using one of our Services to collect data about an EU data subject from
				third-parties, it shall be your sole obligation to inform such data subject about the source of such
				data; and
				We do not collect any Special Categories of Personal Data. Further, if you are a Customer/User, you
				hereby agree and acknowledge that you shall not, under any circumstances, whether directly or
				indirectly, use our Services to collect or process Special Categories of Personal Data or transfer to us
				any such data.
				The term “Special Categories of Personal Data” shall have the meaning ascribed to it under the GDPR and
				shall include, without limitation, data pertaining to a data subject’s race, ethnic origin, genetics,
				political affiliations, biometrics, health or sexual orientation.
			</p>
			<b class="bhead">
				YOUR RIGHTS UNDER CALIFORNIA CONSUMER PRIVACY ACT (CCPA)
			</b>
			<br>
			<b style="color: #f57722; font-size: 16px">AccessQue complies with CCPA by giving you the five privacy
				rights for California consumers:</b>
			<p>
				The right to know about the personal information a business collects about them and how it is used and
				shared You have a right to be informed about the manner in which any of your personal data is collected
				or used which we have endeavoured to do by way of this Policy.</p>
			<p>
				The right to delete personal information collected from them AccessQue gives you the right to request us
				to delete your personal data.</p>
			<p>
				The right to opt-out of the sale of their personal information AccessQue does not sell any data of any
				of its users/customer/lead.</p>
			<p>
				The right to non-discrimination for exercising their CCPA rights AccessQue assures no discrimination
				against consumers exercising their right of privacy under CCPA
				No waiver of privacy rights AccessQue assures that it will not ask for waiver of privacy rights from
				California consumers</p>
			<p>
				In case you want to exercise the rights set out above you can contact our Grievance Officer whose
				details are set out in Section 12 below.

			</p>
			<b style="color: #f57722; font-size: 16px">RETENTION OF PERSONAL INFORMATION</b>
			<p>
				We will store any personal data we collect from you as long as it is necessary in order to facilitate
				your use of the Services and for ancillary legitimate and essential business purposes – these include,
				without limitation, for improving our Services, attending to technical issues, and dealing with
				disputes.</p>
			<p>
				We may need to retain your personal data even if you seek deletion thereof, if it is needed to comply
				with our legal obligations, resolve disputes and enforce our agreements.</p>
			<p>
				If you are a Customer, please be advised that: (i) you will need to inform your Leads about how you
				store and deal with any data you collect from them using one of our Services, in compliance with
				applicable laws including the GDPR; and (ii) after you terminate your usage of a Service, we may, unless
				legally prohibited, delete all data provided or collected by you (including Lead information) from our
				servers.
			</p>
			<b style="color: #f57722; font-size: 16px">TRACKING BY OUR ADVERTISEMENT PARTNERS</b>
			<p>
				Upon receiving your specific consent, we may share the data we collect from Cookies or Web Beacons with
				our advertisement partners to track your visits, establish your non-personal identity and present you
				with targeted advertisements about our Services.
			</p>
			<b style="color: #f57722; font-size: 16px">TOOLS USED BY OUR CUSTOMERS</b>
			<p>If you are a Customer, you are empowered to use proprietary or other third party technologies (for
				example Flash Cookies) on your website and host them in our App. If you do, you agree and acknowledge
				that it is your sole obligation to inform your Leads about any data you collect by using such
				technologies and the policies by which such collection is bound.
			</p>
			<b style="color: #f57722; font-size: 16px">COMPLIANCE WITH CHILDREN’S ONLINE PRIVACY PROTECTION ACT</b>
			<p>As a business service, we neither target our Services toward nor knowingly collect any personal
				information from any individuals under the age of 13.</p>
			<b style="color: #f57722; font-size: 16px">TRANSFER OF INFORMATION</b>
			<p>In order for us to facilitate our global operations, we may transfer and access the data we collect and
				process in accordance with this Policy, to our group companies around the world. Your rights and
				protections will, under no circumstances, be diluted by this transfer.</p>
			<p>Further, in the ordinary course of business, we may employ other companies and people to assist us in
				providing certain components of our Services in compliance with the provisions of this Policy. To do so,
				we may need to share your data with them.</p>
			<p>Where applicable – if the entities to which these transfers are effected are not situated in countries
				deemed ‘adequate’ by the European Commission, we shall enter into appropriate Data Protection Addendums
				with the transferee parties that comprehensively protect your data. We shall also put in place
				industry-standard technical and organizational measures (including robust data handling policies) to
				ensure that such transfers are completed in accordance with applicable laws.
			</p>
			<p>Some of the examples of where we may sub-contract processing activities to third parties include—data
				analysis, marketing assistance, processing credit card payments, and providing customer service.</p>
			<b style="color: #f57722; font-size: 16px">THIRD PARTY LINKS</b>
			<p>
				We may display links to third-party websites or applications on our Website or App for advertising or
				providing you with relevant content. We will not be responsible for such third-party websites or
				applications if you choose to access them. If you provide any data to such website/application, please
				ensure you read their policies given that you will no longer by bound by this Policy in doing so.
			</p>
			<b style="color: #f57722; font-size: 16px">COMPELLED DISCLOSURE</b>
			<p>In addition to the purposes set out in the Policy, we may disclose any data we collected or processed
				from you if it is required:</p>
			<p>Under applicable law or to respond to a legal process, such as a search warrant, court order, or
				subpoena;
				To protect our safety, your safety or the safety of others or in the legitimate interest of any party in
				the context of national security, law enforcement, litigation, criminal investigation or to prevent
				death or imminent bodily harm;
				To investigate fraud or violation of our Acceptable Use Policy;
				If required in connection with legal proceedings brought against AccessQue, its officers, employees,
				affiliates, customers or vendors; or
				To establish, exercise, protect, defend and enforce our legal rights.
			</p>
			<b style="color: #f57722; font-size: 16px">SECURITY OF YOUR PERSONAL INFORMATION</b>
			<p>We implement industry-standard technical and organizational measures by using a variety of security
				technologies and procedures to help protect your data from unauthorized access, use, loss, destruction
				or disclosure. When we collect particularly sensitive data (such as a credit card number or your
				geo-location), it is encrypted using industry-standard cryptographic techniques including but not
				limited to SSL, TLS, RSA, and AES.
			</p>
			<p>Your password is your first line of defense once you set up a AccessQue account, so we recommend that you
				set a strong password which you never share with anyone and ensure you sign out of your AccessQue
				account once you have used that same.
			</p>

			<b style="color: #f57722; font-size: 16px">CONTACT OFFICER</b>
			<p>The name and contact details of our CONTACT Officer, who you may contact if you have any concerns,
				complaints or feedback pertaining to this Policy, are as follows:</p>
			<p>
				NAME: <b>Rajesh Muni</b><br>
				ADDRESS: <b>#39, NGEF Lane, <br>2nd Floor, Suite No.673 Indiranagar,<br> Bangalore, India 560
					038</b><br>
				TEL: <b>+91-9902587664</b><br>
				EMAIL: <b>admin@avantao.com</b>
			</p>

			<b style="color: #f57722; font-size: 16px">DISPUTE RESOLUTION</b>
			<p>
				If you have an unresolved privacy or data use concern that we have not addressed satisfactorily, please
				contact our privacy Admin admin@avantao.com</p>
		</div>
		<!-- Video Section Ends -->
		<!-- Why Vidurax starts -->
	</div>
	<!-- Students outlook ends -->

	<!-- Footer starts -->
	<div class="row b-g">
		<div class="col-md-3 p-a-30">
			<img src="../../assets/images/acq-logo.png" style="width: 150px" />
			<div class="social">
				<a href="#"><img src="../../assets/images/facebook.svg" /></a>
				<a href="#"><img src="../../assets/images/youtube.svg" /></a>
				<a href="#"><img src="../../assets/images/twitter.svg" /></a>
				<a href="#"><img src="../../assets/images/instagram.svg" /></a>
				<a href="#"><img src="../../assets/images/linkedin.svg" /></a>
			</div>
		</div>
		<div class="col-md-3 p-a-30 f-link force-top-45">
			<b>Company</b>
			<a class="" href="#">About Us</a>
		</div>
		<div class="col-md-3 p-a-30 f-link force-top-45">
			<b>Policies</b>
			<a class="" href="#">Privacy Policy</a>
			<a class="" href="#">Terms & Conditions</a>
		</div>
		<div class="col-md-3 p-a-30 f-link text-end force-top-85">
			Developed by <br />
			<b>Avantao Technologies</b>
		</div>
	</div>
	<!-- Footer Ends -->

	<!-- Container div end -->
</div>