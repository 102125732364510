import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import {
  Location,
  LocationStrategy,
  HashLocationStrategy,
} from "@angular/common";
import * as shape from "d3-shape";
import * as d3Array from "d3-array";

import { Color, colorSets } from "@swimlane/ngx-charts/utils/color-sets";

import {
  formatLabel,
  escapeLabel,
} from "@swimlane/ngx-charts/common/label.helper";
import {
  single,
  multi,
  boxData,
  bubble,
  generateData,
  generateGraph,
  treemap,
  timelineFilterBarData,
  fiscalYearReport,
} from "../data";
// import { bubbleDemoData } from './custom-charts/bubble-chart-interactive/data';
import { bubbleDemoData } from "../../../custom-charts/bubble-chart-interactive/data";

import { BubbleChartInteractiveServerDataModel } from "../../../custom-charts/bubble-chart-interactive/models";
import { data as countries } from "emoji-flags";
import chartGroups from "../chartTypes";
import { barChart, lineChartSeries } from "../combo-chart-data";
// import pkg from '../../projects/swimlane/ngx-charts/package.json';
// import pkg from '../../../../projects/swimlane/ngx-charts/package.json';

import { InputTypes } from "@swimlane/ngx-ui";
import { LegendPosition } from "@swimlane/ngx-charts/common/types/legend.model";
import { ScaleType } from "@swimlane/ngx-charts/common/types/scale-type.enum";
import { DashboardReportsService } from "../dashboard-reports.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ReportsService } from "../../reports/reports.service";
import { SharedService } from "src/app/shared/shared.service";
import { MomentFormatService } from "src/app/moment-format-service";
import { each, post } from "jquery";
import { use } from "echarts";
import { forEach, toNumber } from "lodash";
import { Console } from "console";
import * as moment from "moment";
import { CreateAgent } from "../../create-agent/create-agent.service";
import { UhLeadsService } from "../../uhleads.service";
import * as XLSX from 'xlsx';
import { stringLength } from "@firebase/util";
import { ErrorHandlerService } from "src/app/error-handler.service";
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

const monthName = new Intl.DateTimeFormat("en-us", { month: "short" });
const weekdayName = new Intl.DateTimeFormat("en-us", { weekday: "short" });

function multiFormat(value) {
  if (value < 1000) return `${value.toFixed(2)}ms`;
  value /= 1000;
  if (value < 60) return `${value.toFixed(2)}s`;
  value /= 60;
  if (value < 60) return `${value.toFixed(2)}mins`;
  value /= 60;
  return `${value.toFixed(2)}hrs`;
}

const getRandomInt = (min: number, max: number) => {
  return Math.floor(Math.random() * (max - min + 1) + min);
};

@Component({
  selector: "app-dashboard-reports",
  providers: [
    Location,
    { provide: LocationStrategy, useClass: HashLocationStrategy },
  ],
  encapsulation: ViewEncapsulation.None,
  templateUrl: "./app-analytics.component.html",
  styleUrls: ["./app-analytics.component.scss"],
})
export class AppAnalyticsComponent implements OnInit {
  // APP_VERSION = pkg.version;

  inputTypes = InputTypes;

  theme = "dark";
  chartType: string;
  chartGroups: any[];
  chart: any;
  realTimeData: boolean = false;
  countries: any[];
  single: any[];
  multi: any[];
  fiscalYearReport: any[];
  dateData: any[];
  dateDataWithRange: any[];
  calendarData: any[];
  statusData: any[];
  sparklineData: any[];
  timelineFilterBarData: any[];
  graph: { links: any[]; nodes: any[] };
  bubble: any;
  linearScale: boolean = false;
  range: boolean = false;

  view: [number, number];
  width: number = 500;
  height: number = 350;
  fitContainer: boolean = false;

  // options
  showXAxis = true;
  showYAxis = true;
  gradient = false;
  showLegend = true;
  legendTitle = "Legend";
  legendPosition = LegendPosition.Right;
  showXAxisLabel = true;
  tooltipDisabled = false;
  showText = false;
  xAxisLabel = "Country";
  showYAxisLabel = true;
  yAxisLabel = "Applications Received";
  showGridLines = true;
  innerPadding = "10%";
  barPadding = 8;
  groupPadding = 16;
  roundDomains = false;
  maxRadius = 10;
  minRadius = 3;
  showSeriesOnHover = true;
  roundEdges: boolean = true;
  animations: boolean = true;
  xScaleMin: any;
  xScaleMax: any;
  yScaleMin: number;
  yScaleMax: number;
  showDataLabel: boolean = false;
  noBarWhenZero: boolean = true;
  trimXAxisTicks: boolean = true;
  trimYAxisTicks: boolean = true;
  rotateXAxisTicks: boolean = true;
  maxXAxisTickLength: number = 16;
  maxYAxisTickLength: number = 16;
  strokeColor: string = "#FFFFFF";
  strokeWidth: number = 2;

  curves = {
    Basis: shape.curveBasis,
    "Basis Closed": shape.curveBasisClosed,
    Bundle: shape.curveBundle.beta(1),
    Cardinal: shape.curveCardinal,
    "Cardinal Closed": shape.curveCardinalClosed,
    "Catmull Rom": shape.curveCatmullRom,
    "Catmull Rom Closed": shape.curveCatmullRomClosed,
    Linear: shape.curveLinear,
    "Linear Closed": shape.curveLinearClosed,
    "Monotone X": shape.curveMonotoneX,
    "Monotone Y": shape.curveMonotoneY,
    Natural: shape.curveNatural,
    Step: shape.curveStep,
    "Step After": shape.curveStepAfter,
    "Step Before": shape.curveStepBefore,
    default: shape.curveLinear,
  };

  // line interpolation
  curveType: string = "Linear";
  curve: any = this.curves[this.curveType];
  interpolationTypes = [
    "Basis",
    "Bundle",
    "Cardinal",
    "Catmull Rom",
    "Linear",
    "Monotone X",
    "Monotone Y",
    "Natural",
    "Step",
    "Step After",
    "Step Before",
  ];

  closedCurveType: string = "Linear Closed";
  closedCurve: any = this.curves[this.closedCurveType];
  closedInterpolationTypes = [
    "Basis Closed",
    "Cardinal Closed",
    "Catmull Rom Closed",
    "Linear Closed",
  ];

  colorSets: any;
  colorScheme: any;
  schemeType = ScaleType.Ordinal;
  selectedColorScheme: string;
  rangeFillOpacity: number = 0.15;

  // Override colors for certain values
  customColors: any[] = [
    {
      name: "Germany",
      value: "#a8385d",
    },
  ];

  // pie
  showLabels = true;
  explodeSlices = false;
  doughnut = false;
  arcWidth = 0.25;

  // line, area
  autoScale = true;
  timeline = false;

  // margin
  margin: boolean = false;
  marginTop: number = 40;
  marginRight: number = 40;
  marginBottom: number = 40;
  marginLeft: number = 40;

  // box
  boxData = boxData;

  // gauge
  gaugeMin: number = 0;
  gaugeMax: number = 100;
  gaugeLargeSegments: number = 10;
  gaugeSmallSegments: number = 5;
  gaugeTextValue: string = "";
  gaugeUnits: string = "alerts";
  gaugeAngleSpan: number = 240;
  gaugeStartAngle: number = -120;
  gaugeShowAxis: boolean = true;
  gaugeValue: number = 50; // linear gauge value
  gaugePreviousValue: number = 70;

  // heatmap
  heatmapMin: number = 0;
  heatmapMax: number = 50000;

  // Combo Chart
  barChart: any[] = barChart;
  lineChartSeries: any[] = lineChartSeries;
  // barChart: any[];
  // lineChartSeries: any[];
  lineChartScheme: Color = {
    name: "coolthree",
    selectable: true,
    group: ScaleType.Ordinal,
    domain: ["#01579b", "#7aa3e5", "#a8385d", "#00bfa5"],
  };

  comboBarScheme: Color = {
    name: "singleLightBlue",
    selectable: true,
    group: ScaleType.Ordinal,
    domain: ["#01579b"],
  };

  showRightYAxisLabel: boolean = true;
  yAxisLabelRight: string = "Utilization";

  // demos
  totalSales = 0;
  salePrice = 100;
  personnelCost = 100;

  mathText = "3 - 1.5*sin(x) + cos(2*x) - 1.5*abs(cos(x))";
  mathFunction: (o: any) => any;

  treemap: any[];
  treemapPath: any[] = [];
  sumBy: string = "Size";

  // bubble chart interactive demo
  bubbleDemoTempData: any[] = [];
  bubbleDemoChart: BubbleChartInteractiveServerDataModel;

  // Reference lines
  showRefLines: boolean = true;
  showRefLabels: boolean = true;

  // Supports any number of reference lines.
  refLines = [
    { value: 42500, name: "Maximum" },
    { value: 37750, name: "Average" },
    { value: 33000, name: "Minimum" },
  ];

  // data
  plotData: any;

  // Sidebar Controls:
  colorVisible: boolean = true;
  dataVisible: boolean = true;
  dimVisible: boolean = true;
  optsVisible: boolean = true;

  //Filters Data
  userData: any;
  filtersData: any;

  //data for charts
  statusCounts: any;
  completedStatus: any;
  assignedCounts: any;
  courseCounts: any;
  projectId: any;
  combochartData: {
    barChart: any[];
    lineChart: [{ name: "Completed"; series: any[] }];
  };
  barChartCombo: any = [];
  barChartCombo2: any = [
    { name: "Completed", series: [] },
    { name: "Pending", series: [] },
  ];

  lineChartCombo = [{ name: "Completed", series: [] }];
  lineChartCalls = [{ name: "Calls", series: [] }];
  inboundOutbound = [];
  totalAgents: any[];
  GBPagentList: any;
  topPerformers: any;
  incomingVsOutgoing: any[];
  callStatusCounts: any[];
  top10Data: any[];
  top10Validation: any[];

  constructor(
    public location: Location,
    private ReportsService: DashboardReportsService,
    private ReportService2: ReportsService,
    private sharedService: SharedService,
    private momentFormatService: MomentFormatService,
    private agentService: CreateAgent,
    private uhleadsservice: UhLeadsService,
    private handleError: ErrorHandlerService,
    private fb: FormBuilder
  ) {
    this.mathFunction = this.getFunction();

    Object.assign(this, {
      single,
      multi,
      countries,
      chartGroups,
      colorSets,
      graph: generateGraph(50),
      boxData,
      bubble,
      plotData: this.generatePlotData(),
      treemap,
      bubbleDemoData,
      fiscalYearReport,
    });

    // interactive drill down demos
    this.treemapProcess();
    this.bubbleDemoChart = new BubbleChartInteractiveServerDataModel();
    this.bubbleDemoProcess(bubbleDemoData[0]);

    this.dateData = generateData(5, false);
    this.dateDataWithRange = generateData(2, true);
    this.setColorScheme("cool");
    this.calendarData = this.getCalendarData();
    this.statusData = this.getStatusData();
    this.sparklineData = generateData(1, false, 30);
    this.timelineFilterBarData = timelineFilterBarData();
  }

  get dateDataWithOrWithoutRange() {
    if (this.range) {
      return this.dateDataWithRange;
    } else {
      return this.dateData;
    }
  }

  ngOnInit() {
    const state = this.location.path(true);
    // this.selectChart(state.length ? state : 'bar-vertical');

    this.sharedService.pageEvent.emit({
      pageName: "Aplication Analytics",
    });

    this.userData = JSON.parse(localStorage.getItem("userData") || "{}");
    // 


    this.ReportService2.projectId.subscribe((data) => {
      // 
      if (data != null) {
        this.projectId = data;


        this.agentService.getAgentNames().subscribe((data) => {
          this.totalAgents = data;
          this.uhleadsservice.getGBPAgentList().subscribe((res: any) => {
            this.GBPagentList = res;
            for (let i = 0; i < this.GBPagentList.length; i++) {
              this.totalAgents.push({
                FirstName: this.GBPagentList[i].FIRST_NAME,
                LastName: this.GBPagentList[i].LAST_NAME,
                Mailid: this.GBPagentList[i].EMAIL_ADDRESS,
                ProfilePhoto: this.GBPagentList[i].ProfilePhoto,
              });
            }

            this.filtersData = {
              course: "All",
              agentEmail: "All",
              from: this.momentFormatService.dashboardDateFormat(
                new Date(new Date(Date.now() - 864e5 * 365)).getTime()
              ),
              to: this.momentFormatService.dashboardDateFormat(
                new Date(new Date(Date.now() + 864e5))
              ),
              enrollmentYear: "All",
              enrollmentTerm: "All",
              userId: this.userData.id,
              token: this.userData.token,
              platform: "CRM",
              ProjectId: this.projectId,
              application: "All",
            };
            // 
            // this.getDefaultData(postData);

            setInterval(this.updateData.bind(this), 1000);

            if (!this.fitContainer) {
              this.applyDimensions();
            }

            this.ReportService2.filterData.subscribe((filters: any) => {
              // 
              if (filters) {
                this.filtersData = filters;

                this.getIncomingCompleted();
                this.getStatusCounts();
                this.getAssignedCounts();
                this.getCourseCounts();
                this.getTopPerformers();
                this.getPendingCounts();
                // this.getCallsPerDay();
                // this.getCallsInboundOutbound();
                // this.getCallsInVsOut();
                // this.getCallStatus();
                this.gettop10TimeTaken();
                this.gettop10ValidationTime();
              }
            });

            this.getStatusCounts();
            this.getAssignedCounts();
            this.getCourseCounts();
            this.getIncomingCompleted();
            this.getTopPerformers();
            this.getPendingCounts();
            // this.getCallsPerDay();
            // this.getCallsInboundOutbound();
            // this.getCallsInVsOut();
            // this.getCallStatus();
            this.gettop10TimeTaken();
            this.gettop10ValidationTime();
          }, (error) => {
            this.handleError.handleError(error)
          });
        }, (error) => {
          this.handleError.handleError(error)
        });

      }
    });



    // this.getAgentList();
  }

  getAgentList() {
    throw new Error("Method not implemented.");
  }

  getStatusCounts() {
    this.ReportsService.getStatusCounts(this.filtersData).subscribe(
      (res: any) => {
        this.statusCounts = [];
        // 
        let pieData = [];
        let pending: number = 0;
        let completed: number = 0;
        res.data.forEach((element) => {
          pieData.push({
            name: this.capitalizeFirstLetter(element.document_status),
            value: element.document_count,
          });
          if (
            element.document_status == "completed" ||
            element.document_status == "denied"
          ) {
            completed = completed + Number(element.document_count);
          } else {
            pending = pending + Number(element.document_count);
          }
        });

        this.completedStatus = [
          {
            name: "Completed",
            value: completed,
          },
          {
            name: "Pending",
            value: pending,
          },
        ];
        this.statusCounts = pieData;
      }, (error) => {
        this.handleError.handleError(error)
      });
  }

  getAssignedCounts() {
    this.ReportsService.getAssignedCounts(this.filtersData).subscribe(
      (res: any) => {
        this.assignedCounts = [];
        let pieData = [];
        res.data.forEach((element) => {
          pieData.push({ name: element.agent, value: element.document_count });
        });

        for (let i = 0; i < pieData.length; i++) {
          if (pieData[i].name != "Unassigned") {
            if (this.getAgentName(pieData[i].name)) {
              pieData[i].name = this.getAgentName(pieData[i].name);
            }
            // pieData[i].name = this.getAgentName(pieData[i].name)
          }
        }

        this.assignedCounts = pieData;
      }, (error) => {
        this.handleError.handleError(error)
      });
  }

  getCourseCounts() {
    this.ReportsService.getCourseCounts(this.filtersData).subscribe(
      (res: any) => {
        this.courseCounts = [];
        // this.courseCounts = res.data;
        let pieData = [];
        res.data.forEach((element) => {
          pieData.push({
            name: element.Program,
            value: element.count_of_applications,
          });
        });

        this.courseCounts = pieData;
        this.courseCounts.sort(function (a, b) {
          return a.count_of_applications - b.count_of_applications;
        });
        // this.courseCounts.reverse();
      }, (error) => {
        this.handleError.handleError(error)
      });
  }

  getTopPerformers() {
    this.ReportsService.getTopPerformers(this.filtersData).subscribe(
      (res: any) => {
        this.topPerformers = [];
        let gaugeData = [];
        let max = 0;
        res.data.forEach((element) => {
          gaugeData.push({
            name: element.agent,
            value: element.document_count,
          });
          max = max + element.document_count;
        });

        for (let i = 0; i < gaugeData.length; i++) {
          if (this.getAgentName(gaugeData[i].name)) {
            gaugeData[i].name = this.getAgentName(gaugeData[i].name);
          }
        }
        this.gaugeMax = Math.ceil(gaugeData[0].value / 10) * 10;
        this.topPerformers = gaugeData;
      }, (error) => {
        this.handleError.handleError(error)
      });
  }

  getPendingCounts() {
    // this.ReportsService.getPendingCounts(this.filtersData).subscribe(
    //   (res: any) => {
    //     // 
    //   }
    // );
  }

  getIncomingCompleted() {
    // this.ReportsService.getIncomingCompleted2(this.filtersData).subscribe((res: any)=>{
    //   
    // })
    this.ReportsService.getIncomingCompleted(this.filtersData).subscribe(
      (res: any) => {
        // this.ReportsService.getPendingCounts(this.filtersData).subscribe((res2:any)=>{
        //   
        // 
        this.barChartCombo = [];
        // this.barChartCombo2 = [{ name: "Completed", series: [] },{ name: "Pending", series: [] }];

        this.lineChartCombo = [{ name: "Completed", series: [] }];
        const d1 = new Date(this.filtersData.from);
        // d1.setDate(d1.getDate() - 1);
        const d2 = new Date(this.filtersData.to);

        let allDates = this.getDatesInRange(d1, d2);
        // 
        let check = false;
        for (let i = 0; i < allDates.length; i++) {
          check = false;
          for (let j = 0; j < res.data.length; j++) {
            if (
              allDates[i] == res.data[j].incoming_date ||
              allDates[i] == res.data[j].close_date
            ) {
              check = true;
              this.barChartCombo.push({
                name: allDates[i],
                value: toNumber(res.data[j].incoming_count),
              });

              this.lineChartCombo[0].series.push({
                name: allDates[i],
                value: toNumber(res.data[j].processed_count),
              });
            }
          }
          if (check == false) {
            this.barChartCombo.push({
              name: allDates[i],
              value: 0,
            });

            this.lineChartCombo[0].series.push({
              name: allDates[i],
              value: 0,
            });
          }
        }

        if (allDates.length > 500) {
          // 

          for (let i = 0; i < this.barChartCombo.length; i++) {
            let d = new Date(this.barChartCombo[i].name);
            //  
            // 
            this.barChartCombo[i].name = moment(
              this.barChartCombo[i].name
            ).format("YYYY");
            this.lineChartCombo[0].series[i].name = moment(
              this.lineChartCombo[0].series[i].name
            ).format("YYYY");
          }
          let temp = this.barChartCombo;
          let temp2: any[] = [];
          let temp3 = this.lineChartCombo;
          let temp4 = [{ name: "Completed", series: [] }];

          const years = [
            ...new Set(this.barChartCombo.map((item) => item.name)),
          ];
          // 

          for (let i = 0; i < years.length; i++) {
            temp2.push({
              name: years[i],
              value: 0,
            });
            for (let j = 0; j < temp.length; j++) {
              if (years[i] == temp[j].name) {
                temp2[i].value = temp2[i].value + temp[j].value;
              }
            }
          }

          for (let i = 0; i < years.length; i++) {
            temp4[0].series.push({
              name: years[i],
              value: 0,
            });

            for (let j = 0; j < temp3[0].series.length; j++) {
              if (years[i] == temp3[0].series[j].name) {
                temp4[0].series[i].value =
                  temp4[0].series[i].value + temp3[0].series[j].value;
              }
            }
          }
          // 
          // 
          this.barChart = temp2;
          this.lineChartSeries = temp4;
        } else if (allDates.length > 300) {
          // 
          for (let i = 0; i < this.barChartCombo.length; i++) {
            let d = new Date(this.barChartCombo[i].name);
            //  
            // 
            this.barChartCombo[i].name = moment(
              this.barChartCombo[i].name
            ).format("MMM YY");
            this.lineChartCombo[0].series[i].name = moment(
              this.lineChartCombo[0].series[i].name
            ).format("MMM YY");
          }
          let temp = this.barChartCombo;
          let temp2: any[] = [];
          let temp3 = this.lineChartCombo;
          let temp4 = [{ name: "Completed", series: [] }];

          const years = [
            ...new Set(this.barChartCombo.map((item) => item.name)),
          ];
          // 

          for (let i = 0; i < years.length; i++) {
            temp2.push({
              name: years[i],
              value: 0,
            });
            for (let j = 0; j < temp.length; j++) {
              if (years[i] == temp[j].name) {
                temp2[i].value = temp2[i].value + temp[j].value;
              }
            }
          }

          for (let i = 0; i < years.length; i++) {
            temp4[0].series.push({
              name: years[i],
              value: 0,
            });

            for (let j = 0; j < temp3[0].series.length; j++) {
              if (years[i] == temp3[0].series[j].name) {
                temp4[0].series[i].value =
                  temp4[0].series[i].value + temp3[0].series[j].value;
              }
            }
          }
          // 
          // 
          this.barChart = temp2;
          this.lineChartSeries = temp4;
        } else if (allDates.length > 30) {
          // 
          for (let i = allDates.length - 1; i > 0; i--) {
            for (let j = i - 1; j > i - 7; j--) {
              allDates[j] = allDates[i];
            }
            i = i - 6;
          }

          for (let i = 0; i < this.barChartCombo.length; i++) {
            this.barChartCombo[i].name = allDates[i];
          }

          const dates = [
            ...new Set(this.barChartCombo.map((item) => item.name)),
          ];

          let temp = this.barChartCombo;
          let temp2: any[] = [];
          let temp3 = this.lineChartCombo;
          let temp4 = [{ name: "Completed", series: [] }];

          for (let i = 0; i < dates.length; i++) {
            temp2.push({
              name: dates[i],
              value: 0,
            });
            for (let j = 0; j < temp.length; j++) {
              if (dates[i] == temp[j].name) {
                temp2[i].value = temp2[i].value + temp[j].value;
              }
            }
          }

          for (let i = 0; i < dates.length; i++) {
            temp4[0].series.push({
              name: dates[i],
              value: 0,
            });

            for (let j = 0; j < temp3[0].series.length; j++) {
              if (dates[i] == temp3[0].series[j].name) {
                temp4[0].series[i].value =
                  temp4[0].series[i].value + temp3[0].series[j].value;
              }
            }
          }

          this.barChart = temp2;
          this.lineChartSeries = temp4;
        } else {
          this.barChart = this.barChartCombo;
          this.lineChartSeries = this.lineChartCombo;
        }

        // 
        // 

        // 
        // 

        // });
      }, (error) => {
        this.handleError.handleError(error)
      });
  }

  getCallsPerDay() {
    this.ReportsService.getCallsPerDay(this.filtersData).subscribe(
      (res: any) => {
        // 

        this.lineChartCalls = [{ name: "Calls", series: [] }];
        const d1 = new Date(this.filtersData.from);
        const d2 = new Date(this.filtersData.to);

        let allDates = this.getDatesInRange(d1, d2);
        let check = false;
        for (let i = 0; i < allDates.length; i++) {
          check = false;
          for (let j = 0; j < res.data.length; j++) {
            if (allDates[i] == res.data[j].createdAt) {
              check = true;
              this.lineChartCalls[0].series.push({
                name: allDates[i],
                value: toNumber(res.data[j].count),
              });
            }
          }
          if (check == false) {
            this.lineChartCalls[0].series.push({
              name: allDates[i],
              value: 0,
            });
          }
        }

        if (allDates.length > 500) {
          // 

          for (let i = 0; i < this.lineChartCalls[0].series.length; i++) {
            let d = new Date(this.lineChartCalls[0].series[i].name);

            this.lineChartCalls[0].series[i].name = moment(
              this.lineChartCalls[0].series[i].name
            ).format("YYYY");
          }
          let temp3 = this.lineChartCalls;
          let temp4 = [{ name: "Calls", series: [] }];

          const years = [
            ...new Set(this.lineChartCalls[0].series.map((item) => item.name)),
          ];

          for (let i = 0; i < years.length; i++) {
            temp4[0].series.push({
              name: years[i],
              value: 0,
            });

            for (let j = 0; j < temp3[0].series.length; j++) {
              if (years[i] == temp3[0].series[j].name) {
                temp4[0].series[i].value =
                  temp4[0].series[i].value + temp3[0].series[j].value;
              }
            }
          }

          this.lineChartCalls = temp4;
        } else if (allDates.length > 300) {
          // 
          for (let i = 0; i < this.lineChartCalls[0].series.length; i++) {
            let d = new Date(this.lineChartCalls[0].series[i].name);

            this.lineChartCalls[0].series[i].name = moment(
              this.lineChartCalls[0].series[i].name
            ).format("MMM YY");
          }

          let temp3 = this.lineChartCalls;
          let temp4 = [{ name: "Calls", series: [] }];

          const years = [
            ...new Set(this.lineChartCalls[0].series.map((item) => item.name)),
          ];

          for (let i = 0; i < years.length; i++) {
            temp4[0].series.push({
              name: years[i],
              value: 0,
            });

            for (let j = 0; j < temp3[0].series.length; j++) {
              if (years[i] == temp3[0].series[j].name) {
                temp4[0].series[i].value =
                  temp4[0].series[i].value + temp3[0].series[j].value;
              }
            }
          }

          this.lineChartCalls = temp4;
        } else if (allDates.length > 30) {
          // 
          for (let i = allDates.length - 1; i > 0; i--) {
            for (let j = i - 1; j > i - 7; j--) {
              allDates[j] = allDates[i];
            }
            i = i - 6;
          }

          for (let i = 0; i < this.lineChartCalls[0].series.length; i++) {
            this.lineChartCalls[0].series[i].name = allDates[i];
          }

          const dates = [
            ...new Set(this.lineChartCalls[0].series.map((item) => item.name)),
          ];
          let temp3 = this.lineChartCalls;
          let temp4 = [{ name: "Calls", series: [] }];

          for (let i = 0; i < dates.length; i++) {
            temp4[0].series.push({
              name: dates[i],
              value: 0,
            });

            for (let j = 0; j < temp3[0].series.length; j++) {
              if (dates[i] == temp3[0].series[j].name) {
                temp4[0].series[i].value =
                  temp4[0].series[i].value + temp3[0].series[j].value;
              }
            }
          }

          this.lineChartCalls = temp4;
        } else {
          //Empty Condition
        }
      }, (error) => {
        this.handleError.handleError(error)
      });
  }

  // getCallsInboundOutboundOld() {
  //   this.ReportsService.getCallsInboundOutbound(this.filtersData).subscribe(
  //     (res: any) => {
  //       

  //       this.inboundOutbound = [{name:"OutGoing", series: []},{name:"InComing", series: []}];

  //       const d1 = new Date(this.filtersData.from);
  //       const d2 = new Date(this.filtersData.to);

  //       let allDates = this.getDatesInRange(d1, d2);
  //       let check = false;

  //       for(let k = 0; k < 2; k++){
  //         for (let i = 0; i < allDates.length; i++) {
  //           check = false;
  //           for (let j = 0; j < res.data[k].length; j++) {
  //             if (allDates[i] == res.data[k][j].createdAt) {
  //               check = true;
  //               this.inboundOutbound[k].series.push({
  //                 name: allDates[i],
  //                 value: toNumber(res.data[k][j].count),
  //               });
  //             }
  //           }
  //           if (check == false) {
  //             this.inboundOutbound[k].series.push({
  //               name: allDates[i],
  //               value: 0,
  //             });
  //           }
  //         }
  //       }
  //       

  //       if (allDates.length > 500) {
  //         // 
  //         let temp3 = this.inboundOutbound;
  //           let temp4 = [
  //             { name: "OutGoing", series: [] },
  //             { name: "InComing", series: [] },
  //           ];

  //         for(let k=0; k<2;k++){

  //           for (let i = 0; i < this.inboundOutbound[k].series.length; i++) {
  //             let d = new Date(this.inboundOutbound[k].series[i].name);

  //             this.inboundOutbound[k].series[i].name = moment(
  //               this.inboundOutbound[k].series[i].name
  //             ).format("YYYY");
  //           }

  //           const years = [
  //             ...new Set(
  //               this.inboundOutbound[k].series.map((item) => item.name)
  //             ),
  //           ];

  //           for (let i = 0; i < years.length; i++) {
  //             temp4[k].series.push({
  //               name: years[i],
  //               value: 0,
  //             });

  //             for (let j = 0; j < temp3[k].series.length; j++) {
  //               if (years[i] == temp3[k].series[j].name) {
  //                 temp4[k].series[i].value =
  //                   temp4[k].series[i].value + temp3[k].series[j].value;
  //               }
  //             }

  //           }
  //           this.inboundOutbound[k] = temp4[k]
  //         }

  //       }else if (allDates.length > 300) {
  //         // 
  //         let temp3 = this.inboundOutbound;
  //           let temp4 = [
  //             { name: "OutGoing", series: [] },
  //             { name: "InComing", series: [] },
  //           ];

  //         for(let k=0; k<2;k++){

  //           for (let i = 0; i < this.inboundOutbound[k].series.length; i++) {
  //             let d = new Date(this.inboundOutbound[k].series[i].name);

  //             this.inboundOutbound[k].series[i].name = moment(
  //               this.inboundOutbound[k].series[i].name
  //             ).format("MMM YY");
  //           }

  //           const years = [
  //             ...new Set(
  //               this.inboundOutbound[k].series.map((item) => item.name)
  //             ),
  //           ];

  //           for (let i = 0; i < years.length; i++) {
  //             temp4[k].series.push({
  //               name: years[i],
  //               value: 0,
  //             });

  //             for (let j = 0; j < temp3[k].series.length; j++) {
  //               if (years[i] == temp3[k].series[j].name) {
  //                 temp4[k].series[i].value =
  //                   temp4[k].series[i].value + temp3[k].series[j].value;
  //               }
  //             }

  //           }
  //           this.inboundOutbound[k] = temp4[k]
  //         }

  //       } else if(allDates.length > 30){

  //         let temp3 = this.inboundOutbound;
  //           let temp4 = [
  //             { name: "OutGoing", series: [] },
  //             { name: "InComing", series: [] },
  //           ];

  //         for (let i = allDates.length - 1; i > 0; i--) {
  //           for (let j = i - 1; j > i - 7; j--) {
  //             allDates[j] = allDates[i];
  //           }
  //           i = i - 6;
  //         }

  //         for (let k = 0; k < 2; k++) {
  //           for (let i = 0; i < this.inboundOutbound[k].series.length; i++) {
  //             this.inboundOutbound[k].series[i].name = allDates[i];
  //           }
  //           const dates = [
  //             ...new Set(this.inboundOutbound[0].series.map((item) => item.name)),
  //           ];
  //           for (let i = 0; i < dates.length; i++) {
  //             temp4[k].series.push({
  //               name: dates[i],
  //               value: 0,
  //             });

  //             for (let j = 0; j < temp3[k].series.length; j++) {
  //               if (dates[i] == temp3[k].series[j].name) {
  //                 temp4[k].series[i].value =
  //                   temp4[k].series[i].value + temp3[k].series[j].value;
  //               }
  //             }
  //           }
  //           this.inboundOutbound[k] = temp4[k];
  //         }

  //         // this.inboundOutbound = temp4;

  //       }

  //       

  //     }
  //   );
  // }
  getCallsInboundOutbound() {
    this.ReportsService.getCallsInboundOutbound(this.filtersData).subscribe(
      (res: any) => {
        this.inboundOutbound = [
          { name: "OutGoing", series: [] },
          { name: "InComing", series: [] },
        ];

        const d1 = new Date(this.filtersData.from);
        const d2 = new Date(this.filtersData.to);

        let allDates = this.getDatesInRange(d1, d2);
        let check = false;
        let chartData = [];

        for (let k = 0; k < 2; k++) {
          for (let i = 0; i < allDates.length; i++) {
            check = false;
            for (let j = 0; j < res.data[k].length; j++) {
              if (allDates[i] == res.data[k][j].createdAt) {
                check = true;
                this.inboundOutbound[k].series.push({
                  name: allDates[i],
                  value: toNumber(res.data[k][j].count),
                });
              }
            }
            if (check == false) {
              this.inboundOutbound[k].series.push({
                name: allDates[i],
                value: 0,
              });
            }
          }
        }

        if (allDates.length > 300) {
          for (let i = 0; i < this.inboundOutbound[0].series.length; i++) {
            let d = new Date(this.inboundOutbound[0].series[i].name);

            this.inboundOutbound[0].series[i].name = moment(
              this.inboundOutbound[0].series[i].name
            ).format("YYYY");
          }

          const years = [
            ...new Set(this.inboundOutbound[0].series.map((item) => item.name)),
          ];

          for (let i = 0; i < years.length; i++) {
            let outbound = 0;
            let inbound = 0;

            for (let j = 0; j < allDates.length; j++) {
              if (years[i] == this.inboundOutbound[0].series[j].name) {
                outbound = outbound + this.inboundOutbound[0].series[j].value;
                inbound = inbound + this.inboundOutbound[1].series[j].value;
              }
            }

            chartData.push({
              name: years[i],
              series: [
                {
                  name: "outbound",
                  value: outbound,
                },
                {
                  name: "inbound",
                  value: inbound,
                },
              ],
            });
          }
        } else if (allDates.length > 150) {
          for (let i = 0; i < this.inboundOutbound[0].series.length; i++) {
            let d = new Date(this.inboundOutbound[0].series[i].name);

            this.inboundOutbound[0].series[i].name = moment(
              this.inboundOutbound[0].series[i].name
            ).format("MMM YY");
          }

          const years = [
            ...new Set(this.inboundOutbound[0].series.map((item) => item.name)),
          ];

          for (let i = 0; i < years.length; i++) {
            let outbound = 0;
            let inbound = 0;

            for (let j = 0; j < allDates.length; j++) {
              if (years[i] == this.inboundOutbound[0].series[j].name) {
                outbound = outbound + this.inboundOutbound[0].series[j].value;
                inbound = inbound + this.inboundOutbound[1].series[j].value;
              }
            }

            chartData.push({
              name: years[i],
              series: [
                {
                  name: "outbound",
                  value: outbound,
                },
                {
                  name: "inbound",
                  value: inbound,
                },
              ],
            });
          }
        } else if (allDates.length > 10) {
          for (let i = allDates.length - 1; i > 0; i--) {
            for (let j = i - 1; j > i - 7; j--) {
              allDates[j] = allDates[i];
            }
            i = i - 6;
          }

          for (let i = 0; i < this.inboundOutbound[0].series.length; i++) {
            this.inboundOutbound[0].series[i].name = moment(allDates[i]).format(
              "DD MMM"
            );
            this.inboundOutbound[1].series[i].name = moment(allDates[i]).format(
              "DD MMM"
            );
          }

          const years = [
            ...new Set(this.inboundOutbound[0].series.map((item) => item.name)),
          ];

          for (let i = 0; i < years.length; i++) {
            let outbound = 0;
            let inbound = 0;

            for (let j = 0; j < allDates.length; j++) {
              if (years[i] == this.inboundOutbound[0].series[j].name) {
                outbound = outbound + this.inboundOutbound[0].series[j].value;
                inbound = inbound + this.inboundOutbound[1].series[j].value;
              }
            }

            chartData.push({
              name: years[i],
              series: [
                {
                  name: "outbound",
                  value: outbound,
                },
                {
                  name: "inbound",
                  value: inbound,
                },
              ],
            });
          }
        } else {
          for (let i = 0; i < this.inboundOutbound[0].series.length; i++) {
            this.inboundOutbound[0].series[i].name = moment(allDates[i]).format(
              "DD MMM"
            );
            this.inboundOutbound[1].series[i].name = moment(allDates[i]).format(
              "DD MMM"
            );
          }

          const years = [
            ...new Set(this.inboundOutbound[0].series.map((item) => item.name)),
          ];

          for (let i = 0; i < years.length; i++) {
            let outbound = 0;
            let inbound = 0;

            for (let j = 0; j < allDates.length; j++) {
              if (years[i] == this.inboundOutbound[0].series[j].name) {
                outbound = outbound + this.inboundOutbound[0].series[j].value;
                inbound = inbound + this.inboundOutbound[1].series[j].value;
              }
            }

            chartData.push({
              name: years[i],
              series: [
                {
                  name: "outbound",
                  value: outbound,
                },
                {
                  name: "inbound",
                  value: inbound,
                },
              ],
            });
          }
        }

        this.inboundOutbound = chartData;
      }, (error) => {
        this.handleError.handleError(error)
      });
  }

  getCallsInVsOut() {
    this.ReportsService.getCallsInVsOut(this.filtersData).subscribe(
      (res: any) => {
        this.incomingVsOutgoing = [];

        for (let i = 0; i < res.data.length; i++) {
          this.incomingVsOutgoing.push({
            name: this.capitalizeFirstLetter(res.data[i].Direction),
            value: res.data[i].count,
          });
        }
      }, (error) => {
        this.handleError.handleError(error)
      });
  }

  getCallStatus() {
    this.ReportsService.getCallStatus(this.filtersData).subscribe(
      (res: any) => {
        this.callStatusCounts = [];
        for (let i = 0; i < res.data.length; i++) {
          this.callStatusCounts.push({
            name: this.capitalizeFirstLetter(res.data[i].Call_status),
            value: res.data[i].count,
          });
        }
      }, (error) => {
        this.handleError.handleError(error)
      });
  }

  gettop10TimeTaken() {
    this.ReportsService.getProcessingtimeMax10(this.filtersData).subscribe((res: any) => {
      if (res.data) {
        for (let i = 0; i < res.data.length; i++) {
          res.data[i].Assignedto = this.getAgentName(res.data[i].Assignedto);
          res.data[i].GBPAssignedto = this.getAgentName(res.data[i].GBPAssignedto);
        }
        this.top10Data = res.data;
      }
    }, (error) => {
      this.handleError.handleError(error)
    });
  }

  gettop10ValidationTime() {
    this.ReportsService.getValidationtimeMax10(this.filtersData).subscribe((res: any) => {
      if (res.data) {
        for (let i = 0; i < res.data.length; i++) {
          res.data[i].Assignedto = this.getAgentName(res.data[i].Assignedto);
          res.data[i].GBPAssignedto = this.getAgentName(res.data[i].GBPAssignedto);
        }
        this.top10Validation = res.data;
      }
    }, (error) => {
      this.handleError.handleError(error)
    });
  }

  GetSortOrder(prop) {
    return function (a, b) {
      if (a[prop] > b[prop]) {
        return 1;
      } else if (a[prop] < b[prop]) {
        return -1;
      }
      return 0;
    };
  }

  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  getDatesInRange(startDate, endDate) {
    const date = new Date(startDate.getTime());

    // ✅ Exclude start date
    date.setDate(date.getDate() + 1);

    const dates = [];

    // ✅ Exclude end date
    while (date <= endDate) {
      dates.push(this.formatDate(new Date(date)));
      date.setDate(date.getDate() + 1);
    }

    return dates;
  }

  formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }

  getAgentNames() {
    this.agentService.getAgentNames().subscribe((data) => {
      this.totalAgents = data;

      this.uhleadsservice.getGBPAgentList().subscribe((res: any) => {
        this.GBPagentList = res;
        for (let i = 0; i < this.GBPagentList.length; i++) {
          this.totalAgents.push({
            FirstName: this.GBPagentList[i].FIRST_NAME,
            LastName: this.GBPagentList[i].LAST_NAME,
            Mailid: this.GBPagentList[i].EMAIL_ADDRESS,
            ProfilePhoto: this.GBPagentList[i].ProfilePhoto,
          });
        }

      }, (error) => {
        this.handleError.handleError(error)
      });
      // this.totalAgents.push({FirstName: "Test", LastName: "Test", MailId: "test@test.com", ProfilePhote:"test.com"})
    }, (error) => {
      this.handleError.handleError(error)
    });
  }

  getAgentName(email) {
    let name = "";
    if (this.totalAgents) {
      for (var k = 0; k < this.totalAgents.length; k++) {
        if (this.totalAgents[k].Mailid == email) {
          name =
            this.totalAgents[k].FirstName + " " + this.totalAgents[k].LastName;
          return name;
        }
      }
    }
  }

  leadsData() {
    this.ReportsService.getStatusCountsData(this.filtersData).subscribe((res: any) => {
      // 

      var fd = res.data.map(x => {
        var a = '';
        a = x?.Assignedto || 'Unassigned';
        var Assignedto = a;

        const { GUID, FirstName, LastName, Program, EnrollmentTerm, EnrollmentYear, createdAt, updatedAt, LeadDocStatus, CallStatus } = x;
        return { GUID, FirstName, LastName, Program, Assignedto, EnrollmentTerm, EnrollmentYear, createdAt, updatedAt, LeadDocStatus, CallStatus };
      })

      this.downloadFile(fd, 'LeadsData');
    }, (error) => {
      this.handleError.handleError(error)
    });
  }

  incomingVsCompletedData() {
    this.ReportsService.getIncomingCompletedData(this.filtersData).subscribe((res: any) => {
      // 

      var fd = res.data.map(x => {
        var a = '';
        a = x?.Assignedto || 'Unassigned';
        var Assignedto = a;

        const { GUID, FirstName, LastName, Program, EnrollmentYear, EnrollmentTerm, LeadDocStatus, ClosedDate, ClosedBy, createdAt, CallStatus } = x;
        return { GUID, FirstName, LastName, Program, Assignedto, EnrollmentYear, EnrollmentTerm, LeadDocStatus, ClosedDate, ClosedBy, createdAt, CallStatus };
      })

      this.downloadFile(fd, 'LeadsData');
    }, (error) => {
      this.handleError.handleError(error)
    });
  }

  callsData() {
    this.ReportsService.getCallsPerDayData(this.filtersData).subscribe((res: any) => {
      // 

      var fd = res.data.map(x => {
        var a = '';
        a = x?.Assignedto || 'Unassigned';
        var Assignedto = a;

        const { FirstName, LastName, CallerNumber, CallerCity, CallerCountry, CallerState, Direction, CalledNumber, CalledCity, CalledCountry, CalledState, RecordingDuration, RecordingURL, CallAnswered, createdAt, updatedAt, LeadId, Comments, Status, LeadType } = x;
        return { FirstName, LastName, Assignedto, CallerNumber, CallerCity, CallerCountry, CallerState, Direction, CalledNumber, CalledCity, CalledCountry, CalledState, RecordingDuration, RecordingURL, CallAnswered, createdAt, updatedAt, LeadId, Comments, Status, LeadType };
      })

      this.downloadFile(fd, 'CallsData');
    }, (error) => {
      this.handleError.handleError(error)
    });
  }

  top10CompletionData() {
    this.ReportsService.getProcessingtimeMax10Data(this.filtersData).subscribe((res: any) => {

      var fd = res.data.map(x => {
        var a = '';
        a = x?.Assignedto || 'Unassigned';
        var Assignedto = a;

        var b = '';

        if (x.Time_taken_to_close_An_application.days == undefined) {
          x.Time_taken_to_close_An_application.days = 0
        }
        if (x.Time_taken_to_close_An_application.hours == undefined) {
          x.Time_taken_to_close_An_application.hours = 0
        } if (x.Time_taken_to_close_An_application.minutes == undefined) {
          x.Time_taken_to_close_An_application.minutes = 0
        }
        b = x.Time_taken_to_close_An_application.days + ' d ' + x.Time_taken_to_close_An_application.hours + ' h ' + + x.Time_taken_to_close_An_application.minutes + ' m ';
        var CompletionTime = b

        const { GUID, FirstName, LastName, Email, EnrollmentYear, EnrolmentTerm, createdAt, updatedAt, ClosedDate, ClosedBy, Program, LeadDocStatus } = x;
        return { GUID, FirstName, LastName, Email, EnrollmentYear, EnrolmentTerm, Assignedto, createdAt, updatedAt, ClosedDate, ClosedBy, Program, LeadDocStatus, CompletionTime };
      })

      this.downloadFile(fd, 'Completion_Time');
    }, (error) => {
      this.handleError.handleError(error)
    });
  }

  top10ValidationData() {
    this.ReportsService.getValidationtimeMax10Data(this.filtersData).subscribe((res: any) => {

      var fd = res.data.map(x => {
        var a = '';
        a = x?.Assignedto || 'Unassigned';
        var Assignedto = a;

        var b = '';
        if (x.total_time_till_validation.days == undefined) {
          x.total_time_till_validation.days = 0
        }
        if (x.total_time_till_validation.hours == undefined) {
          x.total_time_till_validation.hours = 0
        } if (x.total_time_till_validation.minutes == undefined) {
          x.total_time_till_validation.minutes = 0
        }
        b = x.total_time_till_validation.days + ' d ' + x.total_time_till_validation.hours + ' h ' + x.total_time_till_validation.minutes + ' m ';
        var ValidationTime = b

        const { GUID, FirstName, LastName, Email, EnrollmentYear, EnrolmentTerm, createdAt, updatedAt, ClosedDate, ClosedBy, Program, LeadDocStatus } = x;
        return { GUID, FirstName, LastName, Email, EnrollmentYear, EnrolmentTerm, Assignedto, createdAt, updatedAt, ClosedDate, ClosedBy, Program, LeadDocStatus, ValidationTime };
      })

      this.downloadFile(fd, 'Validtion_Time');
    }, (error) => {
      this.handleError.handleError(error)
    });
  }

  downloadFile(filedata, name) {

    var json = filedata;

    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    var a = document.createElement("a");
    a.setAttribute('style', 'display:none;');
    document.body.appendChild(a);
    var blob = new Blob([excelBuffer], { type: EXCEL_TYPE });
    var url = window.URL.createObjectURL(blob);
    a.href = url;
    var x: Date = new Date();
    var link: string = name + "-" + x.getFullYear() + "-" + x.getMonth() + "-" + x.getDay() + '.xlsx';
    a.download = link;
    a.click();
    this.uhleadsservice.showSuccess('Download Successful!', '')
  }

  updateData() {
    if (!this.realTimeData) {
      return;
    }

    this.gaugeValue =
      this.gaugeMin +
      Math.floor(Math.random() * (this.gaugeMax - this.gaugeMin));

    const country =
      this.countries[Math.floor(Math.random() * this.countries.length)];
    const add = Math.random() < 0.7;
    const remove = Math.random() < 0.5;

    if (remove) {
      if (this.single.length > 1) {
        const index = Math.floor(Math.random() * this.single.length);
        this.single.splice(index, 1);
        this.single = [...this.single];
      }

      if (this.multi.length > 1) {
        const index = Math.floor(Math.random() * this.multi.length);
        this.multi.splice(index, 1);
        this.multi = [...this.multi];
      }

      if (this.bubble.length > 1) {
        const index = Math.floor(Math.random() * this.bubble.length);
        this.bubble.splice(index, 1);
        this.bubble = [...this.bubble];
      }

      if (this.graph.nodes.length > 1) {
        const index = Math.floor(Math.random() * this.graph.nodes.length);
        const value = this.graph.nodes[index].value;
        this.graph.nodes.splice(index, 1);
        const nodes = [...this.graph.nodes];

        const links = this.graph.links.filter((link) => {
          return (
            link.source !== value &&
            link.source.value !== value &&
            link.target !== value &&
            link.target.value !== value
          );
        });
        this.graph = { links, nodes };
      }

      if (this.boxData.length > 1) {
        const index = Math.floor(Math.random() * this.boxData.length);
        this.boxData.splice(index, 1);
        this.boxData = [...this.boxData];
      }
    }

    if (add) {
      // single
      const entry = {
        name: country.name,
        value: Math.floor(10000 + Math.random() * 50000),
      };
      this.single = [...this.single, entry];

      // multi
      const multiEntry = {
        name: country.name,
        series: [
          {
            name: "1990",
            value: Math.floor(10000 + Math.random() * 50000),
          },
          {
            name: "2000",
            value: Math.floor(10000 + Math.random() * 50000),
          },
          {
            name: "2010",
            value: Math.floor(10000 + Math.random() * 50000),
          },
        ],
      };

      this.multi = [...this.multi, multiEntry];

      // graph
      const node = { value: country.name };
      const nodes = [...this.graph.nodes, node];
      const link = {
        source: country.name,
        target: nodes[Math.floor(Math.random() * (nodes.length - 1))].value,
      };
      const links = [...this.graph.links, link];
      this.graph = { links, nodes };

      // bubble
      const bubbleYear = Math.floor((2010 - 1990) * Math.random() + 1990);
      const bubbleEntry = {
        name: country.name,
        series: [
          {
            name: "" + bubbleYear,
            x: new Date(bubbleYear, 0, 1),
            y: Math.floor(30 + Math.random() * 70),
            r: Math.floor(30 + Math.random() * 20),
          },
        ],
      };

      this.bubble = [...this.bubble, bubbleEntry];

      // box
      const boxEntry = {
        name: country.name,
        series: [
          {
            name: "1990",
            value: getRandomInt(10, 5),
          },
          {
            name: "2000",
            value: getRandomInt(15, 5),
          },
          {
            name: "2010",
            value: getRandomInt(20, 10),
          },
          {
            name: "2020",
            value: getRandomInt(30, 10),
          },
          {
            name: "2030",
            value: getRandomInt(50, 20),
          },
        ],
      };

      const index = this.boxData.findIndex((box) => box.name === country.name);
      if (index > -1) {
        this.boxData[index] = boxEntry;
      } else {
        this.boxData = [...this.boxData, boxEntry];
      }

      // bubble interactive demo
      this.bubbleDemoProcess(
        bubbleDemoData[getRandomInt(0, bubbleDemoData.length - 1)]
      );

      this.statusData = this.getStatusData();

      this.timelineFilterBarData = timelineFilterBarData();
    }

    const date = new Date(
      Math.floor(1473700105009 + Math.random() * 1000000000)
    );
    for (const series of this.dateData) {
      series.series.push({
        name: date,
        value: Math.floor(2000 + Math.random() * 5000),
      });
    }
    this.dateData = [...this.dateData];

    this.dateDataWithRange = generateData(2, true);

    if (this.chart.inputFormat === "calendarData")
      this.calendarData = this.getCalendarData();
  }

  applyDimensions() {
    this.view = [this.width, this.height];
  }

  toggleFitContainer() {
    if (this.fitContainer) {
      this.view = undefined;
    } else {
      this.applyDimensions();
    }
  }

  selectChart(chartSelector) {
    this.chartType = chartSelector = chartSelector.replace("/", "");
    this.location.replaceState(this.chartType);

    for (const group of this.chartGroups) {
      this.chart = group.charts.find((x) => x.selector === chartSelector);
      if (this.chart) break;
    }

    this.linearScale = false;
    this.yAxisLabel = "GDP Per Capita";
    this.xAxisLabel = "Country";

    this.width = 700;
    this.height = 300;

    Object.assign(this, this.chart.defaults);

    if (!this.fitContainer) {
      this.applyDimensions();
    }
  }

  changeTheme(theme: string) {
    this.theme = theme;
    if (theme === "light") {
      this.strokeColor = "#000000";
    } else {
      this.strokeColor = "#FFFFFF";
    }
  }

  select(data) {

  }

  activate(data) {
    // 
  }

  deactivate(data) {
    // 
  }

  getInterpolationType(curveType) {
    return this.curves[curveType] || this.curves["default"];
  }

  setColorScheme(name) {
    this.selectedColorScheme = name;
    this.colorScheme = this.colorSets.find((s) => s.name === name);
  }

  onLegendLabelClick(entry) {

  }

  getCalendarData(): any[] {
    // today
    const now = new Date();
    const todaysDay = now.getDate();
    const thisDay = new Date(now.getFullYear(), now.getMonth(), todaysDay);

    // Monday
    const thisMonday = new Date(
      thisDay.getFullYear(),
      thisDay.getMonth(),
      todaysDay - thisDay.getDay() + 1
    );
    const thisMondayDay = thisMonday.getDate();
    const thisMondayYear = thisMonday.getFullYear();
    const thisMondayMonth = thisMonday.getMonth();

    // 52 weeks before monday
    const calendarData = [];
    const getDate = (d) => new Date(thisMondayYear, thisMondayMonth, d);
    for (let week = -52; week <= 0; week++) {
      const mondayDay = thisMondayDay + week * 7;
      const monday = getDate(mondayDay);

      // one week
      const series = [];
      for (let dayOfWeek = 7; dayOfWeek > 0; dayOfWeek--) {
        const date = getDate(mondayDay - 1 + dayOfWeek);

        // skip future dates
        if (date > now) {
          continue;
        }

        // value
        const value = dayOfWeek < 6 ? date.getMonth() + 1 : 0;

        series.push({
          date,
          name: weekdayName.format(date),
          value,
        });
      }

      calendarData.push({
        name: monday.toString(),
        series,
      });
    }

    return calendarData;
  }

  calendarAxisTickFormatting(mondayString: string) {
    const monday = new Date(mondayString);
    const month = monday.getMonth();
    const day = monday.getDate();
    const year = monday.getFullYear();
    const lastSunday = new Date(year, month, day - 1);
    const nextSunday = new Date(year, month, day + 6);
    return lastSunday.getMonth() !== nextSunday.getMonth()
      ? monthName.format(nextSunday)
      : "";
  }

  calendarTooltipText(c): string {
    return `
      <span class="tooltip-label">${c.label
      } • ${c.cell.date.toLocaleDateString()}</span>
      <span class="tooltip-val">${c.data.toLocaleString()}</span>
    `;
  }

  pieTooltipText({ data }) {
    const label = formatLabel(data.name);
    const val = formatLabel(data.value);
    return `
      <span class="tooltip-label">${escapeLabel(label)}</span>
      <span class="tooltip-val">${val}</span>
    `;
  }

  dollarValueFormat(c): string {
    return `$${c.value.toLocaleString()}`;
  }

  getStatusData() {
    const sales = Math.round(1e4 * Math.random());
    const dur = 36e5 * Math.random();
    return this.calcStatusData(sales, dur);
  }

  calcStatusData(
    sales = this.statusData[0].value,
    dur = this.statusData[2].value
  ) {
    const ret = sales * this.salePrice;
    const cost = ((sales * dur) / 60 / 60 / 1000) * this.personnelCost;
    const ROI = (ret - cost) / cost;
    return [
      {
        name: "Sales",
        value: sales,
      },
      {
        name: "Gross",
        value: ret,
        extra: { format: "currency" },
      },
      {
        name: "Avg. Time",
        value: dur,
        extra: { format: "time" },
      },
      {
        name: "Cost",
        value: cost,
        extra: { format: "currency" },
      },
      {
        name: "ROI",
        value: ROI,
        extra: { format: "percent" },
      },
    ];
  }

  statusValueFormat(c): string {
    switch (c.data.extra ? c.data.extra.format : "") {
      case "currency":
        return `$${Math.round(c.value).toLocaleString()}`;
      case "time":
        return multiFormat(c.value);
      case "percent":
        return `${Math.round(c.value * 100)}%`;
      default:
        return c.value.toLocaleString();
    }
  }

  valueFormatting(value: number): string {
    return `${Math.round(value).toLocaleString()} €`;
  }

  currencyFormatting(value: number) {
    return `${Math.round(value).toLocaleString()}`;
  }

  gdpLabelFormatting(c) {
    return `${escapeLabel(
      c.label
    )}<br/><small class="number-card-label"></small>`;
  }

  statusLabelFormat(c): string {
    return `${c.label}<br/><small class="number-card-label">This week</small>`;
  }

  generatePlotData() {
    if (!this.mathFunction) {
      return [];
    }
    const twoPi = 2 * Math.PI;
    const length = 25;
    const series = Array({ length }).map((d, i) => {
      const x = i / (length - 1);
      const t = x * twoPi;
      return {
        name: ~~(x * 360),
        value: this.mathFunction(t),
      };
    });

    return [
      {
        name: this.mathText,
        series,
      },
    ];
  }

  getFunction(text = this.mathText) {
    try {
      text = `with (Math) { return ${this.mathText} }`;
      // tslint:disable-next-line: function-constructor
      const fn = new Function("x", text).bind(Math); // tslint:disable-line: tsr-detect-eval-with-expression
      return typeof fn(1) === "number" ? fn : null;
    } catch (err) {
      return null;
    }
  }

  treemapProcess(sumBy = this.sumBy) {
    this.sumBy = sumBy;
    const children = treemap[0];
    const value =
      sumBy === "Size" ? sumChildren(children) : countChildren(children);
    this.treemap = [children];
    this.treemapPath = [{ name: "Top", children: [children], value }];

    function sumChildren(node) {
      return (node.value =
        node.size || d3Array.sum(node.children, sumChildren));
    }

    function countChildren(node) {
      return (node.value = node.children
        ? d3Array.sum(node.children, countChildren)
        : 1);
    }
  }

  treemapSelect(item) {
    if (item.children) {
      const idx = this.treemapPath.indexOf(item);
      this.treemapPath.splice(idx + 1);
      this.treemap = this.treemapPath[idx].children;
      return;
    }
    const node = this.treemap.find((d) => d.name === item.name);
    if (node.children) {
      this.treemapPath.push(node);
      this.treemap = node.children;
    }
  }

  getFlag(country) {
    return this.countries.find((c) => c.name === country).emoji;
  }

  onFilter(event) {

  }

  /*
  **
  Combo Chart
  **
  [yLeftAxisScaleFactor]="yLeftAxisScale" and [yRightAxisScaleFactor]="yRightAxisScale"
  exposes the left and right min and max axis values for custom scaling, it is probably best to
  scale one axis in relation to the other axis but for flexibility to scale either the left or
  right axis both were exposed.
  **
  */

  yLeftAxisScale(min, max) {
    return { min: `${min}`, max: `${max}` };
  }

  yRightAxisScale(min, max) {
    return { min: `${min}`, max: `${max}` };
  }

  yLeftTickFormat(data) {
    return `${data.toLocaleString()}`;
  }

  yRightTickFormat(data) {
    return `${data}`;
  }
  /*
  **
  End of Combo Chart
  **
  */

  onSelect(event) {

  }

  dblclick(event) {

  }

  /*
  **
  Bubble Chart Interactive Demo
  **
  */

  bubbleDemoProcess(dataFromServer) {
    this.bubbleDemoChart.setDataFromServer(dataFromServer);
    this.bubbleDemoTempData = this.bubbleDemoChart.toChart();
  }

  getBubbleInteractiveTitle() {
    return this.bubbleDemoChart.getChartTitle();
  }

  bubbleShowDrillDownResetLink() {
    return this.bubbleDemoChart.getDrilldownDepth() > 0;
  }

  onClickResetBubbleInteractiveDrill() {
    this.bubbleDemoChart.resetDrilldown();
    this.bubbleDemoTempData = this.bubbleDemoChart.toChart();
  }

  onSelectBubbleInteractivePoint(event) {
    this.bubbleDemoChart.drilldown(event);
    this.bubbleDemoTempData = this.bubbleDemoChart.toChart();
  }
}
