<div class="leftPanel">
    <div class="logo"><img src="../../assets/signup/Accessque-small.svg" alt="AccessQue" /></div>
    <p class="defaultCont">The Next generation AI CRM for small, medium, and enterprise businesses for More sales,
        better service, greater customer insight.

        We Generate insights, scale marketing efforts, boost ROI.Customize to your needs</p>
    <button class="freeTrialBtn"><a style="color:#fff">Book a Demo</a></button>
</div>
<div class="rightPanel" id="rightPanel">
    <div class="signUpPage" id="signUpPage">
        <div class="demoPage" id="demoPage">
            <div class="topHead">Book a Demo<span class="hline"></span></div>
            <div class="formSignUp">
                <form (ngSubmit)="onSubmit()" [formGroup]="bookdemoForm">
                    <div class="form-group">
                        <input class="inputBox user" type="text" formControlName="name"
                            [ngClass]="{ 'is-invalid': f.name.errors }" placeholder="Name" />
                        <div *ngIf="f.name.errors && f.name.touched" class="invalid-feedback">
                            <div *ngIf="f.name.errors.required">Name is required</div>
                            <div *ngIf="f.name.errors.pattern">Only Characters are allowed.</div>
                        </div>
                    </div>
                    <div class="form-group">
                        <input class="inputBox user" type="text" formControlName="cname"
                            [ngClass]="{ 'is-invalid': f.cname.errors }" placeholder="Company Name" />
                        <div *ngIf="f.cname.errors && f.cname.touched" class="invalid-feedback">
                            <div *ngIf="f.cname.errors.required">Company Name is required</div>
                            <div *ngIf="f.cname.errors.pattern">Only Characters are allowed.</div>
                        </div>
                    </div>
                    <!-- <input class="inputBox user" type="text" placeholder="Name" /> -->
                    <!-- <input class="inputBox user" type="text" placeholder="Email" /> -->
                    <div class="form-group">
                        <input class="inputBox user" type="text" formControlName="email"
                            [ngClass]="{ 'is-invalid': f.email.errors }" placeholder="Email" />
                        <div *ngIf="f.email.errors && f.email.touched" class="invalid-feedback">
                            <div *ngIf="f.email.errors.required">Email is required</div>
                            <div *ngIf="f.email.errors.pattern">Please use proper email format.</div>
                        </div>
                    </div>
                    <!-- <div class="twoColumn">
                        <select>
                            <option>Country</option>
                            <option class="ind" style="padding:20px">+91 India</option>
                        </select>
                        <input class="inputBox phone" type="text" placeholder="Phone" />
                    </div> -->
                    <div class="twoColumn">
                        <div class="form-group w20per">
                            <button type="button" class="btn-select w-100" data-toggle="dropdown" aria-haspopup="true"
                                aria-expanded="false">
                                +<span class="country-code">{{ selectedCountryCode }}</span>&nbsp;
                                <i class="fa fa-caret-down"></i>
                            </button>
                            <ul class="dropdown-menu" style="height: 50%;width:90%;overflow: auto;">
                                <div>
                                    <input type="search" (input)="onInput($event)" class="form-control search-all"
                                        style="height: auto; padding: 4px 12px;width: 93%;margin: 0 9px;"
                                        placeholder="Search " autocomplete="off" />
                                    <li *ngFor="let country of countryList">
                                        <span href="#" (click)="selectCountry(country)">
                                            <!-- <div class="flag flag-{{ country.flag || country.flags.svg || country.flags.png }}"></div> -->
                                            <img src="{{ country.flag || country.flags.svg || country.flags.png }}"
                                                alt="" style="height: 15px;width: 20px;">
                                            <span style="padding-left: 8px">{{ country.name }} (+{{
                                                country.callingCodes[0]
                                                }})</span>
                                        </span>
                                    </li>
                                </div>

                            </ul>
                        </div>
                        <div class="form-group w75per">
                            <input class="inputBox phone" type="text" formControlName="phone"
                                [ngClass]="{ 'is-invalid': f.phone.errors }" placeholder="Phone" />
                            <div *ngIf="f.phone.errors && f.phone.touched" class="invalid-feedback">
                                <div *ngIf="f.phone.errors.required">Phone is required</div>
                                <div *ngIf="f.phone.errors.pattern">Only Numbers are allowed</div>
                            </div>
                        </div>
                        <!-- <input class="inputBox phone" type="text" placeholder="Phone" /> -->
                    </div>

                    <!-- <button type="submit" class="secondaryBtn" disabled>Send OTP</button>
                    <input class="inputBox otp" type="text" placeholder="OTP" /> -->
                    <span class="hline" style="width: 100%; height:1px"></span>

                    <div class="btnSection m10">
                        <div>
                            <re-captcha id="recaptcha" name="recaptcha" formControlName="captcha_verification"
                                [ngClass]="{ 'is-invalid': f.captcha_verification.errors }"></re-captcha>
                            <div *ngIf="f.captcha_verification.errors && f.captcha_verification.touched"
                                class="invalid-feedback">
                                <div *ngIf="f.captcha_verification.errors.required">captcha_verification is required
                                </div>
                            </div>
                        </div>
                        <button type="submit" class="primaryBtn">Submit</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>