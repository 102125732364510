<div class="container-fluid">
    <div class="pt-3 pl-2 pr-2 d-flex justify-content-between mb-4">
        <div class="db-sm-header" style="line-height: 330%">
            Call Analytics
        </div>
        <div class="db-sm-header mb-1 ml-auto" (click)="hideDate()">
            <button class="form-group" style="padding: 0">
                <select class="form-control cdd" id="" style="padding: 0; background: #fff" [(ngModel)]="filterValue"
                    (change)="updateDateFilter($event)">
                    <option value="all">All</option>
                    <option value="24hrs">24 hrs</option>
                    <option value="72hrs">72 hrs</option>
                    <option value="7days">07 days</option>
                    <option value="14days">14 days</option>
                    <option value="30days">30 days</option>
                    <option value="custom">Custom</option>
                </select>
            </button>
        </div>

        <div *ngIf="showDate" class="datePicker">
            <div style="background: #fff; height: 76px; border: 1px solid #ccc" class="custom-date">
                <mat-form-field class="mr-3">
                    <input class="label" matInput [matDatepicker]="picker1" placeholder="Start Date"
                        [formControl]="date2" (dateInput)="getStartDate('input', $event)" />
                    <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                    <mat-datepicker #picker1 style="width: 80px"></mat-datepicker>
                </mat-form-field>

                <mat-form-field class="mr-3">
                    <input class="label" matInput [matDatepicker]="picker2" placeholder="End Date" [formControl]="date1"
                        (dateInput)="getEndDate('input', $event)" />
                    <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                    <mat-datepicker #picker2 style="width: 80px"></mat-datepicker>
                </mat-form-field>
                <button class="btn btn-secondary btn-sm" (click)="filterData()">
                    Submit
                </button>
            </div>
        </div>
    </div>

    <div class="mb-3 card">
        <div class="no-gutters row">
            <div class="col-sm-6 col-md-6 col-xl-6">
                <div class="card no-shadow rm-border bg-transparent widget-chart text-left">
                    <div class="icon-wrapper rounded-circle">
                        <div class="icon-wrapper-bg opacity-10">
                            <img src="../../../assets/callanalytics/Totalcalls.svg" alt="" class="w-100 h-100"
                                style="object-fit: cover;">
                        </div>
                    </div>
                    <div class="widget-chart-content">
                        <div class="widget-subheading">
                            Total Calls
                        </div>
                        <div class="widget-numbers">
                            {{counts?.total || 0}}
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-md-6 col-xl-6">
                <div class="card no-shadow rm-border bg-transparent widget-chart text-left">
                    <div class="icon-wrapper rounded-circle">
                        <div class="icon-wrapper-bg opacity-10">
                            <img src="../../../assets/callanalytics/Goodcalls.svg" alt="" class="w-100 h-100"
                                style="object-fit: cover;">
                        </div>
                    </div>
                    <div class="widget-chart-content">
                        <div class="widget-subheading">
                            Good Calls
                        </div>
                        <div class="widget-numbers">
                            {{counts?.good || 0}}
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-md-6 col-xl-6">
                <div class="card no-shadow rm-border bg-transparent widget-chart text-left">
                    <div class="icon-wrapper rounded-circle">
                        <div class="icon-wrapper-bg opacity-10">
                            <img src="../../../assets/callanalytics/Badcalls.svg" alt="" class="w-100 h-100"
                                style="object-fit: cover;">
                        </div>
                    </div>
                    <div class="widget-chart-content">
                        <div class="widget-subheading">
                            Bad Calls
                        </div>
                        <div class="widget-numbers">
                            {{counts?.bad || 0}}
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-md-6 col-xl-6">
                <div class="card no-shadow rm-border bg-transparent widget-chart text-left">
                    <div class="icon-wrapper rounded-circle">
                        <div class="icon-wrapper-bg opacity-10">
                            <img src="../../../assets/callanalytics/Fraudcalls.svg" alt="" class="w-100 h-100"
                                style="object-fit: cover;">
                        </div>
                    </div>
                    <div class="widget-chart-content">
                        <div class="widget-subheading">
                            Fraud Detected Calls
                        </div>
                        <div class="widget-numbers">
                            {{counts?.fraud || 0}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <div class="mb-3 card">
        <div class="card no-shadow rm-border bg-transparent widget-chart text-left">
            <div class="widget-chart-content">
                <div class="widget-subheading">
                    Report
                </div>
            </div>
        </div>
        <div class="body">
            <ngx-charts-line-chart [view]="view" class="chart-container" [scheme]="colorScheme"
                [schemeType]="schemeType" [results]="lineChartCalls" [animations]="animations" [legend]="true"
                [legendTitle]="legendTitle" [legendPosition]="legendPosition" [gradient]="gradient" [xAxis]="showXAxis"
                [yAxis]="showYAxis" [showXAxisLabel]="false" [showYAxisLabel]="false" [xAxisLabel]="xAxisLabel"
                [yAxisLabel]="yAxisLabel" [autoScale]="autoScale" [xScaleMin]="xScaleMin" [xScaleMax]="xScaleMax"
                [yScaleMin]="yScaleMin" [yScaleMax]="yScaleMax" [timeline]="timeline" [showGridLines]="showGridLines"
                [curve]="curve" [rangeFillOpacity]="rangeFillOpacity" [roundDomains]="roundDomains"
                [tooltipDisabled]="tooltipDisabled" [trimXAxisTicks]="trimXAxisTicks" [trimYAxisTicks]="trimYAxisTicks"
                [rotateXAxisTicks]="rotateXAxisTicks" [maxXAxisTickLength]="maxXAxisTickLength"
                [maxYAxisTickLength]="maxYAxisTickLength" (select)="select($event)" (activate)="activate($event)"
                (deactivate)="deactivate($event)">
            </ngx-charts-line-chart>
        </div>
    </div>

    <div class="mb-3 card">
        <div class="no-gutters row">
            <div class="col-sm-6 col-md-6 col-xl-6">
                <div class="card no-shadow rm-border bg-transparent widget-chart text-left">
                    <div class="widget-chart-content">
                        <div class="widget-subheading">
                            Top good calls by agents
                        </div>
                    </div>
                </div>
                <div class="body">
                    <ngx-charts-gauge class="chart-container" [view]="view2" [legend]="showLegend"
                        [legendTitle]="legendTitle" [legendPosition]="legendPosition" [results]="topPerformersGood"
                        [animations]="animations" [textValue]="gaugeTextValue" [scheme]="colorScheme" [min]="gaugeMin"
                        [max]="gaugeMax" [units]="gaugeUnits" [angleSpan]="gaugeAngleSpan"
                        [startAngle]="gaugeStartAngle" [showAxis]="gaugeShowAxis" [bigSegments]="gaugeLargeSegments"
                        [smallSegments]="gaugeSmallSegments" [margin]="
                        margin
                          ? [marginTop, marginRight, marginBottom, marginLeft]
                          : null
                      " [tooltipDisabled]="tooltipDisabled" [showText]="showText" (select)="select($event)"
                        (activate)="activate($event)" (deactivate)="deactivate($event)">
                    </ngx-charts-gauge>
                </div>
            </div>

            <div class="col-sm-6 col-md-6 col-xl-6">
                <div class="card no-shadow rm-border bg-transparent widget-chart text-left">
                    <div class="widget-chart-content">
                        <div class="widget-subheading">
                            Top bad calls by agents
                        </div>
                    </div>
                </div>
                <div class="body">
                    <ngx-charts-gauge class="chart-container" [view]="view2" [legend]="showLegend"
                        [legendTitle]="legendTitle" [legendPosition]="legendPosition" [results]="topPerformersBad"
                        [animations]="animations" [textValue]="gaugeTextValue" [scheme]="colorScheme" [min]="gaugeMin"
                        [max]="gaugeMax" [units]="gaugeUnits" [angleSpan]="gaugeAngleSpan"
                        [startAngle]="gaugeStartAngle" [showAxis]="gaugeShowAxis" [bigSegments]="gaugeLargeSegments"
                        [smallSegments]="gaugeSmallSegments" [margin]="
                                margin
                                  ? [marginTop, marginRight, marginBottom, marginLeft]
                                  : null
                              " [tooltipDisabled]="tooltipDisabled" [showText]="showText" (select)="select($event)"
                        (activate)="activate($event)" (deactivate)="deactivate($event)">
                    </ngx-charts-gauge>
                </div>
            </div>
        </div>
    </div>
    <div class="mb-3 card">
        <div class="card no-shadow rm-border bg-transparent widget-chart text-left">
            <div class="widget-chart-content">
                <div class="widget-subheading">
                    Good V/S Bad V/S Fraud
                </div>
            </div>
        </div>
        <div class="body">
            <ngx-charts-bar-vertical-2d [view]="view" [scheme]="colorScheme" [schemeType]="schemeType"
                [results]="inboundOutbound" [animations]="animations" [gradient]="gradient" [xAxis]="showXAxis"
                [yAxis]="showYAxis" [legend]=true [legendTitle]="legendTitle" [legendPosition]="legendPosition"
                [showXAxisLabel]="false" [showYAxisLabel]="false" [tooltipDisabled]="tooltipDisabled"
                [xAxisLabel]="xAxisLabel" [yAxisLabel]="yAxisLabel" [showGridLines]="showGridLines"
                [barPadding]="barPadding" [groupPadding]="groupPadding" [roundDomains]="roundDomains"
                [roundEdges]="roundEdges" [yScaleMax]="yScaleMax" [noBarWhenZero]="noBarWhenZero"
                [showDataLabel]="showDataLabel" [trimXAxisTicks]="trimXAxisTicks" [trimYAxisTicks]="trimYAxisTicks"
                [rotateXAxisTicks]="rotateXAxisTicks" [maxXAxisTickLength]="maxXAxisTickLength"
                [maxYAxisTickLength]="maxYAxisTickLength" (select)="select($event)" (activate)="activate($event)"
                (deactivate)="deactivate($event)">
            </ngx-charts-bar-vertical-2d>
        </div>
    </div>
</div>