import { Injectable } from "@angular/core";
import { HttpClientService } from "src/app/httpclient.service";
import { environment } from "src/environments/environment";
import { ToastrService } from "ngx-toastr";
import { ReferralData } from "../marketing/smsmarketing/models/ReferralData.model";
import { BehaviorSubject } from "rxjs";
import { FormBuilder, RequiredValidator, Validators } from "@angular/forms";

@Injectable({
  providedIn: "root",
})
export class UhubDashboardService {
  baseApiUrl = environment.SMSapiurl;
  baseApiUrl2 = environment.baseApiUrl2;
  baseApiUrl3 = environment.baseApiUrl;
  webAppUrl = environment.webAppUrl;
  callStatus = new BehaviorSubject<any>(null);
  startCall = new BehaviorSubject<any>(null);
  stopCall = new BehaviorSubject<any>(null);
  startTimer = new BehaviorSubject<any>(null);
  getCallSid = new BehaviorSubject<any>(null);
  conferenceCall = new BehaviorSubject<any>(null);
  public unreadCountRecBehaviorSubject = new BehaviorSubject<any>(null);
  public unreadCountRecObservable = this.unreadCountRecBehaviorSubject.asObservable();
  public viewReceivedLeadBehaviorSubject = new BehaviorSubject<any>(null);
  public viewReceivedLeadObservable = this.viewReceivedLeadBehaviorSubject.asObservable();
  public addLeadBehaviorSubject = new BehaviorSubject<any>(null);
  public addLeadObservable = this.addLeadBehaviorSubject.asObservable();

  editLead = this.formBuilder.group({
    FirstName: ["", [Validators.required]],
    LastName: ["", [Validators.required]],
    CountryCode: ["", [Validators.required]],
    PhoneNumber: ["", [Validators.required]],
    Email: ["", Validators.required],
    University: [""],
    CallStatus: [""],
  });

  addLead = this.formBuilder.group({
    FirstName: ["", [Validators.required]],
    LastName: ["", [Validators.required]],
    CountryCode: ["", [Validators.required]],
    PhoneNumber: ["", [Validators.required]],
    Email: ["", Validators.required],
    University: [""],
  });

  constructor(
    private http: HttpClientService,
    private toastr: ToastrService,
    private readonly formBuilder: FormBuilder
  ) { }


  //new

  getLeads(data) {
    return this.http.post(`${this.baseApiUrl3}getUniversityDashboardLeads`, data);
  }

  getTopBarCount(obj) {
    return this.http.post(`${this.baseApiUrl3}getUniversityTopBarCounts`, obj);
  }

  getLead(id) {
    return this.http.post(`${this.baseApiUrl3}getUhubDashboardLead`, { id: id });
  }

  assignLeads(data) {
    return this.http.post(`${this.baseApiUrl3}assignUhubLead`, data);
  }

  getCountByCallStatus(data) {
    return this.http.post(`${this.baseApiUrl3}getCountByCallStatus`, data);
  }

  getUhubSearchDashboardLeads(data) {
    return this.http.post(`${this.baseApiUrl3}getUhubSearchDashboardLeads`, data);
  }



  //old



  getSms(id) {
    return this.http.get(`${this.baseApiUrl2}communication/uHubSMS/` + id);
  }


  sendSms(data, projectId) {
    return this.http.post(`${this.baseApiUrl}` + projectId + `/messaging-adapter/sendSMS`, data);
  }
}
