import { Group } from './create-agent/models/group.model';
import { environment } from 'src/environments/environment';
// import { HttpClientService } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpClientService } from '../httpclient.service';

interface CreateGroup {
  name: any,
  description: string,
  manager: string,
  createdBy: string,
  roleId: number
}

interface UpdateGroup {
  name: string,
  Description: string,
  activestatus: boolean,
  id: number,
  manager: string,
  createdBy: string,
  roleId: number

}

@Injectable({
  providedIn: 'root'
})
export class GroupService {

  public baseUrl = environment.baseApiUrl2;

  constructor(private http: HttpClientService) { }

  fetchGroups(id) {
    return this.http.get<any[]>(`${this.baseUrl}Group/roles/${id}`).pipe(map(data => {
      let transformedGroup: Group[] = [];
      for (let gp of data) {
        transformedGroup.push(new Group(gp['id'], gp['Name'], gp['Description'], gp['createdAt'], gp['updatedAt'], gp['ActiveStatus'], gp['Manager']))
      }
      return transformedGroup;
    }))
  }

  createGroup(body: CreateGroup) {
    return this.http.post(`${this.baseUrl}createGroup`, body);
  }
  updateGroup(body: UpdateGroup) {
    return this.http.put(`${this.baseUrl}updateGroup/${body.id}`, body)
  }

  getAgentGroups(id) {
    return this.http.get<Group[]>(`${this.baseUrl}agentGroups/${id}`);
  }

  getGroup(id) {
    return this.http.get(`${this.baseUrl}Group/${id}`);
  }

}
