<!-- Overview - Referree Details-->
<mat-sidenav id="sideNav" #sideNav mode="side" class="pr" opened="false" position="end" class="pf w45 z-top">
  <div class="modal-header d-flex pf w45" style="right: 0; top: 0; z-index: 9999">
    <div class="d-flex align-items-center">
      <div class="close hand" (click)="sideNav.toggle()">
        <span aria-hidden="true">&times;</span>
      </div>
      <div class="modal-title mr-auto ml-2">Overview</div>
    </div>
  </div>
  <mat-tab-group class="mt-5 sideDataViewer">
    <mat-tab label="Lead details">
      <div class="mx-2 my-2">
        <table class="table table-borderless table-md caller-table my-1">
          <tr>
            <td>Name</td>
            <td>
              {{ sideViewerData?.FirstName + " " + sideViewerData?.LastName }}
            </td>
          </tr>
          <tr>
            <td>Email</td>
            <td>{{ sideViewerData?.Email }}</td>
          </tr>
          <tr>
            <td>Mobile</td>
            <td>
              {{
              sideViewerData?.CountryCode + " " + sideViewerData?.PhoneNumber
              }}
            </td>
          </tr>
          <tr>
            <td>Created Date</td>
            <td>{{ sideViewerData?.createdAt | date: "mediumDate" }}</td>
          </tr>
          <tr>
            <td>Channel</td>
            <td>{{ sideViewerData?.Channel }}</td>
          </tr>

        </table>
      </div>
    </mat-tab>
    <mat-tab label="Call Details">
      <div class="alert alert-info m-2">
        {{sideViewerData?.CallDetails?.length}} Calls
      </div>
      <div class="mx-2 my-2" *ngFor="let i of sideViewerData?.CallDetails">
        <table class="table table-borderless table-md caller-table my-1">
          <tr>
            <td>Called by</td>
            <td>{{ i?.CalledBy || '-' }}</td>
          </tr>
          <tr>
            <td>Date & Time</td>
            <td>{{ i?.createdAt | date: "mediumDate" }}</td>
          </tr>
          <tr>
            <td>Status</td>
            <td>{{ i?.Status | titlecase }}</td>
          </tr>
          <tr>
            <td>Call Recording</td>
            <td>
              <audio *ngIf="i?.RecordingURL != '-'" style="height: 2.5rem; width: 15srem" [src]="i?.RecordingURL"
                controls controlsList="nodownload" (play)="audioPlay($event)"></audio>
            </td>
          </tr>
          <tr>
            <td>Comments</td>
            <td>{{ i?.Comments || '-'}}</td>
          </tr>
          <tr>
            <td>Direction</td>
            <td>{{ i?.Direction | titlecase }}</td>
          </tr>
        </table>
        <hr class="hr-style" />
      </div>
    </mat-tab>
    <mat-tab label="Communication" style="position:relative">
      <div class="mx-2 my-2">
      </div>
      <div xxstyle="position:absolute; left:15px;right:15px;bottom:0; height:100px;">
        <div class="mb-3 pb-1 ct pr" style="background:#f5f5fa;border-radius: 10px;
            margin: 0 20px 0 10px;">
          <textarea class="form-control ct p-2 mb-1" style="background: #f5f5f5" id="textMessage" rows="2"
            placeholder="Write your message here..."></textarea>
          <button type="button" class="btn btn-link btn-sm mr-2 mb-1 float-right" style="padding: 0 5px"
            (click)="sendSms(sideViewerData, 'textMessage')">
            <img src="../../../assets/img/send-message.svg" class="img12">
          </button>
          <div style="clear: both"></div>
        </div>
      </div>
      <div class="p-3">
        <div class="header-12">
          Total Messages
          <span style="font-size: 12px">{{
            "(" + (smsList?.length || "0") + ")"
            }}</span>
        </div>
        <div class="comments-block" style="background: #fff">
          <div class="dyn-height">
            <div class="d-flex mb-0 mt-1" *ngFor="let j of smsList">
              <div class="d-flex pt-1" style="width: 100%">
                <div style="width:100%">

                  <div class="admin-comment"
                    style="border:1px solid #f5e7e7;border-radius:10px; border-bottom-right-radius: 0;"
                    *ngIf="j?.Direction == 'inbound'">
                    <div class="d-flex">
                      <div>
                        <div class="pic mr-1 float-left text-center">
                          <img src="../../../assets/img/student.svg" style="width: 100%;
                            height: 100%;
                            object-fit: cover;
                            border-radius: 100px;" />

                        </div>
                      </div>
                      <div class="pl-2 pr-2 d-flex flex-column flex-grow-1">
                        <div class="d-flex">
                          <span class="header-12">Student</span>

                          <div class="sub-date float-right ml-auto" style="white-space: nowrap">
                            {{ j?.DateTime | date: "medium" }}
                          </div>
                        </div>
                        <div class="sub-text flex-grow-1">{{ j?.Text }}</div>
                      </div>
                    </div>
                  </div>

                  <div class="agent-comment"
                    style="border-radius:10px;border-bottom-left-radius: 0;border:1px solid #def3f7"
                    *ngIf="j?.Direction == 'outbound'">
                    <div class="d-flex">
                      <div>
                        <div class="pic mr-1 float-left text-center">
                          <img style="width: 100%;
                            height: 100%;
                            object-fit: cover;
                            border-radius: 100px;" src="../../../assets/img/admin-comments.svg" />

                        </div>
                      </div>

                      <div class="pl-2 pr-2 d-flex flex-column">
                        <div class="d-flex">
                          <span class="header-12">Agent</span>
                          <div class="sub-date float-right ml-auto" style="white-space: nowrap">
                            {{ j?.DateTime | date: "medium" }}
                          </div>
                        </div>
                        <div class="sub-text" style="width:315px;line-height: 125%;">{{ j?.Text }}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


    </mat-tab>
    <mat-tab label="Activity">
      <div class="mx-2 my-2">
        <div class="alert alert-info mb-0 d-flex">
          <div class="" style="line-height: 200%">
            {{sideViewerData?.Activities?.length}} Actions taken
          </div>
          <div class="ml-auto" style="width: 180px">
            <input class="searchActivity" type="text" style="font-size: 13px" class="form-control search-input"
              placeholder="Search" [(ngModel)]="activityfilterString" (input)="
                  onInputActivity(sideViewerData?.Activities?.length)
                " />
          </div>
        </div>
        <div *ngFor="
              let i of sideViewerData?.Activities
                | slice: 0:minItems
                | searchFilterTable: activityfilterString
            ">
          <div class="myactivity d-flex align-items-start">
            <div class="mr-auto d-flex">
              <div>
                <img class="img16 mr-2" src="./assets/img/tick-green.svg" />
              </div>
              <div>{{ i?.Description }} </div>
            </div>
            <div class="ml-auto text-right" style="width:230px;"><span class="sm-text ml-2">{{
                i.createdAt | date: "medium"
                }}</span></div>

            <!-- <img class="img16 mr-2" src="./assets/img/tick-green.svg" />
            <p>
              {{ i?.Description }} The Next generation
              AI CRM for small, medium, and enterprise businesses.
              For more sales, better service, greater customer insight.
              <span class="sm-text ml-2">{{
                i.createdAt | date: "medium"
                }}</span>
            </p> -->
          </div>
          <hr style="margin: 0; padding: 0" />
        </div>
        <div class="loadmore" *ngIf="minItems < sideViewerData?.Activities?.length"
          (click)="showmore(sideViewerData?.Activities?.length)">
          Show more..
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
</mat-sidenav>


<!-- Date Filter -->

<div class="p-3">
  <div class="px-2 d-flex mb-4 align-items-center justify-content-center" style="height:30px">
    <div class="db-sm-header mr-auto" style="line-height: 200%;">Activity</div>
    <div class="db-sm-header ml-auto" (click)="hideDate()">
      <button class="form-group" style="padding: 0; background:none;">
        <select class="form-control cdd" id="" style="padding: 0; background: #fff" [(ngModel)]="filterValue"
          (change)="updateDateFilter($event)">
          <option value="all">All</option>
          <option value="24hrs">24 hrs</option>
          <option value="72hrs">72 hrs</option>
          <option value="7days">07 days</option>
          <option value="14days">14 days</option>
          <option value="30days">30 days</option>
          <option value="custom">Custom</option>
        </select>
      </button>
    </div>

    <div *ngIf="showDate" class="datePicker">
      <div style="background: #fff; height: 76px; border: 1px solid #ccc" class="custom-date">
        <mat-form-field class="mr-3">
          <input class="label" matInput [matDatepicker]="picker1" placeholder="Start Date" [formControl]="date2"
            (dateInput)="getStartDate('input', $event)" />
          <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
          <mat-datepicker #picker1 style="width: 80px"></mat-datepicker>
        </mat-form-field>

        <mat-form-field class="mr-3">
          <input class="label" matInput [matDatepicker]="picker2" placeholder="End Date" [formControl]="date1"
            (dateInput)="getEndDate('input', $event)" />
          <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
          <mat-datepicker #picker2 style="width: 80px"></mat-datepicker>
        </mat-form-field>
        <button class="btn btn-secondary btn-sm" (click)="filterData()">
          Submit
        </button>
      </div>
    </div>
  </div>

  <carousel [cellsToScroll]="2" style="padding-left: 50px; z-index: unset" class="fixheight">
    <div class="carousel-cell">
      <div class="mr-3">
        <div class="db-block w-175" (click)="getLeadsByCards('total'); onClick(1)"
          [ngClass]="{ 'db-block-active': tab === 'tab1' }">
          <div class="p-3">
            <div class="d-flex">
              <div class="img-circle d-flex">
                <img src="../../../../assets/img/total_applications.svg" style="width: 20px" />
              </div>
              <div class="f25 text-right ml-auto mt-3">
                {{ topBarCount?.total || 0 }}
              </div>
            </div>
            <div class="box-sub-text1 mt-2">Total Leads</div>
          </div>
        </div>
      </div>
    </div>
    <div class="carousel-cell">
      <div class="mr-3">
        <div class="db-block w-175" (click)="getLeadsByCards('facebook'); onClick(2)"
          [ngClass]="{ 'db-block-active': tab === 'tab2' }">
          <div class="p-3">
            <div class="d-flex">
              <div class="img-circle d-flex">
                <img src="../../../../assets/img/total_applications.svg" style="width: 20px" />
              </div>
              <div class="f25 text-right ml-auto mt-3">
                {{ topBarCount?.facebook || 0 }}
              </div>
            </div>
            <div class="box-sub-text1 mt-2">Facebook Leads</div>
          </div>
        </div>
      </div>
    </div>
    <div class="carousel-cell">
      <div class="mr-3">
        <div class="db-block w-175" (click)="getLeadsByCards('Website'); onClick(3)"
          [ngClass]="{ 'db-block-active': tab === 'tab3' }">
          <div class="p-3">
            <div class="d-flex">
              <div class="img-circle d-flex">
                <img src="../../../../assets/img/total_applications.svg" style="width: 20px" />
              </div>
              <div class="f25 text-right ml-auto mt-3">
                {{ topBarCount?.web || 0 }}
              </div>
            </div>
            <div class="box-sub-text1 mt-2">Web Leads</div>
          </div>
        </div>
      </div>
    </div>
    <div class="carousel-cell">
      <div class="mr-3">
        <div class="db-block w-175" (click)="getLeadsByCards('callback'); onClick(4)"
          [ngClass]="{ 'db-block-active': tab === 'tab4' }">
          <div class="p-3">
            <div class="d-flex">
              <div class="img-circle d-flex">
                <img src="../../../../assets/img/total_applications.svg" style="width: 20px" />
              </div>
              <div class="f25 text-right ml-auto mt-3">
                {{ topBarCount?.followup || 0 }}
              </div>
            </div>
            <div class="box-sub-text1 mt-2">Follow Up</div>
          </div>
        </div>
      </div>
    </div>
    <div class="carousel-cell">
      <div class="mr-3">
        <div class="db-block w-175" (click)="getLeadsByCards('pending'); onClick(5)"
          [ngClass]="{ 'db-block-active': tab === 'tab5' }">
          <div class="p-3">
            <div class="d-flex">
              <div class="img-circle d-flex">
                <img src="../../../../assets/img/total_applications.svg" style="width: 20px" />
              </div>
              <div class="f25 text-right ml-auto mt-3">
                {{ topBarCount?.pending || 0 }}
              </div>
            </div>
            <div class="box-sub-text1 mt-2">Pending</div>
          </div>
        </div>
      </div>
    </div>
    <div class="carousel-cell">
      <div class="mr-3">
        <div class="db-block w-175" (click)="getLeadsByCards('counsellingpending'); onClick(6)"
          [ngClass]="{ 'db-block-active': tab === 'tab6' }">
          <div class="p-3">
            <div class="d-flex">
              <div class="img-circle d-flex">
                <img src="../../../../assets/img/total_applications.svg" style="width: 20px" />
              </div>
              <div class="f25 text-right ml-auto mt-3">
                {{ topBarCount.counsellingpending || 0 }}
              </div>
            </div>
            <div class="box-sub-text1 mt-2">Counselling Pending</div>
          </div>
        </div>
      </div>
    </div>

    <div class="carousel-cell">
      <div class="mr-3">
        <div class="db-block w-175" (click)="getLeadsByCards('counsellingcompleted'); onClick(7)"
          [ngClass]="{ 'db-block-active': tab === 'tab7' }">
          <div class="p-3">
            <div class="d-flex">
              <div class="img-circle d-flex">
                <img src="../../../../assets/img/total_applications.svg" style="width: 20px" />
              </div>
              <div class="f25 text-right ml-auto mt-3">
                {{ topBarCount.counsellingcompleted || 0 }}
              </div>
            </div>
            <div class="box-sub-text1 mt-2">Counselling Completed</div>
          </div>
        </div>
      </div>
    </div>

    <div class="carousel-cell">
      <div class="mr-3">
        <div class="db-block w-175" (click)="getLeadsByCards('interested'); onClick(8)"
          [ngClass]="{ 'db-block-active': tab === 'tab8' }">
          <div class="p-3">
            <div class="d-flex">
              <div class="img-circle d-flex">
                <img src="../../../../assets/img/total_applications.svg" style="width: 20px" />
              </div>
              <div class="f25 text-right ml-auto mt-3">
                {{ topBarCount.interested || 0 }}
              </div>
            </div>
            <div class="box-sub-text1 mt-2">Interested</div>
          </div>
        </div>
      </div>
    </div>

    <div class="carousel-cell">
      <div class="mr-3">
        <div class="db-block w-175" (click)="getLeadsByCards('QualifiedLead'); onClick(9)"
          [ngClass]="{ 'db-block-active': tab === 'tab9' }">
          <div class="p-3">
            <div class="d-flex">
              <div class="img-circle d-flex">
                <img src="../../../../assets/img/total_applications.svg" style="width: 20px" />
              </div>
              <div class="f25 text-right ml-auto mt-3">
                {{ topBarCount.qualifiedLead || 0 }}
              </div>
            </div>
            <div class="box-sub-text1 mt-2">Qualified Lead</div>
          </div>
        </div>
      </div>
    </div>

    <div class="carousel-cell">
      <div class="mr-3">
        <div class="db-block w-175" (click)="getLeadsByCards('QualifiedHotLead'); onClick(10)"
          [ngClass]="{ 'db-block-active': tab === 'tab10' }">
          <div class="p-3">
            <div class="d-flex">
              <div class="img-circle d-flex">
                <img src="../../../../assets/img/total_applications.svg" style="width: 20px" />
              </div>
              <div class="f25 text-right ml-auto mt-3">
                {{ topBarCount.qualifiedHotLead || 0 }}
              </div>
            </div>
            <div class="box-sub-text1 mt-2">Qualified Hot Lead</div>
          </div>
        </div>
      </div>
    </div>

    <div class="carousel-cell">
      <div class="mr-3">
        <div class="db-block w-175" (click)="getLeadsByCards('assignedto'); onClick(11)"
          [ngClass]="{ 'db-block-active': tab === 'tab11' }">
          <div class="p-3">
            <div class="d-flex">
              <div class="img-circle d-flex">
                <img src="../../../../assets/img/total_applications.svg" style="width: 20px" />
              </div>
              <div class="f25 text-right ml-auto mt-3">
                {{ topBarCount.assignedToMe || 0 }}
              </div>
            </div>
            <div class="box-sub-text1 mt-2">Assigned to Me</div>
          </div>
        </div>
      </div>
    </div>
    <div class="carousel-cell"></div>
  </carousel>

  <div style="clear: both"></div>
</div>
<div class="pl-4 pr-4 pb-4">
  <div class="d-flex align-items-center">
    <div class="header-12 mr-auto">List of Leads <a href="javascript:void(0);"
        (click)="openTablesModal(tablesChangemodal)"><img src="../../../assets/images/edit.svg" class="img11"></a>
    </div>

    <div class="ml-auto content-containers" style="margin-left: 30px">
      <input type="file" (change)="convertFile($event)" style="display: none" #uploadFile accept=".xlsx" />
      <div class="d-flex justify-content-end">
        <div>
          <div class="mr-3">
            <div *ngIf="filePath" class="form-control" style="
                  height: 16px;
                  padding: 0px;
                  background: none;
                  font-size: 13px;
                  color: #999;
                  border: 0;
                ">
              {{ filePath }}
            </div>
            <div class="upload-msg-success" *ngIf="uploaded" style="color: green; font-size: 10px">
              {{ insertCount + " " + "Contacts inserted" }}
            </div>
            <div class="upload-msg-error" *ngIf="error">
              {{ errorMessage }}
            </div>
          </div>

          <div style="clear: both"></div>
        </div>

        <div class="ml-auto">
          <button data-toggle="modal" data-target="#exampleModal3" *ngIf="admin" (click)="assignAgentModal()"
            class="btn btn-link btn-sm" style="color: #fff" ngbTooltip="Assign" placement="top"
            tooltipClass="my-custom-class">
            <div>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 32 32">
                <g id="Group_860" data-name="Group 860" transform="translate(-783.465 -58.66)">
                  <path id="Path_965" data-name="Path 965"
                    d="M795.465,62.66a5,5,0,1,1-5,5,5,5,0,0,1,5-5m0-2a7,7,0,1,0,7,7A7,7,0,0,0,795.465,60.66Z"
                    class="psvg" />
                  <path id="Path_966" data-name="Path 966"
                    d="M805.465,88.66h-2v-5a5,5,0,0,0-5-5h-6a5,5,0,0,0-5,5v5h-2v-5a7,7,0,0,1,7-7h6a7,7,0,0,1,7,7Z"
                    class="psvg" />
                  <path id="Path_967" data-name="Path 967" d="M808.465,74.84l-2.59-2.59-1.41,1.41,4,4,7-7-1.41-1.41Z"
                    class="psvg" />
                  <rect id="_Transparent_Rectangle_" data-name=" Transparent Rectangle " width="32" height="32"
                    transform="translate(783.465 58.66)" fill="none" />
                </g>
              </svg>
            </div>
          </button>
          <button class="btn btn-link btn-sm" ngbTooltip="Download Sample" placement="top"
            tooltipClass="my-custom-class">
            <a href="{{ downloadLink }}">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 22 22">
                <g id="Group_776" data-name="Group 776" transform="translate(-215.728 -61.66)">
                  <path id="Path_888" class="psvg" data-name="Path 888"
                    d="M234.853,86.66H221.1a1.375,1.375,0,0,1-1.375-1.375v-8.25A1.375,1.375,0,0,1,221.1,75.66h2.063v1.375H221.1v8.25h13.75v-8.25h-2.063V75.66h2.063a1.375,1.375,0,0,1,1.375,1.375v8.25A1.376,1.376,0,0,1,234.853,86.66Z"
                    transform="translate(-1.25 -4.375)" fill="#b02022" />
                  <rect id="Rectangle_902" class="psvg" data-name="Rectangle 902" width="4" height="1"
                    transform="translate(224.728 75.66)" />
                  <path id="Path_889" class="psvg" data-name="Path 889"
                    d="M233.008,68.19l-2.468,2.461V63.66h-1.375v6.992L226.7,68.19l-.969.969,4.125,4.125,4.125-4.125Z"
                    transform="translate(-3.125 -0.625)" />
                  <rect id="_Transparent_Rectangle_" data-name=" Transparent Rectangle " width="22" height="22"
                    transform="translate(215.728 61.66)" fill="none" />
                </g>
              </svg>

            </a>
          </button>
          <button class="btn btn-link btn-sm" ngbTooltip="Upload File" placement="top" (click)="redirectToUpload()"
            tooltipClass="my-custom-class">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 22 22">
              <g id="Group_775" data-name="Group 775" transform="translate(-171.243 -61.66)">
                <path id="Path_886" class="psvg" data-name="Path 886"
                  d="M177.243,76.419l.953.953,5.131-5.124V84.531h1.352V72.249l5.131,5.124.953-.953L184,69.66Z"
                  transform="translate(-1.944 -2.592)" />
                <path id="Path_887" class="psvg" data-name="Path 887"
                  d="M176.595,67.716v-2.7h13.519v2.7h1.352v-2.7a1.352,1.352,0,0,0-1.352-1.352H176.595a1.352,1.352,0,0,0-1.352,1.352v2.7Z"
                  transform="translate(-1.296 -0.648)" />
                <rect id="_Transparent_Rectangle_" data-name=" Transparent Rectangle " width="22" height="22"
                  transform="translate(171.243 61.66)" fill="none" />
              </g>
            </svg>

          </button>
          <button class="btn btn-link btn-sm" ngbTooltip="Create Lead" placement="top" tooltipClass="my-custom-class"
            (click)="openCreateLead(content3)">
            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22">
              <g id="Group_764" data-name="Group 764" transform="translate(-286.561 -0.829)">
                <path id="Path_869" data-name="Path 869" class="psvg"
                  d="M302.855,15.28V8.829h-1.843V15.28h-6.451v1.843h6.451v6.451h1.843V17.123h6.451V15.28Z"
                  transform="translate(-4.373 -4.373)" />
                <rect id="Rectangle_901" data-name="Rectangle 901" width="22" height="22"
                  transform="translate(286.561 0.829)" fill="none" />
              </g>
            </svg>
          </button>

          <button class="btn btn-link btn-sm" ngbTooltip="Start Call" placement="top" (click)="startCallListener()"
            tooltipClass="my-custom-class" data-toggle="modal" data-target="#callingscreen2">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 22 22">
              <g id="Group_767" data-name="Group 767" class="psvg" transform="translate(-408.685 1.171)">
                <path id="Path_872" class="psvg" data-name="Path 872"
                  d="M427.494,19.7h-.117C413.868,18.926,411.95,7.528,411.682,4.05a2.062,2.062,0,0,1,1.891-2.22h3.794a1.375,1.375,0,0,1,1.279.866l1.045,2.571a1.375,1.375,0,0,1-.3,1.485l-1.464,1.478a6.441,6.441,0,0,0,5.211,5.225l1.492-1.478a1.375,1.375,0,0,1,1.492-.282l2.592,1.038a1.375,1.375,0,0,1,.846,1.279v3.63A2.062,2.062,0,0,1,427.494,19.7ZM413.744,3.2a.688.688,0,0,0-.687.687v.055c.316,4.07,2.344,13.694,14.4,14.382a.688.688,0,0,0,.729-.643V14.011l-2.592-1.038-1.973,1.959-.33-.041c-5.981-.749-6.792-6.73-6.792-6.792l-.041-.33L418.405,5.8,417.374,3.2Z"
                  transform="translate(-0.935 -0.938)" fill="#b02022" />
                <rect id="_Transparent_Rectangle_" data-name=" Transparent Rectangle " width="22" height="22"
                  transform="translate(408.685 -1.171)" fill="none" />
              </g>
            </svg>

          </button>
          <button class="btn btn-link btn-sm" ngbTooltip="Stop Call" placement="top" (click)="stopCallListener()"
            tooltipClass="my-custom-class">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 22 22">
              <g id="Group_774" data-name="Group 774" transform="translate(-121.767 -61.66)">
                <path id="Path_884" class="psvg" data-name="Path 884"
                  d="M129.1,75.547a17.96,17.96,0,0,1-2.932-8.732v-.056a.7.7,0,0,1,.7-.7h3.694l1.05,2.638-1.987,2.008.042.336a9.1,9.1,0,0,0,1.022,2.918l1.022-1.022a6.546,6.546,0,0,1-.588-1.763l1.49-1.5a1.4,1.4,0,0,0,.308-1.511l-1.064-2.617a1.4,1.4,0,0,0-1.3-.882H126.7a2.1,2.1,0,0,0-1.932,2.254v.006a19.593,19.593,0,0,0,3.352,9.635Z"
                  transform="translate(-0.898 -0.901)" fill="#b02022" />
                <path id="Path_885" class="psvg" data-name="Path 885"
                  d="M141.8,75.457,139.16,74.4a1.4,1.4,0,0,0-1.518.287l-1.518,1.5a6.425,6.425,0,0,1-2.946-1.336l10.181-10.209-.987-.987-18.6,18.6.987.987,4.9-4.9c2.365,2.225,5.793,3.932,10.768,4.2h.14a2.1,2.1,0,0,0,2.1-2.1V76.758A1.4,1.4,0,0,0,141.8,75.457Zm-.539,5v.042a.7.7,0,0,1-.739.658h0a15.247,15.247,0,0,1-9.873-3.792l1.518-1.5a7.544,7.544,0,0,0,4.114,1.791l.336.042,2.008-1.994,2.638,1.057Z"
                  transform="translate(-0.601 -0.601)" fill="#b02022" />
                <rect id="_Transparent_Rectangle_" data-name=" Transparent Rectangle " width="22" height="22"
                  transform="translate(121.767 61.66)" fill="none" />
              </g>
            </svg>

          </button>

          <div></div>
        </div>
      </div>
    </div>
  </div>

  <div class="table-responsive">
    <div class="data-table" style="background: #fff">
      <app-custom-table [records]="LeadList" [settings]="projectSettings" [rowSetting]="rowSetting"
        [projectId]="projectId" [isLoading]="isLoading" [noRecords]="noRecords" [selectedAll]="selectedAll" [menuId]="0"
        (updateLeadModal)="updateLeadModal($event)" (callModal)="callModal($event)" (sidenavOpen)="sidenavOpen($event)"
        (dropdownSelectReuse)="dropdownSelectReuse($event)" (searchLeads)="searchLeadsNew($event)"
        (selectAllforAssignment)="selectAllforAssignment()"
        (selectAgentforAssignmentNew)="selectAgentforAssignmentNew($event)"></app-custom-table>
      <!-- <table class="table table-md mt-1 mb-2 ctable">
          <thead>
            <tr>
              <td colspan="5">
                  <div class="indived-search" style="position: relative">
                    <input type="search" (input)="onInput($event)" #inputString class="form-control search-all"
                    [(ngModel)]="search" placeholder="Search " autocomplete="off" />
                    </div>
              </td>
              <td colspan="8" class="vm">
                <div class="pagenation-right d-flex justify-content-end align-items-center">
                  <select class="form-select form-select-dark mx-2" [(ngModel)]="statusValue"
                      (ngModelChange)="dropdownSelect($event)">
                      <option value="all">All</option>
                      <option [value]="item.value" *ngFor="let item of listFeed">{{item.name}}</option>
                      
                  </select>
                  <div style="color: #fff; font-size: 12px">
                    <app-custom-pagination></app-custom-pagination>
                  </div>
                </div>
              </td>
            </tr>
          </thead>
          <tr style="background: #eaf5fd">
            <th style="width: 30px; white-space: nowrap" *ngIf="admin">
              <div style="width: 20px">
                <input type="checkbox" [checked]="selectedAll == true" class="selectAll" id="selectAll"
                  (change)="selectAllforAssignment()" />
              </div>
            </th>
            <th nowrap>Created Date
              <span><button class="sortButton" (click)="toggleSort('createdAt','asc')"><img class="img7 ml-1" src="../../../../assets/img/sort-data.svg" /></button></span>
            </th>
            <th nowrap>Name
              <span><button class="sortButton" (click)="toggleSort('FirstName','asc')"><img class="img7 ml-1" src="../../../../assets/img/sort-data.svg" /></button></span>
            </th>
            <th nowrap>Email
              <span><button class="sortButton" (click)="toggleSort('Email','asc')"><img class="img7 ml-1" src="../../../../assets/img/sort-data.svg" /></button></span>
            </th>
            <th nowrap>Assigned To
              <span><button class="sortButton" (click)="toggleSort('Assignedto','asc')"><img class="img7 ml-1" src="../../../../assets/img/sort-data.svg" /></button></span>
            </th>

            <th nowrap>Form Name
                <span><button class="sortButton" (click)="toggleSort('FormName','asc')"><img class="img7 ml-1" src="../../../../assets/img/sort-data.svg" /></button></span>
              </th>
  
            <th nowrap>Phone
              <span><button class="sortButton" (click)="toggleSort('PhoneNumber','asc')"><img class="img7 ml-1" src="../../../../assets/img/sort-data.svg" /></button></span>
            </th>
            <th nowrap>Channel
              <span><button class="sortButton" (click)="toggleSort('Channel','asc')"><img class="img7 ml-1" src="../../../../assets/img/sort-data.svg" /></button></span>
            </th>
            <th nowrap>Call Status
              <span><button class="sortButton" (click)="toggleSort('CallStatus','asc')"><img class="img7 ml-1" src="../../../../assets/img/sort-data.svg" /></button></span>
            </th>
            <th nowrap>Actions</th>
          </tr>
          <tr *ngFor="
              let i of LeadList
                | filter: filterString
                | slice: (page - 1) * pageSize:page * pageSize; let id = index
            ">
            <td class="vm" nowrap  *ngIf="admin">
              <div style="width: 20px">
                <input type="checkbox" name="lead" [checked]="selectedAll == true" class="selectLead" id="selectLead"
                  (change)="selectAgentforAssignment($event, i)" />
              </div>
            </td>
            <td class="vm" nowrap>{{ i.createdAt | date: "dd MMM, yyyy" }}</td>
            <td class="vm" nowrap>
              <div class="txt-oflow">
                <button class="btn btn-link btn-sm" (click)="updateComments(content2, i)">
                  {{ i.FirstName + " " + i.LastName | titlecase }}
                </button>
              </div>
              </td>
            <td class="vm" nowrap>
              <div *ngIf="i.Email.length<25">{{i.Email}}
              </div>
  
              <div *ngIf="i.Email.length>24" data-title={{i.Email}}>{{i.Email.substr(0,20)+ "..."}}
              </div>
            </td>
            <td class="vm" nowrap>
                <div class="d-flex align-items-center">
                    <div *ngIf="i.AssignedtoName" class="pic mr-1 float-left text-center">
                      <img *ngIf="i.AssignedtoImageURL != null" class="img20"
                        src="{{ i.AssignedtoImageURL }}" />


                      <p [ngStyle]="{ background: i.AssignedtoColor }" class="initials"
                        *ngIf="i.AssignedtoImageURL == null">
                        {{ i.AssignedtoInitial }}
                      </p>


                    </div>
                    <div class="txt-oflow2 float-left mt-2">
                      {{ i.AssignedtoName | titlecase }}
                    </div>
                  </div>
            </td>
            <td class="vm" nowrap>{{ i.FormName }}</td>
  
            <td class="vm" nowrap>{{ i.PhoneNumber }}</td>
            <td class="vm" nowrap>
              <div class="d-flex" *ngIf="i.Channel != '-'">
                <div class="txt-oflow2 lh180 float-left">
                  {{ i.Channel }}
                </div>
              </div>
            </td>
            
            <td class="vm text-center" nowrap style="text-align: left !important;">
              <span class="status-pending" *ngIf="i.CallStatus == 'pending'">Pending</span>
              <span class="status-not-ans" *ngIf="i.CallStatus == 'notinterested'">Not Interested</span>
              <span class="status-completed" *ngIf="i.CallStatus == 'completed'">Completed</span>
              <span class="status-callback" *ngIf="i.CallStatus == 'callback'">Follow Up</span>
              <span class="status-informed" *ngIf="i.CallStatus == 'informed'">Informed</span>
              <span class="status-informed" *ngIf="i.CallStatus == 'called'">Called</span>
              <span class="status-failed" *ngIf="i.CallStatus == 'callfailed'">Wrong Number</span>
              <span class="status-deferred" *ngIf="i.CallStatus == 'deferredintake'">Deferred Intake</span>
              <span class="status-pending" *ngIf="
                  i.CallStatus == 'callnotanswered' ||
                  i.CallStatus == 'notanswered'
                ">Not Answered</span>

              <span class="status-completed" *ngIf="i.CallStatus == 'duplicate'">Duplicate</span>
              <span class="status-completed" *ngIf="i.CallStatus == 'futurelead'">Future Lead</span>
              <span class="status-completed" *ngIf="i.CallStatus == 'interestedpending'">Interested Pending</span>
              <span class="status-completed" *ngIf="i.CallStatus == 'interested'">Interested</span>
              <span class="status-completed" *ngIf="i.CallStatus == 'counsellingpending'">Counselling Pending</span>
              <span class="status-completed" *ngIf="i.CallStatus == 'counsellingcompleted'">Counselling Completed</span>
              <span class="status-completed" *ngIf="i.CallStatus == 'applicationsubmitted'">Application Submitted</span>
              <span class="status-completed" *ngIf="i.CallStatus == 'documentspending'">Documents Pending</span>
              <span class="status-completed" *ngIf="i.CallStatus == 'documentssubmitted'">Documents Submitted</span>
              <span class="status-completed" *ngIf="i.CallStatus == 'paymentpending'">Payment Pending</span>
              <span class="status-completed" *ngIf="i.CallStatus == 'pendingi20'">Pending I20</span>
              <span class="status-completed" *ngIf="i.CallStatus == 'pendingvisa'">Pending VISA</span>
              <span class="status-completed" *ngIf="i.CallStatus == 'visadeclined'">VISA Declined</span>
              <span class="status-completed" *ngIf="i.CallStatus == 'visaapproved'">VISA Approved</span>
              <span class="status-completed" *ngIf="i.CallStatus == 'pendingtravel'">Pending Travel</span>
              <span class="status-completed" *ngIf="i.CallStatus == 'travelcomplete'">Travel Complete</span>
              <span class="status-completed" *ngIf="i.CallStatus == 'enrolled'">Enrolled</span>
              <span class="status-completed" *ngIf="i.CallStatus == 'prospect'">Prospect</span>
              

            </td>
            <td class="vm text-center" nowrap style="text-align: left !important;">
              <div ngbTooltip="Call" placement="top" tooltipClass="my-custom-class" class="mr-2"
                style="display: inline; cursor: pointer" data-toggle="modal" 
                data-target="#callingscreen2">
                <img src="../../../../assets/img/call.svg" class="img13" (click)="followUpCall(i)" />
              </div>
              <mat-sidenav-container style="display: inline !important" ngbTooltip="Overview" placement="top"
                tooltipClass="my-custom-class">
                <mat-sidenav-content style="display: inline !important">
                  <div style="display: inline !important; cursor: pointer" (click)="sideNav.toggle(); sideNavData(i)">
                    <img src="../../../../assets/img/view2.svg" class="img13" />
                  </div>
                </mat-sidenav-content>
              </mat-sidenav-container>
            </td>
          </tr>
          <tr *ngIf="isLoading == true">
            <td colspan="10">
              <div class="norecords">
                <img src="../../../../assets/img/waiting.gif" />
  
                <div>
                  Loading Data<br />Please wait while we fetch the data for you
                </div>
              </div>
            </td>
          </tr>
          <tr *ngIf="noRecords == true">
            <td colspan="10">
              <div class="norecords">
                <img src="../../../../assets/img/nodata.svg" />
                <div>
                  No records found for the selected filters.<br />
                </div>
              </div>
            </td>
          </tr>
        </table> -->
    </div>
  </div>
</div>



<!-- edit lead -->
<ng-template #editLead2 let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Update Lead Details</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" style="max-height: 900px; background: #fff">
    <form class="pt-2" [formGroup]="this.layoutService.commentsForm" (ngSubmit)="submit()">
      <div class="form-row">

        <div class="form-group col-md-4">
          <label>Name</label>
          <input type="text" class="form-control" id="" placeholder="Name" formControlName="name" [ngClass]="{
                'is-invalid':
                  submitted &&
                  f.name.errors &&
                  layoutService.commentsValue.interested !== 'notinterested',
                'is-valid': f.name.valid
              }" />
          <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
            <div *ngIf="f.name.errors.required">name is required</div>
          </div>
        </div>

        <div class="form-group col-md-4">
          <label>Email Id</label>
          <input type="email" class="form-control" id="" placeholder="Email Id" formControlName="emailId" [ngClass]="{
                'is-invalid':
                  submitted &&
                  f.emailId.errors &&
                  layoutService.commentsValue.interested !== 'notinterested',
                'is-valid': f.emailId.valid
              }" />
          <div *ngIf="submitted && f.emailId.errors" class="invalid-feedback">`
            <div *ngIf="
                  f.emailId.errors.required &&
                  layoutService.commentsValue.interested !== 'notinterested'
                ">
              Please Enter a Valid EmailId
            </div>
          </div>
        </div>

        <div class="form-group col-md-4">
          <label>Date of Birth</label>
          <input type="date" class="form-control" id="" placeholder="" formControlName="dob" [ngClass]="{
                'is-invalid':
                  submitted &&
                  f.dob.errors &&
                  layoutService.commentsValue.interested !== 'notinterested','is-valid': f.dob.valid && f.dob.touched
              }" />
          <div *ngIf="
                submitted &&
                f.dob.errors &&
                layoutService.commentsValue.interested !== 'notinterested'" class="invalid-feedback">
            <div *ngIf="f.dob.errors.required">Date of birth is required</div>
          </div>
        </div>

      </div>

      <div class="form-row">
        <div class="form-group col-md-4">
          <label>State/Country</label>
          <input type="email" class="form-control" id="" placeholder="" formControlName="state" [ngClass]="{
                'is-invalid':
                  submitted &&
                  f.state.errors &&
                  layoutService.commentsValue.interested !== 'notinterested',
                'is-valid': f.state.valid && f.state.touched}" />

          <div *ngIf="
                submitted &&
                f.state.errors &&
                layoutService.commentsValue.interested !== 'notinterested'
              " class="invalid-feedback">
            <div *ngIf="f.state.errors.required">State is required</div>
          </div>
        </div>
        <div class="form-group col-md-4">
          <label>Alternative Contact Number</label>
          <input type="text" class="form-control" id="" placeholder="" formControlName="altContactNumber" [ngClass]="{
                'is-invalid':
                  submitted &&
                  f.altContactNumber.errors &&
                  layoutService.commentsValue.interested !== 'notinterested',
                'is-valid': f.altContactNumber.valid && f.altContactNumber.touched
              }" />
          <div *ngIf="submitted && f.altContactNumber.errors" class="invalid-feedback">
            <div *ngIf="f.altContactNumber.errors">
              Alternate ContactNumber is required
            </div>
          </div>
        </div>
        <div class="form-group col-md-4">
          <label>Phone Number</label>
          <div class="form-row">
            <div class="col-2">
              <input type="text" class="form-control" id="" placeholder="" formControlName="countryCode" [ngClass]="{
                    'is-invalid':
                      submitted &&
                      f.altContactNumber.errors &&
                      layoutService.commentsValue.interested !== 'notinterested',
                    'is-valid': f.countryCode.valid && f.countryCode.touched
                  }" />
              <div *ngIf="submitted && f.countryCode.errors" class="invalid-feedback">
                <div *ngIf="f.countryCode.errors">
                  Alternate ContactNumber is required
                </div>
              </div>
            </div>
            <div class="col-8">
              <input type="text" class="form-control" id="" placeholder="" formControlName="phoneNumber" [ngClass]="{
                    'is-invalid':
                      submitted &&
                      f.altContactNumber.errors &&
                      layoutService.commentsValue.interested !== 'notinterested',
                    'is-valid': f.phoneNumber.valid && f.phoneNumber.touched
                  }" />
              <div *ngIf="submitted && f.phoneNumber.errors" class="invalid-feedback">
                <div *ngIf="f.phoneNumber.errors">
                  Alternate ContactNumber is required
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="form-group col-md-4">
          <label for="">Country Preference </label>
          <input type="text" class="form-control" id="" placeholder="" formControlName="countryPreference" [ngClass]="{
                'is-invalid':
                  submitted &&
                  f.countryPreference.errors &&
                  layoutService.commentsValue.interested !== 'notinterested',
                'is-valid':
                  f.countryPreference.valid && f.countryPreference.touched
              }" />
          <div *ngIf="submitted && f.countryPreference.errors" class="invalid-feedback">
            <div *ngIf="f.countryPreference.errors.required">
              CountryPreference is required
            </div>
          </div>
        </div>
      </div>

      <div class="form-row">
        <div class="form-group col-md-4">
          <label for="">Existing Counselling</label>
          <div class="mt-1">
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" name="existingCounseling" id="inlineRadio1" value="yes"
                checked="checked" formControlName="existingCounseling" />
              <label class="form-check-label" for="inlineRadio1">Yes</label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" name="existingCounseling" id="inlineRadio2" value="no"
                formControlName="existingCounseling" />
              <label class="form-check-label" for="inlineRadio2">No</label>
            </div>
          </div>
        </div>
        <div class="form-group col-md-4">
          <label for="">Valid Passport?</label>
          <div class="mt-1">
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" name="passport" id="inlineRadio1" value="yes"
                formControlName="passport" />
              <label class="form-check-label" for="inlineRadio1">Yes</label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" name="passport" id="inlineRadio2" value="no"
                formControlName="passport" />
              <label class="form-check-label" for="inlineRadio2">No</label>
            </div>
          </div>
        </div>

        <div class="form-group col-md-4">
          <label>Applied for Visa?</label>
          <div class="mt-1">
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" name="visa" id="inlineRadio1" value="yes"
                formControlName="visa" />
              <label class="form-check-label" for="inlineRadio1">Yes</label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" name="visa" id="inlineRadio2" value="no"
                formControlName="visa" />
              <label class="form-check-label" for="inlineRadio2">No</label>
            </div>
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-md-4">
          <div style="float: left; width: 35%; margin-right: 10px">
            <label>IELTS</label>
            <input type="text" formControlName="IELETS" class="form-control" placeholder="" [ngClass]="{
                  'is-invalid':
                    submitted &&
                    f.IELETS.errors &&
                    layoutService.commentsValue.interested !== 'notinterested',
                  'is-valid': f.IELETS.valid && f.IELETS.touched
                }" />

            <div *ngIf="
                  submitted &&
                  f.IELETS.errors &&
                  layoutService.commentsValue.interested !== 'notinterested'
                " class="invalid-feedback">
              <div *ngIf="f.IELETS.errors.required">IELETS is required</div>
            </div>
          </div>
          <div class="" style="float: left; width: 35%; margin-right: 10px">
            <label>TOEFL</label>
            <input type="text" formControlName="TOEFL" class="form-control" placeholder="" [ngClass]="{
                  'is-invalid':
                    submitted &&
                    f.TOEFL.errors &&
                    layoutService.commentsValue.interested !== 'notinterested',
                  'is-valid': f.TOEFL.valid && f.TOEFL.touched
                }" />

          </div>
          <div *ngIf="submitted && f.TOEFL.errors" class="invalid-feedback">
            <div *ngIf="f.TOEFL.errors.required">TOEFL is required</div>
          </div>
        </div>

        <div class="form-group col-md-4">
          <div class="" style="float: left; width: 35%; margin-right: 10px">
            <label>PTE</label>
            <input type="text" formControlName="PTE" class="form-control" placeholder="" [ngClass]="{
                  'is-invalid':
                    submitted &&
                    f.PTE.errors &&
                    layoutService.commentsValue.interested !== 'notinterested',
                  'is-valid': f.PTE.valid && f.PTE.touched
                }" />

            <div *ngIf="submitted && f.PTE.errors" class="invalid-feedback">
              <div *ngIf="f.PTE.errors.required">PTE is required</div>
            </div>
          </div>
          <div class="" style="float: left; width: 35%; margin-right: 10px">
            <label>Duolingo</label>
            <input type="text" formControlName="Dulingo" class="form-control" placeholder="" [ngClass]="{
                  'is-invalid':
                    submitted &&
                    f.Dulingo.errors &&
                    layoutService.commentsValue.interested !== 'notinterested',
                  'is-valid': f.Dulingo.valid && f.Dulingo.touched
                }" />

            <div *ngIf="submitted && f.Dulingo.errors" class="invalid-feedback">
              <div *ngIf="f.Dulingo.errors.required">Dulingo is required</div>
            </div>
          </div>
        </div>

        <div class="form-group col-md-4">
          <label>If not when planning to appear</label>
          <input type="" class="form-control" id="" placeholder="" formControlName="planning" [ngClass]="{
                'is-invalid':
                  submitted &&
                  f.planning.errors &&
                  layoutService.commentsValue.interested !== 'notinterested',
                'is-valid': f.planning.valid && f.planning.touched
              }" />
          <div *ngIf="submitted && f.planning.errors" class="invalid-feedback">
            <div *ngIf="f.planning.errors.required &&
                  layoutService.commentsValue.interested !== 'notinterested'">
              Planning is required
            </div>
          </div>
        </div>
      </div>

      <div class="form-row">
        <div class="form-group col-md-4">
          <label for="">Current Qualification</label>
          <input type="text" formControlName="qualification" class="form-control" placeholder="" [ngClass]="{
                'is-invalid':
                  submitted &&
                  f.qualification.errors &&
                  layoutService.commentsValue.interested !== 'notinterested',
                'is-valid': f.qualification.valid && f.qualification.touched
              }" />
          <div *ngIf="
                submitted &&
                f.qualification.errors &&
                layoutService.commentsValue.interested !== 'notinterested'
              " class="invalid-feedback">
            <div *ngIf="f.qualification.errors.required">
              Qualification is required
            </div>
          </div>
        </div>

        <div class="form-group col-md-4">
          <label for="">Work Experience</label>
          <input type="text" class="form-control" id="" formControlName="workExperience" placeholder="" [ngClass]="{
                'is-invalid':
                  submitted &&
                  f.workExperience.errors &&
                  layoutService.commentsValue.interested !== 'notinterested',
                'is-valid': f.workExperience.valid && f.workExperience.touched
              }" />
          <div *ngIf="
                submitted &&
                f.workExperience.errors &&
                layoutService.commentsValue.interested !== 'notinterested'
              " class="invalid-feedback">
            <div *ngIf="f.workExperience.errors.required">
              Work Experience is required
            </div>
          </div>
        </div>

        <div class="form-group col-md-4">
          <label>Course Interested</label>
          <input type="text" formControlName="courseInterested" class="form-control" placeholder="" [ngClass]="{
                'is-invalid':
                  submitted &&
                  f.courseInterested.errors &&
                  layoutService.commentsValue.interested !== 'notinterested',
                'is-valid': f.courseInterested.valid && f.courseInterested.touched
              }" />

          <div *ngIf="
                submitted &&
                f.courseInterested.errors &&
                layoutService.commentsValue.interested !== 'notinterested'
              " class="invalid-feedback">
            <div *ngIf="f.courseInterested.errors.required">
              Course Interested is required
            </div>
          </div>
        </div>

      </div>

      <div class="form-row">

        <div class="col-md-4">
          <div class="form-group">
            <label for="description" class="inputEmail4">Assigned To</label>
            <ng-multiselect-dropdown [settings]="layoutService.dropdownSettings" [data]="layoutService.roleAgents"
              name="manager" formControlName="assignedTo" [(ngModel)]="layoutService.selectedAgent"
              style="width: 100%; padding: 0">
            </ng-multiselect-dropdown>
            <div *ngIf="submitted && f.assignedTo.errors" class="invalid-feedback">
              <div *ngIf="f.assignedTo.errors.required">
                Assigned To is required
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-4">
          <div class="form-group">
            <label for="">Counselling Time and Date ..</label>
            <input type="datetime-local" class="form-control" id="" placeholder="" formControlName="counsellingDate"
              [ngClass]="{'is-invalid':  submitted && f.counsellingDate.errors}" />
            <div *ngIf="submitted && f.counsellingDate.errors" class="invalid-feedback">
              <div *ngIf="f.counsellingDate.errors.required">
                Counselling date is required
              </div>
            </div>
          </div>
        </div>
        <div class="form-group col-md-4">
          <label for="">Call Back</label>
          <input type="datetime-local" class="form-control" id="" placeholder="" formControlName="callBack" [ngClass]="{
                'is-invalid': submitted && f.callBack.errors}" />
          <div *ngIf="submitted && f.callBack.errors" class="invalid-feedback">
            <div *ngIf="f.callBack.errors.required">
              callBack date is required
            </div>
          </div>
        </div>
        <div class="form-group col-md-4">
          <label>Funding Source</label>
          <input type="text" formControlName="fundingResource" class="form-control" placeholder="" [ngClass]="{
                'is-invalid':
                  submitted &&
                  f.fundingResource.errors &&
                  layoutService.commentsValue.interested !== 'notinterested',
                'is-valid': f.fundingResource.valid && f.fundingResource.touched
              }" />

          <div *ngIf=" submitted &&f.fundingResource.errors &&layoutService.commentsValue.interested !== 'notinterested'
              " class="invalid-feedback">
            <div *ngIf="f.fundingResource.errors.required">
              Funding Resource is required
            </div>
          </div>
        </div>
        <div class="form-group col-md-4">
          <label for="">Intake preferred</label>
          <input class="form-control" type="text" formControlName="intakePreferred" [ngClass]="{
                'is-invalid':
                  submitted &&
                  f.intakePreferred.errors &&
                  layoutService.commentsValue.interested !== 'notinterested',
                'is-valid': f.intakePreferred.valid && f.intakePreferred.touched
              }" />

          <div *ngIf="submitted && f.intakePreferred.errors &&layoutService.commentsValue.interested !== 'notinterested'
              " class="invalid-feedback">
            <div *ngIf="f.intakePreferred.errors.required">
              In Take Proffered is required
            </div>
          </div>
        </div>
        <div class="form-group col-md-4">
          <label for="">Comments</label>
          <input type="text" formControlName="comments" class="form-control" placeholder="" [ngClass]="{
                'is-valid': f.comments.valid && f.comments.touched
              }" />
        </div>
      </div>
      <!-- Radio button  -->

      <!-- <div class="form-group col-md-12">
          <div class="form-group">
            <label for="">Feedback</label>
            <select formControlName="interested" class="form-select">
              <option [value]="item.value" *ngFor="let item of listFeed">{{item.name}}</option>
            </select>
          </div>
        </div> -->
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label for="feedback" class="inputEmail4">Feedback</label><br>
            <select formControlName="interested" class="form-select">
              <option [value]="item.value" *ngFor="let item of listFeed">{{item.name}}</option>
            </select>
          </div>
        </div>
        <div class="col-md-6 d-flex">
          <div class="ml-auto mt-5">
            <button class="btn btn-primary btn-sm ml-auto mr-2" type="submit">Update</button>

            <button class="btn btn-light btn-sm ml-auto">Cancel</button>
          </div>
        </div>
      </div>


      <!-- <div class="d-flex justify-content-end">
          <div class="mt-3">
            <button class="btn btn-primary btn-sm" type="submit">Update</button>
          </div>
        </div> -->

    </form>
  </div>
</ng-template>

<!-- for assign model-->
<div class="modal fade" id="exampleModal3" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">
          Assign to Agent
        </h5>
        <button type="button" id="closeAssignButton" #closeButton class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" style="height: none;">
        <div class="px-2">
          <div class="py-2 header-12">
            <b>{{ leadIds.length }}</b> Students are selected to
            assign.
          </div>
          <div class="d-flex mt-2 mb-3">
            <div class="form-group mr-2" style="margin-bottom: 0; width: 220px">
              <select class="form-control" id="exampleFormControlSelect1" [(ngModel)]="defaultAgent"
                (change)="agentSelected($event.target.value)">
                <option value="default" disabled selected>
                  Select Agent
                </option>
                <option *ngFor="let agent of agentList" [value]="agent.Mailid">
                  {{ agent.FirstName + " " + agent.LastName }}
                </option>
              </select>
            </div>
            <button type="button" class="btn btn-secondary btn-sm" (click)="assignLEads()">
              Assign
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<!-- add lead -->
<ng-template #content3 let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Add Lead</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body p-3" style="max-height: 600px; background: #fff">
    <form (ngSubmit)="createLead()" [formGroup]="marketingService.createLead">
      <div class="form-group">
        <label class="form-label">First Name</label>
        <input class="form-control" name="firstName" formControlName="firstName" [ngClass]="{
              'is-invalid': submitted && createLeads.firstName.invalid,
              'is-valid':
                createLeads.firstName.valid && createLeads.firstName.touched
            }" />
        <div *ngIf="submitted && createLeads.firstName.errors" class="invalid-feedback">
          <div>First Name is required</div>
        </div>
      </div>

      <div class="form-group">
        <label class="form-label">Last Name</label>
        <input class="form-control" name="lastName" formControlName="lastName" [ngClass]="{
              'is-invalid': submitted && createLeads.lastName.invalid,
              'is-valid':
                createLeads.lastName.valid && createLeads.lastName.touched
            }" />
        <div *ngIf="submitted && createLeads.lastName.errors" class="invalid-feedback">
          <div>LastName is required</div>
        </div>
      </div>
      <div class="form-group">
        <label class="form-label">Phone Number</label>
        <div class="row">
          <div class="col-2">
            <input class="form-control" name="countryCode" formControlName="countryCode" [ngClass]="{
                  'is-invalid': submitted && createLeads.countryCode.invalid,
                  'is-valid':
                    createLeads.countryCode.valid &&
                    createLeads.countryCode.touched
                }" />
            <div *ngIf="submitted && createLeads.countryCode.errors" class="invalid-feedback">
              <div>Country Code is required</div>
            </div>
          </div>
          <div class="col-10">
            <input class="form-control" name="phoneNumber" formControlName="phoneNumber" [ngClass]="{
                  'is-invalid': submitted && createLeads.phoneNumber.invalid,
                  'is-valid':
                    createLeads.phoneNumber.valid &&
                    createLeads.phoneNumber.touched
                }" />
            <div *ngIf="submitted && createLeads.phoneNumber.errors" class="invalid-feedback">
              <div>Phone Number is required</div>
            </div>
          </div>
        </div>
      </div>
      <div class="form-group">
        <label class="form-label">Email</label>
        <input type="text" name="email" class="form-control" formControlName="email" [ngClass]="{
              'is-invalid': submitted && createLeads.email.invalid,
              'is-valid': createLeads.email.valid && createLeads.email.touched
            }" />
        <div *ngIf="submitted && createLeads.email.errors" class="invalid-feedback">
          <div>Email is required</div>
        </div>
      </div>
      <div class="form-group">
        <label class="form-label">Form Name</label>
        <input type="text" name="form_name" class="form-control" formControlName="form_name" [ngClass]="{
              'is-invalid': submitted && createLeads.form_name.invalid,
              'is-valid':
                createLeads.form_name.valid && createLeads.form_name.touched
            }" />
        <div *ngIf="submitted && createLeads.form_name.errors" class="invalid-feedback">
          <div>Form Name is required</div>
        </div>
      </div>
      <div class="form-group">
        <label class="form-label">Channel</label>
        <input type="text" name="channel" class="form-control" formControlName="channel" [ngClass]="{
              'is-invalid': submitted && createLeads.channel.invalid,
              'is-valid': createLeads.channel.valid && createLeads.channel.touched
            }" />
        <div *ngIf="submitted && createLeads.channel.errors" class="invalid-feedback">
          <div>Channel is required</div>
        </div>
      </div>
      <div class="d-flex justify-content-end">
        <button class="btn btn-primary btn-sm">Submit</button>
      </div>
    </form>
  </div>
</ng-template>






<div class="fixed-footer">Powered by <a href="https://avantao.com/#/" target="_blank">Avantao</a></div>
<ng-template #tablesChangemodal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Tables</h4>
    <button type="button" id="closeButton3" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-md-4" *ngFor="let item of projectSettings1">
        <mat-selection-list>
          <mat-list-option style="font-size: 13px" [selected]="item.view"
            (click)="removeTable(item.primaryKey,!item.view)">
            {{ item["header"] | titlecase }}
          </mat-list-option>
        </mat-selection-list>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 text-right">
        <button type="button" class="btn btn-primary btn-sm" (click)="updateTables();modal.dismiss('Cross click')">
          Update
        </button>
      </div>
    </div>
  </div>
</ng-template>