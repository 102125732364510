import { Component, OnInit } from "@angular/core";
import { ThemePalette } from "@angular/material/core";
import { FormBuilder, Validators } from "@angular/forms";
import { STEPPER_GLOBAL_OPTIONS } from "@angular/cdk/stepper";
import { FormControl } from "@angular/forms";
import { ViewChild } from "@angular/core";
import { NgForm, FormGroup } from "@angular/forms";
import { UhLeadsService } from "../uhleads.service";
import { CreateCompanyService } from "./create-company.service";
import { toInteger, toLower } from "lodash";
import { FormGroupDirective } from "@angular/forms";
import { ErrorStateMatcher } from "@angular/material/core";
import { LeadsDashboardService } from "../leads-dashboard/leads-dashboard.service";
import { COMMA, ENTER, SPACE } from '@angular/cdk/keycodes';
import * as XLSX from 'xlsx';
import { DomSanitizer } from "@angular/platform-browser";
import { ActivatedRoute } from "@angular/router";
import * as moment from "moment";
import { Router } from "@angular/router";
import { ModalDismissReasons, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ExportExcelService } from "../excel.service";


export interface Feedback {
  name: string;
  value: string;
  selected: boolean;
  cards: boolean;
  callingScreen: boolean;
  color: ThemePalette;
  subtasks?: Feedback[];
}

export interface Channel {
  name: string;
  selected: boolean;
  color: ThemePalette;
  subtasks?: Channel[];
}

export interface Columns {
  name: string;
  displayName: string;
  type: string;
  selected: boolean;
  color: ThemePalette;
  subtasks?: Columns[];
}

export interface Task {
  name: string;
  completed: boolean;
  color: ThemePalette;
  subtasks?: Task[];
}



export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: FormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    const isSubmitted = form && form.submitted;
    return !!(
      control &&
      control.invalid &&
      (control.dirty || control.touched || isSubmitted)
    );
  }
}

@Component({
  selector: "app-create-company",
  templateUrl: "./create-company.component.html",
  styleUrls: ["./create-company.component.css"],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { showError: true },
    },
  ],
})
export class CreateCompanyComponent {
  emailFormControl = new FormControl("", [
    Validators.required,
    Validators.email,
  ]);

  matcher = new MyErrorStateMatcher();

  panelOpenState = false;
  firstFormGroup = this._formBuilder.group({
    firstCtrl: ["", Validators.required],
  });
  secondFormGroup = this._formBuilder.group({
    secondCtrl: ["", Validators.required],
  });
  isLinear = true;
  isClass = false;

  task: Task = {
    name: "Indeterminate",
    completed: false,
    color: "primary",
    subtasks: [
      { name: "Primary", completed: false, color: "primary" },
      { name: "Accent", completed: false, color: "accent" },
      { name: "Warn", completed: false, color: "warn" },
    ],
  };

  public separatorKeysCodes = [ENTER, COMMA, SPACE];
  public emailList = [];
  removable = true;
  rulesForm: FormGroup;
  fb2: FormBuilder = new FormBuilder();
  role: any;
  typeOfUpload: any;

  feedBacks: Feedback = {
    name: "Feedbacks",
    value: "",
    selected: false,
    cards: true,
    callingScreen: true,
    color: "primary",
    subtasks: [
      {
        name: "Pending",
        value: "pending",
        selected: false,
        cards: true,
        callingScreen: true,
        color: "primary",
      },
      {
        name: "Called",
        value: "called",
        selected: false,
        cards: true,
        callingScreen: true,
        color: "primary",
      },
      {
        name: "Call Back",
        value: "callback",
        selected: false,
        cards: true,
        callingScreen: true,
        color: "primary",
      },
      {
        name: "Informed",
        value: "informed",
        selected: false,
        cards: true,
        callingScreen: true,
        color: "primary",
      },
      {
        name: "Wrong Number",
        value: "wrongnumber",
        selected: false,
        cards: true,
        callingScreen: true,
        color: "primary",
      },
      {
        name: "Not Answered",
        value: "notanswered",
        selected: false,
        cards: true,
        callingScreen: true,
        color: "primary",
      },
      {
        name: "Un Contactable",
        value: "uncontactable",
        selected: false,
        cards: true,
        callingScreen: true,
        color: "primary",
      },
      {
        name: "Deferred Intake",
        value: "deferredintake",
        selected: false,
        cards: true,
        callingScreen: true,
        color: "primary",
      },
      {
        name: "Accepted",
        value: "accepted",
        selected: false,
        cards: true,
        callingScreen: true,
        color: "primary",
      },
      {
        name: "Not Interested",
        value: "notinterested",
        selected: false,
        cards: true,
        callingScreen: true,
        color: "primary",
      },

      {
        name: "Qualified Hot Lead",
        value: "qualifiedhotlead",
        selected: false,
        cards: true,
        callingScreen: true,
        color: "primary",
      },
      {
        name: "Interested",
        value: "interested",
        selected: false,
        cards: true,
        callingScreen: true,
        color: "primary",
      },
      {
        name: "Future Lead",
        value: "futurelead",
        selected: false,
        cards: true,
        callingScreen: true,
        color: "primary",
      },
      {
        name: "Poor English",
        value: "poorenglish",
        selected: false,
        cards: true,
        callingScreen: true,
        color: "primary",
      },
      {
        name: "Interested Pending",
        value: "interestedpending",
        selected: false,
        cards: true,
        callingScreen: true,
        color: "primary",
      },

      {
        name: "Duplicate",
        value: "duplicate",
        selected: false,
        cards: true,
        callingScreen: true,
        color: "primary",
      },
      {
        name: "Existing Counselling",
        value: "existingcounselling",
        selected: false,
        cards: true,
        callingScreen: true,
        color: "primary",
      },
      {
        name: "Counselling Pending",
        value: "counsellingpending",
        selected: false,
        cards: true,
        callingScreen: true,
        color: "primary",
      },
      {
        name: "Counselling Completed",
        value: "counsellingcompleted",
        selected: false,
        cards: true,
        callingScreen: true,
        color: "primary",
      },
      {
        name: "Application Submitted",
        value: "applicationsubmitted",
        selected: false,
        cards: true,
        callingScreen: true,
        color: "primary",
      },
      {
        name: "Documents Pending",
        value: "documentspending",
        selected: false,
        cards: true,
        callingScreen: true,
        color: "primary",
      },
      {
        name: "Documents Submitted",
        value: "documentssubmitted",
        selected: false,
        cards: true,
        callingScreen: true,
        color: "primary",
      },
      {
        name: "Payment Pending",
        value: "paymentpending",
        selected: false,
        cards: true,
        callingScreen: true,
        color: "primary",
      },
      {
        name: "Pending I20",
        value: "pendingi20",
        selected: false,
        cards: true,
        callingScreen: true,
        color: "primary",
      },
      {
        name: "Pending Visa",
        value: "pendingvisa",
        selected: false,
        cards: true,
        callingScreen: true,
        color: "primary",
      },

      {
        name: "Visa Declined",
        value: "visadeclined",
        selected: false,
        cards: true,
        callingScreen: true,
        color: "primary",
      },
      {
        name: "Visa Approved",
        value: "visaapproved",
        selected: false,
        cards: true,
        callingScreen: true,
        color: "primary",
      },
      {
        name: "Pending Travel",
        value: "pendingtravel",
        selected: false,
        cards: true,
        callingScreen: true,
        color: "primary",
      },
      {
        name: "Travel Complete",
        value: "travelcomplete",
        selected: false,
        cards: true,
        callingScreen: true,
        color: "primary",
      },
      {
        name: "Enrolled",
        value: "enrolled",
        selected: false,
        cards: true,
        callingScreen: true,
        color: "primary",
      },
      {
        name: "Prospect",
        value: "prospect",
        selected: false,
        cards: true,
        callingScreen: true,
        color: "primary",
      },
    ],
  };

  leadsTable = [
    {
      name: "createddate",
      type: "Date",
      displayName: "Created Date",
      selected: true,
      showInDashboard: true,
      showInOverview: true,
    },
    {
      name: "updateddate",
      type: "Date",
      displayName: "Updated Date",
      selected: true,
      showInDashboard: true,
      showInOverview: true,
    },
    {
      name: "firstname",
      type: "String",
      displayName: "First Name",
      selected: true,
      showInDashboard: true,
      showInOverview: true,
    },
    {
      name: "lastname",
      type: "String",
      displayName: "Last Name",
      selected: true,
      showInDashboard: true,
      showInOverview: true,
    },
    {
      name: "email",
      type: "String",
      displayName: "Email",
      selected: true,
      showInDashboard: true,
      showInOverview: true,
    },
    {
      name: "assignedto",
      type: "String",
      displayName: "Assigned To",
      selected: true,
      showInDashboard: true,
      showInOverview: true,
    },
    {
      name: "formname",
      type: "String",
      displayName: "Form Name",
      selected: true,
      showInDashboard: true,
      showInOverview: true,
    },
    {
      name: "countrycode",
      type: "String",
      displayName: "Country Code",
      selected: true,
      showInDashboard: true,
      showInOverview: true,
    },
    {
      name: "phone",
      type: "String",
      displayName: "Phone Number",
      selected: true,
      showInDashboard: true,
      showInOverview: true,
    },
    {
      name: "channel",
      type: "String",
      displayName: "Channel",
      selected: true,
      showInDashboard: true,
      showInOverview: true,
    },
    {
      name: "callstatus",
      type: "String",
      displayName: "Call Status",
      selected: true,
      showInDashboard: true,
      showInOverview: true,
    },
  ];

  activitiesTable = [
    {
      name: "ProjectId",
      type: "String",
      displayName: "Project Id",
      selected: true,
    },
    {
      name: "LeadId",
      type: "String",
      displayName: "Lead Id",
      selected: true,
    },
    {
      name: "Type",
      type: "String",
      displayName: "Type",
      selected: true,
    },
    {
      name: "Description",
      type: "String",
      displayName: "Description",
      selected: true,
    },
    {
      name: "CreatedAt",
      type: "String",
      displayName: "Created At",
      selected: true,
    },
    {
      name: "UpdatedAt",
      type: "String",
      displayName: "Updated At",
      selected: true,
    },
    {
      name: "AgentMail",
      type: "String",
      displayName: "Agent Mail",
      selected: true,
    },
  ];

  calldetailsTable = [
    {
      name: "callernumber",
      type: "String",
      displayName: "Caller Number",
      selected: true,
    },
    {
      name: "callercity",
      type: "String",
      displayName: "Caller City",
      selected: true,
    },
    {
      name: "callercountry",
      type: "String",
      displayName: "Caller Country",
      selected: true,
    },
    {
      name: "callerstate",
      type: "String",
      displayName: "Caller State",
      selected: true,
    },
    {
      name: "callerzip",
      type: "String",
      displayName: "Caller Zip",
      selected: true,
    },
    {
      name: "rolescontacted",
      type: "String",
      displayName: "Roles Contacted",
      selected: true,
    },
    {
      name: "channel",
      type: "String",
      displayName: "Channel",
      selected: true,
    },
    {
      name: "direction",
      type: "String",
      displayName: "Direction",
      selected: true,
    },
    {
      name: "workersid",
      type: "String",
      displayName: "Worker Sid",
      selected: true,
    },
    {
      name: "callednumber",
      type: "String",
      displayName: "Called Number",
      selected: true,
    },
    {
      name: "calledcity",
      type: "String",
      displayName: "Called City",
      selected: true,
    },
    {
      name: "calledcountry",
      type: "String",
      displayName: "Called Country",
      selected: true,
    },
    {
      name: "calledstate",
      type: "String",
      displayName: "Called State",
      selected: true,
    },
    {
      name: "calledzip",
      type: "String",
      displayName: "Called Zip",
      selected: true,
    },
    {
      name: "recordingstarttime",
      type: "String",
      displayName: "Recording Start Time",
      selected: true,
    },
    {
      name: "recordingduration",
      type: "String",
      displayName: "Recording Duration",
      selected: true,
    },
    {
      name: "recordingurl",
      type: "String",
      displayName: "Recording Url",
      selected: true,
    },
    {
      name: "callanswered",
      type: "Boolean",
      displayName: "Call Answered",
      selected: true,
    },
    {
      name: "rolestransferred",
      type: "String",
      displayName: "Roles Transferred",
      selected: true,
    },
    {
      name: "callsid",
      type: "String",
      displayName: "Call Sid",
      selected: true,
    },
    {
      name: "conferencesid",
      type: "String",
      displayName: "Conference Sid",
      selected: true,
    },
    {
      name: "createdat",
      type: "Date",
      displayName: "Created At",
      selected: true,
    },
    {
      name: "updatedat",
      type: "Date",
      displayName: "Updated At",
      selected: true,
    },
    {
      name: "initialcallsid",
      type: "String",
      displayName: "Initial Call Sid",
      selected: true,
    },
    {
      name: "leadid",
      type: "Number",
      displayName: "Lead Id",
      selected: true,
    },
    {
      name: "comments",
      type: "String",
      displayName: "Comments",
      selected: true,
    },
    {
      name: "status",
      type: "String",
      displayName: "Status",
      selected: true,
    },
    {
      name: "followupdate",
      type: "String",
      displayName: "Followup Date",
      selected: true,
    },
    {
      name: "leadtype",
      type: "String",
      displayName: "Lead Type",
      selected: true,
    },
  ];

  channel: Channel = {
    name: "Channels",
    selected: false,
    color: "primary",
    subtasks: [
      { name: "Facebook", selected: false, color: "primary" },
      { name: "Website", selected: false, color: "primary" },
      { name: "LinkedIn", selected: false, color: "primary" },
      { name: "Justdial", selected: false, color: "primary" },
      { name: "Instagram", selected: false, color: "primary" },
    ],
  };

  leadTableColoumn: Columns = {
    name: "Table columns",
    type: "",
    displayName: "",
    selected: false,
    color: "primary",
    subtasks: [
      {
        name: "createddate",
        displayName: "Created Date",
        type: "Date",
        selected: false,
        color: "primary",
      },
      {
        name: "updateddate",
        displayName: "Updated Date",
        type: "Date",
        selected: false,
        color: "primary",
      },
      {
        name: "firstname",
        displayName: "First Name",
        type: "String",
        selected: false,
        color: "primary",
      },
      {
        name: "lastname",
        displayName: "Last Name",
        type: "String",
        selected: false,
        color: "primary",
      },
      {
        name: "email",
        displayName: "Email",
        type: "String",
        selected: false,
        color: "primary",
      },
      {
        name: "assignedto",
        displayName: "Assigned To",
        type: "String",
        selected: false,
        color: "primary",
      },
      {
        name: "formname",
        displayName: "Form Name",
        type: "String",
        selected: false,
        color: "primary",
      },
      {
        name: "countrycode",
        displayName: "Country Code",
        type: "String",
        selected: false,
        color: "primary",
      },
      {
        name: "phone",
        displayName: "Phone",
        type: "String",
        selected: false,
        color: "primary",
      },
      {
        name: "channel",
        displayName: "Channel",
        type: "String",
        selected: false,
        color: "primary",
      },
      {
        name: "callstatus",
        displayName: "Call Status",
        type: "String",
        selected: false,
        color: "primary",
      },
      {
        name: "leaddocstatus",
        displayName: "Lead Doc Status",
        type: "String",
        selected: true,
        color: "primary",
      },
    ],
  };

  allComplete: boolean = false;

  email = new FormControl("", [Validators.required, Validators.email]);
  CName = new FormControl("", [Validators.required]);
  minSubscriptionEndDate: any = null
  CompanyDetails = {
    CompanyName: "",
    NoOfUsers: "",
    PrimaryCountryCode: 91,
    PrimaryPhoneNumber: "",
    SecondaryCountryCode: 91,
    SecondaryPhoneNumber: "",
    PrimaryEmail: "",
    SecondaryEmail: "",
    Voip: {
      voip: "",
      number: "",
    },
    SubscriptionStartDate: "",
    SubscriptionEndDate: "",
    FaltNo: "",
    Address: "",
    City: "",
    Zip: "",
    LandMark: "",
    Country: "",
    Documents: "",
    Theme: {
      sidebar_topbar_text_color: "#000000",
      sidebar_topbar_icon_color: "#000000",
      table_popup_bg_color: "#000000",
      table_popup_button_color: "#000000",
      table_popup_links_color: "#000000",
    },
    favIconLogo: "",
    companyLogo: "",
    companyTanDocument: "",
    companyPanDocument: "",
    companyRegistDocuments: "",
    companylogowithoutname: "",
    domainName: ""
  };
  public page: any = 1;
  public pageSize: any = 8;
  SelectedProjects: any = [];
  addedProjects: any = [];
  ProjectTypeList: any = [];
  ProjectDetails: {
    ProjectName: String;
    ProjectType: String;
    ProjectOther: String;
    ProjectCountryCode: String;
    ProjectTelephoneNumber: String;
    ProjectOutgoingCountryCode: String;
    ProjectOutgoingNumber: String;
    EmailService: {
      choice: any;
      incoming: String;
      outgoing: String;
      mailGroup: String;
    };
    SmsService: any;
    WhatsappService: any;
    Modules: {
      leadManagement: {
        LeadManagement: boolean;
        columns: any;
        feedback: any;
        channel: any;
      };
    };
  } = {
      ProjectName: "",
      ProjectType: "",
      ProjectOther: "",
      ProjectCountryCode: "91",
      ProjectTelephoneNumber: "",
      ProjectOutgoingCountryCode: "91",
      ProjectOutgoingNumber: "",
      EmailService: {
        choice: false,
        incoming: "",
        outgoing: "",
        mailGroup: "",
      },
      SmsService: false,
      WhatsappService: false,
      Modules: {
        leadManagement: {
          LeadManagement: false,
          columns: [],
          feedback: [],
          channel: [],
        },
      },
    };
  moduleData: any;
  noRecords: Boolean = false
  isLoading: Boolean = false
  @ViewChild("form")
  public form: NgForm;
  @ViewChild("form2")
  public form2: NgForm;

  companyCreated: Boolean = false;
  newCompanyDetails: any;
  projectCreated: boolean = false;
  newProjectDetails: any;
  inviteEmail: any;
  userData: any;
  companyRegistDocuments: File[] = [];
  companyPanDocument: File[] = [];
  companyTanDocument: File[] = [];
  companyLogo: File[] = [];
  favIconLogo: File[] = [];
  companylogowithoutname: File[] = [];

  file: File;
  arrayBuffer: any;
  previewCompanyURL: any = ''
  previewFaviconURL: any = ''
  previewCompanyLogoWithoutNameURL: any = ''
  isEditCompany: Boolean = false;
  selectedCompany: any;
  selectedCompanyDetails: any;

  isEditProject: Boolean = false;
  selectedProjectid: any;
  selectedProjectDetails: any;
  isReadOnly: Boolean = false
  isCreateProject: Boolean = false;
  selectedTwilioNumber: any;
  closeResult = "";
  twilioSelected: Boolean = false
  twilioAvailableList: any = []
  countryList: any = []
  selectedCountry: any = null
  selectedCountryCode: any = 0
  colData: { displayName: string }[];
  isVoipSelected: any;
  getErrorMessage() {
    if (this.email.hasError("required")) {
      return "You must enter a value";
    }

    return this.email.hasError("email") ? "Not a valid email" : "";
  }
  constructor(
    private _formBuilder: FormBuilder,
    private uhleadsService: UhLeadsService,
    private CreateCompanyService: CreateCompanyService,
    private LeadsDashboardService: LeadsDashboardService,
    private fb: FormBuilder,
    private sanitizer: DomSanitizer,
    private route: ActivatedRoute,
    private router: Router,
    private modalService: NgbModal,
    public ExcelService: ExportExcelService

  ) {
    this.route.params.subscribe((data) => {
      if (data["companyid"] && data["projectid"]) { // Edit Project
        this.isEditProject = true
        this.selectedCompany = data["companyid"]
        this.selectedProjectid = data["projectid"]
      }
      else if (data["companyId"]) { // Edit Company
        this.isEditCompany = true
        this.selectedCompany = data["companyId"]
      }
      else if (data["company_id"]) { // Create Company
        this.isCreateProject = true
        this.selectedCompany = data["company_id"]
      }

    });
  }

  ngOnInit(): void {
    this.userData = JSON.parse(localStorage.getItem("userData"));

    this.SelectedProjects.push(this.ProjectDetails);

    console.log("ProjectDetials: ", this.SelectedProjects);

    this.rulesForm = this.fb.group({
      emails: this.fb.array([], [this.validateArrayNotEmpty]),
    });
    if (this.isEditCompany) {
      this.getCompanyDetailsById(this.selectedCompany)

    }
    else if (this.isEditProject) {
      this.getProjectDetailsById({ companyId: this.selectedCompany, projectId: this.selectedProjectid })

    }
    this.getProjectType()
    // this.getProjectDetailsById({companyId:49,projectId:154})
  }

  CreateCompany(form: NgForm) {
    console.log(":::::::::::::FORM::::::::::::::::::::");
    console.log(form);
    console.log(":::::::::::::::::::::::::::::::::", form.valid);


    if (form.valid) {
      let formData: FormData = new FormData();
      formData.append("companyname", this.CompanyDetails.CompanyName);
      formData.append("primaryContact", this.CompanyDetails.PrimaryPhoneNumber);
      formData.append(
        "primaryContCountryCode",
        this.CompanyDetails.PrimaryCountryCode.toString()
      );
      formData.append(
        "secondaryContact",
        this.CompanyDetails.SecondaryPhoneNumber
      );
      formData.append(
        "secondaryContCountryCode",
        this.CompanyDetails.SecondaryCountryCode.toString()
      );
      formData.append("primaryEmail", this.CompanyDetails.PrimaryEmail);
      formData.append("secondaryEmail", this.CompanyDetails.SecondaryEmail);
      formData.append("totalUsers", this.CompanyDetails.NoOfUsers);
      formData.append(
        "subscriptionStart",
        this.CompanyDetails.SubscriptionStartDate
      );
      formData.append(
        "subscriptionEnd",
        this.CompanyDetails.SubscriptionEndDate
      );
      formData.append(
        "companyFlatNumber_BuildingNo",
        this.CompanyDetails.FaltNo
      );
      formData.append("fullAddress", this.CompanyDetails.Address);
      formData.append("city", this.CompanyDetails.City);
      formData.append("pin_zip_code", this.CompanyDetails.Zip);
      formData.append("landmark", this.CompanyDetails.LandMark);
      formData.append("country", this.CompanyDetails.Country);
      formData.append("activeStatus", "true");
      formData.append("validity", this.CompanyDetails.SubscriptionEndDate);
      // formData.append("themeConfig", JSON.stringify(this.CompanyDetails.Theme));
      formData.append("voipName", this.CompanyDetails.Voip.voip);
      formData.append("domainName", this.CompanyDetails.domainName);
      formData.append("voipCountryCode", "0");
      formData.append("voipNumber", this.CompanyDetails.Voip.number ? this.CompanyDetails.Voip.number : "0");
      if (this.companyRegistDocuments[0]) {
        formData.append(
          "companyRegistDocuments",
          this.companyRegistDocuments[0],
          this.companyRegistDocuments[0].name
        );
      }

      if (this.companyPanDocument[0]) {
        formData.append(
          "companyPanDocument",
          this.companyPanDocument[0],
          this.companyPanDocument[0].name
        );
      }

      if (this.companyTanDocument[0]) {
        formData.append(
          "companyTanDocument",
          this.companyTanDocument[0],
          this.companyTanDocument[0].name
        );
      }
      if (this.companyLogo[0]) {
        formData.append(
          "companyLogo",
          this.companyLogo[0],
          this.companyLogo[0].name
        );
      }
      if (this.favIconLogo[0]) {
        formData.append(
          "favIconLogo",
          this.favIconLogo[0],
          this.favIconLogo[0].name
        );
      }
      if (this.companylogowithoutname[0]) {
        formData.append(
          "companylogowithoutname",
          this.companylogowithoutname[0],
          this.companylogowithoutname[0].name
        );
      }
      // formData.append("themeConfig", JSON.stringify(this.CompanyDetails.Theme));

      // if (
      //   this.CompanyDetails.Theme.sidebar_topbar_icon_color == "#000000" ||
      //   this.CompanyDetails.Theme.table_popup_button_color == "#000000" ||
      //   this.CompanyDetails.Theme.table_popup_links_color == "#000000"
      // ) {
      //   console.log("Form is invalid");
      //   this.uhleadsService.showWarning(
      //     "Please select the theme colors",
      //     "Missing colors"
      //   );
      //   return;
      // }
      this.isLoading = true
      this.isVoipSelected = this.CompanyDetails.Voip.voip != 'NoVOIP' ? true : false
      this.CreateCompanyService.createCompany(formData).subscribe(
        (res: any) => {
          this.twilioAvailableList = []

          if (res.status == 200) {
            if (this.CompanyDetails.Voip.voip == 'twilio' || this.CompanyDetails.Voip.voip == 'Twilio') {
              this.twilioSelected = true
            }
            // if(this.CompanyDetails.Voip.voip){
            //   this.isReadOnly=this.CompanyDetails.Voip.voip?false:true
            // }
            this.firstFormGroup.get("firstCtrl").clearValidators();
            this.firstFormGroup.get("firstCtrl").updateValueAndValidity();
            this.companyCreated = true;
            this.newCompanyDetails = res.company;
            this.countryList = res.twilioCountryList;
            console.log(this.countryList)
          } else if (res.status == 201) {
            if (this.CompanyDetails.Voip.voip == 'twilio' || this.CompanyDetails.Voip.voip == 'Twilio') {
              this.twilioSelected = true
            }
            this.companyCreated = true;
            this.newCompanyDetails = res.company;
            this.countryList = res.countryList;
          }
          else {
            this.noRecords = true

          }
          this.companyPanDocument = [];
          this.companyTanDocument = [];
          this.companyRegistDocuments = [];
          this.companyLogo = [];
          this.favIconLogo = [];
          this.companylogowithoutname = [];
          this.CompanyDetails = {
            CompanyName: "",
            NoOfUsers: "",
            PrimaryCountryCode: 91,
            PrimaryPhoneNumber: "",
            SecondaryCountryCode: 91,
            SecondaryPhoneNumber: "",
            PrimaryEmail: "",
            SecondaryEmail: "",
            Voip: {
              voip: "",
              number: "",
            },
            SubscriptionStartDate: "",
            SubscriptionEndDate: "",
            FaltNo: "",
            Address: "",
            City: "",
            Zip: "",
            LandMark: "",
            Country: "",
            Documents: "",
            Theme: {
              sidebar_topbar_text_color: "#000000",
              sidebar_topbar_icon_color: "#000000",
              table_popup_bg_color: "#000000",
              table_popup_button_color: "#000000",
              table_popup_links_color: "#000000",
            },
            favIconLogo: "",
            companyLogo: "",
            companyTanDocument: "",
            companyPanDocument: "",
            companyRegistDocuments: "",
            companylogowithoutname: "",
            domainName: ""

          };
          console.log("Company Details: ", this.newCompanyDetails);
          if (this.twilioSelected) {
            this.uhleadsService.showSuccess("Click Next to select number", "Success")
          }
          else {
            this.uhleadsService.showSuccess("Click Next to Create Project", "Success")
          }
          this.isLoading = false

        }
      );
    } else {
      console.log("Form is invalid");
      this.uhleadsService.showWarning(
        "Please fill all the fields",
        "Missing Fields"
      );
    }
  }
  onChnageSubscriptionStartDate(event) {
    let d = new Date(event.target['value'])

    console.log(event)
    console.log("Date", d)
    this.CompanyDetails.SubscriptionEndDate = moment(d).add(1, 'd').format("YYYY-MM-DD")
    this.minSubscriptionEndDate = moment(d).add(1, 'd').format("YYYY-MM-DD")
  }

  CreateProject(form2: NgForm) {
    console.log(this.feedBacks);

    this.isLoading = true
    if (form2.valid) {
      console.log("Valid project form");
      this.ProjectDetails.Modules.leadManagement.feedback = this.feedBacks;
      this.ProjectDetails.Modules.leadManagement.columns =
        this.leadTableColoumn;
      this.ProjectDetails.Modules.leadManagement.channel = this.channel;

      console.log("Project Info: ", this.SelectedProjects);

      let payload = {
        companyId: this.newCompanyDetails ? this.newCompanyDetails.companyId : this.selectedCompany,
        // companyId: 49,
        // companyId: 2,
        projects: [
          {
            projectName: this.ProjectDetails.ProjectName,
            voipIncoimngNumberCode: toInteger(
              this.ProjectDetails.ProjectCountryCode
            ),
            voipIncoimngNumber: toInteger(
              this.ProjectDetails.ProjectTelephoneNumber
            ),
            voipOutgoingNumberCode: toInteger(
              this.ProjectDetails.ProjectOutgoingCountryCode
            ),
            voipOutgoingNumber: toInteger(
              this.ProjectDetails.ProjectOutgoingNumber
            ),
            projectEmailServices: this.ProjectDetails.EmailService.choice,
            incomingMailService: this.ProjectDetails.EmailService.incoming,
            outgoingMailService: this.ProjectDetails.EmailService.outgoing,
            mailGroupService: this.ProjectDetails.EmailService.mailGroup,
            projectSmsServices: this.ProjectDetails.SmsService,
            incomingSmsService: this.ProjectDetails.SmsService,
            outgoingSmsService: this.ProjectDetails.SmsService,
            projectWhatsappServices: this.ProjectDetails.WhatsappService,
            incomingWhatsappService: this.ProjectDetails.WhatsappService,
            outgoingWhatsappService: this.ProjectDetails.WhatsappService,
            // projectType: this.ProjectDetails.ProjectType=='other'?this.ProjectDetails.ProjectOther:this.ProjectDetails.ProjectType,
            ProjectTypeOther: this.ProjectDetails.ProjectOther,
            projectType: this.ProjectDetails.ProjectType,
            modules: [],
            tables: [
              {
                leads: [],
                activities: this.activitiesTable,
                calldetails: this.calldetailsTable,
              },
            ],
            cards: [
              {
                type: "total",
                name: "Total Leads",
                value: "total",
                asset: "url",
              },
              {
                type: "assignedToMe",
                name: "Assigned To Me",
                value: "assignedtome",
                asset: "url",
              },
              // {
              //   type: "channel",
              //   value: "ChannelName1",
              //   name: "ChannelName1",
              //   asset: "url",
              // },
              // {
              //   type: "channel",
              //   value: "ChannelName2",
              //   name: "ChannelName2",
              //   asset: "url",
              // },
              // {
              //   type: "status",
              //   value: "StatusName",
              //   name: "StatusName",
              //   asset: "url",
              // },
            ],
            feedback: this.feedBacks.subtasks,
          },
        ],
      };

      if (this.ProjectDetails.Modules.leadManagement.LeadManagement == true) {
        payload.projects[0].modules.push({ modeule: "Lead Management" });

        for (let i = 0; i < this.leadTableColoumn.subtasks.length; i++) {
          if (this.leadTableColoumn.subtasks[i].selected == true) {
            payload.projects[0].tables[0].leads.push({
              name: this.leadTableColoumn.subtasks[i].name,
              displayName: this.leadTableColoumn.subtasks[i].displayName,
              type: this.leadTableColoumn.subtasks[i].type,
              selected: this.leadTableColoumn.subtasks[i].selected,
              showInDashboard: this.leadTableColoumn.subtasks[i].selected,
              showInOverview: this.leadTableColoumn.subtasks[i].selected,
            });
          }
        }

        for (let i = 0; i < this.channel.subtasks.length; i++) {
          payload.projects[0].cards.push({
            type: "channel",
            name: this.channel.subtasks[i].name,
            value: toLower(this.channel.subtasks[i].name),
            asset: "url",
          });
        }
      }

      console.log('Payload: ', payload)

      this.CreateCompanyService.createProject(payload).subscribe((res: any) => {
        if (res.status == 200) {
          this.secondFormGroup.get("secondCtrl").clearValidators();
          this.secondFormGroup.get("secondCtrl").updateValueAndValidity();
          this.projectCreated = true;
          console.log("Projects List: ", res.ListOfProjectList);
          console.log("addedProjects: ", this.addedProjects);
          this.addedProjects.push(res.lastProjectList)
          this.newProjectDetails = this.addedProjects;
          // this.newProjectDetails=res.lastProjectList

          for (let i = 0; i < this.newProjectDetails.length; i++) {
            this.newProjectDetails[i].selected = false;
            this.newProjectDetails[i].role = "";
          }
          console.log("Projects List: ", this.newProjectDetails);
          this.getProjectType()
          this.DeleteProject(0)
          this.AddProject();

        } else {
          this.projectCreated = false;
          this.DeleteProject(0);
          this.AddProject();
        }
        this.isLoading = false
      });
    } else {
      console.log("inValid project form");
      this.isLoading = false

      this.uhleadsService.showWarning(
        "Please fill all the fields",
        "Missing Fields"
      );
    }
  }

  AddProject() {
    // this.addedProjects.push(this.ProjectDetails);
    // this.DeleteProject(0);
    this.SelectedProjects.push(this.ProjectDetails);
  }

  DeleteProject(index) {
    this.SelectedProjects.splice(index, 1);
    this.ProjectDetails.ProjectName = "",
      this.ProjectDetails.ProjectType = "",
      this.ProjectDetails.ProjectOther = "",
      this.ProjectDetails.ProjectCountryCode = "91",
      this.ProjectDetails.ProjectTelephoneNumber = "",
      this.ProjectDetails.ProjectOutgoingCountryCode = "91",
      this.ProjectDetails.ProjectOutgoingNumber = "",
      this.ProjectDetails.EmailService.choice = false,
      this.ProjectDetails.EmailService.incoming = "",
      this.ProjectDetails.EmailService.outgoing = "",
      this.ProjectDetails.EmailService.mailGroup = "",

      this.ProjectDetails.SmsService = false,
      this.ProjectDetails.WhatsappService = false,
      this.ProjectDetails.Modules.leadManagement.LeadManagement = false
    this.ProjectDetails.Modules.leadManagement.columns = []
    this.ProjectDetails.Modules.leadManagement.feedback = []
    this.ProjectDetails.Modules.leadManagement.channel = []
    this.isClass = false
    // this.ProjectDetails.Modules={
    //   leadManagement: {
    //     LeadManagement: false,
    //     columns: [],
    //     feedback: [],
    //     channel: [],
    //   },
    // }
  }

  updateAllComplete() {
    this.allComplete =
      this.feedBacks.subtasks != null &&
      this.feedBacks.subtasks.every((t) => t.selected);
    console.log();
  }

  someComplete(): boolean {
    if (this.feedBacks.subtasks == null) {
      return false;
    }
    return (
      this.feedBacks.subtasks.filter((t) => t.selected).length > 0 &&
      !this.allComplete
    );
  }

  setAll(selected: boolean) {
    this.allComplete = selected;
    if (this.feedBacks.subtasks == null) {
      return;
    }
    this.feedBacks.subtasks.forEach((t) => (t.selected = selected));
  }
  openState(event?: any) {

    if (event) {
      console.log(event.checked)
      this.isClass = event.checked
      this.ProjectDetails.Modules.leadManagement.LeadManagement = event.checked

    }
    else {
      if (this.isClass == true) {
        this.isClass = false;
        this.ProjectDetails.Modules.leadManagement.LeadManagement = false;
      } else {
        this.isClass = true;
        console.log("selected Lead management");
        this.ProjectDetails.Modules.leadManagement.LeadManagement = true;
      }
    }
  }

  addFeedback(feedback, index) {
    this.feedBacks.selected = true;
    if (this.feedBacks.subtasks[index].selected == false) {
      console.log("Feedback added: ", feedback);
      this.feedBacks.subtasks[index].selected = true;
    } else {
      this.feedBacks.subtasks[index].selected = false;
      console.log("Feedback removed: ", feedback);
    }
    console.log("Feedbacks: ", this.feedBacks.subtasks);
  }

  selectAllFeedbacks() {
    if (this.feedBacks.selected == false) {
      this.feedBacks.selected = true;
      console.log("Selected all feedbacks");
      for (let i = 0; i < this.feedBacks.subtasks.length; i++) {
        this.feedBacks.subtasks[i].selected = true;
      }
    } else {
      this.feedBacks.selected = false;
      for (let i = 0; i < this.feedBacks.subtasks.length; i++) {
        this.feedBacks.subtasks[i].selected = false;
      }
      console.log("removed all feedbacks");
    }
  }

  addChannel(channel, index) {
    this.channel.selected = true;
    if (this.channel.subtasks[index].selected == false) {
      console.log("channel added: ", channel);
      this.channel.subtasks[index].selected = true;
    } else {
      this.channel.subtasks[index].selected = false;
      console.log("channel removed: ", channel);
    }
    console.log("Channels: ", this.channel.subtasks);
  }

  selectAllChannels() {
    if (this.channel.selected == false) {
      this.channel.selected = true;
      console.log("Selected all channels");
      for (let i = 0; i < this.channel.subtasks.length; i++) {
        this.channel.subtasks[i].selected = true;
      }
    } else {
      this.channel.selected = false;
      for (let i = 0; i < this.channel.subtasks.length; i++) {
        this.channel.subtasks[i].selected = false;
      }
      console.log("removed all channels");
    }
  }

  addColumns(column, index) {
    this.leadTableColoumn.selected = true;
    if (this.leadTableColoumn.subtasks[index].selected == false) {
      console.log("leadTableColoumn added: ", column);
      this.leadTableColoumn.subtasks[index].selected = true;
    } else {
      this.leadTableColoumn.subtasks[index].selected = false;
      console.log("leadTableColoumn removed: ", column);
    }
    console.log("leadTableColoumn: ", this.leadTableColoumn.subtasks);
  }

  selectAllColumns() {
    if (this.leadTableColoumn.selected == false) {
      this.leadTableColoumn.selected = true;
      console.log("Selected all leadTableColoumn");
      for (let i = 0; i < this.leadTableColoumn.subtasks.length; i++) {
        this.leadTableColoumn.subtasks[i].selected = true;
      }
    } else {
      this.leadTableColoumn.selected = false;
      for (let i = 0; i < this.leadTableColoumn.subtasks.length; i++) {
        this.leadTableColoumn.subtasks[i].selected = false;
      }
      console.log("removed all leadTableColoumn");
    }
  }
  selectedRoles() {

    // let selectedRecord = this.newProjectDetails.map(x=>x.selected==true)
    // let ProjectTypeStudentStudyAbroad = this.newProjectDetails.map(x=>x.projectType=='Student Study Abroad' && x.selected==true)
    // let ProjectTypeNotStudentStudyAbroad = this.newProjectDetails.map(x=>x.projectType!='Student Study Abroad' && x.selected==true)
    // if(selectedRecord.filter(y=>y==true).length == ProjectTypeStudentStudyAbroad.filter(y=>y==true).length){
    //   this.roles=[
    //     {name:'Administrator',value:'admin'},
    //     {name:'Agent',value:'agent'},
    //     {name:'Counsellors',value:'counsellors'},
    //     {name:'Admission Specialist',value:'admission specialist'}
    //   ]
    // }
    // else if(selectedRecord.filter(y=>y==true).length == ProjectTypeNotStudentStudyAbroad.filter(y=>y==true).length){
    //   this.roles=[
    //     {name:'Administrator',value:'admin'},
    //     {name:'Agent',value:'agent'}
    //   ]
    // }
    // else{
    //  console.log("Please select same project type",Data)

    // }


    console.log("Roles: ", this.newProjectDetails);
    console.log(this.emailFormControl.valid);
    console.log(this.emailFormControl.hasError("email"));
    console.log(this.emailFormControl.hasError("required"));
  }

  inviteUsers() {
    let usersList = [];
    let projects = [];
    if (this.emailFormControl.valid) {
      for (let i = 0; i < this.newProjectDetails.length; i++) {
        if (this.newProjectDetails[i].selected == true) {
          projects.push({
            projectId: this.newProjectDetails[i].projectId,
            role: this.newProjectDetails[i].role,
          });
        }
      }

      usersList.push({
        inviteEmails: this.inviteEmail,
        projects: projects,
        // projectId: this.newProjectDetails[i].projectId,
        // assignedRoles: this.newProjectDetails[i].role,
      });

      console.log("userList: ", usersList);
      let payload = {
        userId: this.userData.id,
        mail: this.userData.mail,
        token: this.userData.token,
        emailBody: "Inviting USer",
        companyId: this.newCompanyDetails ? this.newCompanyDetails.companyId : this.selectedCompany,
        inviteUsersProjects: usersList,
      };
      this.LeadsDashboardService.inviteUSers(payload).subscribe((res: any) => {
        console.log("Invite Users: ", res);
      });
    } else {
      this.uhleadsService.showError("Please enter a valid email", "Invalid!");
    }
  }
  getFileDetails(e, type) {

    switch (type) {
      case "companyRegistDocuments":
        this.companyRegistDocuments = e.target.files;

        break;
      case "companyPanDocument":
        this.companyPanDocument = e.target.files;
        break;
      case "companyTanDocument":
        this.companyTanDocument = e.target.files;
        break;
      case "companyLogo":

        this.companyLogo = e.target.files;
        if (this.companyLogo[0]) {
          var reader = new FileReader();
          reader.readAsDataURL(this.companyLogo[0])
          reader.onload = (event: any) => {

            let tempurl: any = this.sanitizer.bypassSecurityTrustUrl(event.target.result);
            this.previewCompanyURL = tempurl
          }

        }

        break;
      case "favIconLogo":
        this.favIconLogo = e.target.files;
        if (this.favIconLogo[0]) {
          var reader = new FileReader();
          reader.readAsDataURL(this.favIconLogo[0])
          reader.onload = (event: any) => {

            let tempurl: any = this.sanitizer.bypassSecurityTrustUrl(event.target.result);
            this.previewFaviconURL = tempurl

          }
        }
        break;
      case "companylogowithoutname":
        this.companylogowithoutname = e.target.files;
        if (this.companylogowithoutname[0]) {
          var reader = new FileReader();
          reader.readAsDataURL(this.companylogowithoutname[0])
          reader.onload = (event: any) => {

            let tempurl: any = this.sanitizer.bypassSecurityTrustUrl(event.target.result);
            this.previewCompanyLogoWithoutNameURL = tempurl

          }
        }
        break;
      default:
        break;
    }
  }
  deleteFileDetails(type) {

    switch (type) {
      case "companyRegistDocuments":
        this.companyRegistDocuments = [];
        this.CompanyDetails.companyRegistDocuments = ""
        break;
      case "companyPanDocument":
        this.companyPanDocument = [];
        this.CompanyDetails.companyPanDocument = ""
        break;
      case "companyTanDocument":
        this.companyTanDocument = [];
        this.CompanyDetails.companyTanDocument = ""
        break;
      default:
        break;
    }
  }

  removeEmail(data: any): void {
    console.log("Removing " + data);
    console.log("Has error: ", this.rulesForm.get('emails'))

    if (this.emailList.indexOf(data) >= 0) {
      this.emailList.splice(this.emailList.indexOf(data), 1);
    }
  }

  validateArrayNotEmpty(c: FormControl) {
    if (c.value && c.value.length === 0) {
      return {
        validateArrayNotEmpty: { valid: false },
      };
    }
    return null;
  }

  validateEmail(email) {
    var re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  add(event): void {
    console.log(event.value);
    console.log("Has error: ", this.rulesForm.get('emails').hasError('incorrectEmail'))
    if (event.value) {
      if (this.validateEmail(event.value)) {
        this.emailList.push({ value: event.value, invalid: false });
      } else {
        this.emailList.push({ value: event.value, invalid: true });
        this.rulesForm.controls["emails"].setErrors({ incorrectEmail: true });
      }
    }
    if (event.input) {
      event.input.value = "";
    }
  }

  inviteAgents() {
    console.log("Emails: ", this.emailList);
    console.log("Role: ", this.role);
    if (this.emailList.length > 0) {
      let usersList = [];
      let projects = [];

      for (let i = 0; i < this.newProjectDetails.length; i++) {
        if (this.newProjectDetails[i].selected == true) {
          projects.push({
            projectId: this.newProjectDetails[i].projectId,
            role: this.newProjectDetails[i].role,
          });
        }
      }
      for (let j = 0; j < this.emailList.length; j++) {
        if (this.emailList[j].invalid == false)
          usersList.push({
            inviteEmails: this.emailList[j].value,
            projects: projects,
            // projectId: this.newProjectDetails[i].projectId,
            // assignedRoles: this.newProjectDetails[i].role,
          });
      }


      // for (let i = 0; i < this.emailList.length; i++) {
      //   console.log(this.emailList[i]["value"]);
      //   console.log(this.validateEmail(this.emailList[i]["value"]));

      //   if (this.validateEmail(this.emailList[i]["value"])) {
      //     usersList.push({
      //       inviteEmails: this.emailList[i]["value"],
      //       projects: [
      //         {
      //           projectId: 76,
      //           role: this.role,
      //         },
      //       ],
      //     });
      //   }
      // }

      let payload = {
        userId: this.userData.id,
        mail: this.userData.mail,
        token: this.userData.token,
        emailBody: "Inviting USer",
        // companyId: 2,
        companyId: this.newCompanyDetails ? this.newCompanyDetails.companyId : this.selectedCompany,
        inviteUsersProjects: usersList,
      };

      console.log("Payload: ", payload);

      if (payload.inviteUsersProjects[0].projects.length > 0) {
        this.LeadsDashboardService.inviteUSers(payload).subscribe((res: any) => {
          if (res.status == 200) {
            this.uhleadsService.showSuccess("Users invited successfully", "Success");
            document.getElementById("closeInviteButton").click();
          } else {
            this.uhleadsService.showError("Something went wrong", "Error");
          }
        });
      } else {
        this.uhleadsService.showError(
          "Please enter emails and select a role",
          "Missing info"
        );
      }
    } else {
      this.uhleadsService.showError(
        "Please enter emails and select a role",
        "Missing info"
      );
    }
  }

  incomingfile(event) {
    this.file = event.target.files[0];
  }

  Upload() {
    let fileReader = new FileReader();
    fileReader.onload = (e) => {
      this.arrayBuffer = fileReader.result;
      var data = new Uint8Array(this.arrayBuffer);
      var arr = new Array();
      for (var i = 0; i != data.length; ++i)
        arr[i] = String.fromCharCode(data[i]);
      var bstr = arr.join("");
      var workbook = XLSX.read(bstr, { type: "binary" });
      var first_sheet_name = workbook.SheetNames[0];
      var worksheet = workbook.Sheets[first_sheet_name];
      console.log(XLSX.utils.sheet_to_json(worksheet, { raw: true }));
      let emailList = XLSX.utils.sheet_to_json(worksheet, { raw: true });
      // this.emailList = emailList;

      let usersList = [];
      let projects = [];
      if (
        emailList.length > 0 &&
        emailList[0]["Role"] &&
        emailList[0]["Email"]
      ) {

        for (let i = 0; i < this.newProjectDetails.length; i++) {
          if (this.newProjectDetails[i].selected == true) {
            projects.push({
              projectId: this.newProjectDetails[i].projectId,
              role: this.newProjectDetails[i].role,
            });
          }
        }
        // for(let j=0; j<this.emailList.length; j++) {
        //   if(this.emailList[j].invalid==false)
        //   usersList.push({
        //     inviteEmails: this.emailList[j].value,
        //     projects: projects,
        //     // projectId: this.newProjectDetails[i].projectId,
        //     // assignedRoles: this.newProjectDetails[i].role,
        //   });
        // }


        for (let i = 0; i < emailList.length; i++) {
          console.log(emailList[i]["Email"]);
          console.log(this.validateEmail(emailList[i]["Email"]));

          if (this.validateEmail(emailList[i]["Email"])) {
            // this.emailList.push({ value: emailList[i]["Email"], invalid: false})
            usersList.push({
              inviteEmails: emailList[i]["Email"],
              projects: projects,
            });
          }
        }

        let payload = {
          userId: this.userData.id,
          mail: this.userData.mail,
          token: this.userData.token,
          emailBody: "Inviting USer",
          // companyId: 2,
          companyId: this.newCompanyDetails ? this.newCompanyDetails.companyId : this.selectedCompany,
          inviteUsersProjects: usersList,
        };

        console.log("Payload: ", payload);

        if (payload.inviteUsersProjects[0].projects.length > 0) {
          this.LeadsDashboardService.inviteUSers(payload).subscribe((res: any) => {
            if (res.status == 200) {
              this.uhleadsService.showSuccess("Users invited successfully", "Success");
              document.getElementById("closeInviteButton").click();
            } else {
              this.uhleadsService.showError("Something went wrong", "Error");
            }
          })
        } else {
          this.uhleadsService.showError(
            "Please enter emails and select a role",
            "Missing info"
          );
        }

      } else {
        this.uhleadsService.showError(
          "Please upload a valid file",
          "invalid data"
        );
      }
    };
    fileReader.readAsArrayBuffer(this.file);
  }

  getPreviewDetails(Data) {

    // Company Logo
    if (Data) {
      var reader = new FileReader();
      reader.readAsDataURL(Data)
      reader.onload = (event: any) => {

        let tempurl: any = this.sanitizer.bypassSecurityTrustUrl(event.target.result);
        this.previewCompanyURL = tempurl

      }
    }
    else {
      this.previewCompanyURL = this.CompanyDetails.companyLogo
    }
    document.documentElement.style.setProperty('--preview-theme-prime-color', this.CompanyDetails.Theme.sidebar_topbar_icon_color);
  }

  getCompanyDetailsById(CompanyId) {
    this.CreateCompanyService.getcompanyById(CompanyId).subscribe((Data: any) => {
      if (Data.status) {
        this.selectedCompanyDetails = Data.data
        this.editForm()
      }
      else {
        this.uhleadsService.showWarning("No Data Found!", "");
      }
    })
  }
  isdomainName: Boolean = false
  editForm() {
    let themeconfig = this.selectedCompanyDetails.themeConfig.length ? JSON.parse(this.selectedCompanyDetails.themeConfig[0]) : null;
    this.CompanyDetails.CompanyName = this.selectedCompanyDetails.companyName
    this.CompanyDetails.Address = this.selectedCompanyDetails.fullAddress
    this.CompanyDetails.City = this.selectedCompanyDetails.city
    this.CompanyDetails.Country = this.selectedCompanyDetails.country
    this.CompanyDetails.FaltNo = this.selectedCompanyDetails.companyFlatNumber_BuildingNo
    this.CompanyDetails.LandMark = this.selectedCompanyDetails.landmark
    this.CompanyDetails.NoOfUsers = this.selectedCompanyDetails.totalUsers
    this.CompanyDetails.PrimaryCountryCode = this.selectedCompanyDetails.primaryContCountryCode
    this.CompanyDetails.PrimaryEmail = this.selectedCompanyDetails.primaryEmail
    this.CompanyDetails.PrimaryPhoneNumber = this.selectedCompanyDetails.primaryContact
    this.CompanyDetails.SecondaryCountryCode = this.selectedCompanyDetails.secondaryContCountryCode
    this.CompanyDetails.SecondaryEmail = this.selectedCompanyDetails.secondaryEmail
    this.CompanyDetails.SecondaryPhoneNumber = this.selectedCompanyDetails.secondaryContact
    this.CompanyDetails.SubscriptionEndDate = moment(this.selectedCompanyDetails.subscriptionEnd).format("YYYY-MM-DD")
    this.CompanyDetails.SubscriptionStartDate = moment(this.selectedCompanyDetails.subscriptionStart).format("YYYY-MM-DD")
    this.CompanyDetails.Zip = this.selectedCompanyDetails.pin_zip_code
    this.CompanyDetails.Voip.voip = this.selectedCompanyDetails.voipName ? this.selectedCompanyDetails.voipName : 'NoVOIP'
    this.CompanyDetails.Voip.number = this.selectedCompanyDetails.voipNumber ? this.selectedCompanyDetails.voipNumber : null
    this.CompanyDetails.domainName = this.selectedCompanyDetails.domainName ? this.selectedCompanyDetails.domainName : null
    this.isdomainName = this.selectedCompanyDetails.domainName ? true : false
    if (themeconfig) {
      this.CompanyDetails.Theme = {
        sidebar_topbar_text_color: themeconfig.sidebar_topbar_text_color,
        sidebar_topbar_icon_color: themeconfig.sidebar_topbar_icon_color,
        table_popup_bg_color: themeconfig.table_popup_bg_color,
        table_popup_button_color: themeconfig.table_popup_button_color,
        table_popup_links_color: themeconfig.table_popup_links_color,
      }
    }
    this.CompanyDetails.companyRegistDocuments = this.selectedCompanyDetails.companyRegistDocuments
    this.CompanyDetails.companyPanDocument = this.selectedCompanyDetails.companyPanDocument
    this.CompanyDetails.companyTanDocument = this.selectedCompanyDetails.companyTanDocument
    this.CompanyDetails.companyLogo = this.selectedCompanyDetails.companyLogo
    this.CompanyDetails.favIconLogo = this.selectedCompanyDetails.favIconLogo
    this.CompanyDetails.companylogowithoutname = this.selectedCompanyDetails.companylogowithoutname != 'error' ? this.selectedCompanyDetails.companylogowithoutname : ''

    this.previewCompanyURL = this.selectedCompanyDetails.companyLogo
    this.previewCompanyLogoWithoutNameURL = this.selectedCompanyDetails.companylogowithoutname != 'error' ? this.selectedCompanyDetails.companylogowithoutname : ''
    this.previewFaviconURL = this.selectedCompanyDetails.favIconLogo
    // this.CompanyDetails.Theme=this.selectedCompanyDetails.companyName

  }
  updateCompanyDetails(form: NgForm) {
    console.log(":::::::::::::FORM::::::::::::::::::::");
    console.log(form);
    console.log(":::::::::::::::::::::::::::::::::", form.valid);

    if (form.valid) {
      let formData: FormData = new FormData();
      formData.append("companyname", this.CompanyDetails.CompanyName);
      formData.append("primaryContact", this.CompanyDetails.PrimaryPhoneNumber);
      formData.append(
        "primaryContCountryCode",
        this.CompanyDetails.PrimaryCountryCode.toString()
      );
      formData.append(
        "secondaryContact",
        this.CompanyDetails.SecondaryPhoneNumber
      );
      formData.append(
        "secondaryContCountryCode",
        this.CompanyDetails.SecondaryCountryCode.toString()
      );
      formData.append("primaryEmail", this.CompanyDetails.PrimaryEmail);
      formData.append("secondaryEmail", this.CompanyDetails.SecondaryEmail);
      formData.append("totalUsers", this.CompanyDetails.NoOfUsers);
      formData.append(
        "subscriptionStart",
        this.CompanyDetails.SubscriptionStartDate
      );
      formData.append(
        "subscriptionEnd",
        this.CompanyDetails.SubscriptionEndDate
      );
      formData.append(
        "companyFlatNumber_BuildingNo",
        this.CompanyDetails.FaltNo
      );
      formData.append("fullAddress", this.CompanyDetails.Address);
      formData.append("city", this.CompanyDetails.City);
      formData.append("pin_zip_code", this.CompanyDetails.Zip);
      formData.append("landmark", this.CompanyDetails.LandMark);
      formData.append("country", this.CompanyDetails.Country);
      formData.append("activeStatus", "true");
      formData.append("validity", this.CompanyDetails.SubscriptionEndDate);
      formData.append("themeConfig", JSON.stringify(this.CompanyDetails.Theme));
      formData.append("voipName", this.CompanyDetails.Voip.voip);
      formData.append("voipCountryCode", "91");
      formData.append("voipNumber", this.CompanyDetails.Voip.number);
      if (this.companyRegistDocuments[0]) {
        formData.append(
          "companyRegistDocuments",
          this.companyRegistDocuments[0],
          this.companyRegistDocuments[0].name
        );
      }

      if (this.companyPanDocument[0]) {
        formData.append(
          "companyPanDocument",
          this.companyPanDocument[0],
          this.companyPanDocument[0].name
        );
      }

      if (this.companyTanDocument[0]) {
        formData.append(
          "companyTanDocument",
          this.companyTanDocument[0],
          this.companyTanDocument[0].name
        );
      }
      if (this.companyLogo[0]) {
        formData.append(
          "companyLogo",
          this.companyLogo[0],
          this.companyLogo[0].name
        );
      }
      if (this.favIconLogo[0]) {
        formData.append(
          "favIconLogo",
          this.favIconLogo[0],
          this.favIconLogo[0].name
        );
      }
      if (this.companylogowithoutname[0]) {
        formData.append(
          "companylogowithoutname",
          this.companylogowithoutname[0],
          this.companylogowithoutname[0].name
        );
      }

      if (
        this.CompanyDetails.Theme.sidebar_topbar_icon_color == "#000000" ||
        this.CompanyDetails.Theme.table_popup_button_color == "#000000" ||
        this.CompanyDetails.Theme.table_popup_links_color == "#000000"
      ) {
        console.log("Form is invalid");
        this.uhleadsService.showWarning(
          "Please select the theme colors",
          "Missing colors"
        );
        return;
      }
      formData.append("companyId", this.selectedCompany)
      this.CreateCompanyService.updateCompanyDetails(formData).subscribe(
        (res: any) => {
          if (res.status == 200) {
            this.companyCreated = true;
            this.newCompanyDetails = res.company;
          } else if (res.status == 201) {
            this.companyCreated = true;
            this.newCompanyDetails = res.company;
          }
          this.companyPanDocument = [];
          this.companyTanDocument = [];
          this.companyRegistDocuments = [];
          this.companyLogo = [];
          this.favIconLogo = [];
          this.CompanyDetails = {
            CompanyName: "",
            NoOfUsers: "",
            PrimaryCountryCode: 91,
            PrimaryPhoneNumber: "",
            SecondaryCountryCode: 91,
            SecondaryPhoneNumber: "",
            PrimaryEmail: "",
            SecondaryEmail: "",
            Voip: {
              voip: "",
              number: "",
            },
            SubscriptionStartDate: "",
            SubscriptionEndDate: "",
            FaltNo: "",
            Address: "",
            City: "",
            Zip: "",
            LandMark: "",
            Country: "",
            Documents: "",
            Theme: {
              sidebar_topbar_text_color: "#000000",
              sidebar_topbar_icon_color: "#000000",
              table_popup_bg_color: "#000000",
              table_popup_button_color: "#000000",
              table_popup_links_color: "#000000",
            },
            favIconLogo: "",
            companyLogo: "",
            companyTanDocument: "",
            companyPanDocument: "",
            companyRegistDocuments: "",
            companylogowithoutname: "",
            domainName: ""

          };
          console.log("Company Details: ", this.newCompanyDetails);
          this.uhleadsService.showSuccess("Company Details Updated Successfully", "Success")
          this.router.navigate(['/company'])
        }
      );
    } else {
      console.log("Form is invalid");
      this.uhleadsService.showWarning(
        "Please fill all the fields",
        "Missing Fields"
      );
    }
  }

  getProjectDetailsById(Data) {
    let payload = {
      projectId: Data.projectId,
      CompanyId: Data.CompanyId
    }
    this.CreateCompanyService.getProjectById(payload).subscribe((Data: any) => {

      if (Data.status) {
        this.selectedProjectDetails = Data.data
        // this.newProjectDetails=[Data.data[0]]
        this.editProjectForm()
      }
      else {
        this.uhleadsService.showWarning("No Data Found!", "");
      }
    })
  }
  editProjectForm() {

    this.ProjectDetails.ProjectName = this.selectedProjectDetails[0].projectName
    this.ProjectDetails.ProjectOutgoingNumber = this.selectedProjectDetails[0].voipOutgoingNumber
    this.ProjectDetails.ProjectType = this.selectedProjectDetails[0].projectType
    this.ProjectDetails.ProjectOther = this.selectedProjectDetails[0].projectTypeOther
    this.ProjectDetails.ProjectOutgoingCountryCode = this.selectedProjectDetails[0].voipOutgoingNumberCode
    this.ProjectDetails.ProjectTelephoneNumber = this.selectedProjectDetails[0].voipIncoimngNumber
    this.ProjectDetails.ProjectCountryCode = this.selectedProjectDetails[0].voipIncoimngNumberCode
    this.ProjectDetails.SmsService = this.selectedProjectDetails[0].projectSmsServices
    this.ProjectDetails.WhatsappService = this.selectedProjectDetails[0].projectWhatsappServices
    this.ProjectDetails.EmailService.choice = this.selectedProjectDetails[0].projectEmailServices
    this.ProjectDetails.EmailService.incoming = this.selectedProjectDetails[0].incomingMailService
    this.ProjectDetails.EmailService.outgoing = this.selectedProjectDetails[0].outgoingMailService
    this.ProjectDetails.EmailService.mailGroup = this.selectedProjectDetails[0].mailGroupService
    if (this.selectedProjectDetails.CompanyDetails[0]) {
      this.isReadOnly = this.selectedProjectDetails.CompanyDetails[0].voipName ? false : true
    }
    let leadManagement = this.selectedProjectDetails[0].modules.filter(z => z.modeule == 'Lead Management')
    this.isClass = leadManagement.length ? true : false
    this.ProjectDetails.Modules.leadManagement.LeadManagement = leadManagement.length ? true : false

  }
  UpdateProject(form2: NgForm) {
    if (form2.valid) {
      console.log("Valid project form");
      this.ProjectDetails.Modules.leadManagement.feedback = this.feedBacks;
      this.ProjectDetails.Modules.leadManagement.columns =
        this.leadTableColoumn;
      this.ProjectDetails.Modules.leadManagement.channel = this.channel;

      console.log("Project Info: ", this.SelectedProjects);

      let payload = {
        projectId: this.selectedProjectid,
        companyId: this.selectedCompany,
        // companyId: 2,
        projects: [
          {
            projectName: this.ProjectDetails.ProjectName,
            voipIncoimngNumberCode: toInteger(
              this.ProjectDetails.ProjectCountryCode
            ),
            voipIncoimngNumber: toInteger(
              this.ProjectDetails.ProjectTelephoneNumber
            ),
            voipOutgoingNumberCode: toInteger(
              this.ProjectDetails.ProjectOutgoingCountryCode
            ),
            voipOutgoingNumber: toInteger(
              this.ProjectDetails.ProjectOutgoingNumber
            ),
            projectEmailServices: this.ProjectDetails.EmailService.choice,
            incomingMailService: this.ProjectDetails.EmailService.incoming,
            outgoingMailService: this.ProjectDetails.EmailService.outgoing,
            mailGroupService: this.ProjectDetails.EmailService.mailGroup,
            projectSmsServices: this.ProjectDetails.SmsService,
            incomingSmsService: this.ProjectDetails.SmsService,
            outgoingSmsService: this.ProjectDetails.SmsService,
            projectWhatsappServices: this.ProjectDetails.WhatsappService,
            incomingWhatsappService: this.ProjectDetails.WhatsappService,
            outgoingWhatsappService: this.ProjectDetails.WhatsappService,
            ProjectTypeOther: this.ProjectDetails.ProjectOther,
            projectType: this.ProjectDetails.ProjectType,
            modules: [],
            tables: [
              {
                leads: [],
                activities: this.activitiesTable,
                calldetails: this.calldetailsTable,
              },
            ],
            cards: [
              {
                type: "total",
                name: "Total Leads",
                value: "total",
                asset: "url",
              },
              {
                type: "assignedToMe",
                name: "Assigned To Me",
                value: "assignedtome",
                asset: "url",
              },
              // {
              //   type: "channel",
              //   value: "ChannelName1",
              //   name: "ChannelName1",
              //   asset: "url",
              // },
              // {
              //   type: "channel",
              //   value: "ChannelName2",
              //   name: "ChannelName2",
              //   asset: "url",
              // },
              // {
              //   type: "status",
              //   value: "StatusName",
              //   name: "StatusName",
              //   asset: "url",
              // },
            ],
            feedback: this.feedBacks.subtasks,
          },
        ],
      };

      if (this.ProjectDetails.Modules.leadManagement.LeadManagement == true) {
        payload.projects[0].modules.push({ modeule: "Lead Management" });

        for (let i = 0; i < this.leadTableColoumn.subtasks.length; i++) {
          if (this.leadTableColoumn.subtasks[i].selected == true) {
            payload.projects[0].tables[0].leads.push({
              name: this.leadTableColoumn.subtasks[i].name,
              displayName: this.leadTableColoumn.subtasks[i].displayName,
              type: this.leadTableColoumn.subtasks[i].type,
              selected: this.leadTableColoumn.subtasks[i].selected,
              showInDashboard: this.leadTableColoumn.subtasks[i].selected,
              showInOverview: this.leadTableColoumn.subtasks[i].selected,
            });
          }
        }

        for (let i = 0; i < this.channel.subtasks.length; i++) {
          payload.projects[0].cards.push({
            type: "channel",
            name: this.channel.subtasks[i].name,
            value: toLower(this.channel.subtasks[i].name),
            asset: "url",
          });
        }
      }

      console.log('Payload: ', payload)

      this.CreateCompanyService.updateProjectDetails(payload).subscribe((res: any) => {
        if (res.status == 200) {
          this.uhleadsService.showSuccess("Project Details Updated Successfully", "Success")
          this.router.navigate(['/projects/' + this.selectedCompany])
        } else {

        }
      });
    } else {
      console.log("inValid project form");
      this.uhleadsService.showWarning(
        "Please fill all the fields",
        "Missing Fields"
      );
    }

  }
  onChange(Data) {
    if (Data.target['value'] != 'other') {
      this.ProjectDetails.ProjectOther = null
    }
    this.ProjectDetails.ProjectType = Data.target['value']
  }
  resetCompanyForm() {
    this.companyPanDocument = [];
    this.companyTanDocument = [];
    this.companyRegistDocuments = [];
    this.companyLogo = [];
    this.favIconLogo = [];
    this.CompanyDetails = {
      CompanyName: "",
      NoOfUsers: "",
      PrimaryCountryCode: 91,
      PrimaryPhoneNumber: "",
      SecondaryCountryCode: 91,
      SecondaryPhoneNumber: "",
      PrimaryEmail: "",
      SecondaryEmail: "",
      Voip: {
        voip: "",
        number: "",
      },
      SubscriptionStartDate: "",
      SubscriptionEndDate: "",
      FaltNo: "",
      Address: "",
      City: "",
      Zip: "",
      LandMark: "",
      Country: "",
      Documents: "",
      Theme: {
        sidebar_topbar_text_color: "#000000",
        sidebar_topbar_icon_color: "#000000",
        table_popup_bg_color: "#000000",
        table_popup_button_color: "#000000",
        table_popup_links_color: "#000000",
      },
      favIconLogo: "",
      companyLogo: "",
      companyTanDocument: "",
      companyPanDocument: "",
      companyRegistDocuments: "",
      companylogowithoutname: "",
      domainName: ""

    };
    this.previewCompanyLogoWithoutNameURL = null
    this.previewCompanyURL = null
    this.previewFaviconURL = null
  }
  resetForm() {
    this.ProjectDetails.ProjectName = "",
      this.ProjectDetails.ProjectType = "",
      this.ProjectDetails.ProjectOther = "",
      this.ProjectDetails.ProjectCountryCode = "91",
      this.ProjectDetails.ProjectTelephoneNumber = "",
      this.ProjectDetails.ProjectOutgoingCountryCode = "91",
      this.ProjectDetails.ProjectOutgoingNumber = "",
      this.ProjectDetails.EmailService.choice = false,
      this.ProjectDetails.EmailService.incoming = "",
      this.ProjectDetails.EmailService.outgoing = "",
      this.ProjectDetails.EmailService.mailGroup = "",

      this.ProjectDetails.SmsService = false,
      this.ProjectDetails.WhatsappService = false,
      this.ProjectDetails.Modules.leadManagement.LeadManagement = false
    this.ProjectDetails.Modules.leadManagement.columns = []
    this.ProjectDetails.Modules.leadManagement.feedback = []
    this.ProjectDetails.Modules.leadManagement.channel = []
    this.isClass = false
  }
  projectList() {
    this.router.navigate(['/projects/' + this.selectedCompany])
  }
  getProjectType() {
    this.CreateCompanyService.getProjectType().subscribe((Data: any) => {
      if (Data.status == true) {
        this.ProjectTypeList = Data.data
      }
    })
  }
  openConfirmationForm(content1, item) {
    this.selectedTwilioNumber = item

    this.modalService.open(content1, { size: "sm" }).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );

  }
  private getDismissReason(reason: any): string {

    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }

  updateCompanyNumber() {
    this.twilioSelected = false

    let payload = {
      companyId: this.newCompanyDetails.companyId,
      phoneNumber: this.selectedTwilioNumber.phoneNumber ? this.selectedTwilioNumber.phoneNumber : this.selectedTwilioNumber.phone_number
    }
    this.CreateCompanyService.selectTwilioNumber(payload).subscribe((Data: any) => {
      if (Data.status == true) {
        this.uhleadsService.showSuccess("Please Create Project", "Success")
        this.modalService.dismissAll()

      }
    })


  }

  selectCountry(event) {
    console.log(event)
    this.selectedCountry = this.countryList.filter(x => x.countryCode == event)[0]['country']
    let payload = {
      companyId: this.newCompanyDetails.companyId,
      countryCode: event,
      subresourceUris: this.countryList.filter(x => x.countryCode == event)[0]['subresourceUris']
    }
    this.isLoading = true
    this.twilioAvailableList = []
    this.CreateCompanyService.getNumberList(payload).subscribe((res: any) => {
      if (res.status == true) {
        let tempList = []
        if (res.Data.MobileNumber) tempList.push(res.Data.MobileNumber)
        if (res.Data.TollFreeNumber) tempList.push(res.Data.TollFreeNumber)
        if (res.Data.localNumber) tempList.push(res.Data.localNumber)
        this.twilioAvailableList = tempList[0]
        if (this.twilioAvailableList.length == 0) {
          this.noRecords = true

        }
      }
      else {
        this.noRecords = true
      }
      this.isLoading = false

    })
  }
  downloadTemplate() {
    this.colData = [{ displayName: "Email" }, { displayName: "Role" }];
    let colNames = [{}];
    // console.log(this.colData)
    for (let i = 0; i < this.colData.length; i++) {
      colNames[0][this.colData[i].displayName] = "";
    }
    console.log(colNames);
    this.ExcelService.exportAsExcelFile(colNames, "sample_file");

    console.log("Generate Excel file");
  }
  downLoadDocument(url, name) {
    if (url[0]) {
      var reader = new FileReader();
      reader.readAsDataURL(url[0])
      reader.onload = (event: any) => {
        let tempurl: any = this.sanitizer.bypassSecurityTrustUrl(event.target.result);
        const link = document.createElement("a");
        link.setAttribute("target", "_blank");
        link.setAttribute("type", "hidden");
        link.setAttribute("href", tempurl['changingThisBreaksApplicationSecurity']);
        link.setAttribute("download", name);
        document.body.appendChild(link);
        link.click();
        link.remove();
      }
    }

  }
  selectedIndex: number = 0;
  setIndex(event) {
    this.selectedIndex = event.selectedIndex;
  }

  triggerClick() {
    console.log(`Selected tab index: ${this.selectedIndex}`);
  }
  click() {
    // this.firstFormGroup.controls['firstCtrl'].setValue("Test")
    this.firstFormGroup.get('firstCtrl').clearValidators()
    this.firstFormGroup.get('firstCtrl').updateValueAndValidity()
  }
}
