import { ActivatedRoute } from "@angular/router";
import { MailserviceService } from "./../mailservice.service";
import { SharedService } from "src/app/shared/shared.service";
import { Ticket } from "./../dashboard/models/ticket.model";
import {
  ModalDismissReasons,
  NgbCalendar,
  NgbDate,
  NgbModal,
} from "@ng-bootstrap/ng-bootstrap";
import { Component, OnInit } from "@angular/core";
import { DashboardService } from "../dashboard/dashboard.service";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { AuthService } from "src/app/account/auth/auth.service";
import { TicketsService } from "../tickets/tickets.service";
import { IDropdownSettings } from "ng-multiselect-dropdown";
import { CreateAgent } from "../create-agent/create-agent.service";
import { ProjectchangeService } from "src/app/layout/project-change/projectchange.service";
import { ErrorHandlerService } from "src/app/error-handler.service";

interface PendingSelection {
  [key: number]: boolean;
}

@Component({
  selector: "app-custom-table",
  templateUrl: "./custom-table.component.html",
  styleUrls: ["./custom-table.component.less"],
})
export class CustomTableComponent implements OnInit {
  public selectedColumns: any[] = [];
  public unSelectedColumns: any[] = [];
  public tickets: Ticket[] = [];
  public pendingSelection: PendingSelection;
  public userData: any;
  public closeResult: string;
  public page: any = 1;
  public pageSize: any = 10;
  public loading: { displayColumns: boolean; remainingColumns: boolean } = {
    displayColumns: false,
    remainingColumns: false,
  };
  public superAdmin: Boolean;
  public projectId: number;
  public search: any;
  public fromDate: NgbDate;
  public toDate: NgbDate;
  public queryParams: { admin: Boolean; page: string; status: string };
  public admin: Boolean = false;
  public TicketCount: number = 0;
  public dropdownSettings: IDropdownSettings;
  public contacts: any[];
  public selectAgent: any[] = [];
  public order: boolean = false;
  public orderFeild: string = "UpdatedDate";
  public orderString: boolean | "asc" | "desc" = "desc";

  // public page: number = 1;

  constructor(
    private modalService: NgbModal,
    private dashboardService: DashboardService,
    private sharedService: SharedService,
    private mailService: MailserviceService,
    private authService: AuthService,
    public ticketService: TicketsService,
    public route: ActivatedRoute,
    private calendar: NgbCalendar,
    private agentService: CreateAgent,
    public projectchangeService: ProjectchangeService,
    private handleError: ErrorHandlerService,

  ) { }

  ngOnInit() {
    // this.page = 1;
    this.TicketCount = 0;
    this.getColumns();
    this.fromDate = this.calendar.getNext(this.calendar.getToday(), "m", -1);
    this.toDate = this.calendar.getToday();
    this.dropdownSettings = {
      singleSelection: true,
      allowSearchFilter: true,
      closeDropDownOnSelection: true,
      textField: "Name",
    };

    this.userData = JSON.parse(localStorage.getItem("userData"));
    this.route.params.subscribe((param) => {
      if (param.pageName === "My Group Tickets") {
        this.queryParams = {
          page: "mygroups",
          status: "open",
          admin: this.admin,
        };
        this.sharedService.pageEvent.emit({
          pageName: "My Group Tickets",
          togglePage: false,
        });
      } else {
        this.queryParams = {
          page: "closeTickets",
          status: "closed",
          admin: this.admin,
        };
        this.sharedService.pageEvent.emit({
          pageName: "My Group Closed Tickets",
          togglePage: false,
        });
      }
      // this.sharedService.projectId.subscribe((project) => {
      //   if (project) {
      //     this.projectId = project;
      //     this.sharedService.getProjectAdmin(project).subscribe((admin) => {
      //       this.admin = admin;
      //       this.getAgents();
      //       this.getTickets({
      //         from: this.fromDate,
      //         to: this.toDate,
      //         admin: admin,
      //         status: param.pageName === "My Group Tickets" ? "open" : "closed",
      //         agentMail: "",
      //       });
      //     });
      //   }
      // });
      setTimeout(() => {
        this.projectId = this.projectchangeService._projectId;
        this.sharedService.getProjectAdmin(this.projectId).subscribe((admin) => {
          this.admin = admin;
          this.getAgents();
          this.getTickets({
            from: this.fromDate,
            to: this.toDate,
            admin: admin,
            status: param.pageName === "My Group Tickets" ? "open" : "closed",
            agentMail: "",
          });
        }, (error) => {
          this.handleError.handleError(error)
        });
      }, 500);
      // this.sharedService.projectId.subscribe((data) => {
      //   if (this.userData["projectId"] === 0 && data) {
      //     this.projectId = data;
      //     this.admin = true;
      //     this.selectAgent = [];
      //     this.getAgents();
      //     if (param.pageName === "My Group Tickets") {
      //       this.getTickets({
      //         from: this.fromDate,
      //         to: this.toDate,
      //         admin: true,
      //         status: "open",
      //         agentMail: "",
      //       });
      //     } else {
      //       this.getTickets({
      //         from: this.fromDate,
      //         to: this.toDate,
      //         admin: true,
      //         status: "closed",
      //         agentMail: "",
      //       });
      //     }
      //   }
      // });
    });
  }

  getColumns() {
    this.mailService.getConfiguration("displayedColumns").subscribe((data) => {
      this.selectedColumns = data.split(",");
      this.loading.displayColumns = true;
      // this.loading = false;
    }, (error) => {
      this.handleError.handleError(error)
    });

    this.mailService.getConfiguration("remainingColumns").subscribe((data) => {
      this.unSelectedColumns = data.split(",");
      this.loading.remainingColumns = true;
    }, (error) => {
      this.handleError.handleError(error)
    });
  }

  updateColumns() {
    this.mailService
      .updateConfiguration({
        name: "displayedColumns",
        value: this.selectedColumns.toString(),
      })
      .subscribe((data) => {
        // 
      }, (error) => {
        this.handleError.handleError(error)
      });
    this.mailService
      .updateConfiguration({
        name: "remainingColumns",
        value: this.unSelectedColumns.toString(),
      })
      .subscribe((data) => {
        // 
        this.modalService.dismissAll();
      }, (error) => {
        this.handleError.handleError(error)
      });
  }

  public addToSelectedColumns(contact?: any) {
    var changedColumn = contact
      ? [contact]
      : this.getPendingSelectionFromCollection(this.unSelectedColumns);
    this.pendingSelection = Object.create(null);
    this.unSelectedColumns = this.removeColumnsFromCollection(
      this.unSelectedColumns,
      changedColumn
    );
    this.selectedColumns = changedColumn.concat(this.selectedColumns);

  }
  public removeFromColumns(contact?: any) {
    var changeRoles = contact
      ? [contact]
      : this.getPendingSelectionFromCollection(this.selectedColumns);
    this.pendingSelection = Object.create(null);
    this.selectedColumns = this.removeColumnsFromCollection(
      this.selectedColumns,
      changeRoles
    );
    this.unSelectedColumns = changeRoles.concat(this.unSelectedColumns);
  }

  private getPendingSelectionFromCollection(collection: any[]): any[] {
    var selectionFromCollection = collection.filter((contact) => {
      return contact in this.pendingSelection;
    });
    return selectionFromCollection;
  }

  private removeColumnsFromCollection(
    collection: any[],
    RolesToRemove: any[]
  ): any[] {
    var collectionWithoutRoles = collection.filter((contact) => {
      return !RolesToRemove.includes(contact);
    });
    return collectionWithoutRoles;
  }

  open(content) {
    this.modalService.open(content, { size: "lg" }).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(
      this.selectedColumns,
      event.previousIndex,
      event.currentIndex
    );
  }

  getTickets(body: {
    from: NgbDate;
    to: NgbDate;
    agentMail: string;
    admin: Boolean;
    status: string;
  }) {
    this.ticketService
      .getCustomTickets({
        mail: this.userData["mail"],
        companyId: this.projectId,
        from: new Date(
          this.fromDate.year,
          this.fromDate.month - 1,
          this.fromDate.day + 1
        ),
        to: new Date(
          this.toDate.year,
          this.toDate.month - 1,
          this.toDate.day + 1
        ),
        offsetNumber: this.page - 1,
        admin: body.admin,
        agentMail: body.agentMail || "",
        status: body.status,
      })
      .subscribe((data) => {
        this.tickets = data;
        if (data[0]) {
          this.TicketCount = data[0]["TicketCount"];
        } else {
          this.TicketCount = 0;
        }
      }, (error) => {
        this.handleError.handleError(error)
      });
  }

  onPageChange() {
    let mail = { MailId: "" };
    if (this.selectAgent[0]) {
      mail = this.contacts.find(
        (data) => data["id"] === this.selectAgent[0]["id"]
      );
    }
    this.getTickets({
      from: this.fromDate,
      to: this.toDate,
      agentMail: mail["MailId"],
      admin: this.admin,
      status: this.queryParams["status"],
    });
  }

  getDateChange() {
    let mail = { MailId: "" };
    if (this.selectAgent[0]) {
      mail = this.contacts.find(
        (data) => data["id"] === this.selectAgent[0]["id"]
      );
    }
    this.getTickets({
      from: this.fromDate,
      to: this.toDate,
      agentMail: mail["MailId"],
      admin: this.admin,
      status: this.queryParams["status"],
    });
  }

  selectedAgent() {
    let mail = { MailId: "" };
    if (this.selectAgent[0]) {
      mail = this.contacts.find(
        (data) => data["id"] === this.selectAgent[0]["id"]
      );

    }
    this.getTickets({
      from: this.fromDate,
      to: this.toDate,
      agentMail: mail["MailId"],
      admin: this.admin,
      status: this.queryParams["status"],
    });
  }

  sortOrder(feildName) {
    this.orderFeild = feildName;
    this.order = !this.order;
    if (this.order) {
      this.orderString = "asc";
    } else {
      this.orderString = "desc";
    }
  }

  getAgents() {
    this.agentService.GetAllAgents(this.projectId).subscribe((data) => {
      this.contacts = data.map((agent) => ({
        id: agent["id"],
        Name: agent["FirstName"] + " " + agent["LastName"],
        MailId: agent["Mailid"],
      }));
      this.contacts.unshift({ id: "", Name: "select All", Mailid: "" });

    }, (error) => {
      this.handleError.handleError(error)
    });
  }
  projectChange(data) {
    var params;
    this.projectId = data;
    this.route.params.subscribe((param) => {
      params = param
    })
    this.sharedService.getProjectAdmin(data).subscribe((admin) => {
      this.admin = admin;
      this.getAgents();
      this.getTickets({
        from: this.fromDate,
        to: this.toDate,
        admin: admin,
        status: params.pageName === "My Group Tickets" ? "open" : "closed",
        agentMail: "",
      });
    }, (error) => {
      this.handleError.handleError(error)
    });
  }
}
