import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "tabletitle",
})
export class TabletitlePipe implements PipeTransform {
  transform(value: any, ...args: any[]): any {
    switch (value) {
      case "FirstName":
        return "Name";
      case "PhoneNumber":
        return "Phone Number";
      case "createdAt":
        return "Created Date";
      case "Assignedto":
        return "Assigned To";
      default:
        return value;
    }
    return null;
  }
}
