import { Injectable } from '@angular/core';
import { HttpClientService } from 'src/app/httpclient.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class WorkflowserviceService {
  newDashboardAPI: any = environment.newDashboardAPI;

  constructor(private http: HttpClientService) { }

  createRule(data?: any) {
    return this.http.post<any[]>(
      `${this.newDashboardAPI}workflowautomation/createRule`, data
    );
  }
  createTemplate(data?: any) {
    return this.http.post<any[]>(
      `${this.newDashboardAPI}workflowautomation/createTemplate`, data
    );
  }
  getAllRules(data?: any) {
    return this.http.post<any[]>(
      `${this.newDashboardAPI}workflowautomation/getAllRules`, data
    );
  }
}