import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Inject,
} from "@angular/core";
import { IDropdownSettings } from "ng-multiselect-dropdown";
import { CallLogs } from "src/app/pages/calllogs/models/CallLogs.model";
import { SharedService } from "src/app/shared/shared.service";
import { CreateAgent } from "../create-agent/create-agent.service";
import { CreateAgentv2 } from "../create-agent copy/create-agent.service";
import { ManagementService } from "../management.service";
import { DatePipe } from "@angular/common";
import {
  NgbCalendar,
  NgbDate,
  NgbDateParserFormatter,
} from "@ng-bootstrap/ng-bootstrap";
import { LayoutService } from "src/app/layout/layout.service";
import { DOCUMENT } from "@angular/common";
import { ProjectchangeService } from "src/app/layout/project-change/projectchange.service";
import { RoleService } from "../role-management/role.service";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import { MomentFormatService } from "src/app/moment-format-service";
import { FormControl } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { TopbarComponent } from "src/app/layout/topbar/topbar.component";
import { ErrorHandlerService } from "src/app/error-handler.service";
declare var $: any
@Component({
  selector: "app-calllogs",
  templateUrl: "./calllogs.component.html",
  styleUrls: ["./calllogs.component.less"],
  providers: [DatePipe],
})
export class CalllogsComponent implements OnInit {
  userData: any;
  projectId: any;
  public callLogs: any[] = [];
  public page: number = 1;
  public pageCount: number;
  public playing: boolean = false;
  public lastIndex: number = 1;
  public contacts: any[] = [];
  public contactslist: any[] = [];
  public dropdownSettings: IDropdownSettings;
  public selectAgent: any[] = [];
  public fromDate: NgbDate;
  public toDate: NgbDate;
  public previousAudio: HTMLAudioElement;
  public previousIndex: number;
  public order: boolean = false;
  public orderFeild: string = "UpdatedDate";
  public orderString: boolean | "asc" | "desc" = "desc";
  // public order1: boolean = false;
  public showFilter: boolean = false;
  public recentCalls: any = [];
  public liveCalls: any = [];
  incomingCalls: any = 0
  outgoingCalls: any = 0
  missedCalls: any = 0
  payload = {
    ProjectId: 0,
    page: this.page,
    workerSid: "",
    callType: 'all',
    calltatus: '',
    AgentMail: ['all'],
    start: null,
    end: null,
    search: '',
    callQuality: 'all'
  }
  filtertype = [
    { id: 'incoming', name: 'Incoming', checked: false, value: 'Incoming' },
    { id: 'outgoing', name: 'outgoing', checked: false, value: 'Outgoing' }
  ]
  filterStatus = [
    { id: 'answer', name: 'Answered', checked: false, value: 'Answered' },
    { id: 'Missed', name: 'Missed', checked: false, value: 'Missed' }
  ]
  callQuality = [
    { id: 'notProcessed', name: 'Not Processed', checked: false, value: 'notProcessed' },
    { id: 'notApplicable', name: 'Not Applicable', checked: false, value: 'notApplicable' },
    { id: 'bad', name: 'Bad', checked: false, value: 'bad' },
    { id: 'average', name: 'Average', checked: false, value: 'average' },
    { id: 'good', name: 'Good', checked: false, value: 'good' },
    { id: 'exceptional', name: 'Exceptional', checked: false, value: 'exceptional' }
  ]
  selectFilterAgent: boolean = false
  selectFilterAdmin: boolean = false
  showAgents: boolean = false
  usersSelectionArray: any[] = [];
  search: any = null;
  // sortOrder = "des";
  startDate: any;
  endDate: any;
  selectedIndexDate: string;
  selectedIndex: any;
  showDateCustom: boolean = false;
  filterValue: string = "all";
  date1: any;
  date2: any;
  showDate: boolean = false;
  sidenavData: any;
  @ViewChild("audio") audio: ElementRef;
  @ViewChild("sideNav", { static: true }) sideNav;
  @ViewChild(TopbarComponent) auth;
  starWidth: number = 0;
  max = 5;
  isReadonly = true;
  companyId: any;
  projectList: any = []
  selectProjectDetails: any;

  constructor(
    private sharedService: SharedService,
    private managementService: ManagementService,
    private agentService: CreateAgent,
    private calendar: NgbCalendar,
    public formatter: NgbDateParserFormatter,
    private layoutService: LayoutService,
    public projectchangeService: ProjectchangeService,
    public companyService: RoleService,
    private momentService: MomentFormatService,
    private handleError: ErrorHandlerService,
    private AgentServicev2: CreateAgentv2,

    private router: Router,
    private route: ActivatedRoute,

    @Inject(DOCUMENT) private domService: HTMLDocument
  ) {
    this.userData = JSON.parse(localStorage.getItem("userData"));
    this.route.params.subscribe((Data: any) => {
      if (Data.companyName) {
        this.companyId = this.userData.NewCompanyId

      }
    })
  }
  ngOnInit() {
    this.rateProduct(this.starWidth)
    this.sharedService.pageEvent.emit({
      pageName: "Agent Call Logs",
    });

    this.userData = JSON.parse(localStorage.getItem("userData"));
    this.fromDate = this.calendar.getNext(this.calendar.getToday(), "y", -1);
    this.toDate = this.calendar.getToday();
    this.date1 = new FormControl(new Date(this.toDate.year, this.toDate.month - 1, this.toDate.day + 1));
    this.date2 = new FormControl(new Date(this.fromDate.year, this.fromDate.month - 1, this.fromDate.day + 1));
    this.payload.start = new Date(this.fromDate.year, this.fromDate.month - 1, this.fromDate.day + 1);
    this.payload.end = new Date(this.toDate.year, this.toDate.month - 1, this.toDate.day + 1);
    if (this.userData.NewCompanyId) {
      this.getProjectDetails();
    }
    else {
    this.companyService.projectAdmin(this.userData['companyId']).subscribe((projects) => {

      this.projectId = this.projectchangeService._projectId;
      this.projectId = projects[0]['id']
      this.getCallCount();
      this.getLiveCalls();
      this.getRecentCalls();
      this.getCallLogs();
      this.getAgents();
      this.selectAgent = [];
    }, (error) => {
      this.handleError.handleError(error)
    });
    }

    this.dropdownSettings = {
      singleSelection: false,
      maxHeight: 197,
      itemsShowLimit: 3,
      allowSearchFilter: true,
      textField: "Name",
      // enableCheckAll: true,
      selectAllText: 'All',
      // limitSelection: -1,
      // clearSearchFilter: true,
      searchPlaceholderText: 'Search',
      noDataAvailablePlaceholderText: 'No data found.',

    };
  }

  selectedAgent(item?: any) {
    this.selectAgent = item.length == this.contactslist.length ? item : this.selectAgent
    if (item && item.length == 0) {
      this.selectAgent = []
    }
    let selectedAgents = this.selectAgent.length ? this.selectAgent.map(x => x.id) : []
    let agenetEmails = this.contactslist.filter(x => selectedAgents.includes(x.id));
    this.payload.AgentMail = agenetEmails.length ? agenetEmails.map(x => x.Mailid) : ['all']
    this.getCallLogs()
  }

  onDateChange() {
    this.getDateChange();
  }

  onPageChange() {
    if (!this.selectAgent[0]) {
      this.getCallLogs();
    } else {
      this.selectedAgent(this.selectAgent);
    }
    this.playing = !this.playing;
  }
  getLiveCalls() {
    if (this.userData.NewCompanyId) {
      this.managementService.getLiveCallLogsv2(this.userData.NewCompanyId).subscribe((Data: any) => {
        this.liveCalls = Data
      }, (error) => {
        this.handleError.handleError(error)
      });
    }
    else {
    this.managementService.getLiveCallLogs(this.projectId).subscribe((Data: any) => {
      this.liveCalls = Data
    }, (error) => {
      this.handleError.handleError(error)
    });
  }
  }
  getRecentCalls() {
    if (this.userData.NewCompanyId) {
      let payload = {
        companyId: this.userData.NewCompanyId,
        projectId: this.projectId
      }
      this.managementService.getRecentCallLogsv2(payload).subscribe((Data: any) => {
        this.recentCalls = Data

      }, (error) => {
        this.handleError.handleError(error)
      });
    }
    else {
    this.managementService.getRecentCallLogs(this.projectId).subscribe((Data: any) => {
      this.recentCalls = Data

    }, (error) => {
      this.handleError.handleError(error)
    });
  }
  }
  getCallLogs() {

    this.payload.ProjectId = this.projectId;
    this.payload.page = this.page;
    this.payload.workerSid = "";
    let req_payload = {
      ...this.payload,
      callType: this.payload.calltatus ? this.payload.calltatus : this.payload.callType,
      companyId: this.companyId
    }
    if (this.userData.NewCompanyId) {
      let apiName = this.payload.search ? "searchCallReportsv2" : "getCallLogsv2"

      this.managementService[apiName](req_payload)
        .subscribe((Data: any) => {
          this.callLogs = Data.data;
          this.callLogs.map(x => {
            if (Number(x.RecordingDuration) <= 60) {
              x.quality = 'Not Applicable';
            } else if (Number(x.avg_score) > 0 && Number(x.avg_score) <= 50) {
              x.quality = 'Bad'
            } else if (Number(x.avg_score) > 50 && Number(x.avg_score) <= 60) {
              x.quality = 'Average';
            } else if (Number(x.avg_score) > 60 && Number(x.avg_score) <= 70) {
              x.quality = 'Good';
            } else if (Number(x.avg_score) > 70) {
              x.quality = 'Exceptional';
            } else {
              x.quality = 'Not Processed'
            }
          });
          this.pageCount = Data.count;
        }, (error) => {
          this.handleError.handleError(error)
        });
    }
    else {
    let apiName = this.payload.search ? "searchCallReports" : "getCallLogs"

    this.managementService[apiName](req_payload)
      .subscribe((Data: any) => {
        this.callLogs = Data.data;
          this.callLogs.map(x => {
            console.log((x.avg_score));

            if (Number(x.RecordingDuration) <= 60) {
              x.quality = 'Not Applicable';
            } else if (Number(x.avg_score) > 0 && Number(x.avg_score) <= 50) {
              x.quality = 'Bad'
            } else if (Number(x.avg_score) > 50 && Number(x.avg_score) <= 60) {
              x.quality = 'Average';
            } else if (Number(x.avg_score) > 60 && Number(x.avg_score) <= 70) {
              x.quality = 'Good';
            } else if (Number(x.avg_score) > 70) {
              x.quality = 'Exceptional';
            } else {
              x.quality = 'Not Processed'
            }
          });
        this.pageCount = Data.count;
      }, (error) => {
        this.handleError.handleError(error)
      });
  }
  }

  getDateChange() {
    this.managementService
      .getCallLogs({
        companyId: this.projectId,
        rowNumber: this.page - 1,
        workerSid: `${this.selectAgent[0] ? this.selectAgent[0]["id"] : ""}`,
        from: new Date(
          this.fromDate.year,
          this.fromDate.month - 1,
          this.fromDate.day + 1
        ),
        to: new Date(
          this.toDate.year,
          this.toDate.month - 1,
          this.toDate.day + 1
        ),
      })
      .subscribe((data) => {
        this.callLogs = data;
        this.callLogs.map(x => {

          if (Number(x.RecordingDuration) <= 60) {
            x.quality = 'Not Applicable';
          } else if (Number(x.avg_score) > 0 && Number(x.avg_score) <= 50) {
            x.quality = 'Bad'
          } else if (Number(x.avg_score) > 50 && Number(x.avg_score) <= 60) {
            x.quality = 'Average';
          } else if (Number(x.avg_score) > 60 && Number(x.avg_score) <= 70) {
            x.quality = 'Good';
          } else if (Number(x.avg_score) > 70) {
            x.quality = 'Exceptional';
          } else {
            x.quality = 'Not Processed'
          }
        });
        if (data[0]) {
          this.pageCount = data[0]["TotalCount"];
        } else {
          this.pageCount = 0;
        }
      }, (error) => {
        this.handleError.handleError(error)
      });
  }
  isAdmin: Boolean = false
  getAgents() {
    if (this.userData.NewCompanyId) {

      let payload = {
        projectId: this.projectId,
        userMail: this.userData.mail
      }
      this.AgentServicev2.GetAllAgentsV2(payload).subscribe((Data: any) => {
        this.isAdmin = Data.isAdmin
        // let tempdata= Data.data.filter(x=>x.userRole == 'agent');
        this.contacts = Data.data.map((agent) => ({
          id: agent["id"],
          agentname: agent["FirstName"] + " " + agent["LastName"],
          Mailid: agent["Mailid"],
          usersState: false,
          ProfilePhoto: agent["ProfilePhoto"]
        }));
        this.contactslist = this.contacts

        // this.contacts.unshift({ id: "", Name: "select All" });
      }, (error) => {
        this.handleError.handleError(error)
      });
    }
    else {
    this.agentService.GetAllAgents(this.projectId).subscribe((data) => {

      this.contacts = data.map((agent) => ({
        id: agent["id"],
        agentname: agent["FirstName"] + " " + agent["LastName"],
        Mailid: agent["Mailid"],
        usersState: false,
        ProfilePhoto: agent["ProfilePhoto"]
      }));
      this.contactslist = this.contacts

      // this.contacts.unshift({ id: "", Name: "select All" });
    }, (error) => {
      this.handleError.handleError(error)
    });
  }
  }

  playAudio(audio: HTMLAudioElement, index) {
    if (!this.callLogs[index]["playing"]) {
      this.callLogs[index]["playing"] = !this.callLogs[index]["playing"];
      if (this.callLogs[this.lastIndex]["playing"]) {
        this.callLogs[this.lastIndex]["playing"] =
          !this.callLogs[this.lastIndex]["playing"];
        this.previousAudio.pause();
      }
      this.lastIndex = index;
      this.previousAudio = audio;
      audio.play();
    } else {
      if (this.callLogs[index]["playing"]) {
        audio.pause();
        this.callLogs[index]["playing"] = !this.callLogs[index]["playing"];
      }
    }
  }

  callNumber(index) {
    let item =
      this.callLogs[index]["Direction"] === "inbound"
        ? this.callLogs[index]["CallerNumber"]
        : this.callLogs[index]["CalledNumber"];
    this.layoutService.callAgent.next(item);
  }

  audioPlay(e) {
    let eAudio = this.domService.getElementsByTagName("audio");
    if (eAudio && eAudio.length > 0) {
      for (var i = 0; i < eAudio.length; i++) {
        if (e.target !== eAudio[i]) {
          eAudio[i].pause();
        }
      }
    }
  }
  updateDateFilter(event: any) {

    if (event.target.value != "custom") {
      this.endDate = this.momentService.dashboardDateFormat(
        new Date(new Date(Date.now())).getTime()
      );
    }
    if (event.target.value == "custom") {
      //show custom date filter
      this.showDate = true;
    } else if (event.target.value == "all") {
      this.startDate = this.momentService.dashboardDateFormat(
        new Date(new Date(Date.now() - 864e5 * 365)).getTime()
      );

      this.showDate = false;
    } else if (event.target.value == "24hrs") {
      this.startDate = this.momentService.dashboardDateFormat(
        new Date(new Date(Date.now() - 864e5)).getTime()
      );

      this.showDate = false;
    } else if (event.target.value == "72hrs") {
      this.startDate = this.momentService.dashboardDateFormat(
        new Date(new Date(Date.now() - 864e5 * 3)).getTime()
      );

      this.showDate = false;
    } else if (event.target.value == "7days") {
      this.startDate = this.momentService.dashboardDateFormat(
        new Date(new Date(Date.now() - 864e5 * 7)).getTime()
      );

      this.showDate = false;
    } else if (event.target.value == "14days") {
      this.startDate = this.momentService.dashboardDateFormat(
        new Date(new Date(Date.now() - 864e5 * 14)).getTime()
      );

      this.showDate = false;
    } else if (event.target.value == "30days") {
      this.startDate = this.momentService.dashboardDateFormat(
        new Date(new Date(Date.now() - 864e5 * 30)).getTime()
      );

      this.showDate = false;
    }
    this.payload.search = ''
    this.page = 1
    this.search = ''
    this.payload.start = this.startDate;
    this.payload.end = this.endDate;

    this.getCallLogs()
    this.getCallCount()
  }

  sortOrder(feildName) {

    this.orderFeild = feildName;
    this.order = !this.order;
    if (this.order) {
      this.orderString = "asc";
    } else {
      this.orderString = "desc";
    }
  }

  // leaveGroup() {

  // }
  projectChange(data) {
    this.projectId = data;
    this.getLiveCalls();
    this.getCallCount();
    this.getRecentCalls();
    this.getCallLogs();
    this.getAgents();
    this.selectAgent = [];
  }
  onCheckboxChange(event: any) {
    this.payload.calltatus = '';
    this.filterStatus.forEach(element => {
      element.checked = false
    });
    this.filtertype.forEach(element => {
      if (element.value == event.target['value']) {
        element.checked = event.target['checked']
      }
    });
    let calltype = this.filtertype.filter(x => x.checked == true)
    this.payload.callType = calltype.length ? calltype.length > 1 ? 'all' : calltype[0].value : 'all'
    this.search = ''
    this.payload.search = ''
    this.page = 1
    this.getCallLogs()
  }
  onCheckboxChangeStatus(event: any) {
    this.filterStatus.forEach(element => {
      if (element.value == event.target['value']) {
        element.checked = event.target['checked']
      }
    });


    let callstatus = this.filterStatus.filter(x => x.checked == true)
    if (this.payload.callType == 'Incoming') {
      let tempCallStatusValue = callstatus.length ? callstatus.length > 1 ? 'Incoming' : callstatus[0].value : 'Incoming'
      if (callstatus.length == 1) {
        if (tempCallStatusValue == 'Answered') {
          this.payload.calltatus = 'IncomingAnswered'
        }
        else if (tempCallStatusValue == 'Missed') {
          this.payload.calltatus = 'IncomingMissed'
        }
      }
      else {
        this.payload.calltatus = 'Incoming'

      }
    }
    else if (this.payload.callType == 'Outgoing') {
      let tempCallStatusValue = callstatus.length ? callstatus.length > 1 ? 'Outgoing' : callstatus[0].value : 'Outgoing'
      if (callstatus.length == 1) {
        if (tempCallStatusValue == 'Answered') {
          this.payload.calltatus = 'OutgoingAnswered'
        }
        else if (tempCallStatusValue == 'Missed') {
          this.payload.calltatus = 'OutgoingMissed'
        }
      }
      else {
        this.payload.calltatus = 'Outgoing'

      }
    }
    // this.payload.callType=callstatus.length ? callstatus.length>1?'all':callstatus[0].value:'all'
    this.search = ''
    this.payload.search = ''
    this.page = 1
    this.getCallLogs()
  }
  onCheckboxChangeCallQuality(event: any) {
    this.callQuality.forEach(element => {
      element.checked = false;
      if (element.value == event.target['value']) {
        element.checked = event.target['checked']
      }
    });

    let callquality = this.callQuality.filter(x => x.checked == true);
    let callQualityValue = callquality.map(x => x.value);
    this.payload.callQuality = callQualityValue[0];
    this.search = ''
    this.payload.search = ''
    this.page = 1
    this.getCallLogs()
  }
  resetFilter() {
    this.filterStatus.forEach(element => {
      element.checked = false
    });
    this.filtertype.forEach(element => {
      element.checked = false
    });
    this.callQuality.forEach(element => {
      element.checked = false
    });
    this.payload.callType = 'all'
    this.payload.calltatus = '';
    this.selectFilterAgent = false;
    this.showAgents = false
    this.contacts.forEach((x) => {
      return (x.usersState = false);
    });
    this.usersSelectionArray = []
    this.payload.AgentMail = ['all']
    this.getCallLogs()
  }

  onAgentFilterCheckbox(event: any) {

    this.showAgents = true
    if (event.target['checked'] == true) {
      this.selectFilterAgent = true
      this.showAgents = true
      this.checkAllusers(event)
    }
    else {
      this.selectFilterAgent = false
      this.showAgents = false
      this.contacts.forEach((x) => {
        return (x.usersState = event.target.checked);
      });
      this.usersSelectionArray = []

      this.payload.AgentMail = ['all']
      this.getCallLogs()
    }

  }

  // USERS MULTI SELECT HANDLE
  usersSelection(ev) {
    var s = ev.value;
    if (ev.checked) {
      this.usersSelectionArray.push(s);
    } else {
      var index = this.usersSelectionArray.indexOf(s);
      if (index > -1) {
        this.usersSelectionArray.splice(index, 1);
      }
    }
    this.payload.AgentMail = this.usersSelectionArray.length > 0 ? this.usersSelectionArray : ['all']
    this.getCallLogs()
  }

  checkAllusers(ev) {
    this.usersSelectionArray = [];
    this.contacts.forEach((x) => {
      if (ev.target.checked === true) {
        this.usersSelectionArray.push(x.Mailid);
      }
      return (x.usersState = ev.target.checked);
    });
    this.payload.AgentMail = this.usersSelectionArray.length > 0 ? this.usersSelectionArray : ['all']
    this.getCallLogs()
  }
  isAllCheckedusers() {
    var rValue = this.contacts.every((_) => _.usersState);
    return rValue;
  }
  unCheckusersSelectionArray() {
    this.usersSelectionArray = [];
    this.contacts.forEach((x) => {
      return (x.usersState = false);
    });
  }
  getAgentName(val) {
    var contactsList = this.contacts;
    var name = contactsList.filter((x) => x.Mailid === val)[0];
    return name.agentname;
  }
  agentSearch(data: any) {
    let serachDetails = data.target.value;
    let agentnames = this.contactslist
    this.contacts = agentnames.filter(x => (x.agentname.toLowerCase()).includes(serachDetails.toLowerCase()));
  }
  onInput(data: any) {
    let serachdata = data.target.value;
    this.page = 1
    this.payload.search = serachdata
    this.getCallLogs()
  }
  // toggleSort(column, order){
  //   if (this.sortOrder == 'asc'){
  //     this.sortOrder ='des'
  //   }else{
  //     this.sortOrder = 'asc';
  //   }
  //   this.sortTableData(column, this.sortOrder);
  // }
  dateSelection(event) {
    if (event.target.value != "custom") {
      this.endDate = this.momentService.dashboardDateFormat(
        new Date(new Date(Date.now())).getTime()
      );
    }
    if (event.target.value == "custom") {
      this.filterValue = 'custom';
      this.showDateCustom = true;
      this.selectedIndexDate = event.target.value;
    } else if (event.target.value == "24hrs") {
      this.showDateCustom = false;
      this.filterValue = '24hrs'
      this.selectedIndexDate = event.target.value;
      this.startDate = this.momentService.dashboardDateFormat(
        new Date(new Date(Date.now() - 864e5)).getTime()
      );
    } else if (event.target.value == "72hrs") {
      this.showDateCustom = false;
      this.filterValue = '72hrs'
      this.selectedIndexDate = event.target.value;
      this.startDate = this.momentService.dashboardDateFormat(
        new Date(new Date(Date.now() - 864e5 * 3)).getTime()
      );
    } else if (event.target.value == "07days") {
      this.showDateCustom = false;
      this.filterValue = '07days'
      this.selectedIndexDate = event.target.value;
      this.startDate = this.momentService.dashboardDateFormat(
        new Date(new Date(Date.now() - 864e5 * 7)).getTime()
      );
    } else if (event.target.value == "14days") {
      this.showDateCustom = false;
      this.filterValue = '14days'
      this.selectedIndexDate = event.target.value;
      this.startDate = this.momentService.dashboardDateFormat(
        new Date(new Date(Date.now() - 864e5 * 14)).getTime()
      );
    } else if (event.target.value == "30days") {
      this.showDateCustom = false;
      this.filterValue = '30days'
      this.selectedIndexDate = event.target.value;
      this.startDate = this.momentService.dashboardDateFormat(
        new Date(new Date(Date.now() - 864e5 * 30)).getTime()
      );
    }
    else if (event.target.value == "yearly") {
      this.showDateCustom = false;
      this.filterValue = 'yearly'
      this.selectedIndexDate = event.target.value;
      this.startDate = this.momentService.dashboardDateFormat(
        new Date(new Date(Date.now() - 864e5 * 365)).getTime()
      );
    }
  }
  hideDate() {
    // this.filterValuev
    if (this.filterValue == "custom" && this.showDateCustom == false) {
      this.showDateCustom = true;
    }
  }
  filterData() {
    this.startDate = this.momentService.dashboardDateFormat(
      new Date(this.date2.value).getTime()
    );
    this.endDate = this.momentService.dashboardDateFormat(
      new Date(this.date1.value).getTime()
    );
    this.payload.start = this.startDate
    this.payload.end = this.endDate
    this.getCallLogs()
    this.getCallCount()
  }
  getStartDate(type: string, event: MatDatepickerInputEvent<Date>) {
    this.startDate = event.value;
    this.startDate = this.momentService.dashboardDateFormat(
      new Date(this.startDate).getTime
    );
  }
  getEndDate(type: string, event: MatDatepickerInputEvent<Date>) {
    this.endDate = event.value;
    this.endDate = this.momentService.dashboardDateFormat(
      new Date(this.endDate).getTime
    );
  }
  getSideNavData(item?: any) {
    this.sidenavData = [];
    Object.keys(item).map((key) => {
      item[key] === null ? (item[key] = "-") : item[key];
    });
    this.sidenavData = item;
  }
  sideNavData(item?: any) {
    this.sidenavData = item
  }
  getCallCount() {
    this.payload.ProjectId = this.projectId;
    this.payload.page = this.page;
    this.payload.workerSid = "";
    let req_payload = {
      ...this.payload,
      companyId: this.companyId
    }
    if (this.userData.NewCompanyId) {
      this.managementService.callsCountv2(req_payload).subscribe((Data: any) => {

        this.missedCalls = parseInt(Data.Missed)
        this.incomingCalls = parseInt(Data.Incoming)
        this.outgoingCalls = parseInt(Data.Outgoing)

      }, (error) => {
        this.handleError.handleError(error);
      });
    }
    else {
    this.managementService.callsCount(req_payload).subscribe((Data: any) => {

      this.missedCalls = parseInt(Data.Missed)
      this.incomingCalls = parseInt(Data.Incoming)
      this.outgoingCalls = parseInt(Data.Outgoing)

    }, (error) => {
      this.handleError.handleError(error);
    });
    }

  }
  rateProduct(rateValue: number) {
    this.starWidth = 0;
    console.log(rateValue)
    this.starWidth = rateValue;
  }
  getTwoDecimal(value) {
    return value.toFixed(2);
  }
  getStar(perc) {
    return ((perc * 5) / 100);
  }
  getQuality(perc) {
    if (perc == null) {
      return 'No';
    }
    if (Number(perc) > 50) {
      return 'Good';
    } else {
      return 'Bad';
    }
  }

  // new flow

  public getProjectDetails() {
    this.AgentServicev2.getAllProjects({ companyId: this.companyId }).subscribe((Data: any) => {
      if (Data.status === 200) {
        this.projectList = Data.projects
        this.projectId = Data.projects[0].projectId
        this.selectProjectDetails = Data.projects[0]
        this.getCallCount();
        this.getLiveCalls();
        this.getRecentCalls();
        this.getCallLogs();
        this.getAgents();
        this.selectAgent = [];
      }
    })
  }
  checkAdmin(Data: any) {
    this.selectFilterAdmin = Data.target.checked
    if (this.selectFilterAdmin) {
      this.payload.AgentMail = [this.userData.mail]
    }
    else {
      this.payload.AgentMail = ['all']
    }
    this.getCallLogs()
  }
  getObjLength(obj) {
    if (obj != null) {
      if (Object.keys(obj)?.length > 0) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }
}
