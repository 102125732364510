import { ManagementService } from "../../pages/management.service";
import { Component, OnInit } from "@angular/core";
import { AuthService } from "src/app/account/auth/auth.service";
import { LayoutService } from "../layout.service";
import { Router } from "@angular/router";
import { ErrorHandlerService } from "src/app/error-handler.service";
import { LeadsDashboardService } from "src/app/pages/leads-dashboard/leads-dashboard.service";

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.css"],
})
export class SidebarComponent implements OnInit {
  panelExpanded1: false;
  userData: any;
  displayMenus: any[] = [];
  companyName: string;
  companyLogo: any;
  companyHome: string;
  agentcallLogRoute: string;
  projects: any = [];
  logomr = 48;

  constructor(
    private authService: AuthService,
    private managementService: ManagementService,
    private readonly layoutService: LayoutService,
    private handleError: ErrorHandlerService,
    public readonly router: Router,
    private LeadsDashboardService: LeadsDashboardService
  ) { }

  ngOnInit() {
    this.userData = JSON.parse(localStorage.getItem("userData"));
    this.logomr = 48;
    this.layoutService.closeSideParent.subscribe((data) => {
      console.log("🚀 ~ file: sidebar.component.ts:37 ~ SidebarComponent ~ this.layoutService.closeSideParent.subscribe ~ data", data);
      if (data == 'close') {
        this.logomr = 0;
        $(".show").removeClass("show")
      } else {
        this.logomr = 48;
      }
    })
    if (this.userData.NewCompanyId) {
      this.companyHome = "/" + this.userData.NewCompanyId + "/home";
      this.LeadsDashboardService.getCompanyInfo({ companyId: this.userData.NewCompanyId, mail: this.userData.mail }).subscribe((res: any) => {
        console.log('Company Info: ', res);
        this.companyName = res.data[0].companyName;
        this.agentcallLogRoute = "/" + this.companyName + "/calllogs";
        this.companyLogo = res.data[0].companyLogo;
        this.projects = res.projects[0];
        for (let i = 0; i < this.projects.length; i++) {
          this.projects[i].leadsUrl = "/" + this.projects[i].projectId + "/leads"
        }
      })
    }
    // this.authService.checkSuperAdmin(this.userData.id).subscribe((data) => {
    //   this.authService.admin.next(data["superAdmin"]);
    // });
    this.authService.getUserRoles(this.userData.id).subscribe((data) => {
      let values: any[] = data.map((data) => data["Name"].toLowerCase());
      let roleIds: any[] = data.map((data) => data["id"]);
      if (values.includes("admin")) {
        this.authService.companyAdmin.next(true);
      } else {
        this.authService.companyAdmin.next(false);
      }
      this.managementService
        .getSideMenuRoles({
          roles: roleIds,
          superAdmin: this.userData["companyId"] === 0 ? true : false,
        })
        .subscribe((data) => {
          this.displayMenus = data;
        }, (error) => {
          this.handleError.handleError(error)
        });
    }, (error) => {
      this.handleError.handleError(error)
    });
  }

  checkingIdMenu(id): boolean {

    return this.displayMenus.find((val) => val["id"] === id);
  }

  getMenuName(id): string {
    let item = this.displayMenus.find((val) => val["id"] === id);
    return item["Name"] || "";
  }
  toggleSideBar() {
    // this.layoutService.toggleSideBar.next('hello')
  }
}
