import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HttpClientService } from 'src/app/httpclient.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CustomService {
  baseApiUrl2 = environment.baseApiUrl2;
  pageNumber = new BehaviorSubject<any>(null);
  totalPages = new BehaviorSubject<any>(null);
  constructor(private http: HttpClientService) { }
  listFeedback(id,menuId: number = 0) {
    console.log(menuId);
    
    // /feedback/add
    return this.http.get(`${this.baseApiUrl2}feedback/activeMenu/${id}/${menuId}`)
  }
}
