import { Injectable } from '@angular/core';
import { HttpClientService } from 'src/app/httpclient.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RorotelService {
  baseApiUrl = environment.baseApiUrl;
  constructor(
    private http: HttpClientService
  ) { }

  list(obj) {
    return this.http.get(`${this.baseApiUrl}rorotel/list/${obj}`);
  }

  cdranswered(data) {
    return this.http.get(`${this.baseApiUrl}rorotel/cdr/countByStatus/${data.from}/${data.to}/${data.ext}/answered`);
  }
  cdrunanswered(data) {
    return this.http.get(`${this.baseApiUrl}rorotel/cdr/countByStatus/${data.from}/${data.to}/${data.ext}/unanswered`);
  }
  cdrbusy(data) {
    return this.http.get(`${this.baseApiUrl}rorotel/cdr/countByStatus/${data.from}/${data.to}/${data.ext}/busy`);
  }
  cdrfailed(data) {
    return this.http.get(`${this.baseApiUrl}rorotel/cdr/countByStatus/${data.from}/${data.to}/${data.ext}/failed`);
  }
  cdrincoming(data) {
    return this.http.get(`${this.baseApiUrl}rorotel/cdr/countByStatus/${data.from}/${data.to}/${data.ext}/incoming`);
  }
  cdroutgoing(data) {
    return this.http.get(`${this.baseApiUrl}rorotel/cdr/countByStatus/${data.from}/${data.to}/${data.ext}/outgoing`);
  }
  cdrincomingList(data) {
    return this.http.get(`${this.baseApiUrl}rorotel/cdr/countByStatus/${data.from}/${data.to}/${data.ext}/incomingList`);
  }
  cdroutgoingList(data) {
    return this.http.get(`${this.baseApiUrl}rorotel/cdr/countByStatus/${data.from}/${data.to}/${data.ext}/outgoingList`);
  }
  cdrcallsPerDay(data) {
    return this.http.get(`${this.baseApiUrl}rorotel/cdr/countByStatus/${data.from}/${data.to}/${data.ext}/${data.status}/${data.cdr}/callTrends1`);
  }
  cdrtopperformer(data) {
    return this.http.get(`${this.baseApiUrl}rorotel/cdr/countByStatus/${data.from}/${data.to}/${data.ext}/${data.status}/${data.cdr}/callTrends2`);
  }
  cdrcallsinVsOut(data) {
    return this.http.get(`${this.baseApiUrl}rorotel/cdr/countByStatus/${data.from}/${data.to}/${data.ext}/${data.status}/${data.cdr}/inVsOut`);
  }
}
