import { Component, OnInit, ViewChild } from "@angular/core";
import { FormGroup, FormBuilder, Validators, NgModel, NgForm } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthService } from "../auth.service";
// import { HttpClientService } from '@angular/common/http';
import { environment } from "src/environments/environment";
import { MailserviceService } from "src/app/pages/mailservice.service";
import { HttpClientService } from "src/app/httpclient.service";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { CreateAgent } from "src/app/pages/create-agent/create-agent.service";
import { ErrorHandlerService } from "src/app/error-handler.service";
import { SignupService } from "src/app/signupnew/signup.service";
import { LeadsDashboardService } from '../../../pages/leads-dashboard/leads-dashboard.service';
import { ProjectService } from "src/app/pages/project-management/project.service";

@Component({
  selector: "app-forgot",
  templateUrl: "./forgot.component.html",
  styleUrls: ["./forgot.component.css"],
})
export class ForgotComponent implements OnInit {
  public baseUrl = environment.baseApiUrl;
  forgetpassword = true;
  loginForm: FormGroup;
  submitted = false;
  returnUrl: string;
  error = "";
  loading: boolean = false;
  showErrorMessage: any = false;
  errorMessage: any;
  userData: any;
  email = '';
  selectIndex: number = 0;
  template: any;
  random = Math.random().toString(36).substring(7);
  compareNumber: any;
  resetPassword: boolean = false;
  fcmtoken: string;
  fieldTextType: boolean;
  fp = "fp1";
  todaydate = new Date();
  @ViewChild('emailInput') emailInput: NgModel;
  @ViewChild('emailForm') emailForm: NgForm;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private http: HttpClientService,
    private agentService: CreateAgent,
    private mailService: MailserviceService,
    private handleError: ErrorHandlerService,
    private SignUPService: SignupService,
    private LeadsDashboardService: LeadsDashboardService,
    private projectService: ProjectService
  ) { }

  ngOnInit() {
    this.requestPermission();
    this.loginForm = this.formBuilder.group({
      email: ["", [Validators.required, Validators.email]],
      password: ["", Validators.required],
    });
    this.mailService
      .getTemplateCompany(
        "passwordverification",
        environment.forgotPassEmailTempId
      )
      .subscribe(
        (data) => {
          this.template = data["Html"];
        },
        (error) => {
          // this.handleError.handleError(error);
        }
      );
    this.agentService
      .updateStatus({
        id: this.userData["id"],
        status: "online",
        fcmtoken: this.userData.fcmtoken,
        token: this.userData.token,
      })
      .subscribe(
        (data) => { },
        (error) => {
          // this.handleError.handleError(error);
        }
      );
    this.returnUrl = this.route.snapshot.queryParams["returnUrl"] || "/";
  }

  ngAfterViewInit() {
    document.body.classList.add("authentication-bg");
    document.body.classList.add("authentication-bg-pattern");
  }
  get f() {
    return this.loginForm.controls;
  }

  onForget(f) {
    if (f.newPassword !== f.confirmPassword) {
      return (this.error =
        "please enter new password and confirm password as same");
    }
    this.authService
      .changePassword({
        mail: this.email,
        newPassword: f["newPassword"],
      })
      .subscribe(
        (data) => {
          this.forgetpassword = !this.forgetpassword;
          this.error = "";
          this.router.navigate(['/login']);
        },
        (error) => {
          this.error = "please enter valid email or password";

          // this.handleError.handleError(error);
        }
      );
  }

  onSubmit = () => {
    this.submitted = true;
    if (this.loginForm.invalid) {
      return;
    }
    this.loading = true;
    const body = {
      email: this.f.email.value,
      password: this.f.password.value,
    };

    this.http
      .post(`${this.baseUrl}agentsignin`, {
        Mailid: body.email,
        password: body.password,
        fcmtoken: this.fcmtoken,
      })
      .subscribe(
        (res) => {
          if (res !== null) {
            if (res["ResetPassword"]) {
              this.email = res["mail"];
              this.forgetpassword = false;
              return (this.resetPassword = true);
            }
            this.userData = res;
            this.userData.fcmtoken = this.fcmtoken;
            this.SignUPService.newDashboardAgentInfo({
              agentmail: this.userData.mail,
            }).subscribe((res: any) => {
              console.log("New Agent Info: ", res);
              if (res.status == 200) {
                this.userData.NewCompanyId = res.agent[0].CompanyId;
                this.userData.projects = res.agent[0].ProjectId;
                this.userData.SuperAdmin = res.agent[0].SuperAdmin;
                this.userData.newAgentId = res.agent[0].id;
                this.userData.workerSId = res.agent[0].workerSId;
                this.userData.workerFriendlyName = res.agent[0].workerFriendlyName;
              }
              if (!this.userData.status) {
                localStorage.setItem("userData", JSON.stringify(this.userData));
                this.authService.getUserRoles(res["id"] ? res["id"] : this.userData.id).subscribe(
                  (data) => {
                    let values: any[] = data.map((data) => data["Name"]);
                    this.authService.admin.next(values.includes("Admin"));
                  },
                  (error) => {
                    // this.handleError.handleError(error);
                  }
                );
                if (this.userData.NewCompanyId) {
                  this.LeadsDashboardService.getCompanyInfo({
                    companyId: this.userData.NewCompanyId,
                    mail: this.userData.mail
                  }).subscribe((res: any) => {
                    console.log("Company Info: ", res.data[0]);
                  });
                  this.router.navigate([
                    "/" + this.userData.NewCompanyId + "/home",
                  ]);
                } else {
                  this.router.navigate(["/uhleads"]);
                }

                // window.location.reload();
              } else {
                this.error = `${this.userData.status}`;
              }
            });
          }
        },
        (error) => {
          // this.handleError.handleError(error);
        }
      );
  };

  passwordChange() {
    if (this.error.length !== 0) {
      this.error = "";
    }
  }
  checkEmail() {
    if (this.emailForm.valid && !this.submitted) {
      this.submitted = true; // Set submitted flag to true
      // Other logic related to form submission, e.g., calling API
      console.log("Email value:", this.email); // Example: Log email value
      this.authService.checkEmail({ mail: this.email }).subscribe(
        (data) => {
          let replacements = {
            code: this.random,
            FirstName: data["firstName"],
            LastName: data["lastName"],
          };
          const result = this.template.replace(/{{([^{{]+)}}/g, (match, key) => {
            return replacements[key] !== undefined ? replacements[key] : "";
          });

          this.mailService
            .sendmail(
              {
                From: { email: "support@accessque.com", name: "Accessque Ltd" },
                To: `${this.email}`,
                Subject: "sent verfication code",
                Body: {
                  html: result,
                },
                ReplyTo: "support@accessque.com",
              },
              1
            )
            .subscribe((data) => {
              this.fp = "fp2";
              this.submitted = false;
            });
          this.selectIndex += 1;
          this.error = "";
        },
        (error) => {
          this.error = `${error.error.status}`;
          this.submitted = false;
          // this.handleError.handleError(error);
        }
      );
    } else {
      Object.keys(this.emailForm.controls).forEach(controlName => {
        const control = this.emailForm.controls[controlName];
        // Mark control as touched to show validation errors
        control.markAsTouched();
        // Set formErrors based on control validation status
        // if (control.invalid) {
        //     this.formErrors[controlName] = true;
        // }
    });
    }
  }

  comparePassword() {
    if (this.compareNumber === this.random) {
      this.selectIndex += 1;
      this.error = "";
      this.fp = "fp3";
    } else {
      this.error = "please enter the valid verification code";
    }
  }
  resetForm(value) {
    if (value.newPassword === value.confirmPassword) {
      this.authService
        .forgetPassword({
          newPassword: value.newPassword,
          mail: this.email,
          oldPassword: value.oldPassword,
        })
        .subscribe(
          (data) => {
            // this.forgetpassword = true;
            // this.resetPassword = false;
            this.router.navigate(['/login'])
          },
          (error) => {
            this.error = error.error.status;
            setTimeout(() => {
              this.error = "";
            }, 1000);
            // this.handleError.handleError(error);
          }
        );
    } else {
      this.error = "Please Enter Confirm Password and New Password as Same";
      setTimeout(() => {
        this.error = "";
      }, 1000);
    }
  }

  cancel() {
    console.log("cancel clicked");

    this.selectIndex = 0;
    this.forgetpassword = !this.forgetpassword;
    this.router.navigate(['/login']);
  }

  requestPermission() {
    const messaging = getMessaging();
    // GET TOKEN FROM FIREBASE
    getToken(messaging, { vapidKey: environment.firebase.vapidKey })
      .then((currentToken) => {
        if (currentToken) {
          this.fcmtoken = currentToken;
        } else {
        }
      })
      .catch((err) => { });
  }
  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }
}
