import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HttpClientService {
  userData: any
  constructor(
    private http: HttpClient) {

  }

  get<T>(url) {
    this.userData = JSON.parse(localStorage.getItem("userData"));

    let customHeaders = {
      headers: new HttpHeaders(
        { 'x-auth-token': 'asseccque', }
      )
    };
    if (this.userData) {
      customHeaders = {
        headers: new HttpHeaders(
          { 'x-auth-token': 'asseccque', 'Authentication': 'Bearer ' + this.userData.token }
        )
      }
    }
    // if(this.userData){
    //   customHeaders.headers.set('Authentication','Bearer '+this.userData.token)
    // }
    return this.http.get<T>(url, customHeaders);
  }

  post<T>(url, data) {
    this.userData = JSON.parse(localStorage.getItem("userData"));

    let customHeaders = {
      headers: new HttpHeaders(
        { 'x-auth-token': 'asseccque', "Access-Control-Allow-Origin": "*" }
      )
    };
    if (this.userData) {
      customHeaders = {
        headers: new HttpHeaders(
          { 'x-auth-token': 'asseccque', "Access-Control-Allow-Origin": "*", 'Authentication': 'Bearer ' + this.userData.token }
        )
      }
    }

    // if(this.userData){
    //   customHeaders.headers.set('Authentication','Bearer '+this.userData.token)
    // }
    return this.http.post<T>(url, data, customHeaders);
  }

  put<T>(url, data) {
    return this.http.put<T>(url, data, {
      headers: new HttpHeaders(

        { 'x-auth-token': 'asseccque' }
      )
    });
  }
  delete<T>(url) {
    return this.http.delete<T>(url, {
      headers: new HttpHeaders(

        { 'x-auth-token': 'asseccque', 'Authentication': 'Bearer ' + this.userData ? this.userData.token : '' }
      )
    });
  }


}
