<div mat-dialog-title class="p-3 dialog-title">
    <h3 class="component-title">{{header}}</h3>
    <button aria-label="close dialog" mat-dialog-close (click)="closeDialog()">
        <mat-icon>close</mat-icon>
    </button>
</div>

<mat-dialog-content>
    <div class="p-3">
        <div>
            <div class="d-flex flex-column align-items-center justify-content-center mb-4">
                <div><img src="{{imgSrc}}"></div>
                <div class="p-4 success-msg">{{title}}</div>
                <div class="success-msg-subtext">{{message}}</div>
            </div>
        </div>
    </div>
</mat-dialog-content>