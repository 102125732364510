import { Injectable } from "@angular/core";
import { HttpClientService } from "src/app/httpclient.service";
import { environment } from "src/environments/environment";
import { ToastrService } from "ngx-toastr";
import { ReferralData } from "../marketing/smsmarketing/models/ReferralData.model";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class UploadService {
  baseApiUrl = environment.baseApiUrl2;
  baseApiUrl2 = environment.SMSapiurl;



  uploadFile = new BehaviorSubject<any>(null);

  constructor(
    private http: HttpClientService,
    private toastr: ToastrService,
  ) { }


  getSms(id) {
    return this.http.get(`${this.baseApiUrl}communication/campbellsvilleSMS/` + id);
  }

  sendSms(data, projectId) {
    return this.http.post(`${this.baseApiUrl2}` + projectId + `/messaging-adapter/sendSMS`, data);
  }

}
