import { Component, EventEmitter, Input, Output, OnChanges, OnInit, ViewChild, ElementRef, SimpleChanges } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CustomService } from '../custom.service';
import { ColumnMap, ColumnSetting, FormSetting, ModalConfig, RowSetting } from '../layout.model';
import { fromEvent } from "rxjs";
import {
  filter,
  debounceTime,
  distinctUntilChanged,
  tap,
} from "rxjs/operators";

var sortJsonArray = require('node_modules/sort-json-array');

@Component({
  selector: 'app-custom-table',
  templateUrl: './custom-table.component.html',
  styleUrls: ['./custom-table.component.css']
})
export class CustomTableComponent implements OnInit, OnChanges {
  @Input() records: any;
  @Input() caption: string;
  @Input() settings: ColumnSetting[];
  @Input() rowSetting: RowSetting;
  @Input() projectId: number;
  @Input() callStatusSelected: boolean;
  @Input() menuId: number;
  @Output() updateLeadModal = new EventEmitter();
  @Output() callModal = new EventEmitter();
  @Output() sidenavOpen = new EventEmitter();
  @Output() dropdownSelectReuse = new EventEmitter();
  @Output() searchLeads = new EventEmitter();
  @Output() selectAllforAssignment = new EventEmitter();
  @Output() selectAgentforAssignmentNew = new EventEmitter();
  columnMaps: ColumnMap[];
  columnMaps1: any;
  recordMaps: any;
  sortColumn = "updatedAt";
  sortOrder = "des";
  listFeed: any;
  userData: any;
  @Input() isLoading: boolean;
  @Input() noRecords: boolean;
  statusValue = 'all';
  @ViewChild("inputString", { static: true }) input: ElementRef;
  @Input() selectedAll: any;
  constructor(
    private customService: CustomService,
  ) {
  }

  ngOnInit(): void {
    this.userData = JSON.parse(localStorage.getItem("userData"));
    fromEvent(this?.input?.nativeElement, "keyup")
      .pipe(
        filter(Boolean),
        debounceTime(1500),
        distinctUntilChanged(),
        tap((event: KeyboardEvent) => {
          this.searchLeads.emit({ search: this?.input?.nativeElement?.value, pageNumber: 1 });
        })
      )
      .subscribe();
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes.projectId && !changes.projectId.firstChange) {
      this.listFeedback(this.projectId,this.menuId);
    }
    if (changes.callStatusSelected && !changes.callStatusSelected.currentValue) {
      this.statusValue = 'all';
    }
    if (this.settings.length > 0) {
      this.columnMaps1 = this.settings;
    }
  }

  toggleSort(column, order) {
    if (this.sortOrder == 'asc') {
      this.sortOrder = 'des'
    } else {
      this.sortOrder = 'asc';
    }
    this.sortTableData(column, this.sortOrder);
  }

  sortTableData(column, order) {
    this.sortColumn = column;
    this.records = sortJsonArray(this.records, column, this.sortOrder);
  }
  listFeedback(id,menuId) {
    this.customService.listFeedback(id,menuId).toPromise()
      .then((resp: any) => {
        const listFeedbackWOAll = resp.feedback;
        const listFeedbackWOAllCall = resp.feedback.filter(x => x.type === 'call');
        this.listFeed = [
          {
            "name": "All",
            "type": "call",
            "value": "all",
            "active": true,
            "bgColor": "#fff4f4",
            "textColor": "#a52a1e",
            "feedbackId": 0
          }, ...listFeedbackWOAllCall];
      })
      .catch(err => {

      })
  }

  openEditModal(name, item) {
    this.updateLeadModal.emit({
      name: name,
      item: item
    })
  }
  callModal1(name, item, id) {
    this.callModal.emit({
      name: name, item: item, id: id
    })
  }
  openSideNav(id) {
    this.sidenavOpen.emit(id);
  }
  dropdownSelect(event) {
    this.dropdownSelectReuse.emit(event);
  }
  selectAllforAssignmentNew() {
    this.selectAllforAssignment.emit();
  }
  selectAgentforAssignment(event, item) {
    this.selectAgentforAssignmentNew.emit({ e: event, item: item })
  }
  getFeedbackColor(id) {
    var test1 = this.listFeed;
    var test2 = test1.filter(x => {
      if (x.value === id) {
        return x
      }
    })
    if (test2.length > 0) {
      return test2[0];
    } else {
      return []
    }
  }
}
