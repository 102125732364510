<div class="container-fluid">


    <div class="row">
        <div class="col-sm-4">
            <div class="pt-3 pl-2 pr-2 d-flex justify-content-between mb-4">
                <div class="db-sm-header" style="line-height: 330%">Knowledge Articles</div>
            </div>
            <div class="col-sm-12  m-0 p-0" *ngFor="let item of documentsList">
                <div class="card mb-1" *ngIf="item.type == 'doc'">
                    <div class="card-body">
                        <h6 class="card-title">{{item.name}}</h6>
                        <button class="btn btn-primary float-right"
                            (click)="openPreview(item.url,item.type)">Read</button>
                    </div>
                </div>
            </div>
            <div class="pt-3 pl-2 pr-2 d-flex justify-content-between mb-4">
                <div class="db-sm-header" style="line-height: 330%">Knowledge Sessions</div>
            </div>
            <div class="col-sm-12  m-0 p-0" *ngFor="let item of documentsList">
                <div class="card mb-1" *ngIf="item.type == 'video'">
                    <div class="card-body">
                        <h6 class="card-title">{{item.name}}</h6>
                        <button class="btn btn-primary float-right"
                            (click)="openPreview(item.url,item.type)">Watch</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm-8">
            <div *ngIf="docType == 'doc' ">
                <ngx-doc-viewer [url]="docUrl" viewer="google" googleCheckContentLoaded="true"
                    googleCheckInterval="5000" googleMaxChecks="15" style="width: 100%; height: 93vh">
                </ngx-doc-viewer>
            </div>
            <div *ngIf="docType == 'video' " style="margin-top: 10px;width: 100%; height: 600px;">
                <video [src]="docUrl" controls style="width: 100%;"></video>
            </div>
        </div>
    </div>
</div>