import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
// import { MatDatepickerInputEvent } from '@angular/material';
import { MomentFormatService } from 'src/app/moment-format-service';
import { ReportsService } from '../reports.service';
import * as XLSX from 'xlsx';
import { DashboardReportsService } from '../../dashboard-reports/dashboard-reports.service';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

@Component({
  selector: "app-report-filter",
  templateUrl: "./report-filter.component.html",
  styleUrls: ["./report-filter.component.css"],
})
export class ReportFilterComponent implements OnInit {
  userData: any;
  showProjects: boolean = false;
  showCourses: boolean = false;
  showApplications: boolean = false;
  showUsers: boolean = false;
  showEnrollmentYear: boolean = false;
  showEnrollmentTerm: boolean = false;
  showDate: boolean = false;
  showCreatedDate: boolean = false;
  showUpdatedDate: boolean = false;
  projectLists: any;
  coursesList: any;
  applicationsList: any;
  agentsList: any;
  enrollmentTermList: any;
  enrollmentYearList: any;
  list: any[] = [{ name: "Users", checked: false }];
  selectedIndexProject: number;
  currentProjectName: any;
  courseSelectionArray: any[] = [];
  applicationSelectionArray: any[] = [];
  usersSelectionArray: any[] = [];
  enrollmentYearSelectionArray: any[] = [];
  enrollmentTermSelectionArray: any[] = [];
  startDate: any;
  endDate: any;
  selectedIndexDate: string;
  showDateCustom: boolean = false;
  filterValue: string;
  date1: any;
  date2: any;
  filtersData: any;
  projectId: any;
  private colors = [
    [{ bg: "#FAE2DF", color: "#A52A1E" }],
    [{ bg: "#C8EAF2", color: "#014C5C" }],
    [{ bg: "#CDEDDC", color: "#04745A" }],
    [{ bg: "#D1E2FF", color: "#013CA3" }],
    [{ bg: "#e7fadf", color: "#39a51e" }],
    [{ bg: "#dffaeb", color: "#1ea55b" }],
    [{ bg: "#dfe2fa", color: "#1e2ea5" }],
  ];
  @Output() generate = new EventEmitter();
  @Output() export = new EventEmitter();
  constructor(
    private reportService: ReportsService,
    private momentService: MomentFormatService,
    private ReportsService: DashboardReportsService
  ) { }

  ngOnInit() {
    // 

    this.userData = JSON.parse(localStorage.getItem("userData") || "{}");
    this.date1 = new FormControl(new Date());
    this.date2 = new FormControl(new Date(Date.now() - 864e5));
    this.startDate = this.momentService.dashboardDateFormat(
      new Date(new Date(Date.now() - 864e5 * 365)).getTime()
    );
    this.endDate = this.momentService.dashboardDateFormat(
      new Date(new Date(Date.now() + 864e5))
    );




    this.getProjectList();
    this.getCourses();
    this.getAgentList();
    this.getEnrollmentTerm();
    this.getEnrollmentYear();
    this.getApplicationList();
    this.getAgentsCRM();
  }
  toggleFilters(val) {
    if (val === "showProjects") {
      this.showProjects = !this.showProjects;
      this.showCourses = false;
      this.showApplications = false;
      this.showUsers = false;
      this.showEnrollmentYear = false;
      this.showEnrollmentTerm = false;
      this.showDate = false;
      this.showCreatedDate = false;
      this.showUpdatedDate = false;
    } else if (val === "showCourses") {
      this.showProjects = false;
      this.showCourses = !this.showCourses;
      this.showApplications = false;
      this.showUsers = false;
      this.showEnrollmentYear = false;
      this.showEnrollmentTerm = false;
      this.showDate = false;
      this.showCreatedDate = false;
      this.showUpdatedDate = false;
    } else if (val === "showApplications") {
      this.showProjects = false;
      this.showCourses = false;
      this.showApplications = !this.showApplications;
      this.showUsers = false;
      this.showEnrollmentYear = false;
      this.showEnrollmentTerm = false;
      this.showDate = false;
      this.showCreatedDate = false;
      this.showUpdatedDate = false;
    } else if (val === "showUsers") {
      this.showProjects = false;
      this.showCourses = false;
      this.showApplications = false;
      this.showUsers = !this.showUsers;
      this.showEnrollmentYear = false;
      this.showEnrollmentTerm = false;
      this.showDate = false;
      this.showCreatedDate = false;
      this.showUpdatedDate = false;
    } else if (val === "showEnrollmentYear") {
      this.showProjects = false;
      this.showCourses = false;
      this.showApplications = false;
      this.showUsers = false;
      this.showEnrollmentYear = !this.showEnrollmentYear;
      this.showEnrollmentTerm = false;
      this.showDate = false;
      this.showCreatedDate = false;
      this.showUpdatedDate = false;
    } else if (val === "showEnrollmentTerm") {
      this.showProjects = false;
      this.showCourses = false;
      this.showApplications = false;
      this.showUsers = false;
      this.showEnrollmentYear = false;
      this.showEnrollmentTerm = !this.showEnrollmentTerm;
      this.showDate = false;
      this.showCreatedDate = false;
      this.showUpdatedDate = false;
    } else if (val === "showDate") {
      this.showProjects = false;
      this.showCourses = false;
      this.showApplications = false;
      this.showUsers = false;
      this.showEnrollmentYear = false;
      this.showEnrollmentTerm = false;
      this.showDate = !this.showDate;
      this.showCreatedDate = false;
      this.showUpdatedDate = false;
    } else if (val === "showCreatedDate") {
      this.showProjects = false;
      this.showCourses = false;
      this.showApplications = false;
      this.showUsers = false;
      this.showEnrollmentYear = false;
      this.showEnrollmentTerm = false;
      this.showDate = false;
      this.showCreatedDate = !this.showCreatedDate;
      this.showUpdatedDate = false;
    } else if (val === "showUpdatedDate") {
      this.showProjects = false;
      this.showCourses = false;
      this.showApplications = false;
      this.showUsers = false;
      this.showEnrollmentYear = false;
      this.showEnrollmentTerm = false;
      this.showDate = false;
      this.showCreatedDate = false;
      this.showUpdatedDate = !this.showUpdatedDate;
    }
  }
  getProjectList() {
    this.reportService
      .getProjectList(this.userData.id, this.userData.token)
      .toPromise()
      .then((resp: any) => {
        this.projectLists = resp.projectLists;

        for (let i = 0; i < resp.projectLists.length; i++) {
          if (resp.projectLists[i].Name == "Documents") {
            this.currentProjectName = resp.projectLists[i].Name;
            this.selectedIndexProject = resp.projectLists[i].id;
            this.reportService.projectId.next(this.selectedIndexProject)
            this.projectId = resp.projectLists[i].id;
          }
        }
        this.reportService.gerAgentsCRM2({ platform: 'CRM', projectId: this.selectedIndexProject }).subscribe((res: any) => {

          var agentList = res.data;
          agentList.forEach((y) => {
            y.ProfilePhoto = '';
            y.AgentColor = this.colors[this.numberFromText(y.agentInitial) % this.colors.length][0];
          });
          this.agentsList = agentList;
        })
      })
      .catch((err) => { });
  }
  getCourses() {
    this.reportService
      .getCourses(this.userData.id, this.userData.token)
      .toPromise()
      .then((resp: any) => {
        this.coursesList = resp.data;
      })
      .catch((err) => { });
  }
  getAgentsCRM() {
    this.reportService.gerAgentsCRM2({ platform: 'CRM', projectId: '2' }).subscribe((res: any) => {
      var agentList = res.data;
      agentList.forEach((y) => {
        y.ProfilePhoto = '';
        y.AgentColor =
          this.colors[
          this.numberFromText(y.agentInitial) % this.colors.length
          ][0];
      });
      this.agentsList = agentList;
    })
  }
  getAgentList() {
    // this.reportService
    //   .getAgents(this.userData.id, this.userData.token)
    //   .toPromise()
    //   .then((resp: any) => {
    //     var agentList = resp.data;
    //     agentList.forEach((y) => {
    //       y.AgentColor =
    //         this.colors[
    //           this.numberFromText(y.agentInitial) % this.colors.length
    //         ][0];
    //     });
    //     this.agentsList = agentList;
    //   })
    //   .catch((err) => {});
  }
  numberFromText(text) {
    const charCodes = text
      .split("") // => ["A", "A"]
      .map((char) => char.charCodeAt(0)) // => [65, 65]
      .join(""); // => "6565"
    return parseInt(charCodes, 10);
  }
  getApplicationList() {
    var postData = {
      userId: this.userData.id,
      token: this.userData.token,
      platform: "CRM",
    };
    this.reportService
      .getApplication(postData)
      .toPromise()
      .then((resp: any) => {
        this.applicationsList = resp.applicationStatusLists;
      })
      .catch((err) => { });
  }
  getEnrollmentTerm() {
    this.reportService
      .getEnrollmentTerm(this.userData.id, this.userData.token)
      .toPromise()
      .then((resp: any) => {
        this.enrollmentTermList = resp.enrollmentTermList;
      })
      .catch((err) => { });
  }
  getEnrollmentYear() {
    this.reportService
      .getEnrollmentYear(this.userData.id, this.userData.token)
      .toPromise()
      .then((resp: any) => {
        this.enrollmentYearList = resp.enrollmentYearList;
      })
      .catch((err) => { });
  }
  shareCheckedList(item: any[]) {
  }
  shareIndividualCheckedList(item: any) {
    if (item.name === "Users" && item.checked === true) {
      this.toggleFilters("showUsers");
    }
    if (item.name === "Applications" && item.checked === true) {
      this.toggleFilters("showApplications");
    }
    if (item.name === "Enrollment Year" && item.checked === true) {
      this.toggleFilters("showEnrollmentYear");
    }
    if (item.name === "Enrollment Term" && item.checked === true) {
      this.toggleFilters("showEnrollmentTerm");
    }
  }
  dateSelection(event) {
    if (event.target.value != "custom") {
      this.endDate = this.momentService.dashboardDateFormat(
        new Date(new Date(Date.now())).getTime()
      );
    }
    if (event.target.value == "custom") {
      this.filterValue = "custom";
      this.showDateCustom = true;
      this.selectedIndexDate = event.target.value;
    } else if (event.target.value == "24hrs") {
      this.showDateCustom = false;
      this.filterValue = "24hrs";
      this.selectedIndexDate = event.target.value;
      this.startDate = this.momentService.dashboardDateFormat(
        new Date(new Date(Date.now())).getTime()
      );
    } else if (event.target.value == "72hrs") {
      this.showDateCustom = false;
      this.filterValue = "72hrs";
      this.selectedIndexDate = event.target.value;
      this.startDate = this.momentService.dashboardDateFormat(
        new Date(new Date(Date.now() - 864e5 * 3)).getTime()
      );
    } else if (event.target.value == "07days") {
      this.showDateCustom = false;
      this.filterValue = "07days";
      this.selectedIndexDate = event.target.value;
      this.startDate = this.momentService.dashboardDateFormat(
        new Date(new Date(Date.now() - 864e5 * 7)).getTime()
      );
    } else if (event.target.value == "14days") {
      this.showDateCustom = false;
      this.filterValue = "14days";
      this.selectedIndexDate = event.target.value;
      this.startDate = this.momentService.dashboardDateFormat(
        new Date(new Date(Date.now() - 864e5 * 14)).getTime()
      );
    } else if (event.target.value == "30days") {
      this.showDateCustom = false;
      this.filterValue = "30days";
      this.selectedIndexDate = event.target.value;
      this.startDate = this.momentService.dashboardDateFormat(
        new Date(new Date(Date.now() - 864e5 * 30)).getTime()
      );
    }


  }
  hideDate() {
    // this.filterValuev
    if (this.filterValue == "custom" && this.showDateCustom == false) {
      this.showDateCustom = true;
    }
  }
  filterData() {
    // this.startDate = this.momentService.dashboardDateFormat(
    //   new Date(this.date2.value).getTime()
    // );
    // 

    // this.endDate = this.momentService.dashboardDateFormat(
    //   new Date(this.date1.value).getTime()
    // );
    // 


  }
  getStartDate(type: string, event: MatDatepickerInputEvent<Date>) {
    this.startDate = event.value;
    // 
    // this.startDate = this.momentService.dashboardDateFormat(
    //   new Date(this.startDate).getTime
    // );
    // 

  }
  getEndDate(type: string, event: MatDatepickerInputEvent<Date>) {
    this.endDate = event.value;
    // 
    // this.endDate = this.momentService.dashboardDateFormat(
    //   new Date(this.endDate).getTime
    // );
    // 

  }
  // FILTER FUCNTIONS
  // PROJECT SINGLE SELECTION HANDLE
  projectSelection(event, id, name) {
    if (event.target.checked) {
      this.selectedIndexProject = id;
      this.currentProjectName = name;
    } else {
      this.selectedIndexProject = undefined;
      this.currentProjectName = "";
    }
  }

  // COURSE MULTI SELECT HANDLE
  courseSelection(ev) {
    var s = ev.value;
    if (ev.checked) {
      this.courseSelectionArray.push(s);
    } else {
      var index = this.courseSelectionArray.indexOf(s);
      if (index > -1) {
        this.courseSelectionArray.splice(index, 1);
      }
    }
  }

  checkAllcourse(ev) {
    this.courseSelectionArray = [];
    this.coursesList.forEach((x) => {
      if (ev.target.checked === true) {
        this.courseSelectionArray.push(x.course);
      }
      return (x.courseState = ev.target.checked);
    });
  }
  isAllCheckedcourse() {
    var rValue = this.coursesList.every((_) => _.courseState);
    return rValue;
  }
  // APPLICATION MULTI SELECT HANDLE
  applicationSelection(ev) {
    var s = ev.value;
    if (ev.checked) {
      this.applicationSelectionArray.push(s);
    } else {
      var index = this.applicationSelectionArray.indexOf(s);
      if (index > -1) {
        this.applicationSelectionArray.splice(index, 1);
      }
    }
  }

  checkAllapplication(ev) {
    this.applicationSelectionArray = [];
    this.applicationsList.forEach((x) => {
      if (ev.target.checked === true) {
        this.applicationSelectionArray.push(x.LeadDocStatus);
      }
      return (x.applicationState = ev.target.checked);
    });
  }
  isAllCheckedapplication() {
    var rValue = this.applicationsList.every((_) => _.applicationState);
    return rValue;
  }
  unCheckapplicationSelectionArray() {
    this.applicationSelectionArray = [];
    this.applicationsList.forEach((x) => {
      return (x.applicationState = false);
    });
  }
  // Enrollment Year SELECT HANDLE
  enrollmentYearSelection(ev) {
    var s = ev.value;
    if (ev.checked) {
      this.enrollmentYearSelectionArray.push(s);
    } else {
      var index = this.enrollmentYearSelectionArray.indexOf(s);
      if (index > -1) {
        this.enrollmentYearSelectionArray.splice(index, 1);
      }
    }
  }

  checkAllenrollmentYear(ev) {
    this.enrollmentYearSelectionArray = [];
    this.enrollmentYearList.forEach((x) => {
      if (ev.target.checked === true) {
        this.enrollmentYearSelectionArray.push(x.EnrollmentYear);
      }
      return (x.enrollmentYearState = ev.target.checked);
    });
  }
  isAllCheckedenrollmentYear() {
    var rValue = this.enrollmentYearList.every((_) => _.enrollmentYearState);
    return rValue;
  }
  unCheckenrollmentYearSelectionArray() {
    this.enrollmentTermSelectionArray = [];
    this.enrollmentYearList.forEach((x) => {
      return (x.enrollmentYearState = false);
    });
  }
  // Enrollment Term SELECT HANDLE
  enrollmentTermSelection(ev) {
    var s = ev.value;
    if (ev.checked) {
      this.enrollmentTermSelectionArray.push(s);
    } else {
      var index = this.enrollmentTermSelectionArray.indexOf(s);
      if (index > -1) {
        this.enrollmentTermSelectionArray.splice(index, 1);
      }
    }
  }

  checkAllenrollmentTerm(ev) {
    this.enrollmentTermSelectionArray = [];
    this.enrollmentTermList.forEach((x) => {
      if (ev.target.checked === true) {
        this.enrollmentTermSelectionArray.push(x.EnrollmentTerm);
      }
      return (x.enrollmentTermState = ev.target.checked);
    });
  }
  isAllCheckedenrollmentTerm() {
    var rValue = this.enrollmentTermList.every((_) => _.enrollmentTermState);
    return rValue;
  }
  unCheckenrollmentTermSelectionArray() {
    this.enrollmentTermSelectionArray = [];
    this.enrollmentTermList.forEach((x) => {
      return (x.enrollmentTermState = false);
    });
  }
  // USERS MULTI SELECT HANDLE
  usersSelection(ev) {
    var s = ev.value;
    if (ev.checked) {
      this.usersSelectionArray.push(s);
    } else {
      var index = this.usersSelectionArray.indexOf(s);
      if (index > -1) {
        this.usersSelectionArray.splice(index, 1);
      }
    }
  }

  checkAllusers(ev) {
    this.usersSelectionArray = [];
    this.agentsList.forEach((x) => {
      if (ev.target.checked === true) {
        this.usersSelectionArray.push(x.Mailid);
      }
      return (x.usersState = ev.target.checked);
    });
  }
  isAllCheckedusers() {
    var rValue = this.agentsList.every((_) => _.usersState);
    return rValue;
  }
  unCheckusersSelectionArray() {
    this.usersSelectionArray = [];
    this.agentsList.forEach((x) => {
      return (x.usersState = false);
    });
  }

  // ===========================
  incomingVsCompletedData(filtersData) {
    this.ReportsService.getIncomingCompletedData(filtersData).subscribe((res: any) => {
      // 

      var fd = res.data.map(x => {
        var a = '';
        a = x?.Assignedto || 'Unassigned';
        var Assignedto = a;

        const { GUID, FirstName, LastName, Program, EnrollmentYear, EnrollmentTerm, LeadDocStatus, ClosedDate, ClosedBy, createdAt, CallStatus } = x;
        return { GUID, FirstName, LastName, Program, Assignedto, EnrollmentYear, EnrollmentTerm, LeadDocStatus, ClosedDate, ClosedBy, createdAt, CallStatus };
      })

      this.downloadFile(fd, 'LeadsData');
    })
  }

  callsData(filtersData) {
    this.ReportsService.getCallsPerDayData(filtersData).subscribe((res: any) => {
      // 

      var fd = res.data.map(x => {
        var a = '';
        a = x?.Assignedto || 'Unassigned';
        var Assignedto = a;

        const { id, CallerNumber, CallerCity, CallerCountry, CallerState, Direction, CalledNumber, CalledCity, CalledCountry, CalledState, RecordingDuration, RecordingURL, CallAnswered, CallSid, createdAt, updatedAt, WorkerSid, ConferenceSid, InitialCallSid, LeadId, Comments, Status, LeadType, FirstName, LastName } = x;
        return { id, CallerNumber, CallerCity, CallerCountry, CallerState, Direction, CalledNumber, CalledCity, CalledCountry, CalledState, RecordingDuration, RecordingURL, CallAnswered, CallSid, createdAt, updatedAt, WorkerSid, ConferenceSid, InitialCallSid, LeadId, Comments, Status, LeadType, FirstName, LastName };
      })

      this.downloadFile(fd, 'CallsData');
    })
  }

  downloadFile(filedata, name) {

    var json = filedata;

    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    var a = document.createElement("a");
    a.setAttribute('style', 'display:none;');
    document.body.appendChild(a);
    var blob = new Blob([excelBuffer], { type: EXCEL_TYPE });
    var url = window.URL.createObjectURL(blob);
    a.href = url;
    var x: Date = new Date();
    var link: string = name + "-" + x.getFullYear() + "-" + x.getMonth() + "-" + x.getDay() + '.xlsx';
    a.download = link;
    a.click();
  }

  generateData() {
    // this.startDate.setDate(this.startDate.getDate() - 1)
    var postData = {
      course:
        this.courseSelectionArray.length === this.coursesList.length
          ? "All"
          : this.courseSelectionArray.length > 0
            ? this.courseSelectionArray
            : "",
      agentEmail:
        this.usersSelectionArray.length === this.agentsList.length
          ? "All"
          : this.usersSelectionArray.length > 0
            ? this.usersSelectionArray
            : "",
      from: this.startDate,
      // from : this.startDate.setDate(this.startDate.getDate() - 1),
      to: this.endDate,
      // enrollmentYear:
      //   this.enrollmentYearSelectionArray.length ===
      //   this.enrollmentYearList.length
      //     ? "All"
      //     : this.enrollmentYearSelectionArray.length > 0
      //     ? this.enrollmentYearSelectionArray
      //     : "",
      // enrollmentTerm:
      //   this.enrollmentTermSelectionArray.length ===
      //   this.enrollmentTermList.length
      //     ? "All"
      //     : this.enrollmentTermSelectionArray.length > 0
      //     ? this.enrollmentTermSelectionArray
      //     : "",
      userId: this.userData.id,
      token: this.userData.token,
      platform: "CRM",
      ProjectId: this.projectId,
      // application:
      //   this.applicationSelectionArray.length === this.applicationsList.length
      //     ? "All"
      //     : this.applicationSelectionArray.length > 0
      //     ? this.applicationSelectionArray
      //     : "",
    };
    // 
    this.filtersData = postData
    this.reportService.filterData.next(postData);

    this.generate.emit(postData);
  }
  getAgentName(val) {
    var agentList = this.agentsList;
    var name = agentList.filter((x) => x.Mailid === val)[0];
    return name.agentname;
  }
  exportData() {
    var postData = {
      course:
        this.courseSelectionArray.length === this.coursesList.length
          ? "All"
          : this.courseSelectionArray.length > 0
            ? this.courseSelectionArray
            : "",
      agentEmail:
        this.usersSelectionArray.length === this.agentsList.length
          ? "All"
          : this.usersSelectionArray.length > 0
            ? this.usersSelectionArray
            : "",
      from: this.startDate,
      to: this.endDate,
      enrollmentYear:
        this.enrollmentYearSelectionArray.length ===
          this.enrollmentYearList.length
          ? "All"
          : this.enrollmentYearSelectionArray.length > 0
            ? this.enrollmentYearSelectionArray
            : "",
      enrollmentTerm:
        this.enrollmentTermSelectionArray.length ===
          this.enrollmentTermList.length
          ? "All"
          : this.enrollmentTermSelectionArray.length > 0
            ? this.enrollmentTermSelectionArray
            : "",
      userId: this.userData.id,
      token: this.userData.token,
      platform: "CRM",
      ProjectId: this.projectId,
      application:
        this.applicationSelectionArray.length === this.applicationsList.length
          ? "All"
          : this.applicationSelectionArray.length > 0
            ? this.applicationSelectionArray
            : "",
    };
    this.incomingVsCompletedData(postData);
    this.callsData(postData);
    this.export.emit();
  }
  resetFilters() {
    this.startDate = this.momentService.dashboardDateFormat(
      new Date(new Date(Date.now() - 864e5 * 365)).getTime()
    );
    this.endDate = this.momentService.dashboardDateFormat(
      new Date(new Date(Date.now() + 864e5))
    );
    this.courseSelectionArray = [];
    this.usersSelectionArray = [];
    // this.unCheckusersSelectionArray();
    // this.unCheckapplicationSelectionArray();
    this.checkAllcourse({ target: { checked: false } })
    this.checkAllusers({ target: { checked: false } });
    this.filterValue = '';
    this.selectedIndexDate = '';
    this.generateData();

  }
}
