import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClientService } from 'src/app/httpclient.service';

@Injectable({
  providedIn: 'root'
})
export class SignupService {
  baseApiUrl2: any = environment.baseApiUrl2;
  constructor(private http: HttpClientService) { }

  addAgentOld(data) {
    return this.http.post(
      `${this.baseApiUrl2}leads/signup`,
      data
    );
  }

  addAgent(data) {
    return this.http.post(
      `${this.baseApiUrl2}invitedUsers/Signup`,
      data
    );
  }
  agentSignup(data) {
    return this.http.post(
      `${this.baseApiUrl2}agentSignup`,
      data
    );
  }
  getStripeCustomer(data) {
    return this.http.post(
      `${this.baseApiUrl2}leads/getStripeCustomer`,
      data
    );
  }

  newDashboardAgentInfo(data) {
    return this.http.post(`${this.baseApiUrl2}invitedUsers/newDashboardAgentInfo`, data);
  }

}
