import { ManagementService } from "../management.service";
import { NgForm } from "@angular/forms";
import { SharedService } from "./../../shared/shared.service";
import { Component, OnInit, ViewChild } from "@angular/core";
import { NgbModal, ModalDismissReasons, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { Role } from "../create-agent/models/role.model";
import { RoleService } from "./role.service";
import { AuthService } from "src/app/account/auth/auth.service";
import { IDropdownSettings } from "ng-multiselect-dropdown";
import { ActivatedRoute } from "@angular/router";
import { ErrorHandlerService } from "src/app/error-handler.service";
@Component({
  selector: "app-role-management",
  templateUrl: "./role-management.component.html",
  styleUrls: ["./role-management.component.less"],
})
export class RoleManagementComponent implements OnInit {
  public roles: Role[] = [];
  public page: any = 1;
  public pageSize: any = 10;
  public isError: boolean = false;
  public closeResult: string = "";
  public error: string = "";
  public search: string = "";
  public isUpdate: boolean = false;
  public name: string = "";
  public description: string = "";
  public id: number;
  public activestatus: any = "";
  public menus: any[];
  public displayMenus: any[] = [1, 2, 3, 4];
  public menuId: any;
  public selectedIndex: number = 0;
  public admin: Boolean;
  // public twilioNumber: any;
  // public supportMail: any;
  public active = 1;
  public company: any;
  public roleSettings: IDropdownSettings;
  public editRoleName: boolean = false;
  public order: boolean = false;
  public orderFeild: string = "UpdatedDate";
  public orderString: boolean | "asc" | "desc" = "desc";
  menuModal: NgbModalRef;
  addMenuModal: NgbModalRef;
  @ViewChild('content', { static: false }) private content;

  public menuname: string = "";
  public menudescription: string = "";
  public menuactivestatus: any = "";
  public menuParent: any = "";


  constructor(
    private modalService: NgbModal,
    private roleService: RoleService,
    private SharedService: SharedService,
    private managementService: ManagementService,
    private authService: AuthService,
    private handleError: ErrorHandlerService,
    private route: ActivatedRoute
  ) { }

  getRoles() {
    this.roleSettings = {
      singleSelection: true,
      allowSearchFilter: true,
      closeDropDownOnSelection: true,
      textField: "Name",
    };

    this.roleService.fetchRoles(this.company.projectId).subscribe((data) => {
      this.roles = data;
    }, (error) => {
      this.handleError.handleError(error)
    });
  }

  ngOnInit() {
    this.route.params.subscribe((data) => {
      this.company = {
        id: data["id"],
        Name: data["name"],
        projectId: data["projectId"],
      };

      // 
      this.getRoles();
    });

    this.authService.admin.subscribe((data) => {
      this.admin = data;

    });
    this.SharedService.pageEvent.emit({
      pageName: "Role and Group Management",
    });
    // this.roleService.getCompanies().subscribe(data => {
    //   this.companies = data;
    // })
    this.managementService.getMenus().subscribe((data) => {
      this.menus = data;

    }, (error) => {
      this.handleError.handleError(error)
    });
  }

  open(content) {
    this.isError = false;
    this.modalService.open(content, { size: "md" }).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }

  open1(content) {
    this.isError = false;
    this.menuModal.close();
    this.addMenuModal = this.modalService.open(content);
    this.addMenuModal.result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
        this.modalService.open(this.content);
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        this.modalService.open(this.content);
      }
    );
  }

  private getDismissReason(reason: any): string {

    this.getRoles();
    this.name = "";
    this.description = "";
    this.isUpdate = false;
    this.isError = false;
    this.id = null;
    this.selectedIndex = 0;
    this.editRoleName = false;

    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }

  public submit(form: NgForm) {
    if (!this.isUpdate) {
      this.name = form.value["name"];
      this.description = form.value["description"];
      this.activestatus = form.value["ActiveStatus"];
      this.selectedIndex += 1;

    } else {
      this.roleService
        .updateRole({
          id: this.id,
          name: this.name,
          Description: this.description,
          activestatus: this.activestatus === "active",
          companyName: this.company["Name"],
          companyId: this.company["id"],
        })
        .subscribe(
          (data) => {
            if (data["status"] === "successfully updated Role") {
              this.selectedIndex += 1;
            } else {
              this.isError = true;
              this.error = "please try again ";
            }
          },
          (err) => {
            this.isError = true;
            this.error = err.error.status;
            this.handleError.handleError(err)

          }
        );
    }
  }

  public updateRole(id: number, form: any, content) {
    let selectedRole = this.roles[id];


    this.isUpdate = true;
    this.roleService.getRoleById(id).subscribe((data) => {
      this.name = data["Name"];
      this.description = data["Description"];
      this.activestatus = data["ActiveStatus"] ? "active" : "inactive";
      this.editRoleName = data["DefaultRole"];
      // this.company = [{ id: data['CompanyId'], Name: data['CompanyName'] }]
      // this.twilioNumber = data['TwilioNumber'];
      // this.supportMail = data['SupportMail']
      this.id = data["id"];

      this.managementService.getRoleByName(this.id).subscribe((data) => {
        this.menuId = data["id"];
        this.displayMenus = data["DisplayMenus"];
        this.open(content);
      }, (error) => {
        this.handleError.handleError(error)
      });
    }, (error) => {
      this.handleError.handleError(error)
    });
  }

  menuCheckedValues(value) {
    this.checkingParentMenu(this.menus);
    if (this.isUpdate) {
      this.managementService
        .updateMenu({ id: this.menuId, displayMenus: this.displayMenus })
        .subscribe((data) => { }, (error) => {
          this.handleError.handleError(error)
        });
      return this.modalService.dismissAll();
    } else {
      this.roleService
        .createRole({
          name: this.name,
          description: this.description,
          companyName: this.company["Name"],
          companyId: this.company["id"],
          projectId: this.company["projectId"],
        })
        .subscribe(
          (data) => {
            this.id = data["id"];
            this.name = data["Name"];
            this.managementService
              .createRoleMenu({
                roleId: this.id,
                displayMenus: this.displayMenus,
              })
              .subscribe(
                (data) => { },
                (err) => {
                  this.isError = true;
                  this.error = err.error.status;
                  setTimeout(() => {
                    this.isError = false;
                  }, 10000);
                }
              );
            this.modalService.dismissAll();
          },
          (err) => {
            this.isError = true;
            this.error = err.error.status;
            setTimeout(() => {
              this.isError = false;
            }, 10000);
            this.handleError.handleError(err)

          }
        );
    }
  }

  parentMenuCheckbox(value, id, menuId) {
    if (value.option._selected) {
      this.displayMenus.push(value.source._value[0]);
      if (!this.displayMenus.includes(id)) {
        this.displayMenus.push(id);
      }
    } else {
      this.displayMenus = this.displayMenus.filter((val) => val !== menuId);
    }
  }

  childMenuCheckBox(value, menuId) {
    if (value.option._selected) {
      this.displayMenus.push(value.source._value[0]);
    } else {
      this.displayMenus = this.displayMenus.filter((val) => val !== menuId);
    }
  }

  checkBox(item): boolean {
    return this.displayMenus.includes(parseInt(item));
  }

  checkingParentMenu(items) {
    for (let item of items) {
      if (item["child"].length !== 0) {
        const newArr = item["child"].find((val) =>
          this.displayMenus.includes(val["id"])
        );
        if (!newArr && this.displayMenus.includes(item["parent"]["id"])) {
          this.displayMenus = this.displayMenus.filter(
            (val) => val !== item["parent"]["id"]
          );

        }
      }
    }
  }

  selectedRole(id) {

    this.roleService.selectedRole.next(id);
  }

  sortOrder(feildName) {
    this.orderFeild = feildName;
    this.order = !this.order;
    if (this.order) {
      this.orderString = "asc";
    } else {
      this.orderString = "desc";
    }
  }
  submitAddMenu(form: NgForm) {
    let obj = {
      name: this.menuname,
      description: this.menudescription,
      parent: this.menuactivestatus == 'yes' ? 0 : this.menuParent,
      active: 'yes'
    };
    this.roleService.addNewMenu(obj).toPromise()
      .then((resp: any) => {
        this.addMenuModal.close();
      })
      .catch(err => {

      })
  }
}
