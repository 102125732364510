<div class="right-content-wrapper pad-bot-0" style="padding: 0">
	<div class="chat-page-main-wrapper">
		<div class="chat-page-box-header">
			<p class="modal-title">Chat</p>
			<div class="clsoseflex">
				<div class="clsoseflex">
					<button class="btn btn-sm btn-primary" (click)="complete()" [disabled]="isloading">
						End Chat
					</button>
					<div *ngIf="this.agents[index]">
						<button class="btn btn-sm btn-primary mr-2" (click)="removeAgent()" [disabled]="isloading"
							*ngIf="
								selectedIndex === 1 && this.agents[index]['members'].length > 1
							">
							Exit
						</button>
					</div>
					<span (click)="closeChat()">
						<img src="../../../assets/images/minimise.svg" class="img20" />
					</span>
				</div>
			</div>
		</div>
		<div class="chat-box-content-wrapper">
			<div class="chat-page-left-wrapper">
				<div class="search-bar-container">
					<input class="form-control form-control-sm" type="text" placeholder="Search" />
					<a class="search-icon-link" href="javascript:void(0);">
						<img class="search-icon-img" src="./assets/img/search (5).svg" alt="" />
					</a>
				</div>

				<div class="chat-list-wrapper">
					<mat-tab-group [(selectedIndex)]="selectedIndex" (selectedTabChange)="tabChange($event)">
						<mat-tab label="Customers">
							<div *ngIf="typingNotification">
								<p>{{typingNotification}}</p>
							</div>
							<div *ngFor="let item of task; let i = index">

								<div class="chat-list-container" [ngClass]="{ active: index === i }"
									(click)="selectedChat(i, 'client')" *ngIf="task.length !== 0"
									style="cursor: pointer">
									<div class="title-icon">
										{{
										item['attributes']['name'].slice(0, 1) === '+'
										? 'S'
										: item['attributes']['UserName'] !== undefined
										? (item['attributes']['UserName'].slice(0, 1)
										| uppercase)
										: (item['attributes']['name'].slice(0, 1) | uppercase)
										}}
									</div>
									<div class="chat-content-block">
										<div class="chat-head-block">
											<span>{{
												item['attributes']['UserName']
												? item['attributes']['UserName']
												: item['attributes']['name'].slice(0, 18)
												}}</span>
											<span style="font-size: 13px; color: #FA952F">{{
												item['attributes']['channel'] === 'sms' ? 'sms' : ''
												}}</span>
											<span style="font-size: 13px; color: #FA952F">{{
												item['attributes']['channel'] === 'whatsapp'
												? 'whatsapp'
												: ''
												}}</span>
											<span class="nofify-total" *ngIf="item['unReadMessages'] !== 0">{{
												item['unReadMessages'] }}</span>
										</div>
										<div class="notify-description" *ngIf="item['lastMessage']">
											<span>{{ item['lastMessage']['body'].slice(0, 25) }}</span>
											<span>{{
												getMessageTime(item['lastMessage']['state']['timestamp'])
												}}</span>
										</div>
									</div>
								</div>
							</div>
							<div *ngIf="task && task"></div>
						</mat-tab>
						<mat-tab label="Agent">
							<div *ngIf="typingNotification">
								<p>{{typingNotification}}</p>
							</div>
							<div *ngFor="let item of agents; let i = index">

								<div class="chat-list-container" [ngClass]="{ active: index === i }"
									(click)="selectedChat(i, 'agent')" *ngIf="agents.length !== 0"
									style="cursor: pointer">
									<div class="title-icon" *ngIf="item['name']">
										{{ item['name'].slice(0, 1) | uppercase }}
									</div>
									<div class="chat-content-block">
										<div class="chat-head-block">
											<span class="header-sm">{{
												item['members'].length === 1
												? (item['name'] | agentname: totalAgents | titlecase)
												: 'Group Chat'
												}}</span><span class="nofify-total"
												*ngIf="item['unReadMessages'] !== 0">{{ item['unReadMessages'] }}</span>
										</div>
										<div class="notify-description" *ngIf="item['lastMessage']['body']">
											<span>{{ item['lastMessage']['body'].slice(0, 25) }}</span>
											<span>
												<!-- {{
												item['lastMessage']['state']['timestamp'] | date: 'shortTime'
												}} -->
												{{
												getMessageTime(item['lastMessage']['state']['timestamp'])
												}}
											</span>
										</div>
									</div>
								</div>
							</div>
						</mat-tab>
					</mat-tab-group>
				</div>
			</div>
			<div class="chat-page-center-wrapper">

				<div class="chat-messages-main-wrapper p-3" style="height: 84% !important;">

					<!--
							<div class="chat-messages-header d-flex align-items-center">
						<div class="chat-message-header-icon-container" *ngIf="selectedIndex === 0">
							<div>
								<span style="color: #163252; font-size: 16px">{{
									this.customerInformation['ticketId']
									}}</span>
							</div>
						</div>
						<div style="font-size: 12px; margin-left: 1rem; position: relative">
							<mat-form-field appearance="outline" *ngIf="selectedIndex === 0" style="position: relative">
								<mat-select [(ngModel)]="transferQueue" style="position: relative">
									<mat-option *ngFor="let queue of taskQueues" [value]="queue.sid">
										{{ queue.friendlyName }}
									</mat-option>
								</mat-select>
							</mat-form-field>
						</div>
						-->
					<!--
						<div class="mx-2" *ngIf="selectedIndex === 0">
							<button class="btn btn-primary btn-sm" (click)="transferChat()">
								Transfer Chat
							</button>
						</div>
					</div>
						-->


					<!-- <div class="chat-messages-header d-flex align-items-center">
						<div
							*ngIf="selectedIndex === 1 && this.agents[index]"
							class="d-flex"
							style="overflow-x: auto; overflow-y: hidden"
						>
							<div
								*ngFor="let member of this.agents[index]['members']"
								class="mx-1"
							>
								<span style="font-size: 20px">{{ member }}</span>
							</div>
						</div>
					</div> -->
					<div class="clearfix chat-messages-window-wrapper">
						<div class="scrollbar" id="style-1" #chatContainer>
							<div class="force-overflow">
								<div *ngIf="isloading" class="d-flex justify-content-center">
									<mat-spinner></mat-spinner>
								</div>

								<div *ngIf="!isloading">
									<div>
										<div *ngFor="let message of messages" class="chat-block">
											<div class="chat-messages-left-wrapper"
												*ngIf="!(message.author === session.identity)">
												<div *ngIf="message.author === 'system'" class="system my-1">
													{{message.body | titlecase }}
												</div>
												<div *ngIf="message.author !== 'system'">
													<div class="messages-user-img-container">
														<img class="user-img"
															src="../../../assets/img/chat-user-icon.png" alt="" />
														<div class="messages-status online"></div>
													</div>
												</div>
												<div style="width: auto; word-break: break-word"
													*ngIf="message.author !== 'system'">
													<div class="message-left-bubble flex-column">
														<div class="author my-1" *ngIf="selectedIndex === 1">
															{{
															message.author
															| agentname: totalAgents
															| titlecase
															}}
														</div>
														<div class="author my-1" *ngIf="selectedIndex === 0">
															{{
															task[index]['attributes']['UserName'] !==
															undefined
															? task[index]['attributes']['UserName']
															: (message.author
															| agentname: totalAgents
															| titlecase)
															}}
														</div>
														<p class="message-text" style="color: black"
															[ngClass]="{ special: message.style }">
															{{ message.body }}
														</p>
													</div>
													<p class="message-time" style="color: black">
														{{ message.dateUpdated | date: 'short' }}
													</p>
												</div>
											</div>
											<div class="chat-messages-right-wrapper"
												*ngIf="message.author === session.identity">
												<div style="
														width: auto;
														margin-right: 15px;
														word-break: break-word;
													">
													<div class="message-right-bubble">
														<div class="author my-1">
															{{
															message.author
															| agentname: totalAgents
															| titlecase
															}}
														</div>

														<p class="message-text" style="color: black">
															{{message.body | titlecase }}
													</div>
													<p class="message-time right-side" style="color: black">
														{{ message.dateUpdated| date: 'short' }}
													</p>
												</div>
												<div>
													<div class="messages-user-img-container">
														<img class="user-img" src="../../../assets/img/user-avatar.png"
															alt="" />
														<div class="messages-status online"></div>
													</div>
												</div>
											</div>

										</div>
										<div *ngIf="typingNotification">
											<p>{{typingNotification}}</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div class="message-send-box-wrapper">
						<div class="chat-bottom-block pt-3 pl-3 pr-3 pb-2">
							<input type="text" class="form-control form-control-sm mb-1" style="font-size:12px;" name=""
								(keyup)="enterKey($event)" [(ngModel)]="chatMessage" placeholder="Type a message.." />
							<div class="d-flex">
								<div>
									<img src="../../../assets/img/attachment.svg" class="img14 mr-2">
								</div>
								<div>
									<mat-icon
										style="color:#999!important; cursor:pointer!important;font-size:16px;margin-top:2px; "
										(click)="isIconShow = !isIconShow">mood
									</mat-icon>
								</div>
								<div>
									<emoji-mart *ngIf="isIconShow" (emojiClick)="addEmoji($event)" emoji="point_up"
										[hideObsolete]="true" [set]="'facebook'"></emoji-mart>
								</div>
								<div class="ml-auto">
									<button class="btn btn-link btn-sm">
										<img (click)="send()" src="../../../assets/img/send-message.svg"
											class="img16" />
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="chat-page-right-wrapper p-3">
				<div *ngIf="selectedIndex === 0">
					<div class="header-12">User Details</div>
					<div class="py-3">
						<div class="form-group">
							<label class="chat-label">Name</label>
							<input class="form-control form-control-sm" type="text"
								[(ngModel)]="customerInformation['name']" name="name" [disabled]="true" />
						</div>
						<div class="form-group">
							<label class="chat-label">Email</label>
							<input class="form-control form-control-sm" type="text"
								[(ngModel)]="customerInformation['mail']" [disabled]="true" />
						</div>
						<div class="form-group">
							<label class="chat-label">Mobile</label>
							<input class="form-control form-control-sm" type="text"
								[(ngModel)]="customerInformation['phoneNumber']" [disabled]="true" />
						</div>
						<div class="form-group">
							<label class="chat-label">Description</label>
							<textarea class="form-control form-control-sm" name="" id="" cols="25" rows="6"
								[(ngModel)]="customerInformation['desc']" [disabled]="true"></textarea>
						</div>
					</div>
					<div class="header-12">Latest Chats</div>
					<div style="height: 20vh">
						<div class="latest-total-block" *ngFor="let item of customerTickets">
							<div style="
									display: flex;
									justify-content: space-between;
									padding-bottom: 10px;
									align-items: center;
									font-size: 15px;
									color: #605d5d;
								">
								<span style="font-size: 10px">{{
									item['CreatedDate'] | date: 'medium'
									}}</span><span class="open-tick-status" [ngClass]="{
										'close-tick-status': item['RequestStatus'] === 'open'
									}">{{ item['RequestStatus'] }}</span>
								<span>
									<span class="mx-2" class="section-heading" style="font-size: 14px; cursor: pointer"
										[routerLink]="['/tickets/ticket-view', item['id']]">{{ item['RequestId']
										}}</span>
								</span>
							</div>
							<p>{{ item['RequestDetail'] }}</p>
						</div>
					</div>
				</div>
				<div *ngIf="selectedIndex === 1">
					<div>
						<div class="mb-2">
							<select (ngModelChange)="projectChange1($event)" [(ngModel)]="projectId"
								class="form-control" style="font-size:12px;">
								<option *ngFor="let company of projects" [value]="company['id']">
									{{ company["Name"] }}
								</option>
							</select>
						</div>
						<div class="d-flex align-items-center"
							style="padding-bottom: 15px; justify-content: space-between">

							<div class="header-12">Participants</div>
							<div class="mx-2">
								<button *ngIf="selectedIndex === 1 && this.agents[index]" class="btn btn-primary btn-sm"
									(click)="addAgent()">
									<img src="../../../assets/img/add-w.svg" class="img9">
								</button>
							</div>
						</div>
						<div *ngIf="this.agents[index]">
							<div *ngFor="let member of this.agents[index]['members']" class="mar-bot15">
								<span class="mx-2" style="position: relative">
									<img src="{{ member | profilephoto: totalAgents }}"
										style="width: 25px; height: 25px; border-radius: 50%" alt="{{ member }}"
										srcset="" />
									<span class="{{ member | loginstatus: totalAgents }}"></span>
								</span>
								<span class="header-sm">{{
									member | agentname: totalAgents | titlecase
									}}</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>