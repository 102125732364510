import { AuthService } from "src/app/account/auth/auth.service";
import { FormControl, NgForm } from "@angular/forms";
import { RoleService } from "../role-management/role.service";
import { Component, OnInit } from "@angular/core";
import { NgbModal, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";
import { SharedService } from "src/app/shared/shared.service";
import { ManagementService } from "../management.service";
import { IDropdownSettings } from "ng-multiselect-dropdown";
import { CreateAgent } from "../create-agent/create-agent.service";
import { environment } from "src/environments/environment";
import { ErrorHandlerService } from "src/app/error-handler.service";
import { Router } from "@angular/router";
import { MomentFormatService } from "src/app/moment-format-service";
import { CreateCompanyService } from "../create-company/create-company.service";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import { UhLeadsService } from "../uhleads.service";
import * as moment from "moment";


@Component({
  selector: "app-company-management",
  templateUrl: "./company-management.component.html",
  styleUrls: ["./company-management.component.css"],
})
export class CompanyManagementComponentNew implements OnInit {
  closeResult: string;
  search: string;
  companyInfo: any = {
    name: "",
    twilioNumber: "",
    supportMail: "",
    forwardMail: environment.forwardMail,
  };
  totalCompanies: any[] = [];
  isUpdate: boolean = false;
  inputData: any;
  isError: boolean = false;
  errorMessage: string;
  superAdmin: Boolean = false;
  userData: any;
  public page: any = 1;
  public pageSize: any = 8;
  selectedCountryCode;
  countries: any[];
  group: any[] = [];
  groups: any[] = [];
  companySettings: IDropdownSettings;
  public order: boolean = false;
  public orderFeild: string = "UpdatedDate";
  public orderString: boolean | "asc" | "desc" = "desc";
  projectsCount: any;
  isLoading: Boolean = false
  noRecords: Boolean = false
  topBarCounts = {
    totalcompanies: 0,
    acivecompanies: 0,
    inacivecompanies: 0,
    last6monthcompanies: 0,
    currentmonthcompanies: 0,
    renewalExpirycompanies: 0
  };
  currentmonthcompanies: any = null
  startDate: any;
  endDate: any;
  date1: any;
  date2: any;
  tab: any;

  showDate: boolean = false;
  public filterValue = "all";
  selectedCard: any = 'all'
  constructor(
    private modalService: NgbModal,
    private companyService: RoleService,
    private sharedService: SharedService,
    private authService: AuthService,
    private managementService: ManagementService,
    private handleError: ErrorHandlerService,
    private agentService: CreateAgent,
    private router: Router,
    private momentService: MomentFormatService,
    private CreateCompanyService: CreateCompanyService,
    private uhleadsservice: UhLeadsService,

  ) {

  }

  ngOnInit() {
    this.companySettings = {
      singleSelection: true,
      allowSearchFilter: true,
      closeDropDownOnSelection: true,
      textField: "Name",
    };

    this.fetchCountries();
    this.userData = JSON.parse(localStorage.getItem("userData"));
    this.sharedService.companyCreated.next("company created");
    this.sharedService.pageEvent.emit({ pageName: "Company Management" });
    this.authService.admin.subscribe((data) => {
      this.superAdmin = data;
    });
    this.date1 = new FormControl(new Date());
    this.date2 = new FormControl(new Date(Date.now() - 864e5));
    this.filterValue == "24hrs";
    this.endDate = new Date(Date.now());
    this.startDate = new Date(Date.now() - 864e5 * 365);

    this.startDate = this.momentService.dashboardDateFormat(
      new Date(new Date(Date.now() - 864e5 * 365)).getTime()
    );
    this.endDate = this.momentService.dashboardDateFormat(
      new Date(new Date(Date.now() + 864e5))
    );
    this.currentmonthcompanies = moment().format('MMMM');
    this.onClick(1);

    this.getTopBarCards();
    this.getComapnies(this.userData.companyId);

  }

  open(content) {
    // this.isError = false;
    this.router.navigate(["/createCompany"])
    // this.modalService.open(content, { size: "md" }).result.then(
    //   (result) => {
    //     this.closeResult = `Closed with: ${result}`;
    //   },
    //   (reason) => {
    //     this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    //   }
    // );
  }

  private getDismissReason(reason: any): string {
    this.getComapnies(this.userData.companyId);
    this.isUpdate = false;
    this.companyInfo["id"] = null;
    this.resetForm();
    this.isError = false;
    this.companyInfo.forwardMail = environment.forwardMail;
    // this.companyInfo = { name: '', twilioNumber: '', supportMail: '' };
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }

  submit(form: NgForm) {
    if (!this.isUpdate) {
      this.companyService
        .createCompany({
          name: form.value.name,
          supportMail: form.value.supportMail,
          twilioNumber: `xxxxx`,
          forwardMail: form.value.forwardMail,
        })
        .subscribe(
          (data) => {
            console.log(data);
            this.modalService.dismissAll();
            this.sharedService.companyCreated.next("company created");
          },
          (err) => {
            this.isError = true;
            this.errorMessage = err.error.status;
            this.handleError.handleError(err)

          }
        );
    } else {
      console.log(form.value);
      this.companyService
        .updateCompany({
          name: form.value.name,
          ActiveStatus: form.value.ActiveStatus,
          supportMail: form.value.supportMail,
          id: this.companyInfo["id"],
          forwardMail: this.companyInfo["forwardMail"],
        })
        .subscribe(
          (data) => {
            this.modalService.dismissAll();
          },
          (err) => {
            this.isError = true;
            this.errorMessage = err.error.status;
            this.handleError.handleError(err)

          }
        );
    }
  }

  resetForm() {
    this.companyInfo = {
      name: "",
      twilioNumber: "",
      supportMail: "",
      activeStatus: null,
      id: null,
    };
    this.isError = false;
    this.errorMessage = "";
    this.groups = [];
    this.group = [];
  }

  onCompanySelect(id: any, content) {
    if (this.userData.companyId === 0) {
      this.isUpdate = true;

      this.companyService.getCompanyById(id).subscribe((data) => {
        this.agentService.getMailGroups(data["id"]).subscribe((datas) => {
          this.groups = datas.map((datac) => ({
            Name: datac["Name"],
            id: datac["id"],
          }));
          let selectedGroup = this.groups.find(
            (datap) => datap["id"] === data["IncomingMailGroup"]
          );
          if (selectedGroup) {
            this.group = [selectedGroup["Name"]];
          }
        }, (error) => {
          this.handleError.handleError(error)
        });
        this.companyInfo = {
          name: data["Name"],
          supportMail: data["SupportMail"],
          twilioNumber: data["TwilioNumber"],
          ActiveStatus: data["ActiveStatus"],
          id: id,
          forwardMail: data["ForwardMail"] || "",
        };
        this.open(content);
      }, (error) => {
        this.handleError.handleError(error)
      });
    }
  }

  getComapnies(id?: any) {
    let payload = {
      mail: this.userData.mail,
      selectedCard: this.selectedCard,
      start: this.startDate,
      end: this.endDate
    }
    this.isLoading = true
    this.noRecords = false
    this.totalCompanies = []
    this.companyService.getAllCompaniesNew(payload).subscribe((res: any) => {
      if (res.status == 200 && res.companies.length > 0) {
        this.totalCompanies = res.companies;
        this.projectsCount = res.counts;

        for (let i = 0; i < this.totalCompanies.length; i++) {
          for (let j = 0; j < this.projectsCount.length; j++) {
            if (this.totalCompanies[i].companyId == this.projectsCount[j].companyId) {
              this.totalCompanies[i].projectCount = this.projectsCount[j].count;
            }
          }
        }
      }
      else {
        this.noRecords = true
      }
      this.isLoading = false
    });
  }

  selectCountry(country) {
    console.log(country);
    this.selectedCountryCode = country.code;
  }

  fetchCountries() {
    this.sharedService.getCountries().subscribe((data) => {
      this.countries = data;
    }, (error) => {
      this.handleError.handleError(error)
    });
  }

  sortOrder(feildName) {
    this.orderFeild = feildName;
    this.order = !this.order;
    if (this.order) {
      this.orderString = "asc";
    } else {
      this.orderString = "desc";
    }
  }

  forwardMail(event) {
    let name = event.replace(/[^A-Z0-9]+/gi, "");
    this.companyInfo["forwardMail"] = name + environment.forwardMail;
  }
  getTopBarCards() {
    var postData = {
      start: this.startDate,
      end: this.endDate,
      AgentMail: this.userData.mail,
    };
    this.CreateCompanyService
      .getTopBarCards(postData)
      .toPromise()
      .then((resp: any) => {
        this.topBarCounts = resp.TopBarCount;
      })
      .catch((err) => {
        this.handleError.handleError(err)

      });
  }
  updateDateFilter(event: any) {
    if (event.target.value != "custom") {
      this.endDate = this.momentService.dashboardDateFormat(
        new Date(new Date(Date.now())).getTime()
      );
    }
    if (event.target.value == "custom") {
      //show custom date filter
      this.showDate = true;
    } else if (event.target.value == "all") {
      this.startDate = this.momentService.dashboardDateFormat(
        new Date(new Date(Date.now() - 864e5 * 365)).getTime()
      );

      this.showDate = false;
    } else if (event.target.value == "24hrs") {
      this.startDate = this.momentService.dashboardDateFormat(
        new Date(new Date(Date.now() - 864e5)).getTime()
      );

      this.showDate = false;
    } else if (event.target.value == "72hrs") {
      this.startDate = this.momentService.dashboardDateFormat(
        new Date(new Date(Date.now() - 864e5 * 3)).getTime()
      );

      this.showDate = false;
    } else if (event.target.value == "7days") {
      this.startDate = this.momentService.dashboardDateFormat(
        new Date(new Date(Date.now() - 864e5 * 7)).getTime()
      );

      this.showDate = false;
    } else if (event.target.value == "14days") {
      this.startDate = this.momentService.dashboardDateFormat(
        new Date(new Date(Date.now() - 864e5 * 14)).getTime()
      );

      this.showDate = false;
    } else if (event.target.value == "30days") {
      this.startDate = this.momentService.dashboardDateFormat(
        new Date(new Date(Date.now() - 864e5 * 30)).getTime()
      );

      this.showDate = false;
    }
    // this.getCounts(this.userData, "all", this.startDate, this.endDate, "all");
    this.getTopBarCards();
    this.getComapnies();


    // this.getData(this.userData, "all", this.startDate, this.endDate);

  }
  hideDate() {
    // this.filterValuev
    if (this.filterValue == "custom" && this.showDate == false) {
      this.showDate = true;
    }
  }
  getStartDate(type: string, event: MatDatepickerInputEvent<Date>) {
    this.startDate = event.value;
    this.startDate = this.momentService.dashboardDateFormat(
      new Date(this.startDate).getTime
    );
  }
  getEndDate(type: string, event: MatDatepickerInputEvent<Date>) {
    this.endDate = event.value;
    this.endDate = this.momentService.dashboardDateFormat(
      new Date(this.endDate).getTime
    );
  }
  filterData() {
    this.onClick(null);
    this.startDate = this.momentService.dashboardDateFormat(
      new Date(this.date2.value).getTime()
    );
    this.endDate = this.momentService.dashboardDateFormat(
      new Date(this.date1.value).getTime()
    );

    if (this.endDate < this.startDate) {
      // window.alert("Choose the right date format!");
      this.uhleadsservice.showWarning(
        "End date cannot be less than Start date!",
        "Wrong Date!"
      );
      return;
    } else {
      this.getTopBarCards()
      this.showDate = false;
    }
  }
  onClick(check) {
    if (check == 1) {
      this.tab = "tab1";
    } else if (check == 2) {
      this.tab = "tab2";
    } else if (check == 3) {
      this.tab = "tab3";
    } else if (check == 4) {
      this.tab = "tab4";
    } else if (check == 5) {
      this.tab = "tab6";
    } else {
      this.tab = null;
    }
  }
  getCompanyOnCardSelect(Data) {
    this.selectedCard = Data
    this.getComapnies()
  }
  getDocumentRecord(item) {
    if (
      item["companyPanDocument"] && item["companyTanDocument"] && item["companyRegistDocuments"] &&
      item["companyPanDocument"] != 'error' && item["companyTanDocument"] != 'error' && item["companyRegistDocuments"] != 'error'
    ) {
      return 'Uploaded'
    }
    else return 'Pending'
  }
  deleteCompany(item) {
    this.CreateCompanyService.deleteCompany(item).subscribe((Data: any) => {
      if (Data.status == true) {
        this.uhleadsservice.showSuccess(Data.message, "Success");
        this.getTopBarCards();
        this.getComapnies(this.userData.companyId);
      }
      else {
        this.uhleadsservice.showWarning(Data.message, "Warning");

      }
    })
  }
}
