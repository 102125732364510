import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HttpClientService } from 'src/app/httpclient.service';
import { environment } from 'src/environments/environment';
import { Exceldata2 } from '../marketing/smsmarketing/models/ExecelData.model2';

@Injectable({
  providedIn: 'root'
})
export class TransfersService {
  baseApiUrl2: any = environment.baseApiUrl2;
  baseApiUrl = environment.SMSapiurl;
  startCall = new BehaviorSubject<any>(null);
  startCallv2 = new BehaviorSubject<any>(null);
  stopCall = new BehaviorSubject<any>(null);
  getCallSid = new BehaviorSubject<any>(null);
  startTimer = new BehaviorSubject<any>(null);
  pageNumber = new BehaviorSubject<any>(null);
  totalPages = new BehaviorSubject<any>(null);
  callStatus = new BehaviorSubject<any>(null);

  constructor(private http: HttpClientService) { }


  fetchStudents(obj) {
    return this.http.post(`${this.baseApiUrl2}university/fetchStudents`, obj)
  }
  updateCallStatus(data) {
    return this.http.post(
      `${this.baseApiUrl2}university/updateCallStatus`,
      data
    );
  }
  updateCallSid(data) {
    return this.http.post(`${this.baseApiUrl2}university/updateCallSid`, data);
  }
  getLeadById(id) {
    return this.http.get(`${this.baseApiUrl2}university/getTrasnferLeadById/${id}`)
  }
  updateValidationStatus(data) {
    return this.http.post(
      `${this.baseApiUrl2}university/documentValidationTransfer`,
      data
    );
  }
  updateComments(data) {
    return this.http.post(
      `${this.baseApiUrl2}university/addComments`,
      data
    )
  }
  assignTransferLead(data) {
    return this.http.post(
      `${this.baseApiUrl2}university/AssignTransferLead`,
      data
    );
  }
  updateCallComment(data) {
    return this.http.post(
      `${this.baseApiUrl2}university/updateCallComments`,
      data
    )
  }
  getTopBarCount(data) {
    return this.http.post(
      `${this.baseApiUrl2}university/getTopBarAdmin`,
      data
    )
  }
  transferSearch(data) {
    return this.http.post(
      `${this.baseApiUrl2}university/transferSearch`,
      data
    )
  }
  updateLead(data) {
    return this.http.post(`${this.baseApiUrl2}university/updateLead`, data);
  }
  postCallData(data: {
    data: Exceldata2[];
    createdBy: string;
    ProjectId: number;
    addType: string;
  }) {
    return this.http.post(`${this.baseApiUrl2}university/addCallData`, data);
  }
  createLeadCRM(data) {
    return this.http.post(`${this.baseApiUrl2}university/createLeadCRM`, data)
  }

  // AGENT SERVICES
  fetchStudentsRecruiter(obj) {
    return this.http.post(`${this.baseApiUrl2}university/recruiter/fetchAgents`, obj)
  }
  updateCallStatusRecruiter(data) {
    return this.http.post(
      `${this.baseApiUrl2}university/recruiter/updateCallStatus`,
      data
    );
  }
  updateCallSidRecruiter(data) {
    return this.http.post(`${this.baseApiUrl2}university/recruiter/updateCallSid`, data);
  }
  getLeadByIdRecruiter(id) {
    return this.http.get(`${this.baseApiUrl2}university/recruiter/getTrasnferLeadById/${id}`)
  }
  updateValidationStatusRecruiter(data) {
    return this.http.post(
      `${this.baseApiUrl2}university/recruiter/documentValidationTransfer`,
      data
    );
  }
  updateCommentsRecruiter(data) {
    return this.http.post(
      `${this.baseApiUrl2}university/recruiter/addComments`,
      data
    )
  }
  assignTransferLeadRecruiter(data) {
    return this.http.post(
      `${this.baseApiUrl2}university/recruiter/AssignTransferLead`,
      data
    );
  }
  updateCallCommentRecruiter(data) {
    return this.http.post(
      `${this.baseApiUrl2}university/recruiter/updateCallComments`,
      data
    )
  }
  getTopBarCountRecruiter(data) {
    return this.http.post(
      `${this.baseApiUrl2}university/recruiter/getTopBarAdmin`,
      data
    )
  }
  transferSearchRecruiter(data) {
    return this.http.post(
      `${this.baseApiUrl2}university/recruiter/transferSearch`,
      data
    )
  }
  updateLeadRecruiter(data) {
    return this.http.post(`${this.baseApiUrl2}university/recruiter/updateLead`, data);
  }
  postCallDataRecruiter(data: {
    data: Exceldata2[];
    createdBy: string;
    ProjectId: number;
    addType: string;
  }) {
    return this.http.post(`${this.baseApiUrl2}university/recruiter/addCallData`, data);
  }
  createLeadCRMRecruiter(data) {
    return this.http.post(`${this.baseApiUrl2}university/recruiter/createLeadCRM`, data)
  }
  uploadDocument(data, docId) {
    return this.http.post(`${this.baseApiUrl2}university/UploadDocument`, data);
  }
}
