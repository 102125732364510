import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'loginstatus'
})
export class LoginstatusPipe implements PipeTransform {

  transform(values: any, agents: any[]): any {
    if (agents.find(value => value['value'] === values)) {
      let agent = agents.find(value => value['value'] === values);
      return agent['Status'] === null ? 'Offline' : agent['Status'];
    } else {
      return 'Offline'
    }
  }

}
