import { Injectable } from "@angular/core";
import { HttpClientService } from "src/app/httpclient.service";
import { environment } from "src/environments/environment";
import { ToastrService } from "ngx-toastr";
import { ReferralData } from "../marketing/smsmarketing/models/ReferralData.model";
import { BehaviorSubject } from "rxjs";
import { FormBuilder, RequiredValidator, Validators } from "@angular/forms";

@Injectable({
  providedIn: "root",
})
export class LeadsDashboardService {
  SMSapiurl = environment.SMSapiurl;
  baseApiUrl = environment.baseApiUrl;
  baseApiUrl2 = environment.baseApiUrl2;
  baseApiUrl3 = environment.newDashboardAPI;
  cardsList = new BehaviorSubject<any>(null);
  columnList = new BehaviorSubject<any>(null);
  config = new BehaviorSubject<any>(null);
  projectId = new BehaviorSubject<any>(null);
  refresh = new BehaviorSubject<any>(null);
  closeDialog = new BehaviorSubject<any>(null);
  lead = new BehaviorSubject<any>(null);
  callLead = new BehaviorSubject<any>(null);
  startCall = new BehaviorSubject<any>(null);
  stopCall = new BehaviorSubject<any>(null);
  startTimer = new BehaviorSubject<any>(null);
  isEdit = new BehaviorSubject<any>(null);
  cardValue = new BehaviorSubject<any>(null);
  dateChange = new BehaviorSubject<any>(null);
  uploadFileInfo = new BehaviorSubject<any>(null);
  getCallSid = new BehaviorSubject<any>(null);
  updateLeadDetails = new BehaviorSubject<any>(null);




  constructor(
    private http: HttpClientService,
    private toastr: ToastrService,
    private readonly formBuilder: FormBuilder
  ) { }



  getConfig(data) {
    return this.http.post(`${this.baseApiUrl3}ProjectManagement/getConfig`, data);
  }

  updateCards(data) {
    return this.http.post(`${this.baseApiUrl3}ProjectManagement/updateCards`, data)
  }

  updateColumn(data) {
    return this.http.post(`${this.baseApiUrl3}ProjectManagement/updateColumns`, data)
  }

  getLeads(data) {
    return this.http.post(`${this.baseApiUrl3}newLeadsDashboard/getLeads`, data)
  }

  getLeadInfo(data) {
    return this.http.post(`${this.baseApiUrl3}newLeadsDashboard/getLeadInfo`, data)
  }

  updateCallStatus(data) {
    return this.http.post(`${this.baseApiUrl3}newLeadsDashboard/updateCallStatus`, data)
  }

  updateCallSid(data) {
    return this.http.post(`${this.baseApiUrl3}newLeadsDashboard/updateCallSid`, data)
  }
  updateCallDetails(data) {
    return this.http.post(`${this.baseApiUrl3}newLeadsDashboard/updateCallDetails`, data)
  }

  updateLead(data) {
    return this.http.post(`${this.baseApiUrl3}newLeadsDashboard/updateLead`, data)
  }

  searchLead(data) {
    return this.http.post(`${this.baseApiUrl3}newLeadsDashboard/searchLead`, data)
  }

  getTopBarCounts(data) {
    return this.http.post(`${this.baseApiUrl3}newLeadsDashboard/topBarCounts`, data)
  }

  getLeads2(data) {
    return this.http.post(`${this.baseApiUrl3}newLeadsDashboard/getLeads2`, data)
  }

  inviteUSers(data) {
    return this.http.post(`${this.baseApiUrl3}userManagement/agentsInvitingForSentMail`, data)
  }

  addLead(data) {
    return this.http.post(`${this.baseApiUrl3}newLeadsDashboard/addLead`, data)
  }

  getCompanyInfo(data) {
    return this.http.post(`${this.baseApiUrl3}newLeadsDashboard/getCompanyInfo`, data)
  }

  getProjectInfo(data) {
    return this.http.post(`${this.baseApiUrl3}newLeadsDashboard/getProjectInfo`, data)
  }

  uploadLeads(data) {
    return this.http.post(`${this.baseApiUrl3}newLeadsDashboard/uploadLeads`, data)
  }

  getProjects(data) {
    return this.http.post(`${this.baseApiUrl3}newLeadsDashboard/getProjects`, data)
  }

  getAgents(data) {
    return this.http.post(`${this.baseApiUrl3}newLeadsDashboard/agentsList`, data)
  }

  assignLeadsToAgent(data) {
    return this.http.post(`${this.baseApiUrl3}newLeadsDashboard/assignLeadsToAgent`, data)
  }

  uploadCompanyDocument(data) {
    return this.http.post(`${this.baseApiUrl3}companyManagment/updatedCompanyfiles`, data)
  }
  uploadDocumentV2(data, docId) {
    return this.http.post(`${this.baseApiUrl}companyApiList/UploadDocumentv3`, data);
  }
  postDocumentComment(data) {
    return this.http.post(
      `${this.baseApiUrl3}newLeadsDashboard/addComments`,
      data
    );
  }
  checkUserRole(data) {
    return this.http.post(
      `${this.baseApiUrl}checkUserRole`,
      data
    );
  }
  updateCallComments(data) {
    return this.http.post(
      `${this.baseApiUrl3}newLeadsDashboard/updateCallComments`,
      data
    );
  }

  videoCallMailSms(data) {
    return this.http.post(`${this.baseApiUrl3}/newLeadsDashboard/videoCallMailSms`, data);
  }
}
