import { Injectable } from '@angular/core';
import { io } from 'socket.io-client';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SocketService {
  status = new Subject<any>();
  chatStatus = new Subject<any>();
  recruiterLeadAdd = new Subject<any>();
  socket: any;

  constructor() { }
  connect() {
    // this.socket = io(environment.socketUrl, { transports: ['websocket'] });
    this.socket = io(environment.socketUrl2, { transports: ['websocket'] });
    this.socket.on('connect', (data) => {
    })
    this.socket.on('statusUpdated', data => {
      this.status.next('data emitted')
    })
    this.socket.on('chatStatus', data => {
      this.chatStatus.next(data);
    })
    this.socket.on('recruiterleadadd', data => {
      this.recruiterLeadAdd.next(data);
    })
  }
  disConnect() {
    this.socket.disconnect();
  }
}
