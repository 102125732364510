<!-- Overview - Referree Details-->
<mat-sidenav id="sideNav" #sideNav mode="side" class="pr" opened="false" position="end" class="pf w45 z-top">
  <div class="modal-header d-flex pf w45" style="right: 0; top: 0; z-index: 9999">
    <div class="d-flex">
      <div class="close hand" (click)="sideNav.toggle()">
        <span aria-hidden="true">&times;</span>
      </div>
      <div class="modal-title mr-auto">Overview</div>
    </div>
  </div>
  <mat-tab-group class="mt-5 sideDataViewer">
    <mat-tab label="Referre details">
      <div class="mx-2 my-2">
        <table class="table table-borderless table-md caller-table my-1">
          <tr>
            <td>Name</td>
            <td>
              {{ sideViewerData.FirstName + " " + sideViewerData.LastName }}
            </td>
          </tr>
          <tr>
            <td>Email</td>
            <td>{{ sideViewerData.Email }}</td>
          </tr>
          <tr>
            <td>Mobile</td>
            <td>
              {{
              sideViewerData.CountryCode + " " + sideViewerData.PhoneNumber
              }}
            </td>
          </tr>
          <tr>
            <td>Created Date</td>
            <td>{{ sideViewerData.createdAt | date: "medium" }}</td>
          </tr>
          <tr>
            <td>University</td>
            <td>{{ sideViewerData.University }}</td>
          </tr>
          <tr>
            <td>Referral Count</td>
            <td *ngIf="sideViewerData.ReferralCount > 0" data-toggle="modal" data-target="#refcount"
              (click)="getReferredList(sideViewerData.id)" style="cursor: pointer;color: blue;">{{
              sideViewerData.ReferralCount }}</td>
            <td *ngIf="sideViewerData.ReferralCount == 0">{{ sideViewerData.ReferralCount }}</td>
          </tr>
          <tr>
            <td>Referral Bonus</td>
            <td>${{sideViewerData.ReferralBonus!='-' ? sideViewerData.ReferralBonus:'0' }}</td>
          </tr>
        </table>
      </div>
    </mat-tab>
    <mat-tab label="Call Details">
      <div class="alert alert-info mb-0">
        {{ sideViewerData.CallDetails.length }} Calls
      </div>
      <div class="mx-2 my-2" *ngFor="let i of sideViewerData.CallDetails">
        <table class="table table-borderless table-md caller-table my-1">
          <tr>
            <td>Called by</td>
            <td>{{ i.AgentName }}</td>
          </tr>
          <tr>
            <td>Date & Time</td>
            <td>{{ i.DateTime | date: "medium" }}</td>
          </tr>
          <tr>
            <td>Status</td>
            <td>{{ i.CallStatus | titlecase }}</td>
          </tr>
          <tr>
            <td>Call Recording</td>
            <td>
              <audio *ngIf="i.RecordingURL != '-'" style="height: 2.5rem; width: 15srem" [src]="i.RecordingURL" controls
                controlsList="nodownload" (play)="audioPlay($event)"></audio>
            </td>
          </tr>
          <tr>
            <td>Comments</td>
            <td>{{ i.Comments }}</td>
          </tr>
        </table>
        <hr class="hr-style" />
      </div>
    </mat-tab>
    <mat-tab label="Communication" style="position:relative">
      <div class="mx-2 my-2">
      </div>
      <div xxstyle="position:absolute; left:15px;right:15px;bottom:0; height:100px;">
        <div class="mb-3 pb-1 ct pr" style="background:#f5f5fa;border-radius: 10px;
          margin: 0 20px 0 10px;">
          <textarea class="form-control ct p-2 mb-1" style="background: #f5f5f5" id="textMessage" rows="2"
            placeholder="Write your message here..."></textarea>
          <button type="button" class="btn btn-link btn-sm mr-2 mb-1 float-right" style="padding: 0 5px"
            (click)="sendSms(sideViewerData, 'textMessage')">
            <img src="../../../assets/img/send-message.svg" class="img12">
          </button>
          <div style="clear: both"></div>
        </div>
      </div>
      <div class="p-3">
        <!--Comments 2 section-->
        <div class="header-md">
          Total Messages
          <span style="font-size: 12px">{{
            "(" + (smsList?.length || "0") + ")"
            }}</span>
        </div>
        <div class="comments-block" style="background: #fff">
          <div class="dyn-height">
            <div class="d-flex mb-0 mt-1" *ngFor="let j of smsList">
              <div class="d-flex pt-1" style="width: 100%">
                <div style="width:100%">

                  <div class="admin-comment"
                    style="border:1px solid #f5e7e7;border-radius:10px; border-bottom-right-radius: 0;"
                    *ngIf="j.Direction == 'inbound'">
                    <div class="d-flex">
                      <div>
                        <div class="pic mr-1 float-left text-center">
                          <img src="../../../assets/img/student.svg" style="width: 100%;
                          height: 100%;
                          object-fit: cover;
                          border-radius: 100px;" />

                        </div>
                      </div>
                      <div class="pl-2 pr-2 d-flex flex-column flex-grow-1">
                        <div class="d-flex">
                          <span class="header-12">Student</span>

                          <div class="sub-date float-right ml-auto" style="white-space: nowrap">
                            {{ j.DateTime | date: "medium" }}
                          </div>
                        </div>
                        <div class="sub-text flex-grow-1">{{ j.Text }}</div>
                      </div>
                    </div>
                  </div>

                  <div class="agent-comment"
                    style="border-radius:10px;border-bottom-left-radius: 0;border:1px solid #def3f7"
                    *ngIf="j.Direction == 'outbound'">
                    <div class="d-flex">
                      <div>
                        <div class="pic mr-1 float-left text-center">
                          <img style="width: 100%;
                          height: 100%;
                          object-fit: cover;
                          border-radius: 100px;" src="../../../assets/img/admin-comments.svg" />

                        </div>
                      </div>

                      <div class="pl-2 pr-2 d-flex flex-column">
                        <div class="d-flex">
                          <span class="header-12">Agent</span>
                          <!-- <div class="sub-header-sm mr-3">{{ i.AgentName }}</div> -->
                          <div class="sub-date float-right ml-auto" style="white-space: nowrap">
                            {{ j.DateTime | date: "medium" }}
                          </div>
                        </div>
                        <div class="sub-text" style="width:315px;line-height: 125%;">{{ j.Text }}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


    </mat-tab>
    <mat-tab label="Activity">
      <div class="mx-2 my-2">
        <div class="alert alert-info mb-0 d-flex">
          <div class="" style="line-height: 200%">
            {{ sideViewerData.ReferralActivities.length }} Actions taken
          </div>
          <div class="ml-auto" style="width: 180px">
            <input class="searchActivity" type="text" style="font-size: 13px" class="form-control search-input"
              placeholder="Search" [(ngModel)]="activityfilterString" (input)="
                onInputActivity(sideViewerData?.ReferralActivities?.length)
              " />
          </div>
        </div>
        <div *ngFor="
            let i of sideViewerData.ReferralActivities
              | slice: 0:minItems
              | searchFilterTable: activityfilterString
          ">
          <div class="myactivity d-flex align-items-start">
            <img class="img16 mr-2" src="./assets/img/tick-green.svg" />
            <p>
              {{ i.Description }}
              <span class="sm-text ml-2">{{
                i.DateTime | date: "medium"
                }}</span>
            </p>
          </div>
          <hr style="margin: 0; padding: 0" />
        </div>
        <div class="loadmore hand" *ngIf="minItems < sideViewerData.ReferralActivities.length"
          (click)="showmore(sideViewerData.ReferralActivities.length)">
          Show more..
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
</mat-sidenav>


<!-- Sidenav for Referral Details -->

<mat-sidenav id="sideNav2" #sideNav2 mode="side" class="pr" opened="false" position="end" class="pf w45 z-top">
  <div class="modal-header d-flex pf w45" style="right: 0; top: 0; z-index: 9999">
    <div class="d-flex">
      <div class="close hand" (click)="sideNav2.toggle()"><span aria-hidden="true">&times;</span></div>
      <div class="modal-title mr-auto">Overview</div>
    </div>
  </div>
  <mat-tab-group class="mt-5 sideDataViewer">
    <mat-tab label="Referral details">
      <div class="mx-2 my-2">
        <table class="table table-borderless table-md caller-table my-1">
          <tr>
            <td>Name</td>
            <td>{{sideViewerData.FirstName+" "+sideViewerData.LastName}}</td>
          </tr>
          <tr>
            <td>Email</td>
            <td>{{sideViewerData.Email}}</td>
          </tr>
          <tr>
            <td>Mobile</td>
            <td>{{sideViewerData.CountryCode+" "+sideViewerData.PhoneNumber}}</td>
          </tr>
          <tr>
            <td>Created Date</td>
            <td>{{sideViewerData.createdAt | date: 'medium'}}</td>
          </tr>
          <tr>
            <td>University</td>
            <td>{{sideViewerData.University}}</td>
          </tr>

        </table>
      </div>
    </mat-tab>
    <mat-tab label="Call Details">
      <div class="alert alert-info mb-0">
        {{sideViewerData.CallDetails.length}} Calls
      </div>
      <div class="mx-2 my-2" *ngFor="let i of sideViewerData.CallDetails">
        <table class="table table-borderless table-md caller-table my-1">
          <tr>
            <td>Called by</td>
            <td>{{i.AgentName}}</td>
          </tr>
          <tr>
            <td>Date & Time</td>
            <td>{{i.DateTime | date: 'medium'}} </td>
          </tr>
          <tr>
            <td>Status</td>
            <td> {{i.CallStatus | titlecase}} </td>
          </tr>
          <tr>
            <td>Call Recording</td>
            <td><audio *ngIf="i.RecordingURL != '-'" style="height: 2.5rem; width: 15srem" [src]="i.RecordingURL"
                controls controlsList="nodownload" (play)="audioPlay($event)"></audio>
            </td>
          </tr>
          <tr>
            <td>Comments</td>
            <td> {{i.Comments}} </td>
          </tr>
        </table>
        <hr class="hr-style" />
      </div>
    </mat-tab>
    <mat-tab label="Activity">
      <div class="mx-2 my-2">
        <div class="alert alert-info mb-0 d-flex">
          <div class="" style="line-height:200%">
            {{ sideViewerData.ReferralActivities.length }} Actions taken
          </div>
          <div class="ml-auto" style="width:180px;">
            <input class="searchActivity" type="text" style="font-size:13px;" class="form-control search-input"
              placeholder="Search" [(ngModel)]="activityfilterString"
              (input)="onInputActivity(sideViewerData?.ReferralActivities?.length)" />
          </div>
        </div>
        <div
          *ngFor="let i of sideViewerData.ReferralActivities | slice: 0:minItems| searchFilterTable: activityfilterString">
          <div class="myactivity d-flex align-items-start">
            <img class="img16 mr-2" src="./assets/img/tick-green.svg" />
            <p>
              {{ i.Description }}
              <span class="sm-text ml-2">{{
                i.DateTime | date: "medium"
                }}</span>
            </p>
          </div>
          <hr style="margin: 0; padding: 0" />
        </div>
        <div class="loadmore hand" *ngIf="minItems < sideViewerData.ReferralActivities.length"
          (click)="showmore(sideViewerData.ReferralActivities.length)">
          Show more..
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
</mat-sidenav>

<!--Add refarral 1-->
<div class="modal fade modal-md" id="addrefarral1" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Add</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form>
          <div class="form-row">
            <div class="form-group col-md-6">
              <label for="inputEmail4">First Name</label>
              <input type="text" class="form-control" id="" placeholder="" />
            </div>
            <div class="form-group col-md-6">
              <label for="inputEmail4">Last Name</label>
              <input type="text" class="form-control" id="" placeholder="" />
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-6">
              <label for="inputEmail4">Email</label>
              <input type="text" class="form-control" id="" placeholder="" />
            </div>
            <div class="form-group col-md-6">
              <label for="inputEmail4">Phone</label>
              <input type="text" class="form-control" id="" placeholder="" />
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-6">
              <label for="inputEmail4">University</label>
              <select id="inputState" class="form-control">
                <option disabled value="">Choose One</option>
                <option *ngFor="let i of universityList" value="i.university">
                  {{ i.university }}
                </option>
                <option value="other">Other</option>
              </select>
            </div>
            <!-- <div class="form-group col-md-6">
              <label for="inputEmail4">Create Date</label>
              <input type="text" class="form-control" id="" placeholder="" disabled>
            </div> -->
          </div>
          <div class="d-flex">
            <div class="ml-auto">
              <button type="submit" class="btn btn-primary btn-sm mr-2">
                Add
              </button>
              <button type="button" class="btn btn-light btn-sm">Cancel</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
<!-- Add referral Ends-->

<!-- Add Lead -->

<!-- Edit Lead -->
<ng-template #addrecord let-modal>
  <div class="modal-header" style="width: 600px">
    <h4 class="modal-title" id="modal-basic-title">Add New Lead</h4>
    <button type="button" id="closeAddModal" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" style="max-height: 900px; width: 600px; background: #fff">
    <form class="pt-2" [formGroup]="ReferreeService.addLead" (ngSubmit)="addSingleLead()">
      <div class="form-row">
        <div class="form-group col-md-4">
          <label>First Name</label>
          <input class="form-control" name="FirstName" formControlName="FirstName" [(ngModel)]="addLead.FirstName" />
        </div>

        <div class="form-group col-md-4">
          <label>Last Name</label>
          <input class="form-control" name="LastName" formControlName="LastName" [(ngModel)]="addLead.LastName" />
        </div>
      </div>

      <div class="form-row">
        <div class="form-group col-md-4">
          <label>Email</label>
          <input type="text" name="Email" class="form-control" formControlName="Email" [(ngModel)]="addLead.Email" />
        </div>

        <div class="form-group col-md-4">
          <label>Phone Number</label>
          <div class="form-row">
            <div class="col-4">
              <input class="form-control" name="CountryCode" formControlName="CountryCode"
                [(ngModel)]="addLead.CountryCode" />
            </div>
            <div class="col-8">
              <input class="form-control" name="PhoneNumber" formControlName="PhoneNumber"
                [(ngModel)]="addLead.PhoneNumber" />
            </div>
          </div>
        </div>
      </div>

      <div class="form-row">
        <div class="form-group col-md-4">
          <label for="">University </label>
          <select id="inputState" class="form-control" name="University" formControlName="University"
            [(ngModel)]="addLead.University">
            <option disabled [ngValue]="">Choose One</option>
            <option *ngFor="let i of universityList" [ngValue]="i.university">
              {{ i.university }}
            </option>
            <option [ngValue]="otherUniversity">Other</option>
          </select>
        </div>
      </div>

      <div class="d-flex justify-content-end">
        <div class="mt-3" style="margin-right: 5px;">
          <button class="btn btn-primary btn-sm" type="submit">Update</button>
        </div>
        <div class="mt-3">
          <button type="button" class="btn btn-light btn-sm" (click)="modal.dismiss('Cross click')">Cancel</button>
        </div>
      </div>
    </form>
  </div>
</ng-template>

<!-- Add Lead Ends -->

<!-- Ref count starts-->
<div class="modal fade modal-md" id="refcount" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content" style="width: 847px;margin-left: -131px;">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Referral Count</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <!--<div class="header-md">Total 10 members added as referral</div>-->
        <input type="text" class="form-control mb-2" placeholder="Search by name" [(ngModel)]="refereeSearch"
          (input)="onRefereeSearch(referredList?.length)" />
        <div class="dat-table" style="height: 250px; overflow: auto">
          <table class="table table-md">
            <tr>
              <th style="width: 21%;">Full Name</th>
              <th>University Name</th>
              <th style="width: 21%;">Enrollment Term</th>
              <th style="width: 21%;">Referral Amount</th>
              <th>Status</th>
            </tr>
            <tr *ngFor="let i of referredList | searchFilterTable: refereeSearch">
              <td class="vm">
                <mat-sidenav-container style="display:inline !important" tooltipClass="my-custom-class">
                  <mat-sidenav-content style="display:inline !important">
                    <div>{{ i.FIRST_NAME + " " + i.LAST_NAME }}</div>
                  </mat-sidenav-content>
                </mat-sidenav-container>
              </td>
              <td class="vm" nowrap style="width: 100px">
                {{i.university||'--'}}
              </td>
              <td class="vm" nowrap style="width: 100px">
                {{i.enrollTerms ||'--'}} {{i.enrollYear ||'--'}}
              </td>
              <td class="vm" nowrap style="width: 100px">
                ${{i.ReferralCommission ||'0'}}
                <!-- ${{i.ReferralCommission ? i.ReferralCommission:'0' }} -->
              </td>
              <td class="vm" nowrap style="width: 100px">
                <div *ngIf="i.ReferralStatus == 'false'" class="status-pending">
                  Pending
                </div>
                <div *ngIf="i.ReferralStatus == 'true'" class="status-completed">
                  Completed
                </div>
                <div *ngIf="i.ReferralStatus != 'true' && i.ReferralStatus!='false'" class="status-completed">
                  {{i.ReferralStatus}}
                </div>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Ref count ends-->

<!-- Edit Lead -->
<ng-template #editrecord1 let-modal>
  <div class="modal-header" style="width: 600px">
    <h4 class="modal-title" id="modal-basic-title">Update Lead Details</h4>
    <button type="button" id="closeButton3" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" style="max-height: 900px; width: 600px; background: #fff">
    <form class="pt-2" [formGroup]="ReferreeService.editLead" (ngSubmit)="updateLead()">
      <div class="form-row">
        <div class="form-group col-md-4">
          <label>First Name</label>
          <input class="form-control" name="FirstName" formControlName="FirstName" [(ngModel)]="leadtoEdit.FirstName" />
        </div>

        <div class="form-group col-md-4">
          <label>Last Name</label>
          <input class="form-control" name="LastName" formControlName="LastName" [(ngModel)]="leadtoEdit.LastName" />
        </div>
      </div>

      <div class="form-row">
        <div class="form-group col-md-4">
          <label>Email</label>
          <input type="text" name="Email" class="form-control" formControlName="Email" [(ngModel)]="leadtoEdit.Email" />
        </div>

        <div class="form-group col-md-4">
          <label>Phone Number</label>
          <div class="form-row">
            <div class="col-4">
              <input class="form-control" name="CountryCode" formControlName="CountryCode"
                [(ngModel)]="leadtoEdit.CountryCode" />
            </div>
            <div class="col-8">
              <input class="form-control" name="PhoneNumber" formControlName="PhoneNumber"
                [(ngModel)]="leadtoEdit.PhoneNumber" />
            </div>
          </div>
        </div>
      </div>

      <!-- <div class="form-row">
        <div class="form-group col-md-4">
          <label for="">University </label>
          <select id="inputState" class="form-control" name="University" formControlName="University"
            [(ngModel)]="leadtoEdit.University">
            <option disabled [ngValue]="">Choose One</option>
            <option *ngFor="let i of universityList" [ngValue]="i.university">
              {{ i.university }}
            </option>
            <option [ngValue]="otherUniversity">Other</option>
          </select>
        </div>
      </div> -->

      <div class="form-row">
        <!-- Radio button  -->

        <div class="form-group col-md-12">
          <label for="">Feedback</label>
          <div class="mt-2">
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" name="CallStatus" id="inlineRadio1" value="pending"
                formControlName="CallStatus" [(ngModel)]="leadtoEdit.CallStatus"
                (change)="onStatusChange($event.target.value)" />
              <label class="form-check-label" for="inlineRadio1">Pending</label>
            </div>

            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" name="CallStatus" id="inlineRadio2" value="notinterested"
                formControlName="CallStatus" [(ngModel)]="leadtoEdit.CallStatus"
                (change)="onStatusChange($event.target.value)" />
              <label class="form-check-label" for="inlineRadio2">Not Interested</label>
            </div>

            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" name="CallStatus" id="inlineRadio2" value="informed"
                formControlName="CallStatus" [(ngModel)]="leadtoEdit.CallStatus"
                (change)="onStatusChange($event.target.value)" />
              <label class="form-check-label" for="inlineRadio2">Informed</label>
            </div>

            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" name="CallStatus" id="inlineRadio2" value="callfailed"
                formControlName="CallStatus" [(ngModel)]="leadtoEdit.CallStatus"
                (change)="onStatusChange($event.target.value)" />
              <label class="form-check-label" for="inlineRadio2">Wrong Number</label>
            </div>

            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" name="CallStatus" id="inlineRadio2" value="callback"
                formControlName="CallStatus" [(ngModel)]="leadtoEdit.CallStatus"
                (change)="onStatusChange($event.target.value)" />
              <label class="form-check-label" for="inlineRadio2">Call Back</label>
            </div>

            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" name="CallStatus" id="inlineRadio1" value="notanswered"
                formControlName="CallStatus" [(ngModel)]="leadtoEdit.CallStatus"
                (change)="onStatusChange($event.target.value)" />
              <label class="form-check-label" for="inlineRadio1">Not Answered</label>
            </div>

            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" name="CallStatus" id="inlineRadio2" value="paid"
                formControlName="CallStatus" [(ngModel)]="leadtoEdit.CallStatus"
                (change)="onStatusChange($event.target.value)" />
              <label class="form-check-label" for="inlineRadio2">Paid</label>
            </div>
          </div>
        </div>
        <div class="form-group col-md-6" *ngIf="leadtoEdit.CallStatus == 'paid'">
          <div>
            <input type="date" class="form-control" id="" placeholder="Select Date" [(ngModel)]="paidDate"
              [max]="todayDate" />
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-end">
        <div class="mt-3">
          <button class="btn btn-primary btn-sm" type="submit">Update</button>
        </div>
      </div>
    </form>
  </div>
</ng-template>

<div class="p-3">
  <div class="pl-2 pr-2 d-flex mb-4">
    <div class="db-sm-header mr-auto" style="line-height: 330%">Activity</div>
    <div class="db-sm-header mb-1 ml-auto" (click)="hideDate()">
      <button class="form-group" style="padding: 0">
        <select class="form-control cdd" id="" style="padding: 0; background: #fff" [(ngModel)]="filterValue"
          (change)="updateDateFilter($event)">
          <option value="all">All</option>
          <option value="24hrs">24 hrs</option>
          <option value="72hrs">72 hrs</option>
          <option value="7days">07 days</option>
          <option value="14days">14 days</option>
          <option value="30days">30 days</option>
          <option value="custom">Custom</option>
        </select>
      </button>
    </div>

    <div *ngIf="showDate" class="datePicker">
      <div style="background: #fff; height: 76px; border: 1px solid #ccc" class="custom-date">
        <mat-form-field class="mr-3">
          <input class="label" matInput [matDatepicker]="picker1" placeholder="Start Date" [formControl]="date2"
            (dateInput)="getStartDate('input', $event)" />
          <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
          <mat-datepicker #picker1 style="width: 80px"></mat-datepicker>
        </mat-form-field>

        <mat-form-field class="mr-3">
          <input class="label" matInput [matDatepicker]="picker2" placeholder="End Date" [formControl]="date1"
            (dateInput)="getEndDate('input', $event)" />
          <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
          <mat-datepicker #picker2 style="width: 80px"></mat-datepicker>
        </mat-form-field>
        <button class="btn btn-secondary btn-sm" (click)="filterData()" type="button">
          Submit
        </button>
      </div>
    </div>
  </div>

  <carousel [cellsToScroll]="2" style="padding-left: 50px; z-index: unset" class="fixheight">
    <div class="carousel-cell">
      <div class="mr-3">
        <div class="db-block w-175" (click)="getLeadsByCards('total'); onClick(1)"
          [ngClass]="{ 'db-block-active': tab === 'tab1' }">
          <div class="p-3">
            <div class="d-flex">
              <div class="img-circle d-flex">
                <img src="../../../../assets/img/total_applications.svg" style="width: 20px" />
              </div>
              <div class="f25 text-right ml-auto mt-3">
                {{ topBarCountReferee?.total || 0 }}
              </div>
            </div>
            <div class="box-sub-text1 mt-2">Total Referees</div>
          </div>
        </div>
      </div>
    </div>
    <!-- ----------------------------------- -->
    <div class="carousel-cell">
      <div class="mr-3">
        <div class="db-block w-175" (click)="getLeadsByCards('androidSignup'); onClick(8)"
          [ngClass]="{ 'db-block-active': tab === 'tab8' }">
          <div class="p-3">
            <div class="d-flex">
              <div class="img-circle d-flex">
                <img src="../../../../assets/img/total_applications.svg" style="width: 20px" />
              </div>
              <div class="f25 text-right ml-auto mt-3">
                {{ topBarCountReferee?.androidSignup || 0 }}
              </div>
            </div>
            <div class="box-sub-text1 mt-2">Android Signup</div>
          </div>
        </div>
      </div>
    </div>
    <div class="carousel-cell">
      <div class="mr-3">
        <div class="db-block w-175" (click)="getLeadsByCards('iosSignup'); onClick(9)"
          [ngClass]="{ 'db-block-active': tab === 'tab9' }">
          <div class="p-3">
            <div class="d-flex">
              <div class="img-circle d-flex">
                <img src="../../../../assets/img/total_applications.svg" style="width: 20px" />
              </div>
              <div class="f25 text-right ml-auto mt-3">
                {{ topBarCountReferee?.iosSignup || 0 }}
              </div>
            </div>
            <div class="box-sub-text1 mt-2">Ios Signup</div>
          </div>
        </div>
      </div>
    </div>
    <div class="carousel-cell">
      <div class="mr-3">
        <div class="db-block w-175" (click)="getLeadsByCards('webSignup'); onClick(10)"
          [ngClass]="{ 'db-block-active': tab === 'tab10' }">
          <div class="p-3">
            <div class="d-flex">
              <div class="img-circle d-flex">
                <img src="../../../../assets/img/total_applications.svg" style="width: 20px" />
              </div>
              <div class="f25 text-right ml-auto mt-3">
                {{ topBarCountReferee?.webSignup || 0 }}
              </div>
            </div>
            <div class="box-sub-text1 mt-2">Web Signup</div>
          </div>
        </div>
      </div>
    </div>
    <!-- ----------------------------------- -->
    <div class="carousel-cell">
      <div class="mr-3">
        <div class="db-block w-175" (click)="getLeadsByCards('referral'); onClick(2)"
          [ngClass]="{ 'db-block-active': tab === 'tab2' }">
          <div class="p-3">
            <div class="d-flex">
              <div class="img-circle d-flex">
                <img src="../../../../assets/img/total_applications.svg" style="width: 20px" />
              </div>
              <div class="f25 text-right ml-auto mt-3">
                {{ topBarCountReferee?.referral || 0 }}
              </div>
            </div>
            <div class="box-sub-text1 mt-2">Referral Count</div>
          </div>
        </div>
      </div>
    </div>
    <div class="carousel-cell">
      <div class="mr-3">
        <div class="db-block w-175" (click)="getLeadsByCards('signedUp'); onClick(3)"
          [ngClass]="{ 'db-block-active': tab === 'tab3' }">
          <div class="p-3">
            <div class="d-flex">
              <div class="img-circle d-flex">
                <img src="../../../../assets/img/total_applications.svg" style="width: 20px" />
              </div>
              <div class="f25 text-right ml-auto mt-3">
                {{ topBarCountReferee?.signedUp || 0 }}
              </div>
            </div>
            <div class="box-sub-text1 mt-2">Signed Up</div>
          </div>
        </div>
      </div>
    </div>
    <div class="carousel-cell">
      <div class="mr-3">
        <div class="db-block w-175" (click)="getLeadsByCards('notSignedUp'); onClick(4)"
          [ngClass]="{ 'db-block-active': tab === 'tab4' }">
          <div class="p-3">
            <div class="d-flex">
              <div class="img-circle d-flex">
                <img src="../../../../assets/img/total_applications.svg" style="width: 20px" />
              </div>
              <div class="f25 text-right ml-auto mt-3">
                {{ topBarCountReferee?.notSignedUp || 0 }}
              </div>
            </div>
            <div class="box-sub-text1 mt-2">Not Signed Up</div>
          </div>
        </div>
      </div>
    </div>
    <div class="carousel-cell">
      <div class="mr-3">
        <div class="db-block w-175" (click)="getLeadsByCards('assigned'); onClick(5)"
          [ngClass]="{ 'db-block-active': tab === 'tab5' }">
          <div class="p-3">
            <div class="d-flex">
              <div class="img-circle d-flex">
                <img src="../../../../assets/img/total_applications.svg" style="width: 20px" />
              </div>
              <div class="f25 text-right ml-auto mt-3">
                {{ topBarCountReferee?.assigned || 0 }}
              </div>
            </div>
            <div class="box-sub-text1 mt-2">Assigned</div>
          </div>
        </div>
      </div>
    </div>
    <div class="carousel-cell">
      <div class="mr-3">
        <div class="db-block w-175" (click)="getLeadsByCards('notAssigned'); onClick(6)"
          [ngClass]="{ 'db-block-active': tab === 'tab6' }">
          <div class="p-3">
            <div class="d-flex">
              <div class="img-circle d-flex">
                <img src="../../../../assets/img/total_applications.svg" style="width: 20px" />
              </div>
              <div class="f25 text-right ml-auto mt-3">
                {{ topBarCountReferee.unassigned || 0 }}
              </div>
            </div>
            <div class="box-sub-text1 mt-2">Unassigned</div>
          </div>
        </div>
      </div>
    </div>

    <div class="carousel-cell">
      <div class="mr-3">
        <div class="db-block w-175" (click)="getLeadsByCards('assignedtoMe'); onClick(7)"
          [ngClass]="{ 'db-block-active': tab === 'tab7' }">
          <div class="p-3">
            <div class="d-flex">
              <div class="img-circle d-flex">
                <img src="../../../../assets/img/total_applications.svg" style="width: 20px" />
              </div>
              <div class="f25 text-right ml-auto mt-3">
                {{ topBarCountReferee?.assignedtoMe || 0 }}
              </div>
            </div>
            <div class="box-sub-text1 mt-2">AssignedtoMe</div>
          </div>
        </div>
      </div>
    </div>
    <div class="carousel-cell"></div>
  </carousel>

  <div style="clear: both"></div>
</div>
<div class="pl-4 pr-4 pb-4">
  <div class="d-flex align-items-center">
    <div class="header-12 mr-auto">List of Referre</div>

    <div class="ml-auto content-containers" style="margin-left: 30px">
      <input type="file" (change)="convertFile($event)" style="display: none" #uploadFile accept=".xlsx" />
      <div class="d-flex justify-content-end">
        <div>
          <div class="mr-3">
            <div *ngIf="filePath" class="form-control" style="
                height: 16px;
                padding: 0px;
                background: none;
                font-size: 13px;
                color: #999;
                border: 0;
              ">
              {{ filePath }}
            </div>
            <div class="upload-msg-success" *ngIf="uploaded" style="color: green; font-size: 10px">
              {{ insertCount + " " + "Contacts inserted" }}
            </div>
            <div class="upload-msg-error" *ngIf="error">
              {{ errorMessage }}
            </div>
          </div>

          <div style="clear: both"></div>
        </div>

        <div class="ml-auto">
          <button *ngIf="admin" class="btn btn-secondary btn-sm ml-2" style="color: #fff" ngbTooltip="Lead Assign"
            placement="top" tooltipClass="my-custom-class" data-toggle="modal" data-target="#leadassign" type="button">
            <img src="../../../../assets/img/assign-w.svg" style="width: 12px" />
          </button>
          <button class="btn btn-secondary btn-sm ml-2" ngbTooltip="Download Sample" placement="top"
            tooltipClass="my-custom-class" type="button">
            <a href="{{ downloadLink }}">
              <img src="../../../../assets/img/download-w.svg" class="img13" />
            </a>
          </button>
          <button class="btn btn-secondary btn-sm ml-2" ngbTooltip="Upload File" placement="top"
            (click)="redirectToUpload()" tooltipClass="my-custom-class" type="button">
            <img src="../../../../assets/img/upload-w.svg" class="img13" />
          </button>
          <button class="btn btn-secondary btn-sm ml-2" ngbTooltip="Add New" placement="top"
            (click)="openAddRecord(addrecord)" tooltipClass="my-custom-class" type="button">
            <img src="../../../../assets/img/add-w.svg" class="img11" />
          </button>
          <!-- <button class="btn btn-secondary btn-sm ml-2" ngbTooltip="Start Call" placement="top"
            (click)="startCallListener()" tooltipClass="my-custom-class" data-toggle="modal"
            data-target="#callingscreen2" type="button">
            <img src="../../../../assets/img/start-call-w.svg" style="width: 13px" />
          </button>
          <button class="btn btn-secondary btn-sm ml-2" ngbTooltip="Stop Call" placement="top"
            (click)="stopCallListener()" tooltipClass="my-custom-class" type="button">
            <img src="../../../../assets/img/stop-call-w.svg" style="width: 13px" />
          </button> -->
          <div></div>
        </div>
      </div>
    </div>
  </div>

  <div class="table-responsive">
    <div class="data-table" style="background: #fff">
      <table class="table table-md mt-1 mb-2 ctable">
        <thead>
          <tr>
            <td colspan="5">
              <div class="indived-search">
                <input type="text" placeholder="Search" class="form-control search-all" [(ngModel)]="filterString"
                  (input)="onInput($event)" #inputString />
              </div>
            </td>
            <td colspan="8" class="vm">
              <div class="pagenation-right d-flex justify-content-end align-items-center">
                <select class="form-select form-select-dark mx-2" style="padding:6px; font-size:12px"
                  [(ngModel)]="statusValue" (ngModelChange)="dropdownSelect($event)">
                  <option value="all">All</option>
                  <option value="pending">Pending</option>
                  <option value="informed">Informed</option>
                  <option value="called">Called</option>
                  <option value="notanswered">Not Answered</option>
                  <option value="callfailed">Call Failed</option>
                  <option value="callback">Call Back</option>
                  <option value="wrongnumber">Wrong Number</option>
                  <option value="notinterested">Not Interested</option>
                </select>
                <div style="color: #fff; font-size: 12px">
                  <ngb-pagination [(page)]="page" [pageSize]="pageSize" [collectionSize]="collectionSize"
                    (pageChange)="onPageChange($event)" [maxSize]="5" [boundaryLinks]="true"
                    class="pagenation-right"></ngb-pagination>
                </div>
              </div>
            </td>
          </tr>
        </thead>
        <tr style="background: #eaf5fd">
          <th style="width: 30px; white-space: nowrap;padding-left: 10px;" *ngIf="admin">
            <div style="width: 20px">
              <input type="checkbox" [checked]="selectedAll == true" class="selectAll" id="selectAll"
                (change)="selectAllforAssignment()" />
            </div>
          </th>
          <th nowrap>Actions</th>
          <th nowrap>Created
            <span><button class="sortButton" (click)="toggleSort('createdAt','asc')"><img class="img7 ml-1"
                  src="../../../../assets/img/sort-data.svg" /></button></span>
          </th>
          <th nowrap>Updated
            <span><button class="sortButton" (click)="toggleSort('updatedAt','asc')"><img class="img7 ml-1"
                  src="../../../../assets/img/sort-data.svg" /></button></span>
          </th>
          <th nowrap>Name
            <span><button class="sortButton" (click)="toggleSort('FirstName','asc')"><img class="img7 ml-1"
                  src="../../../../assets/img/sort-data.svg" /></button></span>
          </th>
          <th nowrap>Email
            <span><button class="sortButton" (click)="toggleSort('Email','asc')"><img class="img7 ml-1"
                  src="../../../../assets/img/sort-data.svg" /></button></span>
          </th>
          <th nowrap>Assigned
            <span><button class="sortButton" (click)="toggleSort('AssignedToName','asc')"><img class="img7 ml-1"
                  src="../../../../assets/img/sort-data.svg" /></button></span>
          </th>

          <th nowrap>Phone
            <span><button class="sortButton" (click)="toggleSort('PhoneNumber','asc')"><img class="img7 ml-1"
                  src="../../../../assets/img/sort-data.svg" /></button></span>
          </th>
          <th nowrap>Uni
            <span><button class="sortButton" (click)="toggleSort('University','asc')"><img class="img7 ml-1"
                  src="../../../../assets/img/sort-data.svg" /></button></span>
          </th>
          <th nowrap>Count
            <span><button class="sortButton" (click)="toggleSort('ReferralCount','asc')"><img class="img7 ml-1"
                  src="../../../../assets/img/sort-data.svg" /></button></span>
          </th>
          <th nowrap>Bonus
            <span><button class="sortButton" (click)="toggleSort('ReferralBonus','asc')"><img class="img7 ml-1"
                  src="../../../../assets/img/sort-data.svg" /></button></span>
          </th>
          <th nowrap>Call Status
            <span><button class="sortButton" (click)="toggleSort('CallStatus','asc')"><img class="img7 ml-1"
                  src="../../../../assets/img/sort-data.svg" /></button></span>
          </th>
          <th nowrap>Location
            <span><button class="sortButton" (click)="toggleSort('signUpLocation','asc')"><img class="img7 ml-1"
                  src="../../../../assets/img/sort-data.svg" /></button></span>
          </th>
        </tr>
        <tr *ngFor="
            let i of referralList; let id = index">
          <td class="vm" style="width: 30px; white-space: nowrap;padding-left: 10px;" *ngIf="admin">
            <div style="width: 20px;">
              <input type="checkbox" name="lead" [checked]="selectedAll == true" class="selectLead" id="selectLead"
                (change)="selectAgentforAssignment($event, i)" />
            </div>
          </td>
          <td class="vm text-center" nowrap>
            <mat-sidenav-container style="display: inline !important" ngbTooltip="Overview" placement="top"
              tooltipClass="my-custom-class">
              <mat-sidenav-content style="display: inline !important">
                <div style="display: inline !important; cursor: pointer" (click)="sideNav.toggle(); sideNavData(i)">
                  <img src="../../../../assets/img/view2.svg" class="img13" />
                </div>
              </mat-sidenav-content>
            </mat-sidenav-container>
            <div ngbTooltip="Call" *ngIf="getCheckCallVisibleTiming(i)" placement="top" tooltipClass="my-custom-class"
              class="ml-3" style="display: inline; cursor: pointer" data-toggle="modal"
              (click)="startCallListener2(i, id)" data-target="#callingscreen2">
              <img src="../../../../assets/img/call.svg" class="img13" />
            </div>
          </td>
          <td class="vm" nowrap>{{ i.createdAt | date: "dd MMM, yyyy" }}</td>
          <td class="vm" nowrap>{{ i.updatedAt | date: "dd MMM, yyyy" }}</td>
          <td class="vm" nowrap>
            <div class="d-flex align-items-center justify-content-between">
              <div class="mr-1 img20">
                <img src="../../../assets/img/Android.svg" class="w-100" *ngIf="i?.Platform == 'android' " />
                <img src="../../../assets/img/iOS.svg" class="w-100" *ngIf="i?.Platform == 'ios' " />
                <img src="../../../assets/img/web.svg" class="w-100"
                  *ngIf="!i?.Platform || i?.Platform != 'android' && i?.Platform != 'ios' " />
              </div>
              <div>
                <div>
                  <button class="btn btn-link btn-sm" (click)="openEditLead(editrecord1, i)">
                    <div class="tooltip_title">
                      <div class="txt-oflow2">
                        <span>
                          {{ i.FirstName + " " + i.LastName | titlecase }}
                        </span>
                      </div>
                      <span class="tooltiptext">{{ i.FirstName + " " + i.LastName | titlecase }}</span>
                    </div>

                  </button>
                </div>
              </div>
            </div>
          </td>
          <td class="vm" nowrap>
            <div class="tooltip_title">
              <div class="txt-oflow2">
                <span>
                  {{ i.Email }}
                </span>
              </div>
              <span class="tooltiptext">{{ i.Email }}</span>
            </div>
          </td>
          <td class="vm" nowrap>
            <div class="d-flex">
              <div class="pic mr-1 float-left text-center">
                <img *ngIf="i.AssignedToProfilePhoto != '-'" class="img20" src="{{ i.AssignedToProfilePhoto }}" />

                <p [ngStyle]="{ background: i.AssignedtoColor }" class="initials"
                  *ngIf="i.AssignedToProfilePhoto == '-' && i.AssignedTo != '-' ">
                  {{ i.AssignedToInitials }}
                </p>
              </div>
              <div class="tooltip_title">
                <div class="txt-oflow2">
                  <span>
                    {{ i.AssignedToName }}
                  </span>
                </div>
                <span class="tooltiptext">{{ i.AssignedToName }}</span>
              </div>
            </div>
          </td>

          <td class="vm" nowrap>{{ i.PhoneNumber }}</td>
          <td class="vm" nowrap>
            <div class="d-flex">
              <div class="pic mr-1 float-left text-center" *ngIf="i.University != '-'">
                <img class="img20" src="../../../../assets/img/university.svg" />
              </div>
              <div class="tooltip_title">
                <div class="txt-oflow2">
                  <span>
                    {{ i.University }}
                  </span>
                </div>
                <span class="tooltiptext">{{ i.University }}</span>
              </div>
            </div>
          </td>
          <td class="vm text-center" nowrap>
            <a *ngIf="i.ReferralCount > 0" class="btn-link" data-toggle="modal" data-target="#refcount"
              (click)="getReferredList(i.id)">{{ i.ReferralCount }}</a>
            <div *ngIf="i.ReferralCount == 0">{{
              i.ReferralCount }}</div>
          </td>
          <td class="vm text-left" nowrap>
            ${{ i.ReferralBonus!='-' ? i.ReferralBonus:'0' }}
          </td>
          <td class="vm text-center" nowrap>
            <span class="status-pending" *ngIf="i.CallStatus == 'pending'">Pending</span>
            <span class="status-not-ans" *ngIf="i.CallStatus == 'notinterested'">Not Interested</span>
            <span class="status-completed" *ngIf="i.CallStatus == 'completed'">Completed</span>
            <span class="status-completed" *ngIf="i.CallStatus == 'paid'">Paid</span>
            <span class="status-callback" *ngIf="i.CallStatus == 'callback'">Call Back</span>
            <span class="status-informed" *ngIf="i.CallStatus == 'informed'">Informed</span>
            <span class="status-informed" *ngIf="i.CallStatus == 'called'">Called</span>
            <span class="status-failed" *ngIf="i.CallStatus == 'callfailed'">Wrong Number</span>
            <span class="status-deferred" *ngIf="i.CallStatus == 'deferredintake'">Deferred Intake</span>
            <span class="status-pending" *ngIf="
                i.CallStatus == 'callnotanswered' ||
                i.CallStatus == 'notanswered'
              ">Not Answered</span>
          </td>
          <td class="vm" nowrap>
            <div class="tooltip_title">
              <div class="txt-oflow2">
                <span>
                  {{ i.signUpLocation }}
                </span>
              </div>
              <span class="tooltiptext">{{ i.signUpLocation }}</span>
            </div>
          </td>
        </tr>
        <tr class="h30"></tr>
        <tr *ngIf="leadsLoading == true">
          <td colspan="10">
            <div class="norecords">
              <img src="../../../../assets/img/waiting.gif" />

              <div>
                Loading Data<br />Please wait while we fetch the data for you
              </div>
            </div>
          </td>
        </tr>
        <tr *ngIf="noLeadsFound == true">
          <td colspan="10">
            <div class="norecords">
              <img src="../../../../assets/img/nodata.svg" />
              <div>
                No records found.<br />Please select a different date range.
              </div>
            </div>
          </td>
        </tr>
      </table>
    </div>
  </div>
</div>

<!--Popups-->
<!-- Referrals all pops-->
<!-- Lead Assign-->
<div class="modal fade" id="leadassign" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Assign to Agent</h5>
        <button type="button" id="closeAssignButton" #closeButton class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" style="height: none">
        <div class="px-2">
          <div class="py-2 header-lg">
            <b>{{ leadIds.length }}</b> Students are selected to assign.
          </div>
          <div class="d-flex mt-2 mb-3">
            <div class="form-group mr-2" style="margin-bottom: 0; width: 220px">
              <select class="form-control" id="exampleFormControlSelect1" [(ngModel)]="defaultAgent"
                (change)="agentSelected($event.target.value)">
                <option value="default" disabled selected>Select Agent</option>
                <option *ngFor="let agent of agentList" [value]="agent.Mailid">
                  {{ agent.FirstName + " " + agent.LastName }}
                </option>
              </select>
            </div>
            <button type="button" class="btn btn-secondary btn-sm" (click)="assignLEads()">
              Assign
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Lead Assign ends-->

<!-- Add new-->
<!-- Add new ends-->

<!-- Start Call-->
<div class="modal fade" id="callingscreen2" data-backdrop="static" data-keyboard="false" tabindex="-1"
  aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg custom-model-w">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="staticBackdropLabel">Calling Screen</h5>

        <button type="button" #closeButton2 class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" style="overflow-y: auto">
        <div class="row">
          <div class="call-module ml-3">
            <div class="ndialer">
              <!--.......code for no- incoming call screen.........  -->
              <div class="c-caller">Current Caller</div>
              <div class="dialer-middle-screen" style="text-align: center">
                <div class="custom-incomingcall-widget">
                  <h4 class="mt-3 text-ellipsis">
                    {{ marketingId.name }}
                  </h4>
                  <div class="mb-2 mt-1 lg-text" style="font-weight: 100"></div>
                  <div class="my-1 lg-text">
                    {{ marketingId.phoneNumber || agentCall }}
                  </div>
                  <div class="custom-user-img my-3 mt-5">
                    <img class="img80" src="./assets/img/user-w.svg" alt="" />
                  </div>
                  <h5 *ngIf="callStatus != 'inProgress'">{{ callStatus }}</h5>
                  <p *ngIf="callStatus == 'inProgress'" class="custom-call-timer" style="text-align: center">
                    <countup-timer [countUpTimerConfig]="testConfig"></countup-timer>
                  </p>
                  <p class="custom-call-timer" style="text-align: center"></p>

                  <div class="custom-incoming-btns my-3 mt-4" (click)="stopCurrentCall()">
                    <button type="button" class="btn-round m-r-10">
                      <img class="img-38" src="./assets/img/hangup.svg" alt="" />
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div class="mt-2 p-3 ndialer2 ng-star-inserted" *ngIf="marketingId.nextCaller">
              <div class="mt-4" style="float: right">
                <img class="img50" src="./assets/img/user-w.svg" alt="" />
              </div>
              <div class="sm-text" style="
                  text-transform: uppercase;
                  font-weight: 100;
                  background: #f75c2b;
                  width: 110px;
                  padding: 5px;
                  border-radius: 15px;
                  letter-spacing: 1px;
                  text-align: center;
                  color: #fff;
                ">
                NEXT CALLER
              </div>
              <div class="lg-text pt-2 pb-1">{{ nextCallerName }}</div>
              <div class="md-text pb-2">{{ nextCallerCity }}</div>
              <div class="md-text">{{ nextCallerNumber }}</div>
            </div>

            <div class="mt-2 d-flex justify-content-between">
              <div class="cbtn" type="button" placement="top" tooltipClass="my-custom-class"
                (click)="startCallListener()">
                <img class="img18" src="./assets/img/redial3.svg" alt="" />
              </div>
              <button class="cbtn" type="button" placement="right" [ngbPopover]="calendarContent">
                <img class="img18" src="./assets/img/conference.svg" alt="" />
              </button>
              <ng-template #calendarContent>
                <form (ngSubmit)="conferenceCall(form.value)" #form="ngForm">
                  <div class="xp-3" style="width: 185px">
                    <div class="mb-1">
                      <input id="phone_number" name="phone_number" type="text" class="form-control"
                        placeholder="Enter Phone Number" ngModel />
                    </div>
                    <ng-multiselect-dropdown [data]="layoutService.projectAgents"
                      [settings]="layoutService.dropdownSettings" name="manager"
                      [(ngModel)]="layoutService.projectSelectedAgent" style="width: 100%; padding: 0"
                      class="dropdown-primary"></ng-multiselect-dropdown>
                    <button class="btn btn-primary btn-block btn-md mt-2">
                      Connect
                    </button>
                  </div>
                </form>
              </ng-template>

              <div class="cbtn" type="button" ngbTooltip="Stop Calls" placement="top" tooltipClass="my-custom-class">
                <img class="img18" style="padding-top: 3px" src="./assets/img/stop-call-r.svg" alt="" />
              </div>

              <div class="cbtn" type="button" ngbTooltip="Start Calls" placement="top" tooltipClass="my-custom-class">
                <img class="img18" style="padding-top: 3px" src="./assets/img/start-calls.svg" alt="" />
              </div>
            </div>
          </div>
          <div class="call-module-update ml-4 mr-2">
            <div class="row">
              <div class="col-md-12">
                <div class="header-12 mt-2 mb-1">Referre details</div>
              </div>
            </div>
            <div class="row custom-row">
              <div class="col-md-4">
                <div class="form-group">
                  <label for="formGroupExampleInput">Name</label>
                  <div *ngIf="callData[0]">
                    {{ callData[0].FirstName + " " + callData[0].LastName }}
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label for="formGroupExampleInput">Email</label>
                  <div *ngIf="callData[0]">{{ callData[0].Email }}</div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label for="formGroupExampleInput">Mobile</label>
                  <div *ngIf="callData[0]">
                    {{
                    callData[0].CountryCode + " " + callData[0].PhoneNumber
                    }}
                  </div>
                </div>
              </div>
            </div>

            <div class="row custom-row">
              <div class="col-md-4">
                <div class="form-group">
                  <label for="formGroupExampleInput">Created Date</label>
                  <div *ngIf="callData[0]">
                    {{ callData[0].createdAt | date: "medium" }}
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label for="formGroupExampleInput">University</label>
                  <div class="d-flex">
                    <div class="pic mr-1 float-left text-center">
                      <img class="img20" src="../../../../assets/img/university.svg" />
                    </div>
                    <div *ngIf="callData[0]" class="txt-oflow2 lh180 float-left">
                      {{callData[0].University || ''}}
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label for="formGroupExampleInput">Referral Count</label>
                  <div *ngIf="callData[0]">{{callData[0].ReferralCount}}</div>
                </div>
              </div>
            </div>

            <div class="row custom-row">
              <div class="col-md-4">
                <div class="form-group">
                  <label for="formGroupExampleInput">Referral Bonus</label>
                  <div *ngIf="callData[0]">${{callData[0].ReferralBonus}}</div>
                </div>
              </div>
            </div>

            <hr class="hr-style mt-1 mb-1" />
            <div class="row custom-row2">
              <div class="col-md-12">
                <div class="header-12 mt-2 mb-1">Feedback</div>
              </div>

              <div class="col-md-12">
                <div class="form-check mr-3 d-inline header-sm">
                  <label class="form-check-label" for="check1">
                    <input type="radio" [(ngModel)]="newStatus" class="form-check-input" id="check1" name="option1"
                      value="notinterested" (change)="onStatusChange($event.target.value)" />Not Interested
                  </label>
                </div>
                <div class="form-check mr-3 d-inline">
                  <label class="form-check-label" for="check3">
                    <input type="radio" [(ngModel)]="newStatus" class="form-check-input" id="check3" name="option1"
                      value="informed" (change)="onStatusChange($event.target.value)" />Informed
                  </label>
                </div>
                <div class="form-check mr-3 d-inline">
                  <label class="form-check-label" for="check4">
                    <input type="radio" [(ngModel)]="newStatus" class="form-check-input" id="check4" name="option1"
                      value="callfailed" (change)="onStatusChange($event.target.value)" />Wrong Number
                  </label>
                </div>
                <div class="form-check mr-3 d-inline">
                  <label class="form-check-label" for="check5">
                    <input type="radio" [(ngModel)]="newStatus" class="form-check-input" id="check5" name="option1"
                      value="callback" (change)="onStatusChange($event.target.value)" />Call Back
                  </label>
                </div>
                <div class="form-check mr-3 d-inline">
                  <label class="form-check-label" for="check6">
                    <input type="radio" [(ngModel)]="newStatus" class="form-check-input" id="check6" name="option1"
                      value="notanswered" (change)="onStatusChange($event.target.value)" />Not Answered
                  </label>
                </div>
              </div>
            </div>
            <span *ngIf="!newStatus" class="error-msg pt-2 pb-2" style="color: red; font-size: 13px">
              Please select any option above!
            </span>

            <div class="row custom-row">
              <div class="col-md-12">
                <div class="form-group">
                  <div for="commentText" class="header-12 pt-3 pb-2">Comments</div>
                  <textarea class="form-control" id="commentText" rows="3" [(ngModel)]="commentText"></textarea>
                </div>
              </div>
            </div>

            <div class="d-flex justify-content-end">
              <div class="mt-3">
                <button class="btn btn-light btn-sm mr-2" type="button" (click)="resetCallingScreen()">
                  Reset
                </button>
                <button class="btn btn-primary btn-sm" type="button" (click)="updateComments()">
                  Update
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<div class="fixed-footer">Powered by <a href="https://avantao.com/#/" target="_blank">Avantao</a></div>