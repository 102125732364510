export const lineChart: any = [
  {
    value: 50,
    name: "USA",
  },
  {
    value: 80,
    name: "United Kingdom",
  },
  {
    value: 85,
    name: "France",
  },
  {
    value: 90,
    name: "Japan",
  },
  {
    value: 100,
    name: "China",
  },
];

export const lineChartSeries1 = [
  {
    name: "Tablets",
    series: [
      {
        name: "USA",
        value: 50,
      },
      {
        value: 80,
        name: "United Kingdom",
      },
      {
        value: 85,
        name: "France",
      },
      {
        value: 90,
        name: "Japan",
      },
      {
        value: 100,
        name: "China",
      },
    ],
  },
  {
    name: "Cell Phones",
    series: [
      {
        value: 10,
        name: "USA",
      },
      {
        value: 20,
        name: "United Kingdom",
      },
      {
        value: 30,
        name: "France",
      },
      {
        value: 40,
        name: "Japan",
      },
      {
        value: 10,
        name: "China",
      },
    ],
  },
  {
    name: "Computers",
    series: [
      {
        value: 2,
        name: "USA",
      },
      {
        value: 4,
        name: "United Kingdom",
      },
      {
        value: 20,
        name: "France",
      },
      {
        value: 30,
        name: "Japan",
      },
      {
        value: 35,
        name: "China",
      },
    ],
  },
];

export var lineChartSeries = [
  {
    name: "Completed",
    series: [

    ],
  },
];

export const barChart1: any = [
  {
    name: "USA",
    value: 50000,
  },
  {
    name: "United Kingdom",
    value: 30000,
  },
  {
    name: "France",
    value: 10000,
  },
  {
    name: "Japan",
    value: 0,
  },
  {
    name: "China",
    value: 500,
  },
];

export var barChart: any = [

];
