export class Group {
    constructor(
        private id: number,
        private Name: string,
        private Description: String,
        private CreatedDate: Date | string,
        private UpdatedDate: Date | String,
        private ActiveStatus?: boolean,
        private manager?: string,
        private createdAt?: Date,
        private updatedAt?: Date

    ) { }
}