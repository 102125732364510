<mat-list>
	<div mat-subheader style="margin-bottom: 1rem">Available Agents</div>
	<div>
		<div class="d-flex space-between" *ngFor="let folder of data['companyAgents']"
			style="margin-bottom: 1.5rem; cursor: pointer" (click)="addAgent(folder)">
			<span class="mx-2" style="position: relative">
				<img style="width: 25px; height: 25px; border-radius: 50%" src="{{
						folder['ProfilePhoto']
							? folder['ProfilePhoto']
							: 'assets/img/contact-profile-dummy.png'
					}}" alt="{{ folder.name }}" />
				<span class="{{ folder['value'] | loginstatus: data['totalAgents'] }}"></span>
			</span>
			<span>
				{{ folder.name }}
			</span>
		</div>
	</div>
</mat-list>