import { Injectable } from '@angular/core';

import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class MomentFormatService {

  constructor() { }

  dashboardDateFormat(date) {
    return moment.utc(date).format("YYYY-MM-DD HH:mm:ss+00")
  }

  dashboardDateFormatStartOf(date) {
    let d1 = moment.utc(date);
    let d2 = d1.startOf('day').format("YYYY-MM-DD HH:mm:ss+00");
    return d2;
  }

  dashboardDateFormatEndOf(date) {
    let d1 = moment.utc(date);
    let d2 = d1.endOf('day').format("YYYY-MM-DD HH:mm:ss+00");
    return d2;
  }

  reportsDateFormat(yyyy, mm, dd) {
    return moment.utc(new Date(yyyy, mm, dd)).format("YYYY-MM-DD HH:mm:ss+00")
  }

  formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [day, month, year].join("-");
  }
}
