import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TrasnfersRoutingModule } from './trasnfers-routing.module';
import { StudentsComponent } from './students/students.component';
import { AgentsComponent } from './agents/agents.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DemoMaterialModule } from '../uhleads/material.module';
import { NgxTimerModule } from 'ngx-timer';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { TransferSearchPipe } from '../../shared/transfer-search.pipe';
import { IvyCarouselModule } from 'angular-responsive-carousel';
import { PaginationCustomDirective } from './pagination-custom.directive';
import { PaginationCustomComponent } from './pagination-custom/pagination-custom.component';

@NgModule({
  declarations: [
    StudentsComponent,
    AgentsComponent,
    TransferSearchPipe,
    PaginationCustomDirective,
    PaginationCustomComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    TrasnfersRoutingModule,
    NgbModule,
    DemoMaterialModule,
    NgxTimerModule,
    ReactiveFormsModule,
    NgxDocViewerModule,
    NgxSkeletonLoaderModule,
    IvyCarouselModule
  ],
  exports: [TransferSearchPipe, PaginationCustomDirective]
})
export class TrasnfersModule { }
