import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AuthGuard } from 'src/app/core/guards/auth.guard';
import { UhleadsComponent } from '../uhleads/uhleads.component';


const routes: Routes = [
  { path: 'dashboard/dashboard-view', component: DashboardComponent, canActivate: [AuthGuard] },
  { path: 'dashboard/:phoneNumber', component: DashboardComponent, canActivate: [AuthGuard] },
  { path: '', component: UhleadsComponent, canActivate: [AuthGuard] }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DashboardRoutingModule { }
