<div class="text-left mt-2" style="padding: 0 3rem;">
    <div class="row">
        <div>
            <app-project-change (projectChange)="projectChange($event)"></app-project-change>
        </div>
        <div style="margin-left:5px;">
            <select [(ngModel)]="statusValue" class="select-type" (change)="dropdownSelect($event)">
                <option value="" disabled selected>
                    Select your option
                </option>
                <option value="autosms">Auto SMS</option>
                <option value="autocall">Auto Call</option>
            </select>
        </div>
    </div>
</div>
<div class="containers">
    <div class="content-containers mt-3" style="margin: 0 30px">


    </div>
    <div class="right-content-right-box-bottom-container">
        <div class="bottom-content-box-header-container">
            <div class="ticket-requested-list-container">
                <div class="requsted-list-heading-container">
                    <div class="table-scroll data-table">
                        <table class="table table-md">
                            <thead style="position: relative">
                                <tr>
                                    <td colspan="7" style="padding: 8px">
                                        <div class="table-search-content">
                                            <div class="indived-search">
                                                <input type="text" class="form-control search-all" [(ngModel)]="search"
                                                    placeholder="Search..." style="padding-left: 15px" />

                                            </div>
                                            <div class="pagenation-right d-flex">
                                                <!-- <select class="form-select mx-2 form-select-dark"
                                                    [(ngModel)]="statusValue" (change)="dropdownSelect()">
                                                    <option value="" disabled selected>
                                                        Select your option
                                                    </option>
                                                    <option value="Information">Information</option>
                                                    <option value="Feedback">Feedback</option>
                                                </select> -->
                                                <ngb-pagination [(page)]="page" [pageSize]="pageSize"
                                                    [collectionSize]="callData.length" [maxSize]="5"
                                                    [boundaryLinks]="true" class="pagenation-right"></ngb-pagination>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th>
                                        From

                                        <img class="img7" src="../../../../assets/img/sort-data.svg"
                                            style="cursor: pointer; margin-left: 10px"
                                            (click)="sortOrder('CallerId')" />
                                    </th>
                                    <th>
                                        To
                                        <img class="img7" src="../../../../assets/img/sort-data.svg"
                                            style="cursor: pointer; margin-left: 10px"
                                            (click)="sortOrder('RecipientNumber')" />
                                    </th>

                                    <th>
                                        Type
                                        <img class="img7" src="../../../../assets/img/sort-data.svg"
                                            style="cursor: pointer; margin-left: 10px" (click)="sortOrder('Type')" />
                                    </th>
                                    <th *ngIf="statusValue=='autosms'">
                                        SMS Sent

                                        <img class="img7" src="../../../../assets/img/sort-data.svg"
                                            style="cursor: pointer; margin-left: 10px" (click)="sortOrder('SMSSent')" />
                                    </th>
                                    <th *ngIf="statusValue=='autocall'">
                                        Call Dialed

                                        <img class="img7" src="../../../../assets/img/sort-data.svg"
                                            style="cursor: pointer; margin-left: 10px"
                                            (click)="sortOrder('CallDialed')" />
                                    </th>
                                    <th>
                                        Reminder

                                        <img class="img7" src="../../../../assets/img/sort-data.svg"
                                            style="cursor: pointer; margin-left: 10px" (click)="sortOrder('Module')" />
                                    </th>
                                    <th>
                                        Response

                                        <img class="img7" src="../../../../assets/img/sort-data.svg"
                                            style="cursor: pointer; margin-left: 10px" (click)="sortOrder('Module')" />
                                    </th>

                                    <!-- <th>
                                        FeedBack

                                        <img class="img7" src="../../../../assets/img/sort-data.svg"
                                            style="cursor: pointer; margin-left: 10px"
                                            (click)="sortOrder('Feedback')" />
                                    </th> -->
                                    <th *ngIf="statusValue=='autosms'">
                                        Scheduled Time

                                        <img class="img7" src="../../../../assets/img/sort-data.svg"
                                            style="cursor: pointer; margin-left: 10px"
                                            (click)="sortOrder('ScheduledSMSTimeUTC')" />
                                    </th>
                                    <th *ngIf="statusValue=='autocall'">
                                        Scheduled Time

                                        <img class="img7" src="../../../../assets/img/sort-data.svg"
                                            style="cursor: pointer; margin-left: 10px"
                                            (click)="sortOrder('ScheduledCallTimeUTC')" />
                                    </th>
                                </tr>

                                <tr [hidden]="isLoading" *ngFor="
                      let item of callData
                        | filter: search
                        | slice: (page - 1) * pageSize:page * pageSize
                        | sort: orderFeild:orderString;
                      let id = index
                    ">
                                    <td>{{ item["CallerId"] || "-" }}</td>

                                    <td class="id-col-mod">
                                        <span> {{ item["RecipientNumber"] }}</span>
                                    </td>
                                    <td>{{ item["Type"] || "-" }}</td>
                                    <td *ngIf="statusValue=='autosms'">
                                        {{
                                        item["SMSSent"] !== "No"
                                        ? (item["SMSSent"] | date: "short")
                                        : "No"
                                        }}
                                    </td>
                                    <td *ngIf="statusValue=='autocall'">
                                        {{
                                        item["CallDialed"] !== "No"
                                        ? (item["CallDialed"] | date: "short")
                                        : "No"
                                        }}
                                    </td>
                                    <td>{{ item["Reminder"] || "-" }}</td>
                                    <td>{{ item["Feedback"]=='1'?'Yes':item["Feedback"]=='2'?'No':'--'}}</td>
                                    <!-- <td>{{ item['RedialCounter'] || 0 }}</td> -->
                                    <!-- <td>
                                        --
                                    </td> -->
                                    <td *ngIf="statusValue=='autosms'">
                                        {{ item["ScheduledSMSTime"] | date: "medium" }}
                                    </td>
                                    <td *ngIf="statusValue=='autocall'">
                                        {{ item["ScheduledCallTimeUTC"] | date: "medium" }}
                                    </td>
                                </tr>
                                <tr *ngIf="isLoading ==true">
                                    <td colspan="8">
                                        <div class="norecords">
                                            <img src="../../../../assets/img/waiting.gif" />

                                            <div>Loading Data<br />Please wait while we fetch the data for
                                                you</div>
                                        </div>
                                    </td>
                                </tr>
                                <tr *ngIf="noRecords == true">
                                    <td colspan="8">
                                        <div class="norecords">
                                            <img src="../../../../assets/img/nodata.svg" />
                                            <div>No records found.<br />
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>