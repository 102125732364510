<div>
	<div class="modal-header">
		<h4 class="modal-title">Map View</h4>
		<button type="button" class="close" aria-label="Close" (click)="dismiss()">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body">
		<agm-map [latitude]="lat" [longitude]="lng">
			<agm-direction [origin]="origin" [markerOptions]="markerOptions" [renderOptions]="renderOptions"
				[destination]="destination">
			</agm-direction>
		</agm-map>
	</div>
</div>