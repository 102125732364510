<ng-template #modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      {{ modalConfig.modalTitle }}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="close()"
      *ngIf="modalConfig.hideCloseButton === undefined || !modalConfig.hideCloseButton"
      [disabled]="modalConfig.disableCloseButton !== undefined && modalConfig.disableCloseButton()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <ng-content></ng-content>
  </div>
</ng-template>