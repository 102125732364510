import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { ModalDismissReasons, NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { EmailEditorComponent } from 'angular-email-editor';
import { EmailsettingsComponent } from '../emailsettings/emailsettings.component';
import { LeadsDashboardService } from '../leads-dashboard/leads-dashboard.service';
import { MailserviceService } from '../mailservice.service';
import { UhLeadsService } from '../uhleads.service';
import { WorkflowserviceService } from './workflowservice.service';

@Component({
  selector: 'app-workflowrules',
  templateUrl: './workflowrules.component.html',
  styleUrls: ['./workflowrules.component.scss'],
})
export class WorkflowrulesComponent implements OnInit {
  public order: boolean = false;
  public orderFeild: string = "UpdatedDate";
  public orderString: boolean | "asc" | "desc" = "desc";
  public companyId: string = "";
  superAdmin: Boolean = false;
  userData: any;
  public page: any = 1;
  public pageSize: any = 6;
  search: string;
  isLoading: Boolean = false
  noRecords: Boolean = false
  isUpdate: Boolean = false
  totalRules: any = []
  totalProjects: any = []
  closeResult: string;
  ruleForm: FormGroup;
  inputData: any = {};
  comefrom: any;
  notificationtype = [
    { name: 'Email', value: 'email', selected: false },
    { name: 'SMS', value: 'sms', selected: false },
    { name: 'Whatsapp', value: 'whatsapp', selected: false },
    { name: 'Telegram', value: 'telegram', selected: false }
  ]
  activityfilterString: string = "";
  minItems = 5;
  templateModalToggle: any;
  ModalToggle: any;
  @ViewChild('mySelect') mySelect;

  @ViewChild(EmailEditorComponent, { static: true })
  private emailEditor!: EmailEditorComponent;
  emailTemplatePayload: any

  constructor(
    private workflowService: WorkflowserviceService,
    private LeadsDashboardService: LeadsDashboardService,
    private uhleadsservice: UhLeadsService,
    private modalService: NgbModal,
    public readonly templateModal: NgbModal,
    private fb: FormBuilder,
    private mailService: MailserviceService,

  ) {

  }

  ngOnInit() {
    this.userData = JSON.parse(localStorage.getItem("userData"));
    this.companyId = this.userData['NewCompanyId']
    this.getcompanyProjectDetails();
    this.formInIt()
    this.getAllRules();
    console.log(this.emailEditor)
  }
  formInIt() {
    this.ruleForm = this.fb.group({
      ruleName: ["", [Validators.required]],
      companyId: [this.companyId],
      notificationtype: [""],
      projectId: ["", [Validators.required]],
      ruleType: [""],
      description: ["", [Validators.required]],
      createdBy: [""],
      channel: [""],
      emailTemplateName: [""],
    })
  }
  getcompanyProjectDetails() {
    let payload = {
      companyId: this.companyId,
      mail: this.userData.mail
    }
    this.totalProjects = []
    this.LeadsDashboardService.getCompanyInfo(payload).subscribe(
      (res: any) => {
        if (res.projects[0].length > 0) {
          this.totalProjects = res.projects[0];
        }
      }
    );
  }

  getAllRules() {
    this.isLoading = true;
    this.noRecords = false
    this.totalRules = []
    this.workflowService.getAllRules().subscribe((Data: any) => {
      if (Data.status === true) {
        console.log(Data.message)
        this.totalRules = Data.data

      } else {
        this.noRecords = true;
        this.totalRules = []
        console.log(Data.message)
      }
      this.isLoading = false;

    })
  }

  sortOrder(felidName) {
    this.orderFeild = felidName;
    this.order = !this.order;
    if (this.order) {
      this.orderString = "asc";
    } else {
      this.orderString = "desc";
    }
  }

  open(content) {
    this.resetForm()
    this.ModalToggle = this.modalService.open(content, { size: "lg" })
    this.ModalToggle.result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }

  private getDismissReason(reason: any): string {

    // this.companyInfo = { name: '', twilioNumber: '', supportMail: '' };
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }

  get f() {
    return this.ruleForm.controls;
  }
  resetForm() {
    if (this.ruleForm) {
      this.ruleForm.reset()
    }
  }
  submit() {
    if (this.ruleForm.valid) {
      let payload = {
        companyId: this.companyId,
        projectId: this.ruleForm.value.projectId,
        ruleName: this.ruleForm.value.ruleName,
        ruleType: {
          channel: this.ruleForm.value.channel,
          notificationtype: this.ruleForm.value.notificationtype,
        },
        description: this.ruleForm.value.description,
        createdBy: this.userData.mail
      }
      console.log(payload)
      this.workflowService.createRule(payload).subscribe((Data: any) => {
        if (Data.status === true) {
          this.emailTemplatePayload.ruleid = Data.data
          this.workflowService.createTemplate(this.emailTemplatePayload).subscribe((Data: any) => {

          });
          this.uhleadsservice.showSuccess(Data.message, "Success");
          this.getcompanyProjectDetails()
          this.resetForm()
        }
        else {
          this.uhleadsservice.showWarning1(Data.message, "Warning");

        }
      })
    }
  }
  selectChange(Data) {

    // console.log("this.ruleForm.value.notificationtype",this.ruleForm.value.notificationtype)
    // console.log("Data.value",Data.value)
    // console.log("Data.value.length == this.notificationtype",Data.value.length == this.notificationtype.length)
  }
  selectedOption: any;
  selectSingleValue(Data, selected, content) {
    if (Data == 'email' && selected) {
      this.selectedOption = Data
      // this.mySelect.close()
      this.comefrom = "workflow"
      this.templateModalToggle = this.templateModal.open(content, { size: "xl" })
      this.templateModalToggle.result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
    }
  }

  // tosslePerOne(all) {
  //   if (this.allSelected.selected) {
  //     this.allSelected.deselect();
  //     return false;
  //   }
  //   if (
  //     this.ruleForm.controls.notificationtype.value.length ==
  //     this.notificationtype.length
  //   )
  //     this.allSelected.select();
  // }
  // toggleAllSelection() {
  //   if (this.allSelected.selected) {
  //     this.ruleForm.controls.notificationtype.patchValue([
  //       ...this.notificationtype.map((item) => item.value),
  //       0,
  //     ]);
  //   } else {
  //     this.ruleForm.controls.notificationtype.patchValue([]);
  //   }
  // }
  saveTemplate() {
    this.emailEditor.editor.exportHtml((data) => {
      console.log(data);
      this.mailService.htmlTemplate.next(data["html"].toString());
      console.log({
        Name: this.inputData.name,
        Creator: this.userData["workerFriendlyName"],
        Html: data["html"],
        Design: data["design"],
        FromEmail: this.inputData["fromEmail"],
        companyId: this.ruleForm.value.projectId,
      })
    });
  }

  sideNavData(data) {
    this.activityfilterString = "";

    this.minItems = 5;
    var x = document.getElementsByClassName(
      "sideDataViewer"
    ) as HTMLCollectionOf<HTMLElement>;
    //  x.style.display = none;
    for (let i = 0; i < x.length; i++) {
      x[i].style.display = "";
    }
    console.log("data", data)
  }

  showmore(itemsCount) {
    if (itemsCount < this.minItems) {
    } else if (this.minItems + 5 < itemsCount) {
      this.minItems = this.minItems + 5;
    } else if (this.minItems < itemsCount) {
      this.minItems = itemsCount;
    }
  }
  createTemplate(Data) {
    debugger
    this.emailTemplatePayload = JSON.parse(Data)
    this.emailTemplatePayload.projectId = this.ruleForm.value.projectId
    this.emailTemplatePayload.FromEmail = this.totalProjects.filter(x => x.projectId == this.ruleForm.value.projectId)[0]['outgoingMailService']
    console.log(this.emailTemplatePayload)
    this.templateModalToggle.close()

  }
}
