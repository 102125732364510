import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-aicalls',
  templateUrl: './aicalls.component.html',
  styleUrls: ['./aicalls.component.css']
})
export class AicallsComponent implements OnInit {

  todaydate = new Date();
  constructor() { }

  ngOnInit(): void {
  }

}
