import { PrivacypolicyComponent } from "./privacypolicy/privacypolicy.component";
import { NgModule } from "@angular/core";
import { Routes, RouterModule, PreloadAllModules } from "@angular/router";
import { LayoutComponent } from "./layout/layout.component";
import { MailResponseComponent } from "./mail-response/mail-response.component";
import { LoginComponent } from "./account/auth/login/login.component";
import { BookademoComponent } from "./bookademo/bookademo.component";
import { EventScheduleComponent } from "./pages/event-schedule/event-schedule.component";
import { ForgotComponent } from "./account/auth/forgot/forgot.component";

const routes: Routes = [
  {
    path: "videocalling",
    loadChildren: () =>
      import("./videocalling/videocalling.module").then(
        (m) => m.VideocallingModule
      ),
  },
  {
    path: "account",
    loadChildren: () =>
      import("./account/account.module").then((m) => m.AccountModule),
  },
  {
    path: "",
    component: LayoutComponent,
    loadChildren: () =>
      import("./pages/pages.module").then((m) => m.PagesModule),
  },
  { path: "home", redirectTo: "/login", pathMatch: "full" },
  { path: "privacy", component: PrivacypolicyComponent },
  { path: "feedbackMail/:refid/:response", component: MailResponseComponent },
  { path: "login", component: LoginComponent },
  { path: "forgot", component: ForgotComponent },
  {
    path: "signup",
    loadChildren: () =>
      import("./signupnew/signupnew.module").then(
        (m) => m.SignupnewModule
      ),
  },
  { path: "bookademo/:type", component: BookademoComponent },
  { path: "calendar/:agentName", component: EventScheduleComponent }, {
    path: "billing",
    loadChildren: () =>
      import("./billing/billing.module").then(
        (m) => m.BillingModule
      ),
  },
  {
    path: 'mail-settings',
    loadChildren: () =>
      import("./pages/sendgrid/sendgrid.module").then((m) => m.SendgridModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
