<!-- <p>company-management works!</p> -->
<div class="containers">
  <div class="d-flex mb-2">
    <div class="mr-auto">
      <a routerLink="/company" class="btn btn-link btn-sm pl-0">
        <img src="../../../assets/images/left-arrow.svg" class="img9"> &nbsp;
        Back to Dashboard</a>
    </div>
    <div class="ml-auto">
      <button class="btn btn-link btn-sm" ngbTooltip="Download Template" placement="top" style="float: right;"
        (click)="downloadTemplate()" tooltipClass="my-custom-class">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 22 22">
          <g id="Group_776" data-name="Group 776" transform="translate(-215.728 -61.66)">
            <path id="Path_888" class="psvg" data-name="Path 888"
              d="M234.853,86.66H221.1a1.375,1.375,0,0,1-1.375-1.375v-8.25A1.375,1.375,0,0,1,221.1,75.66h2.063v1.375H221.1v8.25h13.75v-8.25h-2.063V75.66h2.063a1.375,1.375,0,0,1,1.375,1.375v8.25A1.376,1.376,0,0,1,234.853,86.66Z"
              transform="translate(-1.25 -4.375)" fill="#b02022" />
            <rect id="Rectangle_902" class="psvg" data-name="Rectangle 902" width="4" height="1"
              transform="translate(224.728 75.66)" fill="#b02022" />
            <path id="Path_889" class="psvg" data-name="Path 889"
              d="M233.008,68.19l-2.468,2.461V63.66h-1.375v6.992L226.7,68.19l-.969.969,4.125,4.125,4.125-4.125Z"
              transform="translate(-3.125 -0.625)" fill="#b02022" />
            <rect id="_Transparent_Rectangle_" data-name=" Transparent Rectangle " width="22" height="22"
              transform="translate(215.728 61.66)" fill="none" />
          </g>
        </svg>
      </button>

      <button class="btn btn-link btn-sm">
        <!-- *ngIf="superAdmin" -->
        <a ngbTooltip="Create Project" placement="top" tooltipClass="my-custom-class"
          [routerLink]="['/createProject', companyId]">

          <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22">
            <g id="Group_764" data-name="Group 764" transform="translate(-286.561 -0.829)">
              <path id="Path_869" data-name="Path 869" class="psvg"
                d="M302.855,15.28V8.829h-1.843V15.28h-6.451v1.843h6.451v6.451h1.843V17.123h6.451V15.28Z"
                transform="translate(-4.373 -4.373)" />
              <rect id="Rectangle_901" data-name="Rectangle 901" width="22" height="22"
                transform="translate(286.561 0.829)" fill="none" />
            </g>
          </svg></a>
      </button>
    </div>
  </div>
  <div class="content-container">
    <div class="table-scroll data-table" style="background:#fff">
      <table class="table table-md">
        <thead>
          <tr>
            <td colspan="9" style="padding: 8px">
              <div class="table-search-content">
                <div class="indived-search" style="position: relative">
                  <input type="text" class="form-control search-all" [(ngModel)]="search" placeholder="Search..." />

                </div>
                <div class="pagenation-right">
                  <ngb-pagination [(page)]="page" [pageSize]="pageSize" [collectionSize]="totalCompanies.length"
                    class="pagenation-right" [maxSize]="6" [boundaryLinks]="true"></ngb-pagination>
                </div>
              </div>
            </td>
          </tr>

          <tr style="background: #eaf5fd; border-bottom: none">
            <!-- <th style="display: none">Company id</th> -->
            <th>
              <span>
                Project Name

                <img class="img7" src="../../../../assets/img/sort-data.svg" style="cursor: pointer"
                  (click)="sortOrder('Name')" />
              </span>
            </th>
            <th>
              <span>
                Created Date

                <img class="img7" src="../../../../assets/img/sort-data.svg" style="cursor: pointer"
                  (click)="sortOrder('Description')" />
              </span>
            </th>

            <th>
              <span>
                Twilio Number
                <img class="img7" src="../../../../assets/img/sort-data.svg" style="cursor: pointer"
                  (click)="sortOrder('TwilioNumber')" />
              </span>
            </th>
            <th>
              <span>
                Support Mail
                <img class="img7" src="../../../../assets/img/sort-data.svg" style="cursor: pointer"
                  (click)="sortOrder('SupportMail')" />
              </span>
            </th>
            <th>
              <span>
                Project Type
                <img class="img7" src="../../../../assets/img/sort-data.svg" style="cursor: pointer"
                  (click)="sortOrder('projectType')" />
              </span>
            </th>
            <th>
              <span>
                Status
                <img class="img7" src="../../../../assets/img/sort-data.svg" style="cursor: pointer"
                  (click)="sortOrder('ActiveStatus')" />
              </span>
            </th>
            <th>
              <span>
                Created Date
                <img class="img7" src="../../../../assets/img/sort-data.svg" style="cursor: pointer"
                  (click)="sortOrder('createdAt')" />
              </span>
            </th>
            <th>Create Role</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody style="background:#fff">
          <tr *ngFor="
              let item of totalCompanies
                | slice: (page - 1) * pageSize:page * pageSize
                | sort: orderFeild:orderString
                | filter: search;
              let id = index
            ">
            <td class="id-col-mod" style="
                vertical-align: middle;
                cursor: pointer;
                text-decoration: none;
                color: #163252;
                text-transform: capitalize;
              " (click)="onCompanySelect(item['id'], content)">
              {{ item["projectName"] | titlecase }}
            </td>
            <td style="vertical-align: middle">{{item["createdAt"] | date:"medium"}}</td>
            <td style="vertical-align: middle">+{{item["voipOutgoingNumberCode"]+' '+item["voipOutgoingNumber"] }}</td>
            <!-- <td>
							{{ item['ActiveStatus'] ? 'active' : 'inactive' }}
						</td> -->
            <td style="vertical-align: middle">{{ item["outgoingMailService"] || '--' }}</td>
            <td style="vertical-align: middle">{{
              item["projectType"]=='other'?item["projectTypeOther"]:item["projectType"] ||'--' }}</td>
            <td style="vertical-align: middle">
              <div [ngClass]="{ 'status-open': item['ActiveStatus'] }">
                {{ item["ActiveStatus"] ? "Active" : "In Active" }}
              </div>
            </td>
            <td style="vertical-align: middle">
              {{ item["createdAt"] | date: "dd/MM/yy, hh:mm a" }}
            </td>
            <td style="vertical-align: middle">
              <a [routerLink]="['/role', companyId, companyName, item['projectId']]"
                class="btn btn-primary btn-sm">Manage
                Roles</a>
            </td>
            <td>
              <button data-toggle="modal" data-target="#exampleModal3"
                (click)="inviteAgentModal(inviteLeadsModal, item.projectId)" class="btn btn-link btn-sm"
                style="color: #fff" ngbTooltip="Invite" placement="top" tooltipClass="my-custom-class">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 32 32">
                  <g id="Group_860" data-name="Group 860" transform="translate(-783.465 -58.66)">
                    <path id="Path_965" data-name="Path 965"
                      d="M795.465,62.66a5,5,0,1,1-5,5,5,5,0,0,1,5-5m0-2a7,7,0,1,0,7,7A7,7,0,0,0,795.465,60.66Z"
                      class="gsvg" />
                    <path id="Path_966" data-name="Path 966"
                      d="M805.465,88.66h-2v-5a5,5,0,0,0-5-5h-6a5,5,0,0,0-5,5v5h-2v-5a7,7,0,0,1,7-7h6a7,7,0,0,1,7,7Z"
                      class="gsvg" />
                    <path id="Path_967" data-name="Path 967" d="M808.465,74.84l-2.59-2.59-1.41,1.41,4,4,7-7-1.41-1.41Z"
                      class="gsvg" />
                    <rect id="_Transparent_Rectangle_" data-name=" Transparent Rectangle " width="32" height="32"
                      transform="translate(783.465 58.66)" fill="none" />
                  </g>
                </svg>
              </button>
              <div ngbTooltip="Menu" placement="top" tooltipClass="my-custom-class" class="mr-2"
                style="display: inline; cursor: pointer" [matMenuTriggerFor]="menu">
                <img src="../../../../assets/img/vdot-menu.svg" class="img13" style="height:14px;" />
              </div>
              <mat-menu #menu="matMenu">
                <button mat-menu-item>
                  <img src="../../../../assets/img/edit.svg" class="img13" />
                  <span><a [routerLink]="['/editProject', item['companyId'],item['projectId']]">Edit Project</a></span>
                </button>
                <button mat-menu-item [disabled]="!item['webhookURL']">
                  <a [className]="item['webhookURL']?'float-left cursor':'float-left'"
                    (click)="copyText(item['webhookURL'])"> <i class="far fa-clone"></i> WebHook URL</a>
                  <!-- <span><a>WebHook URL</a></span> -->
                </button>

              </mat-menu>
            </td>
            <!-- <td>{{ item['updatedAt'] | date: 'short' }}</td> -->
          </tr>
          <tr *ngIf="isLoading ==true">
            <td colspan="8">
              <div class="norecords">
                <img src="../../../../assets/img/waiting.gif" />

                <div>Loading Data<br />Please wait while we fetch the data for you</div>
              </div>
            </td>
          </tr>
          <tr *ngIf="noRecords == true">
            <td colspan="8">
              <div class="norecords">
                <img src="../../../../assets/img/nodata.svg" />
                <div>No records found.<br />Please select a different date range.</div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- <ngb-pagination class="pagination-right"></ngb-pagination> -->
    <!-- [(page)]="page"
        [pageSize]="pageSize"
        [collectionSize]="roles.length"
        class="pagenation-right" -->
    <div class="fixed-footer">Powered by <a href="https://avantao.com/#/" target="_blank">Avantao</a></div>

  </div>
</div>
<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      {{ isUpdate ? "Update" : "Create" }} Project
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" style="height: 465px; overflow: auto">
    <ngb-alert type="danger" *ngIf="isError" [dismissible]="false">{{
      errorMessage
      }}</ngb-alert>
    <mat-tab-group>
      <mat-tab label="Details">
        <form (ngSubmit)="submit(f)" #f="ngForm">
          <div class="row">
            <div class="col-md-6">
              <label for="name" class="form-label mt-2">Project Name</label>
              <input type="text" class="form-control" placeholder="Please enter name of Role" name="name"
                [(ngModel)]="companyInfo.name" required #userName="ngModel" (ngModelChange)="forwardMail($event)"
                max="10" [ngClass]="{
                'is-invalid': !userName.valid && userName.touched,
                'is-valid': userName.valid && userName.touched
              }" />
              <!-- <div
                  class="valid-feedback"
                  *ngIf="userName.valid && userName.touched"
                >
                  looks good!
                </div> -->
              <div class="invalid-feedback" *ngIf="!userName.valid && userName.touched">
                Please Enter Valid Name
              </div>
            </div>
            <div class="col-md-6">
              <label for="name" class="form-label mt-2">Project Type</label>
              <select class="form-control">
                <option>Education - Sample type</option>
                <option>Real Estate</option>
                <option> IT </option>
              </select>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <label for="name" class="form-label mt-2">Project Start Date</label>
              <input type="date" class="form-control" name="name" />
            </div>
            <div class="col-md-6">
              <label for="name" class="form-label mt-2">Project End
                date
              </label>
              <input type="date" class="form-control" />
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <label for="name" class="form-label mt-2">Project Description</label>
              <textarea class="form-control" style="height:auto" row="3" placeholder="About the Project">About the project
              </textarea>

              <!-- <input type="text" class="form-control" placeholder="Please enter Project Description" name="desc"
              [(ngModel)]="companyInfo.desc" required #desc="ngModel" max="100" [ngClass]="{
                'is-invalid': !desc.valid && desc.touched,
                'is-valid': desc.valid && desc.touched
              }" rows="3" >
              </input> -->
              <!-- Above input commented by jk -->


              <!-- (ngModelChange)="forwardMail($event)" -->
              <!-- <div
                  class="valid-feedback"
                  *ngIf="desc.valid && desc.touched"
                >
                  looks good!
                </div> -->
              <div class="invalid-feedback" *ngIf="!desc.valid && desc.touched">
                Please Enter Valid Description
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <label for="description" class="form-label">Support Mail</label>
              <input type="mail" class="form-control" placeholder="Please enter Description of Role" id="description"
                name="supportMail" [(ngModel)]="companyInfo.supportMail" required #desc="ngModel" email [ngClass]="{
                'is-invalid': !desc.valid && desc.touched,
                'is-valid': desc.valid && desc.touched
              }" />
              <!-- <div class="valid-feedback" *ngIf="desc.valid && desc.touched">
                  look good!
                </div> -->
              <div class="invalid-feedback" *ngIf="!desc.valid && desc.touched">
                Please enter valid Description
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <label for="twilioNumber" class="form-label">Phone Number(Twilio Number)</label>
              <div class="input-group">
                <input type="tel" class="form-control" name="twilioNumber" ngModel
                  [(ngModel)]="companyInfo.twilioNumber" required pattern="[- +()0-9]+" #twilioPhoneNumber="ngModel"
                  placeholder="Please enter Twilio Phone Number" [ngClass]="{
                  'is-invalid':
                    !twilioPhoneNumber.valid && twilioPhoneNumber.touched,
                  'is-valid': twilioPhoneNumber.valid && twilioPhoneNumber.touched
                }" />
                <div class="invalid-feedback" *ngIf="!twilioPhoneNumber.valid && twilioPhoneNumber.touched">
                  Please enter a valid Phone Number
                </div>
              </div>
            </div>
          </div>
          <div class="row d-flex align-items-center" *ngIf="isUpdate">
            <div class="col-md-12">
              <mat-checkbox ngModel name="updateIvr" style="margin-top: 10px; color: #666">Update IVR</mat-checkbox>
            </div>
          </div>

          <div class="row" *ngIf="isUpdate">
            <div class="col-md-6">
              <label for="description" class="form-label">Active Status</label>
              <select required class="form-control" name="ActiveStatus" [(ngModel)]="companyInfo['ActiveStatus']"
                #activeStatus="ngModel">
                <option value="true">Active</option>
                <option value="false">In Active</option>
              </select>
              <div class="invalid-feedback" *ngIf="!activeStatus.valid && activeStatus.touched">
                Please select Value
              </div>
            </div>
            <div class="col-md-6">
              <label for="fromEmail">Incoming Mail Group</label>
              <ng-multiselect-dropdown [data]="groups" [settings]="companySettings" [(ngModel)]="group"
                style="width: 100%; padding: 0" name="incomingMailGroup">
              </ng-multiselect-dropdown>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <label for="description" class="form-label">Forward Mail</label>
              <input type="mail" class="form-control" id="description" name="forwardMail"
                [(ngModel)]="companyInfo.forwardMail" #forward="ngModel" [disabled]="true" email [ngClass]="{
                'is-invalid': !forward.valid && forward.touched,
                'is-valid': forward.valid && forward.touched
              }" />
              <div class="invalid-feedback" *ngIf="!forward.valid && forward.touched">
                Please enter valid Description
              </div>
            </div>
          </div>

          <div class="modal-footer">
            <button type="submit" class="btn btn-primary btn-sm" [disabled]="!f.valid">
              {{ isUpdate ? "Update" : "Submit" }}
            </button>
          </div>
        </form>
      </mat-tab>
      <mat-tab label="Feedbacks" *ngIf="isUpdate">
        <form (ngSubmit)="statusCheckedValues(form.value)" #form="ngForm">
          <!-- <div class="d-flex py-1 align-items-center border-bottom">
            <div class="">
            <input class="form-control field-search" type="text" placeholder="Search" />
            </div>
            <div class="ml-auto">
              Pagination
            </div>
          </div> -->
          <div class="pt-2 pb-2 feedback-list-height">
            <div *ngFor="let items of feedbacks" class="">
              <div class="d-flex p-2 border-bottom">
                <div>
                  <mat-selection-list ngModel [name]="items['name']" (selectionChange)="
                  childMenuCheckBox($event)">
                    <mat-list-option [value]="items['id']" [selected]="checkBox(items['id'])" style="font-size: 13px">
                      {{ items["name"] | titlecase }}
                    </mat-list-option>
                  </mat-selection-list>
                </div>
                <div class="d-flex ml-auto">
                  <div class="mr-2" *ngIf="checkBox(items['id'])"
                    (click)="editFeedbackByProject(items['id'],this.companyInfo.id,editProjectFeedback)"><img
                      src="../../../assets/images/edit.svg" class="img12 hand"></div>
                  <!-- <div><img src="../../../assets/images/delete-g.svg" class="img11 hand"></div> -->
                </div>
              </div>

            </div>
          </div>
          <div>
            <div class="d-flex my-2">
              <div class="ml-auto">
                <button class="btn btn-primary btn-sm mr-2" type="button" (click)="open1(addFeedback)">
                  Add New
                </button>
                <button class="btn btn-secondary btn-sm" type="submit">
                  {{ isUpdate ? "Update" : "Submit" }}
                </button>
              </div>
            </div>
          </div>
        </form>
      </mat-tab>
      <mat-tab label="Channels">

      </mat-tab>
    </mat-tab-group>

  </div>
</ng-template>

<ng-template #addFeedback let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      Add New Feedback
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" style="max-height: 600px; overflow: auto">
    <ngb-alert type="danger" *ngIf="isError" [dismissible]="false">{{
      errorMessage
      }}</ngb-alert>
    <form (ngSubmit)="submitFeedback()" [formGroup]="feedbackForm">

      <div class="form-group">
        <label class="form-label">Name</label>
        <input class="form-control" name="name" formControlName="name" [ngClass]="{
            'is-invalid': feb.name.touched && feb.name.invalid,
            'is-valid':
              feb.name.valid && feb.name.touched
          }" />
        <div *ngIf="feb.name.errors" class="invalid-feedback">
          <div>Name is required</div>
        </div>
      </div>

      <div class="form-group">
        <label class="form-label">Type</label>
        <select id="inputState" class="form-control" name="type" class="form-control" formControlName="type" [ngClass]="{
          'is-invalid': feb.type.touched && feb.type.invalid,
          'is-valid': feb.type.valid && feb.type.touched
        }">
          <option disabled value="">Choose One</option>
          <option value="call">Call</option>
          <option value="doc">Document</option>
        </select>
        <div *ngIf="feb.type.errors" class="invalid-feedback">
          <div>Type is required</div>
        </div>
      </div>

      <div class="form-group">
        <label class="form-label">Description</label>
        <input class="form-control" name="desc" formControlName="desc" [ngClass]="{
            'is-invalid': feb.desc.touched && feb.desc.invalid,
            'is-valid':
              feb.desc.valid && feb.desc.touched
          }" />
        <div *ngIf="feb.desc.errors" class="invalid-feedback">
          <div>Description is required</div>
        </div>
      </div>
      <div class="d-flex justify-content-end">
        <button type="submit" class="btn btn-primary btn-sm" [disabled]="!feedbackForm.valid">Submit</button>
      </div>
    </form>
  </div>
</ng-template>

<ng-template #editProjectFeedback let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      Add New Feedback
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" style="max-height: 600px; overflow: auto">
    <ngb-alert type="danger" *ngIf="isError" [dismissible]="false">{{
      errorMessage
      }}</ngb-alert>
    <form (ngSubmit)="updateFeedbackById(f)" #f="ngForm">
      <div class="row mt-2">
        <label for="name" class="form-label mt-2">Name</label>
        <input type="text" class="form-control" placeholder="Please enter name" name="name"
          [(ngModel)]="feedbackDetails.name" required #userName="ngModel" />
      </div>
      <div class="row mt-2">
        <mat-checkbox ngModel [(ngModel)]="feedbackDetails.active" name="updateIvr"
          style="margin-top: 10px; color: #666">Active</mat-checkbox>
      </div>
      <div class="row mt-2">
        <label for="name" class="form-label mt-2">Background Color</label>
        <div class="col-md-4">
          <input type="color" class="form-control" style="height: 3rem;" name="bgcolor"
            [(ngModel)]="feedbackDetails.bgColor" required #userBgColor="ngModel" />
        </div>
      </div>
      <div class="row mt-2">
        <label for="name" class="form-label mt-2">Text Color</label>
        <div class="col-md-4">
          <input type="color" class="form-control" style="height: 3rem;" name="textcolor"
            [(ngModel)]="feedbackDetails.textColor" required #userTextColor="ngModel" />
        </div>
      </div>

      <div class="modal-footer">
        <button type="submit" class="btn btn-primary btn-sm" [disabled]="!f.valid">
          Update
        </button>
      </div>
    </form>
  </div>
</ng-template>


<ng-template #inviteLeadsModal let-modal style="width:500px">

  <div class="modal-dialog" role="document">
    <div class="modal-content" style="width:100%">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">
          Invite Leads
        </h5>
        <button type="button" #closeInviteButton id="closeInviteButton" class="close" aria-label="Close"
          (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
        </button>

      </div>
      <div>
        <mat-radio-group aria-label="Select an option" [(ngModel)]="typeOfUpload">
          <mat-radio-button value="bulk">Bulk Upload</mat-radio-button>
          <mat-radio-button value="manual">Enter Email</mat-radio-button>
        </mat-radio-group>
      </div>


      <div *ngIf="typeOfUpload == 'bulk'" class="modal-body" style="height: none;">
        <div class="px-2">
          <input type="file" style="display: inline-block;" (change)="incomingfile($event)" placeholder="Upload file"
            accept=".xlsx">
          <button type="button" class="btn btn-info" (click)="Upload()">Invite</button>
        </div>
      </div>

      <div *ngIf="typeOfUpload == 'manual'">
        <form [formGroup]="rulesForm">
          <mat-form-field>
            <mat-chip-list #chipList formArrayName="emails">
              <mat-chip [color]="(item.invalid)?'warn':''" selected *ngFor="let item of emailList; let i=index"
                [selectable]="true" [removable]="removable" (removed)="removeEmail(item)" required name="chips">
                {{item.value}}
                <mat-icon matChipRemove *ngIf="removable">X</mat-icon>
              </mat-chip>
              <input placeholder="enter item " [matChipInputFor]="chipList"
                [matChipInputSeparatorKeyCodes]="separatorKeysCodes " [matChipInputAddOnBlur]="true "
                (matChipInputTokenEnd)="add($event) " />
            </mat-chip-list>
          </mat-form-field>

          <!-- <mat-error *ngIf="rulesForm.get('emails').hasError('incorrectEmail')">Invalid email ID</mat-error> -->


          <!-- <div style="padding:20px" *ngIf=rulesForm?.submitted>
          </div> -->
          <div></div>
          <!-- <button mat-button type="submit " [disabled]="rulesForm.invalid">Add</button> -->
        </form>
        <mat-radio-group aria-label="Select an option" [(ngModel)]="role">
          <mat-radio-button value="agent">Agent</mat-radio-button>
          <mat-radio-button value="admin">Admin</mat-radio-button>
        </mat-radio-group>
        <div></div>
        <button mat-button (click)="inviteAgents()">Invite</button>
      </div>



      <!-- {{rulesForm.get('emails').errors | json}} -->
    </div>
  </div>
</ng-template>