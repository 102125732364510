import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EventScheduleService } from './event-schedule.service';
import { MatCalendarCellClassFunction, MatCalendarCellCssClasses } from '@angular/material/datepicker';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
const countryCodes = require('../../../assets/allcountryDetails.json');
const timeSlotList = require('../calendar/timedataset.json');

@Component({
  selector: 'app-event-schedule',
  templateUrl: './event-schedule.component.html',
  styleUrls: ['./event-schedule.component.css']
})
export class EventScheduleComponent implements OnInit {
  showForm: Boolean = false
  selectedDate: Date | null;
  currentDateTime: any = null
  startTimeSlot: any = []
  endTimeSlot: any = []
  timeList: any = []
  SelectedStartTime: any = null
  SelectedEndTime: any = null
  timeZoneDetails: any = []

  payload: any = {
    currentTimezone: moment.tz.guess(),
    currentTime: moment().tz(moment.tz.guess()).format('hh:mm A'),
    display_selected_date: moment().tz(moment.tz.guess()).format('dddd, MMM DD YYYY'),
    selected_date: moment().tz(moment.tz.guess()).format(),
    selected_time: null
  }
  minDate: any;
  startMinDate: any

  // myFilter = (d: Date | null): boolean => { // disable weekend

  //   const day = (d || new Date()).getDay();
  //   return day !== 0 && day !== 6;
  // };

  dateClass1: (d: Date) => any;
  agentDetails: any = {
    agentName: null,
    id: null,
    ProjectId: null,
    scheduleStatus: null,
    respond: null
  }
  agentScheduledEvents: any = []
  showcalendar: Boolean = false
  scheduleEventForm: FormGroup
  cancelEventForm: FormGroup
  rescheduleEventForm: FormGroup
  scheduledDetails: any;
  showConfirmationDialogue: Boolean = false
  cancellationReason: String = null
  scheduleDetailsForCancelReschedle: any = []
  countries: any[] = [];
  selectedCountryCode: any
  constructor(
    public route: ActivatedRoute,
    private router: Router,
    private eventScheduleService: EventScheduleService,
    private toastrservice: ToastrService,
    private formBuilder: FormBuilder,

  ) {

    this.route.queryParams.subscribe((Data) => {

      if (Data.status) {
        if (Data.status == 'accept' || Data.status == 'reject') {
          this.agentDetails.respond = Data.status
        }
        else {
          this.agentDetails.scheduleStatus = Data.status
        }
        this.agentDetails.id = Data.id
        this.agentDetails.ProjectId = Data.pid
      }
      else {
        this.agentDetails.id = Data.id
        this.agentDetails.ProjectId = Data.pid

      }
    })
    this.route.params.subscribe((param) => {

      if (param.agentName) {
        this.agentDetails.agentName = param.agentName
      }
      else {
        if (!this.agentDetails.respond) {
          // this.router.navigate(['/home']);
        }
      }
    });
    if (this.agentDetails.scheduleStatus == null && this.agentDetails.respond == null) {
      this.getAgentEventDetails()
    }
    else {
      if (this.agentDetails.respond) {
        this.updateEventRespond()
      } else {
        this.getScheduleEventbyId()

      }
    }

  }

  ngOnInit(): void {

    this.initForm()
    this.fetchCountries()
    this.startMinDate = moment(new Date).format('YYYY-MM-DD');
    this.currentDateTime = moment().tz(moment.tz.guess()).format('dddd, MMM DD')

    this.startTimeSlot = timeSlotList
    this.endTimeSlot = timeSlotList
    this.getTimeList()

    let allCountryname = moment.tz.countries();
    let currentDate = moment().format('YYYY-MM-DD');
    let timezones = []

    for (let i = 0; i < allCountryname.length; i++) {

      const CountryElement = allCountryname[i];
      timezones = []
      let allTimeZoneName = moment.tz.zonesForCountry(CountryElement);
      if (allTimeZoneName && allTimeZoneName.length) {
        for (let j = 0; j < allTimeZoneName.length; j++) {
          const element = allTimeZoneName[j];
          timezones.push({
            utc_time: moment(currentDate).tz(element).format('Z'),
            display_name: "(" + moment(currentDate).tz(element).format('Z') + ")" + " " + element,
            name: element
          })
        }
        this.timeZoneDetails.push(
          {
            country_name: CountryElement,
            timezones: timezones

          }
        )
      }
    }

    this.minDate = moment().tz(moment.tz.guess()).format()
  }
  initForm() {
    this.scheduleEventForm = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', Validators.required],
      guestmail: [''],
      mobile_no: ['', Validators.required],
      CountryCode: ['', Validators.required],
      message: ['', Validators.required],
      EventTitle: ['', Validators.required],
      EventType: [null, Validators.required]
    });
    this.cancelEventForm = this.formBuilder.group({
      cancel_reason: ['', Validators.required]
    });
    this.rescheduleEventForm = this.formBuilder.group({
      Name: [''],
      EmailId: [''],
      Members: [''],
      CountryCode: [''],
      MobileNumber: [''],
      Message: [''],
      StartDate: [''],
      StartTime: [''],
      EndDate: [''],
      EndTime: [''],
      EventTitle: [''],
      ScheduledTimezone: [''],
      EventType: ['']
    });
  }

  getTimeList() {
    var startTime = new Date();
    var EndTime = new Date();

    // this.minDate=moment().tz(this.payload.currentTimezone).format()
    this.startTimeSlot = timeSlotList.filter(x => {
      if (x.time > moment(new Date()).format('HH:mm:ss')) {
        return x
      }
    })
    this.endTimeSlot = timeSlotList.filter(x => {
      if (x.time > moment(new Date()).format('HH:mm:ss')) {
        return x
      }
    })
    this.timeList = []
    startTime.setHours(7, 29, 59, 9999);
    EndTime.setHours(23, 29, 59, 9999);
    let tempstartTime: any = startTime
    let tempEndTime: any = EndTime
    for (let i = 0; i < 48; i++) {
      if (moment(tempstartTime).format('hh:mm A') != moment(tempEndTime).format('hh:mm A')) {
        this.timeList.push({ time: moment(tempstartTime).add(30, 'm').format('hh:mm A'), Eventbooked: false })
        tempstartTime = moment(tempstartTime).add(30, 'm');
      }
    }
  }

  _pad(num) {
    let norm = Math.floor(Math.abs(num));
    return (norm < 10 ? '0' : '') + norm;
  }

  onSelectedDate(event: any) {

    let d = new Date(event)
    let curretnDate = moment(new Date()).format('YYYY-MM-DD')
    this.selectedDate = event
    this.getTimeList()

    this.payload.selected_date = event
    this.currentDateTime = moment(event).format('dddd, MMM DD')
    this.payload.display_selected_date = new Date(this.selectedDate).toLocaleDateString(undefined, {
      weekday: 'long',
      month: 'short',
      day: '2-digit',
      year: 'numeric'
    });
    this.startTimeSlot = timeSlotList
    this.endTimeSlot = timeSlotList

    this.SelectedStartTime = null
    this.SelectedEndTime = null

    if (this.agentScheduledEvents && this.agentScheduledEvents.length) {
      let agentEventDetailsForSelectedDate = this.agentScheduledEvents.filter(x => x.StartDate == d.getFullYear() + "-" + this._pad(d.getMonth() + 1) + "-" + this._pad(d.getDate()))
      if (agentEventDetailsForSelectedDate && agentEventDetailsForSelectedDate.length) {

        for (let i = 0; i < agentEventDetailsForSelectedDate.length; i++) {
          let StartTime = moment(agentEventDetailsForSelectedDate[i].StartDate + " " + agentEventDetailsForSelectedDate[i].StartTime).format('HH:mm:ss')
          let EndTime = moment(agentEventDetailsForSelectedDate[i].EndDate + " " + agentEventDetailsForSelectedDate[i].EndTime).format('HH:mm:ss')
          this.startTimeSlot = this.startTimeSlot.filter(x => {
            if (x.time >= StartTime.trim() && x.time <= EndTime.trim()) {
              // return x.time, x.Eventbooked = true
            }
            else {
              if (curretnDate == agentEventDetailsForSelectedDate[i].StartDate && x.time > moment(new Date()).format('HH:mm:ss')) {
                return x
              }
              if (curretnDate != agentEventDetailsForSelectedDate[i].StartDate) {
                return x
              }
            }
          })
          this.endTimeSlot = this.endTimeSlot.filter(x => {
            if (x.time >= StartTime.trim() && x.time <= EndTime.trim()) {
              // return x.time, x.Eventbooked = true
            }
            else {
              if (curretnDate == agentEventDetailsForSelectedDate[i].StartDate && x.time > moment(new Date()).format('HH:mm:ss')) {
                return x
              }
              if (curretnDate != agentEventDetailsForSelectedDate[i].StartDate) {
                return x
              }
            }
          })
        }
      }
      else {
        if (moment(d).format('YYYY-MM-DD') == moment(new Date()).format('YYYY-MM-DD')) {
          this.startTimeSlot = timeSlotList.filter(x => {
            if (x.time > moment(new Date()).format('HH:mm:ss')) {
              return x
            }
          })
          this.endTimeSlot = timeSlotList.filter(x => {
            if (x.time > moment(new Date()).format('HH:mm:ss')) {
              return x
            }
          })
        }
        else {
          this.startTimeSlot = timeSlotList
          this.endTimeSlot = timeSlotList
        }

      }
    }
  }


  dateClass() {
    // if (this.agentScheduledEvents && this.agentScheduledEvents.length) {
    return (date: Date): MatCalendarCellCssClasses => {

      let curretnDate = new Date()
      if (date.getDate() >= curretnDate.getDate() &&
        (date.getMonth() + 1) >= (curretnDate.getMonth() + 1) &&
        date.getFullYear() >= curretnDate.getFullYear()) {
        let highlightDate = this.agentScheduledEvents
          .find((x) => {
            return new Date(x.StartDate).getDate() === date.getDate() &&
              (new Date(x.StartDate).getMonth() + 1) === (date.getMonth() + 1) &&
              new Date(x.StartDate).getFullYear() === date.getFullYear()
          });
        return highlightDate ? 'selected-date' : '';
      }
      else {
        return ''
      }
    };
    // }
    // return '';


  }

  selectTimeSlot(item: any, from: any) {
    if (from == 1) {
      this.SelectedStartTime = item;
      this.endTimeSlot = this.startTimeSlot.filter(x => {
        if (x.time > this.SelectedStartTime) {
          return x
        }
      });
      this.SelectedEndTime = null
    }
    if (from == 2) {
      this.SelectedEndTime = item;
    }

  }

  showConfirmationForm(Data?: any) {
    this.showForm = true
    this.payload.selected_time = Data
    // this.payload.currentTimezone = new Date().toLocaleDateString(undefined, {
    //   timeZoneName: 'long'
    // }).split(", ")[1]
  }

  getAgentEventDetails(id?: any) {
    let payload = {
      id: id ? id : this.agentDetails.id,
      ProjectId: this.agentDetails.ProjectId
    }
    this.eventScheduleService.getAgentEventDetails(payload).subscribe((Data: any) => {
      if (Data.status === true) {
        this.agentScheduledEvents = Data.data
        this.showcalendar = true
        if (this.agentScheduledEvents && this.agentScheduledEvents.length) {

          let d = new Date()
          let curretnDate = moment(d).format('YYYY-MM-DD')

          let agentEventDetailsForSelectedDate = this.agentScheduledEvents.filter(x => x.StartDate == d.getFullYear() + "-" + this._pad(d.getMonth() + 1) + "-" + this._pad(d.getDate()))

          if (agentEventDetailsForSelectedDate && agentEventDetailsForSelectedDate.length) {

            for (let i = 0; i < agentEventDetailsForSelectedDate.length; i++) {
              let StartTime = moment(agentEventDetailsForSelectedDate[i].StartDate + " " + agentEventDetailsForSelectedDate[i].StartTime).format('HH:mm:ss')
              let EndTime = moment(agentEventDetailsForSelectedDate[i].EndDate + " " + agentEventDetailsForSelectedDate[i].EndTime).format('HH:mm:ss')
              this.startTimeSlot = this.startTimeSlot.filter(x => {

                if (x.time >= StartTime.trim() && x.time <= EndTime.trim()) {
                  // return x.time, x.Eventbooked = true
                }
                else {
                  if (x.time > moment(new Date()).format('HH:mm:ss')) {
                    return x
                  }
                }
              })
              this.endTimeSlot = this.endTimeSlot.filter(x => {
                if (x.time >= StartTime.trim() && x.time <= EndTime.trim()) {
                  // return x.time, x.Eventbooked = true
                }
                else {
                  if (x.time > moment(new Date()).format('HH:mm:ss')) {
                    return x
                  }
                }
              });

            }
          }
          else {
            this.startTimeSlot = timeSlotList.filter(x => {
              if (x.time > moment(new Date()).format('HH:mm:ss')) {
                return x
              }
            })
            this.endTimeSlot = timeSlotList.filter(x => {
              if (x.time > moment(new Date()).format('HH:mm:ss')) {
                return x
              }
            })
          }
        }
        if (id) {
          this.getScheduleEventTimeSlots()
        }

      }
      else {
        this.toastrservice.error(Data.message)
      }
    })
  }

  getScheduleEventbyId() {
    let req_payload = {
      id: this.agentDetails.id,
      ProjectId: this.agentDetails.ProjectId
    }
    this.eventScheduleService.getScheduleEventById(req_payload).subscribe(async (Data: any) => {
      if (Data.status === true) {
        this.scheduleDetailsForCancelReschedle = {
          ...Data.data,
          fullDate: moment(Data.data.StartDate).format('dddd, MMM, DD YYYY')
        }
        await this.getAgentEventDetails(Buffer.from(this.scheduleDetailsForCancelReschedle.AgentId.toString(), 'binary').toString('base64').toString())

        // this.getScheduleEventTimeSlots()
        if (this.rescheduleEventForm) {
          this.startTimeSlot = timeSlotList
          this.endTimeSlot = timeSlotList
          this.rescheduleEventForm.controls['Name'].setValue(this.scheduleDetailsForCancelReschedle.Name)
          this.rescheduleEventForm.controls['EmailId'].setValue(this.scheduleDetailsForCancelReschedle.EmailId)
          this.rescheduleEventForm.controls['MobileNumber'].setValue(this.scheduleDetailsForCancelReschedle.MobileNumber)
          this.rescheduleEventForm.controls['Members'].setValue(this.scheduleDetailsForCancelReschedle.Members)
          this.rescheduleEventForm.controls['Message'].setValue(this.scheduleDetailsForCancelReschedle.Message)
          this.rescheduleEventForm.controls['StartDate'].setValue(this.scheduleDetailsForCancelReschedle.StartDate)
          this.rescheduleEventForm.controls['StartTime'].setValue(this.scheduleDetailsForCancelReschedle.StartTime)
          this.rescheduleEventForm.controls['EndDate'].setValue(this.scheduleDetailsForCancelReschedle.EndDate)
          this.rescheduleEventForm.controls['EndTime'].setValue(this.scheduleDetailsForCancelReschedle.EndTime)
          this.rescheduleEventForm.controls['ScheduledTimezone'].setValue(this.scheduleDetailsForCancelReschedle.ScheduledTimezone)
          this.rescheduleEventForm.controls['EventTitle'].setValue(this.scheduleDetailsForCancelReschedle.EventTitle)
          this.rescheduleEventForm.controls['EventType'].setValue(this.scheduleDetailsForCancelReschedle.EventType)
          this.rescheduleEventForm.controls['CountryCode'].setValue(this.scheduleDetailsForCancelReschedle.CountryCode)
          this.selectedCountryCode = this.scheduleDetailsForCancelReschedle.CountryCode
          this.selectedDate = this.scheduleDetailsForCancelReschedle.EndDate
        }
        // this.toastrservice.success(Data.message);
      }
      else {
        // this.toastrservice.error(Data.message);
      }
    })
  }

  onSelectedRecheduleDate(event: any) {
    let d = new Date(event.target['value']);
    let curretnDate = moment(new Date()).format('YYYY-MM-DD')
    this.selectedDate = event.target['value']

    this.startTimeSlot = timeSlotList
    this.endTimeSlot = timeSlotList

    this.rescheduleEventForm.controls['EndDate'].setValue(event.target['value'])
    this.rescheduleEventForm.controls['StartTime'].setValue(null)
    this.rescheduleEventForm.controls['EndTime'].setValue(null)

    if (this.agentScheduledEvents && this.agentScheduledEvents.length) {

      let agentEventDetailsForSelectedDate = this.agentScheduledEvents.filter(x => x.StartDate == d.getFullYear() + "-" + this._pad(d.getMonth() + 1) + "-" + this._pad(d.getDate()))
      if (agentEventDetailsForSelectedDate && agentEventDetailsForSelectedDate.length) {

        for (let i = 0; i < agentEventDetailsForSelectedDate.length; i++) {
          let StartTime = moment(agentEventDetailsForSelectedDate[i].StartDate + " " + agentEventDetailsForSelectedDate[i].StartTime).format('HH:mm:ss')
          let EndTime = moment(agentEventDetailsForSelectedDate[i].EndDate + " " + agentEventDetailsForSelectedDate[i].EndTime).format('HH:mm:ss')
          this.startTimeSlot = this.startTimeSlot.filter(x => {
            if (x.time >= StartTime.trim() && x.time <= EndTime.trim()) {
              // return x.time, x.Eventbooked = true
            }
            else {
              if (curretnDate == agentEventDetailsForSelectedDate[i].StartDate && x.time > moment(new Date()).format('HH:mm:ss')) {
                return x
              }
              if (curretnDate != agentEventDetailsForSelectedDate[i].StartDate) {
                return x
              }
            }
          })
          this.endTimeSlot = this.endTimeSlot.filter(x => {
            if (x.time >= StartTime.trim() && x.time <= EndTime.trim()) {
              // return x.time, x.Eventbooked = true
            }
            else {
              if (curretnDate == agentEventDetailsForSelectedDate[i].StartDate && x.time > moment(new Date()).format('HH:mm:ss')) {
                return x
              }
              if (curretnDate != agentEventDetailsForSelectedDate[i].StartDate) {
                return x
              }
            }
          })
        }
      }
      else {
        this.startTimeSlot = timeSlotList.filter(x => {
          if (x.time > moment(new Date()).format('HH:mm:ss')) {
            return x
          }
        })
        this.endTimeSlot = timeSlotList.filter(x => {
          if (x.time > moment(new Date()).format('HH:mm:ss')) {
            return x
          }
        })
      }
    }
  }
  selectedResheduleStartTime(event: any) {

    this.rescheduleEventForm.controls['EndTime'].setValue(null)

    this.endTimeSlot = this.startTimeSlot.filter(x => {
      if (x.time > event.target['value'] + ":00")
        return x
    })

    // this.event_form.controls['EndTime'].value(this.endTimeDataset[0]['time'])
  }

  getScheduleEventTimeSlots() {

    if (this.agentScheduledEvents && this.agentScheduledEvents.length) {
      let d = new Date(this.scheduleDetailsForCancelReschedle.StartDate)
      let curretnDate = moment(new Date()).format('YYYY-MM-DD')
      this.startTimeSlot = timeSlotList
      this.endTimeSlot = timeSlotList
      let agentEventDetailsForSelectedDate = this.agentScheduledEvents.filter(x => x.StartDate == d.getFullYear() + "-" + this._pad(d.getMonth() + 1) + "-" + this._pad(d.getDate()))

      // if (agentEventDetailsForSelectedDate && agentEventDetailsForSelectedDate.length) {

      for (let i = 0; i < agentEventDetailsForSelectedDate.length; i++) {
        let StartTime = moment(agentEventDetailsForSelectedDate[i].StartDate + " " + agentEventDetailsForSelectedDate[i].StartTime).format('HH:mm:ss')
        let EndTime = moment(agentEventDetailsForSelectedDate[i].EndDate + " " + agentEventDetailsForSelectedDate[i].EndTime).format('HH:mm:ss')
        this.startTimeSlot = this.startTimeSlot.filter(x => {

          if (x.time >= StartTime.trim() && x.time <= EndTime.trim()) {
            // return x.time, x.Eventbooked = true
          }
          else {
            if (curretnDate == agentEventDetailsForSelectedDate[i].StartDate && x.time > moment(new Date()).format('HH:mm:ss')) {
              return x
            }
            if (curretnDate != agentEventDetailsForSelectedDate[i].StartDate) {
              return x
            }
          }
        })
        this.endTimeSlot = this.endTimeSlot.filter(x => {
          if (x.time >= StartTime.trim() && x.time <= EndTime.trim()) {
            // return x.time, x.Eventbooked = true
          }
          else {
            if (curretnDate == agentEventDetailsForSelectedDate[i].StartDate && x.time > moment(new Date()).format('HH:mm:ss')) {
              return x
            }
            if (curretnDate != agentEventDetailsForSelectedDate[i].StartDate) {
              return x
            }
          }
        });

      }
      // }
      // else {
      //   this.startTimeSlot = timeSlotList
      //   this.endTimeSlot = timeSlotList
      // }
    }
  }

  scheduleEvent() { // Add schedule Event
    let getUTCOffset = moment.tz(this.payload.currentTimezone).format('Z')
    if (this.SelectedEndTime < this.SelectedStartTime) {
      this.toastrservice.error("Please select valid end time!")
    }
    else {
      if (this.scheduleEventForm.valid) {
        let payload = {
          ...this.scheduleEventForm.value,
          ...this.payload,
          startDateTime: this.SelectedStartTime,
          endDateTime: this.SelectedEndTime,
          selected_date: moment(this.payload.selected_date).utcOffset(getUTCOffset).format('YYYY-MM-DD'),
          CurrentTimezone: this.payload.currentTimezone,
          AgentId: this.agentDetails.id,
          ProjectId: this.agentDetails.ProjectId

        }

        this.eventScheduleService.createEventSchedule(payload).subscribe((Data: any) => {
          if (Data.status === true) {

            this.scheduledDetails = {
              ...Data.data,
              fullDate: moment(Data.data.StartDate).format('dddd, MMM, DD YYYY')
            }
            this.showConfirmationDialogue = true
            this.scheduleEventForm.reset()
            this.toastrservice.success(Data.message)

          }
          else {
            this.toastrservice.error(Data.message)
          }
        })
      }
      else {
        this.toastrservice.error("Please fill mandatory fields!")
      }
    }
  }

  selectedZone(Data?: any) {

    let zoneDateTime = moment.utc().utcOffset(Data.value['utc_time']).format('LTS')
    this.payload.currentTimezone = Data.value['name']
    this.payload.currentTime = zoneDateTime
    this.getTimeList()

    let d = new Date(this.payload.selected_date)
    if (this.agentScheduledEvents && this.agentScheduledEvents.length) {
      let agentEventDetailsForSelectedDate = this.agentScheduledEvents.filter(x => x.StartDate == d.getFullYear() + "-" + this._pad(d.getMonth() + 1) + "-" + this._pad(d.getDate()))
      for (let i = 0; i < agentEventDetailsForSelectedDate.length; i++) {
        let AgentEventStartTime = moment(agentEventDetailsForSelectedDate[i].StartDate + " " + agentEventDetailsForSelectedDate[i].StartTime).format('hh:mm A')
        let AgentEventEndTime = moment(agentEventDetailsForSelectedDate[i].EndDate + " " + agentEventDetailsForSelectedDate[i].EndTime).format('hh:mm A')
        this.timeList = this.timeList.filter(x => {

          if (x.time >= AgentEventStartTime && x.time <= AgentEventEndTime) {
            return x.time, x.Eventbooked = true
          }
          else {
            return x
          }
        })
      }
    }
  }

  onKeyUpSearch(Data: any) {
    let filter = Data.toLowerCase();
    let data = []
    this.timeZoneDetails.filter(x => {
      return x.timezones.filter(y => {
        if (y.display_name.toLowerCase().includes(filter)) {
          data.push(x)
          return data
        }

      });
    });
    // if(x.timezones.toLowerCase().includes(filter)){
    //   return {
    //     country_name:x.country_name,
    //     timezones:x.timezones
    //   }
    // }


  }

  cancelEvent() { // Cancel Event
    let req_payload = {
      ...this.cancelEventForm.value,
      ...this.agentDetails
    }
    this.eventScheduleService.updateScheduleEventDetails(req_payload).subscribe((Data: any) => {
      if (Data.status === true) {

        this.toastrservice.success(Data.message)
      }
      else {
        this.toastrservice.error(Data.message)
      }
    })
  }

  rescheduleEvent() {
    if (this.rescheduleEventForm.value['EndTime'] < this.rescheduleEventForm.value['StartTime']) {
      this.toastrservice.error("Please select valid end time!")
    }
    else {
      let req_payload = {
        ...this.agentDetails,
        ...this.rescheduleEventForm.value
      }
      this.eventScheduleService.updateScheduleEventDetails(req_payload).subscribe((Data: any) => {
        if (Data.status === true) {
          this.toastrservice.success(Data.message)

        }
        else {
          this.toastrservice.warning(Data.message)

        }
      });
    }
  }

  fetchCountries() {
    this.countries = countryCodes;
  }

  selectCountry(country) {
    this.selectedCountryCode = country.callingCodes[0];
    this.scheduleEventForm.controls['CountryCode'].setValue(this.selectedCountryCode)

  }

  onInput(Event: any) {
    let search = Event.target.value;
    let serachData = [];
    if (search) {
      this.countries = countryCodes.filter((x) => {
        return x.name.toLowerCase().includes(search.toLowerCase());
      });
    } else {
      this.countries = countryCodes;
    }
  }

  EventResponded: Boolean = false
  updateEventRespond() {

    this.eventScheduleService.updateEventRespond(this.agentDetails).subscribe((Data: any) => {
      if (Data.status == true) {
        this.EventResponded = true
        this.toastrservice.success(Data.message);

      }
      else {
        this.toastrservice.error(Data.message);
      }
    })
  }

  confirmedTimeSlot() {

    this.showForm = true
  }
  ClearForm() {
    this.showForm = false
    this.SelectedStartTime = null
    this.SelectedEndTime = null
  }
}
