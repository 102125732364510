<div class="gbg p-3">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="p-2 float-right">
                    <div class="form-group">
                        <select class="form-control cdd" id="">
                            <option>1 Day</option>
                            <option>7 days</option>
                            <option>14 days</option>
                            <option>30 days</option>
                            <option>Custom</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-md-3">
                <div class="db-block">
                    <div class="p-3">
                        <div class="head">Completed Calls</div>
                        <div class="py-3 mt-0">
                            <img src="../../../../assets/img/call-completed.svg" class="img32 mr-2 float-left" />
                            <div class="ht mt-1 float-left" style="color:#FA952F">235</div>
                            <div class="db-sm-header mb-2"> Total Calls </div>

                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="db-block">
                    <div class="p-3">
                        <div class="head">Scheduled Calls</div>
                        <div class="py-3 mt-0">
                            <img src="../../../../assets/img/call-schedule.svg" class="img32 mr-2 float-left" />
                            <div class="ht mt-1 float-left" style="color:#163252">235</div>
                            <div class="db-sm-header mb-2"> Counselling Calls</div>

                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="db-block">
                    <div class="p-3">
                        <div class="head">Calls on Queue</div>
                        <div class="py-3 mt-0">
                            <img src="../../../../assets/img/queqe.svg" class="img32 mr-2 float-left" />
                            <div class="ht mt-1 float-left" style="color:#FF9D36">235</div>
                            <div class="db-sm-header mb-2"> Pending Calls</div>

                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="db-block">
                    <div class="p-3">
                        <div class="head">Average Response Time</div>
                        <div class="py-3 mt-0">
                            <img src="../../../../assets/img/timer.svg" class="img32 mr-2 float-left" />
                            <div class="ht mt-1 float-left" style="color:#CC66CC">58 sec</div>
                            <div class="db-sm-header mb-2">Call Response </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-md-3">
                <div class="db-block h280">
                    <div class="p-3">
                        <div class="head">Total Amount of Calls</div>
                        <div class="py-3 mt-1">
                            <img src="../../../../assets/img/dummy-total-calls.png" class="img32 mr-2 float-left" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="db-block h280">
                    <div class="p-3">
                        <div class="head">Call Summary</div>
                        <div class="py-3 mt-1 text-center">
                            <img src="../../../../assets/img/dummy-call-summary.png" class="" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="db-block h280">
                    <div class="p-3">
                        <div class="head">Ranking</div>
                        <div class="py-1 mt-1 text-center">
                            <table class="table">
                                <tr>
                                    <td class="vm" width="10px" style="border-top:0; font-weight: bold;">1</td>
                                    <td width="30px" style="border-top:0;font-weight: bold;">
                                        <img src="../../../../assets/img/u-profile.svg" class="img24" />
                                    </td>
                                    <td class="text-left vm" style="border-top:0;font-weight: bold;">Krishna jakkamsett
                                    </td>
                                    <td width="50px" style="border-top:0;font-weight: bold;">235</td>
                                </tr>
                                <tr>
                                    <td class="vm">2</td>
                                    <td class="vm"><img src="../../../../assets/img/u-profile.svg" class="img24" /></td>
                                    <td class="text-left vm">Krishna jakkamsett</td>
                                    <td class="vm">235</td>
                                </tr>
                                <tr>
                                    <td>3</td>
                                    <td><img src="../../../../assets/img/u-profile.svg" class="img24" /></td>
                                    <td class="text-left">Krishna jakkamsett</td>
                                    <td>235</td>
                                </tr>
                                <tr>
                                    <td>4</td>
                                    <td><img src="../../../../assets/img/u-profile.svg" class="img24" /></td>
                                    <td class="text-left">Krishna jakkamsett</td>
                                    <td>235</td>
                                </tr>
                                <tr>
                                    <td>5</td>
                                    <td><img src="../../../../assets/img/u-profile.svg" class="img24" /></td>
                                    <td class="text-left">Krishna jakkamsett</td>
                                    <td>235</td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row mb-3">
            <div class="col-md-6">
                <div class="db-block h280">
                    <div class="p-3">
                        <div class="head">Inbound Close Rate</div>
                        <div class="py-3 mt-1">
                            <img src="../../../../assets/img/dummy-transfer-calls.png" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="db-block h280">
                    <div class="p-3">
                        <div class="head">Outbound Close Rate</div>
                        <div class="py-3 mt-1 text-center">
                            <img src="../../../../assets/img/dummy-call-summary.png" class="" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-md-6">
                <div class="db-block h280">
                    <div class="p-3">
                        <div class="head">Transferred calls</div>
                        <div class="py-3 mt-1">
                            <img src="../../../../assets/img/dummy-transfer-calls.png" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="db-block h280">
                    <div class="p-3">

                    </div>
                </div>
            </div>
        </div>
        <p>agent-calls-dashboard works!</p>
    </div>