import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LeadsDashboardService } from '../../leads-dashboard/leads-dashboard.service';
import * as data from './data.json'

interface Project {
  value: string;
  viewValue: string;
}
@Component({
  selector: 'app-cards',
  templateUrl: './cards.component.html',
  styleUrls: ['./cards.component.css']
})
export class CardsComponent implements OnInit {
  tab!: string
  noOfCards: any;
  cardData: any;

  projects: Project[] = [
    { value: 'project1', viewValue: 'AccessQue' },
    { value: 'project2', viewValue: 'UniversityHub' },
    { value: 'project2', viewValue: 'Rorotel' }
  ];
  projectId: any;
  config: any;
  feedback: any;
  companyId: any;
  topBarCounts: any;
  userData: any;
  startDate: any;
  endDate: any;
  contentLoaded = false;

  constructor(
    private route: ActivatedRoute,
    public LeadsDashboardService: LeadsDashboardService
  ) { }

  ngOnInit(): void {
    this.userData = JSON.parse(localStorage.getItem("userData"));
    this.projectId = this.route.snapshot.paramMap.get('projectId');
    this.LeadsDashboardService.dateChange.subscribe((dates: any) => {
      if (dates) {
        this.startDate = dates.start;
        this.endDate = dates.end;
        this.getTopBarCounts();
      }
    })

    this.getProjectConfig(this.projectId);

    this.LeadsDashboardService.refresh.subscribe((res: any) => {
      console.log('Refresh: ', res)
      if (res == true) {
        this.getProjectConfig(this.projectId);
        this.getTopBarCounts();
      }
    })

    this.LeadsDashboardService.config.subscribe((res: any) => {
      if (res == "refreshLeads") {
        this.getTopBarCounts();
      }
    });

  }

  getProjectConfig(id) {
    this.contentLoaded = false;
    this.LeadsDashboardService.getConfig({ projectId: id }).subscribe((res: any) => {
      this.config = res.ProjectInfo[0].config[0];
      this.cardData = this.config.cards;
      this.feedback = this.config.feedback;
      this.companyId = res.ProjectInfo[0].companyId;
      this.getTopBarCounts();
      if (this.feedback.length > 0) {
        for (let i = 0; i < this.feedback.length; i++) {
          if (this.feedback[i].selected == true) {
            this.cardData.push({ type: 'status', value: this.feedback[i].value, name: this.feedback[i].name })
          }
        }
        this.contentLoaded = true;
      } else {
        this.contentLoaded = true;
      }

    })
  }

  getTopBarCounts() {
    console.log('topbarcards: ', this.companyId, this.projectId);
    this.LeadsDashboardService.getTopBarCounts({
      projectId: this.projectId,
      companyId: this.companyId,
      mail: this.userData.mail,
      start: this.startDate,
      end: this.endDate
    }).subscribe((res: any) => {
      this.topBarCounts = res.counts;
    })
  }

  getLeadsByCards(item) {
    this.getTopBarCounts();
    console.log("Clicked on on card: ", item);
    this.LeadsDashboardService.cardValue.next(item);
  }
  onClick(i) {

  }

}
