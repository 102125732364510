import { Component, Input, OnInit } from "@angular/core";
import { catchError } from "rxjs/operators";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { SharedService } from "./../../shared/shared.service";
import { MarketingService } from "./../marketing/marketing.service";
import { InputModule } from "../companymodules/models/companymodule.model";
// import { Component, Input, OnInit } from '@angular/core';
import { NgbModal, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";
import { ErrorHandlerService } from "src/app/error-handler.service";

@Component({
  selector: "app-smsmodules",
  templateUrl: "./smsmodules.component.html",
  styleUrls: ["./smsmodules.component.css"],
})
export class SmsmodulesComponent implements OnInit {
  public companyId: any;
  public updateModules: any[] = [];
  public userData: any;
  @Input() update: boolean = false;
  public order: boolean = false;
  public orderFeild: string = "UpdatedDate";
  public orderString: boolean | "asc" | "desc" = "desc";
  public search: string;
  public page: any = 1;
  public pageSize: any = 6;
  closeResult: string;
  @Input() submitted: boolean;
  public isError: boolean = false;
  public Module: string = "";
  @Input() public feedBackId: string;
  @Input() public smsUpdate: boolean = false;

  @Input() smsForm;

  constructor(
    private readonly marketingService: MarketingService,
    private readonly sharedService: SharedService,
    private readonly modalService: NgbModal,
    private handleError: ErrorHandlerService,
    private readonly fb: FormBuilder
  ) { }

  ngOnInit() {
    if (!this.smsUpdate) {
      this.addNewNumber();
      this.smsForm.patchValue({
        time: { hour: 0o0, minute: 15, second: 30 },
      });
    }
    // this.getCompanyId();
  }

  getCompanyInfoData() {
    this.marketingService
      .getAllSmsByCompany(this.companyId)
      .subscribe((data) => {
        this.updateModules = data;

      }, (error) => {
        this.handleError.handleError(error)
      });
  }

  sortOrder(feildName) {
    this.orderFeild = feildName;
    this.order = !this.order;
    if (this.order) {
      this.orderString = "asc";
    } else {
      this.orderString = "desc";
    }
  }

  open(content) {
    if (!this.update) {
      this.addNewNumber();
      this.smsForm.patchValue({
        time: { hour: 0o0, minute: 15, second: 30 },
      });
    }
    if (this.modalService.hasOpenModals) {
      this.modalService.open(content, { size: "md" }).result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
    }
  }

  private getDismissReason(reason: any): string {
    this.resetForm();
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }

  resetForm() {
    this.smsForm.reset();
    this.removeAllControls();

    // this.smsForm.controls.Module.enable()
    this.update = false;
    this.submitted = false;
    // this.Module = '';
    this.feedBackId = null;
  }

  updateModule(content, id) {
    this.update = true;
  }

  updateFeedBackValues(data) {
    let beforeArray = data["BeforeSMSTime"].split(":");
    let time = {
      hour: +beforeArray[0],
      minute: +beforeArray[1],
      second: +beforeArray[2],
    };
    this.removeAllControls();
    if (data["SMSType"] === "Feedback") {
      this.marketingService
        .getSmsConfig({ id: data["id"], moduleName: data["Module"] })
        .subscribe((data1: any) => {
          if (data1) {

            this.feedBackId = data1["id"];
            data1["SmsConfig"].forEach((element) => {
              this.Ivr().push(
                this.fb.group({
                  feedBackNumber: element["feedBackNumber"],
                  value: element["value"],
                })
              );
            });

            this.smsForm.patchValue({
              smsTextHeader: data1["SmsTextHeader"],
              smsTextFooter: data1["SmsTextFooter"],
              // Module: data['Module'],
              time: time,
              // RedialThreshold: data['RedialThreshold'],
              SMSType: data["SMSType"],
            });

          } else {
            // this.addNewNumber();
            this.smsForm.patchValue({
              smsTextHeader: data["SMSText"],
              smsTextFooter: "",
              // Module: data['Module'],
              time: time,
              // RedialThreshold: data['RedialThreshold'],
              SMSType: data["SMSType"],
            });
          }
        }, (error) => {
          this.handleError.handleError(error)
        });
    }
  }

  get f() {
    return this.smsForm.controls;
  }

  Ivr(): FormArray {
    return this.smsForm.get("Ivr") as FormArray;
  }

  newNumber(): FormGroup {
    return this.fb.group({
      feedBackNumber: this.Ivr().length != 0 ? this.Ivr().length - 1 + 1 : 0,
      value: "",
    });
  }

  addNewNumber() {
    this.Ivr().push(this.newNumber());
  }

  removeNumber(index: number) {
    this.Ivr().removeAt(index);
  }

  removeAllControls() {
    this.Ivr().clear();
  }
}
