import { ActivatedRoute } from "@angular/router";
import { Component, OnInit } from "@angular/core";
import { DashboardService } from "../dashboard.service";
import * as io from "socket.io-client";
import { SharedService } from "src/app/shared/shared.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Ticket } from "../models/ticket.model";
import { NgbDropdownConfig } from "@ng-bootstrap/ng-bootstrap";

import { TicketsService } from "../../tickets/tickets.service";
import { AuthService } from "src/app/account/auth/auth.service";
import { Observable } from "rxjs";
import { LayoutService } from "src/app/layout/layout.service";
import { ProjectchangeService } from "src/app/layout/project-change/projectchange.service";
import { ErrorHandlerService } from "src/app/error-handler.service";
declare const $: any;
declare const Twilio: any;
interface TicketChannel {
  mail: any;
  accessque: any;
  sms: any;
  call: any;
  website: any;
  chat: any;
  myGroups: any;
  myTickets: any;
}

@Component({
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.css"],
  providers: [NgbDropdownConfig],
})
export class DashboardComponent implements OnInit {
  tickSummary: any = {};
  ticketsList: any = [];
  tickets: Ticket[] = [];
  connection: any = null;
  phoneNumber: any = "+44 1227 641187";
  private url = "http://localhost:3000";
  private socket;
  public page: any = 1;
  public pageSize: any = 10;
  callData: any;
  workerJS: any = null;
  reservation: any;
  task: any;
  configuration: any;
  worker: any;
  direction: any = null;
  search: any = "";
  totalGroups: number;
  dashboard: TicketChannel = {
    accessque: 0,
    call: 0,
    chat: 0,
    mail: 0,
    sms: 0,
    website: 0,
    myGroups: 0,
    myTickets: 0,
  };
  userData: any;
  // superAdmin: Boolean = false;
  companyId: any;
  companyAdmin: Boolean = false;
  superAdminObervable: Observable<Boolean>;
  order: boolean = false;
  orderFeild: string = "UpdatedDate";
  orderString: boolean | "asc" | "desc" = "desc";
  rotating: boolean = false;

  constructor(
    public dashBoardService: DashboardService,
    public sharedService: SharedService,
    public ticketService: TicketsService,
    config: NgbDropdownConfig,
    private authService: AuthService,
    private readonly route: ActivatedRoute,
    private readonly layoutService: LayoutService,
    public projectchangeService: ProjectchangeService,
    private handleError: ErrorHandlerService,

  ) { }
  toggleIsOnQueue(activitySid) {
    let payload = {
      ActivitySid: activitySid,
    };

    this.workerJS.update(payload, function (error, worker) {

    });
  }
  complete() {
    this.workerJS.completeTask(this.task.sid, function (error, task) {
      if (error) {
        console.error(error);
        return;
      }

    });
  }

  ngOnInit() {
    this.route.params.subscribe((data) => {
      if (data["phoneNumber"]) {
        const reg = new RegExp("^([0|+[0-9]{1,5})?([7-9][0-9]{9})$");
        if (data["phoneNumber"].match(reg)) {
          setTimeout(() => {
            this.layoutService.callAgent.next(data["phoneNumber"]);
          }, 10000);
        }
      }
    });
    this.userData = JSON.parse(localStorage.getItem("userData"));
    this.ticketService
      .getgroupsusers(this.userData["mail"])
      .subscribe((data) => {
        this.totalGroups = data.length;
      }, (error) => {
        this.handleError.handleError(error)
      });
    this.sharedService.pageEvent.emit({ pageName: "Dashboard" });
    // this.sharedService.projectId.subscribe((data) => {
    //   this.companyId = +data;
    //   if (data) {
    //     this.sharedService
    //       .getProjectAdmin(this.companyId)
    //       .subscribe((admin) => {
    //         this.companyAdmin = admin;
    //         this.getIntialTickets(this.companyId, this.companyAdmin);
    //       });
    //   }
    // });
    setTimeout(() => {
      this.companyId = this.projectchangeService._projectId;
      this.sharedService
        .getProjectAdmin(this.companyId)
        .subscribe((admin) => {
          this.companyAdmin = admin;
          this.getIntialTickets(this.companyId, this.companyAdmin);
        }, (error) => {
          this.handleError.handleError(error)
        });
    }, 500);
  }

  getIntialTickets(data, admin) {
    this.rotating = true;
    this.dashBoardService
      .getTicketsChannel({
        mail: this.userData["mail"],
        tickets: "mygroups",
        admin: admin,
        companyId: data,
      })
      .subscribe((data) => {
        this.tickets = data;
        this.rotating = false;
      }, (error) => {
        this.handleError.handleError(error)
      });
    this.dashBoardService
      .getAllDashboardInformation(this.userData["mail"], admin, data)
      .subscribe((data) => {
        this.dashboard = data;
      }, (error) => {
        this.handleError.handleError(error)
      });
  }

  getTicketsChannel(data: string) {
    this.rotating = true;
    // if (this.userData.companyId === 0 || this.companyAdmin) {
    return this.dashBoardService
      .getTicketsChannel({
        mail: this.userData["mail"],
        tickets: data,
        admin: this.companyAdmin,
        companyId: this.companyId,
      })
      .subscribe((data) => {
        this.tickets = data;
        this.rotating = false;
      }, (error) => {
        this.handleError.handleError(error)
      });
  }

  initWorker(res) {
    this.workerJS = new Twilio.TaskRouter.Worker(JSON.parse(res).tokens.worker);
    if (this.workerJS) {
      this.workerJS.on("ready", (worker) => {


        // $scope.worker = worker;
      });

      this.workerJS.on("reservation.created", (reservation) => {
        alert("TaskRouter Worker: reservation.created");
        this.reservation = reservation;
        // $scope.$broadcast('InitializeReservation', { reservation: reservation });
      });
      this.workerJS.on("activity.update", (w) => {


        this.worker = w;
        // this.$apply();
      });
      // this.toggleIsOnQueue();
    }
    this.workerJS.on("reservation.accepted", (reservation) => {

      this.task = reservation.task;

      /* check if the customer name is a phone number */
      var pattern = /(.*)(\+[0-9]{8,20})(.*)$/;

      if (pattern.test(this.task.attributes.name) === true) {
        this.task.attributes.nameIsPhoneNumber = true;
      }

      // $scope.$broadcast('DestroyReservation');
    });

    this.workerJS.on("reservation.timeout", (reservation) => {
      // this.resetWorkspace();
    });

    // this.workerJS.on('reservation.rescinded', function (reservation) {
    //   
    //   this.resetWorkspace();
    // });

    this.workerJS.on("reservation.canceled", function (reservation) {
      // this.resetWorkspace();
    });

    this.workerJS.on("reservation.rejected", function (reservation) {
      // this.resetWorkspace();
    });

    this.workerJS.on("task.completed", function (reservation) {
      // this.resetWorkspace();
    });

    // this.workerJS.on('token.expired', function () {
    //   

    //   /* the worker token expired, the agent shoud log in again, token is generated upon log in */
    //   // window.location.replace('/callcenter/');
    // });

    // /* the agent's browser conntected to Twilio */
    this.workerJS.on("connected", function () {
      // this.UI.warning.worker = null;
      // this.$apply();
    });

    // /* the agent's browser lost the connection to Twilio */
    // this.workerJS.on('disconnected', function () {
    //   console.error('TaskRouter Worker: WebSocket has disconnected');
    //   // this.UI.warning.worker = 'TaskRouter Worker: WebSocket has disconnected';
    //   // this.$apply();
    // });

    // this.workerJS.on('error', function (error) {
    //   console.error('TaskRouter Worker: an error occurred: ' + error.response + ' with message: ' + error.message);
    //   // this.UI.warning.worker = 'TaskRouter Worker: an error occured: ' + error.response + ' with message: ' + error.message;
    //   // this.$apply();
    // });
  }
  InitializePhone(data) {
    Twilio.Device.setup(data.token, {
      debug: true,
      codecPreferences: ["opus", "pcmu"],
    });

    Twilio.Device.ready((device) => {
      // $scope.debug = 'Ready';
      // $timeout(function () {
      // 	$scope.$apply();
      // });
    });

    Twilio.Device.error((error) => {
      // $scope.debug = 'error: ' + error.code + ' - ' + error.message;
      // $scope.reset();
    });

    Twilio.Device.connect((connection) => {
      this.connection = connection;
      // $scope.debug = 'successfully established call';
      // $scope.UI.state = 'busy';

      // $scope.registerConnectionHandler($scope.connection);

      // $timeout(function () {
      // 	$scope.$apply();
      // });
    });

    Twilio.Device.disconnect(function (connection) {
      // $scope.debug = 'call disconnected';
      // $scope.reset();
    });

    Twilio.Device.offline(function (device) {
      // $scope.debug = 'offline';
      // $scope.reset();
    });

    Twilio.Device.incoming((connection) => {
      // $scope.debug = 'incoming connection from ' + connection.parameters.From;
      // $scope.UI.state = 'busy';
      this.connection = connection;
      this.direction = "inbound";
      this.phoneNumber = connection.parameters.From;

      connection.accept();

      connection.disconnect(function (connection) {
        // $scope.debug = 'call has ended';
        // $scope.reset();
      });

      // $scope.registerConnectionHandler($scope.connection);
    });
  }
  accept(reservation) {

    /* depending on the typ of taks that was created we handle the reservation differently */

    if (reservation.task.attributes.channel === "phone") {
      reservation.conference(
        this.configuration.configuration.twilio.callerId,
        null,
        null,
        null,
        function (error, reservation) {
          if (error) {
            console.error(error);
            return;
          }
        },
        {
          EndConferenceOnExit: true,
          EndConferenceOnCustomerExit: true,
          ConferenceRecord:
            this.configuration.configuration.twilio.voice.recording,
        }
      );
    }

    /* we accept the reservation and initiate a call to the customer's phone number */
    // if (reservation.task.attributes.channel === 'callback') {

    // 	reservation.accept(

    // 		function (error, reservation) {

    // 			if (error) {
    // 				console.error(error);
    // 				return;
    // 			}

    // 			$rootScope.$broadcast('CallPhoneNumber', { phoneNumber: reservation.task.attributes.phone });

    // 		});
    // }
  }
  getTickets(page, pageSize, sortBy) {
    this.dashBoardService
      .getTickets(page, pageSize, sortBy)
      .then((res) => {
        this.ticketsList = res;
      })
      .catch((err) => { });
  }
  answerCall() {

    this.dashBoardService
      .answerCall(this.callData.data.CallSid)
      .then((res) => {
      })
      .catch((err) => { });
  }
  Phonecall() {
    if (this.connection == null) {
      const params = { To: this.phoneNumber, IsRecord: false };
      this.connection = Twilio.Device.connect(params);
    } else {
      this.connection = null;
      Twilio.Device.disconnectAll();
    }
  }
  getTicketsSummary() {
    this.dashBoardService
      .getTicketsSummary()
      .then((res) => {
        this.tickSummary = res;
      })
      .catch((err) => { });
  }

  sortOrder(feildName) {
    this.orderFeild = feildName;
    this.order = !this.order;
    if (this.order) {
      this.orderString = "asc";
    } else {
      this.orderString = "desc";
    }
  }
  projectChange(data) {
    this.companyId = +data;
    if (data) {
      this.sharedService
        .getProjectAdmin(this.companyId)
        .subscribe((admin) => {
          this.companyAdmin = admin;
          this.getIntialTickets(this.companyId, this.companyAdmin);
        }, (error) => {
          this.handleError.handleError(error)
        });
    }
  }
}
