<div style="margin-top: 22px;">
    <div class="dropdown_list">
        <div>

        </div>
        <button type="button" class="btn-updated" style="background-color: #fff;"
            (click)="showDropDown=!showDropDown">Updated At
        </button>

        <div class="drop-show" *ngIf="showDropDown" style="width: 374px;">
            <div class="more_dropdown_list">
                <div class="inRange_section">
                    <label class="inRange_section_label">
                        <input class="inRange_section_input" type="radio" name="mode" value="within_the_last"
                            (click)="onClick(1,'within_the_last')">
                        <span>Within the last</span>
                    </label>
                    <form [formGroup]="withForm">
                        <div class="d-flex align-items-center" *ngIf="tab == 'tab1'" style="display: flex;
                    margin-left: 15px;
                    margin-right: -4px;
                    margin-top: 6px;
                    -webkit-box-align: center;
                    align-items: center;">
                            <div class="within_main_div">
                                <div class="within_main_subdiv">
                                    <input type="text" formControlName="inputVal" placeholder=""
                                        class="mr-2 within_main_input" />
                                </div>
                            </div>
                            <div>
                                <div class="within_select_div">
                                    <select class="within_select" formControlName="selectVal">
                                        <option value="minutes">Minutes</option>
                                        <option value="hours">Hours</option>
                                        <option value="days">Days</option>
                                        <option value="weeks">Weeks</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </form>

                </div>
                <div class="inRange_section">
                    <label class="inRange_section_label">
                        <input class="inRange_section_input" type="radio" name="mode" value="more_than"
                            (click)="onClick(2,'more_than')">
                        <span>More than</span>
                    </label>
                    <form [formGroup]="morethanForm">
                        <div class="d-flex align-items-center" *ngIf="tab == 'tab2'" style="display: flex;
                    margin-left: 15px;
                    margin-right: -4px;
                    margin-top: 6px;
                    -webkit-box-align: center;
                    align-items: center;">
                            <div class="within_main_div">
                                <div class="within_main_subdiv">
                                    <input type="text" formControlName="inputVal" placeholder=""
                                        class="mr-2 within_main_input" />
                                </div>
                            </div>
                            <div>
                                <div class="within_select_div">
                                    <select class="within_select" formControlName="selectVal">
                                        <option value="minutes" selected>Minutes</option>
                                        <option value="hours">Hours</option>
                                        <option value="days">Days</option>
                                        <option value="weeks">Weeks</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="inRange_section">
                    <label class="inRange_section_label">
                        <input class="inRange_section_input" type="radio" name="mode" value="in_between"
                            (click)="onClick(3,'in_between')">
                        <span>Between</span>
                    </label>
                    <div *ngIf="tab == 'tab3'">
                        <form [formGroup]="betweenFrom">
                            <div class="between_main_div">
                                <div class="between_sub_div">
                                    <div class="input-group between_input">
                                        <input class="form-control between_input_type" formControlName="inputVal1"
                                            placeholder="dd/mm/yyyy" name="inputVal1" ngbDatepicker
                                            #inputVal1="ngbDatepicker">
                                        <button class="btn btn-outline-secondary calendar between_calender_button"
                                            (click)="inputVal1.toggle()" type="button"></button>
                                    </div>
                                </div>
                                <div class="between_and">and</div>
                                <div class="between_sub_div">
                                    <div class="input-group between_input">
                                        <input class="form-control between_input_type" formControlName="inputVal2"
                                            placeholder="dd/mm/yyyy" name="inputVal2" ngbDatepicker
                                            #inputVal2="ngbDatepicker">
                                        <button class="btn btn-outline-secondary calendar between_calender_button"
                                            (click)="inputVal2.toggle()" type="button"></button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <hr>
                <div class="d-flex my-1 justify-content-end">
                    <button type="button" class="btn btn-primary btn-orange mr-3" style="background-color: #f07a52;"
                        (click)="save()">Save
                    </button>
                    <button type="button" class="btn btn-primary btn-cancel" style="background-color: #aaa;"
                        (click)="cancel()">Cancel
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>