<carousel [cellsToScroll]="5" style="padding-left: 70px; z-index: unset;" class="fixheight" *ngIf="!loading">
  <div class="carousel-cell">
    <div class="mr-3">
      <div class="db-block w-175" (click)="showCards('total');onClick(1)"
        [ngClass]="{ 'db-block-active': tab === 'tab1' }">
        <div class="p-2">
          <div class="d-flex flex-column align-items-center justify-content-center">
            <div class="img-circle">
              <img src="../../../../assets/img/total_applications.svg" class="img10" style="width: 10px;" />
            </div>
            <div class="f20 text-center mt-3">
              {{records1?.TopBarCount?.total}}
            </div>
            <div class="box-sub-text1 mt-2">Total</div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="carousel-cell">
    <div class="mr-3">
      <div class="db-block w-175" (click)="showCards('assignedtoMe');onClick(2)"
        [ngClass]="{ 'db-block-active': tab === 'tab2' }">
        <div class="p-2">
          <div class="d-flex flex-column align-items-center justify-content-center">
            <div class="img-circle">
              <img src="../../../../assets/img/total_applications.svg" class="img10" style="width: 10px;" />
            </div>
            <div class="f20 text-center mt-3">
              {{records1?.TopBarCount?.assignedtoMe}}
            </div>
            <div class="box-sub-text1 mt-2">Assigned to Me</div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="carousel-cell" *ngFor="let map of columnMaps;let id = index"
    [ngStyle]="{'display': map?.view === false?'none':''}">
    <div class="mr-3">
      <div class="db-block w-175" (click)="showCards(map.primaryKey);onClick(id+3)"
        [ngClass]="{ 'db-block-active': tab === 'tab'+(id+3) }">
        <div class="p-2">
          <div class="d-flex flex-column align-items-center justify-content-center">
            <div class="img-circle">
              <img src="../../../../assets/img/total_applications.svg" class="img10" style="width: 10px;" />
            </div>
            <div class="f20 text-center mt-3">
              {{ getCount(map?.primaryKey) }}
            </div>
            <div class="box-sub-text1 mt-2">{{ map?.header }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</carousel>
<carousel [cellsToScroll]="5" style="padding-left: 70px; z-index: unset;" class="fixheight" *ngIf="loading">
  <div class="carousel-cell" *ngFor="let item of [0,1,2,3,4,5]">
    <div class="mr-3">
      <div class="db-block w-175">
        <div class="p-2">
          <ngx-skeleton-loader [theme]="'light'" appearance="circle" [animation]="animation">
          </ngx-skeleton-loader>
          <ngx-skeleton-loader [theme]="'light'" height="20px" [appearance]="appearance" [animation]="animation">
          </ngx-skeleton-loader>
        </div>
      </div>
    </div>
  </div>
</carousel>