
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-dialogs1',
  templateUrl: './dialogs1.component.html',
  styleUrls: ['./dialogs1.component.css']
})
export class Dialogs1Component implements OnInit {
  public title: string;
  public header: string;
  public message: string;
  public imgSrc: string;
  public timeout: number;
  constructor(private dialogRef: MatDialogRef<Dialogs1Component>,
    @Inject(MAT_DIALOG_DATA) data: any,) {
    if (data) {
      this.imgSrc = data.imgSrc;
      this.title = data.title;
      this.header = data.header;
      this.message = data.message;
      this.timeout = data.timeout;
    }
  }

  ngOnInit(): void {
    //   setTimeout(() => {
    //       this.dialogRef.close(true);
    //   }, this.timeout);
  }
  closeDialog() {
    console.log("in dialog close ")
    this.dialogRef.close(true);
  }
}
