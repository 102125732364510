<div class="mt-4 mx-4">
  <a href="#" class="btn btn-primary btn-sm d-flex align-items-center justify-content-center" style="width:125px">
    <div class="mr-2"><img src="../../../assets/images/left-arrow-w.svg" class="img6" /></div>
    <div>Back to Home</div>
  </a>
</div>
<div class="mt-4 mx-4 mb-2 d-flex">
  <div *ngIf="projectList?.length>0">
    <!-- <label class="form-label1">Projects</label> -->
    <select (ngModelChange)="projectChanged($event)" [(ngModel)]="projectId" class="select-company"
      style="text-transform: capitalize; width:180px;">
      <option value="0" disabled>
        Select Project
      </option>
      <!-- <option value="All">
        All
      </option> -->
      <option *ngFor="let item of projectList" [value]="item['projectId']">
        {{ item["projectName"] }}
      </option>
    </select>
  </div>

  <div class="ml-auto">
    <button *ngIf="isAdminForProject" ngbTooltip="Create Agent" placement="top" tooltipClass="my-custom-class"
      class="btn btn-light btn-sm mr-2" (click)="open(content)">
      <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22">
        <g id="Group_764" data-name="Group 764" transform="translate(-286.561 -0.829)">
          <path id="Path_869" data-name="Path 869" class="gsvg"
            d="M302.855,15.28V8.829h-1.843V15.28h-6.451v1.843h6.451v6.451h1.843V17.123h6.451V15.28Z"
            transform="translate(-4.373 -4.373)" fill="#b02022" />
          <rect id="Rectangle_901" data-name="Rectangle 901" width="22" height="22" transform="translate(286.561 0.829)"
            fill="none" />
        </g>
      </svg>
    </button>

    <a class="btn btn-light btn-sm" ngbTooltip="Export Details" placement="top" tooltipClass="my-custom-class"
      (click)="exportList()">
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 22 22">
        <g id="Group_775" data-name="Group 775" transform="translate(-171.243 -61.66)">
          <path id="Path_886" class="gsvg" data-name="Path 886"
            d="M177.243,76.419l.953.953,5.131-5.124V84.531h1.352V72.249l5.131,5.124.953-.953L184,69.66Z"
            transform="translate(-1.944 -2.592)" />
          <path id="Path_887" class="gsvg" data-name="Path 887"
            d="M176.595,67.716v-2.7h13.519v2.7h1.352v-2.7a1.352,1.352,0,0,0-1.352-1.352H176.595a1.352,1.352,0,0,0-1.352,1.352v2.7Z"
            transform="translate(-1.296 -0.648)" />
          <rect id="_Transparent_Rectangle_" data-name=" Transparent Rectangle " width="22" height="22"
            transform="translate(171.243 61.66)" fill="none" />
        </g>
      </svg>
    </a>
  </div>
</div>

<div class="containers">
  <div class="content-containerx">
    <div>
      <span class="searchall-parent"> </span>
    </div>

    <div class="table-scroll data-table" style="background:#fff">
      <table class="table table-md">
        <thead>
          <tr>
            <td colspan="7" style="padding: 8px">
              <div class="table-search-content">
                <div class="indived-search" style="position: relative">
                  <input type="text" class="form-control search-all" [(ngModel)]="search" placeholder="Search" />
                </div>
                <div class="pagenation-right">
                  <ngb-pagination [(page)]="page" [pageSize]="pageSize" [collectionSize]="agents.length"
                    class="pagenation-right"></ngb-pagination>
                </div>
              </div>
            </td>
          </tr>
        </thead>
        <tbody>
          <tr style="background: #eaf5fd; border-bottom: none">
            <!-- <th>User id</th> -->

            <th>
              <span>
                Agent Name
                <img class="img7" src="../../../../assets/img/sort-data.svg" style="cursor: pointer"
                  (click)="sortOrder('FirstName')" />
              </span>
            </th>
            <th>
              <span>
                Role
              </span>
            </th>
            <th>
              <span>
                Mail id
                <img class="img7" src="../../../../assets/img/sort-data.svg" style="cursor: pointer"
                  (click)="sortOrder('Mailid')" />
              </span>
            </th>
            <th>
              <span>
                Status
                <img class="img7" src="../../../../assets/img/sort-data.svg" style="cursor: pointer"
                  (click)="sortOrder('ActiveStatus')" />
              </span>
            </th>
            <th>
              <span>
                Created Date
                <img class="img7" src="../../../../assets/img/sort-data.svg" style="cursor: pointer"
                  (click)="sortOrder('CreatedDate')" />
              </span>
            </th>
            <th>
              <span>
                Updated Date
                <img class="img7" src="../../../../assets/img/sort-data.svg" style="cursor: pointer"
                  (click)="sortOrder('UpdatedDate')" />
              </span>
            </th>
            <th *ngIf="isAdminForProject">Action</th>
          </tr>

          <tr *ngFor="
              let item of agents
                | filter: search
                | slice: (page - 1) * pageSize:page * pageSize
                | sort: orderFeild:orderString
            ">
            <td class="a-link vm">
              <div class="d-flex align-items-center">
                <div class="icon-frame mr-2">
                  <img src="../../../assets/img/user.svg" class="img24" />
                </div>
                <div>{{ item["FirstName"] + " " + item["LastName"] }}</div>
              </div>
            </td>
            <td class="vm">
              {{ item["userRole"]}}
            </td>
            <td class="vm">
              {{ item["Mailid"] }}
            </td>
            <td class="vm">
              <div [ngClass]="{
                  'status-open': item['ActiveStatus'] === 'true',
                  'status-inprogress': item['ActiveStatus'] === 'false'
                }">
                {{ item["ActiveStatus"] === "true" ? "Active" : "In Active" }}
              </div>
            </td>
            <td class="vm">{{ item["CreatedDate"] | date: "dd/MM/yy, hh:mm a" }}</td>
            <td class="vm">{{ item["UpdatedDate"] | date: "dd/MM/yy, hh:mm a" }}</td>
            <td *ngIf="isAdminForProject" class="vm">
              <a *ngIf="item.ActiveStatus == 'true'" style="cursor: pointer;margin-right: 5px;"
                [ngStyle]="{'pointer-events': item['Mailid']!=userData.mail ? 'auto' : 'none'}"
                (click)="activeInactiveUser(content1,item,'1')" class="text-danger">Inactive</a>
              <a *ngIf="item.ActiveStatus == 'false'" style="cursor: pointer;margin-right: 5px;"
                [ngStyle]="{'pointer-events': item['Mailid']!=userData.mail ? 'auto' : 'none'}"
                (click)="activeInactiveUser(content1,item,'2')" class="text-success">Active</a>
              <!-- <mat-slide-toggle (change)="activeInactiveUser(item,$event)" [checked]="item.userStatus" class="mr-3"></mat-slide-toggle> -->
              <img ngbTooltip="Delete" placement="top" tooltipClass="my-custom-class" class="img7"
                src="../../../../assets/img/delete.svg" style="cursor: pointer; width: 13px"
                (click)="updateUserStatus(2,item)" />
            </td>
          </tr>
          <tr *ngIf="isLoading ==true">
            <td colspan="6">
              <div class="norecords">
                <img src="../../../../assets/img/waiting.gif" />

                <div>Loading Data<br />Please wait while we fetch the data for you</div>
              </div>
            </td>
          </tr>
          <tr *ngIf="noRecords == true">
            <td colspan="6">
              <div class="norecords">
                <img src="../../../../assets/img/nodata.svg" />
                <div>No records found.<br />Please select a different date range.</div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
<ng-template #content let-modal>

  <div class="modal-header">
    <h5 class="modal-title" id="exampleModalLabel">
      Invite Users to PROJECT
    </h5>
    <button type="button" #closeInviteButton id="closeInviteButton" class="close" aria-label="Close"
      (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true" style="font-size: 20px;">&times;</span>
    </button>

  </div>
  <div class="modal-body">

    <div class="form-row ml-4">
      <div class="col-md-8">
        <p class="sub-text-1 pt-1 pb-3">
          Invite teammates to collaborate and use products in your
          organisation. We’ll ask new users to enter their personal details
          when they sign up.
        </p>
        <label for="inputState">Email address</label>

        <div class="modal-body" style="height: none;">
          <div class="px-2">
            <input type="file" style="display: inline-block;" (change)="incomingfile($event)" placeholder="Upload file"
              accept=".xlsx">
            <button type="button" class="btn btn-info" (click)="Upload()">Invite</button>
          </div>
        </div>

        <form [formGroup]="rulesForm">
          <mat-form-field>
            <mat-chip-list #chipList formArrayName="emails">
              <mat-chip [color]="(item.invalid)?'warn':''" selected *ngFor="let item of emailList; let i=index"
                [selectable]="true" [removable]="removable" (removed)="removeEmail(item)" required name="chips">
                {{item.value}}
                <mat-icon matChipRemove *ngIf="removable">X</mat-icon>
              </mat-chip>


              <input placeholder="enter item" class="form-control" type="email" matInput [matChipInputFor]="chipList"
                [matChipInputSeparatorKeyCodes]="separatorKeysCodes " [matChipInputAddOnBlur]="true "
                (matChipInputTokenEnd)="add($event) " />
            </mat-chip-list>
          </mat-form-field>
          <!-- <mat-error *ngIf="rulesForm.get('emails').hasError('incorrectEmail')">Invalid email ID</mat-error> -->

        </form>

        <p class="sub-text-2 pt-2 pb-4">
          Separate emails using a comma. Note, we can’t send invitations to
          distribution lists.
        </p>
      </div>
    </div>

    <div class="form-row ml-4">
      <div class="col-md-8">
        <div>
          <table class="table table-condensed table-hover">
            <tr>
              <th>Project</th>
              <th>Project Role</th>
            </tr>
            <tr>
              <td>
                {{selectProjectDetails.projectName}}
              </td>
              <td rowspan="1" class="border-left">
                <mat-radio-group aria-label="Select an option" [(ngModel)]="Role" #role="ngModel">

                  <section class="example-section drop-section">
                    <mat-radio-button value="admin">Administrator</mat-radio-button>
                    <p class="ml-4 mb-2">
                      Can access the product, with no product admin
                      permissions
                    </p>
                  </section>
                  <section class="example-section drop-section">
                    <mat-radio-button value="agent">Agent</mat-radio-button>
                    <p class="ml-4 mb-2">
                      Can access the product, with no product admin
                      permissions
                    </p>
                  </section>
                  <section class="example-section drop-section" *ngIf="projectType">
                    <mat-radio-button value="counsellor">Counsellor</mat-radio-button>
                    <p class="ml-4 mb-2">
                      Can access the product, with no product admin
                      permissions
                    </p>
                  </section>
                  <section class="example-section drop-section" *ngIf="projectType">
                    <mat-radio-button value="administrator specialist">Administrator Specialist</mat-radio-button>
                    <p class="ml-4 mb-2">
                      Can access the product, with no product admin
                      permissions
                    </p>
                  </section>
                </mat-radio-group>
              </td>
            </tr>
          </table>
        </div>
        <div class="d-flex align-items-center justify-content-center p-3">
          <button class="btn btn-secondary btn-sm mr-2" [disabled]="emailList && emailList.length==0"
            (click)="inviteAgents()" type="button">
            Invite
          </button>

          <div class="btn btn-light btn-sm">Cancel</div>
        </div>
      </div>
    </div>

    <div class="float-right">

      <button class="btn btn-secondary btn-sm" type="button">
        Done
      </button>
    </div>
  </div>


</ng-template>
<!-- <ng-template #content let-modal>
    

  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">
          {{ isUpdate ? "Update" : "Invite" }} Agent
        </h5>
        <button type="button" #closeInviteButton id="closeInviteButton" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
        </button>
       
      </div>
      <div class="modal-body" style="height: none;">
       
           <ngb-alert type="danger" *ngIf="iserror" [dismissible]="false">{{
            error
          }}</ngb-alert>
          <form (ngSubmit)="submit(form)" #form="ngForm">
            <div class="row nomar0">
              <div class="col pl0 pb-2">
                <label for="firstname" class="form-label">First Name <span style="color: red">*</span></label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="First name"
                  name="firstname"
                  [(ngModel)]="FirstName"
                  required
                  max="35"
                  #first_name="ngModel"
                  [ngClass]="{
                    'is-invalid': !first_name.valid && form.submitted,
                    'is-valid': first_name.valid && first_name.touched
                  }"
                />
                <div
                  class="invalid-feedback"
                  *ngIf="!first_name.valid && form.submitted"
                >
                  please enter a valid name
                </div>
              </div>
              <div class="col pr0 pb-2">
                <label for="lastname" class="form-label">Last Name <span style="color: red">*</span></label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Last name"
                  name="lastname"
                  [(ngModel)]="LastName"
                  required
                  max="35"
                  #last_name="ngModel"
                  [ngClass]="{
                    'is-invalid': !last_name.valid && form.submitted,
                    'is-valid': last_name.valid && last_name.touched
                  }"
                />
                <div
                  class="invalid-feedback"
                  *ngIf="!last_name.valid && form.submitted"
                >
                  please enter a valid name
                </div>
              </div>
            </div>
            <div class="row nomar0">
              <div class="col pl0 pb-2">
                <label for="email" class="form-label mt-2">Mail Id <span style="color: red">*</span></label>
                <input
                  type="email"
                  class="form-control"
                  placeholder="please enter a valid email"
                  name="email"
                  [disabled]="isUpdate"
                  [(ngModel)]="Mailid"
                  required
                  email
                  #userMail="ngModel"
                  [ngClass]="{
                    'is-invalid': !userMail.valid && form.submitted && !isUpdate,
                    'is-valid': userMail.valid && userMail.touched
                  }"
                />
                <div
                  class="invalid-feedback"
                  *ngIf="!userMail.valid && form.submitted && isUpdate"
                >
                  {{ "please enter a valid email" | titlecase }}
                </div>
              </div>
              <div class="col pr0 pb-2">
                <label for="number" class="form-label mt-2">Phone Number <span style="color: red">*</span></label>
                <div class="input-group">
                  <div class="input-group-prepend" *ngIf="!isUpdate">
                    <button
                      type="button"
                      class="btn btn-default dropdown-toggle ct-code"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      +<span class="country-code">{{ selectedCountryCode }}</span>
                    </button>

                    <ul class="dropdown-menu" style="height: 10rem; overflow: auto">
                      <li
                        *ngFor="let country of countries"
                        class="my-1"
                        style="
                          cursor: pointer;
                          padding: 6px 10px;
                          cursor: pointer;
                          font-size: 13px;
                          color: #747474 !important;
                        "
                      >
                        <span href="#" (click)="selectCountry(country)">
                          <div class="flag flag-{{ country.cc }} mx-1"></div>
                          <span>{{ country.name }} (+{{ country.code }})</span>
                        </span>
                      </li>
                    </ul>
                  </div>
                  <input
                    type="tel"
                    class="form-control"
                    placeholder="please enter a valid phone number"
                    id="number"
                    name="number"
                    pattern="[- +()0-9]+"
                    required
                    #phone_number="ngModel"
                    [(ngModel)]="PhoneNumber"
                    [ngClass]="{
                      'is-invalid': !phone_number.valid && form.submitted,
                      'is-valid': phone_number.valid && phone_number.touched
                    }"
                  />
                  <div
                    class="invalid-feedback"
                    *ngIf="!phone_number.valid && form.submitted"
                  >
                    please enter a valid phone number
                  </div>
                </div>
              </div>
            </div>
            <div class="row nomar0">
              <div class="col pl0 pb-2">
                <label for="role" class="form-label">Select Role <span style="color: red">*</span></label>

                <mat-radio-group
                aria-label="Select an option"
                  name="role"
                  [(ngModel)]="Role"  #role="ngModel"
              >
                <section class="example-section drop-section">
                  <mat-radio-button value="agent">Agent</mat-radio-button>
                </section>
                <section class="example-section drop-section">
                  <mat-radio-button value="admin">Administrator</mat-radio-button>
                </section>
                <section class="example-section drop-section" *ngIf="projectType">
                  <mat-radio-button value="counsellor">Counsellor</mat-radio-button>
                </section>
                <section class="example-section drop-section" *ngIf="projectType">
                  <mat-radio-button value="administrator specialist">Administrator Specialist</mat-radio-button>
                </section>
              </mat-radio-group>
              </div>
           
            </div>

            

            <div class="float-right pb-2 mt-3">
              <button type="submit" class="btn btn-primary btn-sm">
                {{ isUpdate ? "Update" : "Invite" }}
              </button>
              <button
                type="button"
                class="btn btn-default btn-sm"
                (click)="cancel()"
                style="margin-right: 10px"
              >
                {{ isUpdate ? "cancel" : "Cancel" }}
              </button>
            </div>
          </form> 
      </div>
    </div>
  </div>
</ng-template> -->

<ng-template #content1 let-modal>

  <div class="modal-header">
    <h5 class="modal-title" id="exampleModalLabel">
      {{selecteduserStatus=='1'?'Inactive':'Active'}} user
    </h5>
    <button type="button" #closeInviteButton id="closeInviteButton" class="close" aria-label="Close"
      (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true" style="font-size: 20px;">&times;</span>
    </button>
    <!-- <button type="button" id="closeAssignButton" #closeAssignButton (click)="closeAssignModal()" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button> -->
  </div>
  <div class="modal-body">
    <p>Are you sure you want to {{selecteduserStatus=='1'?'Inactive':'Active'}} this user ?</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="updateUserStatus(1)">Yes</button>
    <button type="button" class="btn btn-secondary" data-dismiss="modal">No</button>
  </div>

</ng-template>


<!-- backdrop for create agent  -->
<div class="fixed-footer">Powered by <a href="https://avantao.com/#/" target="_blank">Avantao</a></div>