import { catchError, switchMap, map } from "rxjs/operators";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { SharedService } from "./../../shared/shared.service";
import { MarketingService } from "./../marketing/marketing.service";
import { CompanyModule, InputModule } from "./models/companymodule.model";
import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import {
  NgbModal,
  ModalDismissReasons,
  NgbTimeStruct,
} from "@ng-bootstrap/ng-bootstrap";
import { BehaviorSubject, Observable, Subject, Subscription } from "rxjs";
import { MailserviceService } from "../mailservice.service";
import { ProjectchangeService } from "src/app/layout/project-change/projectchange.service";
import { ErrorHandlerService } from "src/app/error-handler.service";
// import { map } from 'lodash';

@Component({
  selector: "app-companymodules",
  templateUrl: "./companymodules.component.html",
  styleUrls: ["./companymodules.component.css"],
})
export class CompanymodulesComponent implements OnInit, OnDestroy {
  public projectId: any;
  public updateModules: CompanyModule[] = [];
  public userData: any;
  public update: boolean = false;
  public order: boolean = false;
  public orderFeild: string = "UpdatedDate";
  public orderString: boolean | "asc" | "desc" = "desc";
  public search: string;
  public page: any = 1;
  public pageSize: any = 8;
  closeResult: string;
  public submitted: boolean = false;
  public isError: boolean = false;
  public Module: string = "";
  public feedBackCallId: string = "";
  public feedBackSMSId: string = "";
  public smsId: string;
  public callId: string;
  public moduleCall: boolean = false;
  public moduleSms: boolean = false;
  public moduleMail: boolean = false;
  public smsUpdate: boolean = false;
  public callUpdate: boolean = false;
  public mailUpdate: boolean = false;
  public selectedModuleId: string = "";
  public marketingTemplates: any[] = [];
  public project: Subscription;

  moduleForm = this.fb.group({
    Module: ["", Validators.required],
    description: ["", Validators.required],
    RedialThreshold: ["", Validators.required],
    CallTextHeader: ["", Validators.required],
    CallType: ["", Validators.required],
    Ivr: this.fb.array([]),
    CallTextFooter: [""],
    time: [{ hour: "00", minute: "15", second: "30" }],
  });

  smsForm = this.fb.group({
    smsTextHeader: ["", Validators.required],
    SMSType: ["", Validators.required],
    Ivr: this.fb.array([]),
    smsTextFooter: [""],
    time: [{ hour: 0o0, minute: 15, second: 30 }],
  });

  mailForm = this.fb.group({
    mailSubject: ["", Validators.required],
    mailType: ["", Validators.required],
    templateId: ["", Validators.required],
    time: [{ hour: 0o0, minute: 15, second: 30 }],
  });

  constructor(
    private readonly marketingService: MarketingService,
    private readonly sharedService: SharedService,
    private readonly modalService: NgbModal,
    private readonly fb: FormBuilder,
    private readonly mailService: MailserviceService,
    public projectchangeService: ProjectchangeService,
    private handleError: ErrorHandlerService,
  ) { }

  ngOnDestroy(): void {
    if (this.project) {
      this.project.unsubscribe();
    }
  }

  ngOnInit() {
    this.sharedService.pageEvent.emit({ pageName: "Modules" });
    this.getProjectId();
  }

  getProjectId() {
    this.userData = JSON.parse(localStorage.getItem("userData"));
    // this.sharedService.projectId.subscribe((data) => {
    //   if (data) {
    //     this.projectId = data;
    //     this.getCompanyInfoData();
    //     this.getMarketingTemplates();
    //   }
    // });
    setTimeout(() => {
      this.projectId = this.projectchangeService._projectId;
      this.getCompanyInfoData();
      this.getMarketingTemplates();
    }, 500);
  }

  getMarketingTemplates() {
    this.mailService.getMarketingTemplates(this.projectId).subscribe((data) => {
      this.marketingTemplates = data;
    }, (error) => {
      this.handleError.handleError(error)
    });
  }

  getCompanyInfoData() {
    this.marketingService
      .getCompanyModules(this.projectId)
      .subscribe((data) => {
        this.updateModules = data;
      }, (error) => {
        this.handleError.handleError(error)
      });
  }

  sortOrder(feildName) {
    this.orderFeild = feildName;
    this.order = !this.order;
    if (this.order) {
      this.orderString = "asc";
    } else {
      this.orderString = "desc";
    }
  }

  open(content) {
    if (!this.callUpdate) {
      this.addNewNumber();
      this.moduleForm.patchValue({
        time: { hour: 0o0, minute: 15, second: 30 },
      });
    }
    if (!this.smsUpdate) {
      this.smsForm.patchValue({
        time: { hour: 0o0, minute: 15, second: 30 },
      });
    }
    // this.addNewNumber();
    if (this.modalService.hasOpenModals) {
      this.modalService.open(content, { size: "md" }).result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
    }
  }

  private getDismissReason(reason: any): string {
    this.resetForm();
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }

  resetForm() {
    this.smsForm.reset();
    this.moduleForm.reset();
    this.removeAllControls();
    this.moduleForm.controls.Module.enable();
    this.update = false;
    this.submitted = false;
    this.callUpdate = false;
    this.smsUpdate = false;
    this.moduleSms = this.moduleCall = this.moduleMail = false;
    this.Module = "";
    this.feedBackCallId = null;
  }

  submit() {
    this.submitted = true;
    if (this.moduleCall) {
      if (!(this.moduleForm.status === "VALID")) {
        return;
      }
    }
    if (this.moduleSms) {
      if (!(this.smsForm.status === "VALID")) {
        return;
      }
    }
    if (this.moduleMail) {
      if (!(this.mailForm.status === "VALID")) {
        return;
      }
    }

    if (!this.update) {
      let callId = "";
      let smsId = "";
      this.callSubmit()
        .pipe(
          switchMap((data: any) => {
            callId = data["autoDialCompany"]["id"];
            return this.SMSsubmit();
          })
        )
        .pipe(
          switchMap((data) => {
            smsId = data["autoSMSCompany"]["id"];
            return this.mailSubmit();
          })
        )
        .subscribe((data) => {
          this.marketingService
            .createCompanyModule({
              name: this.moduleForm.value["Module"],
              smsModule: smsId,
              description: this.moduleForm.value["description"],
              callModule: callId,
              ProjectId: this.projectId,
              mailModule: data["autoEmailCompany"]["id"],
              createdBy: this.userData["mail"],
            })
            .subscribe(
              (data) => {
                this.modalService.dismissAll();
                this.getCompanyInfoData();
              },
              (err) => {
                this.isError = true;
                setTimeout(() => {
                  this.isError = false;
                }, 3000);
                this.handleError.handleError(err)

              }
            );
        });
    } else {
      this.SMSsubmit().subscribe((data) => {
        this.callSubmit().subscribe((data) => {
          this.mailSubmit().subscribe((data) => {
            this.modalService.dismissAll();
          });
        });
      });

      this.marketingService
        .updateModule({
          id: this.selectedModuleId,
          smsModule: null,
          callModule: null,
          mailModule: null,
          description: this.moduleForm.value["description"],
        })
        .subscribe((data) => {
          this.getCompanyInfoData();
        }, (error) => {
          this.handleError.handleError(error)
        });

      if (!this.smsUpdate && this.moduleSms) {
        let body = this.textSms();
        this.createSmsModule(body).subscribe((data) => {
          this.marketingService
            .updateModule({
              id: this.selectedModuleId,
              mailModule: null,
              callModule: null,
              smsModule: data["autoSMSCompany"]["id"],
              description: this.moduleForm.value["description"],
            })
            .subscribe((data) => {
              this.getCompanyInfoData();
            }, (error) => {
              this.handleError.handleError(error)
            });
        });
      }
      if (!this.callUpdate && this.moduleCall) {
        let body = this.textCall();
        this.createCallModule(body).subscribe((data) => {
          this.marketingService
            .updateModule({
              id: this.selectedModuleId,
              mailModule: null,
              callModule: data["autoDialCompany"]["id"],
              smsModule: null,
              description: this.moduleForm["description"],
            })
            .subscribe((data) => {
              this.getCompanyInfoData();
            }, (error) => {
              this.handleError.handleError(error)
            });
        });
      }
      if (!this.mailUpdate && this.moduleMail) {
        let body = this.textMail();
        this.createMailModule(body).subscribe((data) => {
          this.marketingService
            .updateModule({
              id: this.selectedModuleId,
              mailModule: data["autoEmailCompany"]["id"],
              callModule: null,
              smsModule: null,
              description: this.moduleForm["description"],
            })
            .subscribe((data) => {
              this.getCompanyInfoData();
            }, (error) => {
              this.handleError.handleError(error)
            });
        });
      }
    }
  }

  updateModule(content, module) {
    this.selectedModuleId = module["id"];
    this.moduleForm.patchValue({
      Module: module["Name"],
      description: module["Description"] || "",
    });
    this.update = true;
    if (module["SMSModule"]) {
      this.moduleSms = true;
      this.smsUpdate = true;
      this.marketingService
        .getSMSCompanyById(module["SMSModule"])
        .subscribe((data) => {
          let beforeArray = data["BeforeSMSTime"].split(":");
          this.Module = data["Module"];
          let time = {
            hour: +beforeArray[0],
            minute: +beforeArray[1],
            second: +beforeArray[2],
          };
          this.Module = data["Module"];
          if (data["SMSType"] !== "Feedback") {
            this.removeAllControls();
            this.smsForm.patchValue({
              smsTextHeader: data["SMSText"],
              SMSType: data["SMSType"],
              time: time,
            });
          } else {
            this.updateSMSConfigValues(data);
            // this.updateFeedBackValues(data);
          }
        }, (error) => {
          this.handleError.handleError(error)
        });
    }
    if (module["CallModule"]) {
      this.moduleCall = true;
      this.callUpdate = true;
      this.marketingService
        .getAutoDialById(module["CallModule"])
        .subscribe((data) => {
          this.updateForm(data, content);
        }, (error) => {
          this.handleError.handleError(error)
        });
    }
    if (module["MailModule"]) {
      this.mailUpdate = true;
      this.moduleMail = true;
      this.getAutoEmail(module["MailModule"]);
    }
    setTimeout(() => {
      this.open(content);
    }, 500);
  }

  updateForm(data, content) {
    let beforeArray = data["BeforeDialTime"].split(":");
    let time = {
      hour: +beforeArray[0],
      minute: +beforeArray[1],
      second: +beforeArray[2],
    };
    this.update = true;
    this.updateFeedBackValues(data);
    this.moduleForm.controls.Module.disable();
    this.Module = data["Module"];
    if (data["CallType"] !== "Feedback") {
      this.removeAllControls();
      this.moduleForm.patchValue({
        // Module: data["Module"],
        CallTextHeader: data["CallText"],
        RedialThreshold: data["RedialThreshold"],
        CallType: data["CallType"],
        time: time,
      });
    }
  }

  updateFeedBackValues(data) {
    this.removeAllControls();
    let beforeArray = data["BeforeDialTime"].split(":");
    let time = {
      hour: +beforeArray[0],
      minute: +beforeArray[1],
      second: +beforeArray[2],
    };
    if (data["CallType"] === "Feedback") {
      this.getFeedBackConfig(data, time);
    }
  }

  updateSMSConfigValues(data) {
    let beforeArray = data["BeforeSMSTime"].split(":");
    let time = {
      hour: +beforeArray[0],
      minute: +beforeArray[1],
      second: +beforeArray[2],
    };
    this.removeAllControls();
    if (data["SMSType"] === "Feedback") {
      this.marketingService
        .getSmsConfig({ id: data["id"], moduleName: data["Module"] })
        .subscribe((data1: any) => {
          if (data1) {

            this.feedBackSMSId = data1["id"];
            data1["SmsConfig"].forEach((element) => {
              this.smsConfig().push(
                this.fb.group({
                  feedBackNumber: element["feedBackNumber"],
                  value: element["value"],
                })
              );
            });

            this.smsForm.patchValue({
              smsTextHeader: data1["SmsTextHeader"],
              smsTextFooter: data1["SmsTextFooter"],
              // Module: data['Module'],
              time: time,
              // RedialThreshold: data['RedialThreshold'],
              SMSType: data["SMSType"],
            });

          } else {
            // this.addNewNumber();
            this.smsForm.patchValue({
              smsTextHeader: data["SMSText"],
              smsTextFooter: "",
              // Module: data['Module'],
              time: time,
              // RedialThreshold: data['RedialThreshold'],
              SMSType: data["SMSType"],
            });
          }
        }, (error) => {
          this.handleError.handleError(error)
        });
    }
  }

  getAutoEmail(id) {
    this.marketingService.getAutoEmailCompany(id).subscribe((data) => {
      this.moduleMail = true;
      let time = data["BeforeEmailTime"].split(":");
      this.mailForm.patchValue({
        mailSubject: data["Subject"],
        mailType: data["EmailType"],
        templateId: data["TemplateId"],
        time: { hour: +time[0], minute: +time[1], second: +time[2] },
      });
    }, (error) => {
      this.handleError.handleError(error)
    });
  }

  getFeedBackConfig(data, time) {
    this.marketingService
      .getModuleConfig({ id: data["id"], moduleName: data["Module"] })
      .subscribe((data1: any) => {
        if (data1) {
          this.feedBackCallId = data1["id"];
          this.callIvr().clear();
          data1["NumberConfig"].forEach((element) => {
            this.callIvr().push(
              this.fb.group({
                feedBackNumber: element["feedBackNumber"],
                value: element["value"],
              })
            );
          });
          this.moduleForm.patchValue({
            CallTextHeader: data1["CallTextHeader"],
            CallTextFooter: data1["CallTextFooter"],
            // Module: data["Module"],
            RedialThreshold: data["RedialThreshold"],
            CallType: data["CallType"],
            time: time,
          });

        } else {
          // this.addNewNumber();
          this.moduleForm.patchValue({
            CallTextHeader: data["CallText"],
            // CallTextFooter: data1['CallTextFooter'],
            // Module: data["Module"],
            RedialThreshold: data["RedialThreshold"],
            CallType: data["CallType"],
            time: time,
          });
        }
      }, (error) => {
        this.handleError.handleError(error)
      });
  }

  get f() {
    return this.moduleForm.controls;
  }

  callIvr(): FormArray {
    return this.moduleForm.get("Ivr") as FormArray;
  }

  smsConfig(): FormArray {
    return this.smsForm.get("Ivr") as FormArray;
  }

  newNumber(): FormGroup {
    return this.fb.group({
      feedBackNumber:
        this.callIvr().length != 0 ? this.callIvr().length - 1 + 1 : 0,
      value: "",
    });
  }

  addNewNumber() {
    this.callIvr().push(this.newNumber());
  }

  removeNumber(index: number) {
    this.callIvr().removeAt(index);
  }

  removeAllControls() {
    this.callIvr().clear();
    this.smsConfig().clear();
  }

  SMSsubmit(): Observable<any> {
    if (this.moduleSms) {
      let body = this.textSms();
      body["ProjectId"] = this.projectId;
      if (!this.smsUpdate) {
        return this.createSmsModule(body);
      } else {

        if (body["SMSType"] === "Feedback" && this.feedBackSMSId) {
          this.marketingService
            .updateSmsConfig({
              smsTextFooter: body["smsTextFooter"],
              smsTextHeader: body["smsTextFooter"],
              smsConfig: body["Ivr"],
              id: this.feedBackSMSId,
            })
            .subscribe();
        }
        return this.marketingService.updateAutoSmsConfig(body);
      }
    } else {
      return new BehaviorSubject<any>({ autoSMSCompany: { id: null } });
    }
  }

  callSubmit(): Observable<any> {
    if (this.moduleCall) {
      let body = this.textCall();
      if (!this.callUpdate) {
        return this.createCallModule(body);
      } else {
        body["Module"] = this.Module;
        if (body["CallType"] === "Feedback" && this.feedBackCallId) {
          this.marketingService
            .updateNumberConfigs({
              callTextFooter: body["CallTextFooter"],
              callTextHeader: body["CallTextHeader"],
              numberConfig: body["Ivr"],
              id: this.feedBackCallId,
            })
            .subscribe((data) => {

            }, (error) => {
              this.handleError.handleError(error)
            });
        }
        return this.marketingService.updateAutoDial(body);
      }
    } else {
      return new BehaviorSubject<any>({ autoDialCompany: { id: null } });
    }
  }

  mailSubmit(): Observable<any> {
    if (this.moduleMail) {
      let body = this.textMail();
      if (!this.mailUpdate) {
        return this.createMailModule(body);
      } else {

        return this.marketingService.updateAutoEmail(body);
      }
    } else {
      return new BehaviorSubject<any>({ autoEmailCompany: { id: null } });
    }
  }

  changeModule(type) {
    if (type === "sms") {
      this.moduleSms = !this.moduleSms;
      if (this.update && !this.feedBackSMSId) {
        this.feedBackSMSId = "100";
      }
    } else if (type === "call") {
      this.moduleCall = !this.moduleCall;
    } else if (type === "mail") {
      this.moduleMail = !this.moduleMail;
    }
  }

  createSmsModule(body): Observable<any> {
    return this.marketingService.createAutoSmsConfig(body).pipe(
      map((data: any) => {
        if (body["SMSType"] === "Feedback") {
          this.marketingService
            .createSmsConfig({
              moduleName: data["autoSMSCompany"]["Module"],
              smsTextFooter: body["smsTextFooter"],
              smsTextHeader: body["smsTextHeader"],
              ProjectId: this.projectId,
              moduleId: data["autoSMSCompany"]["id"],
              smsConfig: body["Ivr"],
            })
            .subscribe((data) => {

            }, (error) => {
              this.handleError.handleError(error)
            });
        }
        return data;
      })
    );
  }

  createMailModule(body): Observable<any> {
    return this.marketingService.createAutoEmailCompany(body);
  }

  textSms(): any {
    let body = this.smsForm.value;
    body["Module"] = this.Module || this.moduleForm.value["Module"];
    if (this.smsForm.value.SMSType !== "Feedback") {
      body["SMSText"] = this.smsForm.value["smsTextHeader"];
    } else {
      body["SMSText"] = (
        this.smsForm.value["smsTextHeader"] +
        ".  " +
        `${body["Ivr"].map(
          (data) => `Press ${data["feedBackNumber"]} for ${data["value"]}.  `
        )}` +
        this.smsForm.value["smsTextFooter"]
      ).replace(",", "");
    }
    body["BeforeSMSTime"] = `${body["time"]["hour"] < 9 ? 0 : ""}${body["time"]["hour"]
      }:${body["time"]["minute"] <= 9
        ? "0" + body["time"]["minute"]
        : body["time"]["minute"]
      }:${body["time"]["second"] <= 9
        ? "0" + body["time"]["second"]
        : body["time"]["second"]
      }`;
    return body;
  }

  textCall(): any {
    let body = this.moduleForm.value;

    body["BeforeDialTime"] = `${body["time"]["hour"] <= 9
      ? `0${body["time"]["hour"]}`
      : body["time"]["hour"]
      }:${body["time"]["minute"] <= 9
        ? "0" + body["time"]["minute"]
        : body["time"]["minute"]
      }:${body["time"]["second"] <= 9
        ? "0" + body["time"]["second"]
        : body["time"]["second"]
      }`;
    if (this.moduleForm.value.CallType !== "Feedback") {
      body["CallText"] = this.moduleForm.value["CallTextHeader"];
    } else {
      body["CallText"] = (
        this.moduleForm.value["CallTextHeader"] +
        ".  " +
        `${body["Ivr"].map(
          (data) => `Press ${data["feedBackNumber"]} for ${data["value"]}.  `
        )}` +
        this.moduleForm.value["CallTextFooter"]
      ).replace(",", "");
    }
    body["ProjectId"] = this.projectId;

    return body;
  }

  textMail() {
    let body = this.mailForm.value;
    body["ProjectId"] = this.projectId;
    body["Module"] = this.Module || this.moduleForm.value["Module"];
    body["BeforeEmailTime"] = `${body["time"]["hour"] < 9 ? 0 : ""}${body["time"]["hour"]
      }:${body["time"]["minute"] <= 9
        ? "0" + body["time"]["minute"]
        : body["time"]["minute"]
      }:${body["time"]["second"] <= 9
        ? "0" + body["time"]["second"]
        : body["time"]["second"]
      }`;
    body["Subject"] = body["mailSubject"];
    body["EmailType"] = body["mailType"];
    body["TemplateId"] = body["templateId"];
    return body;
  }

  createCallModule(body): Observable<any> {
    return this.marketingService.createAutoDialCompany(body).pipe(
      map((data: any) => {
        // this.getCompanyInfoData();
        if (data["autoDialCompany"]["CallType"] === "Feedback") {
          this.marketingService
            .createNumberConfigs({
              moduleName: data["autoDialCompany"]["Module"],
              callTextFooter: body["CallTextFooter"],
              callTextHeader: body["CallTextHeader"],
              ProjectId: this.projectId,
              moduleId: data["autoDialCompany"]["id"],
              numberConfig: body["Ivr"],
            })
            .subscribe((data) => { }, (error) => {
              this.handleError.handleError(error)
            });
        }
        return data;
      })
    );
  }

  get mailGroup() {
    return this.mailForm.controls;
  }
  projectChange(data) {
    this.projectId = data;
    this.getCompanyInfoData();
    this.getMarketingTemplates();
  }
}
