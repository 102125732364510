<section class="content">
  <div class="container-fluid bg-g">
    <div class="block-header">
      <app-rorotelFilter></app-rorotelFilter>
    </div>
  </div>
</section>
<section class="content">
  <div class="container-fluid pt-4 pb-5">
    <div class="row clearfix">
      <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 right-padding">
        <div class="card chartSize">
          <div class="header d-flex align-items-center">
            <div class="header-12">Calls Trend - All Calls</div>
            <!-- <div class="ml-auto" style="cursor: pointer">
              <img src="../../../assets/img/download.svg" style="width: 16px" (click)="callsData()" />
            </div> -->
          </div>
          <div class="body" *ngIf="!callTrends;else loader">
            <ngx-charts-line-chart [view]="view" class="chart-container" [scheme]="colorScheme"
              [schemeType]="schemeType" [results]="lineChartCalls" [animations]="animations" [legend]="false"
              [legendTitle]="legendTitle" [legendPosition]="legendPosition" [gradient]="gradient" [xAxis]="showXAxis"
              [yAxis]="showYAxis" [showXAxisLabel]="false" [showYAxisLabel]="false" [xAxisLabel]="xAxisLabel"
              [yAxisLabel]="yAxisLabel" [autoScale]="autoScale" [xScaleMin]="xScaleMin" [xScaleMax]="xScaleMax"
              [yScaleMin]="yScaleMin" [yScaleMax]="yScaleMax" [timeline]="timeline" [showGridLines]="showGridLines"
              [curve]="curve" [rangeFillOpacity]="rangeFillOpacity" [roundDomains]="roundDomains"
              [tooltipDisabled]="tooltipDisabled" [trimXAxisTicks]="trimXAxisTicks" [trimYAxisTicks]="trimYAxisTicks"
              [rotateXAxisTicks]="rotateXAxisTicks" [maxXAxisTickLength]="maxXAxisTickLength"
              [maxYAxisTickLength]="maxYAxisTickLength" (select)="select($event)" (activate)="activate($event)"
              (deactivate)="deactivate($event)">
            </ngx-charts-line-chart>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 right-padding">
        <div class="card chartSize">
          <div class="header d-flex align-items-center">
            <div class="header-12">Calls Trend - All Incoming Calls</div>
            <!-- <div class="ml-auto" style="cursor: pointer">
              <img src="../../../assets/img/download.svg" style="width: 16px" (click)="callsData()" />
            </div> -->
          </div>
          <div class="body" *ngIf="!callTrendsIn;else loader">
            <ngx-charts-line-chart [view]="view" class="chart-container" [scheme]="colorScheme"
              [schemeType]="schemeType" [results]="inlineChartCalls" [animations]="animations" [legend]="false"
              [legendTitle]="legendTitle" [legendPosition]="legendPosition" [gradient]="gradient" [xAxis]="showXAxis"
              [yAxis]="showYAxis" [showXAxisLabel]="false" [showYAxisLabel]="false" [xAxisLabel]="xAxisLabel"
              [yAxisLabel]="yAxisLabel" [autoScale]="autoScale" [xScaleMin]="xScaleMin" [xScaleMax]="xScaleMax"
              [yScaleMin]="yScaleMin" [yScaleMax]="yScaleMax" [timeline]="timeline" [showGridLines]="showGridLines"
              [curve]="curve" [rangeFillOpacity]="rangeFillOpacity" [roundDomains]="roundDomains"
              [tooltipDisabled]="tooltipDisabled" [trimXAxisTicks]="trimXAxisTicks" [trimYAxisTicks]="trimYAxisTicks"
              [rotateXAxisTicks]="rotateXAxisTicks" [maxXAxisTickLength]="maxXAxisTickLength"
              [maxYAxisTickLength]="maxYAxisTickLength" (select)="select($event)" (activate)="activate($event)"
              (deactivate)="deactivate($event)" *ngIf="!callTrendsInList;else notFound">
            </ngx-charts-line-chart>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 right-padding">
        <div class="card chartSize">
          <div class="header d-flex align-items-center">
            <div class="header-12">Calls Trend - All Outgoing Calls</div>
            <!-- <div class="ml-auto" style="cursor: pointer">
              <img src="../../../assets/img/download.svg" style="width: 16px" (click)="callsData()" />
            </div> -->
          </div>
          <div class="body" *ngIf="!callTrendsOut;else loader">
            <ngx-charts-line-chart [view]="view" class="chart-container" [scheme]="colorScheme"
              [schemeType]="schemeType" [results]="outlineChartCalls" [animations]="animations" [legend]="false"
              [legendTitle]="legendTitle" [legendPosition]="legendPosition" [gradient]="gradient" [xAxis]="showXAxis"
              [yAxis]="showYAxis" [showXAxisLabel]="false" [showYAxisLabel]="false" [xAxisLabel]="xAxisLabel"
              [yAxisLabel]="yAxisLabel" [autoScale]="autoScale" [xScaleMin]="xScaleMin" [xScaleMax]="xScaleMax"
              [yScaleMin]="yScaleMin" [yScaleMax]="yScaleMax" [timeline]="timeline" [showGridLines]="showGridLines"
              [curve]="curve" [rangeFillOpacity]="rangeFillOpacity" [roundDomains]="roundDomains"
              [tooltipDisabled]="tooltipDisabled" [trimXAxisTicks]="trimXAxisTicks" [trimYAxisTicks]="trimYAxisTicks"
              [rotateXAxisTicks]="rotateXAxisTicks" [maxXAxisTickLength]="maxXAxisTickLength"
              [maxYAxisTickLength]="maxYAxisTickLength" (select)="select($event)" (activate)="activate($event)"
              (deactivate)="deactivate($event)" *ngIf="!callTrendsOutList;else notFound">
            </ngx-charts-line-chart>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 right-padding">
        <div class="card chartSize">
          <div class="header d-flex align-items-center">
            <div class="header-12">Call Status</div>
            <!-- <div class="ml-auto" style="cursor: pointer">
              <img src="../../../assets/img/download.svg" style="width: 16px" (click)="callsData()" />
            </div> -->
          </div>
          <div class="body" *ngIf="!callTrends;else loader">
            <ngx-charts-pie-chart class="chart-container" [view]="view" [scheme]="colorScheme"
              [results]="callStatusCounts" [animations]="animations" [legend]="true" [legendTitle]="legendTitle"
              [legendPosition]="legendPosition" [explodeSlices]="explodeSlices" [labels]="true" [doughnut]="doughnut"
              [arcWidth]="arcWidth" [gradient]="gradient" [tooltipDisabled]="tooltipDisabled"
              [tooltipText]="pieTooltipText" (dblclick)="dblclick($event)" (select)="select($event)"
              (activate)="activate($event)" (deactivate)="deactivate($event)">
            </ngx-charts-pie-chart>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 right-padding">
        <div class="card chartSize">
          <div class="header d-flex align-items-center">
            <div class="header-12">Calls - Incoming vs Outgoing</div>
            <!-- <div class="ml-auto" style="cursor: pointer">
              <img src="../../../assets/img/download.svg" style="width: 16px" (click)="callsData()" />
            </div> -->
          </div>
          <div class="body" *ngIf="!inoutCalls;else loader">
            <ngx-charts-pie-chart class="chart-container" [view]="view" [scheme]="colorScheme"
              [results]="incomingVsOutgoing" [animations]="animations" [legend]="true" [legendTitle]="legendTitle"
              [legendPosition]="legendPosition" [explodeSlices]="explodeSlices" [labels]="true" [doughnut]="doughnut"
              [arcWidth]="arcWidth" [gradient]="gradient" [tooltipDisabled]="tooltipDisabled"
              [tooltipText]="pieTooltipText" (dblclick)="dblclick($event)" (select)="select($event)"
              (activate)="activate($event)" (deactivate)="deactivate($event)">
            </ngx-charts-pie-chart>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 right-padding">
        <div class="card chartSize">
          <div class="header d-flex align-items-center">
            <div class="header-12">Top Performer</div>
            <!-- <div class="ml-auto" style="cursor: pointer;"><img src="../../../assets/img/download.svg" style="width:16px" (click)="callsData()"></div> -->
          </div>
          <div class="body" *ngIf="!topperformer1;else loader">
            <ngx-charts-gauge class="chart-container" [view]="view" [legend]="showLegend" [legendTitle]="legendTitle"
              [legendPosition]="legendPosition" [results]="topPerformersRorotel" [animations]="animations"
              [textValue]="gaugeTextValue" [scheme]="colorScheme" [min]="gaugeMin" [max]="gaugeMax" [units]="gaugeUnits"
              [angleSpan]="gaugeAngleSpan" [startAngle]="gaugeStartAngle" [showAxis]="gaugeShowAxis"
              [bigSegments]="gaugeLargeSegments" [smallSegments]="gaugeSmallSegments" [margin]="
                margin
                  ? [marginTop, marginRight, marginBottom, marginLeft]
                  : null
              " [tooltipDisabled]="tooltipDisabled" [showText]="showText" (select)="select($event)"
              (activate)="activate($event)" (deactivate)="deactivate($event)">
            </ngx-charts-gauge>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row clearfix"></div>
</section>
<div class="fixed-footer">
  <p>
    Powered by <a href="https://www.avantao.com/" target="_blank">Avantao</a>
  </p>
</div>
<ng-template #loader>
  <div class="norecords">
    <img src="../../../../assets/img/waiting.gif" />
  </div>
</ng-template>
<ng-template #notFound>
  <div class="norecords">
    <img src="../../../../assets/img/nodata.svg" />
    <div>No records found.<br />Try changing the filters.</div>
  </div>
</ng-template>