<button class="p-btn" id='firstPage' (click)="pagination.first()" [disabled]="pagination.isFirst">
  <div>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12" style="width:16px; height:16px;margin-top:4px">
      <g id="Group_222" data-name="Group 222" transform="translate(-722.5 -336)">
        <path id="ic_keyboard_arrow_left_24px" d="M13.6,13.5l-3.461-3.469L13.6,6.566,12.534,5.5,8,10.034l4.534,4.534Z"
          transform="translate(717 330.5)" class="csvg" />
        <path id="ic_keyboard_capslock_24px" d="M6,4.84h9v1.5H6Z" transform="translate(728.84 330) rotate(90)"
          class="csvg" />
      </g>
    </svg>
  </div>
</button>
<button class="p-btn" (click)="pagination.prev()" [disabled]="pagination.isFirst">
  <div class="img12">
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px" y="0px" viewBox="0 0 8.1 9.1" style="enable-background:new 0 0 8.1 9.1;" xml:space="preserve">
      <g id="Group_222" transform="translate(-722.5 -336)">
        <path id="ic_keyboard_arrow_left_24px" class="csvg"
          d="M729.3,344l-3.5-3.5l3.5-3.5l-1.1-1.1l-4.5,4.5l4.5,4.5L729.3,344z" />
      </g>
    </svg>
  </div>
</button>
<button class="p-btn-label" appDashPagination #pagination="appDashPagination" [totalPages]=totalPages
  (pageChange)="onPageChange($event)"><span>Page {{ pageNo }}</span></button>
<span style="color:#fff"> / {{ pagination.totalPages }}</span>

<button class="p-btn" (click)="pagination.next()" [disabled]="pagination.isLast">
  <div class="img12">
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px" y="0px" viewBox="0 0 8.1 9.1" style="enable-background:new 0 0 8.1 9.1;" xml:space="preserve">
      <g id="Group_222" transform="translate(-722.5 -336)">
        <path id="ic_keyboard_arrow_left_24px" class="csvg"
          d="M724.8,345l4.5-4.5l-4.5-4.5l-1.1,1.1l3.5,3.5l-3.5,3.5L724.8,345z" />
      </g>
    </svg>
  </div>
</button>
<button class="p-btn" (click)="pagination.last()" [disabled]="pagination.isLast">
  <div>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12" style="width:16px; height:16px;margin-top:4px">
      <g id="Group_222" transform="translate(-722.5 -336)">
        <path id="ic_keyboard_arrow_left_24px" class="csvg"
          d="M723.6,345.1l4.5-4.5l-4.5-4.5l-1.1,1.1l3.5,3.5l-3.5,3.5L723.6,345.1z" />
        <path id="ic_keyboard_capslock_24px" class="csvg" d="M730.6,336v9h-1.5v-9H730.6z" />
      </g>
    </svg>
  </div>
</button>