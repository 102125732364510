import { AgmCoreModule } from "@agm/core";
import { PagesModule } from "./pages/pages.module";
import { BrowserModule } from "@angular/platform-browser";
import { CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, NgModule } from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { LayoutModule } from "./layout/layout.module";
import { HttpClientModule } from "@angular/common/http";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { PrivacypolicyComponent } from "./privacypolicy/privacypolicy.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { library } from '@fortawesome/fontawesome-svg-core';
// import { MatIconModule } from "@angular/material";
import { faCalendar, faClock } from "@fortawesome/free-solid-svg-icons";
import { MailResponseComponent } from "./mail-response/mail-response.component";
import { BnNgIdleService } from "bn-ng-idle";
import { UhleadsComponent } from "./pages/uhleads/uhleads.component";
import { CuNextComponent } from "./pages/cu-next/cu-next.component";
import { DemoMaterialModule } from "./pages/uhleads/material.module";
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
import { ToastrModule } from "ngx-toastr";
import { MatDialogModule } from "@angular/material/dialog";
import * as $ from "jquery";
import { NgxDocViewerModule } from 'ngx-doc-viewer'
import { NgxTimerModule } from 'ngx-timer';
import { IvyCarouselModule } from 'angular-responsive-carousel';
import { SimpleNotificationsModule } from "./notificaton.module";
import { initializeApp } from 'firebase/app';
import { environment } from "src/environments/environment";
import { ActivityFilterPipe } from 'src/app/shared/activity.pipe'
import { MatIconModule } from "@angular/material/icon";
import { PaginationDirective } from "./shared/custom-pagination/pagination.directive";
import { CustomPaginationComponent } from "./shared/custom-pagination/custom-pagination.component";
import { ErrorHandlerService } from "./error-handler.service";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { EnquiryComponent } from "./pages/enquiry/enquiry.component";
import { BookademoComponent } from './bookademo/bookademo.component';
import { AutocompleteLibModule } from "angular-ng-autocomplete";
import { NavbarsModule } from "./landingpage/navbars/navbars.module";
import { ContactComponent } from './landingpage/navbars/contact/contact.component';
import { MatSelectModule } from "@angular/material/select";
import { MaterialExampleModule } from "./material.module";
import { UhubDashboardComponent } from "./pages/uhub-dashboard/uhub-dashboard.component";
import { CustomsModule } from "src/components/customs/customs.module";
import { MatTabsModule } from "@angular/material/tabs";
import { JsonFormComponent } from "./pages/components/json-form/json-form.component";
import { EditConfigDialog, EditColumnsDialog, LeadsDashboardComponent } from "./pages/leads-dashboard/leads-dashboard.component";
import { CardsComponent } from "./pages/components/cards/cards.component";
import { TableComponent } from "./pages/components/table/table.component";
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { RouteReuseStrategy } from '@angular/router';
import { CompanyHomeComponent } from "./pages/company-home/company-home.component";
import { DashPaginationComponent } from "./pages/components/dash-pagination/dash-pagination.component";
import { DashPaginationDirective } from "./pages/components/dash-pagination/dash-pagination.directive";
import { DialogElementsExampleDialog } from "./pages/components/table/table.component";
import { Dialogs1Component } from "./shared/dialogs/dialogs1/dialogs1.component";
import { NgxSkeletonLoaderModule } from "ngx-skeleton-loader";
import { AccessqueleadsComponent } from "./pages/accessqueleads/accessqueleads.component";
import { CustomPaginationuhubComponent } from "./shared/custom-paginationuhub/custom-paginationuhub.component";
import { AicallsComponent } from "./landingpage/aicalls/aicalls.component";
import { SocialMediaLeadsComponent } from "./pages/uhleads/socialmedialeads/socialmedialeads.component";
import { RecruiterLeadsComponent } from "./pages/uhleads/recruiterleads/recruiterleads.component";

initializeApp(environment.firebase);


@NgModule({
  declarations: [
    AppComponent,
    PrivacypolicyComponent,
    MailResponseComponent,
    UhleadsComponent,
    SocialMediaLeadsComponent,
    RecruiterLeadsComponent,
    AccessqueleadsComponent,
    CuNextComponent,
    UhubDashboardComponent,
    EnquiryComponent,
    ActivityFilterPipe,
    PaginationDirective,
    CustomPaginationComponent,
    BookademoComponent,
    ContactComponent,
    UhubDashboardComponent,
    LeadsDashboardComponent,
    EditConfigDialog,
    EditColumnsDialog,
    CardsComponent,
    TableComponent,
    JsonFormComponent,
    CompanyHomeComponent,
    // EditConfigDialog,
    // CardsComponent,
    // TableComponent,
    // JsonFormComponent
    // JsonFormComponent
    // RequeststatusPipe,
    // DateTimePickerComponent,
    // DatepickerComponent,
    DashPaginationComponent,
    DashPaginationDirective,
    DialogElementsExampleDialog,
    Dialogs1Component,
    CustomPaginationuhubComponent,
    AicallsComponent
  ],
  imports: [
    BrowserModule,
    LayoutModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    NavbarsModule,
    PagesModule,
    FormsModule,
    FontAwesomeModule,
    MatIconModule,
    NgbModule,
    DemoMaterialModule,
    ReactiveFormsModule,
    NgMultiSelectDropDownModule,
    SimpleNotificationsModule,
    AgmCoreModule.forRoot({
      apiKey: "AIzaSyAYClvVNn_82-k1LOLLsBTz_O12v65MhdY",
      libraries: ["places"],
    }),
    MatDialogModule,
    ToastrModule.forRoot(),
    NgxDocViewerModule,
    NgxTimerModule,
    IvyCarouselModule,
    MatSnackBarModule,
    AutocompleteLibModule,
    MaterialExampleModule,
    CustomsModule,
    MatTabsModule,
    IonicModule.forRoot(),
    IonicModule,
    NgxSkeletonLoaderModule
  ],
  providers: [BnNgIdleService, { provide: ErrorHandler, useClass: ErrorHandlerService }, { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }],
  bootstrap: [AppComponent],
  exports: [],
})
export class AppModule {
  constructor() {
    library.add(faCalendar, faClock);
  }
}
