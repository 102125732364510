import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { environment } from 'src/environments/environment';
import { HttpClientService } from 'src/app/httpclient.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LeadgenerationService {
  baseApiUrl2: any = environment.baseApiUrl2;
  reset = new BehaviorSubject<any>(null);
  pageNumber = new BehaviorSubject<any>(null);
  totalPages = new BehaviorSubject<any>(null);
  startCall = new BehaviorSubject<any>(null);
  stopCall = new BehaviorSubject<any>(null);
  getCallSid = new BehaviorSubject<any>(null);
  startTimer = new BehaviorSubject<any>(null);
  constructor(private http: HttpClientService) { }

  getCountryInfo() {
    var apiURL = `https://restcountries.com/v2/all`;
    return this.http.get(apiURL);
  }

  getLeadsByKeywords(data) {
    return this.http.post(`${this.baseApiUrl2}/getByKeywords`, data)
  }
  companyfilters(data) {
    return this.http.post(`${this.baseApiUrl2}/companyfilters`, data)
  }
  companyDetailsByDomain(data) {
    return this.http.post(`${this.baseApiUrl2}/companyDetailsByDomain`, data)
  }

  triggerSearch(data) {
    return this.http.post(`${this.baseApiUrl2}/triggerFind`, data)
  }
  listPeopleCampaigns() {
    return this.http.get(`${this.baseApiUrl2}/listPeopleCampaigns`)
  }

  readJson(path) {
    return this.http.get(path);
  }

  getPeopleByDomain(obj) {
    return this.http.post(`${this.baseApiUrl2}/getPeopleByDomain`, obj)
  }

  updateCallStatus(data) {
    return this.http.post(
      `${this.baseApiUrl2}leads/updateCallStatus`,
      data
    );
  }
  updateCallSid(data) {
    return this.http.post(`${this.baseApiUrl2}leads/updateCallSid`, data);
  }
  getCampaignsList(obj) {
    return this.http.post(`${this.baseApiUrl2}leads/findAllCampaign`, obj);
  }
  getCampaignDetails(id) {
    return this.http.get(`${this.baseApiUrl2}leads/viewCampaign/${id}`);
  }
  getCampaignDetailsById(id) {
    return this.http.get(`${this.baseApiUrl2}leads/viewCampaignByid/${id}`);
  }
  addFilters(obj) {
    return this.http.post(`${this.baseApiUrl2}leads/createCampaign`, obj)
  }
  getLeadsByCampaign(obj) {
    return this.http.post(`${this.baseApiUrl2}leads/getLeadsByCampaign`, obj)
  }
  updateCallCommentRecruiter(data) {
    return this.http.post(
      `${this.baseApiUrl2}leads/updateCallComments`,
      data
    )
  }
  getLeadById(id) {
    return this.http.post(
      `${this.baseApiUrl2}leads/getLeadById`,
      { id: id }
    )
  }
  getCompanyDetails(domain) {
    return this.http.post(
      `${this.baseApiUrl2}leads/getCompanyDetails`,
      { domain: domain }
    )
  }
  getAllLeads(obj) {
    return this.http.post(
      `${this.baseApiUrl2}leads/getAllLeads1`,
      obj
    )
  }

  search(obj) {
    return this.http.post(
      `${this.baseApiUrl2}leads/searchLeads`,
      obj
    );
  }
  searchCampaigns(obj) {
    return this.http.post(
      `${this.baseApiUrl2}leads/searchCampaigns`,
      obj
    );
  }
  getCardCounts() {
    return this.http.get(
      `${this.baseApiUrl2}leads/cardsCounts`);
  }
  assignLeads(body) {
    return this.http.post(
      `${this.baseApiUrl2}leads/assignLeads`,
      body
    );
  }
  exportAllByCampaign(id) {
    return this.http.post(
      `${this.baseApiUrl2}leads/exportAllByCampaign`,
      { campaignId: id }
    );
  }
  exportAll() {
    return this.http.get(
      `${this.baseApiUrl2}leads/exportAll`
    );
  }

  // STRIPE API's
  listStripePlans() {
    return this.http.get(
      `${this.baseApiUrl2}leads/listStripePlans`
    );
  }

  fetchTransactionDetails(body) {
    return this.http.post(
      `${this.baseApiUrl2}leads/createTransaction`,
      body
    );
  }
  fetchTransactionDetails1(body) {
    return this.http.post(
      `${this.baseApiUrl2}leads/createTransactionAgent`,
      body
    );
  }
  getTransactions(body) {
    return this.http.post(
      `${this.baseApiUrl2}leads/getTransactions`,
      body
    );
  }
  transactionSearch(body) {
    return this.http.post(
      `${this.baseApiUrl2}leads/transactionSearch`,
      body
    );
  }

  getOrderId(body) {
    return this.http.post(
      `${this.baseApiUrl2}leads/purchase`,
      body
    );
  }

  addPurchasehistory(body) {
    return this.http.post(
      `${this.baseApiUrl2}leads/createTransactionRazorpay`,
      body
    );
  }
  checkLimit(body) {
    return this.http.post(
      `${this.baseApiUrl2}leads/checkLimit`,
      body
    );
  }
  increaseLimit(body) {
    return this.http.post(
      `${this.baseApiUrl2}leads/increaseLimit`,
      body
    );
  }
}
