<div class="row">
  <div class="home-banner-bg2">
    <div class="banenr-text2">Soar</div>
  </div>
</div>
<div class="container-md m-auto">
  <!--Section AccessQue-->
  <div class="row mt-5">
    <div class="col-md-6 col-sm-6 col-lg-6">
      <div class="hightlight-text-2 ml-3">
        Best Incident Tool with Soar integration.
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-md-6 col-sm-6 col-lg-6">
        <p class="highlightFont w-80 lh130 pt-3 px-3 pb-3">
          Our CRM connect to your customers enquiries, technical issues, calls, SMS support and WhatsApp support. The
          automatic ticket creation separates us from the competitors, we are not stopping here, we also automate the
          Incident resolution with RPA.
        </p>

        <p class="lightFont w-80 lh130 px-3">
          We Create Security Bots to Streamline and Automate Workflows. Replace manual, repetitive tasks and deliver
          delightful self-service experiences with automated, interactive bots in popular tools like Slack and Microsoft
          Teams.
        </p>
        <b class="px-3 lh130">AI Incident Analytics</b>
        <p class="lightFont w-80 lh130 px-3">
          AI powered Analytics will help organisations to solve the repetitive issues and improves Application/product
          Quality.</p>

        <button class="btn btn-primary mt-5 ml-3" style="margin:0 auto" routerLink="/bookademo/soar">
          Book a Demo
        </button>
      </div>
      <div class="col-md-6 col-sm-6 col-lg-6">
        <img src="../../../../assets/images/soar-graphic-1.png">
        <img src="../../../../assets/images/soar-graphic-2.png">
      </div>
    </div>
  </div>
</div>