import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe, DatePipe } from '@angular/common';
import { CustomTableComponent } from './custom-table/custom-table.component';
import { FormatcellPipe } from './formatcell.pipe';
import { CustomPageinationDirective } from './custom-pageination.directive';
import { CusPaginationComponent } from './cus-pagination/cus-pagination.component';
import { DemoMaterialModule } from 'src/app/pages/uhleads/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ModalComponent } from './modals/modal/modal.component';
import { UpdateLeadComponent } from './modals/update-lead/update-lead.component';
import { CustomCardsComponent } from './custom-cards/custom-cards.component';
import { IvyCarouselModule } from 'angular-responsive-carousel';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';



@NgModule({
  declarations: [
    CustomTableComponent,
    FormatcellPipe,
    CustomPageinationDirective,
    CusPaginationComponent,
    ModalComponent,
    UpdateLeadComponent,
    CustomCardsComponent,
  ],
  providers: [CurrencyPipe, DatePipe],
  imports: [
    CommonModule,
    DemoMaterialModule,
    FormsModule,
    ReactiveFormsModule,
    IvyCarouselModule,
    NgxSkeletonLoaderModule
  ],
  exports: [CustomTableComponent, CustomCardsComponent, ModalComponent]
})
export class CustomsModule { }
