import { Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MomentFormatService } from 'src/app/moment-format-service';
import { SharedService } from 'src/app/shared/shared.service';
import { UhLeadsService } from '../../uhleads.service';

@Component({
  selector: 'app-uhleadsfilter',
  templateUrl: './uhleadsfilter.component.html',
  styleUrls: ['./uhleadsfilter.component.css']
})
export class UhleadsfilterComponent implements OnInit {
  userData: any;
  showProjects: boolean = false;
  showCourses: boolean = false;
  showApplications: boolean = false;
  showApplicationsCall: boolean = false;
  showUsers: boolean = false;
  showEnrollmentYear: boolean = false;
  showEnrollmentTerm: boolean = false;
  showDate: boolean = false;
  showCreatedDate: boolean = false;
  showUpdatedDate: boolean = false;
  projectLists: any;
  coursesList: any;
  applicationsList: any;
  applicationsCallList: any
  agentsList: any;
  enrollmentTermList: any;
  enrollmentYearList: any;
  list: any[] = [
    { name: 'Users', checked: false },
    { name: 'Applications', checked: false },
    { name: 'Enrollment Year', checked: false },
    { name: 'Enrollment Term', checked: false },
  ];
  selectedIndexProject: number;
  currentProjectName: any;
  courseSelectionArray: any[] = [];
  applicationSelectionArray: any[] = [];
  applicationCallSelectionArray: any[] = [];
  usersSelectionArray: any[] = [];
  enrollmentYearSelectionArray: any[] = [];
  enrollmentTermSelectionArray: any[] = []
  startDate: any;
  endDate: any;
  selectedIndexDate: string;
  showDateCustom: boolean = false;
  filterValue: string;
  date1: any;
  date2: any;
  private colors = [
    [{ 'bg': '#FAE2DF', 'color': '#A52A1E' }],
    [{ 'bg': '#C8EAF2', 'color': '#014C5C' }],
    [{ 'bg': '#CDEDDC', 'color': '#04745A' }],
    [{ 'bg': '#D1E2FF', 'color': '#013CA3' }],
    [{ 'bg': '#e7fadf', 'color': '#39a51e' }],
    [{ 'bg': '#dffaeb', 'color': '#1ea55b' }],
    [{ 'bg': '#dfe2fa', 'color': '#1e2ea5' }],
  ]
  @Output() generate = new EventEmitter();
  @Output() export = new EventEmitter();
  projectId: any;
  @ViewChild('Program') Program: ElementRef;
  @ViewChild('Applications') Applications: ElementRef;
  @ViewChild('CallStatus') CallStatus: ElementRef;
  @ViewChild('Users') Users: ElementRef;
  @ViewChild('EnrollmentYear') EnrollmentYear: ElementRef;
  @ViewChild('EnrollmentTerm') EnrollmentTerm: ElementRef;
  @ViewChild('Date') Date: ElementRef;
  constructor(
    private services: UhLeadsService,
    private momentService: MomentFormatService,
    private sharedService: SharedService,
    private renderer: Renderer2
  ) { }

  ngOnInit(): void {
    this.userData = JSON.parse(localStorage.getItem("userData") || '{}');
    this.date1 = new FormControl(new Date());
    this.date2 = new FormControl(new Date(Date.now() - 864e5));
    this.startDate = this.momentService.dashboardDateFormat(
      new Date(new Date(Date.now() - 864e5 * 365)).getTime()
    );
    this.endDate = this.momentService.dashboardDateFormat(
      new Date(new Date(Date.now() + 864e5))
    );
    // this.sharedService.projectId.subscribe((data) => {
    this.projectId = 2;
    this.getCourses();
    this.getAgentList();
    this.getEnrollmentTerm();
    this.getEnrollmentYear();
    this.getApplicationList();
    // })
  }
  toggleFilters(val) {

    if (val === 'showProjects') {
      this.showProjects = !this.showProjects;
      this.showCourses = false;
      this.showApplications = false;
      this.showUsers = false;
      this.showEnrollmentYear = false;
      this.showEnrollmentTerm = false;
      this.showDate = false;
      this.showCreatedDate = false;
      this.showUpdatedDate = false;
      this.showApplicationsCall = false;
    } else if (val === 'showCourses') {
      this.showProjects = false;
      this.showCourses = !this.showCourses;
      this.showApplications = false;
      this.showUsers = false;
      this.showEnrollmentYear = false;
      this.showEnrollmentTerm = false;
      this.showDate = false;
      this.showCreatedDate = false;
      this.showUpdatedDate = false;
      this.showApplicationsCall = false;
    } else if (val === 'showApplications') {
      this.showProjects = false;
      this.showCourses = false;
      this.showApplications = !this.showApplications;
      this.showUsers = false;
      this.showEnrollmentYear = false;
      this.showEnrollmentTerm = false;
      this.showDate = false;
      this.showCreatedDate = false;
      this.showUpdatedDate = false;
      this.showApplicationsCall = false;
    } else if (val === 'showApplicationsCall') {
      this.showProjects = false;
      this.showCourses = false;
      this.showApplications = false;
      this.showUsers = false;
      this.showEnrollmentYear = false;
      this.showEnrollmentTerm = false;
      this.showDate = false;
      this.showCreatedDate = false;
      this.showUpdatedDate = false;
      this.showApplicationsCall = !this.showApplicationsCall;
    } else if (val === 'showUsers') {
      this.showProjects = false;
      this.showCourses = false;
      this.showApplications = false;
      this.showUsers = !this.showUsers;
      this.showEnrollmentYear = false;
      this.showEnrollmentTerm = false;
      this.showDate = false;
      this.showCreatedDate = false;
      this.showUpdatedDate = false;
      this.showApplicationsCall = false;
    } else if (val === 'showEnrollmentYear') {
      this.showProjects = false;
      this.showCourses = false;
      this.showApplications = false;
      this.showUsers = false;
      this.showEnrollmentYear = !this.showEnrollmentYear;
      this.showEnrollmentTerm = false;
      this.showDate = false;
      this.showCreatedDate = false;
      this.showUpdatedDate = false;
      this.showApplicationsCall = false;
    } else if (val === 'showEnrollmentTerm') {
      this.showProjects = false;
      this.showCourses = false;
      this.showApplications = false;
      this.showUsers = false;
      this.showEnrollmentYear = false;
      this.showEnrollmentTerm = !this.showEnrollmentTerm;
      this.showDate = false;
      this.showCreatedDate = false;
      this.showUpdatedDate = false;
      this.showApplicationsCall = false;
    } else if (val === 'showDate') {
      this.showProjects = false;
      this.showCourses = false;
      this.showApplications = false;
      this.showUsers = false;
      this.showEnrollmentYear = false;
      this.showEnrollmentTerm = false;
      this.showDate = !this.showDate;
      this.showCreatedDate = false;
      this.showUpdatedDate = false;
      this.showApplicationsCall = false;
    } else if (val === 'showCreatedDate') {
      this.showProjects = false;
      this.showCourses = false;
      this.showApplications = false;
      this.showUsers = false;
      this.showEnrollmentYear = false;
      this.showEnrollmentTerm = false;
      this.showDate = false;
      this.showCreatedDate = !this.showCreatedDate;
      this.showUpdatedDate = false;
      this.showApplicationsCall = false;
    } else if (val === 'showUpdatedDate') {
      this.showProjects = false;
      this.showCourses = false;
      this.showApplications = false;
      this.showUsers = false;
      this.showEnrollmentYear = false;
      this.showEnrollmentTerm = false;
      this.showDate = false;
      this.showCreatedDate = false;
      this.showUpdatedDate = !this.showUpdatedDate;
      this.showApplicationsCall = false;
    }
  }
  getCourses() {
    this.services.getCourses(this.userData.id, this.userData.token).toPromise()
      .then((resp: any) => {
        this.coursesList = resp.data;
        this.checkAllcourse(true);
        this.isAllCheckedcourse()
      })
      .catch(err => {

      })
  }
  getAgentList() {
    this.services.getAgents(this.projectId).toPromise()
      .then((resp: any) => {
        var agentList = resp.data.sort((a, b) => a.agentInitial === b.agentInitial ? 0 : a.agentInitial < b.agentInitial ? -1 : 1);

        agentList.forEach(y => {
          y.AgentColor = this.colors[this.numberFromText(y.agentInitial) % this.colors.length][0]
        })
        this.agentsList = agentList;
        this.checkAllusers(true);
        this.isAllCheckedusers();
      })
      .catch(err => {

      })
  }
  numberFromText(text) {
    const charCodes = text
      .split('') // => ["A", "A"]
      .map(char => char.charCodeAt(0)) // => [65, 65]
      .join(''); // => "6565"
    return parseInt(charCodes, 10);
  }
  getApplicationList() {
    var postData = {
      "userId": this.userData.id,
      "token": this.userData.token,
      "platform": "CRM",
      "project": this.projectId
    }
    this.services.getApplication(postData).toPromise()
      .then((resp: any) => {

        this.applicationsList = resp.applicationStatusLists;
        this.applicationsCallList = resp.applicationCallStatus;
        this.checkAllapplication(true);
        this.checkAllCallapplication(true);
        this.isAllCheckedapplication();
        this.isAllCheckedapplicationCall();
      })
      .catch(err => {

      })
  }
  getEnrollmentTerm() {
    this.services.getEnrollmentTerm(this.userData.id, this.userData.token).toPromise()
      .then((resp: any) => {
        this.enrollmentTermList = resp.enrollmentTermList;
        this.checkAllenrollmentTerm(true);
        this.isAllCheckedenrollmentTerm();

      })
      .catch(err => {

      })
  }
  getEnrollmentYear() {
    this.services.getEnrollmentYear(this.userData.id, this.userData.token).toPromise()
      .then((resp: any) => {
        this.enrollmentYearList = resp.enrollmentYearList;
        this.checkAllenrollmentYear(true);
        this.isAllCheckedenrollmentYear();
      })
      .catch(err => {

      })
  }
  shareCheckedList(item: any[]) {
  }
  shareIndividualCheckedList(item: any) {
    if (item.name === 'Users' && item.checked === true) {
      this.toggleFilters('showUsers')
    }
    if (item.name === 'Applications' && item.checked === true) {
      this.toggleFilters('showApplications')
    }
    if (item.name === 'Enrollment Year' && item.checked === true) {
      this.toggleFilters('showEnrollmentYear')
    }
    if (item.name === 'Enrollment Term' && item.checked === true) {
      this.toggleFilters('showEnrollmentTerm')
    }
  }
  dateSelection(event) {
    if (event.target.value != "custom") {
      this.endDate = this.momentService.dashboardDateFormat(
        new Date(new Date(Date.now())).getTime()
      );
    }
    if (event.target.value == "custom") {
      this.filterValue = 'custom';
      this.showDateCustom = true;
      this.selectedIndexDate = event.target.value;
    } else if (event.target.value == "24hrs") {
      this.showDateCustom = false;
      this.filterValue = '24hrs'
      this.selectedIndexDate = event.target.value;
      this.startDate = this.momentService.dashboardDateFormat(
        new Date(new Date(Date.now() - 864e5)).getTime()
      );
    } else if (event.target.value == "72hrs") {
      this.showDateCustom = false;
      this.filterValue = '72hrs'
      this.selectedIndexDate = event.target.value;
      this.startDate = this.momentService.dashboardDateFormat(
        new Date(new Date(Date.now() - 864e5 * 3)).getTime()
      );
    } else if (event.target.value == "07days") {
      this.showDateCustom = false;
      this.filterValue = '07days'
      this.selectedIndexDate = event.target.value;
      this.startDate = this.momentService.dashboardDateFormat(
        new Date(new Date(Date.now() - 864e5 * 7)).getTime()
      );
    } else if (event.target.value == "14days") {
      this.showDateCustom = false;
      this.filterValue = '14days'
      this.selectedIndexDate = event.target.value;
      this.startDate = this.momentService.dashboardDateFormat(
        new Date(new Date(Date.now() - 864e5 * 14)).getTime()
      );
    } else if (event.target.value == "30days") {
      this.showDateCustom = false;
      this.filterValue = '30days'
      this.selectedIndexDate = event.target.value;
      this.startDate = this.momentService.dashboardDateFormat(
        new Date(new Date(Date.now() - 864e5 * 30)).getTime()
      );
    }
    else if (event.target.value == "yearly") {
      this.showDateCustom = false;
      this.filterValue = 'yearly'
      this.selectedIndexDate = event.target.value;
      this.startDate = this.momentService.dashboardDateFormat(
        new Date(new Date(Date.now() - 864e5 * 365)).getTime()
      );
    }
  }
  hideDate() {
    // this.filterValuev
    if (this.filterValue == "custom" && this.showDateCustom == false) {
      this.showDateCustom = true;
    }
  }
  filterData() {
    this.startDate = this.momentService.dashboardDateFormat(
      new Date(this.date2.value).getTime()
    );
    this.endDate = this.momentService.dashboardDateFormat(
      new Date(this.date1.value).getTime()
    );

  }
  getStartDate(type: string, event: MatDatepickerInputEvent<Date>) {
    this.startDate = event.value;
    this.startDate = this.momentService.dashboardDateFormat(
      new Date(this.startDate).getTime
    );
  }
  getEndDate(type: string, event: MatDatepickerInputEvent<Date>) {
    this.endDate = event.value;
    this.endDate = this.momentService.dashboardDateFormat(
      new Date(this.endDate).getTime
    );
  }
  // FILTER FUCNTIONS
  // PROJECT SINGLE SELECTION HANDLE
  projectSelection(event, id, name) {
    if (event.target.checked) {
      this.selectedIndexProject = id;
      this.currentProjectName = name
    } else {
      this.selectedIndexProject = undefined;
      this.currentProjectName = '';
    }
  }

  // COURSE MULTI SELECT HANDLE
  courseSelection(ev) {
    var s = ev.value;
    if (ev.checked) {
      this.courseSelectionArray.push(s);
    } else {
      var index = this.courseSelectionArray.indexOf(s);
      if (index > -1) {
        this.courseSelectionArray.splice(index, 1);
      }
    }
  }

  checkAllcourse(ev) {
    this.courseSelectionArray = [];
    this.coursesList.forEach(x => {
      if (ev === true) {
        this.courseSelectionArray.push(x.course);
      }
      return x.courseState = ev
    });

  }
  isAllCheckedcourse() {
    var rValue = this.coursesList.every(_ => _.courseState);
    return rValue;
  }
  // APPLICATION MULTI SELECT HANDLE
  applicationSelection(ev) {
    var s = ev.value;
    if (ev.checked) {
      this.applicationSelectionArray.push(s);
    } else {
      var index = this.applicationSelectionArray.indexOf(s);
      if (index > -1) {
        this.applicationSelectionArray.splice(index, 1);
      }
    }
  }

  checkAllapplication(ev) {
    this.applicationSelectionArray = [];
    this.applicationsList.forEach(x => {
      if (ev === true) {
        this.applicationSelectionArray.push(x.value);
      }
      return x.applicationState = ev
    });

  }
  isAllCheckedapplication() {
    var rValue = this.applicationsList.every(_ => _.applicationState);
    return rValue;
  }
  unCheckapplicationSelectionArray() {
    this.applicationSelectionArray = []
    this.applicationsList.forEach(x => {
      return x.applicationState = false
    })
  }
  // CALL APPLICATION MULTI SELECT HANDLE
  applicationCallSelection(ev) {
    var s = ev.value;
    if (ev.checked) {
      this.applicationCallSelectionArray.push(s);
    } else {
      var index = this.applicationCallSelectionArray.indexOf(s);
      if (index > -1) {
        this.applicationCallSelectionArray.splice(index, 1);
      }
    }
  }

  checkAllCallapplication(ev) {
    this.applicationCallSelectionArray = [];
    this.applicationsCallList.forEach(x => {
      if (ev === true) {
        this.applicationCallSelectionArray.push(x.value);
      }
      return x.applicationStateCall = ev
    });

  }
  isAllCheckedapplicationCall() {
    var rValue = this.applicationsCallList.every(_ => _.applicationStateCall);
    return rValue;
  }
  // Enrollment Year SELECT HANDLE
  enrollmentYearSelection(ev) {
    var s = ev.value;
    if (ev.checked) {
      this.enrollmentYearSelectionArray.push(s);
    } else {
      var index = this.enrollmentYearSelectionArray.indexOf(s);
      if (index > -1) {
        this.enrollmentYearSelectionArray.splice(index, 1);
      }
    }

  }

  checkAllenrollmentYear(ev) {
    this.enrollmentYearSelectionArray = [];
    this.enrollmentYearList.forEach(x => {
      if (ev === true) {
        this.enrollmentYearSelectionArray.push(x.EnrollmentYear);
      }
      return x.enrollmentYearState = ev
    });

  }
  isAllCheckedenrollmentYear() {
    var rValue = this.enrollmentYearList.every(_ => _.enrollmentYearState);
    return rValue;
  }
  unCheckenrollmentYearSelectionArray() {
    this.enrollmentTermSelectionArray = [];
    this.enrollmentYearList.forEach(x => {
      return x.enrollmentYearState = false
    })
  }
  // Enrollment Term SELECT HANDLE
  enrollmentTermSelection(ev) {
    var s = ev.value;
    if (ev.checked) {
      this.enrollmentTermSelectionArray.push(s);
    } else {
      var index = this.enrollmentTermSelectionArray.indexOf(s);
      if (index > -1) {
        this.enrollmentTermSelectionArray.splice(index, 1);
      }
    }
  }

  checkAllenrollmentTerm(ev) {
    this.enrollmentTermSelectionArray = [];
    this.enrollmentTermList.forEach(x => {
      if (ev === true) {
        this.enrollmentTermSelectionArray.push(x.EnrollmentTerm);
      }
      return x.enrollmentTermState = ev
    });

  }
  isAllCheckedenrollmentTerm() {
    var rValue = this.enrollmentTermList.every(_ => _.enrollmentTermState);
    return rValue;
  }
  unCheckenrollmentTermSelectionArray() {
    this.enrollmentTermSelectionArray = [];
    this.enrollmentTermList.forEach(x => {
      return x.enrollmentTermState = false
    })
  }
  // USERS MULTI SELECT HANDLE
  usersSelection(ev) {
    var s = ev.value;
    if (ev.checked) {

      this.usersSelectionArray.push(s);

    } else {
      var index = this.usersSelectionArray.indexOf(s);
      if (index > -1) {
        this.usersSelectionArray.splice(index, 1);
      }
    }
  }

  checkAllusers(ev) {
    this.usersSelectionArray = [];
    this.agentsList.forEach(x => {
      if (ev === true) {
        this.usersSelectionArray.push(x.Mailid);
      }
      return x.usersState = ev
    });

  }
  isAllCheckedusers() {
    var rValue = this.agentsList.every(_ => _.usersState);
    return rValue;
  }
  unCheckusersSelectionArray() {
    this.usersSelectionArray = [];
    this.agentsList.forEach(x => {
      return x.usersState = false
    })
  }

  // ===========================

  generateData() {

    this.showProjects = false;
    this.showCourses = false;
    this.showApplications = false;
    this.showUsers = false;
    this.showEnrollmentYear = false;
    this.showEnrollmentTerm = false;
    this.showDate = false;
    this.showCreatedDate = false;
    this.showUpdatedDate = false;
    this.showApplicationsCall = false;
    var postData = {
      "course": this.courseSelectionArray.length === this.coursesList.length ? 'All' : this.courseSelectionArray.length > 0 ? this.courseSelectionArray : 'All',
      "agentEmail": this.usersSelectionArray.length === this.agentsList.length ?
        'All' : this.usersSelectionArray.length > 0 ?
          this.usersSelectionArray : 'All',
      "from": this.startDate,
      "to": this.endDate,
      "callStatus":
        this.applicationCallSelectionArray.length === this.applicationsCallList.length ?
          'All' : this.applicationCallSelectionArray.length > 0 ?
            this.applicationCallSelectionArray : 'All',
      "enrollmentYear": this.enrollmentYearSelectionArray.length === this.enrollmentYearList.length ? 'All' : this.enrollmentYearSelectionArray.length > 0 ? this.enrollmentYearSelectionArray : 'All',
      "enrollmentTerm": this.enrollmentTermSelectionArray.length === this.enrollmentTermList.length ? 'All' : this.enrollmentTermSelectionArray.length > 0 ? this.enrollmentTermSelectionArray : 'All',
      "application": this.applicationSelectionArray.length === this.applicationsList.length ? 'All' : this.applicationSelectionArray.length > 0 ? this.applicationSelectionArray : 'All'
    }
    this.generate.emit(postData);
  }
  getAgentName(val) {
    var agentList = this.agentsList;
    var name = agentList.filter(x => x.Mailid === val)[0];
    return name.agentname
  }
  exportData() {
    this.export.emit();
  }
  getApplicationName(val) {
    var applicationList = this.applicationsList;
    var name = applicationList.filter(x => x.value === val)[0];
    return name.LeadDocStatus
  }
  getApplicationCallName(val) {
    var applicationList = this.applicationsCallList;
    var name = applicationList.filter(x => x.value === val)[0];
    return name.LeadCallStatus
  }
}

