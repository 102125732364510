import { Component, OnInit } from "@angular/core";
import HelloSign from "hellosign-embedded";

@Component({
  selector: "app-hellosign",
  templateUrl: "./hellosign.component.html",
  styleUrls: ["./hellosign.component.css"],
})
export class HellosignComponent implements OnInit {
  client: HelloSign;

  constructor() { }

  ngOnInit() {
    this.client = new HelloSign({
      clientId: "903248d72884ac004b18a9e628890de8",
    });

    setTimeout(() => {
      this.client.open(
        "https://embedded.hellosign.com/prep-and-send/embedded-template?cached_params_token=18ae0f12e435c3ca2066fe564011ce60",
        {
          skipDomainVerification: true,
          clientId: "903248d72884ac004b18a9e628890de8",
        }
      );
    }, 10000);

  }
}
