import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewEncapsulation,
} from "@angular/core";
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { Notification } from "src/app/interfaces/notification.type";
import { NotificationsService } from "src/app/notification.service";
import { UhLeadsService } from "src/app/pages/uhleads.service";
import { UhubDashboardService } from "src/app/pages/uhub-dashboard/uhub-dashboard.service";

@Component({
  selector: "simple-notification",
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger("enterLeave", [
      // Fade
      state("fade", style({ opacity: 1 })),
      transition("* => fade", [
        style({ opacity: 0 }),
        animate("400ms ease-in-out"),
      ]),
      state("fadeOut", style({ opacity: 0 })),
      transition("fade => fadeOut", [
        style({ opacity: 1 }),
        animate("300ms ease-in-out"),
      ]),

      // Enter from top
      state("fromTop", style({ opacity: 1, transform: "translateY(0)" })),
      transition("* => fromTop", [
        style({ opacity: 0, transform: "translateY(-5%)" }),
        animate("400ms ease-in-out"),
      ]),
      state("fromTopOut", style({ opacity: 0, transform: "translateY(5%)" })),
      transition("fromTop => fromTopOut", [
        style({ opacity: 1, transform: "translateY(0)" }),
        animate("300ms ease-in-out"),
      ]),

      // Enter from right
      state("fromRight", style({ opacity: 1, transform: "translateX(0)" })),
      transition("* => fromRight", [
        style({ opacity: 0, transform: "translateX(5%)" }),
        animate("400ms ease-in-out"),
      ]),
      state(
        "fromRightOut",
        style({ opacity: 0, transform: "translateX(-5%)" })
      ),
      transition("fromRight => fromRightOut", [
        style({ opacity: 1, transform: "translateX(0)" }),
        animate("300ms ease-in-out"),
      ]),

      // Enter from bottom
      state("fromBottom", style({ opacity: 1, transform: "translateY(0)" })),
      transition("* => fromBottom", [
        style({ opacity: 0, transform: "translateY(5%)" }),
        animate("400ms ease-in-out"),
      ]),
      state(
        "fromBottomOut",
        style({ opacity: 0, transform: "translateY(-5%)" })
      ),
      transition("fromBottom => fromBottomOut", [
        style({ opacity: 1, transform: "translateY(0)" }),
        animate("300ms ease-in-out"),
      ]),

      // Enter from left
      state("fromLeft", style({ opacity: 1, transform: "translateX(0)" })),
      transition("* => fromLeft", [
        style({ opacity: 0, transform: "translateX(-5%)" }),
        animate("400ms ease-in-out"),
      ]),
      state("fromLeftOut", style({ opacity: 0, transform: "translateX(5%)" })),
      transition("fromLeft => fromLeftOut", [
        style({ opacity: 1, transform: "translateX(0)" }),
        animate("300ms ease-in-out"),
      ]),

      // Rotate
      state("scale", style({ opacity: 1, transform: "scale(1)" })),
      transition("* => scale", [
        style({ opacity: 0, transform: "scale(0)" }),
        animate("400ms ease-in-out"),
      ]),
      state("scaleOut", style({ opacity: 0, transform: "scale(0)" })),
      transition("scale => scaleOut", [
        style({ opacity: 1, transform: "scale(1)" }),
        animate("400ms ease-in-out"),
      ]),

      // Scale
      state("rotate", style({ opacity: 1, transform: "rotate(0deg)" })),
      transition("* => rotate", [
        style({ opacity: 0, transform: "rotate(5deg)" }),
        animate("400ms ease-in-out"),
      ]),
      state("rotateOut", style({ opacity: 0, transform: "rotate(-5deg)" })),
      transition("rotate => rotateOut", [
        style({ opacity: 1, transform: "rotate(0deg)" }),
        animate("400ms ease-in-out"),
      ]),
    ]),
  ],
  templateUrl: "./notification.component.html",
  styleUrls: ["./notification.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationComponent implements OnInit, OnDestroy {
  @Input() public timeOut: number;
  @Input() public showProgressBar: boolean;
  @Input() public pauseOnHover: boolean = true;
  @Input() public clickToClose: boolean;
  @Input() public clickIconToClose: boolean;
  @Input() public maxLength: number;
  @Input() public theClass: string;
  @Input() public rtl: boolean;
  @Input() public animate: string;
  @Input() public position: number;
  @Input() public item: Notification;

  // Progress bar variables
  public title: any;
  public content: any;

  public titleIsTemplate = false;
  public contentIsTemplate = false;

  public progressWidth = 0;
  public safeSvg: SafeHtml;

  private stopTime = false;
  private timer: any;
  private steps: number;
  private speed: number;
  private count = 0;
  private start: any;

  private diff: any;
  private icon: string;
  notificationType: any;
  leadId: any;
  notificationId: any;
  userData: any;
  audio = new Audio();
  saved: boolean = false;

  constructor(
    private notificationService: NotificationsService,
    private uhleadsService: UhLeadsService,
    private domSanitizer: DomSanitizer,
    private cdr: ChangeDetectorRef,
    private zone: NgZone,
    public uhubleadsService: UhubDashboardService
  ) { }

  ngOnInit(): void {
    this.userData = JSON.parse(localStorage.getItem("userData"));
    this.notificationService
      .getAudio(
        this.userData.mail,
        "CRM",
        this.userData.token,
        this.userData.soundId
      )
      .subscribe((res: any) => {
        this.play(res.listOfSounds[0].soundPath);
      });

    if (this.item.override) {
      this.attachOverrides();
    }

    if (this.animate) {
      this.item.state = this.animate;
    }

    if (this.timeOut !== 0) {
      this.startTimeOut();
    }

    this.contentType(this.item.title, "title");
    this.contentType(this.item.content.description, "content");
    this.content = this.item.content.description;
    this.notificationType = this.item.content.notificationtype;
    this.leadId = this.item.content.leadId;
    this.notificationId = this.item.content.notififcaitonReadId;

    this.safeSvg = this.domSanitizer.bypassSecurityTrustHtml(
      this.icon || this.item.icon
    );
    if (this.item.content.Platform === 'Recruiter') {
      this.notificationService.recruiterUnreadCount({
        ProjectId: this.item.content.ProjectId,
        GUID: this.item.content.GUID
      }).subscribe((data: any) => {
        console.log(data)
        console.log(data.unreadCount)
        this.uhubleadsService.unreadCountRecBehaviorSubject.next({
          id: this.item.content.GUID,
          count: data.unreadCount
        });
      }, (err) => {

      })
    }
  }

  play(url) {
    this.audio = new Audio();
    const source = document.createElement("source");
    source.setAttribute("src", url);
    source.setAttribute("type", "audio/mpeg");
    this.audio.append(source);
    this.audio.load();
    const playPromise = this.audio.play();
    if (playPromise !== null) {
      playPromise.catch(() => {
        this.audio.play();
      });
    }
  }

  startTimeOut(): void {
    this.steps = this.timeOut / 10;
    this.speed = this.timeOut / this.steps;
    this.start = new Date().getTime();
    this.zone.runOutsideAngular(
      () => (this.timer = setTimeout(this.instance, this.speed))
    );
  }

  onEnter(): void {
    if (this.pauseOnHover) {
      this.stopTime = true;
    }
  }

  onLeave(): void {
    if (this.pauseOnHover) {
      this.stopTime = false;
      this.zone.runOutsideAngular(() =>
        setTimeout(this.instance, this.speed - this.diff)
      );
    }
  }

  onClick($e: MouseEvent): void {
    this.item.click!.emit($e);

    if (this.clickToClose) {
      this.remove();
    }
  }

  onClickIcon($e: MouseEvent): void {
    this.item.clickIcon!.emit($e);
    window.alert("Action");

    if (this.clickIconToClose) {
      this.remove();
    }
  }

  mycustomremove() {
    this.remove();
  }

  // Attach all the overrides
  attachOverrides(): void {
    Object.keys(this.item.override).forEach((a) => {
      if (this.hasOwnProperty(a)) {
        (<any>this)[a] = this.item.override[a];
      }
    });
  }

  ngOnDestroy(): void {
    clearTimeout(this.timer);
  }

  private instance = () => {
    this.diff = new Date().getTime() - this.start - this.count * this.speed;

    if (this.count++ === this.steps) {
      this.remove();
    } else if (!this.stopTime) {
      if (this.showProgressBar) {
        this.progressWidth += 100 / this.steps;
      }

      this.timer = setTimeout(this.instance, this.speed - this.diff);
    }
    this.zone.run(() => this.cdr.detectChanges());
  };

  remove() {
    if (this.animate) {
      this.item.state = this.animate + "Out";
      setTimeout(() => {
        this.notificationService.set(this.item, false);
      }, 310);
    } else {
      this.notificationService.set(this.item, false);
    }
  }
  saveNotification(value) {
    this.notificationService
      .saveNotification({
        email: this.userData.mail,
        Platform: "CRM",
        "saveType": value,
        token: this.userData.token,
        notificationId: this.notificationId,
      })
      .subscribe((res: any) => {
        if (res.status == 200) {
          this.saved = value;
        }
      });
  }

  viewNotification() {
    if (this.item.content.Platform === 'Recruiter') {
      switch (this.item.content.notificationtype) {
        case 'rec-comment':
        case 'rec-comment-doc':
          this.uhubleadsService.viewReceivedLeadBehaviorSubject.next(this.item.content.GUID);
          break;
        case 'lead-add-rec':
          this.uhubleadsService.addLeadBehaviorSubject.next(this.item.content.GUID);
          break;
        case 'doc-upload':
          this.uhubleadsService.viewReceivedLeadBehaviorSubject.next(this.item.content.GUID);
          break;
        default:
          break;
      }
    }
    if (
      this.notificationType == "LeadComments" ||
      this.notificationType == "Comments"
    ) {
      this.uhleadsService.viewReceivedLead.next(this.leadId);
    }
  }

  deleteNotification() {
    this.notificationService
      .deleteNotification({
        email: this.userData.mail,
        Platform: "CRM",
        token: this.userData.token,
        notificationId: this.notificationId,
      })
      .subscribe((res: any) => {
        this.remove();
        this.notificationService.currentPage.next({ page: 1 });
      });
  }

  private contentType(item: any, key: string) {
    if (item instanceof TemplateRef) {
      this[key] = item;
    } else {
      this[key] = this.domSanitizer.bypassSecurityTrustHtml(item);
    }

    this[key + "IsTemplate"] = item instanceof TemplateRef;
  }
}
