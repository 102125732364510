import { Component, ElementRef, Inject, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { forEach, toLower, toNumber, toUpper } from "lodash";
import { LeadsDashboardService } from "../../leads-dashboard/leads-dashboard.service";
import * as data from "./data.json";
import { DatePipe, DOCUMENT } from "@angular/common";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { JsonFormData } from "../json-form/json-form.component";
import { LayoutService } from "src/app/layout/layout.service";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { NgbModalOptions, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { NotificationsService } from "src/app/notification.service";

import {
  filter,
  debounceTime,
  distinctUntilChanged,
  tap,
} from "rxjs/operators";
import { fromEvent } from "rxjs";
import { ExportExcelService } from "../../excel.service";
import { UhLeadsService } from "../../uhleads.service";
import { DashPaginationService } from "../dash-pagination/dash-pagination.service";
import { iframeIsLoaded } from "ngx-doc-viewer";
import { ErrorStateMatcher } from "@angular/material/core";
import { CreateCompanyService } from "../../create-company/create-company.service";
import { ErrorHandlerService } from "src/app/error-handler.service";
var sortJsonArray = require("node_modules/sort-json-array");
import {
  countUpTimerConfigModel,
  CountupTimerService,
  timerTexts,
} from "ngx-timer";
import { Dialogs1Component } from "src/app/shared/dialogs/dialogs1/dialogs1.component";
import * as moment from "moment";
import { environment } from "src/environments/environment";
import { MarketingService } from "../../marketing/marketing.service";
const feedbacksArray = require('../../../../assets/json/feedbacks.json');

@Component({
  selector: "app-table",
  templateUrl: "./table.component.html",
  styleUrls: ["./table.component.css"],
})
export class TableComponent implements OnInit {
  public formData: JsonFormData;

  isLoading: boolean = false;
  noRecords: boolean = false;
  selectedAll: boolean = false;
  colData: any;
  projectId: string;
  config: any;
  cardData: any;
  feedback: any;
  columns: any;
  companyId: any;
  leads: any;
  topBarCounts: any;
  search: any;
  @ViewChild("inputString", { static: true }) input: ElementRef;
  @ViewChild("closeAssignButton") closeAssignButton;
  @ViewChild("callingSideNav", { static: true }) callingSideNav;

  public startCall: boolean = false;

  editFormData: any = {
    controls: [
      {
        name: "firstName",
        label: "First name",
        value: "",
        type: "text",
        validators: {
          required: true,
          minLength: 10,
        },
      },
      {
        name: "lastName",
        label: "Last name",
        value: "",
        type: "text",
        validators: {},
      },
      {
        name: "comments",
        label: "Comments",
        value: "",
        type: "textarea",
        validators: {},
      },
      {
        name: "agreeTerms",
        label: "Do you agree?",
        value: "false",
        type: "checkbox",
        validators: {},
      },
      {
        name: "lightDark",
        label: "Toggle dark mode",
        value: "false",
        type: "toggle",
        validators: {},
      },
      {
        name: "size",
        label: "Size",
        value: "",
        type: "range",
        options: {
          min: "0",
          max: "100",
          step: "1",
        },
        validators: {},
      },
    ],
  };

  public marketingId: {
    id: string;
    status: string;
    phoneNumber: string;
    email: string;
    name: string;
    module: string;
    city: string;
    nextCaller: any;
    lead: any;
    companyId?: any;
  } = {
      id: "",
      status: "",
      name: "",
      email: "",
      phoneNumber: "",
      module: "",
      city: "",
      nextCaller: null,
      lead: {},
      companyId: ""

    };

  agentCall: String = "";
  callStatus: string = "";
  testConfig: any;
  dialerScreen1 = "dialer";
  commentText: any = "";
  newStatus: any;
  callData: any;
  leadData: {};
  listFeed: any;
  leadIndex: number;
  statusValue: String = "all";

  public myForm: FormGroup = this.fb.group({});
  someval: any = "";
  leadIds: any[] = [];
  leadInfo: any = {
    Activities: [],
    CallDetails: [],
    videoCalldetails: []
  };
  userData: any;
  endDate: Date;
  startDate: Date;
  sortColumn = "updatedAt";
  sortOrder = "des";
  pageNumber: Number = 1;
  totalPages: Number = 0;
  tableLimit: Number = 10;

  defaultAgent = "default";
  selectedAgent: any;
  agentList: any;

  file: File;
  customDocName: string;
  docUploadError: string;
  docUploadError2: string = "Select files to upload.";
  customFileList: any = [];
  customDocLeadId: any;
  selectedLead: any;
  documentsList: any
  documentsList2: any[] = []
  minItems = 5;
  activityfilterString: string = "";
  validationStatus: boolean = false;
  selectedFiles: File[] = [];
  docUploadID: any;
  docUploadStatus: boolean = false;
  uploadSuccess: string = "none";
  disabled: any;
  documentComments: string;
  currentGuid: string;
  isAdmin: Boolean
  companyConfig: any;
  leadInfoCall: any;
  callSid: any;
  counsellingDate: any;
  contentLoadedTable = false;
  docType: any;

  constructor(
    private route: ActivatedRoute,
    private LeadsDashboardService: LeadsDashboardService,
    private datePipe: DatePipe,
    private readonly modalService: NgbModal,
    private LayoutService: LayoutService,
    private fb: FormBuilder,
    private dialog: MatDialog,
    public ExcelService: ExportExcelService,
    private router: Router,
    public uhleadsservice: UhLeadsService,
    public dashPaginationService: DashPaginationService,
    public CreateCompanyService: CreateCompanyService,
    public NotificationService: NotificationsService,
    private handleError: ErrorHandlerService,
    private layoutService: LayoutService,
    @Inject(DOCUMENT) private domService: HTMLDocument,
    public countupTimerService: CountupTimerService,
    public marketingService: MarketingService,
  ) {
  }

  ngOnInit(): void {
    this.projectId = this.route.snapshot.paramMap.get("projectId");
    this.userData = JSON.parse(localStorage.getItem("userData"));
    this.LeadsDashboardService.dateChange.subscribe((dates: any) => {
      this.startDate = dates.start;
      this.endDate = dates.end;
    });

    this.getProjectConfig(this.projectId);
    this.LeadsDashboardService.refresh.subscribe((res: any) => {
      if (res == true) {
        this.getProjectConfig(this.projectId);
      }
    });
    this.LeadsDashboardService.config.subscribe((res: any) => {
      if (res == "refreshLeads") {
        // this.getTopBarCounts();
        this.getLeads();
      }
    });
    this.dashPaginationService.pageNumber.subscribe((pageNo: any) => {
      if (pageNo) {
        this.pageNumber = pageNo;
        this.getLeads();
      }
    });
    this.LeadsDashboardService.cardValue.subscribe((card: any) => {
      if (card) {
        this.contentLoadedTable = false;
        this.LeadsDashboardService.getLeads2({
          projectId: this.projectId,
          companyId: this.companyId ? this.companyId : this.userData.NewCompanyId,
          card: card,
          mail: this.userData.mail,
          start: this.startDate,
          end: this.endDate,
          limit: this.tableLimit,
          page: this.pageNumber,
        }).subscribe((res: any) => {
          this.contentLoadedTable = true;
          this.leads = res.data;
          this.leads.map(x => {
            if (x.gender && x.gender != "null") {
              x.gender = x.gender.charAt(0).toUpperCase();
            }
          });
          this.replaceNull(this.leads);
          let newTotal = ~~(Number(res.totalResults) / Number(this.tableLimit)) + 1;
          console.log("Total Pages: ", newTotal)
          this.dashPaginationService.totalPages.next(Number(newTotal));
          for (let i = 0; i < this.leads.length; i++) {
            this.leads[i].callstatus = this.capitalizeFirstLetter(
              this.leads[i].callstatus
            );
            for (let j = 0; j < this.columns.length; j++) {
              if (this.columns[j].type == "Date") {
                this.leads[i].createddate = this.transformDate(
                  this.leads[i].createddate
                );
                this.leads[i].updateddate = this.transformDate(
                  this.leads[i].updateddate
                );
              }
            }
          }
        });
      }
    });

    this.LeadsDashboardService.dateChange.subscribe((dates: any) => {
      if (dates) {
        this.startDate = dates.start;
        this.endDate = dates.end;
        this.getLeads();
      }
    });
    this.LeadsDashboardService.updateLeadDetails.subscribe((dates: any) => {
      if (dates) {
        this.getLeads();
      }
    });
    this.layoutService.companyConfig.subscribe((res: any) => {
      if (res) {

        this.companyConfig = res
      }
    });

    this.formData = this.editFormData;

    fromEvent(this.input.nativeElement, "keyup")
      .pipe(
        filter(Boolean),
        debounceTime(1500),
        distinctUntilChanged(),
        tap((event: KeyboardEvent) => {
          this.search = this.input.nativeElement.value;
          this.pageNumber = 1;
          this.searchLeads(this.input.nativeElement.value, this.pageNumber);
        })
      )
      .subscribe();
    this.LeadsDashboardService.checkUserRole({ projectId: this.projectId, userMail: this.userData.mail }).subscribe((Data: any) => {
      this.isAdmin = Data.isAdmin
    })

    this.LeadsDashboardService.getCallSid.subscribe((data) => {
      this.callSid = data;
    });

    this.LeadsDashboardService.startTimer.subscribe((data) => {
      if (data) {
        this.callStatus = "inProgress";
        this.startTimer();
        // this.LeadsDashboardService.startTimer.next(null);
      }
    })
  }

  getAgents(projectId, companyId) {
    this.LeadsDashboardService.getAgents({ companyId: companyId, projectId: projectId }).subscribe((res: any) => {
      this.agentList = res.agents;
    })
  }

  searchLeads(value, page) {
    this.contentLoadedTable = false;
    if (value.length > 0) {
      this.LeadsDashboardService.searchLead({
        projectId: this.projectId,
        companyId: this.companyId,
        search: value,
      }).subscribe((res: any) => {
        this.contentLoadedTable = true;
        this.leads = res.data;
        this.leads.map(x => {
          if (x.gender && x.gender != "null") {
            x.gender = x.gender.charAt(0).toUpperCase();
          }
        });
        this.replaceNull(this.leads);
        let newTotal = ~~(Number(res.totalResults) / Number(this.tableLimit)) + 1;
        this.dashPaginationService.totalPages.next(Number(newTotal));
        for (let i = 0; i < this.leads.length; i++) {
          this.leads[i].callstatus = this.capitalizeFirstLetter(
            this.leads[i].callstatus
          );
          for (let j = 0; j < this.columns.length; j++) {
            if (this.columns[j].type == "Date") {
              this.leads[i].createddate = this.transformDate(
                this.leads[i].createddate
              );
              this.leads[i].updateddate = this.transformDate(
                this.leads[i].updateddate
              );
            }
          }
        }
      });
    } else {
      this.getLeads();
    }
  }

  getProjectConfig(id) {
    this.contentLoadedTable = false;
    this.LeadsDashboardService.getConfig({ projectId: id, Mailid: this.userData.mail }).subscribe(
      (res: any) => {
        this.config = res.ProjectInfo[0].config[0];
        this.companyId = res.ProjectInfo[0].companyId;
        this.cardData = this.config.cards;
        this.feedback = this.config.feedback;
        this.columns = this.config.columns;
        for (let i = 0; i < this.columns.length; i++) {
          //change column name to lower case for the table column names
          this.columns[i].name = toLower(this.columns[i].name);

          if (this.columns[i].type == "Date") {
            this.columns[i].pipe = "date: 'mediumDate'";
          }
        }
        this.colData = this.config.columns;
        for (let i = 0; i < this.colData.length; i++) {
          const element = this.colData[i];
          element.Agentname = 'AgentName'
        }
        let tablecell = document.getElementById("tableCellID");
        tablecell.setAttribute("colspan", `4`);

        let tablecell1 = document.getElementById("tableCellID1");
        tablecell1.setAttribute("colspan", `${(this.colData.length + 2) - 4}`);

        this.getLeads();
        this.getAgents(this.projectId, this.companyId);
        this.getcomapnyDetails(res.ProjectInfo[0].companyId)
      }
    );
  }
  twilioNumber: any;
  getcomapnyDetails(CompanyId: any) {

    this.CreateCompanyService.getcompanyById(CompanyId).subscribe((Data: any) => {
      if (Data) {
        this.twilioNumber = Data.data.voipNumber
      }
    })
  }
  transformDate(date) {
    return this.datePipe.transform(date, "yyyy-MM-dd");
  }

  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  getLeads() {
    this.contentLoadedTable = false;
    this.LeadsDashboardService.getLeads2({
      projectId: this.projectId,
      companyId: this.companyId ? this.companyId : this.userData.NewCompanyId,
      card: { type: "total" },
      mail: this.userData.mail,
      start: this.startDate,
      end: this.endDate,
      limit: this.tableLimit,
      page: this.pageNumber,
    }).subscribe((res: any) => {
      this.contentLoadedTable = true;
      this.leads = res.data;
      this.leads.map(x => {
        if (x.gender && x.gender != "null") {
          x.gender = x.gender.charAt(0).toUpperCase();
        }
        // if(x.callstatus){
        //   x.callstatus = this.getCallStatus(x.callstatus);
        // }
      });
      this.replaceNull(this.leads);
      let newTotal = ~~(Number(res.count) / Number(this.tableLimit)) + 1;
      this.dashPaginationService.totalPages.next(Number(newTotal));
      for (let i = 0; i < this.leads.length; i++) {
        this.leads[i].callstatus = this.capitalizeFirstLetter(
          this.leads[i].callstatus
        );
        for (let j = 0; j < this.columns.length; j++) {
          if (this.columns[j].type == "Date") {
            this.leads[i].createddate = this.transformDate(
              this.leads[i].createddate
            );
            this.leads[i].updateddate = this.transformDate(
              this.leads[i].updateddate
            );
          }
        }
      }
    });
  }
  getCallStatus(status) {
    var obj = feedbacksArray.map(x => x.value == status);
    return obj.name;
  }
  getTopBarCounts() {
    this.LeadsDashboardService.getTopBarCounts({
      projectId: this.projectId,
      companyId: this.companyId,
      mail: this.userData.mail,
    }).subscribe((res: any) => {
      this.topBarCounts = res.counts;
    });
  }
  getLeadInfo(item) {
    let payload = {
      leadId: item.id,
      projectId: this.projectId,
      companyId: this.companyId,
    };
    this.LeadsDashboardService.getLeadInfo(payload).subscribe((res: any) => {
      this.leadInfo = res.lead;
      this.leadInfo.Activities = res.Activities;
      this.leadInfo.CallDetails = res.calldetails;
      this.leadInfo.LeadComments = res.LeadComments;
      this.leadInfo.videoCalldetails = res.videoCalldetails;
      this.documentsList2 = res.docDetails;
      Object.keys(this.leadInfo).map((key) => {
        this.leadInfo[key] === null || this.leadInfo[key] == 'null' || this.leadInfo[key] == "null" ? (this.leadInfo[key] = "-") : this.leadInfo[key];
      });
      this.documentsList2.forEach(function (a) {
        Object.keys(a).map((key) => {
          a[key] === null || a[key] == 'null' || a[key] == "null" ? (a[key] = "-") : a[key];
        });
      });
      this.leadInfo.CallDetails.forEach(function (a) {
        Object.keys(a).map((key) => {
          a[key] === null || a[key] == 'null' || a[key] == "null" ? (a[key] = "-") : a[key];
        });
      });
      this.leadInfo.videoCalldetails.forEach(function (a) {
        Object.keys(a).map((key) => {
          a[key] === null || a[key] == 'null' || a[key] == "null" ? (a[key] = "-") : a[key];
        });
      });
      this.checkValidation()
    });
  }
  getLeadInfoCall(item) {
    this.leadInfoCall = item;
  }
  sideNavData(item) {
    this.selectedLead = item
  }
  onInput(event) {
  }

  selectAllforAssignment() {
    if (this.selectedAll == false) {
      this.selectedAll = true;
      this.leadIds = [];
      for (let i = 0; i < this.leads.length; i++) {
        this.leadIds.push(this.leads[i].id);
      }
    } else if (this.selectedAll == true) {
      this.selectedAll = false;
      this.leadIds = [];
    }
  }

  selectAgentforAssignment(e, item) {
    console.log("Selected Lead: ", item)
    console.log("Checked: ", e.target.checked)
    if (e.target.checked) {
      this.leadIds.push(item.id);
      console.log(this.leadIds)

    } else if (!e.target.checked) {
      this.removeItem(this.leadIds, item.id);
    }
    console.log(this.leadIds)
  }

  removeItem(arr, value) {
    var index = arr.indexOf(value);
    if (index > -1) {
      arr.splice(index, 1);
    }
    return arr;
  }

  updateLead(modal, item) {
    this.LeadsDashboardService.lead.next(item);
    this.editFormData.controls = [];
    for (let i = 0; i < this.columns.length; i++) {
      this.editFormData.controls.push({
        name: this.columns[i].name,
        label: this.columns[i].displayName,
        value: item[this.columns[i].name],
        type: this.columns[i].type,
      });
    }
    this.leadData = {
      projectId: this.projectId,
      companyId: this.companyId,
      columns: this.columns,
      lead: item,
    };
    // this.modalService.open(modal, { size: "xl" });
  }

  addNewLead() {
    this.LeadsDashboardService.lead.next({ id: "new" });
  }

  downloadTemplate() {
    let colNames = [{}];
    console.log(this.colData)
    for (let i = 0; i < this.colData.length; i++) {

      colNames[0][this.colData[i].displayName] = ''
    }
    console.log(colNames)
    this.ExcelService.exportAsExcelFile(colNames, 'Leads_Upload');

    console.log("Generate Excel file")
  }

  uploadTemplate() {
    this.LeadsDashboardService.uploadFileInfo.next({ projectId: this.projectId, companyId: this.companyId });    
    let menuid = 0;
    this.router.navigate(['./upload/' + this.projectId+'/'+menuid]);
  }

  organise(arr) {
    var headers = [], // an Array to let us lookup indicies by group
      objs = [], // the Object we want to create
      i,
      j;
    for (i = 0; i < arr.length; ++i) {
      j = headers.indexOf(arr[i].id); // lookup
      if (j === -1) {
        // this entry does not exist yet, init
        j = headers.length;
        headers[j] = arr[i].id;
        objs[j] = {};
        objs[j].id = arr[i].id;
        objs[j].data = [];
      }
      objs[j].data.push(
        // create clone
        {
          case_worked: arr[i].case_worked,
          note: arr[i].note,
          id: arr[i].id,
        }
      );
    }
    return objs;
  }

  onSubmit(data) {
    if (this.myForm.valid) {
    } else {
    }
  }

  change(value) {
  }

  openCallModal() {
    this.callStatus = "Calling..";
    this.callData = this.leadInfoCall;
    console.log("🚀 ~ file: table.component.ts:602 ~ TableComponent ~ openCallModal ~ this.callData", this.callData);

    this.marketingId = {
      id: this.leadInfoCall.id,
      status: this.leadInfoCall.callstatus,
      name: this.leadInfoCall.firstname + " " + this.leadInfoCall.lastname,
      email: this.leadInfoCall.email,
      phoneNumber: "+" + this.leadInfoCall.countrycode.replace("+", "") + this.leadInfoCall.phone,
      module: "",
      city: "",
      nextCaller: null,
      lead: {},
    };
    this.LeadsDashboardService.startCall.next({
      phoneNumber: this.marketingId.phoneNumber,
      userData: this.userData,
      leadData: this.marketingId,
      projectId: this.projectId,
      companyId: this.companyId ? this.companyId : this.userData.NewCompanyId
    });
    // this.LeadsDashboardService.callLead.next(data);
  }

  stopCurrentCall() {

    this.countupTimerService.stopTimer();
    this.LeadsDashboardService.callLead.next(null)
    this.LeadsDashboardService.stopCall.next({
      phoneNumber: this.marketingId.phoneNumber,
    });
    this.CreateCompanyService.stopCall.next({
      phoneNumber: this.marketingId.phoneNumber,
      userData: this.userData,
      leadData: this.marketingId,
    });
    this.callStatus = "Call Ended";
    setTimeout(() => {
      this.callStatus = "";
    }, 3000);
  }

  resetCallingScreen() {
    this.callStatus = "";
  }

  updateComments() {
    if (!this.newStatus) {
    } else {
      this.LeadsDashboardService
        .updateCallComments({
          id: this.marketingId.id,
          comments: this.commentText,
          callStatus: this.newStatus,
          projectId: this.projectId,
          companyId: this.companyId,
          CallSid: this.callSid,
        })
        .subscribe((res) => {
          const config: MatDialogConfig = {
            panelClass: 'dialog-responsive',
            data: {
              imgSrc: '../../../assets/images/dialog_info.png',
              title: 'Update',
              header: 'Lead Update',
              message: 'Lead status has been updated successfully.',
              timeout: 3000
            }
          };
          this.dialog.closeAll();
          const dialogRef = this.dialog.open(Dialogs1Component, config);
          dialogRef.afterClosed().subscribe(result => {
            this.callingSideNav.close();
          });

          if (this.newStatus == 'qualifiedhotlead') {
            this.scheduleDemo();
          }
          let payload = {
            id: this.marketingId.id,
            projectId: this.projectId,
            companyId: this.companyId,
          }
          this.LeadsDashboardService.updateLeadDetails.next(payload);
          this.commentText = "";
        }, (error) => {
          this.handleError.handleError(error)
        });
    }
  }

  onStatusChange(newStatus) { }

  dropdownSelect(event) {
    this.statusValue = event;
    if (event == "all") {
      this.LeadsDashboardService.cardValue.next({
        type: "total",
        value: event,
      });
    } else {
      this.LeadsDashboardService.cardValue.next({
        type: "status",
        value: event,
      });
    }
  }
  toggleSort(column, order) {
    if (this.sortOrder == "asc") {
      this.sortOrder = "des";
    } else {
      this.sortOrder = "asc";
    }
    this.sortTableData(column, this.sortOrder);
  }
  sortTableData(column, order) {
    this.sortColumn = column;
    this.leads = sortJsonArray(this.leads, column, this.sortOrder);
  }
  updateTableLimit(e) {
    this.pageNumber = 1;
    this.dashPaginationService.pageNumber.next(1);
    this.getLeads();
  }

  assignAgentModal(assignLeadsModal) {
    console.log("Leads: ", this.leadIds)
    if (this.leadIds.length > 0) {
      this.defaultAgent = "default";
      this.selectedAgent = null;
      this.modalService
        .open(assignLeadsModal, { size: "md", windowClass: "assignmodalwidth" })
        .result.then(
          (result) => { },
          (reason) => { }
        );
    } else {
      const config: MatDialogConfig = {
        panelClass: 'dialog-responsive',
        data: {
          imgSrc: '../../../assets/images/dialog_info.png',
          title: 'Info',
          header: 'Assign Leads',
          message: 'Select leads to be assigned.',
          timeout: 3000
        }
      };
      this.dialog.closeAll();
      this.dialog.open(Dialogs1Component, config);
    }

  }

  agentSelected(selectedAgent) {
    this.selectedAgent = selectedAgent;
  }

  closeAssignModal() {
    this.closeAssignButton.nativeElement.click();
  }

  assignLEads() {
    if (this.leadIds.length == 0) {
      const config: MatDialogConfig = {
        panelClass: 'dialog-responsive',
        data: {
          imgSrc: '../../../assets/images/dialog_info.png',
          title: 'Info',
          header: 'Assign Leads',
          message: 'Select leads to be assigned.',
          timeout: 3000
        }
      };
      this.dialog.closeAll();
      this.dialog.open(Dialogs1Component, config);
      return;
    }
    if (this.selectedAgent) {
      let payload = {
        companyId: this.companyId,
        projectId: this.projectId,
        assignedBy: this.userData.mail,
        assignedTo: this.selectedAgent,
        LeadIds: this.leadIds
      }
      this.LeadsDashboardService.assignLeadsToAgent(payload).subscribe((res: any) => {
        if (res.status == 200) {
          document.getElementById("closeAssignButton").click();
          const config: MatDialogConfig = {
            panelClass: 'dialog-responsive',
            data: {
              imgSrc: '../../../assets/images/success-info.png',
              title: 'Success!',
              header: 'Assign Leads',
              message: 'Leads Assigned Successfully.',
              timeout: 3000
            }
          };
          this.dialog.closeAll();
          this.dialog.open(Dialogs1Component, config);
          this.getLeads();
          this.selectedAll == false;
        } else if (res.status == 500) {
          this.uhleadsservice.showError("Unable to assign Leads", 'Error');
        } else {
          this.uhleadsservice.showError("Something Went Wrong", 'Error');
        }
      })

    } else {
      const config: MatDialogConfig = {
        panelClass: 'dialog-responsive',
        data: {
          imgSrc: '../../../assets/images/dialog_info.png',
          title: 'Info',
          header: 'Assign Leads',
          message: 'Please select an agent.',
          timeout: 3000
        }
      };
      this.dialog.closeAll();
      this.dialog.open(Dialogs1Component, config);
      // this.uhleadsservice.showWarning("Please select an agent", "Alert!");
    }
  }

  // DocumentValidation 
  clearData() {

  }
  uploadCustomDocument(event) {
    let fileList: FileList = event.target.files;
    this.file = fileList[0];
    if (fileList.length > 0) {
      if (!this.validateFile(this.file.type)) {
        this.uhleadsservice.showError("Invalid file format!", "Error");
        return;
      }
    }
  }

  addCustomDocument() {

    this.docUploadError = "";
    if (this.customDocName == "") {
      this.docUploadError = "Document name cannot be empty!";
      return;
    } else {
      let formData: FormData = new FormData();
      formData.append("GUID", `leads_${this.companyId}_${this.projectId}_${this.selectedLead.id}`);
      formData.append("docName", this.customDocName);
      formData.append("Platform", "CRM");
      formData.append("MailId", this.userData.mail);
      formData.append("companyId", this.companyId);
      formData.append("leadid", this.selectedLead.id);
      formData.append("projectId", this.projectId);

      if (this.file != undefined) {
        formData.append("document", this.file, this.file.name);
      }
      this.uhleadsservice
        .uploadCustomDocumentv2(formData)
        .subscribe((res: any) => {
          // let response = res.map((d:any) =>{status: d.status});
          let response = res.status;
          if (response == 200) {
            this.customFileList.push({
              docName: this.customDocName,
              fileName: "",
            });
            this.docUploadError = "Custom document added";
            this.uhleadsservice.showSuccess(
              "Custom document added!",
              "Success"
            );
          } else if (response == 201) {
            this.customFileList.push({
              docName: this.customDocName,
              fileName: this.file.name,
            });
            this.docUploadError = "Uploaded a custom document";
            this.uhleadsservice.showSuccess(
              "Uploaded a custom document!",
              "Success"
            );
          } else if (response == 202) {
            this.docUploadError = "Document with given name already exists!";
            this.uhleadsservice.showError(
              "Document with given name already exists!",
              "Error"
            );
            return;
          }
          // this.sideNavData({ id: this.currentLeadId });
          let payload = {
            id: this.selectedLead.id,
            projectId: this.projectId,
            companyId: this.companyId,
          };
          this.getLeadInfo(payload)
          this.file = null;
          this.customDocName = "";
        }, (error) => {
          // this.handleError.handleError(error)
        });
    }
  }

  deleteCustomDocument(docId, docName) {
    this.uhleadsservice
      .deleteCustomDocument({
        StudentdocId: docId,
        docName: docName,
        Platform: "CRM",
        MailId: this.userData.mail,
      })
      .subscribe((res: any) => {
        let response = res.status;
        if (response == 200) {
          this.uhleadsservice.showSuccess(
            "Custom document deleted successfully.",
            "Deleted Successfully!"
          );
        } else {
          this.uhleadsservice.showError("Something went wrong!", "Error!");
        }
      }, (error) => {
        // this.handleError.handleError(error)
      });
    // this.sideNavData({ id: this.currentLeadId });
  }
  downLoadDocument(url, name) {
    const link = document.createElement("a");
    link.setAttribute("target", "_blank");
    link.setAttribute("type", "hidden");
    link.setAttribute("href", url);
    link.setAttribute("download", name);
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  validateFile(name: String) {
    var ext = name.substring(name.lastIndexOf(".") + 1);
    if (ext.toLowerCase() == "image/jpeg") {
      return true;
    } else if (ext.toLowerCase() == "document") {
      return true;
    } else if (ext.toLowerCase() == "image/png") {
      return true;
    } else if (
      name.substring(name.lastIndexOf("/") + 1).toLowerCase() == "pdf"
    ) {
      return true;
    } else {
      // window.alert("Please upload a valid file!");
      this.uhleadsservice.showError("Please upload a valid file!", "Error!");
      return false;
    }
  }
  docUrl: any;
  openPreview(content, url) {
    // this.docUrl = '';
    this.docUrl = url;
    this.modalService
      .open(content, {
        ariaLabelledBy: "modal-basic-title",
        windowClass: "myCustomModalClass",
      })
      .result.then(
        (result) => { },
        (reason) => { }
      );
  }
  contentLoaded() {
    document.getElementById("progressBar").style.display = "none";
  }

  changeValidation(value, docId) {
    this.uhleadsservice
      .updateValidationStatusv2({
        StudentdocId: docId,
        // admin: this.admin,
        admin: false,
        validationStatus: value,
        Platform: "CRM",
        MailId: this.userData.mail,
        projectId: this.projectId,
        companyId: this.companyId,
        leadid: this.selectedLead.id
      })
      .subscribe((res) => {
        // this.documentValidation(this.currentLeadId);
        let getLeadpayload = {
          id: this.selectedLead.id,
          projectId: this.projectId,
          companyId: this.companyId,
        };

        this.getLeadInfo(getLeadpayload);
        this.checkValidation();

        this.uhleadsservice.showSuccess("Document Status updated!", "Success!");
        let payload = {
          AgentMail: this.userData.mail,
          GBPAssignedTo: "",
          GUID: "",
          LeadId: 0,
          Platform: "CRM",
          StudentDocId: docId,
          Title: "Document Validation",
          description:
            "Document status changed to " +
            value +
            " by " +
            this.userData.firstName + " " + this.userData.lastName,
          notificationType: "Validation",
          token: this.userData.token,
        };

        this.NotificationService.sendNotifications(payload).subscribe(
          (res: any) => {
            if (res.status === 200) {
            }
          }
        );
      }, (error) => {
        // this.handleError.handleError(error)
      });
  }

  checkValidation() {
    if (this.documentsList2.length == 0) {
      this.validationStatus = false;
      return;
    }
    for (var i = 0; i < this.documentsList2.length; i++) {
      if (
        this.documentsList2[i].AgentValidation == "revalidate" ||
        this.documentsList2[i].AgentValidation == "-"
      ) {
        this.validationStatus = false;
        return;
      } else if (this.documentsList2[i].AgentValidation == "validated") {
        this.validationStatus = true;
        //AddNotification
      } else {
        this.validationStatus = false;
      }
    }
    if (this.validationStatus == true) {
      // Send Notification

      let payload = {
        AgentMail: "ramana@universityhub.com",
        GBPAssignedTo: "",
        GUID: "12356yyu98",
        LeadId: 0,
        Platform: "CRM",
        StudentDocId: 0,
        Title: "Documents Validated",
        description: "All the docuiments have been validated",
        notificationType: "alldocuments",
        token: this.userData.token,
      };

      let payload2 = {
        AgentMail: "ramana@universityhub.com",
        GBPAssignedTo: "",
        GUID: "12356yyu98",
        LeadId: 0,
        Platform: "CRMGBP",
        StudentDocId: 0,
        Title: "Documents Validated",
        description: "All the docuiments have been validated",
        notificationType: "alldocuments",
        token: this.userData.token,
      };

      // this.NotificationService.sendNotifications(payload).subscribe(
      //   (res: any) => {
      //     if (res.status === 200) {
      //       this.uhleadsservice.showSuccess(
      //         "All Documents Validated",
      //         "Validation Complete"
      //       );
      //       this.NotificationService.success("Validation", "Notification sent");
      //     }
      //   }
      // );
      // this.NotificationService.sendNotifications(payload2).subscribe(
      //   (res: any) => {
      //     if (res.status === 200) {
      //       // this.uhleadsservice.showSuccess("All Documents Validated", "Validation Complete");
      //       this.NotificationService.success(
      //         "Validation",
      //         "Notification sent to GBP"
      //       );
      //     }
      //   }
      // );
    }
  }

  showmore(itemsCount) {
    if (itemsCount < this.minItems) {
    } else if (this.minItems + 5 < itemsCount) {
      this.minItems = this.minItems + 5;
    } else if (this.minItems < itemsCount) {
      this.minItems = itemsCount;
    }
  }
  onInputActivity(event: any) {
    console.log("event: ", event.value);
    if (this.activityfilterString != "") {
      this.minItems = event;
    } else {
      this.minItems = 5;
    }
  }

  fileUpload(event, item) {

    let fileList: FileList = event.target.files;
    let file: File = fileList[0];
    if (fileList.length > 0) {
      if (!this.validateFile(file.type)) {
        return;
      }
      let formData: FormData = new FormData();
      formData.append("studentdocid", item.StudentdocId);
      formData.append("document", file, file.name);
      formData.append("Platform", "CRM");
      formData.append("MailId", this.userData.mail);
      formData.append("companyId", this.companyId);
      formData.append("leadid", this.selectedLead.id);
      formData.append("projectId", this.projectId);

      // this.uhleadsservice
      //   .uploadDocument(formData, item.StudentdocId)
      //   .subscribe((res) => {
      //     this.uhleadsservice.showSuccess("File upload successful!", "Success");
      //     // this.sideNavData({ id: this.currentLeadId });
      //   }, (error) => {
      //     // this.handleError.handleError(error)
      //   });
    }


  }
  clearSelectedFiles(item) {
    this.docUploadID = item.StudentdocId;
    this.docType = item.ProgramDoc;
    this.docUploadStatus = false;
    this.selectedFiles = [];
    this.docUploadError2 = "Select files to upload.";
    this.uploadSuccess = "none";

    var x = document.getElementsByClassName(
      "docUploadError"
    ) as HTMLCollectionOf<HTMLElement>;
    for (let i = 0; i < x.length; i++) {
      x[i].style.color = "green";
    }
  }
  deleteFile(i) {
    this.selectedFiles.splice(i, 1);
  }
  uploadAttachments() {

    let check = true;
    this.selectedFiles.forEach((element) => {
      if (this.validateFile(element.type) == false) {
        this.docUploadError2 =
          "One or more files you uploaded is not a valid format. Please upload either doc, pdf, png or JPG/JPEG format.";
        check = false;
        var x = document.getElementsByClassName(
          "docUploadError"
        ) as HTMLCollectionOf<HTMLElement>;
        for (let i = 0; i < x.length; i++) {
          x[i].style.color = "red";
        }
      }
    });
    if (check == true) {
      var x = document.getElementsByClassName(
        "docUploadError"
      ) as HTMLCollectionOf<HTMLElement>;
      for (let i = 0; i < x.length; i++) {
        x[i].style.color = "green";
      }
      this.docUploadError2 =
        "Compressing your documents. Please wait for the process to finish";
      this.docUploadStatus = true;

      let formData: FormData = new FormData();
      formData.append("studentdocid", this.docUploadID);
      Array.from(this.selectedFiles).forEach((file) =>
        formData.append("document", file, file.name)
      );
      formData.append("Platform", "CRM");
      formData.append("MailId", this.userData.mail);
      formData.append("companyId", this.companyId);
      formData.append("leadid", this.selectedLead.id);
      formData.append("projectId", this.projectId);
      formData.append("type", this.docType);

      this.LeadsDashboardService
        .uploadDocumentV2(formData, this.docUploadID)
        .subscribe((res: any) => {
          if (res.status == 200) {
            this.uploadSuccess = "success";
            this.uhleadsservice.showSuccess(
              res.msg,
              "Success"
            );
            this.docUploadStatus = false;
            let payload = {
              id: this.selectedLead.id,
              projectId: this.projectId,
              companyId: this.companyId,
            };
            this.getLeadInfo(payload)
            // document.getElementById("closeButton4").click();
            try {
              // document.getElementById("closeButton4").click();
            } catch (err) {
              this.uploadSuccess = "failure";
            }
          }
        }, (error) => {
          this.handleError.handleError(error)
        });
    }
  }
  cancelUpload() {
    document.getElementById("closeButton4").click();
  }
  selectFiles() {
    const frmData = new FormData();

    for (var i = 0; i < this.selectedFiles.length; i++) {
      frmData.append("fileUpload", this.selectedFiles[i]);
    }
  }

  getFileDetails(e) {
    for (var i = 0; i < e.target.files.length; i++) {
      this.selectedFiles.push(e.target.files[i]);
    }
    if (this.selectedFiles.length > 0) {
      this.docUploadError2 = "Click on upload to start uploading your files";

      var x = document.getElementsByClassName(
        "docUploadError"
      ) as HTMLCollectionOf<HTMLElement>;
      for (let i = 0; i < x.length; i++) {
        x[i].style.color = "green";
      }
    }
  }
  postComments(item, HTMLid) {
    this.documentComments = (<HTMLInputElement>(
      document.getElementById(HTMLid)
    )).value;
    var body = {
      ProjectId: this.projectId,
      GUID: item.StudentGUID,
      StudentDocId: item.StudentdocId,
      Text: this.documentComments,
      Admin: false,
      LeadId: this.selectedLead.id,
      AgentMail: this.userData.mail,
      Platform: "CRM",
    };

    if (HTMLid == "leadComments") {
      body.StudentDocId = null;
    }

    this.LeadsDashboardService.postDocumentComment(body).subscribe((res) => {
      // this.documentValidation(this.selectedLead.id);
      // this.sideNavData(item);
      let payload = {
        id: this.selectedLead.id,
        projectId: this.projectId,
        companyId: this.companyId,
      };
      this.getLeadInfo(payload)
      this.uhleadsservice.showSuccess("Comment Posted!", "Success!");
      (<HTMLInputElement>document.getElementById(HTMLid)).value = "";

      // let payload = {
      //   LeadId: 0,
      //   GBPAssignedTo: "",
      //   Platform: "CRM",
      //   GUID: item.StudentGUID,
      //   StudentDocId: 0,
      //   notificationType: "LeadComments",
      //   description: this.getAgentName(this.userData.mail) + " has commented",
      //   AgentMail: this.userData.mail,
      //   Title: "Comment",
      //   token: this.userData.token,
      // };

      // if (HTMLid == "leadComments") {
      //   payload.StudentDocId = 0;
      // } else if (HTMLid == "documentComments" || HTMLid == "callingComments") {
      //   payload.StudentDocId = item.StudentdocId;
      // }
      // try {
      //   this.NotificationService.sendNotifications(payload).subscribe(
      //     (res: any) => {
      //       if (res.status === 200) {
      //         // this.toastr.showSuccess('Successfully send notification', 'Notification')
      //         // this.NotificationService.success('success', 'loading component');
      //       } else if (res.status == 500) {
      //       }
      //     }, (error) => {
      //       this.handleError.handleError(error)
      //     });
      // } catch (err) {
      //   console.log("Notification not sent- Error :", err);
      // }
    }, (error) => {
      this.handleError.handleError(error)
    });
  }
  documentValidation(id) {
    this.uhleadsservice.getDocumentComments(id).subscribe((res) => {
      this.documentsList = res;
      this.documentsList = this.documentsList.uploadlistofdocuments;
      console.log("document list", this.documentsList);

      this.documentsList.forEach(function (a) {
        Object.keys(a).map((key) => {
          a[key] === null ? (a[key] = "-") : a[key];
        });
      });
      this.checkValidation();
      // for (var i = 0; i < this.documentsList2.length; i++) {
      //   for (var j = 0; j < this.documentsList2[i].DocComments.length; j++) {
      //     for (var k = 0; k < this.totalAgents.length; k++) {
      //       if (
      //         this.totalAgents[k].Mailid ==
      //         this.documentsList2[i].DocComments[j].AgentMail
      //       ) {
      //         this.agentName =
      //           this.totalAgents[k].FirstName +
      //           " " +
      //           this.totalAgents[k].LastName;
      //       }
      //     }
      //     this.documentsList2[i].DocComments[j].AgentMail = this.agentName;
      //   }
      // }
    }, (error) => {
      this.handleError.handleError(error)
    });
  }
  postCommentsV2(item, HTMLid) {
    this.documentComments = (<HTMLInputElement>(
      document.getElementById(HTMLid)
    )).value;
    var body = {
      ProjectId: this.projectId,
      GUID: `leads_${this.companyId}_${this.projectId}_${this.selectedLead.id}`,
      StudentDocId: null,
      Text: this.documentComments,
      Admin: this.isAdmin,
      LeadId: this.selectedLead.id,
      AgentMail: this.userData.mail,
      Platform: "CRM",
    };

    if (HTMLid == "leadComments") {
      body.StudentDocId = null;
    }

    this.LeadsDashboardService.postDocumentComment(body).subscribe((res) => {
      // this.documentValidation(this.selectedLead.id);
      // this.sideNavData(item);
      let payload = {
        id: this.selectedLead.id,
        projectId: this.projectId,
        companyId: this.companyId,
      };
      this.getLeadInfo(payload)
      this.uhleadsservice.showSuccess("Comment Posted!", "Success!");
      (<HTMLInputElement>document.getElementById(HTMLid)).value = "";

      // let payload = {
      //   LeadId: 0,
      //   GBPAssignedTo: "",
      //   Platform: "CRM",
      //   GUID: item.StudentGUID,
      //   StudentDocId: 0,
      //   notificationType: "LeadComments",
      //   description: this.getAgentName(this.userData.mail) + " has commented",
      //   AgentMail: this.userData.mail,
      //   Title: "Comment",
      //   token: this.userData.token,
      // };

      // if (HTMLid == "leadComments") {
      //   payload.StudentDocId = 0;
      // } else if (HTMLid == "documentComments" || HTMLid == "callingComments") {
      //   payload.StudentDocId = item.StudentdocId;
      // }
      // try {
      //   this.NotificationService.sendNotifications(payload).subscribe(
      //     (res: any) => {
      //       if (res.status === 200) {
      //         // this.toastr.showSuccess('Successfully send notification', 'Notification')
      //         // this.NotificationService.success('success', 'loading component');
      //       } else if (res.status == 500) {
      //       }
      //     }, (error) => {
      //       this.handleError.handleError(error)
      //     });
      // } catch (err) {
      //   console.log("Notification not sent- Error :", err);
      // }
    }, (error) => {
      this.handleError.handleError(error)
    });
  }
  audioPlay(e) {
    let eAudio = this.domService.getElementsByTagName("audio");
    if (eAudio && eAudio.length > 0) {
      for (var i = 0; i < eAudio.length; i++) {
        if (e.target !== eAudio[i]) {
          eAudio[i].pause();
        }
      }
    }
  }
  openDialog() {
    this.dialog.open(DialogElementsExampleDialog);
  }
  startTimer() {
    let cdate = new Date();
    cdate.setHours(cdate.getHours());
    this.countupTimerService.startTimer(cdate);
  }
  getProperId(id) {
    if (this.pageNumber == 1) {
      return id + 1;
    } else if (this.pageNumber > 1) {
      return ((id + 1) + (Number(this.pageNumber) - 1) * 10);
    } else {

    }
  }
  getValueBasedOnColumn(i, c) {
    if (c.displayName == 'Age') {
      var age = Math.floor((new Date().getTime() - new Date(i['dateofbirth']).getTime()) / 31557600000);
      if (age > 0) {
        return age;
      } else {
        return 0;
      }
    } else if (c.name == "countrycode") {
      if (i[c.name] != null && i[c.name] != 'null' && i[c.name] != '-') {
        return "+" + i[c.name].replace("+", "");
      } else {
        return i[c.name];
      }
    }
    else {
      return i[c.name];
    }
  }
  replaceNull(a: any) {
    a.map(x => {
      Object.keys(x).map((key) => {
        x[key] == null || x[key] == 'null' ? (x[key] = "-") : x[key];
      });
    })
  }
  scheduleDemo() {
    console.log(this.editFormData);
    let dt = moment(this.counsellingDate).format("dddd D MMM YY") + " at " + moment(this.counsellingDate).format("h:m a");
    let num = Math.floor(Math.random() * 90000) + 10000;
    let room = "live-" + num.toString();
    let smsId = null;
    let callId = null;
    let mailId = null;
    let demoScheduled = `${environment.BaseURL}videocalling/mainscreen/V2/${this.userData.NewCompanyId}/${room}`;

    let obj1: any = {};
    obj1.ProjectId = this.userData.NewCompanyId;
    obj1.AutoEmailId = mailId ? mailId["mailId"] : null;
    obj1.AutoSMSId = smsId ? smsId["smsId"] : null;
    obj1.Comments = null;
    obj1.DateTime = moment(this.counsellingDate, "YYYY-MM-DDTHH:mm:SSZ").format("YYYY-MM-DDTHH:mm:SSZ");
    obj1.Presenter = this.userData.mail;
    obj1.Feedback = 'qualifiedhotlead';
    obj1.Url = demoScheduled + "/" + this.userData.workerFriendlyName;
    obj1.RoomName = room,
      obj1.LeadId = this.marketingId.id;
    obj1.agentEmail = this.userData.mail;
    obj1.Name = this.leadInfoCall.firstname;
    obj1.ClientURL = demoScheduled + "/" + this.leadInfoCall.firstname;
    // this.editFormData.controls.map(x => {
    //   if (x.name == "firstname") {
    //     obj1.Name = x.value;
    //     obj1.ClientURL = demoScheduled + "/" + x.value;
    //   }
    // });
    this.marketingService
      .createDemo(obj1)
      .subscribe((demo) => {
        let obj = {
          userURL: "",
          agentURL: "",
          userMail: "",
          agentMail: "",
          from: "",
          projectId: "",
          student_name: "",
          agent_name: "",
          tosms: "",
          demoDate: ""
        }
        let mobileNumber = '';
        obj.student_name = this.leadInfoCall.firstname;
        obj.userURL = demoScheduled + "/" + this.leadInfoCall.firstname;
        obj.userMail = this.leadInfoCall.email;
        if (this.leadInfoCall.countrycode) {
          mobileNumber = "+" + mobileNumber + this.leadInfoCall.countrycode.replace("+", "");
        }
        if (this.leadInfoCall.phone) {
          mobileNumber = mobileNumber + this.leadInfoCall.phone
        }
        obj.from = "+" + this.twilioNumber.replace("+", "");
        obj.agentMail = obj1.agentEmail;
        obj.agentURL = obj1.Url;
        obj.projectId = this.userData.NewCompanyId;
        obj.agent_name = this.userData.firstName;
        obj.demoDate = dt;
        obj.tosms = mobileNumber;
        this.LeadsDashboardService.videoCallMailSms(obj).subscribe(x => {

        });
      });
  }
}

@Component({
  selector: 'dialog-elements-example-dialog',
  templateUrl: '../dialogs/InviteAgents/inviteAgents.component.html',
  styleUrls: ['../dialogs/InviteAgents/inviteAgents.component.css']
})
export class DialogElementsExampleDialog { }
