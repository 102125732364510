<mat-sidenav id="sideNav" #sideNav mode="side" class="pr" opened="false" position="end" class="pf w45 z-top">
  <div class="modal-header d-flex pf w45" style="right: 0; top: 0; z-index: 9999">
    <div class="d-flex align-items-center">
      <div class="close hand" (click)="sideNav.toggle()">
        <span aria-hidden="true">&times;</span>
      </div>
      <div class="modal-title mr-auto ml-2">Overview</div>
    </div>
  </div>
  <mat-tab-group class="mt-5 sideDataViewer" #sidecartabgroup animationDuration="0ms">
    <mat-tab label="Call Details">
      <div class="alert alert-info m-2">
        {{ sideViewerData?.CallDetails?.length }} Calls
      </div>
      <div *ngFor="let i of sideViewerData.CallDetails" class="mx-2 my-2">
        <table class="table table-borderless table-md caller-table my-1">
          <tr>
            <td>Called by</td>
            <td>{{ i?.AgentName }}</td>
          </tr>
          <tr>
            <td>Date & Time</td>
            <td>{{ i?.DateTime | date: "dd MMM, yyyy hh:mm" }}</td>
          </tr>
          <tr>
            <td>Status</td>
            <td>
              <span *ngIf="i.CallStatus == 'pending'">Pending</span>
              <span *ngIf="i.CallStatus == 'uncontactable'">Uncontactable</span>
              <span *ngIf="i.CallStatus == 'notinterested'">Not Interested</span>
              <span *ngIf="i.CallStatus == 'completed'">Completed</span>
              <span *ngIf="i.CallStatus == 'callback'">Call Back</span>
              <span *ngIf="i.CallStatus == 'informed'">Informed</span>
              <span *ngIf="i.CallStatus == 'called'">Called</span>
              <span *ngIf="i.CallStatus == 'callfailed'">Wrong Number</span>
              <span *ngIf="i.CallStatus == 'deferredintake'">Deferred Intake</span>
              <span *ngIf="
                                    i.CallStatus == 'callnotanswered' ||
                                    i.CallStatus == 'notanswered'
                                  ">Not Answered</span>
              <span *ngIf="
                                    i.CallStatus == 'deferred' ||
                                    i.CallStatus == 'Deferred'
                                  ">Deferred Intake</span>
            </td>
          </tr>
          <tr>
            <td>Direction</td>
            <td *ngIf="i.Direction=='inbound'">InComing Call</td>
            <td *ngIf="i.Direction=='outbound'">OutGoing Call</td>
            <!-- <td>{{i.Direction ||'-'}}</td> -->
          </tr>
          <tr>
            <td style="padding-top: 20px">Call Recording</td>
            <td><audio *ngIf="i.RecordingURL != '-'" style="height: 2.5rem; width: 15srem" [src]="i.RecordingURL"
                controls controlsList="nodownload" (play)="audioPlay($event)"></audio>
            </td>
          </tr>
          <tr>
            <td>Comments</td>
            <td>{{ i?.Comments }}</td>
          </tr>
        </table>
        <hr class="hr-style" />
      </div>
    </mat-tab>

    <mat-tab label="Student Details">
      <div class="mx-2 my-2">
        <table class="table table-borderless table-md caller-table my-1">
          <tr>
            <td>GUID</td>
            <td>{{ sideViewerData?.GUID }}</td>
          </tr>
          <tr>
            <td>Name</td>
            <td>
              {{
              sideViewerData?.FirstName + " " + sideViewerData?.LastName
              | titlecase
              }}
            </td>
          </tr>
          <tr>
            <td>DOB</td>
            <td>{{ sideViewerData?.DOB }}</td>
          </tr>
          <tr>
            <td>Submitted Date</td>
            <td>{{ sideViewerData?.createdAt | date: "medium" }}</td>
          </tr>
          <tr>
            <td>APP Status</td>
            <td>{{ sideViewerData?.APPStatus | titlecase }}</td>
          </tr>
          <tr>
            <td>Enrollment Year</td>
            <td>{{ sideViewerData?.EnrollmentYear || "NA" }}</td>
          </tr>
          <tr>
            <td>Program</td>
            <td>{{ sideViewerData?.Program }}</td>
          </tr>
          <tr>
            <td>Phone Number</td>
            <td>
              {{
              "+" + sideViewerData?.CountryCode + sideViewerData?.PhoneNumber
              }}
            </td>
          </tr>
          <tr>
            <td>Email</td>
            <td>{{ sideViewerData?.Email }}</td>
          </tr>
          <tr>
            <td>Address</td>
            <td>
              {{
              sideViewerData?.Address +
              " " +
              sideViewerData?.City +
              " " +
              sideViewerData?.State +
              " " +
              sideViewerData?.Zip +
              " " +
              sideViewerData?.Country || "NA" | titlecase
              }}
            </td>
          </tr>
        </table>
      </div>
    </mat-tab>

    <mat-tab label="Document Validation">
      <div class="mx-2 my-2">
        <div class="mx-2 my-2">
          <div class="border-bottom" *ngFor="let item of documentsList2">
            <div class="d-flex mb-1">
              <div class="py-2 d-flex flex-column">
                <img *ngIf="item.uploadedDate != '-'" src="./assets/img/docs-r.svg" style="width: 16px" />
                <img *ngIf="item.uploadedDate == '-'" src="./assets/img/doc-grey-2.svg" style="width: 16px" />
              </div>
              <div class="p-2 d-flex flex-column w200">
                <div class="sub-header-md mr-3 d-flex align-items-center">
                  <div style="line-height: 160%;">{{ item.ProgramDoc |titlecase }}</div>
                  <button *ngIf="item.docType=='customDoc'" style="background: none;"
                    (click)="deleteCustomDocument(item.StudentdocId,item.ProgramDoc)">
                    <img class="img12" style="width:10px" src="../../../assets/img/delete.svg" />
                  </button>

                </div>

                <div class="sub-date" *ngIf="item.uploadedDate != '-'">
                  {{ item?.uploadedDate | date: "medium" }}
                </div>
              </div>
              <div>
                <div>
                  <span class="badge badge-pill badge-success mt-3"
                    *ngIf="item.AgentValidation == 'validated'">Validated</span>
                  <span class="badge badge-pill badge-warning mt-3" *ngIf="item.AgentValidation == 'revalidate'">Re
                    Validate</span>
                </div>

                <ng-template #validation11>
                  <form #form="ngForm">
                    <div style="width: 185px; font-size: 12px">
                      <div>
                        <div class="form-check form-check-inline">
                          <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1"
                            value="validated" (change)="
                                              changeValidation(
                                                $event.target.value,
                                                item.StudentdocId
                                              )
                                            " />
                          <label class="form-check-label" for="inlineRadio1">Validated</label>
                        </div>
                        <div class="form-check form-check-inline">
                          <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2"
                            value="revalidate" (change)="
                                              changeValidation(
                                                $event.target.value,
                                                item.StudentdocId
                                              )
                                            " />
                          <label class="form-check-label" for="inlineRadio2">Re Validate</label>
                        </div>
                      </div>
                    </div>
                  </form>
                </ng-template>

                <ng-template #comments11>
                  <form #form="ngForm">
                    <div class="d-flex align-items-end" style="width: 250px">
                      <div class="flex-grow-1">
                        <textarea class="form-control ct" type="text" #documentComment id="documentComments" rows="2"
                          placeholder="Write comments here..."></textarea>
                      </div>
                      <button type="button" class="btn btn-link ml-1 flex-shrink-1" style="padding: 0"
                        (click)="postComments(item, 'documentComments')">
                        <img src="./assets/img/send-message.svg" style="width: 16px" />
                      </button>
                    </div>
                  </form>
                </ng-template>
              </div>
              <div class="ml-auto p-2">
                <button *ngIf="item.uploadedDate != '-'" class="btn btn-light btn-sm mr-2"
                  (click)="downLoadDocument(item.StudentDocPath, item.ProgramDoc)">
                  <img src="../../../assets/img/download.svg" style="width: 16px" />
                </button>

                <input hidden type="file" #uploadFile (change)="fileUpload($event, item)" />

                <button [disabled]="sideViewerData.LeadDocStatus== 'completed'" data-toggle="modal"
                  data-target="#uploadFiles" placement="right" tooltipClass="my-custom-class"
                  class="btn btn-light btn-sm mr-2" (click)="clearSelectedFiles(item)">
                  <img src="./assets/img/file-upload.svg" style="width: 16px" />

                </button>
                <button type="button" class="btn btn-light btn-sm mr-2"
                  (click)="openPreview(pdfContent, item.StudentDocPath)" [disabled]="item.StudentDocPath == '-'">
                  <img src="./assets/img/view2.svg" style="width: 16px" />
                </button>
                <button type="button" class="btn btn-light btn-sm mr-2" placement="bottom" [ngbPopover]="validation11"
                  [disabled]="item.StudentDocPath == '-' || sideViewerData.LeadDocStatus== 'completed'">
                  <img *ngIf="item.AgentValidation == '-'" src="./assets/img/tick-g.svg" style="width: 16px" />
                  <img *ngIf="item.AgentValidation == 'validated'" src="./assets/img/ticker-green.svg"
                    style="width: 16px" />
                  <img *ngIf="item.AgentValidation == 'revalidate'" src="./assets/img/ticker-red.svg"
                    style="width: 16px" />
                </button>
                <button type="button" class="btn btn-light btn-sm mr-2" placement="left" [ngbPopover]="comments11"
                  [disabled]="disabled" ngbTooltip="Add Comment">
                  <img src="./assets/img/comments.svg" style="width: 16px" />
                </button>
                <button type="button" data-toggle="modal" data-target="#exampleModal2" class="btn btn-light btn-sm mr-2"
                  (click)="callFromDocs(sideViewerData, item)">
                  <img src="./assets/img/calls-g.svg" style="width: 16px" />
                </button>
              </div>
            </div>

            <!--Comment section comes here-->
            <mat-expansion-panel *ngIf="item.DocComments.length > 0">
              <mat-expansion-panel-header>
                <mat-panel-title> Comments </mat-panel-title>
              </mat-expansion-panel-header>

              <div class="comments-section" *ngFor="let i of item.DocComments">
                <div class="admin-comment" *ngIf="i.Admin == true">
                  <div class="d-flex">
                    <div>
                      <div *ngIf="i.AgentName" class="pic mr-1 float-left text-center">
                        <img *ngIf="i.ProfilePhoto != null" src="{{ i.ProfilePhoto }}" style="width: 100%;
                        height: 100%;
                        object-fit: cover;
                        border-radius: 100px;" />

                        <p [ngStyle]="{ background: i.AgentColor }" class="initials" *ngIf="i.ProfilePhoto == null">
                          {{ i?.AgentInitials }}
                        </p>
                      </div>
                    </div>
                    <div class="pl-2 pr-2 d-flex flex-column flex-grow-1">
                      <div class="d-flex">
                        <div class="sub-header-sm mr-3 flex-fill">
                          {{ i?.AgentName }}
                        </div>
                        <div class="sub-date float-right ml-auto" style="white-space: nowrap">
                          {{ i?.DateTime | date: "medium" }}
                        </div>
                      </div>
                      <div class="sub-text flex-grow-1">{{ i?.Text }}</div>
                    </div>
                  </div>
                </div>

                <div class="agent-comment" *ngIf="i.Admin == false">
                  <div class="d-flex">
                    <div>
                      <div *ngIf="i.AgentName" class="pic mr-1 float-left text-center">
                        <img *ngIf="i.ProfilePhoto != null" style="width: 100%;
                        height: 100%;
                        object-fit: cover;
                        border-radius: 100px;" src="{{ i.ProfilePhoto }}" />

                        <p [ngStyle]="{ background: i.AgentColor }" class="initials" *ngIf="i.ProfilePhoto == null">
                          {{ i?.AgentInitials }}
                        </p>
                      </div>
                    </div>
                    <div class="pl-2 pr-2 d-flex flex-column">
                      <div class="d-flex">
                        <div class="sub-header-sm mr-3">{{ i?.AgentName }}</div>
                        <div class="sub-date float-right ml-auto" style="white-space: nowrap">
                          {{ i?.DateTime | date: "medium" }}
                        </div>
                      </div>
                      <div class="sub-text">{{ i?.Text }}</div>
                    </div>
                  </div>
                </div>
                <div style="clear: both"></div>
              </div>
            </mat-expansion-panel>
          </div>
          <div class="py-2">
            <button [disabled]="sideViewerData.LeadDocStatus == 'completed'" data-toggle="modal"
              data-target="#exampleModal4" ngbTooltip="Add New Document" placement="right"
              tooltipClass="my-custom-class" class="btn btn-primary btn-sm" (click)="clearData()"
              style="padding: 5px 5px">
              <img src="./assets/img/add-w.svg" style="width: 10px" />
              Add
            </button>
          </div>
        </div>
      </div>
      <div *ngIf="validationStatus == true" class="text-center mb-2">
        <button class="btn btn-success btn-sm mt-2" disabled style="width: 180px">
          <img src="./assets/img/green-tick-4.svg" style="width: 16px" />
          Validation Completed
        </button>
      </div>
      <div *ngIf="validationStatus == false" class="text-center mb-2">
        <button class="btn btn-danger btn-sm mt-2" disabled style="width: 180px">
          <img src="../assets/img/close-r.svg" style="width: 16px" /> Not
          Validated
        </button>
      </div>

      <div class="p-3">
        <!--Comments 2 section-->
        <div class="header-md">
          Comments
          <span style="font-size: 12px">{{
            "(" + (sideViewerData?.LeadComments?.length || "0") + ")"
            }}</span>
        </div>
        <div class="comments-block" style="background: #fff">
          <div class="mb-3 pb-1 ct pr" style="background: #f5f5f5; border-radius: 5px">
            <textarea class="form-control ct p-2 mb-1" style="background: #f5f5f5" id="leadComments" rows="2"
              placeholder="Write comments here..."></textarea>
            <button type="button" class="btn btn-primary btn-sm mr-2 mb-1 float-right" style="padding: 5px 5px"
              (click)="postComments(sideViewerData, 'leadComments')">
              Submit
            </button>
            <div style="clear: both"></div>
          </div>
          <div class="m-h200">
            <div class="d-flex mb-2 mt-1" *ngFor="let j of sideViewerData.LeadComments">
              <div class="d-flex border-bottom pt-1" style="width: 100%">
                <div class="d-flex" style="width:100%">
                  <div class="">
                    <div *ngIf="j.AgentName" class="pic mr-1 float-left text-center">
                      <img *ngIf="j.ProfilePhoto != null" style="width: 100%;
                      height: 100%;
                      object-fit: cover;
                      border-radius: 100px;" src="{{ j.ProfilePhoto }}" />

                      <p [ngStyle]="{ background: j.AgentColor }" class="initials" *ngIf="j.ProfilePhoto == null">
                        {{ j?.AgentInitials }}
                      </p>
                    </div>
                  </div>
                  <div class="d-flex flex-column">
                    <div class="flex-grow-1">
                      <div class="sub-header-sm mr-3 mt-1 pb-1 flex-fill">
                        {{ j?.AgentName }}
                      </div>
                    </div>
                    <div class="sub-text flex-grow-1 pb-2">
                      {{ j?.Text }}
                    </div>
                  </div>
                  <div class="sub-date float-right ml-auto" style="white-space: nowrap">
                    {{ j?.DateTime | date: "medium" }}
                  </div>
                </div>


                <!-- <div>
                  <div class="img-circle2">
                    <img src="./assets/img/admin-comments.svg" style="width: 10px" />
                  </div>
                </div> -->

              </div>
              <!--
            <div>
              <div class="img-circle2 mr-2"><img src="./assets/img/admin-commentsx.svg" style="width:12px;" />
              </div>
            </div>
            <div class="d-flex flex-column">
              <div class="sub-header-md2 mb-1">
                {{j.Text}}
              </div>
              <div class="sub-date">{{j.DateTime | date:'medium'}}</div>
            </div>
          -->
            </div>
          </div>
        </div>
      </div>
    </mat-tab>

    <mat-tab label="Communication">
      <div class="mx-2 my-2">
      </div>
      <div xxstyle="position:absolute; left:15px;right:15px;bottom:0; height:100px;">
        <div class="mb-3 pb-1 ct pr" style="background:#f5f5fa;border-radius: 10px;
          margin: 0 20px 0 10px;">
          <textarea class="form-control ct p-2 mb-1" style="background: #f5f5f5" id="textMessage" rows="2"
            placeholder="Write your message here..."></textarea>
          <button type="button" class="btn btn-link btn-sm mr-2 mb-1 float-right" style="padding: 0 5px"
            (click)="sendSms(sideViewerData, 'textMessage')">
            <img src="../../../assets/img/send-message.svg" class="img12">
          </button>
          <div style="clear: both"></div>
        </div>
      </div>
      <div class="p-3">
        <!--Comments 2 section-->
        <div class="header-md">
          Total Messages
          <span style="font-size: 12px">{{
            "(" + (smsList?.length || "0") + ")"
            }}</span>
        </div>
        <div class="comments-block" style="background: #fff">
          <div class="dyn-height">
            <div class="d-flex mb-0 mt-1" *ngFor="let j of smsList">
              <div class="d-flex pt-1" style="width: 100%">
                <div style="width:100%">

                  <div class="admin-comment"
                    style="border:1px solid #f5e7e7;border-radius:10px; border-bottom-right-radius: 0;"
                    *ngIf="j.Direction == 'inbound'">
                    <div class="d-flex">
                      <div>
                        <div class="pic mr-1 float-left text-center">
                          <img src="../../../assets/img/student.svg" style="width: 100%;
                          height: 100%;
                          object-fit: cover;
                          border-radius: 100px;" />

                        </div>
                      </div>
                      <div class="pl-2 pr-2 d-flex flex-column flex-grow-1">
                        <div class="d-flex">
                          <span class="header-12">Student</span>

                          <div class="sub-date float-right ml-auto" style="white-space: nowrap">
                            {{ j?.DateTime | date: "medium" }}
                          </div>
                        </div>
                        <div class="sub-text flex-grow-1">{{ j?.Text }}</div>
                      </div>
                    </div>
                  </div>

                  <div class="agent-comment"
                    style="border-radius:10px;border-bottom-left-radius: 0;border:1px solid #def3f7"
                    *ngIf="j.Direction == 'outbound'">
                    <div class="d-flex">
                      <div>
                        <div class="pic mr-1 float-left text-center">
                          <img style="width: 100%;
                          height: 100%;
                          object-fit: cover;
                          border-radius: 100px;" src="../../../assets/img/admin-comments.svg" />

                        </div>
                      </div>

                      <div class="pl-2 pr-2 d-flex flex-column">
                        <div class="d-flex">
                          <span class="header-12">Agent</span>
                          <!-- <div class="sub-header-sm mr-3">{{ i.AgentName }}</div> -->
                          <div class="sub-date float-right ml-auto" style="white-space: nowrap">
                            {{ j?.DateTime | date: "medium" }}
                          </div>
                        </div>
                        <div class="sub-text" style="width:315px;line-height: 125%;">{{ j?.Text }}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


    </mat-tab>

    <mat-tab label="Activity">
      <div class="mx-2 my-2">
        <div class="alert alert-info mb-0 p-2 d-flex">
          <div class="" style="line-height:200%">
            {{ sideViewerData?.Activities?.length }} Actions taken
          </div>
          <div class="ml-auto" style="width:180px;">
            <input class="searchActivity" type="text" style="font-size:13px;" class="form-control search-input"
              placeholder="Search" [(ngModel)]="activityfilterString"
              (input)="onInputActivity(sideViewerData?.Activities?.length)" />
          </div>
        </div>
        <div *ngFor="let i of sideViewerData.Activities | slice: 0:minItems | searchFilterTable: activityfilterString">
          <div class="myactivity d-flex align-items-start">
            <div class="mr-auto d-flex">
              <div>
                <img class="img16 mr-2" src="./assets/img/tick-green.svg" />
              </div>
              <div>{{ i?.Description }} </div>
            </div>
            <div class="ml-auto text-right" style="width:230px;"><span class="sm-text ml-2">{{
                i?.DateTime | date: "medium"
                }}</span></div>


            <!-- <img class="img16 mr-2" src="./assets/img/tick-green.svg" />
            <p>
              {{ i.Description }}
              <span class="sm-text ml-2">{{
                i.DateTime | date: "medium"
                }}</span>
            </p> -->
          </div>
          <hr style="margin: 0; padding: 0" />
        </div>
        <div class="loadmore" *ngIf="minItems < sideViewerData.Activities.length"
          (click)="showmore(sideViewerData.Activities.length)">
          Show more..
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
</mat-sidenav>


<div class="container-fluid">
  <div class="pt-3 pl-2 pr-2 d-flex justify-content-between mb-4">
    <div class="db-sm-header" style="line-height: 330%">
      Activity

      <a href="javascript:void(0);" (click)="openCardsModal(cardsChangemodal)"><img
          src="../../../assets/images/edit.svg" class="img11 mb-1"></a>

    </div>
    <div class="db-sm-header mb-1 ml-auto" (click)="hideDate()">
      <button class="form-group" style="padding: 0">
        <select class="form-control cdd" id="" style="padding: 0; background: #fff" [(ngModel)]="filterValue"
          (change)="updateDateFilter($event)">
          <option value="all">All</option>
          <option value="24hrs">24 hrs</option>
          <option value="72hrs">72 hrs</option>
          <option value="7days">07 days</option>
          <option value="14days">14 days</option>
          <option value="30days">30 days</option>
          <option value="custom">Custom</option>
        </select>
      </button>
    </div>

    <div *ngIf="showDate" class="datePicker">
      <div style="background: #fff; height: 76px; border: 1px solid #ccc" class="custom-date">
        <mat-form-field class="mr-3">
          <input class="label" matInput [matDatepicker]="picker1" placeholder="Start Date" [formControl]="date2"
            (dateInput)="getStartDate('input', $event)" />
          <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
          <mat-datepicker #picker1 style="width: 80px"></mat-datepicker>
        </mat-form-field>

        <mat-form-field class="mr-3">
          <input class="label" matInput [matDatepicker]="picker2" placeholder="End Date" [formControl]="date1"
            (dateInput)="getEndDate('input', $event)" />
          <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
          <mat-datepicker #picker2 style="width: 80px"></mat-datepicker>
        </mat-form-field>
        <button class="btn btn-secondary btn-sm" (click)="filterData()">
          Submit
        </button>
      </div>
    </div>
  </div>


  <!-- Carousel  -->
  <app-custom-cards [cardsSettings]="cardsSettings" [records]="topBarCounts" [records1]="topBarCounts12"
    (pageChange)="pageChange($event)"></app-custom-cards>
  <!-- End Carousel -->

  <!-- Activity -->

  <!-- End Carousel -->

  <!-- Activity -->
  <div>
    <div>
      <div>
        <div class="d-flex align-items-end">
          <div class="header-12">
            Total Applications &nbsp;
            <a href="javascript:void(0);" (click)="openTablesModal(tablesChangemodal)"> <img
                src="../../../assets/images/edit.svg" class="img11 mb-1"></a>
          </div>
          <div class="ml-auto mr-3">
            <div class="form-control form-control-dark" style="
            height: 0;
            padding: 0px;
            background: none;
            font-size: 13px;
            color: #999;
            border: 0;
          "></div>
            <div class="upload-msg-success" style="color: green; font-size: 10px"></div>
            <div class="upload-msg-error"></div>

            <!-- attachment bar hidden -->
            <div class="content-containers" style="margin-left: 30px">
              <input type="file" (change)="convertFile($event)" style="display: none" #uploadFile accept=".xlsx" />

              <div class="d-flex justify-content-end mt-3">
                <div>
                  <div class="float-left mr-3">
                    <div *ngIf="filePath" class="form-control" style="
                    height: 16px;
                    padding: 0px;
                    background: none;
                    font-size: 13px;
                    color: #999;
                    border: 0;
                  ">
                      {{ filePath }}
                    </div>
                    <div class="upload-msg-success" *ngIf="uploaded" style="color: green; font-size: 10px">
                      {{ insertCount + " " + "Contacts inserted" }}
                    </div>
                    <div class="upload-msg-error" *ngIf="error">
                      {{ errorMessage }}
                    </div>
                  </div>

                  <div style="clear: both"></div>
                </div>

                <!-- for add new document screen-->
                <div class="modal fade" id="exampleModal4" tabindex="-1" role="dialog"
                  aria-labelledby="exampleModalLabel" aria-hidden="true">
                  <div class="modal-dialog" role="document">
                    <div class="modal-content" style="width: max-content;">
                      <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">
                          Add New Document
                        </h5>
                        <button type="button" #closeButton class="close" data-dismiss="modal" aria-label="Close">
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div class="modal-body">
                        <div class="d-flex">
                          <div class="form-group mr-2">
                            <label for="exampleInputEmail1" style="font-size: 13px">Document Type</label>
                            <select class="form-control" name="doctype" [(ngModel)]="customDocType"
                              style="width: 300px;">
                              <option value="none">Please Select</option>
                              <option value="transcript">Transcript</option>
                              <option value="visa">VISA</option>
                              <option value="passport">Passport</option>
                              <option value="ielts">IELTS</option>
                              <option value="dualingo">Dualingo</option>
                              <option value="gre">GRE</option>
                              <option value="lor">LOR</option>
                              <option value="aos">AOS</option>
                              <option value="resume">Resume</option>
                              <option value="finacial">Financial</option>
                              <option value="i20">I20</option>
                              <option value="toefl">TOEFL</option>
                            </select>
                          </div>
                          <div>
                            <input hidden type="file" accept="application/msword, application/pdf, image/*, .doc, .docx"
                              #uploadFile2 (change)="uploadCustomDocument($event)" />
                            <button type="button" class="btn btn-secondary btn-sm mr-2" style="
                            padding: 5px 10px;
                            margin-top: 22px;
                            font-size: 13px;
                          " (click)="uploadFile2.click()">
                              <img class="img12" src="../../../assets/img/upload-w.svg" />
                            </button>
                          </div>
                          <div>
                            <button (click)="addCustomDocumentAI()" type="button" class="btn btn-secondary btn-sm"
                              style="
                            padding: 5px 10px;
                            margin-top: 22px;
                            font-size: 13px;
                          ">
                              Add
                            </button>
                          </div>

                        </div>

                        <div class="p-3 text-center docUploadError" style="font-size: 14px; font-weight: bold"
                          [ngStyle]="aiDocMessages == true?{'color': 'red'} : {'color': 'green'}">
                          <div class="my-2">
                            <mat-progress-bar mode="indeterminate" *ngIf="docUploadStatus"></mat-progress-bar>
                          </div>
                          {{docUploadError}}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- for upload multiple files-->
                <div class="modal fade" id="uploadFiles" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
                  aria-hidden="true">
                  <div class="modal-dialog" role="document">
                    <div class="modal-content">
                      <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">
                          Upload Documents
                        </h5>
                        <button type="button" id="closeButton4" class="close" data-dismiss="modal" aria-label="Close">
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div class="modal-body">
                        <div *ngIf="uploadSuccess=='none'">
                          <div class="d-flex" *ngIf="!docUploadStatus">
                            <div>
                              <button (click)="filesInput.click()" type="button" class="btn btn-secondary btn-sm">
                                Select Files
                              </button>
                              <input type="file" #filesInput id="filesInput" multiple (change)="getFileDetails($event)"
                                style="display: none;">
                            </div>
                            <div class="add-docs-item-sub ml-auto">
                              <div class="txt-oflow-lg text-right">

                                Selected Files: {{selectedFiles.length}}
                              </div>
                            </div>
                          </div>

                          <!--Addon-->
                          <div *ngIf="selectedFiles.length==0" class="d-flex p-3 flex-column">
                            <div class="text-center p-3"><img src="../../../assets/img/upload-file-2.png" /></div>
                            <div class="text-center">
                              Upload below supported documents only <br>
                              <span style="font-size:18px; line-height:180%">.doc .pdf .png .jpg .jpeg</span>
                            </div>
                          </div>
                          <!--End-->

                          <div *ngIf="selectedFiles.length>0">
                            <div *ngIf="!docUploadStatus" class="py-2 mb-3" style="height:160px;overflow:auto">
                              <div *ngFor="let i of selectedFiles;let indexOfelement=index;"
                                class="d-flex add-docs-item align-items-center py-2">
                                <div class="">
                                  <img class="img12 mr-1 lh160 float-left" src="../../../assets/img/docs-r.svg" />
                                  <div class="txt-oflow-lg" style="display:inline-block;line-height:100%">{{ i.name }}
                                  </div>
                                  <br />
                                </div>
                                <div class="ml-auto">
                                  <button style="background: none;" (click)="deleteFile(indexOfelement)">
                                    <img class="img12" src="../../../assets/img/delete.svg" />
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>




                          <div *ngIf="!docUploadStatus">
                            <div class="d-flex">
                              <div *ngIf="selectedFiles.length > 0">
                                <div *ngIf="!docUploadStatus">
                                  <button (click)="filesInput.click()" type="button" class="btn btn-secondary btn-sm">
                                    + Add more
                                  </button>

                                  <input type="file" #filesInput id="filesInput" multiple
                                    (change)="getFileDetails($event)" style="display: none;">
                                </div>
                              </div>
                              <div class="ml-auto">
                                <button *ngIf="selectedFiles.length > 0" #uploadFilesButton type="button"
                                  class="btn btn-primary btn-sm mr-2" (click)="uploadAttachments()">
                                  Upload
                                </button>
                                <button (click)="cancelUpload()" type="button" class="btn btn-light btn-sm">
                                  Cancel
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="!docUploadStatus">
                          <!--Addon-->
                          <div class="d-flex p-3 flex-column" *ngIf="uploadSuccess=='success'">
                            <div class="text-center p-3"><img src="../../../assets/img/upload-success.png" /></div>
                            <div class="text-center">
                              <b>Upload Success</b><br>
                              <div style="font-size:14px; line-height:130%;padding:5px">Total {{selectedFiles.length}}
                                files
                                uploaded successfully
                              </div>
                            </div>
                          </div>
                          <!--End-->

                          <!--Addon-->
                          <div class="d-flex p-3 flex-column" *ngIf="uploadSuccess=='failure'">
                            <div class="text-center p-3"><img src="../../../assets/img/upload-failed.png" /></div>
                            <div class="text-center">
                              <b>Upload Failed</b><br>
                              <div style="font-size:14px; line-height:130%;padding:5px">Files not uploaded. Please check
                                document extensions,
                                upload supported documents</div>
                            </div>
                          </div>
                          <!--End-->


                        </div>
                        <div>
                          <mat-progress-bar mode="indeterminate" *ngIf="docUploadStatus"></mat-progress-bar>
                        </div>
                        <div class="p-3 text-center docUploadError" *ngIf="uploadSuccess=='none'"
                          style="color: green; font-size: 14px; font-weight: bold">
                          {{docUploadError2}}
                        </div>

                      </div>
                    </div>
                  </div>
                </div>

                <!-- for assign model-->
                <div class="modal fade" id="exampleModal3" tabindex="-1" role="dialog"
                  aria-labelledby="exampleModalLabel" aria-hidden="true">
                  <div class="modal-dialog" role="document">
                    <div class="modal-content">
                      <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">
                          Assign to Agent
                        </h5>
                        <button type="button" id="closeAssignButton" #closeButton class="close" data-dismiss="modal"
                          aria-label="Close">
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div class="modal-body" style="height: none;">
                        <div class="px-2">
                          <div class="py-2 header-lg">
                            <b>{{ leadIds.length }}</b> Students are selected to
                            assign.
                          </div>
                          <div class="d-flex mt-2 mb-3">
                            <div class="form-group mr-2" style="margin-bottom: 0; width: 220px">
                              <select class="form-control" id="exampleFormControlSelect1" [(ngModel)]="defaultAgent"
                                (change)="agentSelected($event.target.value)">
                                <option value="default" disabled selected>
                                  Select Agent
                                </option>
                                <option *ngFor="let agent of agentList" [value]="agent.Mailid">
                                  {{ agent.FirstName + " " + agent.LastName }}
                                </option>
                              </select>
                            </div>
                            <button type="button" class="btn btn-secondary btn-sm" (click)="assignLEads()">
                              Assign
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- attachment bar hidden ends -->
                <button data-toggle="modal" data-target="#exampleModal3" *ngIf="admin" (click)="assignAgentModal()"
                  class="btn btn-link btn-sm ml-2" style="color: #fff" ngbTooltip="Assign" placement="top"
                  tooltipClass="my-custom-class2">
                  <div>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 32 32">
                      <g id="Group_860" data-name="Group 860" transform="translate(-783.465 -58.66)">
                        <path id="Path_965" data-name="Path 965"
                          d="M795.465,62.66a5,5,0,1,1-5,5,5,5,0,0,1,5-5m0-2a7,7,0,1,0,7,7A7,7,0,0,0,795.465,60.66Z"
                          class="psvg" />
                        <path id="Path_966" data-name="Path 966"
                          d="M805.465,88.66h-2v-5a5,5,0,0,0-5-5h-6a5,5,0,0,0-5,5v5h-2v-5a7,7,0,0,1,7-7h6a7,7,0,0,1,7,7Z"
                          class="psvg" />
                        <path id="Path_967" data-name="Path 967"
                          d="M808.465,74.84l-2.59-2.59-1.41,1.41,4,4,7-7-1.41-1.41Z" class="psvg" />
                        <rect id="_Transparent_Rectangle_" data-name=" Transparent Rectangle " width="32" height="32"
                          transform="translate(783.465 58.66)" fill="none" />
                      </g>
                    </svg>
                  </div>
                </button>

                <button class="btn btn-link btn-sm ml-2" ngbTooltip="Download Sample" placement="top"
                  tooltipClass="my-custom-class2">
                  <a href="{{ downloadLink }}">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 22 22">
                      <g id="Group_776" data-name="Group 776" transform="translate(-215.728 -61.66)">
                        <path id="Path_888" class="psvg" data-name="Path 888"
                          d="M234.853,86.66H221.1a1.375,1.375,0,0,1-1.375-1.375v-8.25A1.375,1.375,0,0,1,221.1,75.66h2.063v1.375H221.1v8.25h13.75v-8.25h-2.063V75.66h2.063a1.375,1.375,0,0,1,1.375,1.375v8.25A1.376,1.376,0,0,1,234.853,86.66Z"
                          transform="translate(-1.25 -4.375)" fill="#b02022" />
                        <rect id="Rectangle_902" class="psvg" data-name="Rectangle 902" width="4" height="1"
                          transform="translate(224.728 75.66)" fill="#b02022" />
                        <path id="Path_889" class="psvg" data-name="Path 889"
                          d="M233.008,68.19l-2.468,2.461V63.66h-1.375v6.992L226.7,68.19l-.969.969,4.125,4.125,4.125-4.125Z"
                          transform="translate(-3.125 -0.625)" />
                        <rect id="_Transparent_Rectangle_" data-name=" Transparent Rectangle " width="22" height="22"
                          transform="translate(215.728 61.66)" fill="none" />
                      </g>
                    </svg></a>
                </button>
                <button class="btn btn-link btn-sm ml-2" ngbTooltip="Upload File" placement="top"
                  (click)="redirectToUpload()" tooltipClass="my-custom-class2">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 22 22">
                    <g id="Group_775" data-name="Group 775" transform="translate(-171.243 -61.66)">
                      <path id="Path_886" class="psvg" data-name="Path 886"
                        d="M177.243,76.419l.953.953,5.131-5.124V84.531h1.352V72.249l5.131,5.124.953-.953L184,69.66Z"
                        transform="translate(-1.944 -2.592)" />
                      <path id="Path_887" class="psvg" data-name="Path 887"
                        d="M176.595,67.716v-2.7h13.519v2.7h1.352v-2.7a1.352,1.352,0,0,0-1.352-1.352H176.595a1.352,1.352,0,0,0-1.352,1.352v2.7Z"
                        transform="translate(-1.296 -0.648)" />
                      <rect id="_Transparent_Rectangle_" data-name=" Transparent Rectangle " width="22" height="22"
                        transform="translate(171.243 61.66)" fill="none" />
                    </g>
                  </svg>
                </button>
                <!-- <button class="btn btn-link btn-sm ml-2" ngbTooltip="Add New" placement="top"
              (click)="openCreateLead(content3)" tooltipClass="my-custom-class">
              <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
                <g id="icon">
                  <g>
                    <polygon class="csvg" points="17 15 17 8 15 8 15 15 8 15 8 17 15 17 15 24 17 24 17 17 24 17 24 15 17 15"/>
                    <rect class="csvg" width="16" height="16"/>
                  </g>
                </g>
              </svg>
            </button> -->

                <button class="btn btn-link btn-sm ml-2" ngbTooltip="Add New" placement="top"
                  tooltipClass="my-custom-class2" (click)="openCreateLead(content3)">
                  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22">
                    <g id="Group_764" data-name="Group 764" transform="translate(-286.561 -0.829)">
                      <path id="Path_869" data-name="Path 869" class="psvg"
                        d="M302.855,15.28V8.829h-1.843V15.28h-6.451v1.843h6.451v6.451h1.843V17.123h6.451V15.28Z"
                        transform="translate(-4.373 -4.373)" />
                      <rect id="Rectangle_901" data-name="Rectangle 901" width="22" height="22"
                        transform="translate(286.561 0.829)" fill="none" />
                    </g>
                  </svg>
                </button>



                <button class="btn btn-link btn-sm ml-2" ngbTooltip="Start Call" placement="top"
                  tooltipClass="my-custom-class2" data-toggle="modal" data-target="#staticBackdrop"
                  (click)="startCallListener()">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 22 22">
                    <g id="Group_767" data-name="Group 767" class="csvg" transform="translate(-408.685 1.171)">
                      <path id="Path_872" class="psvg" data-name="Path 872"
                        d="M427.494,19.7h-.117C413.868,18.926,411.95,7.528,411.682,4.05a2.062,2.062,0,0,1,1.891-2.22h3.794a1.375,1.375,0,0,1,1.279.866l1.045,2.571a1.375,1.375,0,0,1-.3,1.485l-1.464,1.478a6.441,6.441,0,0,0,5.211,5.225l1.492-1.478a1.375,1.375,0,0,1,1.492-.282l2.592,1.038a1.375,1.375,0,0,1,.846,1.279v3.63A2.062,2.062,0,0,1,427.494,19.7ZM413.744,3.2a.688.688,0,0,0-.687.687v.055c.316,4.07,2.344,13.694,14.4,14.382a.688.688,0,0,0,.729-.643V14.011l-2.592-1.038-1.973,1.959-.33-.041c-5.981-.749-6.792-6.73-6.792-6.792l-.041-.33L418.405,5.8,417.374,3.2Z"
                        transform="translate(-0.935 -0.938)" />
                      <rect id="_Transparent_Rectangle_" data-name=" Transparent Rectangle " width="22" height="22"
                        transform="translate(408.685 -1.171)" fill="none" />
                    </g>
                  </svg>
                </button>
                <button class="btn btn-link btn-sm ml-2" ngbTooltip="Stop Call" placement="top"
                  tooltipClass="my-custom-class2" (click)="stopCallListener()">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 22 22">
                    <g id="Group_774" data-name="Group 774" transform="translate(-121.767 -61.66)">
                      <path id="Path_884" class="psvg" data-name="Path 884"
                        d="M129.1,75.547a17.96,17.96,0,0,1-2.932-8.732v-.056a.7.7,0,0,1,.7-.7h3.694l1.05,2.638-1.987,2.008.042.336a9.1,9.1,0,0,0,1.022,2.918l1.022-1.022a6.546,6.546,0,0,1-.588-1.763l1.49-1.5a1.4,1.4,0,0,0,.308-1.511l-1.064-2.617a1.4,1.4,0,0,0-1.3-.882H126.7a2.1,2.1,0,0,0-1.932,2.254v.006a19.593,19.593,0,0,0,3.352,9.635Z"
                        transform="translate(-0.898 -0.901)" />
                      <path id="Path_885" class="psvg" data-name="Path 885"
                        d="M141.8,75.457,139.16,74.4a1.4,1.4,0,0,0-1.518.287l-1.518,1.5a6.425,6.425,0,0,1-2.946-1.336l10.181-10.209-.987-.987-18.6,18.6.987.987,4.9-4.9c2.365,2.225,5.793,3.932,10.768,4.2h.14a2.1,2.1,0,0,0,2.1-2.1V76.758A1.4,1.4,0,0,0,141.8,75.457Zm-.539,5v.042a.7.7,0,0,1-.739.658h0a15.247,15.247,0,0,1-9.873-3.792l1.518-1.5a7.544,7.544,0,0,0,4.114,1.791l.336.042,2.008-1.994,2.638,1.057Z"
                        transform="translate(-0.601 -0.601)" />
                      <rect id="_Transparent_Rectangle_" data-name=" Transparent Rectangle " width="22" height="22"
                        transform="translate(121.767 61.66)" fill="none" />
                    </g>
                  </svg>
                </button>
                <button mat-button id="openModalButton" [hidden]="true" ngbTooltip="Start Call" placement="top"
                  tooltipClass="my-custom-class2" data-toggle="modal" data-target="#staticBackdrop">
                  <img class="img12" src="../../../../assets/img/call.svg" />
                </button>
              </div>
              <div style="clear: both"></div>
            </div>
          </div>
        </div>
        <div>
          <div class="ml-3 mt-2 mr-3">
            <div class="row">
              <div class="pl-2 pr-2 pt-2 mb-3" style="min-height: 300px; width: 100%">
                <div class="table-responsive data-table mb-3">
                  <app-custom-table [records]="activityTable" [settings]="projectSettings" [rowSetting]="rowSetting"
                    [projectId]="projectId" [isLoading]="isLoading" [noRecords]="noRecords" [selectedAll]="selectedAll" [menuId]="0"
                    (updateLeadModal)="updateLeadModal($event)" (callModal)="callModal($event)"
                    (sidenavOpen)="sidenavOpen($event)" (dropdownSelectReuse)="dropdownSelectReuse($event)"
                    (searchLeads)="searchLeadsNew($event)" (selectAllforAssignment)="selectAllforAssignment()"
                    (selectAgentforAssignmentNew)="selectAgentforAssignmentNew($event)"></app-custom-table>
                  <!-- <table class="table table-md wbg table-shadow" style="margin-bottom: 0px; width: 100%">
              <thead>
                <tr>
                  <td colspan="2">
                    <div class="indived-search" style="position: relative">
                        <input type="search" (input)="onInput($event)" #inputString class="form-control search-all"
                        style="padding: 4px 12px" [(ngModel)]="search" placeholder="Search "
                        autocomplete="off" />
                    </div>
                  </td>
                  <td colspan="10" class="vm">
                    <div class="pagenation-right d-flex justify-content-end align-items-center">
                      <select class="form-select form-select-dark mx-2" [(ngModel)]="statusValue" (ngModelChange)="dropdownSelect($event)">
                        <option value="all">All</option>
                        <option [value]="item.value" *ngFor="let item of listFeed">{{item.name}}</option>
                        
                      </select>
                      <div class="float-right" style="color: #fff; font-size: 12px">
                        <app-custom-pagination></app-custom-pagination>

                        </div>
                    </div>
                  </td>
                </tr>
              </thead>
              <tr>
                <th nowrap>
                  <div class="d-flex">
                    <div style="width: 20px" *ngIf="admin">
                      <input type="checkbox" [checked]="selectedAll == true" class="selectAll" id="selectAll"
                        (change)="selectAllforAssignment()" />
                    </div>
                    <div *ngIf="currentCard == 'total'">Created Date</div>
                    <div *ngIf="currentCard == 'validated'">Validation Date</div>
                    <div *ngIf="currentCard == 'uncontactable'">Uncontactable Date</div>
                    <div *ngIf="currentCard == 'completed'">Completed Date</div>
                    <div *ngIf="currentCard == 'updated'">Created Date</div>
                    <div *ngIf="currentCard == 'revalidate'">Revalidation Date</div>
                    <div *ngIf="currentCard == 'denied'">Denied Date</div>
                    <div *ngIf="currentCard == 'assigned'">Assigned Date</div>
                    <span><button class="sortButton" (click)="toggleSort('createdAt','asc')"><img class="img7 ml-1" src="../../../../assets/img/sort-data.svg" /></button></span>
                  </div>
                </th>
                <th nowrap>
                  Updated Date
                  <span><button class="sortButton" (click)="toggleSort('updatedAt','asc')"><img class="img7 ml-1" src="../../../../assets/img/sort-data.svg" /></button></span>
                </th>
                <th nowrap>
                  Name
                  <span><button class="sortButton" (click)="toggleSort('FirstName','asc')"><img class="img7 ml-1" src="../../../../assets/img/sort-data.svg" /></button></span>
                </th>
                <th nowrap>
                  Email
                  <span><button class="sortButton" (click)="toggleSort('Email','asc')"><img class="img7 ml-1" src="../../../../assets/img/sort-data.svg" /></button></span>
                </th>
                <th style="width: 180px" nowrap>
                  Assigned To
                  <span><button class="sortButton" (click)="toggleSort('AssignedtoName','asc')"><img class="img7 ml-1" src="../../../../assets/img/sort-data.svg" /></button></span>
                </th>
                <th nowrap>
                  Phone Number
                  <span><button class="sortButton" (click)="toggleSort('PhoneNumber','asc')"><img class="img7 ml-1" src="../../../../assets/img/sort-data.svg" /></button></span>
                </th>
                <th nowrap>
                  Enrollment Term
                  <span><button class="sortButton" (click)="toggleSort('City','asc')"><img class="img7 ml-1" src="../../../../assets/img/sort-data.svg" /></button></span>
                </th>
                <th nowrap>
                  Study Programme
                  <span><button class="sortButton" (click)="toggleSort('Program','asc')"><img class="img7 ml-1" src="../../../../assets/img/sort-data.svg" /></button></span>
                </th>
                <th nowrap>
                  Document Status
                  <span><button class="sortButton" (click)="toggleSort('LeadDocStatus','asc')"><img class="img7 ml-1" src="../../../../assets/img/sort-data.svg" /></button></span>
                </th>
                <th nowrap>
                  Call Status
                  <span><button class="sortButton" (click)="toggleSort('CallStatus','asc')"><img class="img7 ml-1" src="../../../../assets/img/sort-data.svg" /></button></span>
                </th>
                <th>Actions</th>
              </tr>

              <tbody>
                <tr *ngFor="
                    let item of activityTable;
                    let id = index
                  ">
                  <td class="vm" nowrap>
                    <div class="d-flex">
                      <div style="width: 20px" *ngIf="admin">
                        <input type="checkbox" name="lead" [checked]="selectedAll == true" class="selectLead"
                          id="selectLead" (change)="selectAgentforAssignment($event, item)" />
                      </div> 
                      <div style="line-height: 125%">
                        <span *ngIf="currentCard == 'total' || currentCard == 'updated' ">{{item?.createdAt | date: 'dd MMM, yyyy'}}</span>
                        <span *ngIf="currentCard == 'validated' ">{{item?.ValidatedDate | date: 'dd MMM, yyyy'}}</span>
                        <span *ngIf="currentCard == 'uncontactable' ">{{item?.createdAt | date: 'dd MMM, yyyy'}}</span>
                        <span *ngIf="currentCard == 'completed' ">{{item?.CompletedDate | date: 'dd MMM, yyyy'}}</span>
                        <span *ngIf="currentCard == 'denied' ">{{item?.DeniedDate | date: 'dd MMM, yyyy'}}</span>
                        <span *ngIf="currentCard == 'revalidate' ">{{item?.RevalidateDate | date: 'dd MMM, yyyy'}}</span>
                        <span *ngIf="currentCard == 'assigned' ">{{item?.createdAt | date: 'dd MMM, yyyy'}}</span>
                      </div>
                    </div>
                  </td>
                  <td class="vm">
                    <div class="txt-oflow">{{ item.updatedAt | date: "dd MMM, yyyy" }}</div>
                  </td>
                  <td class="vm" nowrap >
                    
                    <div class="txt-oflow">
                      <button class="btn btn-link btn-sm" style="position:relative" 
                      (click)="openEditLead(editLead2, item)">
                        {{ item.FirstName + " " + item.LastName | titlecase }}
                      </button>

                    </div>

                  </td>
                  <td class="vm">
                    <div class="txt-oflow">{{ item.Email | lowercase }}</div>
                  </td>
                  <td class="vm" style="width: 200px" nowrap>
                    <div class="d-flex">
                      <div *ngIf="item.AssignedtoName" class="pic mr-1 float-left text-center">
                        <img *ngIf="item.AssignedtoImageURL != null" class="img20"
                          src="{{ item.AssignedtoImageURL }}" />


                        <p [ngStyle]="{ background: item.AssignedtoColor }" class="initials"
                          *ngIf="item.AssignedtoImageURL == null">
                          {{ item.AssignedtoInitial }}
                        </p>


                      </div>
                      <div class="txt-oflow2 float-left">
                        {{ item.AssignedtoName | titlecase }}
                      </div>
                    </div>
                  </td>
                  <td class="text-capitalize vm">
                    {{ item.PhoneNumber }}
                  </td>
                  <td class="vm">
                    {{ item.DIterms | uppercase }}
                  </td>
                  <td class="text-capitalize vm">
                    {{ item.Program | uppercase }}
                  </td>

                  <td class="text-capitalize vm">
                    <span *ngIf="item?.LeadDocStatus == 'notsubmitted' ">
                      Not Submitted
                    </span>
                    <span *ngIf="item?.LeadDocStatus != 'notsubmitted' ">
                      {{item?.LeadDocStatus | titlecase}}
                    </span>
                  </td>
                  <td class="text-capitalize vm">
                    <span class="status-pending" *ngIf="item.CallStatus == 'pending'">Pending</span>
                    <span class="status-pending" *ngIf="item.CallStatus == 'notinterested'">Not Interested</span>
                    <span class="status-completed" *ngIf="item.CallStatus == 'completed'">Completed</span>
                    <span class="status-callback" *ngIf="item.CallStatus == 'callback'">Call Back</span>
                    <span class="status-informed" *ngIf="item.CallStatus == 'informed'">Informed</span>
                    <span class="status-informed" *ngIf="item.CallStatus == 'called'">Called</span>
                    <span class="status-failed" *ngIf="item.CallStatus == 'callfailed'">Wrong Number</span>
                    <span class="status-deferred" *ngIf="item.CallStatus == 'deferredintake'">Deferred Intake</span>
                    <span class="status-pending" *ngIf="
                        item.CallStatus == 'callnotanswered' ||
                        item.CallStatus == 'notanswered'
                      ">Not Answered</span>
                    <span class="status-deferred" *ngIf="
                        item.CallStatus == 'deferred' ||
                        item.CallStatus == 'Deferred'
                      ">Deferred Intake</span>
                    <span class="status-uncontactable" *ngIf="
                        item.CallStatus == 'uncontactable' ||
                        item.CallStatus == 'uncontactable'
                      ">Un Contactable</span>
                  </td>
                  <td class="text-capitalize vm" nowrap style="padding: 2px">
                    <div class="example-button-row mat-bset d-flex">
                      <mat-sidenav-container>
                        <mat-sidenav-content>
                          <button class="viewLeadButton" (click)="sideNav.toggle(); sideNavData(item)" mat-button>
                            <img class="img12" src="../../../../assets/img/view2.svg" />
                          </button>
                        </mat-sidenav-content>
                      </mat-sidenav-container>
                      <button mat-button (click)="checkThresHold(totalCallCount,item, id)" ngbTooltip="Start Call"
                        placement="top" tooltipClass="my-custom-class">
                        <img class="img12" src="../../../../assets/img/call.svg" />
                      </button>
                      <button mat-button id="openModalButton" [hidden]="true" ngbTooltip="Start Call" placement="top"
                        tooltipClass="my-custom-class" data-toggle="modal" data-target="#staticBackdrop">
                        <img class="img12" src="../../../../assets/img/call.svg" />
                      </button>
                    </div>
                  </td>
                </tr>
                <tr *ngIf="isLoading ==true">
                  <td colspan="10">
                    <div class="norecords">
                      <img src="../../../../assets/img/waiting.gif" />

                      <div>Loading Data<br />Please wait while we fetch the data for you</div>
                    </div>
                  </td>
                </tr>
                <tr *ngIf="noRecords == true">
                  <td colspan="10">
                    <div class="norecords">
                      <img src="../../../../assets/img/nodata.svg" />
                      <div>No records found.<br />Please select a different date range.</div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table> -->

                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- for Modal -->
        <div class="modal fade" id="staticBackdrop" data-backdrop="static" data-keyboard="false" tabindex="-1"
          aria-labelledby="staticBackdropLabel" aria-hidden="true">
          <div class="modal-dialog modal-lg custom-model-w">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="staticBackdropLabel">Calling Screens</h5>

                <button type="button" #closeButton2 class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body" style="overflow-y: auto">
                <div class="row">
                  <div class="call-module ml-3">
                    <div class="ndialer">
                      <!--.......code for no- incoming call screen.........  -->
                      <div class="c-caller">Current Caller</div>
                      <div class="dialer-middle-screen" style="text-align: center"
                        *ngIf="dialerScreen1 === 'outgoing1'">
                        <div class="custom-incomingcall-widget">
                          <h4 class="mt-3 text-ellipsis">
                            {{ marketingId.name }}
                          </h4>
                          <div class="mb-2 mt-1 lg-text" style="font-weight: 100" *ngIf="marketingId.city">
                            {{ marketingId.city }}
                          </div>
                          <div class="my-1 lg-text">
                            {{ marketingId.phoneNumber || agentCall }}
                          </div>
                          <div class="custom-user-img my-3 mt-5">
                            <img class="img80" src="./assets/img/user-w.svg" alt="" />
                          </div>
                          <h5 *ngIf="callStatus != 'inProgress'">{{ callStatus }}</h5>
                          <p *ngIf="callStatus == 'inProgress'" class="custom-call-timer" style="text-align: center">
                            <countup-timer [countUpTimerConfig]="testConfig"></countup-timer>
                          </p>

                          <div class="custom-incoming-btns my-3 mt-4">
                            <button type="button" class="btn-round m-r-10" (click)="stopCurrentCall()">
                              <img class="img-38" src="./assets/img/hangup.svg" alt="" />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="mt-2 p-3 ndialer2 ng-star-inserted">
                      <div class="mt-4" style="float: right">
                        <img class="img50" src="./assets/img/user-w.svg" alt="" />
                      </div>
                      <div class="sm-text" style="
                     
                    font-weight: 100;
                    background: #f75c2b;
                    width: 110px;
                    padding: 5px;
                    border-radius: 15px;
                    letter-spacing: 1px;
                    text-align: center;
                    color: #fff;
                  ">
                        Next Caller
                      </div>
                      <div class="lg-text pt-2 pb-1">
                        {{ nextCallerName }}
                      </div>
                      <div class="md-text pb-2">{{ nextCallerCity }}</div>
                      <div class="md-text">
                        {{ nextCallerNumber }}
                      </div>
                    </div>

                    <div class="mt-2 d-flex justify-content-between">
                      <div class="cbtn" type="button" placement="top" tooltipClass="my-custom-class"
                        (click)="startCallListener()">
                        <img class="img18" src="./assets/img/redial3.svg" alt="" />
                      </div>

                      <button class="cbtn" type="button" placement="right" [ngbPopover]="calendarContent"
                        [disabled]="disabled">
                        <!-- tooltipClass="my-custom-class" -->
                        <img class="img18" src="./assets/img/conference.svg" alt="" />
                      </button>
                      <ng-template #calendarContent>
                        <form (ngSubmit)="conferenceCall(form.value)" #form="ngForm">
                          <div class="xp-3" style="width: 185px">
                            <div class="mb-1">
                              <input id="phone_number" name="phone_number" type="text" class="form-control"
                                placeholder="Enter Phone Number" ngModel />
                            </div>
                            <ng-multiselect-dropdown name="manager" [data]="layoutService.projectAgents"
                              [(ngModel)]="layoutService.projectSelectedAgent"
                              [settings]="layoutService.dropdownSettings" style="width: 100%; padding: 0"
                              class="dropdown-primary"></ng-multiselect-dropdown>
                            <button class="btn btn-primary btn-block btn-md mt-2">
                              Connect
                            </button>
                          </div>
                        </form>
                      </ng-template>

                      <div class="cbtn" type="button" ngbTooltip="Stop Calls" placement="top"
                        tooltipClass="my-custom-class" (click)="stopCurrentCall()">
                        <img class="img18" style="padding-top: 3px" src="./assets/img/stop-call-r.svg" alt="" />
                      </div>

                      <div class="cbtn" type="button" ngbTooltip="Start Calls" placement="top"
                        tooltipClass="my-custom-class" (click)="callNext()">
                        <img class="img18" style="padding-top: 3px" src="./assets/img/start-calls.svg" alt="" />
                      </div>
                    </div>
                  </div>
                  <div class="call-module-update ml-4 mr-2">
                    <div class="row">
                      <div class="col-md-12">
                        <div class="header-md mt-2 mb-1">Student details</div>
                      </div>
                    </div>
                    <div class="row custom-row">
                      <div class="col-md-3">
                        <div class="form-group">
                          <label for="formGroupExampleInput">Name</label>
                          <div *ngIf="callData[0]">
                            {{ callData[0]?.FirstName + " " + callData[0].LastName }}
                          </div>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="form-group">
                          <label for="formGroupExampleInput">GUID</label>
                          <div *ngIf="callData[0]">{{ callData[0]?.GUID }}</div>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="form-group">
                          <label for="formGroupExampleInput">APP Status</label>
                          <div *ngIf="callData[0]">{{ callData[0]?.APPStatus }}</div>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="form-group">
                          <label for="formGroupExampleInput">Submitted Date</label>
                          <div *ngIf="callData[0]">
                            {{ callData[0]?.createdAt | date: "medium" }}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row custom-row">
                      <div class="col-md-3">
                        <div class="form-group">
                          <label for="formGroupExampleInput">DOB</label>
                          <div *ngIf="callData[0]">{{ callData[0].DOB }}</div>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="form-group">
                          <label for="formGroupExampleInput">Enrollment Year</label>
                          <div>{{ callData[0]?.EnrollmentYear }}</div>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="form-group">
                          <label for="formGroupExampleInput">Program</label>
                          <div>{{ callData[0]?.Program }}</div>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="form-group">
                          <label for="formGroupExampleInput">Phone Number</label>
                          <div>{{ callData[0]?.PhoneNumber }}</div>
                        </div>
                      </div>
                    </div>

                    <div class="row custom-row">
                      <div class="col-md-6">
                        <div class="form-group">
                          <label for="formGroupExampleInput">Email</label>
                          <div>{{ callData[0]?.Email }}</div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label for="formGroupExampleInput">Address</label>
                          <div>{{ callData[0]?.Address }}</div>
                        </div>
                      </div>
                    </div>
                    <hr class="hr-style mt-1 mb-1" />
                    <div class="row">
                      <div class="col-md-12">
                        <div class="header-md mt-2 mb-1">Document details</div>
                      </div>
                    </div>
                    <div class="row custom-row" *ngFor="
                  let item of documentsList;
                  let i = index;
                  let even = even;
                  let odd = odd
                ">
                      <div class="col-md-6 py-2" *ngIf="even">
                        <img *ngIf="item.status == true" class="img18 mr-2" src="../../../../assets/img/tick2.svg" />
                        <img *ngIf="item.status == false" class="img18 mr-2" src="../../../../assets/img/minus.svg" />{{
                        documentsList[i].DocumentName }}
                      </div>
                      <div class="col-md-6 py-2" *ngIf="even && i + 1 < documentsList.length">
                        <img *ngIf="documentsList[i + 1].status == true" class="img18 mr-2"
                          src="../../../../assets/img/tick2.svg" />
                        <img *ngIf="documentsList[i + 1].status == false" class="img18 mr-2"
                          src="../../../../assets/img/minus.svg" />{{ documentsList[i + 1].DocumentName }}
                      </div>
                    </div>

                    <hr class="hr-style mt-1 mb-1" />
                    <div class="row custom-row2">
                      <div class="col-md-12">
                        <div class="header-md mt-2 mb-1">Feedback</div>
                      </div>

                      <div class="col-md-12">
                        <!-- [value]="item.value" *ngFor="let item of listFeed" -->
                        <div class="form-check mr-3 d-inline" *ngFor="let item of listFeed">
                          <label class="form-check-label" for="check1">
                            <input type="radio" [(ngModel)]="newStatus" class="form-check-input" id="check1"
                              name="option1" [value]="item.value" (change)="onStatusChange(item.value)" />{{item.name}}
                          </label>
                        </div>
                        <!-- <div class="form-check mr-3 d-inline">
                    <label class="form-check-label" for="check3">
                      <input type="radio" [(ngModel)]="newStatus" class="form-check-input" id="check3" name="option1"
                        value="informed" (change)="onStatusChange($event.target.value)" />Informed
                    </label>
                  </div>
                  <div class="form-check mr-3 d-inline">
                    <label class="form-check-label" for="check4">
                      <input type="radio" [(ngModel)]="newStatus" class="form-check-input" id="check4" name="option1"
                        value="callfailed" (change)="onStatusChange($event.target.value)" />Wrong Number
                    </label>
                  </div>
                  <div class="form-check mr-3 d-inline">
                    <label class="form-check-label" for="check5">
                      <input type="radio" [(ngModel)]="newStatus" class="form-check-input" id="check5" name="option1"
                        value="callback" (change)="onStatusChange($event.target.value)" />Call Back
                    </label>
                  </div>
                  <div class="form-check mr-3 d-inline">
                    <label class="form-check-label" for="check6">
                      <input type="radio" [(ngModel)]="newStatus" class="form-check-input" id="check6" name="option1"
                        value="notanswered" (change)="onStatusChange($event.target.value)" />Not Answered
                    </label>
                  </div>
                  <div class="form-check mr-3 d-inline">
                    <label class="form-check-label" for="check7">
                      <input type="radio" [(ngModel)]="newStatus" class="form-check-input" id="check7" name="option1"
                        value="deferredintake" (change)="onStatusChange($event.target.value)" />Deferred Intake
                    </label>
                  </div>
                  <div class="form-check mr-3 d-inline">
                    <label class="form-check-label" for="check8">
                      <input type="radio" [(ngModel)]="newStatus" class="form-check-input" id="check8" name="option1"
                        value="uncontactable" (change)="onStatusChange($event.target.value)" />Uncontactable
                    </label>
                  </div> -->
                      </div>
                    </div>
                    <div class="row" *ngIf="showdeferredintake">
                      <div class="padding-width">
                        <select class="form-control" name="DIyear" id="DIyear" [(ngModel)]="DIyear"
                          (change)="onDIYearChange($event.target.value)">
                          <option [ngValue]="null" [disabled]="true">Select Year</option>
                          <option value="{{item}}" *ngFor="let item of DIyearArray">{{item}}</option>
                        </select>
                      </div>
                      <div class="padding-width">
                        <select class="form-control" name="DIterms" id="DIterms" [(ngModel)]="DIterms"
                          (change)="onDIItermsChange($event.target.value)">
                          <option [ngValue]="null" [disabled]="true">Select value</option>
                          <option value="G1">G1</option>
                          <option value="G2">G2</option>
                          <option value="G3">G3</option>
                          <option value="G4">G4</option>
                          <option value="G5">G5</option>
                          <option value="G6">G6</option>
                        </select>
                      </div>
                    </div>
                    <span *ngIf="!newStatus" class="error-msg" style="color: red; font-size: 15px">
                      Please select any option above!
                    </span>
                    <div class="row custom-row">
                      <div class="col-md-12">
                        <div class="form-group">
                          <label for="commentText" class="header-md pt-3 pb-2" style="font-size: 14px">Comments</label>
                          <textarea class="form-control" id="commentText" rows="3" [(ngModel)]="commentText"></textarea>
                        </div>
                      </div>
                    </div>

                    <div class="d-flex justify-content-end">
                      <div class="mt-3">
                        <button (click)="resetCallingScreen()" class="btn btn-light btn-sm mr-2" type="button">
                          Reset
                        </button>
                        <button (click)="updateComments()" class="btn btn-primary btn-sm" type="submit">
                          Update
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!--Calling screen from Documents Validation-->
        <div class="modal fade" id="exampleModal2" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-md">
            <div class="modal-content" style="width: 280px; background: none; border: 0">
              <div class="modal-header" style="
            border-bottom: 0;
            display: inline;
            position: absolute;
            right: 5px;
            top: 2px;
            z-index: 999;
          ">
                <button #closeButton type="button" class="close" data-dismiss="modal" aria-label="Close"
                  style="margin-right: 0">
                  <span aria-hidden="true"><img class="img14" style="width: 10px" src="./assets/img/close.svg" alt="" />
                  </span>
                </button>
              </div>
              <div class="modal-body" style="padding: 0">
                <div class="d-flex">
                  <div class="ndialer" style="width: 275px">
                    <!--.......code for no- incoming call screen.........  -->

                    <div class="dialer-middle-screen" style="text-align: center">
                      <div class="custom-incomingcall-widget">
                        <h4 class="mt-3 text-ellipsis">
                          {{
                          sideViewerData.FirstName + " " + sideViewerData.LastName
                          }}
                        </h4>
                        <div class="my-1 lg-text">
                          {{
                          "+" +
                          sideViewerData.CountryCode +
                          " " +
                          sideViewerData.PhoneNumber
                          }}
                        </div>
                        <div class="custom-user-img my-3">
                          <img class="img80" src="./assets/img/user-w.svg" alt="" />
                        </div>

                        <h5>{{ callStatus }}</h5>

                        <div class="custom-incoming-btns my-3 mt-4">
                          <button type="button" class="btn-round m-r-10" (click)="stopCurrentCall()">
                            <img class="img-38" src="./assets/img/hangup.svg" alt="" />
                          </button>
                        </div>

                        <button type="button" class="btn btn-link btn-sm ml-2 float-left" placement="right"
                          #popover="ngbPopover" [ngbPopover]="comments22" [disabled]="disabled">
                          <img src="./assets/img/comments.svg" style="width: 16px" />
                        </button>

                        <ng-template #comments22>
                          <form #form="ngForm">
                            <div class="d-flex align-items-end" style="width: 180px">
                              <div class="flex-grow-1">
                                <textarea class="form-control ct" type="text" #callingComment id="callingComments"
                                  rows="2" placeholder="Write comments here..."></textarea>
                              </div>
                              <button type="button" class="btn btn-link ml-1 flex-shrink-1" style="padding: 0"
                                (click)="postComments(currentItem, 'callingComments')">
                                <img src="./assets/img/send-message.svg" style="width: 16px" />
                              </button>
                            </div>
                          </form>
                        </ng-template>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--Calling popup from Validation  -->
          <div id="footer" class="footer" style="position: fixed; bottom: 0px">
            <p class="powered-txt mr-2 mb-2">Powered by <a href="#">Avantao</a></p>
          </div>

          <!-- add lead -->
          <ng-template #content3 let-modal>
            <div class="modal-header">
              <h4 class="modal-title" id="modal-basic-title">Add Lead</h4>
              <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body p-3" style="max-height: 600px; background: #fff">
              <form (ngSubmit)="createLead()" [formGroup]="uhleadsservice.createLead">
                <div class="form-group">
                  <label class="form-label">First Name <span style="color: red;">*</span></label>
                  <input class="form-control" name="firstName" formControlName="firstName" [ngClass]="{
                    'is-invalid': createLeads.firstName.touched && createLeads.firstName.invalid,
                    'is-valid':
                      createLeads.firstName.valid &&
                      createLeads.firstName.touched
                  }" />
                  <div *ngIf="createLeads.firstName.touched && createLeads.firstName.errors" class="invalid-feedback">
                    <div *ngIf="createLeads.firstName.errors.required">First Name is required</div>
                    <div *ngIf="createLeads.firstName.errors.pattern">Please use valid Name</div>
                    <div *ngIf="createLeads.firstName.errors.minlength">Minimum length should be 2 characters .
                    </div>
                    <div *ngIf="createLeads.firstName.errors.maxlength">Maximum length should be 50 characters .
                    </div>
                  </div>
                </div>

                <div class="form-group">
                  <label class="form-label">Last Name <span style="color: red;">*</span></label>
                  <input class="form-control" name="lastName" formControlName="lastName" [ngClass]="{
                    'is-invalid': createLeads.lastName.touched && createLeads.lastName.invalid,
                    'is-valid':
                      createLeads.lastName.valid &&
                      createLeads.lastName.touched
                  }" />
                  <div *ngIf="createLeads.lastName.touched && createLeads.lastName.errors" class="invalid-feedback">
                    <div *ngIf="createLeads.lastName.errors.required">LastName is required</div>
                    <div *ngIf="createLeads.lastName.errors.pattern">Please use valid Name</div>
                    <div *ngIf="createLeads.lastName.errors.minlength">Minimum length should be 2 characters .</div>
                    <div *ngIf="createLeads.lastName.errors.maxlength">Maximum length should be 50 characters .
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <label class="form-label">Phone Number <span style="color: red;">*</span></label>
                  <div class="row">
                    <div class="col-2">
                      <input class="form-control" name="countryCode" formControlName="countryCode" [ngClass]="{
                    'is-invalid': createLeads.countryCode.touched && createLeads.countryCode.invalid,
                    'is-valid':
                      createLeads.countryCode.valid &&
                      createLeads.countryCode.touched
                  }" />
                      <div *ngIf="createLeads.countryCode.touched && createLeads.countryCode.errors"
                        class="invalid-feedback">
                        <div *ngIf="createLeads.countryCode.errors.required">Country Code is required</div>
                        <div *ngIf="createLeads.countryCode.errors.pattern">Please use valid country code .</div>
                      </div>
                    </div>
                    <div class="col-10">
                      <input class="form-control" name="phoneNumber" formControlName="phoneNumber" [ngClass]="{
                        'is-invalid':
                        createLeads.phoneNumber.touched && createLeads.phoneNumber.invalid,
                        'is-valid':
                          createLeads.phoneNumber.valid &&
                          createLeads.phoneNumber.touched
                      }" />
                      <div *ngIf="createLeads.phoneNumber.touched && createLeads.phoneNumber.errors"
                        class="invalid-feedback">
                        <div *ngIf="createLeads.phoneNumber.errors.required">Phone Number is required</div>
                        <div *ngIf="createLeads.phoneNumber.errors.pattern">Please use valid phone number .</div>
                        <div *ngIf="createLeads.phoneNumber.errors.minlength">Minimum length should be 7 characters .
                        </div>
                        <div *ngIf="createLeads.phoneNumber.errors.maxlength">Maximum length should be 15 characters .
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <label class="form-label">Email <span style="color: red;">*</span></label>
                  <input type="text" name="email" class="form-control" formControlName="email" [ngClass]="{
                    'is-invalid': createLeads.email.touched && createLeads.email.invalid,
                    'is-valid':
                      createLeads.email.valid && createLeads.email.touched
                  }" />
                  <div *ngIf="createLeads.email.touched && createLeads.email.errors" class="invalid-feedback">
                    <div *ngIf="createLeads.email.errors.required">Email is required</div>
                    <div *ngIf="createLeads.email.errors.pattern">Please use valid email</div>
                  </div>
                </div>
                <div class="form-group">
                  <label class="form-label">Form Name</label>
                  <input type="text" name="form_name" class="form-control" formControlName="form_name" [ngClass]="{
                'is-invalid': submitted && createLeads.form_name.invalid,
                'is-valid':
                  createLeads.form_name.valid && createLeads.form_name.touched
              }" />
                  <div *ngIf="submitted && createLeads.form_name.errors" class="invalid-feedback">
                    <div>Form Name is required</div>
                  </div>
                </div>
                <div class="form-group">
                  <label class="form-label">Channel <span style="color: red;">*</span></label>
                  <input type="text" name="channel" class="form-control" formControlName="channel" [ngClass]="{
                'is-invalid': submitted && createLeads.channel.invalid,
                'is-valid':
                  createLeads.channel.valid && createLeads.channel.touched
              }" />
                  <div *ngIf="submitted && createLeads.channel.errors" class="invalid-feedback">
                    <div>Channel is required</div>
                  </div>
                </div>
                <div class="d-flex justify-content-end">
                  <button class="btn btn-primary btn-sm">Submit</button>
                </div>
              </form>
            </div>
          </ng-template>

          <ng-template id="pdfContent" class="modal-x" #pdfContent let-modal>
            <div id="progressBar">
              <mat-progress-bar mode="indeterminate"></mat-progress-bar>
            </div>
            <ngx-doc-viewer [url]="docUrl" viewer="google" googleCheckContentLoaded="true" googleCheckInterval="5000"
              googleMaxChecks="15" style="width: 100%; height: 93vh" (loaded)="contentLoaded()">
            </ngx-doc-viewer>
          </ng-template>


          <!-- Edit Lead New -->
          <ng-template #editLead2 let-modal>
            <div class="modal-header">
              <h4 class="modal-title" id="modal-basic-title">Update Lead Details</h4>
              <button type="button" id="closeButton3" class="close" aria-label="Close"
                (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <form class="pt-2" [formGroup]="uhleadsservice.editLead" (ngSubmit)="updateLead()">
                <div class="form-row">

                  <div class="form-group col-md-4">
                    <label>First Name<span style="color: red;">*</span></label>
                    <input class="form-control" name="FirstName" formControlName="FirstName"
                      [(ngModel)]="leadtoEdit.FirstName"
                      [ngClass]="{ 'is-invalid': editLeads.FirstName.touched && editLeads.FirstName.errors }" />
                    <div *ngIf="editLeads.FirstName.touched && editLeads.FirstName.errors" class="invalid-feedback">
                      <div *ngIf="editLeads.FirstName.errors.required">First Name is required</div>
                      <div *ngIf="editLeads.FirstName.errors.pattern">Please use valid Name</div>
                      <div *ngIf="editLeads.FirstName.errors.minlength">Minimum length should be 2 characters .
                      </div>
                      <div *ngIf="editLeads.FirstName.errors.maxlength">Maximum length should be 50 characters .</div>
                    </div>
                  </div>

                  <div class="form-group col-md-4">
                    <label>Last Name<span style="color: red;">*</span></label>
                    <input class="form-control" name="LastName" formControlName="LastName"
                      [(ngModel)]="leadtoEdit.LastName"
                      [ngClass]="{ 'is-invalid':editLeads.LastName.touched && editLeads.LastName.errors }" />
                    <div *ngIf="editLeads.LastName.touched && editLeads.LastName.errors" class="invalid-feedback">
                      <div *ngIf="editLeads.LastName.errors.required">Last Name is required</div>
                      <div *ngIf="editLeads.LastName.errors.pattern">Please use valid Name</div>
                      <div *ngIf="editLeads.LastName.errors.minlength">Minimum length should be 2 characters .
                      </div>
                      <div *ngIf="editLeads.LastName.errors.maxlength">Maximum length should be 50 characters .</div>
                    </div>

                  </div>

                  <div class="form-group col-md-4">
                    <label>Date of Birth</label>
                    <input type="date" name="DOB" [value]="leadtoEdit.DOB | date:'yyyy-MM-dd'" class="form-control"
                      formControlName="DOB" [(ngModel)]="leadtoEdit.DOB " />

                  </div>

                </div>

                <div class="form-row">
                  <div class="form-group col-md-4">
                    <label>Email<span style="color: red;">*</span></label>
                    <input type="text" name="Email" class="form-control" formControlName="Email"
                      [(ngModel)]="leadtoEdit.Email"
                      [ngClass]="{ 'is-invalid': editLeads.Email.touched && editLeads.Email.errors }" />
                    <div *ngIf="editLeads.Email.touched && editLeads.Email.errors" class="invalid-feedback">
                      <div *ngIf="editLeads.Email.errors.required">Email is required</div>
                      <div *ngIf="editLeads.Email.errors.pattern">Please use valid Email</div>
                    </div>

                  </div>

                  <div class="form-group col-md-4">
                    <label>Phone Number <span style="color: red;">*</span></label>
                    <div class="form-row">
                      <div class="col-4">
                        <input class="form-control" name="CountryCode" formControlName="CountryCode"
                          [(ngModel)]="leadtoEdit.CountryCode"
                          [ngClass]="{ 'is-invalid': editLeads.CountryCode.touched && editLeads.CountryCode.errors }" />
                        <div *ngIf="editLeads.CountryCode.touched && editLeads.CountryCode.errors"
                          class="invalid-feedback">
                          <div *ngIf="editLeads.CountryCode.errors.required">Country Code is required</div>
                          <div *ngIf="editLeads.CountryCode.errors.pattern">Please use valid Country Code</div>
                        </div>

                      </div>
                      <div class="col-8">
                        <input class="form-control" name="PhoneNumber" formControlName="PhoneNumber"
                          [(ngModel)]="leadtoEdit.PhoneNumber"
                          [ngClass]="{ 'is-invalid': editLeads.PhoneNumber.touched && editLeads.PhoneNumber.errors }" />
                        <div *ngIf="editLeads.PhoneNumber.touched && editLeads.PhoneNumber.errors"
                          class="invalid-feedback">
                          <div *ngIf="editLeads.PhoneNumber.errors.required">Phone Number is required</div>
                          <div *ngIf="editLeads.PhoneNumber.errors.pattern">Please use valid Phone Number</div>
                        </div>

                      </div>
                    </div>
                  </div>
                  <div class="form-group col-md-4">
                    <label for="">Enrollment Year <span style="color: red;">*</span></label>
                    <input type="text" name="EnrollmentYear" class="form-control" formControlName="EnrollmentYear"
                      [(ngModel)]="leadtoEdit.EnrollmentYear"
                      [ngClass]="{ 'is-invalid': editLeads.EnrollmentYear.touched && editLeads.EnrollmentYear.errors }" />

                    <div *ngIf="editLeads.EnrollmentYear.touched && editLeads.EnrollmentYear.errors"
                      class="invalid-feedback">
                      <div *ngIf="editLeads.EnrollmentYear.errors.required">Enrollment Year is required</div>
                      <div *ngIf="editLeads.EnrollmentYear.errors.pattern">Only numbers are allowed</div>
                    </div>

                  </div>
                </div>

                <div class="form-row">


                  <div class="form-group col-md-4">
                    <label for="">Enrollment Term <span style="color: red;">*</span></label>
                    <input type="text" name="EnrollmentTerm" class="form-control" formControlName="EnrollmentTerm"
                      [(ngModel)]="leadtoEdit.EnrollmentTerm"
                      [ngClass]="{ 'is-invalid': editLeads.EnrollmentTerm.touched && editLeads.EnrollmentTerm.errors }" />

                    <div *ngIf="editLeads.EnrollmentTerm.touched && editLeads.EnrollmentTerm.errors"
                      class="invalid-feedback">
                      <div *ngIf="editLeads.EnrollmentTerm.errors.required">Enrollment Term is required</div>
                    </div>

                  </div>
                  <div class="form-group col-md-4">
                    <label for="">Address </label>
                    <input type="text" name="Address" class="form-control" formControlName="Address"
                      [(ngModel)]="leadtoEdit.Address" />

                  </div>

                  <div class="form-group col-md-4">
                    <label for="">City </label>
                    <input type="text" name="City" class="form-control" formControlName="City"
                      [(ngModel)]="leadtoEdit.City" />

                  </div>

                </div>

                <div class="form-row">



                  <div class="form-group col-md-4">
                    <label for="">State </label>
                    <input type="text" name="State" class="form-control" formControlName="State"
                      [(ngModel)]="leadtoEdit.State" />

                  </div>
                  <div class="form-group col-md-4">
                    <label for="">Zip </label>
                    <input type="text" name="Zip" class="form-control" formControlName="Zip"
                      [(ngModel)]="leadtoEdit.Zip" />

                  </div>

                  <div class="form-group col-md-4">
                    <label for="">Country </label>
                    <input type="text" name="Country" class="form-control" formControlName="Country"
                      [(ngModel)]="leadtoEdit.Country" />

                  </div>

                </div>

                <div class="form-row">

                  <div class="form-group col-md-4">
                    <label for="">Comments </label>
                    <input type="text" name="LeadComment" class="form-control" formControlName="LeadComment"
                      [(ngModel)]="leadtoEdit.LeadComment" />

                  </div>

                </div>


                <div class="form-row">
                  <div class="form-group col-md-12">
                    <label for="">Feedback</label>
                    <div class="mt-2">

                      <div class="form-check form-check-inline" *ngFor="let item of listFeed">
                        <input class="form-check-input" type="radio" name="CallStatus" id="inlineRadio1"
                          [value]="item.value" formControlName="CallStatus" [(ngModel)]="leadtoEdit.CallStatus" />
                        <label class="form-check-label" for="inlineRadio1">{{item.name}}</label>
                      </div>

                    </div>
                  </div>

                </div>
                <div class="d-flex justify-content-end">
                  <div class="mt-3">
                    <button class="btn btn-primary btn-sm" type="submit">Update</button>
                  </div>
                </div>
              </form>
            </div>
          </ng-template>

          <ng-template id="totalCallCount" class="modal-x" #totalCallCount let-modal>
            <div class="modal-header nm">
              <h4 class="modal-title" id="modal-basic-title">Call</h4>
              <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body ">

              <div class="px-2">
                <div class="py-2 header-md" style="color:#163252">
                  You have reached the calling limit.<br>Are you sure you want to continue?</div>
                <button type="button" class="btn btn-primary btn-sm mr-4" (click)="thresHold()">
                  Confirm
                </button>
                <button type="button" class="btn btn-primary btn-sm" (click)="modal.dismiss('Cross click')">
                  Cancel
                </button>
              </div>
            </div>
          </ng-template>
        </div>
        <div class="fixed-footer">
          Powered by <a href="#">Avantao</a>
        </div>
      </div>

      <ng-template #cardsChangemodal let-modal>
        <div class="modal-header">
          <h4 class="modal-title" id="modal-basic-title">Cards</h4>
          <button type="button" id="closeButton3" class="close" aria-label="Close"
            (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-4" *ngFor="let item of dummycards1">
              <mat-selection-list>
                <mat-list-option style="font-size: 13px" [selected]="item.view"
                  (click)="removeCard(item.primaryKey,!item.view)">
                  {{ item["header"] | titlecase }}
                </mat-list-option>
              </mat-selection-list>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 text-right">
              <button type="button" class="btn btn-primary btn-sm" (click)="modal.dismiss('Cross click')">
                Update
              </button>
            </div>
          </div>
        </div>
      </ng-template>

      <ng-template #tablesChangemodal let-modal>
        <div class="modal-header">
          <h4 class="modal-title" id="modal-basic-title">Tables</h4>
          <button type="button" id="closeButton3" class="close" aria-label="Close"
            (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-4" *ngFor="let item of projectSettings1">
              <mat-selection-list>
                <mat-list-option style="font-size: 13px" [selected]="item.view"
                  (click)="removeTable(item.primaryKey,!item.view)">
                  {{ item["header"] | titlecase }}
                </mat-list-option>
              </mat-selection-list>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 text-right">
              <button type="button" class="btn btn-primary btn-sm"
                (click)="updateTables();modal.dismiss('Cross click')">
                Update
              </button>
            </div>
          </div>
        </div>
      </ng-template>