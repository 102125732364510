import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'pageheaders'
})
export class PageheadersPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    switch (value) {
      case "RequestId":
        return "Incident Id"
      case "Assignedto":
        return "Assigned To"
      case "CreatedDate":
        return "Created Date";
      case "UpdatedDate":
        return "Updated Date"
      case "group":
        return "Assignment Group"
      case "RequestStatus":
        return "Request Status"
      case "summary":
        return "Summary"
      default:
        return value

    }
    return null;
  }

}
