<div class="text-left p-3 mt-2">
  <app-project-change (projectChange)="projectChange($event)"></app-project-change>
</div>
<div class="containers">
  <div class="content-containers" style="margin-left: 30px; margin-right: 30px">
    <div style="width: 100%">
      <div class="mt-3">
        <div class="text-right">
          <button class="btn btn-secondary btn-sm ml-2" (click)="open(content)">
            <img ngbTooltip="Create Module" placement="top" tooltipClass="my-custom-class"
              src="../../../../assets/img/add-w.svg" class="img13" style="width: 11px" />
          </button>
        </div>
      </div>
    </div>
    <div class="right-content-right-box-bottom-container align-items-center">
      <div class="bottom-content-box-header-container">
        <div class="ticket-requested-list-container">
          <div class="requsted-list-heading-container data-table" style="padding: 0">
            <div class="table-scroll data-table">
              <table class="table table-md table-hover table-wrap">
                <thead>
                  <tr>
                    <td colspan="7" style="padding: 8px">
                      <div class="table-search-content">
                        <div class="indived-search" style="position: relative">
                          <input type="text" class="form-control search-all" [(ngModel)]="search"
                            placeholder="Search..." />

                        </div>
                        <div class="pagenation-right">
                          <ngb-pagination [(page)]="page" [pageSize]="pageSize" [collectionSize]="updateModules.length"
                            class="pagenation-right" [maxSize]="5" [boundaryLinks]="true"></ngb-pagination>
                        </div>
                      </div>
                    </td>
                  </tr>

                  <tr style="background: #eaf5fd; border-bottom: none">
                    <!-- <th style="display: none">Company id</th> -->
                    <th>
                      <span>
                        Name
                        <img class="img7" src="../../../../assets/img/sort-data.svg" style="
                            cursor: pointer;
                            margin-left: 10px;
                            color: black;
                          " (click)="sortOrder('Name')" />
                      </span>
                    </th>
                    <th>
                      <span>
                        Description
                        <img class="img7" src="../../../../assets/img/sort-data.svg" style="
                            cursor: pointer;
                            margin-left: 10px;
                            color: black;
                          " (click)="sortOrder('Description')" />
                      </span>
                    </th>
                    <th>
                      <span>
                        SMS
                        <img class="img7" src="../../../../assets/img/sort-data.svg" style="
                            cursor: pointer;
                            margin-left: 10px;
                            color: black;
                          " (click)="sortOrder('SMSModule')" />
                      </span>
                    </th>
                    <th>
                      <span>
                        Call
                        <img class="img7" src="../../../../assets/img/sort-data.svg" style="
                            cursor: pointer;
                            margin-left: 10px;
                            color: black;
                          " (click)="sortOrder('CallModule')" />
                      </span>
                    </th>
                    <th>
                      <span>
                        Mail
                        <img class="img7" src="../../../../assets/img/sort-data.svg" style="
                            cursor: pointer;
                            margin-left: 10px;
                            color: black;
                          " (click)="sortOrder('MailModule')" />
                      </span>
                    </th>
                    <th>
                      <span>
                        Created Date
                        <img class="img7" src="../../../../assets/img/sort-data.svg" style="
                            cursor: pointer;
                            margin-left: 10px;
                            color: black;
                          " (click)="sortOrder('createdAt')" />
                      </span>
                    </th>
                    <th>
                      <span>
                        Updated Date
                        <img class="img7" src="../../../../assets/img/sort-data.svg" style="
                            cursor: pointer;
                            margin-left: 10px;
                            color: black;
                          " (click)="sortOrder('updatedAt')" />
                      </span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="
                      let item of updateModules
                        | filter: search
                        | slice: (page - 1) * pageSize:page * pageSize
                        | sort: orderFeild:orderString;
                      let id = index
                    ">
                    <td class="id-col-mod" style="cursor: pointer; color: #1091ad"
                      (click)="updateModule(content, item)">
                      {{ item["Name"] }}
                    </td>

                    <td>
                      {{
                      item["Description"]
                      ? item["Description"].slice(0, 50) + "..."
                      : "-"
                      }}
                    </td>

                    <td>{{ item["SMSModule"] ? "Active" : "In Active" }}</td>

                    <td>
                      {{ item["CallModule"] ? "Active" : "In Active" }}
                    </td>
                    <td>{{ item["MailModule"] ? "Active" : "In Active" }}</td>
                    <td>{{ item["createdAt"] | date: "medium" }}</td>
                    <td>{{ item["updatedAt"] | date: "medium" }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      {{ update ? "Update " : "Create" }} Module
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" style="overflow: auto; height: 80vh">
    <ngb-alert type="danger" *ngIf="isError">
      Something Wen't Wrong Please Try Again Later
    </ngb-alert>
    <form [formGroup]="moduleForm" class="px-3" (ngSubmit)="submit()">
      <div class="row">
        <label for="name" class="form-label">Module Name</label>
        <input type="text" class="form-control" name="name" [ngClass]="{ 'is-invalid': submitted && f.Module.errors }"
          required formControlName="Module" />
        <div *ngIf="submitted && f.Module.errors" class="invalid-feedback">
          <div *ngIf="f.Module.errors.required">Module Name is required</div>
        </div>
      </div>
      <div class="row my-2">
        <label for="name" class="form-label mt-2">Module Description</label>
        <input type="text" class="form-control" name="name"
          [ngClass]="{ 'is-invalid': submitted && f.description.errors }" required formControlName="description" />
        <div *ngIf="submitted && f.description.errors" class="invalid-feedback">
          <div *ngIf="f.description.errors.required">
            Module description is required
          </div>
        </div>
      </div>
      <div class="row my-2">
        <mat-checkbox class="mx-2" [checked]="moduleCall" (change)="changeModule('call')">Call</mat-checkbox>
        <mat-checkbox class="mx-2" [checked]="moduleSms" (change)="changeModule('sms')">
          SMS</mat-checkbox>
        <mat-checkbox class="mx-2" [checked]="moduleMail" (change)="changeModule('mail')">Mail</mat-checkbox>
      </div>
      <div *ngIf="moduleCall">
        <div></div>
        <div class="row spl-block mb-3">
          <div class="col-md-12 mb-3" *ngIf="!callUpdate">
            <label for="description" class="form-label" required>Call Type</label>
            <select class="form-control" name="CallType" formControlName="CallType"
              [ngClass]="{ 'is-invalid': submitted && f.CallType.errors }">
              <option>Information</option>
              <option>Feedback</option>
            </select>
            <div *ngIf="submitted && f.CallType.errors" class="invalid-feedback">
              <div *ngIf="f.CallType.errors.required">
                Call Type is required
              </div>
            </div>
          </div>

          <div class="col-md-12 mb-3" *ngIf="!callUpdate">
            <label for="description" class="form-label">Redial Count</label>
            <select class="form-control" name="RedialThreshold" formControlName="RedialThreshold" required [ngClass]="{
                'is-invalid': submitted && f.RedialThreshold.errors
              }">
              <option>0</option>
              <option>1</option>
              <option>2</option>
              <option>3</option>
              <option>4</option>
            </select>
            <div *ngIf="submitted && f.RedialThreshold.errors" class="invalid-feedback">
              <div *ngIf="f.RedialThreshold.errors.required">
                Redial Count is required
              </div>
            </div>
          </div>

          <div class="col-md-12 mb-2">
            <label for="description" class="form-label">Call Text
              <span class="" *ngIf="this.moduleForm.value.CallType === 'Feedback'">(Header)</span>
            </label>
            <textarea name="SmsText" class="form-control" formControlName="CallTextHeader" rows="2" required
              [ngClass]="{ 'is-invalid': submitted && f.CallTextHeader.errors }">
            </textarea>
          </div>
          <div>
            <div *ngIf="submitted && f.CallTextHeader.errors" class="invalid-feedback row">
              <div *ngIf="f.CallTextHeader.errors.required">
                Call Text is required
              </div>
            </div>
          </div>

          <div class="col-md-12 mb-2" formArrayName="Ivr" *ngIf="this.moduleForm.value.CallType === 'Feedback'">
            <div class="d-flex align-items-center mb-3" *ngFor="let quantity of callIvr().controls; let i = index"
              [formGroupName]="i">
              <div class="col">
                <label class="form-label">IVR Option :</label>
                <input type="text" formControlName="feedBackNumber" class="form-control" />
              </div>
              <div class="col">
                <label class="form-label">IVR Text :</label>
                <input type="text" formControlName="value" class="form-control" />
              </div>
              <div class="col">
                <button (click)="removeNumber(i)" class="btn btn-primary mt-3"
                  *ngIf="i !== callIvr().controls.length - 1">
                  <i class="fa fa-minus"></i>
                </button>
                <button type="button" (click)="addNewNumber()" class="btn btn-primary mt-3"
                  *ngIf="i === callIvr().controls.length - 1">
                  <i class="fa fa-plus"></i>
                </button>
              </div>
            </div>
          </div>

          <div class="col-md-12 mb-2" *ngIf="this.moduleForm.value.CallType === 'Feedback'">
            <label for="description" class="form-label">Call Text
              <span class="mx-2" *ngIf="this.moduleForm.value.CallType === 'Feedback'">(Footer)</span>
            </label>
            <textarea name="CallText" class="form-control" formControlName="CallTextFooter" rows="2"
              [ngClass]="{ 'is-invalid': submitted && f.CallTextFooter.errors }">
            </textarea>
            <div *ngIf="submitted && f.CallTextFooter.errors" class="invalid-feedback">
              <div *ngIf="f.CallTextFooter.errors.required">
                Call Text Footer is required
              </div>
            </div>
          </div>

          <div class="col-md-12 mb-3">
            <label class="form-label" style="width: 100%">Before Dial Time</label>
            <ngb-timepicker formControlName="time" [seconds]="true"></ngb-timepicker>
          </div>
        </div>
      </div>
      <div class="row spl-block mb-3" style="display: block" *ngIf="moduleSms">
        <app-smsmodules [smsForm]="smsForm" [update]="update" [submitted]="submitted" [feedBackId]="feedBackSMSId"
          [smsUpdate]="smsUpdate"></app-smsmodules>
      </div>
      <div class="row spl-block mb-3" style="display: block" *ngIf="moduleMail">
        <div [formGroup]="mailForm" class="p-2 pt-1">
          <div class="col-md-12 mb-2" *ngIf="!mailUpdate">
            <label for="description" class="form-label" required>Mail Type..</label>
            <select class="form-control" name="mailType" formControlName="mailType" [ngClass]="{
                'is-invalid': submitted && mailGroup.mailType.errors
              }">
              <option>Information</option>
              <option>Feedback</option>
            </select>
            <div *ngIf="submitted && mailGroup.mailType.errors" class="invalid-feedback">
              <div *ngIf="mailGroup.mailType.errors.required">
                SMS Type is required
              </div>
            </div>
          </div>
          <div class="col-md-12 mb-2">
            <label for="description" class="form-label">Mail Subject </label>
            <textarea name="mailSubject" class="form-control" formControlName="mailSubject" rows="2" required [ngClass]="{
                'is-invalid': submitted && mailGroup.mailSubject.errors
              }">
            </textarea>
            <div *ngIf="submitted && mailGroup.mailSubject.errors" class="invalid-feedback">
              <div *ngIf="mailGroup.mailSubject.errors.required">
                Mail Subject is Required
              </div>
            </div>
          </div>
          <div class="col-md-12 mb-2">
            <label for="description" class="form-label" required>Templates</label>
            <select class="form-control" name="templateId" formControlName="templateId" [ngClass]="{
                'is-invalid': submitted && mailGroup.templateId.errors
              }">
              <option *ngFor="let temp of marketingTemplates" [value]="temp.id">
                {{ temp["Name"] | titlecase }}
              </option>
              <!-- <option value="2">template2</option> -->
            </select>
            <div *ngIf="submitted && mailGroup.templateId.errors" class="invalid-feedback">
              <div *ngIf="mailGroup.templateId.errors.required">
                Mail Template is Required
              </div>
            </div>
          </div>
          <div class="col-md-12 mb-2">
            <label class="form-label">Before Mail Time</label>
            <ngb-timepicker formControlName="time" [seconds]="true"></ngb-timepicker>
          </div>
        </div>
      </div>

      <div class="d-flex justify-content-end my-2">
        <button class="btn btn-primary">Submit</button>
      </div>
    </form>
  </div>
</ng-template>