<div style="width:450px;">
    <div class="d-flex mat-modal-header p-4" style="width:100%">
        <div class="modal-title component-title mr-auto">Invite people to call</div>
        <div class="ml-auto close hand">
            <img src="../../../../assets/img/close.svg" class="img10" />
        </div>
    </div>
    <div class="p-4">
        <div>
            <input class="search-invite-input" type="text" placeholder="Type name to invite">

        </div>
    </div>

    <div class="px-4 py-2">
        <div>Invited</div>
    </div>
    <div class="invite-members-list px-4">
        <div class="py-2">
            <div class="d-flex">
                <div class="icon-photo-frame">Pic</div>
                <div class="d-flex pl-2 pt-2 flex-column">
                    <div class="person-title mb-1">Srinivas</div>
                    <div class="person-subtitle">PR Agent</div>
                </div>
                <div class="ml-auto">
                    <img src="../../../../assets/img/close.svg" class="img10" />
                </div>
            </div>
        </div>
        <div class="py-2">
            <div class="d-flex">
                <div class="icon-photo-frame">Pic</div>
                <div class="d-flex pl-2 pt-2 flex-column">
                    <div class="person-title mb-1">Arjun</div>
                    <div class="person-subtitle">PR Agent</div>
                </div>
                <div class="ml-auto">
                    <img src="../../../../assets/img/close.svg" class="img10" />
                </div>
            </div>
        </div>
        <div class="py-2">
            <div class="d-flex">
                <div class="icon-photo-frame">Pic</div>
                <div class="d-flex pl-2 pt-2 flex-column">
                    <div class="person-title mb-1">John</div>
                    <div class="person-subtitle">PR Agent</div>
                </div>
                <div class="ml-auto">
                    <img src="../../../../assets/img/close.svg" class="img10" />
                </div>
            </div>
        </div>
    </div>


    <div class="p-4">
        <div class="d-flex ">
            <div><button class="btn btn-outline-secondary">Copy Link</button></div>
            <div class="ml-auto"><button class="btn btn-primary">Invite</button></div>
        </div>
    </div>




</div>