import { Component, OnInit, ViewChild } from '@angular/core';
import { Calendar, CalendarOptions, EventInput, FullCalendarComponent } from '@fullcalendar/angular';
import { MarketingService } from '../marketing.service';

@Component({
  selector: 'app-eventcalender',
  templateUrl: './eventcalender.component.html',
  styleUrls: ['./eventcalender.component.css']
})
export class EventcalenderComponent implements OnInit {
  @ViewChild('calendar', { static: true }) calendarComponent: FullCalendarComponent;
  calendarApi: Calendar;
  calendarOptions: CalendarOptions = {
    initialView: 'dayGridMonth',
  };
  initialized = false;
  calendarEvents: EventInput[] = [];

  constructor(private calenderService: MarketingService) { }

  ngOnInit() {
  }
  ngAfterViewChecked() {
    this.calendarApi = this.calendarComponent.getApi();

    if (this.calendarApi && !this.initialized) {
      this.initialized = true;
      this.calenderService.getEventsByStartEnd().toPromise()
        .then((value) => {
          var data: any = value;
          data.map((eventval) => {
            this.loadEvents(eventval);
          })
        })
        .catch(err => {
        })
    }
  }
  loadEvents(eventval) {
    const event = {
      title: eventval.Event,
      start: eventval.StartTime,
      end: eventval.EndTime,
    };
    this.calendarEvents.push(event);
    this.calendarApi.removeAllEventSources();
    this.calendarApi.addEventSource(this.calendarEvents);
  }
}
