<div class="notification">
  <div class="dropdown-menu3" style="width: 350px !important; padding: 0" aria-labelledby="navbarDropdown2">
    <div class="notification-head p-3 d-flex">
      <b>Notifications</b>
      <div class="ml-auto">
        <!-- <img src="../../../assets/img/close-w.svg" class="img12" /> -->
      </div>
    </div>
    <div class="noti-m-h">
      <!-- <mat-tab-group>
        <mat-tab label="Recent"> -->
      <div *ngFor="let i of notificationList; let index=index" (click)="view(i,index,i.initiator)">
        <div class="sm-text float-right">{{ i.age }}</div>
        <div class="p-2 d-flex">
          <div>
            <div *ngIf="i.Read == false" class="dot-online mt-15"></div>
          </div>
          <div class="mr-2">
            <div class="circle2">
              <img src="../../../../assets/img/user-profile.svg" />
            </div>
          </div>
          <div class="md-text">
            {{ i.Description }}
          </div>
        </div>
        <div class="text-right">
          <img src="./assets/img/arrow_forward.svg" alt="" class="img12" style="width: 20px;
              margin-right: 10px;">
        </div>
        <!-- <div class="d-flex">
              <div class="ml-auto">

                <div class="bookmark_button_section2 d-flex mr-2">
                  <button *ngIf="i.saved ==false" class="btn-icon mr-2" (click)="save(i,true, index)">
                    <img src="../../../../assets/img/bookmark-b.svg" class="img12" />
                  </button>
                  <button *ngIf="i.saved ==true" class="btn-icon mr-2" (click)="save(i,false, index)">
                    <img src="../../../../assets/img/bookmark-g.svg" class="img12" style="width:10px" />
                  </button>
                  <button class="btn-icon mr-2" (click)="view(i,index)" >
                    <img src="../../../../assets/img/view.svg" class="img12" style="width:15px" />
                  </button>
                  <button class="btn-icon mr-2" (click)="deleteNotification(i)">
                    <img src="../../../../assets/img/delete.svg" class="img12" />
                  </button>
                </div>
              </div>
            </div> -->
        <div class="dropdown-divider"></div>
      </div>
      <!-- <div *ngIf="totalNotifications>=10" class="notification-bottom d-flex text-center"><div class="loadmore" style="cursor: pointer;" (click)="loadMore()">Load More</div></div> -->
      <!-- </mat-tab> -->

      <!-- <mat-tab label="Saved">
          <div *ngFor="let i of savedNotifications; let index=index">
            <div class="sm-text float-right">{{ i.age }}</div>
            <div class="p-2 d-flex">
              <div class="mr-2">
                <div class="circle2">
                  <img src="../../../../assets/img/user-profile.svg" />
                </div>
              </div>
              <div class="md-text">
                {{ i.Description }}
              </div>
            </div>
            <div class="d-flex">
              <div class="bookmark_button_section2 ml-auto">
                <button class="btn-icon mr-2" (click)="unsave(i)">
                  <img src="../../../../assets/img/bookmark-g.svg" class="img12" style="width:10px" />
                </button>
                <button class="btn-icon mr-2" (click)="view2(i)">
                  <img src="../../../../assets/img/view.svg" class="img12" style="width:15px" />
                </button>
                <button class="btn-icon mr-2" (click)="deleteNotification(i)">
                  <img src="../../../../assets/img/delete.svg" class="img12" />
                </button>
              </div>
            </div>
            <div class="dropdown-divider"></div>
          </div>
        </mat-tab> -->
      <!-- </mat-tab-group> -->
    </div>
  </div>
</div>