import { SharedService } from 'src/app/shared/shared.service';
import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-googledirections',
  templateUrl: './googledirections.component.html',
  styleUrls: ['./googledirections.component.css']
})
export class GoogledirectionsComponent implements OnInit {
  public lat = 15.619630;
  public lng = 77.271347;

  public origin: any;
  public destination: any;
  public renderOptions = {
    suppressMarkers: true,
  }

  public markerOptions = {
    origin: {

      // infoWindow: 'This is origin.',
      icon: {
        url: 'assets/googlemaps/destination_custom_pin.svg', scaledSize: {
          height: 40,
          width: 40
        },
      },
    },
    destination: {
      icon: {
        url: 'assets/googlemaps/destination_custom_pin.svg', scaledSize: {
          height: 40,
          width: 40
        },
      },
    },

  }

  constructor(private readonly modalService: NgbModal, private sharedService: SharedService) { }

  ngOnInit() {
    this.getDirection();
    this.sharedService.traceLocation.subscribe(data => {
      if (data) {

        this.origin = data.source;
        this.destination = data.destination;
      }
    })
  }
  dismiss() {
    this.modalService.dismissAll()
  }
  getDirection() {
    this.origin = { lat: 15.619630, lng: 77.271347 };
    this.destination = { lat: 13.184570, lng: 77.479279 };
  }
}
