import {
  CompanyModule,
  InputModule,
} from "./../companymodules/models/companymodule.model";
import { Info } from "./infomarketing/models/Info.model";
import { HttpHeaders, HttpClient } from "@angular/common/http";
// import { HttpClientService } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { HttpClientService } from "src/app/httpclient.service";
import { SharedService } from "src/app/shared/shared.service";
import { environment } from "src/environments/environment";
import { Exceldata } from "./smsmarketing/models/ExecelData.model";
import { catchError, map } from "rxjs/operators";
import { MailserviceService } from "../mailservice.service";
import { FormBuilder, RequiredValidator, Validators } from "@angular/forms";
// import { map } from "lodash";
export enum REGEX {
  EMAIL = '[A-Za-z0-9._%-]+@[A-Za-z0-9._%-]+\\.[a-z]{2,4}',
  PHONE = '[0-9]{10}$',
  PASSWORD = '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$',
  ZIP = '^[a-zA-Z0-9,\'-]*$',
  NUMBER = '^[0-9]*$',
  URL = '^(http:\/\/www\.|https:\/\/www\.|www\.|http:\/\/|https:\/\/)[a-zA-Z0-9\.\-]+\\.[a-zA-Z]{2,5}[\.]{0,1}',
  CHARACTER = "^[a-zA-Z /'.-]*$",
  SPECIAL_PHONE = '^[+|0-9]+[0-9]{1,}$',
  ADDRESS = '^[a-zA-Z0-9\s,\'-]*$',
}

export interface InputCallData {
  ProjectId: number;
  RecipientNumber: string;
  ScheduledCallTime: any;
  Type: string;
  CallerId: string;
  Module: string;
  // channel:
}

interface CreateAutoMailCompany {
  ProjectId: any;
  EmailType: string;
  TemplateId: 18;
  BeforeEmailTime: string;
  Module: string;
  Subject: string;
}
export interface CreateAutoEmail {
  ProjectId: any;
  RecipientEmail: string;
  ScheduledEmailTime: any;
  Type: string;
  Module: string;
}

export interface InputSMSData {
  ProjectId: string;
  RecipientNumber: string;
  ScheduledSMSTime: any;
  Type: string;
  Module: string;
}

export interface CreateModule {
  name: string;
  smsModule: string;
  callModule: string;
  mailModule: string;
  ProjectId: string;
  createdBy: string;
  description: string;
}

interface updateModule {
  id: string;
  smsModule: string;
  callModule: string;
  mailModule: string;
  description: string;
}

interface updateAutoMailComp {
  ProjectId: any;
  EmailType: string;
  TemplateId: string;
  Module: string;
}

interface createEmailData {
  ProjectId: string;
  Type: string;
  Module: string;
  Html: string;
  RecipientEmail: string;
  ScheduledEmailTime: string;
}

interface CreateDemo {
  Name: string;
  Presenter: string;
  DateTime: Date | string;
  Url: string;
  Feedback: string;
  Comments: string;
  ProjectId: any;
  AutoSMSId: any;
  AutoCallId: any;
  AutoEmailId: any;
  ClientURL: any;
  RoomName: any;
  LeadId: any;
  agentEmail: any;
  // ProspectId: any;
}

@Injectable({
  providedIn: "root",
})
export class MarketingService {
  baseApiUrl = environment.baseApiUrl2;
  twilioApiUrl = environment.twilioApiUrl;
  userData = JSON.parse(`${localStorage.getItem("userData")}`);
  createLead = this.formBuilder.group({
    firstName: ["", [Validators.required, Validators.pattern(REGEX.CHARACTER), Validators.minLength(2), Validators.maxLength(50)]],
    lastName: ["", [Validators.required, Validators.pattern(REGEX.CHARACTER), Validators.minLength(2), Validators.maxLength(50)]],
    countryCode: ["", [Validators.required, Validators.pattern(REGEX.NUMBER)]],
    form_name: ["", [Validators.required]],
    phoneNumber: ["", [Validators.required, Validators.pattern(REGEX.NUMBER), Validators.minLength(7), Validators.maxLength(15)]],
    email: ["", [Validators.required, Validators.pattern(REGEX.EMAIL)]],
    channel: ["", [Validators.required]],
    assignedTo: [this.userData ? this.userData["mail"] : ""],
    module: ["LeadsMarketing"],
    Miscellaneous: [""],
  });
  editLead = this.formBuilder.group({
    FirstName: ["", [Validators.required, Validators.pattern(REGEX.CHARACTER), Validators.minLength(2), Validators.maxLength(50)]],
    LastName: ["", [Validators.required, Validators.pattern(REGEX.CHARACTER), Validators.minLength(2), Validators.maxLength(50)]],
    CountryCode: ["", [Validators.required, Validators.pattern(REGEX.NUMBER)]],
    PhoneNumber: ["", [Validators.required, Validators.pattern(REGEX.NUMBER), Validators.minLength(7), Validators.maxLength(15)]],
    Email: ["", [Validators.required, Validators.pattern(REGEX.EMAIL)]],
    DOB: ["", [Validators.required]],
    EnrollmentYear: ["", [Validators.required]],
    EnrollmentTerm: ["", [Validators.required]],
    Address: ["", [Validators.required]],
    City: ["", [Validators.required]],
    State: ["", [Validators.required]],
    Zip: ["", [Validators.required]],
    Country: ["", [Validators.required]],
    LeadComment: ["", [Validators.required]],
  });

  constructor(
    private http: HttpClientService,
    private readonly https: HttpClient,
    private readonly sharedService: SharedService,
    private readonly mailService: MailserviceService,
    private readonly formBuilder: FormBuilder
  ) { }

  resetCreateLead() {
    this.createLead.reset();
    this.createLead.patchValue({
      assignedTo: this.userData["mail"],
      module: "LeadsMarketing",
    });
  }

  get createLeadValue() {
    return this.createLead.value;
  }

  convert2dArraytoObject(formatingData): Exceldata[] {
    let excelData: Exceldata[] = [];
    for (let inner = 1; inner <= formatingData.length - 1; inner++) {
      let convertData: any = {};
      // 

      if (formatingData[inner][0]) {
        convertData["firstName"] = formatingData[inner][0];
        convertData["lastName"] = formatingData[inner][1];
        convertData["countryCode"] = typeof formatingData[inner][2] === 'number' ? formatingData[inner][2] : '';
        convertData["phoneNumber"] = formatingData[inner][3];
        convertData["email"] = formatingData[inner][4];
        convertData["assignedTo"] = formatingData[inner][5];
        convertData["module"] = formatingData[inner][6];
        convertData["channel"] = formatingData[inner][7];
        convertData["Miscellaneous"] = {
          form_id: "",
          form_name: formatingData[inner][8],
          "Choose Batch": "",
          "Training Mode": "",
        };
        convertData["CreatedDate"] = formatingData[inner][9];
        convertData["Comments"] = formatingData[inner][10]
        excelData.push(convertData);
      }
    }

    // 
    return excelData;
  }

  postMarketingData(data: {
    data: Exceldata[];
    createdBy: string;
    ProjectId: number;
  }) {
    return this.http.post(`${this.baseApiUrl}smsData`, data);
  }

  getSmsData(body: {
    SmsStatus: string;
    ProjectId: number;
    mailId: string;
    admin: boolean;
  }) {
    return this.http.post<any[]>(`${this.baseApiUrl}smsMarketing`, body);
  }

  sendSms(body: { from: string; to: string; text: string }, companyId) {
    return this.http.post(
      `${this.twilioApiUrl}${companyId}/messaging-adapter/sendSMS`,
      body
    );
  }

  conferenceCallConnect(
    body: { CallSid: string; phone: string; callerId: string },
    companyId
  ) {
    // 
    return this.http.post(
      `${this.twilioApiUrl}${companyId}/phone/conference-participant`,
      body
    );
  }
  updateSmsStatus(body: {
    id: string;
    messages: {
      body: string;
      sender: string;
      receiver: string;
      sendAt: any;
    };
    assignedTo: any;
  }) {
    return this.http.post(`${this.baseApiUrl}updateSmsStatus`, body);
  }

  updateSmsData(body: { id: string; comments: string; phoneNumber }) {
    return this.http.post(`${this.baseApiUrl}updateSmsData`, body);
  }

  postCallData(data: {
    data: Exceldata[];
    createdBy: string;
    ProjectId: number;
    type: string;
  }) {
    return this.http.post(`${this.baseApiUrl}addCallData`, data);
  }

  updateCallSid(data: { id: string; sid: string }) {
    return this.http.post(`${this.baseApiUrl}updateCallData`, data);
  }

  getCallData(body: {
    callStatus: string;
    ProjectId: string;
    mailId: string;
    admin: boolean;
  }) {
    return this.http.post<any[]>(`${this.baseApiUrl}callData`, body);
  }

  updateCallStatus(body: {
    status: string;
    id: string;
    calledBy: string;
    followUpdate: Date;
  }) {
    return this.http.post(`${this.baseApiUrl}updateCallStatus`, body);
  }

  getExcelLink(location) {
    return this.http.post(`${this.baseApiUrl}getAwsSignedUrl`, {
      url: location,
    });
  }

  getAutodialCompanyData(companyId, apiKey) {
    const headers = new HttpHeaders({ "x-auth-token": apiKey });
    return this.https.get<Info[]>(
      `${this.baseApiUrl}AutoDialCallsByComp/${companyId}`,
      { headers: headers }
    );
  }

  getAutoDialCompany(id) {
    return this.http.get(`${this.baseApiUrl}getAutoDialCompById/${id}`);
  }

  convert2dArraytocallData(formatingData, companyId) {
    let excelData: InputCallData[] = [];
    for (let inner = 1; inner <= formatingData.length - 1; inner++) {
      let convertData: any = {};
      // 
      if (formatingData[inner][0]) {
        convertData["ProjectId"] = companyId;
        convertData["RecipientNumber"] = `+${formatingData[inner][0] || " "}${formatingData[inner][1]
          }`;
        convertData["ScheduledCallTime"] = formatingData[inner][2];
        convertData["Type"] = formatingData[inner][3];
        (convertData[
          "CallerId"
        ] = `+${formatingData[inner][4]}${formatingData[inner][5]}`),
          (convertData["Module"] = formatingData[inner][6]);

        excelData.push(convertData);
      }
    }
    // 
    return excelData;
  }

  async insertCallData(body: InputCallData[], apikey) {
    let headers = new HttpHeaders({ "x-auth-token": apikey });
    let number = 0;
    for (let el = 0; el <= body.length - 1; el++) {
      try {
        await this.https
          .post(`${this.baseApiUrl}createAutoDialCall`, body[el], {
            headers: headers,
          })
          .toPromise();
        number = number + 1;
        // 
      } catch (err) {
        // 
        if (number) {
          number = number - 1;
        }
      }
    }
    // 
    return number;
  }

  getInfoCompanies(companyId) {
    return this.http.get<InputModule[]>(
      `${this.baseApiUrl}getAllAutoDialCompany/${companyId}`
    );
  }

  createAutoDialCompany(body: InputModule) {
    return this.http.post(`${this.baseApiUrl}createAutoDialCompany`, body);
  }

  getAutoDialById(id) {
    return this.http.get(`${this.baseApiUrl}getAutoDialCompany/${id}`);
  }
  updateAutoDial(body: InputModule) {
    return this.http.post(`${this.baseApiUrl}updateAutoDialComp`, body);
  }

  getCallDialType(body: { ProjectId: string; type: string }) {
    return this.http.post<Info[]>(`${this.baseApiUrl}getAutoDialType`, body);
  }

  createNumberConfigs(body: {
    moduleName: string;
    moduleId: string;
    numberConfig: any[];
    ProjectId: string;
    callTextHeader: string;
    callTextFooter: string;
  }) {
    return this.http.post(`${this.baseApiUrl}createIvrNumberConfig`, body);
  }

  updateNumberConfigs(body: {
    id: string;
    callTextHeader: string;
    callTextFooter: string;
    numberConfig: string;
  }) {
    return this.http.post(`${this.baseApiUrl}updateIvrNumberConfig`, body);
  }

  getModuleConfig(body: { id: string; moduleName: string }) {
    return this.http.post(`${this.baseApiUrl}getModuleConfig`, body);
  }

  createSmsConfig(body: {
    moduleName: string;
    moduleId: string;
    smsConfig: any[];
    ProjectId: string;
    smsTextHeader: string;
    smsTextFooter: string;
  }) {
    return this.http.post(`${this.baseApiUrl}createSmsConfig`, body);
  }

  updateSmsConfig(body: {
    id: string;
    smsTextHeader: string;
    smsTextFooter: string;
    smsConfig: string;
  }) {
    return this.http.post(`${this.baseApiUrl}updateSmsConfig`, body);
  }

  getSmsConfig(body: { id: string; moduleName: string }) {
    return this.http.post(`${this.baseApiUrl}getSmsConfig`, body);
  }

  createAutoSmsConfig(body: {
    CompanyId: string;
    SMSType: string;
    SMSText: string;
    BeforeSMSTime: string;
    Module: string;
  }) {
    return this.http.post(`${this.baseApiUrl}createAutoSMSCompany`, body);
  }

  updateAutoSmsConfig(body: {
    CompanyId: string;
    SMSType: string;
    SMSText: string;
    Module: string;
  }) {
    return this.http.post(`${this.baseApiUrl}updateAutoSMSComp`, body);
  }

  getAllSmsByCompany(companyId) {
    return this.http.get<any[]>(
      `${this.baseApiUrl}getAllAutoSMSCompany/${companyId}`
    );
  }

  getSMSCompanyById(id) {
    return this.http.get(`${this.baseApiUrl}getAutoSMSCompany/${id}`);
  }

  getAutoSmsByCompany(companyId, apiKey) {
    const headers = new HttpHeaders({ "x-auth-token": apiKey });
    return this.https.get<any[]>(
      `${this.baseApiUrl}AutoSMSByComp/${companyId}`,
      { headers: headers }
    );
  }

  convert2dArraytoSMSData(formatingData, companyId) {
    let excelData: InputSMSData[] = [];
    for (let inner = 1; inner <= formatingData.length - 1; inner++) {
      let convertData: any = {};
      // 
      if (formatingData[inner][0]) {
        convertData["CompanyId"] = companyId;
        convertData["RecipientNumber"] = `+${formatingData[inner][0] || ""}${formatingData[inner][1]
          }`;
        convertData["ScheduledSMSTime"] = formatingData[inner][2];
        convertData["Type"] = formatingData[inner][3];
        convertData["Module"] = formatingData[inner][4];
        excelData.push(convertData);
      }
    }
    // 
    return excelData;
  }

  async InserSmsData(body: InputSMSData[], apikey) {
    let headers = new HttpHeaders({ "x-auth-token": apikey });
    let number = 0;
    for (let el = 0; el <= body.length - 1; el++) {
      try {
        await this.https
          .post(`${this.baseApiUrl}createAutoSMS`, body[el], {
            headers: headers,
          })
          .toPromise();
        number = number + 1;
        // 
      } catch (err) {
        // 
        // if (number) {
        //   number = number - 1;
        // }
      }
    }
    // 
    return number;
  }

  async createAutoSmsScheduler(body: InputSMSData, apikey) {
    let headers = new HttpHeaders({ "x-auth-token": apikey });
    return this.https
      .post(`${this.baseApiUrl}createAutoSMS`, body, {
        headers: headers,
      })
      .toPromise();
  }

  async createAutoCallScheduler(body: InputCallData, apikey) {
    let headers = new HttpHeaders({ "x-auth-token": apikey });
    return this.https
      .post(`${this.baseApiUrl}createAutoDialCall`, body, {
        headers: headers,
      })
      .toPromise();
  }

  getSMSTypes(body: { ProjectId: string; type: string }) {
    return this.http.post<any[]>(`${this.baseApiUrl}getAutoSMSType`, body);
  }

  createCompanyModule(body: CreateModule) {
    return this.http.post(`${this.baseApiUrl}companyModule`, body);
  }

  getCompanyModule(id) {
    return this.http.get(`${this.baseApiUrl}getModule/${id}`);
  }

  getCompanyModules(companyId) {
    return this.http.get<CompanyModule[]>(
      `${this.baseApiUrl}getAllModules/${companyId}`
    );
  }

  updateModule(body: updateModule) {
    return this.http.post(`${this.baseApiUrl}updateModule`, body);
  }

  getModuleConfigs(name: string, companyId: string) {
    return this.http.get(`${this.baseApiUrl}moduleconfig/${name}/${companyId}`);
  }

  deleteContact(id) {
    return this.http.post(`${this.baseApiUrl}deleteContact`, { id: id });
  }

  updateMailFeedback(body: {
    flag: string;
    EmailReferenceId: string;
    Feedback: string;
  }) {
    return this.http.put(`${this.baseApiUrl}updateAutoEmail`, body);
  }

  createAutoEmailCompany(body: CreateAutoMailCompany) {
    return this.http.post(`${this.baseApiUrl}createAutoEmailCompany`, body);
  }

  async createAutoEmail(body: CreateAutoEmail, apikey) {
    // let headers =
    return this.http
      .post(`${this.baseApiUrl}createAutoEmail`, body)
      .toPromise();
  }

  updateAutoEmail(body: updateAutoMailComp) {
    return this.http.post(`${this.baseApiUrl}updateAutoEmailComp`, body);
  }

  getAutoEmailCompany(id) {
    return this.http.get(`${this.baseApiUrl}getAutoEmailCompany/${id}`);
  }

  getCallsCountDashboard(body: {
    ProjectId: any;
    admin: boolean;
    mail: string;
    CompanyId: number;
    counselling: boolean;
  }) {
    // 
    // 
    return this.http
      .post(`${this.baseApiUrl}getCallsCountDashboard`, body)
      .pipe(
        map((data: any[]) => {
          // 
          // 
          return data;
        })
      );
  }

  getLeadDashboardInfo(body: {
    ProjectId: any;
    admin: boolean;
    mail: string;
    counselling: boolean;
  }) {
    // 
    return this.http.post(`${this.baseApiUrl}getLeadDashboardInfo`, body).pipe(
      map((data: any[]) => {
        // 
        //
        let body = {};
        // 
        for (let i = 0; i <= data.length - 1; i++) {
          // 
          if (data[i]["callstatus"] === "pending") {
            body["pending"] = data[i]["leads"] || 0;
          }
          if (data[i]["callstatus"] === "facebook") {
            body["Facebook"] = data[i]["leads"] || 0;
          }
          if (data[i]["callstatus"] === "Website") {
            body["Website"] = data[i]["leads"] || 0;
          }
          if (data[i]["callstatus"] === "followup") {
            body["followup"] = data[i]["leads"] || 0;
          }
        }
        // 
        return body;
      })
    );
  }

  getDashboardLeads(body: {
    ProjectId: any;
    admin: boolean;
    mail: string;
    leadType: string;
    counselling: boolean;
  }) {
    return this.http.post<any[]>(`${this.baseApiUrl}getDashboardLeads`, body);
  }

  getAutoEmail(companyId, apikey) {
    const headers = new HttpHeaders({ "x-auth-token": apikey });
    return this.http.get<any[]>(
      `${this.baseApiUrl}AutoEmailByComp/${companyId}`
    );
  }

  getAutoMailCompany(companyId) {
    return this.http.get(`${this.baseApiUrl}getAutoEmailCompany/${companyId}`);
  }

  getAutoEmailType(body: { type: string; ProjectId: any }) {
    return this.http.post<any[]>(`${this.baseApiUrl}getAutoEmailType`, body);
  }

  convert2dArraytoMailData(formatingData, companyId) {
    let excelData: createEmailData[] = [];
    for (let inner = 1; inner <= formatingData.length - 1; inner++) {
      let convertData: any = {};
      // 
      if (formatingData[inner][0]) {
        convertData["CompanyId"] = companyId;
        convertData["RecipientEmail"] = `+${formatingData[inner][0] || ""}${formatingData[inner][1]
          }`;
        convertData["ScheduledEmailTime"] = formatingData[inner][2];
        convertData["Type"] = formatingData[inner][3];
        convertData["Module"] = formatingData[inner][4];

        excelData.push(convertData);
      }
    }
    // 
    return excelData;
  }

  async insertEmailData(body: createEmailData[], apikey) {
    let headers = new HttpHeaders({ "x-auth-token": apikey });
    let number = 0;
    for (let el = 0; el <= body.length - 1; el++) {
      try {
        await this.https
          .post(`${this.baseApiUrl}createAutoEmail`, body[el], {
            headers: headers,
          })
          .toPromise();
        number = number + 1;
        // 
      } catch (err) {
        // 
        if (number) {
          number = number - 1;
        }
      }
    }
    // 
    return number;
  }

  createDemo(body: CreateDemo) {
    return this.http.post(`${this.baseApiUrl}createDemo`, body);
  }

  updateDemo(body: { leadId: any; demoId: any }) {
    return this.http.post(`${this.baseApiUrl}leadDemo`, body);
  }

  getLead(id) {
    return this.http.get(`${this.baseApiUrl}getLead/${id}`);
  }

  sendMailForDemo(
    data: { name: any; demoDate: any; url: string },
    toMail: string,
    projectId: any,
    templateName: any
  ) {
    this.getDemoTemplate(projectId, templateName).subscribe((dTemplate) => {
      let template = dTemplate["Html"];
      if (!template) {
        template = `Hi {{name}},
    
    Thank you for showing interest in our product. As discussed, our demo is scheduled on {{demoDate}}. 
    Please join below link for the same:
    {{url}}
    
    Thanks`;
      }
      const result = template.replace(/{{([^{{]+)}}/g, (match, key) => {
        // If there's a replacement for the key, return that replacement with a `<br />`. Otherwise, return a empty string.
        return data[key] !== undefined ? data[key] : "";
      });
      this.sharedService.currentProject.subscribe((data) => {
        if (data) {
          this.mailService
            .sendmail(
              {
                To: toMail,
                From: {
                  email: "support@accessque.com",
                  name: "Accessque",
                },
                Subject: "Accessque: Study abroad made easy",
                Body: {
                  html: result + "<div><br></div>",
                },
                ReplyTo: "support@accessque.com",
              },
              projectId
            )
            .subscribe();
        }
      });
    });
  }

  getLeadByRoomId(roomName: any) {
    return this.http.get(`${this.baseApiUrl}getLeadByRoomName/${roomName}`);
  }

  updateLeadStatus(body: { id: any; phase: any }) {
    return this.http.post<any>(`${this.baseApiUrl}updateLeadPhase`, body);
  }

  sendMOU(projectId, body: { LeadId: any }) {
    return this.http.post(
      `${this.twilioApiUrl}${projectId}/eSign/request`,
      body
    );
  }

  showDetails(id, type) {
    return this.http.get(`${this.baseApiUrl}showDetails/${id}/${type}`);
  }

  getDemoTemplate(id, name) {
    return this.http.get(`${this.baseApiUrl}getDemoTemplate/${id}/${name}`);
  }

  checkCounsellingCandidate({ agentId, projectId }) {
    return this.http.get(
      `${this.baseApiUrl}counselling/${projectId}/${agentId}`
    );
  }

  getEventsByStartEnd() {
    var apiURL = `${this.baseApiUrl}getEventByStartEnd/43/2022-01-30T08:00:00/2022-02-01T12:00:00`;
    return this.http.get(
      `${apiURL}`
    );
  }
}
