<div class="container-fluid p-0">
  <div class="row">
    <div class="home-banner-bg2">
      <div class="banenr-text2">
        AI Call Analytics - We have analyzed {{totalAnalyzedCalls}} calls.
      </div>
    </div>
  </div>
</div>
<div class="container-md m-auto">
  <!--Section AccessQue-->
  <div class="row mt-5">
    <div class="col-md-6 col-sm-6 col-lg-6">
      <p class="lightFont w-80 lh130 px-3">
        CRM has AI data model to monitor Agent Call recordings. OUR AI analyser tells us about Good call and bad call by
        rating the agents based on
      </p>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6 col-sm-6 col-lg-6">
      <ul class="data-list pl-3">
        <li>Report Should be analysed based on the below parameters</li>
        <li>Proper tone of voice</li>
        <li>Skilled listening</li>
        <li>Normal speaking speed</li>
        <li>The right language</li>
        <li>Effective questioning, including the use of open, probing, and closed questions</li>
        <li>Good rapport</li>
        <li>Demonstrated emotional intelligence</li>
        <li>Solid call/chat closeout</li>
        <li>Well-managed customer expectations</li>
        <li>Appropriate use of script</li>
      </ul>

    </div>
    <div class="col-md-6 col-sm-6 col-lg-6">
      <img src="../../../assets/images/ai-calls-graphic-1.png" style="width:100%" />
    </div>
  </div>
  <div class="row">
    <div class="col-md-4">
      <div class="card mt-4">
        <form (ngSubmit)="onSubmit()" [formGroup]="callsForm">
          <div class="card-body p-4 demo-block">
            <div class="header-lg">Check with your Audio file</div>
            <div class="mb-3">
              <label for="exampleFormControlInput1" class="form-label">Name</label>
              <input type="email" class="form-control" id="exampleFormControlInput1" formControlName="name"
                class="form-control" [ngClass]="{ 'is-invalid': f.name.touched && f.name.errors }" placeholder="">
            </div>
            <div class="mb-3">
              <label for="exampleFormControlInput1" class="form-label">Email</label>
              <input type="email" class="form-control" id="exampleFormControlInput1" formControlName="email"
                class="form-control" [ngClass]="{ 'is-invalid': f.email.touched && f.email.errors }" placeholder="">
            </div>
            <div class="mb-3">
              <label for="exampleFormControlInput1" class="form-label">Mobile</label>
              <input type="email" class="form-control" formControlName="phone" class="form-control"
                [ngClass]="{ 'is-invalid': f.phone.touched && f.phone.errors }" id="exampleFormControlInput1"
                placeholder="">
            </div>
            <div class="mb-3">
              <label for="exampleFormControlInput1" class="form-label">Company Name</label>
              <input type="email" class="form-control" formControlName="companyname" class="form-control" [ngClass]="{ 'is-invalid':f.companyname.touched 
                && f.companyname.errors }" id="exampleFormControlInput1" placeholder="">
            </div>
            <div class="mb-3 d-flex text-start">
              <div>
                <input hidden type="file" accept="audio/mp3,audio/*;capture=microphone" #uploadFile2
                  (change)="getFileDetails($event)" />
                <img src="../../../../assets/images/audio-3.png">
                <button class="btn btn-link ml-2 " type="button" (click)="uploadFile2.click()">
                  Upload audio file here..</button>
              </div>
            </div>
            <div class="mb-3 d-flex text-start">
              <div><button class="btn btn-primary ml-2" type="submit">Check</button></div>
            </div>

          </div>
        </form>
      </div>
    </div>
    <div class="col-md-8">
      <div class="card mt-4 ml-2 voice-bg">
        <div class="card-body p-4 demo-block">
          <div *ngIf="isLoaded">
            <table class="table table-borderless table-md caller-table my-1">
              <tr>
                <td class="sound-wave-txt">Emotional Intelligence</td>
                <td class="sound-wave-txt">{{analytics.demonstrated_emotional_intelligence}}%</td>
                <td>
                  <rating style="color: #f57722;font-size: 30px;margin: 0; padding: 0;"
                    [ngModel]="getStar(analytics.demonstrated_emotional_intelligence)" [max]="max"
                    [readonly]="isReadonly">
                  </rating>
                </td>
              </tr>
              <tr>
                <td class="sound-wave-txt">Good Rapport</td>
                <td class="sound-wave-txt">{{analytics.good_rapport}}%</td>
                <td>
                  <rating style="color: #f57722;font-size: 30px;margin: 0; padding: 0;"
                    [ngModel]="getStar(analytics.good_rapport)" [max]="max" [readonly]="isReadonly">
                  </rating>
                </td>
              </tr>
              <tr>
                <td class="sound-wave-txt">Speaking Speed</td>
                <td class="sound-wave-txt">{{analytics.normal_speaking_speed}}%</td>
                <td>
                  <rating style="color: #f57722;font-size: 30px;margin: 0; padding: 0;"
                    [ngModel]="getStar(analytics.normal_speaking_speed)" [max]="max" [readonly]="isReadonly">
                  </rating>
                </td>
              </tr>
              <tr>
                <td class="sound-wave-txt">Tone of Voice</td>
                <td class="sound-wave-txt">{{analytics.proper_tone_of_voice}}%</td>
                <td>
                  <rating style="color: #f57722;font-size: 30px;margin: 0; padding: 0;"
                    [ngModel]="getStar(analytics.proper_tone_of_voice)" [max]="max" [readonly]="isReadonly">
                  </rating>
                </td>
              </tr>
              <tr>
                <td class="sound-wave-txt">Skilled Listening</td>
                <td class="sound-wave-txt">{{analytics.skilled_listening}}%</td>
                <td>
                  <rating style="color: #f57722;font-size: 30px;margin: 0; padding: 0;"
                    [ngModel]="getStar(analytics.skilled_listening)" [max]="max" [readonly]="isReadonly">
                  </rating>
                </td>
              </tr>
              <tr>
                <td class="sound-wave-txt">Solid Call</td>
                <td class="sound-wave-txt">{{analytics['solid_call/chat_closeout']}}%</td>
                <td>
                  <rating style="color: #f57722;font-size: 30px;margin: 0; padding: 0;"
                    [ngModel]="getStar(analytics['solid_call/chat_closeout'])" [max]="max" [readonly]="isReadonly">
                  </rating>
                </td>
              </tr>
              <tr>
                <td class="sound-wave-txt">Language</td>
                <td class="sound-wave-txt">{{analytics.the_right_language}}%</td>
                <td>
                  <rating style="color: #f57722;font-size: 30px;margin: 0; padding: 0;"
                    [ngModel]="getStar(analytics.the_right_language)" [max]="max" [readonly]="isReadonly">
                  </rating>
                </td>
              </tr>
              <tr>
                <td class="sound-wave-txt">Customer Expectations</td>
                <td class="sound-wave-txt">{{analytics['well-customer_expectations']}}%</td>
                <td>
                  <rating style="color: #f57722;font-size: 30px;margin: 0; padding: 0;"
                    [ngModel]="getStar(analytics['well-customer_expectations'])" [max]="max" [readonly]="isReadonly">
                  </rating>
                </td>
              </tr>
            </table>
          </div>
          <div *ngIf="!isLoaded">
            <div class="sound-wave-block">
              <img src="../../../../assets/images/sound-wave-w.svg">
            </div>
            <div class="sound-wave-txt">Emotional Intelligence - Good Rapport - Speaking Speed</div>
            <div class="sound-wave-txt">Tone of Voice - Skilled Listening - Solid Call </div>
            <div class="sound-wave-txt">Language - Customer Expectations</div>
            <div *ngIf="isLoading ==true">
              <div class="norecords">
                <img src="../../../../assets/img/waiting.gif" />
                <div>Loading Data<br />Please wait while we fetch the data for you</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row mt-5 mb-5">
    <div class="col-md-12 text-center">
      <button class="btn btn-primary" routerLink="/bookademo/aianalytics">Book a Demo</button>
    </div>
  </div>
  <!-- Modal -->
  <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-md">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Book a Demo</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><img
              src="../../../../assets/img/close-w.svg" class="img12"></button>
        </div>
        <div class="modal-body">
          <div class="p-3">
            Please complete this form. An expert will contact you soon.
          </div>
          <form>
            <div class="mb-3">
              <label for="exampleFormControlInput1" class="form-label">Name</label>
              <input type="email" class="form-control" id="exampleFormControlInput1" placeholder="">
            </div>
            <div class="mb-3">
              <label for="exampleFormControlInput1" class="form-label">Email</label>
              <input type="email" class="form-control" id="exampleFormControlInput1" placeholder="">
            </div>
            <div class="row mb-3 ml-1">
              <div class="col-auto mr-3">
                <label for="exampleFormControlInput1" class="form-label">Mobile</label>
                <input type="email" class="form-control" id="exampleFormControlInput1" placeholder="">
              </div>
              <div class="col-auto">
                <button type="submit" class="btn btn-primary mt-4">Send OTP</button>
              </div>
            </div>
            <div class="mb-3">
              <label for="exampleFormControlInput1" class="form-label">OTP</label>
              <input type="email" class="form-control" id="exampleFormControlInput1" placeholder="">
            </div>
            <div class="mb-3">
              <button class="btn btn-primary btn-sm">Submit</button>
            </div>
          </form>
        </div>

      </div>
    </div>
  </div>
  <!-- Modal -->
  <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-md">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Book a Demo</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><img
              src="../../../../assets/img/close-w.svg" class="img12"></button>
        </div>
        <div class="modal-body">
          <div class="py-3">
            Please complete this form. An expert will contact you soon.
          </div>
          <form>
            <div class="mb-3">
              <label for="exampleFormControlInput1" class="form-label">First Name</label>
              <input type="" class="form-control" id="exampleFormControlInput1" placeholder="">
            </div>
            <div class="mb-3">
              <label for="exampleFormControlInput1" class="form-label">Last Name</label>
              <input type="" class="form-control" id="exampleFormControlInput1" placeholder="">
            </div>
            <div class="mb-3">
              <label for="exampleFormControlInput1" class="form-label">Company Name</label>
              <input type="" class="form-control" id="exampleFormControlInput1" placeholder="">
            </div>
            <div class="mb-3">
              <label for="exampleFormControlInput1" class="form-label">Description</label>
              <textarea type="" class="form-control" id="exampleFormControlInput1">
            </textarea>
            </div>
            <div class="mb-3">
              <label for="exampleFormControlInput1" class="form-label">Email</label>
              <input type="" class="form-control" id="exampleFormControlInput1" placeholder="">
            </div>
            <div class="row mb-3 ml-0">
              <div class="col-auto mr-3">
                <label for="exampleFormControlInput1" class="form-label">Mobile</label>
                <input type="email" class="form-control" style="width:350px;" id="exampleFormControlInput1"
                  placeholder="">
              </div>
              <div class="col-auto">
                <button type="submit" class="btn btn-outline-secondary mt-20">Send OTP</button>
              </div>
            </div>
            <div class="mb-3">
              <input type="email" class="form-control" id="exampleFormControlInput1" placeholder="Enter OTP">
            </div>
            <div class="mb-3 d-flex align-items-center">
              <div>
                <img src="../../../../assets/signup/cap.jpg" style="width:230px">
              </div>
              <div class="ml-auto">
                <button class="btn btn-primary btn-sm">Submit</button>
              </div>
            </div>
          </form>
        </div>

      </div>
    </div>
  </div>
  <!-- AUDIO UPLOAD FORM -->

  <!-- <div>
    <form>
      <div class="form-group mt-3 mb-3">
        <label for="email" class="label-txt">Email</label>
        <input
          type="email"
          formControlName="email"
          class="form-control"
          [ngClass]="{ 'is-invalid': f.email.touched && f.email.errors }"
          id="email"
          placeholder="Email"
        />

        <div *ngIf="f.email.touched && f.email.errors" class="invalid-feedback">
          <div *ngIf="f.email.errors.required">Email is required</div>
          <div *ngIf="f.email.errors.pattern">
            Email must be a valid email address
          </div>
        </div>
      </div>
    </form>
  </div> -->
</div>