import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { CardsSetting, ColumnMap } from '../layout.model';
import { CardsService } from './cards.service';

@Component({
  selector: 'app-custom-cards',
  templateUrl: './custom-cards.component.html',
  styleUrls: ['./custom-cards.component.css']
})
export class CustomCardsComponent implements OnInit, OnChanges {
  @Input() records: any;
  @Input() records1: any;
  @Input() cardsSettings: CardsSetting[];
  @Input() callStatusSelected: boolean;
  columnMaps: any;
  recordMaps: any;
  userData: any;
  @Output() pageChange = new EventEmitter();
  tab = 'tab1';
  loading = false;
  appearance: string = 'line'; // Choose between 'circle' or 'line'
  animation: string = 'pulse'; // Choose between 'pulse', 'wave', or 'none'
  
  constructor(private cardsService: CardsService) {

  }

  ngOnInit(): void {
    this.userData = JSON.parse(localStorage.getItem("userData"));    
  }
  ngOnChanges(changes: SimpleChanges) {
    console.log(`Start Time :::::: ${new Date().getTime()}`);
    
    this.loading = true;
    if(changes.records1 && !changes.records1.firstChange){
      console.log(`End Time :::::: ${new Date().getTime()}`);
      this.loading = false;
    }
    if(changes.callStatusSelected && !changes.callStatusSelected.firstChange && changes.callStatusSelected.currentValue){
      this.onClick(null);
      this.tab = '';
    }
    this.columnMaps = this.cardsSettings;
  }
  showCards(key) {
    this.pageChange.emit(key);
  }
  getCount(key) {
    var data2 = this.records1.TopBarCount.query;
    var data1 = data2.filter(x => {
      if (x.value === key) {
        return x
      }
    });
    if (data1.length > 0) {
      return data1[0].count
    } else {
      return 0;
    }
  }
  onClick(no) {
    this.tab = 'tab' + no;
  }
}
