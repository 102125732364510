import { Injectable } from "@angular/core";
import { HttpClientService } from "src/app/httpclient.service";
import { environment } from "src/environments/environment";
import { ToastrService } from "ngx-toastr";
import { ReferralData } from "../marketing/smsmarketing/models/ReferralData.model";
import { BehaviorSubject } from "rxjs";
import { FormBuilder, RequiredValidator, Validators } from "@angular/forms";

@Injectable({
  providedIn: "root",
})
export class EnquiryDashboardService {
  baseApiUrl = environment.baseApiUrl2;
  baseApiUrl2 = environment.SMSapiurl;


  startCall = new BehaviorSubject<any>(null);
  stopCall = new BehaviorSubject<any>(null);
  getCallSid = new BehaviorSubject<any>(null);
  viewReceivedLead = new BehaviorSubject<any>(null);
  pageNumber = new BehaviorSubject<any>(null);
  totalPages = new BehaviorSubject<any>(null);
  callStatus = new BehaviorSubject<any>(null);
  conferenceCall = new BehaviorSubject<any>(null);
  startTimer = new BehaviorSubject<any>(null);
  editLead = this.formBuilder.group({
    FirstName: ["", [Validators.required]],
    LastName: ["", [Validators.required]],
    CountryCode: ["", [Validators.required]],
    PhoneNumber: ["", [Validators.required]],
    Email: ["", Validators.required],
    Address: [""],
    City: [""],
    State: [""],
    Zip: [""],
    Country: [""],
    LeadComment: [""],
    CallStatus: [""],
  });

  constructor(
    private http: HttpClientService,
    private toastr: ToastrService,
    private readonly formBuilder: FormBuilder
  ) { }


  getLeads(data) {
    return this.http.post(`${this.baseApiUrl}communication/getDashboardInquiries`, data);
  }

  getCounts(data) {
    return this.http.post(`${this.baseApiUrl}communication/getTopBarInquiries`, data);
  }

  getLeadById(id, projectId) {
    return this.http.get(`${this.baseApiUrl}communication/newInquiryById/` + id + '/' + projectId);
  }

  updateCallStatus(data) {
    return this.http.post(`${this.baseApiUrl}communication/updateCallStatus`, data)
  }

  updateCallSid(data) {
    return this.http.post(`${this.baseApiUrl}communication/updateCallSid`, data)
  }

  updateComments(data) {
    return this.http.post(`${this.baseApiUrl}communication/outgoingCallComments`, data);
  }

  getSms(id) {
    return this.http.get(`${this.baseApiUrl}communication/campbellsvilleSMS/` + id);
  }

  sendSms(data, projectId) {
    return this.http.post(`${this.baseApiUrl2}` + projectId + `/messaging-adapter/sendSMS`, data);
  }

  updateLead(data) {
    return this.http.post(`${this.baseApiUrl}communication/updateNewInquiry`, data);
  }
  getCountByCallStatus(data) {
    return this.http.post(`${this.baseApiUrl}communication/getCountByCallStatus`, data);
  }
  GetSearchEnquiryLeads(data) {
    return this.http.post(
      `${this.baseApiUrl}campbellsville/getSearchEnquiryLeads`,
      data
    );
  }
}
