import { AuthService } from "src/app/account/auth/auth.service";
import { NgForm } from "@angular/forms";
import { RoleService } from "./../role-management/role.service";
import { Component, OnInit } from "@angular/core";
import { NgbModal, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";
import { SharedService } from "src/app/shared/shared.service";
import { ManagementService } from "../management.service";
import { IDropdownSettings } from "ng-multiselect-dropdown";
import { CreateAgent } from "../create-agent/create-agent.service";
import { environment } from "src/environments/environment";
import { ErrorHandlerService } from "src/app/error-handler.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-company-management",
  templateUrl: "./company-management.component.html",
  styleUrls: ["./company-management.component.css"],
})
export class CompanyManagementComponent implements OnInit {
  closeResult: string;
  search: string;
  companyInfo: any = {
    name: "",
    twilioNumber: "",
    supportMail: "",
    forwardMail: environment.forwardMail,
  };
  totalCompanies: any[] = [];
  isUpdate: boolean = false;
  inputData: any;
  isError: boolean = false;
  errorMessage: string;
  superAdmin: Boolean = false;
  userData: any;
  public page: any = 1;
  public pageSize: any = 8;
  selectedCountryCode;
  countries: any[];
  group: any[] = [];
  groups: any[] = [];
  companySettings: IDropdownSettings;
  public order: boolean = false;
  public orderFeild: string = "UpdatedDate";
  public orderString: boolean | "asc" | "desc" = "desc";

  constructor(
    private modalService: NgbModal,
    private companyService: RoleService,
    private sharedService: SharedService,
    private authService: AuthService,
    private managementService: ManagementService,
    private handleError: ErrorHandlerService,
    private agentService: CreateAgent,
    private router: Router
  ) { }

  ngOnInit() {
    this.companySettings = {
      singleSelection: true,
      allowSearchFilter: true,
      closeDropDownOnSelection: true,
      textField: "Name",
    };

    this.fetchCountries();
    this.userData = JSON.parse(localStorage.getItem("userData"));
    this.sharedService.companyCreated.next("company created");
    this.sharedService.pageEvent.emit({ pageName: "Company Management" });
    this.authService.admin.subscribe((data) => {
      this.superAdmin = data;
    });
    this.getComapnies(this.userData.companyId);
  }

  open(content) {
    // this.isError = false;
    this.router.navigate(["/createCompany"])
    // this.modalService.open(content, { size: "md" }).result.then(
    //   (result) => {
    //     this.closeResult = `Closed with: ${result}`;
    //   },
    //   (reason) => {
    //     this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    //   }
    // );
  }

  private getDismissReason(reason: any): string {
    this.getComapnies(this.userData.companyId);
    this.isUpdate = false;
    this.companyInfo["id"] = null;
    this.resetForm();
    this.isError = false;
    this.companyInfo.forwardMail = environment.forwardMail;
    // this.companyInfo = { name: '', twilioNumber: '', supportMail: '' };
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }

  submit(form: NgForm) {
    if (!this.isUpdate) {
      this.companyService
        .createCompany({
          name: form.value.name,
          supportMail: form.value.supportMail,
          twilioNumber: `xxxxx`,
          forwardMail: form.value.forwardMail,
        })
        .subscribe(
          (data) => {
            this.modalService.dismissAll();
            this.sharedService.companyCreated.next("company created");
          },
          (err) => {
            this.isError = true;
            this.errorMessage = err.error.status;
            this.handleError.handleError(err)

          }
        );
    } else {
      this.companyService
        .updateCompany({
          name: form.value.name,
          ActiveStatus: form.value.ActiveStatus,
          supportMail: form.value.supportMail,
          id: this.companyInfo["id"],
          forwardMail: this.companyInfo["forwardMail"],
        })
        .subscribe(
          (data) => {
            this.modalService.dismissAll();
          },
          (err) => {
            this.isError = true;
            this.errorMessage = err.error.status;
            this.handleError.handleError(err)

          }
        );
    }
  }

  resetForm() {
    this.companyInfo = {
      name: "",
      twilioNumber: "",
      supportMail: "",
      activeStatus: null,
      id: null,
    };
    this.isError = false;
    this.errorMessage = "";
    this.groups = [];
    this.group = [];
  }

  onCompanySelect(id: any, content) {
    if (this.userData.companyId === 0) {
      this.isUpdate = true;

      this.companyService.getCompanyById(id).subscribe((data) => {
        this.agentService.getMailGroups(data["id"]).subscribe((datas) => {
          this.groups = datas.map((datac) => ({
            Name: datac["Name"],
            id: datac["id"],
          }));
          let selectedGroup = this.groups.find(
            (datap) => datap["id"] === data["IncomingMailGroup"]
          );
          if (selectedGroup) {
            this.group = [selectedGroup["Name"]];
          }
        }, (error) => {
          this.handleError.handleError(error)
        });
        this.companyInfo = {
          name: data["Name"],
          supportMail: data["SupportMail"],
          twilioNumber: data["TwilioNumber"],
          ActiveStatus: data["ActiveStatus"],
          id: id,
          forwardMail: data["ForwardMail"] || "",
        };
        this.open(content);
      }, (error) => {
        this.handleError.handleError(error)
      });
    }
  }

  getComapnies(id) {
    this.companyService.getAllCompanies(id).subscribe((data) => {
      this.totalCompanies = data;
    }, (error) => {
      this.handleError.handleError(error)
    });
  }

  selectCountry(country) {
    this.selectedCountryCode = country.code;
  }

  fetchCountries() {
    this.sharedService.getCountries().subscribe((data) => {
      this.countries = data;
    }, (error) => {
      this.handleError.handleError(error)
    });
  }

  sortOrder(feildName) {
    this.orderFeild = feildName;
    this.order = !this.order;
    if (this.order) {
      this.orderString = "asc";
    } else {
      this.orderString = "desc";
    }
  }

  forwardMail(event) {
    let name = event.replace(/[^A-Z0-9]+/gi, "");
    this.companyInfo["forwardMail"] = name + environment.forwardMail;
  }
}
