<div class="row">
  <div class="home-banner-bg2">
    <div class="banenr-text2">Accessque lead generation</div>
  </div>
</div>
<div class="container-md m-auto">
  <!--Section AccessQue-->
  <div class="row mt-5">
    <div class="col-md-6 col-sm-6 col-lg-6">
      <p class="lightFont w-80 lh130 px-3">
        Find relevant companies with one click: Trigger events tell you where there is a current need, and you can
        easily generate similar companies as leads from your best customers.
      </p>

      <p class="lightFont w-80 lh130 px-3">
        You will receive contact persons including contact details directly.
      </p>
    </div>
    <div class="col-md-6 col-sm-6 col-lg-6">
      <img src="../../../../assets/images/crm-graphic.png" class="border-tb-radius2" style="width: 100%" />
    </div>
  </div>
  <div class="row mt-5 mb-5">
    <div class="col-md-12 text-center">
      <button class="btn btn-primary" routerLink="/bookademo/leadgeneration">
        Book a Demo
      </button>
    </div>
  </div>
</div>