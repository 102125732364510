<!-- <p>company-management works!</p> -->
<div class="containers">
  <div class="d-flex mb-2">
    <div class="mr-auto">
      <a routerLink="/company" class="btn btn-link btn-sm pl-0">
        <img src="../../../assets/images/left-arrow.svg" class="img9"> &nbsp;
        Back to Dashboard</a>
    </div>
    <div class="ml-auto">
      <button class="btn btn-secondary btn-sm" (click)="open(content)">
      <!-- *ngIf="superAdmin" -->
      <img ngbTooltip="Create Project" placement="top" tooltipClass="my-custom-class" alt="" class="img11"
        src="./assets/img/add-w.svg" />
    </button>
  </div>
  </div>
  <div class="content-container">
    <div class="table-scroll data-table" style="background:#fff">
      <table class="table table-md">
        <thead>
          <tr>
            <td colspan="7" style="padding: 8px">
              <div class="table-search-content">
                <div class="indived-search" style="position: relative">
                  <input type="text" class="form-control search-all" [(ngModel)]="search" placeholder="Search..." />

                </div>
                <div class="pagenation-right">
                  <ngb-pagination [(page)]="page" [pageSize]="pageSize" [collectionSize]="totalCompanies.length"
                    class="pagenation-right" [maxSize]="6" [boundaryLinks]="true"></ngb-pagination>
                </div>
              </div>
            </td>
          </tr>

          <tr style="background: #eaf5fd; border-bottom: none">
            <!-- <th style="display: none">Company id</th> -->
            <th>
              <span>
                Project Name

                <img class="img7" src="../../../../assets/img/sort-data.svg" style="cursor: pointer"
                  (click)="sortOrder('Name')" />
              </span>
            </th>
            <th>
              <span>
                Project Description

                <img class="img7" src="../../../../assets/img/sort-data.svg" style="cursor: pointer"
                  (click)="sortOrder('Description')" />
              </span>
            </th>

            <th>
              <span>
                Twilio Number
                <img class="img7" src="../../../../assets/img/sort-data.svg" style="cursor: pointer"
                  (click)="sortOrder('TwilioNumber')" />
              </span>
            </th>
            <th>
              <span>
                Support Mail
                <img class="img7" src="../../../../assets/img/sort-data.svg" style="cursor: pointer"
                  (click)="sortOrder('SupportMail')" />
              </span>
            </th>
            <th>
              <span>
                Status
                <img class="img7" src="../../../../assets/img/sort-data.svg" style="cursor: pointer"
                  (click)="sortOrder('ActiveStatus')" />
              </span>
            </th>
            <th>
              <span>
                Created Date
                <img class="img7" src="../../../../assets/img/sort-data.svg" style="cursor: pointer"
                  (click)="sortOrder('createdAt')" />
              </span>
            </th>
            <th>Create Role</th>
          </tr>
        </thead>
        <tbody style="background:#fff">
          <tr *ngFor="
              let item of totalCompanies
                | slice: (page - 1) * pageSize:page * pageSize
                | sort: orderFeild:orderString
                | filter: search;
              let id = index
            ">
            <td class="id-col-mod" style="
                vertical-align: middle;
                cursor: pointer;
                text-decoration: none;
                color: #163252;
                text-transform: capitalize;
              " (click)="onCompanySelect(item['id'], content)">
              {{ item["Name"] | titlecase }}
            </td>
            <td style="vertical-align: middle">{{ item["Description"] }}</td>
            <td style="vertical-align: middle">{{ item["TwilioNumber"] }}</td>
            <!-- <td>
							{{ item['ActiveStatus'] ? 'active' : 'inactive' }}
						</td> -->
            <td style="vertical-align: middle">{{ item["SupportMail"] }}</td>
            <td style="vertical-align: middle">
              <div [ngClass]="{ 'status-open': item['ActiveStatus'] }">
                {{ item["ActiveStatus"] ? "Active" : "In Active" }}
              </div>
            </td>
            <td style="vertical-align: middle">
              {{ item["createdAt"] | date: "medium" }}
            </td>
            <td style="vertical-align: middle">
              <a [routerLink]="['/role', companyId, companyName, item['id']]" class="btn btn-primary btn-sm">Manage
                Roles</a>
            </td>
            <!-- <td>{{ item['updatedAt'] | date: 'short' }}</td> -->
          </tr>
        </tbody>
      </table>
    </div>
    <!-- <ngb-pagination class="pagination-right"></ngb-pagination> -->
    <!-- [(page)]="page"
        [pageSize]="pageSize"
        [collectionSize]="roles.length"
        class="pagenation-right" -->
    <div class="fixed-footer">Powered by <a href="https://avantao.com/#/" target="_blank">Avantao</a></div>

  </div>
</div>
<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      {{ isUpdate ? "Update" : "Create" }} Project
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" style="height: 465px; overflow: auto">
    <ngb-alert type="danger" *ngIf="isError" [dismissible]="false">{{
      errorMessage
      }}</ngb-alert>
    <mat-tab-group>
      <mat-tab label="Details">
    <form (ngSubmit)="submit(f)" #f="ngForm">
      <div class="row">
            <div class="col-md-6">
        <label for="name" class="form-label mt-2">Project Name</label>
        <input type="text" class="form-control" placeholder="Please enter name of Role" name="name"
                [(ngModel)]="companyInfo.name" required #userName="ngModel" (ngModelChange)="forwardMail($event)"
                max="10" [ngClass]="{
            'is-invalid': !userName.valid && userName.touched,
            'is-valid': userName.valid && userName.touched
          }" />
        <!-- <div
							class="valid-feedback"
							*ngIf="userName.valid && userName.touched"
						>
							looks good!
						</div> -->
        <div class="invalid-feedback" *ngIf="!userName.valid && userName.touched">
          Please Enter Valid Name
        </div>
      </div>
            <div class="col-md-6">
              <label for="name" class="form-label mt-2">Project Type</label>
              <select class="form-control">
                <option>Education - Sample type</option>
                <option>Real Estate</option>
                <option> IT </option>
              </select>
            </div>
          </div>

      <div class="row">
            <div class="col-md-6">
              <label for="name" class="form-label mt-2">Project Start Date</label>
              <input type="date" class="form-control" name="name" />
            </div>
            <div class="col-md-6">
              <label for="name" class="form-label mt-2">Project End
                date
              </label>
              <input type="date" class="form-control" />
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
        <label for="name" class="form-label mt-2">Project Description</label>
              <textarea class="form-control" style="height:auto" row="3" placeholder="About the Project">About the project
              </textarea>

              <!-- <input type="text" class="form-control" placeholder="Please enter Project Description" name="desc"
          [(ngModel)]="companyInfo.desc" required #desc="ngModel" max="100" [ngClass]="{
            'is-invalid': !desc.valid && desc.touched,
            'is-valid': desc.valid && desc.touched
              }" rows="3" >
              </input> -->
              <!-- Above input commented by jk -->


        <!-- (ngModelChange)="forwardMail($event)" -->
        <!-- <div
							class="valid-feedback"
							*ngIf="desc.valid && desc.touched"
						>
							looks good!
						</div> -->
        <div class="invalid-feedback" *ngIf="!desc.valid && desc.touched">
          Please Enter Valid Description
        </div>
      </div>
          </div>
      <div class="row">
            <div class="col-md-12">
        <label for="description" class="form-label">Support Mail</label>
        <input type="mail" class="form-control" placeholder="Please enter Description of Role" id="description"
          name="supportMail" [(ngModel)]="companyInfo.supportMail" required #desc="ngModel" email [ngClass]="{
            'is-invalid': !desc.valid && desc.touched,
            'is-valid': desc.valid && desc.touched
          }" />
        <!-- <div class="valid-feedback" *ngIf="desc.valid && desc.touched">
							look good!
						</div> -->
        <div class="invalid-feedback" *ngIf="!desc.valid && desc.touched">
          Please enter valid Description
        </div>
      </div>
          </div>
      <div class="row">
            <div class="col-md-12">
        <label for="twilioNumber" class="form-label">Phone Number(Twilio Number)</label>
        <div class="input-group">
                <input type="tel" class="form-control" name="twilioNumber" ngModel
                  [(ngModel)]="companyInfo.twilioNumber" required pattern="[- +()0-9]+" #twilioPhoneNumber="ngModel"
                  placeholder="Please enter Twilio Phone Number" [ngClass]="{
              'is-invalid':
                !twilioPhoneNumber.valid && twilioPhoneNumber.touched,
              'is-valid': twilioPhoneNumber.valid && twilioPhoneNumber.touched
            }" />
          <div class="invalid-feedback" *ngIf="!twilioPhoneNumber.valid && twilioPhoneNumber.touched">
            Please enter a valid Phone Number
          </div>
        </div>
      </div>
          </div>
      <div class="row d-flex align-items-center" *ngIf="isUpdate">
            <div class="col-md-12">
        <mat-checkbox ngModel name="updateIvr" style="margin-top: 10px; color: #666">Update IVR</mat-checkbox>
      </div>
          </div>

      <div class="row" *ngIf="isUpdate">
            <div class="col-md-6">
          <label for="description" class="form-label">Active Status</label>
          <select required class="form-control" name="ActiveStatus" [(ngModel)]="companyInfo['ActiveStatus']"
            #activeStatus="ngModel">
            <option value="true">Active</option>
            <option value="false">In Active</option>
          </select>
          <div class="invalid-feedback" *ngIf="!activeStatus.valid && activeStatus.touched">
            Please select Value
          </div>
        </div>
            <div class="col-md-6">
        <label for="fromEmail">Incoming Mail Group</label>
        <ng-multiselect-dropdown [data]="groups" [settings]="companySettings" [(ngModel)]="group"
          style="width: 100%; padding: 0" name="incomingMailGroup">
        </ng-multiselect-dropdown>
      </div>
          </div>
      <div class="row">
            <div class="col-md-12">
        <label for="description" class="form-label">Forward Mail</label>
        <input type="mail" class="form-control" id="description" name="forwardMail"
          [(ngModel)]="companyInfo.forwardMail" #forward="ngModel" [disabled]="true" email [ngClass]="{
            'is-invalid': !forward.valid && forward.touched,
            'is-valid': forward.valid && forward.touched
          }" />
        <div class="invalid-feedback" *ngIf="!forward.valid && forward.touched">
          Please enter valid Description
        </div>
      </div>
          </div>

      <div class="modal-footer">
        <button type="submit" class="btn btn-primary btn-sm" [disabled]="!f.valid">
          {{ isUpdate ? "Update" : "Submit" }}
            </button>
          </div>
        </form>
      </mat-tab>
      <mat-tab label="Feedbacks" *ngIf="isUpdate">
        <form (ngSubmit)="statusCheckedValues(form.value)" #form="ngForm">
          <!-- <div class="d-flex py-1 align-items-center border-bottom">
            <div class="">
            <input class="form-control field-search" type="text" placeholder="Search" />
            </div>
            <div class="ml-auto">
              Pagination
            </div>
          </div> -->
          <div class="pt-2 pb-2 feedback-list-height">
            <div *ngFor="let items of feedbacks" class="">
              <div class="d-flex p-2 border-bottom">
                <div>
                  <mat-selection-list ngModel [name]="items['name']" (selectionChange)="
                  childMenuCheckBox($event)">
                    <mat-list-option [value]="items['id']" [selected]="checkBox(items['id'])" style="font-size: 13px">
                      {{ items["name"] | titlecase }}
                    </mat-list-option>
                  </mat-selection-list>
                </div>
                <div class="d-flex ml-auto">
                  <div class="mr-2" *ngIf="checkBox(items['id'])"
                    (click)="editFeedbackByProject(items['id'],this.companyInfo.id,editProjectFeedback)"><img
                      src="../../../assets/images/edit.svg" class="img12 hand"></div>
                  <!-- <div><img src="../../../assets/images/delete-g.svg" class="img11 hand"></div> -->
                </div>
              </div>

            </div>
          </div>
          <div>
            <div class="d-flex my-2">
              <div class="ml-auto">
                <button class="btn btn-primary btn-sm mr-2" type="button" (click)="open1(addFeedback)">
                  Add New
                </button>
                <button class="btn btn-secondary btn-sm" type="submit">
                  {{ isUpdate ? "Update" : "Submit" }}
                </button>
              </div>
            </div>
          </div>
        </form>
      </mat-tab>
      <mat-tab label="Channels">

      </mat-tab>
    </mat-tab-group>

  </div>
</ng-template>

<ng-template #addFeedback let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      Add New Feedback
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" style="max-height: 600px; overflow: auto">
    <ngb-alert type="danger" *ngIf="isError" [dismissible]="false">{{
      errorMessage
      }}</ngb-alert>
    <form (ngSubmit)="submitFeedback()" [formGroup]="feedbackForm">

      <div class="form-group">
        <label class="form-label">Name</label>
        <input class="form-control" name="name" formControlName="name" [ngClass]="{
            'is-invalid': feb.name.touched && feb.name.invalid,
            'is-valid':
              feb.name.valid && feb.name.touched
          }" />
        <div *ngIf="feb.name.errors" class="invalid-feedback">
          <div>Name is required</div>
        </div>
      </div>

      <div class="form-group">
        <label class="form-label">Type</label>
        <select id="inputState" class="form-control" name="type" class="form-control" formControlName="type" [ngClass]="{
          'is-invalid': feb.type.touched && feb.type.invalid,
          'is-valid': feb.type.valid && feb.type.touched
        }">
          <option disabled value="">Choose One</option>
          <option value="call">Call</option>
          <option value="doc">Document</option>
        </select>
        <div *ngIf="feb.type.errors" class="invalid-feedback">
          <div>Type is required</div>
        </div>
      </div>

      <div class="form-group">
        <label class="form-label">Description</label>
        <input class="form-control" name="desc" formControlName="desc" [ngClass]="{
            'is-invalid': feb.desc.touched && feb.desc.invalid,
            'is-valid':
              feb.desc.valid && feb.desc.touched
          }" />
        <div *ngIf="feb.desc.errors" class="invalid-feedback">
          <div>Description is required</div>
        </div>
      </div>
      <div class="d-flex justify-content-end">
        <button type="submit" class="btn btn-primary btn-sm" [disabled]="!feedbackForm.valid">Submit</button>
      </div>
    </form>
  </div>
</ng-template>

<ng-template #editProjectFeedback let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      Add New Feedback
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" style="max-height: 600px; overflow: auto">
    <ngb-alert type="danger" *ngIf="isError" [dismissible]="false">{{
      errorMessage
      }}</ngb-alert>
    <form (ngSubmit)="updateFeedbackById(f)" #f="ngForm">
      <div class="row mt-2">
        <label for="name" class="form-label mt-2">Name</label>
        <input type="text" class="form-control" placeholder="Please enter name" name="name"
          [(ngModel)]="feedbackDetails.name" required #userName="ngModel" />
      </div>
      <div class="row mt-2">
        <mat-checkbox ngModel [(ngModel)]="feedbackDetails.active" name="updateIvr"
          style="margin-top: 10px; color: #666">Active</mat-checkbox>
      </div>
      <div class="row mt-2">
        <label for="name" class="form-label mt-2">Background Color</label>
        <div class="col-md-4">
          <input type="color" class="form-control" style="height: 3rem;" name="bgcolor"
            [(ngModel)]="feedbackDetails.bgColor" required #userBgColor="ngModel" />
        </div>
      </div>
      <div class="row mt-2">
        <label for="name" class="form-label mt-2">Text Color</label>
        <div class="col-md-4">
          <input type="color" class="form-control" style="height: 3rem;" name="textcolor"
            [(ngModel)]="feedbackDetails.textColor" required #userTextColor="ngModel" />
        </div>
      </div>

      <div class="modal-footer">
        <button type="submit" class="btn btn-primary btn-sm" [disabled]="!f.valid">
          Update
        </button>
      </div>
    </form>
  </div>
</ng-template>