import { HttpClient } from "@angular/common/http";
import {
  ElementRef,
  Injectable,
  Renderer2,
  RendererFactory2,
  // RendererFactory2,
} from "@angular/core";
import { Router } from "@angular/router";
import { Client, Conversation } from "@twilio/conversations";
import { BehaviorSubject, Observable, of } from "rxjs";
import { switchMap } from "rxjs/operators";
import { environment } from "src/environments/environment";
// import Client from "twilio-chat";
import {
  connect,
  LocalVideoTrack,
  RemoteAudioTrack,
  RemoteParticipant,
  RemoteTrack,
  RemoteTrackPublication,
  RemoteVideoTrack,
  TrackStats,
  VideoTrack,
} from "twilio-video";
import { SharedService } from "../shared/shared.service";

@Injectable({
  providedIn: "root",
})
export class VideoCallingService {
  public screenShare;

  private twilioApiUrl = environment.twilioApiUrl;
  remoteVideo: ElementRef;
  localVideo: ElementRef;
  chatContainer!: ElementRef;
  previewing: boolean;
  msgSubject = new BehaviorSubject("");
  roomObj: any;
  microphone = true;
  video = true;
  participants = new BehaviorSubject<any[]>([]);

  roomParticipants;
  users: any[] = [];
  private renderer: Renderer2;
  el: any = {};
  chat: any;
  messages: any[] = [];
  conversation: any;
  messageUsers: any[] = [];

  constructor(
    private http: HttpClient,
    private sharedService: SharedService,
    private router: Router,
    private rendererFactory: RendererFactory2 //
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  connectToRoom(accessToken: string, options): void {
    connect(accessToken, options).then(
      (room) => {
        this.roomObj = room;
        if (!this.previewing && options["video"]) {
          this.startLocalVideo();
          this.previewing = true;
        }
        this.roomParticipants = room.participants;
        room.participants.forEach((participant) => {
          this.addParticipant(participant);
          this.attachParticipantTracks(participant);
        });

        room.on("participantDisconnected", (participant) => {
          this.detachTracks(participant);
          // this.removeParticipant(participant);

          participant.tracks.forEach((track) => {
            // this.subscribe(track, participant);
          });
        });

        room.on("participantConnected", (participant) => {
          this.roomParticipants = room.participants;
          if (!this.findParticipant(participant)) {
            this.addParticipant(participant);
            this.attachParticipantTracks(participant);
          }
        });
        // When a Participant adds a Track, attach it to the DOM.
        // room.on("trackPublished", (track, participant) => {
        //   this.trackPublished(track, participant);
        // });
        // When a Participant removes a Track, detach it from the DOM.
        room.on("trackDisabled", (track, participant) => {
          let user = this.findParticipant(participant);
          if (track.kind === "audio") {
            user["audioElement"] = null;
          }
          if (track.kind === "video") {
            user["videoElement"] = null;
            this.disablingVideo(participant);
          }
          this.participants.next(this.users);
          // this.detachTracks(track);
          // // this.detachTracks([track]);
          // let participant=
        });
        room.on(
          "trackSubscribed",
          (track, participantPublication, participant) => {
            if (track.kind === "video" && track.name === "screen") {
              this.attachScreenShare(track, participant);
            }
          }
        );
        room.on("trackUnsubscribed", (track, participant) => {
          if (track.kind === "video" && track.name === "screen") {
            this.detachScreenShare(track);
          }
        });
        room.on("trackEnabled", (track, participant) => {
          let user = this.findParticipant(participant);
          if (track.kind === "video") {
            this.enablingVideo(participant, track);
          }
          if (track.kind === "audio") {
            user["audioElement"] = track;
          }
          if (track.kind === "video") {
            user["videoElement"] = track;
          }
          this.participants.next(this.users);
        });

        room.once("disconnected", (room) => {
          room.localParticipant.tracks.forEach((track) => {
          });
        });
      },
      (error) => {
        alert(error.message);
      }
    );
  }

  getToken(id: any, body: any): Observable<any> {
    return this.http.post(`${this.twilioApiUrl}${id}/video/token`, body);
  }

  getTokenV2(id: any, body: any): Observable<any> {
    console.log(id, body);
    return this.http.post(`${this.twilioApiUrl}${id}/video/tokenV2`, body);
  }

  mute() {
    this.roomObj.localParticipant.audioTracks.forEach(function (audioTrack) {
      audioTrack.track.disable();
    });
    this.microphone = false;
  }

  unmute() {
    this.roomObj.localParticipant.audioTracks.forEach(function (audioTrack) {
      audioTrack.track.enable();
    });
    this.microphone = true;
  }

  turnOffVideo() {
    this.roomObj.localParticipant.videoTracks.forEach(function (audioTrack) {
      audioTrack.track.disable();
    });
    this.video = false;
  }

  turnOnVideo() {
    this.roomObj.localParticipant.videoTracks.forEach(function (audioTrack) {
      audioTrack.track.enable();
    });
    this.video = true;
  }

  attachParticipantTracks(participant: RemoteParticipant): void {
    participant.tracks.forEach((part) => {
      this.trackPublished(part, participant);
    });
  }

  trackPublished(
    publication: RemoteTrackPublication,
    participant: RemoteParticipant
  ) {
    publication.on("subscribed", (track) => {
      let remoteUser = this.users.find(
        (data) => data["identity"] === participant.identity
      );
      if (track.kind === "audio") {
        if (remoteUser) {
          remoteUser["audioElement"] = track.attach();
          remoteUser["audioTrack"] = track;
        }
      }
      if (track.kind === "video") {
        if (remoteUser) {
          remoteUser["videoElement"] = track.attach();
          remoteUser["videoTrack"] = track;
        }
      }
      if (remoteUser) {
        if (track.kind === "audio") {
          remoteUser["audioElement"] = track.isEnabled;
        }
        if (track.kind === "video") {
          remoteUser["videoElement"] = track.isEnabled;
        }
      }
      this.participants.next(this.users);
      if (remoteUser) {
        this.attachTracks(track, participant);
      }
    });
  }

  attachTracks(track, participant: RemoteParticipant) {
    const element = track.attach();
    if (track.kind === "video") {
      this.renderer.data.id = track.sid;
      const divElement = this.renderer.createElement("div");
      this.renderer.addClass(divElement, "video-thumb");
      this.renderer.setAttribute(divElement, "id", participant.sid);
      const divElement2 = this.renderer.createElement("div");
      this.renderer.addClass(divElement2, "video-status");
      let text = this.renderer.createText(participant.identity);
      this.renderer.appendChild(divElement2, text);
      this.renderer.appendChild(divElement, divElement2);
      this.renderer.appendChild(divElement, element);
      this.renderer.setStyle(element, "height", "100%");
      this.renderer.setStyle(element, "max-width", "100%");
      this.renderer.appendChild(this.remoteVideo.nativeElement, divElement);
    }
    if (track.kind === "audio") {
      this.renderer.appendChild(this.remoteVideo.nativeElement, element);
    }
  }

  startLocalVideo(): void {
    this.roomObj.localParticipant.videoTracks.forEach((publication) => {
      const element = publication.track.attach();
      this.renderer.data.id = publication.track.sid;
      this.renderer.appendChild(this.localVideo.nativeElement, element);
    });
  }

  detachTracks(participant: RemoteParticipant): void {
    let element = document.getElementById(participant.sid);
    element.remove();
    this.users = this.users.filter(
      (data) => data["identity"] !== participant.identity
    );
    let user = this.users.filter(
      (data) => data["identity"] !== participant.identity
    );
    this.participants.next(this.users);
  }

  addParticipant(participant: RemoteParticipant) {
    if (!this.users.find((data) => data["identity"] === participant.identity)) {
      this.users.push({
        identity: participant.identity,
        sid: participant.sid,
      });
    }
  }

  findParticipant(participant: RemoteParticipant) {
    return this.users.find((user) => user["identity"] === participant.identity);
  }

  private subscribe(
    publication: RemoteTrackPublication | any,
    participant: RemoteParticipant
  ) {
    if (publication && publication.on) {

      publication.on("subscribed", (track: RemoteTrack) => {
        this.attachTracks(track, participant);
      });
      publication.on("unsubscribed", (track: RemoteTrack) =>
        this.detachTracks(participant)
      );
    }
  }

  private disablingVideo(participant: RemoteParticipant) {
    let element = document.querySelector(`#${participant.sid} video`);
    let parent = document.querySelector(`#${participant.sid}`);
    const divElement = document.createElement("div");
    divElement.classList.add("video_off");
    parent.appendChild(divElement);
  }
  private enablingVideo(
    participant: RemoteParticipant,
    publication: RemoteTrackPublication
  ) {
    let element = document.querySelector(`#${participant.sid} .video_off`);
    element.remove();
  }
  private disconnect() {
    this.roomObj.disconnect();
    this.router.navigate(["/home"]);
  }

  shareScreen() {
    try {
      if (!this.screenShare) {
        const mediaDevices = navigator.mediaDevices as any;
        // @ts-ignore
        mediaDevices
          .getDisplayMedia()

          .then((stream) => {
            this.screenShare = new LocalVideoTrack(stream.getTracks()[0], {
              name: "screen",
              logLevel: {
                default: "warn",
                media: "warn",
                webrtc: "warn",
                signaling: "warn",
              },
            });
            this.roomObj.localParticipant.publishTrack(this.screenShare);
            this.screenShare.mediaStreamTrack.onended = () => {
              this.shareScreen();
            };
          })
          .catch((err) => {
          });
      } else {
        this.roomObj.localParticipant.unpublishTrack(this.screenShare);
        this.screenShare.stop();
        this.screenShare = null;
        // shareScreen.innerHTML = 'Share screen';
      }
    } catch (err) {
    }
  }

  async connectToChat(token, conversationSid) {
    const client = await new Client(token);
    this.conversation = await client.getConversationBySid(conversationSid);

    this.conversation.on("messageAdded", (message) => {
      if (!this.messageUsers.find((data) => data === message.state.author)) {
        message["backgroundColor"] = this.setBg();
        this.messages.push(message);
      } else {
        this.messages.push(message);
      }
      setTimeout(() => {
        this.chatContainer.nativeElement.scrollTop =
          this.chatContainer.nativeElement.scrollHeight;
      }, 20);
    });
    const messages = await this.conversation.getMessages(30);
    for (let mess of messages.items) {
      if (
        !this.messageUsers.find((data) => data["name"] === mess.state.author)
      ) {
        mess["backgroundColor"] = this.setBg();
        this.messageUsers.push({
          name: mess.state.author,
          backgroundColor: mess["backgroundColor"],
        });
        this.messages.push(mess);
      } else {
        let user = this.messageUsers.find(
          (data) => data["name"] === mess.state.author
        );
        mess["backgroundColor"] = user["backgroundColor"];
        this.messages.push(mess);
      }
    }
    setTimeout(() => {
      this.chatContainer.nativeElement.scrollTop =
        this.chatContainer.nativeElement.scrollHeight;
    }, 20);
    // client.
  }

  sendMessage(value) {
    this.conversation.sendMessage(value);
    // chatInput.value = '';
  }

  attachScreenShare(track: VideoTrack, participant: RemoteParticipant) {
    let element = document.querySelector("#localvideo video");
    let status = document.querySelector("#localvideo .video-status");
    // let copy = element;
    let localelement = document.querySelector("#localvideo");
    localelement.appendChild(track.attach());
    // let newElement = document.createElement("div");
    let statusElement = document.createElement("div");
    statusElement.classList.add("video-status");
    // statusElement.innerHTML = ``;
    statusElement.innerHTML = `
    <span>${participant.identity}</span>
    <div class="video-online mb-2 ml-1"></div>
    `;
    // element.remove();
    let createElement = document.getElementById("remotevideo");
    let newDiv = document.createElement("div");
    newDiv.setAttribute("id", "tempvideo");
    newDiv.classList.add("video-thumb");
    newDiv.appendChild(element);
    newDiv.appendChild(status);
    localelement.appendChild(statusElement);
    createElement.appendChild(newDiv);
  }

  detachScreenShare(track: VideoTrack) {
    let element = document.querySelector("#tempvideo video");
    let tempel = document.querySelector("#tempvideo .video-status");
    let remote = document.querySelector("#localvideo .video-status");
    remote.remove();
    let localEl = document.querySelector("#localvideo video");
    localEl.remove();
    let local = document.querySelector("#localvideo");
    local.appendChild(element);
    local.appendChild(tempel);
    let temp = document.querySelector("#tempvideo");
    temp.remove();
  }

  setBg() {
    const randomColor = Math.floor(Math.random() * 16777215).toString(16);
    return "#" + randomColor;
    // document.body.style.backgroundColor = "#" + randomColor;
    // color.innerHTML = "#" + randomColor;
  }

  updateComments(body: {
    Comments: string;
    Description: string;
    Status: string;
    FollowUpdate: any;
  }) {
    return this.http.post(`${environment.baseApiUrl}updateDemoRec`, body);
  }
}
