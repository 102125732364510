<div>
  <!-- <ngb-alert type="danger" *ngIf="isError">
    Something Wen't Wrong Please Try Again Later
  </ngb-alert> -->
  <div>
    <div [formGroup]="smsForm" class="p-1 pt-1">
      <div class="col-md-12 mb-2" *ngIf="!smsUpdate">
        <label for="description" class="form-label" required>SMS Type</label>
        <select class="form-control" name="SMSType" formControlName="SMSType"
          [ngClass]="{ 'is-invalid': submitted && f.SMSType.errors }">
          <option>Information</option>
          <option>Feedback</option>
        </select>
        <div *ngIf="submitted && f.SMSType.errors" class="invalid-feedback">
          <div *ngIf="f.SMSType.errors.required">SMS Type is required</div>
        </div>
      </div>
      <div class="col-md-12 mb-2">
        <label for="description" class="form-label">SMS Text
          <span class="mx-2" *ngIf="this.smsForm.value.SMSType === 'Feedback'">(Header)</span>
        </label>
        <textarea name="CallText" class="form-control mb-3" formControlName="smsTextHeader" rows="2" required
          [ngClass]="{ 'is-invalid': submitted && f.smsTextHeader.errors }">
        </textarea>
        <div *ngIf="submitted && f.smsTextHeader.errors" class="invalid-feedback">
          <div *ngIf="f.smsTextHeader.errors.required">
            SMS Text is required
          </div>
        </div>
      </div>

      <div formArrayName="Ivr" *ngIf="this.smsForm.value.SMSType === 'Feedback'">
        <div class="d-flex align-items-center my-1" *ngFor="let quantity of Ivr().controls; let i = index"
          [formGroupName]="i">
          <div class="col">
            <label class="form-label">SMS Option :</label>
            <input type="text" formControlName="feedBackNumber" class="form-control" />
          </div>
          <div class="col">
            <label class="form-label">SMS Text :</label>
            <input type="text" formControlName="value" class="form-control" />
          </div>
          <div class="col">
            <button (click)="removeNumber(i)" class="btn btn-primary" *ngIf="i !== Ivr().controls.length - 1">
              <i class="fa fa-minus"></i>
            </button>
            <button type="button" (click)="addNewNumber()" class="btn btn-primary"
              *ngIf="i === Ivr().controls.length - 1">
              <i class="fa fa-plus"></i>
            </button>
          </div>
        </div>
      </div>

      <div class="col-md-12 mb-2" *ngIf="this.smsForm.value.SMSType === 'Feedback'">
        <label for="description" class="form-label">SMS Text
          <span class="mx-2" *ngIf="this.smsForm.value.SMSType === 'Feedback'">(Footer)</span>
        </label>
        <textarea name="CallText" class="form-control " formControlName="smsTextFooter" rows="2"
          [ngClass]="{ 'is-invalid': submitted && f.smsTextFooter.errors }">
        </textarea>
        <div *ngIf="submitted && f.smsTextFooter.errors" class="invalid-feedback">
          <div *ngIf="f.smsTextFooter.errors.required">
            SMS Text Footer is required
          </div>
        </div>
      </div>

      <div class="col-md-12 mb-2">
        <label class="form-label">Before SMS Time</label>
        <ngb-timepicker formControlName="time" [seconds]="true"></ngb-timepicker>
      </div>

      <!-- <div class="d-flex justify-content-end my-2">
        <button class="btn btn-primary">Submit</button>
      </div> -->
    </div>
  </div>
</div>