import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';


@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.css']
})
export class LayoutComponent implements OnInit {
  toggleChatBox: any = false;
  constructor(private router: Router,) { }

  ngOnInit() {
    if (this.router.url == "/") {
      this.router.navigate(['/uhleads']);
    }
  }
  toggleChat() {
    this.toggleChatBox = !this.toggleChatBox;
  }
}
