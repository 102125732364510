import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
// import { SimpleNotificationsComponent } from 'src/components/simple-notifications/simple-notifications.component';
import { SimpleNotificationsComponent } from 'src/components/simple-notifications/simple-notifications.component';
import { NotificationComponent } from 'src/components/notification/notification.component';
import { NotificationsService } from './notification.service';

export * from 'src/components/notification/notification.component';
export * from 'src/components/simple-notifications/simple-notifications.component';
export * from 'src/app/notification.service'
export * from './interfaces/icons';
export * from './interfaces/notification-event.type';
export * from './interfaces/notification.type';
export * from './interfaces/options.type';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    SimpleNotificationsComponent,
    NotificationComponent
  ],
  exports: [SimpleNotificationsComponent],
  providers: [NotificationsService]
})
export class SimpleNotificationsModule {
  /*  static forRoot(): ModuleWithProviders {
      return {
        ngModule: SimpleNotificationsModule,
        providers: [NotificationsService]
      };
    }*/
}