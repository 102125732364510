<div class="container-fluid p-0">
  <div class="row">
    <div class="home-banner-bg2">
      <div class="banenr-text2">Contact Us</div>
    </div>
  </div>
</div>
<div class="container-md m-auto bg-location">
  <!--Section AccessQue-->
  <div class="row mt-5">
    <div class="col-md-4 col-sm-4 col-lg-4">
      <p class="lightFont w-80 lh130 px-3">
        Our Locations
      </p>
    </div>
  </div>
  <div class="row" style="min-height:180px">
    <div class="col-md-4 col-sm-4 col-lg-4">
      <p class="p-3" style="line-height:150%"><b>United Kingdom</b><br>
        IG26FB, London<br>
        Call: +44(0)7767114713,<br>
        Whatsapp: +44(0)7767114713<br>
        Email: rajesh.muni@avantao.com
      </p>
    </div>
    <div class="col-md-4 col-sm-4 col-lg-4">
      <p class="p-3" style="line-height:150%"><b>Banglore</b><br>
        #39, NGEF Lane, 2nd Floor, Suite,<br>
        No.673 Indiranagar, Bangalore, Karnataka,<br>
        India - 560 038
      </p>
    </div>
  </div>
  <div class="row mb-5" style="min-height:300px">
    <div class="col-md-4 col-sm-4 col-lg-4">
      <p class="p-3" style="line-height:150%"><b>USA</b><br>
        37 Station Dr Suite-D, Princeton<br>
        Junction NJ, 08550 -<br>
        Whatsapp: (609)-271-4961,<br>
        Tel: (609) - 751 – 5311
      </p>
    </div>
    <div class="col-md-4 col-sm-4 col-lg-4">
      <p class="p-3" style="line-height:150%"><b>Hyderabad</b><br>
        #203, 2nd Floor, SBR CV Towers,<br>
        Madhapur, Hyderabad,<br>
        Telangana, India <br>
        500 081<br>
        Whatsapp: +91-9902587664,<br>
        +44-7767124713
      </p>
    </div>
  </div>


  <!-- Modal -->
  <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-md">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Book a Demo</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><img
              src="../../../../assets/img/close-w.svg" class="img12"></button>
        </div>
        <div class="modal-body">
          <div class="py-3">
            Please complete this form. An expert will contact you soon.
          </div>
          <form>
            <div class="mb-3">
              <label for="exampleFormControlInput1" class="form-label">Name</label>
              <input type="email" class="form-control" id="exampleFormControlInput1" placeholder="">
            </div>
            <div class="mb-3">
              <label for="exampleFormControlInput1" class="form-label">Email</label>
              <input type="email" class="form-control" id="exampleFormControlInput1" placeholder="">
            </div>
            <div class="row mb-3 ml-0">
              <div class="col-auto mr-3">
                <label for="exampleFormControlInput1" class="form-label">Mobile</label>
                <input type="email" class="form-control" style="width:350px;" id="exampleFormControlInput1"
                  placeholder="">
              </div>
              <div class="col-auto">
                <button type="submit" class="btn btn-outline-secondary mt-20">Send OTP</button>
              </div>
            </div>
            <div class="mb-3">
              <input type="email" class="form-control" id="exampleFormControlInput1" placeholder="Enter OTP">
            </div>
            <div class="mb-3 d-flex align-items-center">
              <div>
                <img src="../../../../assets/signup/cap.jpg" style="width:230px">
              </div>
              <div class="ml-auto">
                <button class="btn btn-primary btn-sm">Submit</button>
              </div>
            </div>
          </form>
        </div>

      </div>
    </div>
  </div>
</div>