<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">{{comefrom=='workflow'?'Add Template':'Edit Template'}}</h4>
  <button type="button" class="close" aria-label="Close" (click)="modalService.dismissAll()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="container">
  <div class="row">
    <div class="col-md-3 pt-2">
      <label for="name" class="form-label">Template</label>
      <input type="text" id="name" class="form-control" style="font-size: 14px; text-transform: capitalize"
        [(ngModel)]="inputData.name" [disabled]="isUpdate ? true : false" />
    </div>
    <div class="col-md-3 pt-2" *ngIf="comefrom!='workflow'">
      <label for="name" class="form-label">Choose Project</label>
      <app-project-change (projectChange)="projectChange($event)"></app-project-change>
    </div>

    <!-- <div class="col-md-4 pt-2">
      <label for="fromEmail" class="form-label">Sender</label>
      <input
        type="text"
        id="fromEmail"
        class="form-control"
        style="font-size: 14px; text-transform: capitalize"
        [(ngModel)]="inputData.fromEmail"
        [disabled]="true"
      />
    </div> -->

    <!-- <div class="col" style="display: none">
			<label for="fromEmail">Incoming Mail Group:</label>
			<ng-multiselect-dropdown
				[(ngModel)]="group"
				[data]="groups"
				[settings]="companySettings"
				style="width: 100%; padding: 0"
				(onSelect)="updateGroup($event)"
			>
			</ng-multiselect-dropdown>
		</div> -->
  </div>
  <div>
    <email-editor></email-editor>
  </div>
  <div class="row d-flex justify-content-end p-2">
    <button (click)="saveTemplate()" class="btn-p" *ngIf="!isUpdate">
      Create Template
    </button>
    <button (click)="updateTemplate()" class="btn-p" *ngIf="isUpdate">
      Update Template
    </button>
  </div>
</div>