declare const $: any;
import {
  Directive,
  ElementRef,
  HostBinding,
  HostListener,
  Input,
  OnInit,
  Renderer2,
} from "@angular/core";
import { SharedService } from "src/app/shared/shared.service";

@Directive({
  selector: "[appRemoveLink]",
})
export class RemovelinkDirective implements OnInit {
  public active: boolean = false;
  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private sharedService: SharedService
  ) { }

  @Input() appRemoveLink: boolean;
  @Input() collapseChild: string;

  ngOnInit(): void {
    this.sharedService.switchToggle.subscribe((data) => {
      if (this.appRemoveLink) {
        this.renderer.addClass(this.el.nativeElement, "link_active");
      }
    });
  }

  @HostListener("click") removeClass() {
    if (this.appRemoveLink) {
      if ([...this.el.nativeElement.classList].includes("link_active")) {
        this.renderer.removeClass(this.el.nativeElement, "link_active");
      } else {
        this.renderer.addClass(this.el.nativeElement, "link_active");
      }
    }
    // console.log([...this.elementRef.nativeElement.classList]);

    if ($(`#${this.collapseChild}`).attr("class").split(" ").includes("show")) {
      if (
        !($("#SideLogo").attr("src") === "assets/newimages/Accessque-small.png")
      ) {
        this.sharedService.toggleSidebar.next("toggle");
      }
    } else {
    }

    this.active = !this.active;
  }
}
