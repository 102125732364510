import {
  Component, ElementRef, OnInit, Output, EventEmitter,
  ViewChild
} from '@angular/core';
import { Router } from '@angular/router';
import { Client, Conversation } from "@twilio/conversations";
import { environment } from 'src/environments/environment';
declare const Twilio: any;

@Component({
  selector: 'app-landingpage',
  templateUrl: './landingpage.component.html',
  styleUrls: ['./landingpage.component.css']
})

export class LandingpageComponent implements OnInit {
  baseUrl = environment.socketUrl;
  currentIndex = 0;
  content = [
    { title: "Instant Calls", content: "Accessque Guide is a smart knowledge base that helps you harness the power of institutionalknowledge." },
    {
      title: "Live Chat", content: "Create a personal connection with customers looking for support.It\’ s fast and effective"
    },
    {
      title: "Social", content: "Be where they want to be — connect popular messaging apps to Accessque."
    },
    { title: "Mails", content: "Accessque Guide is a smart knowledge base that helps you harness the power of institutionalknowledge." },
    {
      title: "Integrated Voice", content: "Talk it out directly with customers and provide personalized solutions to complex issues."
    }
    , { title: "Tickets", content: "Accessque Guide is a smart knowledge base that helps you harness the power of institutionalknowledge." }

  ]
  client: any;
  channel: any;
  username: any = 'Test User 2';
  //  email:any = 'test@abc.com';
  //  description:any = 'Web chat request';
  //  phone:any = '9096652820';
  RolesContacted: any = 'Studentcalls';
  channelId: any;
  chatClient1: any;
  generalChannel: any;
  message: any = []
  name: any;
  email: any;
  description: any;
  phone: any;
  isIconShow: any
  showNote: Boolean = false
  @ViewChild("chatContainer") chatContainer!: ElementRef;
  // @Output() showchat: EventEmitter<Boolean> = new EventEmitter();
  is_showchat: Boolean = false
  scrollTop() {
    if (this.message && this.message.length > 0) {
      setTimeout(() => {
        this.chatContainer.nativeElement.scrollTop =
          this.chatContainer.nativeElement.scrollHeight;
      }, 20);
    }

  }
  typingNotification: any

  constructor(private router: Router) {

  }

  ngOnInit(): void {
  }
  async getToken() {

    let _data = {
      "identity": this.username
    }
    let url = 'https://crmtest.universityhub.com/api/13/webChat/token';
    let response = await fetch(url, { headers: { 'content-type': 'application/json' }, method: "POST", body: JSON.stringify(_data) });
    const data = await response.json();
    console.log(data); // Logs the response
    this.channelId = data.channelId;
    // Initialize the Chat client
    //Twilio.Chat.Client.create(data.token).then(client => {

    Twilio.Conversations.Client.create(data.token).then((client: any) => {
      console.log('Created chat client');
      this.chatClient1 = client;
      // chatClient.on('channelInvited', function(channel) {
      // 	console.log('Agent Invited to channel ----------------------------->' + channel.sid, channel.attributes);
      // 	// Join the channel that you were invited to
      // 	//channel.join();
      //   generalChannel = channel;
      // 	setupChannel();
      // });

      this.chatClient1.on("connectionStateChanged", (state: any) => {

        if (state == "connected") {
          console.log("Conversation client connected");
        }
        if (state == 'disconnecting') {
          console.log("Conversation client disconnecting");
        }
        if (state == 'disconnected') {
          console.log("Conversation client disconnected");
        }
        if (state == 'denied') {
          console.log("Conversation client denied");
        }

      });

      // chatClient1.on("conversationJoined", (conversation) => {
      //   console.log('here is the result of the conversationJoined hook');
      //   generalChannel = conversation;
      //   setupChannel();
      //   //console.log(conversation);
      // });

      //chatClient.getSubscribedChannels().then(createOrJoinGeneralChannel);
      //let conversations = await chatClient1.getSubscribedConversations();
      this.createOrJoinGeneralChannel();
      this.refreshToken(this.username);

      // when the access token is about to expire, refresh it
      var that = this
      this.chatClient1.on('tokenAboutToExpire', function () {
        that.refreshToken(that.username);
      });

      // if the access token already expired, refresh it
      this.chatClient1.on('tokenExpired', function () {
        console.log("Refersh token: ", that.username);
        that.refreshToken(that.username);
      });

      // Alert the user they have been assigned a random username
      //username = data.identity;
      console.log('You have been assigned a random username of: ' + '<span class="me">' + this.username + '</span>', true);

      // print('You have been assigned a random username of: '
      // + '<span class="me">' + username + '</span>', true);
      this.scrollTop()
    }).catch((error: any) => {
      console.error(error);
      console.log('There was an error creating the chat client:<br/>' + error, true)
      console.log('Please check your .env file.', false)
      // print('There was an error creating the chat client:<br/>' + error, true);
      // print('Please check your .env file.', false);
    });
  }



  async refreshToken(identity: any) {
    console.log('Token about to expire: ', identity);

    let _data = {
      "identity": identity
    }
    let url = 'https://crmtest.universityhub.com/api/13/webChat/token';
    let response = await fetch(url, { headers: { 'content-type': 'application/json' }, method: "POST", body: JSON.stringify(_data) });
    const data = await response.json();
    //console.log(data);
    console.log('updated token for chat client: ', data);
    this.chatClient1.updateToken(data.token);

  }

  async createOrJoinGeneralChannel() {
    console.log('Attempting to create unique browser chat channel...')
    // print('Attempting to create unique browser chat channel...');
    // let url = 'https://www.accessque.com/api/webChat/channelId';
    // let response = await fetch(url, {method: "GET"});
    // const data = await response.json();

    // channelId = data.id;
    //channelId = 'webchat_e787d3ef-f36a-4a77-b505-bab9c764d1b9';
    console.log("Channel Id: ", this.channelId);

    //chatClient.getChannelByUniqueName(channelId)
    let that = this
    this.chatClient1.getConversationBySid(this.channelId)
      .then(function (channel: any) {
        that.generalChannel = channel;
        console.log(`Found ${that.channelId} channel:`);
        console.log(that.generalChannel);
        that.setupChannel();
      }).catch(function () {
        // If it doesn't exist, let's create it
        console.log('Creating channel');
        that.chatClient1.createConversation({
          //chatClient.createChannel({

          uniqueName: that.channelId,
          friendlyName: that.channelId,
          attributes: {
            UserName: that.username,
            EmailId: that.email,
            RequestDescription: that.description,
            Phone: that.phone,
            RolesContacted: that.RolesContacted,
            TwilioNumber: environment.TwilioNumber

          }

        }).then(function (channel: any) {
          console.log('Created channel:');
          console.log(channel);
          that.generalChannel = channel;
          that.setupChannel();
        }).catch(function (channel: any) {
          console.log('Channel could not be created:');
          console.log(channel);
        });
      });

  }


  // Set up channel after it has been found
  setupChannel() {
    // Join the general channel
    let that = this
    this.generalChannel.join().then(async function (channel: any) {
      console.log('Joined channel as ' + '<span class="me">' + that.username + '</span>.', true);
      //await channel.add(username).catch(err => console.log(err));
      // print('Joined channel as '
      // + '<span class="me">' + username + '</span>.', true);

      // call createWebchat API to store data in db
      // var _data = {
      //   ChannelSid : generalChannel.sid,
      //   ChannelName: generalChannel.channelState.uniqueName,
      //   UserName: generalChannel.channelState.createdBy,
      //   EmailId: email,
      //   RequestDescription: description,
      //   Phone: phone,
      //   Channel: 'chat',
      //   ChannelStatus: 'created',
      //   RolesContacted: 'sales'

      // }
      //console.log("Create API data: ", _data);
      // let url = 'https://www.accessque.com/api/v1/createWebChat';
      // let response1 = await fetch(url, {headers: {'content-type': 'application/json'}, method: "POST", body: JSON.stringify(_data)});
      // const data1 = await response1.json(); 
      // console.log(data1);
    });

    // Listen for new messages sent to the channel

    this.generalChannel.on('messageAdded', function (message: any) {
      console.log("message **************** ", message)
      this.showNote = false
      that.message.push({
        body: message.body,
        author: message.author,
        timestamp: Date.now(),
        sid: message.sid,
      })
      // printMessage(message.author, message.body);
    });
    this.generalChannel.on("typingStarted", (member: any) => {
      this.typingNotification = member.identity + ' is typing ...';
      console.log(this.typingNotification)
    });

    this.generalChannel.on("typingEnded", (member: any) => {
      this.typingNotification = '';
    });
  }
  print(infoMessage: any, asHtml: any) {
  }

  submit() {
    this.showNote = true
    this.username = this.name
    this.getToken()

  }
  sendMessage() {
    console.log(this.generalChannel)
    if (this.chatMessage !== "") {
      this.generalChannel.sendMessage(this.chatMessage);
      this.chatMessage = "";
    }
    this.scrollTop()

  }
  chatMessage: any
  addEmoji(event: any) {
    this.chatMessage = this.chatMessage + event.emoji.native;
  }
  enterKey(event: any) {
    if (event.keyCode === 13) {
      this.sendMessage();
    }
  }
  showChat() {
    this.name = ''
    this.email = ''
    this.phone = ''
    this.description = ''
    this.is_showchat = !this.is_showchat
    this.message = []
    // this.showchat.emit(false);
  }
  subscribe(id) {
    sessionStorage.setItem('planId', id);
    sessionStorage.setItem('signupPage', 'subscribe');
    this.router.navigate(['/signup']);
  }
}
