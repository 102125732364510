<div *ngIf="is_showchat" style="display:none">
    <div id="ac-chat-window" style="z-index: 99999;">
        <div id="ac-chat-window-header">
            <h3>Accessque chat</h3>
            <div class="chat-control">
                <!-- <img src="assets/mini-w.png" style="width: 16px; position: cursor;margin-right: 5px;"> -->
                <img src="assets/img/close-w.png" (click)="is_showchat=false" style="width: 16px;">
            </div>
        </div>
        <div class="chat-body">
            <form novalidate="" *ngIf="message && !message.length" class="ng-untouched ng-pristine ng-invalid"
                style="line-height: 20px;">
                <div class="chat-fields"> Name<br>
                    <input type="name" id="exampleInputEmail1" aria-describedby="emailHelp"
                        placeholder="please enter name" name="name" [(ngModel)]="name" required=""
                        class="ng-untouched ng-pristine ng-invalid">
                </div>
                <div class="chat-fields"> Email<br>
                    <input type="email" id="exampleInputEmail1" autocomplete="false" aria-describedby="emailHelp"
                        placeholder="Enter email" name="email" [(ngModel)]="email" required=""
                        class="ng-untouched ng-pristine ng-invalid">
                </div>
                <div class="chat-fields"> Phone<br><input type="text" id="exampleInputPassword1"
                        placeholder="phoneNumber" name="phoneNumber" [(ngModel)]="phone" required=""
                        class="ng-untouched ng-pristine ng-invalid"></div>
                <div class="chat-fields"> Description<br><textarea [(ngModel)]="description" name="desc" required=""
                        class="ng-untouched ng-pristine ng-invalid"></textarea></div>
                <div class="chat-fields" *ngIf="showNote">
                    <span><b>Note :</b> please wait connecting to available agents </span>
                </div>
                <div class="chat-fields">
                    <button type="submit" [disabled]="showNote" class="chat-fields-btn pointer"
                        [ngStyle]="showNote ? {'pointer-events': 'none'} : {'pointer-events': 'auto'}"
                        (click)="submit()"> Submit
                    </button>

                </div>
            </form>
            <div *ngIf="message && message.length>0" style="overflow: auto;height:350px" class="scrollbar"
                #chatContainer>
                <div *ngFor="let message of message" class="chat-block">

                    <div class="chat-messages-left-wrapper" *ngIf="!(message.author === username)">
                        <!-- <div
                        *ngIf="message.author === 'system'"
                        class="system my-1"
                    >
                        {{ message.body | titlecase }}
                    </div> -->
                        <!-- <div *ngIf="message.author !== 'system'">
                        <div class="messages-user-img-container">
                            <img
                                class="user-img"
                                src="../../../assets/img/chat-user-icon.png"
                                alt=""
                            />
                            <div class="messages-status online"></div>
                        </div>
                    </div> -->
                        <div style="width: auto; word-break: break-word" *ngIf="message.author !== 'system'">
                            <div class="messages-user-img-container">
                                <img class="user-img" src="../../../assets/img/chat-user-icon.png" alt="" />
                                <div class="messages-status online"></div>
                            </div>
                            <div class="message-left-bubble flex-column">
                                <div class="author my-1">
                                    {{
                                    message.author
                                    }}:
                                </div>

                                <p class="message-text">
                                    {{ message.body }}
                                </p>
                            </div>
                            <p class="message-time">
                                {{ message.timestamp | date: 'short' }}
                            </p>
                        </div>
                    </div>
                    <div class="chat-messages-right-wrapper" *ngIf="message.author === username">
                        <div style="
                            width: auto;
                            margin-right: 15px;
                            word-break: break-word;
                        ">
                            <div class="message-right-bubble">
                                <div class="author my-1">
                                    {{
                                    message.author
                                    }}:
                                </div>

                                <p class="message-text" style="color: black">
                                    {{ message.body }}
                                </p>
                            </div>
                            <p class="message-time right-side" style="color: black">
                                {{ message.timestamp | date: 'short' }}
                            </p>
                        </div>
                        <div>
                            <div class="messages-user-img-container">
                                <img class="user-img" style="width: 25px; height: 25px"
                                    src="../../../assets/img/chat-accessque-logo.png" alt="" />
                                <div class="messages-status online"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="message-send-box-wrapper" *ngIf="message && message.length>0">
                <div class="chat-bottom-block">
                    <div class="chat-inner-block">
                        <input type="text" class="" name="" (keyup)="enterKey($event)" [(ngModel)]="chatMessage" />
                        <!-- <mat-icon
                                style="position: absolute; top: 7px; right: 90px"
                                (click)="isIconShow = !isIconShow"
                                >mood</mat-icon
                            > -->
                        <!-- <emoji-mart
                                *ngIf="isIconShow"
                                (emojiClick)="addEmoji($event)"
                                emoji="point_up"
                                [hideObsolete]="true"
                                [set]="'facebook'"
                            ></emoji-mart> -->
                        <button class="message-send-btn" (click)="sendMessage()">Send</button>
                    </div>
                </div>
            </div>
            <div class="chat-powerd">Powered by Avantao</div>
        </div>

    </div>
</div>

<div class="container-fluid">
    <!-- top nav bar -->
    <div class="row">
        <!-- Nav -->

        <nav class="navbar navbar-expand-lg bg-light">
            <div class="container-fluid">
                <a class="navbar-brand" href="index.html"><img src='../../assets/images/Accessque-small.svg'
                        alt="AccessQue"></a>
                <button class="navbar-toggler collapsed" type="button" data-bs-toggle="collapse"
                    data-bs-target="#navbarText" aria-controls="navbarText" aria-expanded="false"
                    aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div class="navbar-collapse collapse mobile-view" id="navbarText">
                    <ul class="navbar-nav me-auto mb-2 mb-lg-0 mob-center">
                        <li class="nav-item"><button type="button" class="btn btn-link" routerLink="/home">Home</button>
                        </li>
                        <li class="nav-item"><button type="button" class="btn btn-link" routerLink="">About Us</button>
                        </li>
                        <li class="nav-item">
                            <div class="dropdown">
                                <button class="btn btn-link dropdown-toggle" type="button" id="dropdownMenuButton"
                                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    Our Projects
                                </button>
                                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                    <a class="dropdown-item" routerLink="/home/b2b">B2B & B2C Lead Generation</a>
                                    <a class="dropdown-item" routerLink="/home/aicalls">AI Call Analytics</a>
                                    <a class="dropdown-item" routerLink="/home/lipsync">AI Lip-sync with Deep
                                        fake...</a>
                                    <a class="dropdown-item" routerLink="#">AI powered English Test</a>
                                    <a class="dropdown-item" routerLink="#">AI Image Restoration</a>
                                    <a class="dropdown-item" routerLink="/aivoicebot">Virtual Agents-Voice Bots</a>
                                    <a class="dropdown-item" routerLink="#">Sales, Marketing & Referrals</a>
                                    <a class="dropdown-item" routerLink="#">Agent Desktop Monitoring</a>
                                    <a class="dropdown-item" routerLink="#navbarText">Social Media Bots</a>
                                    <a class="dropdown-item" href="#">BI Analytics</a>
                                    <a class="dropdown-item" href="#">Best Incident Tool </a>
                                </div>
                            </div>
                        </li>
                        <li class="nav-item">
                            <button type="button" class="btn btn-link mr-2" routerLink="/contact">Contact Us</button>
                        </li>
                        <li class="nav-item">
                            <button type="button" class="btn btn-link mr-2" routerLink="/login">Login</button>
                        </li>
                        <li class="nav-item">
                            <button type="button" class="btn btn-link" routerLink="/signup">Sign-up</button>
                        </li>
                        <li class="nav-item">
                            <button type="button" class="btn btn-primary" routerLink="/bookademo/accessque">Book a
                                Demo</button>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    </div>


    <router-outlet></router-outlet>

    <!-- footer bar -->
</div>
<div class="site-footer pt-3 pb-3">
    <div class="container site-footer">
        <div class="row">
            <div class="col-md-3 p-4">
                <a href="#"><img src='../../assets/images/accessQue_white.svg' style="width:150px" /></a>

                <ul class="nav text-white align-items-center mb-20 nav-gap-md nav-no-opacity ml-2 mt-2">
                    <li class="nav-item"><a class="nav-link" href="https://www.facebook.com/Accessque/"
                            target="_blank"><svg xmlns="http://www.w3.org/2000/svg" width="8" height="17" fill="none">
                                <path fill="currentColor"
                                    d="M6.318 2.8h1.391V.202A16.842 16.842 0 0 0 5.683.088c-2.006 0-3.38 1.353-3.38 3.837v2.287H.089v2.902h2.214v7.303h2.713V9.114H7.14l.338-2.902H5.016v-2c0-.839.21-1.413 1.302-1.413Z">
                                </path>
                            </svg></a></li>
                    <li class="nav-item"><a class="nav-link" href="#"><svg xmlns="http://www.w3.org/2000/svg" width="21"
                                height="15" fill="none">
                                <path fill="currentColor"
                                    d="M19.687 2.485A2.472 2.472 0 0 0 17.953.73C16.423.313 10.29.313 10.29.313s-6.133 0-7.662.416A2.473 2.473 0 0 0 .895 2.485c-.41 1.55-.41 4.782-.41 4.782s0 3.233.41 4.782c.226.855.89 1.5 1.734 1.729 1.53.415 7.662.415 7.662.415s6.132 0 7.662-.415a2.435 2.435 0 0 0 1.734-1.729c.41-1.549.41-4.782.41-4.782s0-3.232-.41-4.782ZM8.285 10.203v-5.87l5.126 2.934-5.126 2.936Z">
                                </path>
                            </svg></a></li>
                    <li class="nav-item"><a class="nav-link" href="https://mobile.twitter.com/avantao"
                            target="_blank"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="16" fill="none">
                                <path fill="currentColor"
                                    d="M17.477 4.484c.012.165.012.329.012.493 0 5.014-3.817 10.792-10.792 10.792-2.149 0-4.145-.623-5.824-1.703.305.035.599.047.916.047a7.596 7.596 0 0 0 4.709-1.62 3.8 3.8 0 0 1-3.547-2.63c.235.034.47.058.717.058.34 0 .68-.047.998-.13A3.793 3.793 0 0 1 1.625 6.07v-.047a3.82 3.82 0 0 0 1.714.482 3.79 3.79 0 0 1-1.691-3.159c0-.704.188-1.35.517-1.914a10.781 10.781 0 0 0 7.82 3.97 4.282 4.282 0 0 1-.094-.87c0-2.09 1.691-3.793 3.793-3.793 1.092 0 2.079.458 2.771 1.198a7.466 7.466 0 0 0 2.408-.916c-.282.88-.881 1.62-1.668 2.09a7.604 7.604 0 0 0 2.184-.587 8.153 8.153 0 0 1-1.902 1.961Z">
                                </path>
                            </svg></a></li>
                    <li class="nav-item"><a class="nav-link" href="#"><svg xmlns="http://www.w3.org/2000/svg" width="17"
                                height="17" fill="none">
                                <path fill="currentColor"
                                    d="M8.788 4.097C6.47 4.097 4.6 5.95 4.6 8.25c0 2.298 1.87 4.153 4.188 4.153 2.318 0 4.188-1.855 4.188-4.153 0-2.3-1.87-4.153-4.188-4.153Zm0 6.853c-1.498 0-2.723-1.211-2.723-2.7 0-1.49 1.221-2.7 2.723-2.7 1.502 0 2.723 1.21 2.723 2.7 0 1.489-1.225 2.7-2.723 2.7Zm5.336-7.023a.97.97 0 0 1-.977.968.97.97 0 0 1-.976-.968c0-.535.437-.969.976-.969.54 0 .977.434.977.969Zm2.774.983c-.062-1.298-.36-2.447-1.32-3.394C14.624.569 13.465.272 12.156.207c-1.349-.076-5.39-.076-6.74 0C4.113.27 2.954.565 1.995 1.512 1.035 2.46.74 3.61.674 4.906c-.076 1.338-.076 5.346 0 6.683.063 1.298.361 2.447 1.32 3.394.959.947 2.114 1.244 3.423 1.309 1.348.076 5.39.076 6.739 0 1.308-.062 2.468-.358 3.422-1.309.956-.947 1.254-2.096 1.32-3.394.076-1.337.076-5.342 0-6.68Zm-1.742 8.114a2.745 2.745 0 0 1-1.553 1.54c-1.075.423-3.627.325-4.815.325-1.188 0-3.743.095-4.815-.325a2.746 2.746 0 0 1-1.552-1.54c-.427-1.066-.329-3.596-.329-4.774 0-1.179-.094-3.712.329-4.775a2.745 2.745 0 0 1 1.552-1.54C5.048 1.512 7.6 1.61 8.788 1.61c1.188 0 3.743-.094 4.815.325a2.745 2.745 0 0 1 1.553 1.54c.426 1.066.328 3.596.328 4.775 0 1.178.098 3.712-.328 4.774Z">
                                </path>
                            </svg></a></li>
                </ul>

            </div>
            <div class="col-md-3 col-sm-6 col-xs-6 text-white footerText p-4">
                <h6>Company</h6>
                <ul class="footer-link">
                    <li><a href="#">About Us</a></li>
                </ul>
            </div>
            <div class="col-md-3 col-sm-6 col-xs-6 text-white footerText p-4">
                <h6>Policies</h6>
                <ul class="footer-link">
                    <li><a href="privacy">Privacy Policy</a></li>
                    <li><a href="#">Terms & Conditions</a></li>
                </ul>
            </div>
            <div class="col-md-3 col-sm-6 col-xs-6 text-white footerText p-4">
                <h6>Contact</h6>
                <ul class="footer-link">
                    <li><a href="#"><img src="../../../../assets/images/whatsapp-w.svg" style="width:14px"> &nbsp;
                            +91-9902587664</a></li>
                    <li><a href="#">+44-7767124713</a></li>
                    <li><a href="#">support@avantao.com</a></li>
                </ul>
            </div>

        </div>
    </div>
</div>
<!-- 
    <div class="copy mt-4">
            <p class="extrasmallFont text-white">Developed by <strong>Avantao Technologies </strong><br />© 2021
                Copyright Accessque.</p>
        </div>
<div class="button-container">
    <button (click)="showChat();" class="floating-action-btn" style="position: relative">
        <img src="assets/logos/chat.png" alt="chat" style="width: 30px; height: 30px; padding: 0" />
    </button>
</div> -->