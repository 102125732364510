import { MapsComponent } from "./../tickets/maps/maps.component";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AuthService } from "./../../account/auth/auth.service";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { Subscription } from "rxjs";
import { LayoutService } from "src/app/layout/layout.service";
import { SharedService } from "src/app/shared/shared.service";
import { CreateAgent } from "../create-agent/create-agent.service";
import { SocketService } from "src/app/socket.service";
import { TicketsService } from "../tickets/tickets.service";
import { ProjectchangeService } from "src/app/layout/project-change/projectchange.service";
import { ErrorHandlerService } from "src/app/error-handler.service";

@Component({
  selector: "app-contacts",
  templateUrl: "./contacts.component.html",
  styleUrls: ["./contacts.component.css"],
})
export class ContactsComponent implements OnInit, OnDestroy {
  totalAgents: any[] = [];
  filterText: any;
  userData: any;
  companySubscription: Subscription;
  superAdmin: Boolean = false;
  public page: any = 1;
  public pageSize: any = 10;
  public profilePhotos: any[];
  private projectId: any;
  public currentId: string = null;
  public order: boolean = false;
  public orderFeild: string = "UpdatedDate";
  public orderString: boolean | "asc" | "desc" = "desc";
  public admin: Boolean = false;
  public project: Subscription;

  options = {
    lastOnBottom: false,
    position: ['bottom', 'right'],
    timeOut: 20000,
    showProgressBar: true,
    pauseOnHover: true,
    clickToClose: false,
    maxLength: 10,
  };

  constructor(
    private agentService: CreateAgent,
    private sharedService: SharedService,
    private layoutService: LayoutService,
    private authService: AuthService,
    private socketService: SocketService,
    private modalService: NgbModal,
    public projectchangeService: ProjectchangeService,
    private handleError: ErrorHandlerService,
  ) { }

  ngOnDestroy() {
    if (this.project) {
      this.project.unsubscribe();
    }
    // this.socketService.disConnect()
  }

  ngOnInit() {
    this.userData = JSON.parse(localStorage.getItem("userData"));
    // this.project = this.sharedService.projectId.subscribe((data) => {
    //   if (data) {
    //     this.projectId = data;
    //     this.getAgents(data, this.userData.id);
    //   }
    // });
    setTimeout(() => {
      this.projectId = this.projectchangeService._projectId;
      this.getAgents(this.projectId, this.userData.id);
    }, 500);
    this.sharedService.totalAgents.subscribe((data) => {
      this.profilePhotos = data;
    });

    this.sharedService.pageEvent.emit({ pageName: "Agents" });

    this.socketService.connect();
    this.socketService.status.subscribe((data) => {
      if (this.projectId) {
        this.getAgents(this.projectId, this.userData.id);
      }
    });
  }
  getAgents(companyId, userId) {
    return this.agentService
      .getContacts(companyId, userId)
      .subscribe((data) => {

        this.totalAgents = data;
      }, (error) => {
        this.handleError.handleError(error)
      });
  }

  chatRequest(to) {
    return this.layoutService
      .agentChatStart(
        { to: to, from: this.userData.workerFriendlyName },
        this.projectId
      )
      .subscribe((data) => {
        this.layoutService.openChat.next(true);
      }, (error) => {
        this.handleError.handleError(error)
      });
  }
  callAgent(to) {
    this.layoutService.callAgent.next(`client:${to}`);
  }
  sortOrder(feildName) {
    this.orderFeild = feildName;
    this.order = !this.order;
    if (this.order) {
      this.orderString = "asc";
    } else {
      this.orderString = "desc";
    }
  }

  openMaps(latlng, address, id) {
    this.currentId = id;
    this.sharedService.saveAddress.next({
      latlng: latlng,
      address: address,
      types: "agent",
    });
    this.modalService.open(MapsComponent);
  }
  projectChange(data) {
    this.projectId = data;
    this.getAgents(data, this.userData.id);
  }
}
