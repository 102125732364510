import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'agentname'
})
export class AgentnamePipe implements PipeTransform {

  transform(value: string, args: any[]): any {
    if (!value) {
      return null;
    }
    if (args.find(data => data['value'] === value)) {
      let agentName = args.find(data => data['value'] === value);
      return agentName['name']

    } else {
      return value
    }
  }

}
