import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CustomService } from '../../custom.service';
import { FormSetting } from '../../layout.model';

@Component({
  selector: 'app-update-lead',
  templateUrl: './update-lead.component.html',
  styleUrls: ['./update-lead.component.css']
})
export class UpdateLeadComponent implements OnInit, OnChanges {
  updateForm: FormGroup;
  @Input() formSetting: FormSetting[];
  @Input() projectId: number;
  listFeed: any;
  constructor(private fb: FormBuilder,
    private customService: CustomService,) { }

  ngOnInit(): void {
    this.initUpdate();
    // this.setForm();
  }
  ngOnChanges() {
    if (this.projectId) {
      console.log(this.projectId)
      this.listFeedback(this.projectId);
    }
  }
  initUpdate() {
    this.updateForm = this.fb.group({
      FirstName: ["", [Validators.required]],
      LastName: ["", [Validators.required]],
      CountryCode: ["", [Validators.required]],
      PhoneNumber: ["", [Validators.required]],
      Email: ["", Validators.required],
      DOB: [""],
      EnrollmentYear: ["", [Validators.required]],
      EnrollmentTerm: ["", [Validators.required]],
      Address: [""],
      City: [""],
      State: [""],
      Zip: [""],
      Country: [""],
      LeadComment: [""],
      CallStatus: [""],
    })
  }

  get f() {
    return this.updateForm.controls;
  }

  updateLead() {

  }

  setForm() {
    let form = {};
    for (let i = 0; i < this.formSetting.length; i++) {
      form[this.formSetting[i]?.formControlName] = new FormControl(
        ["", this.formSetting[i].required ? [Validators.required] : []]
      );
    }
    this.updateForm = this.fb.group(form);
  }
  listFeedback(id) {
    this.customService.listFeedback(id).toPromise()
      .then((resp: any) => {
        this.listFeed = resp.feedback;
      })
      .catch(err => {

      })
  }
}
