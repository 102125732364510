import { InfomarketingComponent } from "./infomarketing/infomarketing.component";
import { AuthGuard } from "src/app/core/guards/auth.guard";
import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { CallmarketingComponent } from "./callmarketing/callmarketing.component";
import { SmsmarketingComponent } from "./smsmarketing/smsmarketing.component";
import { AutosmsComponent } from "./autosms/autosms.component";
import { LeadsDashboardComponent } from "./leads-dashboard/leads-dashboard.component";
import { AutoMailComponent } from "./auto-mail/auto-mail.component";
import { AgentCallsDashboardComponent } from "./agent-calls-dashboard/agent-calls-dashboard.component";
import { SalesDashboardComponent } from "./sales-dashboard/sales-dashboard.component";
import { EventcalenderComponent } from "./eventcalender/eventcalender.component";

const routes: Routes = [
  {
    path: "smsMarketing",
    component: SmsmarketingComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "callMarketing",
    component: CallmarketingComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "infoMarketing",
    component: InfomarketingComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "leadsDashboard",
    component: LeadsDashboardComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "leadsDashboard/:roomId",
    component: LeadsDashboardComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "leadsDashboard/type/:lead",
    component: LeadsDashboardComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "eventcalender",
    component: EventcalenderComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "autoSms",
    component: AutosmsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "autoemail",
    component: AutoMailComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "agentcallsdashboard",
    component: AgentCallsDashboardComponent,
  },
  {
    path: "salesdashboard",
    component: SalesDashboardComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class MarketingRoutingModule { }
