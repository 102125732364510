<div class="m-5 pb-5">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="db-bg-block d-flex align-items-center">
          <div class="p-5 ml-5">
            <img src="../../../assets/images/db-tick.png" style="width:150px">
          </div>
          <div class="p-5 ml-auto text-center mr-5">
            <div class="db-ttext p-3">Congratulations</div>
            <div class="db-mtext p-2">{{companyInfo.companyName}}</div>
            <div class="db-btext">Your company created successfully.</div>
          </div>
        </div>
      </div>

    </div>
    <!-- <div class="row">
          <div class="col-md-12">
              <div class="card-homepage pt-3 text-center">
                  <div class="header-lg2">Congratulations {{companyInfo.companyName}}</div>
                  <div>Your company created successfully.</div>
                  <div class="pt-3 ">
                      <img src="../../../assets/images/company-created-infographic.png">
                  </div>
              </div>
          </div>
      </div> -->
    <div class="row">
      <div class="col-md-4">
        <div class="card-homepage p-4 mt-4">
          <div class="header-16 mb-4">Profile Progress</div>
          <hr>
          <div class="header-12"></div>
          <div class="py-3 text-center">
            <img src="../../../assets/images/company-created-profile.png">
          </div>
        </div>
      </div>
      <div class="col-md-8">
        <div class="card-homepage p-4 mt-4" style="height:28wpx;">
          <div class="header-16 mb-4">Documents Pending</div>
          <div class="pb-3 card-table">
            <table class="table table-md" style="border-radius: 0;">
              <tr>
                <th style="width:60%;padding-left:0">Documents</th>
                <th style="width:20%;padding-left:0">Status</th>
                <th class="text-center" style="width:20%">Actions</th>
              </tr>

              <tr>
                <td class="vm" style="padding-left:0">
                  <!--
                                <img src="../../../assets/img/missing-docs.svg" class="img12 mr-2" style="margin-top:-3px" />-->
                  <div class="d-flex align-items-center">
                    <div>
                      <img src="../../../assets/images/doc-g.svg" class="img12 mr-2">
                    </div>
                    <div>
                      <a *ngIf="companyInfo.companyRegistDocuments && companyInfo.companyRegistDocuments!='error'"
                        href={{companyInfo.companyRegistDocuments}} download="Registration Document">Company
                        Registration</a>
                      <span
                        *ngIf="!companyInfo.companyRegistDocuments || companyInfo.companyRegistDocuments=='error'">Company
                        Registration</span>
                    </div>
                  </div>
                </td>
                <td style="padding-left:0">
                  <div *ngIf="!companyInfo.companyRegistDocuments || companyInfo.companyRegistDocuments=='error'"
                    class="status-pending">Missing</div>
                  <div *ngIf="companyInfo.companyRegistDocuments && companyInfo.companyRegistDocuments!='error'"
                    class="status-completed">Uploaded</div>
                </td>
                <td class="text-center">
                  <button *ngIf="!companyInfo.companyRegistDocuments || companyInfo.companyRegistDocuments=='error'"
                    type="button" class="btn btn-light btn-sm mr-2" (click)="filesInput1.click()" ngbTooltip="Upload"
                    placement="top" tooltipClass="my-custom-class">
                    <input type="file" #filesInput1 id="filesInput1"
                      (change)="getFileDetails($event, 'companyRegistDocuments')" style="display: none" />
                    <img src="../../../assets/img/file-upload.svg" class="img12" />
                  </button>
                  <button *ngIf="companyInfo.companyRegistDocuments && companyInfo.companyRegistDocuments!='error'"
                    type="button" class="btn btn-light btn-sm mr-2"
                    (click)="openPreview(pdfContent, companyInfo.companyRegistDocuments)"
                    [disabled]="!companyInfo.companyRegistDocuments || companyInfo.companyRegistDocuments=='error'">
                    <img src="./assets/img/view2.svg" style="width: 16px" />
                  </button>
                </td>
              </tr>

              <tr>
                <td style="padding-left:0">
                  <div class="d-flex align-items-center">
                    <div>
                      <img src="../../../assets/images/doc-g.svg" class="img12 mr-2">
                    </div>
                    <div>
                      <a *ngIf="companyInfo.companyPanDocument && companyInfo.companyPanDocument!='error'"
                        href={{companyInfo.companyPanDocument}} download="Registration Document">Company PAN</a>
                      <span *ngIf="!companyInfo.companyPanDocument || companyInfo.companyPanDocument=='error'">Company
                        PAN</span>
                    </div>
                  </div>
                </td>
                <td style="padding-left:0">
                  <div *ngIf="!companyInfo.companyPanDocument || companyInfo.companyPanDocument=='error'"
                    class="status-pending">Missing</div>
                  <div *ngIf="companyInfo.companyPanDocument && companyInfo.companyPanDocument!='error'"
                    class="status-completed">Uploaded</div>
                </td>
                <td class="text-center">
                  <button *ngIf="!companyInfo.companyPanDocument || companyInfo.companyPanDocument=='error'"
                    type="button" class="btn btn-light btn-sm mr-2" (click)="filesInput1.click()" ngbTooltip="Upload"
                    placement="top" tooltipClass="my-custom-class">
                    <input type="file" #filesInput1 id="filesInput1"
                      (change)="getFileDetails($event, 'companyPanDocument')" style="display: none" />
                    <img src="../../../assets/img/file-upload.svg" class="img12" />
                  </button>
                  <button *ngIf="companyInfo.companyPanDocument && companyInfo.companyPanDocument!='error'"
                    type="button" class="btn btn-light btn-sm mr-2"
                    (click)="openPreview(pdfContent, companyInfo.companyPanDocument)"
                    [disabled]="!companyInfo.companyPanDocument || companyInfo.companyPanDocument=='error'">
                    <img src="./assets/img/view2.svg" style="width: 16px" />
                  </button>
                </td>
              </tr>

              <tr>
                <td style="padding-left:0">
                  <div class="d-flex align-items-center">
                    <div>
                      <img src="../../../assets/images/doc-g.svg" class="img12 mr-2">
                    </div>
                    <div>
                      <a *ngIf="companyInfo.companyTanDocument && companyInfo.companyTanDocument!='error'"
                        href={{companyInfo.companyTanDocument}} download="Registration Document">Company TAN</a>
                      <span *ngIf="!companyInfo.companyTanDocument || companyInfo.companyTanDocument=='error'">Company
                        TAN</span>
                    </div>
                  </div>
                </td>
                <td style="padding-left:0">
                  <div *ngIf="!companyInfo.companyTanDocument || companyInfo.companyTanDocument=='error'"
                    class="status-pending">Missing</div>
                  <div *ngIf="companyInfo.companyTanDocument && companyInfo.companyTanDocument!='error'"
                    class="status-completed">Uploaded</div>
                </td>
                <td class="text-center">
                  <button *ngIf="!companyInfo.companyTanDocument || companyInfo.companyTanDocument=='error'"
                    type="button" class="btn btn-light btn-sm mr-2" (click)="filesInput1.click()" ngbTooltip="Upload"
                    placement="top" tooltipClass="my-custom-class">
                    <input type="file" #filesInput1 id="filesInput1"
                      (change)="getFileDetails($event, 'companyTanDocument')" style="display: none" />
                    <img src="../../../assets/img/file-upload.svg" class="img12" />
                  </button>
                  <button *ngIf="companyInfo.companyTanDocument && companyInfo.companyTanDocument!='error'"
                    type="button" class="btn btn-light btn-sm mr-2"
                    (click)="openPreview(pdfContent, companyInfo.companyTanDocument)"
                    [disabled]="!companyInfo.companyTanDocument || companyInfo.companyTanDocument=='error'">
                    <img src="./assets/img/view2.svg" style="width: 16px" />
                  </button>
                </td>
              </tr>

              <!-- <tr>
                              <td><img src="../../../assets/img/missing-docs.svg" class="img12 mr-2" style="margin-top:-3px" />Company PAN</td>
                              <td><div class="status-pending">Missing</div></td>
                              <td class="text-center"> <img src="../../../assets/img/file-upload.svg" class="img12" /></td>
                          </tr> -->
              <!-- <tr>
                            <td><img src="../../../assets/img/missing-docs.svg" class="img12 mr-2" style="margin-top:-3px" />Company TAN</td>
                            <td><div class="status-pending">Missing</div></td>
                            <td class="text-center"> <img src="../../../assets/img/file-upload.svg" class="img12" /></td>
                        </tr> -->
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="card-homepage p-4 mt-4" style="height:360px;">
          <div class="header-16 mb-4">Company Details</div>
          <hr>
          <div class="row mt-3">
            <div class="col-md-7">
              <div class="header-18 lh170">{{companyInfo.companyName}}</div>

              <div class="row mt-2">
                <div class="col-md-12" style="cursor: pointer;" (click)="openAgentManagement()">
                  <div class="mid-text mb-2 pt-2"><img src="../../../assets/images/people-g.svg"
                      class="img28 mr-3">{{companyInfo.totalRemainUsers}} / {{companyInfo.totalUsers}} Users</div>
                </div>
              </div>
              <div class="row mt-4">
                <div class="col-md-12 d-flex">
                  <div class="mr-3"><img src="../../../assets/images/calendar-g.svg" class="img28"></div>
                  <div class="mr-3" style="width:150px">
                    <div class="label-grey pb-2">Start Date</div>
                    <div class="mid-text" style="width:150px">{{companyInfo.subscriptionStart | date:'dd MMM, yyyy'}}
                    </div>
                  </div>
                  <div class="mr-3" style="width:150px">
                    <div class="label-grey pb-2">End Date</div>
                    <div class="mid-text" style="width:150px">{{companyInfo.subscriptionEnd | date:'dd MMM, yyyy'}}
                    </div>
                  </div>
                </div>

                <!-- <div class="col-md-1 pt-3 pb-1 header-12">
                                  <img src="../../../assets/images/calendar-g.svg" class="img28 mr-3"> 
                              </div>
                              <div class="col-md-5 col-sm-6 py-2">
                                  <div class="label-grey pb-2" style="width:250px">Start Date</div>
                                  <div class="mid-text" style="width:250px">{{companyInfo.subscriptionStart | date:'dd MMM, yyyy'}}
                                  </div>
                              </div>
                              <div class="col-md-5 col-sm-5 py-2">
                                  <div class="label-grey pb-2" style="width:250px" >End Date</div>
                                  <div class="mid-text px-1" style="width:250px">{{companyInfo.subscriptionEnd | date:'dd MMM, yyyy'}}</div>
                              </div> -->
              </div>
            </div>
            <div class="col-md-5 text-right">
              <!-- <img src={{companyInfo.companyLogo}} height="40px"/> -->
            </div>
          </div>

          <div class="row mt-4">
            <div class="col-md-12 py-2 d-flex">
              <div>
                <img src="../../../assets/images/call-g.svg" class="img28 mr-3">
              </div>
              <div>
                <div class="mid-text pb-2">+{{companyInfo.primaryContCountryCode }} {{companyInfo.primaryContact}}</div>
                <div class="mid-text">+{{companyInfo.secondaryContCountryCode }} {{companyInfo.secondaryContact}}</div>
              </div>
            </div>
          </div>
          <div class="row mt-4">
            <div class="col-md-12 pb-2 d-flex">
              <div>
                <img src="../../../assets/images/mail-g.svg" class="img28 mr-3">
              </div>
              <div>
                <div class="mid-text pb-2">{{companyInfo.primaryEmail}}</div>
                <div class="mid-text pb-2">{{companyInfo.secondaryEmail}}</div>
              </div>
            </div>
          </div>

          <!-- <div class="row mt-4">
                      <div class="col-md-12 py-2 d-flex">
                        <div>
                            <img src="../../../assets/img/location-b.svg" class="img20 mr-3">
                        </div>
                        <div>
                           <p class="mid-text">{{companyInfo.companyFlatNumber_BuildingNo}}, {{companyInfo.fullAddress}}<br>
                              {{companyInfo.city}}, {{companyInfo.country}}, {{companyInfo.pin_zip_code}}</p>
                        </div>
                      </div>
                       
                  </div> -->

        </div>
      </div>
      <div class="col-md-6">
        <div class="card-homepage p-4 mt-4" style="height:360px;">
          <div class="d-flex mb-4">
            <div class="header-16">Projects</div>
            <!-- <div class="ml-auto">
                  <button class="btn btn-link btn-sm mr-2 p-0 m-0" ngbTooltip="Download Sample" placement="top"  
                    (click)="downloadTemplate()" tooltipClass="my-custom-class">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 22 22">
                        <g id="Group_776" data-name="Group 776" transform="translate(-215.728 -61.66)">
                          <path id="Path_888" class="csvg" data-name="Path 888" d="M234.853,86.66H221.1a1.375,1.375,0,0,1-1.375-1.375v-8.25A1.375,1.375,0,0,1,221.1,75.66h2.063v1.375H221.1v8.25h13.75v-8.25h-2.063V75.66h2.063a1.375,1.375,0,0,1,1.375,1.375v8.25A1.376,1.376,0,0,1,234.853,86.66Z" transform="translate(-1.25 -4.375)" fill="#b02022"/>
                          <rect id="Rectangle_902"  class="csvg" data-name="Rectangle 902" width="4" height="1" transform="translate(224.728 75.66)" fill="#b02022"/>
                          <path id="Path_889"  class="csvg" data-name="Path 889" d="M233.008,68.19l-2.468,2.461V63.66h-1.375v6.992L226.7,68.19l-.969.969,4.125,4.125,4.125-4.125Z" transform="translate(-3.125 -0.625)" fill="#b02022"/>
                          <rect id="_Transparent_Rectangle_" data-name=" Transparent Rectangle " width="22" height="22" transform="translate(215.728 61.66)" fill="none"/>
                        </g>
                      </svg>
                    </button>
                </div> -->
          </div>
          <div class="card-table" style="width:100%;height:300px;overflow: auto;">
            <table class="table table-md" style="border-radius: 0;">
              <tr>
                <th style="width:60%;padding-left: 0;" nowrap>Project</th>
                <th style="width:20%" nowrap>Created Date</th>
                <th class="text-left" style="width:20%" nowrap>Actions</th>
              </tr>
              <tr *ngFor="let i of projectsList">
                <td nowrap style="padding-left: 0;">{{i.projectName}}</td>
                <td nowrap class="label-grey">{{i.createdAt | date:'medium'}}</td>
                <td class="text-center d-flex align-items-center justify-content-center" nowrap>
                  <a class="btn btn-light btn-sm mr-2" routerLink="{{i.dashboardUrl}}"
                    [routerLinkActive]="['sub_links']" ngbTooltip="View" placement="top" tooltipClass="my-custom-class">
                    <img src="../../../assets/img/view2.svg" class="img12" />
                  </a>
                  <a class="btn btn-light btn-sm" data-toggle="modal" data-target="#exampleModal3"
                    (click)="inviteAgentModal(inviteLeadsModal, i.projectId)" ngbTooltip="Invite" placement="top"
                    tooltipClass="my-custom-class">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 32 32">
                      <g id="Group_860" data-name="Group 860" transform="translate(-783.465 -58.66)">
                        <path id="Path_965" data-name="Path 965"
                          d="M795.465,62.66a5,5,0,1,1-5,5,5,5,0,0,1,5-5m0-2a7,7,0,1,0,7,7A7,7,0,0,0,795.465,60.66Z"
                          class="gsvg" />
                        <path id="Path_966" data-name="Path 966"
                          d="M805.465,88.66h-2v-5a5,5,0,0,0-5-5h-6a5,5,0,0,0-5,5v5h-2v-5a7,7,0,0,1,7-7h6a7,7,0,0,1,7,7Z"
                          class="gsvg" />
                        <path id="Path_967" data-name="Path 967"
                          d="M808.465,74.84l-2.59-2.59-1.41,1.41,4,4,7-7-1.41-1.41Z" class="gsvg" />
                        <rect id="_Transparent_Rectangle_" data-name=" Transparent Rectangle " width="32" height="32"
                          transform="translate(783.465 58.66)" fill="none" />
                      </g>
                    </svg>
                  </a>
                </td>
              </tr>
            </table>

            <!-- <div class=" d-flex flex-column text-center p-3 mt-2">
                          <div class="p-2">Create new project</div>
                          <div>
                          <button class="btn btn-primary btm-sm">Contact Us</button>
                          </div>
                      </div> -->


          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template id="pdfContent" class="modal-x" #pdfContent let-modal>
  <div id="progressBar">
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </div>
  <ngx-doc-viewer [url]="docUrl" viewer="google" googleCheckContentLoaded="true" googleCheckInterval="5000"
    googleMaxChecks="15" style="width: 100%; height: 93vh" (loaded)="contentLoaded()">
  </ngx-doc-viewer>
</ng-template>

<ng-template #inviteLeadsModal let-modal>
  <div class="d-flex mat-modal-header p-4" style="width:100%;background: #FBFBFD;">
    <div class="modal-title component-title mr-auto">Invite User</div>
    <div class="ml-auto close hand" (click)="modal.dismiss('Cross click')">
      <img src="../../../../assets/img/close.svg" class="img10" />
    </div>
  </div>

  <div class="d-flex flex-column">
    <div class="p-4 text-center">
      <img src="../../../assets/images/invite-throgh-mail.svg">
    </div>
    <div class="p-4 text-center">
      <button *ngIf="!companyInfo.companyTanDocument || companyInfo.companyTanDocument=='error'" type="button"
        class="btn btn-light btn-md mr-2 p-2" (click)="filesInput1.click()" style="width:100%">
        <input type="file" #filesInput1 id="filesInput1" (change)="incomingfile($event)" accept=".xlsx"
          style="display: none" />
        Choose file
      </button>

      <!-- <input type="file" style="display: inline-block;" (change)="incomingfile($event)" placeholder="Upload file" accept=".xlsx"> -->
    </div>
    <div class="p-2 mb-4 text-center">
      <button type="button" class="btn btn-primary btn-lg" (click)="Upload()">Invite</button>
    </div>
  </div>


  <!-- <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-body" style="height: none;">
          <div class="px-2">
            <input type="file" style="display: inline-block;" (change)="incomingfile($event)" placeholder="Upload file" accept=".xlsx">
            <button type="button" class="btn btn-info" (click)="Upload()" >Invite</button>           
          </div>
        </div>
      </div>
    </div> -->
</ng-template>