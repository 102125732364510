import {
  Component,
  OnInit,
  ViewChild,
  Inject,
  ElementRef,
} from "@angular/core";
import { FormControl, ReactiveFormsModule } from "@angular/forms";
import { SharedService } from "src/app/shared/shared.service";
import { ReferralService } from "./referral.service";
import { UhLeadsService } from "../uhleads.service";
import { MomentFormatService } from "src/app/moment-format-service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";

import * as xlsx from "xlsx";
import * as moment from "moment";
import { DOCUMENT } from "@angular/common";

import { ReferralData } from "../marketing/smsmarketing/models/ReferralData.model";
import {
  countUpTimerConfigModel,
  CountupTimerService,
  timerTexts,
} from "ngx-timer";
import { now } from "lodash";
import { Data, NavigationEnd, Router } from "@angular/router";
import { RoleService } from "../role-management/role.service";
import { ErrorHandlerService } from "src/app/error-handler.service";
import { environment } from "src/environments/environment";
var sortJsonArray = require("node_modules/sort-json-array");
declare const gtag: Function;
import { fromEvent } from "rxjs";
import {
  filter,
  debounceTime,
  distinctUntilChanged,
  tap,
} from "rxjs/operators";

@Component({
  selector: "app-referral",
  templateUrl: "./referral.component.html",
  styleUrls: ["./referral.component.css"],
})
export class ReferralComponent implements OnInit {
  userData: any;
  projectId: any;
  admin: any;
  public error: boolean = false;
  public errorMessage: string = "";
  public filePath: any;
  public fileData: ReferralData[] = [];
  referralList: any[] = [];
  universityList: any[];
  public page: any = 1;
  public pageSize: any = 12;
  public collectionSize: any = 1;
  dialerScreen1 = "dialer";
  callStatus: string = "Calling..";
  newStatus: any;
  applicationStatus: any = "";
  public callData: any[] = [];
  public startIndex: number = 0;
  selectedLead: any;
  startCall: boolean = false;
  public stopCall: boolean = false;
  statusValue = "all";
  public clearTime: any;
  sideViewerData: any = { ReferralActivities: [], CallDetails: [] };
  currentLeadId: any;
  activityfilterString: string = "";
  minItems = 5;
  commentText: string = "";
  selectedAll: boolean = false;
  leadIds = [];
  defaultAgent = "default";
  selectedAgent: any;
  agentList: any;
  public filterValue = "all";
  showDate: boolean = false;
  endDate: any;
  startDate: any;
  date1: any;
  date2: any;
  agentCall: String = "";
  testConfig: any;
  leadTypereferee: string = "total";
  editStatus: string;
  otherUniversity = "other";
  public edited: boolean = false;
  nextCallerName: any;
  nextCallerCity: any;
  nextCallerNumber: any;
  selectedUniversity: string;
  customUniversityName: string = "";
  customUniversityName2: string = "";
  public intakeListArray: any = [];
  requiredYear: boolean = false;

  @ViewChild("closeButton2") closeButton2;
  @ViewChild("closeAssignModel") closeAssignModel;
  @ViewChild("inputString", { static: true }) input: ElementRef;

  public marketingId: {
    id: string;
    status: string;
    phoneNumber: string;
    email: string;
    name: string;
    module: string;
    city: string;
    nextCaller: any;
    lead: any;
  } = {
      id: "",
      status: "",
      name: "",
      email: "",
      phoneNumber: "",
      module: "",
      city: "",
      nextCaller: null,
      lead: {},
    };

  leadtoEdit: {
    followupDate: Data;
    LeadId: string;
    FirstName: string;
    LastName: string;
    CountryCode: string;
    PhoneNumber: string;
    Email: string;
    University: any;
    CallStatus: string;
    AgentMail: string;
    DIyear?: string;
    DIterms?: string;
  };

  topBarCountReferral: {
    total: number;
    applicationSubmitted: number;
    unassigned: number;
    assigned: number;
    assignedtoMe: number;
    international: number;
    USTransfer: number;
    applicationnotsubmitted: number;
    enrolled: number;
    changeuniversity: number;
  } = {
      total: 0,
      applicationSubmitted: 0,
      unassigned: 0,
      assigned: 0,
      assignedtoMe: 0,
      international: 0,
      USTransfer: 0,
      applicationnotsubmitted: 0,
      enrolled: 0,
      changeuniversity: 0,
    };

  @ViewChild("uploadFile") uploadFile;
  allLeads: any;
  filterString: string;
  leadsLoading: boolean = false;
  noLeadsFound: boolean = false;
  tab!: string;
  downloadLink: any;
  referralList1: any;

  currentCard: string = "total";
  sortColumn = "createdAt";
  sortOrder = "des";
  DIyear: any = null;
  DIterms: any = null;
  showdeferredintake: Boolean = false;
  DIyearArray: any = [];

  smsText: string;
  smsList: [] = [];
  projectLists: any;
  callSid: any;
  cardNumber: any = 1;
  submitting: boolean = false;

  constructor(
    private SharedService: SharedService,
    public ReferralService: ReferralService,
    public countupTimerService: CountupTimerService,
    private uhleadsservice: UhLeadsService,
    private readonly modalService: NgbModal,
    private momentService: MomentFormatService,
    private handleError: ErrorHandlerService,
    private companyService: RoleService,
    private router: Router,
    @Inject(DOCUMENT) private domService: HTMLDocument
  ) {
    this.testConfig = new countUpTimerConfigModel();
    this.testConfig.timerClass = "test_Timer_class";
    this.testConfig.timerTexts = new timerTexts();
    this.testConfig.timerTexts.hourText = ":"; //default - hh
    this.testConfig.timerTexts.minuteText = ":"; //default - mm
    this.testConfig.timerTexts.secondsText = " ";
  }

  ngOnInit() {
    this.SharedService.pageEvent.emit({
      pageName: "Referral",
    });
    var x = document.getElementsByClassName(
      "sideDataViewer"
    ) as HTMLCollectionOf<HTMLElement>;
    for (let i = 0; i < x.length; i++) {
      x[i].style.display = "none";
    }

    this.date1 = new FormControl(new Date());
    this.date2 = new FormControl(new Date(Date.now() - 864e5));
    this.startDate = this.momentService.dashboardDateFormat(
      new Date(new Date(Date.now() - 864e5 * 365)).getTime()
    );
    this.endDate = this.momentService.dashboardDateFormat(
      new Date(new Date(Date.now() + 864e5))
    );

    this.userData = JSON.parse(localStorage.getItem("userData"));
    // this.SharedService.projectId.subscribe((data: any) => {
    this.companyService.projectAdmin(this.userData["companyId"]).subscribe(
      (projects) => {
        this.projectId = projects.filter((x) => x.Name == "Referrals")[0].id;
        // this.projectId = data;
        if (this.projectId) {
          this.SharedService.getProjectAdmin(this.projectId).subscribe(
            (data: any) => {
              this.admin = data;
              this.getListofUniversities();
              this.getTopBarCount();
              this.getLeadsByCards(this.leadTypereferee);
              // this.getLead();

              this.uhleadsservice
                .getAgentList(this.projectId, this.userData.id)
                .subscribe(
                  (data: any) => {
                    this.agentList = data;
                  },
                  (error) => {
                    this.handleError.handleError(error);
                  }
                );
            },
            (error) => {
              this.handleError.handleError(error);
            }
          );
        }
      },
      (error) => {
        this.handleError.handleError(error);
      }
    );

    this.ReferralService.startTimer.subscribe((res) => {
      this.callStatus = "inProgress";
      this.startTimer();
    });
    this.ReferralService.getCallSid.subscribe((data) => {
      this.callSid = data;
    });
    //incoming sms check
    this.uhleadsservice.incomingSms.subscribe((from: any) => {
      // 
      if (from.from) {
        this.ReferralService.getSms(this.currentLeadId).subscribe(
          (smsList: any) => {
            // 
          }
        );
      }
    });

    let d = new Date();
    this.DIyearArray.push(d.getFullYear());
    let year = d.getFullYear();
    for (let i = 0; i < 4; i++) {
      year = year + 1;
      this.DIyearArray.push(year);
    }
    // server-side search
    fromEvent(this.input.nativeElement, "keyup")
      .pipe(
        filter(Boolean),
        debounceTime(1500),
        distinctUntilChanged(),
        tap((event: KeyboardEvent) => {
          this.filterString = this.input.nativeElement.value;
          this.page = 1;
          this.searchLeads();
        })
      )
      .subscribe();
    this.ReferralService.callStatus.subscribe((status) => {
      this.callStatus = status;
    });
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.onSelectedUniversity(8);
    }, 2000);
  }

  onSelectedUniversity(Data: any) {
    let name = this.universityList.filter((i) => i.id == Data)[0]?.university;
    if (name) {
      if (
        name.includes("University of the Cumberlands") ||
        name.includes("Alderson Broaddus University") ||
        name.includes("Campbellsville University")
      ) {
        this.intakeListArray = [
          "Spring-1",
          "Spring-2",
          "Summer-1",
          "Summer-2",
          "Fall-1",
          "Fall-2",
        ];
      } else if (
        name.includes("Judson University") ||
        name.includes("Faulkner University")
      ) {
        this.intakeListArray = ["Spring", "Summer", "Fall"];
      } else if (name.includes("St Francis College")) {
        this.intakeListArray = [
          "Spring-1",
          "Spring-2",
          "Summer",
          "Fall-1",
          "Fall-2",
        ];
      } else {
        this.intakeListArray = [
          "Spring",
          "Spring-1",
          "Spring-2",
          "Summer",
          "Summer-1",
          "Summer-2",
          "Fall",
          "Fall-1",
          "Fall-2",
        ];
      }
    }
    else {
      this.intakeListArray = [
        "Spring",
        "Spring-1",
        "Spring-2",
        "Summer",
        "Summer-1",
        "Summer-2",
        "Fall",
        "Fall-1",
        "Fall-2",
      ];
    }

  }

  startTimer() {
    let cdate = new Date();
    cdate.setHours(cdate.getHours());
    this.countupTimerService.startTimer(cdate);
  }

  //Shows date if the filter value is custom
  hideDate() {
    // this.filterValuev
    if (this.filterValue == "custom" && this.showDate == false) {
      this.showDate = true;
    }
  }

  // dropdownSelect(event) {
  //   // this.onClick(null);
  //   // this.getData(this.userData, event, this.startDate, this.endDate);
  //   // this.getCardData(this.userData, event, this.startDate, this.endDate);
  //   this.getReferralList(this.admin,this.projectId, this.userData.mail, event, this.startDate, this.endDate);
  // }

  updateDateFilter(event: any) {
    if (event.target.value != "custom") {
      this.endDate = this.momentService.dashboardDateFormat(
        new Date(new Date(Date.now())).getTime()
      );
    }
    if (event.target.value == "custom") {
      //show custom date filter
      this.showDate = true;
    } else if (event.target.value == "all") {
      this.startDate = this.momentService.dashboardDateFormat(
        new Date(new Date(Date.now() - 864e5 * 365)).getTime()
      );

      this.showDate = false;
    } else if (event.target.value == "24hrs") {
      this.startDate = this.momentService.dashboardDateFormat(
        new Date(new Date(Date.now() - 864e5)).getTime()
      );

      this.showDate = false;
    } else if (event.target.value == "72hrs") {
      this.startDate = this.momentService.dashboardDateFormat(
        new Date(new Date(Date.now() - 864e5 * 3)).getTime()
      );

      this.showDate = false;
    } else if (event.target.value == "7days") {
      this.startDate = this.momentService.dashboardDateFormat(
        new Date(new Date(Date.now() - 864e5 * 7)).getTime()
      );

      this.showDate = false;
    } else if (event.target.value == "14days") {
      this.startDate = this.momentService.dashboardDateFormat(
        new Date(new Date(Date.now() - 864e5 * 14)).getTime()
      );

      this.showDate = false;
    } else if (event.target.value == "30days") {
      this.startDate = this.momentService.dashboardDateFormat(
        new Date(new Date(Date.now() - 864e5 * 30)).getTime()
      );

      this.showDate = false;
    }
    this.getReferralList(
      this.admin,
      this.projectId,
      this.userData.mail,
      "all",
      this.startDate,
      this.endDate
    );

    this.getTopBarCount();
  }

  getStartDate(type: string, event: MatDatepickerInputEvent<Date>) {
    this.startDate = event.value;
    this.startDate = this.momentService.dashboardDateFormat(
      new Date(this.startDate).getTime
    );
  }
  getEndDate(type: string, event: MatDatepickerInputEvent<Date>) {
    this.endDate = event.value;
    this.endDate = this.momentService.dashboardDateFormat(
      new Date(this.endDate).getTime
    );
  }

  filterData() {
    // this.onClick(null);
    this.startDate = this.momentService.dashboardDateFormat(
      new Date(this.date2.value).getTime()
    );
    this.endDate = this.momentService.dashboardDateFormat(
      new Date(this.date1.value).getTime()
    );

    if (this.endDate < this.startDate) {
      // window.alert("Choose the right date format!");
      this.uhleadsservice.showWarning(
        "End date cannot be less than Start date!",
        "Wrong Date!"
      );
      return;
    } else {
      this.getReferralList(
        this.admin,
        this.projectId,
        this.userData.mail,
        "all",
        this.startDate,
        this.endDate
      );
    }
  }

  getListofUniversities() {
    this.ReferralService.getUniversityList().subscribe(
      (res: any) => {
        this.universityList = res.universities;
        this.onSelectedUniversity(8);
      },
      (error) => {
        this.handleError.handleError(error);
      }
    );
  }

  getReferralList(admin, projectId, mail, leadType, start, end) {
    let payload = {
      admin: admin,
      mail: mail,
      ProjectId: projectId,
      leadType: leadType,
      start: start,
      end: end,
      page: this.page
    };
    this.ReferralService.getReferralList(payload).subscribe(
      (res: any) => {
        this.referralList = res;
        this.referralList.forEach(function (a) {
          Object.keys(a).map((key) => {
            a[key] === null ? (a[key] = "-") : a[key];
            a[key] === "" ? (a[key] = "-") : a[key];
          });
        });
      },
      (error) => {
        this.handleError.handleError(error);
      }
    );

    this.getTopBarCount();
    this.getLeadsByCards(this.leadTypereferee);
  }

  getLead() {
    this.ReferralService.getReferralLead(2).subscribe(
      (res: any) => { },
      (error) => {
        this.handleError.handleError(error);
      }
    );
  }

  sideNavData(data) {
    this.smsList = [];
    var x = document.getElementsByClassName(
      "sideDataViewer"
    ) as HTMLCollectionOf<HTMLElement>;
    //  x.style.display = none;
    for (let i = 0; i < x.length; i++) {
      x[i].style.display = "";
    }
    this.ReferralService.getReferralLead(data.id).subscribe(
      (res: any) => {
        this.ReferralService.getSms(data.id).subscribe((smsList: any) => {
          // 
          this.smsList = smsList.SMSDetails;
        });
        this.sideViewerData = res;
        this.replaceNull(this.sideViewerData);
        this.currentLeadId = this.sideViewerData.id;
      },
      (error) => {
        this.handleError.handleError(error);
      }
    );
  }

  sendSms(item, HTMLid) {
    this.smsText = (<HTMLInputElement>document.getElementById(HTMLid)).value;
    let fromNumber = "";
    this.companyService.projectAdmin(environment.companyId).subscribe((res: any) => {
      this.projectLists = res;
      // 
      for (let i = 0; i < res.length; i++) {
        if (res[i].Name == "Referrals") {
          fromNumber = res[i].TwilioNumber;
        }
      }
      let payload = {
        text: this.smsText,
        from: fromNumber,
        to: "+" + item.CountryCode + item.PhoneNumber,
        AgentMail: this.userData.mail,
      };
      // 
      this.ReferralService.sendSms(payload, this.projectId).subscribe(
        (res: any) => {
          this.uhleadsservice.showInfo("Message successfully sent.", "Sent");
          this.ReferralService.getSms(this.currentLeadId).subscribe(
            (smsList: any) => {
              this.smsList = smsList.SMSDetails;
              (<HTMLInputElement>document.getElementById(HTMLid)).value = "";
            }
          );
        }
      );
    });
  }

  replaceNull(a: any) {
    Object.keys(a).map((key) => {
      a[key] === null ? (a[key] = "-") : a[key];
      a[key] === "" ? (a[key] = "-") : a[key];
    });
  }

  onInputActivity(event: any) {
    if (this.activityfilterString != "") {
      this.minItems = event;
    } else {
      this.minItems = 5;
    }
  }

  showmore(itemsCount) {
    if (itemsCount < this.minItems) {
    } else if (this.minItems + 5 < itemsCount) {
      this.minItems = this.minItems + 5;
    } else if (this.minItems < itemsCount) {
      this.minItems = itemsCount;
    }
  }

  audioPlay(e) {
    let eAudio = this.domService.getElementsByTagName("audio");
    if (eAudio && eAudio.length > 0) {
      for (var i = 0; i < eAudio.length; i++) {
        if (e.target !== eAudio[i]) {
          eAudio[i].pause();
        }
      }
    }
  }

  convertFile(e) {
    this.filePath = e.target.files[0]["name"].toString();
    let reader = new FileReader();
    reader.readAsArrayBuffer(e.target.files[0]);
    reader.onload = (eve) => {
      let data = new Uint8Array(reader.result as any);
      let wb = xlsx.read(data, { type: "array" });
      let sheet_name = wb.SheetNames;
      let sheet_data: any[][] = xlsx.utils.sheet_to_json(
        wb.Sheets[sheet_name[0]],
        { header: 1 }
      );
      if (sheet_data[0][0] === "GUID" && sheet_data.length !== 0) {
        this.fileData = this.ReferralService.convert2dArraytoObject(sheet_data);
      } else {
        this.error = true;
        this.errorMessage = "Please Upload a Valid File";
      }
    };
    this.uploadFile.nativeElement.value = "";
  }

  startCallListener() {
    this.uhleadsservice.startCall.next({
      phoneNumber: this.marketingId.phoneNumber,
      userData: this.userData,
      leadData: this.marketingId,
    });
  }

  startCallListener2(data, index) {
    this.showdeferredintake = false;
    this.DIyear = null;
    this.DIterms = null;
    this.applicationStatus = "";
    this.dialerScreen1 = "outgoing1";
    this.callStatus = "Calling..";
    $("input[name=option1]").prop("checked", false);

    this.newStatus = false;

    this.callData[0] = data;
    this.callData[0].CountryCode = this.callData[0].CountryCode.replace(
      "+",
      ""
    );
    this.selectedLead = data;

    this.marketingId = {
      id: data.id,
      status: data.CallStatus,
      name: data.FirstName,
      email: data.Email,
      phoneNumber: "+" + data.CountryCode.replace("+", "") + data.PhoneNumber,
      module: data.Module,
      city: "",
      nextCaller: 1,
      lead: 0,
    };
    if (this.referralList.length > 1) {
      var nextFirstname = this.referralList[index + 1]?.FirstName
        ? this.referralList[index + 1]?.FirstName
        : "";
      var nextLastname = this.referralList[index + 1]?.LastName
        ? this.referralList[index + 1]?.LastName
        : "";
      var nextCallerCity = this.referralList[index + 1]?.City
        ? this.referralList[index + 1]?.City
        : "";
      var nextCallerCountryCode = this.referralList[index + 1]?.CountryCode
        ? this.referralList[index + 1]?.CountryCode.replace("+", "")
        : "";
      var nextCallerPhoneNumber = this.referralList[index + 1]?.PhoneNumber
        ? this.referralList[index + 1]?.PhoneNumber
        : "";

      this.nextCallerName = nextFirstname;
      this.nextCallerCity = nextCallerCity;
      this.nextCallerNumber = nextCallerCountryCode
        ? "+" + nextCallerCountryCode + nextCallerPhoneNumber
        : "";
      this.startIndex = +1;
    }
    this.ReferralService.startCall.next({
      phoneNumber: this.marketingId.phoneNumber,
      leadData: this.marketingId,
    });
    this.startCall = true;
  }

  stopCallListener() {

    this.ReferralService.startCall.next(null);
    this.ReferralService.stopCall.next({
      phoneNumber: this.marketingId.phoneNumber,
    });
    this.stopCall = true;
    this.startCall = false;
    // if (this.statusValue !== "pending") {
    //   this.startIndex += 1;
    // }
    this.startIndex += 1;
    clearTimeout(this.clearTime);
  }

  stopCurrentCall() {

    this.ReferralService.startCall.next(null);
    this.ReferralService.stopCall.next({
      phoneNumber: this.marketingId.phoneNumber,
    });
    this.callStatus = "Call Ended";
  }

  applicationstatus(data) {
    this.applicationStatus = data;
    if (this.applicationStatus == "Yes") {
    }
  }

  onStatusChange(data) {
    this.newStatus = data;
    if (this.newStatus == "deferredintake") {
      this.onSelectedUniversity(this.leadtoEdit.University);
      this.showdeferredintake = true;
    } else {
      this.showdeferredintake = false;
      this.DIyear = null;
      this.DIterms = null;
    }
  }

  onStatusChange2(data) {
    this.newStatus = data;
    let uniId = this.universityList.filter(x =>
      x.university == this.callData[0].University
    )[0]['id'];

    if (this.newStatus == "deferredintake") {
      this.onSelectedUniversity(uniId);
      this.showdeferredintake = true;
    } else {
      this.showdeferredintake = false;
      this.DIyear = null;
      this.DIterms = null;
    }
  }

  updateComments() {
    if (!this.newStatus) {
    } else {
      // this.ReferralService.getCallSid.subscribe((data) => {
      if (this.applicationStatus == "yes") {
        if (this.selectedUniversity == "other") {
          this.ReferralService.createCustomUniversity({
            ProjectId: this.projectId,
            UniversityName: this.customUniversityName,
          }).subscribe(
            (res: any) => {
              this.selectedUniversity = res.UniversityId;
              this.ReferralService.updateCallComments({
                id: this.selectedLead.id,
                comments: this.commentText,
                callStatus: this.newStatus,
                CallSid: this.callSid,
                followupDate: new Date(now()),
                ApplicationStatus: this.applicationStatus,
                University: this.selectedUniversity,
                diyear: this.DIyear,
                diterms: this.DIterms,
              }).subscribe(
                (res) => {
                  this.showdeferredintake = false;
                  this.DIyear = null;
                  this.DIterms = null;
                  this.commentText = "";
                  this.getReferralList(
                    this.admin,
                    this.projectId,
                    this.userData.mail,
                    "all",
                    this.startDate,
                    this.endDate
                  );
                  this.closeButton2.nativeElement.click();
                },
                (error) => {
                  this.handleError.handleError(error);
                }
              );
            },
            (error) => {
              this.handleError.handleError(error);
            }
          );
        } else {
          this.ReferralService.updateCallComments({
            id: this.selectedLead.id,
            comments: this.commentText,
            callStatus: this.newStatus,
            CallSid: this.callSid,
            followupDate: new Date(now()),
            ApplicationStatus: this.applicationStatus,
            University: this.selectedUniversity,
            diyear: this.DIyear,
            diterms: this.DIterms,
          }).subscribe(
            (res) => {
              this.commentText = "";
              this.getReferralList(
                this.admin,
                this.projectId,
                this.userData.mail,
                "all",
                this.startDate,
                this.endDate
              );
              this.closeButton2.nativeElement.click();
            },
            (error) => {
              this.handleError.handleError(error);
            }
          );
        }
      } else {
        this.selectedUniversity = "";

        this.ReferralService.updateCallComments({
          id: this.selectedLead.id,
          comments: this.commentText,
          callStatus: this.newStatus,
          CallSid: this.callSid,
          followupDate: new Date(now()),
          ApplicationStatus: this.applicationStatus,
          University: this.selectedUniversity,
          diyear: this.DIyear,
          diterms: this.DIterms,
        }).subscribe(
          (res) => {
            this.commentText = "";
            this.getReferralList(
              this.admin,
              this.projectId,
              this.userData.mail,
              "all",
              this.startDate,
              this.endDate
            );
            this.closeButton2.nativeElement.click();
          },
          (error) => {
            this.handleError.handleError(error);
          }
        );
      }

      // });
    }
  }

  resetCallingScreen() {
    this.commentText = "";
    this.newStatus = null;
    this.applicationStatus = "";
    this.showdeferredintake = false;
    this.DIyear = null;
    this.DIterms = null;
  }

  selectAllforAssignment() {
    if (this.selectedAll == false) {
      this.selectedAll = true;
      this.leadIds = [];
      for (let i = 0; i < this.referralList.length; i++) {
        this.leadIds.push(this.referralList[i].id);
      }
    } else if (this.selectedAll == true) {
      this.selectedAll = false;
      this.leadIds = [];
    }
  }

  selectAgentforAssignment(e, item) {
    if (e.target.checked) {
      this.leadIds.push(item.id);
    } else if (!e.target.checked) {
      this.removeItem(this.leadIds, item.id);
    }
  }

  removeItem(arr, value) {
    var index = arr.indexOf(value);
    if (index > -1) {
      arr.splice(index, 1);
    }
    return arr;
  }

  assignAgentModal() {
    this.defaultAgent = "default";
    this.selectedAgent = null;
  }
  agentSelected(selectedAgent) {
    this.selectedAgent = selectedAgent;
  }

  assignLEads() {
    if (this.leadIds.length == 0) {
      this.uhleadsservice.showWarning("Select leads to be assigned", "Alert!");
      return;
    }
    if (this.selectedAgent) {
      this.ReferralService.assignLeads({
        AssignedTo: this.selectedAgent,
        InsertedBy: this.userData.mail,
        LeadIds: this.leadIds,
      }).subscribe(
        (res) => {
          this.uhleadsservice.showSuccess(
            "Successfully assigned the agents",
            "Success"
          );
          this.leadIds = [];
          this.getReferralList(
            this.admin,
            this.projectId,
            this.userData.mail,
            "all",
            this.startDate,
            this.endDate
          );
          this.selectedAll = false;
          document.getElementById("closeAssignButton").click();
        },
        (error) => {
          this.handleError.handleError(error);
        }
      );
    } else {
      this.uhleadsservice.showWarning("Please select an agent", "Alert!");
    }
  }

  getTopBarCount() {
    var postData = {
      AgentMail: this.userData.mail,
      ProjectId: this.projectId,
      start: this.startDate,
      end: this.endDate,
    };
    this.ReferralService.getTopBarCount(postData).subscribe(
      (res: any) => {
        this.topBarCountReferral = res.TopBarCount;
        this.onClick(this.cardNumber);
      },
      (error) => {
        this.handleError.handleError(error);
      }
    );
  }
  getUniversalRecords(cardValue) {
    this.filterString = "";
    let obj;
    this.page = 1;
    this.leadTypereferee = cardValue;
    obj = {
      admin: this.admin,
      mail: this.userData.mail,
      ProjectId: this.projectId,
      leadType: this.leadTypereferee,
      start: this.startDate,
      end: this.endDate,
      page: this.page,
    };
    this.getLeadsSuccess(obj);
  }
  getLeadsByCards(cardValue) {
    this.filterString = "";
    let obj;
    this.page = 1;
    this.leadTypereferee = cardValue;
    obj = {
      admin: this.admin,
      mail: this.userData.mail,
      ProjectId: this.projectId,
      leadType: this.leadTypereferee,
      start: this.startDate,
      end: this.endDate,
      page: this.page,
    };
    this.getLeadsSuccess(obj);
    this.getTopBarCount();
  }

  toggleSort(column, order) {
    if (this.sortOrder == "asc") {
      this.sortOrder = "des";
    } else {
      this.sortOrder = "asc";
    }
    this.sortTableData(column, this.sortOrder);
  }

  sortTableData(column, order) {
    // 
    // 
    this.sortColumn = column;
    this.referralList = sortJsonArray(
      this.referralList,
      this.sortColumn,
      this.sortOrder
    );

  }

  getLeadsSuccess(obj) {
    this.referralList = [];
    this.referralList1 = [];
    this.leadsLoading = true;
    this.noLeadsFound = false;
    this.ReferralService.getReferralList(obj)
      .toPromise()
      .then((resp: any) => {
        this.referralList = resp;
        this.referralList1 = resp;
        this.referralList.forEach(function (a) {
          Object.keys(a).map((key) => {
            a[key] === null ? (a[key] = "-") : a[key];
            a[key] === "" ? (a[key] = "-") : a[key];
          });
        });
        this.sortTableData(this.sortColumn, this.sortOrder);
        this.referralList1 = resp;

        this.leadsLoading = false;

        if (this.referralList.length <= 0) {
          this.noLeadsFound = true;
        }
      })
      .catch((err) => { });
  }
  onClick(check: any) {
    this.page = 1;
    this.cardNumber = check;
    switch (check) {
      case 1:
        this.collectionSize = this.topBarCountReferral.total;
        break;
      case 2:
        this.collectionSize = this.topBarCountReferral.applicationSubmitted;
        break;
      case 3:
        this.collectionSize = this.topBarCountReferral.international;
        break;
      case 4:
        this.collectionSize = this.topBarCountReferral.USTransfer;
        break;
      case 5:
        this.collectionSize = this.topBarCountReferral.assigned;
        break;
      case 6:
        this.collectionSize = this.topBarCountReferral.unassigned;
        break;
      case 7:
        this.collectionSize = this.topBarCountReferral.assignedtoMe;
        break;
      case 8:
        this.collectionSize = this.topBarCountReferral.applicationnotsubmitted;
        break;
      case 9:
        this.collectionSize = this.topBarCountReferral.enrolled;
        break;
      case 10:
        this.collectionSize = this.topBarCountReferral.changeuniversity;
        break;
      default:
        break;
    }
    if (check) {
      this.tab = "tab" + check;
    } else {
      this.tab = "";
    }
  }
  dropdownSelect(event) {
    this.page = 1;
    this.getUniversalRecords(event);
    let payload = {
      status: event,
      ProjectId: this.projectId,
      start: this.startDate,
      end: this.endDate,
    };
    this.ReferralService.getCountByCallStatusReferredLeads(payload).subscribe(
      (data: any) => {

        this.collectionSize = Number(data.count);
      }
    );
  }
  getDownloadLink() {
    this.downloadLink = environment.referralCSV;
  }
  onInput(event) {
    if (this.filterString != "") {
    } else {
      this.referralList = this.referralList1;
      this.referralList.forEach(function (a) {
        Object.keys(a).map((key) => {
          a[key] === null ? (a[key] = "-") : a[key];
          a[key] === "" ? (a[key] = "-") : a[key];
        });
      });
      this.sortTableData(this.sortColumn, this.sortOrder);
    }
  }

  openEditLead(content, lead) {
    this.editStatus = lead.CallStatus;

    this.modalService.open(content, { size: "md" });
    let unversity = this.universityList.filter((x) => {
      if (x?.university == lead?.University) return x.id;
    });
    this.DIterms = lead?.DIterms;
    this.DIyear = parseInt(lead?.DIyear);
    if (this.editStatus == "deferredintake") {
      this.showdeferredintake = true;
    } else {
      this.showdeferredintake = false;
      this.DIyear = null;
      this.DIterms = null;
    }
    this.leadtoEdit = {
      followupDate: new Date(now()),
      LeadId: lead?.id,
      FirstName: lead?.FirstName || "",
      LastName: lead?.LastName || "",
      CountryCode: "+" + lead?.CountryCode.replace("+", "") || "",
      PhoneNumber: lead?.PhoneNumber || "",
      Email: lead?.Email || "",
      University: unversity[0]?.id || "",
      CallStatus: lead?.CallStatus || "",
      AgentMail: this.userData.mail,
    };
  }

  updateLead() {
    this.edited = true;
    if (this.ReferralService.editLead.invalid) {
      return;
    }

    this.leadtoEdit.CountryCode = this.leadtoEdit.CountryCode.replace(
      /\+/g,
      ""
    );
    this.leadtoEdit.DIterms = this.DIterms;
    this.leadtoEdit.DIyear = this.DIyear;
    if (this.leadtoEdit.CountryCode == "-") {
      this.leadtoEdit.CountryCode = "00";
    }
    if (this.leadtoEdit.PhoneNumber == "-") {
      this.leadtoEdit.PhoneNumber = "0000000000";
    }
    this.submitting = true;
    if (this.leadtoEdit.University == "other") {
      this.ReferralService.createCustomUniversity({
        ProjectId: this.projectId,
        UniversityName: this.customUniversityName2,
      }).subscribe(
        (res: any) => {
          this.leadtoEdit.University = res.UniversityId;
          this.ReferralService.updateLead(this.leadtoEdit).subscribe(
            (res: any) => {
              this.submitting = false;
              this.edited = false;
              this.getReferralList(
                this.admin,
                this.projectId,
                this.userData.mail,
                "all",
                this.startDate,
                this.endDate
              );
              document.getElementById("closeButton3").click();
            },
            (error) => {
              this.handleError.handleError(error);
            }
          );
        },
        (error) => {
          this.handleError.handleError(error);
        }
      );
    } else {
      this.ReferralService.updateLead(this.leadtoEdit).subscribe(
        (res: any) => {
          this.submitting = false;
          this.edited = false;
          this.getReferralList(
            this.admin,
            this.projectId,
            this.userData.mail,
            "all",
            this.startDate,
            this.endDate
          );
          document.getElementById("closeButton3").click();
        },
        (error) => {
          this.submitting = false;
          this.handleError.handleError(error);
        }
      );
    }
  }
  onDIItermsChange(data) {
    if (this.DIyear == null || this.DIyear == "") {
      this.requiredYear = true;
    } else {
      this.requiredYear = false;
      this.DIterms = data;
    }
  }
  onDIYearChange(data) {
    // 
    this.DIyear = data;
    this.requiredYear = false;
  }
  getCheckCallVisibleTiming(data: any) {
    if (moment(data.createdAt).add(48, "hours").format() >= moment().format()) {
      return false;
    } else {
      return true;
    }
  }
  searchLeads() {
    this.referralList = [];
    this.noLeadsFound = false;
    this.leadsLoading = true;
    let payload = {
      ProjectId: this.projectId,
      search: this.filterString,
      page: this.page
    };
    if (this.filterString.length > 0) {
      this.leadTypereferee = 'filter';
      this.ReferralService.search(payload).subscribe((resp: any) => {
        this.referralList = resp.data;
        this.referralList.forEach(function (a) {
          Object.keys(a).map((key) => {
            a[key] === null ? (a[key] = "-") : a[key];
            a[key] === "" ? (a[key] = "-") : a[key];
          });
        });
        this.collectionSize = resp.totallength;
        this.sortTableData(this.sortColumn, this.sortOrder);
        this.leadsLoading = false;
        if (this.referralList.length <= 0) {
          this.noLeadsFound = true;
        }
      });
    } else {
      this.leadTypereferee = "total";
      this.getTopBarCount();
      this.getLeadsByCards(this.leadTypereferee);
    }

  }
  onPageChange(event: any) {
    this.referralList = [];
    this.noLeadsFound = false;
    this.leadsLoading = true;
    this.page = event;
    if (this.leadTypereferee != "filter") {
      let obj = {
        admin: this.admin,
        mail: this.userData.mail,
        ProjectId: this.projectId,
        leadType: this.leadTypereferee,
        start: this.startDate,
        end: this.endDate,
        page: this.page,
      };
      this.getLeadsSuccess(obj);
    } else {
      let payload = {
        ProjectId: this.projectId,
        search: this.filterString,
        page: this.page
      };
      if (this.filterString.length > 0) {
        this.ReferralService.search(payload).subscribe((resp: any) => {
          this.referralList = resp.data;
          this.referralList.forEach(function (a) {
            Object.keys(a).map((key) => {
              a[key] === null ? (a[key] = "-") : a[key];
              a[key] === "" ? (a[key] = "-") : a[key];
            });
          });
          this.sortTableData(this.sortColumn, this.sortOrder);
          this.leadsLoading = false;
          if (this.referralList.length <= 0) {
            this.noLeadsFound = true;
          }
        });
      } else {
        this.leadTypereferee = "total";
      }
    }

  }
  get createLeads() {
    return this.ReferralService.editLead.controls;
  }
}
