<div class="containers">
  <div class="d-flex justify-content-end mb-2">
    <button class="btn btn-secondary btn-sm float-right" ngbTooltip="Create Role" placement="top"
      tooltipClass="my-custom-class" (click)="open(content)">
      <img alt="" class="img11" src="./assets/img/add-w.svg" />
    </button>
  </div>

  <div class="table-scroll data-table" style="overflow-y: auto;background:#fff">
    <table class="table table-md">
      <thead>
        <tr>
          <td colspan="7" style="padding: 8px">
            <div class="table-search-content">
              <div class="indived-search">
                <input type="text" class="form-control" [(ngModel)]="search" placeholder="Search..." />

              </div>
              <div class="pagenation-right">
                <ngb-pagination [(page)]="page" [pageSize]="pageSize" [collectionSize]="roles.length"
                  class="pagenation-right"></ngb-pagination>
              </div>
            </div>
          </td>
        </tr>
      </thead>
      <tbody>
        <tr style="background: #eaf5fd; border-bottom: none">
          <!-- <th>Role id</th> -->
          <th>
            <span>
              Role Name
              <img class="img7" src="../../../../assets/img/sort-data.svg" style="cursor: pointer"
                (click)="sortOrder('Name')" />
            </span>
          </th>
          <th>
            <span>
              Role Description
              <img class="img7" src="../../../../assets/img/sort-data.svg" style="cursor: pointer"
                (click)="sortOrder('Description')" />
            </span>
          </th>
          <th>
            <span>
              Status
              <img class="img7" src="../../../../assets/img/sort-data.svg" style="cursor: pointer"
                (click)="sortOrder('ActiveStatus')" />
            </span>
          </th>
          <th>
            <span>
              Created Date
              <img class="img7" src="../../../../assets/img/sort-data.svg" style="cursor: pointer"
                (click)="sortOrder('CreatedDate')" />
            </span>
          </th>
          <th>
            <span>
              Updated Date
              <img class="img7" src="../../../../assets/img/sort-data.svg" style="cursor: pointer"
                (click)="sortOrder('UpdatedDate')" />
            </span>
          </th>
          <th>Groups</th>
        </tr>
        <tr *ngFor="
            let item of roles
              | filter: search
              | slice: (page - 1) * pageSize:page * pageSize
              | sort: orderFeild:orderString;
            let id = index
          ">
          <!-- <td class="id-col-mod" style="cursor: pointer" (click)="updateRole(item['id'], 'form', content)" > {{ 'Role' + item['id'] }} </td> -->
          <td class="id-col-mod vm a-link" (click)="updateRole(item['id'], 'form', content)">
            {{ item["Name"] | titlecase }}
          </td>
          <td class="vm">{{ item["Description"] | titlecase }}</td>
          <td class="vm">
            <div [ngClass]="{ 'status-open': item['ActiveStatus'] }">
              {{ item["ActiveStatus"] ? "Active" : "In Active" }}
            </div>
          </td>
          <td class="vm">
            {{ item["createdAt"] | date: "medium" }}
          </td>
          <td class="vm">
            {{ item["updatedAt"] | date: "medium" }}
          </td>
          <td>
            <button (click)="selectedRole(item)" class="btn btn-primary btn-sm">
              Manage Groups
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div><app-group-management></app-group-management></div>
</div>

<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      {{ isUpdate ? "Update" : "Create" }} Role
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" style="max-height: 600px; overflow: auto">
    <ngb-alert type="danger" *ngIf="isError" [dismissible]="false">{{
      error
      }}</ngb-alert>
    <mat-tab-group [selectedIndex]="selectedIndex">
      <mat-tab label="Roles">
        <form (ngSubmit)="submit(f)" #f="ngForm" class="pt-3 pb-3">
          <div class="form-group">
            <label for="name" class="form-label">Role Name</label>
            <input type="name" class="form-control" placeholder="please enter name of role" name="name"
              [(ngModel)]="name" required [disabled]="editRoleName" #userName="ngModel" max="10" [ngClass]="{
                'is-invalid': !userName.valid && userName.touched,
                'is-valid': userName.valid && userName.touched
              }" />
          </div>
          <!-- <div class="valid-feedback" *ngIf="userName.valid && userName.touched" > looks good! </div> -->
          <div class="invalid-feedback form-group" *ngIf="!userName.valid && userName.touched">
            please enter valid name
          </div>
          <div class="form-group">
            <label for="description" class="form-label">Role Description</label>
            <input type="text" class="form-control" placeholder="please enter description of role" id="description"
              name="description" [(ngModel)]="description" required #desc="ngModel" max="35" [ngClass]="{
                'is-invalid': !desc.valid && desc.touched,
                'is-valid': desc.valid && desc.touched
              }" />
            <!-- <div class="valid-feedback" *ngIf="desc.valid && desc.touched"> look good! </div> -->
            <div class="invalid-feedback" *ngIf="!desc.valid && desc.touched">
              please enter valid description
            </div>
          </div>
          <div class="xrow mb-2" *ngIf="isUpdate" style="align-items: center">
            <label for="description" class="form-label">Active Status</label>
            <select required class="form-control" [(ngModel)]="activestatus" name="ActiveStatus"
              #activeStatus="ngModel">
              <option value="active">Active</option>
              <option value="inactive">In Active</option>
            </select>
            <div class="invalid-feedback" *ngIf="!activeStatus.valid && activeStatus.touched">
              please select value
            </div>
          </div>
          <div class="">
            <div class="d-flex justify-content-end">
              <button type="submit" class="btn btn-primary btn-sm" [disabled]="!f.valid">
                Next
              </button>
            </div>
          </div>
        </form>
      </mat-tab>
      <mat-tab label="Menu Roles">
        <form (ngSubmit)="menuCheckedValues(form.value)" #form="ngForm">
          <div class="pt-3 pb-3" style="">
            <div *ngFor="let item of menus; let i = index" class="">
              <div *ngIf="item['child'].length !== 0" class="">
                <div class="role-pop-headding">
                  {{ item["parent"]["name"] | titlecase }}
                </div>
                <div *ngFor="let items of item['child']" class="">
                  <mat-selection-list ngModel [name]="items['name']" (selectionChange)="
                      parentMenuCheckbox(
                        $event,
                        item['parent']['id'],
                        items['id']
                      )
                    ">
                    <mat-list-option [value]="items['id']" [selected]="checkBox(items['id'])" style="font-size: 13px">
                      {{ items["name"] | titlecase }}
                    </mat-list-option>
                  </mat-selection-list>
                </div>
              </div>
              <div *ngIf="item['child'].length === 0" class="">
                <mat-card>
                  <mat-selection-list ngModel [name]="item['parent']['name']" (selectionChange)="
                      childMenuCheckBox($event, item['parent']['id'])
                    ">
                    <mat-list-option [value]="item['parent']['id']" [selected]="checkBox(item['parent']['id'])"
                      class="role-pop-headding" style="padding-bottom: 20px; padding-top: 20px">
                      {{ item["parent"]["name"] | titlecase }}
                    </mat-list-option>
                  </mat-selection-list>
                </mat-card>
              </div>
            </div>
            <div class="d-flex justify-content-between">
              <button class="btn btn-primary btn-sm" type="button" (click)="open1(addMenu)">
                Add New
              </button>
              <button class="btn btn-primary btn-sm" type="submit">
                {{ isUpdate ? "Update" : "Submit" }}
              </button>
            </div>
          </div>
        </form>
      </mat-tab>
    </mat-tab-group>
  </div>
</ng-template>


<ng-template #addMenu let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      Add New Menu
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" style="max-height: 600px; overflow: auto">
    <form (ngSubmit)="submitAddMenu(f)" #f="ngForm" class="pt-3 pb-3">
      <div class="form-group">
        <label for="name" class="form-label">Menu Name</label>
        <input type="name" class="form-control" placeholder="please enter name of role" name="name"
          [(ngModel)]="menuname" required #menunName="ngModel" max="10" [ngClass]="{
            'is-invalid': !menunName.valid && menunName.touched,
            'is-valid': menunName.valid && menunName.touched
          }" />
      </div>
      <!-- <div class="valid-feedback" *ngIf="userName.valid && userName.touched" > looks good! </div> -->
      <div class="invalid-feedback form-group" *ngIf="!menunName.valid && menunName.touched">
        please enter valid name
      </div>
      <div class="form-group">
        <label for="menudescription" class="form-label">Menu Description</label>
        <input type="text" class="form-control" placeholder="please enter description of role" id="menudescription"
          name="menudescription" [(ngModel)]="menudescription" required #menudesc="ngModel" max="35" [ngClass]="{
            'is-invalid': !menudesc.valid && menudesc.touched,
            'is-valid': menudesc.valid && menudesc.touched
          }" />
        <!-- <div class="valid-feedback" *ngIf="desc.valid && desc.touched"> look good! </div> -->
        <div class="invalid-feedback" *ngIf="!menudesc.valid && menudesc.touched">
          please enter valid description
        </div>
      </div>
      <div class="xrow mb-2" style="align-items: center">
        <label for="description" class="form-label">Is Parent</label>
        <select required class="form-control" [(ngModel)]="menuactivestatus" name="ActiveStatus"
          #menuactiveStatus="ngModel">
          <option value="yes">Yes</option>
          <option value="no">No</option>
        </select>
        <div class="invalid-feedback" *ngIf="!menuactiveStatus.valid && menuactiveStatus.touched">
          please select value
        </div>
      </div>
      <div class="xrow mb-2" style="align-items: center" *ngIf="menuactivestatus == 'no' ">
        <label for="description" class="form-label">Select</label>
        <select required class="form-control" [(ngModel)]="menuParent" name="menuParentId" #menuParentId="ngModel">
          <option [value]='item["parent"]["id"]' *ngFor="let item of menus">
            {{item["parent"]["name"] | titlecase }}
          </option>
        </select>
      </div>
      <div class="">
        <div class="d-flex justify-content-end">
          <button type="submit" class="btn btn-primary btn-sm" [disabled]="!f.valid">
            Submit
          </button>
        </div>
      </div>
    </form>
  </div>
</ng-template>
<!-- backdrop for create Role -->