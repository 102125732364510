<!-- <form [formGroup]="myForm" (ngSubmit)="onSubmit(jsonFormData.controls)" width="600px">

    <mat-form-field *ngFor="let control of jsonFormData?.controls">
        <section>
            <mat-label *ngIf="control.label !== ''">{{ control.label }}</mat-label>

            
            <input matInput *ngIf="
                    [
                      'text',
                      'String',
                      'string',
                      'password',
                      'email',
                      'number',
                      'search',
                      'tel',
                      'url',
                      'Date'
                    ].includes(control.type)
                  " [type]="control.type" [formControlName]="control.name" [value]="control.value" [(ngModel)]="someval" (input)="change(control.value)">
            
                  

            <textarea matInput *ngIf="control.type === 'textarea'" [formControlName]="control.name"
                [value]="control.value"  [(ngModel)]="control.value"></textarea>

            <mat-checkbox class="example-margin" *ngIf="control.type === 'checkbox'" [checked]="control.value"  [(ngModel)]="control.value">Check me!
            </mat-checkbox>

            <mat-checkbox class="example-margin" *ngIf="control.type === 'checkbox'" [formControlName]="control.name"
                [checked]="control.value"  [(ngModel)]="control.value">
                CheckBox
            </mat-checkbox>

            <mat-slide-toggle *ngIf="control.type === 'toggle'" [formControlName]="control.name"
                [checked]="control.value"  [(ngModel)]="control.value">
                Slide me!</mat-slide-toggle>

            <mat-slider aria-label="unit(s)" *ngIf="control.type === 'range'" [min]="control.options.min"
                [max]="control.options.max" [formControlName]="control.name"  [(ngModel)]="control.value">
                <mat-icon size="small" slot="start" name="control.options.icon"></mat-icon>
                <mat-icon slot="end" name="control.options.icon"></mat-icon>
            </mat-slider>

            <input matInput [matDatepicker]="picker" *ngIf="control.type === 'Date'" [value]="control.value"  [(ngModel)]="control.value">
            <mat-datepicker-toggle *ngIf="control.type === 'Date'" matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>

        </section>
    </mat-form-field>
    <button mat-button color="primary" type="submit">Submit</button>

</form> -->

<form [formGroup]="myForm" (ngSubmit)="onSubmit(jsonFormData.controls)">
  <ion-item *ngFor="let control of jsonFormData?.controls">
    <ion-label>{{ control.label }} : </ion-label>
    <ion-input *ngIf="
          [
            'text',
            'password',
            'email',
            'string',
            'String',
            'number',
            'search',
            'tel',
            'url',
            'Date'
          ].includes(control.type)
        " [type]="control.type" [className]="control.name" [formControlName]="control.name" [value]="control.value"
      [(ngModel)]="someval" (input)="change(someval)"></ion-input>
    <ion-textarea *ngIf="control.type === 'textarea'" [formControlName]="control.name"
      [value]="control.value"></ion-textarea>
    <ion-checkbox *ngIf="control.type === 'checkbox'" [formControlName]="control.name"
      [checked]="control.value"></ion-checkbox>
    <ion-toggle *ngIf="control.type === 'toggle'" [formControlName]="control.name"
      [checked]="control.value"></ion-toggle>
    <ion-range *ngIf="control.type === 'range'" [min]="control.options.min" [max]="control.options.max"
      [formControlName]="control.name">
      <ion-icon size="small" slot="start" [name]="control.options.icon"></ion-icon>
      <ion-icon slot="end" [name]="control.options.icon"></ion-icon>
    </ion-range>
  </ion-item>
  <ion-button type="submit">Submit</ion-button>
</form>