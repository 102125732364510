<div>
  <div class="d-flex">
    <div class="ml-auto d-flex">
      <button data-toggle="modal" data-target="#exampleModal3" (click)="assignAgentModal(assignLeadsModal)"
        class="btn btn-link btn-sm" style="color: #fff" ngbTooltip="Assign" placement="top"
        tooltipClass="my-custom-class">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 32 32">
          <g id="Group_860" data-name="Group 860" transform="translate(-783.465 -58.66)">
            <path id="Path_965" data-name="Path 965"
              d="M795.465,62.66a5,5,0,1,1-5,5,5,5,0,0,1,5-5m0-2a7,7,0,1,0,7,7A7,7,0,0,0,795.465,60.66Z" class="csvg" />
            <path id="Path_966" data-name="Path 966"
              d="M805.465,88.66h-2v-5a5,5,0,0,0-5-5h-6a5,5,0,0,0-5,5v5h-2v-5a7,7,0,0,1,7-7h6a7,7,0,0,1,7,7Z"
              class="csvg" />
            <path id="Path_967" data-name="Path 967" d="M808.465,74.84l-2.59-2.59-1.41,1.41,4,4,7-7-1.41-1.41Z"
              class="csvg" />
            <rect id="_Transparent_Rectangle_" data-name=" Transparent Rectangle " width="32" height="32"
              transform="translate(783.465 58.66)" fill="none" />
          </g>
        </svg>
      </button>

      <button class="btn btn-link btn-sm" ngbTooltip="Download Sample" placement="top" (click)="downloadTemplate()"
        tooltipClass="my-custom-class">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 23 23">
          <g id="Group_776" data-name="Group 776" transform="translate(-215.728 -61.66)">
            <path id="Path_888" class="csvg" data-name="Path 888"
              d="M234.853,86.66H221.1a1.375,1.375,0,0,1-1.375-1.375v-8.25A1.375,1.375,0,0,1,221.1,75.66h2.063v1.375H221.1v8.25h13.75v-8.25h-2.063V75.66h2.063a1.375,1.375,0,0,1,1.375,1.375v8.25A1.376,1.376,0,0,1,234.853,86.66Z"
              transform="translate(-1.25 -4.375)" fill="#b02022" />
            <rect id="Rectangle_902" class="csvg" data-name="Rectangle 902" width="4" height="1"
              transform="translate(224.728 75.66)" fill="#b02022" />
            <path id="Path_889" class="csvg" data-name="Path 889"
              d="M233.008,68.19l-2.468,2.461V63.66h-1.375v6.992L226.7,68.19l-.969.969,4.125,4.125,4.125-4.125Z"
              transform="translate(-3.125 -0.625)" fill="#b02022" />
            <rect id="_Transparent_Rectangle_" data-name=" Transparent Rectangle " width="22" height="22"
              transform="translate(215.728 61.66)" fill="none" />
          </g>
        </svg>
      </button>
      <button class="btn btn-link btn-sm" ngbTooltip="Upload Leads" placement="top" (click)="uploadTemplate()"
        tooltipClass="my-custom-class">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 22 22">
          <g id="Group_775" data-name="Group 775" transform="translate(-171.243 -61.66)">
            <path id="Path_886" class="csvg" data-name="Path 886"
              d="M177.243,76.419l.953.953,5.131-5.124V84.531h1.352V72.249l5.131,5.124.953-.953L184,69.66Z"
              transform="translate(-1.944 -2.592)" />
            <path id="Path_887" class="csvg" data-name="Path 887"
              d="M176.595,67.716v-2.7h13.519v2.7h1.352v-2.7a1.352,1.352,0,0,0-1.352-1.352H176.595a1.352,1.352,0,0,0-1.352,1.352v2.7Z"
              transform="translate(-1.296 -0.648)" />
            <rect id="_Transparent_Rectangle_" data-name=" Transparent Rectangle " width="22" height="22"
              transform="translate(171.243 61.66)" fill="none" />
          </g>
        </svg>
      </button>
      <div>
        <button class="btn btn-link btn-sm" ngbTooltip="Add Lead" placement="top" (click)="addNewLead()"
          tooltipClass="my-custom-class">
          <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22">
            <g id="Group_764" data-name="Group 764" transform="translate(-286.561 -0.829)">
              <path id="Path_869" data-name="Path 869" class="csvg"
                d="M302.855,15.28V8.829h-1.843V15.28h-6.451v1.843h6.451v6.451h1.843V17.123h6.451V15.28Z"
                transform="translate(-4.373 -4.373)" fill="#b02022" />
              <rect id="Rectangle_901" data-name="Rectangle 901" width="22" height="22"
                transform="translate(286.561 0.829)" fill="none" />
            </g>
          </svg>
        </button>
      </div>
    </div>
  </div>
</div>

<div class="table-responsive data-table3">
  <table class="table table-md mt-1 mb-2">
    <thead>
      <tr>
        <td id="tableCellID">
          <div class="indived-search" style="position: relative">
            <input type="search" (input)="onInput($event)" #inputString class="form-control search-all"
              [(ngModel)]="search" placeholder="Search " autocomplete="off" />
          </div>
        </td>

        <td id="tableCellID1" class="vm">
          <div class="pagenation-right d-flex justify-content-end align-items-center">

            <select class="form-select form-select-dark mx-2" style="width:90px" id="" [(ngModel)]="tableLimit"
              (change)="updateTableLimit($event.target.value)">
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="15">15</option>
              <option value="20">20</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>

            <select class="form-select form-select-dark mx-2" [(ngModel)]="statusValue"
              (ngModelChange)="dropdownSelect($event)">
              <option value="all">All</option>
              <ng-container *ngFor="let item of feedback">
                <option [value]="item?.value" *ngIf="item?.callingScreen">
                  {{ item?.name }}
                </option>
              </ng-container>
            </select>
            <div class="float-right ">
              <app-dash-pagination></app-dash-pagination>
            </div>
          </div>
        </td>
      </tr>
    </thead>
    <tr style="background: #eaf5fd">
      <th class="vm" style="width: 30px; white-space: nowrap">
        <div style="width: 20px">
          <input type="checkbox" [checked]="selectedAll == true" class="selectAll" id="selectAll"
            (change)="selectAllforAssignment()" />
        </div>
      </th>
      <th class="vm" nowrap>No.</th>
      <ng-container *ngFor="let i of colData">
        <th class="vm" nowrap *ngIf="i?.showInDashboard">
          <span>{{ i?.displayName }}
            <span><button class="sortButton" (click)="toggleSort(i?.name, 'asc')">
                <img class="img7 ml-1" src="../../../../assets/img/sort-data.svg" /></button></span>
          </span>
        </th>
      </ng-container>


      <th class="vm" nowrap>Actions</th>
    </tr>
    <tr *ngFor="let i of leads; let id = index">
      <td class="vm" nowrap>
        <div style="width: 20px">
          <input type="checkbox" name="lead" [checked]="selectedAll == true" class="selectLead" id="selectLead"
            (change)="selectAgentforAssignment($event, i)" />
        </div>
      </td>
      <td class="vm" nowrap>
        <span>{{getProperId(id)}}</span>
      </td>
      <ng-container *ngFor="let c of columns; let index = index">
        <td [className]="c?.type" style="vertical-align: middle;" *ngIf="c?.showInDashboard" nowrap>
          <span *ngIf="c?.showInDashboard && c?.name!='assignedto'" [className]="i[c?.name] + ' callstatus'">{{
            getValueBasedOnColumn(i,c)
            ||'-' }}</span>
          <span *ngIf="c?.showInDashboard && c?.name=='assignedto'" [className]="i[c?.name] + ' callstatus'">{{
            i['AgentName'] ||'-' }}</span>
        </td>
      </ng-container>


      <td class="vm text-center" nowrap style="text-align: left !important">
        <div ngbTooltip="Edit" placement="top" tooltipClass="my-custom-class" class="mr-2"
          (click)="updateLead(editLead, i)" style="display: inline; cursor: pointer" data-toggle="modal"
          data-target="#editLead">
          <img src="../../../../assets/images/edit.svg" class="img13" />
        </div>
        <mat-sidenav-container style="display: inline !important" ngbTooltip="Call" class="mr-2" placement="top"
          tooltipClass="my-custom-class">
          <mat-sidenav-content style="display: inline !important">
            <div style="display: inline !important; cursor: pointer"
              (click)="callingSideNav.toggle();getLeadInfoCall(i)">
              <img src="../../../../assets/img/call.svg" class="img13" />
            </div>
          </mat-sidenav-content>
        </mat-sidenav-container>

        <mat-sidenav-container style="display: inline !important" ngbTooltip="Overview" class="mr-2" placement="top"
          tooltipClass="my-custom-class">
          <mat-sidenav-content style="display: inline !important">
            <div style="display: inline !important; cursor: pointer"
              (click)="sideNav.toggle();sideNavData(i);getLeadInfo(i)">
              <img src="../../../../assets/img/view2.svg" class="img13" />
            </div>
          </mat-sidenav-content>
        </mat-sidenav-container>
      </td>
    </tr>
    <tr *ngIf="contentLoadedTable == false">
      <td colspan="10">
        <div class="norecords">
          <img src="../../../../assets/img/waiting.gif" />

          <div>Loading Data<br />Please wait while we fetch the data for you</div>
        </div>
      </td>
    </tr>
    <tr *ngIf="noRecords == true">
      <td colspan="10">
        <div class="norecords">
          <img src="../../../../assets/img/nodata.svg" />
          <div>No records found for the selected filters.<br /></div>
        </div>
      </td>
    </tr>
  </table>
</div>


<ng-template #editLead let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Update Lead Details</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <form>
    <label for="test">Test</label>
    <input type="text" id="Test" #test value="Vidya" [(ngModel)]="someval" (input)="change(someval)" />
  </form>

  <form [formGroup]="myForm" (ngSubmit)="onSubmit(editFormData?.controls)">
    <ion-item *ngFor="let control of editFormData?.controls">
      <ion-label>{{ control?.label }} : </ion-label>
      <ion-input *ngIf="
          [
            'text',
            'password',
            'email',
            'string',
            'String',
            'number',
            'search',
            'tel',
            'url',
            'Date'
          ].includes(control?.type)
        " [type]="control?.type" [value]="control?.value" [(ngModel)]="control.value"></ion-input>
      <ion-textarea *ngIf="control.type === 'textarea'" [formControlName]="control?.name" [value]="control?.value">
      </ion-textarea>
      <ion-checkbox *ngIf="control.type === 'checkbox'" [formControlName]="control?.name" [checked]="control?.value">
      </ion-checkbox>
      <ion-toggle *ngIf="control.type === 'toggle'" [formControlName]="control?.name" [checked]="control?.value">
      </ion-toggle>
      <ion-range *ngIf="control.type === 'range'" [min]="control.options.min" [max]="control.options.max"
        [formControlName]="control?.name">
        <ion-icon size="small" slot="start" [name]="control.options.icon"></ion-icon>
        <ion-icon slot="end" [name]="control.options.icon"></ion-icon>
      </ion-range>
    </ion-item>
    <ion-button type="submit" style="background:pink">Submit</ion-button>
  </form>
</ng-template>

<ng-template #callModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Calling Screen</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
</ng-template>

<div class="modal fade" id="staticBackdrop" data-backdrop="static" data-keyboard="false" tabindex="-1"
  aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="staticBackdropLabel"> Calling Screens</h5>

        <button type="button" #closeButton2 class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="call-module ml-3">
            <div class="ndialer">
              <div class="c-caller">Current Caller</div>
              <div class="dialer-middle-screen" style="text-align: center" *ngIf="dialerScreen1 === 'outgoing1'">
                <div class="custom-incomingcall-widget">
                  <h4 class="mt-3 text-ellipsis">
                    {{ marketingId?.name }}
                  </h4>
                  <div class="mb-2 mt-1 lg-text" style="font-weight: 100" *ngIf="marketingId.city">
                    {{ marketingId?.city }}
                  </div>
                  <div class="my-1 lg-text">
                    {{ marketingId?.phoneNumber }}
                  </div>
                  <div class="custom-user-img my-3 mt-5">
                    <img class="img80" src="./assets/img/user-w.svg" alt="" />
                  </div>
                  <h5 *ngIf="callStatus != 'inProgress'">{{ callStatus }}</h5>
                  <p *ngIf="callStatus == 'inProgress'" class="custom-call-timer" style="text-align: center">
                    <countup-timer [countUpTimerConfig]="testConfig"></countup-timer>
                  </p>

                  <div class="custom-incoming-btns my-3 mt-4">
                    <button type="button" class="btn-round m-r-10" (click)="stopCurrentCall()">
                      <img class="img-38" src="./assets/img/hangup.svg" alt="" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="call-module-update ml-4 mr-2">
            <div class="row">
              <div class="col-md-12">
                <div class="header-md mt-2 mb-1">Lead details</div>
              </div>
            </div>

            <div class="row custom-row" *ngFor="
               let c of config?.columns;
               let i = index;
               let even = even;
               let odd = odd
             ">
              <div class="col-md-4" *ngIf="even">
                <div class="form-group">
                  <label for="formGroupExampleInput">{{
                    config?.columns[i]?.displayName
                    }}</label>
                  <div>value</div>
                </div>
              </div>
              <div class="col-md-4" *ngIf="even && i + 1 < columns.length">
                <div class="form-group">
                  <label for="formGroupExampleInput">{{
                    config?.columns[i + 1]?.displayName
                    }}</label>
                  <div>value</div>
                </div>
              </div>
            </div>

            <hr class="hr-style mt-1 mb-1" />

            <div class="row">
              <div class="col-md-12">
                <div class="header-md mt-2 mb-1">Document details</div>
              </div>
            </div>
            <div class="row custom-row" *ngFor="
                let item of documentsList;
                let i = index;
                let even = even;
                let odd = odd
              ">
              <div class="col-md-6 py-2" *ngIf="even">
                <img *ngIf="item.status == true" class="img18 mr-2" src="../../../../assets/img/tick2.svg" />
                <img *ngIf="item.status == false" class="img18 mr-2" src="../../../../assets/img/minus.svg" />{{
                documentsList[i]?.DocumentName }}
              </div>
              <div class="col-md-6 py-2" *ngIf="even && i + 1 < documentsList.length">
                <img *ngIf="documentsList[i + 1].status == true" class="img18 mr-2"
                  src="../../../../assets/img/tick2.svg" />
                <img *ngIf="documentsList[i + 1].status == false" class="img18 mr-2"
                  src="../../../../assets/img/minus.svg" />{{ documentsList[i + 1]?.DocumentName }}
              </div>
            </div>

            <hr class="hr-style mt-1 mb-1" />
            <div class="row custom-row2">
              <div class="col-md-12">
                <div class="header-md mt-2 mb-1">Feedback</div>
              </div>

              <div class="col-md-12">
                <div class="form-check mr-3 d-inline">
                  <label class="form-check-label" for="check1">
                    <input type="radio" [(ngModel)]="newStatus" class="form-check-input" id="check1" name="option1"
                      value="notinterested" (change)="onStatusChange($event.target.value)" />Not Interested
                  </label>
                </div>
                <div class="form-check mr-3 d-inline">
                  <label class="form-check-label" for="check3">
                    <input type="radio" [(ngModel)]="newStatus" class="form-check-input" id="check3" name="option1"
                      value="informed" (change)="onStatusChange($event.target.value)" />Informed
                  </label>
                </div>
                <div class="form-check mr-3 d-inline">
                  <label class="form-check-label" for="check4">
                    <input type="radio" [(ngModel)]="newStatus" class="form-check-input" id="check4" name="option1"
                      value="callfailed" (change)="onStatusChange($event.target.value)" />Wrong Number
                  </label>
                </div>
                <div class="form-check mr-3 d-inline">
                  <label class="form-check-label" for="check5">
                    <input type="radio" [(ngModel)]="newStatus" class="form-check-input" id="check5" name="option1"
                      value="callback" (change)="onStatusChange($event.target.value)" />Call Back
                  </label>
                </div>
                <div class="form-check mr-3 d-inline">
                  <label class="form-check-label" for="check6">
                    <input type="radio" [(ngModel)]="newStatus" class="form-check-input" id="check6" name="option1"
                      value="notanswered" (change)="onStatusChange($event.target.value)" />Not Answered
                  </label>
                </div>
                <div class="form-check mr-3 d-inline">
                  <label class="form-check-label" for="check7">
                    <input type="radio" [(ngModel)]="newStatus" class="form-check-input" id="check7" name="option1"
                      value="deferredintake" (change)="onStatusChange($event.target.value)" />Deferred Intake
                  </label>
                </div>
                <div class="form-check mr-3 d-inline">
                  <label class="form-check-label" for="check8">
                    <input type="radio" [(ngModel)]="newStatus" class="form-check-input" id="check8" name="option1"
                      value="uncontactable" (change)="onStatusChange($event.target.value)" />Uncontactable
                  </label>
                </div>
              </div>
            </div>

            <span *ngIf="!newStatus" class="error-msg" style="color: red; font-size: 15px">
              Please select any option above!
            </span>
            <div class="row custom-row">
              <div class="col-md-12">
                <div class="form-group">
                  <label for="commentText" class="header-md pt-3 pb-2" style="font-size: 14px">Comments</label>
                  <textarea class="form-control" id="commentText" rows="3" [(ngModel)]="commentText"></textarea>
                </div>
              </div>
            </div>

            <div class="d-flex justify-content-end">
              <div class="mt-3">
                <button (click)="resetCallingScreen()" class="btn btn-light btn-sm mr-2" type="button">
                  Reset
                </button>
                <button (click)="updateComments()" class="btn btn-primary btn-sm" type="submit">
                  Update
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<mat-sidenav id="sideNav" #sideNav mode="side" class="pr" opened="false" position="end" class="pf w45 z-top">
  <div class="xmodal-header xd-flex pf w45" style="right: 0; top: 0; z-index: 9999">

    <div class="d-flex mat-modal-header p-4" style="width:100%">
      <div class="modal-title component-title mr-auto">Overview</div>
      <div class="ml-auto icon-frame" (click)="sideNav.toggle()">

        <img src="../../../../assets/img/close.svg" style="width:8px" />
      </div>
    </div>
  </div>
  <mat-tab-group class="sideDataViewer" style="margin-top:70px">
    <mat-tab label="Lead details">
      <div class="mx-2 my-2">
        <table class="table table-borderless table-md caller-table my-1">
          <tr *ngFor="let c of config?.columns">
            <td *ngIf="c?.showInOverview == true">{{ c?.displayName }}</td>
            <td *ngIf="c?.showInOverview == true">
              {{ leadInfo[c?.name] }}
            </td>
          </tr>
        </table>

      </div>
    </mat-tab>
    <mat-tab label="Call Details" *ngIf="companyConfig?.voipName">
      <mat-tab-group class="sideDataViewer">
        <mat-tab label="Audio">
          <div class="alert alert-info m-2">
            {{ leadInfo?.CallDetails?.length }} Calls
          </div>
          <div *ngFor="let i of leadInfo.CallDetails" class="mx-2 my-2">
            <table class="table table-borderless table-md caller-table my-1">
              <tr>
                <td>Called by</td>
                <td>{{ i?.AgentName }}</td>
              </tr>
              <tr>
                <td>Date & Time</td>
                <td>{{ i?.DateTime | date: "dd MMM, yyyy hh:mm" }}</td>
              </tr>
              <tr>
                <td>Status</td>
                <td>
                  <span *ngIf="i?.CallStatus == 'pending'">Pending</span>
                  <span *ngIf="i?.CallStatus == 'uncontactable'">Uncontactable</span>
                  <span *ngIf="i?.CallStatus == 'notinterested'">Not Interested</span>
                  <span *ngIf="i?.CallStatus == 'completed'">Completed</span>
                  <span *ngIf="i?.CallStatus == 'callback'">Call Back</span>
                  <span *ngIf="i?.CallStatus == 'informed'">Informed</span>
                  <span *ngIf="i?.CallStatus == 'called'">Called</span>
                  <span *ngIf="i?.CallStatus == 'callfailed'">Wrong Number</span>
                  <span *ngIf="i?.CallStatus == 'deferredintake'">Deferred Intake</span>
                  <span *ngIf="
                                        i?.CallStatus == 'callnotanswered' ||
                                        i?.CallStatus == 'notanswered'
                                      ">Not Answered</span>
                  <span *ngIf="
                                        i?.CallStatus == 'deferred' ||
                                        i?.CallStatus == 'Deferred'
                                      ">Deferred Intake</span>
                </td>?
              </tr>
              <tr>
                <td>Direction</td>
                <td *ngIf="i?.Direction=='inbound'">InComing Call</td>
                <td *ngIf="i?.Direction=='outbound'">OutGoing Call</td>
              </tr>
              <tr>
                <td style="padding-top: 20px">Call Recording</td>
                <td><audio *ngIf="i?.RecordingURL != '-'" style="height: 2.5rem; width: 15srem" [src]="i?.RecordingURL"
                    controls controlsList="nodownload" (play)="audioPlay($event)"></audio>
                </td>
              </tr>
              <tr>
                <td>Comments</td>
                <td>{{ i?.Comments || '-' }}</td>
              </tr>
            </table>
            <hr class="hr-style" />
          </div>
        </mat-tab>
        <mat-tab label="Video">
          <div class="alert alert-info m-2">
            {{ leadInfo?.videoCalldetails.length }} Calls
          </div>
          <div *ngFor="let i of leadInfo?.videoCalldetails" class="mx-2 my-2">
            <table class="table table-borderless table-md caller-table my-1">
              <tr>
                <td>Presenter</td>
                <td>{{ i?.Presenter | titlecase }}</td>
              </tr>
              <tr>
                <td>Date & Time</td>
                <td>{{ i?.DateTime | date: "dd MMM, yyyy hh:mm" }}</td>
              </tr>
              <tr>
                <td style="padding-top: 20px">Call Recording</td>
                <td>
                  <video width="160" height="120" controls *ngIf="i?.RecordingURL != '-'">
                    <source [src]="i?.RecordingURL" type="video/mp4">
                    Your browser does not support the video tag.
                  </video>
                </td>
              </tr>
            </table>
            <hr class="hr-style" />
          </div>
        </mat-tab>
      </mat-tab-group>
    </mat-tab>
    <mat-tab label="Documents">
      <div class="mx-2 my-2">
        <div class="mx-2 my-2">
          <div class="border-bottom" *ngFor="let item of documentsList2">
            <div class="d-flex mb-1">
              <div class="py-2 d-flex flex-column">
                <img *ngIf="item?.uploadedDate != '-'" src="./assets/img/docs-r.svg" style="width: 16px" />
                <img *ngIf="item?.uploadedDate == '-'" src="./assets/img/doc-grey-2.svg" style="width: 16px" />
              </div>
              <div class="p-2 d-flex flex-column w200">
                <div class="sub-header-md mr-3 d-flex align-items-center">
                  <div style="line-height: 160%;">{{ item?.ProgramDoc |titlecase }}</div>

                  <button *ngIf="item?.docType=='customDoc'" style="background: none;"
                    (click)="deleteCustomDocument(item?.StudentdocId,item?.ProgramDoc)">
                    <img class="img12" style="width:10px" src="../../../assets/img/delete.svg" />
                  </button>

                </div>

                <div class="sub-date" *ngIf="item?.uploadedDate != '-'">
                  {{ item?.uploadedDate | date: "medium" }}
                </div>
              </div>
              <div>
                <div>
                  <span class="badge badge-pill badge-success mt-3"
                    *ngIf="item?.AgentValidation == 'validated'">Validated</span>
                  <span class="badge badge-pill badge-warning mt-3" *ngIf="item?.AgentValidation == 'revalidate'">Re
                    Validate</span>
                </div>

                <ng-template #validation11>
                  <form #form="ngForm">
                    <div style="width: 185px; font-size: 12px">
                      <div>
                        <div class="form-check form-check-inline">
                          <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1"
                            value="validated" (change)="
                                              changeValidation(
                                                $event.target.value,
                                                item?.StudentdocId
                                              )
                                            " />
                          <label class="form-check-label" for="inlineRadio1">Validated</label>
                        </div>
                        <div class="form-check form-check-inline">
                          <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2"
                            value="revalidate" (change)="
                                              changeValidation(
                                                $event.target.value,
                                                item?.StudentdocId
                                              )
                                            " />
                          <label class="form-check-label" for="inlineRadio2">Re Validate</label>
                        </div>
                      </div>
                    </div>
                  </form>
                </ng-template>

                <ng-template #comments11>
                  <form #form="ngForm">
                    <div class="d-flex align-items-end" style="width: 250px">
                      <div class="flex-grow-1">
                        <textarea class="form-control ct" type="text" #documentComment id="documentComments" rows="2"
                          placeholder="Write comments here..."></textarea>
                      </div>
                      <button type="button" class="btn btn-link ml-1 flex-shrink-1" style="padding: 0"
                        (click)="postComments(item, 'documentComments')">
                        <img src="./assets/img/send-message.svg" style="width: 16px" />
                      </button>
                    </div>
                  </form>
                </ng-template>
              </div>
              <div class="ml-auto p-2">
                <button *ngIf="item?.uploadedDate != '-'" class="btn btn-light btn-sm mr-2"
                  ngbTooltip="Download Document" placement="bottom" tooltipClass="my-custom-class"
                  (click)="downLoadDocument(item?.StudentDocPath, item?.ProgramDoc)">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 23 23">
                    <g id="Group_776" data-name="Group 776" transform="translate(-215.728 -61.66)">
                      <path id="Path_888" class="gsvg" data-name="Path 888"
                        d="M234.853,86.66H221.1a1.375,1.375,0,0,1-1.375-1.375v-8.25A1.375,1.375,0,0,1,221.1,75.66h2.063v1.375H221.1v8.25h13.75v-8.25h-2.063V75.66h2.063a1.375,1.375,0,0,1,1.375,1.375v8.25A1.376,1.376,0,0,1,234.853,86.66Z"
                        transform="translate(-1.25 -4.375)" class="gsvg" />
                      <rect id="Rectangle_902" class="gsvg" data-name="Rectangle 902" width="4" height="1"
                        transform="translate(224.728 75.66)" class="gsvg" />
                      <path id="Path_889" class="csvg" data-name="Path 889"
                        d="M233.008,68.19l-2.468,2.461V63.66h-1.375v6.992L226.7,68.19l-.969.969,4.125,4.125,4.125-4.125Z"
                        transform="translate(-3.125 -0.625)" class="gsvg" />
                      <rect id="_Transparent_Rectangle_" data-name=" Transparent Rectangle " width="22" height="22"
                        transform="translate(215.728 61.66)" fill="none" />
                    </g>
                  </svg>
                </button>


                <input hidden type="file" #uploadFile (change)="fileUpload($event, item)" />

                <button data-toggle="modal" data-target="#uploadFiles" ngbTooltip="Upload Document" placement="bottom"
                  tooltipClass="my-custom-class" class="btn btn-light btn-sm mr-2" (click)="clearSelectedFiles(item)">
                  <img src="./assets/img/file-upload.svg" style="width: 14px" />

                </button>
                <button type="button" class="btn btn-light btn-sm mr-2"
                  (click)="openPreview(pdfContent, item?.StudentDocPath)"
                  [disabled]="item?.StudentDocPath == '-' || item?.StudentDocPath == ''" ngbTooltip="View Document"
                  placement="bottom" tooltipClass="my-custom-class">
                  <img src="./assets/img/view2.svg" style="width: 14px" />
                </button>

                <button type="button" class="btn btn-light btn-sm mr-2" placement="bottom" [ngbPopover]="validation11"
                  [disabled]="item?.StudentDocPath == '-'|| item?.StudentDocPath == '' " ngbTooltip="Validation"
                  placement="bottom" tooltipClass="my-custom-class">
                  <img *ngIf="item?.AgentValidation == '-' || item?.AgentValidation == null"
                    src="./assets/img/tick-g.svg" style="width: 14px" />
                  <img *ngIf="item?.AgentValidation == 'validated'" src="./assets/img/ticker-green.svg"
                    style="width: 14px" />
                  <img *ngIf="item?.AgentValidation == 'revalidate'" src="./assets/img/ticker-red.svg"
                    style="width: 14px" />
                </button>
                <button type="button" class="btn btn-light btn-sm mr-2" placement="left" [ngbPopover]="comments11"
                  ngbTooltip="Add Comment" tooltipClass="my-custom-class" placement="bottom" [disabled]="disabled">
                  <img src="./assets/img/comments.svg" style="width: 14px" />
                </button>
              </div>
            </div>

            <mat-expansion-panel *ngIf="item?.DocComments?.length > 0">
              <mat-expansion-panel-header>
                <mat-panel-title> Comments </mat-panel-title>
              </mat-expansion-panel-header>

              <div class="comments-section" *ngFor="let i of item?.DocComments">
                <div class="admin-comment" *ngIf="i?.Admin == true">
                  <div class="d-flex">
                    <div>
                      <div *ngIf="i?.AgentName" class="pic mr-1 float-left text-center">
                        <img *ngIf="i?.ProfilePhoto != null" src="{{ i?.ProfilePhoto }}" style="width: 100%;
                        height: 100%;
                        object-fit: cover;
                        border-radius: 100px;" />

                        <p [ngStyle]="{ background: i?.AgentColor }" class="initials" *ngIf="i?.ProfilePhoto == null">
                          {{ i?.AgentInitials }}
                        </p>
                      </div>
                    </div>
                    <div class="pl-2 pr-2 d-flex flex-column flex-grow-1">
                      <div class="d-flex">
                        <div class="sub-header-sm mr-3 flex-fill">
                          {{ i?.AgentName }}
                        </div>
                        <div class="sub-date float-right ml-auto" style="white-space: nowrap">
                          {{ i?.DateTime | date: "medium" }}
                        </div>
                      </div>
                      <div class="sub-text flex-grow-1">{{ i?.Text }}</div>
                    </div>
                  </div>
                </div>

                <div class="agent-comment" *ngIf="i?.Admin == false">
                  <div class="d-flex">
                    <div>
                      <div *ngIf="i?.AgentName" class="pic mr-1 float-left text-center">
                        <img *ngIf="i?.ProfilePhoto != null" style="width: 100%;
                        height: 100%;
                        object-fit: cover;
                        border-radius: 100px;" src="{{ i?.ProfilePhoto }}" />

                        <p [ngStyle]="{ background: i?.AgentColor }" class="initials" *ngIf="i?.ProfilePhoto == null">
                          {{ i?.AgentInitials }}
                        </p>
                      </div>
                    </div>
                    <div class="pl-2 pr-2 d-flex flex-column">
                      <div class="d-flex">
                        <div class="sub-header-sm mr-3">{{ i?.AgentName }}</div>
                        <div class="sub-date float-right ml-auto" style="white-space: nowrap">
                          {{ i?.DateTime | date: "medium" }}
                        </div>
                      </div>
                      <div class="sub-text">{{ i?.Text }}</div>
                    </div>
                  </div>
                </div>
                <div style="clear: both"></div>
              </div>
            </mat-expansion-panel>
          </div>
          <div class="py-2">
            <button data-toggle="modal" data-target="#exampleModal4" ngbTooltip="Add New Document" placement="right"
              tooltipClass="my-custom-class" class="btn btn-primary btn-sm" (click)="clearData()"
              style="padding: 0 5px">
              <img src="./assets/img/add-w.svg" style="width: 10px" />
              Add
            </button>
          </div>
        </div>
      </div>
      <div *ngIf="validationStatus == true" class="text-center mb-2">
        <button class="btn btn-success btn-sm mt-2" disabled style="width: 180px">
          <img src="./assets/img/green-tick-4.svg" style="width: 16px" />
          Validation Completed
        </button>
      </div>
      <div *ngIf="validationStatus == false" class="text-center mb-2">
        <button class="btn btn-danger btn-sm mt-2" disabled style="width: 180px">
          <img src="../assets/img/close-r.svg" style="width: 16px" /> Not
          Validated
        </button>
      </div>


      <div class="p-3">
        <div class="header-md">
          Comments
          <span style="font-size: 12px">{{
            "(" + (leadInfo?.LeadComments?.length || "0") + ")"
            }}</span>
        </div>
        <div class="comments-block" style="background: #fff">
          <div class="mb-3 pb-1 ct pr" style="background: #f5f5f5; border-radius: 5px">
            <textarea class="form-control ct p-2 mb-1" style="background: #f5f5f5" id="leadComments" rows="2"
              placeholder="Write comments here..."></textarea>
            <button type="button" class="btn btn-primary btn-sm mr-2 mb-1 float-right" style="padding: 0 5px"
              (click)="postCommentsV2(leadInfo, 'leadComments')">
              Submit
            </button>
            <div style="clear: both"></div>
          </div>
          <div class="m-h200">
            <div class="d-flex mb-2 mt-1" *ngFor="let j of leadInfo?.LeadComments">
              <div class="d-flex border-bottom pt-1" style="width: 100%">
                <div class="d-flex" style="width:100%">
                  <div class="">
                    <div *ngIf="j?.AgentName" class="pic mr-1 float-left text-center">
                      <img *ngIf="j?.ProfilePhoto != null" style="width: 100%;
                      height: 100%;
                      object-fit: cover;
                      border-radius: 100px;" src="{{ j?.ProfilePhoto }}" />

                      <p [ngStyle]="{ background: j?.AgentColor }" class="initials" *ngIf="j?.ProfilePhoto == null">
                        {{ j?.AgentInitials }}
                      </p>
                    </div>
                  </div>
                  <div class="d-flex flex-column">
                    <div class="flex-grow-1">
                      <div class="sub-header-sm mr-3 mt-1 pb-1 flex-fill">
                        {{ j?.AgentName }}
                      </div>
                    </div>
                    <div class="sub-text flex-grow-1 pb-2">
                      {{ j?.Text }}
                    </div>
                  </div>
                  <div class="sub-date float-right ml-auto" style="white-space: nowrap">
                    {{ j?.DateTime | date: "medium" }}
                  </div>
                </div>


              </div>

            </div>
          </div>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="Communication" *ngIf="companyConfig?.voipName" style="position: relative">
      <!-- <div class="mx-2 my-2">
      </div>
      <div xxstyle="position:absolute; left:15px;right:15px;bottom:0; height:100px;">
        <div class="mb-3 pb-1 ct pr" style="background:#f5f5fa;border-radius: 10px;
          margin: 0 20px 0 10px;">
          <textarea class="form-control ct p-2 mb-1" style="background: #f5f5f5" id="textMessage" rows="2"
            placeholder="Write your message here..."></textarea>
          <button type="button" class="btn btn-link btn-sm mr-2 mb-1 float-right" style="padding: 0 5px"
            (click)="sendSms(sideViewerData, 'textMessage')">
            <img src="../../../assets/img/send-message.svg" class="img12">
          </button>
          <div style="clear: both"></div>
        </div>
      </div>
      <div class="p-3">
        <div class="header-md">
          Total Messages
          <span style="font-size: 12px">{{
            "(" + (smsList?.length || "0") + ")"
            }}</span>
        </div>
        <div class="comments-block" style="background: #fff">
          <div class="dyn-height">
            <div class="d-flex mb-0 mt-1" *ngFor="let j of smsList">
              <div class="d-flex pt-1" style="width: 100%">
                <div style="width:100%">

                  <div class="admin-comment"
                    style="border:1px solid #f5e7e7;border-radius:10px; border-bottom-right-radius: 0;"
                    *ngIf="j.Direction == 'inbound'">
                    <div class="d-flex">
                      <div>
                        <div class="pic mr-1 float-left text-center">
                          <img src="../../../assets/img/student.svg" style="width: 100%;
                          height: 100%;
                          object-fit: cover;
                          border-radius: 100px;" />

                        </div>
                      </div>
                      <div class="pl-2 pr-2 d-flex flex-column flex-grow-1">
                        <div class="d-flex">
                          <span class="header-12">Student</span>

                          <div class="sub-date float-right ml-auto" style="white-space: nowrap">
                            {{ j.DateTime | date: "medium" }}
                          </div>
                        </div>
                        <div class="sub-text flex-grow-1">{{ j.Text }}</div>
                      </div>
                    </div>
                  </div>

                  <div class="agent-comment"
                    style="border-radius:10px;border-bottom-left-radius: 0;border:1px solid #def3f7"
                    *ngIf="j.Direction == 'outbound'">
                    <div class="d-flex">
                      <div>
                        <div class="pic mr-1 float-left text-center">
                          <img style="width: 100%;
                          height: 100%;
                          object-fit: cover;
                          border-radius: 100px;" src="../../../assets/img/admin-comments.svg" />

                        </div>
                      </div>

                      <div class="pl-2 pr-2 d-flex flex-column">
                        <div class="d-flex">
                          <span class="header-12">Agent</span>
                          <div class="sub-date float-right ml-auto" style="white-space: nowrap">
                            {{ j.DateTime | date: "medium" }}
                          </div>
                        </div>
                        <div class="sub-text" style="width:315px;line-height: 125%;">{{ j.Text }}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> -->
    </mat-tab>
    <mat-tab label="Activity">
      <div class="mx-2 my-2">
        <div class="alert alert-info mb-0 p-2 d-flex">
          <div class="" style="line-height:200%">
            {{ leadInfo?.Activities.length }} Actions taken
          </div>
          <div class="ml-auto" style="width:180px;">
            <input class="searchActivity" type="text" style="font-size:13px;" class="form-control search-input"
              placeholder="Search" [(ngModel)]="activityfilterString"
              (input)="onInputActivity(leadInfo?.Activities?.length)" />
          </div>
        </div>
        <div *ngFor="let i of leadInfo?.Activities | slice: 0:minItems | searchFilterTable: activityfilterString">
          <div class="myactivity d-flex align-items-start">
            <div class="mr-auto d-flex">
              <div>
                <img class="img16 mr-2" src="./assets/img/tick-green.svg" />
              </div>
              <div>{{ i?.description }} </div>
            </div>
            <div class="ml-auto text-right" style="width:230px;"><span class="sm-text ml-2">{{
                i?.createdat | date: "medium"
                }}</span></div>


          </div>
          <hr style="margin: 0; padding: 0" />
        </div>
        <div class="loadmore" *ngIf="minItems < leadInfo?.Activities.length"
          (click)="showmore(leadInfo?.Activities.length)">
          Show more..
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
</mat-sidenav>


<mat-sidenav id="sideNav" #callingSideNav mode="side" class="pr" opened="false" position="end" class="pf w45 z-top">
  <div class="modal-header component-header d-flex pf w45" style="right: 0; top: 0; z-index: 9999">
    <div class="d-flex" style="width:100%">
      <div class="modal-title component-title py-3 pl-2 mr-auto ">Calling Screen</div>
      <div class="ml-auto close hand  p-3" (click)="callingSideNav.toggle()">
        <img src="../../../../assets/img/close.svg" class="img10" />
      </div>
    </div>
  </div>
  <div style="clear:both"></div>
  <div style="margin-top:60px;">
    <div class="d-flex p-5 align-items-center flex-column justify-content-center pr">
      <div style="position: absolute;right:25px;top:25px;" class="pinkx ">
      </div>
      <div class="avatar-frame d-flex align-items-center justify-content-center"
        *ngIf="(leadInfoCall?.gender == 'female' || leadInfoCall?.gender == 'Female' || leadInfoCall?.gender == 'FEMALE');else showMaleAvatar">
        <img src="../../../../assets/images/female-avatar.svg" style="width:150px" />
      </div>
      <ng-template #showMaleAvatar>
        <div class="avatar-frame d-flex align-items-center justify-content-center">
          <img src="../../../../assets/images/male-avatar.svg" style="width:150px" />
        </div>
      </ng-template>

      <h5 class="py-3" *ngIf="callStatus != 'inProgress'">{{ callStatus }}</h5>
      <p *ngIf="callStatus == 'inProgress'" class="custom-call-timer p-3" style="text-align: center">
        <countup-timer [countUpTimerConfig]="testConfig"></countup-timer>
      </p>
    </div>
    <div class="px-5 py-2 d-flex  align-items-center justify-content-center">

      <div class="icon-frame mr-3" ngbTooltip="Start Call" placement="top" tooltipClass="my-custom-class"
        (click)="openCallModal()">
        <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
          <g id="icon">
            <g>
              <path class="g-svg-2"
                d="M26,29h-.17C6.18,27.87,3.39,11.29,3,6.23c-.13-1.65,1.1-3.1,2.75-3.23,0,0,0,0,0,0h5.51c.82,0,1.56,.5,1.86,1.26l1.52,3.74c.3,.74,.13,1.59-.44,2.16l-2.13,2.15c.68,3.87,3.71,6.91,7.58,7.6l2.17-2.15c.58-.56,1.43-.72,2.17-.41l3.77,1.51c.75,.31,1.24,1.05,1.23,1.86v5.28c0,1.66-1.34,3-3,3ZM6,5c-.55,0-1,.45-1,1v.08c.46,5.92,3.41,19.92,20.94,20.92,.55,.03,1.03-.39,1.06-.94,0,0,0,0,0,0v-5.34l-3.77-1.51-2.87,2.85-.48-.06c-8.7-1.09-9.88-9.79-9.88-9.88l-.06-.48,2.84-2.87-1.5-3.77H6Z" />
              <rect class="g-svg-1" width="32" height="32" />
            </g>
          </g>
        </svg>
      </div>
      <div class="icon-frame mr-3" ngbTooltip="Stop Call" placement="top" tooltipClass="my-custom-class"
        (click)="stopCurrentCall()">
        <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
          <g id="icon">
            <g>
              <path class="r-svg-2"
                d="M9.19,18.56c-2.46-3.72-3.9-8.03-4.19-12.48v-.08c0-.55,.45-1,1-1h5.28l1.5,3.77-2.84,2.87,.06,.48c.25,1.46,.74,2.87,1.46,4.17l1.46-1.46c-.4-.8-.68-1.64-.84-2.52l2.13-2.15c.57-.57,.74-1.42,.44-2.16l-1.52-3.74c-.3-.76-1.04-1.26-1.86-1.26H5.76c-1.65,.13-2.89,1.57-2.76,3.22,0,0,0,0,0,0,.35,4.93,2.01,9.68,4.79,13.77l1.4-1.44Z" />
              <path class="r-svg-2"
                d="M27.77,18.86l-3.77-1.51c-.74-.31-1.59-.15-2.17,.41l-2.17,2.15c-1.54-.27-2.99-.92-4.21-1.91L30,3.41l-1.41-1.41L2,28.59l1.41,1.41,7-7c3.38,3.18,8.28,5.62,15.39,6h.2c1.66,0,3-1.34,3-3v-5.28c0-.81-.48-1.55-1.23-1.86Zm-.77,7.14v.06c-.03,.55-.51,.97-1.06,.94,0,0,0,0,0,0-6.51-.37-11-2.54-14.11-5.42l2.17-2.14c1.65,1.43,3.71,2.32,5.88,2.56l.48,.06,2.87-2.85,3.77,1.51v5.28Z" />
              <rect class="r-svg-1" width="32" height="32" />
            </g>
          </g>
        </svg>
      </div>
      <div class="icon-frame mr-3" ngbTooltip="Message" placement="top" tooltipClass="my-custom-class">
        <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
          <g id="icon">
            <g>
              <path class="grey-svg-2"
                d="M28,6H8c-1.2,0-2,.8-2,2v14c0,1.2,.8,2,2,2h8v-2H8V8H28v14h-7.2l-4.8,6.8,1.6,1.2,4.2-6h6.2c1.2,0,2-.8,2-2V8c0-1.2-.8-2-2-2Z" />
              <rect id="_Transparent_Rectangle_" data-name=" Transparent Rectangle " class="grey-svg-1" width="32"
                height="32" />
            </g>
          </g>
        </svg>
      </div>
      <div class="icon-frame mr-3" ngbTooltip="Video" placement="top" tooltipClass="my-custom-class">
        <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
          <g id="icon">
            <g>
              <path class="grey-svg-2"
                d="M21,26H4c-1.1,0-2-.9-2-2V8c0-1.1,.9-2,2-2H21c1.1,0,2,.9,2,2v4.06l5.42-3.87c.45-.32,1.07-.22,1.39,.23,.12,.17,.18,.37,.19,.58v14c0,.55-.45,1-1,1-.21,0-.41-.07-.58-.19l-5.42-3.87v4.06c0,1.1-.9,2-2,2ZM4,8V24H21v-6c0-.55,.45-1,1-1,.21,0,.41,.07,.58,.19l5.42,3.87V10.94l-5.42,3.87c-.45,.32-1.07,.22-1.39-.23-.12-.17-.18-.37-.19-.58v-6H4Z" />
              <rect class="grey-svg-1" width="32" height="32" />
            </g>
          </g>
        </svg>
      </div>
      <div class="icon-frame mr-3" ngbTooltip="Mute" placement="top" tooltipClass="my-custom-class">
        <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
          <g id="icon">
            <g>
              <path class="grey-svg-2"
                d="M23,14v3c0,3.87-3.13,7-7,7s-7-3.13-7-7v-3h-2v3c0,4.58,3.45,8.43,8,8.94v2.06h-4v2h10v-2h-4v-2.06c4.55-.51,8-4.36,8-8.94v-3h-2Z" />
              <path class="grey-svg-2"
                d="M16,22c2.76,0,5-2.24,5-5V7c0-2.76-2.24-5-5-5s-5,2.24-5,5v10c0,2.76,2.24,5,5,5Zm-3-15c0-1.66,1.34-3,3-3s3,1.34,3,3v10c0,1.66-1.34,3-3,3s-3-1.34-3-3V7Z" />
              <rect class="grey-svg-1" width="32" height="32" />
            </g>
          </g>
        </svg>
      </div>
      <div class="icon-frame" ngbTooltip="Invite" placement="top" (click)="openDialog()" tooltipClass="my-custom-class">
        <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
          <g id="icon">
            <g>
              <polygon class="grey-svg-2"
                points="32 14 28 14 28 10 26 10 26 14 22 14 22 16 26 16 26 20 28 20 28 16 32 16 32 14" />
              <path class="grey-svg-2"
                d="M12,4c2.76,0,5,2.24,5,5s-2.24,5-5,5-5-2.24-5-5,2.24-5,5-5m0-2c-3.87,0-7,3.13-7,7s3.13,7,7,7,7-3.13,7-7-3.13-7-7-7Z" />
              <path class="grey-svg-2"
                d="M22,30h-2v-5c0-2.76-2.24-5-5-5h-6c-2.76,0-5,2.24-5,5v5H2v-5c0-3.87,3.13-7,7-7h6c3.87,0,7,3.13,7,7v5Z" />
              <rect class="grey-svg-1" width="32" height="32" />
            </g>
          </g>
        </svg>
      </div>
    </div>
    <!-- old -->
    <div class="component-title ml-4 pt-5 pb-3">Student Details</div>
    <div class="mx-4 pr-4 py-3 student-details">
      <div class="row">
        <div class="col-md-3 pr-0 mb-1">
          <div class="form-group student-details-block2">
            <label>Name</label>
            <div class="sd-value">{{leadInfoCall?.fullname || ''}}</div>
          </div>
        </div>
        <div class="col-md-3 pr-0 mb-1">
          <div class="form-group student-details-block2">
            <label>Phone</label>
            <div class="sd-value" *ngIf="leadInfoCall?.countrycode != '-';else showdash">
              {{"+"+leadInfoCall?.countrycode.replace("+","")}} {{leadInfoCall?.phone}}</div>
            <ng-template #showdash>
              <div class="sd-value">
                <span>-</span>
              </div>
            </ng-template>

          </div>
        </div>
        <div class="col-md-6 pr-0 mb-1">
          <div class="form-group student-details-block2">
            <label>Email</label>
            <div class="sd-value">{{leadInfoCall?.email || "-"}}</div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-3 pr-0 mb-1">
          <div class="form-group student-details-block2">
            <label>Student Code</label>
            <div class="sd-value">{{leadInfo?.studentcode || '-'}}</div>
          </div>
        </div>
        <div class="col-md-3 pr-0 mb-1">
          <div class="form-group student-details-block2">
            <label>Enrollment Status</label>
            <div class="sd-value">{{leadInfo?.enrolmentstatus || "-"}}</div>
          </div>
        </div>
        <div class="col-md-3 pr-0 mb-1">
          <div class="form-group student-details-block2">
            <label>Fee Status</label>
            <div class="sd-value">{{leadInfo?.feestatus || "-"}}</div>
          </div>
        </div>
        <div class="col-md-3 pr-0 mb-1">
          <div class="form-group student-details-block2">
            <label>Assigned To</label>
            <div class="sd-value">{{leadInfoCall?.AgentName || "-"}}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="component-title ml-4 py-3">Give your valuable feedback</div>
    <div class="py-3 student-details">
      <div class="row" style="padding:0;margin:0;">
        <div class="col-md-12">
          <div class="radio-btn-g">
            <div class="radio-btn-list">
              <mat-radio-group aria-label="Select an option" class="example-radio-group " [(ngModel)]="newStatus">

                <ng-container *ngFor="let item of feedback">
                  <mat-radio-button class="example-radio-button" [value]="item?.value" *ngIf="!item?.callingScreen">
                    {{ item?.name }} {{ item?.value }}</mat-radio-button>
                </ng-container>

              </mat-radio-group>
            </div>
            <p class="px-2 py-3">Please select which you like</p>
          </div>
        </div>
      </div>
    </div>

    <div class="component-title ml-4 pt-5 pb-3" *ngIf="newStatus == 'qualifiedhotlead' ">Counselling Date</div>
    <div class="mx-4 pr-4 py-3 student-details" *ngIf="newStatus == 'qualifiedhotlead' ">
      <ion-input type="datetime-local" [(ngModel)]="counsellingDate"></ion-input>
    </div>
    <div class="component-title ml-4 py-3">Comments</div>
    <div class="mx-4">
      <textarea class="form-control p-3 custom-comments" id="exampleFormControlTextarea1" rows="3"
        placeholder="Start Typing....." [(ngModel)]="commentText"></textarea>
    </div>

    <div class="ml-4 py-3">
      <div class="d-flex my-3">
        <div class="mr-5"><button class="btn btn-primary btn-lg" (click)="updateComments();"
            [disabled]="!callSid">Update</button></div>
        <div><button class="btn btn-outline-secondary btn-lg" (click)="resetCallingScreen();">Reset</button></div>
      </div>
    </div>
  </div>
</mat-sidenav>

<ng-template id="pdfContent" class="modal-x" #pdfContent let-modal>
  <div id="progressBar">
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </div>
  <ngx-doc-viewer [url]="docUrl" viewer="google" googleCheckContentLoaded="true" googleCheckInterval="5000"
    googleMaxChecks="15" style="width: 100%; height: 93vh" (loaded)="contentLoaded()">
  </ngx-doc-viewer>
</ng-template>

<div class="modal fade" id="exampleModal4" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content" style="width: 440px">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">
          Add New Document
        </h5>
        <button type="button" #closeButton class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="d-flex">
          <div class="form-group mr-2">
            <label for="exampleInputEmail1" style="font-size: 13px">Document Name</label>
            <input [(ngModel)]="customDocName" type="text" class="form-control" style="
                padding: 0px 5px;
                width: 300px;
                font-size: 13px;
              " />
          </div>
          <div>
            <input hidden type="file" #uploadFile2 (change)="uploadCustomDocument($event)" />
            <button type="button" class="btn btn-secondary btn-sm mr-2" style="
                padding: 5px 10px;
                margin-top: 22px;
                font-size: 13px;
              " (click)="uploadFile2.click()">
              <img class="img12" src="../../../assets/img/upload-w.svg" />
            </button>
          </div>
          <div>
            <button (click)="addCustomDocument()" type="button" class="btn btn-secondary btn-sm" style="
                padding: 5px 10px;
                margin-top: 22px;
                font-size: 13px;
              ">
              Add
            </button>
          </div>

        </div>

        <div class="p-3 text-center docUploadError" style="color: green; font-size: 14px; font-weight: bold">
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="uploadFiles" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content" style="width: 400px">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">
          Upload Documents
        </h5>
        <button type="button" id="closeButton4" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div *ngIf="uploadSuccess=='none'">
          <div class="d-flex" *ngIf="!docUploadStatus">
            <div>
              <button (click)="filesInput.click()" type="button" class="btn btn-secondary btn-sm">
                Select Files
              </button>
              <input type="file" #filesInput id="filesInput" (change)="getFileDetails($event)" style="display: none;">
            </div>
            <div class="add-docs-item-sub ml-auto">
              <div class="txt-oflow-lg text-right">

                Selected Files: {{selectedFiles?.length}}
              </div>
            </div>
          </div>

          <div *ngIf="selectedFiles?.length==0" class="d-flex p-3 flex-column">
            <div class="text-center p-3"><img src="../../../assets/img/upload-file-2.png" /></div>
            <div class="text-center">
              Upload below supported documents only <br>
              <span style="font-size:18px; line-height:180%">.doc .pdf .png .jpg .jpeg</span>
            </div>
          </div>

          <div *ngIf="selectedFiles?.length>0">
            <div *ngIf="!docUploadStatus" class="py-2 mb-3" style="height:160px;overflow:auto">
              <div *ngFor="let i of selectedFiles;let indexOfelement=index;"
                class="d-flex add-docs-item align-items-center py-2">
                <div class="">
                  <img class="img12 mr-1 lh160 float-left" src="../../../assets/img/docs-r.svg" />
                  <div class="txt-oflow-lg" style="display:inline-block;line-height:100%">{{ i?.name }}</div>
                  <br />
                </div>
                <div class="ml-auto">
                  <button style="background: none;" (click)="deleteFile(indexOfelement)">
                    <img class="img12" src="../../../assets/img/delete.svg" />
                  </button>
                </div>
              </div>
            </div>
          </div>




          <div *ngIf="!docUploadStatus">
            <div class="d-flex">
              <div *ngIf="selectedFiles.length > 0">
                <div *ngIf="!docUploadStatus">
                  <button (click)="filesInput.click()" type="button" class="btn btn-secondary btn-sm">
                    + Add more
                  </button>

                  <input type="file" #filesInput id="filesInput" (change)="getFileDetails($event)"
                    style="display: none;">
                </div>
              </div>
              <div class="ml-auto">
                <button *ngIf="selectedFiles.length > 0" #uploadFilesButton type="button"
                  class="btn btn-primary btn-sm mr-2" (click)="uploadAttachments()">
                  Upload
                </button>
                <button (click)="cancelUpload()" type="button" class="btn btn-light btn-sm">
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="!docUploadStatus">
          <div class="d-flex p-3 flex-column" *ngIf="uploadSuccess=='success'">
            <div class="text-center p-3"><img src="../../../assets/img/upload-success.png" /></div>
            <div class="text-center">
              <b>Upload Success</b><br>
              <div style="font-size:14px; line-height:130%;padding:5px">Total {{selectedFiles?.length}} files
                uploaded successfully
              </div>
            </div>
          </div>

          <div class="d-flex p-3 flex-column" *ngIf="uploadSuccess=='failure'">
            <div class="text-center p-3"><img src="../../../assets/img/upload-failed.png" /></div>
            <div class="text-center">
              <b>Upload Failed</b><br>
              <div style="font-size:14px; line-height:130%;padding:5px">Files not uploaded. Please check
                document extensions,
                upload supported documents</div>
            </div>
          </div>


        </div>
        <div>
          <mat-progress-bar mode="indeterminate" *ngIf="docUploadStatus"></mat-progress-bar>
        </div>
        <div class="p-3 text-center docUploadError" *ngIf="uploadSuccess=='none'"
          style="color: green; font-size: 14px; font-weight: bold">
          {{docUploadError2}}
        </div>

      </div>
    </div>
  </div>
</div>

<ng-template #assignLeadsModal let-modal>
  <div class="d-flex mat-modal-header p-4" style="width:100%">
    <div class="modal-title component-title mr-auto">Assign to Agent</div>
    <button type="button" class="ml-auto close hand" id="closeAssignButton" #closeAssignButton
      (click)="modal.dismiss('Cross click')">
      <img src="../../../../assets/img/close.svg" class="img10" />
    </button>
  </div>
  <div>
    <div class="d-flex align-items-center justify-content-center">
      <img src="../../../../assets/images/lead-assign.png" />
    </div>
    <div class="px-4">
      <div class="py-4 reg-bold-text ">
        {{ leadIds?.length }} leads are selected to assign.
      </div>
      <div class="d-flex">
        <div class="form-group" style="width: 100%">
          <select class="form-control" style="height:50px" id="exampleFormControlSelect1" [(ngModel)]="defaultAgent"
            (change)="agentSelected($event.target.value)">
            <option value="default" disabled selected>
              Select Agent
            </option>
            <option *ngFor="let agent of agentList" [value]="agent?.Mailid">
              {{ agent?.FirstName + " " + agent?.LastName }}
            </option>
          </select>
        </div>
      </div>
      <div class="d-flex align-items-center justify-content-center">
        <div class="mt-3 mb-5">
          <button type="button" class="btn btn-primary btn-lg" (click)="assignLEads()">Assign</button>
        </div>
      </div>
    </div>
  </div>
</ng-template>