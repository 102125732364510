import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { SharedService } from 'src/app/shared/shared.service';
import { UhLeadsService } from '../uhleads.service';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import * as XLSX from "xlsx";
import { ProjectService } from '../project-management/project.service';
import { UploadService } from './upload.service';
import { Exceldata } from "../marketing/smsmarketing/models/ExecelData.model";
import { Exceldata2 } from "../marketing/smsmarketing/models/ExecelData.model2";
import { ReferralData } from '../marketing/smsmarketing/models/ReferralData.model';
import * as xlsx from "xlsx";
import { ErrorHandlerService } from 'src/app/error-handler.service';
import { MarketingService } from '../marketing/marketing.service';
import { ReferreeService } from '../referree/referree.service';
import { LeadsDashboardService } from '../leads-dashboard/leads-dashboard.service';

import { environment } from "src/environments/environment";

type AOA = any[][];
@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss']
})
export class UploadComponent implements OnInit {

  displayData: any = [];
  colList: any = [];
  data: AOA = [];
  projectId: any;
  companyId: any;
  userData: any;
  admin: any;
  projectName: any;
  e: any;
  public filePath: any;
  public fileData: Exceldata2[] = [];
  public fileData2: Exceldata[] = [];
  public fileData3: ReferralData[] = [];

  public uploaded: boolean = false;
  public error: boolean = false;
  public insertCount: number = 0;
  public errorMessage: string = "";
  fileToUpload: any;
  dashboardType: string = "old";
  config: any;
  columns: any;
  isLoading = false;
  menuId: number;

  constructor(
    public sharedService: SharedService,
    public uhleadsService: UhLeadsService,
    private route: ActivatedRoute,
    private projectService: ProjectService,
    private uploadService: UploadService,
    private handleError: ErrorHandlerService,
    private router: Router,
    private marketingService: MarketingService,
    private ReferreeService: ReferreeService,
    public LeadsDashboardService: LeadsDashboardService
  ) { }

  ngOnInit(): void {
    this.userData = JSON.parse(localStorage.getItem("userData"));
    // console.log(this.userData);

    this.route.paramMap.subscribe((params: ParamMap) => {
      this.projectId = +params.get("id");
      this.menuId = +params.get("menuid");

      this.LeadsDashboardService.uploadFileInfo.subscribe((res: any) => {
        if (res) {
          console.log(res);
          this.projectId = res.projectId;
          this.companyId = res.companyId;
          this.dashboardType = "new";
          this.LeadsDashboardService.getProjectInfo({
            projectId: this.projectId,
          }).subscribe((projectInfo: any) => {
            console.log("Project Info: ", projectInfo.data[0]);
          });
          this.LeadsDashboardService.getConfig({
            projectId: this.projectId,
          }).subscribe((config: any) => {
            this.config = config.ProjectInfo[0].config[0];
            this.columns = this.config.columns;
            console.log("Columns: ", this.columns);
          });
          this.sharedService.pageEvent.emit({
            pageName: "Upload Leads ",
          });
        } else {
          this.dashboardType = "old";

          this.sharedService
            .getProjectAdmin(this.projectId)
            .subscribe((admin: any) => {
              this.admin = admin;
              this.projectService.dashboardProjects({ admin: this.admin, companyId: this.userData.companyId, userId: this.userData.id }).subscribe((projects: any) => {
                for (let i = 0; i < projects.length; i++) {
                  if (this.projectId == projects[i].id) {
                    this.projectName = projects[i].Name;
                    this.sharedService.pageEvent.emit({
                      pageName: "Upload Leads - " + this.projectName,
                    });
                  }
                }
              });
            });
        }
      });
      // console.log(this.projectId);
    });
  }

  @ViewChild("fileDropRef", { static: false }) fileDropEl: ElementRef;

  files: any[] = [];

  /**
   * on file drop handler
   */
  onFileDropped($event) {
    this.prepareFilesList($event);
  }

  /**
   * handle file from browsing
   */
  fileBrowseHandler(files) {
    this.fileToUpload = files[0];
    var name: String;
    var ext = files[0].type.substring(files[0].type.lastIndexOf(".") + 1);
    if (ext.toLowerCase() == "sheet") {
      this.displayData = [];
      this.colList = [];
      this.files = [];
      this.prepareFilesList(files);
    } else {
      this.uhleadsService.showWarning("Please upload a valid excel file", "Wrong Format")
    }
  }


  /**
   * Delete file from files list
   * @param index (File index)
   */
  deleteFile(index: number) {
    if (this.files[index].progress < 100) {
      this.uhleadsService.showWarning("Upload in in progress", "Error");
      return;
    }
    this.displayData = [];
    this.colList = [];
    this.files.splice(index, 1);
  }

  /**
   * Simulate the upload process
   */
  uploadFilesSimulator(index: number) {
    setTimeout(() => {
      if (index === this.files.length) {
        return;
      } else {
        const progressInterval = setInterval(() => {
          if (this.files[index].progress === 100) {
            clearInterval(progressInterval);
            this.uploadFilesSimulator(index + 1);
            this.excelFileToJSON(this.files[index]);
          } else {
            this.files[index].progress += 5;
          }
        }, 200);
      }
    }, 1000);
  }

  /**
   * Convert Files list to normal array list
   * @param files (Files List)
   */
  prepareFilesList(files: Array<any>) {
    for (const item of files) {
      item.progress = 0;
      this.files.push(item);
    }
    this.fileDropEl.nativeElement.value = "";
    this.uploadFilesSimulator(0);
  }

  /**
   * format bytes
   * @param bytes (File size in bytes)
   * @param decimals (Decimals point)
   */
  formatBytes(bytes, decimals = 2) {
    if (bytes === 0) {
      return "0 Bytes";
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  //Method to read excel file and convert it into JSON 
  excelFileToJSON(file) {
    try {
      var reader = new FileReader();
      reader.readAsBinaryString(file);
      reader.onload = (e) => {
        var data = e.target.result;
        var workbook = XLSX.read(data, {
          type: "binary",
        });
        var result = {};
        var firstSheetName = workbook.SheetNames[0];
        //reading only first sheet data
        var jsonData = XLSX.utils.sheet_to_json(
          workbook.Sheets[firstSheetName]
        );
        this.displayData = jsonData;
        this.colList = Object.keys(this.displayData[0]);


        console.log(this.colList);
      };
    } catch (e) {
      console.error(e);
    }
  }


  //Method to display the data in HTML Table
  displayJsonToHtmlTable(jsonData) {
    var table = document.getElementById("display_excel_data");
    if (jsonData.length > 0) {
      var htmlData =
        "<tr><th>Student Name</th><th>Address</th><th>Email ID</th><th>Age</th></tr>";
      for (var i = 0; i < jsonData.length; i++) {
        var row = jsonData[i];
        htmlData +=
          "<tr><td>" +
          row["Student Name"] +
          "</td><td>" +
          row["Address"] +
          "</td><td>" +
          row["Email ID"] +
          "</td><td>" +
          row["Age"] +
          "</td></tr>";
      }
      table.innerHTML = htmlData;
    } else {
      table.innerHTML = "There is no data in Excel";
    }
  }

  fileUpload(e) {
    this.e = e;
  }

  backToDashboard() {
    console.log("Going back: ", this.projectName);
    if (this.dashboardType == "new") {
      this.router.navigate(["/" + this.projectId + "/leads"]);
    } else {
      if (this.projectName == "Documents" && this.menuId == 0) {
        this.router.navigate(["/uhleads"]);
      } else if (this.projectName == "Documents" && this.menuId == 62) {
        this.router.navigate(["/socialmedialeads"]);
      } else if (this.projectName == "Documents" && this.menuId == 63) {
        this.router.navigate(["/recruiterleads"]);
      } else if (this.projectName == "Accessque") {
        this.router.navigate(["/accessque"]);
      } else if (this.projectName == "Referrals") {
        this.router.navigate(["/referree"]);
      }
    }
  }

  uploadAttachments() {
    if (this.dashboardType == "new") {
      this.convertLeadsData(this.e);
    } else {
      if (this.projectName == "Documents") {
        this.convertFileCU(this.e);
      } else if (this.projectName == "Accessque") {
        this.convertFileUhub(this.e);
      } else if (this.projectName == "Referrals") {
        this.convertFileRef(this.e);
      } else {
        this.uhleadsService.showError("Project does not exist", "Error");
      }
    }
    // this.deleteFile(0);
  }

  convertLeadsData(e) {
    this.filePath = this.fileToUpload["name"].toString();

    var reader = new FileReader();
    // reader2.readAsArrayBuffer(e.target.files[0]);
    reader.readAsArrayBuffer(this.fileToUpload);

    reader.onload = (eve) => {
      let data = new Uint8Array(reader.result as any);
      let wb = xlsx.read(data, { type: "array" });
      let sheet_name = wb.SheetNames;
      let sheet_data: any[][] = xlsx.utils.sheet_to_json(
        wb.Sheets[sheet_name[0]],
        { header: 1 }
      );
      if (sheet_data[0][0] === "Created Date" && sheet_data.length !== 0) {
        console.log(sheet_data);
        this.fileData = this.convert2dArraytoObject(sheet_data);
        console.log("Excel data: ", this.fileData);

        this.LeadsDashboardService.uploadLeads({
          projectId: this.projectId,
          companyId: this.companyId,
          colData: this.columns,
          leadsData: this.fileData,
          mail: this.userData.mail,
        }).subscribe((res: any) => {
          if (res.status == 200) {
            this.uploaded = true;
            this.error = false;
            this.insertCount = res.count;
            this.uhleadsService.showSuccess(
              "Inserted " + this.insertCount + " records",
              "Upload Success!"
            );
            // this.uhleadsService.showSuccess("Uploaded "+ res.count+ " leads", "Success")
          } else {
            this.uhleadsService.showError("Upload filed", "Erorr!");
          }
        });
      } else {
        this.error = true;
        this.errorMessage = "Please Upload a Valid File";
      }
    };
  }
  convert2dArraytoObject(formatingData) {
    let excelData: any = [];
    for (let inner = 1; inner <= formatingData.length - 1; inner++) {
      let convertData: any = {};

      if (formatingData[inner][0]) {
        for (let i = 0; i < this.columns.length; i++) {
          convertData[this.columns[i].name] = formatingData[inner][i];
        }

        excelData.push(convertData);
      }
    }
    return excelData;
  }

  convertFileCU(e) {
    // this.filePath = e.target.files[0]["name"].toString();
    this.filePath = this.fileToUpload["name"].toString();
    var reader = new FileReader();
    // reader2.readAsArrayBuffer(e.target.files[0]);
    reader.readAsArrayBuffer(this.fileToUpload);
    reader.onload = (eve) => {
      let data = new Uint8Array(reader.result as any);
      let wb = xlsx.read(data, { type: "array" });
      let sheet_name = wb.SheetNames;
      let sheet_data: any[][] = xlsx.utils.sheet_to_json(
        wb.Sheets[sheet_name[0]],
        { header: 1 }
      );
      if (sheet_data[0][0] === "GUID" && sheet_data.length !== 0) {
        this.fileData = this.uhleadsService.convert2dArraytoObject(sheet_data);
        this.uhleadsService
          .postCallData({
            data: this.fileData,
            createdBy: this.userData["mail"],
            ProjectId: this.projectId,
            addType: 'Excel'
          })
          .subscribe((data) => {
            this.insertCount = data["count"];
            this.uhleadsService.showSuccess('Inserted ' + this.insertCount + ' records', 'Upload Success!')
            setTimeout(() => {
              this.uploaded = true;
              this.error = false;
              this.isLoading = false;
              this.deleteFile(0);
              this.uploaded = false;
              this.filePath = "";
            }, 5000);
            // this.router.navigate(['./uhleads'])

          }, (error) => {
            this.handleError.handleError(error)
          });
      } else {
        this.error = true;
        this.errorMessage = "Please Upload a Valid File";
      }
    };
  }

  convertFileUhub(e) {

    this.isLoading = true;
    this.filePath = this.fileToUpload["name"].toString();
    let reader = new FileReader();
    reader.readAsArrayBuffer(this.fileToUpload);

    reader.onload = (eve) => {
      let data = new Uint8Array(reader.result as any);
      let wb = xlsx.read(data, { type: "array" });
      let sheet_name = wb.SheetNames;
      let sheet_data: any[][] = xlsx.utils.sheet_to_json(
        wb.Sheets[sheet_name[0]],
        { header: 1 }
      );
      if (sheet_data[0][0] === "First Name" && sheet_data.length !== 0) {
        this.fileData2 =
          this.marketingService.convert2dArraytoObject(sheet_data);
        this.marketingService
          .postCallData({
            data: this.fileData2,
            createdBy: this.userData["mail"],
            ProjectId: this.projectId,
            type: "Excel",
          })
          .subscribe((data) => {
            this.insertCount = data["count"];
            this.uhleadsService.showSuccess('Inserted ' + this.insertCount + ' records', 'Upload Success!');
            this.uploaded = true;
            this.error = false;
            this.isLoading = false;
            this.deleteFile(0);
            setTimeout(() => {
              this.uploaded = false;
              this.filePath = "";
            }, 5000);
            // this.router.navigate(['./leadsDashboard']);
            // this.getDashboardLeads(this.leadType);
          }, (error) => {
            this.handleError.handleError(error)
          });
      } else {
        this.error = true;
        this.errorMessage = "Please Upload a Valid File";
      }
    };
    // this.uploadFile.nativeElement.value = "";
  }

  convertFileRef(e) {
    this.isLoading = true;
    // this.filePath = e.target.files[0]["name"].toString();
    this.filePath = this.fileToUpload["name"].toString();
    let reader = new FileReader();
    // reader.readAsArrayBuffer(e.target.files[0]);
    reader.readAsArrayBuffer(this.fileToUpload);
    reader.onload = (eve) => {
      let data = new Uint8Array(reader.result as any);
      let wb = xlsx.read(data, { type: "array" });
      let sheet_name = wb.SheetNames;
      let sheet_data: any[][] = xlsx.utils.sheet_to_json(
        wb.Sheets[sheet_name[0]],
        { header: 1 }
      );
      if (sheet_data[0][0] === "FirstName" && sheet_data.length !== 0) {
        this.fileData3 = this.ReferreeService.convert2dArraytoObject(sheet_data);
        this.ReferreeService.uploadLeads({
          data: this.fileData3,
          CreatedBy: this.userData.mail,
          ProjectId: this.projectId,
          addType: 'Excel'
        }).subscribe((data) => {
          this.insertCount = data["count"];
          this.uhleadsService.showSuccess('Inserted ' + this.insertCount + ' records', 'Upload Success!');
          this.uploaded = true;
          this.error = false;
          this.isLoading = false;
          this.deleteFile(0);
          setTimeout(() => {
            this.uploaded = false;
            this.filePath = "";
          }, 5000);
          // this.router.navigate(['./referree']);
        }, (error) => {
          this.handleError.handleError(error)
        });
      } else {
        this.error = true;
        this.errorMessage = "Please Upload a Valid File";
      }
    };
    // this.uploadFile.nativeElement.value = "";
  }
}
