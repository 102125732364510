import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NavbarsRoutingModule } from './navbars-routing.module';
import { AiAnalyticsComponent } from './ai-analytics/ai-analytics.component';
import { LandingpageComponent } from '../landingpage.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HomepageComponent } from './homepage/homepage.component';
import { LipsyncComponent } from './lipsync/lipsync.component';
import { RatingModule } from 'ngx-bootstrap/rating';
import { B2bComponent } from '../b2b/b2b.component';
import { AivoicebotComponent } from './aivoicebot/aivoicebot.component';
import { CrmComponent } from './crm/crm.component';
import { SoarComponent } from './soar/soar.component';

@NgModule({
  declarations: [
    AiAnalyticsComponent,
    LandingpageComponent,
    HomepageComponent,
    LipsyncComponent,
    B2bComponent,
    CrmComponent,
    AivoicebotComponent,
    SoarComponent,
  ],
  imports: [
    CommonModule,
    NavbarsRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    RatingModule.forRoot(),
  ]
})
export class NavbarsModule { }
