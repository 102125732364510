<mat-sidenav id="sideNav" #sideNav mode="side" class="pr" opened="false" position="end" class="pf w45 z-top">
  <div class="modal-header d-flex pf w45" style="right: 0; top: 0; z-index: 9999">
    <div class="d-flex align-items-center">
      <div class="close hand" (click)="sideNav.toggle()">
        <span aria-hidden="true">&times;</span>
      </div>
      <div class="modal-title mr-auto ml-2">Overview</div>
    </div>
  </div>
  <mat-tab-group class="mt-5 sideDataViewer" #sidecartabgroup animationDuration="0ms">
    <mat-tab label="Call Details">
      <div class="alert alert-info m-2">
        {{ sideViewerData?.CallDetails?.length }} Calls
      </div>
      <div *ngFor="let i of sideViewerData.CallDetails" class="mx-2 my-2">
        <table class="table table-borderless table-md caller-table my-1">
          <tr>
            <td>Called by</td>
            <td>{{ i?.AgentName }}</td>
          </tr>
          <tr>
            <td>Date & Time</td>
            <td>{{ i?.DateTime | date: "dd MMM, yyyy hh:mm" }}</td>
          </tr>
          <tr>
            <td>Status</td>
            <td>
              
              <span>{{getCallStatus(i.CallStatus) || '-' }}</span>
              <!-- <span *ngIf="i.CallStatus == 'pending'">Pending</span>
              <span *ngIf="i.CallStatus == 'uncontactable'">Uncontactable</span>
              <span *ngIf="i.CallStatus == 'notinterested'">Not Interested</span>
              <span *ngIf="i.CallStatus == 'completed'">Completed</span>
              <span *ngIf="i.CallStatus == 'callback'">Call Back</span>
              <span *ngIf="i.CallStatus == 'informed'">Informed</span>
              <span *ngIf="i.CallStatus == 'called'">Called</span>
              <span *ngIf="i.CallStatus == 'callfailed'">Wrong Number</span>
              <span *ngIf="i.CallStatus == 'deferredintake'">Deferred Intake</span>
              <span *ngIf="
                                      i.CallStatus == 'callnotanswered' ||
                                      i.CallStatus == 'notanswered'
                                    ">Not Answered</span>
              <span *ngIf="
                                      i.CallStatus == 'deferred' ||
                                      i.CallStatus == 'Deferred'
                                    ">Deferred Intake</span> -->
            </td>
          </tr>
          <tr>
            <td>Direction</td>
            <td *ngIf="i.Direction=='inbound'">InComing Call</td>
            <td *ngIf="i.Direction=='outbound'">OutGoing Call</td>
            <!-- <td>{{i.Direction ||'-'}}</td> -->
          </tr>
          <tr>
            <td style="padding-top: 20px">Call Recording</td>
            <td><audio *ngIf="i.RecordingURL != '-'" style="height: 2.5rem; width: 15srem" [src]="i.RecordingURL"
                controls controlsList="nodownload" (play)="audioPlay($event)"></audio>
            </td>
          </tr>
          <tr>
            <td>Comments</td>
            <td>{{ i?.Comments }}</td>
          </tr>
        </table>
        <hr class="hr-style" />
      </div>
    </mat-tab>

    <mat-tab label="Student Details">
      <div class="mx-2 my-2">
        <table class="table table-borderless table-md caller-table my-1">
          <tr>
            <td>GUID</td>
            <td>{{ sideViewerData?.GUID }}</td>
          </tr>
          <tr>
            <td>Name</td>
            <td>
              {{
              sideViewerData?.FirstName + " " + sideViewerData?.LastName
              | titlecase
              }}
            </td>
          </tr>
          <tr>
            <td>DOB</td>
            <td>{{ sideViewerData?.DOB }}</td>
          </tr>
          <tr>
            <td>Submitted Date</td>
            <td>{{ sideViewerData?.createdAt | date: "medium" }}</td>
          </tr>
          <tr>
            <td>APP Status</td>
            <td>{{ sideViewerData?.APPStatus | titlecase }}</td>
          </tr>
          <tr>
            <td>Enrollment Year</td>
            <td>{{ sideViewerData?.EnrollmentYear || "NA" }}</td>
          </tr>
          <tr>
            <td>Program</td>
            <td>{{ sideViewerData?.Program }}</td>
          </tr>
          <tr>
            <td>Form Type</td>
            <td>{{ sideViewerData?.Miscellaneous?.form_name || '--' }}</td>
          </tr>
          <tr>
            <td>Phone Number</td>
            <td>
              {{
              "+" + sideViewerData?.CountryCode + sideViewerData?.PhoneNumber
              }}
            </td>
          </tr>
          <tr>
            <td>Email</td>
            <td>{{ sideViewerData?.Email }}</td>
          </tr>
          <tr>
            <td>Address</td>
            <td>
              {{
              sideViewerData?.Address +
              " " +
              sideViewerData?.City +
              " " +
              sideViewerData?.State +
              " " +
              sideViewerData?.Zip +
              " " +
              sideViewerData?.Country || "NA" | titlecase
              }}
            </td>
          </tr>
        </table>
      </div>
    </mat-tab>

    <mat-tab label="Document Validation">
      <div class="mx-2 my-2">
        <div class="mx-2 my-2">
          <div class="border-bottom" *ngFor="let item of sideViewerData.uploadlistofdocuments">
            <div class="d-flex mb-1">
              <div class="py-2 d-flex flex-column">
                <img *ngIf="item.uploadedDate != '-'" src="./assets/img/docs-r.svg" style="width: 16px" />
                <img *ngIf="item.uploadedDate == '-'" src="./assets/img/doc-grey-2.svg" style="width: 16px" />
              </div>
              <div class="p-2 d-flex flex-column w200">
                <div class="sub-header-md mr-3 d-flex align-items-center">
                  <div style="line-height: 160%;">{{ item.ProgramDoc |titlecase }}</div>
                  <button *ngIf="item.docType=='customDoc'" style="background: none;"
                    (click)="deleteCustomDocument(item.StudentdocId,item.ProgramDoc)">
                    <img class="img12" style="width:10px" src="../../../assets/img/delete.svg" />
                  </button>

                </div>

                <div class="sub-date" *ngIf="item.uploadedDate != '-'">
                  {{ item?.uploadedDate | date: "medium" }}
                </div>
              </div>
              <div>
                <div>
                  <span class="badge badge-pill badge-success mt-3"
                    *ngIf="item.AgentValidation == 'validated'">Validated</span>
                  <span class="badge badge-pill badge-warning mt-3" *ngIf="item.AgentValidation == 'revalidate'">Re
                    Validate</span>
                </div>

                <ng-template #validation11>
                  <form #form="ngForm">
                    <div style="width: 185px; font-size: 12px">
                      <div>
                        <div class="form-check form-check-inline">
                          <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1"
                            value="validated" (change)="
                                                changeValidation(
                                                  $event.target.value,
                                                  item.StudentdocId
                                                )
                                              " />
                          <label class="form-check-label" for="inlineRadio1">Validated</label>
                        </div>
                        <div class="form-check form-check-inline">
                          <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2"
                            value="revalidate" (change)="
                                                changeValidation(
                                                  $event.target.value,
                                                  item.StudentdocId
                                                )
                                              " />
                          <label class="form-check-label" for="inlineRadio2">Re Validate</label>
                        </div>
                      </div>
                    </div>
                  </form>
                </ng-template>

                <ng-template #comments11>
                  <form #form="ngForm">
                    <div class="d-flex align-items-end" style="width: 250px">
                      <div class="flex-grow-1">
                        <textarea class="form-control ct" type="text" #documentComment id="documentComments" rows="2"
                          placeholder="Write comments here..."></textarea>
                      </div>
                      <button type="button" class="btn btn-link ml-1 flex-shrink-1" style="padding: 0"
                        (click)="postComments(item, 'documentComments')">
                        <img src="./assets/img/send-message.svg" style="width: 16px" />
                      </button>
                    </div>
                  </form>
                </ng-template>
              </div>
              <div class="ml-auto p-2">
                <button *ngIf="item.StudentDocPath != '-'" class="btn btn-light btn-sm mr-2"
                  (click)="downLoadDocument(item.StudentDocPath, item.ProgramDoc)">
                  <img src="../../../assets/img/download.svg" style="width: 16px" />
                </button>

                <input hidden type="file" #uploadFile (change)="fileUpload($event, item)" />

                <button [disabled]="sideViewerData.LeadDocStatus== 'completed'" data-toggle="modal"
                  data-target="#uploadFiles" placement="right" tooltipClass="my-custom-class"
                  class="btn btn-light btn-sm mr-2" (click)="clearSelectedFiles(item)">
                  <img src="./assets/img/file-upload.svg" style="width: 16px" />

                </button>
                <button type="button" class="btn btn-light btn-sm mr-2"
                  (click)="openPreview(pdfContent, item.StudentDocPath)" [disabled]="item.StudentDocPath == '-'">
                  <img src="./assets/img/view2.svg" style="width: 16px" />
                </button>
                <button type="button" class="btn btn-light btn-sm mr-2" placement="bottom" [ngbPopover]="validation11"
                  [disabled]="item.StudentDocPath == '-' || sideViewerData.LeadDocStatus== 'completed'">
                  <img *ngIf="item.AgentValidation == '-' || item.AgentValidation == null" src="./assets/img/tick-g.svg" style="width: 16px" />
                  <img *ngIf="item.AgentValidation == 'validated'" src="./assets/img/ticker-green.svg"
                    style="width: 16px" />
                  <img *ngIf="item.AgentValidation == 'revalidate'" src="./assets/img/ticker-red.svg"
                    style="width: 16px" />
                </button>
                <button type="button" class="btn btn-light btn-sm mr-2" placement="left" [ngbPopover]="comments11"
                  [disabled]="disabled" ngbTooltip="Add Comment">
                  <img src="./assets/img/comments.svg" style="width: 16px" />
                </button>
                <button type="button" data-toggle="modal" data-target="#exampleModal2" class="btn btn-light btn-sm mr-2"
                  (click)="callFromDocs(sideViewerData, item)">
                  <img src="./assets/img/calls-g.svg" style="width: 16px" />
                </button>
              </div>
            </div>

            <!--Comment section comes here-->
            <mat-expansion-panel *ngIf="item.DocComments.length > 0">
              <mat-expansion-panel-header>
                <mat-panel-title> Comments </mat-panel-title>
              </mat-expansion-panel-header>

              <div class="comments-section" *ngFor="let i of item.DocComments">
                <div class="admin-comment" *ngIf="i.Admin == true">
                  <div class="d-flex">
                    <div>
                      <div *ngIf="i.AgentName" class="pic mr-1 float-left text-center">
                        <img *ngIf="i.ProfilePhoto != null" src="{{ i.ProfilePhoto }}" style="width: 100%;
                          height: 100%;
                          object-fit: cover;
                          border-radius: 100px;" />

                        <p [ngStyle]="{ background: i.AgentColor }" class="initials" *ngIf="i.ProfilePhoto == null">
                          {{ i?.AgentInitials }}
                        </p>
                      </div>
                    </div>
                    <div class="pl-2 pr-2 d-flex flex-column flex-grow-1">
                      <div class="d-flex">
                        <div class="sub-header-sm mr-3 flex-fill">
                          {{ i?.AgentName }}
                        </div>
                        <div class="sub-date float-right ml-auto" style="white-space: nowrap">
                          {{ i?.DateTime | date: "medium" }}
                        </div>
                      </div>
                      <div class="sub-text flex-grow-1">{{ i?.Text }}</div>
                    </div>
                  </div>
                </div>

                <div class="agent-comment" *ngIf="i.Admin == false">
                  <div class="d-flex">
                    <div>
                      <div *ngIf="i.AgentName" class="pic mr-1 float-left text-center">
                        <img *ngIf="i.ProfilePhoto != null" style="width: 100%;
                          height: 100%;
                          object-fit: cover;
                          border-radius: 100px;" src="{{ i.ProfilePhoto }}" />

                        <p [ngStyle]="{ background: i.AgentColor }" class="initials" *ngIf="i.ProfilePhoto == null">
                          {{ i?.AgentInitials }}
                        </p>
                      </div>
                    </div>
                    <div class="pl-2 pr-2 d-flex flex-column">
                      <div class="d-flex">
                        <div class="sub-header-sm mr-3">{{ i?.AgentName }}</div>
                        <div class="sub-date float-right ml-auto" style="white-space: nowrap">
                          {{ i?.DateTime | date: "medium" }}
                        </div>
                      </div>
                      <div class="sub-text">{{ i?.Text }}</div>
                    </div>
                  </div>
                </div>
                <div style="clear: both"></div>
              </div>
            </mat-expansion-panel>
          </div>
          <div class="py-2">
            <button [disabled]="sideViewerData.LeadDocStatus == 'completed'" data-toggle="modal"
              data-target="#exampleModal4" ngbTooltip="Add New Document" placement="right"
              tooltipClass="my-custom-class" class="btn btn-primary btn-sm" (click)="clearData()"
              style="padding: 5px 5px">
              <img src="./assets/img/add-w.svg" style="width: 10px" />
              Add
            </button>
          </div>
        </div>
      </div>
      <div *ngIf="validationStatus == true" class="text-center mb-2">
        <button class="btn btn-success btn-sm mt-2" disabled style="width: 180px">
          <img src="./assets/img/green-tick-4.svg" style="width: 16px" />
          Validation Completed
        </button>
      </div>
      <div *ngIf="validationStatus == false" class="text-center mb-2">
        <button class="btn btn-danger btn-sm mt-2" disabled style="width: 180px">
          <img src="../assets/img/close-r.svg" style="width: 16px" /> Not
          Validated
        </button>
      </div>

      <div class="p-3">
        <!--Comments 2 section-->
        <div class="header-md">
          Comments
          <span style="font-size: 12px">{{
            "(" + (sideViewerData?.LeadComments?.length || "0") + ")"
            }}</span>
        </div>
        <div class="comments-block" style="background: #fff">
          <div class="mb-3 pb-1 ct pr" style="background: #f5f5f5; border-radius: 5px">
            <textarea class="form-control ct p-2 mb-1" style="background: #f5f5f5" id="leadComments" rows="2"
              placeholder="Write comments here..."></textarea>
            <button type="button" class="btn btn-primary btn-sm mr-2 mb-1 float-right" style="padding: 5px 5px"
              (click)="postComments(sideViewerData, 'leadComments')">
              Submit
            </button>
            <div style="clear: both"></div>
          </div>
          <div class="m-h200">
            <div class="d-flex mb-2 mt-1" *ngFor="let j of sideViewerData.LeadComments">
              <div class="d-flex border-bottom pt-1" style="width: 100%">
                <div class="d-flex" style="width:100%">
                  <div class="">
                    <div *ngIf="j.AgentName" class="pic mr-1 float-left text-center">
                      <img *ngIf="j.ProfilePhoto != null" style="width: 100%;
                        height: 100%;
                        object-fit: cover;
                        border-radius: 100px;" src="{{ j.ProfilePhoto }}" />

                      <p [ngStyle]="{ background: j.AgentColor }" class="initials" *ngIf="j.ProfilePhoto == null">
                        {{ j?.AgentInitials }}
                      </p>
                    </div>
                  </div>
                  <div class="d-flex flex-column">
                    <div class="flex-grow-1">
                      <div class="sub-header-sm mr-3 mt-1 pb-1 flex-fill">
                        {{ j?.AgentName }}
                      </div>
                    </div>
                    <div class="sub-text flex-grow-1 pb-2">
                      {{ j?.Text }}
                    </div>
                  </div>
                  <div class="sub-date float-right ml-auto" style="white-space: nowrap">
                    {{ j?.DateTime | date: "medium" }}
                  </div>
                </div>


                <!-- <div>
                    <div class="img-circle2">
                      <img src="./assets/img/admin-comments.svg" style="width: 10px" />
                    </div>
                  </div> -->

              </div>
              <!--
              <div>
                <div class="img-circle2 mr-2"><img src="./assets/img/admin-commentsx.svg" style="width:12px;" />
                </div>
              </div>
              <div class="d-flex flex-column">
                <div class="sub-header-md2 mb-1">
                  {{j.Text}}
                </div>
                <div class="sub-date">{{j.DateTime | date:'medium'}}</div>
              </div>
            -->
            </div>
          </div>
        </div>
      </div>
    </mat-tab>

    <mat-tab label="Communication">
      <mat-tab-group class="sideDataViewer">
        <mat-tab label="SMS">
          <div style="position:absolute; left:15px;right:15px;bottom:0; height:100px;">
            <div class="mb-3 pb-1 ct pr" style="background:#f5f5fa;border-radius: 10px;
            margin: 0 20px 0 10px;">
              <textarea class="form-control ct p-2 mb-1" style="background: #f5f5f5" id="textMessage" rows="2"
                placeholder="Write your message here..."></textarea>
              <button type="button" class="btn btn-link btn-sm mr-2 mb-1 float-right" style="padding: 0 5px"
                (click)="sendSms(sideViewerData, 'textMessage')">
                <img src="../../../assets/img/send-message.svg" class="img12">
              </button>
              <div style="clear: both"></div>
            </div>
          </div>
          <div class="p-3">
            <!--Comments 2 section-->
            <div class="header-md">
              Total Messages
              <span style="font-size: 12px">{{
                "(" + (smsList?.length || "0") + ")"
                }}</span>
            </div>
            <div class="comments-block" style="background: #fff">
              <div class="dyn-height">
                <div class="d-flex mb-0 mt-1" *ngFor="let j of smsList">
                  <div class="d-flex pt-1" style="width: 100%">
                    <div style="width:100%">

                      <div class="admin-comment"
                        style="border:1px solid #f5e7e7;border-radius:10px; border-bottom-right-radius: 0;"
                        *ngIf="j.Direction == 'inbound'">
                        <div class="d-flex">
                          <div>
                            <div class="pic mr-1 float-left text-center">
                              <img src="../../../assets/img/student.svg" style="width: 100%;
                            height: 100%;
                            object-fit: cover;
                            border-radius: 100px;" />

                            </div>
                          </div>
                          <div class="pl-2 pr-2 d-flex flex-column flex-grow-1">
                            <div class="d-flex">
                              <span class="header-12">Student</span>

                              <div class="sub-date float-right ml-auto" style="white-space: nowrap">
                                {{ j?.DateTime | date: "medium" }}
                              </div>
                            </div>
                            <div class="sub-text flex-grow-1">{{ j?.Text }}</div>
                          </div>
                        </div>
                      </div>

                      <div class="agent-comment"
                        style="border-radius:10px;border-bottom-left-radius: 0;border:1px solid #def3f7"
                        *ngIf="j.Direction == 'outbound'">
                        <div class="d-flex">
                          <div>
                            <div class="pic mr-1 float-left text-center">
                              <img style="width: 100%;
                            height: 100%;
                            object-fit: cover;
                            border-radius: 100px;" src="../../../assets/img/admin-comments.svg" />

                            </div>
                          </div>

                          <div class="pl-2 pr-2 d-flex flex-column">
                            <div class="d-flex">
                              <span class="header-12">Agent</span>
                              <!-- <div class="sub-header-sm mr-3">{{ i.AgentName }}</div> -->
                              <div class="sub-date float-right ml-auto" style="white-space: nowrap">
                                {{ j?.DateTime | date: "medium" }}
                              </div>
                            </div>
                            <div class="sub-text" style="width:315px;line-height: 125%;">{{ j?.Text }}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </mat-tab>
        <mat-tab label="WhatsApp">
          <div style="position:absolute; left:15px;right:15px;bottom:0; height:100px;">
            <div class="mb-3 pb-1 ct pr" style="
          background: #f5f5fa;
          border-radius: 10px;
          margin: 0 20px 0 10px;
        ">
              <textarea class="form-control ct p-2 mb-1" style="background: #f5f5f5" id="textMessageWp" rows="2"
                placeholder="Write your message here...">Thank you for considering Accessque</textarea>
              <button type="button" class="btn btn-link btn-sm mr-2 mb-1 float-right" style="padding: 0 5px"
                (click)="sendSmsWp(sideViewerData, 'textMessageWp')">
                <img src="../../../assets/img/send-message.svg" class="img12" />
              </button>
              <div style="clear: both"></div>
            </div>
          </div>
          <div class="p-3">
            <!--Comments 2 section-->
            <div class="header-md">
              Total Messages
              <span style="font-size: 12px">{{
                "(" + (smsListWp?.length || "0") + ")"
                }}</span>
            </div>
            <div class="comments-block" style="background: #fff">
              <div class="dyn-height">
                <div class="d-flex mb-0 mt-1" *ngFor="let j of smsListWp">
                  <div class="d-flex pt-1" style="width: 100%">
                    <div style="width: 100%">
                      <div class="admin-comment" style="
                    border: 1px solid #f5e7e7;
                    border-radius: 10px;
                    border-bottom-right-radius: 0;
                  " *ngIf="j.Direction == 'inbound'">
                        <div class="d-flex">
                          <div>
                            <div class="pic mr-1 float-left text-center">
                              <img src="../../../assets/img/student.svg" style="
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                            border-radius: 100px;
                          " />
                            </div>
                          </div>
                          <div class="pl-2 pr-2 d-flex flex-column flex-grow-1">
                            <div class="d-flex">
                              <span class="header-12">Student</span>

                              <div class="sub-date float-right ml-auto" style="white-space: nowrap">
                                {{ j.createdAt | date : "medium" }}
                              </div>
                            </div>
                            <div class="sub-text flex-grow-1">{{ j.Text }}</div>
                          </div>
                        </div>
                      </div>

                      <div class="agent-comment" style="
                    border-radius: 10px;
                    border-bottom-left-radius: 0;
                    border: 1px solid #def3f7;
                  " *ngIf="j.Direction == 'outbound'">
                        <div class="d-flex">
                          <div>
                            <div class="pic mr-1 float-left text-center">
                              <img style="
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                            border-radius: 100px;
                          " src="../../../assets/img/admin-comments.svg" />
                            </div>
                          </div>

                          <div class="pl-2 pr-2 d-flex flex-column">
                            <div class="d-flex" style="margin-right: 20px;">
                              <span class="header-12">Agent</span>
                              <!-- <div class="sub-header-sm mr-3">{{ i.AgentName }}</div> -->
                              <div class="sub-date float-right ml-auto" style="white-space: nowrap">
                                {{ j.createdAt | date : "medium" }}
                              </div>
                            </div>
                            <div class="sub-text" style="width: 315px; line-height: 125%">
                              {{ j.Text }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </mat-tab>
      </mat-tab-group>
    </mat-tab>

    <mat-tab label="Activity">
      <div class="mx-2 my-2">
        <div class="alert alert-info mb-0 p-2 d-flex">
          <div class="" style="line-height:200%">
            {{ sideViewerData?.Activities?.length }} Actions taken
          </div>
          <div class="ml-auto" style="width:180px;">
            <input class="searchActivity" type="text" style="font-size:13px;" class="form-control search-input"
              placeholder="Search" [(ngModel)]="activityfilterString"
              (input)="onInputActivity(sideViewerData?.Activities?.length)" />
          </div>
        </div>
        <div *ngFor="let i of sideViewerData.Activities | slice: 0:minItems | searchFilterTable: activityfilterString">
          <div class="myactivity d-flex align-items-start">
            <div class="mr-auto d-flex">
              <div>
                <img class="img16 mr-2" src="./assets/img/tick-green.svg" />
              </div>
              <div>{{ i?.Description }} </div>
            </div>
            <div class="ml-auto text-right" style="width:230px;"><span class="sm-text ml-2">{{
                i?.DateTime | date: "medium"
                }}</span></div>


            <!-- <img class="img16 mr-2" src="./assets/img/tick-green.svg" />
              <p>
                {{ i.Description }}
                <span class="sm-text ml-2">{{
                  i.DateTime | date: "medium"
                  }}</span>
              </p> -->
          </div>
          <hr style="margin: 0; padding: 0" />
        </div>
        <div class="loadmore" *ngIf="minItems < sideViewerData.Activities.length"
          (click)="showmore(sideViewerData.Activities.length)">
          Show more..
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
</mat-sidenav>


<div class="container-fluid">
  <div class="pt-3 pl-2 pr-2 d-flex justify-content-between mb-4">
    <div class="db-sm-header" style="line-height: 330%">
      Activity

      <a href="javascript:void(0);" (click)="openCardsModal(cardsChangemodal)"><img
          src="../../../assets/images/edit.svg" class="img11 mb-1"></a>

    </div>
    <div class="db-sm-header mb-1 ml-auto" (click)="hideDate()">
      <button class="form-group" style="padding: 0">
        <select class="form-control cdd" id="" style="padding: 0; background: #fff" [(ngModel)]="filterValue"
          (change)="updateDateFilter($event)">
          <option value="all">All</option>
          <option value="24hrs">24 hrs</option>
          <option value="72hrs">72 hrs</option>
          <option value="7days">07 days</option>
          <option value="14days">14 days</option>
          <option value="30days">30 days</option>
          <option value="custom">Custom</option>
        </select>
      </button>
    </div>

    <div *ngIf="showDate" class="datePicker">
      <div style="background: #fff; height: 76px; border: 1px solid #ccc" class="custom-date">
        <mat-form-field class="mr-3">
          <input class="label" matInput [matDatepicker]="picker1" placeholder="Start Date" [formControl]="date2"
            (dateInput)="getStartDate('input', $event)" />
          <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
          <mat-datepicker #picker1 style="width: 80px"></mat-datepicker>
        </mat-form-field>

        <mat-form-field class="mr-3">
          <input class="label" matInput [matDatepicker]="picker2" placeholder="End Date" [formControl]="date1"
            (dateInput)="getEndDate('input', $event)" [max]="maxDate" />
          <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
          <mat-datepicker #picker2 style="width: 80px"></mat-datepicker>
        </mat-form-field>
        <button class="btn btn-secondary btn-sm" (click)="filterData()">
          Submit
        </button>
      </div>
    </div>
  </div>
</div>

<!-- Carousel  -->
<app-custom-cards [cardsSettings]="cardsSettings" [records]="topBarCounts" [records1]="topBarCounts12"
  [callStatusSelected]="callStatusSelected" (pageChange)="pageChange($event)"></app-custom-cards>
<!-- End Carousel -->

<!-- Activity -->

<!-- End Carousel -->

<!-- Activity -->
<div>
  <div>
    <div>
      <div class="pl-2 d-flex align-items-end">
        <div class="header-12">
          Total Applications &nbsp;
          <a href="javascript:void(0);" (click)="openTablesModal(tablesChangemodal)"> <img
              src="../../../assets/images/edit.svg" class="img11 mb-1"></a>
        </div>
        <div class="ml-auto mr-3">
          <div class="form-control form-control-dark" style="
              height: 0;
              padding: 0px;
              background: none;
              font-size: 13px;
              color: #999;
              border: 0;
            "></div>
          <div class="upload-msg-success" style="color: green; font-size: 10px"></div>
          <div class="upload-msg-error"></div>

          <!-- attachment bar hidden -->
          <div class="content-containers" style="margin-left: 30px">
            <input type="file" (change)="convertFile($event)" style="display: none" #uploadFile accept=".xlsx" />

            <div class="d-flex justify-content-end mt-3">
              <div>
                <div class="float-left mr-3">
                  <div *ngIf="filePath" class="form-control" style="
                      height: 16px;
                      padding: 0px;
                      background: none;
                      font-size: 13px;
                      color: #999;
                      border: 0;
                    ">
                    {{ filePath }}
                  </div>
                  <div class="upload-msg-success" *ngIf="uploaded" style="color: green; font-size: 10px">
                    {{ insertCount + " " + "Contacts inserted" }}
                  </div>
                  <div class="upload-msg-error" *ngIf="error">
                    {{ errorMessage }}
                  </div>
                </div>

                <div style="clear: both"></div>
              </div>

              <!-- for add new document screen-->
              <div class="modal fade" id="exampleModal4" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
                aria-hidden="true">
                <div class="modal-dialog" role="document">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h5 class="modal-title" id="exampleModalLabel">
                        Add New Document
                      </h5>
                      <button type="button" #closeButton class="close" data-dismiss="modal" aria-label="Close"
                        (click)="customFileList = [];docUploadError='';">
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div class="modal-body">
                      <div class="d-flex">
                        <div class="form-group mr-2">
                          <label for="exampleInputEmail1" style="font-size: 13px">Document Type</label>
                          <select class="form-control" name="doctype" [(ngModel)]="customDocType" style="width: 300px;"
                            (change)="customDocNameChange($event.target.value)">
                            <option value="" disabled selected>
                              Select Document
                            </option>
                            <option *ngFor="let item of customDocList" [value]="item.name" [disabled]="item.disabled">
                              {{item?.name}}</option>
                          </select>
                          <div class="mt-2">
                            <small style="font-size: 14px;"><b>Please use CTRL to select multiple files .</b></small>
                          </div>
                        </div>
                        <div>
                          <input hidden type="file" accept="application/msword, application/pdf, image/*, .doc, .docx"
                            #uploadFile2 (change)="uploadCustomDocument($event)" multiple />
                          <button type="button" class="btn btn-secondary btn-sm mr-2" style="
                              padding: 5px 10px;
                              margin-top: 22px;
                              font-size: 13px;
                            " (click)="uploadFile2.click()">
                            <img class="img12" src="../../../assets/img/upload-w.svg" />
                          </button>
                        </div>
                        <div>
                          <button (click)="addCustomDocumentAI()" type="button" class="btn btn-secondary btn-sm" style="
                              padding: 5px 10px;
                              margin-top: 22px;
                              font-size: 13px;
                            ">
                            Add
                          </button>
                        </div>

                      </div>
                      <div>
                        <div class="add-docs-item-sub ml-auto">
                          <div class="txt-oflow-lg text-left">
                            Selected Files: {{ customFileList.length }}
                          </div>
                        </div>
                        <div *ngFor="let i of customFileList" class="d-flex add-docs-item align-items-center">
                          <div class="">
                            <img class="img12 mr-1 lh160" src="../../../assets/img/docs-r.svg" />
                            {{ i.name | titlecase }}<br />
                            <div class="add-docs-item-sub d-flex" style="margin-left: 20px">
                              <div class="txt-oflow-lg">
                                {{ i.fileName }}
                              </div>
                            </div>
                          </div>
                          <div class="">
                            <button (click)="removeFile(i)">
                              <img class="img12 mr-1 lh160" src="../../../assets/img/delete.svg" />
                            </button>
                          </div>
                        </div>
                      </div>
                      <div *ngIf="customFileList.length == 0" class="d-flex p-3 flex-column">
                        <div class="text-center p-3">
                          <img src="../../../assets/img/upload-file-2.png" />
                        </div>
                        <div class="text-center">
                          Upload below supported documents only <br />
                          <span style="font-size: 18px; line-height: 180%">.doc .pdf .png .jpg .jpeg</span>
                        </div>
                      </div>
                      <div class="p-3 text-center docUploadError" style="font-size: 14px; font-weight: bold"
                        [ngStyle]="aiDocMessages == true?{'color': 'red'} : {'color': 'green'}">
                        <div class="my-2">
                          <mat-progress-bar mode="indeterminate" *ngIf="docUploadStatus"></mat-progress-bar>
                        </div>
                        {{docUploadError}}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- for upload multiple files-->
              <div class="modal fade" id="uploadFiles" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
                aria-hidden="true">
                <div class="modal-dialog" role="document">
                  <div class="modal-content" style="width: 400px">
                    <div class="modal-header">
                      <h5 class="modal-title" id="exampleModalLabel">
                        Upload Documents
                      </h5>
                      <button type="button" id="closeButton4" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div class="modal-body">
                      <div *ngIf="uploadSuccess=='none'">
                        <div class="d-flex" *ngIf="!docUploadStatus">
                          <div>
                            <button (click)="filesInput.click()" type="button" class="btn btn-secondary btn-sm">
                              Select Files
                            </button>
                            <input type="file" #filesInput id="filesInput" multiple (change)="getFileDetails($event)"
                              style="display: none;">
                          </div>
                          <div class="add-docs-item-sub ml-auto">
                            <div class="txt-oflow-lg text-right">

                              Selected Files: {{selectedFiles.length}}
                            </div>
                          </div>
                        </div>

                        <!--Addon-->
                        <div *ngIf="selectedFiles.length==0" class="d-flex p-3 flex-column">
                          <div class="text-center p-3"><img src="../../../assets/img/upload-file-2.png" /></div>
                          <div class="text-center">
                            Upload below supported documents only <br>
                            <span style="font-size:18px; line-height:180%">.doc .pdf .png .jpg .jpeg</span>
                          </div>
                        </div>
                        <!--End-->

                        <div *ngIf="selectedFiles.length>0">
                          <div *ngIf="!docUploadStatus" class="py-2 mb-3" style="height:160px;overflow:auto">
                            <div *ngFor="let i of selectedFiles;let indexOfelement=index;"
                              class="d-flex add-docs-item align-items-center py-2">
                              <div class="">
                                <img class="img12 mr-1 lh160 float-left" src="../../../assets/img/docs-r.svg" />
                                <div class="txt-oflow-lg" style="display:inline-block;line-height:100%">{{ i.name }}
                                </div>
                                <br />
                              </div>
                              <div class="ml-auto">
                                <button style="background: none;" (click)="deleteFile(indexOfelement)">
                                  <img class="img12" src="../../../assets/img/delete.svg" />
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>




                        <div *ngIf="!docUploadStatus">
                          <div class="d-flex">
                            <div *ngIf="selectedFiles.length > 0">
                              <div *ngIf="!docUploadStatus">
                                <button (click)="filesInput.click()" type="button" class="btn btn-secondary btn-sm">
                                  + Add more
                                </button>

                                <input type="file" #filesInput id="filesInput" multiple
                                  (change)="getFileDetails($event)" style="display: none;">
                              </div>
                            </div>
                            <div class="ml-auto">
                              <button *ngIf="selectedFiles.length > 0" #uploadFilesButton type="button"
                                class="btn btn-primary btn-sm mr-2" (click)="uploadAttachments()">
                                Upload
                              </button>
                              <button (click)="cancelUpload()" type="button" class="btn btn-light btn-sm">
                                Cancel
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div *ngIf="!docUploadStatus">
                        <!--Addon-->
                        <div class="d-flex p-3 flex-column" *ngIf="uploadSuccess=='success'">
                          <div class="text-center p-3"><img src="../../../assets/img/upload-success.png" /></div>
                          <div class="text-center">
                            <b>Upload Success</b><br>
                            <div style="font-size:14px; line-height:130%;padding:5px">Total {{selectedFiles.length}}
                              files
                              uploaded successfully
                            </div>
                          </div>
                        </div>
                        <!--End-->

                        <!--Addon-->
                        <div class="d-flex p-3 flex-column" *ngIf="uploadSuccess=='failure'">
                          <div class="text-center p-3"><img src="../../../assets/img/upload-failed.png" /></div>
                          <div class="text-center">
                            <b>Upload Failed</b><br>
                            <div style="font-size:14px; line-height:130%;padding:5px">Files not uploaded. Please check
                              document extensions,
                              upload supported documents</div>
                          </div>
                        </div>
                        <!--End-->


                      </div>
                      <div>
                        <mat-progress-bar mode="indeterminate" *ngIf="docUploadStatus"></mat-progress-bar>
                      </div>
                      <div class="p-3 text-center docUploadError" *ngIf="uploadSuccess=='none'"
                        style="color: green; font-size: 14px; font-weight: bold">
                        {{docUploadError2}}
                      </div>

                    </div>
                  </div>
                </div>
              </div>

              <!-- for bulk status update model-->
              <div class="modal fade" id="exampleModalBulk" tabindex="-1" role="dialog"
                aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog" role="document">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h5 class="modal-title" id="exampleModalLabel">
                        Status Change
                      </h5>
                      <button type="button" id="closeAssignButton" #closeAssignButton class="close" data-dismiss="modal"
                        aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div class="modal-body" style="height: none">
                      <div class="px-2">
                        <div class="py-2 header-lg">
                          <b>{{ leadIds.length }}</b> Students are selected for
                          update.
                        </div>
                        <div class="d-flex mt-2 mb-3">
                          <div class="form-group mr-2" style="margin-bottom: 0; width: 220px">
                            <select class="form-control" id="exampleFormControlSelect1"
                              (change)="bulkStatusSelected($event.target.value)">
                              <option value="default" disabled selected>
                                Select Status
                              </option>
                              <option value="previousintake"> Previous Intake</option>
                            </select>
                          </div>
                          <button type="button" class="btn btn-secondary btn-sm" (click)="bulkLeadsChange()">
                            Update
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- attachment bar hidden ends -->
              <!-- <button data-toggle="modal" data-target="#exampleModalBulk" *ngIf="admin"
                    class="btn btn-secondary btn-sm ml-2" style="color: #fff" ngbTooltip="Status Change" placement="top"
                    tooltipClass="my-custom-class">
                    <img src="../../../../assets/images/pen.svg" class="img13" />
                  </button> -->

              <!-- for assign model-->
              <div class="modal fade" id="exampleModal3" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
                aria-hidden="true">
                <div class="modal-dialog" role="document">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h5 class="modal-title" id="exampleModalLabel">
                        Assign to Agent
                      </h5>
                      <button type="button" id="closeAssignButton" #closeButton class="close" data-dismiss="modal"
                        aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div class="modal-body" style="height: none;">
                      <div class="px-2">
                        <div class="py-2 header-lg">
                          <b>{{ leadIds.length }}</b> Students are selected to
                          assign.
                        </div>
                        <div class="d-flex mt-2 mb-3">
                          <div class="form-group mr-2" style="margin-bottom: 0; width: 220px">
                            <select class="form-control" id="exampleFormControlSelect1" [(ngModel)]="defaultAgent"
                              (change)="agentSelected($event.target.value)">
                              <option value="default" disabled selected>
                                Select Agent
                              </option>
                              <option *ngFor="let agent of agentList" [value]="agent.Mailid">
                                {{ agent.FirstName + " " + agent.LastName }}
                              </option>
                            </select>
                          </div>
                          <button type="button" class="btn btn-secondary btn-sm" (click)="assignLEads()">
                            Assign
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- attachment bar hidden ends -->
              <button data-toggle="modal" data-target="#exampleModal3" *ngIf="admin" (click)="assignAgentModal()"
                class="btn btn-link btn-sm ml-2" style="color: #fff" ngbTooltip="Assign" placement="top"
                tooltipClass="my-custom-class2">
                <div>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 32 32">
                    <g id="Group_860" data-name="Group 860" transform="translate(-783.465 -58.66)">
                      <path id="Path_965" data-name="Path 965"
                        d="M795.465,62.66a5,5,0,1,1-5,5,5,5,0,0,1,5-5m0-2a7,7,0,1,0,7,7A7,7,0,0,0,795.465,60.66Z"
                        class="psvg" />
                      <path id="Path_966" data-name="Path 966"
                        d="M805.465,88.66h-2v-5a5,5,0,0,0-5-5h-6a5,5,0,0,0-5,5v5h-2v-5a7,7,0,0,1,7-7h6a7,7,0,0,1,7,7Z"
                        class="psvg" />
                      <path id="Path_967" data-name="Path 967"
                        d="M808.465,74.84l-2.59-2.59-1.41,1.41,4,4,7-7-1.41-1.41Z" class="psvg" />
                      <rect id="_Transparent_Rectangle_" data-name=" Transparent Rectangle " width="32" height="32"
                        transform="translate(783.465 58.66)" fill="none" />
                    </g>
                  </svg>
                </div>
              </button>

              <button class="btn btn-link btn-sm ml-2" ngbTooltip="Download Sample" placement="top"
                tooltipClass="my-custom-class2">
                <a href="{{ downloadLink }}">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 22 22">
                    <g id="Group_776" data-name="Group 776" transform="translate(-215.728 -61.66)">
                      <path id="Path_888" class="psvg" data-name="Path 888"
                        d="M234.853,86.66H221.1a1.375,1.375,0,0,1-1.375-1.375v-8.25A1.375,1.375,0,0,1,221.1,75.66h2.063v1.375H221.1v8.25h13.75v-8.25h-2.063V75.66h2.063a1.375,1.375,0,0,1,1.375,1.375v8.25A1.376,1.376,0,0,1,234.853,86.66Z"
                        transform="translate(-1.25 -4.375)" fill="#b02022" />
                      <rect id="Rectangle_902" class="psvg" data-name="Rectangle 902" width="4" height="1"
                        transform="translate(224.728 75.66)" fill="#b02022" />
                      <path id="Path_889" class="psvg" data-name="Path 889"
                        d="M233.008,68.19l-2.468,2.461V63.66h-1.375v6.992L226.7,68.19l-.969.969,4.125,4.125,4.125-4.125Z"
                        transform="translate(-3.125 -0.625)" />
                      <rect id="_Transparent_Rectangle_" data-name=" Transparent Rectangle " width="22" height="22"
                        transform="translate(215.728 61.66)" fill="none" />
                    </g>
                  </svg></a>
              </button>
              <button class="btn btn-link btn-sm ml-2" ngbTooltip="Upload File" placement="top"
                (click)="redirectToUpload()" tooltipClass="my-custom-class2">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 22 22">
                  <g id="Group_775" data-name="Group 775" transform="translate(-171.243 -61.66)">
                    <path id="Path_886" class="psvg" data-name="Path 886"
                      d="M177.243,76.419l.953.953,5.131-5.124V84.531h1.352V72.249l5.131,5.124.953-.953L184,69.66Z"
                      transform="translate(-1.944 -2.592)" />
                    <path id="Path_887" class="psvg" data-name="Path 887"
                      d="M176.595,67.716v-2.7h13.519v2.7h1.352v-2.7a1.352,1.352,0,0,0-1.352-1.352H176.595a1.352,1.352,0,0,0-1.352,1.352v2.7Z"
                      transform="translate(-1.296 -0.648)" />
                    <rect id="_Transparent_Rectangle_" data-name=" Transparent Rectangle " width="22" height="22"
                      transform="translate(171.243 61.66)" fill="none" />
                  </g>
                </svg>
              </button>

              <button class="btn btn-link btn-sm ml-2" ngbTooltip="Add New" placement="top"
                tooltipClass="my-custom-class2" (click)="openCreateLead(content3)">
                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22">
                  <g id="Group_764" data-name="Group 764" transform="translate(-286.561 -0.829)">
                    <path id="Path_869" data-name="Path 869" class="psvg"
                      d="M302.855,15.28V8.829h-1.843V15.28h-6.451v1.843h6.451v6.451h1.843V17.123h6.451V15.28Z"
                      transform="translate(-4.373 -4.373)" />
                    <rect id="Rectangle_901" data-name="Rectangle 901" width="22" height="22"
                      transform="translate(286.561 0.829)" fill="none" />
                  </g>
                </svg>
              </button>
              <button mat-button id="openModalButton" [hidden]="true" ngbTooltip="Start Call" placement="top"
                tooltipClass="my-custom-class2" data-toggle="modal" data-target="#staticBackdrop">
                <img class="img12" src="../../../../assets/img/call.svg" />
              </button>
            </div>
            <div style="clear: both"></div>
          </div>
        </div>
      </div>
      <div>
        <div class="ml-3 mt-2 mr-3">
          <div class="row">
            <div class="pl-2 pr-2 pt-2 mb-3" style="min-height: 300px; width: 100%">
              <div class=" data-table mb-3">
                <app-custom-table [records]="activityTable" [settings]="projectSettings" [rowSetting]="rowSetting"
                  [projectId]="projectId" [isLoading]="isLoading" [noRecords]="noRecords" [selectedAll]="selectedAll"
                  [callStatusSelected]="callStatusSelected" [menuId]="62" (updateLeadModal)="updateLeadModal($event)"
                  (callModal)="callModal($event)" (sidenavOpen)="sidenavOpen($event)"
                  (dropdownSelectReuse)="dropdownSelectReuse($event)" (searchLeads)="searchLeadsNew($event)"
                  (selectAllforAssignment)="selectAllforAssignment()"
                  (selectAgentforAssignmentNew)="selectAgentforAssignmentNew($event)"></app-custom-table>

              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- for Modal -->
      <div class="modal fade" id="staticBackdrop" data-backdrop="static" data-keyboard="false" tabindex="-1"
        aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg custom-model-w">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="staticBackdropLabel">Calling Screens</h5>

              <button type="button" #closeButton2 class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body" style="overflow-y: auto">
              <div class="row">
                <div class="call-module ml-3">
                  <div class="ndialer">
                    <!--.......code for no- incoming call screen.........  -->
                    <div class="c-caller">Current Caller</div>
                    <div class="dialer-middle-screen" style="text-align: center" *ngIf="dialerScreen1 === 'outgoing1'">
                      <div class="custom-incomingcall-widget">
                        <h4 class="mt-3 text-ellipsis">
                          {{ marketingId.name }}
                        </h4>
                        <div class="mb-2 mt-1 lg-text" style="font-weight: 100" *ngIf="marketingId.city">
                          {{ marketingId.city }}
                        </div>
                        <div class="my-1 lg-text">
                          {{ marketingId.phoneNumber || agentCall }}
                        </div>
                        <div class="custom-user-img my-3 mt-5">
                          <img class="img80" src="./assets/img/user-w.svg" alt="" />
                        </div>
                        <h5 *ngIf="callStatus != 'inProgress'">{{ callStatus }}</h5>
                        <p *ngIf="callStatus == 'inProgress'" class="custom-call-timer" style="text-align: center">
                          <countup-timer [countUpTimerConfig]="testConfig"></countup-timer>
                        </p>

                        <div class="custom-incoming-btns my-3 mt-4">
                          <button type="button" class="btn-round m-r-10" (click)="stopCurrentCall()">
                            <img class="img-38" src="./assets/img/hangup.svg" alt="" />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="mt-2 p-3 ndialer2 ng-star-inserted">
                    <div class="mt-4" style="float: right">
                      <img class="img50" src="./assets/img/user-w.svg" alt="" />
                    </div>
                    <div class="sm-text" style="
                       
                      font-weight: 100;
                      background: #f75c2b;
                      width: 110px;
                      padding: 5px;
                      border-radius: 15px;
                      letter-spacing: 1px;
                      text-align: center;
                      color: #fff;
                    ">
                      Next Caller
                    </div>
                    <div class="lg-text pt-2 pb-1">
                      {{ nextCallerName }}
                    </div>
                    <div class="md-text pb-2">{{ nextCallerCity }}</div>
                    <div class="md-text">
                      {{ nextCallerNumber }}
                    </div>
                  </div>

                  <div class="mt-2 d-flex justify-content-around">
                    <div class="cbtn" type="button" placement="top" tooltipClass="my-custom-class"
                      (click)="startCallListener()">
                      <img class="img18" src="./assets/img/redial3.svg" alt="" />
                    </div>
                    <ng-template #calendarContent>
                      <form (ngSubmit)="conferenceCall(form.value)" #form="ngForm">
                        <div class="xp-3" style="width: 185px">
                          <div class="mb-1">
                            <input id="phone_number" name="phone_number" type="text" class="form-control"
                              placeholder="Enter Phone Number" ngModel />
                          </div>
                          <ng-multiselect-dropdown name="manager" [data]="layoutService.projectAgents"
                            [(ngModel)]="layoutService.projectSelectedAgent" [settings]="layoutService.dropdownSettings"
                            style="width: 100%; padding: 0" class="dropdown-primary"></ng-multiselect-dropdown>
                          <button class="btn btn-primary btn-block btn-md mt-2">
                            Connect
                          </button>
                        </div>
                      </form>
                    </ng-template>

                    <div class="cbtn" type="button" ngbTooltip="Stop Calls" placement="top"
                      tooltipClass="my-custom-class" (click)="stopCurrentCall()">
                      <img class="img18" style="padding-top: 3px" src="./assets/img/stop-call-r.svg" alt="" />
                    </div>
                  </div>
                </div>
                <div class="call-module-update ml-4 mr-2">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="header-md mt-2 mb-1">Student details</div>
                    </div>
                  </div>
                  <div class="row custom-row">
                    <div class="col-md-3">
                      <div class="form-group">
                        <label for="formGroupExampleInput">Name</label>
                        <div *ngIf="callData[0]">
                          {{ callData[0]?.FirstName + " " + callData[0].LastName }}
                        </div>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="form-group">
                        <label for="formGroupExampleInput">GUID</label>
                        <div *ngIf="callData[0]">{{ callData[0]?.GUID }}</div>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="form-group">
                        <label for="formGroupExampleInput">APP Status</label>
                        <div *ngIf="callData[0]">{{ callData[0]?.APPStatus }}</div>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="form-group">
                        <label for="formGroupExampleInput">Submitted Date</label>
                        <div *ngIf="callData[0]">
                          {{ callData[0]?.createdAt | date: "medium" }}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row custom-row">
                    <div class="col-md-3">
                      <div class="form-group">
                        <label for="formGroupExampleInput">DOB</label>
                        <div *ngIf="callData[0]">{{ callData[0].DOB }}</div>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="form-group">
                        <label for="formGroupExampleInput">Enrollment Year</label>
                        <div>{{ callData[0]?.EnrollmentYear }}</div>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="form-group">
                        <label for="formGroupExampleInput">Program</label>
                        <div>{{ callData[0]?.Program }}</div>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="form-group">
                        <label for="formGroupExampleInput">Phone Number</label>
                        <div>{{ callData[0]?.PhoneNumber }}</div>
                      </div>
                    </div>
                  </div>

                  <div class="row custom-row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="formGroupExampleInput">Email</label>
                        <div>{{ callData[0]?.Email }}</div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="formGroupExampleInput">Address</label>
                        <div>{{ callData[0]?.Address }}</div>
                      </div>
                    </div>
                  </div>
                  <hr class="hr-style mt-1 mb-1" />
                  <div class="row">
                    <div class="col-md-12">
                      <div class="header-md mt-2 mb-1">Document details</div>
                    </div>
                  </div>
                  <div class="row custom-row" *ngFor="
                    let item of documentsList;
                    let i = index;
                    let even = even;
                    let odd = odd
                  ">
                    <div class="col-md-6 py-2" *ngIf="even">
                      <img *ngIf="item.status == true" class="img18 mr-2" src="../../../../assets/img/tick2.svg" />
                      <img *ngIf="item.status == false" class="img18 mr-2" src="../../../../assets/img/minus.svg" />{{
                      documentsList[i].DocumentName }}
                    </div>
                    <div class="col-md-6 py-2" *ngIf="even && i + 1 < documentsList.length">
                      <img *ngIf="documentsList[i + 1].status == true" class="img18 mr-2"
                        src="../../../../assets/img/tick2.svg" />
                      <img *ngIf="documentsList[i + 1].status == false" class="img18 mr-2"
                        src="../../../../assets/img/minus.svg" />{{ documentsList[i + 1].DocumentName }}
                    </div>
                  </div>

                  <hr class="hr-style mt-1 mb-1" />
                  <div class="row custom-row2">
                    <div class="col-md-12">
                      <div class="header-md mt-2 mb-1">Feedback</div>
                    </div>

                    <div class="col-md-12">
                      <!-- [value]="item.value" *ngFor="let item of listFeed" -->
                      <div class="form-check mr-3 d-inline" *ngFor="let item of callListfeed">
                        <label class="form-check-label" for="check1">
                          <input type="radio" [(ngModel)]="newStatus" class="form-check-input" id="check1"
                            name="option1" [value]="item.value" (change)="onStatusChange(item.value)" />{{item.name}}
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="row" *ngIf="showdeferredintake">
                    <div class="padding-width">
                      <select class="form-control" name="DIyear" id="DIyear" [(ngModel)]="DIyear"
                        (change)="onDIYearChange($event.target.value)">
                        <option [ngValue]="null" [disabled]="true">Select Enrollment Year</option>
                        <option value="{{item}}" *ngFor="let item of DIyearArray">{{item}}</option>
                      </select>
                    </div>
                    <div class="padding-width">
                      <select class="form-control" name="DIterms" id="DIterms" [(ngModel)]="DIterms"
                        (change)="onDIItermsChange($event.target.value)">
                        <option [ngValue]="null" [disabled]="true">Select Enrollment Term</option>
                        <option value="G1">G1</option>
                        <option value="G2">G2</option>
                        <option value="G3">G3</option>
                        <option value="G4">G4</option>
                        <option value="G5">G5</option>
                        <option value="G6">G6</option>
                      </select>
                    </div>
                  </div>
                  <span *ngIf="!newStatus" class="error-msg" style="color: red; font-size: 15px">
                    Please select any option above!
                  </span>
                  <div class="row custom-row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="commentText" class="header-md pt-3 pb-2" style="font-size: 14px">Comments</label>
                        <textarea class="form-control" id="commentText" rows="3" [(ngModel)]="commentText"></textarea>
                      </div>
                    </div>
                  </div>

                  <div class="d-flex justify-content-end">
                    <div class="mt-3">
                      <button (click)="resetCallingScreen()" class="btn btn-light btn-sm mr-2" type="button">
                        Reset
                      </button>
                      <button (click)="updateComments()" class="btn btn-primary btn-sm" type="submit">
                        Update
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--Calling screen from Documents Validation-->
      <div class="modal fade" id="exampleModal2" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-md">
          <div class="modal-content" style="width: 280px; background: none; border: 0">
            <div class="modal-header" style="
              border-bottom: 0;
              display: inline;
              position: absolute;
              right: 5px;
              top: 2px;
              z-index: 999;
            ">
              <button #closeButton type="button" class="close" data-dismiss="modal" aria-label="Close"
                style="margin-right: 0">
                <span aria-hidden="true"><img class="img14" style="width: 10px" src="./assets/img/close.svg" alt="" />
                </span>
              </button>
            </div>
            <div class="modal-body" style="padding: 0">
              <div class="d-flex">
                <div class="ndialer" style="width: 275px">
                  <!--.......code for no- incoming call screen.........  -->

                  <div class="dialer-middle-screen" style="text-align: center">
                    <div class="custom-incomingcall-widget">
                      <h4 class="mt-3 text-ellipsis">
                        {{
                        sideViewerData.FirstName + " " + sideViewerData.LastName
                        }}
                      </h4>
                      <div class="my-1 lg-text">
                        {{
                        "+" +
                        sideViewerData.CountryCode +
                        " " +
                        sideViewerData.PhoneNumber
                        }}
                      </div>
                      <div class="custom-user-img my-3">
                        <img class="img80" src="./assets/img/user-w.svg" alt="" />
                      </div>

                      <h5>{{ callStatus }}</h5>

                      <div class="custom-incoming-btns my-3 mt-4">
                        <button type="button" class="btn-round m-r-10" (click)="stopCurrentCall()">
                          <img class="img-38" src="./assets/img/hangup.svg" alt="" />
                        </button>
                      </div>

                      <button type="button" class="btn btn-link btn-sm ml-2 float-left" placement="right"
                        #popover="ngbPopover" [ngbPopover]="comments22" [disabled]="disabled">
                        <img src="./assets/img/comments.svg" style="width: 16px" />
                      </button>

                      <ng-template #comments22>
                        <form #form="ngForm">
                          <div class="d-flex align-items-end" style="width: 180px">
                            <div class="flex-grow-1">
                              <textarea class="form-control ct" type="text" #callingComment id="callingComments"
                                rows="2" placeholder="Write comments here..."></textarea>
                            </div>
                            <button type="button" class="btn btn-link ml-1 flex-shrink-1" style="padding: 0"
                              (click)="postComments(currentItem, 'callingComments')">
                              <img src="./assets/img/send-message.svg" style="width: 16px" />
                            </button>
                          </div>
                        </form>
                      </ng-template>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--Calling popup from Validation  -->
        <div id="footer" class="footer" style="position: fixed; bottom: 0px">
          <p class="powered-txt mr-2 mb-2">Powered by <a href="#">Avantao</a></p>
        </div>

        <!-- add lead -->
        <ng-template #content3 let-modal>
          <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">Add Lead</h4>
            <button type="button" class="close" aria-label="Close"
              (click)="modal.dismiss('Cross click');resetAddLeadForm();">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body p-3" style="max-height: 600px; background: #fff">
            <form (ngSubmit)="createLead()" [formGroup]="uhleadsservice.createLead">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="form-label">First Name <span style="color: red;">*</span></label>
                    <input class="form-control" name="firstName" formControlName="firstName" [ngClass]="{
                      'is-invalid': createLeads.firstName.touched && createLeads.firstName.invalid,
                      'is-valid':
                        createLeads.firstName.valid &&
                        createLeads.firstName.touched
                    }" />
                    <div *ngIf="createLeads.firstName.touched && createLeads.firstName.errors" class="invalid-feedback">
                      <div *ngIf="createLeads.firstName.errors.required">First Name is required</div>
                      <div *ngIf="createLeads.firstName.errors.pattern">Please use valid Name</div>
                      <div *ngIf="createLeads.firstName.errors.minlength">Minimum length should be 2 characters .
                      </div>
                      <div *ngIf="createLeads.firstName.errors.maxlength">Maximum length should be 50 characters .
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="form-label">Last Name <span style="color: red;">*</span></label>
                    <input class="form-control" name="lastName" formControlName="lastName" [ngClass]="{
                      'is-invalid': createLeads.lastName.touched && createLeads.lastName.invalid,
                      'is-valid':
                        createLeads.lastName.valid &&
                        createLeads.lastName.touched
                    }" />
                    <div *ngIf="createLeads.lastName.touched && createLeads.lastName.errors" class="invalid-feedback">
                      <div *ngIf="createLeads.lastName.errors.required">Last Name is required</div>
                      <div *ngIf="createLeads.lastName.errors.pattern">Please use valid Name</div>
                      <div *ngIf="createLeads.lastName.errors.minlength">Minimum length should be 2 characters .</div>
                      <div *ngIf="createLeads.lastName.errors.maxlength">Maximum length should be 50 characters .
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <label class="form-label">Phone Number <span style="color: red;">*</span></label>
                <div class="row">
                  <div class="col-4">
                    <div class="dropdown">
                      <button class="form-control dropdown-toggle" type="button" id="dropdownMenuButton1"
                        data-bs-toggle="dropdown" data-toggle="dropdown" aria-expanded="false">
                        +<span class="country-code">{{ selectedCountryCode }}</span>
                      </button>
                      <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1" style="    max-height: 200px;
                          overflow: auto;width: max-content;">
                        <input type="search" (input)="onInputC($event)" class="form-control search-all" style="
                            height: auto;
                            padding: 4px 12px;
                            margin: 0 9px;
                          " placeholder="Search " autocomplete="off" />
                        <li *ngFor="let country of countries" style="    padding: 5px;cursor: pointer;">
                          <span href="#" (click)="selectCountry(country)">
                            <img src="{{
                                    country.flag || country.flags.svg || country.flags.png
                                  }}" alt="" style="height: 15px; width: 20px" />
                            <span style="padding-left: 8px">{{ country.name }} (+{{ country.callingCodes[0] }})</span>
                          </span>
                        </li>
                      </ul>
                    </div>
                    <div *ngIf="createLeads.countryCode.touched && createLeads.countryCode.errors"
                      class="invalid-feedback">
                      <div *ngIf="createLeads.countryCode.errors.required">Country Code is required</div>
                      <div *ngIf="createLeads.countryCode.errors.pattern">Please use valid country code .</div>
                    </div>
                  </div>
                  <div class="col-8">
                    <input class="form-control" name="phoneNumber" formControlName="phoneNumber" [ngClass]="{
                          'is-invalid':
                          createLeads.phoneNumber.touched && createLeads.phoneNumber.invalid,
                          'is-valid':
                            createLeads.phoneNumber.valid &&
                            createLeads.phoneNumber.touched
                        }" />
                    <div *ngIf="createLeads.phoneNumber.touched && createLeads.phoneNumber.errors"
                      class="invalid-feedback">
                      <div *ngIf="createLeads.phoneNumber.errors.required">Phone Number is required</div>
                      <div *ngIf="createLeads.phoneNumber.errors.pattern">Please use valid phone number .</div>
                      <div *ngIf="createLeads.phoneNumber.errors.minlength">Minimum length should be 7 characters .
                      </div>
                      <div *ngIf="createLeads.phoneNumber.errors.maxlength">Maximum length should be 15 characters .
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label class="form-label">Email <span style="color: red;">*</span></label>
                <input type="text" name="email" class="form-control" formControlName="email" [ngClass]="{
                      'is-invalid': createLeads.email.touched && createLeads.email.invalid,
                      'is-valid':
                        createLeads.email.valid && createLeads.email.touched
                    }" />
                <div *ngIf="createLeads.email.touched && createLeads.email.errors" class="invalid-feedback">
                  <div *ngIf="createLeads.email.errors.required">Email is required</div>
                  <div *ngIf="createLeads.email.errors.pattern">Please use valid email</div>
                </div>
              </div>
              <div class="form-group">
                <label class="form-label">Form Name <span style="color: red;">*</span></label>
                <select class="form-control" name="" id="" formControlName="form_name" [ngClass]="{
                  'is-invalid': createLeads.form_name.touched && createLeads.form_name.invalid,
                  'is-valid':
                    createLeads.form_name.valid &&
                    createLeads.form_name.touched
                }">
                  <option value="" selected disabled>Select one</option>
                  <option value="website">Website</option>
                  <option value="facebook">Facebook</option>
                  <option value="instagram">Instagram</option>
                  <option value="linkedin">LinkedIn</option>
                </select>
                <!-- <input type="text" name="form_name" class="form-control" formControlName="form_name" [ngClass]="{
                      'is-invalid': createLeads.form_name.touched && createLeads.form_name.invalid,
                      'is-valid':
                        createLeads.form_name.valid &&
                        createLeads.form_name.touched
                    }" /> -->
                <div *ngIf="createLeads.form_name.touched && createLeads.form_name.errors" class="invalid-feedback">
                  <div *ngIf="createLeads.form_name.errors.required">Form Name is required</div>
                  <div *ngIf="createLeads.form_name.errors.pattern">Please use valid Name</div>
                  <div *ngIf="createLeads.form_name.errors.minlength">Minimum length should be 2 characters .</div>
                  <div *ngIf="createLeads.form_name.errors.maxlength">Maximum length should be 50 characters .
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label class="form-label">Channel <span style="color: red;">*</span></label>
                <input type="text" name="channel" class="form-control" value="socialmedia" formControlName="channel" [ngClass]="{
                      'is-invalid': createLeads.channel.touched && createLeads.channel.invalid,
                      'is-valid':
                        createLeads.channel.valid &&
                        createLeads.channel.touched
                    }" />
                <div *ngIf="createLeads.channel.touched && createLeads.channel.errors" class="invalid-feedback">
                  <div *ngIf="createLeads.channel.errors.required">Channel is required</div>
                  <div *ngIf="createLeads.channel.errors.pattern">Please use valid channel name</div>
                  <div *ngIf="createLeads.channel.errors.minlength">Minimum length should be 2 characters .</div>
                  <div *ngIf="createLeads.channel.errors.maxlength">Maximum length should be 50 characters .
                  </div>
                </div>
              </div>
              <div class="d-flex justify-content-end">
                <button class="btn btn-light btn-sm mr-4" (click)="modal.dismiss('Cross click');resetAddLeadForm();">Cancel</button>
                <button type="submit" class="btn btn-primary btn-sm" [disabled]="!uhleadsservice.createLead.valid">Submit</button>
              </div>
            </form>
          </div>
        </ng-template>

        <ng-template id="pdfContent" class="modal-x" #pdfContent let-modal>
          <div id="progressBar">
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
          </div>
          <ngx-doc-viewer [url]="docUrl" viewer="google" googleCheckContentLoaded="true" googleCheckInterval="5000"
            googleMaxChecks="15" style="width: 100%; height: 93vh" (loaded)="contentLoaded()">
          </ngx-doc-viewer>
        </ng-template>


        <!-- Edit Lead New -->
        <ng-template #editLead2 let-modal>
          <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">Update Lead Details</h4>
            <button type="button" id="closeButton3" class="close" aria-label="Close"
              (click)="modal.dismiss('Cross click')">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form class="pt-2" [formGroup]="uhleadsservice.editLead" (ngSubmit)="updateLead()">
              <div class="form-row">

                <div class="form-group col-md-4">
                  <label>First Name<span style="color: red;">*</span></label>
                  <input class="form-control" name="FirstName" formControlName="FirstName"
                    [(ngModel)]="leadtoEdit.FirstName"
                    [ngClass]="{ 'is-invalid': editLeads.FirstName.touched && editLeads.FirstName.errors }" />
                  <div *ngIf="editLeads.FirstName.touched && editLeads.FirstName.errors" class="invalid-feedback">
                    <div *ngIf="editLeads.FirstName.errors.required">First Name is required</div>
                    <div *ngIf="editLeads.FirstName.errors.pattern">Please use valid Name</div>
                    <div *ngIf="editLeads.FirstName.errors.minlength">Minimum length should be 2 characters .
                    </div>
                    <div *ngIf="editLeads.FirstName.errors.maxlength">Maximum length should be 50 characters .</div>
                  </div>
                </div>

                <div class="form-group col-md-4">
                  <label>Last Name<span style="color: red;">*</span></label>
                  <input class="form-control" name="LastName" formControlName="LastName"
                    [(ngModel)]="leadtoEdit.LastName"
                    [ngClass]="{ 'is-invalid':editLeads.LastName.touched && editLeads.LastName.errors }" />
                  <div *ngIf="editLeads.LastName.touched && editLeads.LastName.errors" class="invalid-feedback">
                    <div *ngIf="editLeads.LastName.errors.required">Last Name is required</div>
                    <div *ngIf="editLeads.LastName.errors.pattern">Please use valid Name</div>
                    <div *ngIf="editLeads.LastName.errors.minlength">Minimum length should be 2 characters .
                    </div>
                    <div *ngIf="editLeads.LastName.errors.maxlength">Maximum length should be 50 characters .</div>
                  </div>

                </div>

                <div class="form-group col-md-4">
                  <label>Date of Birth</label>
                  <input type="date" name="DOB" [value]="leadtoEdit.DOB | date:'yyyy-MM-dd'" class="form-control"
                    formControlName="DOB" [(ngModel)]="leadtoEdit.DOB " />

                </div>

              </div>

              <div class="form-row">
                <div class="form-group col-md-4">
                  <label>Email<span style="color: red;">*</span></label>
                  <input type="text" name="Email" class="form-control" formControlName="Email"
                    [(ngModel)]="leadtoEdit.Email"
                    [ngClass]="{ 'is-invalid': editLeads.Email.touched && editLeads.Email.errors }" />
                  <div *ngIf="editLeads.Email.touched && editLeads.Email.errors" class="invalid-feedback">
                    <div *ngIf="editLeads.Email.errors.required">Email is required</div>
                    <div *ngIf="editLeads.Email.errors.pattern">Please use valid Email</div>
                  </div>

                </div>

                <div class="form-group col-md-4">
                  <label>Phone Number <span style="color: red;">*</span></label>
                  <div class="form-row">
                    <div class="col-4">
                      <div class="dropdown">
                        <button class="form-control dropdown-toggle" type="button" id="dropdownMenuButton1"
                          data-bs-toggle="dropdown" data-toggle="dropdown" aria-expanded="false">
                          +<span class="country-code">{{ selectedCountryCode }}</span>
                        </button>
                        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1" style="    max-height: 200px;
                              overflow: auto;width: max-content;">
                          <input type="search" (input)="onInputC($event)" class="form-control search-all" style="
                                height: auto;
                                padding: 4px 12px;
                                margin: 0 9px;
                              " placeholder="Search " autocomplete="off" />
                          <li *ngFor="let country of countries" style="    padding: 5px;cursor: pointer;">
                            <span href="#" (click)="selectCountryEdit(country)">
                              <img src="{{
                                        country.flag || country.flags.svg || country.flags.png
                                      }}" alt="" style="height: 15px; width: 20px" />
                              <span style="padding-left: 8px">{{ country.name }} (+{{ country.callingCodes[0]
                                }})</span>
                            </span>
                          </li>
                        </ul>
                      </div>
                      <div *ngIf="editLeads.CountryCode.touched && editLeads.CountryCode.errors"
                        class="invalid-feedback">
                        <div *ngIf="editLeads.CountryCode.errors.required">Country Code is required</div>
                        <div *ngIf="editLeads.CountryCode.errors.pattern">Please use valid Country Code</div>
                      </div>

                    </div>
                    <div class="col-8">
                      <input class="form-control" name="PhoneNumber" formControlName="PhoneNumber"
                        [(ngModel)]="leadtoEdit.PhoneNumber"
                        [ngClass]="{ 'is-invalid': editLeads.PhoneNumber.touched && editLeads.PhoneNumber.errors }" />
                      <div *ngIf="editLeads.PhoneNumber.touched && editLeads.PhoneNumber.errors"
                        class="invalid-feedback">
                        <div *ngIf="editLeads.PhoneNumber.errors.required">Phone Number is required</div>
                        <div *ngIf="editLeads.PhoneNumber.errors.pattern">Only numbers are allowed</div>
                        <div *ngIf="editLeads.PhoneNumber.errors.minlength">Minimum length should be 7 characters .
                        </div>
                        <div *ngIf="editLeads.PhoneNumber.errors.maxlength">Maximum length should be 15 characters .
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
                <div class="form-group col-md-4">
                  <label for="">Enrollment Year <span style="color: red;">*</span></label>
                  <input type="text" name="EnrollmentYear" class="form-control" formControlName="EnrollmentYear"
                    [(ngModel)]="leadtoEdit.EnrollmentYear"
                    [ngClass]="{ 'is-invalid': editLeads.EnrollmentYear.touched && editLeads.EnrollmentYear.errors }" />

                  <div *ngIf="editLeads.EnrollmentYear.touched && editLeads.EnrollmentYear.errors"
                    class="invalid-feedback">
                    <div *ngIf="editLeads.EnrollmentYear.errors.required">Enrollment Year is required</div>
                    <div *ngIf="editLeads.EnrollmentYear.errors.pattern">Only numbers are allowed</div>
                  </div>

                </div>
              </div>

              <div class="form-row">


                <div class="form-group col-md-4">
                  <label for="">Enrollment Term <span style="color: red;">*</span></label>
                  <input type="text" name="EnrollmentTerm" class="form-control" formControlName="EnrollmentTerm"
                    [(ngModel)]="leadtoEdit.EnrollmentTerm"
                    [ngClass]="{ 'is-invalid': editLeads.EnrollmentTerm.touched && editLeads.EnrollmentTerm.errors }" />

                  <div *ngIf="editLeads.EnrollmentTerm.touched && editLeads.EnrollmentTerm.errors"
                    class="invalid-feedback">
                    <div *ngIf="editLeads.EnrollmentTerm.errors.required">Enrollment Term is required</div>
                  </div>

                </div>
                <div class="form-group col-md-4">
                  <label for="">Address </label>
                  <input type="text" name="Address" class="form-control" formControlName="Address"
                    [(ngModel)]="leadtoEdit.Address" />

                </div>

                <div class="form-group col-md-4">
                  <label for="">City </label>
                  <input type="text" name="City" class="form-control" formControlName="City"
                    [(ngModel)]="leadtoEdit.City" />

                </div>

              </div>

              <div class="form-row">



                <div class="form-group col-md-4">
                  <label for="">State </label>
                  <input type="text" name="State" class="form-control" formControlName="State"
                    [(ngModel)]="leadtoEdit.State" />

                </div>
                <div class="form-group col-md-4">
                  <label for="">Zip </label>
                  <input type="text" name="Zip" class="form-control" formControlName="Zip"
                    [(ngModel)]="leadtoEdit.Zip" />

                </div>

                <div class="form-group col-md-4">
                  <label for="">Country </label>
                  <div class="dropdown">
                    <button class="form-control dropdown-toggle" type="button" id="dropdownMenuButton1"
                      data-bs-toggle="dropdown" data-toggle="dropdown" aria-expanded="false">
                      <span class="country-code">{{ selectedCountryName }}</span>
                    </button>
                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1" style="    max-height: 200px;
                            overflow: auto;width: max-content;">
                      <input type="search" (input)="onInputC($event)" class="form-control search-all" style="
                              height: auto;
                              padding: 4px 12px;
                              margin: 0 9px;
                            " placeholder="Search " autocomplete="off" />
                      <li *ngFor="let country of countries" style="    padding: 5px;cursor: pointer;">
                        <span href="#" (click)="selectCountryEditName(country)">
                          <img src="{{
                                      country.flag || country.flags.svg || country.flags.png
                                    }}" alt="" style="height: 15px; width: 20px" />
                          <span style="padding-left: 8px">{{ country.name }}</span>
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>

              </div>

              <div class="form-row">

                <div class="form-group col-md-4">
                  <label for="">Comments </label>
                  <input type="text" name="LeadComment" class="form-control" formControlName="LeadComment"
                    [(ngModel)]="leadtoEdit.LeadComment" />

                </div>

              </div>


              <div class="form-row">
                <div class="form-group col-md-12">
                  <label for="">Feedback</label>
                  <div class="mt-2">

                    <div class="form-check form-check-inline" *ngFor="let item of getOnlyCallFeedbacks(listFeed)">
                      <input class="form-check-input" type="radio" name="CallStatus" id="inlineRadio1"
                        [value]="item.value" formControlName="CallStatus" [(ngModel)]="leadtoEdit.CallStatus" />
                      <label class="form-check-label" for="inlineRadio1">{{item.name}}</label>
                    </div>

                  </div>
                </div>

              </div>
              <div class="d-flex justify-content-end">
                <div class="mt-3">
                  <button class="btn btn-primary btn-sm" type="submit"
                    [disabled]="!uhleadsservice.editLead.valid">Update</button>
                </div>
              </div>
            </form>
          </div>
        </ng-template>

        <ng-template id="totalCallCount" class="modal-x" #totalCallCount let-modal>
          <div class="modal-header nm">
            <h4 class="modal-title" id="modal-basic-title">Call</h4>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body ">

            <div class="px-2">
              <div class="py-2 header-md" style="color:#163252">
                You have reached the calling limit.<br>Are you sure you want to continue?</div>
              <button type="button" class="btn btn-primary btn-sm mr-4" (click)="thresHold()">
                Confirm
              </button>
              <button type="button" class="btn btn-primary btn-sm" (click)="modal.dismiss('Cross click')">
                Cancel
              </button>
            </div>
          </div>
        </ng-template>
      </div>
      <div class="fixed-footer">
        Powered by <a href="#">Avantao</a>
      </div>
    </div>
  </div>
</div>
<ng-template #cardsChangemodal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Cards</h4>
    <button type="button" id="closeButton3" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-md-4" *ngFor="let item of dummycards1">
        <mat-selection-list>
          <mat-list-option style="font-size: 13px" [selected]="item.view"
            (click)="removeCard(item.primaryKey,!item.view)">
            {{ item["header"] | titlecase }}
          </mat-list-option>
        </mat-selection-list>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 text-right">
        <button type="button" class="btn btn-primary btn-sm" (click)="modal.dismiss('Cross click')">
          Update
        </button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #tablesChangemodal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Tables</h4>
    <button type="button" id="closeButton3" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-md-4" *ngFor="let item of projectSettings1">
        <mat-selection-list>
          <mat-list-option style="font-size: 13px" [selected]="item.view"
            (click)="removeTable(item.primaryKey,!item.view)">
            {{ item["header"] | titlecase }}
          </mat-list-option>
        </mat-selection-list>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 text-right">
        <button type="button" class="btn btn-primary btn-sm" (click)="updateTables();modal.dismiss('Cross click')">
          Update
        </button>
      </div>
    </div>
  </div>
</ng-template>