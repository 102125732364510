import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ProjectchangeService } from 'src/app/layout/project-change/projectchange.service';
import { RoleService } from '../role-management/role.service';
import { EventCalendarService } from './event-calendar.service';
import { environment } from '../../../environments/environment';
import * as moment from 'moment';
import { ProjectService } from '../project-management/project.service';
import { SharedService } from 'src/app/shared/shared.service';
import { EventScheduleService } from '../event-schedule/event-schedule.service';
const timeDataset = require('./timedataset.json')
const countryCodes = require('../../../assets/allcountryDetails.json');

declare var EventCalendar: any
declare let $: any;
@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.css']
})

export class CalendarComponent implements OnInit {
  projectId: any = 0
  event_form: FormGroup;
  share_event_details: FormGroup;
  user_details: any
  eventDetails: any = []
  upcomingEventList: any = []
  membersEmail: any = []
  shareMembersEmail: any = []
  showEventDetail: any = {}
  isShowing: Boolean = false;
  is_project_change: Boolean = false
  show_schedule_event: Boolean = true
  copyURL: any = null
  allProjects: any;
  projectDetails = {
    projectName: null,
    supportMail: null,
    ProjectId: null
  }
  showModal: boolean;
  startMinDate: any
  endMinDate: any;
  startTimeDataset: any;
  endTimeDataset: any;
  isLoading: Boolean = false
  noRecords: Boolean = false
  agentScheduledEvents: any = []
  countries: any[] = [];
  selectedCountryCode: any;
  selectedDate: any;
  constructor(
    private eventService: EventCalendarService,
    private formBuilder: FormBuilder,
    private toastrService: ToastrService,
    private projectchangeService: ProjectchangeService,
    private companyService: RoleService,
    private projectService: ProjectService,
    private SharedService: SharedService,
    private eventScheduleService: EventScheduleService,

  ) { }
  ngOnInit(): void {
    this.SharedService.pageEvent.emit({
      pageName: "My Calendar",
    });
    this.startMinDate = moment(new Date).format('YYYY-MM-DD');
    this.endMinDate = moment(new Date).format('YYYY-MM-DD');

    this.user_details = JSON.parse(localStorage.getItem("userData"))



    let admin = false;
    this.projectService.dashboardProjects({
      admin: admin,
      companyId: this.user_details["companyId"],
      userId: this.user_details["id"],
    }).subscribe((Data) => {
      this.allProjects = Data
    });

    this.getStartEndTimeDataset()
    this.onInit()

    setTimeout(() => {
      this.projectId = this.projectchangeService._projectId;
      let selectedProject = this.allProjects.filter(x => x.id == this.projectId);
      this.projectDetails.projectName = selectedProject[0].Name
      this.projectDetails.supportMail = selectedProject[0].SupportMail

      this.copyURL = environment.BaseURL + "calendar/" + this.user_details.firstName + "?id=" + Buffer.from(this.user_details.id.toString(), 'binary').toString('base64').toString() + "&pid=" + Buffer.from(this.projectId.toString(), 'binary').toString('base64').toString()

    }, 500);

    if (!this.projectId) {
      this.companyService.projectAdmin(this.user_details.companyId).subscribe((projects) => {
        this.projectId = projects[0]["id"]
        let selectedProject = this.allProjects.filter(x => x.id == this.projectId);
        this.projectDetails.projectName = selectedProject[0].Name
        this.projectDetails.supportMail = selectedProject[0].SupportMail
        this.copyURL = environment.BaseURL + "calendar/" + this.user_details.firstName + "?id=" + Buffer.from(this.user_details.id.toString(), 'binary').toString('base64').toString() + "&pid=" + Buffer.from(this.projectId.toString(), 'binary').toString('base64').toString()

      });
    }
    setTimeout(() => {
      this.getEventsList()
      this.getAgentEventDetails()
      // this.setupCalendarDetails()
    }, 600);

    this.countries = countryCodes
  }
  onInit() { // Form Initialize
    let d = new Date()

    // let currentDate = d.getFullYear() + "-" + this._pad((d.getMonth() + 1)) + "-" + d.getDate()
    let currentDate = d.getFullYear() + "-" + this._pad((d.getMonth() + 1)) + "-" + this._pad(d.getDate())
    let currentTime = d.getHours() + ":" + d.getMinutes()
    this.selectedDate = currentDate
    this.event_form = this.formBuilder.group({
      EventTitle: ['', Validators.required],
      EventDescription: ['', Validators.required],
      EventType: [null, Validators.required],
      EventLocation: [null, Validators.required],
      Message: [''],
      MobileNumber: [''],
      CountryCode: [''],
      Members: ['', Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$")],
      StartDate: [currentDate],
      StartTime: ['', Validators.required],
      EndDate: [currentDate],
      EndTime: ['', Validators.required]
    })
    this.share_event_details = this.formBuilder.group({
      Message: ['Sample content They define what is the problem, who are the users, and what are the constraints and timeframes. Personally, the smaller these requirements meetings, the better, for instance.'],
      Members: ['', Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$")]
    })
  }

  getStartEndTimeDataset() { // For Time Dataset
    this.startTimeDataset = timeDataset
      .filter(x => {
        if (x.time > moment(new Date()).format('HH:mm:ss')) {
          return x
        }
      });
    this.endTimeDataset = timeDataset
      .filter(x => {
        if (x.time > moment(new Date()).format('HH:mm:ss')) {
          return x
        }
      });
  }

  get f() { // get Form Controls
    return this.event_form.controls;
  }

  addEvent() { // Add Event 

    if (this.event_form.value['EndTime'] < this.event_form.value['StartTime']) {
      this.toastrService.error("Please select valid end time!")
    }
    else {
      if (this.event_form.valid) {
        this.isShowing = !this.isShowing
        if (this.event_form.value['Members']) {
          this.membersEmail.push(this.event_form.value['Members'])
        }
        let payload = {
          ...this.event_form.value,
          AgentId: this.user_details.id,
          AgentMailId: this.user_details.mail,
          ProjectId: this.projectId ? this.projectId : 0,
          Members: this.membersEmail.join(",")
        }

        this.eventService.createEvent(payload).subscribe((Data: any) => {
          if (Data.status === true) {

            let ec = document.getElementById("ec")
            let ecl = document.getElementsByClassName("ec-month")

            for (var i = ecl.length - 1; 0 <= i; i--) {
              if (ecl[i] && ecl[i].parentElement)
                ecl[i].parentElement.removeChild(ecl[i]);
            }

            this.getEventsList()
            this.getAgentEventDetails()
            this.resetForm();
            this.toastrService.success(Data.message)
          }
          else {
            this.toastrService.warning(Data.message)
          }
        })
      }
      else {
        this.toastrService.error("Please fill mandatory fields!")
      }
    }
  }

  resetForm() {
    if (this.event_form) {
      this.event_form.reset()
    }
    this.membersEmail = []
    this.selectedCountryCode = null
    this.onInit()
  }

  setupCalendarDetails() {
    let that = this
    let ec = new EventCalendar(document.getElementById('ec'), {
      view: 'dayGridMonth',
      height: '666px',
      headerToolbar: {
        start: 'prev,next today',
        center: 'title',
        end: 'dayGridMonth,timeGridWeek,timeGridDay'
      },

      scrollTime: '09:00:00',
      events: this.bindEventCal(),
      eventClick: function (eventClickInfo) {
        let SelectedeventDetails = eventClickInfo.event
        let event = that.eventDetails.filter(x => x.id == SelectedeventDetails.id)
        that.showEventDetail = event[0]
        that.showModal = true
      },
      views: {
        timeGridWeek: { pointer: true },
      },
      dayMaxEvents: true,
      nowIndicator: true
    });
    // this.isLoading=false
  }

  bindEventCal() {
    let tempeventData = []

    for (let i = 0; i < this.eventDetails.length; i++) {
      const element = this.eventDetails[i];
      let color;
      switch (element.EventType) {
        case 'General Meetings':
          color = '#EFF3FE';
          // color = '#5E81F4'
          break;
        case 'Conference':
          color = '#F2FDF7'
          break;
        case 'Board Meeting':
          color = '#FFF2F3'
          break;
        case 'Client Meeting':
          color = '#FEF9EE'
          break;

        default:
          color = "#FE6B64"
          break;
      }
      tempeventData.push({
        // title: element.EventTitle ? element.EventTitle : 'Meeting with ' + element.Name, color: color, id:element.id,start: element.StartDate + " " + element.StartTime, end: element.EndDate + " " + element.EndTime
        id: element.id, start: element.StartDate + " " + element.StartTime, end: element.EndDate + " " + element.EndTime, title: element.EventTitle ? element.EventTitle : 'Meeting with ' + element.Name, color: color
      })

      // tempeventData.push({
      //   id:element.id,start: element.StartDate + " " + element.StartTime, end: element.EndDate + " " + element.EndTime, title: element.EventTitle ? element.EventTitle : 'Meeting with ' + element.Name, color: color
      // })
    }

    return tempeventData;


  }

  getEventsList() {
    this.eventDetails = []
    let d = new Date()
    // let currentDate = d.getFullYear() + "-" + this._pad(d.getMonth() + 1) + "-" + d.getDate();
    // let currentTime = moment(d).format('HH:mm')
    let payload = {
      ProjectId: this.projectId ? this.projectId : 0,
      AgentId: this.user_details.id,
      AgentMailId: this.user_details.mail,

    }
    // this.isLoading = true
    let apiName = this.show_schedule_event ? 'getScheduleEvent' : 'getEvent'
    this.eventService.getEvent(payload).subscribe((Data: any) => {

      if (Data.status === true) {

        this.eventDetails = Data.data.filter(x => {
          if (x.EmailId) {
            x.Members = x.EmailId + "," + x.Members
          }
          if (x.Members) {
            x.Members = x.AgentMailId + "," + x.Members
          }
          else {
            x.Members = x.AgentMailId
          }
          return x
        })
      }
      else {
        this.eventDetails = []
      }
      // this.isLoading = false
      this.upcomingEvents()
      this.setupCalendarDetails()
    });

  }

  upcomingEvents() {
    let payload = {
      ProjectId: this.projectId ? this.projectId : 0,
      AgentId: this.user_details.id,
      AgentMailId: this.user_details.mail
    }
    this.eventService.getUpcomingEvent(payload).subscribe((Data: any) => {
      if (Data.status === true) {
        this.upcomingEventList = Data.data.filter(x => {
          if (x.EmailId) {
            x.Members = x.EmailId + "," + x.Members
          }
          if (x.Members) {
            x.Members = x.AgentMailId + "," + x.Members
          }
          else {
            x.Members = x.AgentMailId
          }
          return x
        })
      }
      else {
        this.upcomingEventList = []
      }
    });
  }

  _pad(num) {
    let norm = Math.floor(Math.abs(num));
    return (norm < 10 ? '0' : '') + norm;
  }

  projectChange(data) {

    this.show_schedule_event = false
    this.projectId = data;

    let selectedProject = this.allProjects.filter(x => x.id == this.projectId);
    this.projectDetails.ProjectId = this.projectId
    this.projectDetails.projectName = selectedProject[0].Name
    this.projectDetails.supportMail = selectedProject[0].SupportMail


    this.copyURL = environment.BaseURL + "calendar/" + this.user_details.firstName + "?id=" + Buffer.from(this.user_details.id.toString(), 'binary').toString('base64').toString() + "&pid=" + Buffer.from(this.projectId.toString(), 'binary').toString('base64').toString()

    let ec = document.getElementById("ec")
    let ecl = document.getElementsByClassName("ec-month")

    for (var i = ecl.length - 1; 0 <= i; i--)
      if (ecl[i] && ecl[i].parentElement)
        ecl[i].parentElement.removeChild(ecl[i]);

    this.getEventsList()
    this.getAgentEventDetails()
  }

  enterKey(event: any) {
    if (event.keyCode === 13 || event.keyCode === 188) {
      if (event.keyCode === 188) {
        this.event_form.controls['Members'].setValue(this.event_form.value['Members'].split(",")[0])
      }
      if (this.share_event_details.valid) {
        this.membersEmail.push(this.event_form.value['Members'])
        this.event_form.controls['Members'].setValue(null)
      }
      else {
        this.toastrService.warning("Please enter valid email!")
      }
    }
  }

  removeMemberEmails(index: any) {
    this.membersEmail.splice(index, 1)
  }

  enterKeyShare(event: any) {
    if (event.keyCode === 13 || event.keyCode === 188) {
      if (event.keyCode === 188) {
        this.share_event_details.controls['Members'].setValue(this.share_event_details.value['Members'].split(",")[0])
      }
      if (this.share_event_details.valid) {
        this.shareMembersEmail.push(this.share_event_details.value['Members'])
        this.share_event_details.controls['Members'].setValue(null)
      }
      else {
        this.toastrService.warning("Please enter valid email!")
      }

    }
  }

  removeShareMemberEmails(index: any) {
    this.shareMembersEmail.splice(index, 1)
  }

  showEventDetails(Details: any) {
    this.showEventDetail = Details
  }

  toggleSideNav() {
    this.isShowing = !this.isShowing
    // this.getStartEndTimeDataset()
  }

  showShareForm() {
    this.share_event_details.controls['Members'].setValue(null);
    this.shareMembersEmail = []
  }

  shareDetails() {

    if (this.share_event_details.value['Members']) {
      this.shareMembersEmail.push(this.share_event_details.value['Members'])
    }
    let req_payload = {
      ...this.share_event_details.value,
      Members: this.shareMembersEmail,
      ProjectId: this.projectId ? this.projectId : 0,
      id: this.user_details.id
    }
    this.eventService.shareEventDetails(req_payload).subscribe((Data: any) => {
      if (Data.status === true) {
        this.showShareForm();
        this.show()
        this.toastrService.success(Data.message);
      }
      else {
        this.toastrService.error(Data.message);
      }
    });

  }

  copyText() {
    if (this.projectId) {
      let encrypted_agentId = Buffer.from(this.user_details.id.toString(), 'binary').toString('base64').toString()
      let encryoted_projectId = Buffer.from(this.projectId.toString(), 'binary').toString('base64').toString()
      this.copyURL = environment.BaseURL + "calendar/" + this.user_details.firstName + "?id=" + encrypted_agentId.replace("=", "%3D") + "&pid=" + encryoted_projectId.replace("=", "%3D")
      navigator.clipboard.writeText(this.copyURL);
      this.toastrService.success("Copied!")
      this.show()
    }
    else {
      this.toastrService.warning("Please select project!")
    }

  }

  show() {
    document.getElementById("myDropdown").classList.toggle("show");
  }

  checkMettingLinkDuration(data: any) {
    let d = new Date()
    let currentDate = moment(new Date()).format('YYYY-MM-DD')
    let currentTime = moment(new Date()).format('HH:mm')

    if (data.StartDate == currentDate && data.EndTime >= currentTime) {
      return 'auto'
    }
    else {
      return 'none'
    }
  }

  selectedStartDate(event: any) {
    let d = new Date(event.target['value'])
    let curretnDate = moment(new Date()).format('YYYY-MM-DD')
    // this.getTimeSlots(event.target['value'])
    this.startTimeDataset = timeDataset
    this.endTimeDataset = timeDataset
    this.selectedDate = event.target['value']
    if (this.agentScheduledEvents && this.agentScheduledEvents.length) {
      let agentEventDetailsForSelectedDate = this.agentScheduledEvents.filter(x => x.StartDate == d.getFullYear() + "-" + this._pad(d.getMonth() + 1) + "-" + this._pad(d.getDate()))
      if (agentEventDetailsForSelectedDate && agentEventDetailsForSelectedDate.length) {
        for (let i = 0; i < agentEventDetailsForSelectedDate.length; i++) {
          let AgentEventStartTime = moment(agentEventDetailsForSelectedDate[i].StartDate + " " + agentEventDetailsForSelectedDate[i].StartTime).format('HH:mm:ss ')
          let AgentEventEndTime = moment(agentEventDetailsForSelectedDate[i].EndDate + " " + agentEventDetailsForSelectedDate[i].EndTime).format('HH:mm:ss ')
          this.startTimeDataset = this.startTimeDataset.filter(x => {
            if (x.time >= AgentEventStartTime.trim() && x.time <= AgentEventEndTime.trim()) {
              // return x.time, x.Eventbooked = true
            }
            else {
              if (curretnDate == agentEventDetailsForSelectedDate[i].StartDate && x.time > moment(new Date()).format('HH:mm:ss')) {
                return x
              }
              if (curretnDate != agentEventDetailsForSelectedDate[i].StartDate) {
                return x
              }
            }
          })
          this.endTimeDataset = this.endTimeDataset.filter(x => {
            if (x.time >= AgentEventStartTime.trim() && x.time <= AgentEventEndTime.trim()) {
              // return x.time, x.Eventbooked = true
            }
            else {
              if (curretnDate == agentEventDetailsForSelectedDate[i].StartDate && x.time > moment(new Date()).format('HH:mm:ss')) {
                return x
              }
              if (curretnDate != agentEventDetailsForSelectedDate[i].StartDate) {
                return x
              }
            }
          });

        }
      }
      else {
        if (this.selectedDate == moment(new Date()).format('YYYY-MM-DD')) {
          this.startTimeDataset = timeDataset.filter(x => {
            if (x.time > moment(new Date()).format('HH:mm:ss')) {
              return x
            }
          })
          this.endTimeDataset = timeDataset.filter(x => {
            if (x.time > moment(new Date()).format('HH:mm:ss')) {
              return x
            }
          })
        }
        else {
          this.startTimeDataset = timeDataset
          this.endTimeDataset = timeDataset
        }
      }
    }
    this.event_form.controls['EndDate'].setValue(event.target['value'])
    this.event_form.controls['StartTime'].setValue(null)
    this.event_form.controls['EndTime'].setValue(null)
    this.selectedDate = event.target['value']
  }

  selectedStartTime(event: any) {
    this.event_form.controls['EndTime'].setValue(null)

    this.endTimeDataset = this.startTimeDataset.filter(x => {
      if (x.time > event.target['value'] + ":00")
        return x
    })

    // this.event_form.controls['EndTime'].value(this.endTimeDataset[0]['time'])
  }

  getAgentEventDetails() { // For Agent's Scheduled event
    let payload = {
      id: Buffer.from(this.user_details.id.toString(), 'binary').toString('base64').toString(),
      ProjectId: this.projectId
    }
    this.eventScheduleService.getAgentEventDetails(payload).subscribe((Data: any) => {
      if (Data.status === true) {
        this.agentScheduledEvents = Data.data
        // this.showcalendar = true
        let d = new Date()
        if (this.agentScheduledEvents && this.agentScheduledEvents.length) {
          let agentEventDetailsForSelectedDate = this.agentScheduledEvents.filter(x => x.StartDate == d.getFullYear() + "-" + this._pad(d.getMonth() + 1) + "-" + this._pad(d.getDate()))

          for (let i = 0; i < agentEventDetailsForSelectedDate.length; i++) {
            let eventStartTime = moment(agentEventDetailsForSelectedDate[i].StartDate + " " + agentEventDetailsForSelectedDate[i].StartTime).format('HH:mm:ss')
            let eventEndTime = moment(agentEventDetailsForSelectedDate[i].EndDate + " " + agentEventDetailsForSelectedDate[i].EndTime).format('HH:mm:ss')

            this.startTimeDataset = this.startTimeDataset.filter(x => {

              if (x.time >= eventStartTime.trim() && x.time <= eventEndTime.trim()) {
                // return x.time, x.Eventbooked = true
              }
              else {
                if (x.time > moment(new Date()).format('HH:mm:ss')) {
                  return x
                }
              }
            })
            this.endTimeDataset = this.endTimeDataset.filter(x => {
              if (x.time >= eventStartTime.trim() && x.time <= eventEndTime.trim()) {
                // return x.time, x.Eventbooked = true
              }
              else {
                if (x.time > moment(new Date()).format('HH:mm:ss')) {
                  return x
                }
              }
            });
          }

        }

      }
      // else {
      //   this.toastrservice.error(Data.message)
      // }
    })
  }

  selectCountry(country) {
    this.selectedCountryCode = country.callingCodes[0];
    this.event_form.controls['CountryCode'].setValue(this.selectedCountryCode)

  }

  onInput(Event: any) {
    let search = Event.target.value;
    let serachData = [];
    if (search) {
      this.countries = countryCodes.filter((x) => {
        return x.name.toLowerCase().includes(search.toLowerCase());
      });
    } else {
      this.countries = countryCodes;
    }
  }
}
