<div class="pt-3 px-3 d-flex justify-content-between mb-4">
    <div class="db-sm-header" style="line-height: 330%">
        Activity
    </div>
    <div class="db-sm-header mb-1 ml-auto" (click)="hideDate()">
        <div class="d-flex">
            <div *ngIf="isAdmin">
                <button [matTooltip]="isAdmin?'In Cards':''" class="btn btn-secondary btn-sm mr-2"
                    [ngStyle]="{'pointer-events': isAdmin ? 'auto' : 'none'}" (click)="editfeedbackCards()">
                    <img src="../../../assets/images/edit.svg" class="img10" *ngIf="isAdmin" /> Cards
                </button>
            </div>
            <div *ngIf="isAdmin">
                <button [matTooltip]="isAdmin?'In Calling screen':''"
                    [ngStyle]="{'pointer-events': isAdmin ? 'auto' : 'none'}" class="btn btn-secondary btn-sm  mr-2"
                    (click)="editfeedbackCalls()">
                    <img src="../../../assets/images/edit.svg" *ngIf="isAdmin" class="img11" />
                    Calls
                </button>
            </div>
            <div>
                <button class="btn btn-secondary btn-sm  mr-2" id="leadEditButton" #leadEditButton
                    (click)="openEditLeadModal(leadEditModal)" style="display: none;"><img
                        src="../../../assets/images/edit.svg" class="img10" />
                    Edit Lead
                </button>
            </div>
            <div>
                <button class="btn btn-secondary btn-sm  mr-2" id="addLeadButton" #addLeadButton
                    (click)="openEditLeadModal(addLeadModal)" style="display: none;">
                    <img src="../../../assets/images/edit.svg" class="img10" /> Add Lead

                </button>
            </div>
            <div>
                <button class="btn btn-secondary btn-sm  mr-2" id="callLeadButton" #callLead
                    (click)="openCallLeadModal(callLeadModal)" style="display: none;"
                    [ngStyle]="{'pointer-events': isAdmin ? 'auto' : 'none'}">
                    <img src="../../../assets/images/edit.svg" class="img11" /> Call Lead
                </button>
            </div>

            <div>
                <button class="form-group" style="padding: 0">
                    <select class="form-control cdd" id="" style="height:24px;padding: 0; background: #fff"
                        [(ngModel)]="filterValue" (change)="updateDateFilter($event)">
                        <option value="all">All</option>
                        <option value="24hrs">24 hrs</option>
                        <option value="72hrs">72 hrs</option>
                        <option value="7days">07 days</option>
                        <option value="14days">14 days</option>
                        <option value="30days">30 days</option>
                        <option value="custom">Custom</option>
                    </select>
                </button>
            </div>
        </div>
    </div>

    <div *ngIf="showDate" class="datePicker">
        <div style="background: #fff; height: 76px; border: 1px solid #ccc" class="custom-date">
            <mat-form-field class="mr-3">
                <input class="label" matInput [matDatepicker]="picker1" placeholder="Start Date" [formControl]="date2"
                    (dateInput)="getStartDate('input', $event)" />
                <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                <mat-datepicker #picker1 style="width: 80px"></mat-datepicker>
            </mat-form-field>

            <mat-form-field class="mr-3">
                <input class="label" matInput [matDatepicker]="picker2" placeholder="End Date" [formControl]="date1"
                    (dateInput)="getEndDate('input', $event)" />
                <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                <mat-datepicker #picker2 style="width: 80px"></mat-datepicker>
            </mat-form-field>
            <button class="btn btn-secondary btn-sm" (click)="filterData()">
                Submit
            </button>
        </div>
    </div>
</div>
<app-cards></app-cards>

<div class="px-2 pt-3 d-flex">
    <div class="ml-auto" *ngIf="isAdmin">
        <button [matTooltip]="isAdmin?'In Dashboard':''" class="btn btn-secondary btn-sm  mr-2"
            [ngStyle]="{'pointer-events': isAdmin ? 'auto' : 'none'}" (click)="editColumnsDashboard()">
            <img src="../../../assets/images/edit.svg" class="img10" *ngIf="isAdmin" /> Dashboard
        </button>
        <button [matTooltip]="isAdmin?'In Overview':''" class="btn btn-secondary btn-sm  mr-2"
            [ngStyle]="{'pointer-events': isAdmin ? 'auto' : 'none'}" (click)="editColumnsOverview()">
            <img src="../../../assets/images/edit.svg" class="img10" *ngIf="isAdmin" /> Overview
        </button>
    </div>
</div>
<div class="p-3">
    <div class="table-responsive data-table">
        <app-table></app-table>
    </div>
</div>

<ng-template #cardsChangemodal let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Cards</h4>
        <button type="button" id="closeButton3" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
</ng-template>

<ng-template #tablesChangemodal let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Tables</h4>
        <button type="button" id="closeButton3" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-md-4" *ngFor="let item of columnSettings">
                <mat-selection-list>
                    <mat-list-option style="font-size: 13px" [selected]="item.view"
                        (click)="removeTable(item.primaryKey, !item.view)">
                        {{ item["header"] | titlecase }}
                    </mat-list-option>
                </mat-selection-list>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12 text-right">
                <button type="button" class="btn btn-primary btn-sm"
                    (click)="updateTables(); modal.dismiss('Cross click')">
                    Update
                </button>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #leadEditModal let-modal>
    <div class="d-flex mat-modal-header p-4" style="width:100%">
        <div class="modal-title component-title mr-auto">Edit Lead</div>
        <div class="ml-auto close hand" (click)="modal.dismiss('Cross click')">
            <img src="../../../../assets/img/close.svg" class="img10" />
        </div>
    </div>
    <div class="ion-form-ctr">
        <div class="row p-4">
            <div class="col-md-4 pb-2" *ngFor="let control of editFormData?.controls">
                <ion-item class="form__fields">
                    <ion-label position="stacked" class="ion-label">{{ control?.label }}</ion-label>
                    <ion-input *ngIf="
        [
          'text',
          'password',
          'email',
          'string',
          'String',
          'number',
          'search',
          'tel',
          'url',
          'Date'
        ].includes(control.type)
      " [type]="control.type" [(ngModel)]="control.value" [value]="control.value"></ion-input>

                    <ion-textarea *ngIf="control.type === 'textarea'" [value]="control.value"
                        [(ngModel)]="control.value">
                    </ion-textarea>
                    <ion-checkbox *ngIf="control.type === 'checkbox'" [checked]="control.value"
                        [(ngModel)]="control.value">
                    </ion-checkbox>
                    <ion-toggle *ngIf="control.type === 'toggle'" [checked]="control.value" [(ngModel)]="control.value">
                    </ion-toggle>
                    <ion-range *ngIf="control.type === 'range'" [min]="control.options.min" [max]="control.options.max"
                        [(ngModel)]="control.value">
                        <ion-icon size="small" slot="start" [name]="control.options.icon"></ion-icon>
                        <ion-icon slot="end" [name]="control.options.icon"></ion-icon>
                    </ion-range>
                    <ion-select *ngIf="control.type === 'select' && control.name == 'callstatus'"
                        [value]="control.value" [(ngModel)]="control.value">
                        <ion-select-option *ngFor="let i of feedbackList" [value]="i.value">{{
                            i?.name
                            }}</ion-select-option>
                    </ion-select>
                    <ion-select *ngIf="control.type === 'select' && control.name == 'assignedto'"
                        [value]="control.value" [(ngModel)]="control.value">
                        <ion-select-option *ngFor="let i of agentList" [value]="i.Mailid">{{
                            i?.FirstName + ' '+ i?.LastName
                            }}</ion-select-option>
                    </ion-select>

                    <ion-select *ngIf="control.type === 'select' && control.name == 'channel'" [value]="control.value"
                        [(ngModel)]="control.value">
                        <ion-select-option *ngFor="let i of channelList" [value]="i.value">{{
                            i?.name
                            }}</ion-select-option>
                    </ion-select>

                    <ion-select *ngIf="control.type === 'select' && control.name == 'formname'" [value]="control.value"
                        [(ngModel)]="control.value">
                        <ion-select-option *ngFor="let i of formList" [value]="i">
                            <p style="text-transform: capitalize;">{{i}}</p>
                        </ion-select-option>
                    </ion-select>


                </ion-item>
            </div>
            <div *ngFor="let control of editFormData?.controls">
                <ion-item *ngIf="control.name == 'callstatus' && control.value == 'qualifiedhotlead'">
                    <ion-label position="stacked" class="ion-label">Counselling Date : </ion-label>
                    <ion-input type="datetime-local" [(ngModel)]="counsellingDate"></ion-input>
                </ion-item>
            </div>
        </div>

    </div>
    <div class="pb-4 text-center">
        <ion-button type="submit" (click)="editLeadData()">Update</ion-button>
    </div>
</ng-template>


<ng-template #addLeadModal let-modal>
    <div class="d-flex mat-modal-header p-4" style="width:100%">
        <div class="modal-title component-title mr-auto">Add Lead</div>
        <div class="ml-auto close hand" (click)="modal.dismiss('Cross click')">
            <img src="../../../../assets/img/close.svg" class="img10" />
        </div>
    </div>
    <div class="ion-form-ctr">
        <div class="row p-4">
            <div class="col-md-4 pb-2" *ngFor="let control of editFormData?.controls">
                <ion-item class="form__fields">
                    <ion-label position="stacked" class="ion-label">{{ control?.label }} </ion-label>
                    <ion-input *ngIf="
                    [
                      'text',
                      'password',
                      'email',
                      'string',
                      'String',
                      'number',
                      'search',
                      'tel',
                      'url',
                      'Date'
                    ].includes(control.type)
                  " [type]="control.type" [(ngModel)]="control.value" [value]="control.value"></ion-input>
                    <ion-textarea *ngIf="control.type === 'textarea'" [value]="control.value"
                        [(ngModel)]="control.value">
                    </ion-textarea>
                    <ion-checkbox *ngIf="control.type === 'checkbox'" [checked]="control.value"
                        [(ngModel)]="control.value">
                    </ion-checkbox>
                    <ion-toggle *ngIf="control.type === 'toggle'" [checked]="control.value" [(ngModel)]="control.value">
                    </ion-toggle>
                    <ion-range *ngIf="control.type === 'range'" [min]="control.options.min" [max]="control.options.max"
                        [(ngModel)]="control.value">
                        <ion-icon size="small" slot="start" [name]="control.options.icon"></ion-icon>
                        <ion-icon slot="end" [name]="control.options.icon"></ion-icon>
                    </ion-range>
                    <ion-select *ngIf="control.type === 'select' && control.name == 'callstatus'"
                        [value]="control.value" [(ngModel)]="control.value">
                        <ion-select-option *ngFor="let i of feedbackList" [value]="i.value">{{
                            i?.name
                            }}</ion-select-option>
                    </ion-select>
                    <ion-select *ngIf="control.type === 'select' && control.name == 'assignedto'"
                        [value]="control.value" [(ngModel)]="control.value">
                        <ion-select-option *ngFor="let i of agentList" [value]="i.Mailid">{{
                            i?.FirstName + ' '+ i?.LastName
                            }}</ion-select-option>
                    </ion-select>

                    <ion-select *ngIf="control.type === 'select' && control.name == 'channel'" [value]="control.value"
                        [(ngModel)]="control.value">
                        <ion-select-option *ngFor="let i of channelList" [value]="i.value">{{
                            i?.name
                            }}</ion-select-option>
                    </ion-select>

                    <ion-select *ngIf="control.type === 'select' && control.name == 'formname'" [value]="control.value"
                        [(ngModel)]="control.value">
                        <ion-select-option *ngFor="let i of formList" [value]="i">{{
                            i
                            }}</ion-select-option>
                    </ion-select>

                </ion-item>
            </div>
        </div>

    </div>
    <div class="pb-4 text-center">
        <ion-button type="submit" (click)="addLeadData()">Submit</ion-button>
    </div>

</ng-template>

<ng-template #callLeadModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Calling Screen</h4>
        <button type="button" id="closeButton3" class="close" aria-label="Close"
            (click)="modal.dismiss('Cross click'); resetCallingScreen()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body" style="overflow: hidden">
        <div class="row">
            <div class="call-module ml-3">
                <div class="ndialer">
                    <div class="c-caller">Current Caller</div>
                    <div class="dialer-middle-screen" style="text-align: center" *ngIf="dialerScreen1 === 'outgoing1'">
                        <div class="custom-incomingcall-widget">
                            <h4 class="mt-3 text-ellipsis">
                                {{ marketingId.name }}
                            </h4>
                            <div class="mb-2 mt-1 lg-text" style="font-weight: 100" *ngIf="marketingId.city">
                                {{ marketingId.city }}
                            </div>
                            <div class="my-1 lg-text">
                                {{ marketingId.phoneNumber || agentCall }}
                            </div>
                            <div class="custom-user-img my-3 mt-5">
                                <img class="img80" src="./assets/img/user-w.svg" alt="" />
                            </div>
                            <h5 *ngIf="callStatus != 'inProgress'">{{ callStatus }}</h5>
                            <p *ngIf="callStatus == 'inProgress'" class="custom-call-timer" style="text-align: center">
                                <countup-timer [countUpTimerConfig]="testConfig"></countup-timer>
                            </p>

                            <div class="custom-incoming-btns my-3 mt-4">
                                <button type="button" class="btn-round m-r-10" (click)="stopCurrentCall()">
                                    <img class="img-38" src="./assets/img/hangup.svg" alt="" />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="call-module-update ml-4 mr-2">
                <div class="row">
                    <div class="col-md-12">
                        <div class="header-md mt-2 mb-1">Lead details</div>
                    </div>
                </div>

                <div class="row custom-row" *ngFor="
            let c of config.columns;
            let i = index;
            let even = even;
            let odd = odd
          ">
                    <div class="col-md-4" *ngIf="even">
                        <div class="form-group">
                            <label for="formGroupExampleInput">{{
                                config.columns[i].displayName
                                }}</label>
                            <div>{{leadToCall[config.columns[i].name]}}</div>
                        </div>
                    </div>
                    <div class="col-md-4" *ngIf="even && i + 1 < columnList.length">
                        <div class="form-group">
                            <label for="formGroupExampleInput">{{
                                config.columns[i + 1].displayName
                                }}</label>
                            <div>{{ leadToCall[config?.columns[i + 1]?.name] }}</div>
                        </div>
                    </div>
                </div>
                <div class="row custom-row" *ngFor="
            let item of documentsList;
            let i = index;
            let even = even;
            let odd = odd
          ">
                    <div class="col-md-3 py-2" *ngIf="odd">
                        <img *ngIf="item.status == true" class="img18 mr-2" src="../../../../assets/img/tick2.svg" />
                        <img *ngIf="item.status == false" class="img18 mr-2" src="../../../../assets/img/minus.svg" />{{
                        documentsList[i].DocumentName }}
                    </div>
                    <div class="col-md-3 py-2" *ngIf="even && i + 1 < documentsList.length">
                        <img *ngIf="documentsList[i + 1].status == true" class="img18 mr-2"
                            src="../../../../assets/img/tick2.svg" />
                        <img *ngIf="documentsList[i + 1].status == false" class="img18 mr-2"
                            src="../../../../assets/img/minus.svg" />{{ documentsList[i + 1].DocumentName }}
                    </div>
                </div>

                <hr class="hr-style mt-1 mb-1" />
                <div class="row custom-row2">
                    <div class="col-md-12">
                        <div class="header-12 mt-2 mb-1">Feedback</div>
                    </div>
                    <ion-item>
                        <ion-label>Call Status : </ion-label>
                        <ion-select [value]="newStatus" [(ngModel)]="newStatus">
                            <ion-select-option *ngFor="let i of feedbackList" [value]="i.value">{{i.name}}
                            </ion-select-option>
                        </ion-select>
                    </ion-item>
                </div>

                <span *ngIf="!newStatus" class="error-msg" style="color: red; font-size: 13px;padding-top:5px">
                    Please select any option above!
                </span>
                <div class="row custom-row">
                    <div class="col-md-12">
                        <div class="form-group">
                            <label for="commentText" class="header-12 pt-3 pb-2">Comments</label>
                            <textarea class="form-control" id="commentText" rows="3"
                                [(ngModel)]="commentText"></textarea>
                        </div>
                    </div>
                </div>

                <div class="d-flex justify-content-end">
                    <div class="mt-3">
                        <button (click)="resetCallingScreen()" class="btn btn-light btn-sm mr-2" type="button">
                            Reset
                        </button>
                        <button (click)="updateComments()" class="btn btn-primary btn-sm" type="submit">
                            Update
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>
<div class="fixed-footer">
    Powered by
    <a href="https://avantao.com/#/" target="_blank">Avantao</a>
</div>