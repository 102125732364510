import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'profilephoto'
})
export class ProfilephotoPipe implements PipeTransform {

  transform(value: string, args: any[]): any {
    if (!value) {
      return null;
    }
    if (args.find(data => data['value'] === value)) {
      let agentName = args.find(data => data['value'] === value);
      return agentName['profilePhoto'] ? agentName['profilePhoto'] : 'assets/img/contact-profile-dummy.png'

    } else {
      return value
    }
  }

}
